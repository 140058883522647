import { CancelMFDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCancelMFDealRequestRPCImpl extends CancelMFDealRequestRPC {
  execute(
    _request: CancelMFDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CancelMFDealRequestRPC.Response,
      CancelMFDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CancelMFDealRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CancelMFDealRequestRPC.Response,
        CancelMFDealRequestRPC.Errors.Errors
      >
    >;
  }
}
