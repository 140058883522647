"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiPurchaseStatus = exports.InvalidFiPurchaseStatusError = void 0;
class InvalidFiPurchaseStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiPurchaseStatusError";
    }
}
exports.InvalidFiPurchaseStatusError = InvalidFiPurchaseStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiPurchaseStatus;
(function (FiPurchaseStatus_1) {
    let FiPurchaseStatus;
    (function (FiPurchaseStatus) {
        FiPurchaseStatus["INVESTMENT_OPEN"] = "INVESTMENT_OPEN";
        FiPurchaseStatus["MATURED"] = "MATURED";
        FiPurchaseStatus["PARTIALLY_SOLD"] = "PARTIALLY_SOLD";
        FiPurchaseStatus["SETTLED"] = "SETTLED";
    })(FiPurchaseStatus = FiPurchaseStatus_1.FiPurchaseStatus || (FiPurchaseStatus_1.FiPurchaseStatus = {}));
    FiPurchaseStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "INVESTMENT_OPEN":
                return FiPurchaseStatus.INVESTMENT_OPEN;
            case "MATURED":
                return FiPurchaseStatus.MATURED;
            case "PARTIALLY_SOLD":
                return FiPurchaseStatus.PARTIALLY_SOLD;
            case "SETTLED":
                return FiPurchaseStatus.SETTLED;
            default:
                throw new InvalidFiPurchaseStatusError(`Case ${dto.case} is not valid case of FiPurchaseStatus`);
        }
    };
    FiPurchaseStatus_1.toDTO = (fiPurchaseStatus) => {
        const ret = {
            case: FiPurchaseStatus[fiPurchaseStatus],
        };
        return ret;
    };
    FiPurchaseStatus_1.isInstanceOf = (other) => {
        if (other in FiPurchaseStatus) {
            return true;
        }
        return false;
    };
})(FiPurchaseStatus = exports.FiPurchaseStatus || (exports.FiPurchaseStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
