import { SettleFiMaturityRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSettleFiMaturityRequestRPCImpl extends SettleFiMaturityRequestRPC {
  execute(
    _request: SettleFiMaturityRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SettleFiMaturityRequestRPC.Response,
      SettleFiMaturityRequestRPC.Errors.Errors
    >
  > {
    const response = new SettleFiMaturityRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SettleFiMaturityRequestRPC.Response,
        SettleFiMaturityRequestRPC.Errors.Errors
      >
    >;
  }
}
