import React, { useState, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Instance } from "mobx-state-tree";
import {
  DropdownItem,
  SearchField,
  Button,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { MFDealRequestSearchBy } from "@khazana/khazana-rpcs";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { MIN_SEARCH_LENGTH } from "../../../../../utils";
import { getDealRequestSearchByValue } from "../../deal-request/utils/SearchUtils";
import { MFConfirmationFilterOptionsModel } from "../store/ManageConfirmationRequestsStore/ManageConfirmationDetailsStore";
import {
  getContractNoteSearchByOptions,
  getContractNoteStatusOptionsWithAll,
  getMFConfirmationInitialFilter,
} from "../utils/SearchUtils";
import { getBrokerOptions } from "../../../utils/SearchUtils";
import { BrokerModel } from "../../../models/BrokerModel";

interface ContractNoteRequestSearchFilterProps {
  filter: Instance<typeof MFConfirmationFilterOptionsModel>;
  setFilter: (
    newFilter: Instance<typeof MFConfirmationFilterOptionsModel>,
  ) => void;
  brokers: Instance<typeof BrokerModel>[];
}

const Size = {
  container: "100%",
  searchField: "696px",
};

export const ContractNoteRequestsSearchFilter = ({
  filter,
  setFilter,
  brokers,
}: ContractNoteRequestSearchFilterProps): React.ReactElement => {
  const { t } = useTranslation();
  const initialFilter = getMFConfirmationInitialFilter();
  const [currentFilter, setCurrentFilter] = useState(filter);
  const searchByOptions = getContractNoteSearchByOptions();
  const [searchBy, setSearchBy] = useState(filter.searchBy);
  const [searchText, setSearchText] = useState(filter.searchText);
  const requestStatusOptions = getContractNoteStatusOptionsWithAll();
  const [requestStatus, setRequestStatus] = useState(filter.requestStatus);
  const brokerOptions = getBrokerOptions(t, brokers, true);
  const spacing = useSpacing();
  const [brokerId, setBrokerId] = useState(filter.brokerId);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);

  const getFilter = (): Instance<typeof MFConfirmationFilterOptionsModel> => {
    return {
      searchBy,
      searchText,
      requestStatus,
      brokerId,
    };
  };

  useEffect(() => {
    setFilter(filter);
  }, []);

  useEffect(() => {
    const newFilter = getFilter();
    if (
      newFilter.searchText &&
      newFilter.searchText.length < MIN_SEARCH_LENGTH
    ) {
      setIsSearchDisabled(true);
    } else if (newFilter.requestStatus !== currentFilter.requestStatus) {
      setIsSearchDisabled(false);
    } else if (newFilter.brokerId !== currentFilter.brokerId) {
      setIsSearchDisabled(false);
    } else if (
      newFilter.searchText.length >= MIN_SEARCH_LENGTH &&
      newFilter.searchBy !== currentFilter.searchBy
    ) {
      setIsSearchDisabled(false);
    } else if (newFilter.searchText !== currentFilter.searchText) {
      setIsSearchDisabled(false);
    } else if (
      currentFilter.searchText.length >= MIN_SEARCH_LENGTH &&
      !newFilter.searchText
    ) {
      setIsSearchDisabled(false);
    } else {
      setIsSearchDisabled(true);
    }
  }, [searchText, searchBy, requestStatus, brokerId]);

  const onSearchByChange = (selected: DropdownItem): void => {
    setSearchBy(selected.value);
  };

  const onSearchClear = (): void => {
    setSearchText("");
  };

  const onSearchTextChange = (searchValue: string): void => {
    setSearchText(searchValue.trim());
  };

  const onRequestStatusChange = (selected: DropdownItem): void => {
    setRequestStatus(selected.value);
  };

  const onBrokerChange = (selected: DropdownItem): void => {
    setBrokerId(selected.value);
  };

  const onReset = (): void => {
    setSearchBy(initialFilter.searchBy);
    setSearchText(initialFilter.searchText);
    setRequestStatus(initialFilter.requestStatus);
    setBrokerId(initialFilter.brokerId);
    setCurrentFilter(initialFilter);
    setFilter(initialFilter);
  };

  return (
    <Stack
      sx={{
        padding: spacing.spaceMD,
        width: Size.container,
      }}
    >
      <Grid container spacing={spacing.spaceMD}>
        <Grid item xs={4}>
          <DropdownInputFieldSeparateLabel
            name="searchByDropdown"
            label={t("common.searchBy")}
            value={searchBy}
            options={searchByOptions}
            onSelect={onSearchByChange}
          />
        </Grid>
        <Grid item xs={6} alignSelf="flex-end">
          <SearchField
            name="searchField"
            value={searchText}
            onTextChange={onSearchTextChange}
            onClear={onSearchClear}
            allowClearField={true}
            placeholder={`${t(
              "common.searchUsing",
            )} ${getDealRequestSearchByValue(
              t,
              MFDealRequestSearchBy.fromDTO({ case: searchBy }),
            )}`}
            style={{
              maxWidth: Size.searchField,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DropdownInputFieldSeparateLabel
            name="requestStatusFilter"
            value={requestStatus}
            onSelect={onRequestStatusChange}
            label={t("common.status")}
            options={requestStatusOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <DropdownInputFieldSeparateLabel
            name="broker"
            value={brokerId}
            onSelect={onBrokerChange}
            label={t("common.broker")}
            options={brokerOptions}
          />
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          gap={spacing.spaceXS}
          paddingBottom={spacing.spaceXXS}
        >
          <Button
            name="plain-color"
            variant="plain-color"
            color="destructive"
            size="medium"
            label={t("common.reset")}
            disabled={JSON.stringify(filter) === JSON.stringify(initialFilter)}
            onClick={onReset}
          />
          <Button
            name="filled"
            variant="filled"
            size="medium"
            disabled={isSearchDisabled}
            label={t("common.search")}
            onClick={(): void => {
              setIsSearchDisabled(true);
              setCurrentFilter({
                ...getFilter(),
              });
              setFilter({
                ...getFilter(),
              });
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
