import {
  Currency,
  GetFdInvestmentDetailsRPC,
  PayoutFrequency,
  BigDecimal,
  BigAmount,
  CashflowTimelineItem,
  CashflowTimelineAmount,
  ChargeAmount,
  InterestType,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

export class MockGetFdInvestmentDetailsRPCImpl extends GetFdInvestmentDetailsRPC {
  execute(
    _request: GetFdInvestmentDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFdInvestmentDetailsRPC.Response,
      GetFdInvestmentDetailsRPC.Errors.Errors
    >
  > {
    const isFixed = true;
    const response = new GetFdInvestmentDetailsRPC.Response(
      InterestType.InterestType.CUMULATIVE,
      "HDFC BANK",
      PayoutFrequency.PayoutFrequency.MONTHLY,
      new LeoDate(),
      isFixed,
      new LeoDate(),
      isFixed,
      new ChargeAmount(10000000000, new Currency("INR", "₹")),
      "1234567890",
      "HDFC BANK",
      78000,
      isFixed ? new ChargeAmount(10000000, new Currency("INR", "₹")) : null,
      isFixed ? "0987654321" : null,
      [
        new CashflowTimelineItem(new LeoDate(), [
          new CashflowTimelineAmount(
            "cashflow.timeline.totalInvestmentLabel",
            new BigAmount(
              new BigDecimal("296912.92"),
              new Currency("INR", "₹"),
            ),
            null,
          ),
        ]),
        new CashflowTimelineItem(new LeoDate(), [
          new CashflowTimelineAmount(
            "cashflow.timeline.couponLabel",
            new BigAmount(
              new BigDecimal("1477.80", true),
              new Currency("INR", "₹"),
            ),
            null,
          ),
          new CashflowTimelineAmount(
            "cashflow.timeline.principalLabel",
            new BigAmount(
              new BigDecimal("1200.00", true),
              new Currency("INR", "₹"),
            ),
            null,
          ),
        ]),
        new CashflowTimelineItem(new LeoDate(), [
          new CashflowTimelineAmount(
            "cashflow.timeline.couponLabel",
            new BigAmount(new BigDecimal("1477.80"), new Currency("INR", "₹")),
            null,
          ),
        ]),
      ],
      true,
      1,
    );
    // const error = new GetFdInvestmentDetailsRPC.Errors.InvalidDealRequestId();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFdInvestmentDetailsRPC.Response,
        GetFdInvestmentDetailsRPC.Errors.Errors
      >
    >;
  }
}
