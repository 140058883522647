import React from "react";
import { Grid, Stack } from "@mui/material";
import { ReportCard, ReportCardProps } from "./ReportCard";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import { useNavigate } from "react-router-dom";

export interface ReportGridProps {
  reports: ReportCardProps[];
}

export const ReportGrid = ({
  reports,
}: ReportGridProps): React.ReactElement => {
  const spacing = useSpacing();
  const navigate = useNavigate();

  return (
    <Stack padding={spacing.space2XL}>
      <Grid container spacing={spacing.spaceLG}>
        {reports.map((report, index) => {
          return (
            <Grid
              item
              key={index}
              sx={{ cursor: "pointer" }}
              onClick={(): void => navigate(report.path)}
            >
              <ReportCard {...report} />
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};
