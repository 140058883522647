import { Instance, types } from "mobx-state-tree";
import {
  createRequestNoteModel,
  RequestNoteModel,
} from "../../../models/RequestNoteModel";
import { AmountModel } from "../../../models/AmountModel";
import { CurrencyModel } from "../../../models/CurrencyModel";
import {
  FiContractNoteHistoryDetail,
  FiContractNoteRequestStatus,
  FiYieldType,
} from "@khazana/khazana-rpcs";
import { FiContractNoteDetailType } from "./FiContractNoteDetailType";
import {
  FiContractNoteChargeType,
  createFiContractNoteChargeType,
} from "./FiContractNoteChargeType";

export const FiContractNoteDealRequestDetails = types.model({
  dematAccountNumber: types.string,
  entity: types.string,
  ytm: types.number,
  yieldType: types.enumeration<FiYieldType.FiYieldType>(
    "FiYieldType",
    Object.values(FiYieldType.FiYieldType),
  ),
  amount: types.maybeNull(AmountModel),
  quantity: types.maybeNull(types.number),
  transactionType: types.string,
  symbol: types.string,
  requestId: types.number,
  status: types.string,
});

export const FiContractNoteHistoryDetailModel = types.model(
  "FiDealRequestHistoryDetailModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: types.maybeNull(
      types.enumeration<FiContractNoteRequestStatus.FiContractNoteRequestStatus>(
        "FiContractNoteRequestStatus",
        Object.values(FiContractNoteRequestStatus.FiContractNoteRequestStatus),
      ),
    ),
    dealRequestDetails: types.maybeNull(FiContractNoteDealRequestDetails),
    diffData: types.array(FiContractNoteDetailType),
    charges: types.array(FiContractNoteChargeType),
  },
);

export const createFiContractNoteHistoryDetailModel = (
  historyDetails: FiContractNoteHistoryDetail | null,
): Instance<typeof FiContractNoteHistoryDetailModel> => {
  if (historyDetails) {
    const { status, requestNote, dealRequestDetails, diffDetails, charges } =
      historyDetails;
    let dealDetails: Instance<typeof FiContractNoteDealRequestDetails> | null =
      null;
    if (dealRequestDetails) {
      dealDetails = FiContractNoteDealRequestDetails.create({
        dematAccountNumber: dealRequestDetails?.dematAccountNumber,
        entity: dealRequestDetails.entity,
        ytm: dealRequestDetails.ytm,
        yieldType: dealRequestDetails.yieldType,
        amount: dealRequestDetails.amount
          ? AmountModel.create({
              amount: dealRequestDetails.amount.amount,
              currency: CurrencyModel.create({
                code: dealRequestDetails.amount.currency.code,
                symbol: dealRequestDetails.amount.currency.symbol,
              }),
            })
          : null,
        quantity: dealRequestDetails.quantity?.quantity,
        transactionType: dealRequestDetails.transactionType,
        symbol: dealRequestDetails.securityName,
        requestId: dealRequestDetails.requestId,
        status: dealRequestDetails.requestStatus,
      });
    }

    return FiContractNoteHistoryDetailModel.create({
      requestNote: createRequestNoteModel(requestNote),
      status,
      dealRequestDetails: dealDetails,
      diffData:
        diffDetails.map((detail) =>
          FiContractNoteDetailType.create({
            diffData: detail.diffData ? detail.diffData : null,
            originalData: detail.originalData,
            localizedTextId: detail.localizedTextId,
          }),
        ) ?? [],
      charges: charges.map((charge) => createFiContractNoteChargeType(charge)),
    });
  } else {
    return FiContractNoteHistoryDetailModel.create();
  }
};
