import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";
import { DiffCellType } from "../../utils/DiffDetailCellUtils";

export const TextFieldStore = types
  .model("TextFieldStore", {
    value: types.maybe(types.string),
    isDisabled: types.optional(types.boolean, false),
    errorMessage: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setValue: (value: string | undefined): void => {
        store.errorMessage = undefined;
        store.value = value;
      },
      setIsDisabled: (isDisabled: boolean): void => {
        store.isDisabled = isDisabled;
      },
      setErrorMessage: (errorMessage: string | undefined): void => {
        store.errorMessage = errorMessage;
      },
    };
  });

export const createTextField = (): Instance<typeof TextFieldStore> => {
  return TextFieldStore.create({});
};

export const createTextFieldFromDiff = (
  diff: DiffCellType | undefined,
): Instance<typeof TextFieldStore> => {
  if (!diff) {
    return createTextField();
  }
  return TextFieldStore.create({
    value: (diff.diffValue as string | undefined) ?? (diff.value as string),
  });
};
