import {
  Currency,
  EquityRealisedGain,
  GetEquityTopTenRealisedGainsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityTopTenRealisedGainsRPCImpl extends GetEquityTopTenRealisedGainsRPC {
  execute(
    _request: GetEquityTopTenRealisedGainsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityTopTenRealisedGainsRPC.Response,
      GetEquityTopTenRealisedGainsRPC.Errors.Errors
    >
  > {
    const response = new GetEquityTopTenRealisedGainsRPC.Response(
      [
        new EquityRealisedGain("HDFC", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain("ICICI", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain("Axis", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain("SBI", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain("Kotak", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain("BOSCHLTD", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain("FOCUS", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain("PIXTRANS", 1000000000, 123232323, 2000, 120000),
        new EquityRealisedGain(
          "MAGADSUGAR",
          1000000000,
          123232323,
          2000,
          120000,
        ),
        new EquityRealisedGain("HCG", 1000000000, 123232323, 2000, 120000),
      ],
      new Currency("INR", "₹"),
      0,
      0,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityTopTenRealisedGainsRPC.Response,
        GetEquityTopTenRealisedGainsRPC.Errors.Errors
      >
    >;
  }
}
