import {
  FixedDepositInvestmentRegisterResponse,
  GetFixedDepositInvestmentRegisterReportRPC,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFixedDepositInvestmentRegisterReportRPC extends GetFixedDepositInvestmentRegisterReportRPC {
  execute(
    _request: GetFixedDepositInvestmentRegisterReportRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFixedDepositInvestmentRegisterReportRPC.Response,
      GetFixedDepositInvestmentRegisterReportRPC.Errors.Errors
    >
  > {
    const response = new GetFixedDepositInvestmentRegisterReportRPC.Response(
      new LeoDate(),
      new LeoDate(),
      [new FixedDepositInvestmentRegisterResponse("HDFC")],
      10,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFixedDepositInvestmentRegisterReportRPC.Response,
        GetFixedDepositInvestmentRegisterReportRPC.Errors.Errors
      >
    >;
  }
}
