import {
  Currency,
  GetFdCashflowsPaginationResponse,
  GetFdCashflowsRPC,
  BigDecimal,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

const fiCashflows: GetFdCashflowsPaginationResponse[] = [
  new GetFdCashflowsPaginationResponse(
    1,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    1,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    null,
    null,
    null,
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    3,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    4,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    5,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    6,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    7,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    8,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    9,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    10,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    11,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    12,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    13,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    14,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    15,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    16,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    17,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    18,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    19,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
  new GetFdCashflowsPaginationResponse(
    20,
    1,
    new LeoDate(),
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new LeoDate(),
    "HDFC Bank",
    "7676798679676978",
    true,
    new Currency("INR", "₹"),
  ),
];

export class MockGetFdCashflowsRPCImpl extends GetFdCashflowsRPC {
  execute(
    request: GetFdCashflowsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFdCashflowsRPC.Response,
      GetFdCashflowsRPC.Errors.InvalidPageIndex
    >
  > {
    let cashflows = fiCashflows;
    if (request.purchaseTransactionId) {
      cashflows = cashflows.filter((t) => {
        return t.purchaseTransactionId === request.purchaseTransactionId;
      });
    }
    const response = new GetFdCashflowsRPC.Response(
      cashflows.slice(
        request.itemsPerPage.items * request.pageIndex.items,
        request.itemsPerPage.items * (request.pageIndex.items + 1),
      ),
      cashflows.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetFdCashflowsRPC.Response, GetFdCashflowsRPC.Errors.Errors>
    >;
  }
}
