import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Avatar,
  Chip,
  ChipColor,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FiImpactChartBuyModel,
  FiImpactChartSellModel,
} from "../fixed-income/deal-request/models/FiDealRequestHistoryAdditionalDetailModel";
import { ImpactOnPortfolio } from "./ImpactOnPortfolio";
import {
  EqImpactChartBuyModel,
  EqImpactChartSellModel,
  EqImpactTableModel,
} from "../equity/deal-request/models/EquityDealRequestHistoryAdditionalDetailModel";
import { PortfolioBuyChart } from "./PortfolioBuyChart";
import { PortfolioSellChart } from "./PortfolioSellChart";
import { useBorder } from "../../../utils/BorderUtils";
import { getFormattedTimeDateWithComma } from "../../../utils";
import { ImpactTableModel } from "../models/ImpactTableModel";

export interface DealStatusSectionProps {
  firstName: string;
  lastName: string | null;
  requestedAt: string;
  note: string | null;
  impactBuyChart:
    | Instance<typeof FiImpactChartBuyModel>
    | Instance<typeof EqImpactChartBuyModel>
    | undefined;
  impactSellChart:
    | Instance<typeof FiImpactChartSellModel>
    | Instance<typeof EqImpactChartSellModel>
    | undefined;
  isHistoryComponent: boolean;
  impactOnPortfolio:
    | Instance<typeof ImpactTableModel>
    | Instance<typeof EqImpactTableModel>
    | undefined;
  userImage: string | undefined;
  title: string;
  chipText: string;
  chipColor: ChipColor;
  assetName: string | null;
  transactionType: string | null;
}

const Size = {
  container: "100%",
};

export const DealStatusSection = ({
  firstName,
  lastName,
  requestedAt,
  note,
  impactBuyChart,
  impactSellChart,
  isHistoryComponent,
  impactOnPortfolio,
  userImage,
  title,
  chipText,
  chipColor,
  assetName,
  transactionType,
}: DealStatusSectionProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const border = useBorder();

  return (
    <Stack
      sx={{
        backgroundColor: tokens.background,
        width: Size.container,
        borderRadius: isHistoryComponent
          ? `0 0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS}`
          : cornerRadius.radiusXS,
        border: isHistoryComponent ? undefined : border.default,
        borderRight: isHistoryComponent ? undefined : border.default,
      }}
    >
      <Stack flexDirection="row" padding={spacing.spaceXL}>
        <Avatar
          userName={`${firstName} ${lastName ?? ""}`}
          size="large"
          src={userImage}
        />
        <Box sx={{ marginLeft: spacing.spaceMD }}>
          <Typography
            sx={{
              ...typography.sh3,
              color: tokens.labelHighEmphasis,
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {getFormattedTimeDateWithComma(new Date(requestedAt))}
          </Typography>
        </Box>
      </Stack>
      {!isHistoryComponent && <Divider />}
      <Stack
        padding={spacing.spaceXL}
        paddingTop={isHistoryComponent ? "0" : spacing.spaceXL}
        gap={spacing.spaceXL}
        marginLeft={isHistoryComponent ? "64px" : "0px"}
      >
        <Box>
          <Chip label={chipText} color={chipColor} />
        </Box>
        {note && (
          <Stack>
            <Typography {...typography.s1}>{t("common.note")}</Typography>
            <Typography {...typography.b1}>{note}</Typography>
          </Stack>
        )}
        {impactOnPortfolio && (
          <Stack flexDirection="row" gap={spacing.spaceXL}>
            <ImpactOnPortfolio impactTableModel={impactOnPortfolio} />
            {impactBuyChart ? (
              PortfolioBuyChart(
                impactBuyChart.requested,
                impactBuyChart.existing,
                impactBuyChart.otherStocks,
                assetName,
                transactionType,
              )
            ) : impactSellChart ? (
              PortfolioSellChart(
                impactSellChart.existing,
                impactSellChart.otherStocksWithExisting,
                impactSellChart.requested,
                impactSellChart.otherStocksWithRequested,
                assetName,
                transactionType,
              )
            ) : (
              <></>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
