import React, { useMemo } from "react";
import { BorderStyle, useBorder } from "../../../../utils/BorderUtils";
import { Typography as TypographyProps } from "@surya-digital/leo-reactjs-core/dist/theme/typography";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import {
  Dialog,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useCornerRadius } from "@surya-digital/leo-reactjs-core";
import { Stack, Typography } from "@mui/material";
import { WarningAlertComponent } from "../WarningAlertComponent";

export interface CreateRequestSubtitle {
  title: string;
  value: string;
}
export interface CreateRequestDialogProps {
  title: string;
  primaryButtonText: string;
  onPrimaryButtonClick: () => Promise<void>;
  secondaryButtonText: string;
  onSecondaryButtonClick: () => void;
  isCloseIconPresent: boolean;
  isOpen: boolean;
  onClose: (reload?: boolean) => void;
  subtitle?: CreateRequestSubtitle;
  children: React.ReactNode;
  warning?: string;
}

const createRequestDialogStyle = (
  border: BorderStyle,
  typography: TypographyProps,
  spacing: Spacing,
): {
  [key: string]: React.CSSProperties;
} => {
  return {
    rootContainer: {
      width: "100%",
      gap: spacing.spaceLG,
    },
    subtitleContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: border.default,
      paddingBottom: spacing.spaceXS,
      gap: spacing.spaceXS,
    },
    subtitle: {
      ...typography.s1,
    },
  };
};

export const CreateRequestDialog = ({
  title,
  primaryButtonText,
  onPrimaryButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  isCloseIconPresent,
  isOpen,
  onClose,
  subtitle,
  children,
  warning,
}: CreateRequestDialogProps): React.ReactElement => {
  const cornerRadius = useCornerRadius();
  const border = useBorder();
  const spacing = useSpacing();
  const typography = useTypography();

  const style = useMemo(
    () => createRequestDialogStyle(border, typography, spacing),
    [],
  );

  const getDialogChildren = (): React.ReactElement => {
    return (
      <Stack sx={style.rootContainer}>
        {warning && <WarningAlertComponent text={warning} />}
        {subtitle && (
          <>
            <Stack sx={style.subtitleContainer}>
              <Typography sx={style.subtitle}>{subtitle.title}</Typography>
              <Typography sx={style.subtitle}>{subtitle.value}</Typography>
            </Stack>
            {children}
          </>
        )}
        {!subtitle && <>{children}</>}
      </Stack>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title={title}
      primaryButtonText={primaryButtonText}
      onPrimaryButtonClick={onPrimaryButtonClick}
      secondaryButtonText={secondaryButtonText}
      onSecondaryButtonClick={onSecondaryButtonClick}
      isCloseIconPresent={isCloseIconPresent}
      onClose={onClose}
      disableBackdropClick={true}
      style={{
        borderRadius: `${cornerRadius.radiusXXS}px`,
      }}
    >
      {getDialogChildren()}
    </Dialog>
  );
};
