import { Instance, types } from "mobx-state-tree";
import {
  FixedIncomeInvestmentRegisterResponse,
  FixedIncomeSecurityType,
} from "@khazana/khazana-rpcs";
import { getAmountOrUndefined } from "../../../../../utils";
import { getStatusString } from "../utils/ReportUtils";

export const FiInvestmentRegisterSummaryModel = types.model(
  "FiInvestmentRegisterSummaryModel",
  {
    symbol: types.string,
    instrumentType: types.maybe(
      types.enumeration<FixedIncomeSecurityType.FixedIncomeSecurityType>(
        "FixedIncomeSecurityType",
        Object.values(FixedIncomeSecurityType.FixedIncomeSecurityType),
      ),
    ),
    openingAmount: types.maybe(types.number),
    purchaseAmount: types.maybe(types.number),
    maturedAmount: types.maybe(types.number),
    closingAmount: types.maybe(types.number),
    daysInHolding: types.maybe(types.number),
    couponRate: types.maybe(types.number),
    startDate: types.maybe(types.string),
    maturityDate: types.maybe(types.string),
    nextCallDate: types.maybe(types.string),
    status: types.maybe(types.string),
    openingInterest: types.maybe(types.number),
    openingTds: types.maybe(types.number),
    openingOtherCharges: types.maybe(types.number),
    interestReceived: types.maybe(types.number),
    taxPaid: types.maybe(types.number),
    otherChargesPaid: types.maybe(types.number),
    netInterestReceived: types.maybe(types.number),
    interestAccrued: types.maybe(types.number),
    taxOnInterestAccrued: types.maybe(types.number),
    otherTaxesOnInterestAccrued: types.maybe(types.number),
    netInterestReceivable: types.maybe(types.number),
  },
);

export const createFiInvestmentRegisterSummaryModel = (
  response: FixedIncomeInvestmentRegisterResponse,
): Instance<typeof FiInvestmentRegisterSummaryModel> => {
  return FiInvestmentRegisterSummaryModel.create({
    symbol: response.symbol,
    instrumentType: response.instrumentType ?? undefined,
    openingAmount: getAmountOrUndefined(response.openingAmount),
    purchaseAmount: getAmountOrUndefined(response.purchaseAmount),
    maturedAmount: getAmountOrUndefined(response.maturedAmount),
    closingAmount: getAmountOrUndefined(response.closingAmount),
    daysInHolding: response.daysInHolding ?? undefined,
    couponRate: getAmountOrUndefined(response.couponRate),
    startDate: response.startDate?.date,
    maturityDate: response.maturityDate?.date,
    nextCallDate: response.nextCallDate?.date,
    openingInterest: getAmountOrUndefined(response.openingInterest),
    openingTds: getAmountOrUndefined(response.openingTds),
    openingOtherCharges: getAmountOrUndefined(response.openingOtherCharges),
    interestReceived: getAmountOrUndefined(response.interestReceived),
    taxPaid: getAmountOrUndefined(response.taxPaid),
    otherChargesPaid: getAmountOrUndefined(response.otherChargesPaid),
    netInterestReceived: getAmountOrUndefined(response.netInterestReceived),
    interestAccrued: getAmountOrUndefined(response.interestAccrued),
    taxOnInterestAccrued: getAmountOrUndefined(response.taxOnInterestAccrued),
    otherTaxesOnInterestAccrued: getAmountOrUndefined(
      response.otherTaxesOnInterestAccrued,
    ),
    netInterestReceivable: getAmountOrUndefined(response.netInterestReceivable),
    status: getStatusString(response.status),
  });
};
