import {
  applySnapshot,
  cast,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { DiffCellType } from "../../utils/DiffDetailCellUtils";

export const DropdownFieldOptionModel = types.model(
  "DropdownFieldOptionModel",
  {
    value: types.string,
    name: types.string,
  },
);

export const DropdownFieldStore = types
  .model("DropdownFieldStore", {
    value: types.maybe(types.string),
    options: types.array(DropdownFieldOptionModel),
    isDisabled: types.optional(types.boolean, false),
    errorMessage: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setValue: (value: string | undefined): void => {
        store.errorMessage = undefined;
        store.value = value;
      },
      getName: (): string | undefined => {
        const selectedOption = store.options.find(
          (option) => option.value === store.value,
        );
        return selectedOption?.name;
      },
      setIsDisabled: (isDisabled: boolean): void => {
        store.isDisabled = isDisabled;
      },
      setErrorMessage: (errorMessage: string | undefined): void => {
        store.errorMessage = errorMessage;
      },
      setOptions: (options: { name: string; value: string }[]): void => {
        store.options = cast(
          options.map((option) =>
            DropdownFieldOptionModel.create({
              name: option.name,
              value: option.value,
            }),
          ),
        );
      },
      setIsLoading: (isLoading: boolean): void => {
        store.isLoading = isLoading;
      },
    };
  });

export const createDropdownField = (): Instance<typeof DropdownFieldStore> => {
  return DropdownFieldStore.create({});
};

export const createDropdownStoreFromDiff = (
  diff: DiffCellType | undefined,
): Instance<typeof DropdownFieldStore> => {
  if (!diff) {
    return createDropdownField();
  }
  return DropdownFieldStore.create({
    value: (diff.diffValue as string | undefined) ?? (diff.value as string),
  });
};
