"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFDealRequestSummaryRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const bigAmount_1 = require("../types/bigAmount");
const bigDecimal_1 = require("../types/bigDecimal");
const mFTransactionDetails_1 = require("./mFTransactionDetails");
const mFTransactionType_1 = require("./mFTransactionType");
/* eslint-disable import/export */
class GetMFDealRequestSummaryRPC {
}
exports.GetMFDealRequestSummaryRPC = GetMFDealRequestSummaryRPC;
(function (GetMFDealRequestSummaryRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFDealRequestSummaryRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(isin, transactionType, nav = null, grossAmount = null, units = null) {
            this.isGetMFDealRequestSummaryRPCRequest = true;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateTransactionType(transactionType);
            this.transactionType = transactionType;
            if (nav !== undefined && nav !== null) {
                Request.validateNav(nav);
            }
            this.nav = nav;
            if (grossAmount !== undefined && grossAmount !== null) {
                Request.validateGrossAmount(grossAmount);
            }
            this.grossAmount = grossAmount;
            if (units !== undefined && units !== null) {
                Request.validateUnits(units);
            }
            this.units = units;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidRequestError));
            let nav = (0, leo_ts_runtime_1.getOptionalObject)(dto, "nav");
            if (nav !== undefined && nav !== null) {
                nav = bigAmount_1.BigAmount.fromDTO(nav);
            }
            let grossAmount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "grossAmount");
            if (grossAmount !== undefined && grossAmount !== null) {
                grossAmount = bigAmount_1.BigAmount.fromDTO(grossAmount);
            }
            let units = (0, leo_ts_runtime_1.getOptionalObject)(dto, "units");
            if (units !== undefined && units !== null) {
                units = bigDecimal_1.BigDecimal.fromDTO(units);
            }
            return new Request(isin, transactionType, nav, grossAmount, units);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
                transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
            };
            if (this.nav) {
                ret.nav = this.nav.toDTO();
            }
            if (this.grossAmount) {
                ret.grossAmount = this.grossAmount.toDTO();
            }
            if (this.units) {
                ret.units = this.units.toDTO();
            }
            return ret;
        }
        copy(isin = this.isin, transactionType = this.transactionType, nav = this.nav, grossAmount = this.grossAmount, units = this.units) {
            return new Request(isin, transactionType, nav, grossAmount, units);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFDealRequestSummaryRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.nav) {
                if (!this.nav.equals(other.nav)) {
                    return false;
                }
            }
            if (this.grossAmount) {
                if (!this.grossAmount.equals(other.grossAmount)) {
                    return false;
                }
            }
            if (this.units) {
                if (!this.units.equals(other.units)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
        }
    };
    Request.validateNav = (nav) => {
        const isBigAmountValue = nav.isBigAmount;
        if (!(nav instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidRequestError(`Attribute nav is not a BigAmount`);
        }
    };
    Request.validateGrossAmount = (grossAmount) => {
        const isBigAmountValue = grossAmount.isBigAmount;
        if (!(grossAmount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidRequestError(`Attribute grossAmount is not a BigAmount`);
        }
    };
    Request.validateUnits = (units) => {
        const isBigDecimalValue = units.isBigDecimal;
        if (!(units instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidRequestError(`Attribute units is not a BigDecimal`);
        }
    };
    GetMFDealRequestSummaryRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFDealRequestSummaryRPC.Request(isin=${this.isin},transactionType=${this.transactionType},nav=${this.nav},grossAmount=${this.grossAmount},units=${this.units})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFDealRequestSummaryRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(transactionDetails) {
            this.isGetMFDealRequestSummaryRPCResponse = true;
            Response.validateTransactionDetails(transactionDetails);
            this.transactionDetails = transactionDetails;
        }
        static fromDTO(dto) {
            const transactionDetails = mFTransactionDetails_1.MFTransactionDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionDetails", InvalidResponseError));
            return new Response(transactionDetails);
        }
        toDTO() {
            const ret = {
                transactionDetails: this.transactionDetails.toDTO(),
            };
            return ret;
        }
        copy(transactionDetails = this.transactionDetails) {
            return new Response(transactionDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFDealRequestSummaryRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.transactionDetails.equals(other.transactionDetails)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTransactionDetails = (transactionDetails) => {
        const isMFTransactionDetailsValue = transactionDetails.isMFTransactionDetails;
        if (!(transactionDetails instanceof mFTransactionDetails_1.MFTransactionDetails || Boolean(isMFTransactionDetailsValue))) {
            throw new InvalidResponseError(`Attribute transactionDetails is not a MFTransactionDetails`);
        }
    };
    GetMFDealRequestSummaryRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFDealRequestSummaryRPC.Response(transactionDetails=${this.transactionDetails})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetMFDealRequestSummaryRPC.INVALID_ISIN()`;
        };
        class InvalidDealValue extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_VALUE") {
                super(code);
                this.isInvalidDealValue = true;
                InvalidDealValue.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealValue(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealValue(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealValueValue = other.isInvalidDealValue;
                if (!(other instanceof InvalidDealValue || Boolean(isInvalidDealValueValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealValue.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_VALUE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealValue = InvalidDealValue;
        InvalidDealValue.prototype.toString = function toString() {
            return `GetMFDealRequestSummaryRPC.INVALID_DEAL_VALUE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_DEAL_VALUE":
                    return InvalidDealValue.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidDealValueValue = other.isInvalidDealValue;
            if (other instanceof InvalidDealValue || Boolean(isInvalidDealValueValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFDealRequestSummaryRPC.Errors || (GetMFDealRequestSummaryRPC.Errors = {}));
})(GetMFDealRequestSummaryRPC = exports.GetMFDealRequestSummaryRPC || (exports.GetMFDealRequestSummaryRPC = {}));
