"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiMaturityDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getFiMaturityDealRequestsSortOrder_1 = require("./getFiMaturityDealRequestsSortOrder");
const fixedIncomeItemsPerPage_1 = require("./fixedIncomeItemsPerPage");
const fixedIncomePageIndex_1 = require("./fixedIncomePageIndex");
const getFiMaturityDealRequestsPaginationResponse_1 = require("./getFiMaturityDealRequestsPaginationResponse");
const fiMaturityDealRequestStatus_1 = require("./fiMaturityDealRequestStatus");
/* eslint-disable import/export */
class GetFiMaturityDealRequestsRPC {
}
exports.GetFiMaturityDealRequestsRPC = GetFiMaturityDealRequestsRPC;
(function (GetFiMaturityDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiMaturityDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, requestId = null, dealRequestStatus = null, isin = null, entity = null, portfolio = null, itemsPerPage, pageIndex) {
            this.isGetFiMaturityDealRequestsRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (requestId !== undefined && requestId !== null) {
                Request.validateRequestId(requestId);
            }
            this.requestId = requestId;
            if (dealRequestStatus !== undefined && dealRequestStatus !== null) {
                Request.validateDealRequestStatus(dealRequestStatus);
            }
            this.dealRequestStatus = dealRequestStatus;
            if (isin !== undefined && isin !== null) {
                Request.validateIsin(isin);
            }
            this.isin = isin;
            if (entity !== undefined && entity !== null) {
                Request.validateEntity(entity);
            }
            this.entity = entity;
            if (portfolio !== undefined && portfolio !== null) {
                Request.validatePortfolio(portfolio);
            }
            this.portfolio = portfolio;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getFiMaturityDealRequestsSortOrder_1.GetFiMaturityDealRequestsSortOrder.fromDTO, InvalidRequestError);
            const requestId = (0, leo_ts_runtime_1.getOptionalString)(dto, "requestId", InvalidRequestError);
            let dealRequestStatus = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestStatus");
            if (dealRequestStatus !== undefined && dealRequestStatus !== null) {
                dealRequestStatus = fiMaturityDealRequestStatus_1.FiMaturityDealRequestStatus.fromDTO(dealRequestStatus);
            }
            const isin = (0, leo_ts_runtime_1.getOptionalString)(dto, "isin", InvalidRequestError);
            const entity = (0, leo_ts_runtime_1.getOptionalString)(dto, "entity", InvalidRequestError);
            const portfolio = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolio", InvalidRequestError);
            const itemsPerPage = fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = fixedIncomePageIndex_1.FixedIncomePageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, requestId, dealRequestStatus, isin, entity, portfolio, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.requestId !== undefined && this.requestId !== null) {
                ret.requestId = this.requestId;
            }
            if (this.dealRequestStatus !== undefined && this.dealRequestStatus !== null) {
                ret.dealRequestStatus = fiMaturityDealRequestStatus_1.FiMaturityDealRequestStatus.toDTO(this.dealRequestStatus);
            }
            if (this.isin !== undefined && this.isin !== null) {
                ret.isin = this.isin;
            }
            if (this.entity !== undefined && this.entity !== null) {
                ret.entity = this.entity;
            }
            if (this.portfolio !== undefined && this.portfolio !== null) {
                ret.portfolio = this.portfolio;
            }
            return ret;
        }
        copy(sortList = this.sortList, requestId = this.requestId, dealRequestStatus = this.dealRequestStatus, isin = this.isin, entity = this.entity, portfolio = this.portfolio, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, requestId, dealRequestStatus, isin, entity, portfolio, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiMaturityDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.dealRequestStatus !== other.dealRequestStatus) {
                return false;
            }
            if (this.isin !== other.isin) {
                return false;
            }
            if (this.entity !== other.entity) {
                return false;
            }
            if (this.portfolio !== other.portfolio) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetFiMaturityDealRequestsSortOrderValue = sortList[i].isGetFiMaturityDealRequestsSortOrder;
            if (!(sortList[i] instanceof getFiMaturityDealRequestsSortOrder_1.GetFiMaturityDealRequestsSortOrder || Boolean(isGetFiMaturityDealRequestsSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetFiMaturityDealRequestsSortOrder.`);
            }
        }
    };
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isString)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not a String`);
        }
        if (requestId.length < 3) {
            throw new InvalidRequestError(`Attribute requestId is too short. Size is ${requestId.length}. Min size is 3.`);
        }
        if (requestId.length > 200) {
            throw new InvalidRequestError(`Attribute requestId is too long. Size is ${requestId.length}. Max size is 200.`);
        }
        if (requestId.trim().length === 0) {
            throw new InvalidRequestError(`Attribute requestId cannot be an empty string.`);
        }
    };
    Request.validateDealRequestStatus = (dealRequestStatus) => {
        if (!fiMaturityDealRequestStatus_1.FiMaturityDealRequestStatus.isInstanceOf(dealRequestStatus)) {
            throw new InvalidRequestError(`Attribute dealRequestStatus is not a FiMaturityDealRequestStatus.FiMaturityDealRequestStatus`);
        }
    };
    Request.validateIsin = (isin) => {
        if (!(0, leo_ts_runtime_1.isString)(isin)) {
            throw new InvalidRequestError(`Attribute isin is not a String`);
        }
        if (isin.length < 3) {
            throw new InvalidRequestError(`Attribute isin is too short. Size is ${isin.length}. Min size is 3.`);
        }
        if (isin.length > 200) {
            throw new InvalidRequestError(`Attribute isin is too long. Size is ${isin.length}. Max size is 200.`);
        }
        if (isin.trim().length === 0) {
            throw new InvalidRequestError(`Attribute isin cannot be an empty string.`);
        }
    };
    Request.validateEntity = (entity) => {
        if (!(0, leo_ts_runtime_1.isString)(entity)) {
            throw new InvalidRequestError(`Attribute entity is not a String`);
        }
        if (entity.length < 3) {
            throw new InvalidRequestError(`Attribute entity is too short. Size is ${entity.length}. Min size is 3.`);
        }
        if (entity.length > 200) {
            throw new InvalidRequestError(`Attribute entity is too long. Size is ${entity.length}. Max size is 200.`);
        }
        if (entity.trim().length === 0) {
            throw new InvalidRequestError(`Attribute entity cannot be an empty string.`);
        }
    };
    Request.validatePortfolio = (portfolio) => {
        if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
            throw new InvalidRequestError(`Attribute portfolio is not a String`);
        }
        if (portfolio.length < 3) {
            throw new InvalidRequestError(`Attribute portfolio is too short. Size is ${portfolio.length}. Min size is 3.`);
        }
        if (portfolio.length > 200) {
            throw new InvalidRequestError(`Attribute portfolio is too long. Size is ${portfolio.length}. Max size is 200.`);
        }
        if (portfolio.trim().length === 0) {
            throw new InvalidRequestError(`Attribute portfolio cannot be an empty string.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isFixedIncomeItemsPerPageValue = itemsPerPage.isFixedIncomeItemsPerPage;
        if (!(itemsPerPage instanceof fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage || Boolean(isFixedIncomeItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a FixedIncomeItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isFixedIncomePageIndexValue = pageIndex.isFixedIncomePageIndex;
        if (!(pageIndex instanceof fixedIncomePageIndex_1.FixedIncomePageIndex || Boolean(isFixedIncomePageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a FixedIncomePageIndex`);
        }
    };
    GetFiMaturityDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiMaturityDealRequestsRPC.Request(sortList=${this.sortList},requestId='${this.requestId}',dealRequestStatus=${this.dealRequestStatus},isin='${this.isin}',entity='${this.entity}',portfolio='${this.portfolio}',itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiMaturityDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getFiMaturityDealRequestsPaginationResponse, totalItems) {
            this.isGetFiMaturityDealRequestsRPCResponse = true;
            Response.validateGetFiMaturityDealRequestsPaginationResponse(getFiMaturityDealRequestsPaginationResponse);
            this.getFiMaturityDealRequestsPaginationResponse = getFiMaturityDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getFiMaturityDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getFiMaturityDealRequestsPaginationResponse", getFiMaturityDealRequestsPaginationResponse_1.GetFiMaturityDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getFiMaturityDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getFiMaturityDealRequestsPaginationResponse: this.getFiMaturityDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getFiMaturityDealRequestsPaginationResponse = this.getFiMaturityDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getFiMaturityDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiMaturityDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getFiMaturityDealRequestsPaginationResponse, other.getFiMaturityDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetFiMaturityDealRequestsPaginationResponse = (getFiMaturityDealRequestsPaginationResponse) => {
        if (!Array.isArray(getFiMaturityDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getFiMaturityDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getFiMaturityDealRequestsPaginationResponse.length; i += 1) {
            const isGetFiMaturityDealRequestsPaginationResponseValue = getFiMaturityDealRequestsPaginationResponse[i].isGetFiMaturityDealRequestsPaginationResponse;
            if (!(getFiMaturityDealRequestsPaginationResponse[i] instanceof getFiMaturityDealRequestsPaginationResponse_1.GetFiMaturityDealRequestsPaginationResponse || Boolean(isGetFiMaturityDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getFiMaturityDealRequestsPaginationResponse at index ${i} is not a GetFiMaturityDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetFiMaturityDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiMaturityDealRequestsRPC.Response(getFiMaturityDealRequestsPaginationResponse=${this.getFiMaturityDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetFiMaturityDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiMaturityDealRequestsRPC.Errors || (GetFiMaturityDealRequestsRPC.Errors = {}));
})(GetFiMaturityDealRequestsRPC = exports.GetFiMaturityDealRequestsRPC || (exports.GetFiMaturityDealRequestsRPC = {}));
