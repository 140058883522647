"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdCashflowDetailRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigAmount_1 = require("../types/bigAmount");
const accrualItem_1 = require("../types/accrualItem");
/* eslint-disable import/export */
class GetFdCashflowDetailRPC {
}
exports.GetFdCashflowDetailRPC = GetFdCashflowDetailRPC;
(function (GetFdCashflowDetailRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFdCashflowDetailRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fdCashflowId) {
            this.isGetFdCashflowDetailRPCRequest = true;
            Request.validateFdCashflowId(fdCashflowId);
            this.fdCashflowId = fdCashflowId;
        }
        static fromDTO(dto) {
            const fdCashflowId = (0, leo_ts_runtime_1.getInt64)(dto, "fdCashflowId", InvalidRequestError);
            return new Request(fdCashflowId);
        }
        toDTO() {
            const ret = {
                fdCashflowId: this.fdCashflowId,
            };
            return ret;
        }
        copy(fdCashflowId = this.fdCashflowId) {
            return new Request(fdCashflowId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFdCashflowDetailRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.fdCashflowId !== other.fdCashflowId) {
                return false;
            }
            return true;
        }
    }
    Request.validateFdCashflowId = (fdCashflowId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(fdCashflowId)) {
            throw new InvalidRequestError(`Attribute fdCashflowId is not an Int64`);
        }
    };
    GetFdCashflowDetailRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFdCashflowDetailRPC.Request(fdCashflowId=${this.fdCashflowId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFdCashflowDetailRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(bankId, bankName, bankAccountNumber, amount, accruals) {
            this.isGetFdCashflowDetailRPCResponse = true;
            Response.validateBankId(bankId);
            this.bankId = bankId;
            Response.validateBankName(bankName);
            this.bankName = bankName;
            Response.validateBankAccountNumber(bankAccountNumber);
            this.bankAccountNumber = bankAccountNumber;
            Response.validateAmount(amount);
            this.amount = amount;
            Response.validateAccruals(accruals);
            this.accruals = accruals;
        }
        static fromDTO(dto) {
            const bankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "bankId", InvalidResponseError);
            const bankName = (0, leo_ts_runtime_1.getString)(dto, "bankName", InvalidResponseError);
            const bankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "bankAccountNumber", InvalidResponseError);
            const amount = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidResponseError));
            const accruals = (0, leo_ts_runtime_1.getList)(dto, "accruals", accrualItem_1.AccrualItem.fromDTO, InvalidResponseError);
            return new Response(bankId, bankName, bankAccountNumber, amount, accruals);
        }
        toDTO() {
            const ret = {
                bankId: this.bankId.toDTO(),
                bankName: this.bankName,
                bankAccountNumber: this.bankAccountNumber,
                amount: this.amount.toDTO(),
                accruals: this.accruals.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(bankId = this.bankId, bankName = this.bankName, bankAccountNumber = this.bankAccountNumber, amount = this.amount, accruals = this.accruals) {
            return new Response(bankId, bankName, bankAccountNumber, amount, accruals);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFdCashflowDetailRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.bankId.equals(other.bankId)) {
                return false;
            }
            if (this.bankName !== other.bankName) {
                return false;
            }
            if (this.bankAccountNumber !== other.bankAccountNumber) {
                return false;
            }
            if (!this.amount.equals(other.amount)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.accruals, other.accruals)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBankId = (bankId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(bankId)) {
            throw new InvalidResponseError(`Attribute bankId is not a UUID`);
        }
    };
    Response.validateBankName = (bankName) => {
        if (!(0, leo_ts_runtime_1.isString)(bankName)) {
            throw new InvalidResponseError(`Attribute bankName is not a String`);
        }
        if (bankName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute bankName cannot be an empty string.`);
        }
    };
    Response.validateBankAccountNumber = (bankAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(bankAccountNumber)) {
            throw new InvalidResponseError(`Attribute bankAccountNumber is not a String`);
        }
        if (bankAccountNumber.trim().length === 0) {
            throw new InvalidResponseError(`Attribute bankAccountNumber cannot be an empty string.`);
        }
    };
    Response.validateAmount = (amount) => {
        const isBigAmountValue = amount.isBigAmount;
        if (!(amount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidResponseError(`Attribute amount is not a BigAmount`);
        }
    };
    Response.validateAccruals = (accruals) => {
        if (!Array.isArray(accruals)) {
            throw new InvalidResponseError(`Attribute accruals is not a List`);
        }
        for (let i = 0; i < accruals.length; i += 1) {
            const isAccrualItemValue = accruals[i].isAccrualItem;
            if (!(accruals[i] instanceof accrualItem_1.AccrualItem || Boolean(isAccrualItemValue))) {
                throw new InvalidResponseError(`Attribute accruals at index ${i} is not a AccrualItem.`);
            }
        }
    };
    GetFdCashflowDetailRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFdCashflowDetailRPC.Response(bankId=${this.bankId},bankName='${this.bankName}',bankAccountNumber='${this.bankAccountNumber}',amount=${this.amount},accruals=${this.accruals})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidCashflowId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CASHFLOW_ID") {
                super(code);
                this.isInvalidCashflowId = true;
                InvalidCashflowId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidCashflowId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidCashflowId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidCashflowIdValue = other.isInvalidCashflowId;
                if (!(other instanceof InvalidCashflowId || Boolean(isInvalidCashflowIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidCashflowId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CASHFLOW_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidCashflowId = InvalidCashflowId;
        InvalidCashflowId.prototype.toString = function toString() {
            return `GetFdCashflowDetailRPC.INVALID_CASHFLOW_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CASHFLOW_ID":
                    return InvalidCashflowId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidCashflowIdValue = other.isInvalidCashflowId;
            if (other instanceof InvalidCashflowId || Boolean(isInvalidCashflowIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFdCashflowDetailRPC.Errors || (GetFdCashflowDetailRPC.Errors = {}));
})(GetFdCashflowDetailRPC = exports.GetFdCashflowDetailRPC || (exports.GetFdCashflowDetailRPC = {}));
