import {
  Currency,
  GetEquityHoldingDistributionByIsinRPC,
  LabelAmount,
  TransactionAmount,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityHoldingDistributionByIsinRPCImpl extends GetEquityHoldingDistributionByIsinRPC {
  execute(
    _request: GetEquityHoldingDistributionByIsinRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityHoldingDistributionByIsinRPC.Response,
      GetEquityHoldingDistributionByIsinRPC.Errors.Errors
    >
  > {
    const response = new GetEquityHoldingDistributionByIsinRPC.Response([
      new LabelAmount(
        "HDFC",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "ICICI",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "Axis",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "SBI",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "Kotak",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "BOSCHLTD",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "FOCUS",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "PIXTRANS",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "MAGADSUGAR",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
      new LabelAmount(
        "HCG",
        new TransactionAmount(100000, new Currency("INR", "₹")),
      ),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityHoldingDistributionByIsinRPC.Response,
        GetEquityHoldingDistributionByIsinRPC.Errors.Errors
      >
    >;
  }
}
