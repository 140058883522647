"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFConfirmationActions = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidMFConfirmationActionsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFConfirmationActionsError";
    }
}
class MFConfirmationActions {
    constructor(allowEdit, allowUnknownRequest, allowLinkRequest, allowContractCheck, allowFolioCreationCheck, allowLinkCheck, allowUnknownCheck) {
        this.isMFConfirmationActions = true;
        MFConfirmationActions.validateAllowEdit(allowEdit);
        this.allowEdit = allowEdit;
        MFConfirmationActions.validateAllowUnknownRequest(allowUnknownRequest);
        this.allowUnknownRequest = allowUnknownRequest;
        MFConfirmationActions.validateAllowLinkRequest(allowLinkRequest);
        this.allowLinkRequest = allowLinkRequest;
        MFConfirmationActions.validateAllowContractCheck(allowContractCheck);
        this.allowContractCheck = allowContractCheck;
        MFConfirmationActions.validateAllowFolioCreationCheck(allowFolioCreationCheck);
        this.allowFolioCreationCheck = allowFolioCreationCheck;
        MFConfirmationActions.validateAllowLinkCheck(allowLinkCheck);
        this.allowLinkCheck = allowLinkCheck;
        MFConfirmationActions.validateAllowUnknownCheck(allowUnknownCheck);
        this.allowUnknownCheck = allowUnknownCheck;
    }
    static fromDTO(dto) {
        const allowEdit = (0, leo_ts_runtime_1.getBoolean)(dto, "allowEdit", InvalidMFConfirmationActionsError);
        const allowUnknownRequest = (0, leo_ts_runtime_1.getBoolean)(dto, "allowUnknownRequest", InvalidMFConfirmationActionsError);
        const allowLinkRequest = (0, leo_ts_runtime_1.getBoolean)(dto, "allowLinkRequest", InvalidMFConfirmationActionsError);
        const allowContractCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowContractCheck", InvalidMFConfirmationActionsError);
        const allowFolioCreationCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowFolioCreationCheck", InvalidMFConfirmationActionsError);
        const allowLinkCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowLinkCheck", InvalidMFConfirmationActionsError);
        const allowUnknownCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowUnknownCheck", InvalidMFConfirmationActionsError);
        return new MFConfirmationActions(allowEdit, allowUnknownRequest, allowLinkRequest, allowContractCheck, allowFolioCreationCheck, allowLinkCheck, allowUnknownCheck);
    }
    toDTO() {
        const ret = {
            allowEdit: this.allowEdit,
            allowUnknownRequest: this.allowUnknownRequest,
            allowLinkRequest: this.allowLinkRequest,
            allowContractCheck: this.allowContractCheck,
            allowFolioCreationCheck: this.allowFolioCreationCheck,
            allowLinkCheck: this.allowLinkCheck,
            allowUnknownCheck: this.allowUnknownCheck,
        };
        return ret;
    }
    copy(allowEdit = this.allowEdit, allowUnknownRequest = this.allowUnknownRequest, allowLinkRequest = this.allowLinkRequest, allowContractCheck = this.allowContractCheck, allowFolioCreationCheck = this.allowFolioCreationCheck, allowLinkCheck = this.allowLinkCheck, allowUnknownCheck = this.allowUnknownCheck) {
        return new MFConfirmationActions(allowEdit, allowUnknownRequest, allowLinkRequest, allowContractCheck, allowFolioCreationCheck, allowLinkCheck, allowUnknownCheck);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFConfirmationActionsValue = other.isMFConfirmationActions;
        if (!(other instanceof MFConfirmationActions) || Boolean(isMFConfirmationActionsValue)) {
            return false;
        }
        if (this.allowEdit !== other.allowEdit) {
            return false;
        }
        if (this.allowUnknownRequest !== other.allowUnknownRequest) {
            return false;
        }
        if (this.allowLinkRequest !== other.allowLinkRequest) {
            return false;
        }
        if (this.allowContractCheck !== other.allowContractCheck) {
            return false;
        }
        if (this.allowFolioCreationCheck !== other.allowFolioCreationCheck) {
            return false;
        }
        if (this.allowLinkCheck !== other.allowLinkCheck) {
            return false;
        }
        if (this.allowUnknownCheck !== other.allowUnknownCheck) {
            return false;
        }
        return true;
    }
}
exports.MFConfirmationActions = MFConfirmationActions;
MFConfirmationActions.validateAllowEdit = (allowEdit) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowEdit)) {
        throw new InvalidMFConfirmationActionsError(`Attribute allowEdit is not a Boolean`);
    }
};
MFConfirmationActions.validateAllowUnknownRequest = (allowUnknownRequest) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowUnknownRequest)) {
        throw new InvalidMFConfirmationActionsError(`Attribute allowUnknownRequest is not a Boolean`);
    }
};
MFConfirmationActions.validateAllowLinkRequest = (allowLinkRequest) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowLinkRequest)) {
        throw new InvalidMFConfirmationActionsError(`Attribute allowLinkRequest is not a Boolean`);
    }
};
MFConfirmationActions.validateAllowContractCheck = (allowContractCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowContractCheck)) {
        throw new InvalidMFConfirmationActionsError(`Attribute allowContractCheck is not a Boolean`);
    }
};
MFConfirmationActions.validateAllowFolioCreationCheck = (allowFolioCreationCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowFolioCreationCheck)) {
        throw new InvalidMFConfirmationActionsError(`Attribute allowFolioCreationCheck is not a Boolean`);
    }
};
MFConfirmationActions.validateAllowLinkCheck = (allowLinkCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowLinkCheck)) {
        throw new InvalidMFConfirmationActionsError(`Attribute allowLinkCheck is not a Boolean`);
    }
};
MFConfirmationActions.validateAllowUnknownCheck = (allowUnknownCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowUnknownCheck)) {
        throw new InvalidMFConfirmationActionsError(`Attribute allowUnknownCheck is not a Boolean`);
    }
};
MFConfirmationActions.prototype.toString = function toString() {
    return `MFConfirmationActions(allowEdit=${this.allowEdit},allowUnknownRequest=${this.allowUnknownRequest},allowLinkRequest=${this.allowLinkRequest},allowContractCheck=${this.allowContractCheck},allowFolioCreationCheck=${this.allowFolioCreationCheck},allowLinkCheck=${this.allowLinkCheck},allowUnknownCheck=${this.allowUnknownCheck})`;
};
