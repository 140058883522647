import { Instance, types } from "mobx-state-tree";
import { CashflowTimelineAmount } from "@khazana/khazana-rpcs";
import { createCurrencyModel, CurrencyModel } from "./CurrencyModel";

export const CashflowTimelineAmountModel = types.model(
  "CashflowTimelineAmountModel",
  {
    localizedTextId: types.string,
    amount: types.string,
    currency: CurrencyModel,
    isPositive: types.maybeNull(types.boolean),
    faceValue: types.maybeNull(types.string),
  },
);

export const createCashflowTimelineAmountModel = ({
  localizedTextId,
  amount,
  faceValue,
}: CashflowTimelineAmount): Instance<typeof CashflowTimelineAmountModel> => {
  return CashflowTimelineAmountModel.create({
    localizedTextId,
    amount: amount.amount.decimalValue,
    currency: createCurrencyModel(amount.currency),
    isPositive: amount.amount.isPositive,
    faceValue: faceValue?.amount.decimalValue,
  });
};
