import { FiYieldType, GetFiSellShowYtmRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiSellShowYtmRPCImpl extends GetFiSellShowYtmRPC {
  execute(
    _request: GetFiSellShowYtmRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiSellShowYtmRPC.Response,
      GetFiSellShowYtmRPC.Errors.Errors
    >
  > {
    const response = new GetFiSellShowYtmRPC.Response(
      FiYieldType.FiYieldType.C,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiSellShowYtmRPC.Response,
        GetFiSellShowYtmRPC.Errors.Errors
      >
    >;
  }
}
