"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityChangeInGainSortOrder = exports.GetEquityChangeInGainSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const reportsSortOrder_1 = require("./reportsSortOrder");
class InvalidGetEquityChangeInGainSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetEquityChangeInGainSortOrderError";
    }
}
var GetEquityChangeInGainSortOrderEnums;
(function (GetEquityChangeInGainSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["SYMBOL"] = "SYMBOL";
            ColumnName["SOLD_VALUE"] = "SOLD_VALUE";
            ColumnName["REALISED_GAIN"] = "REALISED_GAIN";
            ColumnName["CHANGE"] = "CHANGE";
            ColumnName["NET_GAIN"] = "NET_GAIN";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "SYMBOL":
                    return ColumnName.SYMBOL;
                case "SOLD_VALUE":
                    return ColumnName.SOLD_VALUE;
                case "REALISED_GAIN":
                    return ColumnName.REALISED_GAIN;
                case "CHANGE":
                    return ColumnName.CHANGE;
                case "NET_GAIN":
                    return ColumnName.NET_GAIN;
                default:
                    throw new InvalidGetEquityChangeInGainSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetEquityChangeInGainSortOrderEnums.ColumnName || (GetEquityChangeInGainSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetEquityChangeInGainSortOrderEnums = exports.GetEquityChangeInGainSortOrderEnums || (exports.GetEquityChangeInGainSortOrderEnums = {}));
class GetEquityChangeInGainSortOrder {
    constructor(columnName, order) {
        this.isGetEquityChangeInGainSortOrder = true;
        GetEquityChangeInGainSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetEquityChangeInGainSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const columnName = GetEquityChangeInGainSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetEquityChangeInGainSortOrderError));
        const order = reportsSortOrder_1.ReportsSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetEquityChangeInGainSortOrderError));
        return new GetEquityChangeInGainSortOrder(columnName, order);
    }
    toDTO() {
        const ret = {
            columnName: GetEquityChangeInGainSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: reportsSortOrder_1.ReportsSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(columnName = this.columnName, order = this.order) {
        return new GetEquityChangeInGainSortOrder(columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetEquityChangeInGainSortOrderValue = other.isGetEquityChangeInGainSortOrder;
        if (!(other instanceof GetEquityChangeInGainSortOrder) || Boolean(isGetEquityChangeInGainSortOrderValue)) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetEquityChangeInGainSortOrder = GetEquityChangeInGainSortOrder;
GetEquityChangeInGainSortOrder.validateColumnName = (columnName) => {
    if (!GetEquityChangeInGainSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetEquityChangeInGainSortOrderError(`Attribute columnName is not a GetEquityChangeInGainSortOrderEnums.ColumnName`);
    }
};
GetEquityChangeInGainSortOrder.validateOrder = (order) => {
    if (!reportsSortOrder_1.ReportsSortOrder.isInstanceOf(order)) {
        throw new InvalidGetEquityChangeInGainSortOrderError(`Attribute order is not a ReportsSortOrder.ReportsSortOrder`);
    }
};
GetEquityChangeInGainSortOrder.prototype.toString = function toString() {
    return `GetEquityChangeInGainSortOrder(columnName=${this.columnName},order=${this.order})`;
};
