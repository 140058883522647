import { GetEquityContractNoteHistoryRPC } from "@khazana/khazana-rpcs";
import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";

import { ContractNoteDetailErrors } from "./ContractNoteDetailsErrors";
import {
  createEquityContractNoteHistoryDetailModel,
  EquityContractNoteHistoryDetailModel,
} from "../models/EquityContractNoteHistoryDetailModel";
import { useGetEquityContractNoteHistoryRPCImpl } from "../rpcs/RPC";

export const ViewEquityContractNoteHistoryStore = types
  .model("ViewEquityContractNoteHistoryStore", {
    error: types.maybeNull(
      types.enumeration<ContractNoteDetailErrors>(
        "ViewEquityDealInvalidRequestError",
        Object.values(ContractNoteDetailErrors),
      ),
    ),
    equityContractNoteHistoryDetail: types.maybeNull(
      types.array(EquityContractNoteHistoryDetailModel),
    ),
  })
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.equityContractNoteHistoryDetail = null;
    },
    getEquityContractNoteHistory: flow(function* (
      requestId: number | undefined,
    ) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        if (requestId !== undefined) {
          const apiClient: APIClient = getAPIClient(store);
          const request = new GetEquityContractNoteHistoryRPC.Request(
            requestId,
          );
          const result: LeoRPCResult<
            GetEquityContractNoteHistoryRPC.Response,
            GetEquityContractNoteHistoryRPC.Errors.Errors
          > =
            yield useGetEquityContractNoteHistoryRPCImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.equityContractNoteHistoryDetail = cast(
              response.historyDetails.map((detail) =>
                createEquityContractNoteHistoryDetailModel(detail),
              ),
            );
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              case ContractNoteDetailErrors.InvalidEQRawContractNoteId:
                store.error = ContractNoteDetailErrors.InvalidContractNoteID;
                break;
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in GetEquityContractNoteHistoryRPC`,
                );
            }
          } else {
            logger.error(
              `Unhandled Result: ${result} from GetEquityContractNoteHistoryRPC`,
            );
          }
        } else {
          logger.error(`RequestId is null`);
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetEquityContractNoteHistoryRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetEquityContractNoteHistoryRPC`,
          );
        }
      }
    }),
  }));

export const createViewEquityContractNoteHistoryStore = (): Instance<
  typeof ViewEquityContractNoteHistoryStore
> => {
  return ViewEquityContractNoteHistoryStore.create();
};
