import {
  applySnapshot,
  cast,
  clone,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { BrokerModel, createBrokerModel } from "../../../../models/BrokerModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  Broker,
  CreateEditMFDealRequestOrderRPC,
  Currency,
  GetBrokerListRPC,
  MFDealRequestOrder,
  ModuleType,
  Note,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetBrokerListRPC } from "../../../../rpcs/RPC";
import {
  createCurrencyRPCType,
  CurrencyModel,
} from "../../../../models/CurrencyModel";
import { mfDealDetailsErrorStore } from "./hooks";
import {
  createMFDealRequestOrderModel,
  MFDealRequestOrderModel,
  OrderType,
} from "../../models/MFDealRequestOrderModel";
import { useCreateEditMFDealRequestOrderRPC } from "../../rpc/RPC";
import {
  MFOrderDetailModel,
  MFOrderDetailType,
} from "../../models/MFOrderDetailModel";
import { createMFBrokerOrderDetailModel } from "../../models/MFBrokerOrderDetailModel";
import { createMfAMCOrderDetailModel } from "../../models/MfAMCOrderDetailModel";

export const DealOrderStore = types
  .model("DealOrderStore", {
    brokerList: types.array(BrokerModel),
    dealRequestOrder: MFDealRequestOrderModel,
    createEditDealRequestOrder: MFDealRequestOrderModel,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setupOrderDialogData: (orderType: OrderType): void => {
        store.createEditDealRequestOrder.orderType = orderType;
        if (orderType === OrderType.AMC) {
          store.createEditDealRequestOrder.amcOrders = cast([
            createMfAMCOrderDetailModel(),
          ]);
        } else {
          store.createEditDealRequestOrder.brokerOrders = cast([
            createMFBrokerOrderDetailModel(),
          ]);
        }
      },
      clearBroker(index: number): void {
        if (
          store.createEditDealRequestOrder.orderType === OrderType.BROKER &&
          store.createEditDealRequestOrder.brokerOrders
        ) {
          store.createEditDealRequestOrder.brokerOrders[index].broker =
            undefined;
        }
      },
      editBrokerDetail(index: number, brokerId: string): void {
        const logger = getEnv(store).logger;
        if (
          store.createEditDealRequestOrder.orderType === OrderType.BROKER &&
          store.createEditDealRequestOrder.brokerOrders
        ) {
          const brokers = store.brokerList.filter(
            (broker) => broker.id === brokerId,
          )[0];
          if (brokers) {
            store.createEditDealRequestOrder.brokerOrders[index].broker =
              createBrokerModel(
                new Broker(
                  new LeoUUID(brokers.id),
                  brokers.name,
                  new Currency(brokers.currency.code, brokers.currency.symbol),
                ),
              );
          } else {
            logger.error(
              `Could not find broker with id: ${brokerId} in ${store.brokerList}`,
            );
          }
        } else {
          logger.error(
            "Tried to edit broker without brokerAmountDetails being present in DealOrderStore",
          );
        }
      },
      updateAutoCompleteError(error: boolean, index: number): void {
        if (
          store.createEditDealRequestOrder.orderType === OrderType.BROKER &&
          store.createEditDealRequestOrder.brokerOrders
        ) {
          store.createEditDealRequestOrder.brokerOrders[index].isError = error;
        }
      },
      editAmountDetail(
        index: number,
        value: number,
        orderDetailType: MFOrderDetailType,
      ): void {
        if (store.createEditDealRequestOrder.orderType === OrderType.BROKER) {
          const amountUnits = MFOrderDetailModel.create({
            amountUnits: value.toString(),
            type: orderDetailType,
          });
          store.createEditDealRequestOrder.brokerOrders[index].requestDetail =
            amountUnits;
        } else {
          const amountUnits = MFOrderDetailModel.create({
            amountUnits: value.toString(),
            type: orderDetailType,
          });
          store.createEditDealRequestOrder.amcOrders[index].requestDetail =
            amountUnits;
        }
      },
      addBrokerAmountDetail(): void {
        if (store.createEditDealRequestOrder.orderType === OrderType.BROKER) {
          const brokerOrders = store.createEditDealRequestOrder.brokerOrders;
          const newBrokerEntry = createMFBrokerOrderDetailModel();
          brokerOrders?.push(newBrokerEntry);
          store.createEditDealRequestOrder.brokerOrders = cast(brokerOrders);
        } else {
          const amcOrders = store.createEditDealRequestOrder.amcOrders;
          const newBrokerEntry = createMfAMCOrderDetailModel();
          amcOrders?.push(newBrokerEntry);
          store.createEditDealRequestOrder.amcOrders = cast(amcOrders);
        }
      },
      deleteAmountDetail(index: number): void {
        if (store.createEditDealRequestOrder.orderType === OrderType.BROKER) {
          const brokerOrders = store.createEditDealRequestOrder.brokerOrders;
          brokerOrders?.splice(index, 1);
          store.createEditDealRequestOrder.brokerOrders = cast(brokerOrders);
        } else {
          const amcOrders = store.createEditDealRequestOrder.amcOrders;
          amcOrders?.splice(index, 1);
          store.createEditDealRequestOrder.amcOrders = cast(amcOrders);
        }
      },
      updateAmountUnitsError(error: boolean, index: number): void {
        console.log(store.createEditDealRequestOrder.orderType);
        if (store.createEditDealRequestOrder.orderType === OrderType.BROKER) {
          store.createEditDealRequestOrder.brokerOrders[
            index
          ].isAmountUnitsError = error;
        } else if (store.createEditDealRequestOrder.amcOrders) {
          store.createEditDealRequestOrder.amcOrders[index].isAmountUnitsError =
            error;
        }
      },
      setDealRequestOrder: (
        dealRequestOrder: MFDealRequestOrder.MFDealRequestOrder | null,
      ): void => {
        store.dealRequestOrder =
          createMFDealRequestOrderModel(dealRequestOrder);
        store.createEditDealRequestOrder =
          createMFDealRequestOrderModel(dealRequestOrder);
      },
      resetCreateEditDealRequestOrder: (): void => {
        store.createEditDealRequestOrder = clone(store.dealRequestOrder);
      },
      getBrokerList: flow(function* (
        currency: Instance<typeof CurrencyModel> | undefined,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetBrokerListRPC.Request(
          ModuleType.ModuleType.MUTUAL_FUND,
          currency ? createCurrencyRPCType(currency) : null,
        );
        const result: LeoRPCResult<
          GetBrokerListRPC.Response,
          GetBrokerListRPC.Errors.Errors
        > = yield useGetBrokerListRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.brokerList = cast(
            response.brokers.map((broker) => createBrokerModel(broker)),
          );
        } else {
          const errorStore = mfDealDetailsErrorStore(store);
          errorStore.setError("Unhandled error in getBrokerList");
          logger.error("Unhandled error in getBrokerList");
        }
      }),
      createEditMFDealRequestOrder: flow(function* (
        dealRequestId: number,
        note?: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new CreateEditMFDealRequestOrderRPC.Request(
          dealRequestId,
          store.createEditDealRequestOrder.getDealRequestOrderRPCType(),
          note ? new Note(note) : null,
        );
        const result: LeoRPCResult<
          CreateEditMFDealRequestOrderRPC.Response,
          CreateEditMFDealRequestOrderRPC.Errors.Errors
        > =
          yield useCreateEditMFDealRequestOrderRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          const errorStore = mfDealDetailsErrorStore(store);
          errorStore.setError(error.code);
        } else {
          const errorStore = mfDealDetailsErrorStore(store);
          errorStore.setError(
            "Unhandled error in createEditMFDealRequestOrder",
          );
          logger.error("Unhandled error in createEditMFDealRequestOrder");
        }
      }),
    };
  });

export const createDealOrderStore = (): Instance<typeof DealOrderStore> => {
  return DealOrderStore.create({
    dealRequestOrder: createMFDealRequestOrderModel(),
    createEditDealRequestOrder: createMFDealRequestOrderModel(),
  });
};
