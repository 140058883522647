"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdCashflowDetailRPCClientImpl = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const tedwig_1 = require("@surya-digital/tedwig");
const getFdCashflowDetailRPC_1 = require("./getFdCashflowDetailRPC");
class GetFdCashflowDetailRPCClientImpl extends getFdCashflowDetailRPC_1.GetFdCashflowDetailRPC {
    constructor(client) {
        super();
        this.client = client;
    }
    execute(request) {
        return __awaiter(this, void 0, void 0, function* () {
            GetFdCashflowDetailRPCClientImpl.validateRequest(request);
            const requestBody = request.toDTO();
            const response = yield this.client.sendRequest(new tedwig_1.Request(tedwig_1.Method.Post, "fixed-deposit/GetFdCashflowDetail", undefined, undefined, requestBody));
            return (0, leo_ts_runtime_1.parseResponse)(response, "fixed-deposit/GetFdCashflowDetail", getFdCashflowDetailRPC_1.GetFdCashflowDetailRPC.Response.fromDTO, getFdCashflowDetailRPC_1.GetFdCashflowDetailRPC.Errors.fromDTO);
        });
    }
    static validateRequest(request) {
        const isGetFdCashflowDetailRPCRequestValue = request.isGetFdCashflowDetailRPCRequest;
        if (!(request instanceof getFdCashflowDetailRPC_1.GetFdCashflowDetailRPC.Request || Boolean(isGetFdCashflowDetailRPCRequestValue))) {
            throw new getFdCashflowDetailRPC_1.GetFdCashflowDetailRPC.InvalidRequestError("Attribute request is not a GetFdCashflowDetailRPC.Request");
        }
    }
}
exports.GetFdCashflowDetailRPCClientImpl = GetFdCashflowDetailRPCClientImpl;
