"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityDealRequestStatus = exports.InvalidEquityDealRequestStatusError = void 0;
class InvalidEquityDealRequestStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityDealRequestStatusError";
    }
}
exports.InvalidEquityDealRequestStatusError = InvalidEquityDealRequestStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var EquityDealRequestStatus;
(function (EquityDealRequestStatus_1) {
    let EquityDealRequestStatus;
    (function (EquityDealRequestStatus) {
        EquityDealRequestStatus["IC_PENDING"] = "IC_PENDING";
        EquityDealRequestStatus["IC_APPROVED"] = "IC_APPROVED";
        EquityDealRequestStatus["IC_REJECTED"] = "IC_REJECTED";
        EquityDealRequestStatus["EXPIRED"] = "EXPIRED";
        EquityDealRequestStatus["CANCELLED"] = "CANCELLED";
        EquityDealRequestStatus["DEAL_OPEN"] = "DEAL_OPEN";
        EquityDealRequestStatus["SETTLED"] = "SETTLED";
        EquityDealRequestStatus["SYSTEM_SETTLED"] = "SYSTEM_SETTLED";
        EquityDealRequestStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        EquityDealRequestStatus["ACCOUNTS_APPROVED"] = "ACCOUNTS_APPROVED";
        EquityDealRequestStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
    })(EquityDealRequestStatus = EquityDealRequestStatus_1.EquityDealRequestStatus || (EquityDealRequestStatus_1.EquityDealRequestStatus = {}));
    EquityDealRequestStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "IC_PENDING":
                return EquityDealRequestStatus.IC_PENDING;
            case "IC_APPROVED":
                return EquityDealRequestStatus.IC_APPROVED;
            case "IC_REJECTED":
                return EquityDealRequestStatus.IC_REJECTED;
            case "EXPIRED":
                return EquityDealRequestStatus.EXPIRED;
            case "CANCELLED":
                return EquityDealRequestStatus.CANCELLED;
            case "DEAL_OPEN":
                return EquityDealRequestStatus.DEAL_OPEN;
            case "SETTLED":
                return EquityDealRequestStatus.SETTLED;
            case "SYSTEM_SETTLED":
                return EquityDealRequestStatus.SYSTEM_SETTLED;
            case "ACCOUNTS_PENDING":
                return EquityDealRequestStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_APPROVED":
                return EquityDealRequestStatus.ACCOUNTS_APPROVED;
            case "ACCOUNTS_REJECTED":
                return EquityDealRequestStatus.ACCOUNTS_REJECTED;
            default:
                throw new InvalidEquityDealRequestStatusError(`Case ${dto.case} is not valid case of EquityDealRequestStatus`);
        }
    };
    EquityDealRequestStatus_1.toDTO = (equityDealRequestStatus) => {
        const ret = {
            case: EquityDealRequestStatus[equityDealRequestStatus],
        };
        return ret;
    };
    EquityDealRequestStatus_1.isInstanceOf = (other) => {
        if (other in EquityDealRequestStatus) {
            return true;
        }
        return false;
    };
})(EquityDealRequestStatus = exports.EquityDealRequestStatus || (exports.EquityDealRequestStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
