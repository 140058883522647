import {
  getParent,
  getRoot,
  IAnyStateTreeNode,
  Instance,
  types,
} from "mobx-state-tree";
import { MFCreateDealRequestStore } from "./MFCreateDealRequestStore";
import { useMFStore } from "../../../store/hooks";
import { MFSchemeSearchStore } from "./MFSchemeSearchStore";
import { MFFolioNumberStore } from "./MFFolioNumberStore";
import { MFDealImpactStore } from "./MFDealImpactStore";
import { MFDealRequestSummaryStore } from "./MFDealRequestSummaryStore";
import { MFCreateDealRequestErrorStore } from "./MFCreateDealRequestErrorStore";
import { APIClient } from "@surya-digital/tedwig";
import { NetworkingStore } from "@khazana/khazana-boilerplate";

export const useMFCreateDealRequestStore = (): Instance<
  typeof MFCreateDealRequestStore
> => {
  return useMFStore().createDealRequestStore;
};

export const useMFSchemeSearchStore = (): Instance<
  typeof MFSchemeSearchStore
> => {
  return useMFCreateDealRequestStore().schemeSearchStore;
};

export const useMFFolioNumberStore = (): Instance<
  typeof MFFolioNumberStore
> => {
  return useMFCreateDealRequestStore().folioNumberStore;
};

export const useMFDealImpactStore = (): Instance<typeof MFDealImpactStore> => {
  return useMFCreateDealRequestStore().dealImpactStore;
};

export const useMFDealRequestSummaryStore = (): Instance<
  typeof MFDealRequestSummaryStore
> => {
  return useMFCreateDealRequestStore().dealRequestSummaryStore;
};

export const useMFCreateDealRequestErrorStore = (): Instance<
  typeof MFCreateDealRequestErrorStore
> => {
  return useMFCreateDealRequestStore().errorStore;
};

export const getAPIClient = (store: IAnyStateTreeNode): APIClient => {
  // RootStore model to represent that NetworkingStore is present inside RootStore.
  const RootStore = types.model({ networkingStore: NetworkingStore });
  const rootStore = getRoot<typeof RootStore>(store);
  return rootStore.networkingStore.useAPIClient();
};
export const mfCreateDealErrorStore = (
  store: IAnyStateTreeNode,
): Instance<typeof MFCreateDealRequestErrorStore> => {
  const createDealStore = getParent<typeof MFCreateDealRequestStore>(store);
  return createDealStore.errorStore;
};
