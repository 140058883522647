"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityBenchmarkReturnsGraphRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const percentageDate_1 = require("../types/percentageDate");
const equityReportDateRange_1 = require("./equityReportDateRange");
/* eslint-disable import/export */
class GetEquityBenchmarkReturnsGraphRPC {
}
exports.GetEquityBenchmarkReturnsGraphRPC = GetEquityBenchmarkReturnsGraphRPC;
(function (GetEquityBenchmarkReturnsGraphRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityBenchmarkReturnsGraphRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(indexId = null, range = null, entityId = null, portfolioId = null) {
            this.isGetEquityBenchmarkReturnsGraphRPCRequest = true;
            if (indexId !== undefined && indexId !== null) {
                Request.validateIndexId(indexId);
            }
            this.indexId = indexId;
            if (range !== undefined && range !== null) {
                Request.validateRange(range);
            }
            this.range = range;
            if (entityId !== undefined && entityId !== null) {
                Request.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
        }
        static fromDTO(dto) {
            const indexId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "indexId", InvalidRequestError);
            let range = (0, leo_ts_runtime_1.getOptionalObject)(dto, "range");
            if (range !== undefined && range !== null) {
                range = equityReportDateRange_1.EquityReportDateRange.fromDTO(range);
            }
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            return new Request(indexId, range, entityId, portfolioId);
        }
        toDTO() {
            const ret = {};
            if (this.indexId !== undefined && this.indexId !== null) {
                ret.indexId = this.indexId;
            }
            if (this.range) {
                ret.range = this.range.toDTO();
            }
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            return ret;
        }
        copy(indexId = this.indexId, range = this.range, entityId = this.entityId, portfolioId = this.portfolioId) {
            return new Request(indexId, range, entityId, portfolioId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityBenchmarkReturnsGraphRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.indexId !== other.indexId) {
                return false;
            }
            if (this.range) {
                if (!this.range.equals(other.range)) {
                    return false;
                }
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateIndexId = (indexId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(indexId)) {
            throw new InvalidRequestError(`Attribute indexId is not an Int64`);
        }
    };
    Request.validateRange = (range) => {
        if (!equityReportDateRange_1.EquityReportDateRange.isInstanceOf(range)) {
            throw new InvalidRequestError(`Attribute range is not a EquityReportDateRange.EquityReportDateRange`);
        }
    };
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    GetEquityBenchmarkReturnsGraphRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityBenchmarkReturnsGraphRPC.Request(indexId=${this.indexId},range=${this.range},entityId=${this.entityId},portfolioId=${this.portfolioId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityBenchmarkReturnsGraphRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(benchmarkData, returnsData) {
            this.isGetEquityBenchmarkReturnsGraphRPCResponse = true;
            Response.validateBenchmarkData(benchmarkData);
            this.benchmarkData = benchmarkData;
            Response.validateReturnsData(returnsData);
            this.returnsData = returnsData;
        }
        static fromDTO(dto) {
            const benchmarkData = (0, leo_ts_runtime_1.getList)(dto, "benchmarkData", percentageDate_1.PercentageDate.fromDTO, InvalidResponseError);
            const returnsData = (0, leo_ts_runtime_1.getList)(dto, "returnsData", percentageDate_1.PercentageDate.fromDTO, InvalidResponseError);
            return new Response(benchmarkData, returnsData);
        }
        toDTO() {
            const ret = {
                benchmarkData: this.benchmarkData.map((e) => {
                    return e.toDTO();
                }),
                returnsData: this.returnsData.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(benchmarkData = this.benchmarkData, returnsData = this.returnsData) {
            return new Response(benchmarkData, returnsData);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityBenchmarkReturnsGraphRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.benchmarkData, other.benchmarkData)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.returnsData, other.returnsData)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBenchmarkData = (benchmarkData) => {
        if (!Array.isArray(benchmarkData)) {
            throw new InvalidResponseError(`Attribute benchmarkData is not a List`);
        }
        for (let i = 0; i < benchmarkData.length; i += 1) {
            const isPercentageDateValue = benchmarkData[i].isPercentageDate;
            if (!(benchmarkData[i] instanceof percentageDate_1.PercentageDate || Boolean(isPercentageDateValue))) {
                throw new InvalidResponseError(`Attribute benchmarkData at index ${i} is not a PercentageDate.`);
            }
        }
    };
    Response.validateReturnsData = (returnsData) => {
        if (!Array.isArray(returnsData)) {
            throw new InvalidResponseError(`Attribute returnsData is not a List`);
        }
        for (let i = 0; i < returnsData.length; i += 1) {
            const isPercentageDateValue = returnsData[i].isPercentageDate;
            if (!(returnsData[i] instanceof percentageDate_1.PercentageDate || Boolean(isPercentageDateValue))) {
                throw new InvalidResponseError(`Attribute returnsData at index ${i} is not a PercentageDate.`);
            }
        }
    };
    GetEquityBenchmarkReturnsGraphRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityBenchmarkReturnsGraphRPC.Response(benchmarkData=${this.benchmarkData},returnsData=${this.returnsData})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIndexId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_INDEX_ID") {
                super(code);
                this.isInvalidIndexId = true;
                InvalidIndexId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIndexId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIndexId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIndexIdValue = other.isInvalidIndexId;
                if (!(other instanceof InvalidIndexId || Boolean(isInvalidIndexIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIndexId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_INDEX_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIndexId = InvalidIndexId;
        InvalidIndexId.prototype.toString = function toString() {
            return `GetEquityBenchmarkReturnsGraphRPC.INVALID_INDEX_ID()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetEquityBenchmarkReturnsGraphRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `GetEquityBenchmarkReturnsGraphRPC.INVALID_PORTFOLIO_ID()`;
        };
        class InvalidRange extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_RANGE") {
                super(code);
                this.isInvalidRange = true;
                InvalidRange.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRange(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRange(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRangeValue = other.isInvalidRange;
                if (!(other instanceof InvalidRange || Boolean(isInvalidRangeValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRange.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_RANGE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRange = InvalidRange;
        InvalidRange.prototype.toString = function toString() {
            return `GetEquityBenchmarkReturnsGraphRPC.INVALID_RANGE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_INDEX_ID":
                    return InvalidIndexId.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "INVALID_RANGE":
                    return InvalidRange.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIndexIdValue = other.isInvalidIndexId;
            if (other instanceof InvalidIndexId || Boolean(isInvalidIndexIdValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isInvalidRangeValue = other.isInvalidRange;
            if (other instanceof InvalidRange || Boolean(isInvalidRangeValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityBenchmarkReturnsGraphRPC.Errors || (GetEquityBenchmarkReturnsGraphRPC.Errors = {}));
})(GetEquityBenchmarkReturnsGraphRPC = exports.GetEquityBenchmarkReturnsGraphRPC || (exports.GetEquityBenchmarkReturnsGraphRPC = {}));
