import { FiImpactTable, MFImpactTable } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";
import { getServerAmount } from "../../../utils";

export const ImpactTableModel = types.model("ImpactTableModel", {
  currentUnits: types.number,
  unitsAfterRequest: types.number,
  currentPercentage: types.number,
  percentageAfterRequest: types.number,
  currentVar: types.number,
  varAfterRequest: types.number,
});

export const createFiImpactTableModel = (
  impactTableValues: FiImpactTable,
): Instance<typeof ImpactTableModel> => {
  return ImpactTableModel.create({
    currentPercentage: impactTableValues.currentPercentage,
    currentUnits: impactTableValues.currentUnits,
    currentVar: impactTableValues.currentVar,
    percentageAfterRequest: impactTableValues.percentageAfterRequest,
    unitsAfterRequest: impactTableValues.unitsAfterRequest,
    varAfterRequest: impactTableValues.varAfterRequest,
  });
};

export const createMFImpactTableModel = (
  impactTableValues: MFImpactTable,
): Instance<typeof ImpactTableModel> => {
  return ImpactTableModel.create({
    currentPercentage: getServerAmount(
      Number(impactTableValues.currentPercentage.decimalValue),
    ), // This is done because the impact table values handle the amount in 10^4
    currentUnits: Number(impactTableValues.currentUnits.decimalValue),
    currentVar: Number(impactTableValues.currentVar.decimalValue),
    percentageAfterRequest: getServerAmount(
      Number(impactTableValues.percentageAfterRequest.decimalValue),
    ),
    unitsAfterRequest: Number(impactTableValues.unitsAfterRequest.decimalValue),
    varAfterRequest: Number(impactTableValues.varAfterRequest.decimalValue),
  });
};
