import {
  applySnapshot,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";

export enum MFSubmitError {
  InvalidAMCID = "INVALID_AMC_ID",
  InvalidISIN = "INVALID_ISIN",
  InvalidFolioNumber = "INVALID_FOLIO_NUMBER",
  InvalidFolioEntityCombination = "INVALID_FOLIO_ENTITY_COMBINATION",
  InvalidFolioAMCCombination = "INVALID_FOLIO_AMC_COMBINATION",
  InvalidBrokerId = "INVALID_BROKER_ID",
  InvalidEntityId = "INVALID_ENTITY_ID",
  InvalidRequestID = "INVALID_REQUEST_ID",
  IllegalConfirmationRequestState = "ILLEGAL_CONFIRMATION_REQUEST_STATE",
  UnknownError = "UNKNOWN_ERROR",
}
export const SubmitErrorStore = types
  .model("SubmitErrorStore", {
    error: types.maybe(types.enumeration(Object.values(MFSubmitError))),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setError: (error: string | undefined): void => {
        if (error) {
          const logger = getEnv(store).logger;
          switch (error) {
            case MFSubmitError.InvalidAMCID:
              store.error = MFSubmitError.InvalidAMCID;
              break;
            case MFSubmitError.InvalidISIN:
              store.error = MFSubmitError.InvalidISIN;
              break;
            case MFSubmitError.InvalidFolioNumber:
              store.error = MFSubmitError.InvalidFolioNumber;
              break;
            case MFSubmitError.InvalidFolioEntityCombination:
              store.error = MFSubmitError.InvalidFolioEntityCombination;
              break;
            case MFSubmitError.InvalidFolioAMCCombination:
              store.error = MFSubmitError.InvalidFolioAMCCombination;
              break;
            case MFSubmitError.InvalidBrokerId:
              store.error = MFSubmitError.InvalidBrokerId;
              break;
            case MFSubmitError.InvalidEntityId:
              store.error = MFSubmitError.InvalidEntityId;
              break;
            case MFSubmitError.InvalidRequestID:
              store.error = MFSubmitError.InvalidRequestID;
              break;
            case MFSubmitError.IllegalConfirmationRequestState:
              store.error = MFSubmitError.IllegalConfirmationRequestState;
              break;
            default:
              store.error = MFSubmitError.UnknownError;
              logger.error(`Unhandled error: ${error}`);
          }
        } else {
          store.error = undefined;
        }
      },
    };
  });

export const createSubmitErrorStore = (): Instance<typeof SubmitErrorStore> => {
  return SubmitErrorStore.create({});
};
