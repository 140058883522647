import { Instance, types } from "mobx-state-tree";
import { CurrencyModel, createCurrencyModel } from "./CurrencyModel";
import { BigAmount } from "@khazana/khazana-rpcs";

export const BigAmountModel = types.model({
  amount: types.string,
  currency: CurrencyModel,
});

export const createBigAmountModel = ({
  amount,
  currency,
}: BigAmount): Instance<typeof BigAmountModel> => {
  return BigAmountModel.create({
    amount: amount.decimalValue,
    currency: createCurrencyModel(currency),
  });
};
