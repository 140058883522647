import { FixedDepositStatus } from "@khazana/khazana-rpcs";
import i18next from "i18next";

export const getStatusString = (
  status: FixedDepositStatus.FixedDepositStatus | null,
): string | undefined => {
  switch (status) {
    case FixedDepositStatus.FixedDepositStatus.MATURED:
      return i18next.t("fd.reports.statusLabel.matured");
    case FixedDepositStatus.FixedDepositStatus.ACTIVE:
      return i18next.t("fd.reports.statusLabel.active");
    case FixedDepositStatus.FixedDepositStatus.WITHDRAWN:
      return i18next.t("fd.reports.statusLabel.withdrawn");
    default:
      return undefined;
  }
};
