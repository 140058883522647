import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { createSubmitErrorStore, SubmitErrorStore } from "./SubmitErrorStore";
import {
  createMFConfirmationFields,
  createMFConfirmationFieldsFromRPC,
  MFConfirmationFields,
} from "../../models/MFConfirmationFields";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  GetBrokerListRPC,
  GetMFAmcListRPC,
  MFTransactionType,
  ModuleType,
  Note,
  SubmitMFConfirmationRequestRPC,
  ValidateMFConfirmationRequestRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useGetMFAmcListRPC,
  useSubmitMFConfirmationRequestRPC,
  useValidateMFConfirmationRequestRPC,
} from "../../rpc/RPC";
import {
  createMFSchemeSearchStore,
  MFSchemeSearchStore,
} from "../../../deal-request/store/createDealRequestStore/MFSchemeSearchStore";
import { useGetBrokerListRPCClientImpl } from "../../../../fixed-income/contract-note/rpcs/RPC";
import { DropdownFieldOptionModel } from "../../../../store/fieldStores/DropdownFieldStore";
import i18next from "i18next";
import { DiffDetailType } from "../../../../models/DiffDetailType";
import {
  createFileUploadStore,
  FileUploadStore,
} from "../../../../store/FileUploadStore";
import { AutocompleteFieldOptionModel } from "../../../../store/fieldStores/AutocompleteFieldStore";

export const SubmitConfirmationDetailsStore = types
  .model("SubmitConfirmationDetailsStore", {
    fields: MFConfirmationFields,
    errorStore: SubmitErrorStore,
    schemeSearchStore: MFSchemeSearchStore,
    isLoading: types.optional(types.boolean, false),
    fileUploadStore: FileUploadStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setIsLoading: (isLoading: boolean): void => {
        store.isLoading = isLoading;
      },
      setFields: (
        details: Instance<typeof DiffDetailType>[],
        documentUrl: string | undefined,
      ): void => {
        store.fields = createMFConfirmationFieldsFromRPC(details);
        store.fields.setDocumentUrl(documentUrl);
      },
      getBrokerList: flow(function* () {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetBrokerListRPC.Request(
          ModuleType.ModuleType.MUTUAL_FUND,
        );
        store.fields.broker.setIsLoading(true);
        const result: LeoRPCResult<
          GetBrokerListRPC.Response,
          GetBrokerListRPC.Errors.Errors
        > = yield useGetBrokerListRPCClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fields.broker.setOptions(
            response.brokers.map((broker) =>
              DropdownFieldOptionModel.create({
                name: broker.name,
                value: broker.id.uuid,
              }),
            ),
          );
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetBrokerListRPC`,
          );
        }
        store.fields.broker.setIsLoading(false);
      }),
      getMFAmcList: flow(function* () {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFAmcListRPC.Request();
        store.fields.amc.setIsLoading(true);
        const result: LeoRPCResult<
          GetMFAmcListRPC.Response,
          GetMFAmcListRPC.Errors.Errors
        > = yield useGetMFAmcListRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fields.amc.setOptions(
            response.amcs.map((amc) =>
              AutocompleteFieldOptionModel.create({
                label: amc.name,
                id: amc.id.toString(),
              }),
            ),
          );
        } else {
          logger.error("Unhandled error");
        }
        store.fields.amc.setIsLoading(false);
      }),
      validateMFConfirmationRequest: flow<boolean, [number | undefined]>(
        function* (requestId?: number | undefined) {
          const logger = getEnv(store).logger;
          const apiClient = getAPIClient(store);
          if (store.fields.isValid()) {
            store.isLoading = true;
            const request = new ValidateMFConfirmationRequestRPC.Request(
              requestId,
              store.fields.getMFConfirmationRPCType(),
            );
            const result: LeoRPCResult<
              ValidateMFConfirmationRequestRPC.Response,
              ValidateMFConfirmationRequestRPC.Errors.Errors
            > =
              yield useValidateMFConfirmationRequestRPC(apiClient).execute(
                request,
              );
            if (result instanceof LeoRPCResult.Response) {
              store.isLoading = false;
              return true;
            } else if (result instanceof LeoRPCResult.Error) {
              store.errorStore.setError(result.error.code);
            } else {
              logger.error("Unhandled error");
            }
            store.isLoading = false;
          }
          return false;
        },
      ),
      submitMFConfirmationRequest: flow<
        number | undefined,
        [string | undefined, boolean, number | undefined]
      >(function* (
        note: string | undefined,
        forceFolioCreation: boolean,
        requestId?: number | undefined,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        if (store.fields.isValid()) {
          const request = new SubmitMFConfirmationRequestRPC.Request(
            requestId,
            store.fields.getMFConfirmationRPCType(),
            forceFolioCreation,
            note ? new Note(note) : undefined,
          );
          const result: LeoRPCResult<
            SubmitMFConfirmationRequestRPC.Response,
            SubmitMFConfirmationRequestRPC.Errors.Errors
          > =
            yield useSubmitMFConfirmationRequestRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            return response.requestId;
          } else if (result instanceof LeoRPCResult.Error) {
            store.errorStore.setError(result.error.code);
          } else {
            logger.error("Unhandled error");
          }
        }
      }),
    };
  })
  .views((store) => {
    return {
      get schemeDisabled(): boolean {
        return (
          store.fields.transactionType.value === undefined ||
          store.fields.amc.value === undefined
        );
      },
      get loadLabel(): string {
        if (
          store.fields.transactionType.value ===
          MFTransactionType.MFTransactionType.MF_PURCHASE
        ) {
          return i18next.t("mf.fields.entryLoadLabel", {
            val: store.schemeSearchStore.selectedScheme?.currency.symbol,
          });
        } else if (
          store.fields.transactionType.value ===
          MFTransactionType.MFTransactionType.MF_REDEEM
        ) {
          return i18next.t("mf.fields.exitLoadLabel", {
            val: store.schemeSearchStore.selectedScheme?.currency.symbol,
          });
        } else {
          return i18next.t("mf.fields.entryExitLoadLabel", {
            val: store.schemeSearchStore.selectedScheme?.currency.symbol,
          });
        }
      },
      get loadPlaceholder(): string {
        if (
          store.fields.transactionType.value ===
          MFTransactionType.MFTransactionType.MF_PURCHASE
        ) {
          return i18next.t("mf.fields.entryLoadPlaceholder");
        } else if (
          store.fields.transactionType.value ===
          MFTransactionType.MFTransactionType.MF_REDEEM
        ) {
          return i18next.t("mf.fields.exitLoadPlaceholder");
        } else {
          return i18next.t("mf.fields.entryExitLoadPlaceholder");
        }
      },
    };
  });

export const createSubmitConfirmationDetailsStore = (): Instance<
  typeof SubmitConfirmationDetailsStore
> => {
  return SubmitConfirmationDetailsStore.create({
    fields: createMFConfirmationFields(),
    errorStore: createSubmitErrorStore(),
    schemeSearchStore: createMFSchemeSearchStore(),
    fileUploadStore: createFileUploadStore(),
  });
};
