"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFImpactTable = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
class InvalidMFImpactTableError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFImpactTableError";
    }
}
class MFImpactTable {
    constructor(currentUnits, unitsAfterRequest, currentPercentage, percentageAfterRequest, currentVar, varAfterRequest) {
        this.isMFImpactTable = true;
        MFImpactTable.validateCurrentUnits(currentUnits);
        this.currentUnits = currentUnits;
        MFImpactTable.validateUnitsAfterRequest(unitsAfterRequest);
        this.unitsAfterRequest = unitsAfterRequest;
        MFImpactTable.validateCurrentPercentage(currentPercentage);
        this.currentPercentage = currentPercentage;
        MFImpactTable.validatePercentageAfterRequest(percentageAfterRequest);
        this.percentageAfterRequest = percentageAfterRequest;
        MFImpactTable.validateCurrentVar(currentVar);
        this.currentVar = currentVar;
        MFImpactTable.validateVarAfterRequest(varAfterRequest);
        this.varAfterRequest = varAfterRequest;
    }
    static fromDTO(dto) {
        const currentUnits = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currentUnits", InvalidMFImpactTableError));
        const unitsAfterRequest = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "unitsAfterRequest", InvalidMFImpactTableError));
        const currentPercentage = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currentPercentage", InvalidMFImpactTableError));
        const percentageAfterRequest = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "percentageAfterRequest", InvalidMFImpactTableError));
        const currentVar = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currentVar", InvalidMFImpactTableError));
        const varAfterRequest = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "varAfterRequest", InvalidMFImpactTableError));
        return new MFImpactTable(currentUnits, unitsAfterRequest, currentPercentage, percentageAfterRequest, currentVar, varAfterRequest);
    }
    toDTO() {
        const ret = {
            currentUnits: this.currentUnits.toDTO(),
            unitsAfterRequest: this.unitsAfterRequest.toDTO(),
            currentPercentage: this.currentPercentage.toDTO(),
            percentageAfterRequest: this.percentageAfterRequest.toDTO(),
            currentVar: this.currentVar.toDTO(),
            varAfterRequest: this.varAfterRequest.toDTO(),
        };
        return ret;
    }
    copy(currentUnits = this.currentUnits, unitsAfterRequest = this.unitsAfterRequest, currentPercentage = this.currentPercentage, percentageAfterRequest = this.percentageAfterRequest, currentVar = this.currentVar, varAfterRequest = this.varAfterRequest) {
        return new MFImpactTable(currentUnits, unitsAfterRequest, currentPercentage, percentageAfterRequest, currentVar, varAfterRequest);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFImpactTableValue = other.isMFImpactTable;
        if (!(other instanceof MFImpactTable) || Boolean(isMFImpactTableValue)) {
            return false;
        }
        if (!this.currentUnits.equals(other.currentUnits)) {
            return false;
        }
        if (!this.unitsAfterRequest.equals(other.unitsAfterRequest)) {
            return false;
        }
        if (!this.currentPercentage.equals(other.currentPercentage)) {
            return false;
        }
        if (!this.percentageAfterRequest.equals(other.percentageAfterRequest)) {
            return false;
        }
        if (!this.currentVar.equals(other.currentVar)) {
            return false;
        }
        if (!this.varAfterRequest.equals(other.varAfterRequest)) {
            return false;
        }
        return true;
    }
}
exports.MFImpactTable = MFImpactTable;
MFImpactTable.validateCurrentUnits = (currentUnits) => {
    const isBigDecimalValue = currentUnits.isBigDecimal;
    if (!(currentUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFImpactTableError(`Attribute currentUnits is not a BigDecimal`);
    }
};
MFImpactTable.validateUnitsAfterRequest = (unitsAfterRequest) => {
    const isBigDecimalValue = unitsAfterRequest.isBigDecimal;
    if (!(unitsAfterRequest instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFImpactTableError(`Attribute unitsAfterRequest is not a BigDecimal`);
    }
};
MFImpactTable.validateCurrentPercentage = (currentPercentage) => {
    const isBigDecimalValue = currentPercentage.isBigDecimal;
    if (!(currentPercentage instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFImpactTableError(`Attribute currentPercentage is not a BigDecimal`);
    }
};
MFImpactTable.validatePercentageAfterRequest = (percentageAfterRequest) => {
    const isBigDecimalValue = percentageAfterRequest.isBigDecimal;
    if (!(percentageAfterRequest instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFImpactTableError(`Attribute percentageAfterRequest is not a BigDecimal`);
    }
};
MFImpactTable.validateCurrentVar = (currentVar) => {
    const isBigDecimalValue = currentVar.isBigDecimal;
    if (!(currentVar instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFImpactTableError(`Attribute currentVar is not a BigDecimal`);
    }
};
MFImpactTable.validateVarAfterRequest = (varAfterRequest) => {
    const isBigDecimalValue = varAfterRequest.isBigDecimal;
    if (!(varAfterRequest instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFImpactTableError(`Attribute varAfterRequest is not a BigDecimal`);
    }
};
MFImpactTable.prototype.toString = function toString() {
    return `MFImpactTable(currentUnits=${this.currentUnits},unitsAfterRequest=${this.unitsAfterRequest},currentPercentage=${this.currentPercentage},percentageAfterRequest=${this.percentageAfterRequest},currentVar=${this.currentVar},varAfterRequest=${this.varAfterRequest})`;
};
