import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import {
  getFdCashflowDetailsPath,
  getPath,
} from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FDDealRequestDetailsSection } from "../components/FDDealRequestDetailsSection";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { FDDealInvalidRequestError } from "../store/FDDealRequestErrors";
import { FDDealRequestHistorySection } from "../components/FDDealRequestHistorySection";
import { GetFDDealStatusSection } from "../components/GetFDDealStatusSection";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { CheckResponse, FDDealRequestStatus } from "@khazana/khazana-rpcs";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { createServerNoteRPCType } from "../../../../../utils";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { DepositCertificateTable } from "../components/DepositCertificateTable";
import { FdUserPrivileges } from "../../../../user/UserPrivileges";
import { useUserStore } from "../../../store/hooks";
import { FDSettleDealDialog } from "../components/FDSettleDealDialog";
import { WithdrawDialog } from "../components/WithdrawDialog";
import { WithdrawTable } from "../components/WithdrawTable";
import {
  HeaderButton,
  HeaderButtonProps,
} from "../../../components/page-header/HeaderButton";
import { HeaderContainer } from "../../../components/page-header/HeaderContainer";
import { FDDealRequestDetailsKeys } from "../models/FDDealRequestDetailsKeys";
import { getDetailCellValue } from "../../../utils/DetailCellUtils";
import { FdInvestmentDetailsCard } from "../../components/investment-details/FdInvestmentDetailsCard";

const CreateViewFDDealRequestStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    body: {
      gap: spacing.space2XL,
      padding: spacing.space2XL,
      minWidth: "calc(100vw - 256px)",
    },
  };
};

export const ViewFDDealRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const store = useFDDealRequestDetailsStore();
  const requestId = Number(searchParam.get("requestId"));
  const privileges = useUserStore().privileges;
  const [dialogType, setDialogType] = useState<
    "Approve" | "Reject" | "Submit" | "Cancel"
  >("Submit");
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSettleDialogOpen, setIsSettleDialogOpen] = useState(false);
  const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);
  const [isWithdrawConfirm, setIsWithdrawConfirm] = useState(false);
  const [isCertificateEdit, setIsCertificateEdit] = useState(false);
  const style = useMemo(() => CreateViewFDDealRequestStyle(spacing), []);

  const getData = useCallback(() => {
    const id = Number(requestId);
    store.getFdDealDetails(id);
    if (privileges.includes(FdUserPrivileges.ViewFixedDepositCertificate)) {
      store.certificateStore?.getFDCertificateList(id);
    }
    if (
      privileges.includes(FdUserPrivileges.ViewFixedDepositWithdrawCertificate)
    ) {
      store.withdrawStore?.list.getFdWithdrawList(id);
    }
    store.bannerInfoStore.getFdDealRequestDetailsBannerInfo(id);
  }, []);

  useEffect(() => {
    getData();
    return (): void => {
      store.reset();
    };
  }, []);

  const getErrorDialogText = (): string => {
    switch (store.error) {
      case FDDealInvalidRequestError.InvalidRequestId:
        return t("common.noResultsFound");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const getDealStatusSection = (): React.ReactElement => {
    const fdBannerInfo = store.bannerInfoStore.fdDealRequestDetailsBannerInfo;
    if (fdBannerInfo && fdBannerInfo.status && fdBannerInfo.requestNote) {
      return (
        <>
          <GetFDDealStatusSection value={fdBannerInfo} />
        </>
      );
    } else {
      return <></>;
    }
  };

  const getCheckActionButtons = (): React.ReactElement => {
    const status = getDetailCellValue(
      store.details,
      FDDealRequestDetailsKeys.requestStatus,
    ).value as FDDealRequestStatus.FDDealRequestStatus | undefined;
    if (!status) {
      return <></>;
    }
    if (
      store.requestActions.allowCheck &&
      status === FDDealRequestStatus.FDDealRequestStatus.ACCOUNTS_PENDING
    ) {
      return (
        <HeaderButton
          label={t("fd.depositCertificate.viewCertificateDetailsButtonTitle")}
          onClick={(): void => {
            navigate({
              pathname: getPath(
                Module.Fd,
                Route.ManageDealRequestCertificateDetails,
              ),
              search: createSearchParams({
                requestId: requestId.toString(),
                certificateId:
                  store.certificateStore.certificateList[0]?.certificateId.toString(),
              }).toString(),
            });
          }}
          buttonType="outlined"
        />
      );
    } else {
      return (
        <HeaderContainer
          primaryButton={{
            label: t("common.approve"),
            buttonType: "outlined",
            onClick: (): void => {
              setDialogType("Approve");
              setIsDialogOpen(true);
            },
          }}
          secondaryButton={{
            label: t("common.reject"),
            onClick: async (): Promise<void> => {
              setDialogType("Reject");
              setIsDialogOpen(true);
            },
            buttonType: "outlined",
          }}
        />
      );
    }
  };

  const getCancelActionButtonProps = (): HeaderButtonProps => {
    return {
      label: t("common.cancel"),
      onClick: (): void => {
        setDialogType("Cancel");
        setIsDialogOpen(true);
      },
      buttonType: "outlined",
    };
  };

  const getConfirmDepositDetailsButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("fd.dealDetails.confirmDepositDetailsTitle"),
          buttonType: "outlined",
          onClick: async (): Promise<void> => {
            navigate({
              pathname: getPath(
                Module.Fd,
                Route.ManageDealRequestCertificateEdit,
              ),
              search: createSearchParams({
                requestId: requestId.toString(),
              }).toString(),
            });
          },
        }}
        secondaryButton={getCancelActionButtonProps()}
      />
    );
  };

  const getCancelActionButton = (): React.ReactElement => {
    return <HeaderButton {...getCancelActionButtonProps()} />;
  };

  const getSettleActionButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.settleDeal")}
        onClick={async (): Promise<void> => {
          store.createSettleStore();
          setIsSettleDialogOpen(true);
        }}
        buttonType="filled"
      />
    );
  };

  const getWithdrawActionButton = (): React.ReactElement => {
    const amountDetails = getDetailCellValue(
      store.details,
      FDDealRequestDetailsKeys.depositAmount,
    );
    const currency = amountDetails.currency;
    if (currency === undefined) {
      return <></>;
    }
    return (
      <HeaderButton
        label={t("fd.dealDetails.withdrawButtonTitle")}
        onClick={async (): Promise<void> => {
          store.withdrawStore.confirmRequest.fields.setCurrency({
            code: currency.code,
            symbol: currency.symbol,
          });
          store.withdrawStore.createRequest.fields.setCurrency({
            code: currency.code,
            symbol: currency.symbol,
          });
          setIsWithdrawDialogOpen(true);
        }}
        buttonType="filled"
      />
    );
  };

  const getSubmitActionButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.submit")}
        onClick={async (): Promise<void> => {
          if (store.certificateStore.editCertificateDetail?.isValid()) {
            setDialogType("Submit");
            setIsDialogOpen(true);
          }
        }}
        buttonType="filled"
      />
    );
  };

  const getPageHeaderButtons = (): React.ReactElement | undefined => {
    if (isCertificateEdit) {
      return getSubmitActionButton();
    } else if (store.requestActions) {
      if (store.requestActions.allowCheck) {
        return getCheckActionButtons();
      } else if (store.requestActions.allowCancel) {
        if (store.requestActions.allowUpload) {
          return getConfirmDepositDetailsButtons();
        } else {
          return getCancelActionButton();
        }
      } else if (store.requestActions.allowWithdraw) {
        return getWithdrawActionButton();
      } else if (store.requestActions.allowSettle) {
        return getSettleActionButton();
      }
    }
  };

  const getBody = (): React.ReactElement => {
    const status = getDetailCellValue(
      store.details,
      FDDealRequestDetailsKeys.requestStatus,
    ).value as FDDealRequestStatus.FDDealRequestStatus | undefined;
    if (!status) {
      return <></>;
    }
    return (
      <>
        {getDealStatusSection()}
        <FdInvestmentDetailsCard
          store={store.investmentDetailsStore}
          onButtonClick={() => {
            navigate(
              getFdCashflowDetailsPath(
                store.investmentDetailsStore.investmentId,
                requestId ? Number(requestId) : undefined,
              ) ?? "",
            );
          }}
        />
        {store.details && (
          <WithdrawTable
            status={status}
            onClick={(id) => {
              if (
                status ===
                FDDealRequestStatus.FDDealRequestStatus.FD_WITHDRAWAL_APPROVED
              ) {
                setIsWithdrawConfirm(true);
                store.withdrawStore.setConfirmRequest(id);
                setIsWithdrawDialogOpen(true);
              }
            }}
          />
        )}
        <DepositCertificateTable
          onViewCertificate={(cid): void => {
            navigate({
              pathname: getPath(
                Module.Fd,
                Route.ManageDealRequestCertificateDetails,
              ),
              search: createSearchParams({
                requestId: requestId.toString(),
                certificateId: cid.toString(),
              }).toString(),
            });
          }}
          onEditCertificate={(cid): void => {
            navigate({
              pathname: getPath(
                Module.Fd,
                Route.ManageDealRequestCertificateEdit,
              ),
              search: createSearchParams({
                requestId: requestId.toString(),
                certificateId: cid.toString(),
              }).toString(),
            });
          }}
        />
        <FDDealRequestDetailsSection />
        <FDDealRequestHistorySection />
      </>
    );
  };

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      let title = "";
      let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
      switch (dialogType) {
        case "Approve":
          title = t("common.approveDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            if (
              privileges.includes(FdUserPrivileges.CheckFixedDepositDealRequest)
            ) {
              await store.checkFDDealRequestStore.checkICDealRequest(
                Number(requestId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
                  createServerNoteRPCType(note),
                ),
              );
            } else if (
              privileges.includes(
                FdUserPrivileges.CheckFixedDepositCertificateRequest,
              )
            ) {
              await store.checkFDDealRequestStore.checkACDealRequest(
                Number(requestId),
                Number(store.certificateStore.certificateList[0].certificateId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
                  createServerNoteRPCType(note),
                ),
              );
            }
          };
          break;
        case "Reject":
          title = t("common.rejectDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            if (
              privileges.includes(FdUserPrivileges.CheckFixedDepositDealRequest)
            ) {
              await store.checkFDDealRequestStore.checkICDealRequest(
                Number(requestId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                  createServerNoteRPCType(note),
                ),
              );
            } else if (
              privileges.includes(
                FdUserPrivileges.CheckFixedDepositCertificateRequest,
              )
            ) {
              await store.checkFDDealRequestStore.checkACDealRequest(
                Number(requestId),
                Number(store.certificateStore.certificateList[0].certificateId),
                new CheckResponse(
                  CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                  createServerNoteRPCType(note),
                ),
              );
            }
          };
          break;
        case "Cancel":
          title = t("common.cancelDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            await store.cancelDealRequest(Number(requestId), note);
          };
          break;
        case "Submit":
          title = t("fd.dealDetails.confirmDepositDetailsTitle");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            await store.certificateStore?.submitFDCertificateDetails(
              Number(requestId),
              note,
            );
            if (!store.certificateStore?.error) {
              setIsCertificateEdit(false);
            }
          };
          break;
      }
      return (
        <TextFieldDialog
          title={title}
          onClose={(): void => {
            setIsDialogOpen(false);
          }}
          isOpen={isDialogOpen}
          primaryButtonType={dialogType}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsDialogLoading(true);
            await onPrimaryButtonClick(note);
            setIsDialogOpen(false);
            store.bannerInfoStore.reset();
            getData();
            setIsDialogLoading(false);
          }}
          isCloseIconPresent={!isDialogLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const showError = (): boolean => {
    return (
      store.error !== null ||
      store.checkFDDealRequestStore.error !== null ||
      store.withdrawStore.createRequest.error !== null ||
      store.withdrawStore.confirmRequest.error !== null
    );
  };

  return (
    <Stack>
      <PageHeader
        title={t("fd.dealDetails.pageTitle")}
        actionElement={getPageHeaderButtons()}
      />
      <Stack sx={style.body}>
        <Breadcrumb
          links={[
            {
              label: t("common.manageDealRequests"),
              onLabelClick: (): void => {
                navigate(getPath(Module.Fd, Route.ManageDealRequest));
              },
            },
          ]}
          currentLabel={t("fd.dealDetails.pageTitle")}
        />
        {store.isLoading ? (
          <LoadingIndicator isLoading={store.isLoading} />
        ) : (
          getBody()
        )}
      </Stack>
      {showError() && (
        <ErrorDialog
          errorMessage={getErrorDialogText()}
          isErrorDialogOpen={showError()}
          onClose={(): void => {
            if (store.error === FDDealInvalidRequestError.InvalidRequestId) {
              navigate(-1);
            }
            store.clearError();
          }}
        />
      )}
      {isDialogOpen && getDialog()}
      {isSettleDialogOpen && (
        <FDSettleDealDialog
          isOpen={isSettleDialogOpen}
          onClose={(reload) => {
            setIsSettleDialogOpen(false);
            if (reload) {
              getData();
            }
          }}
        />
      )}
      {isWithdrawDialogOpen && (
        <WithdrawDialog
          isConfirm={isWithdrawConfirm}
          isOpen={isWithdrawDialogOpen}
          onClose={(reload) => {
            setIsWithdrawDialogOpen(false);
            if (reload) {
              getData();
            }
          }}
        />
      )}
    </Stack>
  );
});
