import {
  GetMFConfirmationRequestDetailsRPC,
  MFConfirmationActions,
  MFConfirmationRequestStatus,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFConfirmationRequestDetailsRPCImpl extends GetMFConfirmationRequestDetailsRPC {
  execute(
    _request: GetMFConfirmationRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFConfirmationRequestDetailsRPC.Response,
      GetMFConfirmationRequestDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetMFConfirmationRequestDetailsRPC.Response(
      new MFConfirmationActions(true, false, false, false, false, false, false),
      [],
      MFConfirmationRequestStatus.MFConfirmationRequestStatus.ACCOUNTS_PENDING,
      null,
      null,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFConfirmationRequestDetailsRPC.Response,
        GetMFConfirmationRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
