"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterestType = exports.InvalidInterestTypeError = void 0;
class InvalidInterestTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidInterestTypeError";
    }
}
exports.InvalidInterestTypeError = InvalidInterestTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var InterestType;
(function (InterestType_1) {
    let InterestType;
    (function (InterestType) {
        InterestType["SIMPLE"] = "SIMPLE";
        InterestType["CUMULATIVE"] = "CUMULATIVE";
    })(InterestType = InterestType_1.InterestType || (InterestType_1.InterestType = {}));
    InterestType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "SIMPLE":
                return InterestType.SIMPLE;
            case "CUMULATIVE":
                return InterestType.CUMULATIVE;
            default:
                throw new InvalidInterestTypeError(`Case ${dto.case} is not valid case of InterestType`);
        }
    };
    InterestType_1.toDTO = (interestType) => {
        const ret = {
            case: InterestType[interestType],
        };
        return ret;
    };
    InterestType_1.isInstanceOf = (other) => {
        if (other in InterestType) {
            return true;
        }
        return false;
    };
})(InterestType = exports.InterestType || (exports.InterestType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
