import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMFConfirmationRequestDetailsStore } from "../store/confirmationRequestDetailsStore/hooks";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { ConfirmationRequestDetailsPageErrors } from "../store/confirmationRequestDetailsStore/ConfirmationRequestDetailsErrorStore";
import { ConfirmationRequestDetailSection } from "../components/ConfirmationRequestDetailSection";
import { HeaderButton } from "../../../components/page-header/HeaderButton";
import { HeaderContainer } from "../../../components/page-header/HeaderContainer";
import { CheckResponse } from "@khazana/khazana-rpcs";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { createServerNoteRPCType } from "../../../../../utils";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { FolioCreationDialog } from "../components/FolioCreationDialog";
import { getDiffDetailCellValue } from "../../../utils/DiffDetailCellUtils";
import { MFConfirmationDetailsKeys } from "../models/MFConfirmationDetailsKeys";
import { MFLinkDealRequestDialog } from "../components/MFLinkDealRequestDialog";

const MFConfirmationRequestDetailsStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    body: {
      gap: spacing.space2XL,
      padding: spacing.space2XL,
      minWidth: "calc(100vw - 256px)",
    },
  };
};

export const MFConfirmationRequestDetails = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const requestId = searchParam.get("requestId");
  const store = useMFConfirmationRequestDetailsStore();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<
    "Approve" | "Reject" | "Unknown" | "Link"
  >("Reject");
  const [dealDialogOpen, setDealDialogOpen] = useState(false);
  const [isFolioDialogOpen, setIsFolioDialogOpen] = useState(false);
  const style = useMemo(() => MFConfirmationRequestDetailsStyle(spacing), []);
  const [selectedDealRequestId, setSelectedDealRequestId] = useState<number>();
  const getData = useCallback(() => {
    const id = Number(requestId);
    store.getMFConfirmationRequestDetails(id);
  }, []);

  useEffect(() => {
    getData();
    return (): void => {
      store.reset();
    };
  }, []);

  const getErrorDialogText = (): string => {
    switch (store.errorStore.error) {
      case ConfirmationRequestDetailsPageErrors.InvalidRequestId:
        return t("common.invalidDealRequestId");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      let title = "";
      let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
      if (dialogType === "Approve") {
        title = t("mf.confirmationRequest.approveConfirmationRequestTitle");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.checkMfConfirmationRequest(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
              createServerNoteRPCType(note),
            ),
            Number(requestId),
          );
        };
      } else if (dialogType === "Reject") {
        title = t("mf.confirmationRequest.rejectConfirmationRequestTitle");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          if (store.actions.allowFolioCreationCheck) {
            await store.checkMFFolioCreationRequest(
              new CheckResponse(
                CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                createServerNoteRPCType(note),
              ),
              Number(requestId),
            );
          } else {
            await store.checkMfConfirmationRequest(
              new CheckResponse(
                CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                createServerNoteRPCType(note),
              ),
              Number(requestId),
            );
          }
        };
      } else if (dialogType === "Unknown") {
        title = t("contractNotes.markContractNoteAsUnknown");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.submitMFConfirmationUnknownRequest(
            Number(requestId),
            note,
          );
        };
      } else if (dialogType === "Link") {
        title = t("contractNotes.linkContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.linkDealStore.submitMFConfirmationLinkRequest(
            Number(requestId),
            selectedDealRequestId,
            note,
          );
        };
      }
      return (
        <TextFieldDialog
          title={title}
          onClose={(): void => {
            setIsDialogOpen(false);
            if (dialogType === "Link") {
              setDealDialogOpen(true);
            }
          }}
          isOpen={isDialogOpen}
          primaryButtonType={dialogType}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            await onPrimaryButtonClick(note);
            setIsDialogOpen(false);
            await store.getMFConfirmationRequestDetails(Number(requestId));
          }}
          isCloseIconPresent={!store.isLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const unknownButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("contractNotes.markAsUnknown")}
        onClick={async (): Promise<void> => {
          setDialogType("Unknown");
          setIsDialogOpen(true);
        }}
        buttonType="red-outlined"
      />
    );
  };

  const checkButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.approve"),
          onClick: async (): Promise<void> => {
            if (store.actions.allowFolioCreationCheck) {
              setIsFolioDialogOpen(true);
            } else {
              setDialogType("Approve");
              setIsDialogOpen(true);
            }
          },
          buttonType: "outlined",
        }}
        secondaryButton={{
          label: t("contractNotes.reject"),
          onClick: async (): Promise<void> => {
            setDialogType("Reject");
            setIsDialogOpen(true);
          },
          buttonType: "outlined",
        }}
      />
    );
  };

  const linkButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.link"),
          onClick: async (): Promise<void> => {
            setDealDialogOpen(true);
          },
          buttonType: "filled",
        }}
        secondaryButton={{
          label: t("contractNotes.markAsUnknown"),
          onClick: async (): Promise<void> => {
            setDialogType("Unknown");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
      />
    );
  };

  const getButtons = (): React.ReactElement | undefined => {
    if (store.actions.allowUnknownRequest && !store.actions.allowLinkRequest) {
      return unknownButton();
    }
    if (
      store.actions?.allowContractCheck ||
      store.actions.allowLinkCheck ||
      store.actions.allowUnknownCheck ||
      store.actions.allowFolioCreationCheck
    ) {
      return checkButtons();
    } else if (store.actions.allowLinkRequest) {
      return linkButtons();
    }
  };

  const getDealDialog = (): React.ReactElement => {
    return (
      <MFLinkDealRequestDialog
        onClose={(): void => {
          setSelectedDealRequestId(undefined);
          setDealDialogOpen(false);
        }}
        isOpen={dealDialogOpen}
        onContinue={(dealRequestId): void => {
          setDealDialogOpen(false);
          setDialogType("Link");
          setSelectedDealRequestId(dealRequestId);
          setIsDialogOpen(true);
        }}
        selectedDealRequestId={selectedDealRequestId}
      />
    );
  };

  const getFolioDialog = (): React.ReactElement => {
    return (
      <FolioCreationDialog
        isRequest={false}
        onClose={() => setIsFolioDialogOpen(false)}
        isOpen={isFolioDialogOpen}
        onPrimaryButtonClick={async (
          note: string | undefined,
        ): Promise<void> => {
          await store.checkMFFolioCreationRequest(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
              createServerNoteRPCType(note),
            ),
            Number(requestId),
          );
          setIsFolioDialogOpen(false);
          await store.getMFConfirmationRequestDetails(Number(requestId));
        }}
        amcName={
          getDiffDetailCellValue(store.details, MFConfirmationDetailsKeys.amc)
            ?.value as string | undefined
        }
        entityName={
          getDiffDetailCellValue(
            store.details,
            MFConfirmationDetailsKeys.entity,
          )?.value as string | undefined
        }
        folioNumber={
          getDiffDetailCellValue(
            store.details,
            MFConfirmationDetailsKeys.folioNumber,
          )?.value as string | undefined
        }
      />
    );
  };

  return (
    <Stack>
      {isFolioDialogOpen && getFolioDialog()}
      {dealDialogOpen && getDealDialog()}
      {isDialogOpen && getDialog()}
      <PageHeader
        title={t("mf.confirmationRequest.pageTitle")}
        actionElement={getButtons()}
      />
      <Stack sx={style.body}>
        <Breadcrumb
          links={[
            {
              label: t("mf.manageConfirmationRequests.pageTitle"),
              onLabelClick: (): void => {
                navigate(getPath(Module.MF, Route.ManageConfirmationRequests));
              },
            },
          ]}
          currentLabel={t("mf.confirmationRequest.pageTitle")}
        />
        {store.isLoading ? (
          <LoadingIndicator isLoading={store.isLoading} />
        ) : (
          <ConfirmationRequestDetailSection />
        )}
        {store.errorStore.error !== undefined && (
          <ErrorDialog
            errorMessage={getErrorDialogText()}
            isErrorDialogOpen={store.errorStore.error !== undefined}
            onClose={(): void => {
              if (
                store.errorStore.error ===
                ConfirmationRequestDetailsPageErrors.InvalidRequestId
              ) {
                navigate(-1);
              }
              store.errorStore.reset();
            }}
          />
        )}
      </Stack>
    </Stack>
  );
});
