"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiSellShowYtmRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const fiYieldType_1 = require("./fiYieldType");
/* eslint-disable import/export */
class GetFiSellShowYtmRPC {
}
exports.GetFiSellShowYtmRPC = GetFiSellShowYtmRPC;
(function (GetFiSellShowYtmRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiSellShowYtmRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(isin) {
            this.isGetFiSellShowYtmRPCRequest = true;
            Request.validateIsin(isin);
            this.isin = isin;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            return new Request(isin);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
            };
            return ret;
        }
        copy(isin = this.isin) {
            return new Request(isin);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiSellShowYtmRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            return true;
        }
    }
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    GetFiSellShowYtmRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiSellShowYtmRPC.Request(isin=${this.isin})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiSellShowYtmRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(yieldType) {
            this.isGetFiSellShowYtmRPCResponse = true;
            Response.validateYieldType(yieldType);
            this.yieldType = yieldType;
        }
        static fromDTO(dto) {
            const yieldType = fiYieldType_1.FiYieldType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yieldType", InvalidResponseError));
            return new Response(yieldType);
        }
        toDTO() {
            const ret = {
                yieldType: fiYieldType_1.FiYieldType.toDTO(this.yieldType),
            };
            return ret;
        }
        copy(yieldType = this.yieldType) {
            return new Response(yieldType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiSellShowYtmRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.yieldType !== other.yieldType) {
                return false;
            }
            return true;
        }
    }
    Response.validateYieldType = (yieldType) => {
        if (!fiYieldType_1.FiYieldType.isInstanceOf(yieldType)) {
            throw new InvalidResponseError(`Attribute yieldType is not a FiYieldType.FiYieldType`);
        }
    };
    GetFiSellShowYtmRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiSellShowYtmRPC.Response(yieldType=${this.yieldType})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetFiSellShowYtmRPC.INVALID_ISIN()`;
        };
        class NeitherMaturityNorNextOptionDateFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                super(code);
                this.isNeitherMaturityNorNextOptionDateFound = true;
                NeitherMaturityNorNextOptionDateFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
                if (!(other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NeitherMaturityNorNextOptionDateFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NeitherMaturityNorNextOptionDateFound = NeitherMaturityNorNextOptionDateFound;
        NeitherMaturityNorNextOptionDateFound.prototype.toString = function toString() {
            return `GetFiSellShowYtmRPC.NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND":
                    return NeitherMaturityNorNextOptionDateFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
            if (other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiSellShowYtmRPC.Errors || (GetFiSellShowYtmRPC.Errors = {}));
})(GetFiSellShowYtmRPC = exports.GetFiSellShowYtmRPC || (exports.GetFiSellShowYtmRPC = {}));
