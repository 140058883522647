import React, { useEffect } from "react";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useDataVisualizationPalette } from "../../../../theme/color-tokens/lightColorTokens";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import * as echarts from "echarts";
import { Stack } from "@mui/material";
import {
  CRORE,
  getFormattedAmountString,
  getFormattedDate,
} from "../../../../utils";
import { useTranslation } from "react-i18next";

const Size = {
  chart: {
    strokeWidth: 2,
  },
  graphHeight: "346px",
  legendSize: 17,
  legendRadius: 4,
};

export interface DateNumber {
  x: Date;
  y: number;
}

export interface DataSeries {
  data: DateNumber[];
  name: string;
}

export interface LineChartProps {
  series: DataSeries[];
}

export const LineChart = ({ series }: LineChartProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();
  const dataVisualisation = useDataVisualizationPalette();
  const { t } = useTranslation();

  useEffect(() => {
    const chart = echarts.init(
      document.getElementById("lineChart") as HTMLDivElement,
    );
    chart.setOption({
      tooltip: {
        trigger: "axis",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (params: any[]): string => {
          const date = getFormattedDate(new Date(params[0].axisValue));
          return (
            `<span style="font-size:${typography.s2.fontSize};font-family: ${typography.s2.fontFamily}; font-weight:${typography.s2.fontWeight};color:${tokens.label}">` +
            date +
            `</span>` +
            params
              .map(
                (p) =>
                  `<span style="display: flex;flex-direction:column">` +
                  `<p style="font-size:${typography.b2.fontSize};font-family: ${typography.b2.fontFamily}; font-weight:${typography.b2.fontWeight};color:${tokens.labelSubtle}">` +
                  `<span style="display: flex;justify-content:space-between">` +
                  `<span style="display: flex;align-items:center;margin-right:${cornerRadius.radiusXS}">` +
                  `<span style="margin-right:${cornerRadius.radiusXS};border-radius:${cornerRadius.radiusXXS};width:20px;height:20px;background-color:${p.color};"></span>` +
                  p.seriesName +
                  ": " +
                  `</span>` +
                  getFormattedAmountString(p.value, 2, 2) +
                  "</span>" +
                  "</p>" +
                  "</span>",
              )
              .join("")
          );
        },
      },
      legend: {
        data: series.map((s) => s.name),
        bottom: 0,
        itemWidth: Size.legendSize,
        itemHeight: Size.legendSize,
        lineStyle: {
          width: 0,
          inactiveWidth: 0,
        },
        textStyle: {
          fontFamily: typography.b2.fontFamily,
          fontSize: typography.b2.fontSize,
          color: tokens.labelSubtle,
          fontWeight: typography.b2.fontWeight,
        },
      },
      grid: {
        top: 20,
        left: "3%",
        right: "4%",
        bottom: 40,
        containLabel: true,
      },
      lineStyle: {
        width: spacing.spaceXXS,
      },
      textStyle: {
        fontFamily: typography.b2.fontFamily,
        fontSize: typography.b2.fontSize,
        color: tokens.labelSubtle,
        fontWeight: typography.b2.fontWeight,
      },
      color: [
        dataVisualisation.one,
        dataVisualisation.two,
        dataVisualisation.three,
        dataVisualisation.four,
        dataVisualisation.five,
        dataVisualisation.six,
        dataVisualisation.seven,
        dataVisualisation.eight,
        dataVisualisation.nine,
        dataVisualisation.ten,
      ],
      xAxis: {
        type: "category",
        data: series[0].data.map((d) => d.x.toDateString()),
        axisLabel: {
          formatter(value: string): string {
            return getFormattedDate(new Date(value), "DD MMM'YY");
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter(value: number): string {
            return t("common.croreSuffix", {
              val: getFormattedAmountString(value / CRORE),
            });
          },
        },
      },
      series: series.map((s) => {
        return {
          name: s.name,
          type: "line",
          symbol: "roundRect",
          showSymbol: false,
          data: s.data.map((d) => d.y),
        };
      }),
    });
  }, [series]);

  return (
    <Stack
      id="lineChart"
      sx={{
        width: "100%",
        height: Size.graphHeight,
        flexGrow: 1,
      }}
    />
  );
};
