"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFSchemeType = exports.InvalidMFSchemeTypeError = void 0;
class InvalidMFSchemeTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFSchemeTypeError";
    }
}
exports.InvalidMFSchemeTypeError = InvalidMFSchemeTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFSchemeType;
(function (MFSchemeType_1) {
    let MFSchemeType;
    (function (MFSchemeType) {
        MFSchemeType["FUND_OF_FUNDS"] = "FUND_OF_FUNDS";
        MFSchemeType["INDEX_FUNDS"] = "INDEX_FUNDS";
        MFSchemeType["HYBRID"] = "HYBRID";
        MFSchemeType["SOLUTION_ORIENTED"] = "SOLUTION_ORIENTED";
        MFSchemeType["DEBT"] = "DEBT";
        MFSchemeType["EQUITY"] = "EQUITY";
    })(MFSchemeType = MFSchemeType_1.MFSchemeType || (MFSchemeType_1.MFSchemeType = {}));
    MFSchemeType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "FUND_OF_FUNDS":
                return MFSchemeType.FUND_OF_FUNDS;
            case "INDEX_FUNDS":
                return MFSchemeType.INDEX_FUNDS;
            case "HYBRID":
                return MFSchemeType.HYBRID;
            case "SOLUTION_ORIENTED":
                return MFSchemeType.SOLUTION_ORIENTED;
            case "DEBT":
                return MFSchemeType.DEBT;
            case "EQUITY":
                return MFSchemeType.EQUITY;
            default:
                throw new InvalidMFSchemeTypeError(`Case ${dto.case} is not valid case of MFSchemeType`);
        }
    };
    MFSchemeType_1.toDTO = (mFSchemeType) => {
        const ret = {
            case: MFSchemeType[mFSchemeType],
        };
        return ret;
    };
    MFSchemeType_1.isInstanceOf = (other) => {
        if (other in MFSchemeType) {
            return true;
        }
        return false;
    };
})(MFSchemeType = exports.MFSchemeType || (exports.MFSchemeType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
