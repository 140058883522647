import React, { useState } from "react";
import {
  Button,
  Icon,
  Table,
  TableHeader,
  TableRowItems,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../../../../utils";
import { useUserStore } from "../../../store/hooks";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { MFUserPrivileges } from "../../../../user/UserPrivileges";
import { useBorder } from "../../../../../utils/BorderUtils";
import {
  MFDealRequestOrderModel,
  OrderType,
} from "../models/MFDealRequestOrderModel";
import { MFOrderStatus } from "@khazana/khazana-rpcs";
import {
  MFOrderDetailModel,
  MFOrderDetailType,
} from "../models/MFOrderDetailModel";

export interface MFDealRequestOrderSectionProps {
  orderSectionDetails: Instance<typeof MFDealRequestOrderModel>;
  isEditable: boolean;
  onEditOrderButtonClick: () => Promise<void>;
}

const Size = {
  container: "100%",
  // This is a specific case where width values are expected to be a integer
  icon: 16,
  header: {
    name: "240px",
    requestedAmount: "200px",
    executedAmount: "200px",
    referenceNumber: "240px",
    status: "236px",
    action: "56px",
  },
};

export const MFDealRequestOrderSection = ({
  orderSectionDetails,
  isEditable,
  onEditOrderButtonClick,
}: MFDealRequestOrderSectionProps): React.ReactElement => {
  const { t } = useTranslation();
  const userPrivileges = useUserStore().privileges;
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const navigate = useNavigate();
  const typography = useTypography();
  const spacing = useSpacing();
  const [isEditButtonLoading, setIsEditButtonLoading] = useState(false);

  const getStatus = (
    status: MFOrderStatus.MFOrderStatus | undefined,
  ): string => {
    if (status === MFOrderStatus.MFOrderStatus.LINKED) {
      return t("common.linked");
    } else if (status === MFOrderStatus.MFOrderStatus.LINKING_PENDING) {
      return t("common.linkingPending");
    } else {
      return "-";
    }
  };

  const getAmountUnits = (
    order: Instance<typeof MFOrderDetailModel> | undefined,
  ): string => {
    if (order?.type === MFOrderDetailType.AMOUNT) {
      return t("common.decimal2", { val: order?.amountUnits });
    } else if (order?.type === "UNITS") {
      return t("common.decimal4", { val: order?.amountUnits });
    } else {
      console.error(
        `No requested amount or quantity found. This should not happen since broker details should include quantity or amount.`,
      );
      return "-";
    }
  };

  const getDate = (
    order: Instance<typeof MFOrderDetailModel> | undefined,
  ): string => {
    if (order?.date) {
      return getFormattedDate(order.date);
    } else {
      return "-";
    }
  };

  const getTableOptions = async (): Promise<string | TableRowItems> => {
    if (orderSectionDetails.orderType === OrderType.BROKER) {
      return orderSectionDetails.brokerOrders.map((orders) => {
        return [
          { data: orders.broker?.name ?? "-" },
          {
            data: getAmountUnits(orders.requestDetail),
            align: "right",
          },
          {
            data: orders.requestDetail?.date
              ? getFormattedDate(orders.requestDetail.date)
              : "-",
          },
          {
            data: getAmountUnits(orders.executedDetail),
            align: "right",
          },
          {
            data: orders.executedDetail?.date
              ? getFormattedDate(orders.executedDetail.date)
              : "-",
          },
          {
            data: orders.referenceNumber ?? "-",
          },
          {
            data: getStatus(orders.orderStatus),
          },
          {
            align: "right",
            data: (
              <Icon
                color={tokens.iconLowEmphasis}
                type="chevron-right"
                height={Size.icon}
                width={Size.icon}
              />
            ),
          },
        ];
      });
    } else {
      return orderSectionDetails.amcOrders.map((orders) => {
        return [
          {
            data: getAmountUnits(orders.requestDetail),
            align: "right",
          },
          {
            data: getDate(orders.requestDetail),
          },
          {
            data: getAmountUnits(orders.executedDetail),
            align: "right",
          },
          {
            data: getDate(orders.executedDetail),
          },
          {
            data: orders.referenceNumber ?? "-",
          },
          {
            data: getStatus(orders.orderStatus),
          },
          {
            align: "right",
            data: (
              <Icon
                color={tokens.iconLowEmphasis}
                type="chevron-right"
                height={Size.icon}
                width={Size.icon}
              />
            ),
          },
        ];
      });
    }
  };

  const getCurrencySymbol = (
    order: Instance<typeof MFOrderDetailModel> | undefined,
  ): string | undefined => {
    return order?.currency?.symbol
      ? `( ${order?.currency?.symbol} )`
      : undefined;
  };

  const getRequestedAmountQuantityTableHeader = (): string => {
    // We are using the first element to look for quantity or amount as it will be same for all the brokers.
    if (orderSectionDetails.orderType === OrderType.BROKER) {
      return orderSectionDetails.brokerOrders[0].requestDetail?.type ===
        MFOrderDetailType.AMOUNT
        ? `${t("common.requestedAmount", {
            symbol: getCurrencySymbol(
              orderSectionDetails.brokerOrders[0].requestDetail,
            ),
          })}`
        : t("common.requestedUnits");
    } else {
      return orderSectionDetails.amcOrders[0].requestDetail?.type ===
        MFOrderDetailType.AMOUNT
        ? `${t("common.requestedAmount", {
            symbol: getCurrencySymbol(
              orderSectionDetails.amcOrders[0].requestDetail,
            ),
          })}`
        : t("common.requestedUnits");
    }
  };

  const getExecutedAmountUnitsTableHeader = (): string => {
    // We are using the first element to look for quantity or amount as it will be same for all the brokers.
    if (orderSectionDetails.orderType === OrderType.BROKER) {
      return orderSectionDetails.brokerOrders[0].executedDetail?.type ===
        MFOrderDetailType.AMOUNT
        ? `${t("common.executedAmount", {
            symbol: getCurrencySymbol(
              orderSectionDetails.brokerOrders[0].executedDetail,
            ),
          })}`
        : t("common.executedUnits");
    } else {
      return orderSectionDetails.amcOrders[0].executedDetail?.type ===
        MFOrderDetailType.AMOUNT
        ? `${t("common.executedAmount", {
            symbol: getCurrencySymbol(
              orderSectionDetails.amcOrders[0].executedDetail,
            ),
          })}`
        : t("common.executedUnits");
    }
  };

  const getStatusFromIndex = (
    index: number,
  ): MFOrderStatus.MFOrderStatus | undefined => {
    if (orderSectionDetails.orderType === OrderType.BROKER) {
      return orderSectionDetails.brokerOrders[index].orderStatus;
    } else {
      return orderSectionDetails.amcOrders[index].orderStatus;
    }
  };

  const getConfirmationIdFromIndex = (index: number): number | undefined => {
    if (orderSectionDetails.orderType === OrderType.BROKER) {
      return orderSectionDetails.brokerOrders[index].confirmationId;
    } else {
      return orderSectionDetails.amcOrders[index].confirmationId;
    }
  };

  const getHeaders = (): TableHeader => {
    const headers: TableHeader = [];
    if (orderSectionDetails.orderType === OrderType.BROKER) {
      headers.push({
        id: "name",
        name: t("common.name"),
        width: Size.header.name,
      });
    }
    headers.push(
      {
        id: "requestedAmount",
        name: getRequestedAmountQuantityTableHeader(),
        width: Size.header.requestedAmount,
        align: "right",
      },
      {
        id: "requestedDate",
        name: t("common.requestedDate"),
        width: Size.header.name,
      },
      {
        id: "executedAmount",
        name: getExecutedAmountUnitsTableHeader(),
        width: Size.header.executedAmount,
        align: "right",
      },
      {
        id: "executedDate",
        name: t("common.executedDate"),
        width: Size.header.name,
      },
      {
        id: "referenceNumber",
        name: t("mf.fields.referenceNumberLabel"),
        width: Size.header.referenceNumber,
      },
      {
        id: "status",
        name: t("common.status"),
        width: Size.header.status,
      },
      { id: "action", name: "", width: Size.header.action },
    );
    return headers;
  };

  return (
    <Stack
      sx={{
        width: "100%",
        border: border.default,
        borderRadius: cornerRadius.radiusXS,
      }}
    >
      <Stack
        sx={{
          background: tokens.background,
          padding: `${spacing.spaceSM} ${spacing.spaceXL}`,
          borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
          borderBottom: border.default,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            ...typography.sh3,
          }}
        >
          {orderSectionDetails.orderType === OrderType.BROKER
            ? t("mf.dealDetails.brokerOrdersTitle")
            : t("mf.dealDetails.amcOrdersTitle")}
        </Typography>
        {isEditable && (
          <Button
            name={"editBrokerDetail"}
            size={"small"}
            loading={isEditButtonLoading}
            variant={"plain-color"}
            label={t("mf.dealDetails.editOrderDetailsButtonTitle")}
            onClick={async (): Promise<void> => {
              setIsEditButtonLoading(true);
              await onEditOrderButtonClick();
              setIsEditButtonLoading(false);
            }}
            style={{ padding: `${spacing.spaceXS} ${spacing.spaceSM}` }}
          />
        )}
      </Stack>
      <Table
        name={"mfDealRequestOrderSection"}
        headers={getHeaders()}
        onTableOptionsChange={getTableOptions}
        viewOverrides={{
          empty: { message: t("common.noResultsFound") },
          idle: { message: t("common.searchTableIdleState") },
          loading: { message: t("common.searchTableLoadingState") },
        }}
        styleOverrides={{
          divider: "cell",
          background: tokens.background,
          borderRadius: `0 0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS}`,
          border: "0",
        }}
        onRowClick={(_, index): void => {
          if (
            userPrivileges.includes(
              MFUserPrivileges.ViewMutualFundConfirmationRequest,
            ) &&
            getStatusFromIndex(index) === MFOrderStatus.MFOrderStatus.LINKED
          ) {
            const confirmationId = getConfirmationIdFromIndex(index);
            if (confirmationId) {
              navigate(
                getPath(
                  Module.MF,
                  Route.ManageConfirmationRequestsDetailsWithParams,
                  confirmationId.toString(),
                ),
              );
            }
          }
        }}
      />
    </Stack>
  );
};
