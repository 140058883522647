import React, { RefObject } from "react";
import {
  Table,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableRowItems,
  TableSortOption,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  CellAlignment,
  TableHeader,
} from "@surya-digital/leo-reactjs-core/dist/table/Table";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { getFormattedAmountString } from "../../../../utils";
import { observer } from "mobx-react";

export interface ReportTableData {
  rows: (string | number | React.ReactElement | undefined)[][];
  totalItems: number;
}

export interface ReportTableHeaderProps {
  name: string;
  sortable?: boolean;
  width?: ReportTableHeaderWidth;
  alignment?: CellAlignment;
}

export interface ReportTableProps {
  name: string;
  headers: ReportTableHeaderProps[];
  itemsPerPage: number;
  getRows: (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ) => Promise<ReportTableData>;
  tableRef: RefObject<TableReloadHandle>;
}

export enum ReportTableHeaderWidth {
  TableWidthMD = "160px",
  TableWidthLG = "180px",
  TableWidthXL = "344px",
}

export const ReportTable = observer(
  ({
    name,
    headers,
    itemsPerPage,
    getRows,
    tableRef,
  }: ReportTableProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();

    const getWidth = (header: ReportTableHeaderProps): string => {
      if (header.width) {
        return header.width;
      } else if (header.sortable) {
        return ReportTableHeaderWidth.TableWidthLG;
      } else {
        return ReportTableHeaderWidth.TableWidthMD;
      }
    };

    const tableHeader: TableHeader = headers.map((header) => {
      const width = getWidth(header);
      return {
        id: header.name,
        name: header.name,
        width,
        align: header.alignment,
        sortable: header.sortable,
      };
    });

    const getRowValue = (item: string | number | undefined): string => {
      if (typeof item === "string") {
        return item;
      } else if (typeof item === "number") {
        return getFormattedAmountString(item);
      } else {
        return "-";
      }
    };

    const getTableOptions = async (
      option: TableOptions,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItems> => {
      const { rows, totalItems } = await getRows(
        option.page ? option.page - 1 : 0,
        itemsPerPage,
        option.sort,
      );
      setTotalItems(totalItems);
      return rows.map((row, rowIndex): TableRowItem => {
        if (rowIndex === rows.length - 1 && row[length - 1] === "Grand Total") {
          return row.map((item, colIndex) => {
            if (
              typeof item === "string" ||
              typeof item === "number" ||
              item === undefined
            ) {
              return {
                data: (
                  <Typography {...typography.sh4}>
                    {getRowValue(item)}
                  </Typography>
                ),
                align: headers[colIndex].alignment,
              };
            } else {
              return {
                data: item,
                align: headers[colIndex].alignment,
              };
            }
          });
        } else {
          return row.map((item, colIndex) => {
            if (
              typeof item === "string" ||
              typeof item === "number" ||
              item === undefined
            ) {
              return {
                data: getRowValue(item),
                align: headers[colIndex].alignment,
              };
            } else {
              return {
                data: item,
                align: headers[colIndex].alignment,
              };
            }
          });
        }
      });
    };

    return (
      <Table
        ref={tableRef}
        name={name}
        headers={tableHeader}
        paginationOption={{
          itemsPerPage,
          getPageIndicatorText(startItem, endItem, totalItems): string {
            return t("common.paginationIndicationText", {
              startItem,
              endItem,
              totalItems,
            });
          },
        }}
        styleOverrides={{
          divider: "cell",
        }}
        onTableOptionsChange={getTableOptions}
        viewOverrides={{
          empty: { message: t("common.noDataFound") },
          loading: { message: t("reports.fetchingData") },
        }}
      />
    );
  },
);
