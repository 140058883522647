import { types } from "mobx-state-tree";
import { DetailCell, DetailCellType } from "@khazana/khazana-rpcs";

export interface DetailCellView {
  label: string;
  cellType: DetailCellType.DetailCellType;
}

let localizedLabel: string;
export const DetailType = types.custom<DetailCell, DetailCellView>({
  name: "DetailType",
  fromSnapshot(snapshot: DetailCell): DetailCellView {
    localizedLabel = snapshot.localizedTextId;
    return {
      label: snapshot.localizedTextId,
      cellType: snapshot.cellType,
    };
  },
  toSnapshot(value: DetailCellView): DetailCell {
    return new DetailCell(localizedLabel, value.cellType);
  },
  isTargetType(value: DetailCell | DetailCell): boolean {
    return !(value instanceof DetailCell);
  },
  getValidationMessage(_snapshot: DetailCell): string {
    return "";
  },
});
