"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MfAMCOrderDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mFOrderDetail_1 = require("./mFOrderDetail");
const mFOrderStatus_1 = require("./mFOrderStatus");
class InvalidMfAMCOrderDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMfAMCOrderDetailError";
    }
}
class MfAMCOrderDetail {
    constructor(confirmationId = null, requestDetail, executedDetail = null, orderStatus = null, referenceNumber = null) {
        this.isMfAMCOrderDetail = true;
        if (confirmationId !== undefined && confirmationId !== null) {
            MfAMCOrderDetail.validateConfirmationId(confirmationId);
        }
        this.confirmationId = confirmationId;
        MfAMCOrderDetail.validateRequestDetail(requestDetail);
        this.requestDetail = requestDetail;
        if (executedDetail !== undefined && executedDetail !== null) {
            MfAMCOrderDetail.validateExecutedDetail(executedDetail);
        }
        this.executedDetail = executedDetail;
        if (orderStatus !== undefined && orderStatus !== null) {
            MfAMCOrderDetail.validateOrderStatus(orderStatus);
        }
        this.orderStatus = orderStatus;
        if (referenceNumber !== undefined && referenceNumber !== null) {
            MfAMCOrderDetail.validateReferenceNumber(referenceNumber);
        }
        this.referenceNumber = referenceNumber;
    }
    static fromDTO(dto) {
        const confirmationId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "confirmationId", InvalidMfAMCOrderDetailError);
        const requestDetail = mFOrderDetail_1.MFOrderDetail.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestDetail", InvalidMfAMCOrderDetailError));
        let executedDetail = (0, leo_ts_runtime_1.getOptionalObject)(dto, "executedDetail");
        if (executedDetail !== undefined && executedDetail !== null) {
            executedDetail = mFOrderDetail_1.MFOrderDetail.fromDTO(executedDetail);
        }
        let orderStatus = (0, leo_ts_runtime_1.getOptionalObject)(dto, "orderStatus");
        if (orderStatus !== undefined && orderStatus !== null) {
            orderStatus = mFOrderStatus_1.MFOrderStatus.fromDTO(orderStatus);
        }
        const referenceNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "referenceNumber", InvalidMfAMCOrderDetailError);
        return new MfAMCOrderDetail(confirmationId, requestDetail, executedDetail, orderStatus, referenceNumber);
    }
    toDTO() {
        const ret = {
            requestDetail: this.requestDetail.toDTO(),
        };
        if (this.confirmationId !== undefined && this.confirmationId !== null) {
            ret.confirmationId = this.confirmationId;
        }
        if (this.executedDetail) {
            ret.executedDetail = this.executedDetail.toDTO();
        }
        if (this.orderStatus !== undefined && this.orderStatus !== null) {
            ret.orderStatus = mFOrderStatus_1.MFOrderStatus.toDTO(this.orderStatus);
        }
        if (this.referenceNumber !== undefined && this.referenceNumber !== null) {
            ret.referenceNumber = this.referenceNumber;
        }
        return ret;
    }
    copy(confirmationId = this.confirmationId, requestDetail = this.requestDetail, executedDetail = this.executedDetail, orderStatus = this.orderStatus, referenceNumber = this.referenceNumber) {
        return new MfAMCOrderDetail(confirmationId, requestDetail, executedDetail, orderStatus, referenceNumber);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMfAMCOrderDetailValue = other.isMfAMCOrderDetail;
        if (!(other instanceof MfAMCOrderDetail) || Boolean(isMfAMCOrderDetailValue)) {
            return false;
        }
        if (this.confirmationId !== other.confirmationId) {
            return false;
        }
        if (!this.requestDetail.equals(other.requestDetail)) {
            return false;
        }
        if (this.executedDetail) {
            if (!this.executedDetail.equals(other.executedDetail)) {
                return false;
            }
        }
        if (this.orderStatus !== other.orderStatus) {
            return false;
        }
        if (this.referenceNumber !== other.referenceNumber) {
            return false;
        }
        return true;
    }
}
exports.MfAMCOrderDetail = MfAMCOrderDetail;
MfAMCOrderDetail.validateConfirmationId = (confirmationId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(confirmationId)) {
        throw new InvalidMfAMCOrderDetailError(`Attribute confirmationId is not an Int64`);
    }
};
MfAMCOrderDetail.validateRequestDetail = (requestDetail) => {
    if (!mFOrderDetail_1.MFOrderDetail.isInstanceOf(requestDetail)) {
        throw new InvalidMfAMCOrderDetailError(`Attribute requestDetail is not a MFOrderDetail.MFOrderDetail`);
    }
};
MfAMCOrderDetail.validateExecutedDetail = (executedDetail) => {
    if (!mFOrderDetail_1.MFOrderDetail.isInstanceOf(executedDetail)) {
        throw new InvalidMfAMCOrderDetailError(`Attribute executedDetail is not a MFOrderDetail.MFOrderDetail`);
    }
};
MfAMCOrderDetail.validateOrderStatus = (orderStatus) => {
    if (!mFOrderStatus_1.MFOrderStatus.isInstanceOf(orderStatus)) {
        throw new InvalidMfAMCOrderDetailError(`Attribute orderStatus is not a MFOrderStatus.MFOrderStatus`);
    }
};
MfAMCOrderDetail.validateReferenceNumber = (referenceNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(referenceNumber)) {
        throw new InvalidMfAMCOrderDetailError(`Attribute referenceNumber is not a String`);
    }
    if (referenceNumber.trim().length === 0) {
        throw new InvalidMfAMCOrderDetailError(`Attribute referenceNumber cannot be an empty string.`);
    }
};
MfAMCOrderDetail.prototype.toString = function toString() {
    return `MfAMCOrderDetail(confirmationId=${this.confirmationId},requestDetail=${this.requestDetail},executedDetail=${this.executedDetail},orderStatus=${this.orderStatus},referenceNumber='${this.referenceNumber}')`;
};
