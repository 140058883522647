import React from "react";
import { Stack } from "@mui/material";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import { HeaderButton, HeaderButtonProps } from "./HeaderButton";

export const HeaderContainer = ({
  primaryButton,
  secondaryButton,
}: {
  primaryButton: HeaderButtonProps;
  secondaryButton?: HeaderButtonProps;
}): React.ReactElement => {
  const spacing = useSpacing();
  return (
    <Stack direction="row" spacing={spacing.spaceXS}>
      {secondaryButton ? <HeaderButton {...secondaryButton} /> : undefined}
      <HeaderButton {...primaryButton} />
    </Stack>
  );
};
