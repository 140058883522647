"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryDetailCell = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const detailCell_1 = require("./detailCell");
class InvalidSummaryDetailCellError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidSummaryDetailCellError";
    }
}
class SummaryDetailCell {
    constructor(summary, detailList = null, isBold = null, isShownInCollapsedView) {
        this.isSummaryDetailCell = true;
        SummaryDetailCell.validateSummary(summary);
        this.summary = summary;
        if (detailList !== undefined && detailList !== null) {
            SummaryDetailCell.validateDetailList(detailList);
        }
        this.detailList = detailList;
        if (isBold !== undefined && isBold !== null) {
            SummaryDetailCell.validateIsBold(isBold);
        }
        this.isBold = isBold;
        SummaryDetailCell.validateIsShownInCollapsedView(isShownInCollapsedView);
        this.isShownInCollapsedView = isShownInCollapsedView;
    }
    static fromDTO(dto) {
        const summary = detailCell_1.DetailCell.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "summary", InvalidSummaryDetailCellError));
        const detailList = (0, leo_ts_runtime_1.getOptionalList)(dto, "detailList", detailCell_1.DetailCell.fromDTO, InvalidSummaryDetailCellError);
        const isBold = (0, leo_ts_runtime_1.getOptionalBoolean)(dto, "isBold", InvalidSummaryDetailCellError);
        const isShownInCollapsedView = (0, leo_ts_runtime_1.getBoolean)(dto, "isShownInCollapsedView", InvalidSummaryDetailCellError);
        return new SummaryDetailCell(summary, detailList, isBold, isShownInCollapsedView);
    }
    toDTO() {
        const ret = {
            summary: this.summary.toDTO(),
            isShownInCollapsedView: this.isShownInCollapsedView,
        };
        if (this.detailList) {
            ret.detailList = this.detailList.map((e) => {
                return e.toDTO();
            });
        }
        if (this.isBold !== undefined && this.isBold !== null) {
            ret.isBold = this.isBold;
        }
        return ret;
    }
    copy(summary = this.summary, detailList = this.detailList, isBold = this.isBold, isShownInCollapsedView = this.isShownInCollapsedView) {
        return new SummaryDetailCell(summary, detailList, isBold, isShownInCollapsedView);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isSummaryDetailCellValue = other.isSummaryDetailCell;
        if (!(other instanceof SummaryDetailCell) || Boolean(isSummaryDetailCellValue)) {
            return false;
        }
        if (!this.summary.equals(other.summary)) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.detailList, other.detailList)) {
            return false;
        }
        if (this.isBold !== other.isBold) {
            return false;
        }
        if (this.isShownInCollapsedView !== other.isShownInCollapsedView) {
            return false;
        }
        return true;
    }
}
exports.SummaryDetailCell = SummaryDetailCell;
SummaryDetailCell.validateSummary = (summary) => {
    const isDetailCellValue = summary.isDetailCell;
    if (!(summary instanceof detailCell_1.DetailCell || Boolean(isDetailCellValue))) {
        throw new InvalidSummaryDetailCellError(`Attribute summary is not a DetailCell`);
    }
};
SummaryDetailCell.validateDetailList = (detailList) => {
    if (!Array.isArray(detailList)) {
        throw new InvalidSummaryDetailCellError(`Attribute detailList is not a List`);
    }
    for (let i = 0; i < detailList.length; i += 1) {
        const isDetailCellValue = detailList[i].isDetailCell;
        if (!(detailList[i] instanceof detailCell_1.DetailCell || Boolean(isDetailCellValue))) {
            throw new InvalidSummaryDetailCellError(`Attribute detailList at index ${i} is not a DetailCell.`);
        }
    }
};
SummaryDetailCell.validateIsBold = (isBold) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isBold)) {
        throw new InvalidSummaryDetailCellError(`Attribute isBold is not a Boolean`);
    }
};
SummaryDetailCell.validateIsShownInCollapsedView = (isShownInCollapsedView) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isShownInCollapsedView)) {
        throw new InvalidSummaryDetailCellError(`Attribute isShownInCollapsedView is not a Boolean`);
    }
};
SummaryDetailCell.prototype.toString = function toString() {
    return `SummaryDetailCell(summary=${this.summary},detailList=${this.detailList},isBold=${this.isBold},isShownInCollapsedView=${this.isShownInCollapsedView})`;
};
