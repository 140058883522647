import { CreateEditMFDealRequestOrderRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCreateEditMFDealRequestOrderRPCImpl extends CreateEditMFDealRequestOrderRPC {
  execute(
    _request: CreateEditMFDealRequestOrderRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateEditMFDealRequestOrderRPC.Response,
      CreateEditMFDealRequestOrderRPC.Errors.Errors
    >
  > {
    const response = new CreateEditMFDealRequestOrderRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateEditMFDealRequestOrderRPC.Response,
        CreateEditMFDealRequestOrderRPC.Errors.Errors
      >
    >;
  }
}
