import { FiEditAccrualRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiEditAccrualRPCImpl extends FiEditAccrualRPC {
  execute(
    _request: FiEditAccrualRPC.Request,
  ): Promise<
    LeoRPCResult<FiEditAccrualRPC.Response, FiEditAccrualRPC.Errors.Errors>
  > {
    const response = new FiEditAccrualRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<FiEditAccrualRPC.Response, FiEditAccrualRPC.Errors.Errors>
    >;
  }
}
