import {
  Currency,
  GetFiCashflowDetailRPC,
  AccrualItem,
  BigAmount,
  BigDecimal,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiCashflowDetailRPCImpl extends GetFiCashflowDetailRPC {
  execute(
    _request: GetFiCashflowDetailRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiCashflowDetailRPC.Response,
      GetFiCashflowDetailRPC.Errors.Errors
    >
  > {
    const accrualList: AccrualItem[] = [
      new AccrualItem(
        1,
        "Coupon Expected",
        new LeoDate(),
        new BigAmount(new BigDecimal("1444.23"), new Currency("INR", "₹")),
      ),
    ];
    const response = new GetFiCashflowDetailRPC.Response(
      new LeoUUID("38385beb-7066-41cf-8233-24b343e78f9b"),
      "HDFC Bank",
      "7363130238873",
      new BigAmount(new BigDecimal("1444.23"), new Currency("INR", "₹")),
      accrualList,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiCashflowDetailRPC.Response,
        GetFiCashflowDetailRPC.Errors.Errors
      >
    >;
  }
}
