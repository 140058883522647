import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createMFConfirmationActionModel,
  MFConfirmationActionModel,
} from "../../models/MFConfirmationActionModel";
import {
  ConfirmationRequestDetailsErrorStore,
  createConfirmationRequestDetailsErrorStore,
} from "./ConfirmationRequestDetailsErrorStore";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CheckMfConfirmationRequestRPC,
  CheckMFFolioCreationRequestRPC,
  CheckResponse,
  ContractNoteCheckType,
  GetMFConfirmationRequestDetailsRPC,
  MFConfirmationRequestStatus,
  Note,
  SubmitMFConfirmationUnknownRequestRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useCheckMfConfirmationRequestRPC,
  useCheckMFFolioCreationRequestRPC,
  useGetMFConfirmationRequestDetailsRPC,
  useSubmitMFConfirmationUnknownRequestRPC,
} from "../../rpc/RPC";
import { DiffDetailType } from "../../../../models/DiffDetailType";
import {
  getMFConfirmationRequestStatusValue,
  getMFConfirmationStatusBackgroundColor,
} from "../../utils/UIUtils";
import { ChipColor } from "@surya-digital/leo-reactjs-material-ui";
import {
  ConfirmationRequestHistoryStore,
  createConfirmationRequestHistoryStore,
} from "./ConfirmationRequestHistoryStore";
import {
  createMFLinkDealRequestStore,
  MFLinkDealRequestStore,
} from "./MFLinkDealRequestStore";

export const ConfirmationRequestDetailsStore = types
  .model("ConfirmationRequestDetailsStore", {
    isLoading: types.optional(types.boolean, false),
    details: types.array(DiffDetailType),
    requestStatus: types.maybe(
      types.enumeration(
        Object.values(MFConfirmationRequestStatus.MFConfirmationRequestStatus),
      ),
    ),
    confirmationDocumentURL: types.maybe(types.string),
    actions: MFConfirmationActionModel,
    historyStore: ConfirmationRequestHistoryStore,
    errorStore: ConfirmationRequestDetailsErrorStore,
    linkDealStore: MFLinkDealRequestStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFConfirmationRequestDetails: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFConfirmationRequestDetailsRPC.Request(
          requestId,
        );
        store.isLoading = true;
        const result: LeoRPCResult<
          GetMFConfirmationRequestDetailsRPC.Response,
          GetMFConfirmationRequestDetailsRPC.Errors.Errors
        > =
          yield useGetMFConfirmationRequestDetailsRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.details = cast(response.requestDetails);
          store.actions = createMFConfirmationActionModel(
            response.requestAction,
          );
          store.requestStatus = response.requestStatus;
          store.confirmationDocumentURL =
            response.confirmationDocumentURL?.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          store.errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in GetMFConfirmationRequestDetailsRPC");
        }
        store.isLoading = false;
      }),
      submitMFConfirmationUnknownRequest: flow(function* (
        requestId: number,
        note?: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new SubmitMFConfirmationUnknownRequestRPC.Request(
          requestId,
          note ? new Note(note) : null,
        );
        const result: LeoRPCResult<
          SubmitMFConfirmationUnknownRequestRPC.Response,
          SubmitMFConfirmationUnknownRequestRPC.Errors.Errors
        > =
          yield useSubmitMFConfirmationUnknownRequestRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in submitMFConfirmationUnknownRequestRPC`,
              );
          }
        } else {
          logger.error(
            "Unhandled error in SubmitMFConfirmationUnknownRequestRPC",
          );
        }
      }),
      checkMfConfirmationRequest: flow(function* (
        response: CheckResponse,
        requestId: number,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        let type: ContractNoteCheckType.ContractNoteCheckType | undefined;
        if (store.actions.allowContractCheck) {
          type = ContractNoteCheckType.ContractNoteCheckType.REVIEW_REQUEST;
        } else if (store.actions.allowLinkCheck) {
          type = ContractNoteCheckType.ContractNoteCheckType.LINK_REQUEST;
        } else if (store.actions.allowUnknownCheck) {
          type = ContractNoteCheckType.ContractNoteCheckType.UNKNOWN_REQUEST;
        }
        if (!type) {
          logger.error("ContractNoteCheckType is unset");
          return;
        }
        const request = new CheckMfConfirmationRequestRPC.Request(
          type,
          requestId,
          response,
        );
        const result: LeoRPCResult<
          CheckMfConfirmationRequestRPC.Response,
          CheckMfConfirmationRequestRPC.Errors.Errors
        > = yield useCheckMfConfirmationRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          store.errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in CheckMfConfirmationRequestRPC");
        }
      }),
      checkMFFolioCreationRequest: flow(function* (
        response: CheckResponse,
        requestId: number,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new CheckMFFolioCreationRequestRPC.Request(
          requestId,
          response,
        );
        const result: LeoRPCResult<
          CheckMFFolioCreationRequestRPC.Response,
          CheckMFFolioCreationRequestRPC.Errors.Errors
        > = yield useCheckMFFolioCreationRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          store.errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in CheckMFFolioCreationRequestRPC");
        }
      }),
    };
  })
  .views((store) => {
    return {
      get requestStatusView(): { name: string; color: ChipColor } | undefined {
        if (!store.requestStatus) {
          return;
        }
        return {
          name: getMFConfirmationRequestStatusValue(store.requestStatus),
          color: getMFConfirmationStatusBackgroundColor(store.requestStatus),
        };
      },
    };
  });

export const createConfirmationRequestDetailsStore = (): Instance<
  typeof ConfirmationRequestDetailsStore
> => {
  return ConfirmationRequestDetailsStore.create({
    actions: createMFConfirmationActionModel(),
    errorStore: createConfirmationRequestDetailsErrorStore(),
    historyStore: createConfirmationRequestHistoryStore(),
    linkDealStore: createMFLinkDealRequestStore(),
  });
};
