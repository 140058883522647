"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankBranch = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidBankBranchError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBankBranchError";
    }
}
class BankBranch {
    constructor(branchId, branchName, bankName) {
        this.isBankBranch = true;
        BankBranch.validateBranchId(branchId);
        this.branchId = branchId;
        BankBranch.validateBranchName(branchName);
        this.branchName = branchName;
        BankBranch.validateBankName(bankName);
        this.bankName = bankName;
    }
    static fromDTO(dto) {
        const branchId = (0, leo_ts_runtime_1.getString)(dto, "branchId", InvalidBankBranchError);
        const branchName = (0, leo_ts_runtime_1.getString)(dto, "branchName", InvalidBankBranchError);
        const bankName = (0, leo_ts_runtime_1.getString)(dto, "bankName", InvalidBankBranchError);
        return new BankBranch(branchId, branchName, bankName);
    }
    toDTO() {
        const ret = {
            branchId: this.branchId,
            branchName: this.branchName,
            bankName: this.bankName,
        };
        return ret;
    }
    copy(branchId = this.branchId, branchName = this.branchName, bankName = this.bankName) {
        return new BankBranch(branchId, branchName, bankName);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isBankBranchValue = other.isBankBranch;
        if (!(other instanceof BankBranch) || Boolean(isBankBranchValue)) {
            return false;
        }
        if (this.branchId !== other.branchId) {
            return false;
        }
        if (this.branchName !== other.branchName) {
            return false;
        }
        if (this.bankName !== other.bankName) {
            return false;
        }
        return true;
    }
}
exports.BankBranch = BankBranch;
BankBranch.validateBranchId = (branchId) => {
    if (!(0, leo_ts_runtime_1.isString)(branchId)) {
        throw new InvalidBankBranchError(`Attribute branchId is not a String`);
    }
    if (branchId.trim().length === 0) {
        throw new InvalidBankBranchError(`Attribute branchId cannot be an empty string.`);
    }
};
BankBranch.validateBranchName = (branchName) => {
    if (!(0, leo_ts_runtime_1.isString)(branchName)) {
        throw new InvalidBankBranchError(`Attribute branchName is not a String`);
    }
    if (branchName.trim().length === 0) {
        throw new InvalidBankBranchError(`Attribute branchName cannot be an empty string.`);
    }
};
BankBranch.validateBankName = (bankName) => {
    if (!(0, leo_ts_runtime_1.isString)(bankName)) {
        throw new InvalidBankBranchError(`Attribute bankName is not a String`);
    }
    if (bankName.trim().length === 0) {
        throw new InvalidBankBranchError(`Attribute bankName cannot be an empty string.`);
    }
};
BankBranch.prototype.toString = function toString() {
    return `BankBranch(branchId='${this.branchId}',branchName='${this.branchName}',bankName='${this.bankName}')`;
};
