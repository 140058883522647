import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import { SendToBrokerEquityDealRequestStore } from "../equity/deal-request/store/SendToBrokerEquityDealRequestStore";
import {
  AutoCompleteItem,
  Button,
  Dialog,
  Icon,
  IconButton,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { Fragment, useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SendToBrokerFiDealRequestStore } from "../fixed-income/deal-request/store/SendToBrokerFiDealRequestStore";
import { getFormattedAmountString } from "../../../utils";
import { BrokerAmountDetailModel } from "../equity/deal-request/models/BrokerAmountDetailModel";
import { FiBrokerAmountDetailModel } from "../fixed-income/deal-request/models/FiBrokerAmountDetailModel";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "./AsyncAutoCompleteInputFieldSeparateLabel";
import { AmountTextField } from "./AmountTextField";
import { FileCheck } from "../../../assets/FileCheck";
import { Trash } from "../../../assets/Trash";
import { useBorder } from "../../../utils/BorderUtils";
import { AmountQuantityModel } from "../models/AmountQuantityModel";
import { WarningAlertComponent } from "./WarningAlertComponent";
import { NoteTextArea } from "./NoteTextArea";

export interface SendToBrokerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  store:
    | Instance<typeof SendToBrokerEquityDealRequestStore>
    | Instance<typeof SendToBrokerFiDealRequestStore>;
  onSuccessfulSendToBroker: () => void;
  price?: number | undefined;
  getErrorMessage: () => string | undefined;
}

const Size = {
  containerWidth: "100%",
  amountQuantityLabel: {
    container: {
      height: "32px",
    },
    height: "24px",
  },
  icon: "24px",
  addButton: "67px",
  errorBanner: "40px",
};

export const SendToBrokerDialog = observer(
  ({
    isOpen,
    onClose,
    store,
    onSuccessfulSendToBroker,
    price,
    getErrorMessage,
  }: SendToBrokerDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const border = useBorder();

    const getErrorBanner = (): React.ReactElement => {
      if (store.error) {
        const errorMessage = getErrorMessage();
        if (errorMessage) {
          return (
            <Box
              style={{
                height: Size.errorBanner,
                marginBottom: spacing.spaceLG,
                border: border.errorSubtle,
                background: tokens.backgroundErrorSubtle,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  ...typography.b1,
                  color: tokens.labelError,
                  margin: `${spacing.spaceXS} ${spacing.spaceMD}`,
                }}
              >
                {errorMessage}
              </Typography>
            </Box>
          );
        }
      }
      return <></>;
    };

    const getRequestedAmountQuantityLabel = (): React.ReactElement => {
      const requestedAmountQuantity = store.requestedAmountQuantity;
      let label: string;
      let value: string;
      if (requestedAmountQuantity) {
        if (requestedAmountQuantity.quantity === null) {
          const _symbol = requestedAmountQuantity.amount?.currency.symbol;
          label = `${t("common.requestedAmount")} (${_symbol})`;
          const amount = requestedAmountQuantity.amount?.amount;
          if (amount) {
            const formattedAmountString = getFormattedAmountString(amount);
            value = formattedAmountString;
          } else {
            value = "-";
          }
        } else {
          label = t("common.requestedQuantity");
          const formattedQuantityString = getFormattedAmountString(
            requestedAmountQuantity.quantity,
          );
          value = formattedQuantityString;
        }
        return (
          <Stack
            direction="column"
            justifyContent="space-between"
            height={Size.amountQuantityLabel.container.height}
            width={Size.containerWidth}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              height={Size.amountQuantityLabel.height}
              width={Size.containerWidth}
            >
              <Typography style={typography.s1}>{label}</Typography>
              <Typography style={typography.s1} textAlign="right">
                {value}
              </Typography>
            </Stack>
            <Divider sx={{ mt: spacing.spaceXS, color: tokens.border }} />
          </Stack>
        );
      }
      return <></>;
    };

    const getBrokerAutoCompleteField = (
      brokerValue: AutoCompleteItem | undefined,
      index: number,
      isContractNoteLinked: boolean,
    ): React.ReactElement => {
      return (
        <AsyncAutoCompleteInputFieldSeparateLabel
          id="broker"
          key={brokerValue?.label}
          label={t("common.broker")}
          value={brokerValue}
          placeholder={t("common.selectBroker")}
          options={store
            .getBrokerListWith(brokerValue?.id ?? undefined)
            .map(({ id, name }) => ({
              label: name,
              id,
            }))}
          onSelect={(value): void => {
            if (value && typeof value !== "string" && value.id) {
              store.updateAutoCompleteError(false, index);
              store.editBrokerDetail(index, value.id);
            }
          }}
          onInputClear={(): void => {
            store.updateAutoCompleteError(false, index);
            store.clearBroker(index);
          }}
          isDisabled={isContractNoteLinked}
          error={
            brokerValue === undefined &&
            store.brokerAmountDetails[index].isError
          }
          style={{
            background: tokens.background,
          }}
        />
      );
    };

    const getAmountQuantityTextFieldPlaceholder = (): string => {
      const requestedAmountQuantity = store.requestedAmountQuantity;
      if (requestedAmountQuantity) {
        if (requestedAmountQuantity.quantity === null) {
          return t("common.enterAmount");
        } else {
          return t("common.enterQuantity");
        }
      }
      return "-";
    };

    const getAmountQuantityTextFieldLabel = (): string => {
      const requestedAmountQuantity = store.requestedAmountQuantity;
      if (requestedAmountQuantity) {
        if (requestedAmountQuantity.quantity === null) {
          const currencySymbol =
            requestedAmountQuantity.amount?.currency.symbol;
          return `${t("common.amountWithPostfixLabel", {
            val: currencySymbol,
          })}`;
        } else {
          return t("common.quantity");
        }
      }
      return "-";
    };

    const getAmountQuantityError = (
      amountQuantity: Instance<typeof AmountQuantityModel> | undefined,
    ): boolean => {
      if (amountQuantity === undefined) {
        return true;
      } else {
        if (amountQuantity.amount !== null) {
          if (amountQuantity.amount.amount === 0) {
            return true;
          } else {
            return false;
          }
        } else if (amountQuantity.quantity !== null) {
          if (amountQuantity.quantity === 0) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    };

    const getAmountTextField = (
      brokerValue: AutoCompleteItem | undefined,
      amountQuantity: Instance<typeof AmountQuantityModel> | undefined,
      index: number,
      isContractNoteLinked: boolean,
      isAmountQuantityError: boolean,
    ): React.ReactElement => {
      const amountQuantityValue = amountQuantity?.quantity
        ? amountQuantity?.quantity
        : amountQuantity?.amount?.amount;
      return (
        <AmountTextField
          name="amount-quantity"
          key={brokerValue?.id}
          value={amountQuantityValue}
          isDecimalAllowed={amountQuantity?.quantity ? false : true}
          onAmountChange={(value: string): void => {
            store.updateAmountQuantityError(false, index);
            const _value = Number(value);
            store.editAmountDetail(index, _value);
          }}
          isDisabled={isContractNoteLinked}
          placeholder={getAmountQuantityTextFieldPlaceholder()}
          label={getAmountQuantityTextFieldLabel()}
          helperText={
            amountQuantity?.amount &&
            amountQuantity.amount.amount < (price ?? 0)
              ? t("fi.dealRequestDetails.amountLessThanPricePerUnit")
              : undefined
          }
          error={
            isAmountQuantityError ||
            (getAmountQuantityError(amountQuantity) &&
              store.brokerAmountDetails[index].isAmountQuantityError) ||
            (!!amountQuantity?.amount &&
              (amountQuantity?.amount?.amount ?? 0) < (price ?? 0))
          }
        />
      );
    };

    const getBrokerAmountTrailingButton = (
      index: number,
      isContractNoteLinked: boolean,
    ): React.ReactElement => {
      return (
        <>
          {(store.brokerAmountDetails.length > 1 || isContractNoteLinked) && (
            <IconButton
              name="remove"
              variant="plain-neutral"
              size="large"
              style={{
                marginTop: spacing.spaceXL,
                padding: 0,
                background: tokens.backgroundSubtle,
              }}
              icon={
                isContractNoteLinked ? (
                  <FileCheck height={Size.icon} width={Size.icon} />
                ) : (
                  <Trash height={Size.icon} width={Size.icon} />
                )
              }
              disabled={isContractNoteLinked}
              onClick={(): void => {
                if (isContractNoteLinked) {
                  // since the button will be disabled when contract note is linked, no action should be performed
                } else {
                  store.deleteBrokerAmountDetail(index);
                }
              }}
            />
          )}
        </>
      );
    };

    const getBrokerAmountComponent = (
      brokerValue: AutoCompleteItem | undefined,
      amountQuantity: Instance<typeof AmountQuantityModel> | undefined,
      index: number,
      isContractNoteLinked: boolean,
      isAmountQuantityError: boolean,
    ): React.ReactElement => {
      if (store.brokerList.length > 0) {
        return (
          <Stack
            key={index}
            sx={{
              width: Size.containerWidth,
              backgroundColor: tokens.backgroundSubtle,
              padding: spacing.spaceMD,
              flexDirection: "row",
              borderRadius: cornerRadius.radiusXS,
              justifyContent: "space-between",
              gap: spacing.spaceMD,
            }}
          >
            <Stack
              width={Size.containerWidth}
              sx={{
                gap: spacing.spaceMD,
              }}
            >
              {getBrokerAutoCompleteField(
                brokerValue,
                index,
                isContractNoteLinked,
              )}
              {getAmountTextField(
                brokerValue,
                amountQuantity,
                index,
                isContractNoteLinked,
                isAmountQuantityError,
              )}
            </Stack>
            {getBrokerAmountTrailingButton(index, isContractNoteLinked)}
          </Stack>
        );
      } else {
        console.error(
          "There is no list of brokers while showing the broker dropdown in send to brokers",
        );
        return <Fragment key={index}></Fragment>;
      }
    };

    const getBrokerAmountComponents = (): React.ReactElement[] => {
      const brokerAmountDetails = store.brokerAmountDetails;
      if (brokerAmountDetails) {
        return brokerAmountDetails.map(
          (
            brokerAmountDetail:
              | Instance<typeof BrokerAmountDetailModel>
              | Instance<typeof FiBrokerAmountDetailModel>,
            index,
          ) => {
            return getBrokerAmountComponent(
              brokerAmountDetail.broker
                ? {
                    label: brokerAmountDetail.broker.name,
                    id: brokerAmountDetail.broker.id,
                  }
                : undefined,
              brokerAmountDetail.amountQuantity ?? undefined,
              index,
              brokerAmountDetail.isLinked,
              brokerAmountDetail.isAmountQuantityError,
            );
          },
        );
      } else {
        return [];
      }
    };

    const getBrokerAmountQuantityComponent = (): React.ReactElement => {
      if (store.brokerAmountDetails) {
        return (
          <Stack
            width={Size.containerWidth}
            mt={spacing.spaceLG}
            gap={spacing.spaceMD}
          >
            {getBrokerAmountComponents()}
          </Stack>
        );
      }
      return <></>;
    };

    const getAddButton = (): React.ReactElement => {
      return (
        <Button
          name="add-broker-amount-quantity"
          size="small"
          variant="outlined-color"
          label={t("common.add")}
          iconPosition="leading"
          icon={<Icon type="plus" color="" />} // since the color will be overridden by the button hence it is not provided here
          onClick={(): void => {
            store.addBrokerAmountDetail();
          }}
          style={{
            marginTop: spacing.spaceMD,
            width: Size.addButton,
            marginBottom: spacing.spaceLG,
          }}
        />
      );
    };

    const excessAmountQuantityBanner = (): React.ReactElement => {
      const totalExecutedAmountQuantity = store.brokerAmountDetails.reduce(
        (accumulator, currentValue) => {
          let amountQuantityValue;
          if (currentValue.isLinked) {
            amountQuantityValue = currentValue.amountQuantity?.quantity
              ? currentValue.amountQuantity?.quantity
              : currentValue.amountQuantity?.amount?.amount;
          }

          return accumulator + (amountQuantityValue ? amountQuantityValue : 0);
        },
        0,
      );

      const requestedAmountQuantity = store.requestedAmountQuantity?.amount
        ? store.requestedAmountQuantity?.amount.amount
        : store.requestedAmountQuantity?.quantity;

      if (
        totalExecutedAmountQuantity >
        (requestedAmountQuantity ? requestedAmountQuantity : 0)
      ) {
        return (
          <WarningAlertComponent
            text={
              Boolean(store.requestedAmountQuantity?.amount)
                ? t("fi.dealRequestDetails.executedAmountIsMoreErrorMessage")
                : t("fi.dealRequestDetails.executedQuantityIsMoreErrorMessage")
            }
          />
        );
      }
      return <></>;
    };

    return (
      <Dialog
        open={isOpen}
        onClose={(): void => {
          store.resetStore();
          onClose();
        }}
        title={t("common.sendToBroker")}
        isCloseIconPresent={isCloseIconPresent}
        primaryButtonText={t("common.submit")}
        onPrimaryButtonClick={async (): Promise<void> => {
          store.validateInputs();
          setIsCloseIconPresent(false);
          await store.submitSendToBroker();
          setIsCloseIconPresent(true);
          if (store.error) {
            // error will be shown in the banner
          } else {
            onClose();
            onSuccessfulSendToBroker();
          }
        }}
        disableBackdropClick={true}
      >
        <Box display="flex" width={Size.containerWidth} flexDirection="column">
          {getErrorBanner()}
          {excessAmountQuantityBanner()}
          {getRequestedAmountQuantityLabel()}
          {getBrokerAmountQuantityComponent()}
          {getAddButton()}
          <NoteTextArea note={store.note} setNote={store.setNote} />
        </Box>
      </Dialog>
    );
  },
);
