import { cast, types, flow, Instance } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FiMaturityDealRequestStatus,
  GetFiMaturityDealRequestsRPC,
  FixedIncomeItemsPerPage,
  FixedIncomeSortOrder,
  FiMaturityDealRequestSearchBy,
  GetFiMaturityDealRequestsSortOrder,
  FixedIncomePageIndex,
  GetFiMaturityDealRequestsPaginationResponse,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { AllEnum } from "../../../../../types/EnumTypes";
import { DealRequestsFilterOptionsModel } from "../../../models/DealRequestsFilterOptionsModel";
import { getFiMaturityDealRequestsInitialFilter } from "../utils/SearchUtils";
import { GetFiMaturityDealRequestsSortOrderEnums } from "@khazana/khazana-rpcs/build/fixed-income/getFiMaturityDealRequestsSortOrder";
import { FiMaturityDealRequestModel } from "../models/FiMaturityDealRequestModel";
import { useGetFiMaturityDealRequestsRPCImpl } from "../rpcs/RPC";
import { createCurrencyModel } from "../../../models/CurrencyModel";

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): FixedIncomeSortOrder.FixedIncomeSortOrder => {
  if (sortOrder === "asc") {
    return FixedIncomeSortOrder.FixedIncomeSortOrder.ASCENDING;
  }
  return FixedIncomeSortOrder.FixedIncomeSortOrder.DESCENDING;
};

const getDealRequestStatusType = (
  dealRequestStatus: string,
): FiMaturityDealRequestStatus.FiMaturityDealRequestStatus | null => {
  return dealRequestStatus !== AllEnum.All
    ? FiMaturityDealRequestStatus.fromDTO({ case: dealRequestStatus })
    : null;
};

const getSearchText = (
  searchBy: FiMaturityDealRequestSearchBy.FiMaturityDealRequestSearchBy,
  filter: Instance<typeof DealRequestsFilterOptionsModel>,
): string | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return filter.searchText;
  }
};

export const FiMaturityDealRequestsStore = types
  .model({
    filterOptions: DealRequestsFilterOptionsModel,
    totalItems: types.number,
    requests: types.array(FiMaturityDealRequestModel),
    currencySymbol: types.maybeNull(types.string),
  })
  .actions((store) => ({
    updateFilterOptions(
      filterOptions: Instance<typeof DealRequestsFilterOptionsModel>,
    ): void {
      store.filterOptions = filterOptions;
    },
    getFiMaturityDealRequests: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sortOrder?: "asc" | "desc",
    ) {
      const request = new GetFiMaturityDealRequestsRPC.Request(
        [
          new GetFiMaturityDealRequestsSortOrder(
            0,
            GetFiMaturityDealRequestsSortOrderEnums.ColumnName.ColumnName.ISSUE_DATE,
            getSortOrder(sortOrder),
          ),
          new GetFiMaturityDealRequestsSortOrder(
            0,
            GetFiMaturityDealRequestsSortOrderEnums.ColumnName.ColumnName.VALUE_DATE,
            getSortOrder(sortOrder),
          ),
          new GetFiMaturityDealRequestsSortOrder(
            0,
            GetFiMaturityDealRequestsSortOrderEnums.ColumnName.ColumnName.MATURED_DATE,
            getSortOrder(sortOrder),
          ),
        ],
        getSearchText(
          FiMaturityDealRequestSearchBy.FiMaturityDealRequestSearchBy
            .REQUEST_ID,
          store.filterOptions,
        ),
        getDealRequestStatusType(store.filterOptions.requestStatus),
        getSearchText(
          FiMaturityDealRequestSearchBy.FiMaturityDealRequestSearchBy.ISIN,
          store.filterOptions,
        ),
        getSearchText(
          FiMaturityDealRequestSearchBy.FiMaturityDealRequestSearchBy.ENTITY,
          store.filterOptions,
        ),
        getSearchText(
          FiMaturityDealRequestSearchBy.FiMaturityDealRequestSearchBy.PORTFOLIO,
          store.filterOptions,
        ),
        new FixedIncomeItemsPerPage(itemsPerPage),
        new FixedIncomePageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetFiMaturityDealRequestsRPC.Response,
        GetFiMaturityDealRequestsRPC.Errors.InvalidPageIndex
      > = yield useGetFiMaturityDealRequestsRPCImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response
          .getFiMaturityDealRequestsPaginationResponse[0]?.currency
          ? response.getFiMaturityDealRequestsPaginationResponse[0].currency
              .symbol
          : null;
        const requests =
          response.getFiMaturityDealRequestsPaginationResponse.map(
            (_request: GetFiMaturityDealRequestsPaginationResponse) => {
              return FiMaturityDealRequestModel.create({
                requestId: _request.requestId,
                dealRequestStatus: _request.dealRequestStatus,
                security: _request.security,
                isin: _request.isin.isin,
                entity: _request.entity,
                portfolio: _request.portfolio,
                issueDate: new Date(_request.issueDate.date),
                valueDate: new Date(_request.valueDate.date),
                maturedDate: new Date(_request.maturedDate.date),
                faceValue: _request.faceValue,
                purchasePrice: _request.purchasePrice,
                maturedUnits: _request.maturedUnits,
                settlementAmount: _request.settlementAmount,
                currency: createCurrencyModel(_request.currency),
              });
            },
          );
        store.requests = cast(requests);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createFiMaturityDealRequestsStore = (): Instance<
  typeof FiMaturityDealRequestsStore
> => {
  return FiMaturityDealRequestsStore.create({
    filterOptions: getFiMaturityDealRequestsInitialFilter(),
    requests: [],
    totalItems: 0,
  });
};
