import { Stack } from "@mui/material";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NoteTextArea } from "./NoteTextArea";

export type TextFieldDialogButtonType =
  | "Submit"
  | "Approve"
  | "Reject"
  | "Discard"
  | "Unknown"
  | "Link"
  | "Cancel"
  | "Create";

interface TextFieldDialogProps {
  title: string;
  onClose: () => void;
  isOpen: boolean;
  primaryButtonType: TextFieldDialogButtonType;
  onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
  isCloseIconPresent?: boolean;
  children?: React.ReactElement;
}

const Size = {
  textFieldContainer: "100%",
};

export const TextFieldDialog = ({
  title,
  onClose,
  isOpen,
  primaryButtonType,
  onPrimaryButtonClick: onSubmit,
  isCloseIconPresent,
  children = undefined,
}: TextFieldDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const [note, setNote] = useState<string>();

  const getButtonTitle = (): string | undefined => {
    if (primaryButtonType === "Approve") {
      return t("common.approve");
    }
    if (primaryButtonType === "Reject") {
      return t("common.reject");
    }
    if (primaryButtonType === "Submit") {
      return t("common.submit");
    }
    if (primaryButtonType === "Discard") {
      return t("contractNotes.discard");
    }
    if (primaryButtonType === "Unknown") {
      return t("contractNotes.markAsUnknown");
    }
    if (primaryButtonType === "Link") {
      return t("contractNotes.link");
    }
    if (primaryButtonType === "Cancel") {
      return t("common.cancel");
    }
    if (primaryButtonType === "Create") {
      return t("common.createRequest");
    }
  };
  return (
    <Dialog
      open={isOpen}
      title={title}
      isCloseIconPresent={isCloseIconPresent}
      onClose={onClose}
      onSecondaryButtonClick={onClose}
      secondaryButtonText={
        primaryButtonType === "Link" ? t("common.back") : undefined
      }
      onPrimaryButtonClick={(): Promise<void> => onSubmit(note)}
      primaryButtonText={getButtonTitle()}
      disableBackdropClick={true}
    >
      <Stack width={Size.textFieldContainer}>
        {children}
        <NoteTextArea note={note} setNote={setNote} />
      </Stack>
    </Dialog>
  );
};
