import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { createCurrencyModel, CurrencyModel } from "../models/CurrencyModel";
import { GetBankAccountListRPC } from "@khazana/khazana-rpcs";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { useGetBankAccountListRPC } from "../fixed-deposit/deal-request/rpc/RPC";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";

export const BankAccount = types.model("BankAccount", {
  bankAccountNumber: types.string,
  currency: CurrencyModel,
});

export const BankAccountDropdownStore = types
  .model("BankAccountDropdownStore", {
    bankAccountList: types.array(BankAccount),
    selectedBankAccount: types.maybe(BankAccount),
    isLoading: types.optional(types.boolean, false),
    error: types.maybe(types.string),
  })
  .actions((store) => ({
    setSelectedAccount: (account: string | undefined): void => {
      const bankAccount = store.bankAccountList.find(
        (item) => item.bankAccountNumber === account,
      );
      if (bankAccount) {
        store.selectedBankAccount = BankAccount.create({
          bankAccountNumber: bankAccount.bankAccountNumber,
          currency: CurrencyModel.create({
            code: bankAccount.currency.code,
            symbol: bankAccount.currency.symbol,
          }),
        });
      } else {
        store.selectedBankAccount = undefined;
      }
      store.error = undefined;
    },
    setisLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    setError: (error: string | undefined): void => {
      store.error = error;
    },
    getAccountList: flow(function* (bankId?: string | undefined) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.selectedBankAccount = undefined;
      store.isLoading = true;
      const request = new GetBankAccountListRPC.Request(
        bankId ? new LeoUUID(bankId) : undefined,
      );
      const result: LeoRPCResult<
        GetBankAccountListRPC.Response,
        GetBankAccountListRPC.Errors.Errors
      > = yield useGetBankAccountListRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.bankAccountList = cast(
          response.bankAccounts.map((bankAccount) => {
            return BankAccount.create({
              bankAccountNumber: bankAccount.bankAccountNumber,
              currency: createCurrencyModel(bankAccount.currency),
            });
          }),
        );
      } else {
        logger.error("unable to fetch bank account list");
      }
      store.isLoading = false;
    }),
  }))
  .views((store) => ({
    get selectedBankAccountAutocompleteItem(): AutoCompleteItem | undefined {
      if (store.selectedBankAccount) {
        return {
          id: store.selectedBankAccount.bankAccountNumber,
          label: store.selectedBankAccount.bankAccountNumber,
        };
      }
    },
  }));

export const createBankAccountDropdownStore = (): Instance<
  typeof BankAccountDropdownStore
> => {
  return BankAccountDropdownStore.create();
};
