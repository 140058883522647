import {
  FixedIncomeHoldingSummaryResponse,
  GetFixedIncomeHoldingSummaryReportRPC,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFixedIncomeHoldingSummaryReportRPC extends GetFixedIncomeHoldingSummaryReportRPC {
  execute(
    _request: GetFixedIncomeHoldingSummaryReportRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFixedIncomeHoldingSummaryReportRPC.Response,
      GetFixedIncomeHoldingSummaryReportRPC.Errors.Errors
    >
  > {
    const response = new GetFixedIncomeHoldingSummaryReportRPC.Response(
      new LeoDate(),
      [new FixedIncomeHoldingSummaryResponse("INE100078912")],
      10,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFixedIncomeHoldingSummaryReportRPC.Response,
        GetFixedIncomeHoldingSummaryReportRPC.Errors.Errors
      >
    >;
  }
}
