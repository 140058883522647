import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FileExtension,
  GetMFHoldingSummaryDownloadURLRPC,
  GetMFHoldingSummaryReportRPC,
  PeriodInput,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  types,
} from "mobx-state-tree";
import {
  useGetMFHoldingSummaryDownloadURLRPCClientImpl,
  useGetMFHoldingSummaryReportRPCClientImpl,
} from "../rpcs/RPC";
import { TableSortOption } from "@surya-digital/leo-reactjs-core";
import {
  PortfolioDropdownStore,
  createPortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  EntityDropdownStore,
  createEntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  ReportExportFormatEnum,
  checkAllOptionAndGetId,
} from "../../../utils/ReportUtils";
import { getMfHoldingSummarySortOrder } from "../utils/SortUtils";
import { getLeoDate } from "../../../../../utils";
import {
  MfHoldingSummaryModel,
  createMfHoldingSummaryModel,
} from "../models/MfHoldingSummaryModel";

export const MfHoldingSummaryStore = types
  .model("MfHoldingSummaryStore", {
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    hasError: types.boolean,
    totalItems: types.number,
    downloadURL: types.maybe(types.string),
    mfHoldingSummarySummaryList: types.array(MfHoldingSummaryModel),
    asOnDate: types.maybe(types.string),
    isScreenLoading: types.boolean,
    isDataFetched: types.boolean,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    getHoldingSummarySummary: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sort: TableSortOption | undefined,
      period?: PeriodInput.PeriodInput,
    ) {
      store.isScreenLoading = true;
      if (period instanceof PeriodInput.AsOnDate) {
        store.asOnDate = period.date.date;
      }
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetMFHoldingSummaryReportRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          store.asOnDate ? getLeoDate(new Date(store.asOnDate)) : null,
          pageIndex,
          itemsPerPage,
          getMfHoldingSummarySortOrder(sort),
        );
        const result: LeoRPCResult<
          GetMFHoldingSummaryReportRPC.Response,
          GetMFHoldingSummaryReportRPC.Errors.Errors
        > =
          yield useGetMFHoldingSummaryReportRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.totalItems = response.totalItems;
          store.asOnDate = response.asOnDate?.date;
          store.mfHoldingSummarySummaryList = cast(
            response.holdingSummaryResponse.map((item) => {
              return createMfHoldingSummaryModel(item);
            }),
          );
          store.isDataFetched = true;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetMFHoldingSummaryReportRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in getHoldingSummarySummary`,
          );
        }
      }
      store.isScreenLoading = false;
    }),
    getDownloadURL: flow(function* (fileExtention: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetMFHoldingSummaryDownloadURLRPC.Request(
          new LeoUUID(store.entityDropdownStore.selectedEntity),
          new LeoUUID(store.portfolioDropdownStore.selectedPortfolio?.id),
          new LeoDate(store.asOnDate),
          fileExtention === ReportExportFormatEnum.CSV
            ? FileExtension.FileExtension.CSV
            : FileExtension.FileExtension.XLSX,
        );
        const result: LeoRPCResult<
          GetMFHoldingSummaryDownloadURLRPC.Response,
          GetMFHoldingSummaryDownloadURLRPC.Errors.Errors
        > =
          yield useGetMFHoldingSummaryDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetMFHoldingSummaryDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetMFHoldingSummaryDownloadURLRPC`,
          );
        }
      }
    }),
    setAsOnDate: (value: string | undefined): void => {
      store.asOnDate = value;
    },
    setIsScreenLoading: (value: boolean): void => {
      store.isScreenLoading = value;
    },
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createMFHoldingSummaryStore = (): Instance<
  typeof MfHoldingSummaryStore
> => {
  return MfHoldingSummaryStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    portfolioDropdownStore: createPortfolioDropdownStore(),
    totalItems: 0,
    hasError: false,
    isScreenLoading: false,
    isDataFetched: false,
  });
};
