import React from "react";
import { Typography, styled, ListItemButton } from "@mui/material";
import { getSidebarIcon } from "./SidebarUtils";
import {
  ListItemComponentProps,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-core";

export const ListItem = ({
  listItem,
  onSelect,
  currentPath,
  isCollapsed,
}: ListItemComponentProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const cornerRadius = useCornerRadius();
  const listItemPath = listItem.path ?? "";

  const handleClick = (): void => {
    onSelect(listItem.path ?? "");
  };

  const StyledListItemButton = styled(ListItemButton)(() => ({
    width: "100%",
    height: "40px",
    padding: spacing.spaceSM,
    borderRadius: cornerRadius.radiusXS,
    flexGrow: 0,
    flex: "none",
  }));

  const StyledTypography = styled(Typography)(() => ({
    ...(currentPath.includes(listItemPath) ? typography.s1 : typography.b1),
    marginLeft: spacing.spaceXS,
  }));

  return (
    <StyledListItemButton
      disabled={listItem.isDisabled}
      selected={currentPath.includes(listItemPath)}
      onClick={handleClick}
      sx={{
        color: tokens.label,
        ":hover": {
          backgroundColor: tokens.backgroundHovered,
          color: tokens.label,
        },
        "&.Mui-selected": {
          backgroundColor: tokens.backgroundSelected,
          color: tokens.labelPrimary,
          ":hover": {
            backgroundColor: tokens.backgroundSelected,
            color: tokens.labelPrimary,
          },
        },
      }}
    >
      {listItem.icon && getSidebarIcon(listItem.icon)}
      {!isCollapsed && <StyledTypography>{listItem.label}</StyledTypography>}
    </StyledListItemButton>
  );
};
