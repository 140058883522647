import { CreateFiBuyDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCreateFiBuyRequestRPCImpl extends CreateFiBuyDealRequestRPC {
  execute(
    _request: CreateFiBuyDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateFiBuyDealRequestRPC.Response,
      CreateFiBuyDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CreateFiBuyDealRequestRPC.Response(1);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateFiBuyDealRequestRPC.Response,
        CreateFiBuyDealRequestRPC.Errors.Errors
      >
    >;
  }
}
