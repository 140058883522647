import { Box, Stack } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { SidebarComponent } from "../../../components/SidebarComponent";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { NetworkingError } from "@khazana/khazana-boilerplate";
import { useHomeStore } from "../store/hooks";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { useTranslation } from "react-i18next";
import { Route } from "../../../routes/RoutesEnum";
import { useKeycloak } from "keycloak-react-web";
import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-core";

const Size = {
  container: "100vh",
  outletContainer: "calc(100vw - 256px)",
};

export const HomeLayout = observer((): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const errorStore = useNetworkingErrorStore();
  const navigate = useNavigate();
  const store = useHomeStore();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const location = useLocation();
  const [isInvalidRequestError, setIsInvalidRequestError] = useState(false);
  const [isTimeOutError, setIsTimeOutError] = useState(false);
  const [isUnauthenticated, setIsUnauthenticated] = useState(false);
  const [isNoInternetDialogOpen, setIsNoInternetDialogOpen] = useState(false);

  const signOut = (): void => {
    store.signOutUser();
    keycloak.logout({
      redirectUri: `${window.location.protocol}//${window.location.hostname}`,
    });
    navigate(Route.SignIn);
  };

  const getUserPrivileges = useCallback(async () => {
    await store.getUserPrivileges();
  }, [location.pathname]);

  useEffect(() => {
    getUserPrivileges();
  }, [location.pathname]);

  useEffect(() => {
    if (errorStore.error) {
      switch (errorStore.error) {
        case NetworkingError.NetworkError:
        case NetworkingError.InternalError:
        case NetworkingError.InternalServerError:
          navigate("/500");
          break;
        case NetworkingError.PageNotFound:
          navigate("/404");
          break;
        case NetworkingError.RPCError:
          signOut();
          break;
        case NetworkingError.InvalidRequest:
          setIsInvalidRequestError(true);
          break;
        case NetworkingError.Unauthenticated:
          setIsUnauthenticated(true);
          break;
        case NetworkingError.TimeOut:
          setIsTimeOutError(true);
          break;
        case NetworkingError.NoInternet:
          setIsNoInternetDialogOpen(true);
          break;
      }
      errorStore.removeError();
    }
  }, [errorStore.error]);

  return (
    <Box
      sx={{
        display: "flex",
        background: tokens.backgroundSubtle,
        minHeight: Size.container,
      }}
    >
      <SidebarComponent />
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <Stack width={Size.outletContainer}>
          <Outlet />
        </Stack>
        <ErrorDialog
          title={t("common.somethingWentWrong")}
          errorMessage={t("common.somethingWentWrongProcessingRequest")}
          isErrorDialogOpen={isInvalidRequestError || isTimeOutError}
          onClose={(): void => {
            if (isTimeOutError) {
              setIsTimeOutError(false);
            } else {
              setIsInvalidRequestError(false);
            }
            // This is done because it is very much possible that the page is stuck in a loading state while making the RPC call
            // hence to break the loading state, the page is reloaded.
            navigate(0);
          }}
        />
        <ErrorDialog
          title={t("errors.sessionTimeout")}
          errorMessage={t("errors.sessionTimeoutDescription")}
          isErrorDialogOpen={isUnauthenticated}
          onClose={(): void => {
            signOut();
          }}
        />
        <ErrorDialog
          title={t("errors.noInternetConnectionFound")}
          errorMessage={t("errors.noInternetConnectionFoundDescription")}
          isErrorDialogOpen={isNoInternetDialogOpen}
          onClose={function (): void {
            setIsNoInternetDialogOpen(false);
          }}
        />
      </Box>
    </Box>
  );
});
