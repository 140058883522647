import { Instance } from "mobx-state-tree";
import React from "react";

import { getMFHistoryTitleText, getMFStatusColor } from "../utils/UIUtils";
import { getRequestStatusValue } from "../utils/SearchUtils";
import { DealHistoryRow } from "../../../components/deal-history/DealHistoryRow";
import { MFDealRequestHistoryModel } from "../models/MFDealRequestHistoryModel";
import { ImpactOnPortfolio } from "../../../components/ImpactOnPortfolio";
import { SchemeDetails } from "./SchemeDetails";

interface MFDealStatusSectionProps {
  value: Instance<typeof MFDealRequestHistoryModel>;
  isHistoryComponent?: boolean;
}
export const MFDealStatusSection = ({
  value,
}: MFDealStatusSectionProps): React.ReactElement => {
  const additionalDetails = [];
  if (value.impactTable) {
    additionalDetails.push(
      <ImpactOnPortfolio impactTableModel={value.impactTable} />,
    );
  }
  if (value.schemeDetails) {
    additionalDetails.push(<SchemeDetails store={value.schemeDetails} />);
  }
  return value.requestNote?.requestedAt && value.status ? (
    <DealHistoryRow
      firstName={value.requestNote.userName.firstName}
      lastName={value.requestNote.userName.lastName}
      requestedAt={value.requestNote.requestedAt}
      note={value.requestNote?.note}
      isHistoryComponent={false}
      userImage={value.requestNote.profilePhoto?.toString()}
      title={getMFHistoryTitleText(
        value.requestNote.userName.firstName,
        value.requestNote.userName.lastName,
        value.status,
      )}
      chipText={getRequestStatusValue(value.status).toLocaleUpperCase()}
      chipColor={getMFStatusColor(value.status)}
      additionalDetails={additionalDetails}
    />
  ) : (
    <></>
  );
};
