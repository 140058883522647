import {
  applySnapshot,
  cast,
  castToSnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  ChangeInGainModel,
  createChangeInGainModel,
} from "../models/ChangeInGainModel";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FileExtension,
  GetEquityBenchmarkReturnsGraphRPC,
  GetEquityChangeInGainReportDownloadURLRPC,
  GetEquityChangeInGainRPC,
  GetEquityGrandTotalChangeInGainRPC,
  PeriodInput,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useGetEquityChangeInGainReportDownloadURLRPCClientImpl,
  useGetEquityChangeInGainRPCClientImpl,
  useGetEquityGrandTotalChangeInGainRPCClientImpl,
} from "../rpcs/RPC";
import {
  ChangeInGainSummaryModel,
  createChangeInGainSummaryModel,
} from "../models/ChangeInGainSummaryModel";
import {
  getLeoDateOrNull,
  ReportExportFormatEnum,
  checkAllOptionAndGetId,
} from "../../../utils/ReportUtils";
import { TableSortOption } from "@surya-digital/leo-reactjs-material-ui";
import { getChangeInGainSortOrder } from "../utils/SortUtils";
import {
  createEntityDropdownStore,
  EntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  createPortfolioDropdownStore,
  PortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  BenchmarkIndexDropdownStore,
  createBenchmarkIndexDropdownStore,
} from "../../../store/BenchmarkIndexDropdownStore";
import { createServerDateRangeModel } from "../../models/EquityReportDateRangeModel";
import { useGetEquityBenchmarkReturnsGraphRPC } from "../../dashboard/rpcs/RPC";
import {
  createPercentageDateModel,
  PercentageDateModel,
} from "../../../models/PercentageDateModel";

export const EQChangeInGainStore = types
  .model("EQChangeInGainStore", {
    downloadURL: types.maybe(types.string),
    eqChangeInGainList: types.array(ChangeInGainModel),
    totalItems: types.number,
    eqChangeInGainSummary: types.maybe(ChangeInGainSummaryModel),
    hasError: types.boolean,
    fromDate: types.maybe(types.string),
    toDate: types.maybe(types.string),
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    isScreenLoading: types.boolean,
    isDataFetched: types.boolean,
    isBenchmarkLoading: types.optional(types.boolean, false),
    benchmarks: types.array(PercentageDateModel),
    returns: types.array(PercentageDateModel),
    benchmarkIndex: BenchmarkIndexDropdownStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    getEQChangeInGain: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sort?: TableSortOption,
      period?: PeriodInput.PeriodInput,
    ) {
      store.isScreenLoading = true;
      if (period instanceof PeriodInput.BetweenTwoDates) {
        store.toDate = period.endDate.date;
        store.fromDate = period.startDate.date;
      }
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetEquityChangeInGainRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          getLeoDateOrNull(store.fromDate),
          getLeoDateOrNull(store.toDate),
          pageIndex,
          itemsPerPage,
          getChangeInGainSortOrder(sort),
        );
        const result: LeoRPCResult<
          GetEquityChangeInGainRPC.Response,
          GetEquityChangeInGainRPC.Errors.Errors
        > =
          yield useGetEquityChangeInGainRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fromDate = response.fromDate.date;
          store.toDate = response.toDate.date;
          store.eqChangeInGainList = cast(
            response.changeInGainResponse.map((item) => {
              return createChangeInGainModel(item);
            }),
          );
          store.totalItems = response.totalItems;
          store.isDataFetched = true;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainRPC`,
          );
        }
        store.isScreenLoading = false;
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainRPC`,
          );
        }
        store.isScreenLoading = false;
      }
    }),
    getDownloadURL: flow(function* (fileExtention: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetEquityChangeInGainReportDownloadURLRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          new LeoDate(store.fromDate),
          new LeoDate(store.toDate),
          fileExtention === ReportExportFormatEnum.CSV
            ? FileExtension.FileExtension.CSV
            : FileExtension.FileExtension.XLSX,
        );
        const result: LeoRPCResult<
          GetEquityChangeInGainReportDownloadURLRPC.Response,
          GetEquityChangeInGainReportDownloadURLRPC.Errors.Errors
        > =
          yield useGetEquityChangeInGainReportDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainReportDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainReportDownloadURLRPC`,
          );
        }
      }
    }),
    getGrandTotalChangeInGain: flow(function* () {
      store.isScreenLoading = true;
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetEquityGrandTotalChangeInGainRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          getLeoDateOrNull(store.fromDate),
          getLeoDateOrNull(store.toDate),
        );
        const result: LeoRPCResult<
          GetEquityGrandTotalChangeInGainRPC.Response,
          GetEquityGrandTotalChangeInGainRPC.Errors.Errors
        > =
          yield useGetEquityGrandTotalChangeInGainRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.eqChangeInGainSummary =
            createChangeInGainSummaryModel(response);
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainReportDownloadURLRPC`,
          );
        }
        store.isScreenLoading = false;
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainReportDownloadURLRPC`,
          );
        }
        store.isScreenLoading = false;
      }
    }),
    getEquityBenchmarkReturnsGraph: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      let dateRange = null;
      if (store.fromDate && store.toDate) {
        dateRange = createServerDateRangeModel({
          startDate: new Date(store.fromDate),
          endDate: new Date(store.toDate),
        });
      }
      const request = new GetEquityBenchmarkReturnsGraphRPC.Request(
        store.benchmarkIndex.selectedBenchmarkIndex?.id ?? 0,
        dateRange,
        checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
        checkAllOptionAndGetId(
          store.portfolioDropdownStore.selectedPortfolio?.id,
        ),
      );
      store.isBenchmarkLoading = true;
      const result: LeoRPCResult<
        GetEquityBenchmarkReturnsGraphRPC.Response,
        GetEquityBenchmarkReturnsGraphRPC.Errors.Errors
      > =
        yield useGetEquityBenchmarkReturnsGraphRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.benchmarks = cast(
          response.benchmarkData.map((benchmark) =>
            createPercentageDateModel(benchmark),
          ),
        );
        store.returns = cast(
          response.returnsData.map((returns) =>
            createPercentageDateModel(returns),
          ),
        );
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          default:
            logger.error(
              `Unhandled error: ${error} occurred in getEquityBenchmarkReturnsGraphRPC`,
            );
        }
      } else {
        logger.error("Unhandled error");
      }
      store.isBenchmarkLoading = false;
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createEQChanageInGainStore = (): Instance<
  typeof EQChangeInGainStore
> => {
  return EQChangeInGainStore.create({
    totalItems: 0,
    hasError: false,
    isScreenLoading: false,
    entityDropdownStore: castToSnapshot(createEntityDropdownStore()),
    portfolioDropdownStore: castToSnapshot(createPortfolioDropdownStore()),
    isDataFetched: false,
    benchmarkIndex: createBenchmarkIndexDropdownStore(),
  });
};
