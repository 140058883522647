import { Instance, types } from "mobx-state-tree";
import { BigDecimal, FiMaturityCharge } from "@khazana/khazana-rpcs";

export const FiMaturityChargeModel = types.model("FiMaturityChargeModel", {
  displayName: types.string,
  chargeType: types.string,
  chargeRuleId: types.maybeNull(types.number),
  amount: types.maybeNull(types.string),
});

export const createFiMaturityChargeModel = (
  fiChargeRule: FiMaturityCharge,
): Instance<typeof FiMaturityChargeModel> => {
  return FiMaturityChargeModel.create({
    displayName: fiChargeRule.displayName,
    chargeType: fiChargeRule.chargeType,
    chargeRuleId: fiChargeRule.chargeRuleId,
    amount: fiChargeRule.amount?.decimalValue,
  });
};

export const createRPCFiMaturityChargeType = (
  charge: Instance<typeof FiMaturityChargeModel>,
): FiMaturityCharge => {
  return new FiMaturityCharge(
    charge.displayName,
    charge.chargeType,
    charge.chargeRuleId,
    charge.amount ? new BigDecimal(charge.amount) : null,
  );
};
