import { Typography } from "@mui/material";
import {
  getSeparateLabelInputFieldLabel,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-core";
import React from "react";

export function InputFieldLabel({
  label,
  error,
  disabled,
  required,
}: {
  label: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
}): React.ReactElement {
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();

  return (
    <Typography
      sx={{
        ...typography.s2,
        color: error
          ? tokens.labelError
          : disabled
            ? tokens.labelLowEmphasis
            : tokens.label,
        paddingBottom: spacing.spaceXXS,
      }}
    >
      {getSeparateLabelInputFieldLabel(label, required)}
    </Typography>
  );
}
