import { SubmitFiDealContractNoteRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitFiDealContractNoteRPCImpl extends SubmitFiDealContractNoteRPC {
  execute(
    _request: SubmitFiDealContractNoteRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitFiDealContractNoteRPC.Response,
      SubmitFiDealContractNoteRPC.Errors.Errors
    >
  > {
    const response = new SubmitFiDealContractNoteRPC.Response([1]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitFiDealContractNoteRPC.Response,
        SubmitFiDealContractNoteRPC.Errors.Errors
      >
    >;
  }
}
