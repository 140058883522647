"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CashflowTimelineAmount = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigAmount_1 = require("./bigAmount");
class InvalidCashflowTimelineAmountError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidCashflowTimelineAmountError";
    }
}
class CashflowTimelineAmount {
    constructor(localizedTextId, amount, faceValue = null) {
        this.isCashflowTimelineAmount = true;
        CashflowTimelineAmount.validateLocalizedTextId(localizedTextId);
        this.localizedTextId = localizedTextId;
        CashflowTimelineAmount.validateAmount(amount);
        this.amount = amount;
        if (faceValue !== undefined && faceValue !== null) {
            CashflowTimelineAmount.validateFaceValue(faceValue);
        }
        this.faceValue = faceValue;
    }
    static fromDTO(dto) {
        const localizedTextId = (0, leo_ts_runtime_1.getString)(dto, "localizedTextId", InvalidCashflowTimelineAmountError);
        const amount = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidCashflowTimelineAmountError));
        let faceValue = (0, leo_ts_runtime_1.getOptionalObject)(dto, "faceValue");
        if (faceValue !== undefined && faceValue !== null) {
            faceValue = bigAmount_1.BigAmount.fromDTO(faceValue);
        }
        return new CashflowTimelineAmount(localizedTextId, amount, faceValue);
    }
    toDTO() {
        const ret = {
            localizedTextId: this.localizedTextId,
            amount: this.amount.toDTO(),
        };
        if (this.faceValue) {
            ret.faceValue = this.faceValue.toDTO();
        }
        return ret;
    }
    copy(localizedTextId = this.localizedTextId, amount = this.amount, faceValue = this.faceValue) {
        return new CashflowTimelineAmount(localizedTextId, amount, faceValue);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isCashflowTimelineAmountValue = other.isCashflowTimelineAmount;
        if (!(other instanceof CashflowTimelineAmount) || Boolean(isCashflowTimelineAmountValue)) {
            return false;
        }
        if (this.localizedTextId !== other.localizedTextId) {
            return false;
        }
        if (!this.amount.equals(other.amount)) {
            return false;
        }
        if (this.faceValue) {
            if (!this.faceValue.equals(other.faceValue)) {
                return false;
            }
        }
        return true;
    }
}
exports.CashflowTimelineAmount = CashflowTimelineAmount;
CashflowTimelineAmount.validateLocalizedTextId = (localizedTextId) => {
    if (!(0, leo_ts_runtime_1.isString)(localizedTextId)) {
        throw new InvalidCashflowTimelineAmountError(`Attribute localizedTextId is not a String`);
    }
    if (localizedTextId.trim().length === 0) {
        throw new InvalidCashflowTimelineAmountError(`Attribute localizedTextId cannot be an empty string.`);
    }
};
CashflowTimelineAmount.validateAmount = (amount) => {
    const isBigAmountValue = amount.isBigAmount;
    if (!(amount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidCashflowTimelineAmountError(`Attribute amount is not a BigAmount`);
    }
};
CashflowTimelineAmount.validateFaceValue = (faceValue) => {
    const isBigAmountValue = faceValue.isBigAmount;
    if (!(faceValue instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidCashflowTimelineAmountError(`Attribute faceValue is not a BigAmount`);
    }
};
CashflowTimelineAmount.prototype.toString = function toString() {
    return `CashflowTimelineAmount(localizedTextId='${this.localizedTextId}',amount=${this.amount},faceValue=${this.faceValue})`;
};
