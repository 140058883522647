export const MOCK = {
  signIn: false,
  entity: false,
  portfolio: false,
  equity: false,
  fi: false,
  fd: false,
  mf: false,
  common: false,
};

MOCK.common = MOCK.fi || MOCK.mf || MOCK.fd || MOCK.equity;
