import { Instance, types } from "mobx-state-tree";
import { DetailsCardItem } from "../components/DetailsTimelineCard";

export const DetailsCardItemModel = types.model("DetailsCardItemModel", {
  top: types.string,
  middle: types.string,
  bottom: types.string,
});

export const createDetailsCardItemModel = ({
  top,
  middle,
  bottom,
}: DetailsCardItem): Instance<typeof DetailsCardItemModel> => {
  return DetailsCardItemModel.create({
    top,
    middle,
    bottom,
  });
};
