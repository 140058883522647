import React from "react";
import {
  List,
  Collapse,
  Typography,
  styled,
  Box,
  ListItemButton,
} from "@mui/material";
import { getSidebarColor, getSidebarIcon } from "./SidebarUtils";
import { SubListItem } from "./SubListItem";
import {
  DropdownListItemProps,
  useCornerRadius,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-core";
import { Icon } from "@surya-digital/leo-reactjs-material-ui";

export const DropdownListItem = ({
  listItem,
  onSubListItemSelect,
  isExpanded,
  onSelect,
  currentPath,
  isCollapsed,
}: DropdownListItemProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();

  const StyledList = styled(List)(() => ({
    width: "100%",
    flexGrow: 0,
    "&.MuiList-root": { padding: 0, gap: spacing.spaceXS },
    gap: spacing.spaceXS,
  }));

  const StyledTypography = styled(Typography)(() => ({
    ...(isExpanded ? typography.s1 : typography.b1),
    color: getSidebarColor(isExpanded),
    marginLeft: spacing.spaceSM,
  }));

  const StyledListItemButton = styled(ListItemButton)(() => ({
    width: "100%",
    height: "40px",
    padding: spacing.spaceSM,
    borderRadius: cornerRadius.radiusXS,
    flexGrow: 0,
    flex: "none",
  }));

  return (
    <StyledList>
      <StyledListItemButton
        disabled={listItem.isDisabled}
        onClick={(): void => onSelect()}
        sx={{ justifyContent: "space-between" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {listItem.icon &&
            getSidebarIcon(
              listItem.icon,
              getSidebarColor(
                isExpanded &&
                  (!isCollapsed ||
                    listItem.subList?.some(
                      (item) => item.path === currentPath,
                    )),
              ),
            )}
          {!isCollapsed && (
            <StyledTypography>{listItem.label}</StyledTypography>
          )}
        </Box>
        {!isCollapsed &&
          (isExpanded ? (
            <Icon
              type="chevron-down"
              color={getSidebarColor(isExpanded)}
              width={20}
              height={20}
            />
          ) : (
            <Icon
              type="chevron-right"
              color={getSidebarColor()}
              width={20}
              height={20}
            />
          ))}
      </StyledListItemButton>
      {!isCollapsed && (
        <Collapse in={isExpanded} sx={{ gap: spacing.spaceXS }}>
          {listItem.subList?.map((subListItem, index) => {
            return (
              <SubListItem
                key={index}
                subListItem={subListItem}
                onSelect={(path: string): void => {
                  onSubListItemSelect(path);
                }}
                currentPath={currentPath}
              />
            );
          })}
        </Collapse>
      )}
    </StyledList>
  );
};
