import { Instance, types } from "mobx-state-tree";
import { MFBrokerOrderDetail } from "@khazana/khazana-rpcs";
import {
  createMFOrderDetailModel,
  MFOrderDetailModel,
} from "./MFOrderDetailModel";
import { BrokerModel, createBrokerModel } from "../../../models/BrokerModel";
import { MFOrderStatusModel } from "./MFOrderStatusModel";

export const MFBrokerOrderDetailModel = types
  .model("MFBrokerOrderDetailModel", {
    confirmationId: types.maybe(types.number),
    broker: types.maybe(BrokerModel),
    orderStatus: types.maybe(MFOrderStatusModel),
    referenceNumber: types.maybe(types.string),
    requestDetail: types.maybe(MFOrderDetailModel),
    executedDetail: types.maybe(MFOrderDetailModel),
    isError: types.boolean, // This is added to show error for individual dropdown component in the dialog.
    isAmountUnitsError: types.boolean, // This is added to show error for individual amountQuantity component in the dialog.
  })
  .actions((store) => ({
    getBrokerOrderDetailRPCType: (): MFBrokerOrderDetail => {
      if (store.requestDetail && store.broker) {
        return new MFBrokerOrderDetail(
          store.confirmationId,
          store.broker.getBrokerRPCType(),
          store.requestDetail?.getMFOrderDetailRPCType(),
          store.executedDetail?.getMFOrderDetailRPCType(),
          store.orderStatus,
          store.referenceNumber,
        );
      } else {
        throw new Error("Invalid Broker Order Detail");
      }
    },
  }));

export const createMFBrokerOrderDetailModel = (
  order?: MFBrokerOrderDetail,
): Instance<typeof MFBrokerOrderDetailModel> => {
  if (!order) {
    return MFBrokerOrderDetailModel.create({
      isError: false,
      isAmountUnitsError: false,
    });
  }
  return MFBrokerOrderDetailModel.create({
    confirmationId: order.confirmationId ?? undefined,
    broker: order.broker ? createBrokerModel(order.broker) : undefined,
    orderStatus: order.orderStatus ?? undefined,
    referenceNumber: order.referenceNumber ?? undefined,
    requestDetail: createMFOrderDetailModel(order.requestDetail),
    executedDetail: createMFOrderDetailModel(order.executedDetail),
    isError: false,
    isAmountUnitsError: false,
  });
};
