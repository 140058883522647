import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  PageHeader,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { DealRequestsFilterOptionsModel } from "../../../models/DealRequestsFilterOptionsModel";
import { getFormattedDate } from "../../../../../utils";
import { DealRequestsSearchFilter } from "../components/DealRequestsSearchFilter";
import { getMaturityRequestStatusValue } from "../utils/SearchUtils";
import { useNavigate } from "react-router-dom";
import { useFiMaturityDealRequestsStore } from "../store/hooks";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";

const Size = {
  table: {
    default: "max-content",
    security: "344px",
    status: "200px",
  },
};

export const ManageFiMaturityDealRequests = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useFiMaturityDealRequestsStore();
  const spacing = useSpacing();
  const navigate = useNavigate();

  const getDealRequests = async (
    option: TableOptions<Instance<typeof DealRequestsFilterOptionsModel>>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    if (option.filter) store.updateFilterOptions(option.filter);
    await store.getFiMaturityDealRequests(
      option.page ? option.page - 1 : 0,
      store.itemsPerPage(),
      option.sort?.order,
    );
    setTotalItems(store.totalItems);

    return store.requests.map((request) => {
      return [
        {
          data: request.requestId,
        },

        {
          data: request.security,
        },
        {
          data: getMaturityRequestStatusValue(t, request.dealRequestStatus),
        },
        {
          data: request.isin,
        },
        {
          data: request.entity,
        },
        {
          data: request.portfolio,
        },
        {
          data: getFormattedDate(request.issueDate),
        },
        {
          data: getFormattedDate(request.valueDate),
        },
        {
          data: getFormattedDate(request.maturedDate),
        },
        {
          data: request.currency.code,
        },
        {
          data: t("common.decimal2", { val: request.faceValue }),
          align: "right",
        },
        {
          data: t("common.decimal2", { val: request.purchasePrice }),
          align: "right",
        },
        {
          data: t("common.numberFormat", { val: request.maturedUnits }),
          align: "right",
        },
        {
          data: t("common.decimal2", { val: request.settlementAmount }),
          align: "right",
        },
      ];
    });
  };

  const getHeaders = (): TableHeader => {
    return [
      {
        id: "id",
        name: t("common.maturityRequestId"),
        width: Size.table.default,
      },
      {
        id: "securityName",
        name: t("fi.securityName"),
        width: Size.table.security,
      },
      {
        id: "status",
        name: t("common.status"),
        width: Size.table.status,
      },
      {
        id: "isin",
        name: t("contractNotes.isin"),
        width: Size.table.default,
      },
      {
        id: "entity",
        name: t("common.entity"),
        width: Size.table.default,
      },
      {
        id: "portfolio",
        name: t("common.portfolio"),
        width: Size.table.default,
      },
      {
        id: "issueDate",
        name: t("fi.securityDetails.issueDateLabel"),
        width: Size.table.default,
        sortable: true,
      },
      {
        id: "valueDate",
        name: t("fi.valueDate"),
        width: Size.table.default,
        sortable: true,
      },
      {
        id: "maturedDate",
        name: t("fi.maturedDate"),
        width: Size.table.default,
        sortable: true,
      },
      {
        id: "currency",
        name: t("fi.currency"),
        width: Size.table.default,
      },
      {
        id: "faceValue",
        name: t("fi.faceValue"),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "purchasePrice",
        name: t("fi.purchasePrice"),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "maturedUnits",
        name: t("fi.maturedUnits"),
        width: Size.table.default,
      },
      {
        id: "settlementAmount",
        name: t("fi.settlementAmount"),
        width: Size.table.default,
        align: "right",
      },
    ];
  };

  return (
    <>
      <PageHeader title={t("fi.viewFixedIncomeMaturityRequests")} />
      <Box margin={spacing.space2XL}>
        <Table
          name="fiMaturityDealRequestsTable"
          styleOverrides={{
            divider: "cell",
          }}
          headers={getHeaders()}
          onTableOptionsChange={getDealRequests}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            idle: { message: t("common.searchTableIdleState") },
            loading: { message: t("common.searchMaturityTableLoadingState") },
          }}
          paginationOption={{
            itemsPerPage: store.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          filterOption={{
            initialFilterValue: store.filterOptions,
            filterComponent(filter, setFilter): React.ReactElement {
              return (
                <DealRequestsSearchFilter
                  filter={filter}
                  setFilter={setFilter}
                  isMaturityScreen={true}
                />
              );
            },
          }}
          onRowClick={(_, index): void => {
            const selectedRequestId = store.requests[index].requestId;
            navigate(
              getPath(
                Module.Fi,
                Route.ManageMaturityRequestDetailsWithParams,
                selectedRequestId.toString(),
              ),
            );
          }}
        />
      </Box>
    </>
  );
});
