import { Instance, types } from "mobx-state-tree";
import { FdDealRequestHistoryAdditionalDetail } from "@khazana/khazana-rpcs";
import { getAmount } from "../../../../../utils";

export const FDHistoryWithdrawDetails = types.model(
  "FDHistoryWithdrawDetails",
  {
    withdrawalAmount: types.number,
    bankCharge: types.number,
    bankChargeAmount: types.number,
    currencySymbol: types.string,
  },
);

export const createFDHistoryWithdrawDetails = (
  item: FdDealRequestHistoryAdditionalDetail | null,
): Instance<typeof FDHistoryWithdrawDetails> | undefined => {
  if (!item) {
    return;
  }
  return FDHistoryWithdrawDetails.create({
    withdrawalAmount: getAmount(item.withdrawalAmount.amount) ?? 0,
    bankCharge: getAmount(item.bankChargePercentage) ?? 0,
    bankChargeAmount: getAmount(item.bankChargeAmount.amount) ?? 0,
    currencySymbol: item.withdrawalAmount.currency.symbol,
  });
};
