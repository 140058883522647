import {
  GetFixedDepositHoldingSummaryDownloadURLRPC,
  GetFixedDepositHoldingSummaryDownloadURLRPCClientImpl,
  GetFixedDepositHoldingSummaryReportRPC,
  GetFixedDepositHoldingSummaryReportRPCClientImpl,
  GetFixedDepositInvestmentRegisterDownloadURLRPC,
  GetFixedDepositInvestmentRegisterDownloadURLRPCClientImpl,
  GetFixedDepositInvestmentRegisterReportRPC,
  GetFixedDepositInvestmentRegisterReportRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetFixedDepositInvestmentRegisterReportRPC } from "./MockGetFixedDepositInvestmentRegisterReportRPC";
import { MockGetFixedDepositInvestmentRegisterDownloadURLRPC } from "./MockGetFixedDepositInvestmentRegisterDownloadURLRPC";
import { MockGetFixedDepositHoldingSummaryReportRPC } from "./MockGetFixedDepositHoldingSummaryReportRPC";
import { MockGetFixedDepositHoldingSummaryDownloadURLRPC } from "./MockGetFixedDepositHoldingSummaryDownloadURLRPC";

export const useGetFixedDepositInvestmentRegisterReportRPCClientImpl = (
  apiClient: APIClient,
): GetFixedDepositInvestmentRegisterReportRPC => {
  if (MOCK.fd) {
    return new MockGetFixedDepositInvestmentRegisterReportRPC();
  } else {
    return new GetFixedDepositInvestmentRegisterReportRPCClientImpl(apiClient);
  }
};

export const useGetFixedDepositInvestmentRegisterDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetFixedDepositInvestmentRegisterDownloadURLRPC => {
  if (MOCK.fd) {
    return new MockGetFixedDepositInvestmentRegisterDownloadURLRPC();
  } else {
    return new GetFixedDepositInvestmentRegisterDownloadURLRPCClientImpl(
      apiClient,
    );
  }
};

export const useGetFixedDepositHoldingSummaryReportRPCClientImpl = (
  apiClient: APIClient,
): GetFixedDepositHoldingSummaryReportRPC => {
  if (MOCK.fd) {
    return new MockGetFixedDepositHoldingSummaryReportRPC();
  } else {
    return new GetFixedDepositHoldingSummaryReportRPCClientImpl(apiClient);
  }
};

export const useGetFixedDepositHoldingSummaryDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetFixedDepositHoldingSummaryDownloadURLRPC => {
  if (MOCK.fd) {
    return new MockGetFixedDepositHoldingSummaryDownloadURLRPC();
  } else {
    return new GetFixedDepositHoldingSummaryDownloadURLRPCClientImpl(apiClient);
  }
};
