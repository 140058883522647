import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CancelFDDealRequestRPC,
  DetailCellType,
  GetFDDealRequestDetailsRPC,
  Note,
  PayoutFrequency,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useCancelFDDealRequestRPC,
  useGetFDDealRequestDetailsRPC,
} from "../rpc/RPC";
import {
  FDDealCancelError,
  FDDealInvalidRequestError,
} from "./FDDealRequestErrors";
import {
  createFDDealRequestHistoryStore,
  FDDealRequestHistoryStore,
} from "./FDDealRequestHistoryStore";
import {
  createFDDealRequestBannerInfoStore,
  FDDealRequestBannerInfoStore,
} from "./FDDealRequestBannerInfoStore";
import {
  createFDDealRequestActionModel,
  FDDealRequestActionModel,
} from "../models/FDDealRequestActionModel";
import {
  CheckFDDealRequestStore,
  createCheckFDDealRequestStore,
} from "./CheckFDDealRequestStore";
import {
  createFDCertificateStore,
  FDCertificateStore,
} from "./FDCertificateStore";
import {
  createFDSettleDealStore,
  FDSettleDealStore,
} from "./FDSettleDealStore";
import {
  createFDWithdrawStore,
  FDWithdrawStore,
} from "./withdrawStore/FDWithdrawStore";
import { createCurrencyModel } from "../../../models/CurrencyModel";
import { getAmount } from "../../../../../utils";
import { DetailType } from "../../../models/DetailCellModel";
import {
  createFdInvestmentDetailsStore,
  FdInvestmentDetailsStore,
} from "../../components/investment-details/FdInvestmentDetailsStore";

export const FDDealRequestDetailsStore = types
  .model("FDDealDetailsStore", {
    details: types.array(DetailType),
    requestActions: FDDealRequestActionModel,
    isLoading: types.boolean,
    error: types.maybeNull(
      types.union(
        types.enumeration<FDDealInvalidRequestError>(
          "FDDealInvalidRequestError",
          Object.values(FDDealInvalidRequestError),
        ),
        types.enumeration<FDDealCancelError>(
          "FDDealCancelError",
          Object.values(FDDealCancelError),
        ),
      ),
    ),
    historyStore: FDDealRequestHistoryStore,
    bannerInfoStore: FDDealRequestBannerInfoStore,
    checkFDDealRequestStore: CheckFDDealRequestStore,
    certificateStore: FDCertificateStore,
    settleStore: FDSettleDealStore,
    withdrawStore: FDWithdrawStore,
    investmentDetailsStore: FdInvestmentDetailsStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      clearError: (): void => {
        store.historyStore.error = null;
        store.bannerInfoStore.error = null;
        store.checkFDDealRequestStore.error = null;
        store.withdrawStore.confirmRequest.error = null;
        store.withdrawStore.createRequest.error = null;
        store.error = null;
      },
      createCertificateEditModel: (): void => {
        if (store.details) {
          store.certificateStore.setEditModel(store.details);
        }
      },
      createSettleStore: (): void => {
        if (store.details) {
          const payoutFrequency = store.details.find(
            (item) => item.label === "fd.fields.payoutFrequencyLabel",
          );
          const outstandingDepositAmount = store.details.find(
            (item) => item.label === "fd.fields.outstandingDepositAmountLabel",
          )?.cellType as DetailCellType.AmountType;
          const depositAmount = store.details.find(
            (item) => item.label === "fd.fields.depositAmountLabel",
          )?.cellType as DetailCellType.AmountType;

          let amount = 0;
          if (outstandingDepositAmount.amount) {
            amount = getAmount(outstandingDepositAmount.amount.amount);
          } else if (depositAmount.amount) {
            amount = getAmount(depositAmount.amount.amount);
          }
          if (depositAmount.amount) {
            store.settleStore.setModelData(
              (payoutFrequency?.cellType as DetailCellType.PayoutFrequencyType)
                .frequency === PayoutFrequency.PayoutFrequency.BULLET,
              amount,
              createCurrencyModel(depositAmount.amount.currency),
            );
          }
        }
      },
      getFdDealDetails: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        store.error = null;
        store.isLoading = true;
        yield store.investmentDetailsStore.getInvestmentDetails(requestId);
        const request = new GetFDDealRequestDetailsRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFDDealRequestDetailsRPC.Response,
          GetFDDealRequestDetailsRPC.Errors.Errors
        > = yield useGetFDDealRequestDetailsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.details = cast(response.requestDetails);
          store.requestActions = createFDDealRequestActionModel(
            response.requestAction,
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FDDealInvalidRequestError.InvalidRequestId:
              store.error = FDDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFDDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
      cancelDealRequest: flow(function* (requestId: number, note?: string) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        store.error = null;
        store.isLoading = true;
        const request = new CancelFDDealRequestRPC.Request(
          requestId,
          note ? new Note(note) : null,
        );
        const result: LeoRPCResult<
          CancelFDDealRequestRPC.Response,
          CancelFDDealRequestRPC.Errors.Errors
        > = yield useCancelFDDealRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          store.isLoading = false;
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FDDealInvalidRequestError.InvalidRequestId:
              store.error = FDDealInvalidRequestError.InvalidRequestId;
              break;
            case FDDealCancelError.RequestAlreadyCancelled:
              store.error = FDDealCancelError.RequestAlreadyCancelled;
              break;
            case FDDealCancelError.RequestAlreadyActive:
              store.error = FDDealCancelError.RequestAlreadyActive;
              break;
            case FDDealCancelError.RequestAlreadyRejected:
              store.error = FDDealCancelError.RequestAlreadyRejected;
              break;
            case FDDealCancelError.CanCancelOnlySelfRequest:
              store.error = FDDealCancelError.CanCancelOnlySelfRequest;
              break;
            case FDDealCancelError.RequestAlreadySettled:
              store.error = FDDealCancelError.RequestAlreadySettled;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFDDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
    };
  });

export const createFDDealDetailsStore = (): Instance<
  typeof FDDealRequestDetailsStore
> => {
  return FDDealRequestDetailsStore.create({
    isLoading: false,
    requestActions: createFDDealRequestActionModel(),
    historyStore: createFDDealRequestHistoryStore(),
    bannerInfoStore: createFDDealRequestBannerInfoStore(),
    checkFDDealRequestStore: createCheckFDDealRequestStore(),
    certificateStore: createFDCertificateStore(),
    settleStore: createFDSettleDealStore(),
    withdrawStore: createFDWithdrawStore(),
    investmentDetailsStore: createFdInvestmentDetailsStore(),
  });
};
