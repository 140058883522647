import { KHToggleButton } from "./KHToggleButton";
import React from "react";
import { ReportDateRange } from "../../equity/models/EquityReportDateRangeModel";
import { Instance } from "mobx-state-tree";
import { DateRangeModel } from "../../equity/models/DateRangeModel";
import { observer } from "mobx-react";
import { KHDateRangePicker } from "../date-picker/KHDateRangePicker";

export interface DurationToggleButtonProps {
  value: ReportDateRange | Instance<typeof DateRangeModel>;
  size?: "small" | "medium";
  onChange: (
    value: ReportDateRange | { startDate: Date | null; endDate: Date | null },
  ) => void;
}

export const DurationToggleButton = observer(
  ({
    value,
    size,
    onChange,
  }: DurationToggleButtonProps): React.ReactElement => {
    const [dateRange, setDateRange] =
      React.useState<[Date | null, Date | null]>();
    return (
      <KHToggleButton
        value={typeof value === "string" ? value : ReportDateRange.CUSTOM}
        size={size}
        items={[
          { label: "YTD", value: ReportDateRange.YTD },
          { label: "1m", value: ReportDateRange.ONE_MONTH },
          { label: "3m", value: ReportDateRange.THREE_MONTH },
          { label: "6m", value: ReportDateRange.SIX_MONTH },
          { label: "1y", value: ReportDateRange.ONE_YEAR },
          {
            label: (
              <KHDateRangePicker
                type="BUTTON"
                amountOfMonths={2}
                buttonSize={size}
                value={dateRange}
                onChange={(newRange): void => {
                  setDateRange(newRange);
                  onChange({
                    startDate: newRange[0],
                    endDate: newRange[1],
                  });
                }}
              />
            ),
            value: ReportDateRange.CUSTOM,
          },
        ]}
        onChange={(_event, selectedValue): void => {
          if (!selectedValue) {
            return;
          }
          if (selectedValue === ReportDateRange.CUSTOM) {
            onChange({ startDate: null, endDate: null });
            return;
          }
          onChange(selectedValue as ReportDateRange);
        }}
      />
    );
  },
);
