import React from "react";
import Tab from "@mui/material/Tab";
import {
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Typography, Tabs as MUITabs } from "@mui/material";
import { useBorder } from "../../../utils/BorderUtils";

/**
 * Creates a TabField
 * @param tabs - Array of label and isDisabled, where label are of type string which is used to display in the tab and isDisabled is a boolean which determines if the tab is disabled or not.
 * @param onTabChange - Takes a number as parameter and a callback is fired when the value is changed.
 * @param tabIndex - Index value of the tab.
 * @returns TabField component.
 */

export interface TabProps {
  label: string;
  isDisabled?: boolean;
}

export interface TabsProps {
  tabs: TabProps[];
  onTabChange: (index: number) => void;
  tabIndex: number;
}

const Size = {
  tabMaxWidth: "320px",
};

export const Tabs = ({
  tabs,
  onTabChange,
  tabIndex,
}: TabsProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const border = useBorder();

  const handleChange = (
    _event: React.SyntheticEvent,
    selectedIndex: number,
  ): void => {
    onTabChange(selectedIndex);
  };

  const getTextColor = (
    isDisabled: boolean | undefined,
    selectedIndex: number,
  ): string => {
    if (isDisabled) {
      return tokens.labelLowEmphasis;
    } else if (tabIndex === selectedIndex) {
      return tokens.labelPrimary;
    } else {
      return tokens.label;
    }
  };

  return (
    <MUITabs
      value={tabIndex}
      onChange={handleChange}
      aria-label="tabs"
      sx={{
        ".MuiButtonBase-root": { textTransform: "none" },
        ".MuiTabs-indicator": {
          borderBottom: border.tabIndicator,
        },
      }}
    >
      {tabs.map((tab, index) => {
        return (
          <Tab
            label={
              <Typography
                // -webkit-box-reflect is non-standard and is not on a standards track. It will not work for every user. There may also be large incompatibilities between implementations and the behavior may change in the future.
                sx={{
                  ...typography.s1,
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  color: getTextColor(tab.isDisabled, index),
                }}
              >
                {tab.label}
              </Typography>
            }
            disabled={tab.isDisabled}
            key={index}
            disableRipple={true}
            sx={{
              maxWidth: Size.tabMaxWidth,
            }}
          />
        );
      })}
    </MUITabs>
  );
};
