import { Instance, types } from "mobx-state-tree";
import { AccrualItem } from "@khazana/khazana-rpcs";
import { BigAmountModel, createBigAmountModel } from "./BigAmountModel";

export const AccrualItemModel = types.model({
  accrualId: types.number,
  accrualType: types.string,
  accrualPostedDate: types.Date,
  accrualAmount: BigAmountModel,
});

export const createAccrualItemModel = ({
  accrualId,
  accrualType,
  accrualPostedDate,
  accrualAmount,
}: AccrualItem): Instance<typeof AccrualItemModel> => {
  return AccrualItemModel.create({
    accrualId,
    accrualType,
    accrualPostedDate: new Date(accrualPostedDate.date),
    accrualAmount: createBigAmountModel(accrualAmount),
  });
};
