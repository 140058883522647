import { Divider, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  AlertCircle,
  ChevronLeft,
  ChevronRight,
  IconButton,
  LoadingIndicator,
  Plus,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Remove } from "../../../assets/Remove";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PdfProps {
  url?: string;
  isEdit?: boolean;
  zoom?: number;
}

const Size = {
  container: "50%",
  fullContainer: "100%",
  documentContainer: "calc(100vh - 286px)",
  errorIcon: "32px",
};

const enum Zoom {
  MAX = 3,
  MIN = 0.6,
  FACTOR = 0.1,
}

export const Pdf = ({
  url,
  zoom = 1.2,
  isEdit = true,
}: PdfProps): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(isEdit ? zoom : 1.8);
  const [previousDisabled, setPreviousDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [zoomIncreaseDisabled, setZoomIncreaseDisabled] = useState(true);
  const [zoomDecreaseDisabled, setZoomDecreaseDisabled] = useState(true);

  useEffect(() => {
    if (currentPage === 1 && totalPages >= 1) {
      setPreviousDisabled(true);
    } else {
      setPreviousDisabled(false);
    }

    if (currentPage === totalPages) {
      setNextDisabled(true);
    } else {
      setNextDisabled(false);
    }
  }, [totalPages, currentPage]);

  useEffect(() => {
    if (zoomLevel > Zoom.MAX) {
      setZoomIncreaseDisabled(true);
    } else {
      setZoomIncreaseDisabled(false);
    }
    if (zoomLevel <= Zoom.MIN) {
      setZoomDecreaseDisabled(true);
    } else {
      setZoomDecreaseDisabled(false);
    }
  }, [zoomLevel]);

  const onDocumentLoadSuccess = ({
    numPages: _numPages,
  }: {
    numPages: number;
  }): void => {
    setTotalPages(_numPages);
  };

  const getErrorComponent = (): React.ReactNode => {
    return (
      <Stack
        sx={{
          height: Size.documentContainer,
          width: ref.current?.clientWidth,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <AlertCircle
          width={Size.errorIcon}
          height={Size.errorIcon}
          color={tokens.labelError}
        />
        <Typography color={tokens.labelError} sx={{ ...typography.b1 }}>
          {t("contractNotes.failedToLoadPDF")}
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack
      ref={ref}
      sx={{ width: isEdit ? Size.container : Size.fullContainer }}
    >
      {url && (
        <>
          <Stack
            sx={{
              height: isEdit ? Size.documentContainer : Size.fullContainer,
              overflow: "auto",
              width: isEdit ? ref.current?.clientWidth : undefined,
              alignItems: isEdit ? undefined : "center",
            }}
          >
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              error={getErrorComponent}
              loading={
                <Stack
                  style={{
                    height: Size.documentContainer,
                    width: ref.current?.clientWidth,
                  }}
                >
                  <LoadingIndicator
                    isLoading={true}
                    variant="container"
                    loadingText={t("common.loading")}
                  />
                </Stack>
              }
            >
              <Page
                pageNumber={currentPage}
                scale={zoomLevel}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            paddingY={spacing.spaceMD}
            spacing={spacing.spaceMD}
            justifyContent={"center"}
          >
            <Stack direction={"row"} spacing={spacing.spaceXS}>
              <IconButton
                name={"previous"}
                size={"small"}
                icon={<ChevronLeft />}
                disabled={previousDisabled}
                variant={"outlined-neutral"}
                onClick={(): void => {
                  setCurrentPage(currentPage - 1);
                }}
              />
              <IconButton
                name={"next"}
                size={"small"}
                disabled={nextDisabled}
                icon={<ChevronRight />}
                variant={"outlined-neutral"}
                onClick={(): void => {
                  setCurrentPage(currentPage + 1);
                }}
              />
            </Stack>
            <Divider orientation="vertical" sx={{ color: tokens.border }} />
            <Stack direction={"row"} spacing={spacing.spaceXS}>
              <IconButton
                name={"zoom+"}
                size={"small"}
                disabled={zoomDecreaseDisabled}
                icon={<Remove />}
                variant={"outlined-color"}
                onClick={(): void => {
                  setZoomLevel(zoomLevel - Zoom.FACTOR);
                }}
              />
              <IconButton
                name={"zoom-"}
                size={"small"}
                disabled={zoomIncreaseDisabled}
                icon={<Plus />}
                variant={"outlined-color"}
                onClick={(): void => {
                  setZoomLevel(zoomLevel + Zoom.FACTOR);
                }}
              />
            </Stack>
          </Stack>
        </>
      )}
    </Stack>
  );
};
