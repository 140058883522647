import {
  applySnapshot,
  cast,
  clone,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { SchemeModel } from "../../models/SchemeModel";
import { getAPIClient } from "./hooks";
import { GetMFSchemeListRPC, MFTransactionType } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetMFSchemeListRPC } from "../../rpc/RPC";
import { createCurrencyModel } from "../../../../models/CurrencyModel";

export const MFSchemeSearchStore = types
  .model("MFSchemeSearchStore", {
    schemeList: types.array(SchemeModel),
    selectedScheme: types.maybe(SchemeModel),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setIsLoading: (isLoading: boolean): void => {
        store.isLoading = isLoading;
      },
      setSelectedScheme: (isin?: string | null): void => {
        if (isin) {
          store.selectedScheme = clone(
            store.schemeList.find((scheme) => scheme.isin === isin),
          );
        } else {
          store.selectedScheme = undefined;
        }
      },
      getMFSchemeList: flow(function* (
        searchText: string,
        transactionType: MFTransactionType.MFTransactionType,
        amcId?: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFSchemeListRPC.Request(
          searchText,
          transactionType,
          amcId ? Number(amcId) : null,
        );
        store.isLoading = true;
        const result: LeoRPCResult<
          GetMFSchemeListRPC.Response,
          GetMFSchemeListRPC.Errors.Errors
        > = yield useGetMFSchemeListRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.schemeList = cast(
            response.schemes.map((scheme) =>
              SchemeModel.create({
                isin: scheme.isin.isin,
                name: scheme.name,
                currency: createCurrencyModel(scheme.currency),
                amcId: scheme.amcId,
              }),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getMFSchemeListRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
    };
  });

export const createMFSchemeSearchStore = (): Instance<
  typeof MFSchemeSearchStore
> => {
  return MFSchemeSearchStore.create({});
};
