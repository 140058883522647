import { Stack } from "@mui/material";
import { useSpacing } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

const Size = {
  width: "1120px",
  height: "100vh",
};

interface DashboardContainerProps {
  elements: React.ReactElement[][];
}

export const DashboardContainer = ({
  elements,
}: DashboardContainerProps): React.ReactElement => {
  const spacing = useSpacing();
  return (
    <Stack height={Size.height}>
      <Stack
        spacing={spacing.space2XL}
        padding={spacing.space2XL}
        alignItems={"center"}
        overflow={"auto"}
        flexGrow={1}
      >
        {elements.map((row, index) => (
          <Stack
            key={index}
            direction="row"
            spacing={2}
            flexGrow={1}
            width={Size.width}
          >
            {row.map((element, idx) => (
              <Stack key={idx} width={"-webkit-fill-available"}>
                {element}
              </Stack>
            ))}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
