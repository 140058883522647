"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFDealRequestHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const mFDealRequestStatus_1 = require("./mFDealRequestStatus");
const mFDealRequestHistoryAdditionalDetails_1 = require("./mFDealRequestHistoryAdditionalDetails");
class InvalidMFDealRequestHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFDealRequestHistoryDetailError";
    }
}
class MFDealRequestHistoryDetail {
    constructor(requestNote, status, additionalDetail) {
        this.isMFDealRequestHistoryDetail = true;
        MFDealRequestHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        MFDealRequestHistoryDetail.validateStatus(status);
        this.status = status;
        MFDealRequestHistoryDetail.validateAdditionalDetail(additionalDetail);
        this.additionalDetail = additionalDetail;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidMFDealRequestHistoryDetailError));
        const status = mFDealRequestStatus_1.MFDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidMFDealRequestHistoryDetailError));
        const additionalDetail = (0, leo_ts_runtime_1.getList)(dto, "additionalDetail", mFDealRequestHistoryAdditionalDetails_1.MFDealRequestHistoryAdditionalDetails.fromDTO, InvalidMFDealRequestHistoryDetailError);
        return new MFDealRequestHistoryDetail(requestNote, status, additionalDetail);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: mFDealRequestStatus_1.MFDealRequestStatus.toDTO(this.status),
            additionalDetail: this.additionalDetail.map((e) => {
                return e.toDTO();
            }),
        };
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status, additionalDetail = this.additionalDetail) {
        return new MFDealRequestHistoryDetail(requestNote, status, additionalDetail);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFDealRequestHistoryDetailValue = other.isMFDealRequestHistoryDetail;
        if (!(other instanceof MFDealRequestHistoryDetail) || Boolean(isMFDealRequestHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.additionalDetail, other.additionalDetail)) {
            return false;
        }
        return true;
    }
}
exports.MFDealRequestHistoryDetail = MFDealRequestHistoryDetail;
MFDealRequestHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidMFDealRequestHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
MFDealRequestHistoryDetail.validateStatus = (status) => {
    if (!mFDealRequestStatus_1.MFDealRequestStatus.isInstanceOf(status)) {
        throw new InvalidMFDealRequestHistoryDetailError(`Attribute status is not a MFDealRequestStatus.MFDealRequestStatus`);
    }
};
MFDealRequestHistoryDetail.validateAdditionalDetail = (additionalDetail) => {
    if (!Array.isArray(additionalDetail)) {
        throw new InvalidMFDealRequestHistoryDetailError(`Attribute additionalDetail is not a List`);
    }
    for (let i = 0; i < additionalDetail.length; i += 1) {
        if (!mFDealRequestHistoryAdditionalDetails_1.MFDealRequestHistoryAdditionalDetails.isInstanceOf(additionalDetail[i])) {
            throw new InvalidMFDealRequestHistoryDetailError(`Attribute additionalDetail at index ${i} is not a MFDealRequestHistoryAdditionalDetails.`);
        }
    }
};
MFDealRequestHistoryDetail.prototype.toString = function toString() {
    return `MFDealRequestHistoryDetail(requestNote=${this.requestNote},status=${this.status},additionalDetail=${this.additionalDetail})`;
};
