import { flow, getEnv, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetPresignedDownloadUrlRPC, SHA256 } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetPresignedDownloadUrlRPC } from "../rpcs/RPC";

export const DocumentStore = types.model("DocumentStore").actions((store) => {
  return {
    getPresignedDownloadUrl: flow<string | undefined, [string]>(function* (
      sha256: string,
    ) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      const request = new GetPresignedDownloadUrlRPC.Request(
        new SHA256(sha256),
      );
      const result: LeoRPCResult<
        GetPresignedDownloadUrlRPC.Response,
        GetPresignedDownloadUrlRPC.Errors.Errors
      > = yield useGetPresignedDownloadUrlRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.downloadURL.toString();
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          default:
            logger.error(
              `Unhandled error: ${error} occurred in getPresignedDownloadUrlRPC`,
            );
        }
      } else {
        logger.error("Unhandled error");
      }
    }),
  };
});

export const createDocumentStore = (): Instance<typeof DocumentStore> => {
  return DocumentStore.create({});
};
