import React from "react";
import { ReportGrid } from "../../../components/reports/ReportGrid";
import { ReportCardProps } from "../../../components/reports/ReportCard";
import { RefreshCW } from "../../../../../assets/RefreshCW";
import { useTranslation } from "react-i18next";
import {
  PageHeader,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ClipboardList } from "../../../../../assets/ClipboardList";
import { useUserStore } from "../../../store/hooks";
import { EquityUserPrivileges } from "../../../../user/UserPrivileges";

const Size = {
  icon: "24px",
};

export const EqReportList = (): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const privileges = useUserStore().privileges;

  const reports: ReportCardProps[] = [];
  if (privileges.includes(EquityUserPrivileges.ViewEquityChangeInGainReport)) {
    reports.push({
      icon: (
        <RefreshCW
          height={Size.icon}
          width={Size.icon}
          color={tokens.iconPrimary}
        />
      ),
      name: t("equity.reports.changeInGain"),
      subtitle: t("equity.reports.changeInGainSubtitle"),
      path: getPath(Module.Equity, Route.ChangeInGain),
    });
  }

  if (privileges.includes(EquityUserPrivileges.ViewEquityHoldingSummary)) {
    reports.push({
      icon: (
        <ClipboardList
          height={Size.icon}
          width={Size.icon}
          color={tokens.iconPrimary}
        />
      ),
      name: t("equity.reports.holdingSummary"),
      subtitle: t("equity.reports.holdingSummarySubtitle"),
      path: getPath(Module.Equity, Route.HoldingSummary),
    });
  }
  return (
    <>
      <PageHeader title={t("common.reports")} />
      <ReportGrid reports={reports} />
    </>
  );
};
