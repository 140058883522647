import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  GetInvestmentCashflowBannerInfoRPC,
  ModuleType,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetInvestmentCashflowBannerInfoRPC } from "../../rpcs/RPC";
import { DiffCellType } from "../../utils/DiffDetailCellUtils";
import {
  BigAmountModel,
  createBigAmountModel,
} from "../../models/BigAmountModel";
import {
  createSettledCashflowModel,
  SettledCashflowModel,
} from "../../models/SettledCashflowModel";
import {
  createPendingCashflowModel,
  PendingCashflowModel,
} from "../../models/PendingCashflowModel";

export const InvestmentCashflowBannerStore = types
  .model("InvestmentCashflowBannerStore", {
    totalInvestedValue: types.maybe(BigAmountModel),
    amountPaidOut: types.maybe(BigAmountModel),
    accrualTillDate: types.maybe(BigAmountModel),
    settledCashflow: types.maybe(SettledCashflowModel),
    pendingCashflow: types.maybe(PendingCashflowModel),
    isLoading: types.optional(types.boolean, false),
    error: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    setisLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    setError: (error: string): void => {
      store.error = error;
    },
    getInvestmentCashflowBannerDetails: flow(function* (
      investmentId: number | undefined,
      maturityId: number | undefined,
      module: ModuleType.ModuleType,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      store.error = undefined;
      store.isLoading = true;
      const request = new GetInvestmentCashflowBannerInfoRPC.Request(
        investmentId,
        maturityId,
        module,
      );
      const result: LeoRPCResult<
        GetInvestmentCashflowBannerInfoRPC.Response,
        GetInvestmentCashflowBannerInfoRPC.Errors.Errors
      > =
        yield useGetInvestmentCashflowBannerInfoRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.totalInvestedValue = createBigAmountModel(
          response.totalInvestment,
        );
        store.amountPaidOut = response.amountPaidOut
          ? createBigAmountModel(response.amountPaidOut)
          : undefined;
        store.accrualTillDate = response.accrualsTillDate
          ? createBigAmountModel(response.accrualsTillDate)
          : undefined;
        store.settledCashflow = createSettledCashflowModel(
          response.settledCashflows,
        );
        store.pendingCashflow = createPendingCashflowModel(
          response.pendingCashflows,
        );
      } else {
        store.error = result.error.code;
        logger.error(
          `Unhandled Error: ${result.error} from GetInvestmentCashflowBannerInfoRPC`,
        );
      }
      store.isLoading = false;
    }),
  }));

export const createInvestmentCashflowBannerStore = (): Instance<
  typeof InvestmentCashflowBannerStore
> => {
  return InvestmentCashflowBannerStore.create();
};

export const createInvestmentCashflowBannerStoreFromDiff = (
  diff: DiffCellType | undefined,
): Instance<typeof InvestmentCashflowBannerStore> => {
  if (!diff) {
    return createInvestmentCashflowBannerStore();
  }
  return InvestmentCashflowBannerStore.create({});
};
