import {
  FiBuyDealValue,
  FiCollection,
  FiPriceYtm,
  FiSellDealValue,
  FiYieldType,
  FixedIncomeInstrumentType,
  GetAvailableBankBalanceRPC,
  ISIN,
  Quantity,
  YieldObject,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { CreateFiDealRequestError } from "./CreateFiDealRequestError";
import { PortfolioModel } from "../../../models/PortfolioModel";
import { DematAccountNumberModel } from "../../../models/DematAccountModel";
import { EntityModel } from "../../../models/EntityModel";
import { SecurityModel } from "../../../models/SecurityModel";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { FiDealType } from "../../../../../types/EnumTypes";
import { CreateFiBuyDealRequestStore } from "./CreateFiBuyDealRequestStore";
import { CreateFiSellDealRequestStore } from "./CreateFiSellDealRequestStore";
import { AMOUNT_LIMIT, getAmount, getServerAmount } from "../../../../../utils";
import { convertStringToNumber } from "../../../utils/UIUtils";
import {
  AutoCompleteSeparateLabelItem,
  DropdownItem,
} from "@surya-digital/leo-reactjs-core";
import { createServerTransactionAmountRPCType } from "../../../models/AmountModel";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { useGetAvailableBankBalanceRPCClientImpl } from "../../../rpcs/RPC";
import { ImpactTableModel } from "../../../models/ImpactTableModel";
import {
  createFiDealSummaryModel,
  FiDealSummaryModel,
} from "../models/FiDealSummaryModel";
import {
  BankAccountDropdownStore,
  createBankAccountDropdownStore,
} from "../../../store/BankAccountDropdownStore";
import {
  createDepositBankDropdownStore,
  DepositBankDropdownStore,
} from "../../../store/DepositBankDropdownStore";

// CreateDealRequestDisabledFields is used to store the disabled state of field associated with store value property name.
interface CreateFiDealRequestDisabledFields {
  grossAmount: boolean;
  quantity: boolean;
  price: boolean;
  entity: boolean;
  portfolio: boolean;
  bank: boolean;
  bankAccount: boolean;
  dematAccount: boolean;
  yieldToMaturity: boolean;
  note: boolean;
  createDeal: boolean;
}

// CreateDealRequestFieldErrors is used to store the error message of field associated with store value property name.
export const CreateFiDealRequestFieldErrors = types.model({
  security: types.maybeNull(types.string),
  grossAmount: types.maybeNull(types.string),
  quantity: types.maybeNull(types.string),
  price: types.maybeNull(types.string),
  entity: types.maybeNull(types.string),
  portfolio: types.maybeNull(types.string),
  bank: types.maybeNull(types.string),
  bankAccount: types.maybeNull(types.string),
  dematAccount: types.maybeNull(types.string),
  yieldToMaturity: types.maybeNull(types.string),
});

const getFiCollection = (
  portfolio: string | null,
  dematAccount: string | null,
  showFiPortfolio: boolean,
  showFiDematAccount: boolean,
): FiCollection.FiCollection | null => {
  if (showFiPortfolio && portfolio) {
    if (showFiDematAccount && dematAccount) {
      return new FiCollection.PortfolioDematAccount(
        new LeoUUID(portfolio),
        dematAccount,
      );
    } else {
      return new FiCollection.Portfolio(new LeoUUID(portfolio));
    }
  } else if (showFiDematAccount && dematAccount) {
    return new FiCollection.DematAccount(dematAccount);
  }
  return null;
};

export const CreateFiDealRequestStore = types
  .model("CreateFiDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<CreateFiDealRequestError>(
        "CreateFiDealRequestError",
        Object.values(CreateFiDealRequestError),
      ),
    ),
    fieldErrors: types.maybeNull(CreateFiDealRequestFieldErrors),
    securityType:
      types.enumeration<FixedIncomeInstrumentType.FixedIncomeInstrumentType>(
        "FixedIncomeInstrumentType",
        Object.values(FixedIncomeInstrumentType.FixedIncomeInstrumentType),
      ),
    securityList: types.array(SecurityModel),
    entityList: types.array(EntityModel),
    portfolioList: types.array(PortfolioModel),
    dematAccountNumberList: types.array(DematAccountNumberModel),
    fiSummaryDetails: types.maybeNull(FiDealSummaryModel),
    isin: types.maybeNull(types.string),
    grossAmount: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
    price: types.maybeNull(types.number),
    yieldToMaturity: types.maybeNull(types.number),
    entity: types.maybeNull(types.string),
    portfolio: types.maybeNull(types.string),
    bankAccount: BankAccountDropdownStore,
    bank: DepositBankDropdownStore,
    dematAccount: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    availableBalance: types.maybeNull(types.number),
    // errorGrossQuantity to store data related to INSUFFICIENT_GROSS_QUANTITY error code in Sell flow.
    errorGrossQuantity: types.maybeNull(types.number),
    // errorNetQuantity to store data related to INSUFFICIENT_NET_QUANTITY error code in Sell flow.
    errorNetQuantity: types.maybeNull(types.number),
    yieldType: types.maybeNull(
      types.enumeration<FiYieldType.FiYieldType>(
        "FiYieldType",
        Object.values(FiYieldType.FiYieldType),
      ),
    ),
    impactTableModel: types.maybeNull(ImpactTableModel),
    createFiBuyDealRequestStore: CreateFiBuyDealRequestStore,
    createFiSellDealRequestStore: CreateFiSellDealRequestStore,
  })
  .views((store) => ({
    disableFields: (): CreateFiDealRequestDisabledFields => ({
      grossAmount:
        !store.isin ||
        Boolean(store.quantity) ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      quantity:
        !store.isin ||
        Boolean(store.grossAmount) ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      price:
        !store.isin ||
        Boolean(store.yieldToMaturity) ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      entity:
        !store.isin ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      portfolio:
        !store.isin ||
        !store.entity ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      bank:
        !store.isin ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      bankAccount:
        !store.isin ||
        !store.bank.selectedBankId ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      dematAccount:
        !store.isin ||
        !store.entity ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      note:
        !store.isin ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      yieldToMaturity:
        !store.isin ||
        Boolean(store.price) ||
        store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
      createDeal:
        store.error ===
        CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound,
    }),
    get selectedBank(): AutoCompleteSeparateLabelItem | null | undefined {
      const selectedBank = store.bank.bankList.find(
        (bank) => bank.id === store.bank.selectedBankId,
      );
      return selectedBank
        ? { label: selectedBank.name, id: selectedBank.id }
        : undefined;
    },
    get selectedBankAccount():
      | AutoCompleteSeparateLabelItem
      | null
      | undefined {
      const selectedAccount =
        store.bankAccount.selectedBankAccount?.bankAccountNumber;
      return selectedAccount
        ? { id: selectedAccount, label: selectedAccount }
        : undefined;
    },
    getCurrencyModelFromSelectedSecurity: (): Instance<
      typeof CurrencyModel
    > | null => {
      const selectedSecurity = store.securityList.find(
        ({ isin }) => isin.isin === store.isin,
      );
      if (!selectedSecurity) return null;
      return selectedSecurity.currency;
    },
    getCurrencySymbolPostfixString: (): string => {
      const selectedSecurity = store.securityList.find(
        ({ isin }) => isin.isin === store.isin,
      );
      if (!selectedSecurity?.currency) {
        return "";
      } else {
        return ` (${selectedSecurity.currency.symbol})`;
      }
    },
    isAmountValid: (): boolean => {
      if (store.quantity && store.price) {
        if (
          store.createFiBuyDealRequestStore.error ===
            CreateFiDealRequestError.AmountLimitExceeded ||
          store.createFiSellDealRequestStore.error ===
            CreateFiDealRequestError.AmountLimitExceeded ||
          store.quantity * store.price > AMOUNT_LIMIT
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
  }))
  .actions((store) => ({
    performFiImpactOnPortfolioChecks: (
      dealType: FiDealType,
    ):
      | FiBuyDealValue.FiBuyDealValue
      | FiSellDealValue.FiSellDealValue
      | null => {
      const logger = getEnv(store).logger;
      const currency = store.getCurrencyModelFromSelectedSecurity();

      if (!currency) {
        logger.error("Currency could not retrieved from security list");
        return null;
      }

      if (store.isin === null) {
        logger.error("No security selected");
        store.error = CreateFiDealRequestError.InvalidISIN;
        return null;
      }

      let buyDealValue: FiBuyDealValue.FiBuyDealValue | null = null;
      let sellDealValue: FiSellDealValue.FiSellDealValue | null = null;

      switch (dealType) {
        case FiDealType.Purchase: {
          if (store.grossAmount && Number(store.grossAmount)) {
            buyDealValue = new FiBuyDealValue.DealAmount(
              createServerTransactionAmountRPCType(store.grossAmount, currency),
            );
          } else if (
            store.quantity &&
            store.yieldToMaturity &&
            Boolean(store.yieldToMaturity)
          ) {
            buyDealValue = new FiBuyDealValue.DealQuantity(
              new Quantity(store.quantity),
            );
          } else {
            logger.error("Neither amount nor quantity entered");
            store.impactTableModel = null;
            return null;
          }
          break;
        }
        case FiDealType.Sell: {
          if (
            store.quantity &&
            store.yieldToMaturity &&
            Boolean(store.yieldToMaturity)
          ) {
            sellDealValue = new FiSellDealValue.DealQuantity(
              new Quantity(store.quantity),
            );
          } else {
            logger.error("quantity is not present");
            store.impactTableModel = null;
            return null;
          }
          break;
        }
      }

      return buyDealValue ? buyDealValue : sellDealValue;
    },
    setSecurityType: (type: string): void => {
      store.securityType = FixedIncomeInstrumentType.fromDTO({ case: type });
    },
    clearAvailableBalance: (): void => {
      store.availableBalance = null;
    },
    setErrorGrossQuantity: (value: number): void => {
      store.errorGrossQuantity = value;
    },
    setErrorNetQuantity: (value: number): void => {
      store.errorNetQuantity = value;
    },
    validateAvailableBalanceWithGrossAmount: (): void => {
      if (
        store.grossAmount &&
        store.availableBalance &&
        store.grossAmount > store.availableBalance
      ) {
        store.createFiBuyDealRequestStore.errorBankBalance = true;
      } else {
        store.createFiBuyDealRequestStore.errorBankBalance = false;
      }
    },
    validateAvailableBalanceWithQuantity: (): void => {
      if (
        store.quantity &&
        store.availableBalance &&
        store.price &&
        store.quantity * store.price > store.availableBalance
      ) {
        store.createFiBuyDealRequestStore.errorBankBalance = true;
      } else {
        store.createFiBuyDealRequestStore.errorBankBalance = false;
      }
    },
  }))
  .actions((store) => ({
    getAvailableBankBalance: flow(function* () {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        if (
          store.bankAccount.selectedBankAccount?.bankAccountNumber !== undefined
        ) {
          const request = new GetAvailableBankBalanceRPC.Request(
            new GetAvailableBankBalanceRPC.RequestEnums.AccountNumber.BankAccount(
              store.bankAccount.selectedBankAccount.bankAccountNumber,
            ),
          );
          const result: LeoRPCResult<
            GetAvailableBankBalanceRPC.Response,
            GetAvailableBankBalanceRPC.Errors.Errors
          > =
            yield useGetAvailableBankBalanceRPCClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.availableBalance = getAmount(
              response.availableBalance?.amount,
            );
            if (store.grossAmount) {
              store.validateAvailableBalanceWithGrossAmount();
            } else {
              store.validateAvailableBalanceWithQuantity();
            }
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetAvailableBankBalanceRPC`,
            );
          }
        } else {
          logger.error("Demat account is not present");
        }
      } catch (error) {
        logger.error(
          `Unknown Error: ${error} occured in GetAvailableBankBalanceRPC`,
        );
      }
    }),
    getSecurityList: flow(function* (
      dealType: FiDealType,
      securityType: FixedIncomeInstrumentType.FixedIncomeInstrumentType,
      searchText: string,
    ) {
      switch (dealType) {
        case FiDealType.Purchase:
          store.securityList =
            yield store.createFiBuyDealRequestStore.getBuySecurityList(
              searchText,
              securityType,
            );
          break;
        case FiDealType.Sell:
          store.securityList =
            yield store.createFiSellDealRequestStore.getSellSecurityList(
              searchText,
              securityType,
            );
          break;
      }
      return store.securityList;
    }),
    getFiDealSummaryDetails: flow(function* (dealType: FiDealType) {
      const logger = getEnv(store).logger;
      const currency = store.getCurrencyModelFromSelectedSecurity();
      if (!currency) {
        logger.error("Currency could not retrieved from security list");
        return;
      }

      switch (dealType) {
        case FiDealType.Purchase: {
          let buyDealValue: FiBuyDealValue.FiBuyDealValue | null = null;

          if (store.grossAmount && Number(store.grossAmount)) {
            buyDealValue = new FiBuyDealValue.DealAmount(
              createServerTransactionAmountRPCType(store.grossAmount, currency),
            );
          } else if (store.quantity) {
            buyDealValue = new FiBuyDealValue.DealQuantity(
              new Quantity(store.quantity),
            );
          }
          let priceYtm: FiPriceYtm.FiPriceYtm | null = null;
          if (store.yieldToMaturity) {
            priceYtm = new FiPriceYtm.YieldToMaturity(
              new YieldObject(getServerAmount(store.yieldToMaturity)),
            );
          } else if (store.price) {
            priceYtm = new FiPriceYtm.Price(
              createServerTransactionAmountRPCType(store.price, currency),
            );
          }
          if (store.isin) {
            const data = createFiDealSummaryModel(
              yield store.createFiBuyDealRequestStore.getFiBuyDealSummaryDetails(
                buyDealValue,
                new ISIN(store.isin),
                priceYtm,
              ),
            );
            store.fiSummaryDetails = data;
          } else {
            store.fiSummaryDetails = null;
            logger.error("buyDealValue, isin and yieldToMaturity are required");
          }
          store.error = store.createFiBuyDealRequestStore.error;
          store.createFiBuyDealRequestStore.error = null;
          break;
        }
        case FiDealType.Sell: {
          let sellDealValue: FiSellDealValue.FiSellDealValue | null = null;
          if (store.quantity) {
            sellDealValue = new FiSellDealValue.DealQuantity(
              new Quantity(store.quantity),
            );
          }

          let priceYtm: FiPriceYtm.FiPriceYtm | null = null;
          if (store.yieldToMaturity) {
            priceYtm = new FiPriceYtm.YieldToMaturity(
              new YieldObject(getServerAmount(store.yieldToMaturity)),
            );
          } else if (store.price) {
            priceYtm = new FiPriceYtm.Price(
              createServerTransactionAmountRPCType(store.price, currency),
            );
          }
          if (store.isin) {
            store.fiSummaryDetails = createFiDealSummaryModel(
              yield store.createFiSellDealRequestStore.getFiSellDealSummaryDetails(
                sellDealValue,
                new ISIN(store.isin),
                priceYtm,
              ),
            );
          } else {
            store.fiSummaryDetails = null;
            logger.error(
              "sellDealValue, isin and yieldToMaturity are required",
            );
          }
          store.error = store.createFiSellDealRequestStore.error;
          store.createFiSellDealRequestStore.error = null;
          break;
        }
      }
    }),
    setSecurity: flow(function* (value: string, dealType: FiDealType) {
      const logger = getEnv(store).logger;
      store.isin = value ?? null;
      store.fieldErrors = null;
      store.error = null;

      if (value) {
        switch (dealType) {
          case FiDealType.Purchase:
            store.yieldType =
              yield store.createFiBuyDealRequestStore.getFiBuyShowYtm(
                new ISIN(store.isin),
              );
            store.error = store.createFiBuyDealRequestStore.error;
            store.entityList =
              yield store.createFiBuyDealRequestStore.getBuyEntityList();
            break;
          case FiDealType.Sell:
            if (!store.isin) {
              logger.error("isin is null, sell entity list cannot be fetched.");
              return;
            }
            store.yieldType =
              yield store.createFiSellDealRequestStore.getFiSellShowYtm(
                store.isin,
              );
            store.error = store.createFiSellDealRequestStore.error;
            store.entityList =
              yield store.createFiSellDealRequestStore.getSellEntityList(
                new ISIN(store.isin),
              );
            break;
        }
        yield store.bank.getBankList();
      }
    }),
    setEntity: flow(function* (
      { value }: DropdownItem,
      dealType: FiDealType,
      showEntityPortfolio: boolean,
      showFiDematAccount: boolean,
    ) {
      const logger = getEnv(store).logger;
      store.portfolio = null;
      store.entity = value;
      store.fieldErrors = null;
      store.availableBalance = null;
      store.dematAccount = null;
      store.createFiBuyDealRequestStore.errorBankBalance = false;
      const entityId = new LeoUUID(value);
      if (showEntityPortfolio) {
        switch (dealType) {
          case FiDealType.Purchase:
            store.portfolioList =
              yield store.createFiBuyDealRequestStore.getBuyPortfolioList(
                entityId,
              );
            break;
          case FiDealType.Sell:
            if (!store.isin) {
              logger.error(
                "Developer Error: Security is null, sell portfolio account number list cannot be fetched.",
              );
              return;
            }
            store.portfolioList =
              yield store.createFiSellDealRequestStore.getSellPortfolioList(
                new ISIN(store.isin),
                entityId,
              );
        }
      }
      if (showFiDematAccount) {
        switch (dealType) {
          case FiDealType.Purchase:
            store.dematAccountNumberList =
              yield store.createFiBuyDealRequestStore.getBuyDematAccountNumberList(
                entityId,
              );
            break;
          case FiDealType.Sell:
            if (!store.isin) {
              logger.error(
                "Security is null, sell demat account number list cannot be fetched.",
              );
              return;
            }
            store.dematAccountNumberList =
              yield store.createFiSellDealRequestStore.getSellDematAccountNumberList(
                new ISIN(store.isin),
                entityId,
              );
            break;
        }
      }
    }),
    createFiDealRequest: flow(function* (
      dealType: FiDealType,
      showFiPortfolio: boolean,
      showFiDematAccount: boolean,
    ) {
      const logger = getEnv(store).logger;
      const currency = store.getCurrencyModelFromSelectedSecurity();
      if (
        !store.isin ||
        !store.entity ||
        !store.bank.selectedBankId ||
        !store.bankAccount.selectedBankAccount ||
        !currency ||
        !store.fieldErrors ||
        Object.values(store.fieldErrors).some((value) => value !== "")
      ) {
        return;
      }
      const fiCollection = getFiCollection(
        store.portfolio,
        store.dematAccount,
        showFiPortfolio,
        showFiDematAccount,
      );
      if (!fiCollection) {
        logger.error("Both fi portfolio and fi demat account are hidden");
        return;
      }

      switch (dealType) {
        case FiDealType.Purchase: {
          let buyDealValue: FiBuyDealValue.FiBuyDealValue | null = null;
          if (store.grossAmount) {
            buyDealValue = new FiBuyDealValue.DealAmount(
              createServerTransactionAmountRPCType(store.grossAmount, currency),
            );
          } else if (store.quantity) {
            buyDealValue = new FiBuyDealValue.DealQuantity(
              new Quantity(store.quantity),
            );
          }
          let priceYtm: FiPriceYtm.FiPriceYtm | null = null;
          if (store.yieldToMaturity) {
            priceYtm = new FiPriceYtm.YieldToMaturity(
              new YieldObject(getServerAmount(store.yieldToMaturity)),
            );
          } else if (store.price) {
            priceYtm = new FiPriceYtm.Price(
              createServerTransactionAmountRPCType(store.price, currency),
            );
          }
          if (buyDealValue && priceYtm) {
            const dealRequestId =
              yield store.createFiBuyDealRequestStore.createFiBuyDealRequest(
                store.isin,
                new LeoUUID(store.entity),
                fiCollection,
                buyDealValue,
                store.note,
                priceYtm,
                new LeoUUID(store.bank.selectedBankId),
                store.bankAccount.selectedBankAccount.bankAccountNumber,
              );
            store.error = store.createFiBuyDealRequestStore.error;
            store.createFiBuyDealRequestStore.error = null;
            return dealRequestId;
          }

          break;
        }
        case FiDealType.Sell: {
          let sellDealValue: FiSellDealValue.FiSellDealValue | null = null;
          if (store.quantity) {
            sellDealValue = new FiSellDealValue.DealQuantity(
              new Quantity(store.quantity),
            );
          }
          let priceYtm: FiPriceYtm.FiPriceYtm | null = null;
          if (store.yieldToMaturity) {
            priceYtm = new FiPriceYtm.YieldToMaturity(
              new YieldObject(getServerAmount(store.yieldToMaturity)),
            );
          } else if (store.price) {
            priceYtm = new FiPriceYtm.Price(
              createServerTransactionAmountRPCType(store.price, currency),
            );
          }
          if (sellDealValue && store.yieldToMaturity && priceYtm) {
            const dealRequestId =
              store.createFiSellDealRequestStore.createSellFiDealRequest(
                store.isin,
                new LeoUUID(store.entity),
                fiCollection,
                sellDealValue,
                store.note,
                priceYtm,
                new LeoUUID(store.bank.selectedBankId),
                store.bankAccount.selectedBankAccount.bankAccountNumber,
                store.setErrorGrossQuantity,
                store.setErrorNetQuantity,
              );
            store.error = store.createFiSellDealRequestStore.error;
            store.createFiSellDealRequestStore.error = null;
            return dealRequestId;
          }
          break;
        }
      }
    }),
    getFiImpactOnPortfolio: flow(function* (dealType: FiDealType) {
      const dealValue = store.performFiImpactOnPortfolioChecks(dealType);
      const currencyModel = store.getCurrencyModelFromSelectedSecurity();

      if (
        !store.isin ||
        !store.entity ||
        !store.portfolio ||
        !dealValue ||
        !currencyModel
      ) {
        return;
      }
      let priceYtm = null;
      if (store.yieldToMaturity) {
        priceYtm = new FiPriceYtm.YieldToMaturity(
          new YieldObject(getServerAmount(store.yieldToMaturity)),
        );
      } else if (store.price) {
        priceYtm = new FiPriceYtm.Price(
          createServerTransactionAmountRPCType(store.price, currencyModel),
        );
      }
      if (!priceYtm) {
        return;
      }
      switch (dealType) {
        case FiDealType.Purchase: {
          store.impactTableModel =
            yield store.createFiBuyDealRequestStore.getFiBuyImpactOnPortfolio(
              store.isin,
              new LeoUUID(store.entity),
              new LeoUUID(store.portfolio),
              dealValue,
              priceYtm,
            );
          break;
        }
        case FiDealType.Sell:
          if (dealValue instanceof FiSellDealValue.DealQuantity) {
            store.impactTableModel =
              yield store.createFiSellDealRequestStore.getFiSellImpactOnPortfolio(
                store.isin,
                new LeoUUID(store.entity),
                new LeoUUID(store.portfolio),
                dealValue,
                priceYtm,
              );
            break;
          }
      }
      return store.impactTableModel;
    }),
    setGrossAmount: (value: string): void => {
      store.createFiBuyDealRequestStore.errorBankBalance = false;
      store.grossAmount = convertStringToNumber(value);
      store.validateAvailableBalanceWithGrossAmount();
      store.fieldErrors = null;
    },
    setYieldToMaturity: (value: string): void => {
      store.yieldToMaturity = convertStringToNumber(value);
      store.fieldErrors = null;
      if (store.grossAmount) {
        store.validateAvailableBalanceWithGrossAmount();
      } else {
        store.validateAvailableBalanceWithQuantity();
      }
    },
    setPortfolio: ({ value }: DropdownItem): void => {
      store.portfolio = value;
      store.fieldErrors = null;
    },
    setDematAccount: ({ value }: DropdownItem): void => {
      store.dematAccount = value;
      store.fieldErrors = null;
      store.createFiBuyDealRequestStore.errorBankBalance = false;
    },
    setQuantity: (value: string): void => {
      store.createFiBuyDealRequestStore.errorBankBalance = false;
      store.quantity = convertStringToNumber(value);
      store.fieldErrors = null;
      store.validateAvailableBalanceWithQuantity();
    },
    setPrice: (value: string): void => {
      store.price = convertStringToNumber(value);
      store.fieldErrors = null;
    },
    setFieldErrors: (
      errors: Instance<typeof CreateFiDealRequestFieldErrors>,
    ): void => {
      store.fieldErrors = errors;
    },
    setNote: (value: string): void => {
      store.note = value;
    },
    clearStoreError: (): void => {
      store.error = null;
      store.errorGrossQuantity = null;
      store.errorNetQuantity = null;
      store.createFiBuyDealRequestStore.errorBankBalance = false;
    },
    resetFiImpactTableModel: (): void => {
      store.impactTableModel = null;
    },
    resetFiTransactionDetails: (): void => {
      store.fiSummaryDetails = null;
    },
    clearFields: (): void => {
      store.error = null;
      store.fieldErrors = null;
      store.entityList = cast([]);
      store.portfolioList = cast([]);
      store.dematAccountNumberList = cast([]);
      store.fiSummaryDetails = null;
      store.grossAmount = null;
      store.quantity = null;
      store.price = null;
      store.entity = null;
      store.portfolio = null;
      store.dematAccount = null;
      store.bank.setSelectedBank(undefined);
      store.bankAccount.setSelectedAccount(undefined);
      store.availableBalance = null;
      store.note = null;
      store.yieldToMaturity = null;
    },
    clearStore: (): void => {
      store.yieldType = null;
      store.error = null;
      store.fieldErrors = null;
      store.securityList = cast([]);
      store.entityList = cast([]);
      store.portfolioList = cast([]);
      store.dematAccountNumberList = cast([]);
      store.fiSummaryDetails = null;
      store.isin = null;
      store.grossAmount = null;
      store.quantity = null;
      store.price = null;
      store.entity = null;
      store.portfolio = null;
      store.dematAccount = null;
      store.bank.setSelectedBank(undefined);
      store.bankAccount.setSelectedAccount(undefined);
      store.availableBalance = null;
      store.note = null;
      store.impactTableModel = null;
      store.yieldToMaturity = null;
    },
  }));

export const createCreateFiDealRequestStore = (): Instance<
  typeof CreateFiDealRequestStore
> => {
  return CreateFiDealRequestStore.create({
    securityType: FixedIncomeInstrumentType.FixedIncomeInstrumentType.G_SEC,
    createFiBuyDealRequestStore: CreateFiBuyDealRequestStore.create(),
    createFiSellDealRequestStore: CreateFiSellDealRequestStore.create(),
    bankAccount: createBankAccountDropdownStore(),
    bank: createDepositBankDropdownStore(),
  });
};
