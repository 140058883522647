import React from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import { observer } from "mobx-react";
import { getEquityRoutes } from "./EquityRoutes";
import { useHomeStore, useUserStore } from "../modules/home/store/hooks";
import { RootPage } from "../modules/root/pages/RootPage";
import { HomeLayout } from "../modules/home/layouts/HomeLayout";
import { PageNotFound } from "../modules/error/pages/PageNotFound";
import { InternalServerError } from "../modules/error/pages/InternalServerError";
import { ErrorLayout } from "../modules/error/layout/ErrorLayout";
import { SignIn } from "../modules/auth/pages/SignIn";
import { Route } from "./RoutesEnum";
import { getSettingsRoutes } from "./SettingsRoutes";
import { Instance } from "mobx-state-tree";
import { getFiRoutes } from "./FiRoutes";
import { getFdRoutes } from "./FdRoutes";
import { HomeStore } from "../modules/home/store/HomeStore";
import { getMFRoutes } from "./MFRoutes";

export const homeRoutes = (
  userPrivileges: string[],
  store: Instance<typeof HomeStore>,
): RouteObject[] => {
  const homeRoutesChildren: RouteObject[] = [];
  if (store.isEquityEnabled) {
    homeRoutesChildren.push(...getEquityRoutes(userPrivileges));
  }
  if (store.isFIEnabled) {
    homeRoutesChildren.push(...getFiRoutes(userPrivileges));
  }
  if (store.isFDEnabled) {
    homeRoutesChildren.push(...getFdRoutes(userPrivileges));
  }
  if (store.isMFEnabled) {
    homeRoutesChildren.push(...getMFRoutes(userPrivileges));
  }
  homeRoutesChildren.push(...getSettingsRoutes(store.settingsStore));

  return [
    {
      path: "/",
      element: <HomeLayout />,
      children: [...homeRoutesChildren],
    },
    {
      path: Route.SignIn,
      element: <SignIn />,
    },
  ];
};

const errorRoutes = (): RouteObject[] => {
  const pageNotFound: RouteObject = {
    path: "/404",
    element: <PageNotFound />,
  };
  const internalServerError: RouteObject = {
    path: "/500",
    element: <InternalServerError />,
  };
  const pathNotFound: RouteObject = {
    path: "*",
    element: <PageNotFound />,
  };

  return [
    {
      path: "/",
      element: <ErrorLayout />,
      children: [pageNotFound, internalServerError, pathNotFound],
    },
  ];
};

const getRoutes = (
  userPrivileges: string[],
  store: Instance<typeof HomeStore>,
): RouteObject[] => {
  return [
    {
      path: "/",
      element: <RootPage />,
    },
    ...homeRoutes(userPrivileges, store),
    ...errorRoutes(),
  ];
};

export const Router = observer((): React.ReactElement | null => {
  const userPrivileges = useUserStore().privileges;
  const store = useHomeStore();
  const routes = getRoutes(userPrivileges, store);

  return useRoutes(routes);
});
