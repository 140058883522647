import { SubmitMFConfirmationRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitMFConfirmationRequestRPCImpl extends SubmitMFConfirmationRequestRPC {
  execute(
    _request: SubmitMFConfirmationRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitMFConfirmationRequestRPC.Response,
      SubmitMFConfirmationRequestRPC.Errors.Errors
    >
  > {
    const response = new SubmitMFConfirmationRequestRPC.Response(1);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitMFConfirmationRequestRPC.Response,
        SubmitMFConfirmationRequestRPC.Errors.Errors
      >
    >;
  }
}
