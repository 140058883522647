import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetEquityHoldingDistributionByIsinRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetEquityHoldingDistributionByIsinRPC } from "../rpcs/RPC";
import {
  createLabelAmountModel,
  LabelAmountModel,
} from "../../../models/LabelAmountModel";

export const EquityHoldingDistributionStore = types
  .model("EquityHoldingDistributionStore", {
    distribution: types.array(LabelAmountModel),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getEquityHoldingDistributionByIsin: flow(function* () {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetEquityHoldingDistributionByIsinRPC.Request();
        const result: LeoRPCResult<
          GetEquityHoldingDistributionByIsinRPC.Response,
          GetEquityHoldingDistributionByIsinRPC.Errors.Errors
        > =
          yield useGetEquityHoldingDistributionByIsinRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.distribution = cast(
            response.holdingDistribution.map((labelAmount) =>
              createLabelAmountModel(labelAmount),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getEquityHoldingDistributionByIsinRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createEquityHoldingDistributionStore = (): Instance<
  typeof EquityHoldingDistributionStore
> => {
  return EquityHoldingDistributionStore.create({});
};
