"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFConfirmationRequestsRPCClientImpl = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const tedwig_1 = require("@surya-digital/tedwig");
const getMFConfirmationRequestsRPC_1 = require("./getMFConfirmationRequestsRPC");
class GetMFConfirmationRequestsRPCClientImpl extends getMFConfirmationRequestsRPC_1.GetMFConfirmationRequestsRPC {
    constructor(client) {
        super();
        this.client = client;
    }
    execute(request) {
        return __awaiter(this, void 0, void 0, function* () {
            GetMFConfirmationRequestsRPCClientImpl.validateRequest(request);
            const requestBody = request.toDTO();
            const response = yield this.client.sendRequest(new tedwig_1.Request(tedwig_1.Method.Post, "mutual-fund/GetMFConfirmationRequests", undefined, undefined, requestBody));
            return (0, leo_ts_runtime_1.parseResponse)(response, "mutual-fund/GetMFConfirmationRequests", getMFConfirmationRequestsRPC_1.GetMFConfirmationRequestsRPC.Response.fromDTO, getMFConfirmationRequestsRPC_1.GetMFConfirmationRequestsRPC.Errors.fromDTO);
        });
    }
    static validateRequest(request) {
        const isGetMFConfirmationRequestsRPCRequestValue = request.isGetMFConfirmationRequestsRPCRequest;
        if (!(request instanceof getMFConfirmationRequestsRPC_1.GetMFConfirmationRequestsRPC.Request || Boolean(isGetMFConfirmationRequestsRPCRequestValue))) {
            throw new getMFConfirmationRequestsRPC_1.GetMFConfirmationRequestsRPC.InvalidRequestError("Attribute request is not a GetMFConfirmationRequestsRPC.Request");
        }
    }
}
exports.GetMFConfirmationRequestsRPCClientImpl = GetMFConfirmationRequestsRPCClientImpl;
