import { castToSnapshot, Instance, types } from "mobx-state-tree";
import {
  createMFCreateDealRequestStore,
  MFCreateDealRequestStore,
} from "../deal-request/store/createDealRequestStore/MFCreateDealRequestStore";
import {
  createMFDealRequestsStore,
  MFDealRequestsStore,
} from "../deal-request/store/DealRequestListStore/MFDealRequestsStore";
import {
  createDealRequestDetailsStore,
  DealRequestDetailsStore,
} from "../deal-request/store/DealRequestDetailsStore/DealRequestDetailsStore";
import {
  createManageConfirmationDetailsStore,
  ManageConfirmationDetailsStore,
} from "../confirmation/store/ManageConfirmationRequestsStore/ManageConfirmationDetailsStore";
import {
  createSubmitConfirmationDetailsStore,
  SubmitConfirmationDetailsStore,
} from "../confirmation/store/SubmitConfirmationDetailsStore/SubmitConfirmationDetailsStore";
import {
  ConfirmationRequestDetailsStore,
  createConfirmationRequestDetailsStore,
} from "../confirmation/store/confirmationRequestDetailsStore/ConfirmationRequestDetailsStore";
import {
  createMfReportsStore,
  MfReportsStore,
} from "../reports/store/MfReportsStore";

export const MFStore = types.model("MFStore", {
  createDealRequestStore: MFCreateDealRequestStore,
  dealRequestsStore: MFDealRequestsStore,
  dealRequestDetailsStore: DealRequestDetailsStore,
  manageContractNoteDetailsStore: ManageConfirmationDetailsStore,
  submitConfirmationDetailsStore: SubmitConfirmationDetailsStore,
  confirmationRequestDetailsStore: ConfirmationRequestDetailsStore,
  mfReportsStore: MfReportsStore,
});

export const createMFStore = (): Instance<typeof MFStore> => {
  return MFStore.create({
    createDealRequestStore: castToSnapshot(createMFCreateDealRequestStore()),
    dealRequestsStore: createMFDealRequestsStore(),
    dealRequestDetailsStore: createDealRequestDetailsStore(),
    manageContractNoteDetailsStore: createManageConfirmationDetailsStore(),
    submitConfirmationDetailsStore: createSubmitConfirmationDetailsStore(),
    confirmationRequestDetailsStore: createConfirmationRequestDetailsStore(),
    mfReportsStore: createMfReportsStore(),
  });
};
