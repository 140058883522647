import {
  Instance,
  types,
  getSnapshot,
  applySnapshot,
  flow,
  getEnv,
  cast,
  clone,
} from "mobx-state-tree";
import {
  FdSettleCashflowRPC,
  FixedDepositItemsPerPage,
  FixedDepositPageIndex,
  FixedDepositSortOrder,
  GetFdCashflowDetailRPC,
  GetFdCashflowsPaginationResponse,
  GetFdCashflowsRPC,
  GetFdCashflowsSortOrder,
  Note,
} from "@khazana/khazana-rpcs";

import { FDCashflowModel } from "../models/FDCashflowModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  CurrencyModel,
  createCurrencyModel,
} from "../../../models/CurrencyModel";
import { FDCashflowDetailModel } from "../models/FDCashflowDetailModel";
import { createBigAmountModel } from "../../../models/BigAmountModel";
import { createAccrualItemModel } from "../../../models/AccrualItemModel";
import {
  BankAccountDropdownStore,
  createBankAccountDropdownStore,
} from "../../../store/BankAccountDropdownStore";
import {
  DepositBankDropdownStore,
  createDepositBankDropdownStore,
} from "../../../store/DepositBankDropdownStore";
import {
  AutoCompleteSeparateLabelItem,
  DatePickerInput,
} from "@surya-digital/leo-reactjs-core";
import { getLeoDate } from "../../../../../utils";
import {
  useFdSettleCashflowRPCClientImpl,
  useGetFdCashflowDetailRPCClientImpl,
  useGetFdCashflowsRPCClientImpl,
} from "../rpc/RPC";
import { GetFdCashflowsSortOrderEnums } from "@khazana/khazana-rpcs/build/fixed-deposit/getFdCashflowsSortOrder";

export const FDCashflowsStore = types
  .model("FDCashflowsStore", {
    error: types.optional(types.boolean, false),
    cashflowPageNumber: types.optional(types.number, 0),
    cashflowList: types.array(FDCashflowModel),
    currency: types.maybe(CurrencyModel),
    cashflowTotalItems: types.optional(types.number, 0),
    selectedCashflow: types.maybe(FDCashflowModel),
    note: types.maybe(types.string),
    settlementDate: types.maybe(types.Date),
    cashflowDetails: types.maybe(FDCashflowDetailModel),
    bankAccount: BankAccountDropdownStore,
    bank: DepositBankDropdownStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    setCashflowPage: (pageNumber: number): void => {
      store.cashflowPageNumber = pageNumber;
    },
    setNote: (note: string | undefined): void => {
      if (note === "") {
        store.note = undefined;
      } else {
        store.note = note;
      }
    },
    setSelectedCashflow: (
      selectedCashflow: Instance<typeof FDCashflowModel>,
    ): void => {
      store.selectedCashflow = clone(selectedCashflow);
    },
    getCashflows: flow(function* (investmentId?: number) {
      const logger = getEnv(store).logger;
      if (investmentId !== undefined) {
        const request = new GetFdCashflowsRPC.Request(
          [
            new GetFdCashflowsSortOrder(
              0,
              GetFdCashflowsSortOrderEnums.ColumnName.ColumnName.CF_DATE,
              FixedDepositSortOrder.FixedDepositSortOrder.DESCENDING,
            ),
          ],
          investmentId,
          new FixedDepositItemsPerPage(10),
          new FixedDepositPageIndex(store.cashflowPageNumber),
        );
        const apiClient = getAPIClient(store);
        const result: LeoRPCResult<
          GetFdCashflowsRPC.Response,
          GetFdCashflowsRPC.Errors.InvalidPageIndex
        > = yield useGetFdCashflowsRPCClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.currency = response.getFdCashflowsPaginationResponse[0]
            ?.currency
            ? createCurrencyModel(
                response.getFdCashflowsPaginationResponse[0]?.currency,
              )
            : undefined;
          const cashflows = response.getFdCashflowsPaginationResponse.map(
            (cashflow: GetFdCashflowsPaginationResponse) => {
              return FDCashflowModel.create({
                cashFlowId: cashflow.fdCashflowId,
                purchaseTransactionId: cashflow.purchaseTransactionId,
                cashflowDate: new Date(cashflow.cashflowDate.date),
                cashflowType: cashflow.cashflowType,
                rate: cashflow.rate.decimalValue,
                amountCCY: cashflow.amountCCY.decimalValue,
                amountHCY: cashflow.amountHCY.decimalValue,
                settlementDate: cashflow.settlementDate
                  ? new Date(cashflow.settlementDate.date)
                  : undefined,
                settlementBank: cashflow.bankName ?? undefined,
                settlementBankAccountNumber:
                  cashflow.settlementBankAccount ?? undefined,
                isCredited: cashflow.isCredited,
                currency: createCurrencyModel(cashflow.currency),
              });
            },
          );
          store.cashflowList = cast(cashflows);
          store.cashflowTotalItems = response.totalItems;
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetFiCashflowsRPC`,
          );
        }
      }
    }),
    setBank: (bankId: string): void => {
      store.bank.setSelectedBank(bankId);
      store.bankAccount.setSelectedAccount(undefined);
      store.bankAccount.getAccountList(bankId);
    },
    setSettlementDate: (date: DatePickerInput): void => {
      store.settlementDate = date ?? new Date();
    },
    resetCashflowDetail: (): void => {
      store.cashflowDetails = undefined;
    },
    getCashflowDetail: flow(function* () {
      const logger = getEnv(store).logger;
      if (store.selectedCashflow) {
        const request = new GetFdCashflowDetailRPC.Request(
          store.selectedCashflow?.cashFlowId,
        );
        const apiClient = getAPIClient(store);
        const result: LeoRPCResult<
          GetFdCashflowDetailRPC.Response,
          GetFdCashflowDetailRPC.Errors.Errors
        > =
          yield useGetFdCashflowDetailRPCClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          yield store.bank.getBankList();
          store.bank.setSelectedBank(response.bankId.uuid);
          yield store.bankAccount.getAccountList(response.bankId.uuid);
          store.bankAccount.setSelectedAccount(response.bankAccountNumber);
          store.cashflowDetails = FDCashflowDetailModel.create({
            bankId: response.bankId.uuid,
            bankName: response.bankName,
            bankAccountNumber: response.bankAccountNumber,
            amount: createBigAmountModel(response.amount),
            accruals: response.accruals.map((accrual) => {
              return createAccrualItemModel(accrual);
            }),
          });
          store.settlementDate = new Date();
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetFiCashflowsRPC`,
          );
        }
      }
    }),
    settleCashflow: flow(function* () {
      const logger = getEnv(store).logger;
      store.error = false;
      if (
        store.selectedCashflow !== undefined &&
        store.bankAccount.selectedBankAccount !== undefined &&
        store.settlementDate !== undefined
      ) {
        const request = new FdSettleCashflowRPC.Request(
          store.selectedCashflow.cashFlowId,
          store.bankAccount.selectedBankAccount.bankAccountNumber,
          getLeoDate(store.settlementDate),
          store.note ? new Note(store.note) : null,
        );
        const apiClient = getAPIClient(store);
        const result: LeoRPCResult<
          FdSettleCashflowRPC.Response,
          FdSettleCashflowRPC.Errors.Errors
        > = yield useFdSettleCashflowRPCClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Error) {
          store.error = true;
          logger.error(
            `Unhandled Error: ${result.error} from FiSettleCashflowRPC`,
          );
        }
      }
    }),
  }))
  .views((store) => ({
    getBank: (): AutoCompleteSeparateLabelItem | null => {
      const bank = store.bank.bankList.find((item) => {
        return item.id === store.bank.selectedBankId;
      });
      return bank ? { id: bank.id, label: bank.name } : null;
    },
  }));

export const createFDCashflowsStore = (): Instance<typeof FDCashflowsStore> => {
  return FDCashflowsStore.create({
    bank: createDepositBankDropdownStore(),
    bankAccount: createBankAccountDropdownStore(),
  });
};
