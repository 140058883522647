"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdCashflowsSortOrder = exports.GetFdCashflowsSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedDepositSortOrder_1 = require("./fixedDepositSortOrder");
class InvalidGetFdCashflowsSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFdCashflowsSortOrderError";
    }
}
var GetFdCashflowsSortOrderEnums;
(function (GetFdCashflowsSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["CF_DATE"] = "CF_DATE";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "CF_DATE":
                    return ColumnName.CF_DATE;
                default:
                    throw new InvalidGetFdCashflowsSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFdCashflowsSortOrderEnums.ColumnName || (GetFdCashflowsSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFdCashflowsSortOrderEnums = exports.GetFdCashflowsSortOrderEnums || (exports.GetFdCashflowsSortOrderEnums = {}));
class GetFdCashflowsSortOrder {
    constructor(sortIndex, columnName, order) {
        this.isGetFdCashflowsSortOrder = true;
        GetFdCashflowsSortOrder.validateSortIndex(sortIndex);
        this.sortIndex = sortIndex;
        GetFdCashflowsSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFdCashflowsSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const sortIndex = (0, leo_ts_runtime_1.getInt32)(dto, "sortIndex", InvalidGetFdCashflowsSortOrderError);
        const columnName = GetFdCashflowsSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFdCashflowsSortOrderError));
        const order = fixedDepositSortOrder_1.FixedDepositSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFdCashflowsSortOrderError));
        return new GetFdCashflowsSortOrder(sortIndex, columnName, order);
    }
    toDTO() {
        const ret = {
            sortIndex: this.sortIndex,
            columnName: GetFdCashflowsSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: fixedDepositSortOrder_1.FixedDepositSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(sortIndex = this.sortIndex, columnName = this.columnName, order = this.order) {
        return new GetFdCashflowsSortOrder(sortIndex, columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFdCashflowsSortOrderValue = other.isGetFdCashflowsSortOrder;
        if (!(other instanceof GetFdCashflowsSortOrder) || Boolean(isGetFdCashflowsSortOrderValue)) {
            return false;
        }
        if (this.sortIndex !== other.sortIndex) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFdCashflowsSortOrder = GetFdCashflowsSortOrder;
GetFdCashflowsSortOrder.validateSortIndex = (sortIndex) => {
    if (!(0, leo_ts_runtime_1.isInt32)(sortIndex)) {
        throw new InvalidGetFdCashflowsSortOrderError(`Attribute sortIndex is not an Int32`);
    }
};
GetFdCashflowsSortOrder.validateColumnName = (columnName) => {
    if (!GetFdCashflowsSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFdCashflowsSortOrderError(`Attribute columnName is not a GetFdCashflowsSortOrderEnums.ColumnName`);
    }
};
GetFdCashflowsSortOrder.validateOrder = (order) => {
    if (!fixedDepositSortOrder_1.FixedDepositSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFdCashflowsSortOrderError(`Attribute order is not a FixedDepositSortOrder.FixedDepositSortOrder`);
    }
};
GetFdCashflowsSortOrder.prototype.toString = function toString() {
    return `GetFdCashflowsSortOrder(sortIndex=${this.sortIndex},columnName=${this.columnName},order=${this.order})`;
};
