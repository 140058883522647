"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BigAmount = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("./bigDecimal");
const currency_1 = require("./currency");
class InvalidBigAmountError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBigAmountError";
    }
}
class BigAmount {
    constructor(amount, currency) {
        this.isBigAmount = true;
        BigAmount.validateAmount(amount);
        this.amount = amount;
        BigAmount.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const amount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidBigAmountError));
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidBigAmountError));
        return new BigAmount(amount, currency);
    }
    toDTO() {
        const ret = {
            amount: this.amount.toDTO(),
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(amount = this.amount, currency = this.currency) {
        return new BigAmount(amount, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isBigAmountValue = other.isBigAmount;
        if (!(other instanceof BigAmount) || Boolean(isBigAmountValue)) {
            return false;
        }
        if (!this.amount.equals(other.amount)) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.BigAmount = BigAmount;
BigAmount.validateAmount = (amount) => {
    const isBigDecimalValue = amount.isBigDecimal;
    if (!(amount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidBigAmountError(`Attribute amount is not a BigDecimal`);
    }
};
BigAmount.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidBigAmountError(`Attribute currency is not a Currency`);
    }
};
BigAmount.prototype.toString = function toString() {
    return `BigAmount(amount=${this.amount},currency=${this.currency})`;
};
