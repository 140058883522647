import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "./hooks";
import { GetMFFolioNumbersRPC, ISIN } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetMFFolioNumbersRPC } from "../../rpc/RPC";

export const MFFolioNumberStore = types
  .model("MFFolioNumberStore", {
    folioList: types.array(types.string),
    selectedFolio: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
    error: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setError: (error: string): void => {
        store.error = error;
      },
      setSelectedFolio: (folio: string | undefined): void => {
        store.selectedFolio = folio;
        store.error = undefined;
      },
      getMFFolioNumbers: flow(function* (
        entityId: string,
        amcId: number,
        isin?: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        try {
          const request = new GetMFFolioNumbersRPC.Request(
            new LeoUUID(entityId),
            amcId,
            isin ? new ISIN(isin) : null,
          );
          store.isLoading = true;
          const result: LeoRPCResult<
            GetMFFolioNumbersRPC.Response,
            GetMFFolioNumbersRPC.Errors.Errors
          > = yield useGetMFFolioNumbersRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.folioList = cast(response.folioNumbers);
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in getMFFolioNumbersRPC`,
                );
            }
          } else {
            logger.error("Unhandled error");
          }
        } catch (e) {
          logger.error("Unhandled error", e);
        }
        store.isLoading = false;
      }),
    };
  });

export const createMFFolioNumberStore = (): Instance<
  typeof MFFolioNumberStore
> => {
  return MFFolioNumberStore.create({});
};
