import { Instance, castToSnapshot, types } from "mobx-state-tree";
import {
  FiInvestmentRegisterStore,
  createFiInvestmentRegisterStore,
} from "./FiInvestmentRegisterStore";
import {
  FiHoldingSummaryStore,
  createFiHoldingSummaryStore,
} from "./FiHoldingSummaryStore";

export const FiReportsStore = types.model("FiReportsStore", {
  fiInvestmentRegisterStore: FiInvestmentRegisterStore,
  fiHoldingSummaryStore: FiHoldingSummaryStore,
});

export const createFiReportsStore = (): Instance<typeof FiReportsStore> => {
  return FiReportsStore.create({
    fiInvestmentRegisterStore: castToSnapshot(
      createFiInvestmentRegisterStore(),
    ),
    fiHoldingSummaryStore: castToSnapshot(createFiHoldingSummaryStore()),
  });
};
