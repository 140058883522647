"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiTransactionType = exports.InvalidFiTransactionTypeError = void 0;
class InvalidFiTransactionTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiTransactionTypeError";
    }
}
exports.InvalidFiTransactionTypeError = InvalidFiTransactionTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiTransactionType;
(function (FiTransactionType_1) {
    let FiTransactionType;
    (function (FiTransactionType) {
        FiTransactionType["FI_PURCHASE"] = "FI_PURCHASE";
        FiTransactionType["FI_SELL"] = "FI_SELL";
    })(FiTransactionType = FiTransactionType_1.FiTransactionType || (FiTransactionType_1.FiTransactionType = {}));
    FiTransactionType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "FI_PURCHASE":
                return FiTransactionType.FI_PURCHASE;
            case "FI_SELL":
                return FiTransactionType.FI_SELL;
            default:
                throw new InvalidFiTransactionTypeError(`Case ${dto.case} is not valid case of FiTransactionType`);
        }
    };
    FiTransactionType_1.toDTO = (fiTransactionType) => {
        const ret = {
            case: FiTransactionType[fiTransactionType],
        };
        return ret;
    };
    FiTransactionType_1.isInstanceOf = (other) => {
        if (other in FiTransactionType) {
            return true;
        }
        return false;
    };
})(FiTransactionType = exports.FiTransactionType || (exports.FiTransactionType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
