import { Stack } from "@mui/material";
import React from "react";
import { Section, SectionError } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { GetDealStatusSection } from "./GetDealStatusSection";
import { useViewEquityDealRequestDetailsStore } from "../store/hooks";

const Size = {
  section: "100%",
};

export const DealRequestHistorySection = (): React.ReactElement => {
  const { t } = useTranslation();
  const store = useViewEquityDealRequestDetailsStore();
  const [searchParam] = useSearchParams();
  const requestId = searchParam.get("requestId");

  async function fetchDealRequestHistory(): Promise<
    SectionError | React.ReactElement[]
  > {
    if (requestId) {
      await store.getEquityDealRequestHistory(requestId);
    } else {
      console.error("No requestId found while fetching deal request history");
    }

    const equityHistory = store.getEquityHistory();

    if (equityHistory) {
      const history = [...equityHistory];

      if (history && history.length > 0) {
        return history.map((value, index) => {
          return history && value.requestNote?.requestedAt && value.status ? (
            <Stack key={index}>
              {GetDealStatusSection({
                value,
                isHistoryComponent: true,
                t,
                assetName: store.legalName,
                transactionType: store.transactionType,
              })}
            </Stack>
          ) : (
            <></>
          );
        });
      } else {
        console.error(`History Data is missing for Equity Deal Request`);
      }
    } else if (store.error) {
      console.error(
        `Error: ${store.error} occurred while showing equity deal request history`,
      );
      return t("common.somethingWentWrongProcessingRequest");
    }
    return [];
  }

  return (
    <Section
      title={t("equity.dealRequestDetails.dealRequestHistory")}
      viewOverrides={{
        empty: { message: t("common.noResultsFound") },
        loading: { message: t("common.fetchingHistory") },
      }}
      onExpand={fetchDealRequestHistory}
      expandIndicator={t("common.showMore")}
      collapseIndicator={t("common.showLess")}
      styleOverrides={{ width: Size.section }}
    />
  );
};
