import { BankBranch, GetBranchSearchListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetBranchSearchListRPCImpl extends GetBranchSearchListRPC {
  execute(
    _request: GetBranchSearchListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetBranchSearchListRPC.Response,
      GetBranchSearchListRPC.Errors.Errors
    >
  > {
    const response = new GetBranchSearchListRPC.Response([
      new BankBranch("HDFC1000000", "Jayanagar", "HDFC"),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetBranchSearchListRPC.Response,
        GetBranchSearchListRPC.Errors.Errors
      >
    >;
  }
}
