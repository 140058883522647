import { Alert, debounce, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FiDealType } from "../../../../../types/EnumTypes";
import {
  AutoCompleteInputFieldSeparateLabel,
  Button,
  DropdownInputFieldSeparateLabel,
  TabProps,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Tabs } from "../../../components/Tabs";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { AmountTextField } from "../../../components/AmountTextField";
import { useAppConfigurationStore } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import {
  DEBOUNCE_DELAY,
  getFormattedAmount,
  getFormattedAmountString,
  MIN_SEARCH_LENGTH,
} from "../../../../../utils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getPath } from "../../../../../utils/RoutesUtils";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import { useCreateFiDealRequestStore } from "../store/hooks";
import { CreateFiDealRequestFieldErrors } from "../store/CreateFiDealRequestStore";
import {
  getDealRequestTabBackgroundColor,
  getFISecurityTypeToggleButtonOptions,
} from "../utils/UIUtils";
import { useBorder } from "../../../../../utils/BorderUtils";
import { CreateFiDealRequestError } from "../store/CreateFiDealRequestError";
import { getFiSecurityDropdownOptions } from "../../utils/UIUtils";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { getFIYieldTypeValue } from "../utils/SearchUtils";
import { KHToggleButton } from "../../../components/toggleButton/KHToggleButton";

interface CreateFiDealRequestFormProps {
  setIsScreenLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showTab: boolean;
  tabValue: FiDealType;
  setTabValue: (value: FiDealType) => void;
}

const Size = {
  searchField: "100%",
  priceField: "90%",
  createButton: "153px",
  autoComplete: {
    subLabelWidth: "120px",
  },
};

export const CreateFiDealRequestForm = observer(
  ({
    tabValue,
    showTab,
    setTabValue,
    setIsScreenLoading,
  }: CreateFiDealRequestFormProps): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const tokens = useFoundationColorTokens();
    const typography = useTypography();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const [noOptionsText, setNoOptionsText] = useState(
      t("fi.createDealRequest.startTypingToSearchSecurity"),
    );
    const store = useCreateFiDealRequestStore();
    const { showFiPortfolio, showFiDematAccount } = useAppConfigurationStore();
    const [isSymbolDropdownOptionsLoading, setIsSymbolDropdownOptionsLoading] =
      useState(false);
    const [isEntityDropdownOptionsLoading, setIsEntityDropdownOptionsLoading] =
      useState(false);
    const [
      isPortfolioDematAccountDropdownOptionsLoading,
      setIsPortfolioDematAccountDropdownOptionsLoading,
    ] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isWarningBannerVisible, setIsWarningBannerVisible] = useState(false);
    const [isErrorDialogMessage, setIsErrorDialogMessage] = useState<
      string | null
    >(null);
    const disabledFields = store.disableFields();
    const postfixLabel = store.getCurrencySymbolPostfixString();
    const border = useBorder();
    const tabLabels: TabProps[] = [
      {
        label: t("fi.purchase"),
      },
      {
        label: t("common.sell"),
      },
    ];

    const debounceSymbolSearch = useCallback(
      debounce(function (searchValue: string | null | undefined) {
        if (!searchValue || searchValue.length < MIN_SEARCH_LENGTH) {
          if (!searchValue) {
            store.clearStore();
          }
          debounceSymbolSearch.clear();
          setNoOptionsText(
            t("fi.createDealRequest.startTypingToSearchSecurity"),
          );
          return;
        }
        setIsSymbolDropdownOptionsLoading(true);
        store
          .getSecurityList(tabValue, store.securityType, searchValue)
          .then(() => {
            if (!store.securityList.length) {
              setNoOptionsText(t("fi.createDealRequest.noSecurityFound"));
            }
          })
          .finally(() => {
            setIsSymbolDropdownOptionsLoading(false);
          });
      }, DEBOUNCE_DELAY),
      [tabValue, store.securityType],
    );

    const handleStoreError = (): void => {
      if (store.error) {
        if (
          store.error ===
          CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound
        ) {
          setIsWarningBannerVisible(true);
          return;
        }
        if (store.error === CreateFiDealRequestError.GrossAmountTooLess) {
          store.setFieldErrors(
            CreateFiDealRequestFieldErrors.create({
              grossAmount: t("fi.createDealRequest.grossAmountTooLess"),
            }),
          );
          store.clearStoreError();
          return;
        }
        if (store.error === CreateFiDealRequestError.InvalidPrice) {
          store.setFieldErrors(
            CreateFiDealRequestFieldErrors.create({
              price: t("fi.createDealRequest.invalidPrice"),
            }),
          );
          store.clearStoreError();
          return;
        }
        setIsErrorDialogOpen(true);
      } else if (store.errorGrossQuantity) {
        store.setFieldErrors(
          CreateFiDealRequestFieldErrors.create({
            quantity: t("common.insufficientGrossQuantityErrorMessage", {
              grossQuantity: getFormattedAmountString(
                (store.quantity ?? 0) - store.errorGrossQuantity,
              ),
            }),
          }),
        );
      } else if (store.errorNetQuantity) {
        setIsErrorDialogMessage(
          t("common.insufficientNetQuantityErrorMessage", {
            netQuantity: getFormattedAmountString(store.errorNetQuantity),
          }),
        );
        setIsErrorDialogOpen(true);
      }
      // Once the error is handled, reset the error in store.
      store.clearStoreError(); // TODO: https://surya-digital.atlassian.net/browse/KD-445
    };

    useEffect(() => {
      store.clearStore();
    }, [tabValue]);

    useEffect(() => {
      handleStoreError();
    }, [store.error, store.errorGrossQuantity, store.errorNetQuantity]);

    const validateFields = (): void => {
      const errors = {
        security: "",
        grossAmount: "",
        quantity: "",
        price: "",
        entity: "",
        portfolio: "",
        bank: "",
        bankAccount: "",
        dematAccount: "",
        yieldToMaturity: "",
      };

      if (!store.isin) {
        errors.security = t("fi.createDealRequest.securityIsRequired");
      }
      if (!disabledFields.grossAmount && !store.grossAmount) {
        if (store.grossAmount === 0) {
          errors.grossAmount = t("common.grossAmountCannotBeZero");
        } else {
          errors.grossAmount = t("common.grossAmountIsRequired");
        }
      }
      if (!disabledFields.quantity && !store.quantity) {
        if (store.quantity === 0) {
          errors.quantity = t("common.quantityCannotBeZero");
        } else {
          errors.quantity = t("common.quantityIsRequired");
        }
      }
      if (!disabledFields.price && !store.price) {
        if (store.price === 0) {
          errors.price = t("common.priceCannotBeZero");
        } else {
          errors.price = t("common.priceIsRequired");
        }
      }
      if (!disabledFields.yieldToMaturity && !store.yieldToMaturity) {
        errors.yieldToMaturity = t(
          "fi.createDealRequest.yieldToMaturityIsRequired",
        );
      }
      if (!disabledFields.entity && !store.entity) {
        errors.entity = t("common.entityIsRequired");
      }
      if (showFiPortfolio && !disabledFields.portfolio && !store.portfolio) {
        errors.portfolio = t("common.portfolioIsRequired");
      }
      if (!disabledFields.bank && !store.selectedBank) {
        errors.bank = t("common.paymentBankNameIsRequired");
      }
      if (!disabledFields.bankAccount && !store.selectedBankAccount) {
        errors.bankAccount = t("common.paymentAccountNumberIsRequired");
      }
      if (
        showFiDematAccount &&
        !disabledFields.dematAccount &&
        !store.dematAccount
      ) {
        errors.dematAccount = t("common.dematAccountIsRequired");
      }
      store.setFieldErrors(errors);
    };

    const getYieldToMaturityHelperText = (): string | undefined => {
      if (store.yieldToMaturity && store.yieldToMaturity > 100) {
        return t("fi.createDealRequest.yieldToMaturityCannotBeMoreThan");
      } else if (store.yieldToMaturity && store.yieldToMaturity < -100) {
        return t("fi.createDealRequest.yieldToMaturityCannotBeLessThan");
      }
      return !store.isAmountValid()
        ? t("common.resultGrossAmountTooLarge")
        : undefined;
    };

    const GetErrorBanner = (): React.ReactElement => {
      if (isWarningBannerVisible) {
        return (
          <Alert
            icon={false}
            variant="filled"
            sx={{
              background: tokens.backgroundErrorSubtle,
              border: border.errorSubtle,
              color: tokens.labelWarning,
              margin: spacing.spaceXL,
              marginBottom: "0",
              ...typography.b1,
            }}
          >
            {t("fi.createDealRequest.neitherMaturityNorNextCallDateFound")}
          </Alert>
        );
      }
      return <></>;
    };

    const getQuantityField = (): React.ReactElement => {
      return (
        <AmountTextField
          isRequired
          name="quantity"
          label={t("common.quantity")}
          error={Boolean(store.fieldErrors?.quantity) || !store.isAmountValid()}
          helperText={
            (!store.isAmountValid()
              ? t("common.resultGrossAmountTooLarge")
              : store.fieldErrors?.quantity) ?? undefined
          }
          isDisabled={disabledFields.quantity}
          value={store.quantity?.toString() ?? ""}
          onAmountChange={store.setQuantity}
          placeholder={t("common.enterQuantity")}
          isDecimalAllowed={false}
        />
      );
    };

    const getPurchaseFirstRow = (): React.ReactElement => {
      return (
        <>
          <AmountTextField
            isRequired
            name="grossAmount"
            label={t("common.grossAmountWithCurrencySymbol", {
              postfixLabel,
            })}
            error={Boolean(store.fieldErrors?.grossAmount)}
            helperText={store.fieldErrors?.grossAmount ?? undefined}
            isDisabled={disabledFields.grossAmount}
            value={store.grossAmount?.toString() ?? ""}
            onAmountChange={(value: string): void => {
              store.setGrossAmount(value);
            }}
            placeholder={t("common.enterGrossAmount")}
            isDecimalAllowed={true}
          />
          <Typography
            sx={{
              typography: typography.b1,
              marginTop: `36px !important`,
            }}
          >
            {t("common.or")}
          </Typography>
          {getQuantityField()}
        </>
      );
    };

    const getPriceYTMField = (): React.ReactElement => {
      return (
        <Stack
          direction={"row"}
          gap={
            tabValue === FiDealType.Purchase ? spacing.spaceMD : spacing.spaceSM
          }
        >
          <AmountTextField
            isRequired
            name="price"
            label={t("common.priceWithPostfixLabel", {
              postfixLabel,
            })}
            error={Boolean(store.fieldErrors?.price) || !store.isAmountValid()}
            helperText={
              (!store.isAmountValid()
                ? t("common.resultGrossAmountTooLarge")
                : store.fieldErrors?.price) ?? undefined
            }
            isDisabled={disabledFields.price}
            value={store.price?.toString() ?? ""}
            onAmountChange={store.setPrice}
            placeholder={t("common.pricePlaceholder")}
            isDecimalAllowed={true}
            style={{
              width: tabValue === FiDealType.Sell ? Size.priceField : "unset",
            }}
          />
          <Typography
            sx={{
              typography: typography.b1,
              marginTop: `36px !important`,
            }}
          >
            {t("common.or")}
          </Typography>
          <AmountTextField
            fractionDigits={4}
            isRequired
            name="yieldToMaturityCall"
            label={getFIYieldTypeValue(store.yieldType)}
            error={
              (store.yieldToMaturity &&
                (store.yieldToMaturity > 100 ||
                  // We need to use pipe operator but ts is suggesting null check which is not required hence rules has been disabled here.
                  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                  store.yieldToMaturity < -100)) ||
              !store.isAmountValid()
            }
            helperText={getYieldToMaturityHelperText()}
            isDisabled={disabledFields.yieldToMaturity}
            value={store.yieldToMaturity?.toString() ?? ""}
            onAmountChange={(value): void => {
              store.setYieldToMaturity(value);
            }}
            placeholder={t("fi.createDealRequest.enterValue")}
            isDecimalAllowed={true}
            isNegativeAllowed
          />
        </Stack>
      );
    };

    const getSellFirstRow = (): React.ReactElement => {
      return (
        <>
          <Stack width={"50%"}>{getQuantityField()}</Stack>
          <Stack width={"50%"}>{getPriceYTMField()}</Stack>
        </>
      );
    };

    return (
      <Stack>
        <Stack
          spacing={spacing.spaceMD}
          sx={{
            backgroundColor: getDealRequestTabBackgroundColor(tabValue, tokens),
            padding: `${spacing.spaceXL} ${spacing.spaceXL} 0 ${spacing.spaceXL}`,
            borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
            borderBottom: border.default,
          }}
        >
          <Stack direction={"row"} spacing={spacing.spaceMD}>
            <Stack height={"fit-content"}>
              <KHToggleButton
                items={getFISecurityTypeToggleButtonOptions()}
                value={store.securityType}
                onChange={(_event, value): void => {
                  store.clearStore();
                  store.setSecurityType(value);
                }}
              />
            </Stack>
            <AsyncAutoCompleteInputFieldSeparateLabel
              key={store.securityType + tabValue}
              isRequired
              id={t("fi.security")}
              placeholder={t("fi.createDealRequest.searchForSecurity")}
              options={getFiSecurityDropdownOptions(store.securityList)}
              isFilterDisabled={true}
              onInputChange={(inputValue: string | null): void => {
                setIsWarningBannerVisible(false);
                const searchText = inputValue?.trim();
                if (!searchText) {
                  store.clearStore();
                }
                debounceSymbolSearch(searchText);
              }}
              subLabelWidth={Size.autoComplete.subLabelWidth}
              onInputClear={(): void => {
                setNoOptionsText(
                  t("fi.createDealRequest.startTypingToSearchSecurity"),
                );
                store.clearStore();
              }}
              onSelect={(value): void => {
                setIsWarningBannerVisible(false);
                store.clearFields();
                setIsEntityDropdownOptionsLoading(true);
                if (typeof value !== "string") {
                  store
                    .setSecurity(value?.id ?? "", tabValue)
                    .finally(() => setIsEntityDropdownOptionsLoading(false));
                }
              }}
              isLoading={isSymbolDropdownOptionsLoading}
              style={{
                width: Size.searchField,
                backgroundColor: tokens.background,
              }}
              error={Boolean(store.fieldErrors?.security)}
              helperText={
                store.fieldErrors?.security
                  ? store.fieldErrors?.security
                  : store.isin ?? undefined
              }
              noOptionsText={noOptionsText}
            />
          </Stack>
          {showTab && (
            <Tabs
              tabs={tabLabels}
              onTabChange={(index: number): void => {
                setIsWarningBannerVisible(false);
                store.clearStoreError();
                setTabValue(index);
              }}
              tabIndex={tabValue}
            />
          )}
        </Stack>
        <GetErrorBanner />
        <Stack
          padding={spacing.spaceXL}
          spacing={spacing.spaceXL}
          sx={{
            ".MuiOutlinedInput-root": {
              "&.Mui-disabled": {
                backgroundColor: tokens.backgroundSubtle,
              },
            },
          }}
        >
          <Stack gap={spacing.spaceSM}>
            <Stack direction={"row"} gap={spacing.spaceMD}>
              {tabValue === FiDealType.Purchase
                ? getPurchaseFirstRow()
                : getSellFirstRow()}
            </Stack>
            {tabValue === FiDealType.Purchase && getPriceYTMField()}
            <Stack direction={"row"} gap={spacing.spaceMD}>
              <Stack flexGrow={1} flexBasis={0}>
                <DropdownInputFieldSeparateLabel
                  name="entity"
                  isRequired
                  isDisabled={disabledFields.entity}
                  // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
                  value={store.entity ?? undefined}
                  onSelect={(value): void => {
                    setIsPortfolioDematAccountDropdownOptionsLoading(true);
                    store
                      .setEntity(
                        value,
                        tabValue,
                        showFiPortfolio,
                        showFiDematAccount,
                      )
                      .finally(() =>
                        setIsPortfolioDematAccountDropdownOptionsLoading(false),
                      );
                  }}
                  label={t("common.entity")}
                  placeholder={t("common.selectEntity")}
                  options={store.entityList.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                  error={Boolean(store.fieldErrors?.entity)}
                  helperText={store.fieldErrors?.entity ?? undefined}
                  loadingText={t("common.loading")}
                  isLoading={isEntityDropdownOptionsLoading}
                />
              </Stack>
              {showFiPortfolio && (
                <Stack flexGrow={1} flexBasis={0}>
                  <DropdownInputFieldSeparateLabel
                    name="portfolio"
                    isRequired
                    isDisabled={disabledFields.portfolio}
                    // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
                    value={store.portfolio ?? undefined}
                    onSelect={store.setPortfolio}
                    label={t("common.portfolio")}
                    placeholder={t("common.selectPortfolio")}
                    options={store.portfolioList.map(({ id, name }) => ({
                      name,
                      value: id,
                    }))}
                    error={Boolean(store.fieldErrors?.portfolio)}
                    helperText={store.fieldErrors?.portfolio ?? undefined}
                    loadingText={t("common.loading")}
                    isLoading={isPortfolioDematAccountDropdownOptionsLoading}
                  />
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={spacing.spaceMD}>
              <Stack flexGrow={1} flexBasis={0}>
                <AutoCompleteInputFieldSeparateLabel
                  key={"bank" + tabValue + store.securityType}
                  id={"bank"}
                  label={t("common.paymentBankNameLabel")}
                  placeholder={t("common.paymentBankNamePlaceholder")}
                  isRequired
                  options={store.bank.bankList.map((bank) => {
                    return { id: bank.id, label: bank.name };
                  })}
                  error={store.bank.error !== undefined}
                  helperText={store.bank.error}
                  isLoading={store.bank.isLoading}
                  isDisabled={disabledFields.bank}
                  loadingText={t("common.loading")}
                  onSelect={(bank) => {
                    if (typeof bank !== "string") {
                      store.bank.setError(undefined);
                      store.bank.setSelectedBank(bank?.id ?? undefined);
                      store.bankAccount.getAccountList(
                        store.bank.selectedBankId,
                      );
                    }
                  }}
                />
              </Stack>
              <Stack flexGrow={1} flexBasis={0}>
                <AutoCompleteInputFieldSeparateLabel
                  key={
                    "paymentBankAccount" +
                    tabValue +
                    store.securityType +
                    store.bank.selectedBankId
                  }
                  id={"paymentBankAccount"}
                  label={t("common.paymentAccountNumberLabel")}
                  placeholder={t("common.paymentAccountNumberPlaceholder")}
                  isRequired
                  error={
                    Boolean(store.fieldErrors?.bankAccount) ||
                    store.createFiBuyDealRequestStore.errorBankBalance
                  }
                  helperText={
                    store.availableBalance !== null
                      ? t("common.availableBalance", {
                          symbol: store.getCurrencySymbolPostfixString(),
                          amount: getFormattedAmount(
                            store.availableBalance,
                            undefined,
                            undefined,
                            true,
                          ),
                        })
                      : store.fieldErrors?.bankAccount ?? undefined
                  }
                  options={store.bankAccount.bankAccountList.map((account) => {
                    return {
                      id: account.bankAccountNumber,
                      label: account.bankAccountNumber,
                    };
                  })}
                  isLoading={store.bankAccount.isLoading}
                  isDisabled={disabledFields.bankAccount}
                  loadingText={t("common.loading")}
                  onSelect={(value) => {
                    if (typeof value !== "string") {
                      store.bankAccount.setError(undefined);
                      store.bankAccount.setSelectedAccount(
                        value?.id ?? undefined,
                      );
                      if (tabValue === FiDealType.Purchase) {
                        store.getAvailableBankBalance();
                      }
                    }
                  }}
                  onInputChange={(inputValue) => {
                    if (inputValue === null) {
                      store.clearAvailableBalance();
                    }
                  }}
                />
              </Stack>
            </Stack>
            <Stack direction={"row"} gap={spacing.spaceMD}>
              {showFiDematAccount && (
                <Stack flexGrow={1} flexBasis={0}>
                  <DropdownInputFieldSeparateLabel
                    name="dematAccount"
                    isRequired
                    isDisabled={disabledFields.dematAccount}
                    // MUI select does not provide a placeholder,the placeholder prop does not do anything hence this workaround is used.
                    value={store.dematAccount ?? undefined}
                    onSelect={async (value): Promise<void> => {
                      store.setDematAccount(value);
                    }}
                    label={t("common.dematAccount")}
                    placeholder={t("common.selectDematAccount")}
                    options={store.dematAccountNumberList.map(
                      ({ dematAccountNumber: dematAccountNumbers }) => ({
                        name: dematAccountNumbers,
                        value: dematAccountNumbers,
                      }),
                    )}
                    error={Boolean(store.fieldErrors?.dematAccount)}
                    helperText={store.fieldErrors?.dematAccount ?? undefined}
                    loadingText={t("common.loading")}
                    isLoading={isPortfolioDematAccountDropdownOptionsLoading}
                  />
                </Stack>
              )}
              <Stack flexGrow={1}></Stack>
            </Stack>
          </Stack>
          <NoteTextArea
            note={store.note}
            setNote={store.setNote}
            isDisabled={disabledFields.note}
          />
          <Button
            name={t("common.createRequest")}
            label={t("common.createRequest")}
            disabled={disabledFields.createDeal}
            size="medium"
            variant="filled"
            onClick={async (): Promise<void> => {
              if (
                store.yieldToMaturity &&
                (store.yieldToMaturity > 1000 || store.yieldToMaturity < -1000)
              ) {
                return;
              }
              setIsScreenLoading(true);
              validateFields();
              if (!store.isAmountValid()) {
                setIsErrorDialogMessage(t("common.resultGrossAmountTooLarge"));
                setIsErrorDialogOpen(true);
                setIsScreenLoading(false);
                return;
              }

              store.clearStoreError();
              const dealRequestId = await store.createFiDealRequest(
                tabValue,
                showFiPortfolio,
                showFiDematAccount,
              );
              setIsScreenLoading(false);
              if (store.createFiBuyDealRequestStore.errorBankBalance) {
                setIsErrorDialogMessage(
                  t("common.availableBalanceErrorMessage"),
                );
                setIsErrorDialogOpen(true);
                return;
              }
              if (dealRequestId) {
                store.clearStore();
                navigate(
                  getPath(
                    Module.Fi,
                    Route.ManageDealRequestDetailsWithParams,
                    dealRequestId.toString(),
                  ),
                );
              }
            }}
            style={{ alignSelf: "flex-end", width: Size.createButton }}
          />
        </Stack>
        <ErrorDialog
          errorMessage={isErrorDialogMessage}
          isErrorDialogOpen={isErrorDialogOpen}
          onClose={(): void => {
            setIsErrorDialogMessage(null);
            setIsErrorDialogOpen(false);
          }}
        />
      </Stack>
    );
  },
);
