import { observer } from "mobx-react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { debounce, Stack } from "@mui/material";
import { Section } from "../../../components/Section";
import {
  AutoCompleteInputFieldSeparateLabel,
  Banner,
  InvalidDateError,
  TextInputFieldSeparateLabel,
} from "@surya-digital/leo-reactjs-material-ui";
import { FormGroup } from "../../../components/form/FormGroup";
import { useFiMaturitySettleStore } from "../store/hooks";
import { AmountTextField } from "../../../components/AmountTextField";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import { DATE_PICKER_FORMAT, DEBOUNCE_DELAY } from "../../../../../utils";
import { useSearchParams } from "react-router-dom";
import { DatePickerFieldSeparateLabel } from "../../../components/date-picker/DatePickerFieldSeparateLabel";

const Size = {
  containerMaxWidth: "500px",
  bannerWidth: "100%",
};

export const FiMaturitySettleForm = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const { t } = useTranslation();
  const store = useFiMaturitySettleStore();
  const [searchParam] = useSearchParams();
  const requestId = Number(searchParam.get("requestId"));

  const getTransactionalDetails = useCallback(
    debounce(function () {
      store.getFiMaturityTransactionDetails(requestId);
    }, DEBOUNCE_DELAY),
    [store.couponReceivedOnMaturity, store.charges],
  );

  return (
    <Section
      title={t("fi.maturityRequestDetails.maturityDetailsTitle")}
      hasPadding={true}
    >
      <Stack spacing={spacing.spaceMD} maxWidth={Size.containerMaxWidth}>
        {store.isBankChanged && (
          <Banner
            message={t("fi.maturityRequestDetails.bankChangedMessage")}
            severity={"info"}
            width={Size.bannerWidth}
          />
        )}
        <FormGroup title={t("contractNotes.amountDetails")}>
          <TextInputFieldSeparateLabel
            name={"settlementReferenceNumber"}
            value={store.settlementReferenceNumber}
            type={"text"}
            onTextChange={(text): void => {
              store.setSettlementReferenceNumber(text);
            }}
            label={t(
              "fi.maturityRequestDetails.settlementReferenceNumberLabel",
            )}
            placeholder={t(
              "fi.maturityRequestDetails.settlementReferenceNumberPlaceholder",
            )}
          />
          <DatePickerFieldSeparateLabel
            label={t("fi.maturityRequestDetails.MaturitySettlementDateLabel")}
            value={store.maturitySettlementDate ?? null}
            isRequired
            format={DATE_PICKER_FORMAT}
            onChange={(date) => {
              store.setMaturitySettlementDate(date);
            }}
            helperText={store.errors.maturitySettlementDateError}
            error={store.errors.maturitySettlementDateError !== undefined}
            onError={function (_error: InvalidDateError | null): void {
              store.errors.maturitySettlementDateError = "";
            }}
          />
          <AmountTextField
            xs={12}
            name={"couponReceivedAtMaturity"}
            label={t("fi.maturityRequestDetails.couponReceivedAtMaturity", {
              val: store.transactionDetails?.currency.symbol,
            })}
            placeholder={t(
              "fi.maturityRequestDetails.couponReceivedAtMaturityPlaceholder",
            )}
            isRequired
            error={store.errors.couponReceivedOnMaturityError !== undefined}
            helperText={store.errors.couponReceivedOnMaturityError}
            value={store.couponReceivedOnMaturity}
            onAmountChange={(amount) => {
              store.setCoupon(amount);
              getTransactionalDetails();
            }}
            isDecimalAllowed={true}
          />
        </FormGroup>
        <FormGroup title={t("contractNotes.applicableCharges")}>
          {store.charges.map((charge, index) => {
            // @ts-ignore
            const chargeLabel: string = t(`${charge.displayName}`, {
              val: store.transactionDetails?.currency.symbol,
            });

            const chargePlaceholder: string = t("contractNotes.enterCharge", {
              // @ts-ignore
              charge: t(`${charge.displayName}`),
            });
            const error = store.errors.chargesError.find(
              (e) => e.chargeType === charge.chargeType,
            )?.error;
            return (
              <AmountTextField
                key={index}
                name={charge.displayName}
                label={chargeLabel}
                isRequired={true}
                value={charge.amount ?? undefined}
                onAmountChange={(amount) => {
                  store.setCharge(charge.chargeType, amount);
                  getTransactionalDetails();
                }}
                error={error !== undefined}
                helperText={error}
                placeholder={chargePlaceholder}
                isDecimalAllowed={true}
              />
            );
          })}
        </FormGroup>
        <FormGroup
          title={t("fi.maturityRequestDetails.recipientAccountDetailsTitle")}
        >
          <AutoCompleteInputFieldSeparateLabel
            id={"recipientBank"}
            label={t("fi.maturityRequestDetails.recipientBankLabel")}
            options={store.recipientBank.bankList.map((bank) => {
              return {
                label: bank.name,
                id: bank.id,
              };
            })}
            isRequired={true}
            placeholder={t(
              "fi.maturityRequestDetails.recipientBankPlaceholder",
            )}
            value={store.recipientBank.selectedBankAutocompleteItem}
            error={store.recipientBank.error !== undefined}
            helperText={store.recipientBank.error}
            isLoading={store.recipientBank.isLoading}
            onSelect={(option) => {
              store.recipientBank.setSelectedBank(option?.id ?? undefined);
              store.recipientAccount.setSelectedAccount(undefined);
              store.recipientAccount.getAccountList(option?.id ?? undefined);
              store.checkBankChange();
            }}
            noOptionsText={t("common.noDataFound")}
          />
          <AutoCompleteInputFieldSeparateLabel
            id={"recipientBankAccount"}
            label={t("fi.maturityRequestDetails.recipientBankAccountLabel")}
            options={store.recipientAccount.bankAccountList.map((account) => {
              return {
                label: account.bankAccountNumber,
                id: account.bankAccountNumber,
              };
            })}
            value={store.recipientAccount.selectedBankAccountAutocompleteItem}
            isRequired={true}
            placeholder={t(
              "fi.maturityRequestDetails.recipientBankAccountPlaceholder",
            )}
            error={store.recipientAccount.error !== undefined}
            helperText={store.recipientAccount.error}
            isDisabled={store.recipientBank.selectedBankId === undefined}
            isLoading={store.recipientAccount.isLoading}
            onSelect={(option) => {
              store.recipientAccount.setSelectedAccount(
                option?.id ?? undefined,
              );
              store.checkBankChange();
            }}
            noOptionsText={t("common.noDataFound")}
          />
        </FormGroup>
      </Stack>
    </Section>
  );
});
