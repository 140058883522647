import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType } from "@khazana/khazana-rpcs";
import {
  Period,
  getDateOrNull,
  getHoldingCategoryOptionsWithAll,
  getInstrumentTypeOptionsWithAll,
} from "../../../utils/ReportUtils";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import {
  DateRangePickerInput,
  DropdownInputFieldSeparateLabel,
  DropdownItem,
  LoadingIndicator,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ReportTableData,
  ReportTableHeaderProps,
  ReportTableHeaderWidth,
} from "../../../components/reports/ReportTable";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { observer } from "mobx-react";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";
import { useFiHoldingSummaryStore } from "../store/hooks";
import { DatePickerFieldSeparateLabel } from "../../../components/date-picker/DatePickerFieldSeparateLabel";
import { DATE_PICKER_FORMAT } from "../../../../../utils";

export const FiHoldingSummary = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useFiHoldingSummaryStore();
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  const getHoldingSummaryDetails = useCallback(async () => {
    store.setIsScreenLoading(true);
    await store.getHoldingSummarySummary(0, store.itemsPerPage(), undefined);
    store.setIsScreenLoading(false);
  }, []);

  useEffect(() => {
    getHoldingSummaryDetails();
    return () => {
      store.reset();
    };
  }, []);

  const Size = {
    fieldWidth: "250px",
  };

  const headers: ReportTableHeaderProps[] = [
    {
      name: t("contractNotes.isin"),
    },
    {
      name: t("fi.security"),
      width: ReportTableHeaderWidth.TableWidthXL,
      sortable: true,
    },
    {
      name: t("fi.reports.holdingSummary.headers.issuerName"),
    },
    {
      name: t("fi.reports.holdingSummary.headers.instrumentType"),
      sortable: true,
    },
    {
      name: t("fi.reports.holdingSummary.headers.maturityDate"),
    },
    {
      name: t("fi.reports.holdingSummary.headers.category"),
    },
    {
      name: t("fi.reports.holdingSummary.headers.entity"),
    },
    {
      name: t("fi.reports.holdingSummary.headers.portfolioName"),
    },
    {
      name: t("fi.reports.holdingSummary.headers.holdingUnits"),
      alignment: "right",
    },
    {
      name: t("fi.reports.holdingSummary.headers.avgRate"),
      alignment: "right",
    },
    {
      name: t("fi.reports.holdingSummary.headers.purchaseAmount"),
      alignment: "right",
    },
    {
      name: t("fi.reports.holdingSummary.headers.marketPrice"),
      alignment: "right",
    },
    {
      name: t("fi.reports.holdingSummary.headers.marketValue"),
      alignment: "right",
    },
  ];

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    await store.getHoldingSummarySummary(page, itemsPerPage, sort);
    return {
      rows: store.fiHoldingSummarySummaryList.map((item) => {
        return [
          item.isin,
          item.securityName,
          item.issuerName,
          item.instrumentType,
          item.maturityDate,
          item.holdingCategory,
          item.entityName,
          item.portfolioName,
          item.holdingUnits,
          item.averageRate,
          item.purchaseAmount,
          item.marketPrice,
          item.marketValue,
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  const getDateRange = (): DateRangePickerInput => {
    return {
      startDate: getDateOrNull(store.maturityFromDate),
      endDate: getDateOrNull(store.maturityToDate),
    };
  };

  return (
    <>
      <LoadingIndicator isLoading={store.isScreenLoading} />
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {store.isDataFetched && (
        <ReportTablePage
          pageHeader={{
            title: t("fi.reports.holdingSummaryLabel"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Fi, Route.Reports), {
                replace: true,
              });
            },
          }}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            isAllOptionEnabled: true,
            isPeriodRequired: false,
            fromDateLabel: t("fi.reports.holdingSummary.maturityFromDateLabel"),
            toDateLabel: t("fi.reports.holdingSummary.maturityToDateLabel"),
            open: viewFilter,
            onClose: () => setViewFilter(false),
            onViewReport: async (period): Promise<void> => {
              tableRef.current?.reload();
              await store.getHoldingSummarySummary(
                0,
                store.itemsPerPage(),
                undefined,
                period,
              );
              return setViewFilter(false);
            },
            module: ModuleType.ModuleType.FIXED_INCOME,
            showReportType: false,
            period: {
              reportType: Period.BETWEEN_TWO_DATES,
              asOnDate: null,
              betweenTwoDatesDateRange: getDateRange(),
            },
            entityDropdownStore: store.entityDropdownStore,
            portfolioDropdownStore: store.portfolioDropdownStore,
            additionalFields: [
              {
                element: (
                  <DropdownInputFieldSeparateLabel
                    key={"holdingCategory"}
                    name={"holdingCategory"}
                    label={t("fi.reports.holdingCategoryLabel")}
                    isRequired={true}
                    style={{ width: Size.fieldWidth }}
                    placeholder={t(
                      "fi.reports.selectHoldingCategoryPlaceholder",
                    )}
                    value={store.holdingCategory}
                    options={getHoldingCategoryOptionsWithAll()}
                    onSelect={function (value: DropdownItem): void {
                      store.setHoldingCategory(value.value);
                    }}
                  />
                ),
                selectedValue: store.holdingCategory,
                isRequired: false,
              },
              {
                element: (
                  <DropdownInputFieldSeparateLabel
                    key={"instrumentType"}
                    name={"instrumentType"}
                    label={t(
                      "fi.reports.holdingSummary.headers.instrumentType",
                    )}
                    isRequired={true}
                    style={{ width: Size.fieldWidth }}
                    placeholder={t(
                      "fi.reports.selectInstrumentTypePlaceholder",
                    )}
                    value={store.instrumentType}
                    options={getInstrumentTypeOptionsWithAll()}
                    onSelect={function (value: DropdownItem): void {
                      store.setInstrumentType(value.value);
                    }}
                  />
                ),
                selectedValue: store.instrumentType,
                isRequired: false,
              },
              {
                element: (
                  <DatePickerFieldSeparateLabel
                    label={t("reports.date")}
                    isRequired={true}
                    style={{ width: Size.fieldWidth }}
                    value={store.asOnDate ? new Date(store.asOnDate) : null}
                    maxDate={new Date()}
                    onChange={(newDate): void => {
                      store.setAsOnDate(newDate?.toString());
                    }}
                    onError={(): void => {}}
                    format={DATE_PICKER_FORMAT}
                  />
                ),
                selectedValue: store.holdingCategory,
                isRequired: false,
              },
            ],
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          summary={undefined}
          isExportable={true}
          onExport={async (exportFormat): Promise<void> => {
            if (
              store.entityDropdownStore.selectedEntity &&
              store.portfolioDropdownStore.selectedPortfolio
            ) {
              await store.getDownloadURL(exportFormat);
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={
            store.fiHoldingSummarySummaryList.length === 0
          }
        />
      )}
    </>
  );
});
