import React from "react";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";
import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-core";

const Size = {
  width: "100vw",
  height: "100vh",
};

export const ErrorLayout = (): React.ReactElement => {
  const tokens = useFoundationColorTokens();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height={Size.height}
      width={Size.width}
      sx={{
        backgroundColor: `${tokens.backgroundSubtle}`,
      }}
    >
      <Outlet />
    </Stack>
  );
};
