"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModuleAvailability = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidModuleAvailabilityError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidModuleAvailabilityError";
    }
}
class ModuleAvailability {
    constructor(isEquityEnabled, isFIEnabled, isFDEnabled, isMutualFundEnabled) {
        this.isModuleAvailability = true;
        ModuleAvailability.validateIsEquityEnabled(isEquityEnabled);
        this.isEquityEnabled = isEquityEnabled;
        ModuleAvailability.validateIsFIEnabled(isFIEnabled);
        this.isFIEnabled = isFIEnabled;
        ModuleAvailability.validateIsFDEnabled(isFDEnabled);
        this.isFDEnabled = isFDEnabled;
        ModuleAvailability.validateIsMutualFundEnabled(isMutualFundEnabled);
        this.isMutualFundEnabled = isMutualFundEnabled;
    }
    static fromDTO(dto) {
        const isEquityEnabled = (0, leo_ts_runtime_1.getBoolean)(dto, "isEquityEnabled", InvalidModuleAvailabilityError);
        const isFIEnabled = (0, leo_ts_runtime_1.getBoolean)(dto, "isFIEnabled", InvalidModuleAvailabilityError);
        const isFDEnabled = (0, leo_ts_runtime_1.getBoolean)(dto, "isFDEnabled", InvalidModuleAvailabilityError);
        const isMutualFundEnabled = (0, leo_ts_runtime_1.getBoolean)(dto, "isMutualFundEnabled", InvalidModuleAvailabilityError);
        return new ModuleAvailability(isEquityEnabled, isFIEnabled, isFDEnabled, isMutualFundEnabled);
    }
    toDTO() {
        const ret = {
            isEquityEnabled: this.isEquityEnabled,
            isFIEnabled: this.isFIEnabled,
            isFDEnabled: this.isFDEnabled,
            isMutualFundEnabled: this.isMutualFundEnabled,
        };
        return ret;
    }
    copy(isEquityEnabled = this.isEquityEnabled, isFIEnabled = this.isFIEnabled, isFDEnabled = this.isFDEnabled, isMutualFundEnabled = this.isMutualFundEnabled) {
        return new ModuleAvailability(isEquityEnabled, isFIEnabled, isFDEnabled, isMutualFundEnabled);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isModuleAvailabilityValue = other.isModuleAvailability;
        if (!(other instanceof ModuleAvailability) || Boolean(isModuleAvailabilityValue)) {
            return false;
        }
        if (this.isEquityEnabled !== other.isEquityEnabled) {
            return false;
        }
        if (this.isFIEnabled !== other.isFIEnabled) {
            return false;
        }
        if (this.isFDEnabled !== other.isFDEnabled) {
            return false;
        }
        if (this.isMutualFundEnabled !== other.isMutualFundEnabled) {
            return false;
        }
        return true;
    }
}
exports.ModuleAvailability = ModuleAvailability;
ModuleAvailability.validateIsEquityEnabled = (isEquityEnabled) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isEquityEnabled)) {
        throw new InvalidModuleAvailabilityError(`Attribute isEquityEnabled is not a Boolean`);
    }
};
ModuleAvailability.validateIsFIEnabled = (isFIEnabled) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isFIEnabled)) {
        throw new InvalidModuleAvailabilityError(`Attribute isFIEnabled is not a Boolean`);
    }
};
ModuleAvailability.validateIsFDEnabled = (isFDEnabled) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isFDEnabled)) {
        throw new InvalidModuleAvailabilityError(`Attribute isFDEnabled is not a Boolean`);
    }
};
ModuleAvailability.validateIsMutualFundEnabled = (isMutualFundEnabled) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isMutualFundEnabled)) {
        throw new InvalidModuleAvailabilityError(`Attribute isMutualFundEnabled is not a Boolean`);
    }
};
ModuleAvailability.prototype.toString = function toString() {
    return `ModuleAvailability(isEquityEnabled=${this.isEquityEnabled},isFIEnabled=${this.isFIEnabled},isFDEnabled=${this.isFDEnabled},isMutualFundEnabled=${this.isMutualFundEnabled})`;
};
