import {
  BigDecimal,
  GetMFDealRequestSummaryRPC,
  MFDividendType,
  MFPlanType,
  MFSchemeType,
  MFTransactionDetails,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFDealRequestSummaryRPCImpl extends GetMFDealRequestSummaryRPC {
  execute(
    _request: GetMFDealRequestSummaryRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFDealRequestSummaryRPC.Response,
      GetMFDealRequestSummaryRPC.Errors.Errors
    >
  > {
    const response = new GetMFDealRequestSummaryRPC.Response(
      new MFTransactionDetails(
        "Aditya Birla Sun Life AMC Limited",
        MFSchemeType.MFSchemeType.EQUITY,
        MFPlanType.MFPlanType.DIRECT,
        MFDividendType.MFDividendType.GROWTH,
        new BigDecimal("3000000000000"),
        null,
        new BigDecimal("30000000000"),
        new BigDecimal("3030000000000"),
      ),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFDealRequestSummaryRPC.Response,
        GetMFDealRequestSummaryRPC.Errors.Errors
      >
    >;
  }
}
