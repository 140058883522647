import { Instance, types } from "mobx-state-tree";
import {
  createRequestNoteModel,
  RequestNoteModel,
} from "../../../models/RequestNoteModel";
import {
  MFDealRequestHistoryAdditionalDetails,
  MFDealRequestHistoryDetail,
  MFImpactTable,
  MFSchemeDetails,
} from "@khazana/khazana-rpcs";
import {
  createMFImpactTableModel,
  ImpactTableModel,
} from "../../../models/ImpactTableModel";
import {
  createSchemeDetailsModel,
  SchemeDetailsModel,
} from "./SchemeDetailsModel";
import { MFDealRequestStatusModel } from "./MFDealRequestStatusModel";

export const MFDealRequestHistoryModel = types.model(
  "MFDealRequestHistoryModel",
  {
    requestNote: types.maybe(RequestNoteModel),
    impactTable: types.maybe(ImpactTableModel),
    schemeDetails: types.maybe(SchemeDetailsModel),
    status: MFDealRequestStatusModel,
  },
);

export const createMFDealRequestHistoryModel = (
  requestDetails: MFDealRequestHistoryDetail | null,
): Instance<typeof MFDealRequestHistoryModel> => {
  let impactTable: MFImpactTable | undefined;
  let schemeDetails: MFSchemeDetails | undefined;
  if (requestDetails) {
    requestDetails.additionalDetail.forEach((detail) => {
      if (
        detail instanceof
        MFDealRequestHistoryAdditionalDetails.PortfolioHoldingImpactDetails
      ) {
        impactTable = detail.impactTable;
      }
      if (
        detail instanceof MFDealRequestHistoryAdditionalDetails.SchemeDetails
      ) {
        schemeDetails = detail.schemeDetails;
      }
    });
    return MFDealRequestHistoryModel.create({
      requestNote: createRequestNoteModel(requestDetails.requestNote),
      status: requestDetails.status,
      impactTable: impactTable
        ? createMFImpactTableModel(impactTable)
        : undefined,
      schemeDetails: schemeDetails
        ? createSchemeDetailsModel(schemeDetails)
        : undefined,
    });
  } else {
    return MFDealRequestHistoryModel.create();
  }
};
