import {
  BigAmount,
  BigDecimal,
  Currency,
  GetInvestmentCashflowBannerInfoRPC,
  PendingCashflows,
  SettledCashflows,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetInvestmentCashflowBannerInfoRPCImpl extends GetInvestmentCashflowBannerInfoRPC {
  execute(
    _request: GetInvestmentCashflowBannerInfoRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetInvestmentCashflowBannerInfoRPC.Response,
      GetInvestmentCashflowBannerInfoRPC.Errors.Errors
    >
  > {
    const response = new GetInvestmentCashflowBannerInfoRPC.Response(
      new BigAmount(new BigDecimal("100000"), new Currency("INR", "₹")),
      new BigAmount(new BigDecimal("10000"), new Currency("INR", "₹")),
      new BigAmount(new BigDecimal("1000.34"), new Currency("INR", "₹")),
      new SettledCashflows(
        new BigAmount(new BigDecimal("10000.000"), new Currency("INR", "₹")),
        new BigAmount(new BigDecimal("109.01"), new Currency("INR", "₹")),
        null,
        null,
      ),
      new PendingCashflows(null, null, null, null),
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetInvestmentCashflowBannerInfoRPC.Response,
        GetInvestmentCashflowBannerInfoRPC.Errors.Errors
      >
    >;
  }
}
