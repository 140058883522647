"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettleFdDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SettleFdDealRequestRPC {
}
exports.SettleFdDealRequestRPC = SettleFdDealRequestRPC;
(function (SettleFdDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SettleFdDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fdDealRequestId, totalAmountReceived, totalInterestReceived = null, note = null) {
            this.isSettleFdDealRequestRPCRequest = true;
            Request.validateFdDealRequestId(fdDealRequestId);
            this.fdDealRequestId = fdDealRequestId;
            Request.validateTotalAmountReceived(totalAmountReceived);
            this.totalAmountReceived = totalAmountReceived;
            if (totalInterestReceived !== undefined && totalInterestReceived !== null) {
                Request.validateTotalInterestReceived(totalInterestReceived);
            }
            this.totalInterestReceived = totalInterestReceived;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const fdDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "fdDealRequestId", InvalidRequestError);
            const totalAmountReceived = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "totalAmountReceived", InvalidRequestError));
            let totalInterestReceived = (0, leo_ts_runtime_1.getOptionalObject)(dto, "totalInterestReceived");
            if (totalInterestReceived !== undefined && totalInterestReceived !== null) {
                totalInterestReceived = transactionAmount_1.TransactionAmount.fromDTO(totalInterestReceived);
            }
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(fdDealRequestId, totalAmountReceived, totalInterestReceived, note);
        }
        toDTO() {
            const ret = {
                fdDealRequestId: this.fdDealRequestId,
                totalAmountReceived: this.totalAmountReceived.toDTO(),
            };
            if (this.totalInterestReceived) {
                ret.totalInterestReceived = this.totalInterestReceived.toDTO();
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(fdDealRequestId = this.fdDealRequestId, totalAmountReceived = this.totalAmountReceived, totalInterestReceived = this.totalInterestReceived, note = this.note) {
            return new Request(fdDealRequestId, totalAmountReceived, totalInterestReceived, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSettleFdDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.fdDealRequestId !== other.fdDealRequestId) {
                return false;
            }
            if (!this.totalAmountReceived.equals(other.totalAmountReceived)) {
                return false;
            }
            if (this.totalInterestReceived) {
                if (!this.totalInterestReceived.equals(other.totalInterestReceived)) {
                    return false;
                }
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateFdDealRequestId = (fdDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(fdDealRequestId)) {
            throw new InvalidRequestError(`Attribute fdDealRequestId is not an Int64`);
        }
    };
    Request.validateTotalAmountReceived = (totalAmountReceived) => {
        const isTransactionAmountValue = totalAmountReceived.isTransactionAmount;
        if (!(totalAmountReceived instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidRequestError(`Attribute totalAmountReceived is not a TransactionAmount`);
        }
    };
    Request.validateTotalInterestReceived = (totalInterestReceived) => {
        const isTransactionAmountValue = totalInterestReceived.isTransactionAmount;
        if (!(totalInterestReceived instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidRequestError(`Attribute totalInterestReceived is not a TransactionAmount`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SettleFdDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SettleFdDealRequestRPC.Request(fdDealRequestId=${this.fdDealRequestId},totalAmountReceived=${this.totalAmountReceived},totalInterestReceived=${this.totalInterestReceived},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SettleFdDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SettleFdDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `SettleFdDealRequestRPC.INVALID_REQUEST_ID()`;
        };
        class CanOnlySettleSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_ONLY_SETTLE_SELF_REQUEST") {
                super(code);
                this.isCanOnlySettleSelfRequest = true;
                CanOnlySettleSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanOnlySettleSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanOnlySettleSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
                if (!(other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanOnlySettleSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_ONLY_SETTLE_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanOnlySettleSelfRequest = CanOnlySettleSelfRequest;
        CanOnlySettleSelfRequest.prototype.toString = function toString() {
            return `SettleFdDealRequestRPC.CAN_ONLY_SETTLE_SELF_REQUEST()`;
        };
        class IncorrectTotalAmount extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INCORRECT_TOTAL_AMOUNT", totalAmount) {
                super(code);
                this.isIncorrectTotalAmount = true;
                IncorrectTotalAmount.validateCode(code);
                IncorrectTotalAmount.validateTotalAmount(totalAmount);
                this.totalAmount = totalAmount;
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                const totalAmount = (0, leo_ts_runtime_1.getInt64)(dto, "totalAmount", InvalidResponseError);
                return new IncorrectTotalAmount(code, totalAmount);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                    totalAmount: this.totalAmount,
                };
                return ret;
            }
            copy(code = this.code, totalAmount = this.totalAmount) {
                return new IncorrectTotalAmount(code, totalAmount);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIncorrectTotalAmountValue = other.isIncorrectTotalAmount;
                if (!(other instanceof IncorrectTotalAmount || Boolean(isIncorrectTotalAmountValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                if (this.totalAmount !== other.totalAmount) {
                    return false;
                }
                return true;
            }
        }
        IncorrectTotalAmount.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INCORRECT_TOTAL_AMOUNT") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        IncorrectTotalAmount.validateTotalAmount = (totalAmount) => {
            if (!(0, leo_ts_runtime_1.isInt64)(totalAmount)) {
                throw new InvalidResponseError(`Attribute totalAmount is not an Int64`);
            }
        };
        Errors.IncorrectTotalAmount = IncorrectTotalAmount;
        IncorrectTotalAmount.prototype.toString = function toString() {
            return `SettleFdDealRequestRPC.INCORRECT_TOTAL_AMOUNT(totalAmount=${this.totalAmount})`;
        };
        class DealAlreadyCancelled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_CANCELLED") {
                super(code);
                this.isDealAlreadyCancelled = true;
                DealAlreadyCancelled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadyCancelled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadyCancelled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
                if (!(other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadyCancelled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_CANCELLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadyCancelled = DealAlreadyCancelled;
        DealAlreadyCancelled.prototype.toString = function toString() {
            return `SettleFdDealRequestRPC.DEAL_ALREADY_CANCELLED()`;
        };
        class DealNotMatured extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_NOT_MATURED") {
                super(code);
                this.isDealNotMatured = true;
                DealNotMatured.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealNotMatured(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealNotMatured(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealNotMaturedValue = other.isDealNotMatured;
                if (!(other instanceof DealNotMatured || Boolean(isDealNotMaturedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealNotMatured.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_NOT_MATURED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealNotMatured = DealNotMatured;
        DealNotMatured.prototype.toString = function toString() {
            return `SettleFdDealRequestRPC.DEAL_NOT_MATURED()`;
        };
        class DealAlreadySettled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_ALREADY_SETTLED") {
                super(code);
                this.isDealAlreadySettled = true;
                DealAlreadySettled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealAlreadySettled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealAlreadySettled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealAlreadySettledValue = other.isDealAlreadySettled;
                if (!(other instanceof DealAlreadySettled || Boolean(isDealAlreadySettledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealAlreadySettled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_ALREADY_SETTLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealAlreadySettled = DealAlreadySettled;
        DealAlreadySettled.prototype.toString = function toString() {
            return `SettleFdDealRequestRPC.DEAL_ALREADY_SETTLED()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "CAN_ONLY_SETTLE_SELF_REQUEST":
                    return CanOnlySettleSelfRequest.fromDTO(dto);
                case "INCORRECT_TOTAL_AMOUNT":
                    return IncorrectTotalAmount.fromDTO(dto);
                case "DEAL_ALREADY_CANCELLED":
                    return DealAlreadyCancelled.fromDTO(dto);
                case "DEAL_NOT_MATURED":
                    return DealNotMatured.fromDTO(dto);
                case "DEAL_ALREADY_SETTLED":
                    return DealAlreadySettled.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
            if (other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue)) {
                return true;
            }
            const isIncorrectTotalAmountValue = other.isIncorrectTotalAmount;
            if (other instanceof IncorrectTotalAmount || Boolean(isIncorrectTotalAmountValue)) {
                return true;
            }
            const isDealAlreadyCancelledValue = other.isDealAlreadyCancelled;
            if (other instanceof DealAlreadyCancelled || Boolean(isDealAlreadyCancelledValue)) {
                return true;
            }
            const isDealNotMaturedValue = other.isDealNotMatured;
            if (other instanceof DealNotMatured || Boolean(isDealNotMaturedValue)) {
                return true;
            }
            const isDealAlreadySettledValue = other.isDealAlreadySettled;
            if (other instanceof DealAlreadySettled || Boolean(isDealAlreadySettledValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SettleFdDealRequestRPC.Errors || (SettleFdDealRequestRPC.Errors = {}));
})(SettleFdDealRequestRPC = exports.SettleFdDealRequestRPC || (exports.SettleFdDealRequestRPC = {}));
