"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendingCashflows = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigAmount_1 = require("./bigAmount");
class InvalidPendingCashflowsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidPendingCashflowsError";
    }
}
class PendingCashflows {
    constructor(accruedCouponBooked = null, accruedTdsBooked = null, accruedCouponPending = null, accruedTdsPending = null) {
        this.isPendingCashflows = true;
        if (accruedCouponBooked !== undefined && accruedCouponBooked !== null) {
            PendingCashflows.validateAccruedCouponBooked(accruedCouponBooked);
        }
        this.accruedCouponBooked = accruedCouponBooked;
        if (accruedTdsBooked !== undefined && accruedTdsBooked !== null) {
            PendingCashflows.validateAccruedTdsBooked(accruedTdsBooked);
        }
        this.accruedTdsBooked = accruedTdsBooked;
        if (accruedCouponPending !== undefined && accruedCouponPending !== null) {
            PendingCashflows.validateAccruedCouponPending(accruedCouponPending);
        }
        this.accruedCouponPending = accruedCouponPending;
        if (accruedTdsPending !== undefined && accruedTdsPending !== null) {
            PendingCashflows.validateAccruedTdsPending(accruedTdsPending);
        }
        this.accruedTdsPending = accruedTdsPending;
    }
    static fromDTO(dto) {
        let accruedCouponBooked = (0, leo_ts_runtime_1.getOptionalObject)(dto, "accruedCouponBooked");
        if (accruedCouponBooked !== undefined && accruedCouponBooked !== null) {
            accruedCouponBooked = bigAmount_1.BigAmount.fromDTO(accruedCouponBooked);
        }
        let accruedTdsBooked = (0, leo_ts_runtime_1.getOptionalObject)(dto, "accruedTdsBooked");
        if (accruedTdsBooked !== undefined && accruedTdsBooked !== null) {
            accruedTdsBooked = bigAmount_1.BigAmount.fromDTO(accruedTdsBooked);
        }
        let accruedCouponPending = (0, leo_ts_runtime_1.getOptionalObject)(dto, "accruedCouponPending");
        if (accruedCouponPending !== undefined && accruedCouponPending !== null) {
            accruedCouponPending = bigAmount_1.BigAmount.fromDTO(accruedCouponPending);
        }
        let accruedTdsPending = (0, leo_ts_runtime_1.getOptionalObject)(dto, "accruedTdsPending");
        if (accruedTdsPending !== undefined && accruedTdsPending !== null) {
            accruedTdsPending = bigAmount_1.BigAmount.fromDTO(accruedTdsPending);
        }
        return new PendingCashflows(accruedCouponBooked, accruedTdsBooked, accruedCouponPending, accruedTdsPending);
    }
    toDTO() {
        const ret = {};
        if (this.accruedCouponBooked) {
            ret.accruedCouponBooked = this.accruedCouponBooked.toDTO();
        }
        if (this.accruedTdsBooked) {
            ret.accruedTdsBooked = this.accruedTdsBooked.toDTO();
        }
        if (this.accruedCouponPending) {
            ret.accruedCouponPending = this.accruedCouponPending.toDTO();
        }
        if (this.accruedTdsPending) {
            ret.accruedTdsPending = this.accruedTdsPending.toDTO();
        }
        return ret;
    }
    copy(accruedCouponBooked = this.accruedCouponBooked, accruedTdsBooked = this.accruedTdsBooked, accruedCouponPending = this.accruedCouponPending, accruedTdsPending = this.accruedTdsPending) {
        return new PendingCashflows(accruedCouponBooked, accruedTdsBooked, accruedCouponPending, accruedTdsPending);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isPendingCashflowsValue = other.isPendingCashflows;
        if (!(other instanceof PendingCashflows) || Boolean(isPendingCashflowsValue)) {
            return false;
        }
        if (this.accruedCouponBooked) {
            if (!this.accruedCouponBooked.equals(other.accruedCouponBooked)) {
                return false;
            }
        }
        if (this.accruedTdsBooked) {
            if (!this.accruedTdsBooked.equals(other.accruedTdsBooked)) {
                return false;
            }
        }
        if (this.accruedCouponPending) {
            if (!this.accruedCouponPending.equals(other.accruedCouponPending)) {
                return false;
            }
        }
        if (this.accruedTdsPending) {
            if (!this.accruedTdsPending.equals(other.accruedTdsPending)) {
                return false;
            }
        }
        return true;
    }
}
exports.PendingCashflows = PendingCashflows;
PendingCashflows.validateAccruedCouponBooked = (accruedCouponBooked) => {
    const isBigAmountValue = accruedCouponBooked.isBigAmount;
    if (!(accruedCouponBooked instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidPendingCashflowsError(`Attribute accruedCouponBooked is not a BigAmount`);
    }
};
PendingCashflows.validateAccruedTdsBooked = (accruedTdsBooked) => {
    const isBigAmountValue = accruedTdsBooked.isBigAmount;
    if (!(accruedTdsBooked instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidPendingCashflowsError(`Attribute accruedTdsBooked is not a BigAmount`);
    }
};
PendingCashflows.validateAccruedCouponPending = (accruedCouponPending) => {
    const isBigAmountValue = accruedCouponPending.isBigAmount;
    if (!(accruedCouponPending instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidPendingCashflowsError(`Attribute accruedCouponPending is not a BigAmount`);
    }
};
PendingCashflows.validateAccruedTdsPending = (accruedTdsPending) => {
    const isBigAmountValue = accruedTdsPending.isBigAmount;
    if (!(accruedTdsPending instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidPendingCashflowsError(`Attribute accruedTdsPending is not a BigAmount`);
    }
};
PendingCashflows.prototype.toString = function toString() {
    return `PendingCashflows(accruedCouponBooked=${this.accruedCouponBooked},accruedTdsBooked=${this.accruedTdsBooked},accruedCouponPending=${this.accruedCouponPending},accruedTdsPending=${this.accruedTdsPending})`;
};
