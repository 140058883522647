"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFHoldingSummaryResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
class InvalidMFHoldingSummaryResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFHoldingSummaryResponseError";
    }
}
class MFHoldingSummaryResponse {
    constructor(isin, schemeName = null, amcName = null, entityName = null, portfolioName = null, holdingUnits = null, marketPrice = null, marketValue = null, salesProceed = null, unrealizedGain = null, realizedGain = null, irr = null) {
        this.isMFHoldingSummaryResponse = true;
        MFHoldingSummaryResponse.validateIsin(isin);
        this.isin = isin;
        if (schemeName !== undefined && schemeName !== null) {
            MFHoldingSummaryResponse.validateSchemeName(schemeName);
        }
        this.schemeName = schemeName;
        if (amcName !== undefined && amcName !== null) {
            MFHoldingSummaryResponse.validateAmcName(amcName);
        }
        this.amcName = amcName;
        if (entityName !== undefined && entityName !== null) {
            MFHoldingSummaryResponse.validateEntityName(entityName);
        }
        this.entityName = entityName;
        if (portfolioName !== undefined && portfolioName !== null) {
            MFHoldingSummaryResponse.validatePortfolioName(portfolioName);
        }
        this.portfolioName = portfolioName;
        if (holdingUnits !== undefined && holdingUnits !== null) {
            MFHoldingSummaryResponse.validateHoldingUnits(holdingUnits);
        }
        this.holdingUnits = holdingUnits;
        if (marketPrice !== undefined && marketPrice !== null) {
            MFHoldingSummaryResponse.validateMarketPrice(marketPrice);
        }
        this.marketPrice = marketPrice;
        if (marketValue !== undefined && marketValue !== null) {
            MFHoldingSummaryResponse.validateMarketValue(marketValue);
        }
        this.marketValue = marketValue;
        if (salesProceed !== undefined && salesProceed !== null) {
            MFHoldingSummaryResponse.validateSalesProceed(salesProceed);
        }
        this.salesProceed = salesProceed;
        if (unrealizedGain !== undefined && unrealizedGain !== null) {
            MFHoldingSummaryResponse.validateUnrealizedGain(unrealizedGain);
        }
        this.unrealizedGain = unrealizedGain;
        if (realizedGain !== undefined && realizedGain !== null) {
            MFHoldingSummaryResponse.validateRealizedGain(realizedGain);
        }
        this.realizedGain = realizedGain;
        if (irr !== undefined && irr !== null) {
            MFHoldingSummaryResponse.validateIrr(irr);
        }
        this.irr = irr;
    }
    static fromDTO(dto) {
        const isin = (0, leo_ts_runtime_1.getString)(dto, "isin", InvalidMFHoldingSummaryResponseError);
        const schemeName = (0, leo_ts_runtime_1.getOptionalString)(dto, "schemeName", InvalidMFHoldingSummaryResponseError);
        const amcName = (0, leo_ts_runtime_1.getOptionalString)(dto, "amcName", InvalidMFHoldingSummaryResponseError);
        const entityName = (0, leo_ts_runtime_1.getOptionalString)(dto, "entityName", InvalidMFHoldingSummaryResponseError);
        const portfolioName = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolioName", InvalidMFHoldingSummaryResponseError);
        let holdingUnits = (0, leo_ts_runtime_1.getOptionalObject)(dto, "holdingUnits");
        if (holdingUnits !== undefined && holdingUnits !== null) {
            holdingUnits = bigDecimal_1.BigDecimal.fromDTO(holdingUnits);
        }
        let marketPrice = (0, leo_ts_runtime_1.getOptionalObject)(dto, "marketPrice");
        if (marketPrice !== undefined && marketPrice !== null) {
            marketPrice = bigDecimal_1.BigDecimal.fromDTO(marketPrice);
        }
        let marketValue = (0, leo_ts_runtime_1.getOptionalObject)(dto, "marketValue");
        if (marketValue !== undefined && marketValue !== null) {
            marketValue = bigDecimal_1.BigDecimal.fromDTO(marketValue);
        }
        let salesProceed = (0, leo_ts_runtime_1.getOptionalObject)(dto, "salesProceed");
        if (salesProceed !== undefined && salesProceed !== null) {
            salesProceed = bigDecimal_1.BigDecimal.fromDTO(salesProceed);
        }
        let unrealizedGain = (0, leo_ts_runtime_1.getOptionalObject)(dto, "unrealizedGain");
        if (unrealizedGain !== undefined && unrealizedGain !== null) {
            unrealizedGain = bigDecimal_1.BigDecimal.fromDTO(unrealizedGain);
        }
        let realizedGain = (0, leo_ts_runtime_1.getOptionalObject)(dto, "realizedGain");
        if (realizedGain !== undefined && realizedGain !== null) {
            realizedGain = bigDecimal_1.BigDecimal.fromDTO(realizedGain);
        }
        let irr = (0, leo_ts_runtime_1.getOptionalObject)(dto, "irr");
        if (irr !== undefined && irr !== null) {
            irr = bigDecimal_1.BigDecimal.fromDTO(irr);
        }
        return new MFHoldingSummaryResponse(isin, schemeName, amcName, entityName, portfolioName, holdingUnits, marketPrice, marketValue, salesProceed, unrealizedGain, realizedGain, irr);
    }
    toDTO() {
        const ret = {
            isin: this.isin,
        };
        if (this.schemeName !== undefined && this.schemeName !== null) {
            ret.schemeName = this.schemeName;
        }
        if (this.amcName !== undefined && this.amcName !== null) {
            ret.amcName = this.amcName;
        }
        if (this.entityName !== undefined && this.entityName !== null) {
            ret.entityName = this.entityName;
        }
        if (this.portfolioName !== undefined && this.portfolioName !== null) {
            ret.portfolioName = this.portfolioName;
        }
        if (this.holdingUnits) {
            ret.holdingUnits = this.holdingUnits.toDTO();
        }
        if (this.marketPrice) {
            ret.marketPrice = this.marketPrice.toDTO();
        }
        if (this.marketValue) {
            ret.marketValue = this.marketValue.toDTO();
        }
        if (this.salesProceed) {
            ret.salesProceed = this.salesProceed.toDTO();
        }
        if (this.unrealizedGain) {
            ret.unrealizedGain = this.unrealizedGain.toDTO();
        }
        if (this.realizedGain) {
            ret.realizedGain = this.realizedGain.toDTO();
        }
        if (this.irr) {
            ret.irr = this.irr.toDTO();
        }
        return ret;
    }
    copy(isin = this.isin, schemeName = this.schemeName, amcName = this.amcName, entityName = this.entityName, portfolioName = this.portfolioName, holdingUnits = this.holdingUnits, marketPrice = this.marketPrice, marketValue = this.marketValue, salesProceed = this.salesProceed, unrealizedGain = this.unrealizedGain, realizedGain = this.realizedGain, irr = this.irr) {
        return new MFHoldingSummaryResponse(isin, schemeName, amcName, entityName, portfolioName, holdingUnits, marketPrice, marketValue, salesProceed, unrealizedGain, realizedGain, irr);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFHoldingSummaryResponseValue = other.isMFHoldingSummaryResponse;
        if (!(other instanceof MFHoldingSummaryResponse) || Boolean(isMFHoldingSummaryResponseValue)) {
            return false;
        }
        if (this.isin !== other.isin) {
            return false;
        }
        if (this.schemeName !== other.schemeName) {
            return false;
        }
        if (this.amcName !== other.amcName) {
            return false;
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        if (this.portfolioName !== other.portfolioName) {
            return false;
        }
        if (this.holdingUnits) {
            if (!this.holdingUnits.equals(other.holdingUnits)) {
                return false;
            }
        }
        if (this.marketPrice) {
            if (!this.marketPrice.equals(other.marketPrice)) {
                return false;
            }
        }
        if (this.marketValue) {
            if (!this.marketValue.equals(other.marketValue)) {
                return false;
            }
        }
        if (this.salesProceed) {
            if (!this.salesProceed.equals(other.salesProceed)) {
                return false;
            }
        }
        if (this.unrealizedGain) {
            if (!this.unrealizedGain.equals(other.unrealizedGain)) {
                return false;
            }
        }
        if (this.realizedGain) {
            if (!this.realizedGain.equals(other.realizedGain)) {
                return false;
            }
        }
        if (this.irr) {
            if (!this.irr.equals(other.irr)) {
                return false;
            }
        }
        return true;
    }
}
exports.MFHoldingSummaryResponse = MFHoldingSummaryResponse;
MFHoldingSummaryResponse.validateIsin = (isin) => {
    if (!(0, leo_ts_runtime_1.isString)(isin)) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute isin is not a String`);
    }
    if (isin.trim().length === 0) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute isin cannot be an empty string.`);
    }
};
MFHoldingSummaryResponse.validateSchemeName = (schemeName) => {
    if (!(0, leo_ts_runtime_1.isString)(schemeName)) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute schemeName is not a String`);
    }
    if (schemeName.trim().length === 0) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute schemeName cannot be an empty string.`);
    }
};
MFHoldingSummaryResponse.validateAmcName = (amcName) => {
    if (!(0, leo_ts_runtime_1.isString)(amcName)) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute amcName is not a String`);
    }
    if (amcName.trim().length === 0) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute amcName cannot be an empty string.`);
    }
};
MFHoldingSummaryResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
MFHoldingSummaryResponse.validatePortfolioName = (portfolioName) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolioName)) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute portfolioName is not a String`);
    }
    if (portfolioName.trim().length === 0) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute portfolioName cannot be an empty string.`);
    }
};
MFHoldingSummaryResponse.validateHoldingUnits = (holdingUnits) => {
    const isBigDecimalValue = holdingUnits.isBigDecimal;
    if (!(holdingUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute holdingUnits is not a BigDecimal`);
    }
};
MFHoldingSummaryResponse.validateMarketPrice = (marketPrice) => {
    const isBigDecimalValue = marketPrice.isBigDecimal;
    if (!(marketPrice instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute marketPrice is not a BigDecimal`);
    }
};
MFHoldingSummaryResponse.validateMarketValue = (marketValue) => {
    const isBigDecimalValue = marketValue.isBigDecimal;
    if (!(marketValue instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute marketValue is not a BigDecimal`);
    }
};
MFHoldingSummaryResponse.validateSalesProceed = (salesProceed) => {
    const isBigDecimalValue = salesProceed.isBigDecimal;
    if (!(salesProceed instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute salesProceed is not a BigDecimal`);
    }
};
MFHoldingSummaryResponse.validateUnrealizedGain = (unrealizedGain) => {
    const isBigDecimalValue = unrealizedGain.isBigDecimal;
    if (!(unrealizedGain instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute unrealizedGain is not a BigDecimal`);
    }
};
MFHoldingSummaryResponse.validateRealizedGain = (realizedGain) => {
    const isBigDecimalValue = realizedGain.isBigDecimal;
    if (!(realizedGain instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute realizedGain is not a BigDecimal`);
    }
};
MFHoldingSummaryResponse.validateIrr = (irr) => {
    const isBigDecimalValue = irr.isBigDecimal;
    if (!(irr instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFHoldingSummaryResponseError(`Attribute irr is not a BigDecimal`);
    }
};
MFHoldingSummaryResponse.prototype.toString = function toString() {
    return `MFHoldingSummaryResponse(isin='${this.isin}',schemeName='${this.schemeName}',amcName='${this.amcName}',entityName='${this.entityName}',portfolioName='${this.portfolioName}',holdingUnits=${this.holdingUnits},marketPrice=${this.marketPrice},marketValue=${this.marketValue},salesProceed=${this.salesProceed},unrealizedGain=${this.unrealizedGain},realizedGain=${this.realizedGain},irr=${this.irr})`;
};
