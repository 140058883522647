import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-core";
import React from "react";

/**
 * Function to determine the color of the list item
 * @param isActive - Indicates if the item is active or not
 * @returns - If `isActive` is true, returns tokens.labelPrimary, else tokens.label
 * @example -
 *      isActive: true
 *      Function returns: `tokens.labelPrimary`
 */
export const getSidebarColor = (isActive?: boolean): string => {
  const tokens = useFoundationColorTokens();
  if (isActive) {
    return tokens.labelPrimary;
  }
  return tokens.label;
};

/**
 * Overrides the Icon properties
 * @param icon - Icon passed to sidebar
 * @param color - Color of the Icon
 * @returns the icon with updated properties
 */
export const getSidebarIcon = (
  icon: React.ReactElement,
  color?: string,
): React.ReactElement<React.SVGProps<SVGSVGElement>> | undefined => {
  if (icon) {
    const updatedImage = React.cloneElement(icon, {
      color,
      width: "24px",
      height: "24px",
    });
    return updatedImage;
  }
};

/**
 * Checks if Sidebar is overflowing
 * @param sidebarTopMenu - Reference to the sidebar top menu component
 * @param onSidebarOverflow - Callback to update the isOverflow
 * @returns true, if top menu is overflowing else false
 */
export const checkSidebarOverflow = (
  sidebarTopMenu: React.RefObject<HTMLDivElement | undefined>,
  onSidebarOverflow: Function,
): boolean => {
  const [isOverflow, setIsOverflow] = React.useState<boolean>(false);
  React.useLayoutEffect(() => {
    const { current } = sidebarTopMenu;
    const trigger = (): void => {
      const hasOverflow = current
        ? current.scrollHeight > current.clientHeight
        : false;
      setIsOverflow(hasOverflow);
      if (onSidebarOverflow) onSidebarOverflow(hasOverflow);
    };
    if (current) {
      trigger();
    }
    window.addEventListener("resize", trigger);
    return () => window.removeEventListener("resize", trigger);
  }, [onSidebarOverflow, sidebarTopMenu]);
  return isOverflow;
};
