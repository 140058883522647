import { FiSettleCashflowRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFiSettleCashflowRPCImpl extends FiSettleCashflowRPC {
  execute(
    _request: FiSettleCashflowRPC.Request,
  ): Promise<
    LeoRPCResult<
      FiSettleCashflowRPC.Response,
      FiSettleCashflowRPC.Errors.Errors
    >
  > {
    const response = new FiSettleCashflowRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        FiSettleCashflowRPC.Response,
        FiSettleCashflowRPC.Errors.Errors
      >
    >;
  }
}
