"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonSortOrder = exports.InvalidCommonSortOrderError = void 0;
class InvalidCommonSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidCommonSortOrderError";
    }
}
exports.InvalidCommonSortOrderError = InvalidCommonSortOrderError;
/* eslint-disable @typescript-eslint/no-shadow */
var CommonSortOrder;
(function (CommonSortOrder_1) {
    let CommonSortOrder;
    (function (CommonSortOrder) {
        CommonSortOrder["ASCENDING"] = "ASCENDING";
        CommonSortOrder["DESCENDING"] = "DESCENDING";
    })(CommonSortOrder = CommonSortOrder_1.CommonSortOrder || (CommonSortOrder_1.CommonSortOrder = {}));
    CommonSortOrder_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ASCENDING":
                return CommonSortOrder.ASCENDING;
            case "DESCENDING":
                return CommonSortOrder.DESCENDING;
            default:
                throw new InvalidCommonSortOrderError(`Case ${dto.case} is not valid case of CommonSortOrder`);
        }
    };
    CommonSortOrder_1.toDTO = (commonSortOrder) => {
        const ret = {
            case: CommonSortOrder[commonSortOrder],
        };
        return ret;
    };
    CommonSortOrder_1.isInstanceOf = (other) => {
        if (other in CommonSortOrder) {
            return true;
        }
        return false;
    };
})(CommonSortOrder = exports.CommonSortOrder || (exports.CommonSortOrder = {}));
/* eslint-enable @typescript-eslint/no-shadow */
