import {
  DealRequestSearchBy,
  EquityDealRequestStatus,
} from "@khazana/khazana-rpcs";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { AllEnum } from "../../../../../types/EnumTypes";
import { getRequestStatusValue } from "../../utils/SearchUtils";
import { DealRequestsFilterOptionsModel } from "../../../models/DealRequestsFilterOptionsModel";

export const getEquityDealRequestsInitialFilter = (): Instance<
  typeof DealRequestsFilterOptionsModel
> => {
  return DealRequestsFilterOptionsModel.create({
    searchBy: DealRequestSearchBy.DealRequestSearchBy.REQUEST_ID,
    searchText: "",
    requestStatus: AllEnum.All,
    transactionType: AllEnum.All,
  });
};

export const getDealRequestStatusOptionsWithAny = (
  t: TFunction,
): DropdownItem[] => {
  const values = Object.keys(EquityDealRequestStatus.EquityDealRequestStatus);
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getRequestStatusValue(
      t,
      value as EquityDealRequestStatus.EquityDealRequestStatus,
    );
    return { name, value };
  });
  return [
    {
      name: t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItems,
  ];
};

export const getDealRequestSearchByValue = (
  t: TFunction,
  value: DealRequestSearchBy.DealRequestSearchBy,
): string => {
  const searchByType = DealRequestSearchBy.DealRequestSearchBy;
  switch (value) {
    case searchByType.REQUEST_ID:
      return t("common.requestId");
    case searchByType.PORTFOLIO:
      return t("common.portfolio");
    case searchByType.SYMBOL:
      return t("common.symbol");
    case searchByType.DEMAT_ACCOUNT_NUMBER:
      return t("common.dematAccountNumber");
  }
};

export const getDealRequestSearchByOptions = (t: TFunction): DropdownItem[] => {
  const values = Object.values(DealRequestSearchBy.DealRequestSearchBy);
  return values.map((value: string): DropdownItem => {
    const name = getDealRequestSearchByValue(
      t,
      value as DealRequestSearchBy.DealRequestSearchBy,
    );
    return { name, value };
  });
};
