import React from "react";
import { RouteObject } from "react-router-dom";
import { MFUserPrivileges } from "../modules/user/UserPrivileges";
import { Module, Route } from "./RoutesEnum";
import { CreateMFDealRequest } from "../modules/home/mutual-fund/deal-request/pages/CreateMFDealRequest";
import { ManageDealRequests } from "../modules/home/mutual-fund/deal-request/pages/ManageDealRequests";
import { MFDealRequestDetails } from "../modules/home/mutual-fund/deal-request/pages/MFDealRequestDetails";
import { ManageConfirmationRequests } from "../modules/home/mutual-fund/confirmation/pages/ManageConfirmationRequests";
import { CreateConfirmationRequest } from "../modules/home/mutual-fund/confirmation/pages/CreateConfirmationRequest";
import { MFConfirmationRequestDetails } from "../modules/home/mutual-fund/confirmation/pages/MFConfirmationRequestDetails";
import { MfReportList } from "../modules/home/mutual-fund/reports/pages/MfReportList";
import { MfHoldingSummary } from "../modules/home/mutual-fund/reports/pages/MfHoldingSummary";
import { MfInvestmentReturnsGrowth } from "../modules/home/mutual-fund/reports/pages/MfInvestmentReturnsGrowth";

export const getMFRoutes = (userPrivileges: string[]): RouteObject[] => {
  const mfChildren: RouteObject[] = [];
  const mfPath = Module.MF;

  if (userPrivileges.includes(MFUserPrivileges.CreateMutualFundDealRequest)) {
    const createDealRequestRoute: RouteObject = {
      path: Route.CreateDealRequest,
      element: <CreateMFDealRequest />,
    };
    mfChildren.push(createDealRequestRoute);
  }

  if (userPrivileges.includes(MFUserPrivileges.ViewMutualFundDealRequest)) {
    const viewDealRequestsRoute: RouteObject = {
      path: Route.ManageDealRequest,
      element: <ManageDealRequests />,
    };
    mfChildren.push(viewDealRequestsRoute);
    const viewDealRequestDetailsRoute: RouteObject = {
      path: Route.ManageDealRequestDetails,
      element: <MFDealRequestDetails />,
    };
    mfChildren.push(viewDealRequestDetailsRoute);
  }

  if (
    userPrivileges.includes(MFUserPrivileges.ViewMutualFundConfirmationRequest)
  ) {
    const viewContractNoteRoute: RouteObject = {
      path: Route.ManageConfirmationRequests,
      element: <ManageConfirmationRequests />,
    };
    mfChildren.push(viewContractNoteRoute);
    const viewDealRequestDetailsRoute: RouteObject = {
      path: Route.ManageConfirmationRequestsDetails,
      element: <MFConfirmationRequestDetails />,
    };
    mfChildren.push(viewDealRequestDetailsRoute);
  }

  if (
    userPrivileges.includes(
      MFUserPrivileges.CreateMutualFundConfirmationRequest,
    )
  ) {
    const createConfirmationRequestRoute: RouteObject = {
      path: Route.CreateConfirmationRequest,
      element: <CreateConfirmationRequest />,
    };
    mfChildren.push(createConfirmationRequestRoute);
    const editConfirmationRequestRoute: RouteObject = {
      path: Route.EditConfirmationRequest,
      element: <CreateConfirmationRequest />,
    };
    mfChildren.push(editConfirmationRequestRoute);
  }

  if (userPrivileges.includes(MFUserPrivileges.ViewMutualFundReports)) {
    const mfReportsListRoute: RouteObject = {
      path: Route.Reports,
      element: <MfReportList />,
    };
    mfChildren.push(mfReportsListRoute);

    const holdingSummary: RouteObject = {
      path: Route.HoldingSummary,
      element: <MfHoldingSummary />,
    };
    mfChildren.push(holdingSummary);

    const investmentReturnsGrowth: RouteObject = {
      path: Route.InvestmentReturnsGrowthReport,
      element: <MfInvestmentReturnsGrowth />,
    };
    mfChildren.push(investmentReturnsGrowth);
  }

  if (mfChildren.length === 0) {
    return [];
  } else {
    return [
      {
        path: mfPath,
        children: mfChildren,
      },
    ];
  }
};
