"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettleMFDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SettleMFDealRequestRPC {
}
exports.SettleMFDealRequestRPC = SettleMFDealRequestRPC;
(function (SettleMFDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SettleMFDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(dealRequestId, note = null) {
            this.isSettleMFDealRequestRPCRequest = true;
            Request.validateDealRequestId(dealRequestId);
            this.dealRequestId = dealRequestId;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const dealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "dealRequestId", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(dealRequestId, note);
        }
        toDTO() {
            const ret = {
                dealRequestId: this.dealRequestId,
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(dealRequestId = this.dealRequestId, note = this.note) {
            return new Request(dealRequestId, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSettleMFDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.dealRequestId !== other.dealRequestId) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateDealRequestId = (dealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(dealRequestId)) {
            throw new InvalidRequestError(`Attribute dealRequestId is not an Int64`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SettleMFDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SettleMFDealRequestRPC.Request(dealRequestId=${this.dealRequestId},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SettleMFDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SettleMFDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `SettleMFDealRequestRPC.INVALID_REQUEST_ID()`;
        };
        class CanOnlySettleSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_ONLY_SETTLE_SELF_REQUEST") {
                super(code);
                this.isCanOnlySettleSelfRequest = true;
                CanOnlySettleSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanOnlySettleSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanOnlySettleSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
                if (!(other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanOnlySettleSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_ONLY_SETTLE_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanOnlySettleSelfRequest = CanOnlySettleSelfRequest;
        CanOnlySettleSelfRequest.prototype.toString = function toString() {
            return `SettleMFDealRequestRPC.CAN_ONLY_SETTLE_SELF_REQUEST()`;
        };
        class IllegalRequestState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_REQUEST_STATE") {
                super(code);
                this.isIllegalRequestState = true;
                IllegalRequestState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalRequestState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalRequestState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalRequestStateValue = other.isIllegalRequestState;
                if (!(other instanceof IllegalRequestState || Boolean(isIllegalRequestStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalRequestState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_REQUEST_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalRequestState = IllegalRequestState;
        IllegalRequestState.prototype.toString = function toString() {
            return `SettleMFDealRequestRPC.ILLEGAL_REQUEST_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "CAN_ONLY_SETTLE_SELF_REQUEST":
                    return CanOnlySettleSelfRequest.fromDTO(dto);
                case "ILLEGAL_REQUEST_STATE":
                    return IllegalRequestState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isCanOnlySettleSelfRequestValue = other.isCanOnlySettleSelfRequest;
            if (other instanceof CanOnlySettleSelfRequest || Boolean(isCanOnlySettleSelfRequestValue)) {
                return true;
            }
            const isIllegalRequestStateValue = other.isIllegalRequestState;
            if (other instanceof IllegalRequestState || Boolean(isIllegalRequestStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SettleMFDealRequestRPC.Errors || (SettleMFDealRequestRPC.Errors = {}));
})(SettleMFDealRequestRPC = exports.SettleMFDealRequestRPC || (exports.SettleMFDealRequestRPC = {}));
