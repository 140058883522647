import { GetFixedDepositInvestmentRegisterDownloadURLRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFixedDepositInvestmentRegisterDownloadURLRPC extends GetFixedDepositInvestmentRegisterDownloadURLRPC {
  execute(
    _request: GetFixedDepositInvestmentRegisterDownloadURLRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFixedDepositInvestmentRegisterDownloadURLRPC.Response,
      GetFixedDepositInvestmentRegisterDownloadURLRPC.Errors.Errors
    >
  > {
    const response =
      new GetFixedDepositInvestmentRegisterDownloadURLRPC.Response(
        new URL(
          "https://f005.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_zc6fbc72522a28f4288ae0314_f107bdb3be8d81487_d20230911_m122228_c005_v0501003_t0012_u01694434948893",
        ),
      );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFixedDepositInvestmentRegisterDownloadURLRPC.Response,
        GetFixedDepositInvestmentRegisterDownloadURLRPC.Errors.Errors
      >
    >;
  }
}
