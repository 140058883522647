import { Instance } from "mobx-state-tree";
import {
  MFConfirmationRequestStatus,
  MFConfirmationSearchBy,
} from "@khazana/khazana-rpcs";
import { AllEnum } from "../../../../../types/EnumTypes";
import { MFConfirmationFilterOptionsModel } from "../store/ManageConfirmationRequestsStore/ManageConfirmationDetailsStore";
import i18next from "i18next";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import { getMFConfirmationRequestStatusValue } from "./UIUtils";

export const getContractNoteStatusOptionsWithAll = (): DropdownItem[] => {
  const values = Object.keys(
    MFConfirmationRequestStatus.MFConfirmationRequestStatus,
  );
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getMFConfirmationRequestStatusValue(
      value as MFConfirmationRequestStatus.MFConfirmationRequestStatus,
    );
    return { name, value };
  });
  return [
    {
      name: i18next.t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItems,
  ];
};

export const getConfirmationSearchByValue = (
  value: MFConfirmationSearchBy.MFConfirmationSearchBy,
): string => {
  const searchByType = MFConfirmationSearchBy.MFConfirmationSearchBy;
  switch (value) {
    case searchByType.REQUEST_ID:
      return i18next.t("common.requestId");
    case searchByType.REFERENCE_NUMBER:
      return i18next.t("mf.fields.referenceNumberLabel");
    case searchByType.SCHEME:
      return i18next.t("mf.fields.schemeLabel");
  }
};

export const getContractNoteSearchByOptions = (): DropdownItem[] => {
  const values = Object.values(MFConfirmationSearchBy.MFConfirmationSearchBy);
  return values.map((value: string): DropdownItem => {
    const name = getConfirmationSearchByValue(
      value as MFConfirmationSearchBy.MFConfirmationSearchBy,
    );
    return { name, value };
  });
};

export const getMFConfirmationInitialFilter = (): Instance<
  typeof MFConfirmationFilterOptionsModel
> => {
  return MFConfirmationFilterOptionsModel.create({
    searchBy: MFConfirmationSearchBy.MFConfirmationSearchBy.SCHEME,
    searchText: "",
    requestStatus: AllEnum.All,
    brokerId: AllEnum.All,
  });
};
