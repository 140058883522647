import { SubmitMFConfirmationLinkRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitMFConfirmationLinkRequestRPCImpl extends SubmitMFConfirmationLinkRequestRPC {
  execute(
    _request: SubmitMFConfirmationLinkRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitMFConfirmationLinkRequestRPC.Response,
      SubmitMFConfirmationLinkRequestRPC.Errors.Errors
    >
  > {
    const response = new SubmitMFConfirmationLinkRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitMFConfirmationLinkRequestRPC.Response,
        SubmitMFConfirmationLinkRequestRPC.Errors.Errors
      >
    >;
  }
}
