import React from "react";
import { Stack, Typography } from "@mui/material";
import { EmptyStateIcon } from "../../../../assets/EmptyStateIcon";
import { useTranslation } from "react-i18next";
import { useTypography } from "@surya-digital/leo-reactjs-material-ui";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";

const Size = {
  iconWidth: "32px",
  iconHeight: "32px",
};

export interface EmptyStateProps {
  message?: string;
}
export const EmptyState = ({
  message,
}: EmptyStateProps): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      spacing={spacing.spaceXS}
    >
      <EmptyStateIcon width={Size.iconWidth} height={Size.iconHeight} />
      <Typography
        sx={{
          color: tokens.labelLowEmphasis,
          typography: typography.b1,
          textAlign: "center",
        }}
      >
        {message ?? t("common.noDataFound")}
      </Typography>
    </Stack>
  );
};
