import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";
import { FDWithdrawListItem, FDWithdrawStatus } from "@khazana/khazana-rpcs";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import { getAmount } from "../../../../../utils";

export const FDWithdrawModel = types
  .model("FDWithdrawModel", {
    requestId: types.maybe(types.number),
    withdrawalAmount: types.maybe(types.number),
    withdrawalAmountError: types.maybe(types.boolean),
    penaltyAmount: types.maybe(types.number),
    penaltyAmountError: types.maybe(types.boolean),
    penaltyCharges: types.maybe(types.number),
    penaltyChargeError: types.maybe(types.boolean),
    withdrawalReferenceNumber: types.maybe(types.string),
    status: types.maybe(
      types.enumeration<FDWithdrawStatus.FDWithdrawStatus>(
        "withdrawStatus",
        Object.values(FDWithdrawStatus.FDWithdrawStatus),
      ),
    ),
    actualWithdrawalDate: types.maybe(types.Date),
    actualWithdrawalDateError: types.maybe(types.boolean),
    requestWithdrawalDate: types.maybe(types.Date),
    currency: types.maybe(CurrencyModel),
    showWarning: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setWithdrawalAmount: (amount: string): void => {
        store.showWarning = undefined;
        store.withdrawalAmountError = false;
        store.withdrawalAmount = Number(amount);
      },
      setPenaltyAmount: (amount: string): void => {
        store.penaltyAmountError = false;
        store.penaltyAmount = Number(amount);
      },
      setPenaltyCharges: (amount: string): void => {
        store.penaltyChargeError = false;
        store.penaltyCharges = Number(amount);
      },
      setWithdrawalReferenceNumber: (referenceNumber: string): void => {
        store.withdrawalReferenceNumber = referenceNumber;
      },
      setActualWithdrawalDate: (date: Date | null): void => {
        store.actualWithdrawalDateError = false;
        store.actualWithdrawalDate = date ?? undefined;
      },
      setActualDateError: (error: boolean): void => {
        store.actualWithdrawalDateError = error;
      },
      setCurrency: (
        currency: Instance<typeof CurrencyModel> | undefined,
      ): void => {
        if (currency) {
          store.currency = CurrencyModel.create({
            code: currency.code,
            symbol: currency.symbol,
          });
        }
      },
      setShowWarning: (message: string): void => {
        store.withdrawalAmountError = true;
        store.showWarning = message;
      },
      isCreateValid: (): boolean => {
        let isValid = true;
        if (!store.withdrawalAmount) {
          store.withdrawalAmountError = true;
          isValid = false;
        }
        if (!store.penaltyCharges) {
          store.penaltyChargeError = true;
          isValid = false;
        }
        if (!store.penaltyAmount) {
          store.penaltyAmountError = true;
          isValid = false;
        }
        return isValid;
      },
    };
  })
  .actions((store) => ({
    isConfirmValid: (): boolean => {
      let isValid = store.isCreateValid();
      if (!store.actualWithdrawalDate) {
        store.actualWithdrawalDateError = true;
        isValid = false;
      }
      return isValid;
    },
  }))
  .views((store) => ({
    get currencySymbol(): string | undefined {
      if (store.currency) {
        return `(${store.currency.symbol})`;
      }
    },
  }));

export const createFDWithdrawModel = (
  item?: Instance<typeof FDWithdrawModel>,
): Instance<typeof FDWithdrawModel> => {
  if (item) {
    return FDWithdrawModel.create({
      requestId: item.requestId,
      withdrawalAmount: item.withdrawalAmount,
      penaltyAmount: item.penaltyAmount,
      penaltyCharges: item.penaltyCharges,
      withdrawalReferenceNumber: item.withdrawalReferenceNumber,
      status: item.status,
      actualWithdrawalDate: item.actualWithdrawalDate,
      requestWithdrawalDate: item.requestWithdrawalDate,
      currency: item.currency
        ? CurrencyModel.create({
            code: item.currency.code,
            symbol: item.currency.symbol,
          })
        : undefined,
    });
  }
  return FDWithdrawModel.create();
};

export const createFDWithdrawModelFromRPCType = (
  item: FDWithdrawListItem,
): Instance<typeof FDWithdrawModel> => {
  return FDWithdrawModel.create({
    requestId: item.requestId,
    withdrawalAmount: getAmount(item.withdrawalAmount.amount),
    penaltyAmount: getAmount(item.penaltyAmount.amount),
    penaltyCharges: getAmount(item.penaltyCharges),
    withdrawalReferenceNumber: item.withdrawReferenceNumber ?? undefined,
    status: item.status,
    actualWithdrawalDate: item.actualWithdrawalDate
      ? new Date(item.actualWithdrawalDate.date)
      : undefined,
    requestWithdrawalDate: new Date(item.requestedWithdrawDate.date),
    currency: createCurrencyModel(item.withdrawalAmount.currency),
  });
};
