"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const rateOfInterest_1 = require("./rateOfInterest");
const transactionAmount_1 = require("../types/transactionAmount");
const fdDepositDuration_1 = require("./fdDepositDuration");
const interestType_1 = require("../types/interestType");
/* eslint-disable import/export */
class GetFdDetailsRPC {
}
exports.GetFdDetailsRPC = GetFdDetailsRPC;
(function (GetFdDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFdDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(interestType, rateOfInterest, valueDate, depositAmount, depositDuration) {
            this.isGetFdDetailsRPCRequest = true;
            Request.validateInterestType(interestType);
            this.interestType = interestType;
            Request.validateRateOfInterest(rateOfInterest);
            this.rateOfInterest = rateOfInterest;
            Request.validateValueDate(valueDate);
            this.valueDate = valueDate;
            Request.validateDepositAmount(depositAmount);
            this.depositAmount = depositAmount;
            Request.validateDepositDuration(depositDuration);
            this.depositDuration = depositDuration;
        }
        static fromDTO(dto) {
            const interestType = interestType_1.InterestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "interestType", InvalidRequestError));
            const rateOfInterest = rateOfInterest_1.RateOfInterest.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "rateOfInterest", InvalidRequestError));
            const valueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "valueDate", InvalidRequestError);
            const depositAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositAmount", InvalidRequestError));
            const depositDuration = fdDepositDuration_1.FdDepositDuration.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "depositDuration", InvalidRequestError));
            return new Request(interestType, rateOfInterest, valueDate, depositAmount, depositDuration);
        }
        toDTO() {
            const ret = {
                interestType: interestType_1.InterestType.toDTO(this.interestType),
                rateOfInterest: this.rateOfInterest.toDTO(),
                valueDate: this.valueDate.toDTO(),
                depositAmount: this.depositAmount.toDTO(),
                depositDuration: this.depositDuration.toDTO(),
            };
            return ret;
        }
        copy(interestType = this.interestType, rateOfInterest = this.rateOfInterest, valueDate = this.valueDate, depositAmount = this.depositAmount, depositDuration = this.depositDuration) {
            return new Request(interestType, rateOfInterest, valueDate, depositAmount, depositDuration);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFdDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.interestType !== other.interestType) {
                return false;
            }
            if (!this.rateOfInterest.equals(other.rateOfInterest)) {
                return false;
            }
            if (!this.valueDate.equals(other.valueDate)) {
                return false;
            }
            if (!this.depositAmount.equals(other.depositAmount)) {
                return false;
            }
            if (!this.depositDuration.equals(other.depositDuration)) {
                return false;
            }
            return true;
        }
    }
    Request.validateInterestType = (interestType) => {
        if (!interestType_1.InterestType.isInstanceOf(interestType)) {
            throw new InvalidRequestError(`Attribute interestType is not a InterestType.InterestType`);
        }
    };
    Request.validateRateOfInterest = (rateOfInterest) => {
        const isRateOfInterestValue = rateOfInterest.isRateOfInterest;
        if (!(rateOfInterest instanceof rateOfInterest_1.RateOfInterest || Boolean(isRateOfInterestValue))) {
            throw new InvalidRequestError(`Attribute rateOfInterest is not a RateOfInterest`);
        }
    };
    Request.validateValueDate = (valueDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(valueDate)) {
            throw new InvalidRequestError(`Attribute valueDate is not a Date`);
        }
    };
    Request.validateDepositAmount = (depositAmount) => {
        const isTransactionAmountValue = depositAmount.isTransactionAmount;
        if (!(depositAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidRequestError(`Attribute depositAmount is not a TransactionAmount`);
        }
    };
    Request.validateDepositDuration = (depositDuration) => {
        const isFdDepositDurationValue = depositDuration.isFdDepositDuration;
        if (!(depositDuration instanceof fdDepositDuration_1.FdDepositDuration || Boolean(isFdDepositDurationValue))) {
            throw new InvalidRequestError(`Attribute depositDuration is not a FdDepositDuration`);
        }
    };
    GetFdDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFdDetailsRPC.Request(interestType=${this.interestType},rateOfInterest=${this.rateOfInterest},valueDate=${this.valueDate},depositAmount=${this.depositAmount},depositDuration=${this.depositDuration})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFdDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(interestEarned, maturityAmount, maturityDate) {
            this.isGetFdDetailsRPCResponse = true;
            Response.validateInterestEarned(interestEarned);
            this.interestEarned = interestEarned;
            Response.validateMaturityAmount(maturityAmount);
            this.maturityAmount = maturityAmount;
            Response.validateMaturityDate(maturityDate);
            this.maturityDate = maturityDate;
        }
        static fromDTO(dto) {
            const interestEarned = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "interestEarned", InvalidResponseError));
            const maturityAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "maturityAmount", InvalidResponseError));
            const maturityDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "maturityDate", InvalidResponseError);
            return new Response(interestEarned, maturityAmount, maturityDate);
        }
        toDTO() {
            const ret = {
                interestEarned: this.interestEarned.toDTO(),
                maturityAmount: this.maturityAmount.toDTO(),
                maturityDate: this.maturityDate.toDTO(),
            };
            return ret;
        }
        copy(interestEarned = this.interestEarned, maturityAmount = this.maturityAmount, maturityDate = this.maturityDate) {
            return new Response(interestEarned, maturityAmount, maturityDate);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFdDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.interestEarned.equals(other.interestEarned)) {
                return false;
            }
            if (!this.maturityAmount.equals(other.maturityAmount)) {
                return false;
            }
            if (!this.maturityDate.equals(other.maturityDate)) {
                return false;
            }
            return true;
        }
    }
    Response.validateInterestEarned = (interestEarned) => {
        const isTransactionAmountValue = interestEarned.isTransactionAmount;
        if (!(interestEarned instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidResponseError(`Attribute interestEarned is not a TransactionAmount`);
        }
    };
    Response.validateMaturityAmount = (maturityAmount) => {
        const isTransactionAmountValue = maturityAmount.isTransactionAmount;
        if (!(maturityAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidResponseError(`Attribute maturityAmount is not a TransactionAmount`);
        }
    };
    Response.validateMaturityDate = (maturityDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
            throw new InvalidResponseError(`Attribute maturityDate is not a Date`);
        }
    };
    GetFdDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFdDetailsRPC.Response(interestEarned=${this.interestEarned},maturityAmount=${this.maturityAmount},maturityDate=${this.maturityDate})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidDepositDuration extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEPOSIT_DURATION") {
                super(code);
                this.isInvalidDepositDuration = true;
                InvalidDepositDuration.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDepositDuration(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDepositDuration(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDepositDurationValue = other.isInvalidDepositDuration;
                if (!(other instanceof InvalidDepositDuration || Boolean(isInvalidDepositDurationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDepositDuration.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEPOSIT_DURATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDepositDuration = InvalidDepositDuration;
        InvalidDepositDuration.prototype.toString = function toString() {
            return `GetFdDetailsRPC.INVALID_DEPOSIT_DURATION()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_DEPOSIT_DURATION":
                    return InvalidDepositDuration.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidDepositDurationValue = other.isInvalidDepositDuration;
            if (other instanceof InvalidDepositDuration || Boolean(isInvalidDepositDurationValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFdDetailsRPC.Errors || (GetFdDetailsRPC.Errors = {}));
})(GetFdDetailsRPC = exports.GetFdDetailsRPC || (exports.GetFdDetailsRPC = {}));
