"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PercentageDate = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidPercentageDateError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidPercentageDateError";
    }
}
class PercentageDate {
    constructor(percentage, date) {
        this.isPercentageDate = true;
        PercentageDate.validatePercentage(percentage);
        this.percentage = percentage;
        PercentageDate.validateDate(date);
        this.date = date;
    }
    static fromDTO(dto) {
        const percentage = (0, leo_ts_runtime_1.getInt64)(dto, "percentage", InvalidPercentageDateError);
        const date = (0, leo_ts_runtime_1.getLeoDate)(dto, "date", InvalidPercentageDateError);
        return new PercentageDate(percentage, date);
    }
    toDTO() {
        const ret = {
            percentage: this.percentage,
            date: this.date.toDTO(),
        };
        return ret;
    }
    copy(percentage = this.percentage, date = this.date) {
        return new PercentageDate(percentage, date);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isPercentageDateValue = other.isPercentageDate;
        if (!(other instanceof PercentageDate) || Boolean(isPercentageDateValue)) {
            return false;
        }
        if (this.percentage !== other.percentage) {
            return false;
        }
        if (!this.date.equals(other.date)) {
            return false;
        }
        return true;
    }
}
exports.PercentageDate = PercentageDate;
PercentageDate.validatePercentage = (percentage) => {
    if (!(0, leo_ts_runtime_1.isInt64)(percentage)) {
        throw new InvalidPercentageDateError(`Attribute percentage is not an Int64`);
    }
};
PercentageDate.validateDate = (date) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(date)) {
        throw new InvalidPercentageDateError(`Attribute date is not a Date`);
    }
};
PercentageDate.prototype.toString = function toString() {
    return `PercentageDate(percentage=${this.percentage},date=${this.date})`;
};
