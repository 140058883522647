import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { useTypography } from "@surya-digital/leo-reactjs-material-ui";
import { t } from "i18next";
import React from "react";
import { WarningAlertComponent } from "../WarningAlertComponent";
import { useBorder } from "../../../../utils/BorderUtils";

interface TransactionDetailsProps {
  title: string;
  section: {
    label?: string;
    rows: { label: string; value: string | undefined }[];
  }[];
  isSell: boolean;
  totalAmountLabel?: string | undefined;
  totalAmount: string | undefined;
  postfixLabel: string | undefined;
  errorBankBalance: boolean;
  isTransactionDetailsLoading: boolean;
}

interface CardRowProps {
  heading: string;
  value?: string;
}

interface CardSummaryRowProps {
  heading: string;
  value: string | undefined;
}

const Size = {
  container: "386px",
  errorBanner: "64px",
  valueWidth: "200px",
};

export const TransactionDetails = ({
  title,
  section,
  totalAmountLabel,
  totalAmount,
  isSell,
  postfixLabel,
  errorBankBalance,
  isTransactionDetailsLoading,
}: TransactionDetailsProps): React.ReactElement => {
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const typography = useTypography();

  const getTransactionAmountLabel = (): string => {
    if (totalAmountLabel) {
      return totalAmountLabel;
    } else {
      if (isSell) {
        return t("common.minReceivableAmount", { val: postfixLabel });
      } else {
        return t("common.maxPayableAmount", { val: postfixLabel });
      }
    }
  };

  const CardRow = ({ heading, value }: CardRowProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={spacing.spaceXXS}
      >
        <Typography sx={{ ...typography.b2, color: tokens.label }}>
          {heading}
        </Typography>
        <Typography
          sx={{
            ...typography.b2,
            color: tokens.label,
            width: Size.valueWidth,
            textAlign: "right",
          }}
        >
          {value ?? "-"}
        </Typography>
      </Stack>
    );
  };

  const CardSummaryRow = ({
    heading,
    value,
  }: CardSummaryRowProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingY={spacing.spaceSM}
        borderTop={border.default}
        borderBottom={border.default}
      >
        <Typography sx={typography.s2}>{heading}</Typography>
        <Typography sx={typography.s2}>{value}</Typography>
      </Stack>
    );
  };

  const WarningBanner = (): React.ReactElement => {
    if (errorBankBalance) {
      return (
        <WarningAlertComponent
          text={t("common.availableBalanceErrorMessage")}
        />
      );
    }
    return <></>;
  };

  const getSection = (
    index: number,
    sectionTitle: string | undefined,
    rows: { label: string; value: string | undefined }[],
  ): React.ReactElement => {
    return (
      <div key={index}>
        <Typography sx={typography.b2}>{sectionTitle}</Typography>
        <Box
          padding={`${sectionTitle ? spacing.spaceSM : 0} 0 ${
            spacing.spaceSM
          } ${sectionTitle ? spacing.spaceSM : 0}`}
        >
          {rows.map((row) => {
            return (
              <CardRow key={row.label} heading={row.label} value={row.value} />
            );
          })}
        </Box>
      </div>
    );
  };

  return (
    <Stack
      width={Size.container}
      padding={spacing.spaceMD}
      spacing={spacing.spaceMD}
      height="fit-content"
      sx={{
        borderRadius: cornerRadius.radiusXS,
        background: tokens.background,
        border: border.default,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={typography.sh2}>
          {title ?? t("common.transactionDetails")}
        </Typography>
        {isTransactionDetailsLoading && (
          <CircularProgress
            size={28}
            sx={{
              color: tokens.iconPrimary,
            }}
          />
        )}
      </Stack>
      <Stack spacing={spacing.spaceXS}>
        {section.map((item, index) => getSection(index, item.label, item.rows))}
      </Stack>
      <CardSummaryRow
        heading={getTransactionAmountLabel()}
        value={t("common.decimal2", { val: totalAmount })}
      />
      {WarningBanner()}
    </Stack>
  );
};
