import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createPercentageDateModel,
  PercentageDateModel,
} from "../../../models/PercentageDateModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetEquityBenchmarkReturnsGraphRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetEquityBenchmarkReturnsGraphRPC } from "../rpcs/RPC";
import {
  createServerDateRangeModel,
  EquityReportDateRangeModel,
  ReportDateRange,
} from "../../models/EquityReportDateRangeModel";
import { DateRangeModel } from "../../models/DateRangeModel";
import {
  BenchmarkIndexDropdownStore,
  createBenchmarkIndexDropdownStore,
} from "../../../store/BenchmarkIndexDropdownStore";

export const EquityReturnVsBenchmarkStore = types
  .model("EquityReturnVsBenchmarkStore", {
    index: types.number,
    dateRange: EquityReportDateRangeModel,
    benchmarks: types.array(PercentageDateModel),
    returns: types.array(PercentageDateModel),
    benchmarkIndex: BenchmarkIndexDropdownStore,
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      onDateRangeChange: (
        dateRange:
          | ReportDateRange
          | { startDate: Date | null; endDate: Date | null },
      ): void => {
        if (typeof dateRange === "string") {
          store.dateRange = dateRange;
        } else if (typeof dateRange === "object") {
          store.dateRange = DateRangeModel.create({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          });
        }
      },
      getEquityBenchmarkReturnsGraph: flow(function* () {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetEquityBenchmarkReturnsGraphRPC.Request(
          store.benchmarkIndex.selectedBenchmarkIndex?.id ?? 0,
          createServerDateRangeModel(store.dateRange),
        );
        store.isLoading = true;
        const result: LeoRPCResult<
          GetEquityBenchmarkReturnsGraphRPC.Response,
          GetEquityBenchmarkReturnsGraphRPC.Errors.Errors
        > =
          yield useGetEquityBenchmarkReturnsGraphRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.benchmarks = cast(
            response.benchmarkData.map((benchmark) =>
              createPercentageDateModel(benchmark),
            ),
          );
          store.returns = cast(
            response.returnsData.map((returns) =>
              createPercentageDateModel(returns),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getEquityBenchmarkReturnsGraphRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
    };
  });

export const createEquityReturnVsBenchmarkStore = (): Instance<
  typeof EquityReturnVsBenchmarkStore
> => {
  return EquityReturnVsBenchmarkStore.create({
    index: 0,
    dateRange: ReportDateRange.YTD,
    benchmarks: [],
    returns: [],
    benchmarkIndex: createBenchmarkIndexDropdownStore(),
  });
};
