import { castToSnapshot, Instance, types } from "mobx-state-tree";
import {
  createFDDealRequestStore,
  CreateFDDealRequestStore,
} from "../deal-request/store/CreateFDDealRequestStore";
import {
  createFDDealRequestsStore,
  FDDealRequestsStore,
} from "../deal-request/store/FDDealRequestsStore";
import {
  createFDDealDetailsStore,
  FDDealRequestDetailsStore,
} from "../deal-request/store/FDDealRequestDetailsStore";
import {
  createFdReportsStore,
  FDReportsStore,
} from "../reports/store/FdReportsStore";
import {
  createFDViewCashflowsStore,
  FDViewCashflowsStore,
} from "../deal-request/store/FDViewCashflowsStore";

export const FDStore = types.model("FDStore", {
  createFDDealRequestStore: CreateFDDealRequestStore,
  dealRequestsStore: FDDealRequestsStore,
  dealRequestDetailsStore: FDDealRequestDetailsStore,
  fdReportsStore: FDReportsStore,
  fdViewCashflowsStore: FDViewCashflowsStore,
});

export const createFDStore = (): Instance<typeof FDStore> => {
  return FDStore.create({
    createFDDealRequestStore: castToSnapshot(createFDDealRequestStore()),
    dealRequestsStore: createFDDealRequestsStore(),
    dealRequestDetailsStore: createFDDealDetailsStore(),
    fdReportsStore: createFdReportsStore(),
    fdViewCashflowsStore: createFDViewCashflowsStore(),
  });
};
