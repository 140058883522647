import { Instance, types } from "mobx-state-tree";
import { SummaryDetailCell } from "@khazana/khazana-rpcs";
import { DetailType } from "./DetailCellModel";

export const SummaryDetailModel = types.model("SummaryDetailModel", {
  isShownInCollapsedView: types.maybeNull(types.boolean),
  isBold: types.maybeNull(types.boolean),
  summary: DetailType,
  detailList: types.array(DetailType),
});

export const createSummaryDetailModel = ({
  isShownInCollapsedView,
  isBold,
  summary,
  detailList,
}: SummaryDetailCell): Instance<typeof SummaryDetailModel> => {
  return SummaryDetailModel.create({
    isShownInCollapsedView,
    isBold,
    summary,
    detailList: detailList?.map((detail) => {
      return DetailType.create(detail);
    }),
  });
};
