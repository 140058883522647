import { Instance, castToSnapshot, types } from "mobx-state-tree";
import {
  FdInvestmentRegisterStore,
  createFdInvestmentRegisterStore,
} from "./FdInvestmentRegisterStore";
import {
  FdHoldingSummaryStore,
  createFdHoldingSummaryStore,
} from "./FdHoldingSummaryStore";

export const FDReportsStore = types.model("FiReportsStore", {
  fdInvestmentRegisterStore: FdInvestmentRegisterStore,
  fdHoldingSummaryStore: FdHoldingSummaryStore,
});

export const createFdReportsStore = (): Instance<typeof FDReportsStore> => {
  return FDReportsStore.create({
    fdInvestmentRegisterStore: castToSnapshot(
      createFdInvestmentRegisterStore(),
    ),
    fdHoldingSummaryStore: castToSnapshot(createFdHoldingSummaryStore()),
  });
};
