"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMFDealRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const bigAmount_1 = require("../types/bigAmount");
const bigDecimal_1 = require("../types/bigDecimal");
const note_1 = require("../types/note");
const mFTransactionType_1 = require("./mFTransactionType");
const mFRedeemType_1 = require("./mFRedeemType");
/* eslint-disable import/export */
class CreateMFDealRequestRPC {
}
exports.CreateMFDealRequestRPC = CreateMFDealRequestRPC;
(function (CreateMFDealRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateMFDealRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(schemeId, transactionType, redeemType = null, grossAmount = null, units = null, approxNav, entityId, portfolioId, folioNumber = null, amcId, bankId, accountNumber, note = null) {
            this.isCreateMFDealRequestRPCRequest = true;
            Request.validateSchemeId(schemeId);
            this.schemeId = schemeId;
            Request.validateTransactionType(transactionType);
            this.transactionType = transactionType;
            if (redeemType !== undefined && redeemType !== null) {
                Request.validateRedeemType(redeemType);
            }
            this.redeemType = redeemType;
            if (grossAmount !== undefined && grossAmount !== null) {
                Request.validateGrossAmount(grossAmount);
            }
            this.grossAmount = grossAmount;
            if (units !== undefined && units !== null) {
                Request.validateUnits(units);
            }
            this.units = units;
            Request.validateApproxNav(approxNav);
            this.approxNav = approxNav;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
            if (folioNumber !== undefined && folioNumber !== null) {
                Request.validateFolioNumber(folioNumber);
            }
            this.folioNumber = folioNumber;
            Request.validateAmcId(amcId);
            this.amcId = amcId;
            Request.validateBankId(bankId);
            this.bankId = bankId;
            Request.validateAccountNumber(accountNumber);
            this.accountNumber = accountNumber;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const schemeId = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "schemeId", InvalidRequestError));
            const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidRequestError));
            let redeemType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "redeemType");
            if (redeemType !== undefined && redeemType !== null) {
                redeemType = mFRedeemType_1.MFRedeemType.fromDTO(redeemType);
            }
            let grossAmount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "grossAmount");
            if (grossAmount !== undefined && grossAmount !== null) {
                grossAmount = bigAmount_1.BigAmount.fromDTO(grossAmount);
            }
            let units = (0, leo_ts_runtime_1.getOptionalObject)(dto, "units");
            if (units !== undefined && units !== null) {
                units = bigDecimal_1.BigDecimal.fromDTO(units);
            }
            const approxNav = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "approxNav", InvalidRequestError));
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const folioNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "folioNumber", InvalidRequestError);
            const amcId = (0, leo_ts_runtime_1.getInt64)(dto, "amcId", InvalidRequestError);
            const bankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "bankId", InvalidRequestError);
            const accountNumber = (0, leo_ts_runtime_1.getString)(dto, "accountNumber", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(schemeId, transactionType, redeemType, grossAmount, units, approxNav, entityId, portfolioId, folioNumber, amcId, bankId, accountNumber, note);
        }
        toDTO() {
            const ret = {
                schemeId: this.schemeId.toDTO(),
                transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
                approxNav: this.approxNav.toDTO(),
                entityId: this.entityId.toDTO(),
                portfolioId: this.portfolioId.toDTO(),
                amcId: this.amcId,
                bankId: this.bankId.toDTO(),
                accountNumber: this.accountNumber,
            };
            if (this.redeemType !== undefined && this.redeemType !== null) {
                ret.redeemType = mFRedeemType_1.MFRedeemType.toDTO(this.redeemType);
            }
            if (this.grossAmount) {
                ret.grossAmount = this.grossAmount.toDTO();
            }
            if (this.units) {
                ret.units = this.units.toDTO();
            }
            if (this.folioNumber !== undefined && this.folioNumber !== null) {
                ret.folioNumber = this.folioNumber;
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(schemeId = this.schemeId, transactionType = this.transactionType, redeemType = this.redeemType, grossAmount = this.grossAmount, units = this.units, approxNav = this.approxNav, entityId = this.entityId, portfolioId = this.portfolioId, folioNumber = this.folioNumber, amcId = this.amcId, bankId = this.bankId, accountNumber = this.accountNumber, note = this.note) {
            return new Request(schemeId, transactionType, redeemType, grossAmount, units, approxNav, entityId, portfolioId, folioNumber, amcId, bankId, accountNumber, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateMFDealRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.schemeId.equals(other.schemeId)) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.redeemType !== other.redeemType) {
                return false;
            }
            if (this.grossAmount) {
                if (!this.grossAmount.equals(other.grossAmount)) {
                    return false;
                }
            }
            if (this.units) {
                if (!this.units.equals(other.units)) {
                    return false;
                }
            }
            if (!this.approxNav.equals(other.approxNav)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            if (this.folioNumber !== other.folioNumber) {
                return false;
            }
            if (this.amcId !== other.amcId) {
                return false;
            }
            if (!this.bankId.equals(other.bankId)) {
                return false;
            }
            if (this.accountNumber !== other.accountNumber) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateSchemeId = (schemeId) => {
        const isISINValue = schemeId.isISIN;
        if (!(schemeId instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute schemeId is not a ISIN`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
        }
    };
    Request.validateRedeemType = (redeemType) => {
        if (!mFRedeemType_1.MFRedeemType.isInstanceOf(redeemType)) {
            throw new InvalidRequestError(`Attribute redeemType is not a MFRedeemType.MFRedeemType`);
        }
    };
    Request.validateGrossAmount = (grossAmount) => {
        const isBigAmountValue = grossAmount.isBigAmount;
        if (!(grossAmount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidRequestError(`Attribute grossAmount is not a BigAmount`);
        }
    };
    Request.validateUnits = (units) => {
        const isBigDecimalValue = units.isBigDecimal;
        if (!(units instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidRequestError(`Attribute units is not a BigDecimal`);
        }
    };
    Request.validateApproxNav = (approxNav) => {
        const isBigAmountValue = approxNav.isBigAmount;
        if (!(approxNav instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidRequestError(`Attribute approxNav is not a BigAmount`);
        }
    };
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateFolioNumber = (folioNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(folioNumber)) {
            throw new InvalidRequestError(`Attribute folioNumber is not a String`);
        }
        if (folioNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute folioNumber cannot be an empty string.`);
        }
    };
    Request.validateAmcId = (amcId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(amcId)) {
            throw new InvalidRequestError(`Attribute amcId is not an Int64`);
        }
    };
    Request.validateBankId = (bankId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(bankId)) {
            throw new InvalidRequestError(`Attribute bankId is not a UUID`);
        }
    };
    Request.validateAccountNumber = (accountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(accountNumber)) {
            throw new InvalidRequestError(`Attribute accountNumber is not a String`);
        }
        if (accountNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute accountNumber cannot be an empty string.`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CreateMFDealRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateMFDealRequestRPC.Request(schemeId=${this.schemeId},transactionType=${this.transactionType},redeemType=${this.redeemType},grossAmount=${this.grossAmount},units=${this.units},approxNav=${this.approxNav},entityId=${this.entityId},portfolioId=${this.portfolioId},folioNumber='${this.folioNumber}',amcId=${this.amcId},bankId=${this.bankId},accountNumber='${this.accountNumber}',note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateMFDealRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(dealRequestId) {
            this.isCreateMFDealRequestRPCResponse = true;
            Response.validateDealRequestId(dealRequestId);
            this.dealRequestId = dealRequestId;
        }
        static fromDTO(dto) {
            const dealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "dealRequestId", InvalidResponseError);
            return new Response(dealRequestId);
        }
        toDTO() {
            const ret = {
                dealRequestId: this.dealRequestId,
            };
            return ret;
        }
        copy(dealRequestId = this.dealRequestId) {
            return new Response(dealRequestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isCreateMFDealRequestRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.dealRequestId !== other.dealRequestId) {
                return false;
            }
            return true;
        }
    }
    Response.validateDealRequestId = (dealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(dealRequestId)) {
            throw new InvalidResponseError(`Attribute dealRequestId is not an Int64`);
        }
    };
    CreateMFDealRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `CreateMFDealRequestRPC.Response(dealRequestId=${this.dealRequestId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INVALID_PORTFOLIO_ID()`;
        };
        class InvalidAmcId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_AMC_ID") {
                super(code);
                this.isInvalidAmcId = true;
                InvalidAmcId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidAmcId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidAmcId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidAmcIdValue = other.isInvalidAmcId;
                if (!(other instanceof InvalidAmcId || Boolean(isInvalidAmcIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidAmcId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_AMC_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidAmcId = InvalidAmcId;
        InvalidAmcId.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INVALID_AMC_ID()`;
        };
        class InvalidBankId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BANK_ID") {
                super(code);
                this.isInvalidBankId = true;
                InvalidBankId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBankId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBankId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBankIdValue = other.isInvalidBankId;
                if (!(other instanceof InvalidBankId || Boolean(isInvalidBankIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBankId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BANK_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBankId = InvalidBankId;
        InvalidBankId.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INVALID_BANK_ID()`;
        };
        class InvalidDealValue extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_VALUE") {
                super(code);
                this.isInvalidDealValue = true;
                InvalidDealValue.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealValue(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealValue(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealValueValue = other.isInvalidDealValue;
                if (!(other instanceof InvalidDealValue || Boolean(isInvalidDealValueValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealValue.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_VALUE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealValue = InvalidDealValue;
        InvalidDealValue.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INVALID_DEAL_VALUE()`;
        };
        class MissingFolioNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "MISSING_FOLIO_NUMBER") {
                super(code);
                this.isMissingFolioNumber = true;
                MissingFolioNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new MissingFolioNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new MissingFolioNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isMissingFolioNumberValue = other.isMissingFolioNumber;
                if (!(other instanceof MissingFolioNumber || Boolean(isMissingFolioNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        MissingFolioNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "MISSING_FOLIO_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.MissingFolioNumber = MissingFolioNumber;
        MissingFolioNumber.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.MISSING_FOLIO_NUMBER()`;
        };
        class InvalidAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidAccountNumber = true;
                InvalidAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidAccountNumberValue = other.isInvalidAccountNumber;
                if (!(other instanceof InvalidAccountNumber || Boolean(isInvalidAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidAccountNumber = InvalidAccountNumber;
        InvalidAccountNumber.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INVALID_ACCOUNT_NUMBER()`;
        };
        class DealValueExceeded extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DEAL_VALUE_EXCEEDED") {
                super(code);
                this.isDealValueExceeded = true;
                DealValueExceeded.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new DealValueExceeded(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new DealValueExceeded(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDealValueExceededValue = other.isDealValueExceeded;
                if (!(other instanceof DealValueExceeded || Boolean(isDealValueExceededValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        DealValueExceeded.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DEAL_VALUE_EXCEEDED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.DealValueExceeded = DealValueExceeded;
        DealValueExceeded.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.DEAL_VALUE_EXCEEDED()`;
        };
        class InsufficientBankBalance extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INSUFFICIENT_BANK_BALANCE") {
                super(code);
                this.isInsufficientBankBalance = true;
                InsufficientBankBalance.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InsufficientBankBalance(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InsufficientBankBalance(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInsufficientBankBalanceValue = other.isInsufficientBankBalance;
                if (!(other instanceof InsufficientBankBalance || Boolean(isInsufficientBankBalanceValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InsufficientBankBalance.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INSUFFICIENT_BANK_BALANCE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InsufficientBankBalance = InsufficientBankBalance;
        InsufficientBankBalance.prototype.toString = function toString() {
            return `CreateMFDealRequestRPC.INSUFFICIENT_BANK_BALANCE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "INVALID_AMC_ID":
                    return InvalidAmcId.fromDTO(dto);
                case "INVALID_BANK_ID":
                    return InvalidBankId.fromDTO(dto);
                case "INVALID_DEAL_VALUE":
                    return InvalidDealValue.fromDTO(dto);
                case "MISSING_FOLIO_NUMBER":
                    return MissingFolioNumber.fromDTO(dto);
                case "INVALID_ACCOUNT_NUMBER":
                    return InvalidAccountNumber.fromDTO(dto);
                case "DEAL_VALUE_EXCEEDED":
                    return DealValueExceeded.fromDTO(dto);
                case "INSUFFICIENT_BANK_BALANCE":
                    return InsufficientBankBalance.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isInvalidAmcIdValue = other.isInvalidAmcId;
            if (other instanceof InvalidAmcId || Boolean(isInvalidAmcIdValue)) {
                return true;
            }
            const isInvalidBankIdValue = other.isInvalidBankId;
            if (other instanceof InvalidBankId || Boolean(isInvalidBankIdValue)) {
                return true;
            }
            const isInvalidDealValueValue = other.isInvalidDealValue;
            if (other instanceof InvalidDealValue || Boolean(isInvalidDealValueValue)) {
                return true;
            }
            const isMissingFolioNumberValue = other.isMissingFolioNumber;
            if (other instanceof MissingFolioNumber || Boolean(isMissingFolioNumberValue)) {
                return true;
            }
            const isInvalidAccountNumberValue = other.isInvalidAccountNumber;
            if (other instanceof InvalidAccountNumber || Boolean(isInvalidAccountNumberValue)) {
                return true;
            }
            const isDealValueExceededValue = other.isDealValueExceeded;
            if (other instanceof DealValueExceeded || Boolean(isDealValueExceededValue)) {
                return true;
            }
            const isInsufficientBankBalanceValue = other.isInsufficientBankBalance;
            if (other instanceof InsufficientBankBalance || Boolean(isInsufficientBankBalanceValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CreateMFDealRequestRPC.Errors || (CreateMFDealRequestRPC.Errors = {}));
})(CreateMFDealRequestRPC = exports.CreateMFDealRequestRPC || (exports.CreateMFDealRequestRPC = {}));
