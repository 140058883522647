import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType } from "@khazana/khazana-rpcs";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import { observer } from "mobx-react";
import { useEQChangeInGainStore } from "../store/hooks";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";
import {
  DateRangePickerInput,
  LoadingIndicator,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ReportTableData,
  ReportTableHeaderProps,
  ReportTableHeaderWidth,
} from "../../../components/reports/ReportTable";
import { useUserStore } from "../../../store/hooks";
import { EquityUserPrivileges } from "../../../../user/UserPrivileges";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { Period, getDateOrNull } from "../../../utils/ReportUtils";
import { DashboardLineGraph } from "../../../components/dashboard/DashboardLineGraph";
import { DataSeries } from "../../../components/charts/LineChart";
import {
  DashboardStats,
  DashboardStatsItem,
} from "../../../components/dashboard/DashboardStats";
import { DashboardStatModel } from "../../../models/DashboardStatModel";
import { Instance } from "mobx-state-tree";

export const EqChangeInGain = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useEQChangeInGainStore();
  const privileges = useUserStore().privileges;
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  const getChangeInGainDetails = useCallback(async () => {
    await store.getEQChangeInGain(0, store.itemsPerPage(), undefined);
    await store.getGrandTotalChangeInGain();
    await store.benchmarkIndex.getBenchmarkIndexList();
    await store.getEquityBenchmarkReturnsGraph();
  }, []);

  useEffect(() => {
    getChangeInGainDetails();
    return () => {
      store.reset();
    };
  }, []);

  const headers: ReportTableHeaderProps[] = [
    {
      name: t("common.symbol"),
      width: ReportTableHeaderWidth.TableWidthMD,
      sortable: true,
    },
    {
      name: t("equity.reports.changeInGainHeaders.salesProceeds"),
      alignment: "right",
      sortable: true,
    },
    {
      name: t("equity.reports.changeInGainHeaders.totalRg"),
      alignment: "right",
      sortable: true,
    },
    {
      name: t("equity.reports.changeInGainHeaders.change"),
      alignment: "right",
      sortable: true,
    },
    {
      name: t("equity.reports.changeInGainHeaders.netGain"),
      alignment: "right",
      sortable: true,
    },
    {
      name: t("equity.reports.changeInGainHeaders.irr"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.rgOldShares"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.rgNewShares"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.ugOldShares"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.ugNewShares"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.totalUg"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.mvOldShares"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.costNewShares"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.costSoldShares"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.costOnEndDate"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.totalMVOnEndDate"),
      alignment: "right",
    },
    {
      name: t("equity.reports.changeInGainHeaders.totalDividend"),
      alignment: "right",
    },
  ];

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    await store.getEQChangeInGain(page, itemsPerPage, sort);
    return {
      rows: store.eqChangeInGainList.map((item) => {
        return [
          item.symbol,
          item.salesProceeds,
          item.totalRg,
          item.change,
          item.totalGains,
          item.irr,
          item.rgOldShares,
          item.rgNewShares,
          item.ugOldShares,
          item.ugNewShares,
          item.totalUg,
          item.mvOldShares,
          item.costNewShares,
          item.costSoldShares,
          item.costOnEndDate,
          item.totalMVOnEndDate,
          item.totalDividend,
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  const getStat = (
    label: string,
    stat: Instance<typeof DashboardStatModel>,
  ): DashboardStatsItem => {
    return {
      title: label,
      value: stat.amount,
      fullValue: stat.fullAmount,
      isPositive: stat.isGain,
      percentage: stat.percentage ? stat.percentage + "%" : undefined,
    };
  };

  const getStats = (): DashboardStatsItem[] => {
    const stats = store.eqChangeInGainSummary;
    if (stats === undefined) return [];
    return [
      getStat(
        t("equity.reports.changeInGainSummary.mvEndDate", {
          val: stats?.mvEndDate.currency?.symbol,
        }),
        stats.mvEndDate,
      ),
      getStat(
        t("equity.reports.changeInGainSummary.totalRealisedGain", {
          val: stats.totalRealisedGain.currency?.symbol,
        }),
        stats.totalRealisedGain,
      ),
      getStat(
        t("equity.reports.changeInGainSummary.totalUnrealisedGain", {
          val: stats.totalUnrealisedGain.currency?.symbol,
        }),
        stats.totalUnrealisedGain,
      ),
      getStat(
        t("equity.reports.changeInGainSummary.newShareCost", {
          val: stats.newShareCost.currency?.symbol,
        }),
        stats.newShareCost,
      ),
      getStat(t("equity.reports.changeInGainSummary.totalIrr"), stats.totalIrr),
    ];
  };

  const getDateRange = (): DateRangePickerInput => {
    return {
      startDate: getDateOrNull(store.fromDate),
      endDate: getDateOrNull(store.toDate),
    };
  };

  const getReturnVsBenchmark = (): DataSeries[] => {
    return [
      {
        data: store.returns.map((item) => {
          return {
            x: item.date,
            y: item.percentage,
          };
        }),
        name: t("dashboard.returnsLabel"),
      },
    ];
  };

  return (
    <>
      {store.isScreenLoading && (
        <LoadingIndicator isLoading={store.isScreenLoading} />
      )}
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {store.isDataFetched && (
        <ReportTablePage
          pageHeader={{
            title: t("equity.reports.changeInGain"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Equity, Route.Reports), {
                replace: true,
              });
            },
          }}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            isAllOptionEnabled: true,
            open: viewFilter,
            onClose: () => setViewFilter(false),
            onViewReport: async (period): Promise<void> => {
              tableRef.current?.reload();
              await store.getEQChangeInGain(
                0,
                store.itemsPerPage(),
                undefined,
                period,
              );
              await store.getGrandTotalChangeInGain();
              await store.getEquityBenchmarkReturnsGraph();
              return setViewFilter(false);
            },
            module: ModuleType.ModuleType.EQUITY,
            showReportType: false,
            period: {
              reportType: Period.BETWEEN_TWO_DATES,
              asOnDate: null,
              betweenTwoDatesDateRange: getDateRange(),
            },
            entityDropdownStore: store.entityDropdownStore,
            portfolioDropdownStore: store.portfolioDropdownStore,
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          isExportable={privileges.includes(
            EquityUserPrivileges.DownloadEquityChangeInGainReport,
          )}
          onExport={async (exportFormat): Promise<void> => {
            if (
              store.entityDropdownStore.selectedEntity &&
              store.portfolioDropdownStore.selectedPortfolio
            ) {
              await store.getDownloadURL(exportFormat);
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={store.eqChangeInGainList.length === 0}
          additionalSection={
            store.eqChangeInGainList.length > 0 ? (
              <>
                <DashboardStats key={"equityStats"} stats={getStats()} />
                <DashboardLineGraph
                  key={"equityReturnVsBenchmark"}
                  title={t("dashboard.returnVsBenchmarkTitle")}
                  series={getReturnVsBenchmark()}
                  isLoading={store.isBenchmarkLoading}
                />
              </>
            ) : (
              <></>
            )
          }
        />
      )}
    </>
  );
});
