"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomePurchaseStatus = exports.InvalidFixedIncomePurchaseStatusError = void 0;
class InvalidFixedIncomePurchaseStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomePurchaseStatusError";
    }
}
exports.InvalidFixedIncomePurchaseStatusError = InvalidFixedIncomePurchaseStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedIncomePurchaseStatus;
(function (FixedIncomePurchaseStatus_1) {
    let FixedIncomePurchaseStatus;
    (function (FixedIncomePurchaseStatus) {
        FixedIncomePurchaseStatus["INVESTMENT_OPEN"] = "INVESTMENT_OPEN";
        FixedIncomePurchaseStatus["MATURED"] = "MATURED";
        FixedIncomePurchaseStatus["PARTIALLY_SOLD"] = "PARTIALLY_SOLD";
        FixedIncomePurchaseStatus["SETTLED"] = "SETTLED";
    })(FixedIncomePurchaseStatus = FixedIncomePurchaseStatus_1.FixedIncomePurchaseStatus || (FixedIncomePurchaseStatus_1.FixedIncomePurchaseStatus = {}));
    FixedIncomePurchaseStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "INVESTMENT_OPEN":
                return FixedIncomePurchaseStatus.INVESTMENT_OPEN;
            case "MATURED":
                return FixedIncomePurchaseStatus.MATURED;
            case "PARTIALLY_SOLD":
                return FixedIncomePurchaseStatus.PARTIALLY_SOLD;
            case "SETTLED":
                return FixedIncomePurchaseStatus.SETTLED;
            default:
                throw new InvalidFixedIncomePurchaseStatusError(`Case ${dto.case} is not valid case of FixedIncomePurchaseStatus`);
        }
    };
    FixedIncomePurchaseStatus_1.toDTO = (fixedIncomePurchaseStatus) => {
        const ret = {
            case: FixedIncomePurchaseStatus[fixedIncomePurchaseStatus],
        };
        return ret;
    };
    FixedIncomePurchaseStatus_1.isInstanceOf = (other) => {
        if (other in FixedIncomePurchaseStatus) {
            return true;
        }
        return false;
    };
})(FixedIncomePurchaseStatus = exports.FixedIncomePurchaseStatus || (exports.FixedIncomePurchaseStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
