import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-material-ui";

export interface BorderStyle {
  default: string;
  error: string;
  errorSubtle: string;
  infoSubtle: string;
  lowEmphasis: string;
  tabIndicator: string;
  button: string;
}

export const useBorder = (): BorderStyle => {
  const tokens = useFoundationColorTokens();
  return {
    default: `1px solid ${tokens.border}`,
    error: `2px solid ${tokens.borderError}`,
    errorSubtle: `1px solid ${tokens.borderErrorSubtle}`,
    infoSubtle: `1px solid ${tokens.borderInfoSubtle}`,
    lowEmphasis: `1px solid ${tokens.border}`,
    tabIndicator: `2px solid ${tokens.borderPrimary}`,
    button: `1px solid ${tokens.borderPrimary}`,
  };
};
