import {
  applySnapshot,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";

export enum ConfirmationRequestDetailsPageErrors {
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  InvalidRequestId = "INVALID_REQUEST_ID",
}

export const ConfirmationRequestDetailsErrorStore = types
  .model("ConfirmationRequestDetailsErrorStore", {
    error: types.maybe(
      types.enumeration(Object.values(ConfirmationRequestDetailsPageErrors)),
    ),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setError: (error: string | undefined): void => {
        if (error) {
          const logger = getEnv(store).logger;
          switch (error) {
            case ConfirmationRequestDetailsPageErrors.InvalidRequestId:
              store.error =
                ConfirmationRequestDetailsPageErrors.InvalidRequestId;
              break;
            case ConfirmationRequestDetailsPageErrors.UNKNOWN_ERROR:
              store.error = ConfirmationRequestDetailsPageErrors.UNKNOWN_ERROR;
              break;
            default:
              store.error = ConfirmationRequestDetailsPageErrors.UNKNOWN_ERROR;
              logger.error(`Unhandled error: ${error}`);
          }
        } else {
          store.error = undefined;
        }
      },
    };
  });

export const createConfirmationRequestDetailsErrorStore = (): Instance<
  typeof ConfirmationRequestDetailsErrorStore
> => {
  return ConfirmationRequestDetailsErrorStore.create({});
};
