import {
  DetailCell,
  DetailCellType,
  FDDealRequestAction,
  FDDealRequestStatus,
  GetFDDealRequestDetailsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoTimestamp } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFDDealRequestDetailsRPCImpl extends GetFDDealRequestDetailsRPC {
  execute(
    _request: GetFDDealRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFDDealRequestDetailsRPC.Response,
      GetFDDealRequestDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetFDDealRequestDetailsRPC.Response(
      new FDDealRequestAction(true, true, false, false, false, true),
      [
        new DetailCell(
          "fd.fields.requestStatusLabel",
          new DetailCellType.StatusType(
            FDDealRequestStatus.FDDealRequestStatus.ACCOUNTS_PENDING,
          ),
        ),
        new DetailCell(
          "fd.fields.createdAtLabel",
          new DetailCellType.Time(new LeoTimestamp()),
        ),
        new DetailCell(
          "fd.fields.requestIdLabel",
          new DetailCellType.Unstyled("1", false),
        ),
      ],
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFDDealRequestDetailsRPC.Response,
        GetFDDealRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
