import {
  Currency,
  FDWithdrawListItem,
  FDWithdrawStatus,
  GetFdWithdrawListRPC,
  TransactionAmount,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFdWithdrawListRPCImpl extends GetFdWithdrawListRPC {
  execute(
    _request: GetFdWithdrawListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFdWithdrawListRPC.Response,
      GetFdWithdrawListRPC.Errors.Errors
    >
  > {
    const response = new GetFdWithdrawListRPC.Response([
      new FDWithdrawListItem(
        100,
        new TransactionAmount(3000000000000, new Currency("INR", "₹")),
        new TransactionAmount(300000000, new Currency("INR", "₹")),
        34,
        null,
        FDWithdrawStatus.FDWithdrawStatus.WITHDRAW_PENDING,
        null,
        new LeoDate(),
      ),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFdWithdrawListRPC.Response,
        GetFdWithdrawListRPC.Errors.Errors
      >
    >;
  }
}
