import React from "react";
import { ListItemButton, styled, Typography } from "@mui/material";
import {
  SubListItemComponentProps,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-core";

export const SubListItem = ({
  subListItem,
  onSelect,
  currentPath,
}: SubListItemComponentProps): React.ReactElement => {
  const typography = useTypography();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();

  const StyledListItemButton = styled(ListItemButton)(() => ({
    width: "100%",
    height: "40px",
    borderRadius: cornerRadius.radiusXS,
    flexGrow: 0,
    flex: "none",
    padding: `${spacing.spaceSM} ${spacing.spaceSM} ${spacing.spaceSM} 48px`,
    marginTop: spacing.spaceXS,
  }));
  const StyledTypography = styled(Typography)(() => ({
    ...(currentPath.includes(subListItem.path) ? typography.s1 : typography.b1),
  }));

  const handleClick = (): void => {
    onSelect(subListItem.path);
  };

  return (
    <StyledListItemButton
      disabled={subListItem.isDisabled}
      selected={currentPath.includes(subListItem.path)}
      onClick={handleClick}
      sx={{
        color: tokens.label,
        ":hover": {
          backgroundColor: tokens.backgroundHovered,
          color: tokens.label,
        },
        "&.Mui-disabled": {
          color: tokens.labelLowEmphasis,
        },
        "&.Mui-selected": {
          backgroundColor: tokens.backgroundSelected,
          color: tokens.labelPrimary,
          ":hover": {
            backgroundColor: tokens.backgroundSelected,
            color: tokens.labelPrimary,
          },
        },
      }}
    >
      <StyledTypography>{subListItem.label}</StyledTypography>
    </StyledListItemButton>
  );
};
