"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFDealRequestImpactRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const bigAmount_1 = require("../types/bigAmount");
const bigDecimal_1 = require("../types/bigDecimal");
const mFImpactTable_1 = require("./mFImpactTable");
const mFTransactionType_1 = require("./mFTransactionType");
/* eslint-disable import/export */
class GetMFDealRequestImpactRPC {
}
exports.GetMFDealRequestImpactRPC = GetMFDealRequestImpactRPC;
(function (GetMFDealRequestImpactRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFDealRequestImpactRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(isin, entityId, portfolioId, transactionType, grossAmount = null, units = null, approxNav) {
            this.isGetMFDealRequestImpactRPCRequest = true;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validatePortfolioId(portfolioId);
            this.portfolioId = portfolioId;
            Request.validateTransactionType(transactionType);
            this.transactionType = transactionType;
            if (grossAmount !== undefined && grossAmount !== null) {
                Request.validateGrossAmount(grossAmount);
            }
            this.grossAmount = grossAmount;
            if (units !== undefined && units !== null) {
                Request.validateUnits(units);
            }
            this.units = units;
            Request.validateApproxNav(approxNav);
            this.approxNav = approxNav;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidRequestError));
            let grossAmount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "grossAmount");
            if (grossAmount !== undefined && grossAmount !== null) {
                grossAmount = bigAmount_1.BigAmount.fromDTO(grossAmount);
            }
            let units = (0, leo_ts_runtime_1.getOptionalObject)(dto, "units");
            if (units !== undefined && units !== null) {
                units = bigDecimal_1.BigDecimal.fromDTO(units);
            }
            const approxNav = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "approxNav", InvalidRequestError));
            return new Request(isin, entityId, portfolioId, transactionType, grossAmount, units, approxNav);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
                entityId: this.entityId.toDTO(),
                portfolioId: this.portfolioId.toDTO(),
                transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
                approxNav: this.approxNav.toDTO(),
            };
            if (this.grossAmount) {
                ret.grossAmount = this.grossAmount.toDTO();
            }
            if (this.units) {
                ret.units = this.units.toDTO();
            }
            return ret;
        }
        copy(isin = this.isin, entityId = this.entityId, portfolioId = this.portfolioId, transactionType = this.transactionType, grossAmount = this.grossAmount, units = this.units, approxNav = this.approxNav) {
            return new Request(isin, entityId, portfolioId, transactionType, grossAmount, units, approxNav);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFDealRequestImpactRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (!this.portfolioId.equals(other.portfolioId)) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.grossAmount) {
                if (!this.grossAmount.equals(other.grossAmount)) {
                    return false;
                }
            }
            if (this.units) {
                if (!this.units.equals(other.units)) {
                    return false;
                }
            }
            if (!this.approxNav.equals(other.approxNav)) {
                return false;
            }
            return true;
        }
    }
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
        }
    };
    Request.validateGrossAmount = (grossAmount) => {
        const isBigAmountValue = grossAmount.isBigAmount;
        if (!(grossAmount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidRequestError(`Attribute grossAmount is not a BigAmount`);
        }
    };
    Request.validateUnits = (units) => {
        const isBigDecimalValue = units.isBigDecimal;
        if (!(units instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidRequestError(`Attribute units is not a BigDecimal`);
        }
    };
    Request.validateApproxNav = (approxNav) => {
        const isBigAmountValue = approxNav.isBigAmount;
        if (!(approxNav instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidRequestError(`Attribute approxNav is not a BigAmount`);
        }
    };
    GetMFDealRequestImpactRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFDealRequestImpactRPC.Request(isin=${this.isin},entityId=${this.entityId},portfolioId=${this.portfolioId},transactionType=${this.transactionType},grossAmount=${this.grossAmount},units=${this.units},approxNav=${this.approxNav})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFDealRequestImpactRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(impactTable = null) {
            this.isGetMFDealRequestImpactRPCResponse = true;
            if (impactTable !== undefined && impactTable !== null) {
                Response.validateImpactTable(impactTable);
            }
            this.impactTable = impactTable;
        }
        static fromDTO(dto) {
            let impactTable = (0, leo_ts_runtime_1.getOptionalObject)(dto, "impactTable");
            if (impactTable !== undefined && impactTable !== null) {
                impactTable = mFImpactTable_1.MFImpactTable.fromDTO(impactTable);
            }
            return new Response(impactTable);
        }
        toDTO() {
            const ret = {};
            if (this.impactTable) {
                ret.impactTable = this.impactTable.toDTO();
            }
            return ret;
        }
        copy(impactTable = this.impactTable) {
            return new Response(impactTable);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFDealRequestImpactRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.impactTable) {
                if (!this.impactTable.equals(other.impactTable)) {
                    return false;
                }
            }
            return true;
        }
    }
    Response.validateImpactTable = (impactTable) => {
        const isMFImpactTableValue = impactTable.isMFImpactTable;
        if (!(impactTable instanceof mFImpactTable_1.MFImpactTable || Boolean(isMFImpactTableValue))) {
            throw new InvalidResponseError(`Attribute impactTable is not a MFImpactTable`);
        }
    };
    GetMFDealRequestImpactRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFDealRequestImpactRPC.Response(impactTable=${this.impactTable})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetMFDealRequestImpactRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetMFDealRequestImpactRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `GetMFDealRequestImpactRPC.INVALID_PORTFOLIO_ID()`;
        };
        class CurrencyMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CURRENCY_MISMATCH") {
                super(code);
                this.isCurrencyMismatch = true;
                CurrencyMismatch.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CurrencyMismatch(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CurrencyMismatch(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCurrencyMismatchValue = other.isCurrencyMismatch;
                if (!(other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CurrencyMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CURRENCY_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CurrencyMismatch = CurrencyMismatch;
        CurrencyMismatch.prototype.toString = function toString() {
            return `GetMFDealRequestImpactRPC.CURRENCY_MISMATCH()`;
        };
        class InvalidDealValue extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_VALUE") {
                super(code);
                this.isInvalidDealValue = true;
                InvalidDealValue.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealValue(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealValue(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealValueValue = other.isInvalidDealValue;
                if (!(other instanceof InvalidDealValue || Boolean(isInvalidDealValueValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealValue.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_VALUE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealValue = InvalidDealValue;
        InvalidDealValue.prototype.toString = function toString() {
            return `GetMFDealRequestImpactRPC.INVALID_DEAL_VALUE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "CURRENCY_MISMATCH":
                    return CurrencyMismatch.fromDTO(dto);
                case "INVALID_DEAL_VALUE":
                    return InvalidDealValue.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isCurrencyMismatchValue = other.isCurrencyMismatch;
            if (other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue)) {
                return true;
            }
            const isInvalidDealValueValue = other.isInvalidDealValue;
            if (other instanceof InvalidDealValue || Boolean(isInvalidDealValueValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFDealRequestImpactRPC.Errors || (GetMFDealRequestImpactRPC.Errors = {}));
})(GetMFDealRequestImpactRPC = exports.GetMFDealRequestImpactRPC || (exports.GetMFDealRequestImpactRPC = {}));
