import { observer } from "mobx-react";
import React, { useEffect } from "react";
import {
  PageHeader,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Box } from "@mui/material";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { ContractNoteRequestsSearchFilter } from "../components/ContractNoteRequestsSearchFilter";
import { useMFManageContractNoteDetailsStore } from "../store/ManageConfirmationRequestsStore/hooks";
import { MFConfirmationFilterOptionsModel } from "../store/ManageConfirmationRequestsStore/ManageConfirmationDetailsStore";
import { getFormattedTimeDateWithComma } from "../../../../../utils";
import { getMFConfirmationRequestStatusValue } from "../utils/UIUtils";
import { useUserStore } from "../../../store/hooks";
import { MFUserPrivileges } from "../../../../user/UserPrivileges";
import { HeaderButton } from "../../../components/page-header/HeaderButton";

const Size = {
  table: {
    default: "max-content",
    status: "250px",
    scheme: "250px",
    timestamp: "220px",
  },
};

export const ManageConfirmationRequests = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const privileges = useUserStore().privileges;
  const store = useMFManageContractNoteDetailsStore();

  useEffect(() => {
    store.getBrokerList();
  }, []);

  const getDealRequests = async (
    option: TableOptions<Instance<typeof MFConfirmationFilterOptionsModel>>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    if (option.filter) store.updateFilterOptions(option.filter);
    await store.getMFConfirmationRequests(
      option.page ? option.page - 1 : 0,
      store.itemsPerPage(),
      option.sort?.order,
    );
    setTotalItems(store.totalItems);
    return store.requests.map((request) => {
      return [
        {
          data: getMFConfirmationRequestStatusValue(
            request.status,
          ).toLocaleUpperCase(),
        },
        {
          data: request.brokerName ?? "-",
        },
        {
          data: request.schemeName ?? "-",
        },
        {
          data: request.referenceNumber ?? "-",
        },
        {
          data: request.requestId ?? "-",
        },
        {
          data: t("common.numberFormat", { val: request.amount }),
          align: "right",
        },
        {
          data: getFormattedTimeDateWithComma(request.receivedAt),
        },
      ];
    });
  };
  const getHeaders = (): TableHeader => {
    return [
      {
        id: "status",
        name: t("common.status"),
        width: Size.table.status,
      },
      {
        id: "broker",
        name: t("contractNotes.broker"),
        width: Size.table.default,
      },
      {
        id: "scheme",
        name: t("mf.fields.schemeLabel"),
        width: Size.table.scheme,
      },
      {
        id: "referenceNumber",
        name: t("mf.fields.referenceNumberLabel"),
        width: Size.table.default,
      },
      {
        id: "requestId",
        name: t("common.requestId"),
        width: Size.table.default,
      },
      {
        id: "amount",
        name: t("common.amountWithPostfixLabel", {
          val: store.currencySymbol,
        }),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "receivedAt",
        name: t("common.receivedAt"),
        width: Size.table.timestamp,
        sortable: true,
      },
    ];
  };

  const getConfirmationActions = (): React.ReactElement | undefined => {
    return privileges.includes(
      MFUserPrivileges.CreateMutualFundConfirmationRequest,
    ) ? (
      <HeaderButton
        label={t("mf.manageConfirmationRequests.createRequestButtonTitle")}
        onClick={(): void => {
          navigate(getPath(Module.MF, Route.CreateConfirmationRequest));
        }}
        buttonType={"outlined"}
      />
    ) : undefined;
  };

  return (
    <>
      <PageHeader
        title={t("mf.manageConfirmationRequests.pageTitle")}
        actionElement={getConfirmationActions()}
      />
      <Box margin={spacing.space2XL}>
        <Table
          name="mfDealRequestsTable"
          styleOverrides={{
            divider: "cell",
          }}
          headers={getHeaders()}
          onTableOptionsChange={getDealRequests}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            idle: { message: t("common.searchTableIdleState") },
            loading: {
              message: t("mf.manageConfirmationRequests.loadingStateMessage"),
            },
          }}
          paginationOption={{
            itemsPerPage: store.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          filterOption={{
            initialFilterValue: store.filterOptions,
            filterComponent(filter, setFilter): React.ReactElement {
              return (
                <ContractNoteRequestsSearchFilter
                  filter={filter}
                  setFilter={setFilter}
                  brokers={store.brokers}
                />
              );
            },
          }}
          onRowClick={(_, index): void => {
            const selectedRequestId = store.requests[index].requestId;
            navigate(
              getPath(
                Module.MF,
                Route.ManageConfirmationRequestsDetailsWithParams,
                selectedRequestId.toString(),
              ),
            );
          }}
        />
      </Box>
    </>
  );
});
