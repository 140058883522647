"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityHoldingDistributionByIsinRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const labelAmount_1 = require("../types/labelAmount");
/* eslint-disable import/export */
class GetEquityHoldingDistributionByIsinRPC {
}
exports.GetEquityHoldingDistributionByIsinRPC = GetEquityHoldingDistributionByIsinRPC;
(function (GetEquityHoldingDistributionByIsinRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityHoldingDistributionByIsinRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId = null, portfolioId = null, asOnDate = null) {
            this.isGetEquityHoldingDistributionByIsinRPCRequest = true;
            if (entityId !== undefined && entityId !== null) {
                Request.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
            if (asOnDate !== undefined && asOnDate !== null) {
                Request.validateAsOnDate(asOnDate);
            }
            this.asOnDate = asOnDate;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const asOnDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "asOnDate", InvalidRequestError);
            return new Request(entityId, portfolioId, asOnDate);
        }
        toDTO() {
            const ret = {};
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            if (this.asOnDate) {
                ret.asOnDate = this.asOnDate.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, asOnDate = this.asOnDate) {
            return new Request(entityId, portfolioId, asOnDate);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityHoldingDistributionByIsinRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            if (this.asOnDate) {
                if (!this.asOnDate.equals(other.asOnDate)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateAsOnDate = (asOnDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(asOnDate)) {
            throw new InvalidRequestError(`Attribute asOnDate is not a Date`);
        }
    };
    GetEquityHoldingDistributionByIsinRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityHoldingDistributionByIsinRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},asOnDate=${this.asOnDate})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityHoldingDistributionByIsinRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(holdingDistribution) {
            this.isGetEquityHoldingDistributionByIsinRPCResponse = true;
            Response.validateHoldingDistribution(holdingDistribution);
            this.holdingDistribution = holdingDistribution;
        }
        static fromDTO(dto) {
            const holdingDistribution = (0, leo_ts_runtime_1.getList)(dto, "holdingDistribution", labelAmount_1.LabelAmount.fromDTO, InvalidResponseError);
            return new Response(holdingDistribution);
        }
        toDTO() {
            const ret = {
                holdingDistribution: this.holdingDistribution.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(holdingDistribution = this.holdingDistribution) {
            return new Response(holdingDistribution);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityHoldingDistributionByIsinRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.holdingDistribution, other.holdingDistribution)) {
                return false;
            }
            return true;
        }
    }
    Response.validateHoldingDistribution = (holdingDistribution) => {
        if (!Array.isArray(holdingDistribution)) {
            throw new InvalidResponseError(`Attribute holdingDistribution is not a List`);
        }
        for (let i = 0; i < holdingDistribution.length; i += 1) {
            const isLabelAmountValue = holdingDistribution[i].isLabelAmount;
            if (!(holdingDistribution[i] instanceof labelAmount_1.LabelAmount || Boolean(isLabelAmountValue))) {
                throw new InvalidResponseError(`Attribute holdingDistribution at index ${i} is not a LabelAmount.`);
            }
        }
    };
    GetEquityHoldingDistributionByIsinRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityHoldingDistributionByIsinRPC.Response(holdingDistribution=${this.holdingDistribution})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DATE") {
                super(code);
                this.isInvalidDate = true;
                InvalidDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDateValue = other.isInvalidDate;
                if (!(other instanceof InvalidDate || Boolean(isInvalidDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDate = InvalidDate;
        InvalidDate.prototype.toString = function toString() {
            return `GetEquityHoldingDistributionByIsinRPC.INVALID_DATE()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetEquityHoldingDistributionByIsinRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `GetEquityHoldingDistributionByIsinRPC.INVALID_PORTFOLIO_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_DATE":
                    return InvalidDate.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidDateValue = other.isInvalidDate;
            if (other instanceof InvalidDate || Boolean(isInvalidDateValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityHoldingDistributionByIsinRPC.Errors || (GetEquityHoldingDistributionByIsinRPC.Errors = {}));
})(GetEquityHoldingDistributionByIsinRPC = exports.GetEquityHoldingDistributionByIsinRPC || (exports.GetEquityHoldingDistributionByIsinRPC = {}));
