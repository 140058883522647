"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiMaturityDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
const fiMaturityDealRequestStatus_1 = require("./fiMaturityDealRequestStatus");
class InvalidGetFiMaturityDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiMaturityDealRequestsPaginationResponseError";
    }
}
class GetFiMaturityDealRequestsPaginationResponse {
    constructor(requestId, security, dealRequestStatus, isin, entity, portfolio, issueDate, valueDate, maturedDate, faceValue, purchasePrice, maturedUnits, settlementAmount, currency) {
        this.isGetFiMaturityDealRequestsPaginationResponse = true;
        GetFiMaturityDealRequestsPaginationResponse.validateRequestId(requestId);
        this.requestId = requestId;
        GetFiMaturityDealRequestsPaginationResponse.validateSecurity(security);
        this.security = security;
        GetFiMaturityDealRequestsPaginationResponse.validateDealRequestStatus(dealRequestStatus);
        this.dealRequestStatus = dealRequestStatus;
        GetFiMaturityDealRequestsPaginationResponse.validateIsin(isin);
        this.isin = isin;
        GetFiMaturityDealRequestsPaginationResponse.validateEntity(entity);
        this.entity = entity;
        GetFiMaturityDealRequestsPaginationResponse.validatePortfolio(portfolio);
        this.portfolio = portfolio;
        GetFiMaturityDealRequestsPaginationResponse.validateIssueDate(issueDate);
        this.issueDate = issueDate;
        GetFiMaturityDealRequestsPaginationResponse.validateValueDate(valueDate);
        this.valueDate = valueDate;
        GetFiMaturityDealRequestsPaginationResponse.validateMaturedDate(maturedDate);
        this.maturedDate = maturedDate;
        GetFiMaturityDealRequestsPaginationResponse.validateFaceValue(faceValue);
        this.faceValue = faceValue;
        GetFiMaturityDealRequestsPaginationResponse.validatePurchasePrice(purchasePrice);
        this.purchasePrice = purchasePrice;
        GetFiMaturityDealRequestsPaginationResponse.validateMaturedUnits(maturedUnits);
        this.maturedUnits = maturedUnits;
        GetFiMaturityDealRequestsPaginationResponse.validateSettlementAmount(settlementAmount);
        this.settlementAmount = settlementAmount;
        GetFiMaturityDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const security = (0, leo_ts_runtime_1.getString)(dto, "security", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const dealRequestStatus = fiMaturityDealRequestStatus_1.FiMaturityDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealRequestStatus", InvalidGetFiMaturityDealRequestsPaginationResponseError));
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidGetFiMaturityDealRequestsPaginationResponseError));
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const portfolio = (0, leo_ts_runtime_1.getString)(dto, "portfolio", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const issueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "issueDate", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const valueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "valueDate", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const maturedDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "maturedDate", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const faceValue = (0, leo_ts_runtime_1.getString)(dto, "faceValue", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const purchasePrice = (0, leo_ts_runtime_1.getString)(dto, "purchasePrice", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const maturedUnits = (0, leo_ts_runtime_1.getString)(dto, "maturedUnits", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const settlementAmount = (0, leo_ts_runtime_1.getString)(dto, "settlementAmount", InvalidGetFiMaturityDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetFiMaturityDealRequestsPaginationResponseError));
        return new GetFiMaturityDealRequestsPaginationResponse(requestId, security, dealRequestStatus, isin, entity, portfolio, issueDate, valueDate, maturedDate, faceValue, purchasePrice, maturedUnits, settlementAmount, currency);
    }
    toDTO() {
        const ret = {
            requestId: this.requestId,
            security: this.security,
            dealRequestStatus: fiMaturityDealRequestStatus_1.FiMaturityDealRequestStatus.toDTO(this.dealRequestStatus),
            isin: this.isin.toDTO(),
            entity: this.entity,
            portfolio: this.portfolio,
            issueDate: this.issueDate.toDTO(),
            valueDate: this.valueDate.toDTO(),
            maturedDate: this.maturedDate.toDTO(),
            faceValue: this.faceValue,
            purchasePrice: this.purchasePrice,
            maturedUnits: this.maturedUnits,
            settlementAmount: this.settlementAmount,
            currency: this.currency.toDTO(),
        };
        return ret;
    }
    copy(requestId = this.requestId, security = this.security, dealRequestStatus = this.dealRequestStatus, isin = this.isin, entity = this.entity, portfolio = this.portfolio, issueDate = this.issueDate, valueDate = this.valueDate, maturedDate = this.maturedDate, faceValue = this.faceValue, purchasePrice = this.purchasePrice, maturedUnits = this.maturedUnits, settlementAmount = this.settlementAmount, currency = this.currency) {
        return new GetFiMaturityDealRequestsPaginationResponse(requestId, security, dealRequestStatus, isin, entity, portfolio, issueDate, valueDate, maturedDate, faceValue, purchasePrice, maturedUnits, settlementAmount, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiMaturityDealRequestsPaginationResponseValue = other.isGetFiMaturityDealRequestsPaginationResponse;
        if (!(other instanceof GetFiMaturityDealRequestsPaginationResponse) || Boolean(isGetFiMaturityDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.security !== other.security) {
            return false;
        }
        if (this.dealRequestStatus !== other.dealRequestStatus) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.portfolio !== other.portfolio) {
            return false;
        }
        if (!this.issueDate.equals(other.issueDate)) {
            return false;
        }
        if (!this.valueDate.equals(other.valueDate)) {
            return false;
        }
        if (!this.maturedDate.equals(other.maturedDate)) {
            return false;
        }
        if (this.faceValue !== other.faceValue) {
            return false;
        }
        if (this.purchasePrice !== other.purchasePrice) {
            return false;
        }
        if (this.maturedUnits !== other.maturedUnits) {
            return false;
        }
        if (this.settlementAmount !== other.settlementAmount) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetFiMaturityDealRequestsPaginationResponse = GetFiMaturityDealRequestsPaginationResponse;
GetFiMaturityDealRequestsPaginationResponse.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute requestId is not an Int64`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateSecurity = (security) => {
    if (!(0, leo_ts_runtime_1.isString)(security)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute security is not a String`);
    }
    if (security.trim().length === 0) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute security cannot be an empty string.`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateDealRequestStatus = (dealRequestStatus) => {
    if (!fiMaturityDealRequestStatus_1.FiMaturityDealRequestStatus.isInstanceOf(dealRequestStatus)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute dealRequestStatus is not a FiMaturityDealRequestStatus.FiMaturityDealRequestStatus`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute isin is not a ISIN`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute entity cannot be an empty string.`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validatePortfolio = (portfolio) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute portfolio is not a String`);
    }
    if (portfolio.trim().length === 0) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute portfolio cannot be an empty string.`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateIssueDate = (issueDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(issueDate)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute issueDate is not a Date`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateValueDate = (valueDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(valueDate)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute valueDate is not a Date`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateMaturedDate = (maturedDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(maturedDate)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute maturedDate is not a Date`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateFaceValue = (faceValue) => {
    if (!(0, leo_ts_runtime_1.isString)(faceValue)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute faceValue is not a String`);
    }
    if (faceValue.trim().length === 0) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute faceValue cannot be an empty string.`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validatePurchasePrice = (purchasePrice) => {
    if (!(0, leo_ts_runtime_1.isString)(purchasePrice)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute purchasePrice is not a String`);
    }
    if (purchasePrice.trim().length === 0) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute purchasePrice cannot be an empty string.`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateMaturedUnits = (maturedUnits) => {
    if (!(0, leo_ts_runtime_1.isString)(maturedUnits)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute maturedUnits is not a String`);
    }
    if (maturedUnits.trim().length === 0) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute maturedUnits cannot be an empty string.`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateSettlementAmount = (settlementAmount) => {
    if (!(0, leo_ts_runtime_1.isString)(settlementAmount)) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute settlementAmount is not a String`);
    }
    if (settlementAmount.trim().length === 0) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute settlementAmount cannot be an empty string.`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFiMaturityDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFiMaturityDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetFiMaturityDealRequestsPaginationResponse(requestId=${this.requestId},security='${this.security}',dealRequestStatus=${this.dealRequestStatus},isin=${this.isin},entity='${this.entity}',portfolio='${this.portfolio}',issueDate=${this.issueDate},valueDate=${this.valueDate},maturedDate=${this.maturedDate},faceValue='${this.faceValue}',purchasePrice='${this.purchasePrice}',maturedUnits='${this.maturedUnits}',settlementAmount='${this.settlementAmount}',currency=${this.currency})`;
};
