import React from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import {
  Button,
  EllipsisTypography,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../utils/BorderUtils";
import { observer } from "mobx-react";
import { KHTimeline } from "./timeline/KHTimeline";
import { getTimelineItem } from "../utils/TimelineUtils";
import { Instance } from "mobx-state-tree";
import { CashflowTimelineModel } from "../models/CashflowTimelineModel";

export interface DetailsCardItem {
  top: string;
  middle: string;
  bottom: string;
}

interface DetailsTimelineCardProps {
  dataList: DetailsCardItem[];
  title: string;
  headerButtonTitle: string;
  isButtonVisible: boolean;
  onButtonClick: () => void;
  cashFlowTimeLineItems: Instance<typeof CashflowTimelineModel>[];
}

const Size = {
  container: "100%",
};

export const DetailsTimeLineCard = observer(
  ({
    dataList,
    title,
    headerButtonTitle,
    isButtonVisible,
    onButtonClick,
    cashFlowTimeLineItems,
  }: DetailsTimelineCardProps): React.ReactElement => {
    const typography = useTypography();
    const cornerRadius = useCornerRadius();
    const tokens = useFoundationColorTokens();
    const border = useBorder();
    const spacing = useSpacing();
    const dataElements = dataList.map((item, index): React.ReactElement => {
      return (
        <Grid item sm={12} md={6} xl={4} textAlign={"center"} key={index}>
          <Typography
            sx={{
              ...typography.h5,
              color: tokens.labelSubtle,
            }}
          >
            {item.top}
          </Typography>
          <EllipsisTypography
            ellipseTypography={{
              ...typography.s2,
            }}
            textColor={tokens.label}
            maxLines={2}
            width={Size.container}
          >
            {item.middle}
          </EllipsisTypography>
          <Typography
            sx={{
              ...typography.b3,
              color: tokens.labelSubtle,
            }}
          >
            {item.bottom}
          </Typography>
        </Grid>
      );
    });
    const detailsWidth = cashFlowTimeLineItems.length <= 0 ? 12 : 8;
    return (
      <Stack
        sx={{
          backgroundColor: tokens.background,
          width: Size.container,
          borderRadius: cornerRadius.radiusXS,
          border: border.default,
          borderRight: border.default,
        }}
      >
        <Stack
          flexDirection="row"
          padding={spacing.spaceMD}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box sx={{ marginLeft: spacing.spaceMD }}>
            <Typography
              sx={{
                ...typography.sh3,
                color: tokens.labelHighEmphasis,
              }}
            >
              {title}
            </Typography>
          </Box>
          {isButtonVisible && (
            <Button
              name={headerButtonTitle}
              variant={"plain-color"}
              color={"primary"}
              label={headerButtonTitle}
              size={"small"}
              onClick={onButtonClick}
            />
          )}
        </Stack>
        <Divider />
        <Stack flexDirection={"row"}>
          <Grid container>
            <Grid
              item
              xs={detailsWidth}
              padding={spacing.spaceXL}
              alignSelf={"center"}
            >
              <Grid container spacing={5} justifyContent={"space-evenly"}>
                {dataElements}
              </Grid>
            </Grid>
            {cashFlowTimeLineItems.length > 0 && (
              <Grid item xs={4} borderLeft={border.lowEmphasis}>
                <Stack height={Size.container} justifyContent={"center"}>
                  <KHTimeline
                    title=""
                    items={getTimelineItem(cashFlowTimeLineItems)}
                    isCreateDealScreen={false}
                  />
                </Stack>
              </Grid>
            )}
          </Grid>
        </Stack>
      </Stack>
    );
  },
);
