export enum ContractNoteDetailErrors {
  InvalidContractNoteID = "INVALID_CONTRACT_NOTE_ID",
  InvalidEQRawContractNoteId = "INVALID_EQ_RAW_CONTRACT_NOTE_ID",
  DataMismatch = "DATA_MISMATCH",
  IllegalContractNoteState = "ILLEGAL_CONTRACT_NOTE_STATE",
  CannotCheckSelfRequest = "CANNOT_CHECK_SELF_REQUEST",
  InvalidDealRequestId = "INVALID_DEAL_REQUEST_ID",
  LinkAlreadyExists = "LINK_REQUEST_ALREADY_EXISTS",
  ContractNoteAlreadyLinked = "CONTRACT_NOTE_ALREADY_LINKED",
  DealValueExceeded = "DEAL_VALUE_EXCEEDED",
  Unknown = "UNKNOWN",
  InvalidISINError = "InvalidISINError",
  InvalidDealRequestState = "INVALID_DEAL_REQUEST_STATE",
}
