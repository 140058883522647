import {
  Currency,
  GetActiveAccrualsPaginationResponse,
  GetActiveAccrualsRPC,
  BigDecimal,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

const activeAccruals: GetActiveAccrualsPaginationResponse[] = [
  new GetActiveAccrualsPaginationResponse(
    1,
    "FIXED_INCOME",
    1,
    4,
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    null,
    null,
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    true,
    true,
    new Currency("INR", "₹"),
  ),
  new GetActiveAccrualsPaginationResponse(
    1,
    "FIXED_INCOME",
    1,
    4,
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new BigDecimal("1100.78"),
    new BigDecimal("1100.78"),
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    true,
    false,
    new Currency("INR", "₹"),
  ),
  new GetActiveAccrualsPaginationResponse(
    2,
    "FIXED_DEPOSIT",
    2,
    4,
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new BigDecimal("1100.78"),
    new BigDecimal("1100.78"),
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    true,
    true,
    new Currency("INR", "₹"),
  ),

  new GetActiveAccrualsPaginationResponse(
    3,
    "FIXED_INCOME",
    3,
    4,
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    null,
    null,
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    true,
    true,
    new Currency("INR", "₹"),
  ),
  new GetActiveAccrualsPaginationResponse(
    4,
    "FIXED_DEPOSIT",
    4,
    4,
    "PRINCIPAL_EXPECTED",
    new BigDecimal("7.84"),
    new BigDecimal("1144.78"),
    new BigDecimal("1144.78"),
    new BigDecimal("1100.78"),
    new BigDecimal("1100.78"),
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    true,
    true,
    new Currency("INR", "₹"),
  ),
];

export class MockGetActiveAccrualsRPCImpl extends GetActiveAccrualsRPC {
  execute(
    request: GetActiveAccrualsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetActiveAccrualsRPC.Response,
      GetActiveAccrualsRPC.Errors.InvalidPageIndex
    >
  > {
    let accruals = activeAccruals;
    if (request.purchaseId) {
      accruals = accruals.filter((t) => {
        return t.correlationId === request.purchaseId;
      });
    }
    if (request.moduleName) {
      accruals = accruals.filter((t) => {
        return t.moduleName === request.moduleName;
      });
    }
    const response = new GetActiveAccrualsRPC.Response(
      accruals.slice(
        request.pageIndex.items * request.itemsPerPage.items,
        request.itemsPerPage.items,
      ),
      accruals.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetActiveAccrualsRPC.Response,
        GetActiveAccrualsRPC.Errors.Errors
      >
    >;
  }
}
