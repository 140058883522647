import React, { useCallback, useEffect } from "react";
import { debounce } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react";
import { BankBranchAutocompleteStore } from "./BankBranchAutocompleteStore";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../AsyncAutoCompleteInputFieldSeparateLabel";
import { DEBOUNCE_DELAY } from "../../../../utils";

interface BankBranchAutocompleteProps {
  store: Instance<typeof BankBranchAutocompleteStore>;
  label: string;
  placeholder?: string;
  width?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: boolean;
  helperText?: string;
  noOptionText?: string;
}

const Sizes = {
  subLabelMaxWidth: "40%",
};

export const BankBranchAsyncAutocomplete = observer(
  ({
    store,
    label,
    placeholder,
    width = "auto",
    isDisabled = false,
    isRequired = false,
    error,
    helperText,
  }: BankBranchAutocompleteProps): React.ReactElement => {
    const { t } = useTranslation();

    const debounceBranchSearch = useCallback(
      debounce(function (searchValue: string | null | undefined) {
        if (!searchValue || searchValue.length < 2) {
          if (!searchValue) {
            store.deselectBranch();
          }
          debounceBranchSearch.clear();
          store.setNoOptionText(t("common.bankBranchAutocomplete.placeholder"));
          return;
        }
        store.getBranchList().then(() => {
          if (!store.branchList.length) {
            store.setNoOptionText(
              t("common.bankBranchAutocomplete.emptyStateMessage"),
            );
          }
        });
      }, DEBOUNCE_DELAY),
      [],
    );

    useEffect(() => {
      if (store.selectedBranchId) {
        store.setSearchText(store.selectedBranchId);
        debounceBranchSearch(store.selectedBranchId);
      }
      store.setNoOptionText(t("common.bankBranchAutocomplete.placeholder"));
    }, []);

    return (
      <AsyncAutoCompleteInputFieldSeparateLabel
        id={"bankBranch"}
        label={label}
        placeholder={placeholder}
        isRequired={isRequired}
        isDisabled={isDisabled}
        error={error}
        helperText={helperText ?? store.getSelectedBranch()?.subLabel}
        isLoading={store.isLoading}
        loadingText={t("common.loading")}
        value={store.getSelectedBranch()}
        options={store.getBranchOptions()}
        isFilterDisabled={true}
        onInputChange={(inputValue: string | null): void => {
          const searchText = inputValue?.trim();
          if (!searchText) {
            store.deselectBranch();
          }
          store.setSearchText(searchText ?? "");
          debounceBranchSearch(searchText);
        }}
        onInputClear={(): void => {
          store.setNoOptionText(t("common.bankBranchAutocomplete.placeholder"));
          store.deselectBranch();
        }}
        onSelect={(value): void => {
          store.deselectBranch();
          if (typeof value !== "string") {
            store.setSearchText(value?.id ?? "");
            store.setSelectedBranch(value?.id ?? "");
          }
        }}
        subLabelMaxWidth={Sizes.subLabelMaxWidth}
        style={{ width }}
        noOptionsText={store.noOptionText}
      />
    );
  },
);
