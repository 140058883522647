"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiSettleCashflowRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class FiSettleCashflowRPC {
}
exports.FiSettleCashflowRPC = FiSettleCashflowRPC;
(function (FiSettleCashflowRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    FiSettleCashflowRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(fiCashflowId, bankAccountNumber, settlementDate, note = null) {
            this.isFiSettleCashflowRPCRequest = true;
            Request.validateFiCashflowId(fiCashflowId);
            this.fiCashflowId = fiCashflowId;
            Request.validateBankAccountNumber(bankAccountNumber);
            this.bankAccountNumber = bankAccountNumber;
            Request.validateSettlementDate(settlementDate);
            this.settlementDate = settlementDate;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const fiCashflowId = (0, leo_ts_runtime_1.getInt64)(dto, "fiCashflowId", InvalidRequestError);
            const bankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "bankAccountNumber", InvalidRequestError);
            const settlementDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "settlementDate", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(fiCashflowId, bankAccountNumber, settlementDate, note);
        }
        toDTO() {
            const ret = {
                fiCashflowId: this.fiCashflowId,
                bankAccountNumber: this.bankAccountNumber,
                settlementDate: this.settlementDate.toDTO(),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(fiCashflowId = this.fiCashflowId, bankAccountNumber = this.bankAccountNumber, settlementDate = this.settlementDate, note = this.note) {
            return new Request(fiCashflowId, bankAccountNumber, settlementDate, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isFiSettleCashflowRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.fiCashflowId !== other.fiCashflowId) {
                return false;
            }
            if (this.bankAccountNumber !== other.bankAccountNumber) {
                return false;
            }
            if (!this.settlementDate.equals(other.settlementDate)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateFiCashflowId = (fiCashflowId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(fiCashflowId)) {
            throw new InvalidRequestError(`Attribute fiCashflowId is not an Int64`);
        }
    };
    Request.validateBankAccountNumber = (bankAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(bankAccountNumber)) {
            throw new InvalidRequestError(`Attribute bankAccountNumber is not a String`);
        }
        if (bankAccountNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute bankAccountNumber cannot be an empty string.`);
        }
    };
    Request.validateSettlementDate = (settlementDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(settlementDate)) {
            throw new InvalidRequestError(`Attribute settlementDate is not a Date`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    FiSettleCashflowRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `FiSettleCashflowRPC.Request(fiCashflowId=${this.fiCashflowId},bankAccountNumber='${this.bankAccountNumber}',settlementDate=${this.settlementDate},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    FiSettleCashflowRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    FiSettleCashflowRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidCashflowId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CASHFLOW_ID") {
                super(code);
                this.isInvalidCashflowId = true;
                InvalidCashflowId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidCashflowId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidCashflowId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidCashflowIdValue = other.isInvalidCashflowId;
                if (!(other instanceof InvalidCashflowId || Boolean(isInvalidCashflowIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidCashflowId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CASHFLOW_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidCashflowId = InvalidCashflowId;
        InvalidCashflowId.prototype.toString = function toString() {
            return `FiSettleCashflowRPC.INVALID_CASHFLOW_ID()`;
        };
        class InvalidBankAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BANK_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidBankAccountNumber = true;
                InvalidBankAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBankAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBankAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBankAccountNumberValue = other.isInvalidBankAccountNumber;
                if (!(other instanceof InvalidBankAccountNumber || Boolean(isInvalidBankAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBankAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BANK_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBankAccountNumber = InvalidBankAccountNumber;
        InvalidBankAccountNumber.prototype.toString = function toString() {
            return `FiSettleCashflowRPC.INVALID_BANK_ACCOUNT_NUMBER()`;
        };
        class InvalidSettlementDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_SETTLEMENT_DATE") {
                super(code);
                this.isInvalidSettlementDate = true;
                InvalidSettlementDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidSettlementDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidSettlementDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidSettlementDateValue = other.isInvalidSettlementDate;
                if (!(other instanceof InvalidSettlementDate || Boolean(isInvalidSettlementDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidSettlementDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_SETTLEMENT_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidSettlementDate = InvalidSettlementDate;
        InvalidSettlementDate.prototype.toString = function toString() {
            return `FiSettleCashflowRPC.INVALID_SETTLEMENT_DATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CASHFLOW_ID":
                    return InvalidCashflowId.fromDTO(dto);
                case "INVALID_BANK_ACCOUNT_NUMBER":
                    return InvalidBankAccountNumber.fromDTO(dto);
                case "INVALID_SETTLEMENT_DATE":
                    return InvalidSettlementDate.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidCashflowIdValue = other.isInvalidCashflowId;
            if (other instanceof InvalidCashflowId || Boolean(isInvalidCashflowIdValue)) {
                return true;
            }
            const isInvalidBankAccountNumberValue = other.isInvalidBankAccountNumber;
            if (other instanceof InvalidBankAccountNumber || Boolean(isInvalidBankAccountNumberValue)) {
                return true;
            }
            const isInvalidSettlementDateValue = other.isInvalidSettlementDate;
            if (other instanceof InvalidSettlementDate || Boolean(isInvalidSettlementDateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = FiSettleCashflowRPC.Errors || (FiSettleCashflowRPC.Errors = {}));
})(FiSettleCashflowRPC = exports.FiSettleCashflowRPC || (exports.FiSettleCashflowRPC = {}));
