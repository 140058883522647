// CreateFiDealRequestError is used to store the error codes of CreateFiDealRequestRPC Errors.
export enum CreateFiDealRequestError {
  InvalidISIN = "INVALID_ISIN",
  InvalidEntityId = "INVALID_ENTITY_ID",
  InvalidPortfolioId = "INVALID_PORTFOLIO_ID",
  InvalidDematAccountNumber = "INVALID_DEMAT_ACCOUNT_NUMBER",
  CurrencyMismatch = "CURRENCY_MISMATCH",
  InsufficientGrossQuantity = "INSUFFICIENT_GROSS_QUANTITY",
  InsufficientNetQuantity = "INSUFFICIENT_NET_QUANTITY",
  InsufficientBankBalance = "INSUFFICIENT_BANK_BALANCE",
  InvalidISINError = "InvalidISINError",
  AmountLimitExceeded = "AMOUNT_LIMIT_EXCEEDED",
  NeitherMaturityNorNextOptionDateFound = "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND",
  GrossAmountTooLess = "GROSS_AMOUNT_TOO_LESS",
  InvalidPrice = "INVALID_PRICE",
}
