export enum FDDealRequestDetailsKeys {
  requestStatus = "fd.fields.requestStatusLabel",
  depositAmount = "fd.fields.depositAmountLabel",
  outstandingDepositAmount = "fd.fields.outstandingDepositAmountLabel",
  depositBank = "fd.fields.depositBankLabel",
  depositBankBranch = "fd.fields.depositBranchLabel",
  rateOfInterest = "fd.fields.rateOfInterestLabel",
  valueDate = "fd.fields.valueDateLabel",
  interestType = "fd.fields.interestTypeLabel",
  payoutFrequency = "fd.fields.payoutFrequencyLabel",
  compoundingFrequency = "fd.fields.compoundingFrequencyLabel",
  depositDuration = "fd.fields.depositDurationLabel",
  requestId = "fd.fields.requestIdLabel",
  entity = "common.entity",
  paymentBankAccount = "fd.fields.paymentBankAccountLabel",
  portfolio = "common.portfolio",
  createdAt = "fd.fields.createdAtLabel",
  bankId = "bankId",
  branchId = "branchId",
  expectedMaturityDate = "fd.fields.expectedMaturityDateLabel",
  expectedMaturityAmount = "fd.fields.expectedMaturityAmountLabel",
}
