import { Stack } from "@mui/material";
import React from "react";
import { useSpacing } from "@surya-digital/leo-reactjs-material-ui";

export interface CreateDealContainerProps {
  children: React.ReactNode;
}

const Size = {
  width: "calc(100vw - 256px)",
};

export const CreateDealContainer = ({
  children,
}: CreateDealContainerProps): React.ReactElement => {
  const spacing = useSpacing();

  return (
    <Stack
      width={Size.width}
      direction={"row"}
      padding={spacing.space2XL}
      gap={spacing.spaceXL}
      flexWrap={"wrap"}
    >
      {children}
    </Stack>
  );
};
