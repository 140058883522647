import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Section } from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { Copy } from "@surya-digital/leo-reactjs-core";
import { ActionButtons } from "../../../components/Section";
import { FormGroup } from "../../../components/form/FormGroup";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { Stack } from "@mui/material";
import {
  TextInputFieldSeparateLabel,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { AmountTextField } from "../../../components/AmountTextField";
import { DepositDuration } from "./DepositDuration";
import {
  DEPOSIT_CERTIFICATE_PDF_ZOOM_LEVEL,
  getFrequencyPlaceholder,
  getFrequencyTitle,
  getFrequencyTypeOptions,
  getInterestTypeOptions,
} from "../utils/UIUtils";
import { PeriodUnit } from "@khazana/khazana-rpcs";
import { FileUploadDialog } from "../../../components/dialog/FileUploadDialog";
import { Pdf } from "../../../components/Pdf";
import { BankBranchAsyncAutocomplete } from "../../../components/bank-branch/BankBranchAsyncAutoComplete";
import { DatePickerFieldSeparateLabel } from "../../../components/date-picker/DatePickerFieldSeparateLabel";
import { DATE_PICKER_FORMAT } from "../../../../../utils";

const createFDCertificateEditStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    container: {
      flexDirection: "row",
      width: "100%",
      height: "Calc(100vh - 335px)",
    },
    formContainer: {
      minWidth: "720px",
      width: "720px",
      margin: "auto",
      overflow: "auto",
      padding: spacing.spaceXL,
      height: "Calc(100vh - 335px)",
      gap: spacing.spaceXL,
    },
  };
};

export interface FDCertificateEditProps {
  onCancel: () => void;
}
export const FDCertificateEdit = observer(
  ({ onCancel }: FDCertificateEditProps): React.ReactElement => {
    const { t } = useTranslation();
    const store = useFDDealRequestDetailsStore().certificateStore;
    const spacing = useSpacing();
    const errorStore = store.editCertificateDetail?.errorStore;
    const depositBankBranchDropdownStore =
      store.editCertificateDetail?.depositBankBranchAutocompleteStore;
    const [openDialog, setOpenDialog] = useState(false);

    const style = useMemo(() => createFDCertificateEditStyle(spacing), []);

    useEffect(() => {
      return () => {
        store.editCertificateDetail?.reset();
      };
    }, []);
    const getActionButtons = (): ActionButtons => {
      return {
        secondaryButton: {
          label: store.editCertificateDetail?.certificateURL
            ? t("fd.depositCertificate.UpdateDepositCertificate")
            : t("fd.depositCertificate.attachDepositCertificate"),
          onClick: (): void => {
            setOpenDialog(true);
          },
          icon: <Copy />,
        },
        primaryButton: {
          label: t("common.cancel"),
          onClick: onCancel,
        },
      };
    };

    return (
      <Stack>
        <FileUploadDialog
          title={
            store.editCertificateDetail?.certificateURL
              ? t("fd.depositCertificate.UpdateDepositCertificate")
              : t("fd.depositCertificate.attachDepositCertificate")
          }
          onSuccess={(url, sha256) => {
            store.editCertificateDetail?.setCertificateUrl(url);
            store.editCertificateDetail?.setSHA(sha256);
          }}
          showUploadNoteDialog={openDialog}
          onUploadNoteDialogClose={() => setOpenDialog(false)}
          store={store.uploadStore}
        />
        <Section
          title={t("fd.depositCertificate.sectionTitle")}
          actions={getActionButtons()}
          hasPadding={true}
        >
          <Stack sx={style.container}>
            <Stack
              sx={{
                ...style.formContainer,
              }}
            >
              <FormGroup title={t("fd.formGroupHeaders.basicDetailsTitle")}>
                {depositBankBranchDropdownStore ? (
                  <BankBranchAsyncAutocomplete
                    label={t("fd.fields.depositBranchLabel")}
                    placeholder={t("fd.fields.depositBankBranchPlaceholder")}
                    isRequired
                    error={errorStore?.depositBranchError !== undefined}
                    helperText={errorStore?.depositBranchError}
                    store={depositBankBranchDropdownStore}
                  />
                ) : (
                  <></>
                )}
                <TextInputFieldSeparateLabel
                  name={"depositCertificateNumber"}
                  value={store.editCertificateDetail?.depositCertificateNumber}
                  type={"text"}
                  onTextChange={(text): void => {
                    errorStore?.setDepositCertificateNumberError(undefined);
                    store.editCertificateDetail?.setDepositCertificateNumber(
                      text,
                    );
                  }}
                  error={
                    errorStore?.depositCertificateNumberError !== undefined
                  }
                  helperText={errorStore?.depositCertificateNumberError}
                  label={t("fd.fields.depositCertificateNumberLabel")}
                  placeholder={t(
                    "fd.fields.depositCertificateNumberPlaceholder",
                  )}
                />
              </FormGroup>
              <FormGroup title={t("fd.formGroupHeaders.depositDetailsTitle")}>
                <AmountTextField
                  name={"depositAmount"}
                  label={t("fd.fields.depositAmountLabel", {
                    currencySymbol: store.editCertificateDetail?.currencySymbol,
                  })}
                  placeholder={t("fd.fields.depositAmountPlaceholder")}
                  isRequired
                  error={errorStore?.depositAmountError !== undefined}
                  helperText={errorStore?.depositAmountError}
                  value={store.editCertificateDetail?.depositAmount}
                  onAmountChange={(amount) => {
                    errorStore?.setDepositAmountError(undefined);
                    store.editCertificateDetail?.setDepositAmount(amount);
                  }}
                  isDecimalAllowed={true}
                />
                <DepositDuration
                  depositDuration={store.editCertificateDetail?.depositDuration}
                  onDurationChange={(duration) => {
                    errorStore?.setDepositDurationError(undefined);
                    store.editCertificateDetail?.setDepositDuration(duration);
                  }}
                  error={errorStore?.depositDurationError}
                  depositUnit={
                    store.editCertificateDetail?.depositDurationUnit ??
                    PeriodUnit.PeriodUnit.MONTHS
                  }
                  onDepositUnitChange={(unit) => {
                    errorStore?.setDepositDurationError(undefined);
                    store.editCertificateDetail?.setDepositDurationUnit(unit);
                  }}
                />
                <TextInputFieldSeparateLabel
                  name={"depositAccountNumber"}
                  value={store.editCertificateDetail?.depositAccountNumber}
                  isRequired
                  type={"text"}
                  onTextChange={(text): void => {
                    errorStore?.setDepositAccountError(undefined);
                    store.editCertificateDetail?.setDepositAccount(text);
                  }}
                  error={errorStore?.depositAccountError !== undefined}
                  helperText={errorStore?.depositAccountError}
                  label={t("fd.fields.depositAccountNumberLabel")}
                  placeholder={t("fd.fields.depositAccountNumberPlaceholder")}
                />
                <DatePickerFieldSeparateLabel
                  label={t("fd.fields.valueDateLabel")}
                  value={store.editCertificateDetail?.valueDate ?? null}
                  isRequired
                  error={errorStore?.valueDateError !== undefined}
                  helperText={errorStore?.valueDateError}
                  onChange={(date) => {
                    errorStore?.setValueDateError(undefined);
                    store.editCertificateDetail?.setValueDate(date);
                  }}
                  onError={() => {
                    errorStore?.setValueDateError("");
                  }}
                  format={DATE_PICKER_FORMAT}
                />
              </FormGroup>
              <FormGroup title={t("fd.formGroupHeaders.interestDetailsTitle")}>
                <DropdownInputFieldSeparateLabel
                  name={"interestType"}
                  label={t("fd.fields.interestTypeLabel")}
                  placeholder={t("fd.fields.interestTypePlaceholder")}
                  isRequired
                  error={errorStore?.interestTypeError !== undefined}
                  helperText={errorStore?.interestTypeError}
                  value={store.editCertificateDetail?.interestType}
                  options={getInterestTypeOptions()}
                  onSelect={(item) => {
                    errorStore?.setInterestTypeError(undefined);
                    store.editCertificateDetail?.setInterestType(item.value);
                  }}
                />
                <DropdownInputFieldSeparateLabel
                  name={"frequency"}
                  label={getFrequencyTitle(
                    store.editCertificateDetail?.interestType,
                  )}
                  placeholder={getFrequencyPlaceholder(
                    store.editCertificateDetail?.interestType,
                  )}
                  isRequired
                  error={errorStore?.frequencyError !== undefined}
                  helperText={errorStore?.frequencyError}
                  isDisabled={!store.editCertificateDetail?.interestType}
                  value={store.editCertificateDetail?.frequency}
                  options={getFrequencyTypeOptions(
                    store.editCertificateDetail?.interestType,
                  )}
                  onSelect={(item) => {
                    errorStore?.setFrequencyError(undefined);
                    store.editCertificateDetail?.setFrequency(item.value);
                  }}
                />
                <AmountTextField
                  name={"rateOfInterest"}
                  label={t("fd.fields.rateOfInterestLabel")}
                  placeholder={t("fd.fields.rateOfInterestPlaceholder")}
                  isRequired
                  error={errorStore?.rateOfInterestError !== undefined}
                  helperText={errorStore?.rateOfInterestError}
                  value={store.editCertificateDetail?.rateOfInterest}
                  onAmountChange={(value) => {
                    errorStore?.setRateOfInterestError(undefined);
                    store.editCertificateDetail?.setInterestRate(value);
                  }}
                  isDecimalAllowed={true}
                  maxLimit={100}
                />
              </FormGroup>
              <FormGroup title={t("fd.formGroupHeaders.maturityDetailsTitle")}>
                <DatePickerFieldSeparateLabel
                  label={t("fd.fields.maturityDateLabel")}
                  value={store.editCertificateDetail?.maturityDate ?? null}
                  isRequired
                  error={errorStore?.maturityDateError !== undefined}
                  helperText={errorStore?.maturityDateError}
                  onChange={(date) => {
                    errorStore?.setMaturityDateError(undefined);
                    store.editCertificateDetail?.setMaturityDate(date);
                  }}
                  onError={() => {
                    errorStore?.setMaturityDateError("");
                  }}
                  format={DATE_PICKER_FORMAT}
                />
                <AmountTextField
                  name={"MaturityAmount"}
                  label={t("fd.fields.expectedMaturityAmountLabel", {
                    currencySymbol: store.editCertificateDetail?.currencySymbol,
                  })}
                  placeholder={t("fd.fields.expectedMaturityAmountPlaceholder")}
                  isRequired
                  error={errorStore?.maturityAmountError !== undefined}
                  helperText={errorStore?.maturityAmountError}
                  value={store.editCertificateDetail?.expectedMaturityAmount}
                  onAmountChange={(amount) => {
                    errorStore?.setMaturityAmountError(undefined);
                    store.editCertificateDetail?.setMaturityAmount(amount);
                  }}
                  isDecimalAllowed={true}
                />
              </FormGroup>
            </Stack>
            {store.editCertificateDetail?.certificateURL && (
              <Pdf
                url={store.editCertificateDetail?.certificateURL}
                zoom={DEPOSIT_CERTIFICATE_PDF_ZOOM_LEVEL}
              />
            )}
          </Stack>
        </Section>
      </Stack>
    );
  },
);
