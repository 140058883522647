import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Alert, Stack, Typography } from "@mui/material";
import {
  Breadcrumb,
  Button,
  Chip,
  LoadingIndicator,
  PageHeader,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { ContractNoteDetailSection } from "../components/FiContractNoteDetailSection";
import { Pdf } from "../../../components/Pdf";
import { History } from "../../../../../assets/History";
import { Edit } from "../../../../../assets/Edit";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { FiContractNoteDetailErrors } from "../store/FiContractNoteDetailsErrors";
import { FiContractNoteEdit } from "../components/FiContractNoteEdit";
import { FiContractNoteRequestStatus } from "@khazana/khazana-rpcs";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { getFiContractNoteRequestStatusValue } from "../utils/SearchUtils";
import {
  getFiCashflowDetailsPath,
  getPath,
} from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getFiContractNoteStatusBackgroundColor } from "../utils/UIUtils";
import { useFiContractNoteDetailsStore } from "../store/hooks";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { FiContractNoteHistoryDialog } from "../components/FiContractNoteHistoryDialog";
import { useBorder } from "../../../../../utils/BorderUtils";
import {
  CheckResponse,
  CheckResponseEnums,
} from "@khazana/khazana-rpcs/build/types/checkResponse";
import { FiLinkDealRequestDialog } from "../components/FiLinkDealRequestDialog";
import { FileEmptyState } from "../../../../../assets/FileEmptyState";
import { createServerNoteRPCType } from "../../../../../utils";
import {
  HeaderButton,
  HeaderButtonProps,
} from "../../../components/page-header/HeaderButton";
import { HeaderContainer } from "../../../components/page-header/HeaderContainer";
import { FiInvestmentDetailsCard } from "../../components/investment-details/FiInvestmentDetailsCard";
import { KHToggleButton } from "../../../components/toggleButton/KHToggleButton";
import { getFIAllocationToggleButtonOptions } from "../../deal-request/utils/UIUtils";

const Size = {
  fileEmptyState: {
    width: "32px",
    height: "32px",
  },
  alertWidth: "100%",
};

export const FiContractNoteDetails = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const typography = useTypography();
  const store = useFiContractNoteDetailsStore();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const contractNoteId = searchParam.get("contractNoteId")
    ? Number(searchParam.get("contractNoteId"))
    : undefined;
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<
    "Approve" | "Reject" | "Submit" | "Discard" | "Unknown" | "Link"
  >("Submit");
  const [dealDialogOpen, setDealDialogOpen] = useState(false);
  const [selectedDealRequestId, setSelectedDealRequestId] = useState<number>();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  useEffect(() => {
    const getDetails = async (): Promise<void> => {
      setIsLoading(true);
      await store.fetchContractNoteDetails(contractNoteId);
      if (
        store.status ===
          FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNPARSED &&
        store.checkRequestStore.allowedActions.allowEdit
      ) {
        setIsEdit(true);
      } else if (
        store.status ===
        FiContractNoteRequestStatus.FiContractNoteRequestStatus.LINKED
      ) {
        await store.getInvestmentDetails(contractNoteId);
      }
      setIsLoading(false);
    };
    getDetails();

    return () => {
      store.resetStore();
    };
  }, []);

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      let title = "";
      let children;
      let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
      if (dialogType === "Approve") {
        title = t("contractNotes.approveContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.checkContractNote(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
              createServerNoteRPCType(note),
            ),
          );
        };
      } else if (dialogType === "Reject") {
        title = t("contractNotes.rejectContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.checkContractNote(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
              createServerNoteRPCType(note),
            ),
          );
        };
      } else if (dialogType === "Submit") {
        title = t("contractNotes.submitContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.submitContractNote({ contractNoteId, note });
        };
      } else if (dialogType === "Discard") {
        title = t("contractNotes.discardContractNote");
        onPrimaryButtonClick = store.submitContractNoteDiscardRequest;
      } else if (dialogType === "Unknown") {
        title = t("contractNotes.markContractNoteAsUnknown");
        onPrimaryButtonClick = store.submitContractNoteUnknownRequest;
      } else if (dialogType === "Link") {
        title = t("contractNotes.linkContractNote");
        onPrimaryButtonClick = async (
          note: string | undefined,
        ): Promise<void> => {
          await store.submitContractNoteLinkRequest(
            selectedDealRequestId,
            note,
          );
        };
      }
      if (
        dialogType === "Approve" &&
        store.status ===
          FiContractNoteRequestStatus.FiContractNoteRequestStatus
            .LINKING_PENDING
      ) {
        children = (
          <Stack paddingBottom={spacing.spaceMD}>
            <Alert
              icon={false}
              variant="filled"
              sx={{
                background: tokens.backgroundInfoSubtle,
                border: border.infoSubtle,
                boxSizing: "border-box",
                color: tokens.labelInfo,
                width: `${Size.alertWidth} !important`,
                marginBottom: spacing.spaceLG,
                borderRadius: `${cornerRadius.radiusXS} !important`,
                ...typography.b1,
              }}
            >
              {t("fi.holdingCategoryInfo")}
            </Alert>
            <KHToggleButton
              items={getFIAllocationToggleButtonOptions()}
              value={store.holdingCategory}
              onChange={(_event, value): void => {
                store.setHoldingCategory(value);
              }}
            />
          </Stack>
        );
      }
      return (
        <TextFieldDialog
          title={title}
          onClose={(): void => {
            setIsDialogOpen(false);
            if (dialogType === "Link") {
              setDealDialogOpen(true);
            }
          }}
          isOpen={isDialogOpen}
          primaryButtonType={dialogType}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsLoading(true);
            await onPrimaryButtonClick(note);
            setIsDialogOpen(false);
            setIsEdit(false);
            await store.fetchContractNoteDetails(contractNoteId);
            setIsLoading(false);
          }}
          isCloseIconPresent={!isLoading}
        >
          {children}
        </TextFieldDialog>
      );
    } else {
      return <></>;
    }
  };

  const getSubmitButtonProps = (): HeaderButtonProps => {
    return {
      label: t("common.submit"),
      onClick: async (): Promise<void> => {
        if (
          store.status ===
          FiContractNoteRequestStatus.FiContractNoteRequestStatus.PARSED
        ) {
          setDialogType("Submit");
          setIsDialogOpen(true);
        } else {
          setIsSubmitClicked(true);
          if (store.validateStore.checkIsRequiredDataInserted()) {
            setIsLoading(true);
            await store.validateContractNote(contractNoteId);
            setIsLoading(false);
            if (!store.error) {
              setDialogType("Submit");
              setIsDialogOpen(true);
            }
            if (store.error === FiContractNoteDetailErrors.DataMismatch) {
              setIsAlertVisible(true);
            }
          }
        }
      },
      buttonType: "filled",
    };
  };

  const submitButton = (): React.ReactElement => {
    return <HeaderButton {...getSubmitButtonProps()} />;
  };

  const discardSubmitButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={getSubmitButtonProps()}
        secondaryButton={{
          label: t("contractNotes.discard"),
          onClick: async (): Promise<void> => {
            setDialogType("Discard");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
      />
    );
  };

  const discardUnknownButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.markAsUnknown"),
          onClick: async (): Promise<void> => {
            setDialogType("Unknown");
            setIsDialogOpen(true);
          },
          buttonType: "filled",
        }}
        secondaryButton={{
          label: t("contractNotes.discard"),
          onClick: async (): Promise<void> => {
            setDialogType("Discard");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
      />
    );
  };

  const unknownButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("contractNotes.markAsUnknown")}
        onClick={async (): Promise<void> => {
          setDialogType("Unknown");
          setIsDialogOpen(true);
        }}
        buttonType="red-outlined"
      />
    );
  };

  const checkButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.approve"),
          onClick: async (): Promise<void> => {
            setDialogType("Approve");
            setIsDialogOpen(true);
          },
          buttonType: "outlined",
        }}
        secondaryButton={{
          label: t("contractNotes.reject"),
          onClick: async (): Promise<void> => {
            setDialogType("Reject");
            setIsDialogOpen(true);
          },
          buttonType: "outlined",
        }}
      />
    );
  };

  const linkButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.link"),
          onClick: async (): Promise<void> => {
            setDealDialogOpen(true);
          },
          buttonType: "filled",
        }}
        secondaryButton={{
          label: t("contractNotes.markAsUnknown"),
          onClick: async (): Promise<void> => {
            setDialogType("Unknown");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
      />
    );
  };

  const getButtons = (): React.ReactElement | undefined => {
    if (isEdit) {
      return submitButton();
    } else {
      if (
        store.checkRequestStore?.allowedActions.allowReviewRequest &&
        !store.checkRequestStore?.allowedActions.allowDiscardRequest
      ) {
        return submitButton();
      } else if (
        store.checkRequestStore?.allowedActions.allowDiscardRequest &&
        !store.checkRequestStore?.allowedActions.allowUnknownRequest
      ) {
        return discardSubmitButtons();
      } else if (
        store.checkRequestStore?.allowedActions.allowUnknownRequest &&
        !store.checkRequestStore?.allowedActions.allowLinkRequest &&
        !store.checkRequestStore?.allowedActions.allowDiscardRequest
      ) {
        return unknownButton();
      } else if (
        store.checkRequestStore?.allowedActions.allowUnknownRequest &&
        store.checkRequestStore?.allowedActions.allowDiscardRequest &&
        !store.checkRequestStore?.allowedActions.allowLinkRequest
      ) {
        return discardUnknownButtons();
      } else if (
        store.checkRequestStore?.allowedActions.allowContractCheck ||
        store.checkRequestStore?.allowedActions.allowDiscardCheck ||
        store.checkRequestStore?.allowedActions.allowLinkCheck ||
        store.checkRequestStore?.allowedActions.allowUnknownCheck
      ) {
        return checkButtons();
      } else if (store.checkRequestStore?.allowedActions.allowLinkRequest) {
        return linkButtons();
      }
    }
  };

  const getErrorMessage = (): string => {
    switch (store.error) {
      case FiContractNoteDetailErrors.InvalidContractNoteID:
        return t("contractNotes.invalidContractNote");
      case FiContractNoteDetailErrors.DealValueExceeded:
        const selectedDealRequest = store.linkDealStore.requests.find(
          (request) => request.dealRequestId === selectedDealRequestId,
        );
        if (selectedDealRequest) {
          if (selectedDealRequest.amountHCY) {
            return t("contractNotes.linkAmountExceeds");
          } else if (selectedDealRequest.quantity) {
            return t("contractNotes.linkQuantityExceeds");
          } else {
            console.error(
              `Developer Error: Quantity and amount of deal request with id: ${selectedDealRequestId} is null.`,
            );
          }
        } else {
          console.error(
            `Developer Error: Deal request with id: ${selectedDealRequestId} does not exist in approved requests.`,
          );
        }
        return t("errors.internalServerErrorDescription");
      default:
        return t("errors.internalServerErrorDescription");
    }
  };

  return (
    <Stack direction="column">
      {store.error &&
        store.error !== FiContractNoteDetailErrors.DataMismatch &&
        store.error !== LeoErrors.InvalidContractNoteEditsError &&
        store.error !== LeoErrors.InvalidEquityTransactionTypeError &&
        store.error !== FiContractNoteDetailErrors.InvalidISINError && (
          <ErrorDialog
            errorMessage={getErrorMessage()}
            isErrorDialogOpen={store.error !== null}
            onClose={(): void => {
              store.error === FiContractNoteDetailErrors.InvalidDealRequestState
                ? navigate(0)
                : navigate(getPath(Module.Fi, Route.ManageContractNotes));
              store.removeError();
            }}
          />
        )}
      {isDialogOpen && getDialog()}
      {dealDialogOpen && (
        <FiLinkDealRequestDialog
          onClose={(): void => {
            setSelectedDealRequestId(undefined);
            setDealDialogOpen(false);
          }}
          isOpen={dealDialogOpen}
          onContinue={(dealRequestId): void => {
            setDealDialogOpen(false);
            setDialogType("Link");
            setSelectedDealRequestId(dealRequestId);
            setIsDialogOpen(true);
          }}
          selectedDealRequestId={selectedDealRequestId}
        />
      )}
      <LoadingIndicator isLoading={isLoading} />
      <PageHeader
        title={t("fi.contractNotes.contractNoteDetailTitle")}
        actionElement={getButtons()}
      />
      {
        <FiContractNoteHistoryDialog
          onClose={(): void => {
            setIsHistoryOpen(false);
          }}
          isOpen={isHistoryOpen}
        />
      }
      <Breadcrumb
        links={[
          {
            label: t("common.manageContractNotes"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Fi, Route.ManageContractNotes));
            },
          },
        ]}
        currentLabel={t("fi.contractNotes.contractNoteDetailTitle")}
        style={{ padding: spacing.space2XL, paddingBottom: 0 }}
      />
      <Stack
        sx={{
          margin: spacing.space2XL,
          marginBottom: 0,
        }}
      >
        <FiInvestmentDetailsCard
          store={store.investmentDetailsStore}
          onButtonClick={() => {
            navigate(
              getFiCashflowDetailsPath(
                store.investmentDetailsStore.investmentId,
                contractNoteId,
              ) ?? "",
            );
          }}
        />
      </Stack>
      <Stack
        sx={{
          margin: spacing.space2XL,
          borderRadius: cornerRadius.radiusXS,
          backgroundColor: tokens.background,
          border: border.default,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            padding: `${spacing.spaceMD} ${spacing.spaceXL} ${spacing.spaceMD} ${spacing.spaceXL}`,
            borderBottom: border.default,
          }}
          spacing={spacing.spaceXS}
        >
          <Stack
            direction={"row"}
            spacing={spacing.spaceXS}
            alignItems={"center"}
          >
            <Typography sx={{ ...typography.sh3 }}>
              {t("fi.contractNotes.contractNoteDetailTitle")}
            </Typography>
            {store.status ? (
              <Chip
                label={getFiContractNoteRequestStatusValue(
                  store.status,
                ).toLocaleUpperCase()}
                color={getFiContractNoteStatusBackgroundColor(store.status)}
              />
            ) : null}
          </Stack>
          <Stack direction={"row"} spacing={spacing.spaceXS}>
            {isEdit &&
              store.status !==
                FiContractNoteRequestStatus.FiContractNoteRequestStatus
                  .UNPARSED && (
                <Button
                  name="cancel"
                  size="small"
                  variant="outlined-color"
                  onClick={(): void => {
                    setIsSubmitClicked(false);
                    store.resetEdit();
                    setIsEdit(false);
                  }}
                  label={t("common.cancel")}
                />
              )}
            {!isEdit && store.details.length > 0 && (
              <>
                <Button
                  name={"ViewHistory"}
                  size={"small"}
                  iconPosition="leading"
                  icon={<History />}
                  variant={"outlined-color"}
                  onClick={async (): Promise<void> => {
                    setIsLoading(true);
                    await store.getFiContractNoteHistory(contractNoteId);
                    setIsHistoryOpen(true);
                    setIsLoading(false);
                  }}
                  label={t("contractNotes.viewHistory")}
                />
                {store.checkRequestStore?.allowedActions.allowEdit ? (
                  <Button
                    name={"edit"}
                    size={"small"}
                    iconPosition="leading"
                    icon={<Edit />}
                    variant={"outlined-color"}
                    onClick={(): void => {
                      setIsEdit(true);
                    }}
                    label={t("common.editDetails")}
                  />
                ) : null}
              </>
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"}>
          {isEdit ? (
            <FiContractNoteEdit
              isSubmitClicked={isSubmitClicked}
              setIsSubmitClicked={setIsSubmitClicked}
              isAlertVisible={isAlertVisible}
              setIsAlertVisible={setIsAlertVisible}
            />
          ) : !store.checkRequestStore.allowedActions.allowEdit &&
            store.status ===
              FiContractNoteRequestStatus.FiContractNoteRequestStatus
                .UNPARSED ? (
            <Pdf url={store.contractNoteUrl} isEdit={false} />
          ) : (
            <ContractNoteDetailSection />
          )}
          {(store.contractNoteUrl &&
            (store.checkRequestStore.allowedActions.allowEdit ||
              store.status !==
                FiContractNoteRequestStatus.FiContractNoteRequestStatus
                  .UNPARSED) && <Pdf url={store.contractNoteUrl} />) ??
            (store.fiContractNoteHistoryId && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                width={"50%"}
              >
                <FileEmptyState
                  width={Size.fileEmptyState.width}
                  height={Size.fileEmptyState.height}
                  style={{ marginBottom: Size.fileEmptyState.height }}
                />
                <Typography
                  style={{
                    ...typography.sh3,
                    color: tokens.labelLowEmphasis,
                  }}
                >
                  {t("contractNotes.noPdfAvailableTitle")}
                </Typography>
                <Typography
                  style={{
                    ...typography.b2,
                    color: tokens.labelLowEmphasis,
                  }}
                >
                  {t("contractNotes.noPdfAvailableSubtitle")}
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
});
