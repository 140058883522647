import {
  GetFdInvestmentDetailsRPC,
  GetFdInvestmentDetailsRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../constants/RPC-Mock";
import { MockGetFdInvestmentDetailsRPCImpl } from "./MockGetFdInvestmentDetailsRPCImpl";

export const useGetFdInvestmentDetailsClientImpl = (
  apiClient: APIClient,
): GetFdInvestmentDetailsRPC => {
  if (MOCK.fd) {
    return new MockGetFdInvestmentDetailsRPCImpl();
  } else {
    return new GetFdInvestmentDetailsRPCClientImpl(apiClient);
  }
};
