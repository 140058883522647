"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFApprovedDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
const mFTransactionType_1 = require("./mFTransactionType");
const mFDealRequestStatus_1 = require("./mFDealRequestStatus");
class InvalidGetMFApprovedDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetMFApprovedDealRequestsPaginationResponseError";
    }
}
class GetMFApprovedDealRequestsPaginationResponse {
    constructor(mfDealRequestId, transactionType, folioNumber, schemeName, isin, amountHCY = null, units = null, entityName, currentDealStatus, currency) {
        this.isGetMFApprovedDealRequestsPaginationResponse = true;
        GetMFApprovedDealRequestsPaginationResponse.validateMfDealRequestId(mfDealRequestId);
        this.mfDealRequestId = mfDealRequestId;
        GetMFApprovedDealRequestsPaginationResponse.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        GetMFApprovedDealRequestsPaginationResponse.validateFolioNumber(folioNumber);
        this.folioNumber = folioNumber;
        GetMFApprovedDealRequestsPaginationResponse.validateSchemeName(schemeName);
        this.schemeName = schemeName;
        GetMFApprovedDealRequestsPaginationResponse.validateIsin(isin);
        this.isin = isin;
        if (amountHCY !== undefined && amountHCY !== null) {
            GetMFApprovedDealRequestsPaginationResponse.validateAmountHCY(amountHCY);
        }
        this.amountHCY = amountHCY;
        if (units !== undefined && units !== null) {
            GetMFApprovedDealRequestsPaginationResponse.validateUnits(units);
        }
        this.units = units;
        GetMFApprovedDealRequestsPaginationResponse.validateEntityName(entityName);
        this.entityName = entityName;
        GetMFApprovedDealRequestsPaginationResponse.validateCurrentDealStatus(currentDealStatus);
        this.currentDealStatus = currentDealStatus;
        GetMFApprovedDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const mfDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "mfDealRequestId", InvalidGetMFApprovedDealRequestsPaginationResponseError);
        const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidGetMFApprovedDealRequestsPaginationResponseError));
        const folioNumber = (0, leo_ts_runtime_1.getString)(dto, "folioNumber", InvalidGetMFApprovedDealRequestsPaginationResponseError);
        const schemeName = (0, leo_ts_runtime_1.getString)(dto, "schemeName", InvalidGetMFApprovedDealRequestsPaginationResponseError);
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidGetMFApprovedDealRequestsPaginationResponseError));
        let amountHCY = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amountHCY");
        if (amountHCY !== undefined && amountHCY !== null) {
            amountHCY = bigDecimal_1.BigDecimal.fromDTO(amountHCY);
        }
        let units = (0, leo_ts_runtime_1.getOptionalObject)(dto, "units");
        if (units !== undefined && units !== null) {
            units = bigDecimal_1.BigDecimal.fromDTO(units);
        }
        const entityName = (0, leo_ts_runtime_1.getString)(dto, "entityName", InvalidGetMFApprovedDealRequestsPaginationResponseError);
        const currentDealStatus = mFDealRequestStatus_1.MFDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currentDealStatus", InvalidGetMFApprovedDealRequestsPaginationResponseError));
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetMFApprovedDealRequestsPaginationResponseError));
        return new GetMFApprovedDealRequestsPaginationResponse(mfDealRequestId, transactionType, folioNumber, schemeName, isin, amountHCY, units, entityName, currentDealStatus, currency);
    }
    toDTO() {
        const ret = {
            mfDealRequestId: this.mfDealRequestId,
            transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
            folioNumber: this.folioNumber,
            schemeName: this.schemeName,
            isin: this.isin.toDTO(),
            entityName: this.entityName,
            currentDealStatus: mFDealRequestStatus_1.MFDealRequestStatus.toDTO(this.currentDealStatus),
            currency: this.currency.toDTO(),
        };
        if (this.amountHCY) {
            ret.amountHCY = this.amountHCY.toDTO();
        }
        if (this.units) {
            ret.units = this.units.toDTO();
        }
        return ret;
    }
    copy(mfDealRequestId = this.mfDealRequestId, transactionType = this.transactionType, folioNumber = this.folioNumber, schemeName = this.schemeName, isin = this.isin, amountHCY = this.amountHCY, units = this.units, entityName = this.entityName, currentDealStatus = this.currentDealStatus, currency = this.currency) {
        return new GetMFApprovedDealRequestsPaginationResponse(mfDealRequestId, transactionType, folioNumber, schemeName, isin, amountHCY, units, entityName, currentDealStatus, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetMFApprovedDealRequestsPaginationResponseValue = other.isGetMFApprovedDealRequestsPaginationResponse;
        if (!(other instanceof GetMFApprovedDealRequestsPaginationResponse) || Boolean(isGetMFApprovedDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (this.mfDealRequestId !== other.mfDealRequestId) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.folioNumber !== other.folioNumber) {
            return false;
        }
        if (this.schemeName !== other.schemeName) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.amountHCY) {
            if (!this.amountHCY.equals(other.amountHCY)) {
                return false;
            }
        }
        if (this.units) {
            if (!this.units.equals(other.units)) {
                return false;
            }
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        if (this.currentDealStatus !== other.currentDealStatus) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetMFApprovedDealRequestsPaginationResponse = GetMFApprovedDealRequestsPaginationResponse;
GetMFApprovedDealRequestsPaginationResponse.validateMfDealRequestId = (mfDealRequestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(mfDealRequestId)) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute mfDealRequestId is not an Int64`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateTransactionType = (transactionType) => {
    if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateFolioNumber = (folioNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(folioNumber)) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute folioNumber is not a String`);
    }
    if (folioNumber.trim().length === 0) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute folioNumber cannot be an empty string.`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateSchemeName = (schemeName) => {
    if (!(0, leo_ts_runtime_1.isString)(schemeName)) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute schemeName is not a String`);
    }
    if (schemeName.trim().length === 0) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute schemeName cannot be an empty string.`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute isin is not a ISIN`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateAmountHCY = (amountHCY) => {
    const isBigDecimalValue = amountHCY.isBigDecimal;
    if (!(amountHCY instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute amountHCY is not a BigDecimal`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateUnits = (units) => {
    const isBigDecimalValue = units.isBigDecimal;
    if (!(units instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute units is not a BigDecimal`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateCurrentDealStatus = (currentDealStatus) => {
    if (!mFDealRequestStatus_1.MFDealRequestStatus.isInstanceOf(currentDealStatus)) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute currentDealStatus is not a MFDealRequestStatus.MFDealRequestStatus`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetMFApprovedDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetMFApprovedDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetMFApprovedDealRequestsPaginationResponse(mfDealRequestId=${this.mfDealRequestId},transactionType=${this.transactionType},folioNumber='${this.folioNumber}',schemeName='${this.schemeName}',isin=${this.isin},amountHCY=${this.amountHCY},units=${this.units},entityName='${this.entityName}',currentDealStatus=${this.currentDealStatus},currency=${this.currency})`;
};
