import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createHoldingSummaryModel,
  HoldingSummaryModel,
} from "../models/HoldingSummaryModel";
import {
  EquityReportDateRange,
  FileExtension,
  GetEquityBenchmarkReturnsGraphRPC,
  GetEquityHoldingDistributionByIsinRPC,
  GetEquityHoldingSummaryReportDownloadURLRPC,
  GetEquityHoldingSummaryRPC,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useGetEquityHoldingSummaryReportDownloadURLRPCClientImpl,
  useGetEquityHoldingSummaryRPCClientImpl,
} from "../rpcs/RPC";
import {
  getLeoDateOrNull,
  ReportExportFormatEnum,
  checkAllOptionAndGetId,
} from "../../../utils/ReportUtils";
import { TableSortOption } from "@surya-digital/leo-reactjs-material-ui";
import { getHoldingSummarySortOrder } from "../utils/SortUtils";
import {
  createEntityDropdownStore,
  EntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  createPortfolioDropdownStore,
  PortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  createPercentageDateModel,
  PercentageDateModel,
} from "../../../models/PercentageDateModel";
import {
  useGetEquityBenchmarkReturnsGraphRPC,
  useGetEquityHoldingDistributionByIsinRPC,
} from "../../dashboard/rpcs/RPC";
import {
  createLabelAmountModel,
  LabelAmountModel,
} from "../../../models/LabelAmountModel";
import {
  createEquityDashboardStatsStore,
  EquityDashboardStatsStore,
} from "../../dashboard/store/EquityDashboardStatsStore";

export const EQHoldingSummaryStore = types
  .model("EQHoldingSummaryStore", {
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    downloadURL: types.maybe(types.string),
    totalItems: types.number,
    eqHoldingSummaryList: types.array(HoldingSummaryModel),
    hasError: types.boolean,
    isScreenLoading: types.boolean,
    isDataFetched: types.boolean,
    asOnDate: types.maybe(types.string),
    returns: types.array(PercentageDateModel),
    distribution: types.array(LabelAmountModel),
    dashboardStats: EquityDashboardStatsStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    setAsOnDate: (value: LeoDate): void => {
      store.asOnDate = value.date;
    },
    getHoldingSummary: flow(function* (
      sort?: TableSortOption,
      pageIndex?: number,
      itemsPerPage?: number,
    ) {
      store.isScreenLoading = true;
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetEquityHoldingSummaryRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          getLeoDateOrNull(store.asOnDate),
          pageIndex ?? 0,
          itemsPerPage ?? 10,
          getHoldingSummarySortOrder(sort),
        );
        const result: LeoRPCResult<
          GetEquityHoldingSummaryRPC.Response,
          GetEquityHoldingSummaryRPC.Errors.Errors
        > =
          yield useGetEquityHoldingSummaryRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.eqHoldingSummaryList = cast(
            response.holdingSummaryResponse.map((item) => {
              return createHoldingSummaryModel(item);
            }),
          );
          store.asOnDate = response.asOnDate.date;
          store.totalItems = response.totalItems;
          store.isDataFetched = true;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityHoldingSummaryRPC`,
          );
        }
        store.isScreenLoading = false;
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityHoldingSummaryRPC`,
          );
        }
        store.isScreenLoading = false;
      }
    }),
    getDownloadURL: flow(function* (fileExtention: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetEquityHoldingSummaryReportDownloadURLRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          new LeoDate(store.asOnDate),
          fileExtention === ReportExportFormatEnum.CSV
            ? FileExtension.FileExtension.CSV
            : FileExtension.FileExtension.XLSX,
        );
        const result: LeoRPCResult<
          GetEquityHoldingSummaryReportDownloadURLRPC.Response,
          GetEquityHoldingSummaryReportDownloadURLRPC.Errors.Errors
        > =
          yield useGetEquityHoldingSummaryReportDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityHoldingSummaryReportDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetEquityChangeInGainReportDownloadURLRPC`,
          );
        }
      }
    }),
    getReturns: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      const request = new GetEquityBenchmarkReturnsGraphRPC.Request(
        null,
        new EquityReportDateRange.Custom(
          null,
          getLeoDateOrNull(store.asOnDate),
        ),
        checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
        checkAllOptionAndGetId(
          store.portfolioDropdownStore.selectedPortfolio?.id,
        ),
      );
      const result: LeoRPCResult<
        GetEquityBenchmarkReturnsGraphRPC.Response,
        GetEquityBenchmarkReturnsGraphRPC.Errors.Errors
      > =
        yield useGetEquityBenchmarkReturnsGraphRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.returns = cast(
          response.returnsData.map((returns) =>
            createPercentageDateModel(returns),
          ),
        );
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          default:
            logger.error(
              `Unhandled error: ${error} occurred in getEquityBenchmarkReturnsGraphRPC`,
            );
        }
      } else {
        logger.error("Unhandled error");
      }
    }),
    getEquityHoldingDistributionByIsin: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      const request = new GetEquityHoldingDistributionByIsinRPC.Request(
        checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
        checkAllOptionAndGetId(
          store.portfolioDropdownStore.selectedPortfolio?.id,
        ),
        getLeoDateOrNull(store.asOnDate),
      );
      store.distribution = cast([]);
      const result: LeoRPCResult<
        GetEquityHoldingDistributionByIsinRPC.Response,
        GetEquityHoldingDistributionByIsinRPC.Errors.Errors
      > =
        yield useGetEquityHoldingDistributionByIsinRPC(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.distribution = cast(
          response.holdingDistribution.map((labelAmount) =>
            createLabelAmountModel(labelAmount),
          ),
        );
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          default:
            logger.error(
              `Unhandled error: ${error} occurred in getEquityHoldingDistributionByIsinRPC`,
            );
        }
      } else {
        logger.error("Unhandled error");
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));
export const createEQHoldingSummaryStore = (): Instance<
  typeof EQHoldingSummaryStore
> => {
  return EQHoldingSummaryStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    portfolioDropdownStore: createPortfolioDropdownStore(),
    totalItems: 0,
    hasError: false,
    isScreenLoading: false,
    isDataFetched: false,
    dashboardStats: createEquityDashboardStatsStore(),
  });
};
