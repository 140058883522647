"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitMFConfirmationUnknownRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SubmitMFConfirmationUnknownRequestRPC {
}
exports.SubmitMFConfirmationUnknownRequestRPC = SubmitMFConfirmationUnknownRequestRPC;
(function (SubmitMFConfirmationUnknownRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SubmitMFConfirmationUnknownRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId, note = null) {
            this.isSubmitMFConfirmationUnknownRequestRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(requestId, note);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(requestId = this.requestId, note = this.note) {
            return new Request(requestId, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSubmitMFConfirmationUnknownRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SubmitMFConfirmationUnknownRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SubmitMFConfirmationUnknownRequestRPC.Request(requestId=${this.requestId},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SubmitMFConfirmationUnknownRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SubmitMFConfirmationUnknownRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `SubmitMFConfirmationUnknownRequestRPC.INVALID_REQUEST_ID()`;
        };
        class IllegalConfirmationRequestState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONFIRMATION_REQUEST_STATE") {
                super(code);
                this.isIllegalConfirmationRequestState = true;
                IllegalConfirmationRequestState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalConfirmationRequestState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalConfirmationRequestState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalConfirmationRequestStateValue = other.isIllegalConfirmationRequestState;
                if (!(other instanceof IllegalConfirmationRequestState || Boolean(isIllegalConfirmationRequestStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalConfirmationRequestState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONFIRMATION_REQUEST_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalConfirmationRequestState = IllegalConfirmationRequestState;
        IllegalConfirmationRequestState.prototype.toString = function toString() {
            return `SubmitMFConfirmationUnknownRequestRPC.ILLEGAL_CONFIRMATION_REQUEST_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "ILLEGAL_CONFIRMATION_REQUEST_STATE":
                    return IllegalConfirmationRequestState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isIllegalConfirmationRequestStateValue = other.isIllegalConfirmationRequestState;
            if (other instanceof IllegalConfirmationRequestState || Boolean(isIllegalConfirmationRequestStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SubmitMFConfirmationUnknownRequestRPC.Errors || (SubmitMFConfirmationUnknownRequestRPC.Errors = {}));
})(SubmitMFConfirmationUnknownRequestRPC = exports.SubmitMFConfirmationUnknownRequestRPC || (exports.SubmitMFConfirmationUnknownRequestRPC = {}));
