import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FileExtension,
  GetFixedDepositHoldingSummaryDownloadURLRPC,
  GetFixedDepositHoldingSummaryReportRPC,
  ModuleType,
  PeriodInput,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  types,
} from "mobx-state-tree";
import {
  useGetFixedDepositHoldingSummaryDownloadURLRPCClientImpl,
  useGetFixedDepositHoldingSummaryReportRPCClientImpl,
} from "../rpcs/RPC";
import { getHoldingSummarySortOrder } from "../utils/SortUtils";
import {
  DateRangePickerInput,
  TableSortOption,
} from "@surya-digital/leo-reactjs-core";
import {
  PortfolioDropdownStore,
  createPortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  EntityDropdownStore,
  createEntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  ReportExportFormatEnum,
  getLeoDateOrNull,
  getDateOrNull,
  checkAllOptionAndGetId,
} from "../../../utils/ReportUtils";
import {
  FdHoldingSummaryModel,
  createFdHoldingSummaryModel,
} from "../models/FdHoldingSummaryResponseModel";

export const FdHoldingSummaryStore = types
  .model("FdHoldingSummaryStore", {
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    hasError: types.boolean,
    totalItems: types.number,
    downloadURL: types.maybe(types.string),
    fdHoldingSummarySummaryList: types.array(FdHoldingSummaryModel),
    fromDate: types.maybe(types.string),
    toDate: types.maybe(types.string),
    isScreenLoading: types.boolean,
    isDataFetched: types.boolean,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    getHoldingSummary: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sort: TableSortOption | undefined,
      period?: PeriodInput.PeriodInput,
    ) {
      store.isScreenLoading = true;
      if (period instanceof PeriodInput.BetweenTwoDates) {
        store.toDate = period.endDate.date;
        store.fromDate = period.startDate.date;
      }
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        yield store.entityDropdownStore.getEntityList();
        yield store.portfolioDropdownStore.getPortfolioList(
          store.entityDropdownStore.entityList[0].id,
          ModuleType.ModuleType.FIXED_DEPOSIT,
        );
        const request = new GetFixedDepositHoldingSummaryReportRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          getLeoDateOrNull(store.fromDate),
          getLeoDateOrNull(store.toDate),
          pageIndex,
          itemsPerPage,
          getHoldingSummarySortOrder(sort),
        );
        const result: LeoRPCResult<
          GetFixedDepositHoldingSummaryReportRPC.Response,
          GetFixedDepositHoldingSummaryReportRPC.Errors.Errors
        > =
          yield useGetFixedDepositHoldingSummaryReportRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.totalItems = response.totalItems;
          store.fromDate = response.fromDate.date;
          store.toDate = response.toDate.date;
          store.fdHoldingSummarySummaryList = cast(
            response.holdingSummaryResponse.map((item) => {
              return createFdHoldingSummaryModel(item);
            }),
          );
          store.isDataFetched = true;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedDepositHoldingSummaryReportRPC`,
          );
        }
        store.isScreenLoading = false;
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in getHoldingSummarySummary`,
          );
        }
        store.isScreenLoading = false;
      }
    }),
    getDownloadURL: flow(function* (fileExtention: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetFixedDepositHoldingSummaryDownloadURLRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          new LeoDate(store.fromDate),
          new LeoDate(store.toDate),
          fileExtention === ReportExportFormatEnum.CSV
            ? FileExtension.FileExtension.CSV
            : FileExtension.FileExtension.XLSX,
        );
        const result: LeoRPCResult<
          GetFixedDepositHoldingSummaryDownloadURLRPC.Response,
          GetFixedDepositHoldingSummaryDownloadURLRPC.Errors.Errors
        > =
          yield useGetFixedDepositHoldingSummaryDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedDepositHoldingSummaryDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedDepositHoldingSummaryDownloadURLRPC`,
          );
        }
      }
    }),
  }))
  .views((store) => ({
    itemsPerPage: (): number => 10,
    getDateRange: (): DateRangePickerInput => {
      return {
        startDate: getDateOrNull(store.fromDate),
        endDate: getDateOrNull(store.toDate),
      };
    },
  }));

export const createFdHoldingSummaryStore = (): Instance<
  typeof FdHoldingSummaryStore
> => {
  return FdHoldingSummaryStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    portfolioDropdownStore: createPortfolioDropdownStore(),
    totalItems: 0,
    hasError: false,
    isScreenLoading: false,
    isDataFetched: false,
  });
};
