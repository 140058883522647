import React, { useMemo } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { BorderStyle, useBorder } from "../../../../utils/BorderUtils";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";

export interface FormGroupProps {
  title: string;
  children: React.ReactElement[];
}

const CreateFormGroupStyle = (
  border: BorderStyle,
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    header: {
      borderBottom: border.default,
      paddingBottom: spacing.spaceXS,
      gap: spacing.spaceXS,
    },
    box: {
      paddingTop: spacing.spaceLG,
    },
  };
};

export const FormGroup = ({
  title,
  children,
}: FormGroupProps): React.ReactElement => {
  const typography = useTypography();
  const border = useBorder();
  const spacing = useSpacing();

  const style = useMemo(() => CreateFormGroupStyle(border, spacing), []);

  return (
    <Stack>
      <Stack sx={style.header}>
        <Typography sx={{ ...typography.s1 }}>{title}</Typography>
      </Stack>
      <Box sx={style.box}>
        <Grid container spacing={spacing.spaceMD}>
          {children.map((item, index) => {
            return (
              <Grid item key={index} xs={item.props.xs ?? 6}>
                {item}
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Stack>
  );
};
