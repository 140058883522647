"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiCashflowsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
class InvalidGetFiCashflowsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiCashflowsPaginationResponseError";
    }
}
class GetFiCashflowsPaginationResponse {
    constructor(fiCashflowId, purchaseTransactionId, cashflowDate, cashflowType, rate, amountCCY, amountHCY, settlementDate = null, settlementBankAccount = null, bankName = null, isCredited, currency) {
        this.isGetFiCashflowsPaginationResponse = true;
        GetFiCashflowsPaginationResponse.validateFiCashflowId(fiCashflowId);
        this.fiCashflowId = fiCashflowId;
        GetFiCashflowsPaginationResponse.validatePurchaseTransactionId(purchaseTransactionId);
        this.purchaseTransactionId = purchaseTransactionId;
        GetFiCashflowsPaginationResponse.validateCashflowDate(cashflowDate);
        this.cashflowDate = cashflowDate;
        GetFiCashflowsPaginationResponse.validateCashflowType(cashflowType);
        this.cashflowType = cashflowType;
        GetFiCashflowsPaginationResponse.validateRate(rate);
        this.rate = rate;
        GetFiCashflowsPaginationResponse.validateAmountCCY(amountCCY);
        this.amountCCY = amountCCY;
        GetFiCashflowsPaginationResponse.validateAmountHCY(amountHCY);
        this.amountHCY = amountHCY;
        if (settlementDate !== undefined && settlementDate !== null) {
            GetFiCashflowsPaginationResponse.validateSettlementDate(settlementDate);
        }
        this.settlementDate = settlementDate;
        if (settlementBankAccount !== undefined && settlementBankAccount !== null) {
            GetFiCashflowsPaginationResponse.validateSettlementBankAccount(settlementBankAccount);
        }
        this.settlementBankAccount = settlementBankAccount;
        if (bankName !== undefined && bankName !== null) {
            GetFiCashflowsPaginationResponse.validateBankName(bankName);
        }
        this.bankName = bankName;
        GetFiCashflowsPaginationResponse.validateIsCredited(isCredited);
        this.isCredited = isCredited;
        GetFiCashflowsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const fiCashflowId = (0, leo_ts_runtime_1.getInt64)(dto, "fiCashflowId", InvalidGetFiCashflowsPaginationResponseError);
        const purchaseTransactionId = (0, leo_ts_runtime_1.getInt64)(dto, "purchaseTransactionId", InvalidGetFiCashflowsPaginationResponseError);
        const cashflowDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "cashflowDate", InvalidGetFiCashflowsPaginationResponseError);
        const cashflowType = (0, leo_ts_runtime_1.getString)(dto, "cashflowType", InvalidGetFiCashflowsPaginationResponseError);
        const rate = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "rate", InvalidGetFiCashflowsPaginationResponseError));
        const amountCCY = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountCCY", InvalidGetFiCashflowsPaginationResponseError));
        const amountHCY = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountHCY", InvalidGetFiCashflowsPaginationResponseError));
        const settlementDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "settlementDate", InvalidGetFiCashflowsPaginationResponseError);
        const settlementBankAccount = (0, leo_ts_runtime_1.getOptionalString)(dto, "settlementBankAccount", InvalidGetFiCashflowsPaginationResponseError);
        const bankName = (0, leo_ts_runtime_1.getOptionalString)(dto, "bankName", InvalidGetFiCashflowsPaginationResponseError);
        const isCredited = (0, leo_ts_runtime_1.getBoolean)(dto, "isCredited", InvalidGetFiCashflowsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetFiCashflowsPaginationResponseError));
        return new GetFiCashflowsPaginationResponse(fiCashflowId, purchaseTransactionId, cashflowDate, cashflowType, rate, amountCCY, amountHCY, settlementDate, settlementBankAccount, bankName, isCredited, currency);
    }
    toDTO() {
        const ret = {
            fiCashflowId: this.fiCashflowId,
            purchaseTransactionId: this.purchaseTransactionId,
            cashflowDate: this.cashflowDate.toDTO(),
            cashflowType: this.cashflowType,
            rate: this.rate.toDTO(),
            amountCCY: this.amountCCY.toDTO(),
            amountHCY: this.amountHCY.toDTO(),
            isCredited: this.isCredited,
            currency: this.currency.toDTO(),
        };
        if (this.settlementDate) {
            ret.settlementDate = this.settlementDate.toDTO();
        }
        if (this.settlementBankAccount !== undefined && this.settlementBankAccount !== null) {
            ret.settlementBankAccount = this.settlementBankAccount;
        }
        if (this.bankName !== undefined && this.bankName !== null) {
            ret.bankName = this.bankName;
        }
        return ret;
    }
    copy(fiCashflowId = this.fiCashflowId, purchaseTransactionId = this.purchaseTransactionId, cashflowDate = this.cashflowDate, cashflowType = this.cashflowType, rate = this.rate, amountCCY = this.amountCCY, amountHCY = this.amountHCY, settlementDate = this.settlementDate, settlementBankAccount = this.settlementBankAccount, bankName = this.bankName, isCredited = this.isCredited, currency = this.currency) {
        return new GetFiCashflowsPaginationResponse(fiCashflowId, purchaseTransactionId, cashflowDate, cashflowType, rate, amountCCY, amountHCY, settlementDate, settlementBankAccount, bankName, isCredited, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiCashflowsPaginationResponseValue = other.isGetFiCashflowsPaginationResponse;
        if (!(other instanceof GetFiCashflowsPaginationResponse) || Boolean(isGetFiCashflowsPaginationResponseValue)) {
            return false;
        }
        if (this.fiCashflowId !== other.fiCashflowId) {
            return false;
        }
        if (this.purchaseTransactionId !== other.purchaseTransactionId) {
            return false;
        }
        if (!this.cashflowDate.equals(other.cashflowDate)) {
            return false;
        }
        if (this.cashflowType !== other.cashflowType) {
            return false;
        }
        if (!this.rate.equals(other.rate)) {
            return false;
        }
        if (!this.amountCCY.equals(other.amountCCY)) {
            return false;
        }
        if (!this.amountHCY.equals(other.amountHCY)) {
            return false;
        }
        if (this.settlementDate) {
            if (!this.settlementDate.equals(other.settlementDate)) {
                return false;
            }
        }
        if (this.settlementBankAccount !== other.settlementBankAccount) {
            return false;
        }
        if (this.bankName !== other.bankName) {
            return false;
        }
        if (this.isCredited !== other.isCredited) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetFiCashflowsPaginationResponse = GetFiCashflowsPaginationResponse;
GetFiCashflowsPaginationResponse.validateFiCashflowId = (fiCashflowId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(fiCashflowId)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute fiCashflowId is not an Int64`);
    }
};
GetFiCashflowsPaginationResponse.validatePurchaseTransactionId = (purchaseTransactionId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(purchaseTransactionId)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute purchaseTransactionId is not an Int64`);
    }
};
GetFiCashflowsPaginationResponse.validateCashflowDate = (cashflowDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(cashflowDate)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute cashflowDate is not a Date`);
    }
};
GetFiCashflowsPaginationResponse.validateCashflowType = (cashflowType) => {
    if (!(0, leo_ts_runtime_1.isString)(cashflowType)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute cashflowType is not a String`);
    }
    if (cashflowType.trim().length === 0) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute cashflowType cannot be an empty string.`);
    }
};
GetFiCashflowsPaginationResponse.validateRate = (rate) => {
    const isBigDecimalValue = rate.isBigDecimal;
    if (!(rate instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute rate is not a BigDecimal`);
    }
};
GetFiCashflowsPaginationResponse.validateAmountCCY = (amountCCY) => {
    const isBigDecimalValue = amountCCY.isBigDecimal;
    if (!(amountCCY instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute amountCCY is not a BigDecimal`);
    }
};
GetFiCashflowsPaginationResponse.validateAmountHCY = (amountHCY) => {
    const isBigDecimalValue = amountHCY.isBigDecimal;
    if (!(amountHCY instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute amountHCY is not a BigDecimal`);
    }
};
GetFiCashflowsPaginationResponse.validateSettlementDate = (settlementDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(settlementDate)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute settlementDate is not a Date`);
    }
};
GetFiCashflowsPaginationResponse.validateSettlementBankAccount = (settlementBankAccount) => {
    if (!(0, leo_ts_runtime_1.isString)(settlementBankAccount)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute settlementBankAccount is not a String`);
    }
    if (settlementBankAccount.trim().length === 0) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute settlementBankAccount cannot be an empty string.`);
    }
};
GetFiCashflowsPaginationResponse.validateBankName = (bankName) => {
    if (!(0, leo_ts_runtime_1.isString)(bankName)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute bankName is not a String`);
    }
    if (bankName.trim().length === 0) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute bankName cannot be an empty string.`);
    }
};
GetFiCashflowsPaginationResponse.validateIsCredited = (isCredited) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isCredited)) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute isCredited is not a Boolean`);
    }
};
GetFiCashflowsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFiCashflowsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFiCashflowsPaginationResponse.prototype.toString = function toString() {
    return `GetFiCashflowsPaginationResponse(fiCashflowId=${this.fiCashflowId},purchaseTransactionId=${this.purchaseTransactionId},cashflowDate=${this.cashflowDate},cashflowType='${this.cashflowType}',rate=${this.rate},amountCCY=${this.amountCCY},amountHCY=${this.amountHCY},settlementDate=${this.settlementDate},settlementBankAccount='${this.settlementBankAccount}',bankName='${this.bankName}',isCredited=${this.isCredited},currency=${this.currency})`;
};
