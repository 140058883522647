import { FixedIncomePurchaseStatus } from "@khazana/khazana-rpcs";
import i18next from "i18next";

export const getStatusString = (
  status: FixedIncomePurchaseStatus.FixedIncomePurchaseStatus | null,
): string | undefined => {
  switch (status) {
    case FixedIncomePurchaseStatus.FixedIncomePurchaseStatus.MATURED:
      return i18next.t("fi.reports.statusLabel.matured");
    case FixedIncomePurchaseStatus.FixedIncomePurchaseStatus.INVESTMENT_OPEN:
      return i18next.t("fi.reports.statusLabel.investmentOpen");
    case FixedIncomePurchaseStatus.FixedIncomePurchaseStatus.PARTIALLY_SOLD:
      return i18next.t("fi.reports.statusLabel.partiallySold");
    case FixedIncomePurchaseStatus.FixedIncomePurchaseStatus.SETTLED:
      return i18next.t("common.settled");
    default:
      return undefined;
  }
};
