import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient, LeoErrors } from "@khazana/khazana-boilerplate";
import { GetPortfolioListRPC, ModuleType } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  createPortfolioModel,
  PortfolioModel,
} from "../../models/PortfolioModel";
import { useGetPortfolioListRPCClientImpl } from "../../rpcs/RPC";
import { AllEnum } from "../../../../types/EnumTypes";
import i18next from "i18next";

export const PortfolioDropdownStore = types
  .model("PortfolioDropdownStore", {
    portfolioList: types.array(PortfolioModel),
    selectedPortfolio: types.optional(PortfolioModel, { id: "", name: "" }),
    isLoading: types.optional(types.boolean, false),
    error: types.maybe(types.string),
  })
  .actions((store) => ({
    setSelectedPortfolio: (
      portfolio: Instance<typeof PortfolioModel> | undefined,
    ): void => {
      store.selectedPortfolio = portfolio ?? { id: "", name: "" };
    },
    setError: (error: string): void => {
      store.error = error;
    },
    getPortfolioList: flow(function* (
      entityId: string,
      moduleType: ModuleType.ModuleType,
    ) {
      if (store.portfolioList.length === 0) {
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.isLoading = true;
        try {
          const request = new GetPortfolioListRPC.Request(
            new LeoUUID(entityId),
            moduleType,
          );
          const result: LeoRPCResult<
            GetPortfolioListRPC.Response,
            GetPortfolioListRPC.Errors.Errors
          > =
            yield useGetPortfolioListRPCClientImpl(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.portfolioList = cast(
              response.portfolios.map((portfolio) =>
                createPortfolioModel(portfolio),
              ),
            );
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetEntityListRPC`,
            );
          }
        } catch (error) {
          if (error instanceof Error) {
            switch (error.name) {
              case LeoErrors.InvalidLeoUUIDError:
                if (entityId === AllEnum.All) {
                  store.selectedPortfolio = {
                    id: AllEnum.All,
                    name: i18next.t("common.all"),
                  };
                }
                logger.error(`Invalid entity id: ${entityId}`);
                break;
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in GetPortfolioListRPC`,
                );
            }
          }
        }
        store.isLoading = false;
      }
    }),
    deselectPortfolio: (): void => {
      store.selectedPortfolio = { id: "", name: "" };
      store.error = undefined;
    },
    clearList: (): void => {
      store.portfolioList = cast([]);
    },
  }));

export const createPortfolioDropdownStore = (): Instance<
  typeof PortfolioDropdownStore
> => {
  return PortfolioDropdownStore.create();
};
