export enum MFDealRequestDetailsKeys {
  requestStatus = "common.requestStatus",
  grossAmount = "mf.fields.grossAmountLabel",
  units = "mf.fields.unitsLabel",
  approxNav = "mf.fields.approxNavLabel",
  transactionType = "common.transactionType",
  schemeName = "mf.fields.schemeNameLabel",
  amcName = "mf.fields.amcNameLabel",
  entity = "common.entity",
  portfolio = "common.portfolio",
  folioNumber = "mf.fields.folioNumberLabel",
  paymentBank = "mf.fields.paymentBankNameLabel",
  paymentBankAccount = "mf.fields.paymentAccountNumberLabel",
  createdAt = "common.createdAt",
  requestId = "common.requestId",
}
