import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import {
  DropdownInputFieldSeparateLabel,
  DropdownItem,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { EntityDropdownStore } from "./EntityDropdownStore";
import { observer } from "mobx-react";
import { AllEnum } from "../../../../types/EnumTypes";

interface EntityDropdownProps {
  store: Instance<typeof EntityDropdownStore>;
  onChange?: () => void;
  width?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  error?: string;
  isAllOptionEnabled?: boolean;
}

export const EntityDropdown = observer(
  ({
    store,
    onChange,
    width = "auto",
    isDisabled = false,
    isRequired = false,
    error,
    isAllOptionEnabled = false,
  }: EntityDropdownProps): React.ReactElement => {
    const { t } = useTranslation();

    const entityList = useMemo((): DropdownItem[] => {
      const list = store.entityList.map((entity) => {
        return { name: entity.name, value: entity.id };
      });
      if (isAllOptionEnabled) {
        list.push({
          name: t("common.all"),
          value: AllEnum.All,
        });
      }
      return list;
    }, [...store.entityList, isAllOptionEnabled]);

    return (
      <Stack>
        <DropdownInputFieldSeparateLabel
          name={"entity"}
          style={{ width }}
          label={t("common.entity")}
          placeholder={t("common.selectEntity")}
          value={store.selectedEntity}
          options={entityList}
          isLoading={store.isLoading}
          onSelect={(entity): void => {
            store.setSelectedEntity(entity.value);
            if (onChange) {
              onChange();
            }
          }}
          error={error !== undefined}
          helperText={error}
          isDisabled={isDisabled}
          isRequired={isRequired}
          loadingText={t("common.loading")}
        />
      </Stack>
    );
  },
);
