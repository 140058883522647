import { Divider, Stack } from "@mui/material";
import {
  isValidDateRange,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import React, { useState } from "react";
import { DatePickerFieldSeparateLabel } from "./DatePickerFieldSeparateLabel";
import { DateRangePickerFieldProps } from "./DateRangePickerField";

/**
 * Creates a DateRangePickerFieldSeparateLabel
 * @param value - Value of the input element, required for a controller component
 * @param onChange - Callback fired when the value is changed
 * @param onError - Callback fired when an error is occurred
 * @param startDateLabel - StartDateField Label of the input
 * @param endDateLabel - EndDateField Label of the input
 * @param minDate - Minimal selectable date
 * @param maxDate - Maximal selectable date
 * @param direction - Sets the flex direction of the field
 * @param showDivider -The divider is made visible, if false the divider is hidden
 * @param divider - ReactElement that is used as a divider.  If this is not provided, default divider will be used and it will not be displayed if direction is column.
 * @param spacing - Sets the spacing between the fields
 * @param locale - Set the locale
 * @param style - Prop of type React.CSSProperties if further customization of password input field is required
 * @param format - String value to format the date. If provided it will override the format specified by the locale.
 * @returns DateRangePickerFieldSeparateLabel component
 */

export const DateRangePickerFieldSeparateLabel = ({
  value,
  onChange,
  onError,
  startDateLabel,
  endDateLabel,
  minDate,
  maxDate,
  direction = "row",
  showDivider,
  divider,
  spacing: fieldSpacing,
  style,
  locale,
  format,
}: DateRangePickerFieldProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();
  const { startDate, endDate } = value;
  const [startDateError, setStartDateError] = useState(false);

  const renderDivider = (): React.ReactElement => {
    if (!showDivider || direction === "column") return <></>;
    return (
      divider ?? (
        <Divider
          orientation="horizontal"
          sx={{
            width: "16px",
            paddingTop: "20px",
            borderColor: tokens.border,
          }}
        />
      )
    );
  };

  return (
    <Stack
      direction={direction}
      spacing={fieldSpacing ?? spacing.spaceMD}
      alignItems="center"
    >
      <DatePickerFieldSeparateLabel
        value={startDate}
        minDate={minDate}
        maxDate={maxDate}
        label={startDateLabel}
        onChange={(newStartDate): void => {
          onChange({ startDate: newStartDate, endDate: null });
        }}
        onError={(error): void => {
          setStartDateError(Boolean(error));
          onError(error, null);
        }}
        style={{ ...style, minWidth: "160px" }} // If width is less than 160 the calendar Icon overlaps with the text field
        locale={locale}
        format={format}
      />
      {renderDivider()}
      <DatePickerFieldSeparateLabel
        error={!Boolean(isValidDateRange(startDate, endDate)) || startDateError}
        isDisabled={!startDate || startDateError}
        minDate={startDate ?? minDate}
        maxDate={maxDate}
        value={endDate}
        label={endDateLabel}
        onChange={(newEndDate): void => {
          // By default when the end date is selected the time is set to 12:00 AM beginning of the day.
          // When the end date is selected we set the time to 23:59 PM end the day i.e 23 hour, 59 minute, 59 second, 999 millisecond.
          newEndDate?.setHours(23, 59, 59, 999);
          onChange({ startDate, endDate: newEndDate });
        }}
        onError={(error): void => {
          onError(null, error);
        }}
        style={{ ...style, minWidth: "160px" }} // If width is less than 160 the calendar Icon overlaps with the text field
        locale={locale}
        format={format}
      />
    </Stack>
  );
};
