"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFScheme = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
class InvalidMFSchemeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFSchemeError";
    }
}
class MFScheme {
    constructor(isin, name, currency, amcId) {
        this.isMFScheme = true;
        MFScheme.validateIsin(isin);
        this.isin = isin;
        MFScheme.validateName(name);
        this.name = name;
        MFScheme.validateCurrency(currency);
        this.currency = currency;
        MFScheme.validateAmcId(amcId);
        this.amcId = amcId;
    }
    static fromDTO(dto) {
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidMFSchemeError));
        const name = (0, leo_ts_runtime_1.getString)(dto, "name", InvalidMFSchemeError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidMFSchemeError));
        const amcId = (0, leo_ts_runtime_1.getInt64)(dto, "amcId", InvalidMFSchemeError);
        return new MFScheme(isin, name, currency, amcId);
    }
    toDTO() {
        const ret = {
            isin: this.isin.toDTO(),
            name: this.name,
            currency: this.currency.toDTO(),
            amcId: this.amcId,
        };
        return ret;
    }
    copy(isin = this.isin, name = this.name, currency = this.currency, amcId = this.amcId) {
        return new MFScheme(isin, name, currency, amcId);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFSchemeValue = other.isMFScheme;
        if (!(other instanceof MFScheme) || Boolean(isMFSchemeValue)) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.name !== other.name) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        if (this.amcId !== other.amcId) {
            return false;
        }
        return true;
    }
}
exports.MFScheme = MFScheme;
MFScheme.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidMFSchemeError(`Attribute isin is not a ISIN`);
    }
};
MFScheme.validateName = (name) => {
    if (!(0, leo_ts_runtime_1.isString)(name)) {
        throw new InvalidMFSchemeError(`Attribute name is not a String`);
    }
    if (name.trim().length === 0) {
        throw new InvalidMFSchemeError(`Attribute name cannot be an empty string.`);
    }
};
MFScheme.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidMFSchemeError(`Attribute currency is not a Currency`);
    }
};
MFScheme.validateAmcId = (amcId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amcId)) {
        throw new InvalidMFSchemeError(`Attribute amcId is not an Int64`);
    }
};
MFScheme.prototype.toString = function toString() {
    return `MFScheme(isin=${this.isin},name='${this.name}',currency=${this.currency},amcId=${this.amcId})`;
};
