import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createDepositBankDropdownStore,
  DepositBankDropdownStore,
} from "../../../store/DepositBankDropdownStore";
import {
  BankAccountDropdownStore,
  createBankAccountDropdownStore,
} from "../../../store/BankAccountDropdownStore";
import {
  createFiMaturityChargeModel,
  createRPCFiMaturityChargeType,
  FiMaturityChargeModel,
} from "../models/FiMaturityChargeModel";
import { getAPIClient, LeoErrors } from "@khazana/khazana-boilerplate";
import {
  BigDecimal,
  DetailCellType,
  GetFiMaturityTransactionDetailsRPC,
  Note,
  SettleFiMaturityRequestRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useGetFiMaturityTransactionDetailsRPC,
  useSettleFiMaturityRequestRPC,
} from "../rpcs/RPC";
import { getLeoDate } from "../../../../../utils";
import {
  createFiMaturityTransactionDetails,
  FiMaturityTransactionDetails,
} from "../models/FiMaturityTransactionDetails";
import { DatePickerInput } from "@surya-digital/leo-reactjs-material-ui";
import { DetailType } from "../../../models/DetailCellModel";

export enum FiMaturitySettleRequestError {
  InvalidRequestId = "INVALID_REQUEST_ID",
}

export const FiMaturityChargeError = types.model({
  chargeType: types.maybe(types.string),
  error: types.maybe(types.string),
});
export const FiMaturityStoreErrors = types.model({
  screenError: types.maybe(
    types.enumeration<FiMaturitySettleRequestError>(
      "FiMaturitySettleRequestError",
      Object.values(FiMaturitySettleRequestError),
    ),
  ),
  maturitySettlementDateError: types.maybe(types.string),
  couponReceivedOnMaturityError: types.maybe(types.string),
  chargesError: types.array(FiMaturityChargeError),
});

export const FiMaturitySettleStore = types
  .model("FiMaturitySettleStore", {
    settlementReferenceNumber: types.maybe(types.string),
    maturitySettlementDate: types.maybe(types.Date),
    couponReceivedOnMaturity: types.maybe(types.string),
    charges: types.array(FiMaturityChargeModel),
    originalBank: types.maybe(types.string),
    recipientBank: DepositBankDropdownStore,
    originalAccount: types.maybe(types.string),
    recipientAccount: BankAccountDropdownStore,
    transactionDetails: types.maybe(FiMaturityTransactionDetails),
    errors: FiMaturityStoreErrors,
    isBankChanged: types.optional(types.boolean, false),
    isTransactionDetailsLoading: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => {
    return {
      validateForm(): boolean {
        let isValid = true;
        if (!store.maturitySettlementDate) {
          store.errors.maturitySettlementDateError = "";
          isValid = false;
        }
        if (!Number(store.couponReceivedOnMaturity)) {
          store.errors.couponReceivedOnMaturityError = "";
          isValid = false;
        }
        store.charges.forEach((charge) => {
          if (!Number(charge.amount)) {
            const chargeError = store.errors.chargesError.find(
              (error) => error.chargeType === charge.chargeType,
            );
            if (chargeError) {
              chargeError.error = "";
            } else {
              store.errors.chargesError.push(
                FiMaturityChargeError.create({
                  chargeType: charge.chargeType,
                  error: "",
                }),
              );
            }
            isValid = false;
          }
        });
        if (!store.recipientBank.selectedBankId) {
          store.recipientBank.error = "";
          isValid = false;
        }
        if (!store.recipientAccount.selectedBankAccount) {
          store.recipientAccount.error = "";
          isValid = false;
        }
        return isValid;
      },
    };
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setSettlementReferenceNumber: (
        settlementReferenceNumber: string,
      ): void => {
        store.settlementReferenceNumber = settlementReferenceNumber;
      },
      setMaturitySettlementDate: (
        maturitySettlementDate: DatePickerInput,
      ): void => {
        store.errors.maturitySettlementDateError = undefined;
        store.maturitySettlementDate = maturitySettlementDate
          ? (maturitySettlementDate as Date)
          : undefined;
      },
      setCoupon: (couponReceivedOnMaturity: string): void => {
        store.errors.couponReceivedOnMaturityError = undefined;
        store.couponReceivedOnMaturity = couponReceivedOnMaturity;
      },
      setCharge: (chargeType: string, amount: string): void => {
        const chargesError = store.errors.chargesError.filter(
          (error) => error.chargeType !== chargeType,
        );
        store.errors.chargesError = cast(
          chargesError.map((error) =>
            FiMaturityChargeError.create({
              chargeType: error.chargeType,
              error: error.error,
            }),
          ),
        );
        store.charges = cast(
          store.charges.map((charge) => {
            if (charge.chargeType === chargeType) {
              charge.amount = amount;
            }
            return charge;
          }),
        );
      },
      checkBankChange: (): void => {
        if (store.originalBank !== store.recipientBank.selectedBankId) {
          store.isBankChanged = true;
          return;
        }
        if (
          store.originalAccount !== store.recipientAccount.selectedBankAccount
        ) {
          store.isBankChanged = true;
          return;
        }
        store.isBankChanged = false;
      },
      setBankDetails: flow(function* (
        details: Instance<typeof DetailType>[] | null,
      ) {
        if (details) {
          const bankId = details.find((item) => {
            return (
              item.label === "fi.maturityRequestDetails.recipientBankLabel"
            );
          })?.cellType as DetailCellType.Unstyled;
          store.recipientBank.setSelectedBank(bankId?.text ?? undefined);
          if (bankId?.text) {
            yield store.recipientAccount.getAccountList(bankId?.text);
          }
          const bankAccount = details.find((item) => {
            return (
              item.label ===
              "fi.maturityRequestDetails.recipientBankAccountLabel"
            );
          })?.cellType as DetailCellType.Unstyled;
          store.recipientAccount.setSelectedAccount(
            bankAccount?.text ?? undefined,
          );
        }
      }),
      settleFiMaturityRequest: flow(function* (
        requestId: number,
        note?: string,
      ) {
        if (
          store.validateForm() &&
          store.maturitySettlementDate &&
          store.couponReceivedOnMaturity &&
          store.recipientBank.selectedBankId &&
          store.recipientAccount.selectedBankAccount
        ) {
          const logger = getEnv(store).logger;
          const apiClient = getAPIClient(store);
          const request = new SettleFiMaturityRequestRPC.Request(
            requestId,
            store.settlementReferenceNumber,
            getLeoDate(store.maturitySettlementDate),
            new BigDecimal(store.couponReceivedOnMaturity),
            store.charges.map((charge) =>
              createRPCFiMaturityChargeType(charge),
            ),
            new LeoUUID(store.recipientBank.selectedBankId),
            store.recipientAccount.selectedBankAccount.bankAccountNumber,
            note ? new Note(note) : null,
          );
          store.isLoading = true;
          const result: LeoRPCResult<
            SettleFiMaturityRequestRPC.Response,
            SettleFiMaturityRequestRPC.Errors.Errors
          > = yield useSettleFiMaturityRequestRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            return;
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              case LeoErrors.InvalidLeoUUIDError:
                store.errors.screenError =
                  FiMaturitySettleRequestError.InvalidRequestId;
                break;
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in settleFiMaturityRequestRPC`,
                );
            }
          } else {
            logger.error("Unhandled error");
          }
        }
        store.isLoading = false;
      }),
      getFiMaturityTransactionDetails: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetFiMaturityTransactionDetailsRPC.Request(
          requestId,
          store.couponReceivedOnMaturity
            ? new BigDecimal(store.couponReceivedOnMaturity)
            : null,
          store.charges.map((charge) => createRPCFiMaturityChargeType(charge)),
        );
        store.isTransactionDetailsLoading = true;
        const result: LeoRPCResult<
          GetFiMaturityTransactionDetailsRPC.Response,
          GetFiMaturityTransactionDetailsRPC.Errors.Errors
        > =
          yield useGetFiMaturityTransactionDetailsRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.transactionDetails =
            createFiMaturityTransactionDetails(response);
          if (store.charges.length === 0) {
            store.charges = cast(
              response.charges.map((charge) =>
                createFiMaturityChargeModel(charge),
              ),
            );
          }
          if (!store.couponReceivedOnMaturity) {
            store.couponReceivedOnMaturity =
              response.couponReceivedOnMaturity.decimalValue;
          }
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case LeoErrors.InvalidLeoUUIDError:
              store.errors.screenError =
                FiMaturitySettleRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getFiMaturityTransactionDetailsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
        store.isTransactionDetailsLoading = false;
      }),
    };
  });

export const createFiMaturitySettleStore = (): Instance<
  typeof FiMaturitySettleStore
> => {
  return FiMaturitySettleStore.create({
    recipientBank: createDepositBankDropdownStore(),
    recipientAccount: createBankAccountDropdownStore(),
    errors: FiMaturityStoreErrors.create({}),
    maturitySettlementDate: new Date(),
  });
};
