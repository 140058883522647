import {
  FiContractNoteDetail,
  FiContractNoteRequestStatus,
  FiTransactionType,
} from "@khazana/khazana-rpcs";
import {
  ChipColor,
  DetailRowCellEnum,
  DetailRowCellType,
} from "@surya-digital/leo-reactjs-core";
import {
  getFormattedAmount,
  getFormattedAmountString,
  getFormattedDate,
} from "../../../../../utils";
import { FiContractNoteFields } from "../models/FiContractNoteEditModel";
import AlertCircle from "../../../../../assets/alert-circle.svg";
import { getFiTransactionText } from "../../deal-request/utils/UIUtils";

export const getFiContractNoteStatusBackgroundColor = (
  status: FiContractNoteRequestStatus.FiContractNoteRequestStatus,
): ChipColor => {
  switch (status) {
    case FiContractNoteRequestStatus.FiContractNoteRequestStatus.DISCARDED:
    case FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNKNOWN:
      return "red";
    case FiContractNoteRequestStatus.FiContractNoteRequestStatus.LINKED:
      return "green";
    default:
      return "orange";
  }
};

const getChipRow = (
  text: string,
  diffText: string | undefined,
): DetailRowCellType => {
  return {
    type: DetailRowCellEnum.Chip,
    chips: [
      {
        label: getFiTransactionText(text),
        color:
          text === FiTransactionType.FiTransactionType.FI_PURCHASE
            ? "blueSubtle"
            : "orangeSubtle",
      },
    ],
    updatedChips: diffText
      ? [
          {
            label: getFiTransactionText(diffText),
            color:
              diffText === FiTransactionType.FiTransactionType.FI_PURCHASE
                ? "blueSubtle"
                : "orangeSubtle",
          },
        ]
      : undefined,
  };
};

const getPrefixIconRow = (text: string): DetailRowCellType => {
  return {
    text,
    type: DetailRowCellEnum.PrefixIcon,
    iconPath: AlertCircle,
  };
};

const getUnstyledRow = (
  text: string,
  diffText: string | undefined,
): DetailRowCellType => {
  return {
    text,
    type: DetailRowCellEnum.Unstyled,
    diffText,
  };
};

export const getCell = (
  text: string,
  diffText: string | undefined,
  hasError: boolean,
): DetailRowCellType => {
  if (hasError) {
    return getPrefixIconRow(text);
  } else {
    return getUnstyledRow(text, diffText);
  }
};

export const fiContractNotetransformCell = (
  key: string,
  cellType: FiContractNoteDetail.FiContractNoteDetail,
  diff: FiContractNoteDetail.FiContractNoteDetail | null,
): DetailRowCellType | undefined => {
  let diffText: string | number | null = null;
  if (diff instanceof FiContractNoteDetail.Error) {
    diffText = diff.errorMessage;
  }
  const hasError = diffText !== null;
  if (cellType instanceof FiContractNoteDetail.Amount) {
    const diffT = diff
      ? (diff as FiContractNoteDetail.Amount).amount ?? 0
      : null;
    diffText = diffText ?? diffT;
    let maximumFractionDigits = 2;
    if (
      key === FiContractNoteFields.rate ||
      key === FiContractNoteFields.couponRate ||
      key === FiContractNoteFields.faceValuePerUnit
    ) {
      maximumFractionDigits = 4;
    }
    let minimumFractionDigits = 2;
    if (
      key === FiContractNoteFields.rate ||
      key === FiContractNoteFields.faceValuePerUnit
    ) {
      minimumFractionDigits = 4;
    }
    return getCell(
      cellType.amount
        ? getFormattedAmount(
            cellType.amount,
            maximumFractionDigits,
            minimumFractionDigits,
          )
        : "-",
      diffText !== null
        ? getFormattedAmount(
            diffText as number,
            maximumFractionDigits,
            minimumFractionDigits,
          )
        : undefined,
      hasError,
    );
  } else if (cellType instanceof FiContractNoteDetail.Percentage) {
    diffText =
      diffText ??
      (diff ? (diff as FiContractNoteDetail.Percentage).percentage : null);
    return getCell(
      cellType.percentage !== undefined && cellType.percentage !== null
        ? getFormattedAmount(cellType.percentage, 4, 2)
        : "-",
      diffText
        ? getFormattedAmount(parseFloat(diffText.toString()), 4, 2)
        : undefined,
      hasError,
    );
  } else if (cellType instanceof FiContractNoteDetail.DateType) {
    diffText =
      diffText ??
      (diff ? (diff as FiContractNoteDetail.DateType).date?.date ?? "-" : null);
    return getCell(
      cellType.date?.date
        ? getFormattedDate(new Date(cellType.date.date))
        : "-",
      diffText ? diffText.toString() : undefined,
      hasError,
    );
  } else if (cellType instanceof FiContractNoteDetail.QuantityType) {
    diffText =
      diffText ??
      (diff
        ? (diff as FiContractNoteDetail.QuantityType).quantity?.quantity ?? null
        : null);
    return getCell(
      getFormattedAmountString(cellType.quantity?.quantity ?? 0),
      diffText ? getFormattedAmountString(diffText as number) : undefined,
      hasError,
    );
  } else if (cellType instanceof FiContractNoteDetail.Unstyled) {
    diffText = diffText ?? (diff as FiContractNoteDetail.Unstyled)?.text;
    return getCell(cellType.text ?? "-", diffText?.toString() ?? "", hasError);
  } else if (cellType instanceof FiContractNoteDetail.TransactionType) {
    diffText =
      diffText ??
      (diff as FiContractNoteDetail.TransactionType)?.transactionType;
    return getChipRow(cellType.transactionType, diffText?.toString());
  }
};
