import { Instance, types } from "mobx-state-tree";
import { MFSchemeDetails } from "@khazana/khazana-rpcs";

export const SchemeDetailsModel = types.model("SchemeDetailsModel", {
  isin: types.maybe(types.string),
  amc: types.maybe(types.string),
  schemeType: types.maybe(types.string),
  planType: types.maybe(types.string),
  dividendType: types.maybe(types.string),
});

export const createSchemeDetailsModel = (
  details: MFSchemeDetails,
): Instance<typeof SchemeDetailsModel> => {
  return SchemeDetailsModel.create({
    isin: details.isin.isin,
    amc: details.amc,
    schemeType: details.schemeType,
    planType: details.planType,
    dividendType: details.dividendType,
  });
};
