import {
  Currency,
  DashboardStat,
  GetEquityGrandTotalChangeInGainRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityGrandTotalChangeInGainRPC extends GetEquityGrandTotalChangeInGainRPC {
  execute(
    _request: GetEquityGrandTotalChangeInGainRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityGrandTotalChangeInGainRPC.Response,
      GetEquityGrandTotalChangeInGainRPC.Errors.Errors
    >
  > {
    const response = new GetEquityGrandTotalChangeInGainRPC.Response(
      new DashboardStat(71589190500000, new Currency("INR", "₹"), null, null),
      new DashboardStat(72043690500000, new Currency("INR", "₹"), true, null),
      new DashboardStat(4545000000, new Currency("INR", "₹"), true, null),
      new DashboardStat(4545000000, new Currency("INR", "₹"), null, null),
      new DashboardStat(120000, null, true, null),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityGrandTotalChangeInGainRPC.Response,
        GetEquityGrandTotalChangeInGainRPC.Errors.Errors
      >
    >;
  }
}
