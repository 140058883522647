import { UserLocalStorageKeys } from "./UserLocalStorageKeys";

export enum SettingsPrivileges {
  // Settings Privileges
  ManageEntity = "MANAGE_ENTITY",
  ManagePortfolio = "MANAGE_PORTFOLIO",
}

export enum EquityUserPrivileges {
  // Equity Deal Request
  CreateEquityDealRequest = "CREATE_EQUITY_DEAL_REQUEST",
  BuyEquityDeal = "BUY_EQUITY_DEAL",
  SellEquityDeal = "SELL_EQUITY_DEAL",

  ViewEquityDealRequest = "VIEW_EQUITY_DEAL_REQUEST",
  CancelEquityDealRequest = "CANCEL_EQUITY_DEAL_REQUEST",
  ViewEquityImpactOnHoldingsDisplaySection = "VIEW_EQUITY_IMPACT_ON_HOLDINGS_DISPLAY_SECTION",
  CheckEquityImpactDealRequest = "CHECK_EQUITY_IMPACT_DEAL_REQUEST",
  ViewEquityHistoricalIsinData = "VIEW_EQUITY_HISTORICAL_ISIN_DATA",

  ViewEquityAvailableBalanceDisplaySection = "VIEW_EQUITY_AVAILABLE_BALANCE_DISPLAY_SECTION",
  ViewEquityTransactionDetailsDisplaySection = "VIEW_EQUITY_TRANSACTION_DETAILS_DISPLAY_SECTION",
  CheckEquityTransactionDetailsForDealRequest = "CHECK_EQUITY_TRANSACTION_DETAILS_FOR_DEAL_REQUEST",

  CreateEquityBrokerEntryInDealRequest = "CREATE_EQUITY_BROKER_ENTRY_IN_DEAL_REQUEST",
  EditEquityBrokerEntryInDealRequest = "EDIT_EQUITY_BROKER_ENTRY_IN_DEAL_REQUEST",
  DeleteEquityBrokerEntryInDealRequest = "DELETE_EQUITY_BROKER_ENTRY_IN_DEAL_REQUEST",
  SettleEquityDealRequest = "SETTLE_EQUITY_DEAL_REQUEST",

  // Equity Contract Note
  UploadEquityContractNote = "UPLOAD_EQUITY_CONTRACT_NOTE",
  ViewEquityContractNote = "VIEW_EQUITY_CONTRACT_NOTE",
  ViewEquityDetails = "VIEW_EQUITY_DETAILS",
  EditEquityContractNote = "EDIT_EQUITY_CONTRACT_NOTE",
  CreateEquityContractNoteReviewRequest = "CREATE_EQUITY_CONTRACT_NOTE_REVIEW_REQUEST",
  CreateEquityContractNoteDiscardRequest = "CREATE_EQUITY_CONTRACT_NOTE_DISCARD_REQUEST",
  CreateEquityContractNoteUnknownRequest = "CREATE_EQUITY_CONTRACT_NOTE_UNKNOWN_REQUEST",
  ViewEquityContractNoteLinkedDealRequestDisplaySection = "VIEW_EQUITY_CONTRACT_NOTE_LINKED_DEAL_REQUEST_DISPLAY_SECTION",
  CheckEquityContractNote = "CHECK_EQUITY_CONTRACT_NOTE",
  CreateEquityContractNoteLinkRequest = "CREATE_EQUITY_CONTRACT_NOTE_LINK_REQUEST",
  CheckEquityContractNoteLinkRequest = "CHECK_EQUITY_CONTRACT_NOTE_LINK_REQUEST",
  CheckEquityContractNoteUnknownRequest = "CHECK_EQUITY_CONTRACT_NOTE_UNKNOWN_REQUEST",
  CheckEquityContractNoteDiscardRequest = "CHECK_EQUITY_CONTRACT_NOTE_DISCARD_REQUEST",

  // Equity Reports
  ViewEquityReports = "VIEW_EQUITY_REPORTS",
  ViewEquityChangeInGainReport = "VIEW_EQUITY_CHANGE_IN_GAIN_REPORT",
  ViewEquityHoldingSummary = "VIEW_EQUITY_HOLDING_SUMMARY",
  DownloadEquityHoldingSummary = "DOWNLOAD_EQUITY_HOLDING_SUMMARY",
  DownloadEquityChangeInGainReport = "DOWNLOAD_EQUITY_CHANGE_IN_GAIN_REPORT",

  // Equity Dashboard
  ViewEquityDashboard = "VIEW_EQUITY_DASHBOARD",
}

export enum FiUserPrivileges {
  CreateFixedIncomeDealRequest = "CREATE_FIXED_INCOME_DEAL_REQUEST",
  SellFixedIncomeDeal = "SELL_FIXED_INCOME_DEAL",
  BuyFixedIncomeDeal = "BUY_FIXED_INCOME_DEAL",
  ViewFixedIncomeContractNote = "VIEW_FIXED_INCOME_CONTRACT_NOTE",
  ViewFixedIncomeDealRequest = "VIEW_FIXED_INCOME_DEAL_REQUEST",
  ViewFixedIncomeMaturityDealRequest = "VIEW_FIXED_INCOME_MATURITY_DEAL_REQUEST",
  UploadFixedIncomeContractNote = "UPLOAD_FIXED_INCOME_CONTRACT_NOTE",
  CheckFixedIncomeTransactionDetailsForDealRequest = "CHECK_FIXED_INCOME_TRANSACTION_DETAILS_FOR_DEAL_REQUEST",
  CheckFixedIncomeImpactDealRequest = "CHECK_FIXED_INCOME_IMPACT_DEAL_REQUEST",
  CreateFixedIncomeContractNoteReviewRequest = "CREATE_FIXED_INCOME_CONTRACT_NOTE_REVIEW_REQUEST",
  EditFixedIncomeContractNote = "EDIT_FIXED_INCOME_CONTRACT_NOTE",
  CheckFixedIncomeContractNote = "CHECK_FIXED_INCOME_CONTRACT_NOTE",
  ModifyFixedIncomeBrokerEntryInDealRequest = "MODIFY_FIXED_INCOME_BROKER_ENTRY_IN_DEAL_REQUEST",
  CreateFixedIncomeContractNoteLinkRequest = "CREATE_FIXED_INCOME_CONTRACT_NOTE_LINK_REQUEST",
  CheckFixedIncomeContractNoteLinkRequest = "CHECK_FIXED_INCOME_CONTRACT_NOTE_LINK_REQUEST",
  SettleFixedIncomeDealRequest = "SETTLE_FIXED_INCOME_DEAL_REQUEST",
  SettleFixedIncomeMaturityDealRequest = "SETTLE_FIXED_INCOME_MATURITY_DEAL_REQUEST",
  CreateFixedIncomeContractNoteUnknownRequest = "CREATE_FIXED_INCOME_CONTRACT_NOTE_UNKNOWN_REQUEST",
  CreateFixedIncomeContractNoteDiscardRequest = "CREATE_FIXED_INCOME_CONTRACT_NOTE_DISCARD_REQUEST",
  ViewFixedIncomeDetails = "VIEW_FIXED_INCOME_DETAILS",
  ViewFixedIncomeCashFlow = "VIEW_FIXED_INCOME_CASH_FLOW",
  EditFixedIncomeAccrual = "EDIT_FIXED_INCOME_ACCRUAL",
  SettleFixedIncomeCashFlow = "SETTLE_FIXED_INCOME_CASH_FLOW",

  // Fixed Income Reports
  ViewFixedIncomeReports = "VIEW_FIXED_INCOME_REPORTS",
  ViewFixedIncomeInvestmentRegister = "VIEW_FIXED_INCOME_INVESTMENT_REGISTER",
  DownloadFixedIncomeInvestmentRegister = "DOWNLOAD_FIXED_INCOME_INVESTMENT_REGISTER",
  ViewFixedIncomeHoldingSummary = "VIEW_FIXED_INCOME_HOLDING_SUMMARY",
}

export enum FdUserPrivileges {
  CreateFixedDepositDealRequest = "CREATE_FIXED_DEPOSIT_DEAL_REQUEST",
  ViewFixedDepositDealRequest = "VIEW_FIXED_DEPOSIT_DEAL_REQUEST",
  ViewFixedDepositCertificate = "VIEW_FIXED_DEPOSIT_CERTIFICATE",
  CheckFixedDepositCertificateRequest = "CHECK_FIXED_DEPOSIT_CERTIFICATE_REQUEST",
  CheckFixedDepositDealRequest = "CHECK_FIXED_DEPOSIT_DEAL_REQUEST",
  EditFixedDepositCertificate = "EDIT_FIXED_DEPOSIT_CERTIFICATE",
  CreateFixedDepositCertificate = "CREATE_FIXED_DEPOSIT_WITHDRAW_REQUEST",
  ViewFixedDepositWithdrawCertificate = "VIEW_FIXED_DEPOSIT_WITHDRAW_REQUEST",
  ViewFixedDepositCashFlow = "VIEW_FIXED_DEPOSIT_CASH_FLOW",
  EditFixedDepositAccrual = "EDIT_FIXED_DEPOSIT_ACCRUAL",
  SettleFixedDepositCashFlow = "SETTLE_FIXED_DEPOSIT_CASH_FLOW",
  // Fixed Deposit Reports
  ViewFixedDepositReports = "VIEW_FIXED_DEPOSIT_REPORTS",
  ViewFixedDepositInvestmentRegister = "VIEW_FIXED_DEPOSIT_INVESTMENT_REGISTER",
  DownloadFixedDepositInvestmentRegister = "DOWNLOAD_FIXED_DEPOSIT_INVESTMENT_REGISTER",
  ViewFixedDepositHoldingSummary = "VIEW_FIXED_DEPOSIT_HOLDING_SUMMARY",
  DownloadFixedDepositHoldingSummary = "DOWNLOAD_FIXED_DEPOSIT_HOLDING_SUMMARY",
}

export enum MFUserPrivileges {
  CreateMutualFundDealRequest = "CREATE_MUTUAL_FUND_DEAL_REQUEST",
  ViewMutualFundDealRequest = "VIEW_MUTUAL_FUND_DEAL_REQUEST",
  CheckMutualFundDealRequest = "CHECK_MUTUAL_FUND_DEAL_REQUEST",
  ViewMutualFundConfirmationRequest = "VIEW_MUTUAL_FUND_CONFIRMATION_REQUEST",
  CreateMutualFundConfirmationRequest = "CREATE_MUTUAL_FUND_CONFIRMATION_REQUEST",
  // Mutual Fund Reports
  ViewMutualFundReports = "VIEW_MUTUAL_FUND_REPORTS",
  DownloadMutualFundHoldingSummary = "DOWNLOAD_MUTUAL_FUND_HOLDING_SUMMARY",
  ViewMutualFundHoldingSummary = "VIEW_MUTUAL_FUND_HOLDING_SUMMARY",
  DownloadMutualFundInvestmentReturnsGrowth = "DOWNLOAD_MUTUAL_FUND_INVESTMENT_RETURNS_GROWTH",
  ViewMutualFundInvestmentReturnsGrowth = "VIEW_MUTUAL_FUND_INVESTMENT_RETURNS_GROWTH",
}

// This function should only be used by Root-Store and User-Store.
// Root store should use the persisted privileges to check if the user is logged in or not.
// User store should use the persisted value to start its initial value.
export const getPersistedUserPrivileges = (): string[] => {
  const privilegeString = localStorage.getItem(UserLocalStorageKeys.privileges);
  if (privilegeString) {
    return privilegeString.split(",");
  }
  return [];
};

export const removePersistedUserPrivileges = (): void => {
  localStorage.removeItem(UserLocalStorageKeys.privileges);
  localStorage.removeItem(UserLocalStorageKeys.userId);
};
