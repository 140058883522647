import { SettleMFDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSettleMFDealRequestRPCImpl extends SettleMFDealRequestRPC {
  execute(
    _request: SettleMFDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SettleMFDealRequestRPC.Response,
      SettleMFDealRequestRPC.Errors.Errors
    >
  > {
    const response = new SettleMFDealRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SettleMFDealRequestRPC.Response,
        SettleMFDealRequestRPC.Errors.Errors
      >
    >;
  }
}
