import { GetFiDealRequestHistoryRPC } from "@khazana/khazana-rpcs";
import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { ViewFiDealInvalidRequestError } from "./ViewFiDealRequestDetailsError";
import {
  FiDealRequestHistoryDetailModel,
  createFiDealRequestHistoryDetailModel,
} from "../models/FiDealRequestHistoryDetailModel";
import { useGetFiDealRequestHistoryRPCClientImpl } from "../rpcs/RPC";

export const ViewFiDealRequestHistoryStore = types
  .model("CheckFiDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<ViewFiDealInvalidRequestError>(
        "ViewFiDealInvalidRequestError",
        Object.values(ViewFiDealInvalidRequestError),
      ),
    ),
    fiDealRequestHistoryDetail: types.maybeNull(
      types.array(FiDealRequestHistoryDetailModel),
    ),
  })
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.fiDealRequestHistoryDetail = null;
    },
    getFiDealRequestHistory: flow(function* (requestId: number) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiDealRequestHistoryRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFiDealRequestHistoryRPC.Response,
          GetFiDealRequestHistoryRPC.Errors.Errors
        > =
          yield useGetFiDealRequestHistoryRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fiDealRequestHistoryDetail = cast(
            response.requestDetails.map((detail) =>
              createFiDealRequestHistoryDetailModel(detail),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiDealInvalidRequestError.InvalidRequestId:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiDealRequestDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiDealRequestHistoryRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiDealRequestHistoryRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiDealRequestHistoryRPC`,
          );
        }
      }
    }),
  }));

export const createViewFiDealRequestHistoryStore = (): Instance<
  typeof ViewFiDealRequestHistoryStore
> => {
  return ViewFiDealRequestHistoryStore.create();
};
