import {
  FixedDepositHoldingSummaryResponse,
  GetFixedDepositHoldingSummaryReportRPC,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFixedDepositHoldingSummaryReportRPC extends GetFixedDepositHoldingSummaryReportRPC {
  execute(
    _request: GetFixedDepositHoldingSummaryReportRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFixedDepositHoldingSummaryReportRPC.Response,
      GetFixedDepositHoldingSummaryReportRPC.Errors.Errors
    >
  > {
    const response = new GetFixedDepositHoldingSummaryReportRPC.Response(
      new LeoDate(),
      new LeoDate(),
      [new FixedDepositHoldingSummaryResponse("HDFC")],
      10,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFixedDepositHoldingSummaryReportRPC.Response,
        GetFixedDepositHoldingSummaryReportRPC.Errors.Errors
      >
    >;
  }
}
