import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetBankListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetBankListRPC } from "../fixed-deposit/deal-request/rpc/RPC";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";

export const Bank = types.model("Bank", {
  id: types.string,
  name: types.string,
});

export const DepositBankDropdownStore = types
  .model("DepositBankDropdownStore", {
    bankList: types.array(Bank),
    selectedBankId: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
    error: types.maybe(types.string),
  })
  .actions((store) => ({
    setSelectedBank: (bankId: string | undefined): void => {
      store.selectedBankId = bankId;
      store.error = undefined;
    },
    setisLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    setError: (error: string | undefined): void => {
      store.error = error;
    },
    getBankList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.isLoading = true;
      const request = new GetBankListRPC.Request();
      const result: LeoRPCResult<
        GetBankListRPC.Response,
        GetBankListRPC.Errors.Errors
      > = yield useGetBankListRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.bankList = cast(
          response.banks.map((bank) =>
            Bank.create({
              id: bank.id.uuid,
              name: bank.name,
            }),
          ),
        );
      } else {
        logger.error("unable to fetch bank list");
      }
      store.isLoading = false;
    }),
  }))
  .views((store) => ({
    get selectedBankAutocompleteItem(): AutoCompleteItem | undefined {
      if (store.selectedBankId) {
        const selectedBank = store.bankList.find(
          (bank) => bank.id === store.selectedBankId,
        );
        if (selectedBank) {
          return {
            id: selectedBank.id,
            label: selectedBank.name,
          };
        }
      }
      return undefined;
    },
  }));

export const createDepositBankDropdownStore = (
  selectedId?: string,
): Instance<typeof DepositBankDropdownStore> => {
  return DepositBankDropdownStore.create({
    selectedBankId: selectedId,
  });
};
