import React, { RefObject } from "react";
import { useBorder } from "../../../../utils/BorderUtils";
import {
  Button,
  spacing,
  Table,
  TableOptions,
  TableReloadHandle,
  TableRowItem,
  TableRowItems,
  TableSortOption,
  useCornerRadius,
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack, Typography } from "@mui/material";
import { TableHeader } from "@surya-digital/leo-reactjs-core/dist/table/Table";
import { useTranslation } from "react-i18next";
import { getFormattedAmount } from "../../../../utils";

const Size = {
  width: "1120px",
};

export interface DashboardTableData {
  rows: (string | number | undefined)[][];
  totalItems: number;
}
export interface DashboardTableProps {
  title: string;
  total?: number;
  percentage?: number;
  headers: string[];
  getRows: (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ) => Promise<DashboardTableData>;
  tableRef: RefObject<TableReloadHandle>;
  filter?: React.ReactElement;
  onViewAll: () => void;
}
export const DashboardTable = ({
  title,
  total,
  percentage,
  headers,
  getRows,
  tableRef,
  filter,
  onViewAll,
}: DashboardTableProps): React.ReactElement => {
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const { t } = useTranslation();

  const tableHeader: TableHeader = headers.map((header, index) => {
    if (index === 0) {
      return {
        id: header,
        name: header,
        width: "auto",
        align: "left",
      };
    }
    return {
      id: header,
      name: header,
      width: "auto",
      align: "right",
    };
  });

  const getTypography = (
    value: string,
    isPositive?: boolean | undefined,
  ): React.ReactElement => {
    let color = tokens.label;
    if (isPositive === true) {
      color = tokens.labelSuccess;
    } else if (isPositive === false) {
      color = tokens.labelError;
    }
    return (
      <Typography sx={{ typography: typography.b1, color }}>{value}</Typography>
    );
  };

  const getSubTitleColor = (data?: number): string => {
    if (data === undefined) {
      return tokens.label;
    }
    return data > 0 ? tokens.labelSuccess : tokens.labelError;
  };

  const getRowValue = (
    item: string | number | undefined,
    isColored?: boolean,
  ): React.ReactElement => {
    if (typeof item === "string") {
      return getTypography(item);
    } else if (typeof item === "number") {
      return getTypography(
        getFormattedAmount(item, 2, 2),
        item && isColored ? item > 0 : undefined,
      );
    } else {
      return getTypography("-");
    }
  };

  const getTableOptions = async (
    option: TableOptions,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    const { rows, totalItems } = await getRows(
      option.page ? option.page - 1 : 0,
      10,
      option.sort,
    );
    setTotalItems(totalItems);
    return rows.map((row): TableRowItem => {
      return row.map((item, colIndex) => {
        if (colIndex === 0) {
          return { data: getRowValue(item) };
        } else {
          return { data: getRowValue(item, colIndex > 1), align: "right" };
        }
      });
    });
  };

  return (
    <Stack
      sx={{
        maxWidth: Size.width,
        flexGrow: 1,
        border: border.default,
        background: tokens.backgroundElevatedLevel1,
        borderRadius: cornerRadius.radiusXS,
      }}
    >
      <Stack
        sx={{
          padding: `${spacing.spaceMD} ${spacing.spaceXL}`,
          borderBottom: border.default,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"row"}
          spacing={spacing.spaceXS}
          alignItems={"center"}
        >
          <Typography
            sx={{ typography: typography.sh3, color: tokens.labelHighEmphasis }}
          >
            {title}
          </Typography>
          {total !== undefined && (
            <Typography
              sx={{
                typography: typography.b1,
                color: getSubTitleColor(total),
              }}
            >
              {getFormattedAmount(total)}
            </Typography>
          )}
          {percentage !== undefined && (
            <Typography
              sx={{
                typography: typography.b1,
                color: getSubTitleColor(percentage),
              }}
            >
              {`(${getFormattedAmount(percentage)}%)`}
            </Typography>
          )}
        </Stack>
        <Stack
          direction={"row"}
          spacing={spacing.spaceXS}
          alignItems={"center"}
        >
          {filter}
          <Button
            label={t("common.viewAllButtonTitle")}
            name={"viewAll"}
            size={"small"}
            variant={"plain-color"}
            onClick={onViewAll}
          />
        </Stack>
      </Stack>
      <Table
        ref={tableRef}
        name={title}
        headers={tableHeader}
        paginationOption={{
          itemsPerPage: 10,
          getPageIndicatorText(startItem, endItem, totalItems): string {
            return t("common.paginationIndicationText", {
              startItem,
              endItem,
              totalItems,
            });
          },
        }}
        styleOverrides={{
          border: "unset",
          divider: "row",
        }}
        onTableOptionsChange={getTableOptions}
        viewOverrides={{
          empty: { message: t("common.noDataFound") },
          loading: { message: t("reports.fetchingData") },
        }}
      />
    </Stack>
  );
};
