import {
  GetFiInvestmentDetailsRPC,
  GetFiInvestmentDetailsRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../constants/RPC-Mock";
import { MockGetFiInvestmentDetailsRPCImpl } from "./MockGetFiInvestmentDetailsRPCImpl";

export const useGetFiInvestmentDetailsClientImpl = (
  apiClient: APIClient,
): GetFiInvestmentDetailsRPC => {
  if (MOCK.fi) {
    return new MockGetFiInvestmentDetailsRPCImpl();
  } else {
    return new GetFiInvestmentDetailsRPCClientImpl(apiClient);
  }
};
