import { SubmitEquityDealContractNoteRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitEquityDealContractNoteRPCImpl extends SubmitEquityDealContractNoteRPC {
  execute(
    _request: SubmitEquityDealContractNoteRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitEquityDealContractNoteRPC.Response,
      SubmitEquityDealContractNoteRPC.Errors.Errors
    >
  > {
    const response = new SubmitEquityDealContractNoteRPC.Response([1]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitEquityDealContractNoteRPC.Response,
        SubmitEquityDealContractNoteRPC.Errors.Errors
      >
    >;
  }
}
