import React, { useState, useEffect } from "react";
import { Grid, Stack } from "@mui/material";
import { Instance } from "mobx-state-tree";
import {
  DropdownItem,
  SearchField,
  Button,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { EquityContractNoteSearchBy } from "@khazana/khazana-rpcs";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import {
  getContractNoteSearchByOptions,
  getContractNoteStatusOptionsWithAll,
  getContractNotesSearchByValue,
  getEquityContractNotesInitialFilter,
} from "../utils/SearchUtils";
import { BrokerModel } from "../../../models/BrokerModel";
import { ContractNotesFilterOptionsModel } from "../models/ContractNotesFilterOptionsModel";
import { getBrokerOptions } from "../../../utils/SearchUtils";
import { MIN_SEARCH_LENGTH } from "../../../../../utils";

interface ContractNotesSearchFilterProps {
  filter: Instance<typeof ContractNotesFilterOptionsModel>;
  setFilter: (
    newFilter: Instance<typeof ContractNotesFilterOptionsModel>,
  ) => void;
  brokers: Instance<typeof BrokerModel>[];
}

const Size = {
  container: "100%",
  searchField: "696px",
};

export const ContractNotesSearchFilter = ({
  filter,
  setFilter,
  brokers,
}: ContractNotesSearchFilterProps): React.ReactElement => {
  const { t } = useTranslation();
  const initialFilter = getEquityContractNotesInitialFilter();
  const [currentFilter, setCurrentFilter] = useState(filter);
  const searchByOptions = getContractNoteSearchByOptions(t);
  const [searchBy, setSearchBy] = useState(filter.searchBy);
  const [searchText, setSearchText] = useState(filter.searchText);
  const statusOptions = getContractNoteStatusOptionsWithAll(t);
  const [status, setRequestStatus] = useState(filter.status);
  const brokerOptions = getBrokerOptions(t, brokers, true);
  const spacing = useSpacing();
  const [brokerId, setBrokerId] = useState(filter.brokerId);
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);

  const getFilter = (): Instance<typeof ContractNotesFilterOptionsModel> => {
    return {
      searchBy,
      searchText,
      status,
      brokerId,
    };
  };

  useEffect(() => {
    setFilter(filter);
  }, []);

  useEffect(() => {
    if (
      (searchText && searchText.length < MIN_SEARCH_LENGTH) ||
      JSON.stringify(getFilter()) === JSON.stringify(currentFilter)
    ) {
      setIsSearchDisabled(true);
    } else {
      setIsSearchDisabled(false);
    }
  }, [searchText, status, brokerId]);

  const onSearchByChange = (selected: DropdownItem): void => {
    setSearchBy(selected.value);
  };

  const onSearchClear = (): void => {
    setSearchText("");
  };

  const onSearchTextChange = (searchValue: string): void => {
    setSearchText(searchValue);
  };

  const onRequestStatusChange = (selected: DropdownItem): void => {
    setRequestStatus(selected.value);
  };

  const onBrokerChange = (selected: DropdownItem): void => {
    setBrokerId(selected.value);
  };

  const onReset = (): void => {
    setSearchBy(initialFilter.searchBy);
    setSearchText(initialFilter.searchText);
    setRequestStatus(initialFilter.status);
    setBrokerId(initialFilter.brokerId);
    setCurrentFilter(initialFilter);
    setFilter(initialFilter);
  };

  return (
    <Stack
      sx={{
        padding: spacing.spaceMD,
        width: Size.container,
      }}
    >
      <Grid container spacing={spacing.spaceMD}>
        <Grid item xs={4}>
          <DropdownInputFieldSeparateLabel
            name="searchByDropdown"
            label={t("common.searchBy")}
            value={searchBy}
            options={searchByOptions}
            onSelect={onSearchByChange}
          />
        </Grid>
        <Grid item xs={6} alignSelf={"flex-end"}>
          <SearchField
            name="searchField"
            value={searchText}
            onTextChange={onSearchTextChange}
            onClear={onSearchClear}
            allowClearField={true}
            placeholder={`${t(
              "common.searchUsing",
            )} ${getContractNotesSearchByValue(
              t,
              EquityContractNoteSearchBy.fromDTO({ case: searchBy }),
            )}`}
            style={{
              maxWidth: Size.searchField,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <DropdownInputFieldSeparateLabel
            name="requestStatusFilter"
            value={status}
            onSelect={onRequestStatusChange}
            label={t("common.status")}
            options={statusOptions}
          />
        </Grid>
        <Grid item xs={4}>
          <DropdownInputFieldSeparateLabel
            name="brokerFilter"
            value={brokerId}
            onSelect={onBrokerChange}
            label={t("contractNotes.broker")}
            options={brokerOptions}
          />
        </Grid>
        <Grid
          item
          xs={2}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          gap={spacing.spaceXS}
          paddingBottom={spacing.spaceXXS}
        >
          <Button
            name="plain-color"
            variant="plain-color"
            color="destructive"
            size="medium"
            label={t("common.reset")}
            disabled={JSON.stringify(filter) === JSON.stringify(initialFilter)}
            onClick={onReset}
          />
          <Button
            name="filled"
            variant="filled"
            size="medium"
            disabled={isSearchDisabled}
            label={t("common.search")}
            onClick={(): void => {
              setIsSearchDisabled(true);
              setCurrentFilter({
                ...getFilter(),
              });
              setFilter({
                ...getFilter(),
              });
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
