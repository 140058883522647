"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityTopTenHoldingsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const equityHoldings_1 = require("./equityHoldings");
const currency_1 = require("../types/currency");
/* eslint-disable import/export */
class GetEquityTopTenHoldingsRPC {
}
exports.GetEquityTopTenHoldingsRPC = GetEquityTopTenHoldingsRPC;
(function (GetEquityTopTenHoldingsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityTopTenHoldingsRPC.InvalidRequestError = InvalidRequestError;
    let RequestEnums;
    (function (RequestEnums) {
        let Sort;
        (function (Sort_1) {
            let Sort;
            (function (Sort) {
                Sort["GAIN"] = "GAIN";
                Sort["CURRENT_VALUE"] = "CURRENT_VALUE";
            })(Sort = Sort_1.Sort || (Sort_1.Sort = {}));
            Sort_1.fromDTO = (dto) => {
                switch (dto.case) {
                    case "GAIN":
                        return Sort.GAIN;
                    case "CURRENT_VALUE":
                        return Sort.CURRENT_VALUE;
                    default:
                        throw new InvalidRequestError(`Case ${dto.case} is not valid case of Sort`);
                }
            };
            Sort_1.toDTO = (sort) => {
                const ret = {
                    case: Sort[sort],
                };
                return ret;
            };
            Sort_1.isInstanceOf = (other) => {
                if (other in Sort) {
                    return true;
                }
                return false;
            };
        })(Sort = RequestEnums.Sort || (RequestEnums.Sort = {}));
        /* eslint-enable @typescript-eslint/no-shadow */
    })(RequestEnums = GetEquityTopTenHoldingsRPC.RequestEnums || (GetEquityTopTenHoldingsRPC.RequestEnums = {}));
    class Request {
        constructor(entityId = null, portfolioId = null, sort) {
            this.isGetEquityTopTenHoldingsRPCRequest = true;
            if (entityId !== undefined && entityId !== null) {
                Request.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
            Request.validateSort(sort);
            this.sort = sort;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const sort = RequestEnums.Sort.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "sort", InvalidRequestError));
            return new Request(entityId, portfolioId, sort);
        }
        toDTO() {
            const ret = {
                sort: RequestEnums.Sort.toDTO(this.sort),
            };
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, sort = this.sort) {
            return new Request(entityId, portfolioId, sort);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityTopTenHoldingsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            if (this.sort !== other.sort) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateSort = (sort) => {
        if (!RequestEnums.Sort.isInstanceOf(sort)) {
            throw new InvalidRequestError(`Attribute sort is not a RequestEnums.Sort`);
        }
    };
    GetEquityTopTenHoldingsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityTopTenHoldingsRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},sort=${this.sort})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityTopTenHoldingsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(topTenHoldings, currency) {
            this.isGetEquityTopTenHoldingsRPCResponse = true;
            Response.validateTopTenHoldings(topTenHoldings);
            this.topTenHoldings = topTenHoldings;
            Response.validateCurrency(currency);
            this.currency = currency;
        }
        static fromDTO(dto) {
            const topTenHoldings = (0, leo_ts_runtime_1.getList)(dto, "topTenHoldings", equityHoldings_1.EquityHoldings.fromDTO, InvalidResponseError);
            const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidResponseError));
            return new Response(topTenHoldings, currency);
        }
        toDTO() {
            const ret = {
                topTenHoldings: this.topTenHoldings.map((e) => {
                    return e.toDTO();
                }),
                currency: this.currency.toDTO(),
            };
            return ret;
        }
        copy(topTenHoldings = this.topTenHoldings, currency = this.currency) {
            return new Response(topTenHoldings, currency);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityTopTenHoldingsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.topTenHoldings, other.topTenHoldings)) {
                return false;
            }
            if (!this.currency.equals(other.currency)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTopTenHoldings = (topTenHoldings) => {
        if (!Array.isArray(topTenHoldings)) {
            throw new InvalidResponseError(`Attribute topTenHoldings is not a List`);
        }
        for (let i = 0; i < topTenHoldings.length; i += 1) {
            const isEquityHoldingsValue = topTenHoldings[i].isEquityHoldings;
            if (!(topTenHoldings[i] instanceof equityHoldings_1.EquityHoldings || Boolean(isEquityHoldingsValue))) {
                throw new InvalidResponseError(`Attribute topTenHoldings at index ${i} is not a EquityHoldings.`);
            }
        }
    };
    Response.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidResponseError(`Attribute currency is not a Currency`);
        }
    };
    GetEquityTopTenHoldingsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityTopTenHoldingsRPC.Response(topTenHoldings=${this.topTenHoldings},currency=${this.currency})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DATE") {
                super(code);
                this.isInvalidDate = true;
                InvalidDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDateValue = other.isInvalidDate;
                if (!(other instanceof InvalidDate || Boolean(isInvalidDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDate = InvalidDate;
        InvalidDate.prototype.toString = function toString() {
            return `GetEquityTopTenHoldingsRPC.INVALID_DATE()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetEquityTopTenHoldingsRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `GetEquityTopTenHoldingsRPC.INVALID_PORTFOLIO_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_DATE":
                    return InvalidDate.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidDateValue = other.isInvalidDate;
            if (other instanceof InvalidDate || Boolean(isInvalidDateValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityTopTenHoldingsRPC.Errors || (GetEquityTopTenHoldingsRPC.Errors = {}));
})(GetEquityTopTenHoldingsRPC = exports.GetEquityTopTenHoldingsRPC || (exports.GetEquityTopTenHoldingsRPC = {}));
