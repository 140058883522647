import { GetIndexListRPC, Index } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetIndexListRPCImpl extends GetIndexListRPC {
  execute(
    _request: GetIndexListRPC.Request,
  ): Promise<
    LeoRPCResult<GetIndexListRPC.Response, GetIndexListRPC.Errors.Errors>
  > {
    const response = new GetIndexListRPC.Response([
      new Index(1, "NIFTY 50"),
      new Index(2, "NASDAQ"),
      new Index(3, "S&P 500"),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetIndexListRPC.Response, GetIndexListRPC.Errors.Errors>
    >;
  }
}
