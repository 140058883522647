import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  Broker,
  CreateEditFiDealRequestBrokerEntryRPC,
  GetFiDealRequestSendToBrokerDetailsRPC,
  FiBrokerAmountQuantity,
  Currency,
} from "@khazana/khazana-rpcs";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { AmountModel } from "../../../models/AmountModel";
import {
  FiSendToBrokerDealRequestDetailError,
  ViewFiDealInvalidRequestError,
} from "./ViewFiDealRequestDetailsError";
import { createAmountQuantityModel } from "../models/FiDealRequestBrokerSectionDetailModel";
import {
  FiDealRequestBrokerModel,
  createFiDealRequestBrokerModel,
} from "../models/FiDealRequestBrokerModel";
import {
  FiBrokerAmountDetailModel,
  createFiBrokerAmountQuantity,
} from "../models/FiBrokerAmountDetailModel";
import {
  useCreateEditFiDealRequestBrokerEntryRPCImpl,
  useGetFiDealRequestSendToBrokerDetailsRPCImpl,
} from "../rpcs/RPC";
import { Logger } from "../../../../logger/Logger";
import { AmountQuantityModel } from "../../../models/AmountQuantityModel";
import { createServerNoteRPCType } from "../../../../../utils";

const getCreateEditFiDealRequestBrokerEntryError = (
  error: CreateEditFiDealRequestBrokerEntryRPC.Errors.Errors,
  logger: Logger,
):
  | FiSendToBrokerDealRequestDetailError
  | ViewFiDealInvalidRequestError
  | null => {
  switch (error.code) {
    case ViewFiDealInvalidRequestError.InvalidRequestId:
      return ViewFiDealInvalidRequestError.InvalidRequestId;
    case FiSendToBrokerDealRequestDetailError.DealNotApproved:
      return FiSendToBrokerDealRequestDetailError.DealNotApproved;
    case FiSendToBrokerDealRequestDetailError.InvalidBroker:
      return FiSendToBrokerDealRequestDetailError.InvalidBroker;
    case FiSendToBrokerDealRequestDetailError.CurrencyMismatch:
      return FiSendToBrokerDealRequestDetailError.CurrencyMismatch;
    case FiSendToBrokerDealRequestDetailError.DealAlreadyCancelled:
      return FiSendToBrokerDealRequestDetailError.DealAlreadyCancelled;
    case FiSendToBrokerDealRequestDetailError.DealAlreadyExpired:
      return FiSendToBrokerDealRequestDetailError.DealAlreadyExpired;
    case FiSendToBrokerDealRequestDetailError.CanModifyOnlySelfRequest:
      return FiSendToBrokerDealRequestDetailError.CanModifyOnlySelfRequest;
    case FiSendToBrokerDealRequestDetailError.CannotChangeAlreadyLinkedBrokerData:
      return FiSendToBrokerDealRequestDetailError.CannotChangeAlreadyLinkedBrokerData;
    case FiSendToBrokerDealRequestDetailError.ExceededAmount:
      return FiSendToBrokerDealRequestDetailError.ExceededAmount;
    case FiSendToBrokerDealRequestDetailError.ExceededQuantity:
      return FiSendToBrokerDealRequestDetailError.ExceededQuantity;
    case FiSendToBrokerDealRequestDetailError.NoBrokerEntryFound:
      return FiSendToBrokerDealRequestDetailError.NoBrokerEntryFound;
    case FiSendToBrokerDealRequestDetailError.UnalteredData:
      return FiSendToBrokerDealRequestDetailError.UnalteredData;
    case FiSendToBrokerDealRequestDetailError.InconsistentDealValue:
      return FiSendToBrokerDealRequestDetailError.InconsistentDealValue;
    case FiSendToBrokerDealRequestDetailError.MissingValues:
      return FiSendToBrokerDealRequestDetailError.MissingValues;
    case FiSendToBrokerDealRequestDetailError.MultipleEntriesForSameNonLinkedBrokerFound:
      return FiSendToBrokerDealRequestDetailError.MultipleEntriesForSameNonLinkedBrokerFound;
    default:
      logger.error(
        `Unhandled error: ${error} occurred in CreateEditFiDealRequestBrokerEntryRPC`,
      );
      return null;
  }
};

const getAmountQuantityModel = (
  value: number,
  requestedAmountQuantity: Instance<typeof AmountQuantityModel>,
): Instance<typeof AmountQuantityModel> | undefined => {
  if (requestedAmountQuantity.amount) {
    const currency = requestedAmountQuantity.amount.currency;
    const currencyModelInstance = CurrencyModel.create({
      code: currency.code,
      symbol: currency.symbol,
    });
    const amountValue = value;
    const amountModelInstance = AmountModel.create({
      amount: amountValue,
      currency: currencyModelInstance,
    });
    return AmountQuantityModel.create({
      amount: amountModelInstance,
    });
  } else {
    return AmountQuantityModel.create({ quantity: value });
  }
};

const getBrokerAmountQuantity = (
  brokerAmountDetails: Instance<typeof FiBrokerAmountDetailModel>[],
  logger: Logger,
): FiBrokerAmountQuantity[] => {
  let isInvalidEntryPresent = false;
  const _brokerAmountDetails: FiBrokerAmountQuantity[] = brokerAmountDetails
    .map((brokerAmountDetail) => {
      if (
        brokerAmountDetail.broker &&
        brokerAmountDetail.amountQuantity &&
        // rule is disable since `or` operator is required here as compared to `??`
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        (brokerAmountDetail.amountQuantity?.amount?.amount ||
          brokerAmountDetail.amountQuantity?.quantity)
      ) {
        return createFiBrokerAmountQuantity(
          brokerAmountDetail.broker,
          brokerAmountDetail.amountQuantity,
          logger,
        );
      } else {
        isInvalidEntryPresent = true;
        logger.error(
          `${brokerAmountDetail} is missing values to be converted to a type that can be sent to RPC request`,
        );
      }
    })
    .filter((brokerAmountDetail: FiBrokerAmountQuantity | undefined) => {
      if (brokerAmountDetail) {
        return brokerAmountDetail;
      }
    }) as FiBrokerAmountQuantity[];
  // as operator is used to silence the warning since the above function returns a type of BrokerAmountQuantity | undefined.
  // Filter operator is used to remove the undefined values to ensure that unwanted values are not present in the array sent to the RPC
  if (isInvalidEntryPresent) {
    const error = new Error();
    error.name = FiSendToBrokerDealRequestDetailError.MissingValues;
    throw error;
  } else {
    return _brokerAmountDetails;
  }
};

export const SendToBrokerFiDealRequestStore = types
  .model("SendToBrokerFiDealRequestStore", {
    requestId: types.maybeNull(types.number),
    brokerAmountDetails: types.array(FiBrokerAmountDetailModel),
    requestedAmountQuantity: types.maybeNull(AmountQuantityModel),
    error: types.maybeNull(
      types.union(
        types.enumeration<FiSendToBrokerDealRequestDetailError>(
          "FiSendToBrokerDealRequestDetailError",
          Object.values(FiSendToBrokerDealRequestDetailError),
        ),
        types.enumeration<ViewFiDealInvalidRequestError>(
          "ViewFiDealInvalidRequestError",
          Object.values(ViewFiDealInvalidRequestError),
        ),
      ),
    ),
    note: types.maybeNull(types.string),
    brokerList: types.array(FiDealRequestBrokerModel),
  })
  .actions((store) => ({
    updateErrorInBrokerAmountDetails(error: boolean): void {
      const brokerAmountDetails = store.brokerAmountDetails;
      if (brokerAmountDetails) {
        brokerAmountDetails.forEach((_, index) => {
          const brokerAmountDetail = brokerAmountDetails[index];
          brokerAmountDetails[index].isAmountQuantityError = error;
          if (brokerAmountDetail.isLinked) {
            // error should not be added for brokers with linked contract note
            brokerAmountDetails[index].isAmountQuantityError = false;
          }
        });
      }
      store.brokerAmountDetails = cast(brokerAmountDetails);
    },
    updateAutoCompleteError(error: boolean, index: number): void {
      if (store.brokerAmountDetails) {
        store.brokerAmountDetails[index].isError = error;
      }
    },
    updateAmountQuantityError(error: boolean, index: number): void {
      if (store.brokerAmountDetails) {
        store.brokerAmountDetails[index].isAmountQuantityError = error;
      }
    },
    validateInputs(): void {
      const brokerAmountDetails = store.brokerAmountDetails;
      if (brokerAmountDetails) {
        brokerAmountDetails.forEach((_, index) => {
          const brokerAmountDetail = brokerAmountDetails[index];
          if (brokerAmountDetail.broker === null) {
            brokerAmountDetail.isError = true;
          }
          if (brokerAmountDetail.amountQuantity === null) {
            brokerAmountDetail.isAmountQuantityError = true;
          }
          if (brokerAmountDetail.isLinked) {
            // error should not be added for brokers with linked contract note
            brokerAmountDetails[index].isError = false;
          }
        });
      }
      store.brokerAmountDetails = cast(brokerAmountDetails);
    },
  }))
  .actions((store) => ({
    addBrokerAmountDetail(): void {
      const brokerAmountDetails = store.brokerAmountDetails;
      const newBrokerEntry = FiBrokerAmountDetailModel.create({
        isLinked: false,
        isError: false,
        isAmountQuantityError: false,
      });
      brokerAmountDetails?.push(newBrokerEntry);
      store.brokerAmountDetails = cast(brokerAmountDetails);
    },
    deleteBrokerAmountDetail(index: number): void {
      const brokerAmountDetails = store.brokerAmountDetails;
      brokerAmountDetails?.splice(index, 1);
      store.brokerAmountDetails = cast(brokerAmountDetails);
    },
    clearBroker(index: number): void {
      store.error = null;
      if (store.brokerAmountDetails) {
        store.brokerAmountDetails[index].broker = null;
      }
    },
    editBrokerDetail(index: number, brokerId: string): void {
      store.error = null;
      const logger = getEnv(store).logger;
      if (store.brokerAmountDetails) {
        const brokers = store.brokerList.filter(
          (broker) => broker.id === brokerId,
        )[0];
        if (brokers) {
          store.brokerAmountDetails[index].broker =
            createFiDealRequestBrokerModel(
              new Broker(
                new LeoUUID(brokers.id),
                brokers.name,
                new Currency(brokers.currency.code, brokers.currency.symbol),
              ),
            );
        } else {
          logger.error(
            `Could not find broker with id: ${brokerId} in ${store.brokerList}`,
          );
        }
      } else {
        logger.error(
          "Tried to edit broker without brokerAmountDetails being present in SendToBrokerFiDealRequestStore",
        );
      }
    },
    editAmountDetail(index: number, value: number): void {
      store.error = null;
      const logger = getEnv(store).logger;
      if (store.requestedAmountQuantity) {
        const brokerAmountDetails = store.brokerAmountDetails;
        if (brokerAmountDetails) {
          const amountQuantity = getAmountQuantityModel(
            value,
            store.requestedAmountQuantity,
          );
          brokerAmountDetails[index].amountQuantity = cast(amountQuantity);
        } else {
          logger.error(
            "Tried to edit broker without brokerAmountDetails being present in SendToBrokerFiDealRequestStore",
          );
        }
      } else {
        logger.error(
          "RequestedAmountQuantity not found in SendToBrokerFiDealRequestStore",
        );
      }
    },
  }))
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.brokerList = cast([]);
      store.requestedAmountQuantity = null;
      store.brokerAmountDetails = cast([]);
      store.note = null;
      store.requestId = null;
    },
    setBrokerAmountDetails(
      brokerAmountDetails: Instance<typeof FiBrokerAmountDetailModel>[],
      requestId: number,
    ): void {
      if (brokerAmountDetails.length > 0) {
        store.brokerAmountDetails = cast(brokerAmountDetails);
      } else {
        store.brokerAmountDetails = cast([]);
        store.addBrokerAmountDetail();
      }
      store.requestId = requestId;
    },
    setNote(note: string): void {
      store.note = note;
    },
    getSendToBrokerDetails: flow(function* (requestId: number) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiDealRequestSendToBrokerDetailsRPC.Request(
          requestId,
        );
        const result: LeoRPCResult<
          GetFiDealRequestSendToBrokerDetailsRPC.Response,
          GetFiDealRequestSendToBrokerDetailsRPC.Errors.Errors
        > =
          yield useGetFiDealRequestSendToBrokerDetailsRPCImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          const brokers = response.brokers;
          store.brokerList = cast(
            brokers.map((broker: Broker) => {
              return createFiDealRequestBrokerModel(broker);
            }),
          );
          store.requestedAmountQuantity = createAmountQuantityModel(
            response.requestedAmountQuantity,
            logger,
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiDealInvalidRequestError.InvalidRequestId:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiDealRequestBrokerListRPC`,
              );
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiDealRequestBrokerListRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiDealRequestBrokerListRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiDealRequestBrokerListRPC`,
          );
        }
      }
    }),
    submitSendToBroker: flow(function* () {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        if (store.requestId === null) {
          logger.error("RequestId cannot be null");
        }
        if (store.brokerAmountDetails && store.requestId) {
          const _brokerAmountDetails = getBrokerAmountQuantity(
            store.brokerAmountDetails,
            logger,
          );
          const request = new CreateEditFiDealRequestBrokerEntryRPC.Request(
            store.requestId,
            _brokerAmountDetails,
            createServerNoteRPCType(store.note),
          );
          const result: LeoRPCResult<
            CreateEditFiDealRequestBrokerEntryRPC.Response,
            CreateEditFiDealRequestBrokerEntryRPC.Errors.Errors
          > =
            yield useCreateEditFiDealRequestBrokerEntryRPCImpl(
              apiClient,
            ).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            return;
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            store.error = getCreateEditFiDealRequestBrokerEntryError(
              error,
              logger,
            );
            if (
              store.error ===
              FiSendToBrokerDealRequestDetailError.ExceededAmount
            ) {
              store.updateErrorInBrokerAmountDetails(true);
            } else if (
              store.error ===
              FiSendToBrokerDealRequestDetailError.ExceededQuantity
            ) {
              store.updateErrorInBrokerAmountDetails(true);
            }
          }
        } else {
          logger.error(
            "brokerAmountDetails is missing values to be converted to a type that can be sent to RPC request",
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            case FiSendToBrokerDealRequestDetailError.MissingValues:
              store.error = FiSendToBrokerDealRequestDetailError.MissingValues;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in CreateEditFiDealRequestBrokerEntryRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in CreateEditFiDealRequestBrokerEntryRPC`,
          );
        }
      }
    }),
  }))
  .views((store) => ({
    getBrokerListWith(
      brokerId: string | undefined, // undefined is used for new entries in send to broker
    ): Instance<typeof FiDealRequestBrokerModel>[] {
      // This function is used to get broker dropdown options to be shown in the section dialog
      // the requirement is to show only the brokers that have not been selected ( including the broker that is currently selected )
      const selectedBrokers = store.brokerAmountDetails
        ?.filter((brokerAmountDetail) => {
          return !brokerAmountDetail.isLinked; // this is done since although a broker-entry exists, we can allow selection of a broker which has a contract note linked
        })
        .map((brokerAmountDetail) => {
          return brokerAmountDetail.broker?.id;
        });
      const filteredBrokers = store.brokerList.filter((broker) => {
        if (selectedBrokers?.includes(broker.id)) {
          return false;
        }
        return true;
      });
      const _broker = store.brokerList.filter(
        (broker) => broker.id === brokerId,
      )[0];
      if (_broker) {
        filteredBrokers?.push(_broker);
      }
      return filteredBrokers ?? []; // This is done to handle the undefined check, if there are no brokers present in the store, no value will be shown in the dropdown
    },
  }));

export const createSendToBrokerFiDealRequestStore = (): Instance<
  typeof SendToBrokerFiDealRequestStore
> => {
  return SendToBrokerFiDealRequestStore.create();
};
