import {
  GetEquityBenchmarkReturnsGraphRPC,
  PercentageDate,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityBenchmarkReturnsGraphRPCImpl extends GetEquityBenchmarkReturnsGraphRPC {
  execute(
    _request: GetEquityBenchmarkReturnsGraphRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityBenchmarkReturnsGraphRPC.Response,
      GetEquityBenchmarkReturnsGraphRPC.Errors.Errors
    >
  > {
    const response = new GetEquityBenchmarkReturnsGraphRPC.Response(
      [
        new PercentageDate(10, new LeoDate("2021-01-01")),
        new PercentageDate(12, new LeoDate("2021-01-02")),
        new PercentageDate(14, new LeoDate("2021-01-03")),
        new PercentageDate(9, new LeoDate("2021-01-04")),
        new PercentageDate(11, new LeoDate("2021-01-05")),
        new PercentageDate(13, new LeoDate("2021-01-06")),
        new PercentageDate(15, new LeoDate("2021-01-07")),
        new PercentageDate(16, new LeoDate("2021-01-08")),
        new PercentageDate(17, new LeoDate("2021-01-09")),
        new PercentageDate(18, new LeoDate("2021-01-10")),
        new PercentageDate(19, new LeoDate("2021-01-11")),
        new PercentageDate(20, new LeoDate("2021-01-12")),
        new PercentageDate(21, new LeoDate("2021-01-13")),
      ],
      [
        new PercentageDate(3, new LeoDate("2021-01-01")),
        new PercentageDate(6, new LeoDate("2021-01-02")),
        new PercentageDate(4, new LeoDate("2021-01-03")),
        new PercentageDate(8, new LeoDate("2021-01-04")),
        new PercentageDate(10, new LeoDate("2021-01-05")),
        new PercentageDate(12, new LeoDate("2021-01-06")),
        new PercentageDate(14, new LeoDate("2021-01-07")),
        new PercentageDate(16, new LeoDate("2021-01-08")),
        new PercentageDate(18, new LeoDate("2021-01-09")),
        new PercentageDate(20, new LeoDate("2021-01-10")),
        new PercentageDate(22, new LeoDate("2021-01-11")),
        new PercentageDate(24, new LeoDate("2021-01-12")),
      ],
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityBenchmarkReturnsGraphRPC.Response,
        GetEquityBenchmarkReturnsGraphRPC.Errors.Errors
      >
    >;
  }
}
