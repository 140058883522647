import React, { useCallback, useEffect, useState } from "react";
import { DashboardContainer } from "../../../components/dashboard/DashboardContainer";
import { useTranslation } from "react-i18next";
import {
  DashboardStats,
  DashboardStatsItem,
} from "../../../components/dashboard/DashboardStats";
import { DashboardLineGraph } from "../../../components/dashboard/DashboardLineGraph";
import { DashboardDoughnutGraph } from "../../../components/dashboard/DashboardDoughnutGraph";
import {
  DashboardTable,
  DashboardTableData,
} from "../../../components/dashboard/DashboardTable";
import { DataSeries } from "../../../components/charts/LineChart";
import { DoughnutSeriesItem } from "../../../components/charts/DoughnutChart";
import {
  LoadingIndicator,
  spacing,
  TableReloadHandle,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { useEquityDashboardStore } from "../store/hooks";
import { Instance } from "mobx-state-tree";
import { DashboardStatModel } from "../../../models/DashboardStatModel";
import { DurationToggleButton } from "../../../components/toggleButton/DurationToggleButton";
import { ReportDateRange } from "../../models/EquityReportDateRangeModel";
import { DateRangeModel } from "../../models/DateRangeModel";
import { observer } from "mobx-react";
import { MenuButton } from "../../../components/page-header/MenuButton";
import { GetEquityTopTenHoldingsRPC } from "@khazana/khazana-rpcs";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";

const Size = {
  width: "1120px",
};

export const EquityDashboard = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tokens = useFoundationColorTokens();
  const holdingTableRef = React.createRef<TableReloadHandle>();
  const realisedGainsTableRef = React.createRef<TableReloadHandle>();
  const [isLoading, setIsLoading] = useState(false);
  const store = useEquityDashboardStore();

  const getData = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const promises = [
      store.dashboardStats.getEquityDashboardStats(),
      store.benchmarkReturns.benchmarkIndex.getBenchmarkIndexList().then(() => {
        store.benchmarkReturns.getEquityBenchmarkReturnsGraph();
      }),
      store.holdingDistribution.getEquityHoldingDistributionByIsin(),
      store.topTenHoldings.getEquityTopTenHoldings(),
      store.topTenRealisedGains.getEquityTopTenRealisedGains(),
    ];
    await Promise.all(promises);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getStat = (
    label: string,
    stat: Instance<typeof DashboardStatModel>,
  ): DashboardStatsItem => {
    return {
      title: label,
      value: stat.amount,
      fullValue: stat.fullAmount,
      isPositive: stat.isGain,
      percentage: stat.percentage ? stat.percentage + "%" : undefined,
    };
  };

  const getStats = (): DashboardStatsItem[] => {
    const stats = store.dashboardStats;
    return [
      getStat(
        t("equity.dashboard.stats.investedValueLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.investedValue,
      ),
      getStat(
        t("equity.dashboard.stats.marketValueLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.marketValue,
      ),
      getStat(
        t("equity.dashboard.stats.todayPnlLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.todayPnL,
      ),
      getStat(
        t("equity.dashboard.stats.unrealisedPnlLabel", {
          val: stats.investedValue.currency?.symbol,
        }),
        stats.unrealisedGain,
      ),
      getStat(t("equity.dashboard.stats.irrLabel"), stats.irr),
    ];
  };

  const getReturnVsBenchmark = (): DataSeries[] => {
    return [
      {
        data: store.benchmarkReturns.returns.map((item) => {
          return {
            x: item.date,
            y: item.percentage,
          };
        }),
        name: t("dashboard.returnsLabel"),
      },
    ];
  };

  const getHoldingDistribution = (): DoughnutSeriesItem[] => {
    return store.holdingDistribution.distribution.map((item) => {
      return {
        name: item.label,
        value: item.amount.amount,
      };
    });
  };

  const holdingTableHeaders: string[] = [
    t("equity.dashboard.holdingsTableHeaders.symbol"),
    t("equity.dashboard.holdingsTableHeaders.currentValue", {
      val: store.topTenHoldings.currency?.symbol,
    }),
    t("equity.dashboard.holdingsTableHeaders.p&l", {
      val: store.topTenHoldings.currency?.symbol,
    }),
    t("equity.dashboard.holdingsTableHeaders.chg"),
    t("equity.dashboard.holdingsTableHeaders.irr"),
  ];

  const getHoldings = async (): Promise<DashboardTableData> => {
    await store.topTenHoldings.getEquityTopTenHoldings();
    return {
      rows: store.topTenHoldings.list.map((item) => {
        return [
          item.symbol,
          item.currentValue,
          item.pnl,
          item.changePercentage,
          item.irr,
        ];
      }),
      totalItems: store.topTenHoldings.list.length,
    };
  };

  const realisedGainsTableHeaders: string[] = [
    t("equity.dashboard.realisedGainTableHeaders.symbol"),
    t("equity.dashboard.realisedGainTableHeaders.soldValue", {
      val: store.topTenRealisedGains.currency?.symbol,
    }),
    t("equity.dashboard.realisedGainTableHeaders.realisedP&l", {
      val: store.topTenRealisedGains.currency?.symbol,
    }),
    t("equity.dashboard.realisedGainTableHeaders.chg"),
    t("equity.dashboard.realisedGainTableHeaders.irr"),
  ];

  const getRealisedGains = async (): Promise<DashboardTableData> => {
    await store.topTenRealisedGains.getEquityTopTenRealisedGains();
    return {
      rows: store.topTenRealisedGains.list.map((item) => {
        return [
          item.symbol,
          item.soldValue,
          item.realisedGain,
          item.changePercentage,
          item.irr,
        ];
      }),
      totalItems: store.topTenHoldings.list.length,
    };
  };

  const getSortByName = (): string => {
    if (
      store.topTenHoldings.sortBy ===
      GetEquityTopTenHoldingsRPC.RequestEnums.Sort.Sort.CURRENT_VALUE
    ) {
      return t("equity.dashboard.holdingTableSortColumns.value");
    } else {
      return t("equity.dashboard.holdingTableSortColumns.gain");
    }
  };
  const holdingsFilter = (): React.ReactElement => {
    return (
      <MenuButton
        label={t("common.sortBy") + getSortByName()}
        buttonSize={"small"}
        color={tokens.label}
        menuFullWidth={true}
        menuItems={[
          {
            label: t("equity.dashboard.holdingTableSortColumns.gain"),
            onClick: async (): Promise<void> => {
              store.topTenHoldings.onSortByChange(
                GetEquityTopTenHoldingsRPC.RequestEnums.Sort.Sort.GAIN,
              );
              holdingTableRef.current?.reload();
            },
          },
          {
            label: t("equity.dashboard.holdingTableSortColumns.value"),
            onClick: async (): Promise<void> => {
              store.topTenHoldings.onSortByChange(
                GetEquityTopTenHoldingsRPC.RequestEnums.Sort.Sort.CURRENT_VALUE,
              );
              holdingTableRef.current?.reload();
            },
          },
        ]}
      />
    );
  };

  const realisedGainsFilter = (): React.ReactElement => {
    return (
      <Stack>
        <DurationToggleButton
          value={
            store.topTenRealisedGains.dateRange as
              | ReportDateRange
              | Instance<typeof DateRangeModel>
          }
          onChange={(value): void => {
            store.topTenRealisedGains.onDateRangeChange(value);
            realisedGainsTableRef.current?.reload();
          }}
          size={"small"}
        />
      </Stack>
    );
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator isLoading={isLoading} />
      ) : (
        <DashboardContainer
          elements={[
            [<DashboardStats key={"equityStats"} stats={getStats()} />],
            [
              <DashboardLineGraph
                key={"equityReturnVsBenchmark"}
                title={t("dashboard.returnVsBenchmarkTitle")}
                series={getReturnVsBenchmark()}
                duration={
                  store.benchmarkReturns.dateRange as
                    | ReportDateRange
                    | Instance<typeof DateRangeModel>
                }
                isLoading={store.benchmarkReturns.isLoading}
                onDurationChange={(value): void => {
                  store.benchmarkReturns.onDateRangeChange(value);
                  if (
                    typeof value === "string" ||
                    (typeof value === "object" &&
                      value.startDate &&
                      value.endDate)
                  ) {
                    store.benchmarkReturns.getEquityBenchmarkReturnsGraph();
                  }
                }}
              />,
            ],
            [
              <Stack
                key={"TopHoldings"}
                direction={"row"}
                spacing={spacing.spaceXL}
                width={Size.width}
              >
                <Stack flexBasis={0}>
                  <DashboardDoughnutGraph
                    key={"equityHoldingDistribution"}
                    title={t("equity.dashboard.holdingsDistributionTitle")}
                    series={getHoldingDistribution()}
                  />
                </Stack>
                <DashboardTable
                  key={"equityTopHoldings"}
                  title={t("dashboard.top10holdingsLabel")}
                  headers={holdingTableHeaders}
                  getRows={getHoldings}
                  tableRef={holdingTableRef}
                  filter={holdingsFilter()}
                  onViewAll={(): void => {
                    navigate(getPath(Module.Equity, Route.HoldingSummary));
                  }}
                />
              </Stack>,
            ],
            [
              <DashboardTable
                key={"equityRealisedGains"}
                title={t("dashboard.realisedGainLabel")}
                total={store.topTenRealisedGains.totalRealisedGains}
                percentage={
                  store.topTenRealisedGains.totalRealisedGainsPercentage
                }
                headers={realisedGainsTableHeaders}
                getRows={getRealisedGains}
                tableRef={realisedGainsTableRef}
                filter={realisedGainsFilter()}
                onViewAll={(): void => {
                  navigate(getPath(Module.Equity, Route.ChangeInGain));
                }}
              />,
            ],
          ]}
        />
      )}
    </>
  );
});
