import { Stack, Typography } from "@mui/material";
import {
  Card,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBorder } from "../../../../../utils/BorderUtils";
import { Instance } from "mobx-state-tree";
import { SchemeDetailsModel } from "../models/SchemeDetailsModel";

const Size = {
  cardWidth: "388px",
  cardHeading: "48px",
  cardContent: "124px",
  cardDivider: "40px",
  valueWidth: "200px",
};
export interface SchemeDetailsProps {
  store: Instance<typeof SchemeDetailsModel>;
}
export const SchemeDetails = ({
  store,
}: SchemeDetailsProps): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();

  const CardRow = ({
    heading,
    value,
  }: {
    heading: string;
    value: string | undefined;
  }): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={spacing.spaceXXS}
      >
        <Typography sx={{ ...typography.b2, color: tokens.label }}>
          {heading}
        </Typography>
        <Typography
          sx={{
            ...typography.b2,
            color: tokens.label,
            width: Size.valueWidth,
            textAlign: "right",
          }}
        >
          {value ?? "-"}
        </Typography>
      </Stack>
    );
  };

  return (
    <Card
      style={{
        width: Size.cardWidth,
        background: tokens.background,
      }}
    >
      <Stack flexDirection="column" borderRadius={cornerRadius.radiusXXS}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: tokens.backgroundSubtle,
            borderBottom: border.default,
            borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
          }}
        >
          <Typography
            sx={{
              typography: { ...typography.sh3 },
              color: tokens.label,
              height: Size.cardHeading,
              padding: `${spacing.spaceSM} ${spacing.spaceMD}`,
              borderRadius: `${cornerRadius.radiusXXS} ${cornerRadius.radiusXXS} 0 0`,
            }}
          >
            {t("mf.fields.schemeDetailsLabel")}
          </Typography>
        </Stack>
        <Stack padding={spacing.spaceMD}>
          <CardRow heading={t("mf.fields.isinLabel")} value={store?.isin} />
          <CardRow heading={t("mf.fields.amcLabel")} value={store?.amc} />
          <CardRow
            heading={t("mf.fields.schemeTypeLabel")}
            value={store?.schemeType}
          />
          <CardRow
            heading={t("mf.fields.planTypeLabel")}
            value={store?.planType}
          />
          <CardRow
            heading={t("mf.fields.dividendTypeLabel")}
            value={store?.dividendType}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
