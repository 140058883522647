import { GetMFSellPortfolioListRPC, Portfolio } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFSellPortfolioListRPCImpl extends GetMFSellPortfolioListRPC {
  execute(
    _request: GetMFSellPortfolioListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFSellPortfolioListRPC.Response,
      GetMFSellPortfolioListRPC.Errors.Errors
    >
  > {
    const response = new GetMFSellPortfolioListRPC.Response([
      new Portfolio(new LeoUUID(), "Compounding"),
      new Portfolio(new LeoUUID(), "Opportunities"),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFSellPortfolioListRPC.Response,
        GetMFSellPortfolioListRPC.Errors.Errors
      >
    >;
  }
}
