"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettleFiMaturityRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const fiMaturityCharge_1 = require("./fiMaturityCharge");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SettleFiMaturityRequestRPC {
}
exports.SettleFiMaturityRequestRPC = SettleFiMaturityRequestRPC;
(function (SettleFiMaturityRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SettleFiMaturityRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId, settlementReferenceNumber = null, maturitySettlementDate, couponReceivedOnMaturity = null, charges = null, recipientBankId, recipientAccountNumber, note = null) {
            this.isSettleFiMaturityRequestRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
            if (settlementReferenceNumber !== undefined && settlementReferenceNumber !== null) {
                Request.validateSettlementReferenceNumber(settlementReferenceNumber);
            }
            this.settlementReferenceNumber = settlementReferenceNumber;
            Request.validateMaturitySettlementDate(maturitySettlementDate);
            this.maturitySettlementDate = maturitySettlementDate;
            if (couponReceivedOnMaturity !== undefined && couponReceivedOnMaturity !== null) {
                Request.validateCouponReceivedOnMaturity(couponReceivedOnMaturity);
            }
            this.couponReceivedOnMaturity = couponReceivedOnMaturity;
            if (charges !== undefined && charges !== null) {
                Request.validateCharges(charges);
            }
            this.charges = charges;
            Request.validateRecipientBankId(recipientBankId);
            this.recipientBankId = recipientBankId;
            Request.validateRecipientAccountNumber(recipientAccountNumber);
            this.recipientAccountNumber = recipientAccountNumber;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            const settlementReferenceNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "settlementReferenceNumber", InvalidRequestError);
            const maturitySettlementDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "maturitySettlementDate", InvalidRequestError);
            let couponReceivedOnMaturity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "couponReceivedOnMaturity");
            if (couponReceivedOnMaturity !== undefined && couponReceivedOnMaturity !== null) {
                couponReceivedOnMaturity = bigDecimal_1.BigDecimal.fromDTO(couponReceivedOnMaturity);
            }
            const charges = (0, leo_ts_runtime_1.getOptionalList)(dto, "charges", fiMaturityCharge_1.FiMaturityCharge.fromDTO, InvalidRequestError);
            const recipientBankId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "recipientBankId", InvalidRequestError);
            const recipientAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "recipientAccountNumber", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(requestId, settlementReferenceNumber, maturitySettlementDate, couponReceivedOnMaturity, charges, recipientBankId, recipientAccountNumber, note);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
                maturitySettlementDate: this.maturitySettlementDate.toDTO(),
                recipientBankId: this.recipientBankId.toDTO(),
                recipientAccountNumber: this.recipientAccountNumber,
            };
            if (this.settlementReferenceNumber !== undefined && this.settlementReferenceNumber !== null) {
                ret.settlementReferenceNumber = this.settlementReferenceNumber;
            }
            if (this.couponReceivedOnMaturity) {
                ret.couponReceivedOnMaturity = this.couponReceivedOnMaturity.toDTO();
            }
            if (this.charges) {
                ret.charges = this.charges.map((e) => {
                    return e.toDTO();
                });
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(requestId = this.requestId, settlementReferenceNumber = this.settlementReferenceNumber, maturitySettlementDate = this.maturitySettlementDate, couponReceivedOnMaturity = this.couponReceivedOnMaturity, charges = this.charges, recipientBankId = this.recipientBankId, recipientAccountNumber = this.recipientAccountNumber, note = this.note) {
            return new Request(requestId, settlementReferenceNumber, maturitySettlementDate, couponReceivedOnMaturity, charges, recipientBankId, recipientAccountNumber, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSettleFiMaturityRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.settlementReferenceNumber !== other.settlementReferenceNumber) {
                return false;
            }
            if (!this.maturitySettlementDate.equals(other.maturitySettlementDate)) {
                return false;
            }
            if (this.couponReceivedOnMaturity) {
                if (!this.couponReceivedOnMaturity.equals(other.couponReceivedOnMaturity)) {
                    return false;
                }
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
                return false;
            }
            if (!this.recipientBankId.equals(other.recipientBankId)) {
                return false;
            }
            if (this.recipientAccountNumber !== other.recipientAccountNumber) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    Request.validateSettlementReferenceNumber = (settlementReferenceNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(settlementReferenceNumber)) {
            throw new InvalidRequestError(`Attribute settlementReferenceNumber is not a String`);
        }
        if (settlementReferenceNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute settlementReferenceNumber cannot be an empty string.`);
        }
    };
    Request.validateMaturitySettlementDate = (maturitySettlementDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(maturitySettlementDate)) {
            throw new InvalidRequestError(`Attribute maturitySettlementDate is not a Date`);
        }
    };
    Request.validateCouponReceivedOnMaturity = (couponReceivedOnMaturity) => {
        const isBigDecimalValue = couponReceivedOnMaturity.isBigDecimal;
        if (!(couponReceivedOnMaturity instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidRequestError(`Attribute couponReceivedOnMaturity is not a BigDecimal`);
        }
    };
    Request.validateCharges = (charges) => {
        if (!Array.isArray(charges)) {
            throw new InvalidRequestError(`Attribute charges is not a List`);
        }
        for (let i = 0; i < charges.length; i += 1) {
            const isFiMaturityChargeValue = charges[i].isFiMaturityCharge;
            if (!(charges[i] instanceof fiMaturityCharge_1.FiMaturityCharge || Boolean(isFiMaturityChargeValue))) {
                throw new InvalidRequestError(`Attribute charges at index ${i} is not a FiMaturityCharge.`);
            }
        }
    };
    Request.validateRecipientBankId = (recipientBankId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(recipientBankId)) {
            throw new InvalidRequestError(`Attribute recipientBankId is not a UUID`);
        }
    };
    Request.validateRecipientAccountNumber = (recipientAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(recipientAccountNumber)) {
            throw new InvalidRequestError(`Attribute recipientAccountNumber is not a String`);
        }
        if (recipientAccountNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute recipientAccountNumber cannot be an empty string.`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SettleFiMaturityRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SettleFiMaturityRequestRPC.Request(requestId=${this.requestId},settlementReferenceNumber='${this.settlementReferenceNumber}',maturitySettlementDate=${this.maturitySettlementDate},couponReceivedOnMaturity=${this.couponReceivedOnMaturity},charges=${this.charges},recipientBankId=${this.recipientBankId},recipientAccountNumber='${this.recipientAccountNumber}',note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SettleFiMaturityRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SettleFiMaturityRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `SettleFiMaturityRequestRPC.INVALID_REQUEST_ID()`;
        };
        class RequestAlreadySettled extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_ALREADY_SETTLED") {
                super(code);
                this.isRequestAlreadySettled = true;
                RequestAlreadySettled.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestAlreadySettled(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestAlreadySettled(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestAlreadySettledValue = other.isRequestAlreadySettled;
                if (!(other instanceof RequestAlreadySettled || Boolean(isRequestAlreadySettledValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestAlreadySettled.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_ALREADY_SETTLED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestAlreadySettled = RequestAlreadySettled;
        RequestAlreadySettled.prototype.toString = function toString() {
            return `SettleFiMaturityRequestRPC.REQUEST_ALREADY_SETTLED()`;
        };
        class InvalidBankId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BANK_ID") {
                super(code);
                this.isInvalidBankId = true;
                InvalidBankId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBankId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBankId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBankIdValue = other.isInvalidBankId;
                if (!(other instanceof InvalidBankId || Boolean(isInvalidBankIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBankId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BANK_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBankId = InvalidBankId;
        InvalidBankId.prototype.toString = function toString() {
            return `SettleFiMaturityRequestRPC.INVALID_BANK_ID()`;
        };
        class InvalidBankAccountNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BANK_ACCOUNT_NUMBER") {
                super(code);
                this.isInvalidBankAccountNumber = true;
                InvalidBankAccountNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBankAccountNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBankAccountNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBankAccountNumberValue = other.isInvalidBankAccountNumber;
                if (!(other instanceof InvalidBankAccountNumber || Boolean(isInvalidBankAccountNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBankAccountNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BANK_ACCOUNT_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBankAccountNumber = InvalidBankAccountNumber;
        InvalidBankAccountNumber.prototype.toString = function toString() {
            return `SettleFiMaturityRequestRPC.INVALID_BANK_ACCOUNT_NUMBER()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "REQUEST_ALREADY_SETTLED":
                    return RequestAlreadySettled.fromDTO(dto);
                case "INVALID_BANK_ID":
                    return InvalidBankId.fromDTO(dto);
                case "INVALID_BANK_ACCOUNT_NUMBER":
                    return InvalidBankAccountNumber.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isRequestAlreadySettledValue = other.isRequestAlreadySettled;
            if (other instanceof RequestAlreadySettled || Boolean(isRequestAlreadySettledValue)) {
                return true;
            }
            const isInvalidBankIdValue = other.isInvalidBankId;
            if (other instanceof InvalidBankId || Boolean(isInvalidBankIdValue)) {
                return true;
            }
            const isInvalidBankAccountNumberValue = other.isInvalidBankAccountNumber;
            if (other instanceof InvalidBankAccountNumber || Boolean(isInvalidBankAccountNumberValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SettleFiMaturityRequestRPC.Errors || (SettleFiMaturityRequestRPC.Errors = {}));
})(SettleFiMaturityRequestRPC = exports.SettleFiMaturityRequestRPC || (exports.SettleFiMaturityRequestRPC = {}));
