import { Instance, types } from "mobx-state-tree";
import { AmountModel, createAmountModel } from "../../../models/AmountModel";
import {
  BuySettlementDetails,
  EquitySettlementPriceDetails,
  SellSettlementDetails,
  SettlementDetails,
} from "@khazana/khazana-rpcs";

const SettlementBuyAmountDetailsModel = types.model({
  requestedAmount: AmountModel,
  executedAmount: AmountModel,
  pendingAmount: types.maybeNull(AmountModel),
});

const SettlementSellAmountDetailsModel = types.model({
  requestedAmount: AmountModel,
  approxQuantityRequested: types.number,
  soldQuantity: types.number,
  unsoldQuantity: types.maybeNull(types.number),
});

const SettlementAmountDetailsModel = types.model({
  settlementBuyAmountDetailsModel: types.maybeNull(
    SettlementBuyAmountDetailsModel,
  ),
  settlementSellAmountDetailsModel: types.maybeNull(
    SettlementSellAmountDetailsModel,
  ),
});

const SettlementBuyQuantityDetailsModel = types.model({
  requestedQuantity: types.number,
  executedUnits: types.number,
  pendingUnits: types.maybeNull(types.number),
});

const SettlementSellQuantityDetailsModel = types.model({
  requestedQuantity: types.number,
  soldQuantity: types.number,
  unsoldQuantity: types.maybeNull(types.number),
});

const SettlementQuantityDetailsModel = types.model({
  settlementBuyQuantityDetailsModel: types.maybeNull(
    SettlementBuyQuantityDetailsModel,
  ),
  settlementSellQuantityDetailsModel: types.maybeNull(
    SettlementSellQuantityDetailsModel,
  ),
});

const SettlementPriceDetails = types.model({
  requestedPricePerUnit: types.maybeNull(AmountModel),
  executedPricePerUnit: AmountModel,
});

export const SettlementDetailsModel = types.model({
  settlementAmountDetails: types.maybeNull(SettlementAmountDetailsModel),
  settlementQuantityDetails: types.maybeNull(SettlementQuantityDetailsModel),
  settlementPriceDetails: SettlementPriceDetails,
});

export const createSettlementDetailsModel = (
  settlementDetails: SettlementDetails.SettlementDetails,
  priceDetails: EquitySettlementPriceDetails,
): Instance<typeof SettlementDetailsModel> | undefined => {
  const settlementPriceDetails = SettlementPriceDetails.create({
    requestedPricePerUnit:
      priceDetails.requestedPricePerUnit !== null
        ? createAmountModel(priceDetails.requestedPricePerUnit)
        : null,
    executedPricePerUnit: createAmountModel(priceDetails.executedPricePerUnit),
  });
  if (settlementDetails instanceof SettlementDetails.BuySettlementDetail) {
    if (
      settlementDetails.buySettlementDetail instanceof
      BuySettlementDetails.DealAmount
    ) {
      return SettlementDetailsModel.create({
        settlementAmountDetails: SettlementAmountDetailsModel.create({
          settlementBuyAmountDetailsModel:
            SettlementBuyAmountDetailsModel.create({
              requestedAmount: createAmountModel(
                settlementDetails.buySettlementDetail.requestedAmount,
              ),
              executedAmount: createAmountModel(
                settlementDetails.buySettlementDetail.executedAmount,
              ),
              pendingAmount:
                settlementDetails.buySettlementDetail.pendingAmount === null
                  ? null
                  : createAmountModel(
                      settlementDetails.buySettlementDetail.pendingAmount,
                    ),
            }),
        }),
        settlementPriceDetails,
      });
    } else if (
      settlementDetails.buySettlementDetail instanceof
      BuySettlementDetails.DealQuantity
    ) {
      return SettlementDetailsModel.create({
        settlementQuantityDetails: SettlementQuantityDetailsModel.create({
          settlementBuyQuantityDetailsModel:
            SettlementBuyQuantityDetailsModel.create({
              executedUnits:
                settlementDetails.buySettlementDetail.executedQuantity.quantity,
              requestedQuantity:
                settlementDetails.buySettlementDetail.requestedQuantity
                  .quantity,
              pendingUnits:
                settlementDetails.buySettlementDetail.pendingQuantity?.quantity,
            }),
        }),
        settlementPriceDetails,
      });
    }
  } else if (
    settlementDetails instanceof SettlementDetails.SellSettlementDetail
  ) {
    if (
      settlementDetails.sellSettlementDetail instanceof
      SellSettlementDetails.DealAmount
    ) {
      return SettlementDetailsModel.create({
        settlementAmountDetails: SettlementAmountDetailsModel.create({
          settlementSellAmountDetailsModel:
            SettlementSellAmountDetailsModel.create({
              requestedAmount: createAmountModel(
                settlementDetails.sellSettlementDetail.requestedAmount,
              ),
              soldQuantity:
                settlementDetails.sellSettlementDetail.soldQuantity.quantity,
              approxQuantityRequested:
                settlementDetails.sellSettlementDetail.approxQuantityRequested
                  .quantity,
              unsoldQuantity:
                settlementDetails.sellSettlementDetail.unsoldQuantity?.quantity,
            }),
        }),
        settlementPriceDetails,
      });
    } else if (
      settlementDetails.sellSettlementDetail instanceof
      SellSettlementDetails.DealQuantity
    ) {
      return SettlementDetailsModel.create({
        settlementQuantityDetails: SettlementQuantityDetailsModel.create({
          settlementSellQuantityDetailsModel:
            SettlementSellQuantityDetailsModel.create({
              soldQuantity:
                settlementDetails.sellSettlementDetail.soldQuantity.quantity,
              requestedQuantity:
                settlementDetails.sellSettlementDetail.requestedQuantity
                  .quantity,
              unsoldQuantity:
                settlementDetails.sellSettlementDetail.unsoldQuantity?.quantity,
            }),
        }),
        settlementPriceDetails,
      });
    }
  }
  console.error(
    `Type of ${settlementDetails} is not handeled in createSettlementDetailsModel`,
  );
};
