import React from "react";
import { RouteObject } from "react-router-dom";
import { FiUserPrivileges } from "../modules/user/UserPrivileges";
import { CreateFiDealRequest } from "../modules/home/fixed-income/deal-request/pages/CreateFiDealRequest";
import { Module, Route } from "./RoutesEnum";
import { ManageDealRequests } from "../modules/home/fixed-income/deal-request/pages/ManageDealRequests";
import { ViewFiDealRequestDetails } from "../modules/home/fixed-income/deal-request/pages/ViewFiDealRequestDetails";
import { FiManageContractNotes } from "../modules/home/fixed-income/contract-note/pages/FiManageContractNotes";
import { FiContractNoteDetails } from "../modules/home/fixed-income/contract-note/pages/FiContractNoteDetails";
import { FiCreatePaymentRequest } from "../modules/home/fixed-income/contract-note/pages/FiCreatePaymentRequest";
import { FiReportList } from "../modules/home/fixed-income/reports/pages/FiReportList";
import { FiInvestmentRegister } from "../modules/home/fixed-income/reports/pages/FiInvestmentRegister";
import { FiHoldingSummary } from "../modules/home/fixed-income/reports/pages/FiHoldingSummary";
import { ViewFiInvestmentCashFlows } from "../modules/home/fixed-income/contract-note/pages/FiViewInvestmentCashFlows";
import { ManageFiMaturityDealRequests } from "../modules/home/fixed-income/deal-request/pages/ManageFiMaturityDealRequests";
import { ViewFiMaturityRequestDetails } from "../modules/home/fixed-income/deal-request/pages/ViewFiMaturityRequestDetails";
import { SettleMaturityRequest } from "../modules/home/fixed-income/deal-request/pages/SettleMaturityRequest";

export const getFiRoutes = (userPrivileges: string[]): RouteObject[] => {
  const fiChildren: RouteObject[] = [];
  const fiPath = Module.Fi;

  if (userPrivileges.includes(FiUserPrivileges.CreateFixedIncomeDealRequest)) {
    const createDealRequestRoute: RouteObject = {
      path: Route.CreateDealRequest,
      element: <CreateFiDealRequest />,
    };
    fiChildren.push(createDealRequestRoute);
  }

  if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeDealRequest)) {
    const viewDealRequestsRoute: RouteObject = {
      path: Route.ManageDealRequest,
      element: <ManageDealRequests />,
    };
    fiChildren.push(viewDealRequestsRoute);
    const viewFiDealRequestDetailsRoute: RouteObject = {
      path: Route.ManageDealRequestDetails,
      element: <ViewFiDealRequestDetails />,
    };
    fiChildren.push(viewFiDealRequestDetailsRoute);
  }

  if (
    userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeMaturityDealRequest)
  ) {
    const viewMaturityRequestsRoute: RouteObject = {
      path: Route.ManageMaturityRequest,
      element: <ManageFiMaturityDealRequests />,
    };
    fiChildren.push(viewMaturityRequestsRoute);
    const viewMaturityRequestDetailsRoute: RouteObject = {
      path: Route.ManageMaturityRequestDetails,
      element: <ViewFiMaturityRequestDetails />,
    };
    fiChildren.push(viewMaturityRequestDetailsRoute);
    const settleMaturityRequestRoute: RouteObject = {
      path: Route.SettleMaturityDealRequest,
      element: <SettleMaturityRequest />,
    };
    fiChildren.push(settleMaturityRequestRoute);
  }

  if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeContractNote)) {
    const viewContractNotesRoute: RouteObject = {
      path: Route.ManageContractNotes,
      element: <FiManageContractNotes />,
    };
    fiChildren.push(viewContractNotesRoute);
  }

  if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeContractNote)) {
    const fiContractNoteDetailsRoute: RouteObject = {
      path: Route.ContractNotesDetails,
      element: <FiContractNoteDetails />,
    };
    fiChildren.push(fiContractNoteDetailsRoute);
  }

  if (userPrivileges.includes(FiUserPrivileges.UploadFixedIncomeContractNote)) {
    const fiCreatePaymentRequestRoute: RouteObject = {
      path: Route.CreatePaymentRequest,
      element: <FiCreatePaymentRequest />,
    };
    fiChildren.push(fiCreatePaymentRequestRoute);
  }

  if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeCashFlow)) {
    const fiViewCashFlowsRouteContractNote: RouteObject = {
      path: Route.ContractNoteInvestmentCashFlows,
      element: <ViewFiInvestmentCashFlows />,
    };
    fiChildren.push(fiViewCashFlowsRouteContractNote);
    const fiViewCashFlowsRouteMaturity: RouteObject = {
      path: Route.MaturityInvestmentCashFlows,
      element: <ViewFiInvestmentCashFlows />,
    };
    fiChildren.push(fiViewCashFlowsRouteMaturity);
  }

  if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeReports)) {
    const fiReportsListRoute: RouteObject = {
      path: Route.Reports,
      element: <FiReportList />,
    };
    fiChildren.push(fiReportsListRoute);

    const investmentRegister: RouteObject = {
      path: Route.InvestmentRegisterReport,
      element: <FiInvestmentRegister />,
    };
    fiChildren.push(investmentRegister);

    const holdingSummary: RouteObject = {
      path: Route.HoldingSummary,
      element: <FiHoldingSummary />,
    };
    fiChildren.push(holdingSummary);
  }

  if (fiChildren.length === 0) {
    return [];
  } else {
    return [
      {
        path: fiPath,
        children: fiChildren,
      },
    ];
  }
};
