import {
  Dialog,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography, CircularProgress } from "@mui/material";
import { observer } from "mobx-react";
import successIcon from "../../../../assets/check-circle.svg";
import errorIcon from "../../../../assets/alert-circle.svg";
import { FileUploadError } from "../../../../utils/FileUploadUtils";
import { FileUploadComponent } from "../FileUploadComponent";
import { useDocumentStore } from "../../store/hooks";
import { FileUploadStore } from "../../store/FileUploadStore";
import { Instance } from "mobx-state-tree";

export interface FileUploadDialogProps {
  title: string;
  showUploadNoteDialog: boolean;
  onSuccess: (url: string | undefined, sha256: string) => void;
  onUploadNoteDialogClose: () => void;
  store: Instance<typeof FileUploadStore>;
}

const enum FileUploadState {
  Upload,
  Processing,
  SubmitError,
}

const Size = {
  stateComponent: "50%",
  icon: "20px",
  dialog: "100%",
  dialogTextField: "520px",
  largeIcon: "32px",
};

export const FileUploadDialog = observer(
  ({
    title,
    showUploadNoteDialog,
    onUploadNoteDialogClose,
    onSuccess,
    store,
  }: FileUploadDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const [fileName, setFileName] = useState<string | null>();
    const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
    const [isFileUploadComplete, setIsFileUploadComplete] = useState(false);
    const [fileUploadErrorLabel, setFileUploadErrorLabel] = useState<
      string | null
    >();
    const [dialogState, setDialogState] = useState(FileUploadState.Upload);
    const documentStore = useDocumentStore();

    const getFileUploadErrorLabel = (error: FileUploadError): string => {
      switch (error) {
        case FileUploadError.MaxFileSizeExceeded:
          return t("common.maxFileSizeExceeded");
        case FileUploadError.MinFileSizeDidNotExceed:
          return t("common.minSizeLimitDidNotExceed");
        case FileUploadError.InvalidFileName:
          return t("common.invalidFileName");
        case FileUploadError.InvalidFileFormat:
          return t("common.invalidFileFormat");
        default:
          return t("common.uploadFail");
      }
    };

    const isFileUploadErrorLabel = (): boolean => {
      return Boolean(fileUploadErrorLabel);
    };

    const onFileUploadError = (error: FileUploadError): void => {
      setIsFileUploadComplete(true);
      setFileUploadErrorLabel(getFileUploadErrorLabel(error));
      setIsCloseIconPresent(true);
    };

    const onFileUploadSuccess = (): void => {
      setIsFileUploadComplete(true);
      setIsCloseIconPresent(true);
    };

    const onFileUploadBegin = (_fileName: string): void => {
      setIsCloseIconPresent(false);
      setFileUploadErrorLabel(null);
      setIsFileUploadComplete(false);
      setFileName(_fileName);
    };

    const getFileUploadComponent = (): React.ReactElement => {
      return (
        <FileUploadComponent
          onFileUploadBegin={onFileUploadBegin}
          onFileUploadError={onFileUploadError}
          onFileUploadSuccess={onFileUploadSuccess}
          store={store}
        />
      );
    };

    const getFileUploadLoadingComponent = (): React.ReactElement => {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: Size.stateComponent,
          }}
        >
          <CircularProgress
            size={Size.icon}
            sx={{
              color: tokens.iconPrimary,
            }}
          />
          <Typography
            sx={{
              ...typography.b1,
              color: tokens.labelPrimary,
              marginLeft: spacing.spaceXXS,
              whiteSpace: "nowrap",
            }}
          >
            {t("common.uploadingFile")}
          </Typography>
        </Stack>
      );
    };

    const getFileUploadSuccessfulComponent = (): React.ReactElement => {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: Size.stateComponent,
          }}
        >
          <img
            src={successIcon}
            style={{ height: Size.icon, width: Size.icon }}
          />
          <Typography
            sx={{
              ...typography.b1,
              color: tokens.labelSuccess,
              marginLeft: spacing.spaceXXS,
              whiteSpace: "nowrap",
            }}
          >
            {t("common.uploadSuccessful")}
          </Typography>
        </Stack>
      );
    };

    const getFileUploadErrorComponent = (): React.ReactElement => {
      return (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: Size.stateComponent,
          }}
        >
          <img
            src={errorIcon}
            style={{ height: Size.icon, width: Size.icon }}
          />
          <Typography
            sx={{
              ...typography.b1,
              color: tokens.labelError,
              marginLeft: spacing.spaceXXS,
              whiteSpace: "nowrap",
            }}
          >
            {fileUploadErrorLabel}
          </Typography>
        </Stack>
      );
    };

    const getFileDetailsComponent = (): React.ReactElement => {
      return (
        <Stack>
          <Typography
            sx={{ ...typography.s1 }}
            color={tokens.labelHighEmphasis}
          >
            {t("common.fileDetails")}
          </Typography>
          <Stack marginTop={spacing.spaceXXS}>
            {fileName && (
              <Typography
                sx={{
                  ...typography.b1,
                }}
                color={tokens.label}
              >
                {fileName}
              </Typography>
            )}
            {!isFileUploadComplete && getFileUploadLoadingComponent()}
            {isFileUploadComplete &&
              !isFileUploadErrorLabel() &&
              getFileUploadSuccessfulComponent()}
            {fileUploadErrorLabel && getFileUploadErrorComponent()}
          </Stack>
        </Stack>
      );
    };

    const resetComponentStates = (): void => {
      setFileName(null);
      store.resetStore();
      setFileUploadErrorLabel(null);
      setIsFileUploadComplete(false);
      setIsCloseIconPresent(true);
      setDialogState(FileUploadState.Upload);
    };

    const getSecondaryButtonText = (): string | undefined => {
      switch (dialogState) {
        case FileUploadState.SubmitError:
          return t("common.close");
        default:
          return undefined;
      }
    };

    const getDialogChildren = (): React.ReactElement => {
      switch (dialogState) {
        case FileUploadState.Upload:
          return (
            <Stack spacing={spacing.spaceMD} width={Size.dialog}>
              {(!fileName || isFileUploadErrorLabel()) &&
                getFileUploadComponent()}
              {fileName && getFileDetailsComponent()}
            </Stack>
          );
        case FileUploadState.Processing:
          return (
            <Stack
              justifyContent="center"
              alignItems="center"
              margin={spacing.spaceSM}
              spacing={spacing.spaceXS}
              width={Size.dialog}
            >
              <CircularProgress
                size={Size.largeIcon}
                sx={{
                  color: tokens.iconPrimary,
                }}
              />
              <Typography
                sx={{
                  ...typography.b1,
                  color: tokens.labelPrimary,
                }}
              >
                {t("common.processingFilePleaseWait", {
                  fileName,
                })}
              </Typography>
            </Stack>
          );
        case FileUploadState.SubmitError:
          return (
            <Stack
              justifyContent="center"
              alignItems="center"
              margin={spacing.spaceSM}
              spacing={spacing.spaceXS}
              width={Size.dialog}
            >
              <img
                src={errorIcon}
                style={{
                  height: Size.largeIcon,
                  width: Size.largeIcon,
                }}
              />
              <Typography
                sx={{
                  ...typography.b1,
                  color: tokens.labelError,
                }}
              >
                {t(
                  "fd.depositCertificate.failedToUploadAndSubmitDepositCertificateError",
                )}
              </Typography>
            </Stack>
          );
      }
    };

    const onClose = (): void => {
      onUploadNoteDialogClose();
      resetComponentStates();
    };

    return (
      <Dialog
        open={showUploadNoteDialog}
        title={title}
        primaryButtonText={
          dialogState === FileUploadState.Upload
            ? t("common.submit")
            : undefined
        }
        onPrimaryButtonClick={async (): Promise<void> => {
          setDialogState(FileUploadState.Processing);
          if (store.sha256) {
            const url = await documentStore.getPresignedDownloadUrl(
              store.sha256,
            );
            onSuccess(url, store.sha256);
          }
          onClose();
        }}
        isPrimaryButtonDisabled={
          !isFileUploadComplete || isFileUploadErrorLabel()
        }
        secondaryButtonText={getSecondaryButtonText()}
        onSecondaryButtonClick={onClose}
        isCloseIconPresent={isCloseIconPresent}
        onClose={onClose}
        disableBackdropClick={true}
        style={{
          borderRadius: `${cornerRadius.radiusXXS}px`,
        }}
      >
        {getDialogChildren()}
      </Dialog>
    );
  },
);
