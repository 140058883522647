import React from "react";

import { DatePicker, DatePickerInput, DatesRangeValue } from "@mantine/dates";
import { ButtonSize } from "@surya-digital/leo-reactjs-core";
import { CalendarRange } from "../../../../assets/CalendarRange";
import { Button, IconButton, Menu, Tooltip } from "@mui/material";
import { getFormattedDate } from "../../../../utils";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";

export const Size = {
  calendarSmall: "16px",
  calendarMedium: "24px",
};

interface KHDateRangePickerProps {
  label?: string | React.ReactElement<React.SVGProps<SVGSVGElement>>;
  amountOfMonths?: number;
  type: "INPUT" | "BUTTON";
  buttonVariant?: "text" | "outlined" | "contained" | undefined;
  buttonSize?: ButtonSize;
  value?: DatesRangeValue;
  onChange?: (dateRange: DatesRangeValue) => void;
}

// NOTE: This component is only checked for the date range for icon button type. For other types the styling has to be updated.
export const KHDateRangePicker = ({
  label,
  amountOfMonths,
  type = "INPUT",
  buttonVariant,
  buttonSize,
  value,
  onChange,
}: KHDateRangePickerProps): React.ReactElement => {
  const ref = React.useRef<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const getTooltipText = (): string | undefined => {
    if (value) {
      if (value[0] && value[1]) {
        return `${getFormattedDate(value[0])} - ${getFormattedDate(value[1])}`;
      } else if (value[0]) {
        return `${getFormattedDate(value[0])} -`;
      } else if (value[1]) {
        return `- ${getFormattedDate(value[1])}`;
      }
    }
  };

  if (type === "BUTTON") {
    return (
      <MantineProvider>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <DatePicker
            type={"range"}
            numberOfColumns={amountOfMonths}
            value={value ?? [null, null]}
            onChange={(_value): void => {
              if (onChange) {
                onChange(_value);
              }
            }}
          />
        </Menu>
        <Tooltip title={getTooltipText()}>
          {typeof label === "string" ? (
            <Button
              ref={ref as React.RefObject<HTMLButtonElement>}
              title={label}
              name={label}
              size={buttonSize ?? "medium"}
              variant={buttonVariant ?? "text"}
              onClick={handleClick}
            />
          ) : (
            <IconButton
              ref={ref as React.RefObject<HTMLButtonElement>}
              name={"datePickerIcon"}
              size={buttonSize ?? "medium"}
              onClick={handleClick}
              style={{ padding: "0px" }}
            >
              {
                <CalendarRange
                  height={
                    buttonSize === "small"
                      ? Size.calendarSmall
                      : Size.calendarMedium
                  }
                  width={
                    buttonSize === "small"
                      ? Size.calendarSmall
                      : Size.calendarMedium
                  }
                />
              }
            </IconButton>
          )}
        </Tooltip>
      </MantineProvider>
    );
  } else {
    return (
      <MantineProvider>
        <DatePickerInput
          type={"range"}
          label={label}
          numberOfColumns={amountOfMonths}
          value={value}
          onChange={(_value): void => {
            if (onChange) {
              onChange(_value);
            }
          }}
        />
      </MantineProvider>
    );
  }
};
