"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedDepositInvestmentRegisterResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedDepositType_1 = require("./fixedDepositType");
const fixedDepositStatus_1 = require("./fixedDepositStatus");
class InvalidFixedDepositInvestmentRegisterResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedDepositInvestmentRegisterResponseError";
    }
}
class FixedDepositInvestmentRegisterResponse {
    constructor(investmentId, depositNumber = null, bank = null, openingAmount = null, purchaseAmount = null, maturedFDAmount = null, closingAmount = null, fdType = null, days = null, interestRate = null, startDate = null, maturityDate = null, isCallable = null, status = null, openingTds = null, openingTaxes = null, interestReceived = null, tdsPaid = null, otherTaxesPaid = null, netInterestReceived = null, interestAccrued = null, tdsOnInterestAccrued = null, otherTaxesOnInterestAccrued = null, netInterestReceivable = null) {
        this.isFixedDepositInvestmentRegisterResponse = true;
        FixedDepositInvestmentRegisterResponse.validateInvestmentId(investmentId);
        this.investmentId = investmentId;
        if (depositNumber !== undefined && depositNumber !== null) {
            FixedDepositInvestmentRegisterResponse.validateDepositNumber(depositNumber);
        }
        this.depositNumber = depositNumber;
        if (bank !== undefined && bank !== null) {
            FixedDepositInvestmentRegisterResponse.validateBank(bank);
        }
        this.bank = bank;
        if (openingAmount !== undefined && openingAmount !== null) {
            FixedDepositInvestmentRegisterResponse.validateOpeningAmount(openingAmount);
        }
        this.openingAmount = openingAmount;
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
            FixedDepositInvestmentRegisterResponse.validatePurchaseAmount(purchaseAmount);
        }
        this.purchaseAmount = purchaseAmount;
        if (maturedFDAmount !== undefined && maturedFDAmount !== null) {
            FixedDepositInvestmentRegisterResponse.validateMaturedFDAmount(maturedFDAmount);
        }
        this.maturedFDAmount = maturedFDAmount;
        if (closingAmount !== undefined && closingAmount !== null) {
            FixedDepositInvestmentRegisterResponse.validateClosingAmount(closingAmount);
        }
        this.closingAmount = closingAmount;
        if (fdType !== undefined && fdType !== null) {
            FixedDepositInvestmentRegisterResponse.validateFdType(fdType);
        }
        this.fdType = fdType;
        if (days !== undefined && days !== null) {
            FixedDepositInvestmentRegisterResponse.validateDays(days);
        }
        this.days = days;
        if (interestRate !== undefined && interestRate !== null) {
            FixedDepositInvestmentRegisterResponse.validateInterestRate(interestRate);
        }
        this.interestRate = interestRate;
        if (startDate !== undefined && startDate !== null) {
            FixedDepositInvestmentRegisterResponse.validateStartDate(startDate);
        }
        this.startDate = startDate;
        if (maturityDate !== undefined && maturityDate !== null) {
            FixedDepositInvestmentRegisterResponse.validateMaturityDate(maturityDate);
        }
        this.maturityDate = maturityDate;
        if (isCallable !== undefined && isCallable !== null) {
            FixedDepositInvestmentRegisterResponse.validateIsCallable(isCallable);
        }
        this.isCallable = isCallable;
        if (status !== undefined && status !== null) {
            FixedDepositInvestmentRegisterResponse.validateStatus(status);
        }
        this.status = status;
        if (openingTds !== undefined && openingTds !== null) {
            FixedDepositInvestmentRegisterResponse.validateOpeningTds(openingTds);
        }
        this.openingTds = openingTds;
        if (openingTaxes !== undefined && openingTaxes !== null) {
            FixedDepositInvestmentRegisterResponse.validateOpeningTaxes(openingTaxes);
        }
        this.openingTaxes = openingTaxes;
        if (interestReceived !== undefined && interestReceived !== null) {
            FixedDepositInvestmentRegisterResponse.validateInterestReceived(interestReceived);
        }
        this.interestReceived = interestReceived;
        if (tdsPaid !== undefined && tdsPaid !== null) {
            FixedDepositInvestmentRegisterResponse.validateTdsPaid(tdsPaid);
        }
        this.tdsPaid = tdsPaid;
        if (otherTaxesPaid !== undefined && otherTaxesPaid !== null) {
            FixedDepositInvestmentRegisterResponse.validateOtherTaxesPaid(otherTaxesPaid);
        }
        this.otherTaxesPaid = otherTaxesPaid;
        if (netInterestReceived !== undefined && netInterestReceived !== null) {
            FixedDepositInvestmentRegisterResponse.validateNetInterestReceived(netInterestReceived);
        }
        this.netInterestReceived = netInterestReceived;
        if (interestAccrued !== undefined && interestAccrued !== null) {
            FixedDepositInvestmentRegisterResponse.validateInterestAccrued(interestAccrued);
        }
        this.interestAccrued = interestAccrued;
        if (tdsOnInterestAccrued !== undefined && tdsOnInterestAccrued !== null) {
            FixedDepositInvestmentRegisterResponse.validateTdsOnInterestAccrued(tdsOnInterestAccrued);
        }
        this.tdsOnInterestAccrued = tdsOnInterestAccrued;
        if (otherTaxesOnInterestAccrued !== undefined && otherTaxesOnInterestAccrued !== null) {
            FixedDepositInvestmentRegisterResponse.validateOtherTaxesOnInterestAccrued(otherTaxesOnInterestAccrued);
        }
        this.otherTaxesOnInterestAccrued = otherTaxesOnInterestAccrued;
        if (netInterestReceivable !== undefined && netInterestReceivable !== null) {
            FixedDepositInvestmentRegisterResponse.validateNetInterestReceivable(netInterestReceivable);
        }
        this.netInterestReceivable = netInterestReceivable;
    }
    static fromDTO(dto) {
        const investmentId = (0, leo_ts_runtime_1.getString)(dto, "investmentId", InvalidFixedDepositInvestmentRegisterResponseError);
        const depositNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "depositNumber", InvalidFixedDepositInvestmentRegisterResponseError);
        const bank = (0, leo_ts_runtime_1.getOptionalString)(dto, "bank", InvalidFixedDepositInvestmentRegisterResponseError);
        const openingAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingAmount", InvalidFixedDepositInvestmentRegisterResponseError);
        const purchaseAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "purchaseAmount", InvalidFixedDepositInvestmentRegisterResponseError);
        const maturedFDAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "maturedFDAmount", InvalidFixedDepositInvestmentRegisterResponseError);
        const closingAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "closingAmount", InvalidFixedDepositInvestmentRegisterResponseError);
        let fdType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "fdType");
        if (fdType !== undefined && fdType !== null) {
            fdType = fixedDepositType_1.FixedDepositType.fromDTO(fdType);
        }
        const days = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "days", InvalidFixedDepositInvestmentRegisterResponseError);
        const interestRate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "interestRate", InvalidFixedDepositInvestmentRegisterResponseError);
        const startDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "startDate", InvalidFixedDepositInvestmentRegisterResponseError);
        const maturityDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "maturityDate", InvalidFixedDepositInvestmentRegisterResponseError);
        const isCallable = (0, leo_ts_runtime_1.getOptionalBoolean)(dto, "isCallable", InvalidFixedDepositInvestmentRegisterResponseError);
        let status = (0, leo_ts_runtime_1.getOptionalObject)(dto, "status");
        if (status !== undefined && status !== null) {
            status = fixedDepositStatus_1.FixedDepositStatus.fromDTO(status);
        }
        const openingTds = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingTds", InvalidFixedDepositInvestmentRegisterResponseError);
        const openingTaxes = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingTaxes", InvalidFixedDepositInvestmentRegisterResponseError);
        const interestReceived = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "interestReceived", InvalidFixedDepositInvestmentRegisterResponseError);
        const tdsPaid = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "tdsPaid", InvalidFixedDepositInvestmentRegisterResponseError);
        const otherTaxesPaid = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "otherTaxesPaid", InvalidFixedDepositInvestmentRegisterResponseError);
        const netInterestReceived = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "netInterestReceived", InvalidFixedDepositInvestmentRegisterResponseError);
        const interestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "interestAccrued", InvalidFixedDepositInvestmentRegisterResponseError);
        const tdsOnInterestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "tdsOnInterestAccrued", InvalidFixedDepositInvestmentRegisterResponseError);
        const otherTaxesOnInterestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "otherTaxesOnInterestAccrued", InvalidFixedDepositInvestmentRegisterResponseError);
        const netInterestReceivable = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "netInterestReceivable", InvalidFixedDepositInvestmentRegisterResponseError);
        return new FixedDepositInvestmentRegisterResponse(investmentId, depositNumber, bank, openingAmount, purchaseAmount, maturedFDAmount, closingAmount, fdType, days, interestRate, startDate, maturityDate, isCallable, status, openingTds, openingTaxes, interestReceived, tdsPaid, otherTaxesPaid, netInterestReceived, interestAccrued, tdsOnInterestAccrued, otherTaxesOnInterestAccrued, netInterestReceivable);
    }
    toDTO() {
        const ret = {
            investmentId: this.investmentId,
        };
        if (this.depositNumber !== undefined && this.depositNumber !== null) {
            ret.depositNumber = this.depositNumber;
        }
        if (this.bank !== undefined && this.bank !== null) {
            ret.bank = this.bank;
        }
        if (this.openingAmount !== undefined && this.openingAmount !== null) {
            ret.openingAmount = this.openingAmount;
        }
        if (this.purchaseAmount !== undefined && this.purchaseAmount !== null) {
            ret.purchaseAmount = this.purchaseAmount;
        }
        if (this.maturedFDAmount !== undefined && this.maturedFDAmount !== null) {
            ret.maturedFDAmount = this.maturedFDAmount;
        }
        if (this.closingAmount !== undefined && this.closingAmount !== null) {
            ret.closingAmount = this.closingAmount;
        }
        if (this.fdType !== undefined && this.fdType !== null) {
            ret.fdType = fixedDepositType_1.FixedDepositType.toDTO(this.fdType);
        }
        if (this.days !== undefined && this.days !== null) {
            ret.days = this.days;
        }
        if (this.interestRate !== undefined && this.interestRate !== null) {
            ret.interestRate = this.interestRate;
        }
        if (this.startDate) {
            ret.startDate = this.startDate.toDTO();
        }
        if (this.maturityDate) {
            ret.maturityDate = this.maturityDate.toDTO();
        }
        if (this.isCallable !== undefined && this.isCallable !== null) {
            ret.isCallable = this.isCallable;
        }
        if (this.status !== undefined && this.status !== null) {
            ret.status = fixedDepositStatus_1.FixedDepositStatus.toDTO(this.status);
        }
        if (this.openingTds !== undefined && this.openingTds !== null) {
            ret.openingTds = this.openingTds;
        }
        if (this.openingTaxes !== undefined && this.openingTaxes !== null) {
            ret.openingTaxes = this.openingTaxes;
        }
        if (this.interestReceived !== undefined && this.interestReceived !== null) {
            ret.interestReceived = this.interestReceived;
        }
        if (this.tdsPaid !== undefined && this.tdsPaid !== null) {
            ret.tdsPaid = this.tdsPaid;
        }
        if (this.otherTaxesPaid !== undefined && this.otherTaxesPaid !== null) {
            ret.otherTaxesPaid = this.otherTaxesPaid;
        }
        if (this.netInterestReceived !== undefined && this.netInterestReceived !== null) {
            ret.netInterestReceived = this.netInterestReceived;
        }
        if (this.interestAccrued !== undefined && this.interestAccrued !== null) {
            ret.interestAccrued = this.interestAccrued;
        }
        if (this.tdsOnInterestAccrued !== undefined && this.tdsOnInterestAccrued !== null) {
            ret.tdsOnInterestAccrued = this.tdsOnInterestAccrued;
        }
        if (this.otherTaxesOnInterestAccrued !== undefined && this.otherTaxesOnInterestAccrued !== null) {
            ret.otherTaxesOnInterestAccrued = this.otherTaxesOnInterestAccrued;
        }
        if (this.netInterestReceivable !== undefined && this.netInterestReceivable !== null) {
            ret.netInterestReceivable = this.netInterestReceivable;
        }
        return ret;
    }
    copy(investmentId = this.investmentId, depositNumber = this.depositNumber, bank = this.bank, openingAmount = this.openingAmount, purchaseAmount = this.purchaseAmount, maturedFDAmount = this.maturedFDAmount, closingAmount = this.closingAmount, fdType = this.fdType, days = this.days, interestRate = this.interestRate, startDate = this.startDate, maturityDate = this.maturityDate, isCallable = this.isCallable, status = this.status, openingTds = this.openingTds, openingTaxes = this.openingTaxes, interestReceived = this.interestReceived, tdsPaid = this.tdsPaid, otherTaxesPaid = this.otherTaxesPaid, netInterestReceived = this.netInterestReceived, interestAccrued = this.interestAccrued, tdsOnInterestAccrued = this.tdsOnInterestAccrued, otherTaxesOnInterestAccrued = this.otherTaxesOnInterestAccrued, netInterestReceivable = this.netInterestReceivable) {
        return new FixedDepositInvestmentRegisterResponse(investmentId, depositNumber, bank, openingAmount, purchaseAmount, maturedFDAmount, closingAmount, fdType, days, interestRate, startDate, maturityDate, isCallable, status, openingTds, openingTaxes, interestReceived, tdsPaid, otherTaxesPaid, netInterestReceived, interestAccrued, tdsOnInterestAccrued, otherTaxesOnInterestAccrued, netInterestReceivable);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedDepositInvestmentRegisterResponseValue = other.isFixedDepositInvestmentRegisterResponse;
        if (!(other instanceof FixedDepositInvestmentRegisterResponse) || Boolean(isFixedDepositInvestmentRegisterResponseValue)) {
            return false;
        }
        if (this.investmentId !== other.investmentId) {
            return false;
        }
        if (this.depositNumber !== other.depositNumber) {
            return false;
        }
        if (this.bank !== other.bank) {
            return false;
        }
        if (this.openingAmount !== other.openingAmount) {
            return false;
        }
        if (this.purchaseAmount !== other.purchaseAmount) {
            return false;
        }
        if (this.maturedFDAmount !== other.maturedFDAmount) {
            return false;
        }
        if (this.closingAmount !== other.closingAmount) {
            return false;
        }
        if (this.fdType !== other.fdType) {
            return false;
        }
        if (this.days !== other.days) {
            return false;
        }
        if (this.interestRate !== other.interestRate) {
            return false;
        }
        if (this.startDate) {
            if (!this.startDate.equals(other.startDate)) {
                return false;
            }
        }
        if (this.maturityDate) {
            if (!this.maturityDate.equals(other.maturityDate)) {
                return false;
            }
        }
        if (this.isCallable !== other.isCallable) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.openingTds !== other.openingTds) {
            return false;
        }
        if (this.openingTaxes !== other.openingTaxes) {
            return false;
        }
        if (this.interestReceived !== other.interestReceived) {
            return false;
        }
        if (this.tdsPaid !== other.tdsPaid) {
            return false;
        }
        if (this.otherTaxesPaid !== other.otherTaxesPaid) {
            return false;
        }
        if (this.netInterestReceived !== other.netInterestReceived) {
            return false;
        }
        if (this.interestAccrued !== other.interestAccrued) {
            return false;
        }
        if (this.tdsOnInterestAccrued !== other.tdsOnInterestAccrued) {
            return false;
        }
        if (this.otherTaxesOnInterestAccrued !== other.otherTaxesOnInterestAccrued) {
            return false;
        }
        if (this.netInterestReceivable !== other.netInterestReceivable) {
            return false;
        }
        return true;
    }
}
exports.FixedDepositInvestmentRegisterResponse = FixedDepositInvestmentRegisterResponse;
FixedDepositInvestmentRegisterResponse.validateInvestmentId = (investmentId) => {
    if (!(0, leo_ts_runtime_1.isString)(investmentId)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute investmentId is not a String`);
    }
    if (investmentId.trim().length === 0) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute investmentId cannot be an empty string.`);
    }
};
FixedDepositInvestmentRegisterResponse.validateDepositNumber = (depositNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(depositNumber)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute depositNumber is not a String`);
    }
    if (depositNumber.trim().length === 0) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute depositNumber cannot be an empty string.`);
    }
};
FixedDepositInvestmentRegisterResponse.validateBank = (bank) => {
    if (!(0, leo_ts_runtime_1.isString)(bank)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute bank is not a String`);
    }
    if (bank.trim().length === 0) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute bank cannot be an empty string.`);
    }
};
FixedDepositInvestmentRegisterResponse.validateOpeningAmount = (openingAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingAmount)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute openingAmount is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validatePurchaseAmount = (purchaseAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(purchaseAmount)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute purchaseAmount is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateMaturedFDAmount = (maturedFDAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(maturedFDAmount)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute maturedFDAmount is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateClosingAmount = (closingAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(closingAmount)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute closingAmount is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateFdType = (fdType) => {
    if (!fixedDepositType_1.FixedDepositType.isInstanceOf(fdType)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute fdType is not a FixedDepositType.FixedDepositType`);
    }
};
FixedDepositInvestmentRegisterResponse.validateDays = (days) => {
    if (!(0, leo_ts_runtime_1.isInt64)(days)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute days is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateInterestRate = (interestRate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(interestRate)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute interestRate is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateStartDate = (startDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(startDate)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute startDate is not a Date`);
    }
};
FixedDepositInvestmentRegisterResponse.validateMaturityDate = (maturityDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute maturityDate is not a Date`);
    }
};
FixedDepositInvestmentRegisterResponse.validateIsCallable = (isCallable) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isCallable)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute isCallable is not a Boolean`);
    }
};
FixedDepositInvestmentRegisterResponse.validateStatus = (status) => {
    if (!fixedDepositStatus_1.FixedDepositStatus.isInstanceOf(status)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute status is not a FixedDepositStatus.FixedDepositStatus`);
    }
};
FixedDepositInvestmentRegisterResponse.validateOpeningTds = (openingTds) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingTds)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute openingTds is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateOpeningTaxes = (openingTaxes) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingTaxes)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute openingTaxes is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateInterestReceived = (interestReceived) => {
    if (!(0, leo_ts_runtime_1.isInt64)(interestReceived)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute interestReceived is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateTdsPaid = (tdsPaid) => {
    if (!(0, leo_ts_runtime_1.isInt64)(tdsPaid)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute tdsPaid is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateOtherTaxesPaid = (otherTaxesPaid) => {
    if (!(0, leo_ts_runtime_1.isInt64)(otherTaxesPaid)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute otherTaxesPaid is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateNetInterestReceived = (netInterestReceived) => {
    if (!(0, leo_ts_runtime_1.isInt64)(netInterestReceived)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute netInterestReceived is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateInterestAccrued = (interestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(interestAccrued)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute interestAccrued is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateTdsOnInterestAccrued = (tdsOnInterestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(tdsOnInterestAccrued)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute tdsOnInterestAccrued is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateOtherTaxesOnInterestAccrued = (otherTaxesOnInterestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(otherTaxesOnInterestAccrued)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute otherTaxesOnInterestAccrued is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.validateNetInterestReceivable = (netInterestReceivable) => {
    if (!(0, leo_ts_runtime_1.isInt64)(netInterestReceivable)) {
        throw new InvalidFixedDepositInvestmentRegisterResponseError(`Attribute netInterestReceivable is not an Int64`);
    }
};
FixedDepositInvestmentRegisterResponse.prototype.toString = function toString() {
    return `FixedDepositInvestmentRegisterResponse(investmentId='${this.investmentId}',depositNumber='${this.depositNumber}',bank='${this.bank}',openingAmount=${this.openingAmount},purchaseAmount=${this.purchaseAmount},maturedFDAmount=${this.maturedFDAmount},closingAmount=${this.closingAmount},fdType=${this.fdType},days=${this.days},interestRate=${this.interestRate},startDate=${this.startDate},maturityDate=${this.maturityDate},isCallable=${this.isCallable},status=${this.status},openingTds=${this.openingTds},openingTaxes=${this.openingTaxes},interestReceived=${this.interestReceived},tdsPaid=${this.tdsPaid},otherTaxesPaid=${this.otherTaxesPaid},netInterestReceived=${this.netInterestReceived},interestAccrued=${this.interestAccrued},tdsOnInterestAccrued=${this.tdsOnInterestAccrued},otherTaxesOnInterestAccrued=${this.otherTaxesOnInterestAccrued},netInterestReceivable=${this.netInterestReceivable})`;
};
