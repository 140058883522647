import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  Avatar,
  Chip,
  ChipColor,
  DetailRowCellEnum,
  DetailsRow,
  DetailsRowProps,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../utils/BorderUtils";
import { useTranslation } from "react-i18next";
import { FiContractNoteDetailType } from "../fixed-income/contract-note/models/FiContractNoteDetailType";
import { Instance } from "mobx-state-tree";
import {
  fiContractNotetransformCell,
  getCell,
} from "../fixed-income/contract-note/utils/UIUtils";
import { getFormattedAmountString } from "../../../utils";
import { FiContractNoteChargeType } from "../fixed-income/contract-note/models/FiContractNoteChargeType";

export interface ContractNoteHistoryRowProps {
  index: number;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  profilePhoto: string | undefined | null;
  titleText: string | undefined | null;
  requestedAt: string | undefined | null;
  chipText: string | undefined | null;
  backgroundColor: ChipColor | undefined;
  note: string | undefined | null;
  dealDetails: React.ReactElement | null | undefined;
  diffData: Instance<typeof FiContractNoteDetailType>[];
  charges: Instance<typeof FiContractNoteChargeType>[];
  currencySymbol: string | undefined;
}

const Size = {
  container: {
    width: "100%",
  },
  row: {
    maxWidth: "730px",
  },
};

export const ContractNoteHistoryRow = ({
  index,
  firstName,
  lastName,
  profilePhoto,
  titleText,
  requestedAt,
  chipText,
  backgroundColor,
  note,
  dealDetails,
  diffData,
  charges,
  currencySymbol,
}: ContractNoteHistoryRowProps): React.ReactElement => {
  const typography = useTypography();
  const border = useBorder();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const { t } = useTranslation();

  const getRows = (): DetailsRowProps[] => {
    const rows: DetailsRowProps[] = [
      {
        label: "",
        cellType: {
          currentDetailsLabel: t(`contractNotes.sourceData`),
          updatedDetailsLabel: t(`contractNotes.suggestedEdits`),
          type: DetailRowCellEnum.DiffHeader,
        },
      },
    ];

    diffData.map((data) =>
      rows.push({
        /* @ts-ignore */
        label: t(data.localizedTextId),
        cellType: fiContractNotetransformCell(
          data.localizedTextId,
          data.originalData,
          data.diffData,
        ) ?? {
          text: "",
          type: DetailRowCellEnum.Unstyled,
        },
      }),
    );

    charges.map((charge) => {
      if (charge.diffValue !== null) {
        rows.push({
          label: currencySymbol
            ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
              /* @ts-ignore */
              t(charge.displayName, { val: ` (${currencySymbol})` })
            : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
              /* @ts-ignore */
              t(charge.displayName, { val: null }),
          cellType: getCell(
            charge.amount ? getFormattedAmountString(charge.amount) : "-",
            getFormattedAmountString(charge.diffValue),
            false,
          ),
        });
      }
    });

    return rows;
  };

  return (
    <Stack
      key={index}
      width={Size.container.width}
      padding={spacing.spaceXL}
      sx={{ borderBottom: border.default }}
    >
      <Stack flexDirection={"row"}>
        <Avatar
          userName={`${firstName} ${lastName ?? ""}`}
          size="large"
          src={profilePhoto}
        />
        <Box sx={{ marginLeft: spacing.spaceMD }}>
          <Typography
            sx={{
              ...typography.sh3,
              color: tokens.labelHighEmphasis,
            }}
          >
            {titleText}
          </Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {requestedAt}
          </Typography>
        </Box>
      </Stack>
      <Stack
        padding={spacing.spaceXL}
        gap={spacing.spaceXL}
        marginLeft={spacing.space3XL}
      >
        {chipText && backgroundColor && (
          <Box>
            <Chip label={chipText} color={backgroundColor} />
          </Box>
        )}
        {note && (
          <Stack>
            <Typography {...typography.s1}>{t("common.note")}</Typography>
            <Typography {...typography.b1}>{note}</Typography>
          </Stack>
        )}
        {dealDetails}
        {(diffData.length > 0 || charges.length > 0) && (
          <Stack maxWidth={Size.row.maxWidth}>
            {getRows().map(({ label, cellType }) => (
              <Box key={label} borderBottom={border.default}>
                <DetailsRow label={label} cellType={cellType} />
              </Box>
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
