"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FdDealRequestHistoryAdditionalDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
class InvalidFdDealRequestHistoryAdditionalDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFdDealRequestHistoryAdditionalDetailError";
    }
}
class FdDealRequestHistoryAdditionalDetail {
    constructor(withdrawalAmount, bankChargePercentage, bankChargeAmount) {
        this.isFdDealRequestHistoryAdditionalDetail = true;
        FdDealRequestHistoryAdditionalDetail.validateWithdrawalAmount(withdrawalAmount);
        this.withdrawalAmount = withdrawalAmount;
        FdDealRequestHistoryAdditionalDetail.validateBankChargePercentage(bankChargePercentage);
        this.bankChargePercentage = bankChargePercentage;
        FdDealRequestHistoryAdditionalDetail.validateBankChargeAmount(bankChargeAmount);
        this.bankChargeAmount = bankChargeAmount;
    }
    static fromDTO(dto) {
        const withdrawalAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "withdrawalAmount", InvalidFdDealRequestHistoryAdditionalDetailError));
        const bankChargePercentage = (0, leo_ts_runtime_1.getInt32)(dto, "bankChargePercentage", InvalidFdDealRequestHistoryAdditionalDetailError);
        const bankChargeAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "bankChargeAmount", InvalidFdDealRequestHistoryAdditionalDetailError));
        return new FdDealRequestHistoryAdditionalDetail(withdrawalAmount, bankChargePercentage, bankChargeAmount);
    }
    toDTO() {
        const ret = {
            withdrawalAmount: this.withdrawalAmount.toDTO(),
            bankChargePercentage: this.bankChargePercentage,
            bankChargeAmount: this.bankChargeAmount.toDTO(),
        };
        return ret;
    }
    copy(withdrawalAmount = this.withdrawalAmount, bankChargePercentage = this.bankChargePercentage, bankChargeAmount = this.bankChargeAmount) {
        return new FdDealRequestHistoryAdditionalDetail(withdrawalAmount, bankChargePercentage, bankChargeAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFdDealRequestHistoryAdditionalDetailValue = other.isFdDealRequestHistoryAdditionalDetail;
        if (!(other instanceof FdDealRequestHistoryAdditionalDetail) || Boolean(isFdDealRequestHistoryAdditionalDetailValue)) {
            return false;
        }
        if (!this.withdrawalAmount.equals(other.withdrawalAmount)) {
            return false;
        }
        if (this.bankChargePercentage !== other.bankChargePercentage) {
            return false;
        }
        if (!this.bankChargeAmount.equals(other.bankChargeAmount)) {
            return false;
        }
        return true;
    }
}
exports.FdDealRequestHistoryAdditionalDetail = FdDealRequestHistoryAdditionalDetail;
FdDealRequestHistoryAdditionalDetail.validateWithdrawalAmount = (withdrawalAmount) => {
    const isTransactionAmountValue = withdrawalAmount.isTransactionAmount;
    if (!(withdrawalAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFdDealRequestHistoryAdditionalDetailError(`Attribute withdrawalAmount is not a TransactionAmount`);
    }
};
FdDealRequestHistoryAdditionalDetail.validateBankChargePercentage = (bankChargePercentage) => {
    if (!(0, leo_ts_runtime_1.isInt32)(bankChargePercentage)) {
        throw new InvalidFdDealRequestHistoryAdditionalDetailError(`Attribute bankChargePercentage is not an Int32`);
    }
};
FdDealRequestHistoryAdditionalDetail.validateBankChargeAmount = (bankChargeAmount) => {
    const isTransactionAmountValue = bankChargeAmount.isTransactionAmount;
    if (!(bankChargeAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFdDealRequestHistoryAdditionalDetailError(`Attribute bankChargeAmount is not a TransactionAmount`);
    }
};
FdDealRequestHistoryAdditionalDetail.prototype.toString = function toString() {
    return `FdDealRequestHistoryAdditionalDetail(withdrawalAmount=${this.withdrawalAmount},bankChargePercentage=${this.bankChargePercentage},bankChargeAmount=${this.bankChargeAmount})`;
};
