"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFFolioNumbersRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
/* eslint-disable import/export */
class GetMFFolioNumbersRPC {
}
exports.GetMFFolioNumbersRPC = GetMFFolioNumbersRPC;
(function (GetMFFolioNumbersRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFFolioNumbersRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId, amcId, isin = null) {
            this.isGetMFFolioNumbersRPCRequest = true;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validateAmcId(amcId);
            this.amcId = amcId;
            if (isin !== undefined && isin !== null) {
                Request.validateIsin(isin);
            }
            this.isin = isin;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const amcId = (0, leo_ts_runtime_1.getInt64)(dto, "amcId", InvalidRequestError);
            let isin = (0, leo_ts_runtime_1.getOptionalObject)(dto, "isin");
            if (isin !== undefined && isin !== null) {
                isin = iSIN_1.ISIN.fromDTO(isin);
            }
            return new Request(entityId, amcId, isin);
        }
        toDTO() {
            const ret = {
                entityId: this.entityId.toDTO(),
                amcId: this.amcId,
            };
            if (this.isin) {
                ret.isin = this.isin.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, amcId = this.amcId, isin = this.isin) {
            return new Request(entityId, amcId, isin);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFFolioNumbersRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (this.amcId !== other.amcId) {
                return false;
            }
            if (this.isin) {
                if (!this.isin.equals(other.isin)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validateAmcId = (amcId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(amcId)) {
            throw new InvalidRequestError(`Attribute amcId is not an Int64`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    GetMFFolioNumbersRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFFolioNumbersRPC.Request(entityId=${this.entityId},amcId=${this.amcId},isin=${this.isin})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFFolioNumbersRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(folioNumbers) {
            this.isGetMFFolioNumbersRPCResponse = true;
            Response.validateFolioNumbers(folioNumbers);
            this.folioNumbers = folioNumbers;
        }
        static fromDTO(dto) {
            const folioNumbers = (0, leo_ts_runtime_1.getList)(dto, "folioNumbers", (e) => e, InvalidResponseError);
            return new Response(folioNumbers);
        }
        toDTO() {
            const ret = {
                folioNumbers: this.folioNumbers,
            };
            return ret;
        }
        copy(folioNumbers = this.folioNumbers) {
            return new Response(folioNumbers);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFFolioNumbersRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.folioNumbers, other.folioNumbers)) {
                return false;
            }
            return true;
        }
    }
    Response.validateFolioNumbers = (folioNumbers) => {
        if (!Array.isArray(folioNumbers)) {
            throw new InvalidResponseError(`Attribute folioNumbers is not a List`);
        }
        for (let i = 0; i < folioNumbers.length; i += 1) {
            if (!(0, leo_ts_runtime_1.isString)(folioNumbers[i])) {
                throw new InvalidResponseError(`Attribute folioNumbers at index ${i} is not a String.`);
            }
        }
    };
    GetMFFolioNumbersRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFFolioNumbersRPC.Response(folioNumbers=${this.folioNumbers})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetMFFolioNumbersRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetMFFolioNumbersRPC.INVALID_ISIN()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFFolioNumbersRPC.Errors || (GetMFFolioNumbersRPC.Errors = {}));
})(GetMFFolioNumbersRPC = exports.GetMFFolioNumbersRPC || (exports.GetMFFolioNumbersRPC = {}));
