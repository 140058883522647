"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiContractNoteChargesRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("../types/currency");
const fiContractNoteCharge_1 = require("./fiContractNoteCharge");
const fiTransactionType_1 = require("./fiTransactionType");
/* eslint-disable import/export */
class GetFiContractNoteChargesRPC {
}
exports.GetFiContractNoteChargesRPC = GetFiContractNoteChargesRPC;
(function (GetFiContractNoteChargesRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiContractNoteChargesRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(brokerId, currency, transactionType) {
            this.isGetFiContractNoteChargesRPCRequest = true;
            Request.validateBrokerId(brokerId);
            this.brokerId = brokerId;
            Request.validateCurrency(currency);
            this.currency = currency;
            Request.validateTransactionType(transactionType);
            this.transactionType = transactionType;
        }
        static fromDTO(dto) {
            const brokerId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "brokerId", InvalidRequestError);
            const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidRequestError));
            const transactionType = fiTransactionType_1.FiTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidRequestError));
            return new Request(brokerId, currency, transactionType);
        }
        toDTO() {
            const ret = {
                brokerId: this.brokerId.toDTO(),
                currency: this.currency.toDTO(),
                transactionType: fiTransactionType_1.FiTransactionType.toDTO(this.transactionType),
            };
            return ret;
        }
        copy(brokerId = this.brokerId, currency = this.currency, transactionType = this.transactionType) {
            return new Request(brokerId, currency, transactionType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiContractNoteChargesRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.brokerId.equals(other.brokerId)) {
                return false;
            }
            if (!this.currency.equals(other.currency)) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            return true;
        }
    }
    Request.validateBrokerId = (brokerId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
            throw new InvalidRequestError(`Attribute brokerId is not a UUID`);
        }
    };
    Request.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidRequestError(`Attribute currency is not a Currency`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
        }
    };
    GetFiContractNoteChargesRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiContractNoteChargesRPC.Request(brokerId=${this.brokerId},currency=${this.currency},transactionType=${this.transactionType})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiContractNoteChargesRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(charges) {
            this.isGetFiContractNoteChargesRPCResponse = true;
            Response.validateCharges(charges);
            this.charges = charges;
        }
        static fromDTO(dto) {
            const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", fiContractNoteCharge_1.FiContractNoteCharge.fromDTO, InvalidResponseError);
            return new Response(charges);
        }
        toDTO() {
            const ret = {
                charges: this.charges.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(charges = this.charges) {
            return new Response(charges);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiContractNoteChargesRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
                return false;
            }
            return true;
        }
    }
    Response.validateCharges = (charges) => {
        if (!Array.isArray(charges)) {
            throw new InvalidResponseError(`Attribute charges is not a List`);
        }
        for (let i = 0; i < charges.length; i += 1) {
            const isFiContractNoteChargeValue = charges[i].isFiContractNoteCharge;
            if (!(charges[i] instanceof fiContractNoteCharge_1.FiContractNoteCharge || Boolean(isFiContractNoteChargeValue))) {
                throw new InvalidResponseError(`Attribute charges at index ${i} is not a FiContractNoteCharge.`);
            }
        }
    };
    GetFiContractNoteChargesRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiContractNoteChargesRPC.Response(charges=${this.charges})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidBrokerId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BROKER_ID") {
                super(code);
                this.isInvalidBrokerId = true;
                InvalidBrokerId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBrokerId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBrokerId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBrokerIdValue = other.isInvalidBrokerId;
                if (!(other instanceof InvalidBrokerId || Boolean(isInvalidBrokerIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBrokerId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BROKER_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBrokerId = InvalidBrokerId;
        InvalidBrokerId.prototype.toString = function toString() {
            return `GetFiContractNoteChargesRPC.INVALID_BROKER_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_BROKER_ID":
                    return InvalidBrokerId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidBrokerIdValue = other.isInvalidBrokerId;
            if (other instanceof InvalidBrokerId || Boolean(isInvalidBrokerIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiContractNoteChargesRPC.Errors || (GetFiContractNoteChargesRPC.Errors = {}));
})(GetFiContractNoteChargesRPC = exports.GetFiContractNoteChargesRPC || (exports.GetFiContractNoteChargesRPC = {}));
