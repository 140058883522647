import {
  GetEquityBenchmarkReturnsGraphRPC,
  GetEquityBenchmarkReturnsGraphRPCClientImpl,
  GetEquityDashboardStatsRPC,
  GetEquityDashboardStatsRPCClientImpl,
  GetEquityHoldingDistributionByIsinRPC,
  GetEquityHoldingDistributionByIsinRPCClientImpl,
  GetEquityTopTenHoldingsRPC,
  GetEquityTopTenHoldingsRPCClientImpl,
  GetEquityTopTenRealisedGainsRPC,
  GetEquityTopTenRealisedGainsRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetEquityDashboardStatsRPCImpl } from "./MockGetEquityDashboardStatsRPCImpl";
import { MockGetEquityHoldingDistributionByIsinRPCImpl } from "./MockGetEquityHoldingDistributionByIsinRPCImpl";
import { MockGetEquityBenchmarkReturnsGraphRPCImpl } from "./MockGetEquityBenchmarkReturnsGraphRPCImpl";
import { MockGetEquityTopTenHoldingsRPCImpl } from "./MockGetEquityTopTenHoldingsRpcImpl";
import { MockGetEquityTopTenRealisedGainsRPCImpl } from "./MockGetEquityTopTenRealisedGainsRPCImpl";

export const useGetEquityDashboardStatsRPC = (
  apiClient: APIClient,
): GetEquityDashboardStatsRPC => {
  if (MOCK.equity) {
    return new MockGetEquityDashboardStatsRPCImpl();
  } else {
    return new GetEquityDashboardStatsRPCClientImpl(apiClient);
  }
};

export const useGetEquityHoldingDistributionByIsinRPC = (
  apiClient: APIClient,
): GetEquityHoldingDistributionByIsinRPC => {
  if (MOCK.equity) {
    return new MockGetEquityHoldingDistributionByIsinRPCImpl();
  } else {
    return new GetEquityHoldingDistributionByIsinRPCClientImpl(apiClient);
  }
};

export const useGetEquityBenchmarkReturnsGraphRPC = (
  apiClient: APIClient,
): GetEquityBenchmarkReturnsGraphRPC => {
  if (MOCK.equity) {
    return new MockGetEquityBenchmarkReturnsGraphRPCImpl();
  } else {
    return new GetEquityBenchmarkReturnsGraphRPCClientImpl(apiClient);
  }
};

export const useGetEquityTopTenHoldingsRPC = (
  apiClient: APIClient,
): GetEquityTopTenHoldingsRPC => {
  if (MOCK.equity) {
    return new MockGetEquityTopTenHoldingsRPCImpl();
  } else {
    return new GetEquityTopTenHoldingsRPCClientImpl(apiClient);
  }
};

export const useGetEquityTopTenRealisedGainsRPC = (
  apiClient: APIClient,
): GetEquityTopTenRealisedGainsRPC => {
  if (MOCK.equity) {
    return new MockGetEquityTopTenRealisedGainsRPCImpl();
  } else {
    return new GetEquityTopTenRealisedGainsRPCClientImpl(apiClient);
  }
};
