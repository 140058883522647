import React, { useCallback, useEffect, useMemo } from "react";
import { debounce, Stack } from "@mui/material";
import { FormGroup } from "../../../components/form/FormGroup";
import {
  DropdownInputFieldSeparateLabel,
  TextInputFieldSeparateLabel,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { getTransactionTypeOptions } from "../../deal-request/utils/SearchUtils";
import { EntityDropdown } from "../../../components/entity/EntityDropdown";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import { getSchemeDropdownOptions } from "../../deal-request/utils/UIUtils";
import { AmountTextField } from "../../../components/AmountTextField";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { MFConfirmationFields } from "../models/MFConfirmationFields";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { observer } from "mobx-react";
import { DEBOUNCE_DELAY, MIN_SEARCH_LENGTH } from "../../../../../utils";
import { MFTransactionType } from "@khazana/khazana-rpcs";
import { MFSchemeSearchStore } from "../../deal-request/store/createDealRequestStore/MFSchemeSearchStore";

const ConfirmationStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    container: {
      flexDirection: "row",
      width: `100%`,
      height: "Calc(100vh - 290px)",
      overflow: "auto",
    },
    body: {
      gap: spacing.space2XL,
      padding: spacing.space2XL,
      minWidth: "calc(100vw - 256px)",
    },
    subLabel: {
      width: "130px",
    },
    formContainer: {
      maxWidth: "720px",
      margin: "auto",
      overflow: "auto",
      padding: spacing.spaceXL,
      height: "Calc(100vh - 290x)",
      gap: spacing.spaceXL,
    },
  };
};

export interface ConfirmationRequestFormProps {
  fields: Instance<typeof MFConfirmationFields>;
  schemeDisabled: boolean;
  schemeSearchStore: Instance<typeof MFSchemeSearchStore>;
  loadLabel: string;
  loadPlaceholder: string;
}

export const ConfirmationRequestForm = observer(
  ({
    fields,
    schemeDisabled,
    schemeSearchStore,
    loadLabel,
    loadPlaceholder,
  }: ConfirmationRequestFormProps): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const tokens = useFoundationColorTokens();
    const style = useMemo(() => ConfirmationStyle(spacing), []);

    const debounceSymbolSearch = useCallback(
      debounce(function (searchValue: string | null | undefined) {
        if (!searchValue || searchValue.length < MIN_SEARCH_LENGTH) {
          if (!searchValue) {
            fields.scheme.reset();
            schemeSearchStore.reset();
          }
          debounceSymbolSearch.clear();
          fields.scheme.setNoOptionsMessage(
            t("mf.fields.schemeNoOptionsPlaceholder"),
          );
          return;
        }
        if (fields.transactionType.value) {
          schemeSearchStore.setIsLoading(true);
          schemeSearchStore
            .getMFSchemeList(
              searchValue,
              MFTransactionType.fromDTO({
                case: fields.transactionType.value,
              }),
              fields.amc.value?.id,
            )
            .then(() => {
              if (!schemeSearchStore.schemeList.length) {
                fields.scheme.setNoOptionsMessage(t("mf.fields.noSchemeFound"));
              }
            })
            .finally(() => {
              schemeSearchStore.setIsLoading(false);
            });
        }
      }, DEBOUNCE_DELAY),
      [fields.transactionType.value],
    );

    useEffect(() => {
      if (fields.scheme.value) {
        debounceSymbolSearch(fields.scheme.value.label);
      }
    }, []);

    return (
      <Stack sx={style.container}>
        <Stack
          sx={{
            ...style.formContainer,
          }}
        >
          <FormGroup title={t("mf.formGroupHeaders.basicDetailsTitle")}>
            <DropdownInputFieldSeparateLabel
              isRequired
              name={"transactionType"}
              label={t("mf.fields.transactionTypeLabel")}
              placeholder={t("mf.fields.transactionTypePlaceholder")}
              value={fields.transactionType.value}
              options={getTransactionTypeOptions(t)}
              onSelect={(item) => fields.transactionType.setValue(item.value)}
              error={fields.transactionType.errorMessage !== undefined}
              helperText={fields.transactionType.errorMessage}
            />
            <AsyncAutoCompleteInputFieldSeparateLabel
              isRequired
              id={"amc"}
              label={t("mf.fields.amcLabel")}
              placeholder={t("mf.fields.amcPlaceholder")}
              value={fields.amc.value}
              options={fields.amc.options.map((option) => {
                return { label: option.label, id: option.id };
              })}
              onSelect={(value) => {
                if (typeof value !== "string") {
                  fields.amc.setValue(value);
                }
              }}
              error={fields.amc.errorMessage !== undefined}
              helperText={fields.amc.errorMessage}
            />
            <EntityDropdown
              isRequired
              store={fields.entity}
              error={fields.entity.error}
            />
            <TextInputFieldSeparateLabel
              name={"folioNumber"}
              isRequired
              value={fields.folio.value}
              type={"text"}
              onTextChange={(text) => fields.folio.setValue(text)}
              label={t("mf.fields.folioNumberLabel")}
              placeholder={t("mf.fields.folioNumberPlaceholder")}
              error={fields.folio.errorMessage !== undefined}
              helperText={fields.folio.errorMessage}
            />
            <AsyncAutoCompleteInputFieldSeparateLabel
              isRequired
              id="scheme"
              isDisabled={schemeDisabled}
              label={t("mf.fields.schemeLabel")}
              placeholder={t("mf.fields.schemePlaceholder")}
              value={fields.scheme.value}
              options={getSchemeDropdownOptions(schemeSearchStore.schemeList)}
              onInputChange={(inputValue: string | null): void => {
                const searchText = inputValue?.trim();
                if (!searchText) {
                  fields.scheme.reset();
                }
                debounceSymbolSearch(searchText);
              }}
              onInputClear={(): void => {
                fields.scheme.setNoOptionsMessage(
                  t("mf.fields.schemeNoOptionsPlaceholder"),
                );
                fields.scheme.reset();
              }}
              onSelect={(value): void => {
                if (typeof value !== "string") {
                  fields.scheme.setValue(value);
                }
              }}
              subLabelWidth={style.subLabel.width as string} // Explicitly typecasting as string because style.subLabel.width is of type CSSProperty which could be number as well
              isLoading={schemeSearchStore.isLoading}
              style={{
                backgroundColor: tokens.background,
              }}
              error={fields.scheme.errorMessage !== undefined}
              helperText={fields.scheme.errorMessage}
              noOptionsText={fields.scheme.noOptionsMessage}
            />
            <DropdownInputFieldSeparateLabel
              name="broker"
              value={fields.broker.value}
              onSelect={(item) => fields.broker.setValue(item.value)}
              label={t("common.broker")}
              placeholder={t("common.selectBroker")}
              options={fields.broker.options.map((option) => {
                return { name: option.name, value: option.value };
              })}
              error={fields.broker.errorMessage !== undefined}
              helperText={fields.broker.errorMessage}
            />
            <TextInputFieldSeparateLabel
              name={"referenceNumber"}
              value={fields.referenceNumber.value}
              type={"text"}
              onTextChange={(text) => fields.referenceNumber.setValue(text)}
              label={t("mf.fields.referenceNumberLabel")}
              placeholder={t("mf.fields.referenceNumberPlaceholder")}
              error={fields.referenceNumber.errorMessage !== undefined}
              helperText={fields.referenceNumber.errorMessage}
            />
          </FormGroup>
          <FormGroup title={t("mf.formGroupHeaders.amountDetailsTitle")}>
            <AmountTextField
              isRequired
              name="quantity"
              label={t("mf.fields.unitsLabel")}
              error={fields.quantity.errorMessage !== undefined}
              helperText={fields.quantity.errorMessage}
              value={fields.quantity.value}
              onAmountChange={(amount) => fields.quantity.setValue(amount)}
              placeholder={t("mf.fields.unitsPlaceholder")}
              isDecimalAllowed={true}
            />
            <AmountTextField
              isRequired
              name="nav"
              label={t("mf.fields.navPerUnitLabel", {
                val: schemeSearchStore.selectedScheme?.currency.symbol,
              })}
              error={fields.navPerUnit.errorMessage !== undefined}
              helperText={fields.navPerUnit.errorMessage}
              value={fields.navPerUnit.value}
              onAmountChange={(amount) => fields.navPerUnit.setValue(amount)}
              placeholder={t("mf.fields.navPerUnitPlaceholder")}
              isDecimalAllowed={true}
            />
            <AmountTextField
              name="entryExitLoad"
              label={loadLabel}
              error={fields.entryExitLoad.errorMessage !== undefined}
              helperText={fields.entryExitLoad.errorMessage}
              value={fields.entryExitLoad.value}
              onAmountChange={(amount) => fields.entryExitLoad.setValue(amount)}
              placeholder={loadPlaceholder}
              isDecimalAllowed={true}
            />
            <AmountTextField
              isRequired
              name="netAmount"
              label={t("mf.fields.netAmountLabel", {
                val: schemeSearchStore.selectedScheme?.currency.symbol,
              })}
              error={fields.netAmount.errorMessage !== undefined}
              helperText={fields.netAmount.errorMessage}
              value={fields.netAmount.value}
              onAmountChange={(amount) => fields.netAmount.setValue(amount)}
              placeholder={t("mf.fields.netAmountPlaceholder")}
              isDecimalAllowed={true}
            />
          </FormGroup>
        </Stack>
      </Stack>
    );
  },
);
