"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFConfirmation = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const sHA256_1 = require("../types/sHA256");
const iSIN_1 = require("../types/iSIN");
const bigDecimal_1 = require("../types/bigDecimal");
const mFTransactionType_1 = require("./mFTransactionType");
class InvalidMFConfirmationError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFConfirmationError";
    }
}
class MFConfirmation {
    constructor(transactionType, sha256 = null, amcId, entityId, folioNumber, schemeId, referenceNumber = null, brokerId = null, quantity, navPerUnit, entryExitLoad = null, netAmount) {
        this.isMFConfirmation = true;
        MFConfirmation.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        if (sha256 !== undefined && sha256 !== null) {
            MFConfirmation.validateSha256(sha256);
        }
        this.sha256 = sha256;
        MFConfirmation.validateAmcId(amcId);
        this.amcId = amcId;
        MFConfirmation.validateEntityId(entityId);
        this.entityId = entityId;
        MFConfirmation.validateFolioNumber(folioNumber);
        this.folioNumber = folioNumber;
        MFConfirmation.validateSchemeId(schemeId);
        this.schemeId = schemeId;
        if (referenceNumber !== undefined && referenceNumber !== null) {
            MFConfirmation.validateReferenceNumber(referenceNumber);
        }
        this.referenceNumber = referenceNumber;
        if (brokerId !== undefined && brokerId !== null) {
            MFConfirmation.validateBrokerId(brokerId);
        }
        this.brokerId = brokerId;
        MFConfirmation.validateQuantity(quantity);
        this.quantity = quantity;
        MFConfirmation.validateNavPerUnit(navPerUnit);
        this.navPerUnit = navPerUnit;
        if (entryExitLoad !== undefined && entryExitLoad !== null) {
            MFConfirmation.validateEntryExitLoad(entryExitLoad);
        }
        this.entryExitLoad = entryExitLoad;
        MFConfirmation.validateNetAmount(netAmount);
        this.netAmount = netAmount;
    }
    static fromDTO(dto) {
        const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidMFConfirmationError));
        let sha256 = (0, leo_ts_runtime_1.getOptionalObject)(dto, "sha256");
        if (sha256 !== undefined && sha256 !== null) {
            sha256 = sHA256_1.SHA256.fromDTO(sha256);
        }
        const amcId = (0, leo_ts_runtime_1.getInt64)(dto, "amcId", InvalidMFConfirmationError);
        const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidMFConfirmationError);
        const folioNumber = (0, leo_ts_runtime_1.getString)(dto, "folioNumber", InvalidMFConfirmationError);
        const schemeId = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "schemeId", InvalidMFConfirmationError));
        const referenceNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "referenceNumber", InvalidMFConfirmationError);
        const brokerId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "brokerId", InvalidMFConfirmationError);
        const quantity = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "quantity", InvalidMFConfirmationError));
        const navPerUnit = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "navPerUnit", InvalidMFConfirmationError));
        let entryExitLoad = (0, leo_ts_runtime_1.getOptionalObject)(dto, "entryExitLoad");
        if (entryExitLoad !== undefined && entryExitLoad !== null) {
            entryExitLoad = bigDecimal_1.BigDecimal.fromDTO(entryExitLoad);
        }
        const netAmount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "netAmount", InvalidMFConfirmationError));
        return new MFConfirmation(transactionType, sha256, amcId, entityId, folioNumber, schemeId, referenceNumber, brokerId, quantity, navPerUnit, entryExitLoad, netAmount);
    }
    toDTO() {
        const ret = {
            transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
            amcId: this.amcId,
            entityId: this.entityId.toDTO(),
            folioNumber: this.folioNumber,
            schemeId: this.schemeId.toDTO(),
            quantity: this.quantity.toDTO(),
            navPerUnit: this.navPerUnit.toDTO(),
            netAmount: this.netAmount.toDTO(),
        };
        if (this.sha256) {
            ret.sha256 = this.sha256.toDTO();
        }
        if (this.referenceNumber !== undefined && this.referenceNumber !== null) {
            ret.referenceNumber = this.referenceNumber;
        }
        if (this.brokerId) {
            ret.brokerId = this.brokerId.toDTO();
        }
        if (this.entryExitLoad) {
            ret.entryExitLoad = this.entryExitLoad.toDTO();
        }
        return ret;
    }
    copy(transactionType = this.transactionType, sha256 = this.sha256, amcId = this.amcId, entityId = this.entityId, folioNumber = this.folioNumber, schemeId = this.schemeId, referenceNumber = this.referenceNumber, brokerId = this.brokerId, quantity = this.quantity, navPerUnit = this.navPerUnit, entryExitLoad = this.entryExitLoad, netAmount = this.netAmount) {
        return new MFConfirmation(transactionType, sha256, amcId, entityId, folioNumber, schemeId, referenceNumber, brokerId, quantity, navPerUnit, entryExitLoad, netAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFConfirmationValue = other.isMFConfirmation;
        if (!(other instanceof MFConfirmation) || Boolean(isMFConfirmationValue)) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.sha256) {
            if (!this.sha256.equals(other.sha256)) {
                return false;
            }
        }
        if (this.amcId !== other.amcId) {
            return false;
        }
        if (!this.entityId.equals(other.entityId)) {
            return false;
        }
        if (this.folioNumber !== other.folioNumber) {
            return false;
        }
        if (!this.schemeId.equals(other.schemeId)) {
            return false;
        }
        if (this.referenceNumber !== other.referenceNumber) {
            return false;
        }
        if (this.brokerId) {
            if (!this.brokerId.equals(other.brokerId)) {
                return false;
            }
        }
        if (!this.quantity.equals(other.quantity)) {
            return false;
        }
        if (!this.navPerUnit.equals(other.navPerUnit)) {
            return false;
        }
        if (this.entryExitLoad) {
            if (!this.entryExitLoad.equals(other.entryExitLoad)) {
                return false;
            }
        }
        if (!this.netAmount.equals(other.netAmount)) {
            return false;
        }
        return true;
    }
}
exports.MFConfirmation = MFConfirmation;
MFConfirmation.validateTransactionType = (transactionType) => {
    if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidMFConfirmationError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
    }
};
MFConfirmation.validateSha256 = (sha256) => {
    const isSHA256Value = sha256.isSHA256;
    if (!(sha256 instanceof sHA256_1.SHA256 || Boolean(isSHA256Value))) {
        throw new InvalidMFConfirmationError(`Attribute sha256 is not a SHA256`);
    }
};
MFConfirmation.validateAmcId = (amcId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amcId)) {
        throw new InvalidMFConfirmationError(`Attribute amcId is not an Int64`);
    }
};
MFConfirmation.validateEntityId = (entityId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
        throw new InvalidMFConfirmationError(`Attribute entityId is not a UUID`);
    }
};
MFConfirmation.validateFolioNumber = (folioNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(folioNumber)) {
        throw new InvalidMFConfirmationError(`Attribute folioNumber is not a String`);
    }
    if (folioNumber.trim().length === 0) {
        throw new InvalidMFConfirmationError(`Attribute folioNumber cannot be an empty string.`);
    }
};
MFConfirmation.validateSchemeId = (schemeId) => {
    const isISINValue = schemeId.isISIN;
    if (!(schemeId instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidMFConfirmationError(`Attribute schemeId is not a ISIN`);
    }
};
MFConfirmation.validateReferenceNumber = (referenceNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(referenceNumber)) {
        throw new InvalidMFConfirmationError(`Attribute referenceNumber is not a String`);
    }
    if (referenceNumber.trim().length === 0) {
        throw new InvalidMFConfirmationError(`Attribute referenceNumber cannot be an empty string.`);
    }
};
MFConfirmation.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidMFConfirmationError(`Attribute brokerId is not a UUID`);
    }
};
MFConfirmation.validateQuantity = (quantity) => {
    const isBigDecimalValue = quantity.isBigDecimal;
    if (!(quantity instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFConfirmationError(`Attribute quantity is not a BigDecimal`);
    }
};
MFConfirmation.validateNavPerUnit = (navPerUnit) => {
    const isBigDecimalValue = navPerUnit.isBigDecimal;
    if (!(navPerUnit instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFConfirmationError(`Attribute navPerUnit is not a BigDecimal`);
    }
};
MFConfirmation.validateEntryExitLoad = (entryExitLoad) => {
    const isBigDecimalValue = entryExitLoad.isBigDecimal;
    if (!(entryExitLoad instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFConfirmationError(`Attribute entryExitLoad is not a BigDecimal`);
    }
};
MFConfirmation.validateNetAmount = (netAmount) => {
    const isBigDecimalValue = netAmount.isBigDecimal;
    if (!(netAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFConfirmationError(`Attribute netAmount is not a BigDecimal`);
    }
};
MFConfirmation.prototype.toString = function toString() {
    return `MFConfirmation(transactionType=${this.transactionType},sha256=${this.sha256},amcId=${this.amcId},entityId=${this.entityId},folioNumber='${this.folioNumber}',schemeId=${this.schemeId},referenceNumber='${this.referenceNumber}',brokerId=${this.brokerId},quantity=${this.quantity},navPerUnit=${this.navPerUnit},entryExitLoad=${this.entryExitLoad},netAmount=${this.netAmount})`;
};
