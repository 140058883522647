import { CreateWithdrawRequestRPC } from "@khazana/khazana-rpcs";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";

export class MockCreateWithdrawRequestRPCImpl extends CreateWithdrawRequestRPC {
  execute(
    _request: CreateWithdrawRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateWithdrawRequestRPC.Response,
      CreateWithdrawRequestRPC.Errors.Errors
    >
  > {
    const response = new CreateWithdrawRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateWithdrawRequestRPC.Response,
        CreateWithdrawRequestRPC.Errors.Errors
      >
    >;
  }
}
