import {
  Currency,
  GetFiInvestmentDetailsRPC,
  ISIN,
  PayoutFrequency,
  BigDecimal,
  BigAmount,
  CashflowTimelineItem,
  CashflowTimelineAmount,
  FiYieldType,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../networking/MockExecuteHelper";

export class MockGetFiInvestmentDetailsRPCImpl extends GetFiInvestmentDetailsRPC {
  execute(
    _request: GetFiInvestmentDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiInvestmentDetailsRPC.Response,
      GetFiInvestmentDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetFiInvestmentDetailsRPC.Response(
      new ISIN("INE000114563"),
      "SIDBI 7.79% 2027 Sr VI",
      new LeoDate(),
      new BigDecimal("10000"),
      new BigAmount(new BigDecimal("3324300.87"), new Currency("INR", "₹")),
      new LeoDate(),
      FiYieldType.FiYieldType.M,
      new LeoDate(),
      "1234567890",
      "HDFC Bank",
      "DEM0987654321",
      new BigDecimal("7.86"),
      new BigDecimal("7.79"),
      new BigAmount(new BigDecimal("15000.57"), new Currency("INR", "₹")),
      PayoutFrequency.PayoutFrequency.MONTHLY,
      [
        new CashflowTimelineItem(new LeoDate(), [
          new CashflowTimelineAmount(
            "cashflow.timeline.totalInvestmentLabel",
            new BigAmount(
              new BigDecimal("296912.92"),
              new Currency("INR", "₹"),
            ),
            null,
          ),
        ]),
        new CashflowTimelineItem(new LeoDate(), [
          new CashflowTimelineAmount(
            "cashflow.timeline.couponLabel",
            new BigAmount(
              new BigDecimal("1477.80", true),
              new Currency("INR", "₹"),
            ),
            null,
          ),
          new CashflowTimelineAmount(
            "cashflow.timeline.principalLabel",
            new BigAmount(
              new BigDecimal("1200.00", true),
              new Currency("INR", "₹"),
            ),
            null,
          ),
        ]),
        new CashflowTimelineItem(new LeoDate(), [
          new CashflowTimelineAmount(
            "cashflow.timeline.couponLabel",
            new BigAmount(new BigDecimal("1477.80"), new Currency("INR", "₹")),
            null,
          ),
        ]),
      ],
      true,
      1,
    );
    // const error = new GetFiInvestmentDetailsRPC.Errors.InvalidDealRequestId();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiInvestmentDetailsRPC.Response,
        GetFiInvestmentDetailsRPC.Errors.Errors
      >
    >;
  }
}
