import { observer } from "mobx-react";
import React, { useState } from "react";
import { useUserStore } from "../../../store/hooks";
import { FiUserPrivileges } from "../../../../user/UserPrivileges";
import { Stack } from "@mui/material";
import {
  LoadingIndicator,
  PageHeader,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { CreateFiDealRequestForm } from "../components/CreateFiDealRequestForm";
import { FiDealType } from "../../../../../types/EnumTypes";
import { useNavigate } from "react-router-dom";
import { CreateFIDealDetails } from "../components/CreateFIDealDetails";
import { useBorder } from "../../../../../utils/BorderUtils";

const Size = {
  pageMinWidth: "1000px",
  containerMinWidth: "640px",
  minWidth: "600px",
  maxWidth: "740px",
  maxHeight: "calc(100vh - 84px)",
};

export const CreateFiDealRequest = observer((): React.ReactElement => {
  const userPrivileges = useUserStore().privileges;
  const [isScreenLoading, setIsScreenLoading] = useState(false);
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const { t } = useTranslation();
  const navigate = useNavigate();
  useState(false);
  const getTabConfiguration = ():
    | {
        showTab: boolean;
        defaultTab: FiDealType;
      }
    | undefined => {
    if (
      userPrivileges.includes(FiUserPrivileges.BuyFixedIncomeDeal) &&
      userPrivileges.includes(FiUserPrivileges.SellFixedIncomeDeal)
    ) {
      return { showTab: true, defaultTab: FiDealType.Purchase };
    } else if (userPrivileges.includes(FiUserPrivileges.BuyFixedIncomeDeal)) {
      return { showTab: false, defaultTab: FiDealType.Purchase };
    }
    if (userPrivileges.includes(FiUserPrivileges.SellFixedIncomeDeal)) {
      return { showTab: false, defaultTab: FiDealType.Sell };
    } else {
      console.error("User doesn't have the required privileges");
      navigate("/");
    }
  };

  const tabConfiguration = getTabConfiguration();
  const [tabValue, setTabValue] = useState(tabConfiguration?.defaultTab);

  if (
    typeof tabValue === "undefined" ||
    typeof tabConfiguration?.showTab === "undefined"
  ) {
    return <></>;
  }

  return (
    <Stack
      direction="column"
      alignItems={"flex-start"}
      minWidth={Size.pageMinWidth}
    >
      <LoadingIndicator isLoading={isScreenLoading} />
      <PageHeader
        title={t("fi.createDealRequest.createNewFixedIncomeDealRequest")}
      />
      <Stack direction="row" width={"-webkit-fill-available"}>
        <Stack
          padding={spacing.space2XL}
          gap={spacing.spaceXL}
          justifyContent={"center"}
          flexGrow={1}
          maxHeight={Size.maxHeight}
          minWidth={Size.containerMinWidth}
          direction={"row"}
          overflow={"auto"}
        >
          <Stack
            style={{
              minWidth: Size.minWidth,
              maxWidth: Size.maxWidth,
              height: "fit-content",
              border: border.default,
              borderRadius: cornerRadius.radiusXS,
              backgroundColor: tokens.background,
              flexGrow: 1,
            }}
          >
            <CreateFiDealRequestForm
              showTab={tabConfiguration.showTab}
              tabValue={tabValue}
              setTabValue={(value): void => setTabValue(value)}
              setIsScreenLoading={setIsScreenLoading}
            />
          </Stack>
        </Stack>
        <CreateFIDealDetails tabValue={tabValue} />
      </Stack>
    </Stack>
  );
});
