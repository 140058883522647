"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileExtension = exports.InvalidFileExtensionError = void 0;
class InvalidFileExtensionError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFileExtensionError";
    }
}
exports.InvalidFileExtensionError = InvalidFileExtensionError;
/* eslint-disable @typescript-eslint/no-shadow */
var FileExtension;
(function (FileExtension_1) {
    let FileExtension;
    (function (FileExtension) {
        FileExtension["CSV"] = "CSV";
        FileExtension["XLSX"] = "XLSX";
    })(FileExtension = FileExtension_1.FileExtension || (FileExtension_1.FileExtension = {}));
    FileExtension_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "CSV":
                return FileExtension.CSV;
            case "XLSX":
                return FileExtension.XLSX;
            default:
                throw new InvalidFileExtensionError(`Case ${dto.case} is not valid case of FileExtension`);
        }
    };
    FileExtension_1.toDTO = (fileExtension) => {
        const ret = {
            case: FileExtension[fileExtension],
        };
        return ret;
    };
    FileExtension_1.isInstanceOf = (other) => {
        if (other in FileExtension) {
            return true;
        }
        return false;
    };
})(FileExtension = exports.FileExtension || (exports.FileExtension = {}));
/* eslint-enable @typescript-eslint/no-shadow */
