import { Alert, Divider, Stack, Typography } from "@mui/material";
import {
  DetailRowCellEnum,
  DetailsRow,
  Dialog,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { useBorder } from "../../../../../utils/BorderUtils";

interface FolioCreationDialogProps {
  isRequest: boolean;
  onClose: () => void;
  isOpen: boolean;
  onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
  amcName?: string;
  entityName?: string;
  folioNumber?: string;
}

const Size = {
  alertWidth: "100% !important",
};

export const FolioCreationDialog = ({
  isRequest = true,
  onClose,
  isOpen,
  onPrimaryButtonClick: onSubmit,
  amcName,
  entityName,
  folioNumber,
}: FolioCreationDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const tokens = useFoundationColorTokens();
  const [note, setNote] = useState<string>();

  const getTitle = (): string => {
    if (isRequest) {
      return t("mf.confirmationRequest.requestFolioCreationTitle");
    }
    return t("mf.confirmationRequest.confirmFolioCreationTitle");
  };

  const getButtonTitle = (): string => {
    if (isRequest) {
      return t("mf.confirmationRequest.requestFolioCreationButtonTitle");
    }
    return t("mf.confirmationRequest.confirmFolioCreationButtonTitle");
  };

  const getInfoText = (): string => {
    if (isRequest) {
      return t("mf.confirmationRequest.requestFolioCreationDescription");
    }
    return t("mf.confirmationRequest.confirmFolioCreationDescription");
  };

  const getRow = (label: string, value?: string): React.ReactElement => {
    return (
      <>
        <DetailsRow
          label={label}
          cellType={{
            text: value ?? "-",
            type: DetailRowCellEnum.Unstyled,
          }}
        />
        <Divider />
      </>
    );
  };

  return (
    <Dialog
      open={isOpen}
      title={getTitle()}
      isCloseIconPresent={true}
      onClose={onClose}
      onPrimaryButtonClick={(): Promise<void> => onSubmit(note)}
      primaryButtonText={getButtonTitle()}
      disableBackdropClick={true}
    >
      <Stack spacing={spacing.spaceLG}>
        <Alert
          icon={false}
          variant="filled"
          sx={{
            width: Size.alertWidth,
            background: tokens.backgroundInfoSubtle,
            border: border.infoSubtle,
            color: tokens.labelInfo,
            padding: `${spacing.spaceXS} ${spacing.spaceMD}`,
            ...typography.b1,
          }}
        >
          {getInfoText()}
        </Alert>
        <Stack spacing={spacing.spaceXS}>
          <Typography sx={{ ...typography.s1 }}>
            {t("mf.confirmationRequest.folioDetailsSubtitle")}
          </Typography>
          <Divider />
          <Stack>
            {getRow(t("mf.fields.amcLabel"), amcName)}
            {getRow(t("common.entity"), entityName)}
            {getRow(t("mf.fields.folioNumberLabel"), folioNumber)}
          </Stack>
        </Stack>
        <NoteTextArea note={note} setNote={setNote} />
      </Stack>
    </Dialog>
  );
};
