import { DetailCellDiff, DetailCellType } from "@khazana/khazana-rpcs";
import { types } from "mobx-state-tree";

export interface DiffDetailCellView {
  label: string;
  originalData: DetailCellType.DetailCellType;
  diffData?: DetailCellType.DetailCellType;
}

let localizedLabel: string;

export const DiffDetailType = types.custom<DetailCellDiff, DiffDetailCellView>({
  name: "DiffDetailType",
  fromSnapshot(snapshot: DetailCellDiff): DiffDetailCellView {
    localizedLabel = snapshot.localizedTextId;
    return {
      label: snapshot.localizedTextId,
      originalData: snapshot.originalData,
      diffData: snapshot.diffData ?? undefined,
    };
  },
  toSnapshot(value: DiffDetailCellView): DetailCellDiff {
    return new DetailCellDiff(
      localizedLabel,
      value.originalData,
      value.diffData,
    );
  },
  isTargetType(value: DetailCellDiff | DetailCellDiff): boolean {
    return !(value instanceof DetailCellDiff);
  },
  getValidationMessage(_snapshot: DetailCellDiff): string {
    return "";
  },
});
