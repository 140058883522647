import { getAPIClient } from "@khazana/khazana-boilerplate";
import { Instance, flow, getParent, types, getEnv } from "mobx-state-tree";
import { KeycloakToken, SignInRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { keycloak } from "../../../common/keycloak";
import { RootStore } from "../../root/store/RootStore";
import { useSignInRPCClientImpl } from "../rpcs/RPC";

export enum SignInError {
  KeycloakNotFound = "KEYCLOAK_NOT_FOUND",
  InvalidAccessToken = "INVALID_ACCESS_TOKEN",
}

export const SignInStore = types
  .model({
    error: types.maybeNull(
      types.enumeration<SignInError>("SignInError", Object.values(SignInError)),
    ),
  })
  .actions((store) => ({
    signInUser: flow(function* () {
      store.error = null;
      const apiClient = getAPIClient(store);
      const logger = getEnv(store).logger;
      if (keycloak.token && keycloak.refreshToken) {
        const request = new SignInRPC.Request(
          new KeycloakToken(keycloak.token, keycloak.refreshToken),
        );
        const result: LeoRPCResult<
          SignInRPC.Response,
          SignInRPC.Errors.Errors
        > = yield useSignInRPCClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          const privileges = response.privileges;
          const rootStore = getParent<typeof RootStore>(store);
          rootStore.completeSignInProcess(privileges);
          rootStore.homeStore.setUserData(response.userName, response.emailId);
          rootStore.homeStore.setIsEquityEnabled(
            response.moduleAvailability.isEquityEnabled,
          );
          rootStore.homeStore.setIsFIEnabled(
            response.moduleAvailability.isFIEnabled,
          );
          rootStore.homeStore.setIsFDEnabled(
            response.moduleAvailability.isFDEnabled,
          );
          rootStore.homeStore.setIsMFEnabled(
            response.moduleAvailability.isMutualFundEnabled,
          );
        } else if (result instanceof LeoRPCResult.Error) {
          store.error = SignInError.InvalidAccessToken;
          logger.error("Access token not found while calling SignInUser RPC");
          keycloak.logout();
        }
      } else {
        store.error = SignInError.KeycloakNotFound;
        logger.error("Keycloak tokens not present while calling sign-in");
      }
    }),
  }));

export const createSignInStore = (): Instance<typeof SignInStore> => {
  return SignInStore.create();
};
