"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityReportDateRange = exports.InvalidEquityReportDateRangeError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityReportDateRangeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityReportDateRangeError";
    }
}
exports.InvalidEquityReportDateRangeError = InvalidEquityReportDateRangeError;
var EquityReportDateRange;
(function (EquityReportDateRange) {
    class Ytd {
        constructor() {
            this.isYtd = true;
        }
        static fromDTO(dto) {
            return new Ytd();
        }
        toDTO() {
            const ret = {
                case: "YTD",
            };
            return ret;
        }
        copy() {
            return new Ytd();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isYtdValue = other.isYtd;
            if (!(other instanceof Ytd) || Boolean(isYtdValue)) {
                return false;
            }
            return true;
        }
    }
    EquityReportDateRange.Ytd = Ytd;
    Ytd.prototype.toString = function toString() {
        return `EquityReportDateRange.YTD()`;
    };
    class OneMonth {
        constructor() {
            this.isOneMonth = true;
        }
        static fromDTO(dto) {
            return new OneMonth();
        }
        toDTO() {
            const ret = {
                case: "ONE_MONTH",
            };
            return ret;
        }
        copy() {
            return new OneMonth();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isOneMonthValue = other.isOneMonth;
            if (!(other instanceof OneMonth) || Boolean(isOneMonthValue)) {
                return false;
            }
            return true;
        }
    }
    EquityReportDateRange.OneMonth = OneMonth;
    OneMonth.prototype.toString = function toString() {
        return `EquityReportDateRange.ONE_MONTH()`;
    };
    class ThreeMonths {
        constructor() {
            this.isThreeMonths = true;
        }
        static fromDTO(dto) {
            return new ThreeMonths();
        }
        toDTO() {
            const ret = {
                case: "THREE_MONTHS",
            };
            return ret;
        }
        copy() {
            return new ThreeMonths();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isThreeMonthsValue = other.isThreeMonths;
            if (!(other instanceof ThreeMonths) || Boolean(isThreeMonthsValue)) {
                return false;
            }
            return true;
        }
    }
    EquityReportDateRange.ThreeMonths = ThreeMonths;
    ThreeMonths.prototype.toString = function toString() {
        return `EquityReportDateRange.THREE_MONTHS()`;
    };
    class SixMonths {
        constructor() {
            this.isSixMonths = true;
        }
        static fromDTO(dto) {
            return new SixMonths();
        }
        toDTO() {
            const ret = {
                case: "SIX_MONTHS",
            };
            return ret;
        }
        copy() {
            return new SixMonths();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isSixMonthsValue = other.isSixMonths;
            if (!(other instanceof SixMonths) || Boolean(isSixMonthsValue)) {
                return false;
            }
            return true;
        }
    }
    EquityReportDateRange.SixMonths = SixMonths;
    SixMonths.prototype.toString = function toString() {
        return `EquityReportDateRange.SIX_MONTHS()`;
    };
    class OneYear {
        constructor() {
            this.isOneYear = true;
        }
        static fromDTO(dto) {
            return new OneYear();
        }
        toDTO() {
            const ret = {
                case: "ONE_YEAR",
            };
            return ret;
        }
        copy() {
            return new OneYear();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isOneYearValue = other.isOneYear;
            if (!(other instanceof OneYear) || Boolean(isOneYearValue)) {
                return false;
            }
            return true;
        }
    }
    EquityReportDateRange.OneYear = OneYear;
    OneYear.prototype.toString = function toString() {
        return `EquityReportDateRange.ONE_YEAR()`;
    };
    class Custom {
        constructor(startDate = null, endDate = null) {
            this.isCustom = true;
            if (startDate !== undefined && startDate !== null) {
                Custom.validateStartDate(startDate);
            }
            this.startDate = startDate;
            if (endDate !== undefined && endDate !== null) {
                Custom.validateEndDate(endDate);
            }
            this.endDate = endDate;
        }
        static fromDTO(dto) {
            const startDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "startDate", InvalidEquityReportDateRangeError);
            const endDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "endDate", InvalidEquityReportDateRangeError);
            return new Custom(startDate, endDate);
        }
        toDTO() {
            const ret = {
                case: "CUSTOM",
            };
            if (this.startDate) {
                ret.startDate = this.startDate.toDTO();
            }
            if (this.endDate) {
                ret.endDate = this.endDate.toDTO();
            }
            return ret;
        }
        copy(startDate = this.startDate, endDate = this.endDate) {
            return new Custom(startDate, endDate);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isCustomValue = other.isCustom;
            if (!(other instanceof Custom) || Boolean(isCustomValue)) {
                return false;
            }
            if (this.startDate) {
                if (!this.startDate.equals(other.startDate)) {
                    return false;
                }
            }
            if (this.endDate) {
                if (!this.endDate.equals(other.endDate)) {
                    return false;
                }
            }
            return true;
        }
    }
    Custom.validateStartDate = (startDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(startDate)) {
            throw new InvalidEquityReportDateRangeError(`Attribute startDate is not a Date`);
        }
    };
    Custom.validateEndDate = (endDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(endDate)) {
            throw new InvalidEquityReportDateRangeError(`Attribute endDate is not a Date`);
        }
    };
    EquityReportDateRange.Custom = Custom;
    Custom.prototype.toString = function toString() {
        return `EquityReportDateRange.CUSTOM(startDate=${this.startDate},endDate=${this.endDate})`;
    };
    EquityReportDateRange.fromDTO = (dto) => {
        switch (dto.case) {
            case "YTD":
                return Ytd.fromDTO(dto);
            case "ONE_MONTH":
                return OneMonth.fromDTO(dto);
            case "THREE_MONTHS":
                return ThreeMonths.fromDTO(dto);
            case "SIX_MONTHS":
                return SixMonths.fromDTO(dto);
            case "ONE_YEAR":
                return OneYear.fromDTO(dto);
            case "CUSTOM":
                return Custom.fromDTO(dto);
            default:
                throw new InvalidEquityReportDateRangeError(`Case ${dto.case} is not valid case of EquityReportDateRange.`);
        }
    };
    EquityReportDateRange.isInstanceOf = (other) => {
        const isYTDValue = other.isYTD;
        if (other instanceof Ytd || Boolean(isYTDValue)) {
            return true;
        }
        const isONE_MONTHValue = other.isONE_MONTH;
        if (other instanceof OneMonth || Boolean(isONE_MONTHValue)) {
            return true;
        }
        const isTHREE_MONTHSValue = other.isTHREE_MONTHS;
        if (other instanceof ThreeMonths || Boolean(isTHREE_MONTHSValue)) {
            return true;
        }
        const isSIX_MONTHSValue = other.isSIX_MONTHS;
        if (other instanceof SixMonths || Boolean(isSIX_MONTHSValue)) {
            return true;
        }
        const isONE_YEARValue = other.isONE_YEAR;
        if (other instanceof OneYear || Boolean(isONE_YEARValue)) {
            return true;
        }
        const isCUSTOMValue = other.isCUSTOM;
        if (other instanceof Custom || Boolean(isCUSTOMValue)) {
            return true;
        }
        return false;
    };
})(EquityReportDateRange = exports.EquityReportDateRange || (exports.EquityReportDateRange = {}));
