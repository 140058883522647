import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import {
  getAmountStringOrHyphen,
  getFormattedTimeDateWithComma,
} from "../../../../../utils";
import { FiContractNotesFilterOptionsModel } from "../models/FiContractNotesFilterOptionsModel";
import { ContractNotesSearchFilter } from "../components/FiContractNotesSearchFilter";
import { useNavigate } from "react-router-dom";
import { UploadNoteDialog } from "../../../components/UploadNoteDialog";
import { FiUserPrivileges } from "../../../../user/UserPrivileges";
import { useUserStore } from "../../../store/hooks";
import { getFiContractNoteRequestStatusValue } from "../utils/SearchUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getPath } from "../../../../../utils/RoutesUtils";
import {
  useFiContractNotesSearchStore,
  useFiUploadContractNoteStore,
} from "../store/hooks";
import { HeaderContainer } from "../../../components/page-header/HeaderContainer";

const Size = {
  table: {
    default: "max-content",
    security: "344px",
    status: "220px",
    broker: "250px",
    fileName: "200px",
    timestamp: "220px",
  },
};

export const FiManageContractNotes = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useFiContractNotesSearchStore();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const privileges = useUserStore().privileges;
  const [showUploadNoteDialog, setShowUploadNoteDialog] = useState(false);

  useEffect(() => {
    store.getBrokerList();
  }, []);

  const getUploadNoteButton = (): React.ReactElement | undefined => {
    return privileges.includes(
      FiUserPrivileges.UploadFixedIncomeContractNote,
    ) ? (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.uploadNote"),
          onClick: (): void => {
            setShowUploadNoteDialog(true);
          },
          buttonType: "filled",
        }}
        secondaryButton={{
          label: t("fi.contractNotes.createContractNote"),
          onClick: (): void => {
            navigate(getPath(Module.Fi, Route.CreatePaymentRequest));
          },
          buttonType: "outlined",
        }}
      />
    ) : undefined;
  };

  const getContractNotes = async (
    option: TableOptions<Instance<typeof FiContractNotesFilterOptionsModel>>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    if (option.filter) store.updateFilterOptions(option.filter);
    await store.getContractNotes(
      option.page ? option.page - 1 : 0,
      store.itemsPerPage(),
      option.sort?.order,
    );
    setTotalItems(store.totalItems);
    return store.contractNotes.map((contractNote) => {
      return [
        {
          data: getFiContractNoteRequestStatusValue(
            contractNote.status,
          ).toLocaleUpperCase(),
        },
        {
          data: contractNote.brokerName ?? "-",
        },
        {
          data: contractNote.fileName ?? "-",
        },
        {
          data: contractNote.referenceNumber ?? "-",
        },
        {
          data: contractNote.isin ?? "-",
        },
        {
          data: contractNote.security ?? "-",
        },
        {
          data: contractNote.dealRequestId ?? "-",
        },
        {
          data: getAmountStringOrHyphen(contractNote.amount?.amount),
          align: "right",
        },
        {
          data: getFormattedTimeDateWithComma(contractNote.receivedAt),
        },
      ];
    });
  };
  const getHeaders = (): TableHeader => {
    return [
      {
        id: "status",
        name: t("common.status"),
        width: Size.table.status,
      },
      {
        id: "broker",
        name: t("contractNotes.broker"),
        width: Size.table.broker,
      },
      {
        id: "fileName",
        name: t("contractNotes.fileName"),
        width: Size.table.fileName,
      },
      {
        id: "contractNoteNumber",
        name: t("fi.contractNotes.contractNoteNumber"),
        width: Size.table.default,
      },
      {
        id: "isin",
        name: t("contractNotes.isin"),
        width: Size.table.default,
      },
      {
        id: "security",
        name: t("fi.security"),
        width: Size.table.security,
      },
      {
        id: "dealRequestId",
        name: t("contractNotes.dealRequestId"),
        width: Size.table.default,
      },
      {
        id: "amount",
        name: t("common.amountWithPostfixLabel", {
          val: store.currencySymbol,
        }),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "receivedAt",
        name: t("common.receivedAt"),
        width: Size.table.timestamp,
        sortable: true,
      },
    ];
  };

  return (
    <>
      <PageHeader
        title={t("fi.manageFixedIncomeContractNotes")}
        actionElement={getUploadNoteButton()}
      />
      <UploadNoteDialog
        title={t("contractNotes.uploadNote")}
        showUploadNoteDialog={showUploadNoteDialog}
        onUploadNoteDialogClose={(): void => {
          setShowUploadNoteDialog(false);
        }}
        successPathModule={Module.Fi}
        store={useFiUploadContractNoteStore()}
      />
      <Box margin={spacing.space2XL}>
        <Table
          name="fixedIncomeContractNotesTable"
          styleOverrides={{
            divider: "cell",
          }}
          headers={getHeaders()}
          onTableOptionsChange={getContractNotes}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            idle: { message: t("common.searchTableIdleState") },
            loading: { message: t("contractNotes.searchTableLoadingState") },
          }}
          paginationOption={{
            itemsPerPage: store.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          filterOption={{
            initialFilterValue: store.filterOptions,
            filterComponent(filter, setFilter): React.ReactElement {
              return (
                <ContractNotesSearchFilter
                  filter={filter}
                  setFilter={setFilter}
                  brokers={store.brokers}
                />
              );
            },
          }}
          onRowClick={(_, index): void => {
            const selectedContractNoteId =
              store.contractNotes[index].contractNoteId;
            navigate(
              getPath(
                Module.Fi,
                Route.ContractNotesDetailsWithParams,
                selectedContractNoteId.toString(),
              ),
            );
          }}
        />
      </Box>
    </>
  );
});
