"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutualFundPageIndex = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidMutualFundPageIndexError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMutualFundPageIndexError";
    }
}
class MutualFundPageIndex {
    constructor(items) {
        this.isMutualFundPageIndex = true;
        MutualFundPageIndex.validateItems(items);
        this.items = items;
    }
    static fromDTO(dto) {
        const items = (0, leo_ts_runtime_1.getInt32)(dto, "items", InvalidMutualFundPageIndexError);
        return new MutualFundPageIndex(items);
    }
    toDTO() {
        const ret = {
            items: this.items,
        };
        return ret;
    }
    copy(items = this.items) {
        return new MutualFundPageIndex(items);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMutualFundPageIndexValue = other.isMutualFundPageIndex;
        if (!(other instanceof MutualFundPageIndex) || Boolean(isMutualFundPageIndexValue)) {
            return false;
        }
        if (this.items !== other.items) {
            return false;
        }
        return true;
    }
}
exports.MutualFundPageIndex = MutualFundPageIndex;
MutualFundPageIndex.validateItems = (items) => {
    if (!(0, leo_ts_runtime_1.isInt32)(items)) {
        throw new InvalidMutualFundPageIndexError(`Attribute items is not an Int32`);
    }
    if (items < 0) {
        throw new InvalidMutualFundPageIndexError(`Attribute items has value ${items}. Min value is 0.`);
    }
};
MutualFundPageIndex.prototype.toString = function toString() {
    return `MutualFundPageIndex(items=${this.items})`;
};
