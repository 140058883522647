import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { HeaderButton } from "../../../components/page-header/HeaderButton";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import {
  getFiCashflowDetailsPath,
  getPath,
} from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FiInvestmentDetailsCard } from "../../components/investment-details/FiInvestmentDetailsCard";
import {
  useFiMaturitySettleStore,
  useViewFiMaturityRequestDetailsStore,
} from "../store/hooks";
import { FiMaturitySettleForm } from "../components/FiMaturitySettleForm";
import { TransactionDetails } from "../../../components/create-deal/TransactionDetails";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { FiMaturitySettleRequestError } from "../store/FiMaturitySettleStore";
import { TextFieldDialog } from "../../../components/TextFieldDialog";

const Size = {
  containerWidth: "100%",
};

export const SettleMaturityRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isSubmitDialogOpen, setIsSubmitDialogOpen] = useState(false);
  const detailsStore = useViewFiMaturityRequestDetailsStore();
  const settleStore = useFiMaturitySettleStore();
  const requestId = Number(searchParam.get("requestId"));

  useEffect(() => {
    const getDetails = async (): Promise<void> => {
      setIsScreenBlockingLoading(true);
      detailsStore.resetError();
      if (requestId) {
        const promises = [
          detailsStore.getInvestmentDetails(requestId),
          detailsStore.getFiMaturityRequestDetails(requestId),
          settleStore.getFiMaturityTransactionDetails(requestId),
          settleStore.recipientBank.getBankList(),
        ];
        await Promise.all(promises);
        await settleStore.setBankDetails(detailsStore.dealDetails);
      } else {
        setIsErrorDialogOpen(true);
      }
      if (detailsStore.error !== null) {
        setIsErrorDialogOpen(true);
      }
      setIsScreenBlockingLoading(false);
    };

    getDetails();

    return () => {
      // The store needs to get cleared once the page is unmounted to remove the persistent data.
      detailsStore.resetStore();
      settleStore.reset();
    };
  }, []);

  const getErrorDialogText = (): string => {
    switch (settleStore.errors.screenError) {
      case FiMaturitySettleRequestError.InvalidRequestId:
        return t("common.noResultsFound");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const getDialog = (): React.ReactElement => {
    if (isSubmitDialogOpen) {
      return (
        <TextFieldDialog
          title={t("fi.settleMaturityRequest")}
          onClose={(): void => {
            setIsSubmitDialogOpen(false);
          }}
          isOpen={isSubmitDialogOpen}
          primaryButtonType={"Submit"}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            if (settleStore.validateForm()) {
              await settleStore.settleFiMaturityRequest(requestId, note);
              navigate(
                getPath(
                  Module.Fi,
                  Route.ManageMaturityRequestDetailsWithParams,
                  requestId.toString(),
                ),
              );
            }
            setIsSubmitDialogOpen(false);
          }}
          isCloseIconPresent={!settleStore.isLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const getSubmitActionButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.submit")}
        onClick={async (): Promise<void> => {
          if (settleStore.validateForm()) {
            setIsSubmitDialogOpen(true);
          }
        }}
        buttonType="filled"
      />
    );
  };

  return (
    <Stack
      direction="column"
      alignItems={"center"}
      style={{ width: Size.containerWidth }}
    >
      <PageHeader
        title={t("fi.settleMaturityRequest")}
        actionElement={getSubmitActionButton()}
      />
      {isScreenBlockingLoading ? (
        <LoadingIndicator isLoading={isScreenBlockingLoading} />
      ) : (
        <Stack
          padding={spacing.space2XL}
          gap={spacing.space2XL}
          width={Size.containerWidth}
        >
          <Breadcrumb
            links={[
              {
                label: t("common.manageMaturityRequest"),
                onLabelClick: (): void => {
                  navigate(getPath(Module.Fi, Route.ManageMaturityRequest));
                },
              },
              {
                label: t("fi.fiMaturityRequestDetails"),
                onLabelClick: (): void => {
                  navigate(
                    getPath(
                      Module.Fi,
                      Route.ManageMaturityRequestDetailsWithParams,
                      requestId.toString(),
                    ),
                  );
                },
              },
            ]}
            currentLabel={t("fi.settleMaturityRequest")}
          />
          <FiInvestmentDetailsCard
            store={detailsStore.investmentDetailsStore}
            onButtonClick={() => {
              navigate(
                getFiCashflowDetailsPath(
                  detailsStore.investmentDetailsStore.investmentId,
                  undefined,
                  requestId,
                ) ?? "",
              );
            }}
          />
          <Stack direction={"row"} spacing={spacing.spaceXL}>
            <FiMaturitySettleForm />
            <TransactionDetails
              title={t("dealRequest.transactionDetails")}
              section={[
                {
                  label: undefined,
                  rows: [
                    {
                      label: t("fi.maturedUnits"),
                      value: t("common.decimal2", {
                        val:
                          settleStore.transactionDetails?.maturedUnits ?? "-",
                      }),
                    },
                    {
                      label: t("fi.maturityRequestDetails.maturityAmount", {
                        val: settleStore.transactionDetails?.currency.symbol,
                      }),
                      value: t("common.decimal2", {
                        val:
                          settleStore.transactionDetails?.maturedAmount ?? "-",
                      }),
                    },
                    {
                      label: t(
                        "fi.maturityRequestDetails.couponReceivedOnMaturity",
                        {
                          val: settleStore.transactionDetails?.currency.symbol,
                        },
                      ),
                      value: t("common.decimal2", {
                        val:
                          settleStore.transactionDetails
                            ?.couponReceivedOnMaturity ?? "-",
                      }),
                    },
                  ],
                },
                {
                  label: t("dealRequest.approxChargesAndTaxes"),
                  rows:
                    settleStore.transactionDetails?.charges.map((charge) => {
                      // @ts-ignore
                      const chargeLabel: string = t(`${charge.displayName}`, {
                        val: settleStore.transactionDetails?.currency.symbol,
                      });
                      return {
                        label: chargeLabel,
                        value: t("common.decimal2", {
                          val: charge.amount ?? "-",
                        }),
                      };
                    }) ?? [],
                },
              ]}
              isSell={false}
              totalAmountLabel={t(
                "fi.maturityRequestDetails.totalSettlementAmount",
                { val: settleStore.transactionDetails?.currency.symbol },
              )}
              totalAmount={
                settleStore.transactionDetails?.totalSettlementAmount
              }
              postfixLabel={settleStore.transactionDetails?.currency.symbol}
              errorBankBalance={false}
              isTransactionDetailsLoading={
                settleStore.isTransactionDetailsLoading
              }
            />
          </Stack>
        </Stack>
      )}
      {getDialog()}
      <ErrorDialog
        errorMessage={getErrorDialogText()}
        isErrorDialogOpen={isErrorDialogOpen}
        onClose={(): void => {
          if (
            settleStore.errors.screenError ===
            FiMaturitySettleRequestError.InvalidRequestId
          ) {
            navigate(-1);
          }
          setIsErrorDialogOpen(false);
        }}
      />
    </Stack>
  );
});
