"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDWithdrawListItem = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const fDWithdrawStatus_1 = require("./fDWithdrawStatus");
class InvalidFDWithdrawListItemError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDWithdrawListItemError";
    }
}
class FDWithdrawListItem {
    constructor(requestId, withdrawalAmount, penaltyAmount, penaltyCharges, withdrawReferenceNumber = null, status, actualWithdrawalDate = null, requestedWithdrawDate) {
        this.isFDWithdrawListItem = true;
        FDWithdrawListItem.validateRequestId(requestId);
        this.requestId = requestId;
        FDWithdrawListItem.validateWithdrawalAmount(withdrawalAmount);
        this.withdrawalAmount = withdrawalAmount;
        FDWithdrawListItem.validatePenaltyAmount(penaltyAmount);
        this.penaltyAmount = penaltyAmount;
        FDWithdrawListItem.validatePenaltyCharges(penaltyCharges);
        this.penaltyCharges = penaltyCharges;
        if (withdrawReferenceNumber !== undefined && withdrawReferenceNumber !== null) {
            FDWithdrawListItem.validateWithdrawReferenceNumber(withdrawReferenceNumber);
        }
        this.withdrawReferenceNumber = withdrawReferenceNumber;
        FDWithdrawListItem.validateStatus(status);
        this.status = status;
        if (actualWithdrawalDate !== undefined && actualWithdrawalDate !== null) {
            FDWithdrawListItem.validateActualWithdrawalDate(actualWithdrawalDate);
        }
        this.actualWithdrawalDate = actualWithdrawalDate;
        FDWithdrawListItem.validateRequestedWithdrawDate(requestedWithdrawDate);
        this.requestedWithdrawDate = requestedWithdrawDate;
    }
    static fromDTO(dto) {
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidFDWithdrawListItemError);
        const withdrawalAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "withdrawalAmount", InvalidFDWithdrawListItemError));
        const penaltyAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "penaltyAmount", InvalidFDWithdrawListItemError));
        const penaltyCharges = (0, leo_ts_runtime_1.getInt32)(dto, "penaltyCharges", InvalidFDWithdrawListItemError);
        const withdrawReferenceNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "withdrawReferenceNumber", InvalidFDWithdrawListItemError);
        const status = fDWithdrawStatus_1.FDWithdrawStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidFDWithdrawListItemError));
        const actualWithdrawalDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "actualWithdrawalDate", InvalidFDWithdrawListItemError);
        const requestedWithdrawDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "requestedWithdrawDate", InvalidFDWithdrawListItemError);
        return new FDWithdrawListItem(requestId, withdrawalAmount, penaltyAmount, penaltyCharges, withdrawReferenceNumber, status, actualWithdrawalDate, requestedWithdrawDate);
    }
    toDTO() {
        const ret = {
            requestId: this.requestId,
            withdrawalAmount: this.withdrawalAmount.toDTO(),
            penaltyAmount: this.penaltyAmount.toDTO(),
            penaltyCharges: this.penaltyCharges,
            status: fDWithdrawStatus_1.FDWithdrawStatus.toDTO(this.status),
            requestedWithdrawDate: this.requestedWithdrawDate.toDTO(),
        };
        if (this.withdrawReferenceNumber !== undefined && this.withdrawReferenceNumber !== null) {
            ret.withdrawReferenceNumber = this.withdrawReferenceNumber;
        }
        if (this.actualWithdrawalDate) {
            ret.actualWithdrawalDate = this.actualWithdrawalDate.toDTO();
        }
        return ret;
    }
    copy(requestId = this.requestId, withdrawalAmount = this.withdrawalAmount, penaltyAmount = this.penaltyAmount, penaltyCharges = this.penaltyCharges, withdrawReferenceNumber = this.withdrawReferenceNumber, status = this.status, actualWithdrawalDate = this.actualWithdrawalDate, requestedWithdrawDate = this.requestedWithdrawDate) {
        return new FDWithdrawListItem(requestId, withdrawalAmount, penaltyAmount, penaltyCharges, withdrawReferenceNumber, status, actualWithdrawalDate, requestedWithdrawDate);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFDWithdrawListItemValue = other.isFDWithdrawListItem;
        if (!(other instanceof FDWithdrawListItem) || Boolean(isFDWithdrawListItemValue)) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (!this.withdrawalAmount.equals(other.withdrawalAmount)) {
            return false;
        }
        if (!this.penaltyAmount.equals(other.penaltyAmount)) {
            return false;
        }
        if (this.penaltyCharges !== other.penaltyCharges) {
            return false;
        }
        if (this.withdrawReferenceNumber !== other.withdrawReferenceNumber) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.actualWithdrawalDate) {
            if (!this.actualWithdrawalDate.equals(other.actualWithdrawalDate)) {
                return false;
            }
        }
        if (!this.requestedWithdrawDate.equals(other.requestedWithdrawDate)) {
            return false;
        }
        return true;
    }
}
exports.FDWithdrawListItem = FDWithdrawListItem;
FDWithdrawListItem.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidFDWithdrawListItemError(`Attribute requestId is not an Int64`);
    }
};
FDWithdrawListItem.validateWithdrawalAmount = (withdrawalAmount) => {
    const isTransactionAmountValue = withdrawalAmount.isTransactionAmount;
    if (!(withdrawalAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFDWithdrawListItemError(`Attribute withdrawalAmount is not a TransactionAmount`);
    }
};
FDWithdrawListItem.validatePenaltyAmount = (penaltyAmount) => {
    const isTransactionAmountValue = penaltyAmount.isTransactionAmount;
    if (!(penaltyAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidFDWithdrawListItemError(`Attribute penaltyAmount is not a TransactionAmount`);
    }
};
FDWithdrawListItem.validatePenaltyCharges = (penaltyCharges) => {
    if (!(0, leo_ts_runtime_1.isInt32)(penaltyCharges)) {
        throw new InvalidFDWithdrawListItemError(`Attribute penaltyCharges is not an Int32`);
    }
    if (penaltyCharges > 10000000) {
        throw new InvalidFDWithdrawListItemError(`Attribute penaltyCharges has value ${penaltyCharges}. Max value is 10000000.`);
    }
};
FDWithdrawListItem.validateWithdrawReferenceNumber = (withdrawReferenceNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(withdrawReferenceNumber)) {
        throw new InvalidFDWithdrawListItemError(`Attribute withdrawReferenceNumber is not a String`);
    }
    if (withdrawReferenceNumber.trim().length === 0) {
        throw new InvalidFDWithdrawListItemError(`Attribute withdrawReferenceNumber cannot be an empty string.`);
    }
};
FDWithdrawListItem.validateStatus = (status) => {
    if (!fDWithdrawStatus_1.FDWithdrawStatus.isInstanceOf(status)) {
        throw new InvalidFDWithdrawListItemError(`Attribute status is not a FDWithdrawStatus.FDWithdrawStatus`);
    }
};
FDWithdrawListItem.validateActualWithdrawalDate = (actualWithdrawalDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(actualWithdrawalDate)) {
        throw new InvalidFDWithdrawListItemError(`Attribute actualWithdrawalDate is not a Date`);
    }
};
FDWithdrawListItem.validateRequestedWithdrawDate = (requestedWithdrawDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(requestedWithdrawDate)) {
        throw new InvalidFDWithdrawListItemError(`Attribute requestedWithdrawDate is not a Date`);
    }
};
FDWithdrawListItem.prototype.toString = function toString() {
    return `FDWithdrawListItem(requestId=${this.requestId},withdrawalAmount=${this.withdrawalAmount},penaltyAmount=${this.penaltyAmount},penaltyCharges=${this.penaltyCharges},withdrawReferenceNumber='${this.withdrawReferenceNumber}',status=${this.status},actualWithdrawalDate=${this.actualWithdrawalDate},requestedWithdrawDate=${this.requestedWithdrawDate})`;
};
