import { Instance, types } from "mobx-state-tree";
import { FDDealRequestAction } from "@khazana/khazana-rpcs";

export const FDDealRequestActionModel = types.model({
  allowCancel: types.optional(types.boolean, false),
  allowCheck: types.optional(types.boolean, false),
  allowUpload: types.optional(types.boolean, false),
  allowSettle: types.optional(types.boolean, false),
  allowWithdraw: types.optional(types.boolean, false),
  allowEditCertificate: types.optional(types.boolean, false),
});

export const createFDDealRequestActionModel = (
  fdDealRequestAction?: FDDealRequestAction,
): Instance<typeof FDDealRequestActionModel> => {
  if (fdDealRequestAction) {
    return FDDealRequestActionModel.create({
      allowCancel: fdDealRequestAction.allowCancel,
      allowCheck: fdDealRequestAction.allowCheck,
      allowUpload: fdDealRequestAction.allowUpload,
      allowSettle: fdDealRequestAction.allowSettle,
      allowWithdraw: fdDealRequestAction.allowWithdraw,
      allowEditCertificate: fdDealRequestAction.allowEditCertificate,
    });
  }
  return FDDealRequestActionModel.create();
};
