import { observer } from "mobx-react";
import React from "react";
import { Box, Stack } from "@mui/material";
import {
  DetailsSection,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { getDetailCellRows } from "../../../utils/DetailCellUtils";
import { Module } from "../../../../../routes/RoutesEnum";
import { useMFDealRequestDetailsStore } from "../store/DealRequestDetailsStore/hooks";
import { LineGraph } from "./LineGraph";

const Size = {
  container: "100%",
  lineGraph: "calc(100% - 496px)",
};
export const MFDealRequestDetailsSection = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const cornerRadius = useCornerRadius();
  const store = useMFDealRequestDetailsStore();

  return (
    <Stack
      sx={{
        backgroundColor: tokens.backgroundSubtle,
        width: Size.container,
        flexDirection: "row",
        borderRadius: cornerRadius.radiusXS,
      }}
    >
      <Box
        sx={{
          width:
            store.lineGraphData !== null && store.lineGraphData?.length > 0
              ? Size.lineGraph
              : Size.container,
        }}
      >
        <DetailsSection
          title={t("common.dealRequestDetails")}
          rows={getDetailCellRows(store.details, Module.MF)}
        />
      </Box>
      {store.lineGraphData !== null && store.lineGraphData?.length > 0 && (
        <LineGraph />
      )}
    </Stack>
  );
});
