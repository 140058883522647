import {
  GetMFHoldingSummaryReportRPC,
  MFHoldingSummaryResponse,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFHoldingSummaryReportRPC extends GetMFHoldingSummaryReportRPC {
  execute(
    _request: GetMFHoldingSummaryReportRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFHoldingSummaryReportRPC.Response,
      GetMFHoldingSummaryReportRPC.Errors.Errors
    >
  > {
    const response = new GetMFHoldingSummaryReportRPC.Response(
      new LeoDate(),
      [new MFHoldingSummaryResponse("INE100078912")],
      10,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFHoldingSummaryReportRPC.Response,
        GetMFHoldingSummaryReportRPC.Errors.Errors
      >
    >;
  }
}
