"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckMFFolioCreationRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const checkResponse_1 = require("../types/checkResponse");
/* eslint-disable import/export */
class CheckMFFolioCreationRequestRPC {
}
exports.CheckMFFolioCreationRequestRPC = CheckMFFolioCreationRequestRPC;
(function (CheckMFFolioCreationRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CheckMFFolioCreationRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(confirmationRequestId, checkResponse) {
            this.isCheckMFFolioCreationRequestRPCRequest = true;
            Request.validateConfirmationRequestId(confirmationRequestId);
            this.confirmationRequestId = confirmationRequestId;
            Request.validateCheckResponse(checkResponse);
            this.checkResponse = checkResponse;
        }
        static fromDTO(dto) {
            const confirmationRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "confirmationRequestId", InvalidRequestError);
            const checkResponse = checkResponse_1.CheckResponse.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "checkResponse", InvalidRequestError));
            return new Request(confirmationRequestId, checkResponse);
        }
        toDTO() {
            const ret = {
                confirmationRequestId: this.confirmationRequestId,
                checkResponse: this.checkResponse.toDTO(),
            };
            return ret;
        }
        copy(confirmationRequestId = this.confirmationRequestId, checkResponse = this.checkResponse) {
            return new Request(confirmationRequestId, checkResponse);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCheckMFFolioCreationRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.confirmationRequestId !== other.confirmationRequestId) {
                return false;
            }
            if (!this.checkResponse.equals(other.checkResponse)) {
                return false;
            }
            return true;
        }
    }
    Request.validateConfirmationRequestId = (confirmationRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(confirmationRequestId)) {
            throw new InvalidRequestError(`Attribute confirmationRequestId is not an Int64`);
        }
    };
    Request.validateCheckResponse = (checkResponse) => {
        const isCheckResponseValue = checkResponse.isCheckResponse;
        if (!(checkResponse instanceof checkResponse_1.CheckResponse || Boolean(isCheckResponseValue))) {
            throw new InvalidRequestError(`Attribute checkResponse is not a CheckResponse`);
        }
    };
    CheckMFFolioCreationRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CheckMFFolioCreationRequestRPC.Request(confirmationRequestId=${this.confirmationRequestId},checkResponse=${this.checkResponse})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CheckMFFolioCreationRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CheckMFFolioCreationRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class CannotCheckSelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CANNOT_CHECK_SELF_REQUEST") {
                super(code);
                this.isCannotCheckSelfRequest = true;
                CannotCheckSelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CannotCheckSelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CannotCheckSelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCannotCheckSelfRequestValue = other.isCannotCheckSelfRequest;
                if (!(other instanceof CannotCheckSelfRequest || Boolean(isCannotCheckSelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CannotCheckSelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CANNOT_CHECK_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CannotCheckSelfRequest = CannotCheckSelfRequest;
        CannotCheckSelfRequest.prototype.toString = function toString() {
            return `CheckMFFolioCreationRequestRPC.CANNOT_CHECK_SELF_REQUEST()`;
        };
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `CheckMFFolioCreationRequestRPC.INVALID_REQUEST_ID()`;
        };
        class FolioAlreadyExists extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "FOLIO_ALREADY_EXISTS") {
                super(code);
                this.isFolioAlreadyExists = true;
                FolioAlreadyExists.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new FolioAlreadyExists(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new FolioAlreadyExists(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isFolioAlreadyExistsValue = other.isFolioAlreadyExists;
                if (!(other instanceof FolioAlreadyExists || Boolean(isFolioAlreadyExistsValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        FolioAlreadyExists.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "FOLIO_ALREADY_EXISTS") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.FolioAlreadyExists = FolioAlreadyExists;
        FolioAlreadyExists.prototype.toString = function toString() {
            return `CheckMFFolioCreationRequestRPC.FOLIO_ALREADY_EXISTS()`;
        };
        class IllegalConfirmationRequestState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONFIRMATION_REQUEST_STATE") {
                super(code);
                this.isIllegalConfirmationRequestState = true;
                IllegalConfirmationRequestState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalConfirmationRequestState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalConfirmationRequestState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalConfirmationRequestStateValue = other.isIllegalConfirmationRequestState;
                if (!(other instanceof IllegalConfirmationRequestState || Boolean(isIllegalConfirmationRequestStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalConfirmationRequestState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONFIRMATION_REQUEST_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalConfirmationRequestState = IllegalConfirmationRequestState;
        IllegalConfirmationRequestState.prototype.toString = function toString() {
            return `CheckMFFolioCreationRequestRPC.ILLEGAL_CONFIRMATION_REQUEST_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "CANNOT_CHECK_SELF_REQUEST":
                    return CannotCheckSelfRequest.fromDTO(dto);
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "FOLIO_ALREADY_EXISTS":
                    return FolioAlreadyExists.fromDTO(dto);
                case "ILLEGAL_CONFIRMATION_REQUEST_STATE":
                    return IllegalConfirmationRequestState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isCannotCheckSelfRequestValue = other.isCannotCheckSelfRequest;
            if (other instanceof CannotCheckSelfRequest || Boolean(isCannotCheckSelfRequestValue)) {
                return true;
            }
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isFolioAlreadyExistsValue = other.isFolioAlreadyExists;
            if (other instanceof FolioAlreadyExists || Boolean(isFolioAlreadyExistsValue)) {
                return true;
            }
            const isIllegalConfirmationRequestStateValue = other.isIllegalConfirmationRequestState;
            if (other instanceof IllegalConfirmationRequestState || Boolean(isIllegalConfirmationRequestStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CheckMFFolioCreationRequestRPC.Errors || (CheckMFFolioCreationRequestRPC.Errors = {}));
})(CheckMFFolioCreationRequestRPC = exports.CheckMFFolioCreationRequestRPC || (exports.CheckMFFolioCreationRequestRPC = {}));
