import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import {
  DropdownInputFieldSeparateLabel,
  DropdownItem,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { PortfolioDropdownStore } from "./PortfolioDropdownStore";
import { PortfolioModel } from "../../models/PortfolioModel";
import { observer } from "mobx-react";
import { AllEnum } from "../../../../types/EnumTypes";

interface PortfolioDropdownProps {
  store: Instance<typeof PortfolioDropdownStore>;
  isDisabled?: boolean;
  onChange?: () => void;
  width?: string;
  isRequired?: boolean;
  error?: string;
  isAllOptionEnabled?: boolean;
}

export const PortfolioDropdown = observer(
  ({
    store,
    isDisabled,
    onChange,
    width = "auto",
    isRequired = false,
    error,
    isAllOptionEnabled = false,
  }: PortfolioDropdownProps): React.ReactElement => {
    const { t } = useTranslation();

    const portfolioList = useMemo((): DropdownItem[] => {
      const list = store.portfolioList.map((portfolio) => {
        return { name: portfolio.name, value: portfolio.id };
      });
      if (isAllOptionEnabled && store.portfolioList.length !== 1) {
        list.push({
          name: t("common.all"),
          value: AllEnum.All,
        });
      }
      return list;
    }, [...store.portfolioList, isAllOptionEnabled]);

    return (
      <Stack>
        <DropdownInputFieldSeparateLabel
          name="portfolio"
          style={{ width }}
          label={t("common.portfolio")}
          placeholder={t("common.selectPortfolio")}
          value={
            store.selectedPortfolio?.id === ""
              ? undefined
              : store.selectedPortfolio?.id
          }
          options={portfolioList}
          isDisabled={isDisabled}
          isLoading={store.isLoading}
          onSelect={(portfolio): void => {
            if (onChange) {
              onChange();
            }
            store.setSelectedPortfolio(
              PortfolioModel.create({
                id: portfolio.value,
                name: portfolio.name,
              }),
            );
          }}
          error={error !== undefined}
          helperText={error}
          isRequired={isRequired}
          loadingText={t("common.loading")}
        />
      </Stack>
    );
  },
);
