import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { InvestmentCashflowBanner } from "../../../components/cash-flow-summary/InvestmentCashflowBanner";
import { Tabs } from "../../../components/Tabs";
import { useBorder } from "../../../../../utils/BorderUtils";
import { useFdViewCashflowsStore } from "../../store/hooks";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { FdInvestmentDetailsCard } from "../../components/investment-details/FdInvestmentDetailsCard";
import { FdCashflowTable } from "../components/FdCashflowTable";
import { FdAccrualTable } from "../components/FdAccrualTable";

const Size = {
  containerWidth: "100%",
};

export const ViewFdInvestmentCashFlows = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const { t } = useTranslation();
  const store = useFdViewCashflowsStore();
  const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  useState(false);
  const [searchParam] = useSearchParams();
  const requestId = searchParam.get("investmentId")
    ? Number(searchParam.get("investmentId"))
    : undefined;
  const dealRequestId = searchParam.get("dealRequestId")
    ? Number(searchParam.get("dealRequestId"))
    : undefined;
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();

  useEffect(() => {
    store.setPreviousId(dealRequestId);
  }, []);

  const getDetails = async (): Promise<void> => {
    setIsScreenBlockingLoading(true);
    store.softReset();
    if (requestId) {
      const promises = [
        store.getInvestmentDetails(requestId),
        store.getInvestmentBannerData(requestId),
      ];
      await Promise.all(promises);
    } else {
      setIsErrorDialogOpen(true);
    }
    if (store.error) {
      setIsErrorDialogOpen(true);
    }
    setIsScreenBlockingLoading(false);
  };

  useEffect(() => {
    getDetails();
    return () => {
      // The store needs to get cleared once the page is unmounted to remove the persistent data.
      store.reset();
    };
  }, []);

  return (
    <Stack
      direction="column"
      alignItems={"center"}
      style={{ width: Size.containerWidth }}
    >
      <LoadingIndicator isLoading={isScreenBlockingLoading} />
      <PageHeader title={t("cashflow.investmentCashFlowsTitle")} />
      <Stack
        padding={spacing.space2XL}
        gap={spacing.space2XL}
        width={Size.containerWidth}
      >
        <Breadcrumb
          links={[
            {
              label: t("common.manageDealRequest"),
              onLabelClick: (): void => {
                navigate(getPath(Module.Fd, Route.ManageDealRequest));
              },
            },
            {
              label: t("fd.dealDetails.pageTitle"),
              onLabelClick: (): void => {
                navigate(
                  getPath(
                    Module.Fd,
                    Route.ManageDealRequestDetailsWithParams,
                    store.previousDealRequestId?.toString(),
                  ),
                );
              },
            },
          ]}
          currentLabel={t("cashflow.investmentCashFlowsTitle")}
        />
        <FdInvestmentDetailsCard
          store={store.investmentDetailsStore}
          onButtonClick={() => {}}
        />
        <InvestmentCashflowBanner store={store.investmentDetailsBannerStore} />
        {!isScreenBlockingLoading && (
          <Stack
            sx={{
              backgroundColor: tokens.background,
              width: Size.containerWidth,
              borderRadius: cornerRadius.radiusXS,
              border: border.default,
              borderRight: border.default,
            }}
          >
            <Tabs
              tabs={[
                { label: t("cashflow.cashflowTable.cashflowsTitle") },
                { label: t("cashflow.accrualsTable.accrualsTitle") },
              ]}
              onTabChange={(index) => {
                setTab(index);
              }}
              tabIndex={tab}
            ></Tabs>
            {tab === 0 ? (
              <FdCashflowTable requestId={requestId} getDetails={getDetails} />
            ) : (
              <FdAccrualTable requestId={requestId} getDetails={getDetails} />
            )}
          </Stack>
        )}
      </Stack>
      <ErrorDialog
        errorMessage={t("common.somethingWentWrong")}
        isErrorDialogOpen={isErrorDialogOpen}
        onClose={(): void => {
          navigate(-1);
          setIsErrorDialogOpen(false);
        }}
      />
    </Stack>
  );
});
