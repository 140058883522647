import { ValidateMFConfirmationRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockValidateMFConfirmationRequestRPCImpl extends ValidateMFConfirmationRequestRPC {
  execute(
    _request: ValidateMFConfirmationRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      ValidateMFConfirmationRequestRPC.Response,
      ValidateMFConfirmationRequestRPC.Errors.Errors
    >
  > {
    const response = new ValidateMFConfirmationRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        ValidateMFConfirmationRequestRPC.Response,
        ValidateMFConfirmationRequestRPC.Errors.Errors
      >
    >;
  }
}
