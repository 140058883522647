"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiBuyDealSummaryDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const summaryDetailCell_1 = require("../types/summaryDetailCell");
const cashflowTimelineItem_1 = require("../types/cashflowTimelineItem");
const transactionAmount_1 = require("../types/transactionAmount");
const fiBuyDealValue_1 = require("./fiBuyDealValue");
const fiPriceYtm_1 = require("./fiPriceYtm");
/* eslint-disable import/export */
class GetFiBuyDealSummaryDetailsRPC {
}
exports.GetFiBuyDealSummaryDetailsRPC = GetFiBuyDealSummaryDetailsRPC;
(function (GetFiBuyDealSummaryDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiBuyDealSummaryDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(dealValue = null, isin, priceYtm = null) {
            this.isGetFiBuyDealSummaryDetailsRPCRequest = true;
            if (dealValue !== undefined && dealValue !== null) {
                Request.validateDealValue(dealValue);
            }
            this.dealValue = dealValue;
            Request.validateIsin(isin);
            this.isin = isin;
            if (priceYtm !== undefined && priceYtm !== null) {
                Request.validatePriceYtm(priceYtm);
            }
            this.priceYtm = priceYtm;
        }
        static fromDTO(dto) {
            let dealValue = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealValue");
            if (dealValue !== undefined && dealValue !== null) {
                dealValue = fiBuyDealValue_1.FiBuyDealValue.fromDTO(dealValue);
            }
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            let priceYtm = (0, leo_ts_runtime_1.getOptionalObject)(dto, "priceYtm");
            if (priceYtm !== undefined && priceYtm !== null) {
                priceYtm = fiPriceYtm_1.FiPriceYtm.fromDTO(priceYtm);
            }
            return new Request(dealValue, isin, priceYtm);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
            };
            if (this.dealValue) {
                ret.dealValue = this.dealValue.toDTO();
            }
            if (this.priceYtm) {
                ret.priceYtm = this.priceYtm.toDTO();
            }
            return ret;
        }
        copy(dealValue = this.dealValue, isin = this.isin, priceYtm = this.priceYtm) {
            return new Request(dealValue, isin, priceYtm);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiBuyDealSummaryDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.dealValue) {
                if (!this.dealValue.equals(other.dealValue)) {
                    return false;
                }
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (this.priceYtm) {
                if (!this.priceYtm.equals(other.priceYtm)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateDealValue = (dealValue) => {
        if (!fiBuyDealValue_1.FiBuyDealValue.isInstanceOf(dealValue)) {
            throw new InvalidRequestError(`Attribute dealValue is not a FiBuyDealValue.FiBuyDealValue`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validatePriceYtm = (priceYtm) => {
        if (!fiPriceYtm_1.FiPriceYtm.isInstanceOf(priceYtm)) {
            throw new InvalidRequestError(`Attribute priceYtm is not a FiPriceYtm.FiPriceYtm`);
        }
    };
    GetFiBuyDealSummaryDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiBuyDealSummaryDetailsRPC.Request(dealValue=${this.dealValue},isin=${this.isin},priceYtm=${this.priceYtm})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiBuyDealSummaryDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(transactionDetails, securityDetails, cashflowTimeline) {
            this.isGetFiBuyDealSummaryDetailsRPCResponse = true;
            Response.validateTransactionDetails(transactionDetails);
            this.transactionDetails = transactionDetails;
            Response.validateSecurityDetails(securityDetails);
            this.securityDetails = securityDetails;
            Response.validateCashflowTimeline(cashflowTimeline);
            this.cashflowTimeline = cashflowTimeline;
        }
        static fromDTO(dto) {
            const transactionDetails = (0, leo_ts_runtime_1.getList)(dto, "transactionDetails", summaryDetailCell_1.SummaryDetailCell.fromDTO, InvalidResponseError);
            const securityDetails = (0, leo_ts_runtime_1.getList)(dto, "securityDetails", summaryDetailCell_1.SummaryDetailCell.fromDTO, InvalidResponseError);
            const cashflowTimeline = (0, leo_ts_runtime_1.getList)(dto, "cashflowTimeline", cashflowTimelineItem_1.CashflowTimelineItem.fromDTO, InvalidResponseError);
            return new Response(transactionDetails, securityDetails, cashflowTimeline);
        }
        toDTO() {
            const ret = {
                transactionDetails: this.transactionDetails.map((e) => {
                    return e.toDTO();
                }),
                securityDetails: this.securityDetails.map((e) => {
                    return e.toDTO();
                }),
                cashflowTimeline: this.cashflowTimeline.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(transactionDetails = this.transactionDetails, securityDetails = this.securityDetails, cashflowTimeline = this.cashflowTimeline) {
            return new Response(transactionDetails, securityDetails, cashflowTimeline);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiBuyDealSummaryDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.transactionDetails, other.transactionDetails)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.securityDetails, other.securityDetails)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.cashflowTimeline, other.cashflowTimeline)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTransactionDetails = (transactionDetails) => {
        if (!Array.isArray(transactionDetails)) {
            throw new InvalidResponseError(`Attribute transactionDetails is not a List`);
        }
        for (let i = 0; i < transactionDetails.length; i += 1) {
            const isSummaryDetailCellValue = transactionDetails[i].isSummaryDetailCell;
            if (!(transactionDetails[i] instanceof summaryDetailCell_1.SummaryDetailCell || Boolean(isSummaryDetailCellValue))) {
                throw new InvalidResponseError(`Attribute transactionDetails at index ${i} is not a SummaryDetailCell.`);
            }
        }
    };
    Response.validateSecurityDetails = (securityDetails) => {
        if (!Array.isArray(securityDetails)) {
            throw new InvalidResponseError(`Attribute securityDetails is not a List`);
        }
        for (let i = 0; i < securityDetails.length; i += 1) {
            const isSummaryDetailCellValue = securityDetails[i].isSummaryDetailCell;
            if (!(securityDetails[i] instanceof summaryDetailCell_1.SummaryDetailCell || Boolean(isSummaryDetailCellValue))) {
                throw new InvalidResponseError(`Attribute securityDetails at index ${i} is not a SummaryDetailCell.`);
            }
        }
    };
    Response.validateCashflowTimeline = (cashflowTimeline) => {
        if (!Array.isArray(cashflowTimeline)) {
            throw new InvalidResponseError(`Attribute cashflowTimeline is not a List`);
        }
        for (let i = 0; i < cashflowTimeline.length; i += 1) {
            const isCashflowTimelineItemValue = cashflowTimeline[i].isCashflowTimelineItem;
            if (!(cashflowTimeline[i] instanceof cashflowTimelineItem_1.CashflowTimelineItem || Boolean(isCashflowTimelineItemValue))) {
                throw new InvalidResponseError(`Attribute cashflowTimeline at index ${i} is not a CashflowTimelineItem.`);
            }
        }
    };
    GetFiBuyDealSummaryDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiBuyDealSummaryDetailsRPC.Response(transactionDetails=${this.transactionDetails},securityDetails=${this.securityDetails},cashflowTimeline=${this.cashflowTimeline})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidCurrency extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CURRENCY") {
                super(code);
                this.isInvalidCurrency = true;
                InvalidCurrency.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidCurrency(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidCurrency(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidCurrencyValue = other.isInvalidCurrency;
                if (!(other instanceof InvalidCurrency || Boolean(isInvalidCurrencyValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidCurrency.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CURRENCY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidCurrency = InvalidCurrency;
        InvalidCurrency.prototype.toString = function toString() {
            return `GetFiBuyDealSummaryDetailsRPC.INVALID_CURRENCY()`;
        };
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetFiBuyDealSummaryDetailsRPC.INVALID_ISIN()`;
        };
        class NeitherMaturityNorNextOptionDateFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                super(code);
                this.isNeitherMaturityNorNextOptionDateFound = true;
                NeitherMaturityNorNextOptionDateFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
                if (!(other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NeitherMaturityNorNextOptionDateFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NeitherMaturityNorNextOptionDateFound = NeitherMaturityNorNextOptionDateFound;
        NeitherMaturityNorNextOptionDateFound.prototype.toString = function toString() {
            return `GetFiBuyDealSummaryDetailsRPC.NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND()`;
        };
        class GrossAmountTooLess extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "GROSS_AMOUNT_TOO_LESS", faceValue) {
                super(code);
                this.isGrossAmountTooLess = true;
                GrossAmountTooLess.validateCode(code);
                GrossAmountTooLess.validateFaceValue(faceValue);
                this.faceValue = faceValue;
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                const faceValue = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "faceValue", InvalidResponseError));
                return new GrossAmountTooLess(code, faceValue);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                    faceValue: this.faceValue.toDTO(),
                };
                return ret;
            }
            copy(code = this.code, faceValue = this.faceValue) {
                return new GrossAmountTooLess(code, faceValue);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isGrossAmountTooLessValue = other.isGrossAmountTooLess;
                if (!(other instanceof GrossAmountTooLess || Boolean(isGrossAmountTooLessValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                if (!this.faceValue.equals(other.faceValue)) {
                    return false;
                }
                return true;
            }
        }
        GrossAmountTooLess.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "GROSS_AMOUNT_TOO_LESS") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        GrossAmountTooLess.validateFaceValue = (faceValue) => {
            const isTransactionAmountValue = faceValue.isTransactionAmount;
            if (!(faceValue instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
                throw new InvalidResponseError(`Attribute faceValue is not a TransactionAmount`);
            }
        };
        Errors.GrossAmountTooLess = GrossAmountTooLess;
        GrossAmountTooLess.prototype.toString = function toString() {
            return `GetFiBuyDealSummaryDetailsRPC.GROSS_AMOUNT_TOO_LESS(faceValue=${this.faceValue})`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CURRENCY":
                    return InvalidCurrency.fromDTO(dto);
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND":
                    return NeitherMaturityNorNextOptionDateFound.fromDTO(dto);
                case "GROSS_AMOUNT_TOO_LESS":
                    return GrossAmountTooLess.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidCurrencyValue = other.isInvalidCurrency;
            if (other instanceof InvalidCurrency || Boolean(isInvalidCurrencyValue)) {
                return true;
            }
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
            if (other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue)) {
                return true;
            }
            const isGrossAmountTooLessValue = other.isGrossAmountTooLess;
            if (other instanceof GrossAmountTooLess || Boolean(isGrossAmountTooLessValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiBuyDealSummaryDetailsRPC.Errors || (GetFiBuyDealSummaryDetailsRPC.Errors = {}));
})(GetFiBuyDealSummaryDetailsRPC = exports.GetFiBuyDealSummaryDetailsRPC || (exports.GetFiBuyDealSummaryDetailsRPC = {}));
