"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateContractNoteRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const contractNoteEdits_1 = require("./contractNoteEdits");
const contractNoteRow_1 = require("./contractNoteRow");
const contractNoteCharge_1 = require("../types/contractNoteCharge");
/* eslint-disable import/export */
class ValidateContractNoteRequestRPC {
}
exports.ValidateContractNoteRequestRPC = ValidateContractNoteRequestRPC;
(function (ValidateContractNoteRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    ValidateContractNoteRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(eqRawContractNoteDisplayId, contractNoteEdits) {
            this.isValidateContractNoteRequestRPCRequest = true;
            Request.validateEqRawContractNoteDisplayId(eqRawContractNoteDisplayId);
            this.eqRawContractNoteDisplayId = eqRawContractNoteDisplayId;
            Request.validateContractNoteEdits(contractNoteEdits);
            this.contractNoteEdits = contractNoteEdits;
        }
        static fromDTO(dto) {
            const eqRawContractNoteDisplayId = (0, leo_ts_runtime_1.getInt64)(dto, "eqRawContractNoteDisplayId", InvalidRequestError);
            const contractNoteEdits = contractNoteEdits_1.ContractNoteEdits.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "contractNoteEdits", InvalidRequestError));
            return new Request(eqRawContractNoteDisplayId, contractNoteEdits);
        }
        toDTO() {
            const ret = {
                eqRawContractNoteDisplayId: this.eqRawContractNoteDisplayId,
                contractNoteEdits: this.contractNoteEdits.toDTO(),
            };
            return ret;
        }
        copy(eqRawContractNoteDisplayId = this.eqRawContractNoteDisplayId, contractNoteEdits = this.contractNoteEdits) {
            return new Request(eqRawContractNoteDisplayId, contractNoteEdits);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isValidateContractNoteRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.eqRawContractNoteDisplayId !== other.eqRawContractNoteDisplayId) {
                return false;
            }
            if (!this.contractNoteEdits.equals(other.contractNoteEdits)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEqRawContractNoteDisplayId = (eqRawContractNoteDisplayId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(eqRawContractNoteDisplayId)) {
            throw new InvalidRequestError(`Attribute eqRawContractNoteDisplayId is not an Int64`);
        }
    };
    Request.validateContractNoteEdits = (contractNoteEdits) => {
        const isContractNoteEditsValue = contractNoteEdits.isContractNoteEdits;
        if (!(contractNoteEdits instanceof contractNoteEdits_1.ContractNoteEdits || Boolean(isContractNoteEditsValue))) {
            throw new InvalidRequestError(`Attribute contractNoteEdits is not a ContractNoteEdits`);
        }
    };
    ValidateContractNoteRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `ValidateContractNoteRequestRPC.Request(eqRawContractNoteDisplayId=${this.eqRawContractNoteDisplayId},contractNoteEdits=${this.contractNoteEdits})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    ValidateContractNoteRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(tempContractNoteId) {
            this.isValidateContractNoteRequestRPCResponse = true;
            Response.validateTempContractNoteId(tempContractNoteId);
            this.tempContractNoteId = tempContractNoteId;
        }
        static fromDTO(dto) {
            const tempContractNoteId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "tempContractNoteId", InvalidResponseError);
            return new Response(tempContractNoteId);
        }
        toDTO() {
            const ret = {
                tempContractNoteId: this.tempContractNoteId.toDTO(),
            };
            return ret;
        }
        copy(tempContractNoteId = this.tempContractNoteId) {
            return new Response(tempContractNoteId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isValidateContractNoteRequestRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.tempContractNoteId.equals(other.tempContractNoteId)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTempContractNoteId = (tempContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(tempContractNoteId)) {
            throw new InvalidResponseError(`Attribute tempContractNoteId is not a UUID`);
        }
    };
    ValidateContractNoteRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `ValidateContractNoteRequestRPC.Response(tempContractNoteId=${this.tempContractNoteId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidContractNoteId = true;
                InvalidContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
                if (!(other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidContractNoteId = InvalidContractNoteId;
        InvalidContractNoteId.prototype.toString = function toString() {
            return `ValidateContractNoteRequestRPC.INVALID_CONTRACT_NOTE_ID()`;
        };
        class DataMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DATA_MISMATCH", brokerId, details, charges) {
                super(code);
                this.isDataMismatch = true;
                DataMismatch.validateCode(code);
                DataMismatch.validateBrokerId(brokerId);
                this.brokerId = brokerId;
                DataMismatch.validateDetails(details);
                this.details = details;
                DataMismatch.validateCharges(charges);
                this.charges = charges;
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                const brokerId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "brokerId", InvalidResponseError);
                const details = (0, leo_ts_runtime_1.getList)(dto, "details", contractNoteRow_1.ContractNoteRow.fromDTO, InvalidResponseError);
                const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", contractNoteCharge_1.ContractNoteCharge.fromDTO, InvalidResponseError);
                return new DataMismatch(code, brokerId, details, charges);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                    brokerId: this.brokerId.toDTO(),
                    details: this.details.map((e) => {
                        return e.toDTO();
                    }),
                    charges: this.charges.map((e) => {
                        return e.toDTO();
                    }),
                };
                return ret;
            }
            copy(code = this.code, brokerId = this.brokerId, details = this.details, charges = this.charges) {
                return new DataMismatch(code, brokerId, details, charges);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDataMismatchValue = other.isDataMismatch;
                if (!(other instanceof DataMismatch || Boolean(isDataMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                if (!this.brokerId.equals(other.brokerId)) {
                    return false;
                }
                if (!(0, leo_ts_runtime_1.listEquals)(this.details, other.details)) {
                    return false;
                }
                if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
                    return false;
                }
                return true;
            }
        }
        DataMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DATA_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        DataMismatch.validateBrokerId = (brokerId) => {
            if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
                throw new InvalidResponseError(`Attribute brokerId is not a UUID`);
            }
        };
        DataMismatch.validateDetails = (details) => {
            if (!Array.isArray(details)) {
                throw new InvalidResponseError(`Attribute details is not a List`);
            }
            for (let i = 0; i < details.length; i += 1) {
                const isContractNoteRowValue = details[i].isContractNoteRow;
                if (!(details[i] instanceof contractNoteRow_1.ContractNoteRow || Boolean(isContractNoteRowValue))) {
                    throw new InvalidResponseError(`Attribute details at index ${i} is not a ContractNoteRow.`);
                }
            }
        };
        DataMismatch.validateCharges = (charges) => {
            if (!Array.isArray(charges)) {
                throw new InvalidResponseError(`Attribute charges is not a List`);
            }
            for (let i = 0; i < charges.length; i += 1) {
                const isContractNoteChargeValue = charges[i].isContractNoteCharge;
                if (!(charges[i] instanceof contractNoteCharge_1.ContractNoteCharge || Boolean(isContractNoteChargeValue))) {
                    throw new InvalidResponseError(`Attribute charges at index ${i} is not a ContractNoteCharge.`);
                }
            }
        };
        Errors.DataMismatch = DataMismatch;
        DataMismatch.prototype.toString = function toString() {
            return `ValidateContractNoteRequestRPC.DATA_MISMATCH(brokerId=${this.brokerId},details=${this.details},charges=${this.charges})`;
        };
        class IllegalContractNoteState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONTRACT_NOTE_STATE") {
                super(code);
                this.isIllegalContractNoteState = true;
                IllegalContractNoteState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalContractNoteState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalContractNoteState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
                if (!(other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalContractNoteState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONTRACT_NOTE_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalContractNoteState = IllegalContractNoteState;
        IllegalContractNoteState.prototype.toString = function toString() {
            return `ValidateContractNoteRequestRPC.ILLEGAL_CONTRACT_NOTE_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CONTRACT_NOTE_ID":
                    return InvalidContractNoteId.fromDTO(dto);
                case "DATA_MISMATCH":
                    return DataMismatch.fromDTO(dto);
                case "ILLEGAL_CONTRACT_NOTE_STATE":
                    return IllegalContractNoteState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
            if (other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue)) {
                return true;
            }
            const isDataMismatchValue = other.isDataMismatch;
            if (other instanceof DataMismatch || Boolean(isDataMismatchValue)) {
                return true;
            }
            const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
            if (other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = ValidateContractNoteRequestRPC.Errors || (ValidateContractNoteRequestRPC.Errors = {}));
})(ValidateContractNoteRequestRPC = exports.ValidateContractNoteRequestRPC || (exports.ValidateContractNoteRequestRPC = {}));
