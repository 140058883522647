import {
  Currency,
  ContractNoteRequestStatus,
  GetEquityContractNotesPaginationResponse,
  GetEquityContractNotesRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoTimestamp } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import { brokerList } from "../../../rpcs/MockGetBrokerListRPCImpl";

const equityContractNotes: GetEquityContractNotesPaginationResponse[] = [
  new GetEquityContractNotesPaginationResponse(
    1,
    ContractNoteRequestStatus.ContractNoteRequestStatus.PARSED,
    brokerList[0].id,
    brokerList[0].name,
    "CLSA-2023-07-20.pdf",
    "A01CM2007",
    "AXISBANK",
    null,
    173979000,
    new LeoTimestamp("2023-09-04T06:23:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetEquityContractNotesPaginationResponse(
    2,
    ContractNoteRequestStatus.ContractNoteRequestStatus.UNPARSED,
    null,
    null,
    "CLSA-2023-07-20.pdf",
    null,
    null,
    null,
    null,
    new LeoTimestamp("2023-09-05T06:45:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetEquityContractNotesPaginationResponse(
    3,
    ContractNoteRequestStatus.ContractNoteRequestStatus.DATA_MISMATCH,
    brokerList[0].id,
    brokerList[0].name,
    "CLSA-2023-07-20.pdf",
    "A01CM2008",
    "HDFCBANK",
    null,
    135567370,
    new LeoTimestamp("2023-09-05T06:45:59Z"),
    new Currency("INR", "₹"),
  ),
  new GetEquityContractNotesPaginationResponse(
    4,
    ContractNoteRequestStatus.ContractNoteRequestStatus.UNKNOWN,
    brokerList[2].id,
    brokerList[2].name,
    "CLSA-2023-07-20.pdf",
    "BSN2223258066",
    "ICICIBANK",
    null,
    23423875905,
    new LeoTimestamp("2023-09-05T08:23:59Z"),
    new Currency("INR", "₹"),
  ),
];

export class MockGetEquityContractNotesRPCImpl extends GetEquityContractNotesRPC {
  execute(
    request: GetEquityContractNotesRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityContractNotesRPC.Response,
      GetEquityContractNotesRPC.Errors.InvalidPageIndex
    >
  > {
    let contractNotes = equityContractNotes;
    if (request.status) {
      contractNotes = contractNotes.filter((t) => {
        return t.status === request.status;
      });
    }
    if (request.brokerId) {
      contractNotes = contractNotes.filter((t) => {
        return t.brokerId?.uuid === request.brokerId?.uuid;
      });
    }
    if (request.dealRequestId) {
      contractNotes = contractNotes.filter((t) => {
        if (!t.dealRequestId) {
          return;
        }
        return t.dealRequestId
          .toString()
          .toLocaleLowerCase()
          .includes(request.dealRequestId?.toLocaleLowerCase()!);
      });
    }
    if (request.contractNoteNumber) {
      contractNotes = contractNotes.filter((t) => {
        return t.contractNoteNumber
          ?.toLocaleLowerCase()
          .includes(request.contractNoteNumber?.toLocaleLowerCase()!);
      });
    }
    if (request.symbol) {
      contractNotes = contractNotes.filter((t) => {
        if (!t.symbol) {
          return;
        }
        return t.symbol
          .toLocaleLowerCase()
          .includes(request.symbol?.toLocaleLowerCase()!);
      });
    }
    const response = new GetEquityContractNotesRPC.Response(
      contractNotes.slice(
        request.pageIndex.items * request.itemsPerPage.items,
        request.itemsPerPage.items,
      ),
      contractNotes.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityContractNotesRPC.Response,
        GetEquityContractNotesRPC.Errors.Errors
      >
    >;
  }
}
