import { Box, Divider, Stack } from "@mui/material";
import {
  DetailsRowProps,
  DetailRowCellEnum,
  DetailsRow,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { getFormattedAmountString } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import { useContractNoteDetailsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";
import { eqContractNoteTransformCell, getCell } from "../utils/UIUtils";

const Size = {
  container: {
    width: "50%",
    height: "Calc(100vh - 220px)",
  },
};

export const ContractNoteDetailSection = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const border = useBorder();
  const store = useContractNoteDetailsStore();

  const getRows = (): DetailsRowProps[] => {
    const rows: DetailsRowProps[] = [];
    if (store.columnHeaders) {
      rows.push({
        label: "",
        cellType: {
          // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
          /* @ts-ignore */
          currentDetailsLabel: t(`${store.columnHeaders.originalDataHeader}`),
          // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
          /* @ts-ignore */
          updatedDetailsLabel: t(`${store.columnHeaders.diffDataHeader}`),
          type: DetailRowCellEnum.DiffHeader,
        },
      });
    }
    store.details?.map((item) => {
      rows.push({
        label: store.currency
          ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.localizedTextId, { val: `(${store.currency.symbol})` })
          : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.localizedTextId, { val: null }),
        cellType: eqContractNoteTransformCell(
          item.localizedTextId,
          item.originalData,
          item.diffData,
        ) ?? {
          text: "",
          type: DetailRowCellEnum.Unstyled,
        },
      });
    });
    store.charges.map((item) => {
      rows.push({
        label: store.currency
          ? // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.displayName) + ` (${store.currency.symbol})`
          : // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            /* @ts-ignore */
            t(item.displayName, { val: null }),
        cellType: getCell(
          item.amount ? getFormattedAmountString(item.amount) : "-",
          item.diffValue !== null
            ? getFormattedAmountString(item.diffValue)
            : undefined,
          false,
        ),
      });
    });
    return rows;
  };

  return (
    <Stack
      sx={{
        height: Size.container.height,
        width: Size.container.width,
        overflow: "-moz-scrollbars-vertical",
        overflowY: "scroll",
        borderRight: border.default,
      }}
    >
      {getRows().map(({ label, cellType }) => (
        <Box key={label}>
          <DetailsRow label={label} cellType={cellType} />
          <Divider />
        </Box>
      ))}
    </Stack>
  );
});
