import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createDashboardStatModel,
  DashboardStatModel,
} from "../../../models/DashboardStatModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetEquityDashboardStatsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetEquityDashboardStatsRPC } from "../rpcs/RPC";
import {
  checkAllOptionAndGetId,
  getLeoDateOrNull,
} from "../../../utils/ReportUtils";

export const EquityDashboardStatsStore = types
  .model("EquityDashboardStatsStore", {
    investedValue: DashboardStatModel,
    marketValue: DashboardStatModel,
    todayPnL: DashboardStatModel,
    unrealisedGain: DashboardStatModel,
    irr: DashboardStatModel,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getEquityDashboardStats: flow(function* (
        entityId?: string,
        portfolioId?: string,
        asOnDate?: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetEquityDashboardStatsRPC.Request(
          checkAllOptionAndGetId(entityId),
          checkAllOptionAndGetId(portfolioId),
          getLeoDateOrNull(asOnDate),
        );
        const result: LeoRPCResult<
          GetEquityDashboardStatsRPC.Response,
          GetEquityDashboardStatsRPC.Errors.Errors
        > = yield useGetEquityDashboardStatsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.investedValue = createDashboardStatModel(
            response.investedValue,
          );
          store.marketValue = createDashboardStatModel(response.marketValue);
          store.todayPnL = createDashboardStatModel(response.todaysPnl);
          store.unrealisedGain = createDashboardStatModel(
            response.unrealizedGain,
          );
          store.irr = createDashboardStatModel(response.irr, false);
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getEquityDashboardStatsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createEquityDashboardStatsStore = (): Instance<
  typeof EquityDashboardStatsStore
> => {
  return EquityDashboardStatsStore.create({
    investedValue: createDashboardStatModel(),
    marketValue: createDashboardStatModel(),
    todayPnL: createDashboardStatModel(),
    unrealisedGain: createDashboardStatModel(),
    irr: createDashboardStatModel(),
  });
};
