"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFPlanType = exports.InvalidMFPlanTypeError = void 0;
class InvalidMFPlanTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFPlanTypeError";
    }
}
exports.InvalidMFPlanTypeError = InvalidMFPlanTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFPlanType;
(function (MFPlanType_1) {
    let MFPlanType;
    (function (MFPlanType) {
        MFPlanType["DIRECT"] = "DIRECT";
        MFPlanType["REGULAR"] = "REGULAR";
    })(MFPlanType = MFPlanType_1.MFPlanType || (MFPlanType_1.MFPlanType = {}));
    MFPlanType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "DIRECT":
                return MFPlanType.DIRECT;
            case "REGULAR":
                return MFPlanType.REGULAR;
            default:
                throw new InvalidMFPlanTypeError(`Case ${dto.case} is not valid case of MFPlanType`);
        }
    };
    MFPlanType_1.toDTO = (mFPlanType) => {
        const ret = {
            case: MFPlanType[mFPlanType],
        };
        return ret;
    };
    MFPlanType_1.isInstanceOf = (other) => {
        if (other in MFPlanType) {
            return true;
        }
        return false;
    };
})(MFPlanType = exports.MFPlanType || (exports.MFPlanType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
