import React from "react";
import { Alert } from "@mui/material";
import {
  useSpacing,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { useTypography } from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../utils/BorderUtils";

export const WarningAlertComponent = ({
  text,
}: {
  text: string;
}): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  return (
    <Alert
      icon={false}
      variant="filled"
      sx={{
        background: tokens.backgroundWarningSubtle,
        border: border.errorSubtle,
        boxSizing: "border-box",
        color: tokens.labelWarning,
        width: "100% !important",
        marginBottom: spacing.spaceLG,
        borderRadius: `${cornerRadius.radiusXXS} !important`,
        ...typography.b1,
      }}
    >
      {text}
    </Alert>
  );
};
