"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutualFundItemsPerPage = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidMutualFundItemsPerPageError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMutualFundItemsPerPageError";
    }
}
class MutualFundItemsPerPage {
    constructor(items) {
        this.isMutualFundItemsPerPage = true;
        MutualFundItemsPerPage.validateItems(items);
        this.items = items;
    }
    static fromDTO(dto) {
        const items = (0, leo_ts_runtime_1.getInt32)(dto, "items", InvalidMutualFundItemsPerPageError);
        return new MutualFundItemsPerPage(items);
    }
    toDTO() {
        const ret = {
            items: this.items,
        };
        return ret;
    }
    copy(items = this.items) {
        return new MutualFundItemsPerPage(items);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMutualFundItemsPerPageValue = other.isMutualFundItemsPerPage;
        if (!(other instanceof MutualFundItemsPerPage) || Boolean(isMutualFundItemsPerPageValue)) {
            return false;
        }
        if (this.items !== other.items) {
            return false;
        }
        return true;
    }
}
exports.MutualFundItemsPerPage = MutualFundItemsPerPage;
MutualFundItemsPerPage.validateItems = (items) => {
    if (!(0, leo_ts_runtime_1.isInt32)(items)) {
        throw new InvalidMutualFundItemsPerPageError(`Attribute items is not an Int32`);
    }
    if (items < 1) {
        throw new InvalidMutualFundItemsPerPageError(`Attribute items has value ${items}. Min value is 1.`);
    }
};
MutualFundItemsPerPage.prototype.toString = function toString() {
    return `MutualFundItemsPerPage(items=${this.items})`;
};
