"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityChangeInGainResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityChangeInGainResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityChangeInGainResponseError";
    }
}
class EquityChangeInGainResponse {
    constructor(symbol, costNewShares = null, costSoldShares = null, costOnEndDate = null, totalDividend = null, irr = null, change = null, totalGains = null, mvOldShares = null, rgNewShares = null, rgOldShares = null, salesProceeds = null, totalMVOnEndDate = null, ugNewShares = null, ugOldShares = null, totalUg = null, totalRg = null, tgOnOldShares = null, tgOnNewShares = null) {
        this.isEquityChangeInGainResponse = true;
        EquityChangeInGainResponse.validateSymbol(symbol);
        this.symbol = symbol;
        if (costNewShares !== undefined && costNewShares !== null) {
            EquityChangeInGainResponse.validateCostNewShares(costNewShares);
        }
        this.costNewShares = costNewShares;
        if (costSoldShares !== undefined && costSoldShares !== null) {
            EquityChangeInGainResponse.validateCostSoldShares(costSoldShares);
        }
        this.costSoldShares = costSoldShares;
        if (costOnEndDate !== undefined && costOnEndDate !== null) {
            EquityChangeInGainResponse.validateCostOnEndDate(costOnEndDate);
        }
        this.costOnEndDate = costOnEndDate;
        if (totalDividend !== undefined && totalDividend !== null) {
            EquityChangeInGainResponse.validateTotalDividend(totalDividend);
        }
        this.totalDividend = totalDividend;
        if (irr !== undefined && irr !== null) {
            EquityChangeInGainResponse.validateIrr(irr);
        }
        this.irr = irr;
        if (change !== undefined && change !== null) {
            EquityChangeInGainResponse.validateChange(change);
        }
        this.change = change;
        if (totalGains !== undefined && totalGains !== null) {
            EquityChangeInGainResponse.validateTotalGains(totalGains);
        }
        this.totalGains = totalGains;
        if (mvOldShares !== undefined && mvOldShares !== null) {
            EquityChangeInGainResponse.validateMvOldShares(mvOldShares);
        }
        this.mvOldShares = mvOldShares;
        if (rgNewShares !== undefined && rgNewShares !== null) {
            EquityChangeInGainResponse.validateRgNewShares(rgNewShares);
        }
        this.rgNewShares = rgNewShares;
        if (rgOldShares !== undefined && rgOldShares !== null) {
            EquityChangeInGainResponse.validateRgOldShares(rgOldShares);
        }
        this.rgOldShares = rgOldShares;
        if (salesProceeds !== undefined && salesProceeds !== null) {
            EquityChangeInGainResponse.validateSalesProceeds(salesProceeds);
        }
        this.salesProceeds = salesProceeds;
        if (totalMVOnEndDate !== undefined && totalMVOnEndDate !== null) {
            EquityChangeInGainResponse.validateTotalMVOnEndDate(totalMVOnEndDate);
        }
        this.totalMVOnEndDate = totalMVOnEndDate;
        if (ugNewShares !== undefined && ugNewShares !== null) {
            EquityChangeInGainResponse.validateUgNewShares(ugNewShares);
        }
        this.ugNewShares = ugNewShares;
        if (ugOldShares !== undefined && ugOldShares !== null) {
            EquityChangeInGainResponse.validateUgOldShares(ugOldShares);
        }
        this.ugOldShares = ugOldShares;
        if (totalUg !== undefined && totalUg !== null) {
            EquityChangeInGainResponse.validateTotalUg(totalUg);
        }
        this.totalUg = totalUg;
        if (totalRg !== undefined && totalRg !== null) {
            EquityChangeInGainResponse.validateTotalRg(totalRg);
        }
        this.totalRg = totalRg;
        if (tgOnOldShares !== undefined && tgOnOldShares !== null) {
            EquityChangeInGainResponse.validateTgOnOldShares(tgOnOldShares);
        }
        this.tgOnOldShares = tgOnOldShares;
        if (tgOnNewShares !== undefined && tgOnNewShares !== null) {
            EquityChangeInGainResponse.validateTgOnNewShares(tgOnNewShares);
        }
        this.tgOnNewShares = tgOnNewShares;
    }
    static fromDTO(dto) {
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidEquityChangeInGainResponseError);
        const costNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "costNewShares", InvalidEquityChangeInGainResponseError);
        const costSoldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "costSoldShares", InvalidEquityChangeInGainResponseError);
        const costOnEndDate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "costOnEndDate", InvalidEquityChangeInGainResponseError);
        const totalDividend = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalDividend", InvalidEquityChangeInGainResponseError);
        const irr = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "irr", InvalidEquityChangeInGainResponseError);
        const change = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "change", InvalidEquityChangeInGainResponseError);
        const totalGains = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalGains", InvalidEquityChangeInGainResponseError);
        const mvOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "mvOldShares", InvalidEquityChangeInGainResponseError);
        const rgNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "rgNewShares", InvalidEquityChangeInGainResponseError);
        const rgOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "rgOldShares", InvalidEquityChangeInGainResponseError);
        const salesProceeds = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "salesProceeds", InvalidEquityChangeInGainResponseError);
        const totalMVOnEndDate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalMVOnEndDate", InvalidEquityChangeInGainResponseError);
        const ugNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "ugNewShares", InvalidEquityChangeInGainResponseError);
        const ugOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "ugOldShares", InvalidEquityChangeInGainResponseError);
        const totalUg = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalUg", InvalidEquityChangeInGainResponseError);
        const totalRg = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "totalRg", InvalidEquityChangeInGainResponseError);
        const tgOnOldShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "tgOnOldShares", InvalidEquityChangeInGainResponseError);
        const tgOnNewShares = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "tgOnNewShares", InvalidEquityChangeInGainResponseError);
        return new EquityChangeInGainResponse(symbol, costNewShares, costSoldShares, costOnEndDate, totalDividend, irr, change, totalGains, mvOldShares, rgNewShares, rgOldShares, salesProceeds, totalMVOnEndDate, ugNewShares, ugOldShares, totalUg, totalRg, tgOnOldShares, tgOnNewShares);
    }
    toDTO() {
        const ret = {
            symbol: this.symbol,
        };
        if (this.costNewShares !== undefined && this.costNewShares !== null) {
            ret.costNewShares = this.costNewShares;
        }
        if (this.costSoldShares !== undefined && this.costSoldShares !== null) {
            ret.costSoldShares = this.costSoldShares;
        }
        if (this.costOnEndDate !== undefined && this.costOnEndDate !== null) {
            ret.costOnEndDate = this.costOnEndDate;
        }
        if (this.totalDividend !== undefined && this.totalDividend !== null) {
            ret.totalDividend = this.totalDividend;
        }
        if (this.irr !== undefined && this.irr !== null) {
            ret.irr = this.irr;
        }
        if (this.change !== undefined && this.change !== null) {
            ret.change = this.change;
        }
        if (this.totalGains !== undefined && this.totalGains !== null) {
            ret.totalGains = this.totalGains;
        }
        if (this.mvOldShares !== undefined && this.mvOldShares !== null) {
            ret.mvOldShares = this.mvOldShares;
        }
        if (this.rgNewShares !== undefined && this.rgNewShares !== null) {
            ret.rgNewShares = this.rgNewShares;
        }
        if (this.rgOldShares !== undefined && this.rgOldShares !== null) {
            ret.rgOldShares = this.rgOldShares;
        }
        if (this.salesProceeds !== undefined && this.salesProceeds !== null) {
            ret.salesProceeds = this.salesProceeds;
        }
        if (this.totalMVOnEndDate !== undefined && this.totalMVOnEndDate !== null) {
            ret.totalMVOnEndDate = this.totalMVOnEndDate;
        }
        if (this.ugNewShares !== undefined && this.ugNewShares !== null) {
            ret.ugNewShares = this.ugNewShares;
        }
        if (this.ugOldShares !== undefined && this.ugOldShares !== null) {
            ret.ugOldShares = this.ugOldShares;
        }
        if (this.totalUg !== undefined && this.totalUg !== null) {
            ret.totalUg = this.totalUg;
        }
        if (this.totalRg !== undefined && this.totalRg !== null) {
            ret.totalRg = this.totalRg;
        }
        if (this.tgOnOldShares !== undefined && this.tgOnOldShares !== null) {
            ret.tgOnOldShares = this.tgOnOldShares;
        }
        if (this.tgOnNewShares !== undefined && this.tgOnNewShares !== null) {
            ret.tgOnNewShares = this.tgOnNewShares;
        }
        return ret;
    }
    copy(symbol = this.symbol, costNewShares = this.costNewShares, costSoldShares = this.costSoldShares, costOnEndDate = this.costOnEndDate, totalDividend = this.totalDividend, irr = this.irr, change = this.change, totalGains = this.totalGains, mvOldShares = this.mvOldShares, rgNewShares = this.rgNewShares, rgOldShares = this.rgOldShares, salesProceeds = this.salesProceeds, totalMVOnEndDate = this.totalMVOnEndDate, ugNewShares = this.ugNewShares, ugOldShares = this.ugOldShares, totalUg = this.totalUg, totalRg = this.totalRg, tgOnOldShares = this.tgOnOldShares, tgOnNewShares = this.tgOnNewShares) {
        return new EquityChangeInGainResponse(symbol, costNewShares, costSoldShares, costOnEndDate, totalDividend, irr, change, totalGains, mvOldShares, rgNewShares, rgOldShares, salesProceeds, totalMVOnEndDate, ugNewShares, ugOldShares, totalUg, totalRg, tgOnOldShares, tgOnNewShares);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityChangeInGainResponseValue = other.isEquityChangeInGainResponse;
        if (!(other instanceof EquityChangeInGainResponse) || Boolean(isEquityChangeInGainResponseValue)) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.costNewShares !== other.costNewShares) {
            return false;
        }
        if (this.costSoldShares !== other.costSoldShares) {
            return false;
        }
        if (this.costOnEndDate !== other.costOnEndDate) {
            return false;
        }
        if (this.totalDividend !== other.totalDividend) {
            return false;
        }
        if (this.irr !== other.irr) {
            return false;
        }
        if (this.change !== other.change) {
            return false;
        }
        if (this.totalGains !== other.totalGains) {
            return false;
        }
        if (this.mvOldShares !== other.mvOldShares) {
            return false;
        }
        if (this.rgNewShares !== other.rgNewShares) {
            return false;
        }
        if (this.rgOldShares !== other.rgOldShares) {
            return false;
        }
        if (this.salesProceeds !== other.salesProceeds) {
            return false;
        }
        if (this.totalMVOnEndDate !== other.totalMVOnEndDate) {
            return false;
        }
        if (this.ugNewShares !== other.ugNewShares) {
            return false;
        }
        if (this.ugOldShares !== other.ugOldShares) {
            return false;
        }
        if (this.totalUg !== other.totalUg) {
            return false;
        }
        if (this.totalRg !== other.totalRg) {
            return false;
        }
        if (this.tgOnOldShares !== other.tgOnOldShares) {
            return false;
        }
        if (this.tgOnNewShares !== other.tgOnNewShares) {
            return false;
        }
        return true;
    }
}
exports.EquityChangeInGainResponse = EquityChangeInGainResponse;
EquityChangeInGainResponse.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute symbol cannot be an empty string.`);
    }
};
EquityChangeInGainResponse.validateCostNewShares = (costNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(costNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute costNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateCostSoldShares = (costSoldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(costSoldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute costSoldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateCostOnEndDate = (costOnEndDate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(costOnEndDate)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute costOnEndDate is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTotalDividend = (totalDividend) => {
    if (!(0, leo_ts_runtime_1.isInt64)(totalDividend)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute totalDividend is not an Int64`);
    }
};
EquityChangeInGainResponse.validateIrr = (irr) => {
    if (!(0, leo_ts_runtime_1.isInt64)(irr)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute irr is not an Int64`);
    }
};
EquityChangeInGainResponse.validateChange = (change) => {
    if (!(0, leo_ts_runtime_1.isInt64)(change)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute change is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTotalGains = (totalGains) => {
    if (!(0, leo_ts_runtime_1.isInt64)(totalGains)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute totalGains is not an Int64`);
    }
};
EquityChangeInGainResponse.validateMvOldShares = (mvOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(mvOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute mvOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateRgNewShares = (rgNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(rgNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute rgNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateRgOldShares = (rgOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(rgOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute rgOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateSalesProceeds = (salesProceeds) => {
    if (!(0, leo_ts_runtime_1.isInt64)(salesProceeds)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute salesProceeds is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTotalMVOnEndDate = (totalMVOnEndDate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(totalMVOnEndDate)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute totalMVOnEndDate is not an Int64`);
    }
};
EquityChangeInGainResponse.validateUgNewShares = (ugNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(ugNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute ugNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateUgOldShares = (ugOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(ugOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute ugOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTotalUg = (totalUg) => {
    if (!(0, leo_ts_runtime_1.isInt64)(totalUg)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute totalUg is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTotalRg = (totalRg) => {
    if (!(0, leo_ts_runtime_1.isInt64)(totalRg)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute totalRg is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTgOnOldShares = (tgOnOldShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(tgOnOldShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute tgOnOldShares is not an Int64`);
    }
};
EquityChangeInGainResponse.validateTgOnNewShares = (tgOnNewShares) => {
    if (!(0, leo_ts_runtime_1.isInt64)(tgOnNewShares)) {
        throw new InvalidEquityChangeInGainResponseError(`Attribute tgOnNewShares is not an Int64`);
    }
};
EquityChangeInGainResponse.prototype.toString = function toString() {
    return `EquityChangeInGainResponse(symbol='${this.symbol}',costNewShares=${this.costNewShares},costSoldShares=${this.costSoldShares},costOnEndDate=${this.costOnEndDate},totalDividend=${this.totalDividend},irr=${this.irr},change=${this.change},totalGains=${this.totalGains},mvOldShares=${this.mvOldShares},rgNewShares=${this.rgNewShares},rgOldShares=${this.rgOldShares},salesProceeds=${this.salesProceeds},totalMVOnEndDate=${this.totalMVOnEndDate},ugNewShares=${this.ugNewShares},ugOldShares=${this.ugOldShares},totalUg=${this.totalUg},totalRg=${this.totalRg},tgOnOldShares=${this.tgOnOldShares},tgOnNewShares=${this.tgOnNewShares})`;
};
