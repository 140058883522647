import {
  GetAvailableBankBalanceRPC,
  GetAvailableBankBalanceRPCClientImpl,
  GetBrokerListRPC,
  GetBrokerListRPCClientImpl,
  GetBranchSearchListRPC,
  GetBranchSearchListRPCClientImpl,
  GetEntityListRPC,
  GetEntityListRPCClientImpl,
  GetPortfolioListRPC,
  GetPortfolioListRPCClientImpl,
  GetPresignedDownloadUrlRPC,
  GetPresignedDownloadUrlRPCClientImpl,
  HealthCheckRPC,
  HealthCheckRPCClientImpl,
  GetInvestmentCashflowBannerInfoRPC,
  GetInvestmentCashflowBannerInfoRPCClientImpl,
  GetActiveAccrualsRPC,
  GetActiveAccrualsRPCClientImpl,
  GetIndexListRPC,
  GetIndexListRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../constants/RPC-Mock";
import { MockGetEntityListRPCImpl } from "./MockGetEntityListRPCImpl";
import { MockGetPortfolioListRPCImpl } from "./MockGetPortfolioListRPCImpl";
import { MockGetAvailableBankBalanceRPCImpl } from "./MockGetAvailableBankBalanceRPCImpl";
import { MockHealthCheckRPCImpl } from "./MockHealthCheckRPCImpl";
import { MockGetPresignedDownloadUrlRPCImpl } from "./MockGetPresignedDownloadUrlRPCImpl";
import { MockGetBrokerListRPCImpl } from "./MockGetBrokerListRPCImpl";
import { MockGetBranchSearchListRPCImpl } from "./MockGetBranchSearchListRPCImpl";
import { MockGetInvestmentCashflowBannerInfoRPCImpl } from "./MockGetInvestmentCashflowBannerInfoRPCImpl";
import { MockGetActiveAccrualsRPCImpl } from "./MockGetActiveAccrualsRPCImpl";
import { MockGetIndexListRPCImpl } from "./MockGetIndexListRPCImpl";

export const useGetEntityRPCClientImpl = (
  apiClient: APIClient,
): GetEntityListRPC => {
  if (MOCK.common) {
    return new MockGetEntityListRPCImpl();
  } else {
    return new GetEntityListRPCClientImpl(apiClient);
  }
};

export const useGetPortfolioListRPCClientImpl = (
  apiClient: APIClient,
): GetPortfolioListRPC => {
  if (MOCK.common) {
    return new MockGetPortfolioListRPCImpl();
  } else {
    return new GetPortfolioListRPCClientImpl(apiClient);
  }
};

export const useGetAvailableBankBalanceRPCClientImpl = (
  apiClient: APIClient,
): GetAvailableBankBalanceRPC => {
  if (MOCK.common) {
    return new MockGetAvailableBankBalanceRPCImpl();
  } else {
    return new GetAvailableBankBalanceRPCClientImpl(apiClient);
  }
};

export const useHealthCheckRPCClientImpl = (
  apiClient: APIClient,
): HealthCheckRPC => {
  if (MOCK.common) {
    return new MockHealthCheckRPCImpl();
  } else {
    return new HealthCheckRPCClientImpl(apiClient);
  }
};

export const useGetPresignedDownloadUrlRPC = (
  apiClient: APIClient,
): GetPresignedDownloadUrlRPC => {
  if (MOCK.common) {
    return new MockGetPresignedDownloadUrlRPCImpl();
  } else {
    return new GetPresignedDownloadUrlRPCClientImpl(apiClient);
  }
};

export const useGetBrokerListRPC = (apiClient: APIClient): GetBrokerListRPC => {
  if (MOCK.common) {
    return new MockGetBrokerListRPCImpl();
  } else {
    return new GetBrokerListRPCClientImpl(apiClient);
  }
};

export const useGetBranchSearchListRPC = (
  apiClient: APIClient,
): GetBranchSearchListRPC => {
  if (MOCK.common) {
    return new MockGetBranchSearchListRPCImpl();
  } else {
    return new GetBranchSearchListRPCClientImpl(apiClient);
  }
};

export const useGetInvestmentCashflowBannerInfoRPC = (
  apiClient: APIClient,
): GetInvestmentCashflowBannerInfoRPC => {
  if (MOCK.common) {
    return new MockGetInvestmentCashflowBannerInfoRPCImpl();
  } else {
    return new GetInvestmentCashflowBannerInfoRPCClientImpl(apiClient);
  }
};

export const useGetActiveAccrualsRPC = (
  apiClient: APIClient,
): GetActiveAccrualsRPC => {
  if (MOCK.common) {
    return new MockGetActiveAccrualsRPCImpl();
  } else {
    return new GetActiveAccrualsRPCClientImpl(apiClient);
  }
};

export const useGetIndexListRPC = (apiClient: APIClient): GetIndexListRPC => {
  if (MOCK.common) {
    return new MockGetIndexListRPCImpl();
  } else {
    return new GetIndexListRPCClientImpl(apiClient);
  }
};
