"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFApprovedDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const mutualFundItemsPerPage_1 = require("./mutualFundItemsPerPage");
const mutualFundPageIndex_1 = require("./mutualFundPageIndex");
const getMFApprovedDealRequestsPaginationResponse_1 = require("./getMFApprovedDealRequestsPaginationResponse");
const mFTransactionType_1 = require("./mFTransactionType");
/* eslint-disable import/export */
class GetMFApprovedDealRequestsRPC {
}
exports.GetMFApprovedDealRequestsRPC = GetMFApprovedDealRequestsRPC;
(function (GetMFApprovedDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFApprovedDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(transactionType = null, folioNumber = null, isin = null, itemsPerPage, pageIndex) {
            this.isGetMFApprovedDealRequestsRPCRequest = true;
            if (transactionType !== undefined && transactionType !== null) {
                Request.validateTransactionType(transactionType);
            }
            this.transactionType = transactionType;
            if (folioNumber !== undefined && folioNumber !== null) {
                Request.validateFolioNumber(folioNumber);
            }
            this.folioNumber = folioNumber;
            if (isin !== undefined && isin !== null) {
                Request.validateIsin(isin);
            }
            this.isin = isin;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            let transactionType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "transactionType");
            if (transactionType !== undefined && transactionType !== null) {
                transactionType = mFTransactionType_1.MFTransactionType.fromDTO(transactionType);
            }
            const folioNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "folioNumber", InvalidRequestError);
            let isin = (0, leo_ts_runtime_1.getOptionalObject)(dto, "isin");
            if (isin !== undefined && isin !== null) {
                isin = iSIN_1.ISIN.fromDTO(isin);
            }
            const itemsPerPage = mutualFundItemsPerPage_1.MutualFundItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = mutualFundPageIndex_1.MutualFundPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(transactionType, folioNumber, isin, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.transactionType !== undefined && this.transactionType !== null) {
                ret.transactionType = mFTransactionType_1.MFTransactionType.toDTO(this.transactionType);
            }
            if (this.folioNumber !== undefined && this.folioNumber !== null) {
                ret.folioNumber = this.folioNumber;
            }
            if (this.isin) {
                ret.isin = this.isin.toDTO();
            }
            return ret;
        }
        copy(transactionType = this.transactionType, folioNumber = this.folioNumber, isin = this.isin, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(transactionType, folioNumber, isin, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFApprovedDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.folioNumber !== other.folioNumber) {
                return false;
            }
            if (this.isin) {
                if (!this.isin.equals(other.isin)) {
                    return false;
                }
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateTransactionType = (transactionType) => {
        if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
        }
    };
    Request.validateFolioNumber = (folioNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(folioNumber)) {
            throw new InvalidRequestError(`Attribute folioNumber is not a String`);
        }
        if (folioNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute folioNumber cannot be an empty string.`);
        }
    };
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isMutualFundItemsPerPageValue = itemsPerPage.isMutualFundItemsPerPage;
        if (!(itemsPerPage instanceof mutualFundItemsPerPage_1.MutualFundItemsPerPage || Boolean(isMutualFundItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a MutualFundItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isMutualFundPageIndexValue = pageIndex.isMutualFundPageIndex;
        if (!(pageIndex instanceof mutualFundPageIndex_1.MutualFundPageIndex || Boolean(isMutualFundPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a MutualFundPageIndex`);
        }
    };
    GetMFApprovedDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFApprovedDealRequestsRPC.Request(transactionType=${this.transactionType},folioNumber='${this.folioNumber}',isin=${this.isin},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFApprovedDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getMFApprovedDealRequestsPaginationResponse, totalItems) {
            this.isGetMFApprovedDealRequestsRPCResponse = true;
            Response.validateGetMFApprovedDealRequestsPaginationResponse(getMFApprovedDealRequestsPaginationResponse);
            this.getMFApprovedDealRequestsPaginationResponse = getMFApprovedDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getMFApprovedDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getMFApprovedDealRequestsPaginationResponse", getMFApprovedDealRequestsPaginationResponse_1.GetMFApprovedDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getMFApprovedDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getMFApprovedDealRequestsPaginationResponse: this.getMFApprovedDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getMFApprovedDealRequestsPaginationResponse = this.getMFApprovedDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getMFApprovedDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFApprovedDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getMFApprovedDealRequestsPaginationResponse, other.getMFApprovedDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetMFApprovedDealRequestsPaginationResponse = (getMFApprovedDealRequestsPaginationResponse) => {
        if (!Array.isArray(getMFApprovedDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getMFApprovedDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getMFApprovedDealRequestsPaginationResponse.length; i += 1) {
            const isGetMFApprovedDealRequestsPaginationResponseValue = getMFApprovedDealRequestsPaginationResponse[i].isGetMFApprovedDealRequestsPaginationResponse;
            if (!(getMFApprovedDealRequestsPaginationResponse[i] instanceof getMFApprovedDealRequestsPaginationResponse_1.GetMFApprovedDealRequestsPaginationResponse || Boolean(isGetMFApprovedDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getMFApprovedDealRequestsPaginationResponse at index ${i} is not a GetMFApprovedDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetMFApprovedDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFApprovedDealRequestsRPC.Response(getMFApprovedDealRequestsPaginationResponse=${this.getMFApprovedDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetMFApprovedDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFApprovedDealRequestsRPC.Errors || (GetMFApprovedDealRequestsRPC.Errors = {}));
})(GetMFApprovedDealRequestsRPC = exports.GetMFApprovedDealRequestsRPC || (exports.GetMFApprovedDealRequestsRPC = {}));
