import { observer } from "mobx-react";
import React from "react";
import { Stack } from "@mui/material";
import { DetailsSection } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { getDetailCellRows } from "../../../utils/DetailCellUtils";
import { Module } from "../../../../../routes/RoutesEnum";

export const FDDealRequestDetailsSection = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useFDDealRequestDetailsStore();

  return (
    <Stack>
      <DetailsSection
        title={t("common.dealRequestDetails")}
        rows={getDetailCellRows(store.details, Module.Fd)}
      />
    </Stack>
  );
});
