import React from "react";
import { NumericFormat } from "react-number-format";
import {
  TextFieldWithSeparateLabelHelperContent,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  FormHelperTextProps,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { AMOUNT_LIMIT } from "../../../utils";

export interface SeparateLabelInputFieldProps {
  name: string;
  value: string | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label: string;
  error?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  helperText?: string;
  helperTextColor?: string;
  style?: React.CSSProperties;
}

export function SeparateLabelInputField({
  isDisabled,
  isRequired,
  name,
  value,
  onChange,
  placeholder,
  label,
  error,
  helperText,
  helperTextColor,
  style,
}: SeparateLabelInputFieldProps): React.ReactElement {
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();

  return (
    <Stack spacing={spacing.spaceXXS} flexGrow={1} style={style}>
      <Typography
        sx={{
          ...typography.s2,
          color: isDisabled
            ? tokens.labelLowEmphasis
            : error
              ? tokens.labelError
              : tokens.label,
        }}
      >
        {isRequired ? `${label} *` : label}
      </Typography>
      <TextField
        fullWidth
        disabled={isDisabled}
        id={name}
        type="text"
        name={name}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        error={error}
        sx={{
          "& .MuiOutlinedInput-root": {
            background: tokens.background,
            "& fieldset": {
              borderColor: tokens.border,
              borderWidth: "1px",
            },
            "&:hover fieldset": {
              borderColor: tokens.borderHighEmphasis,
              borderWidth: "2px",
            },
            "&.Mui-focused fieldset": {
              borderColor: tokens.borderPrimary,
              borderWidth: "2px",
            },
          },
          "& .MuiInputBase-root.Mui-error": {
            "& fieldset": {
              borderColor: tokens.borderError,
              borderWidth: "2px",
            },
            "&:hover fieldset": {
              borderColor: tokens.borderError,
              borderWidth: "2px",
            },
            "&.Mui-focused fieldset": {
              borderColor: tokens.borderError,
              borderWidth: "2px",
            },
          },
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: tokens.labelLowEmphasis,
              opacity: 1,
            },
          },
        }}
        helperText={
          <TextFieldWithSeparateLabelHelperContent
            value={value}
            error={!!error}
            helperText={helperText}
            helperTextColor={helperTextColor}
          />
        }
        // By default the helperText is placed with a `p` tag. HTML does not allow a `div` tag to be placed
        // inside of `p` tag. The helperText is customized to use a `div` tag instead of `p` tag.
        FormHelperTextProps={
          {
            component: "div",
            style: { margin: "0px" },
          } as Partial<FormHelperTextProps<"p", {}>>
        }
        style={{
          ...style,
          width: "100%",
        }}
      />
    </Stack>
  );
}

interface AmountTextFieldProps {
  name: string;
  label: string;
  value: string | number | undefined;
  onAmountChange: (value: string) => void;
  placeholder: string;
  error?: boolean;
  isDisabled?: boolean;
  helperText?: string;
  style?: React.CSSProperties;
  isDecimalAllowed: boolean;
  fractionDigits?: number;
  isRequired?: boolean;
  isNegativeAllowed?: boolean;
  maxLimit?: number;
  xs?: number;
}

export function AmountTextField({
  isDisabled,
  name,
  label,
  value,
  onAmountChange,
  placeholder,
  error,
  helperText,
  style,
  isDecimalAllowed,
  fractionDigits = 2,
  isRequired,
  isNegativeAllowed = false,
  maxLimit = AMOUNT_LIMIT,
}: AmountTextFieldProps): React.ReactElement {
  return (
    <NumericFormat
      value={value}
      allowNegative={isNegativeAllowed}
      allowLeadingZeros={false}
      name={name}
      type="text"
      decimalScale={isDecimalAllowed ? fractionDigits : 0}
      thousandsGroupStyle="lakh"
      thousandSeparator=","
      isRequired={isRequired}
      placeholder={placeholder}
      error={error}
      onValueChange={(values): void => {
        onAmountChange(values.value);
      }}
      isDisabled={isDisabled}
      helperText={helperText}
      style={style}
      label={label}
      isAllowed={(values): boolean => {
        const { floatValue } = values;
        if (floatValue) {
          return floatValue <= maxLimit;
        }
        return true;
      }}
      customInput={SeparateLabelInputField}
    />
  );
}
