"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutualFundSortOrder = exports.InvalidMutualFundSortOrderError = void 0;
class InvalidMutualFundSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMutualFundSortOrderError";
    }
}
exports.InvalidMutualFundSortOrderError = InvalidMutualFundSortOrderError;
/* eslint-disable @typescript-eslint/no-shadow */
var MutualFundSortOrder;
(function (MutualFundSortOrder_1) {
    let MutualFundSortOrder;
    (function (MutualFundSortOrder) {
        MutualFundSortOrder["ASCENDING"] = "ASCENDING";
        MutualFundSortOrder["DESCENDING"] = "DESCENDING";
    })(MutualFundSortOrder = MutualFundSortOrder_1.MutualFundSortOrder || (MutualFundSortOrder_1.MutualFundSortOrder = {}));
    MutualFundSortOrder_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ASCENDING":
                return MutualFundSortOrder.ASCENDING;
            case "DESCENDING":
                return MutualFundSortOrder.DESCENDING;
            default:
                throw new InvalidMutualFundSortOrderError(`Case ${dto.case} is not valid case of MutualFundSortOrder`);
        }
    };
    MutualFundSortOrder_1.toDTO = (mutualFundSortOrder) => {
        const ret = {
            case: MutualFundSortOrder[mutualFundSortOrder],
        };
        return ret;
    };
    MutualFundSortOrder_1.isInstanceOf = (other) => {
        if (other in MutualFundSortOrder) {
            return true;
        }
        return false;
    };
})(MutualFundSortOrder = exports.MutualFundSortOrder || (exports.MutualFundSortOrder = {}));
/* eslint-enable @typescript-eslint/no-shadow */
