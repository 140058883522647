import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Avatar,
  Chip,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  getFormattedAmountString,
  getFormattedTimeDateWithComma,
} from "../../../../../utils";
import { FDDealRequestStatus } from "@khazana/khazana-rpcs";
import { useBorder } from "../../../../../utils/BorderUtils";
import { getFDRequestStatusValue, getFdStatusColor } from "../utils/UIUtils";
import { FDHistoryWithdrawDetails } from "../models/FDHistoryWithdrawDetails";
import { Instance } from "mobx-state-tree";
import { Section } from "../../../components/Section";

export interface FDDealStatusSectionProps {
  firstName: string;
  lastName: string | null;
  requestedAt: string;
  status: FDDealRequestStatus.FDDealRequestStatus;
  note: string | null;
  isHistoryComponent: boolean;
  userImage: string | undefined;
  withdrawDetails?: Instance<typeof FDHistoryWithdrawDetails>;
}

const Size = {
  container: "100%",
  withdrawContainer: "356px",
  withdrawRow: "100%",
};

export const FDDealStatusSection = ({
  firstName,
  lastName,
  requestedAt,
  status,
  note,
  isHistoryComponent,
  userImage,
  withdrawDetails,
}: FDDealStatusSectionProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const border = useBorder();

  const getTitleText = (): string => {
    switch (status) {
      case FDDealRequestStatus.FDDealRequestStatus.IC_PENDING:
      case FDDealRequestStatus.FDDealRequestStatus.ACCOUNTS_PENDING:
        return t("common.hasRaisedARequest", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.DEAL_OPEN:
      case FDDealRequestStatus.FDDealRequestStatus.FD_ACTIVE:
        return t("common.hasPlacedAnOrder", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.ACCOUNTS_REJECTED:
      case FDDealRequestStatus.FDDealRequestStatus.IC_REJECTED:
        return t("common.hasRejectedTheDealRequest", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.CANCELLED:
        return t("common.hasCancelledTheDealRequest", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.SETTLED:
        return t("common.hasSettledTheDeal", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.FD_MATURED:
        return t("common.hasMaturedTheDeal", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.FD_WITHDRAWAL_REQUESTED:
        return t("common.hasRaisedAWithdrawRequest", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.FD_WITHDRAWAL_APPROVED:
        return t("common.hasApprovedWithdrawRequest", {
          firstName,
          lastName,
        });
      case FDDealRequestStatus.FDDealRequestStatus.FD_CLOSED:
        return t("common.hasClosedTheRequest", {
          firstName,
          lastName,
        });
    }
  };

  const getRow = (title: string, value: string): React.ReactElement => {
    return (
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography sx={{ ...typography.b2 }}>{title}</Typography>
        <Typography sx={{ ...typography.b2 }}>{value}</Typography>
      </Stack>
    );
  };

  const getWithdrawDetails = (): React.ReactElement => {
    if (withdrawDetails) {
      return (
        <Section
          title={t("fd.withdraw.tableTitle")}
          titleBackgroundColor={tokens.background}
          width={Size.withdrawContainer}
        >
          <Stack padding={spacing.spaceXL} width={Size.withdrawRow}>
            {getRow(
              t("fd.fields.withdrawalAmountLabel", {
                currencySymbol: `(${withdrawDetails.currencySymbol})`,
              }),
              getFormattedAmountString(withdrawDetails.withdrawalAmount),
            )}
            {getRow(
              t("fd.fields.bankChargeLabel"),
              getFormattedAmountString(withdrawDetails.bankCharge),
            )}
            {getRow(
              t("fd.fields.bankChargeAmountLabel", {
                currencySymbol: `(${withdrawDetails.currencySymbol})`,
              }),
              getFormattedAmountString(withdrawDetails.bankChargeAmount),
            )}
          </Stack>
        </Section>
      );
    }
    return <></>;
  };

  return (
    <Stack
      sx={{
        backgroundColor: tokens.background,
        width: Size.container,
        borderRadius: isHistoryComponent
          ? `0 0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS}`
          : cornerRadius.radiusXS,
        border: isHistoryComponent ? undefined : border.default,
        borderRight: isHistoryComponent ? undefined : border.default,
      }}
    >
      <Stack flexDirection="row" padding={spacing.spaceXL}>
        <Avatar
          userName={`${firstName} ${lastName ?? ""}`}
          size="large"
          src={userImage}
        />
        <Box sx={{ marginLeft: spacing.spaceMD }}>
          <Typography
            sx={{
              ...typography.sh3,
              color: tokens.labelHighEmphasis,
            }}
          >
            {getTitleText()}
          </Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {getFormattedTimeDateWithComma(new Date(requestedAt))}
          </Typography>
        </Box>
      </Stack>
      {!isHistoryComponent && <Divider />}
      <Stack
        padding={spacing.spaceXL}
        paddingTop={isHistoryComponent ? "0" : spacing.spaceXL}
        gap={spacing.spaceXL}
        marginLeft={isHistoryComponent ? "64px" : "0px"}
      >
        <Box>
          <Chip
            label={getFDRequestStatusValue(status).toLocaleUpperCase()}
            color={getFdStatusColor(status)}
          />
        </Box>
        {note && (
          <Stack>
            <Typography {...typography.s1}>{t("common.note")}</Typography>
            <Typography {...typography.b1}>{note}</Typography>
          </Stack>
        )}
        {withdrawDetails && getWithdrawDetails()}
      </Stack>
    </Stack>
  );
};
