export enum MFConfirmationDetailsKeys {
  transactionType = "common.transactionType",
  amc = "mf.fields.amcNameLabel",
  amcId = "mf.amcId",
  entity = "common.entity",
  entityId = "common.entityId",
  folioNumber = "mf.fields.folioNumberLabel",
  schemeName = "mf.fields.schemeNameLabel",
  isin = "mf.fields.isinLabel",
  broker = "common.broker",
  brokerId = "common.brokerId",
  referenceNumber = "mf.fields.referenceNumberLabel",
  units = "mf.fields.unitsLabel",
  navPerUnit = "mf.fields.navPerUnitLabel",
  entryLoad = "mf.fields.entryLoadLabel",
  exitLoad = "mf.fields.exitLoadLabel",
  netAmount = "mf.fields.netAmountLabel",
  createdAt = "mf.fields.createdAtLabel",
  requestId = "mf.fields.requestIdLabel",
}
