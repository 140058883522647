import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack, Typography } from "@mui/material";
import {
  Breadcrumb,
  Button,
  Chip,
  LoadingIndicator,
  PageHeader,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { ContractNoteDetailSection } from "../components/ContractNoteDetailSection";
import { History } from "../../../../../assets/History";
import { Edit } from "../../../../../assets/Edit";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { ContractNoteDetailErrors } from "../store/ContractNoteDetailsErrors";
import { ContractNoteEdit } from "../components/ContractNoteEdit";
import {
  CheckResponse,
  ContractNoteRequestStatus,
} from "@khazana/khazana-rpcs";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { LinkDealRequestDialog } from "../components/LinkDealRequestDialog";
import { ContractNoteHistoryDialog } from "../components/ContractNoteHistoryDialog";
import { AMOUNT_LIMIT, createServerNoteRPCType } from "../../../../../utils";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { useContractNoteDetailsStore } from "../store/hooks";
import { getContractNoteRequestStatusValue } from "../utils/SearchUtils";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getContractNoteStatusColor } from "../utils/UIUtils";
import { useBorder } from "../../../../../utils/BorderUtils";
import { Pdf } from "../../../components/Pdf";
import {
  HeaderButton,
  HeaderButtonProps,
} from "../../../components/page-header/HeaderButton";
import { HeaderContainer } from "../../../components/page-header/HeaderContainer";
import { FileEmptyState } from "../../../../../assets/FileEmptyState";

export const ContractNoteDetails = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const typography = useTypography();
  const store = useContractNoteDetailsStore();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const contractNoteId = searchParam.get("contractNoteId")
    ? Number(searchParam.get("contractNoteId"))
    : undefined;
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState<
    "Approve" | "Reject" | "Submit" | "Discard" | "Unknown" | "Link"
  >("Submit");
  const [dealDialogOpen, setDealDialogOpen] = useState(false);
  const [selectedDealRequestId, setSelectedDealRequestId] = useState<string>();
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const Size = {
    fileEmptyState: {
      width: "32px",
      height: "32px",
      containerWidth: "50%",
    },
  };

  useEffect(() => {
    const getDetails = async (): Promise<void> => {
      setIsLoading(true);
      // Empty string is passed if the contractNoteId is undefined. This will result in validateContractNote throwing LeoUUID error.
      // If Null or undefined is passed the error is not thrown as `new LeoUUID()` will create a new uuid.
      await store.fetchContractNoteDetails(contractNoteId ?? undefined);
      if (
        store.status ===
          ContractNoteRequestStatus.ContractNoteRequestStatus.UNPARSED &&
        store.allowedActions.allowEdit
      ) {
        setIsEdit(true);
      }
      setIsLoading(false);
    };
    getDetails();

    return () => {
      store.resetStore();
    };
  }, []);

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      let title = "";
      let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
      if (dialogType === "Approve") {
        title = t("contractNotes.approveContractNote");
        onPrimaryButtonClick = (note: string | undefined): Promise<void> =>
          store.checkContractNote(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
              createServerNoteRPCType(note),
            ),
          );
      } else if (dialogType === "Reject") {
        title = t("contractNotes.rejectContractNote");
        onPrimaryButtonClick = (note: string | undefined): Promise<void> =>
          store.checkContractNote(
            new CheckResponse(
              CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
              createServerNoteRPCType(note),
            ),
          );
      } else if (dialogType === "Submit") {
        title = t("contractNotes.submitContractNote");
        onPrimaryButtonClick = (note: string | undefined): Promise<void> => {
          return store.submitContractNote(note, isEdit);
        };
      } else if (dialogType === "Discard") {
        title = t("contractNotes.discardContractNote");
        onPrimaryButtonClick = store.submitContractNoteDiscardRequest;
      } else if (dialogType === "Unknown") {
        title = t("contractNotes.markContractNoteAsUnknown");
        onPrimaryButtonClick = store.submitContractNoteUnknownRequest;
      } else if (dialogType === "Link") {
        title = t("contractNotes.linkContractNote");
        onPrimaryButtonClick = (note: string | undefined): Promise<void> =>
          store.submitContractNoteLinkRequest(selectedDealRequestId, note);
      }
      return (
        <TextFieldDialog
          title={title}
          onClose={(): void => {
            setIsDialogOpen(false);
            if (dialogType === "Link") {
              setDealDialogOpen(true);
            }
          }}
          isOpen={isDialogOpen}
          primaryButtonType={dialogType}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsLoading(true);
            await onPrimaryButtonClick(note);
            setIsDialogOpen(false);
            setIsEdit(false);
            await store.fetchContractNoteDetails(contractNoteId);
            setIsLoading(false);
          }}
          isCloseIconPresent={!isLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const getSubmitButtonProps = (): HeaderButtonProps => {
    return {
      label: t("common.submit"),
      onClick: async (): Promise<void> => {
        const quantityPriceProduct =
          parseFloat(
            store.editDetails?.grossPricePerUnit.value
              ? store.editDetails?.grossPricePerUnit.value
              : "0",
          ) *
          parseInt(
            store.editDetails?.quantity.value
              ? store.editDetails?.quantity.value
              : "0",
          );
        if (quantityPriceProduct > AMOUNT_LIMIT) {
          setIsAlertVisible(true);
          return;
        }
        if (
          !isEdit &&
          store.status ===
            ContractNoteRequestStatus.ContractNoteRequestStatus.PARSED
        ) {
          setDialogType("Submit");
          setIsDialogOpen(true);
        } else {
          setIsSubmitClicked(true);
          if (
            store.editDetails?.isBasicSectionValid() &&
            store.editDetails?.isAmountDetailsValid()
          ) {
            setIsLoading(true);
            // Empty string is passed if the contractNoteId is undefined. This will result in validateContractNote throwing LeoUUID error.
            // If Null or undefined is passed the error is not thrown as `new LeoUUID()` will create a new uuid.
            await store.validateContractNote(contractNoteId);
            setIsLoading(false);
            if (!store.error) {
              setDialogType("Submit");
              setIsDialogOpen(true);
            }
            if (store.error === ContractNoteDetailErrors.DataMismatch) {
              setIsAlertVisible(true);
            }
          } else {
            setIsAlertVisible(true);
          }
        }
      },
      buttonType: "filled",
    };
  };

  const submitButton = (): React.ReactElement => {
    return <HeaderButton {...getSubmitButtonProps()} />;
  };

  const discardSubmitButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={getSubmitButtonProps()}
        secondaryButton={{
          label: t("contractNotes.discard"),
          onClick: async (): Promise<void> => {
            setDialogType("Discard");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
      />
    );
  };

  const discardUnknownButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.discard"),
          onClick: async (): Promise<void> => {
            setDialogType("Discard");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
        secondaryButton={{
          label: t("contractNotes.markAsUnknown"),
          onClick: async (): Promise<void> => {
            setDialogType("Unknown");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
      />
    );
  };

  const unknownButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("contractNotes.markAsUnknown")}
        onClick={async (): Promise<void> => {
          setDialogType("Unknown");
          setIsDialogOpen(true);
        }}
        buttonType="red-outlined"
      />
    );
  };

  const checkButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.approve"),
          onClick: async (): Promise<void> => {
            setDialogType("Approve");
            setIsDialogOpen(true);
          },
          buttonType: "outlined",
        }}
        secondaryButton={{
          label: t("contractNotes.reject"),
          onClick: async (): Promise<void> => {
            setDialogType("Reject");
            setIsDialogOpen(true);
          },
          buttonType: "outlined",
        }}
      />
    );
  };

  const linkButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("contractNotes.link"),
          onClick: async (): Promise<void> => {
            setDealDialogOpen(true);
          },
          buttonType: "filled",
        }}
        secondaryButton={{
          label: t("contractNotes.markAsUnknown"),
          onClick: async (): Promise<void> => {
            setDialogType("Unknown");
            setIsDialogOpen(true);
          },
          buttonType: "red-outlined",
        }}
      />
    );
  };

  const getHeaderButtons = (): React.ReactElement | undefined => {
    if (isEdit) {
      return submitButton();
    } else {
      if (
        store.allowedActions.allowReviewRequest &&
        !store.allowedActions.allowDiscardRequest
      ) {
        return submitButton();
      } else if (
        store.allowedActions.allowDiscardRequest &&
        !store.allowedActions.allowUnknownRequest
      ) {
        return discardSubmitButtons();
      } else if (
        store.allowedActions.allowUnknownRequest &&
        !store.allowedActions.allowLinkRequest &&
        !store.allowedActions.allowDiscardRequest
      ) {
        return unknownButton();
      } else if (
        store.allowedActions.allowUnknownRequest &&
        store.allowedActions.allowDiscardRequest &&
        !store.allowedActions.allowLinkRequest
      ) {
        return discardUnknownButtons();
      } else if (
        store.allowedActions.allowContractCheck ||
        store.allowedActions.allowDiscardCheck ||
        store.allowedActions.allowLinkCheck ||
        store.allowedActions.allowUnknownCheck
      ) {
        return checkButtons();
      } else if (store.allowedActions.allowLinkRequest) {
        return linkButtons();
      }
    }
  };

  const getErrorMessage = (): string => {
    switch (store.error) {
      case ContractNoteDetailErrors.InvalidContractNoteID:
        return t("contractNotes.invalidContractNote");
      case ContractNoteDetailErrors.DealValueExceeded:
        const selectedDealRequest = store.linkDealStore.requests.find(
          (request) => request.dealRequestId === selectedDealRequestId,
        );
        if (selectedDealRequest) {
          if (selectedDealRequest.amount) {
            return t("contractNotes.linkAmountExceeds");
          } else if (selectedDealRequest.quantity) {
            return t("contractNotes.linkQuantityExceeds");
          } else {
            console.error(
              `Developer Error: Quantity and amount of deal request with id: ${selectedDealRequestId} is null.`,
            );
          }
        } else {
          console.error(
            `Developer Error: Deal request with id: ${selectedDealRequestId} does not exist in approved requests.`,
          );
        }
        return t("errors.internalServerErrorDescription");
      default:
        return t("errors.internalServerErrorDescription");
    }
  };

  return (
    <Stack direction="column">
      {store.error &&
        store.error !== ContractNoteDetailErrors.DataMismatch &&
        store.error !== LeoErrors.InvalidContractNoteEditsError &&
        store.error !== LeoErrors.InvalidEquityTransactionTypeError &&
        store.error !== ContractNoteDetailErrors.InvalidISINError && (
          <ErrorDialog
            errorMessage={getErrorMessage()}
            isErrorDialogOpen={store.error !== null}
            onClose={(): void => {
              store.error === ContractNoteDetailErrors.InvalidDealRequestState
                ? navigate(0)
                : navigate(getPath(Module.Equity, Route.ManageContractNotes));
              store.removeError();
            }}
          />
        )}
      {isDialogOpen && getDialog()}
      {dealDialogOpen && (
        <LinkDealRequestDialog
          onClose={(): void => {
            setSelectedDealRequestId(undefined);
            setDealDialogOpen(false);
          }}
          isOpen={dealDialogOpen}
          onContinue={(dealRequestId): void => {
            setDealDialogOpen(false);
            setDialogType("Link");
            setSelectedDealRequestId(dealRequestId);
            setIsDialogOpen(true);
          }}
          selectedDealRequestId={selectedDealRequestId}
        />
      )}
      <LoadingIndicator isLoading={isLoading} />
      <PageHeader
        title={t("contractNotes.contractNoteDetailTitle")}
        actionElement={getHeaderButtons()}
      />
      <ContractNoteHistoryDialog
        onClose={(): void => {
          setIsHistoryOpen(false);
        }}
        isOpen={isHistoryOpen}
      />
      <Breadcrumb
        links={[
          {
            label: t("common.manageContractNotes"),
            onLabelClick: (): void => {
              navigate(getPath(Module.Equity, Route.ManageContractNotes));
            },
          },
        ]}
        currentLabel={t("contractNotes.contractNoteDetailTitle")}
        style={{ padding: spacing.space2XL, paddingBottom: 0 }}
      />
      <Stack
        sx={{
          margin: spacing.space2XL,
          borderRadius: cornerRadius.radiusXS,
          backgroundColor: tokens.background,
          border: border.default,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            padding: `${spacing.spaceMD} ${spacing.spaceXL} ${spacing.spaceMD} ${spacing.spaceXL}`,
            borderBottom: border.default,
          }}
          spacing={spacing.spaceXS}
        >
          <Stack
            direction={"row"}
            spacing={spacing.spaceXS}
            alignItems={"center"}
          >
            <Typography sx={{ ...typography.sh3 }}>
              {t("contractNotes.contractNoteDetailTitle")}
            </Typography>
            {store.status ? (
              <Chip
                label={getContractNoteRequestStatusValue(
                  t,
                  store.status,
                ).toLocaleUpperCase()}
                color={getContractNoteStatusColor(store.status)}
              />
            ) : null}
          </Stack>
          <Stack direction={"row"} spacing={spacing.spaceXS}>
            {isEdit &&
              store.status !==
                ContractNoteRequestStatus.ContractNoteRequestStatus
                  .UNPARSED && (
                <Button
                  name={"cancel"}
                  size={"small"}
                  variant={"outlined-color"}
                  onClick={(): void => {
                    store.resetEdit();
                    setIsEdit(false);
                  }}
                  label={t("common.cancel")}
                />
              )}
            {!isEdit && store.details.length > 0 && (
              <>
                <Button
                  name={"ViewHistory"}
                  size={"small"}
                  iconPosition="leading"
                  icon={<History />}
                  variant={"outlined-color"}
                  onClick={async (): Promise<void> => {
                    setIsLoading(true);
                    await store.getEquityContractNoteHistory(contractNoteId);
                    setIsHistoryOpen(true);
                    setIsLoading(false);
                  }}
                  label={t("contractNotes.viewHistory")}
                />
                {store.allowedActions.allowEdit ? (
                  <Button
                    name={"edit"}
                    size={"small"}
                    iconPosition="leading"
                    icon={<Edit />}
                    variant={"outlined-color"}
                    onClick={(): void => {
                      setIsEdit(true);
                    }}
                    label={t("common.editDetails")}
                  />
                ) : null}
              </>
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"}>
          {isEdit ? (
            <ContractNoteEdit
              isSubmitClicked={isSubmitClicked}
              setIsSubmitClicked={setIsSubmitClicked}
              isAlertVisible={isAlertVisible}
              setIsAlertVisible={setIsAlertVisible}
            />
          ) : !store.allowedActions.allowEdit &&
            store.status ===
              ContractNoteRequestStatus.ContractNoteRequestStatus.UNPARSED ? (
            <Pdf url={store.contractNoteUrl} isEdit={false} />
          ) : (
            <ContractNoteDetailSection />
          )}
          {(store.contractNoteUrl &&
            (store.allowedActions.allowEdit ||
              store.status !==
                ContractNoteRequestStatus.ContractNoteRequestStatus
                  .UNPARSED) && <Pdf url={store.contractNoteUrl} />) ??
            (store.parsedContractNoteId && (
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                width={Size.fileEmptyState.containerWidth}
              >
                <FileEmptyState
                  width={Size.fileEmptyState.width}
                  height={Size.fileEmptyState.height}
                  style={{ marginBottom: Size.fileEmptyState.height }}
                />
                <Typography
                  style={{
                    ...typography.sh3,
                    color: tokens.labelLowEmphasis,
                  }}
                >
                  {t("contractNotes.noPdfAvailableTitle")}
                </Typography>
                <Typography
                  style={{
                    ...typography.b2,
                    color: tokens.labelLowEmphasis,
                  }}
                >
                  {t("contractNotes.noPdfAvailableSubtitle")}
                </Typography>
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
});
