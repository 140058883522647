import {
  ContractNoteDetail,
  ContractNoteRequestStatus,
} from "@khazana/khazana-rpcs";
import {
  ChipColor,
  DetailRowCellEnum,
  DetailRowCellType,
} from "@surya-digital/leo-reactjs-core";
import { ContractNoteFields } from "../models/ContractNoteEditModel";
import {
  getFormattedAmount,
  getFormattedAmountString,
} from "../../../../../utils";
import AlertCircle from "../../../../../assets/alert-circle.svg";

export const getContractNoteStatusColor = (
  status: ContractNoteRequestStatus.ContractNoteRequestStatus,
): ChipColor => {
  switch (status) {
    case ContractNoteRequestStatus.ContractNoteRequestStatus.DISCARDED:
    case ContractNoteRequestStatus.ContractNoteRequestStatus.UNKNOWN:
      return "red";
    case ContractNoteRequestStatus.ContractNoteRequestStatus.LINKED:
      return "green";
    default:
      return "orange";
  }
};

const getUnstyledRow = (
  text: string,
  diffText: string | undefined,
): DetailRowCellType => {
  return {
    text,
    type: DetailRowCellEnum.Unstyled,
    diffText,
  };
};

const getPrefixIconRow = (text: string): DetailRowCellType => {
  return {
    text,
    type: DetailRowCellEnum.PrefixIcon,
    iconPath: AlertCircle,
  };
};

export const getCell = (
  text: string,
  diffText: string | undefined,
  hasError: boolean,
): DetailRowCellType => {
  if (hasError) {
    return getPrefixIconRow(text);
  } else {
    return getUnstyledRow(text, diffText);
  }
};

export const eqContractNoteTransformCell = (
  key: string,
  cellType: ContractNoteDetail.ContractNoteDetail,
  diff: ContractNoteDetail.ContractNoteDetail | null,
): DetailRowCellType | undefined => {
  let diffText: string | number | null = null;
  if (diff instanceof ContractNoteDetail.Error) {
    diffText = diff.errorMessage;
  }
  const hasError = diffText !== null;
  if (cellType instanceof ContractNoteDetail.Amount) {
    const diffT = diff ? (diff as ContractNoteDetail.Amount).amount ?? 0 : null;
    diffText = diffText ?? diffT;
    let fractionDigits = 2;
    if (
      key === ContractNoteFields.grossPricePerUnit ||
      key === ContractNoteFields.grossAmount
    ) {
      fractionDigits = 4;
    }
    return getCell(
      cellType.amount
        ? getFormattedAmount(cellType.amount, fractionDigits)
        : "-",
      diffText !== null
        ? getFormattedAmount(diffText as number, fractionDigits)
        : undefined,
      hasError,
    );
  } else if (cellType instanceof ContractNoteDetail.Quantity) {
    diffText =
      diffText ??
      (diff ? (diff as ContractNoteDetail.Quantity).quantity : null);
    return getCell(
      getFormattedAmountString(cellType.quantity),
      diffText ? getFormattedAmountString(diffText as number) : undefined,
      hasError,
    );
  } else if (cellType instanceof ContractNoteDetail.Unstyled) {
    diffText = diffText ?? (diff as ContractNoteDetail.Unstyled)?.text;
    return getCell(cellType.text ?? "-", diffText?.toString() ?? "", hasError);
  } else if (cellType instanceof ContractNoteDetail.TransactionType) {
    diffText =
      diffText ?? (diff as ContractNoteDetail.TransactionType)?.transactionType;
    return getCell(cellType.transactionType, diffText?.toString(), hasError);
  } else if (cellType instanceof ContractNoteDetail.Isin) {
    diffText = diffText ?? (diff as ContractNoteDetail.Isin)?.isin.isin;
    return getCell(cellType.isin.isin, diffText?.toString(), hasError);
  }
};
