import React from "react";
import { Instance } from "mobx-state-tree";
import { FiDealRequestHistoryDetailModel } from "../models/FiDealRequestHistoryDetailModel";
import { DealStatusSection } from "../../../components/DealStatusSection";
import { FiDealRequestStatus } from "@khazana/khazana-rpcs";
import { getRequestStatusValue } from "../utils/SearchUtils";
import { getFiStatusColor } from "../utils/UIUtils";
import { TFunction } from "i18next";

interface GetFiDealStatusSectionProps {
  value: Instance<typeof FiDealRequestHistoryDetailModel>;
  isHistoryComponent?: boolean;
  t: TFunction;
  assetName: string | null;
  transactionType: string | null;
}
export const GetFiDealStatusSection = ({
  value,
  isHistoryComponent = false,
  t,
  assetName,
  transactionType,
}: GetFiDealStatusSectionProps): React.ReactElement => {
  const portfolioHoldingImpactDetails =
    value.FiDealRequestHistoryAdditionalDetail?.portfolioHoldingImpactDetails;

  const impactBuyChart =
    portfolioHoldingImpactDetails?.impactChart.impactChartBuyCase;

  const impactSellChart =
    portfolioHoldingImpactDetails?.impactChart.impactChartSellCase;

  const impactTable = portfolioHoldingImpactDetails?.impactTable;

  const getTitleText = (
    status: FiDealRequestStatus.FiDealRequestStatus,
  ): string => {
    const firstName = value.requestNote?.userName.firstName;
    const lastName = value.requestNote?.userName.lastName;
    switch (status) {
      case FiDealRequestStatus.FiDealRequestStatus.IC_PENDING:
      case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_PENDING:
        return t("common.hasRaisedARequest", {
          firstName,
          lastName,
        });
      case FiDealRequestStatus.FiDealRequestStatus.IC_APPROVED:
      case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_APPROVED:
        return t("common.hasApprovedTheDealRequest", {
          firstName,
          lastName,
        });
      case FiDealRequestStatus.FiDealRequestStatus.DEAL_OPEN:
        return t("common.hasPlacedAnOrder", {
          firstName,
          lastName,
        });
      case FiDealRequestStatus.FiDealRequestStatus.ACCOUNTS_REJECTED:
      case FiDealRequestStatus.FiDealRequestStatus.IC_REJECTED:
        return t("common.hasRejectedTheDealRequest", {
          firstName,
          lastName,
        });
      case FiDealRequestStatus.FiDealRequestStatus.CANCELLED:
        return t("common.hasCancelledTheDealRequest", {
          firstName,
          lastName,
        });
      case FiDealRequestStatus.FiDealRequestStatus.EXPIRED:
        return t("common.hasMarkedTheDealExpired", {
          firstName,
          lastName,
        });
      case FiDealRequestStatus.FiDealRequestStatus.SETTLED:
      case FiDealRequestStatus.FiDealRequestStatus.SYSTEM_SETTLED:
        return t("common.hasSettledTheDeal", {
          firstName,
          lastName,
        });
    }
  };

  return value.requestNote?.requestedAt && value.status ? (
    <DealStatusSection
      firstName={value.requestNote?.userName.firstName}
      lastName={value.requestNote?.userName.lastName}
      requestedAt={value.requestNote?.requestedAt}
      note={value.requestNote?.note}
      impactBuyChart={impactBuyChart ?? undefined}
      impactSellChart={impactSellChart ?? undefined}
      isHistoryComponent={isHistoryComponent}
      impactOnPortfolio={impactTable ? impactTable : undefined}
      userImage={value.requestNote.profilePhoto?.toString()}
      title={getTitleText(value.status)}
      chipText={getRequestStatusValue(t, value.status).toLocaleUpperCase()}
      chipColor={getFiStatusColor(value.status)}
      assetName={assetName}
      transactionType={transactionType}
    />
  ) : (
    <></>
  );
};
