import { MFConfirmationRequestStatus } from "@khazana/khazana-rpcs";
import i18next from "i18next";
import { ChipColor } from "@surya-digital/leo-reactjs-core";

export const getMFConfirmationRequestStatusValue = (
  value: MFConfirmationRequestStatus.MFConfirmationRequestStatus,
): string => {
  const statusType = MFConfirmationRequestStatus.MFConfirmationRequestStatus;
  switch (value) {
    case statusType.FOLIO_CREATION_REQUESTED:
      return i18next.t(
        "mf.confirmationRequestAdditionalStatus.folioCreationRequested",
      );
    case statusType.FOLIO_CREATION_REJECTED:
      return i18next.t(
        "mf.confirmationRequestAdditionalStatus.folioCreationRejected",
      );
    case statusType.ACCOUNTS_PENDING:
      return i18next.t("contractNotes.accountsPending");
    case statusType.ACCOUNTS_APPROVED:
      return i18next.t("contractNotes.accountsApproved");
    case statusType.ACCOUNTS_REJECTED:
      return i18next.t("contractNotes.accountsRejected");
    case statusType.LINKING_PENDING:
      return i18next.t("contractNotes.linkingPending");
    case statusType.LINKED:
      return i18next.t("contractNotes.linked");
    case statusType.UNKNOWN_REQUESTED:
      return i18next.t("contractNotes.unknownRequested");
    case statusType.UNKNOWN:
      return i18next.t("contractNotes.unknown");
  }
};

export const getMFConfirmationStatusBackgroundColor = (
  status: MFConfirmationRequestStatus.MFConfirmationRequestStatus,
): ChipColor => {
  switch (status) {
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus.UNKNOWN:
      return "red";
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus.LINKED:
      return "green";
    default:
      return "orange";
  }
};

export const getMFConfirmationHistoryTitle = (
  status: MFConfirmationRequestStatus.MFConfirmationRequestStatus | null,
  firstName: string | undefined,
  lastName: string | undefined,
): string => {
  switch (status) {
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus.LINKED:
      return i18next.t("mf.confirmationHistory.linkedMessage", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus
      .FOLIO_CREATION_REQUESTED:
      return i18next.t("mf.confirmationHistory.folioRequestedTitle", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus
      .FOLIO_CREATION_REJECTED:
      return i18next.t("mf.confirmationHistory.folioRejectedTitle", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus
      .ACCOUNTS_PENDING:
      return i18next.t("mf.confirmationHistory.accountsPendingMessage", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus
      .UNKNOWN_REQUESTED:
      return i18next.t("mf.confirmationHistory.unknownRequestedMessage", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus
      .ACCOUNTS_APPROVED:
      return i18next.t("mf.confirmationHistory.accountsApprovedMessage", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus
      .ACCOUNTS_REJECTED:
      return i18next.t("mf.confirmationHistory.accountsRejectedMessage", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus
      .LINKING_PENDING:
      return i18next.t("mf.confirmationHistory.linkRequestMessage", {
        firstName,
        lastName,
      });
    case MFConfirmationRequestStatus.MFConfirmationRequestStatus.UNKNOWN:
      return i18next.t("mf.confirmationHistory.unknownMessage", {
        firstName,
        lastName,
      });
    default:
      return "";
  }
};
