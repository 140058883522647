"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const fiContractNoteDealRequestDetail_1 = require("./fiContractNoteDealRequestDetail");
const fiContractNoteRow_1 = require("./fiContractNoteRow");
const fiContractNoteCharge_1 = require("./fiContractNoteCharge");
const fiContractNoteRequestStatus_1 = require("./fiContractNoteRequestStatus");
class InvalidFiContractNoteHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteHistoryDetailError";
    }
}
class FiContractNoteHistoryDetail {
    constructor(requestNote, status, dealRequestDetails = null, diffDetails, charges) {
        this.isFiContractNoteHistoryDetail = true;
        FiContractNoteHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        FiContractNoteHistoryDetail.validateStatus(status);
        this.status = status;
        if (dealRequestDetails !== undefined && dealRequestDetails !== null) {
            FiContractNoteHistoryDetail.validateDealRequestDetails(dealRequestDetails);
        }
        this.dealRequestDetails = dealRequestDetails;
        FiContractNoteHistoryDetail.validateDiffDetails(diffDetails);
        this.diffDetails = diffDetails;
        FiContractNoteHistoryDetail.validateCharges(charges);
        this.charges = charges;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidFiContractNoteHistoryDetailError));
        const status = fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidFiContractNoteHistoryDetailError));
        let dealRequestDetails = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestDetails");
        if (dealRequestDetails !== undefined && dealRequestDetails !== null) {
            dealRequestDetails = fiContractNoteDealRequestDetail_1.FiContractNoteDealRequestDetail.fromDTO(dealRequestDetails);
        }
        const diffDetails = (0, leo_ts_runtime_1.getList)(dto, "diffDetails", fiContractNoteRow_1.FiContractNoteRow.fromDTO, InvalidFiContractNoteHistoryDetailError);
        const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", fiContractNoteCharge_1.FiContractNoteCharge.fromDTO, InvalidFiContractNoteHistoryDetailError);
        return new FiContractNoteHistoryDetail(requestNote, status, dealRequestDetails, diffDetails, charges);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.toDTO(this.status),
            diffDetails: this.diffDetails.map((e) => {
                return e.toDTO();
            }),
            charges: this.charges.map((e) => {
                return e.toDTO();
            }),
        };
        if (this.dealRequestDetails) {
            ret.dealRequestDetails = this.dealRequestDetails.toDTO();
        }
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status, dealRequestDetails = this.dealRequestDetails, diffDetails = this.diffDetails, charges = this.charges) {
        return new FiContractNoteHistoryDetail(requestNote, status, dealRequestDetails, diffDetails, charges);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiContractNoteHistoryDetailValue = other.isFiContractNoteHistoryDetail;
        if (!(other instanceof FiContractNoteHistoryDetail) || Boolean(isFiContractNoteHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.dealRequestDetails) {
            if (!this.dealRequestDetails.equals(other.dealRequestDetails)) {
                return false;
            }
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.diffDetails, other.diffDetails)) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
            return false;
        }
        return true;
    }
}
exports.FiContractNoteHistoryDetail = FiContractNoteHistoryDetail;
FiContractNoteHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidFiContractNoteHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
FiContractNoteHistoryDetail.validateStatus = (status) => {
    if (!fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.isInstanceOf(status)) {
        throw new InvalidFiContractNoteHistoryDetailError(`Attribute status is not a FiContractNoteRequestStatus.FiContractNoteRequestStatus`);
    }
};
FiContractNoteHistoryDetail.validateDealRequestDetails = (dealRequestDetails) => {
    const isFiContractNoteDealRequestDetailValue = dealRequestDetails.isFiContractNoteDealRequestDetail;
    if (!(dealRequestDetails instanceof fiContractNoteDealRequestDetail_1.FiContractNoteDealRequestDetail || Boolean(isFiContractNoteDealRequestDetailValue))) {
        throw new InvalidFiContractNoteHistoryDetailError(`Attribute dealRequestDetails is not a FiContractNoteDealRequestDetail`);
    }
};
FiContractNoteHistoryDetail.validateDiffDetails = (diffDetails) => {
    if (!Array.isArray(diffDetails)) {
        throw new InvalidFiContractNoteHistoryDetailError(`Attribute diffDetails is not a List`);
    }
    for (let i = 0; i < diffDetails.length; i += 1) {
        const isFiContractNoteRowValue = diffDetails[i].isFiContractNoteRow;
        if (!(diffDetails[i] instanceof fiContractNoteRow_1.FiContractNoteRow || Boolean(isFiContractNoteRowValue))) {
            throw new InvalidFiContractNoteHistoryDetailError(`Attribute diffDetails at index ${i} is not a FiContractNoteRow.`);
        }
    }
};
FiContractNoteHistoryDetail.validateCharges = (charges) => {
    if (!Array.isArray(charges)) {
        throw new InvalidFiContractNoteHistoryDetailError(`Attribute charges is not a List`);
    }
    for (let i = 0; i < charges.length; i += 1) {
        const isFiContractNoteChargeValue = charges[i].isFiContractNoteCharge;
        if (!(charges[i] instanceof fiContractNoteCharge_1.FiContractNoteCharge || Boolean(isFiContractNoteChargeValue))) {
            throw new InvalidFiContractNoteHistoryDetailError(`Attribute charges at index ${i} is not a FiContractNoteCharge.`);
        }
    }
};
FiContractNoteHistoryDetail.prototype.toString = function toString() {
    return `FiContractNoteHistoryDetail(requestNote=${this.requestNote},status=${this.status},dealRequestDetails=${this.dealRequestDetails},diffDetails=${this.diffDetails},charges=${this.charges})`;
};
