import React from "react";

export const CheveronsUp = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.81077 4.41205C10.1362 4.08661 10.6638 4.08661 10.9893 4.41205L15.1559 8.57871C15.4814 8.90415 15.4814 9.43179 15.1559 9.75722C14.8305 10.0827 14.3029 10.0827 13.9774 9.75722L10.4 6.17981L6.82261 9.75722C6.49718 10.0827 5.96954 10.0827 5.6441 9.75722C5.31867 9.43179 5.31867 8.90415 5.6441 8.57871L9.81077 4.41205ZM5.6441 14.412L9.81077 10.2454C10.1362 9.91994 10.6638 9.91994 10.9893 10.2454L15.1559 14.412C15.4814 14.7375 15.4814 15.2651 15.1559 15.5906C14.8305 15.916 14.3029 15.916 13.9774 15.5906L10.4 12.0131L6.82261 15.5906C6.49718 15.916 5.96954 15.916 5.6441 15.5906C5.31867 15.2651 5.31867 14.7375 5.6441 14.412Z"
        fill="currentColor"
      />
    </svg>
  );
};
