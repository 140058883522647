export const enum Module {
  Equity = "equity",
  Fi = "fixed-income",
  Fd = "fixed-deposit",
  MF = "mutual-fund",
}

export const enum Route {
  Root = "/",
  SignIn = "/sign-in",
  SignOut = "/sign-out",
  Dashboard = "dashboard",
  CreateDealRequest = "create-deal-request",
  ViewDealRequest = "view-deal-request",
  DealRequestDetails = "view-deal-request-details",
  DealRequestDetailsWithParams = "view-deal-request-details?requestId=",
  ContractNotesDetails = "manage-contract-notes/details",
  ContractNotesDetailsWithParams = "manage-contract-notes/details?contractNoteId=",
  ManageContractNotes = "manage-contract-notes",
  ManageDealRequest = "manage-deal-request",
  ManageMaturityRequest = "manage-maturity-request",
  ManageDealRequestDetails = "manage-deal-request/details",
  ManageDealRequestDetailsWithParams = "manage-deal-request/details?requestId=",
  ManageMaturityRequestDetails = "manage-maturity-request/details",
  ManageMaturityRequestDetailsWithParams = "manage-maturity-request/details?requestId=",
  ManageDealRequestCertificateDetails = "manage-deal-request/certificate-details",
  ManageDealRequestCertificateEdit = "manage-deal-request/certificate-edit",
  Reports = "reports",
  ChangeInGain = "reports/change-in-gain",
  HoldingSummary = "reports/holding-summary",
  EntityDetails = "settings/entity",
  PortfolioDetails = "settings/portfolio",
  CreatePaymentRequest = "manage-contract-notes/create-payment-request",
  ManageConfirmationRequests = "manage-confirmation-requests",
  CreateConfirmationRequest = "manage-confirmation-requests/create-request",
  EditConfirmationRequest = "manage-confirmation-requests/edit-request",
  EditConfirmationRequestWithParams = "manage-confirmation-requests/edit-request?requestId=",
  ManageConfirmationRequestsDetails = "manage-confirmation-requests/details",
  ManageConfirmationRequestsDetailsWithParams = "manage-confirmation-requests/details?requestId=",
  InvestmentRegisterReport = "reports/investment-register-report",
  InvestmentReturnsGrowthReport = "reports/investment-returns-growth-report",
  ContractNoteInvestmentCashFlowsWithParams = "manage-contract-notes/investment-cash-flows?investmentId=",
  ContractNoteInvestmentCashFlows = "manage-contract-notes/investment-cash-flows",
  MaturityInvestmentCashFlowsWithParams = "manage-maturity-request/investment-cash-flows?investmentId=",
  MaturityInvestmentCashFlows = "manage-maturity-request/investment-cash-flows",
  DealInvestmentCashFlowsWithParams = "manage-deal-request/investment-cash-flows?investmentId=",
  DealInvestmentCashFlows = "manage-deal-request/investment-cash-flows",
  SettleMaturityDealRequest = "manage-maturity-request/settle",
  SettleMaturityDealRequestWithParams = "manage-maturity-request/settle?requestId=",
}
