"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccrualItem = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigAmount_1 = require("./bigAmount");
class InvalidAccrualItemError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidAccrualItemError";
    }
}
class AccrualItem {
    constructor(accrualId, accrualType, accrualPostedDate, accrualAmount) {
        this.isAccrualItem = true;
        AccrualItem.validateAccrualId(accrualId);
        this.accrualId = accrualId;
        AccrualItem.validateAccrualType(accrualType);
        this.accrualType = accrualType;
        AccrualItem.validateAccrualPostedDate(accrualPostedDate);
        this.accrualPostedDate = accrualPostedDate;
        AccrualItem.validateAccrualAmount(accrualAmount);
        this.accrualAmount = accrualAmount;
    }
    static fromDTO(dto) {
        const accrualId = (0, leo_ts_runtime_1.getInt64)(dto, "accrualId", InvalidAccrualItemError);
        const accrualType = (0, leo_ts_runtime_1.getString)(dto, "accrualType", InvalidAccrualItemError);
        const accrualPostedDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "accrualPostedDate", InvalidAccrualItemError);
        const accrualAmount = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "accrualAmount", InvalidAccrualItemError));
        return new AccrualItem(accrualId, accrualType, accrualPostedDate, accrualAmount);
    }
    toDTO() {
        const ret = {
            accrualId: this.accrualId,
            accrualType: this.accrualType,
            accrualPostedDate: this.accrualPostedDate.toDTO(),
            accrualAmount: this.accrualAmount.toDTO(),
        };
        return ret;
    }
    copy(accrualId = this.accrualId, accrualType = this.accrualType, accrualPostedDate = this.accrualPostedDate, accrualAmount = this.accrualAmount) {
        return new AccrualItem(accrualId, accrualType, accrualPostedDate, accrualAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isAccrualItemValue = other.isAccrualItem;
        if (!(other instanceof AccrualItem) || Boolean(isAccrualItemValue)) {
            return false;
        }
        if (this.accrualId !== other.accrualId) {
            return false;
        }
        if (this.accrualType !== other.accrualType) {
            return false;
        }
        if (!this.accrualPostedDate.equals(other.accrualPostedDate)) {
            return false;
        }
        if (!this.accrualAmount.equals(other.accrualAmount)) {
            return false;
        }
        return true;
    }
}
exports.AccrualItem = AccrualItem;
AccrualItem.validateAccrualId = (accrualId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(accrualId)) {
        throw new InvalidAccrualItemError(`Attribute accrualId is not an Int64`);
    }
};
AccrualItem.validateAccrualType = (accrualType) => {
    if (!(0, leo_ts_runtime_1.isString)(accrualType)) {
        throw new InvalidAccrualItemError(`Attribute accrualType is not a String`);
    }
    if (accrualType.trim().length === 0) {
        throw new InvalidAccrualItemError(`Attribute accrualType cannot be an empty string.`);
    }
};
AccrualItem.validateAccrualPostedDate = (accrualPostedDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(accrualPostedDate)) {
        throw new InvalidAccrualItemError(`Attribute accrualPostedDate is not a Date`);
    }
};
AccrualItem.validateAccrualAmount = (accrualAmount) => {
    const isBigAmountValue = accrualAmount.isBigAmount;
    if (!(accrualAmount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidAccrualItemError(`Attribute accrualAmount is not a BigAmount`);
    }
};
AccrualItem.prototype.toString = function toString() {
    return `AccrualItem(accrualId=${this.accrualId},accrualType='${this.accrualType}',accrualPostedDate=${this.accrualPostedDate},accrualAmount=${this.accrualAmount})`;
};
