import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import {
  EquityCollection,
  EquityDealValue,
  GetAvailableBankBalanceRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { EquityModel } from "../models/EquityModel";
import { EntityModel } from "../../../models/EntityModel";
import { PortfolioModel } from "../../../models/PortfolioModel";
import { DematAccountNumberModel } from "../../../models/DematAccountModel";
import { EquityTransactionDetailsModel } from "../models/EquityTransactionDetailsModel";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import { DealType } from "../../../../../types/EnumTypes";
import { convertStringToNumber } from "../../../utils/UIUtils";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { createServerTransactionAmountRPCType } from "../../../models/AmountModel";
import { EqImpactTableModel } from "../models/EquityDealRequestHistoryAdditionalDetailModel";
import { AMOUNT_LIMIT, getAmount } from "../../../../../utils";
import { CreateBuyEquityDealRequestStore } from "./CreateBuyEquityDealRequestStore";
import { CreateEquityDealRequestError } from "./CreateEquityDealRequestError";
import { CreateSellEquityDealRequestStore } from "./CreateSellEquityDealRequestStore";
import { useGetAvailableBankBalanceRPCClientImpl } from "../../../rpcs/RPC";

// CreateDealRequestDisabledFields is used to store the disabled state of field associated with store value property name.
interface CreateDealRequestDisabledFields {
  grossAmount: boolean;
  quantity: boolean;
  price: boolean;
  entity: boolean;
  portfolio: boolean;
  dematAccount: boolean;
  note: boolean;
}

// CreateDealRequestFieldErrors is used to store the error message of field associated with store value property name.
export const CreateDealRequestFieldErrors = types.model({
  symbol: types.maybe(types.string),
  grossAmount: types.maybe(types.string),
  quantity: types.maybe(types.string),
  entity: types.maybe(types.string),
  portfolio: types.maybe(types.string),
  dematAccount: types.maybe(types.string),
  price: types.maybe(types.string),
});

const getEquityCollection = (
  portfolio: string | null,
  dematAccount: string | null,
  showEquityPortfolio: boolean,
  showEquityDematAccount: boolean,
): EquityCollection.EquityCollection | null => {
  if (showEquityPortfolio && portfolio) {
    if (showEquityDematAccount && dematAccount) {
      return new EquityCollection.PortfolioDematAccount(
        new LeoUUID(portfolio),
        dematAccount,
      );
    } else {
      return new EquityCollection.Portfolio(new LeoUUID(portfolio));
    }
  } else if (showEquityDematAccount && dematAccount) {
    return new EquityCollection.DematAccount(dematAccount);
  }
  return null;
};

export const CreateEquityDealRequestStore = types
  .model("CreateEquityDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<CreateEquityDealRequestError>(
        "CreateEquityDealRequestError",
        Object.values(CreateEquityDealRequestError),
      ),
    ),
    fieldErrors: types.maybeNull(CreateDealRequestFieldErrors),
    equityList: types.array(EquityModel),
    entityList: types.array(EntityModel),
    portfolioList: types.array(PortfolioModel),
    dematAccountNumberList: types.array(DematAccountNumberModel),
    equityTransactionDetails: types.maybeNull(EquityTransactionDetailsModel),
    symbol: types.maybeNull(types.string),
    grossAmount: types.maybeNull(types.number),
    quantity: types.maybeNull(types.number),
    // price field is maxPrice for Buy flow and minPrice for Sell flow.
    price: types.maybeNull(types.number),
    entity: types.maybeNull(types.string),
    portfolio: types.maybeNull(types.string),
    dematAccount: types.maybeNull(types.string),
    note: types.maybeNull(types.string),
    availableBalance: types.maybeNull(types.number),
    impactTableModel: types.maybeNull(EqImpactTableModel),
    createBuyEquityDealRequestStore: CreateBuyEquityDealRequestStore,
    createSellEquityDealRequestStore: CreateSellEquityDealRequestStore,
  })
  .views((store) => ({
    disableFields: (): CreateDealRequestDisabledFields => ({
      grossAmount:
        !store.symbol || Boolean(store.quantity) || Boolean(store.price),
      quantity: !store.symbol || Boolean(store.grossAmount),
      price: !store.symbol || Boolean(store.grossAmount),
      entity: !store.symbol,
      portfolio: !store.symbol || !store.entity,
      dematAccount: !store.symbol || !store.entity,
      note: !store.symbol,
    }),
    getCurrencyModelFromSelectedEquity: (): Instance<
      typeof CurrencyModel
    > | null => {
      const selectedEquity = store.equityList.find(
        ({ isin }) => isin.isin === store.symbol,
      );
      if (!selectedEquity) return null;
      return selectedEquity.currency;
    },
    getCurrencySymbolPostfixString: (): string => {
      const selectedEquity = store.equityList.find(
        ({ isin }) => isin.isin === store.symbol,
      );
      if (!selectedEquity?.currency) {
        return "";
      } else {
        return ` (${selectedEquity.currency.symbol})`;
      }
    },
  }))
  // The first block of actions contains function performEquityImpactOnPortfolioChecks which is required by getEquityImpactOnPortfolio
  .actions((store) => ({
    performEquityImpactOnPortfolioChecks:
      (): EquityDealValue.EquityDealValue | null => {
        const logger = getEnv(store).logger;
        const currency = store.getCurrencyModelFromSelectedEquity();

        if (!currency) {
          logger.error("Currency could not retrieved from equity list");
          return null;
        }

        if (store.symbol === null) {
          logger.error("No symbol selected");
          store.error = CreateEquityDealRequestError.InvalidISIN;
          return null;
        }

        let dealValue: EquityDealValue.EquityDealValue;
        if (store.grossAmount && Number(store.grossAmount)) {
          dealValue = new EquityDealValue.DealAmount(
            createServerTransactionAmountRPCType(store.grossAmount, currency),
          );
        } else if (store.quantity && store.price && Number(store.price)) {
          dealValue = new EquityDealValue.DealQuantity(
            store.quantity,
            createServerTransactionAmountRPCType(store.price, currency),
          );
        } else {
          logger.error("Neither amount nor quantity and price entered");
          store.impactTableModel = null;
          return null;
        }
        return dealValue;
      },
    validateAvailableBalanceWithGrossAmount: (): void => {
      if (
        store.grossAmount &&
        store.availableBalance &&
        store.grossAmount > store.availableBalance
      ) {
        store.createBuyEquityDealRequestStore.errorBankBalance = true;
      } else {
        store.createBuyEquityDealRequestStore.errorBankBalance = false;
      }
    },
    validateAvailableBalanceWithQuantity: (): void => {
      if (
        store.quantity &&
        store.availableBalance &&
        store.price &&
        store.quantity * store.price > store.availableBalance
      ) {
        store.createBuyEquityDealRequestStore.errorBankBalance = true;
      } else {
        store.createBuyEquityDealRequestStore.errorBankBalance = false;
      }
    },
  }))
  .actions((store) => ({
    getAvailableBankBalance: flow(function* () {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        if (store.dematAccount !== null) {
          const request = new GetAvailableBankBalanceRPC.Request(
            new GetAvailableBankBalanceRPC.RequestEnums.AccountNumber.DematAccount(
              store.dematAccount,
            ),
          );
          const result: LeoRPCResult<
            GetAvailableBankBalanceRPC.Response,
            GetAvailableBankBalanceRPC.Errors.Errors
          > =
            yield useGetAvailableBankBalanceRPCClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.availableBalance = getAmount(
              response.availableBalance?.amount,
            );
            if (store.grossAmount) {
              store.validateAvailableBalanceWithGrossAmount();
            } else {
              store.validateAvailableBalanceWithQuantity();
            }
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetAvailableBankBalanceRPC`,
            );
          }
        } else {
          logger.error("Demat Account is not present");
        }
      } catch (error) {
        logger.error(
          `Unknown Error: ${error} occurred in GetAvailableBankBalanceRPC`,
        );
      }
    }),
    getEquityList: flow(function* (dealType: DealType, searchText: string) {
      switch (dealType) {
        case DealType.Buy:
          store.equityList =
            yield store.createBuyEquityDealRequestStore.getBuyEquityList(
              searchText,
            );
          break;
        case DealType.Sell:
          store.equityList =
            yield store.createSellEquityDealRequestStore.getSellEquityList(
              searchText,
            );
          break;
      }
      return store.equityList;
    }),
    getEquityTransactionDetails: flow(function* (dealType: DealType) {
      const logger = getEnv(store).logger;
      const currency = store.getCurrencyModelFromSelectedEquity();
      if (!currency) {
        logger.error("Currency could not retrieved from equity list");
        return;
      }
      let dealValue: EquityDealValue.EquityDealValue;
      if (store.grossAmount && Number(store.grossAmount)) {
        dealValue = new EquityDealValue.DealAmount(
          createServerTransactionAmountRPCType(store.grossAmount, currency),
        );
      } else if (store.quantity && store.price && Number(store.price)) {
        dealValue = new EquityDealValue.DealQuantity(
          store.quantity,
          createServerTransactionAmountRPCType(store.price, currency),
        );
      } else {
        store.equityTransactionDetails = null;
        return;
      }
      switch (dealType) {
        case DealType.Buy:
          store.equityTransactionDetails =
            yield store.createBuyEquityDealRequestStore.getBuyEquityTransactionDetails(
              dealValue,
            );
          break;
        case DealType.Sell:
          store.equityTransactionDetails =
            yield store.createSellEquityDealRequestStore.getSellEquityTransactionDetails(
              dealValue,
            );
          break;
      }
      return store.equityTransactionDetails;
    }),
    getEquityImpactOnPortfolio: flow(function* (dealType: DealType) {
      const dealValue = store.performEquityImpactOnPortfolioChecks();
      if (!store.symbol || !store.entity || !store.portfolio || !dealValue) {
        return;
      }
      switch (dealType) {
        case DealType.Buy:
          store.impactTableModel =
            yield store.createBuyEquityDealRequestStore.getBuyEquityImpactOnPortfolio(
              store.symbol,
              new LeoUUID(store.entity),
              new LeoUUID(store.portfolio),
              dealValue,
            );
          break;
        case DealType.Sell:
          store.impactTableModel =
            yield store.createSellEquityDealRequestStore.getSellEquityImpactOnPortfolio(
              store.symbol,
              new LeoUUID(store.entity),
              new LeoUUID(store.portfolio),
              dealValue,
            );
      }
      return store.impactTableModel;
    }),
    createEquityDealRequest: flow(function* (
      dealType: DealType,
      showEquityPortfolio: boolean,
      showEquityDematAccount: boolean,
    ) {
      const logger = getEnv(store).logger;
      const currency = store.getCurrencyModelFromSelectedEquity();
      if (
        !store.symbol ||
        !store.entity ||
        !currency ||
        !store.fieldErrors ||
        Object.values(store.fieldErrors).some((value) => value !== "")
      ) {
        return;
      }
      const equityCollection = getEquityCollection(
        store.portfolio,
        store.dematAccount,
        showEquityPortfolio,
        showEquityDematAccount,
      );
      if (!equityCollection) {
        logger.error(
          "Both Equity Portfolio and Equity Demat Account are hidden",
        );
        return;
      }
      let dealValue: EquityDealValue.EquityDealValue;
      if (store.grossAmount) {
        dealValue = new EquityDealValue.DealAmount(
          createServerTransactionAmountRPCType(store.grossAmount, currency),
        );
      } else if (store.quantity && store.price) {
        dealValue = new EquityDealValue.DealQuantity(
          store.quantity,
          createServerTransactionAmountRPCType(store.price, currency),
        );
      } else return;
      switch (dealType) {
        case DealType.Buy:
          return store.createBuyEquityDealRequestStore.createBuyEquityDealRequest(
            store.symbol,
            new LeoUUID(store.entity),
            equityCollection,
            dealValue,
            store.note,
          );
        case DealType.Sell:
          return store.createSellEquityDealRequestStore.createSellEquityDealRequest(
            store.symbol,
            new LeoUUID(store.entity),
            equityCollection,
            dealValue,
            store.note,
          );
      }
    }),
    setSymbol: flow(function* (value: string, dealType: DealType) {
      const logger = getEnv(store).logger;
      store.symbol = value;
      store.fieldErrors = null;

      if (value) {
        switch (dealType) {
          case DealType.Buy:
            store.entityList =
              yield store.createBuyEquityDealRequestStore.getBuyEntityList();
            break;
          case DealType.Sell:
            if (!store.symbol) {
              logger.error(
                "Symbol is null, Sell Entity List cannot be fetched. If this error occurs then it is a developer error.",
              );
              return;
            }
            store.entityList =
              yield store.createSellEquityDealRequestStore.getSellEntityListRPC(
                store.symbol,
              );
            break;
        }
      }
    }),
    setGrossAmount: (value: string): void => {
      store.createBuyEquityDealRequestStore.errorBankBalance = false;
      store.grossAmount = convertStringToNumber(value);
      store.fieldErrors = null;
    },
    setQuantity: (value: string): void => {
      store.quantity = convertStringToNumber(value);
      store.fieldErrors = null;
      store.createBuyEquityDealRequestStore.errorBankBalance = false;
    },
    setPrice: (value: string): void => {
      store.price = convertStringToNumber(value);
      store.fieldErrors = null;
      store.createBuyEquityDealRequestStore.errorBankBalance = false;
    },
    setEntity: flow(function* (
      { value }: DropdownItem,
      dealType: DealType,
      showEntityPortfolio: boolean,
      showEquityDematAccount: boolean,
    ) {
      const logger = getEnv(store).logger;
      store.portfolio = null;
      store.entity = value;
      store.fieldErrors = null;
      store.dematAccount = null;
      store.availableBalance = null;
      store.createBuyEquityDealRequestStore.errorBankBalance = false;
      const entityId = new LeoUUID(value);
      if (showEntityPortfolio) {
        switch (dealType) {
          case DealType.Buy:
            store.portfolioList =
              yield store.createBuyEquityDealRequestStore.getBuyPortfolioList(
                entityId,
              );
            break;
          case DealType.Sell:
            if (!store.symbol) {
              logger.error(
                "Developer Error: Symbol is null, Sell Portfolio Account Number List cannot be fetched.",
              );
              return;
            }
            store.portfolioList =
              yield store.createSellEquityDealRequestStore.getSellPortfolioList(
                store.symbol,
                entityId,
              );
        }
      }
      if (showEquityDematAccount) {
        switch (dealType) {
          case DealType.Buy:
            store.dematAccountNumberList =
              yield store.createBuyEquityDealRequestStore.getBuyDematAccountNumberList(
                entityId,
              );
            break;
          case DealType.Sell:
            if (!store.symbol) {
              logger.error(
                "Symbol is null, Sell Demat Account Number List cannot be fetched. If this error occurs then it is a developer error.",
              );
              return;
            }
            store.dematAccountNumberList =
              yield store.createSellEquityDealRequestStore.getSellDematAccountNumberList(
                store.symbol,
                entityId,
              );
            break;
        }
      }
    }),
    setPortfolio: ({ value }: DropdownItem): void => {
      store.portfolio = value;
      store.fieldErrors = null;
    },
    setDematAccount: ({ value }: DropdownItem): void => {
      store.dematAccount = value;
      store.fieldErrors = null;
      store.createBuyEquityDealRequestStore.errorBankBalance = false;
    },
    setNote: (value: string): void => {
      store.note = value;
    },
    setFieldErrors: (
      errors: Instance<typeof CreateDealRequestFieldErrors>,
    ): void => {
      store.fieldErrors = errors;
    },
    clearStoreError: (): void => {
      store.error = null;
      store.createBuyEquityDealRequestStore.clearStoreError();
      store.createSellEquityDealRequestStore.clearStoreError();
    },
    clearFields: (): void => {
      store.error = null;
      store.createBuyEquityDealRequestStore.clearStoreError();
      store.createSellEquityDealRequestStore.clearStoreError();
      store.fieldErrors = null;
      store.entityList = cast([]);
      store.portfolioList = cast([]);
      store.dematAccountNumberList = cast([]);
      store.equityTransactionDetails = null;
      store.grossAmount = null;
      store.quantity = null;
      store.price = null;
      store.entity = null;
      store.portfolio = null;
      store.dematAccount = null;
      store.availableBalance = null;
      store.note = null;
      store.impactTableModel = null;
    },
    clearStore: (): void => {
      store.createBuyEquityDealRequestStore.clearStoreError();
      store.createSellEquityDealRequestStore.clearStoreError();
      store.fieldErrors = null;
      store.equityList = cast([]);
      store.entityList = cast([]);
      store.portfolioList = cast([]);
      store.dematAccountNumberList = cast([]);
      store.equityTransactionDetails = null;
      store.symbol = null;
      store.grossAmount = null;
      store.quantity = null;
      store.price = null;
      store.entity = null;
      store.portfolio = null;
      store.dematAccount = null;
      store.availableBalance = null;
      store.note = null;
      store.impactTableModel = null;
    },
    resetImpactTableModel: (): void => {
      store.impactTableModel = null;
    },
    resetEquityTransactionDetails: (): void => {
      store.equityTransactionDetails = null;
    },
  }))
  .views((store) => ({
    isAmountValid: (): boolean => {
      if (store.quantity && store.price) {
        return store.quantity * store.price <= AMOUNT_LIMIT;
      } else {
        return true;
      }
    },
  }));

export const createCreateEquityDealRequestStore = (): Instance<
  typeof CreateEquityDealRequestStore
> => {
  return CreateEquityDealRequestStore.create({
    symbol: "",
    grossAmount: null,
    quantity: null,
    price: null,
    entity: null,
    portfolio: null,
    dematAccount: null,
    note: "",
    createBuyEquityDealRequestStore: CreateBuyEquityDealRequestStore.create({}),
    createSellEquityDealRequestStore: CreateSellEquityDealRequestStore.create(
      {},
    ),
  });
};
