"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardStat = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("./currency");
class InvalidDashboardStatError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidDashboardStatError";
    }
}
class DashboardStat {
    constructor(amount = null, currency = null, isGain = null, percentage = null) {
        this.isDashboardStat = true;
        if (amount !== undefined && amount !== null) {
            DashboardStat.validateAmount(amount);
        }
        this.amount = amount;
        if (currency !== undefined && currency !== null) {
            DashboardStat.validateCurrency(currency);
        }
        this.currency = currency;
        if (isGain !== undefined && isGain !== null) {
            DashboardStat.validateIsGain(isGain);
        }
        this.isGain = isGain;
        if (percentage !== undefined && percentage !== null) {
            DashboardStat.validatePercentage(percentage);
        }
        this.percentage = percentage;
    }
    static fromDTO(dto) {
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidDashboardStatError);
        let currency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "currency");
        if (currency !== undefined && currency !== null) {
            currency = currency_1.Currency.fromDTO(currency);
        }
        const isGain = (0, leo_ts_runtime_1.getOptionalBoolean)(dto, "isGain", InvalidDashboardStatError);
        const percentage = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "percentage", InvalidDashboardStatError);
        return new DashboardStat(amount, currency, isGain, percentage);
    }
    toDTO() {
        const ret = {};
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.currency) {
            ret.currency = this.currency.toDTO();
        }
        if (this.isGain !== undefined && this.isGain !== null) {
            ret.isGain = this.isGain;
        }
        if (this.percentage !== undefined && this.percentage !== null) {
            ret.percentage = this.percentage;
        }
        return ret;
    }
    copy(amount = this.amount, currency = this.currency, isGain = this.isGain, percentage = this.percentage) {
        return new DashboardStat(amount, currency, isGain, percentage);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isDashboardStatValue = other.isDashboardStat;
        if (!(other instanceof DashboardStat) || Boolean(isDashboardStatValue)) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (this.currency) {
            if (!this.currency.equals(other.currency)) {
                return false;
            }
        }
        if (this.isGain !== other.isGain) {
            return false;
        }
        if (this.percentage !== other.percentage) {
            return false;
        }
        return true;
    }
}
exports.DashboardStat = DashboardStat;
DashboardStat.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidDashboardStatError(`Attribute amount is not an Int64`);
    }
};
DashboardStat.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidDashboardStatError(`Attribute currency is not a Currency`);
    }
};
DashboardStat.validateIsGain = (isGain) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isGain)) {
        throw new InvalidDashboardStatError(`Attribute isGain is not a Boolean`);
    }
};
DashboardStat.validatePercentage = (percentage) => {
    if (!(0, leo_ts_runtime_1.isInt64)(percentage)) {
        throw new InvalidDashboardStatError(`Attribute percentage is not an Int64`);
    }
};
DashboardStat.prototype.toString = function toString() {
    return `DashboardStat(amount=${this.amount},currency=${this.currency},isGain=${this.isGain},percentage=${this.percentage})`;
};
