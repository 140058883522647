import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createFDWithdrawModel,
  FDWithdrawModel,
} from "../../models/FDWithdrawModel";
import { CreateWithdrawRequestRPC, Note } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { useCreateWithdrawRequestRPC } from "../../rpc/RPC";
import { createServerTransactionAmountRPCType } from "../../../../models/AmountModel";
import { getServerAmount } from "../../../../../../utils";
import {
  FDCreateWithdrawRequestErrors,
  FDDealInvalidRequestError,
} from "../FDDealRequestErrors";
import i18next from "i18next";

export const CreateWithdrawRequestStore = types
  .model("CreateWithdrawRequestStore", {
    fields: FDWithdrawModel,
    note: types.maybe(types.string),
    error: types.maybeNull(
      types.union(
        types.enumeration<FDDealInvalidRequestError>(
          "FDDealInvalidRequestError",
          Object.values(FDDealInvalidRequestError),
        ),
        types.enumeration<FDCreateWithdrawRequestErrors>(
          "FDCreateWithdrawRequestErrors",
          Object.values(FDCreateWithdrawRequestErrors),
        ),
      ),
    ),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setNote: (value: string): void => {
        store.note = value;
      },
      createWithdrawRequest: flow(function* (requestId: number) {
        if (
          store.fields.isCreateValid() &&
          store.fields.withdrawalAmount &&
          store.fields.currency &&
          store.fields.penaltyCharges &&
          store.fields.penaltyAmount
        ) {
          const logger = getEnv(store).logger;
          const apiClient = getAPIClient(store);
          const request = new CreateWithdrawRequestRPC.Request(
            requestId,
            createServerTransactionAmountRPCType(
              store.fields.withdrawalAmount,
              store.fields.currency,
            ),
            getServerAmount(store.fields.penaltyCharges),
            createServerTransactionAmountRPCType(
              store.fields.penaltyAmount,
              store.fields.currency,
            ),
            store.note ? new Note(store.note) : null,
          );
          const result: LeoRPCResult<
            CreateWithdrawRequestRPC.Response,
            CreateWithdrawRequestRPC.Errors.Errors
          > = yield useCreateWithdrawRequestRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            return;
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              case FDDealInvalidRequestError.InvalidRequestId:
                store.error = FDDealInvalidRequestError.InvalidRequestId;
                break;
              case FDCreateWithdrawRequestErrors.CanModifyOnlySelfRequest:
                store.error =
                  FDCreateWithdrawRequestErrors.CanModifyOnlySelfRequest;
                break;
              case FDCreateWithdrawRequestErrors.DealNotActive:
                store.error = FDCreateWithdrawRequestErrors.DealNotActive;
                break;
              case FDCreateWithdrawRequestErrors.InvalidWithdrawAmount:
                store.fields.setShowWarning(
                  i18next.t("fd.dealRequest.errors.withdrawWarningMessage"),
                );
                break;
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in createWithdrawRequestRPC`,
                );
            }
          } else {
            logger.error("Unhandled error");
          }
        }
      }),
    };
  });

export const createCreateWithdrawRequestStore = (): Instance<
  typeof CreateWithdrawRequestStore
> => {
  return CreateWithdrawRequestStore.create({
    fields: createFDWithdrawModel(),
  });
};
