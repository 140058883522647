import { flow, Instance, types, getEnv, getParent } from "mobx-state-tree";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { FiContractNoteDetailErrors } from "./FiContractNoteDetailsErrors";
import {
  useCheckFiContractNoteRequestRPCClientImpl,
  useFiSubmitContractNoteDiscardRequestRPCClientImpl,
  useFiSubmitContractNoteLinkRequestRPCClientImpl,
  useFiSubmitContractNoteUnknownRequestRPCClientImpl,
} from "../rpcs/RPC";
import {
  FiContractNoteCheckType,
  CheckResponse,
  CheckFiContractNoteRequestRPC,
  FiSubmitContractNoteDiscardRequestRPC,
  FiSubmitContractNoteUnknownRequestRPC,
  FiSubmitContractNoteLinkRequestRPC,
  FiHoldingCategory,
} from "@khazana/khazana-rpcs";
import { getAPIClient, LeoErrors } from "@khazana/khazana-boilerplate";
import { FiContractNoteDetailsStore } from "./FiContractNoteDetailsStore";
import { createServerNoteRPCType } from "../../../../../utils";

export const AllowedActions = types.model({
  allowEdit: types.optional(types.boolean, false),
  allowReviewRequest: types.optional(types.boolean, false),
  allowDiscardRequest: types.optional(types.boolean, false),
  allowUnknownRequest: types.optional(types.boolean, false),
  allowLinkRequest: types.optional(types.boolean, false),
  allowContractCheck: types.optional(types.boolean, false),
  allowLinkCheck: types.optional(types.boolean, false),
  allowDiscardCheck: types.optional(types.boolean, false),
  allowUnknownCheck: types.optional(types.boolean, false),
});

export const FiContractNoteCheckResponseStore = types
  .model({
    allowedActions: AllowedActions,
  })
  .actions((store) => ({
    resetStore(): void {
      store.allowedActions = AllowedActions.create();
    },
    checkContractNote: flow(function* (
      response: CheckResponse,
      fiContractNoteHistoryId: number,
      holdingCategory: FiHoldingCategory.FiHoldingCategory,
    ) {
      const logger = getEnv(store).logger;
      let type: FiContractNoteCheckType.FiContractNoteCheckType | undefined;
      if (store.allowedActions.allowContractCheck) {
        type = new FiContractNoteCheckType.ReviewRequest();
      }
      if (store.allowedActions.allowLinkCheck) {
        type = new FiContractNoteCheckType.LinkRequest(holdingCategory);
      }
      if (store.allowedActions.allowDiscardCheck) {
        type = new FiContractNoteCheckType.DiscardRequest();
      }
      if (store.allowedActions.allowUnknownCheck) {
        type = new FiContractNoteCheckType.UnknownRequest();
      }

      if (!type) {
        logger.error("FiContractNoteCheckType is unset");
        return;
      }
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new CheckFiContractNoteRequestRPC.Request(
          type,
          fiContractNoteHistoryId,
          response,
        );
        const result: LeoRPCResult<
          CheckFiContractNoteRequestRPC.Response,
          CheckFiContractNoteRequestRPC.Errors.Errors
        > =
          yield useCheckFiContractNoteRequestRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FiContractNoteDetailErrors.InvalidContractNoteID:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            case FiContractNoteDetailErrors.IllegalContractNoteState:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.IllegalContractNoteState,
              );
              break;
            case FiContractNoteDetailErrors.InvalidDealRequestState:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidDealRequestState,
              );
              break;
            case FiContractNoteDetailErrors.CannotCheckSelfRequest:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.CannotCheckSelfRequest,
              );
              break;
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error occurred in CheckContractNoteReviewRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            default:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.Unknown,
              );
              logger.error(
                `Unhandled error: ${error} occurred in CheckContractNoteReviewRequestRPC`,
              );
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error: ${error} occurred in CheckContractNoteReviewRequestRPC`,
          );
        }
      }
    }),
    submitContractNoteLinkRequest: flow(function* (
      fiContractNoteHistoryId: number,
      dealRequestId: number,
      note: string | undefined,
    ) {
      const logger = getEnv(store).logger;
      try {
        const request = new FiSubmitContractNoteLinkRequestRPC.Request(
          fiContractNoteHistoryId,
          dealRequestId,
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          FiSubmitContractNoteLinkRequestRPC.Response,
          FiSubmitContractNoteLinkRequestRPC.Errors.Errors
        > =
          yield useFiSubmitContractNoteLinkRequestRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FiContractNoteDetailErrors.InvalidContractNoteID:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            case FiContractNoteDetailErrors.IllegalContractNoteState:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.IllegalContractNoteState,
              );
              break;
            case FiContractNoteDetailErrors.InvalidDealRequestId:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidDealRequestId,
              );
              break;
            case FiContractNoteDetailErrors.ContractNoteAlreadyLinked:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.ContractNoteAlreadyLinked,
              );
              break;
            case FiContractNoteDetailErrors.LinkAlreadyExists:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.LinkAlreadyExists,
              );
              break;
            case FiContractNoteDetailErrors.DealValueExceeded:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.DealValueExceeded,
              );
              break;
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error occurred in SubmitContractNoteRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            default:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.Unknown,
              );
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteLinkRequestRPC`,
              );
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error: ${error} occurred in submitContractNoteLinkRequestRPC`,
          );
        }
      }
    }),
    submitContractNoteUnknownRequest: flow(function* (
      note: string | undefined,
      fiContractNoteHistoryId: number,
    ) {
      const logger = getEnv(store).logger;
      try {
        const request = new FiSubmitContractNoteUnknownRequestRPC.Request(
          fiContractNoteHistoryId,
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          FiSubmitContractNoteUnknownRequestRPC.Response,
          FiSubmitContractNoteUnknownRequestRPC.Errors.Errors
        > =
          yield useFiSubmitContractNoteUnknownRequestRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FiContractNoteDetailErrors.InvalidContractNoteID:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            case FiContractNoteDetailErrors.IllegalContractNoteState:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.IllegalContractNoteState,
              );
              break;
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error occurred in SubmitContractNoteUnknownRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            default:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.Unknown,
              );
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
              );
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
          );
        }
      }
    }),
    submitContractNoteDiscardRequest: flow(function* (
      note: string | undefined,
      fiContractNoteHistoryId: number,
    ) {
      const logger = getEnv(store).logger;
      try {
        const request = new FiSubmitContractNoteDiscardRequestRPC.Request(
          fiContractNoteHistoryId,
          createServerNoteRPCType(note),
        );
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          FiSubmitContractNoteDiscardRequestRPC.Response,
          FiSubmitContractNoteDiscardRequestRPC.Errors.Errors
        > =
          yield useFiSubmitContractNoteDiscardRequestRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FiContractNoteDetailErrors.InvalidContractNoteID:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            case FiContractNoteDetailErrors.IllegalContractNoteState:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.IllegalContractNoteState,
              );
              break;
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error occurred in SubmitContractNoteUnknownRequestRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.InvalidContractNoteID,
              );
              break;
            default:
              getParent<typeof FiContractNoteDetailsStore>(store).setError(
                FiContractNoteDetailErrors.Unknown,
              );
              logger.error(
                `Unhandled error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
              );
          }
        } else {
          getParent<typeof FiContractNoteDetailsStore>(store).setError(
            FiContractNoteDetailErrors.Unknown,
          );
          logger.error(
            `Unknown error: ${error} occurred in SubmitContractNoteUnknownRequestRPC`,
          );
        }
      }
    }),
  }));

export const createFiContractNoteCheckResponseStore = (): Instance<
  typeof FiContractNoteCheckResponseStore
> => {
  return FiContractNoteCheckResponseStore.create({
    allowedActions: AllowedActions.create(),
  });
};
