import { Instance, types } from "mobx-state-tree";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import {
  createFiMaturityChargeModel,
  FiMaturityChargeModel,
} from "./FiMaturityChargeModel";
import { GetFiMaturityTransactionDetailsRPC } from "@khazana/khazana-rpcs";

export const FiMaturityTransactionDetails = types.model(
  "FiMaturityTransactionDetails",
  {
    maturedUnits: types.string,
    maturedAmount: types.string,
    currency: CurrencyModel,
    couponReceivedOnMaturity: types.maybe(types.string),
    charges: types.array(FiMaturityChargeModel),
    totalSettlementAmount: types.string,
  },
);

export const createFiMaturityTransactionDetails = (
  transactionDetail: GetFiMaturityTransactionDetailsRPC.Response,
): Instance<typeof FiMaturityTransactionDetails> => {
  return FiMaturityTransactionDetails.create({
    maturedUnits: transactionDetail.maturedUnits.decimalValue,
    maturedAmount: transactionDetail.maturedAmount.decimalValue,
    currency: createCurrencyModel(transactionDetail.currency),
    couponReceivedOnMaturity:
      transactionDetail.couponReceivedOnMaturity.decimalValue,
    charges: transactionDetail.charges.map((charge) =>
      createFiMaturityChargeModel(charge),
    ),
    totalSettlementAmount: transactionDetail.totalSettlementAmount.decimalValue,
  });
};
