import {
  BigAmount,
  BigDecimal,
  CashflowTimelineAmount,
  CashflowTimelineItem,
  Currency,
  DetailCell,
  DetailCellType,
  GetFiBuyDealSummaryDetailsRPC,
  SummaryDetailCell,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

const securityDetails = [
  new SummaryDetailCell(
    new DetailCell(
      "fi.securityDetails.faceValueLabel",
      new DetailCellType.BigAmountType(
        new BigAmount(new BigDecimal("1000000"), new Currency("INR", "₹")),
      ),
    ),
    null,
    false,
    true,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.securityDetails.issueDateLabel",
      new DetailCellType.Date(new LeoDate()),
    ),
    null,
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.securityDetails.perpetualLabel",
      new DetailCellType.Unstyled("No", false),
    ),
    null,
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.securityDetails.maturityDateLabel",
      new DetailCellType.Date(new LeoDate()),
    ),
    null,
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.securityDetails.nextCallDateLabel",
      new DetailCellType.Date(new LeoDate()),
    ),
    null,
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.securityDetails.couponRateLabel",
      new DetailCellType.PercentageType(86000),
    ),
    null,
    false,
    true,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.securityDetails.creditRatingLabel",
      new DetailCellType.Unstyled("AA Crisil", false),
    ),
    null,
    false,
    true,
  ),
];

const transactionDetails = [
  new SummaryDetailCell(
    new DetailCell(
      "fi.createDealRequest.priceLabel",
      new DetailCellType.BigAmountType(
        new BigAmount(new BigDecimal("1000000"), new Currency("INR", "₹")),
      ),
    ),
    null,
    false,
    true,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.manageDealRequest.ytm",
      new DetailCellType.PercentageType(71500),
    ),
    null,
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.createDealRequest.accruedInterest",
      new DetailCellType.BigAmountType(
        new BigAmount(new BigDecimal("1000000"), new Currency("INR", "₹")),
      ),
    ),
    null,
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell("common.approxChargesAndTaxes", new DetailCellType.None()),
    [
      new DetailCell(
        "common.stampDuty",
        new DetailCellType.BigAmountType(
          new BigAmount(new BigDecimal("0.11"), new Currency("INR", "₹")),
        ),
      ),
    ],
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "fi.createDealRequest.totalApproxCharges",
      new DetailCellType.BigAmountType(
        new BigAmount(new BigDecimal("0.11"), new Currency("INR", "₹")),
      ),
    ),
    null,
    false,
    false,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "common.grossAmount",
      new DetailCellType.BigAmountType(
        new BigAmount(new BigDecimal("106825.70"), new Currency("INR", "₹")),
      ),
    ),
    null,
    false,
    true,
  ),
  new SummaryDetailCell(
    new DetailCell(
      "common.maxPayableAmount",
      new DetailCellType.BigAmountType(
        new BigAmount(new BigDecimal("106848.90"), new Currency("INR", "₹")),
      ),
    ),
    null,
    true,
    true,
  ),
];

const cashFlowDetails = [
  new CashflowTimelineItem(new LeoDate(), [
    new CashflowTimelineAmount(
      "cashflow.timeline.totalInvestmentLabel",
      new BigAmount(new BigDecimal("296912.92"), new Currency("INR", "₹")),
      null,
    ),
  ]),
  new CashflowTimelineItem(new LeoDate(), [
    new CashflowTimelineAmount(
      "cashflow.timeline.couponLabel",
      new BigAmount(new BigDecimal("1477.80", true), new Currency("INR", "₹")),
      null,
    ),
    new CashflowTimelineAmount(
      "cashflow.timeline.principalLabel",
      new BigAmount(new BigDecimal("1200.00", true), new Currency("INR", "₹")),
      null,
    ),
  ]),
  new CashflowTimelineItem(new LeoDate(), [
    new CashflowTimelineAmount(
      "cashflow.timeline.couponLabel",
      new BigAmount(new BigDecimal("1477.80"), new Currency("INR", "₹")),
      null,
    ),
  ]),
  new CashflowTimelineItem(new LeoDate(), [
    new CashflowTimelineAmount(
      "cashflow.timeline.couponLabel",
      new BigAmount(new BigDecimal("1477.80"), new Currency("INR", "₹")),
      null,
    ),
  ]),
  new CashflowTimelineItem(new LeoDate(), [
    new CashflowTimelineAmount(
      "cashflow.timeline.couponLabel",
      new BigAmount(new BigDecimal("1477.80"), new Currency("INR", "₹")),
      null,
    ),
  ]),
  new CashflowTimelineItem(new LeoDate(), [
    new CashflowTimelineAmount(
      "cashflow.timeline.maturityValueLabel",
      new BigAmount(new BigDecimal("738.90"), new Currency("INR", "₹")),
      null,
    ),
  ]),
];
export class MockGetFiBuyDealSummaryDetailsRPCImpl extends GetFiBuyDealSummaryDetailsRPC {
  execute(
    _request: GetFiBuyDealSummaryDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiBuyDealSummaryDetailsRPC.Response,
      GetFiBuyDealSummaryDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetFiBuyDealSummaryDetailsRPC.Response(
      _request.dealValue && _request.priceYtm ? transactionDetails : [],
      _request.isin ? securityDetails : [],
      _request.dealValue && _request.priceYtm ? cashFlowDetails : [],
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiBuyDealSummaryDetailsRPC.Response,
        GetFiBuyDealSummaryDetailsRPC.Errors.Errors
      >
    >;
  }
}
