import {
  Instance,
  cast,
  flow,
  types,
  getEnv,
  getSnapshot,
  applySnapshot,
} from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  GetFiMaturityRequestDetailsRPC,
  GetFiMaturityRequestDetailsBannerInfoRPC,
  GetFiMaturityRequestHistoryRPC,
  ModuleType,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import {
  FiMaturityRequestHistoryDetailModel,
  createFiMaturityRequestHistoryDetailModel,
} from "../models/FiMaturityRequestHistoryDetailModel";
import {
  useGetFiMaturityRequestDetailsBannerInfoRPCClientImpl,
  useGetFiMaturityRequestDetailsRPCClientImpl,
  useGetFiMaturityRequestHistoryRPCClientImpl,
} from "../rpcs/RPC";
import {
  FiInvestmentDetailsStore,
  createFiInvestmentDetailsStore,
} from "../../components/investment-details/FiInvestmentDetailsStore";
import {
  InvestmentCashflowBannerStore,
  createInvestmentCashflowBannerStore,
} from "../../../components/cash-flow-summary/InvestmentCashflowBannerStore";
import { DetailType } from "../../../models/DetailCellModel";

export enum ViewFiMaturityInvalidRequestError {
  InvalidRequestId = "INVALID_REQUEST_ID",
}

export const ViewFiMaturityRequestDetailsStore = types
  .model("ViewFiMaturityRequestDetailsStore", {
    isSettlementAllowed: types.optional(types.boolean, false),
    dealDetails: types.array(DetailType),
    currencySymbol: types.maybeNull(types.string),
    error: types.maybeNull(
      types.union(
        types.enumeration<ViewFiMaturityInvalidRequestError>(
          "ViewFiMaturityInvalidRequestError",
          Object.values(ViewFiMaturityInvalidRequestError),
        ),
      ),
    ),
    fiDealRequestDetailsBannerInfo: types.maybeNull(
      FiMaturityRequestHistoryDetailModel,
    ),
    fiDealRequestHistoryDetail: types.array(
      FiMaturityRequestHistoryDetailModel,
    ),
    investmentDetailsStore: FiInvestmentDetailsStore,
    investmentDetailsBannerStore: InvestmentCashflowBannerStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      resetStore: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    setCurrencySymbol(currencySymbol: string): void {
      store.currencySymbol = currencySymbol;
    },
    resetError(): void {
      store.error = undefined;
    },
    getFiMaturityRequestDetails: flow(function* (requestId: number) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiMaturityRequestDetailsRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFiMaturityRequestDetailsRPC.Response,
          GetFiMaturityRequestDetailsRPC.Errors.Errors
        > =
          yield useGetFiMaturityRequestDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.isSettlementAllowed = response.allowSettlement;
          store.dealDetails = cast(response.requestDetails);
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiMaturityInvalidRequestError.InvalidRequestId:
              store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
              break;
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiMaturityRequestDetailsRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiMaturityRequestDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiMaturityRequestDetailsRPC`,
          );
        }
      }
    }),
    getFiMaturityRequestDetailsBannerInfo: flow(function* (requestId: number) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiMaturityRequestDetailsBannerInfoRPC.Request(
          requestId,
        );
        const result: LeoRPCResult<
          GetFiMaturityRequestDetailsBannerInfoRPC.Response,
          GetFiMaturityRequestDetailsBannerInfoRPC.Errors.Errors
        > =
          yield useGetFiMaturityRequestDetailsBannerInfoRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fiDealRequestDetailsBannerInfo =
            createFiMaturityRequestHistoryDetailModel(response.requestDetails);
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiMaturityInvalidRequestError.InvalidRequestId:
              store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
              break;
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiMaturityRequestDetailsBannerInfoRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiMaturityRequestDetailsBannerInfoRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiMaturityRequestDetailsBannerInfoRPC`,
          );
        }
      }
    }),
    getFiMaturityRequestHistory: flow(function* (requestId: number) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiMaturityRequestHistoryRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFiMaturityRequestHistoryRPC.Response,
          GetFiMaturityRequestHistoryRPC.Errors.Errors
        > =
          yield useGetFiMaturityRequestHistoryRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.fiDealRequestHistoryDetail = cast(
            response.requestDetails.map((detail) =>
              createFiMaturityRequestHistoryDetailModel(detail),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiMaturityInvalidRequestError.InvalidRequestId:
              store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiMaturityRequestDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiMaturityRequestHistoryRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiMaturityRequestHistoryRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiMaturityRequestHistoryRPC`,
          );
        }
      }
    }),
    getInvestmentBannerData: flow(function* (requestId: number) {
      yield store.investmentDetailsBannerStore.getInvestmentCashflowBannerDetails(
        undefined,
        requestId,
        ModuleType.ModuleType.FIXED_INCOME,
      );
      if (store.investmentDetailsBannerStore.error) {
        store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
      }
    }),
    getInvestmentDetails: flow(function* (requestId: number) {
      yield store.investmentDetailsStore.getInvestmentDetails(
        undefined,
        undefined,
        undefined,
        requestId,
      );
      if (store.investmentDetailsStore.error) {
        store.error = ViewFiMaturityInvalidRequestError.InvalidRequestId;
      }
    }),
  }));

export const createViewFiMaturityRequestDetailsStore = (): Instance<
  typeof ViewFiMaturityRequestDetailsStore
> => {
  return ViewFiMaturityRequestDetailsStore.create({
    investmentDetailsStore: createFiInvestmentDetailsStore(),
    investmentDetailsBannerStore: createInvestmentCashflowBannerStore(),
  });
};
