import React, { useRef } from "react";
import { DropdownListItem } from "./DropdownListItem";
import { checkSidebarOverflow } from "./SidebarUtils";
import { Box, styled } from "@mui/material";
import { ListItem } from "./ListItem";
import {
  ListItemProps,
  SubListItemProps,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";

interface SidebarMenuProps {
  list: ListItemProps[];
  selectedItemList: boolean[];
  setSelectedItemList: (dic: boolean[]) => void;
  onSidebarOverflow: (isOverflow: boolean) => void;
  currentPath: string;
  setCurrentPath: (path: string) => void;
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}

export const SidebarMenu = ({
  list,
  selectedItemList,
  setSelectedItemList,
  onSidebarOverflow,
  currentPath,
  setCurrentPath,
  isCollapsed,
  setIsCollapsed,
}: SidebarMenuProps): React.ReactElement => {
  const spacing = useSpacing();
  const defaultSelectedItemList = new Array(Object.keys(list).length).fill(
    false,
  );
  const ref = useRef<HTMLDivElement>(null);
  checkSidebarOverflow(ref, onSidebarOverflow);

  const StyledTopMenuList = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    padding: spacing.spaceXS,
    marginTop: spacing.space2XL,
    order: 1,
    flexGrow: 1,
    zIndex: 1,
    overflowX: "hidden",
    gap: spacing.spaceXS,
    overflowY: "scroll",
    "&::-webkit-scrollbar": { display: "none" },
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  }));

  const handleDropdownSelect = (index: number): void => {
    const updatedSelectedList = [...selectedItemList];
    updatedSelectedList[index] = !updatedSelectedList[index];
    setSelectedItemList(updatedSelectedList);
  };

  const handleSubListItemSelect = (index: number): void => {
    const updatedSelectedList = [...defaultSelectedItemList];
    updatedSelectedList[index] = true;
    setSelectedItemList(updatedSelectedList);
  };

  // Function to expand the dropdown item
  const isDropdownExpandable = (
    subListItemList: SubListItemProps[],
    index: number,
  ): boolean => {
    return (
      subListItemList.some((item) => currentPath.includes(item.path)) ||
      selectedItemList[index]
    );
  };

  return (
    <StyledTopMenuList ref={ref}>
      {list.map((listItem, index) => {
        return listItem.subList ? (
          <DropdownListItem
            key={index}
            listItem={listItem}
            isExpanded={isDropdownExpandable(listItem.subList, index)}
            onSubListItemSelect={(path: string): void => {
              handleSubListItemSelect(index);
              setCurrentPath(path);
            }}
            onSelect={(): void => {
              setIsCollapsed(false);
              handleDropdownSelect(index);
            }}
            currentPath={currentPath}
            isCollapsed={isCollapsed}
          />
        ) : (
          <ListItem
            key={index}
            listItem={listItem}
            onSelect={(path: string): void => {
              setIsCollapsed(false);
              setSelectedItemList([...defaultSelectedItemList]);
              setCurrentPath(path);
            }}
            currentPath={currentPath}
            isCollapsed={isCollapsed}
          />
        );
      })}
    </StyledTopMenuList>
  );
};
