import { Broker } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";
import {
  CurrencyModel,
  createCurrencyModel,
  createCurrencyRPCType,
} from "./CurrencyModel";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";

export const BrokerModel = types
  .model({
    id: types.string,
    name: types.string,
    currency: CurrencyModel,
  })
  .actions((store) => ({
    getBrokerRPCType: (): Broker => {
      return new Broker(
        new LeoUUID(store.id),
        store.name,
        createCurrencyRPCType(store.currency),
      );
    },
  }));

export const createBrokerModel = ({
  id,
  name,
  currency,
}: Broker): Instance<typeof BrokerModel> => {
  return BrokerModel.create({
    id: id.uuid,
    name,
    currency: createCurrencyModel(currency),
  });
};
