"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquitySettlementPriceDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
class InvalidEquitySettlementPriceDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquitySettlementPriceDetailsError";
    }
}
class EquitySettlementPriceDetails {
    constructor(requestedPricePerUnit = null, executedPricePerUnit) {
        this.isEquitySettlementPriceDetails = true;
        if (requestedPricePerUnit !== undefined && requestedPricePerUnit !== null) {
            EquitySettlementPriceDetails.validateRequestedPricePerUnit(requestedPricePerUnit);
        }
        this.requestedPricePerUnit = requestedPricePerUnit;
        EquitySettlementPriceDetails.validateExecutedPricePerUnit(executedPricePerUnit);
        this.executedPricePerUnit = executedPricePerUnit;
    }
    static fromDTO(dto) {
        let requestedPricePerUnit = (0, leo_ts_runtime_1.getOptionalObject)(dto, "requestedPricePerUnit");
        if (requestedPricePerUnit !== undefined && requestedPricePerUnit !== null) {
            requestedPricePerUnit = transactionAmount_1.TransactionAmount.fromDTO(requestedPricePerUnit);
        }
        const executedPricePerUnit = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedPricePerUnit", InvalidEquitySettlementPriceDetailsError));
        return new EquitySettlementPriceDetails(requestedPricePerUnit, executedPricePerUnit);
    }
    toDTO() {
        const ret = {
            executedPricePerUnit: this.executedPricePerUnit.toDTO(),
        };
        if (this.requestedPricePerUnit) {
            ret.requestedPricePerUnit = this.requestedPricePerUnit.toDTO();
        }
        return ret;
    }
    copy(requestedPricePerUnit = this.requestedPricePerUnit, executedPricePerUnit = this.executedPricePerUnit) {
        return new EquitySettlementPriceDetails(requestedPricePerUnit, executedPricePerUnit);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquitySettlementPriceDetailsValue = other.isEquitySettlementPriceDetails;
        if (!(other instanceof EquitySettlementPriceDetails) || Boolean(isEquitySettlementPriceDetailsValue)) {
            return false;
        }
        if (this.requestedPricePerUnit) {
            if (!this.requestedPricePerUnit.equals(other.requestedPricePerUnit)) {
                return false;
            }
        }
        if (!this.executedPricePerUnit.equals(other.executedPricePerUnit)) {
            return false;
        }
        return true;
    }
}
exports.EquitySettlementPriceDetails = EquitySettlementPriceDetails;
EquitySettlementPriceDetails.validateRequestedPricePerUnit = (requestedPricePerUnit) => {
    const isTransactionAmountValue = requestedPricePerUnit.isTransactionAmount;
    if (!(requestedPricePerUnit instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidEquitySettlementPriceDetailsError(`Attribute requestedPricePerUnit is not a TransactionAmount`);
    }
};
EquitySettlementPriceDetails.validateExecutedPricePerUnit = (executedPricePerUnit) => {
    const isTransactionAmountValue = executedPricePerUnit.isTransactionAmount;
    if (!(executedPricePerUnit instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidEquitySettlementPriceDetailsError(`Attribute executedPricePerUnit is not a TransactionAmount`);
    }
};
EquitySettlementPriceDetails.prototype.toString = function toString() {
    return `EquitySettlementPriceDetails(requestedPricePerUnit=${this.requestedPricePerUnit},executedPricePerUnit=${this.executedPricePerUnit})`;
};
