import { types } from "mobx-state-tree";
import { MFConfirmationStatus } from "./MFConfirmationStatus";

export const MFConfirmationModel = types.model("MFConfirmationModel", {
  requestId: types.number,
  status: MFConfirmationStatus,
  brokerName: types.maybe(types.string),
  amcName: types.string,
  schemeName: types.string,
  referenceNumber: types.maybe(types.string),
  amount: types.string,
  receivedAt: types.Date,
});
