"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettledCashflows = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigAmount_1 = require("./bigAmount");
class InvalidSettledCashflowsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidSettledCashflowsError";
    }
}
class SettledCashflows {
    constructor(couponReceived = null, couponTds = null, principalReceived = null, principalTds = null) {
        this.isSettledCashflows = true;
        if (couponReceived !== undefined && couponReceived !== null) {
            SettledCashflows.validateCouponReceived(couponReceived);
        }
        this.couponReceived = couponReceived;
        if (couponTds !== undefined && couponTds !== null) {
            SettledCashflows.validateCouponTds(couponTds);
        }
        this.couponTds = couponTds;
        if (principalReceived !== undefined && principalReceived !== null) {
            SettledCashflows.validatePrincipalReceived(principalReceived);
        }
        this.principalReceived = principalReceived;
        if (principalTds !== undefined && principalTds !== null) {
            SettledCashflows.validatePrincipalTds(principalTds);
        }
        this.principalTds = principalTds;
    }
    static fromDTO(dto) {
        let couponReceived = (0, leo_ts_runtime_1.getOptionalObject)(dto, "couponReceived");
        if (couponReceived !== undefined && couponReceived !== null) {
            couponReceived = bigAmount_1.BigAmount.fromDTO(couponReceived);
        }
        let couponTds = (0, leo_ts_runtime_1.getOptionalObject)(dto, "couponTds");
        if (couponTds !== undefined && couponTds !== null) {
            couponTds = bigAmount_1.BigAmount.fromDTO(couponTds);
        }
        let principalReceived = (0, leo_ts_runtime_1.getOptionalObject)(dto, "principalReceived");
        if (principalReceived !== undefined && principalReceived !== null) {
            principalReceived = bigAmount_1.BigAmount.fromDTO(principalReceived);
        }
        let principalTds = (0, leo_ts_runtime_1.getOptionalObject)(dto, "principalTds");
        if (principalTds !== undefined && principalTds !== null) {
            principalTds = bigAmount_1.BigAmount.fromDTO(principalTds);
        }
        return new SettledCashflows(couponReceived, couponTds, principalReceived, principalTds);
    }
    toDTO() {
        const ret = {};
        if (this.couponReceived) {
            ret.couponReceived = this.couponReceived.toDTO();
        }
        if (this.couponTds) {
            ret.couponTds = this.couponTds.toDTO();
        }
        if (this.principalReceived) {
            ret.principalReceived = this.principalReceived.toDTO();
        }
        if (this.principalTds) {
            ret.principalTds = this.principalTds.toDTO();
        }
        return ret;
    }
    copy(couponReceived = this.couponReceived, couponTds = this.couponTds, principalReceived = this.principalReceived, principalTds = this.principalTds) {
        return new SettledCashflows(couponReceived, couponTds, principalReceived, principalTds);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isSettledCashflowsValue = other.isSettledCashflows;
        if (!(other instanceof SettledCashflows) || Boolean(isSettledCashflowsValue)) {
            return false;
        }
        if (this.couponReceived) {
            if (!this.couponReceived.equals(other.couponReceived)) {
                return false;
            }
        }
        if (this.couponTds) {
            if (!this.couponTds.equals(other.couponTds)) {
                return false;
            }
        }
        if (this.principalReceived) {
            if (!this.principalReceived.equals(other.principalReceived)) {
                return false;
            }
        }
        if (this.principalTds) {
            if (!this.principalTds.equals(other.principalTds)) {
                return false;
            }
        }
        return true;
    }
}
exports.SettledCashflows = SettledCashflows;
SettledCashflows.validateCouponReceived = (couponReceived) => {
    const isBigAmountValue = couponReceived.isBigAmount;
    if (!(couponReceived instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidSettledCashflowsError(`Attribute couponReceived is not a BigAmount`);
    }
};
SettledCashflows.validateCouponTds = (couponTds) => {
    const isBigAmountValue = couponTds.isBigAmount;
    if (!(couponTds instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidSettledCashflowsError(`Attribute couponTds is not a BigAmount`);
    }
};
SettledCashflows.validatePrincipalReceived = (principalReceived) => {
    const isBigAmountValue = principalReceived.isBigAmount;
    if (!(principalReceived instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidSettledCashflowsError(`Attribute principalReceived is not a BigAmount`);
    }
};
SettledCashflows.validatePrincipalTds = (principalTds) => {
    const isBigAmountValue = principalTds.isBigAmount;
    if (!(principalTds instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidSettledCashflowsError(`Attribute principalTds is not a BigAmount`);
    }
};
SettledCashflows.prototype.toString = function toString() {
    return `SettledCashflows(couponReceived=${this.couponReceived},couponTds=${this.couponTds},principalReceived=${this.principalReceived},principalTds=${this.principalTds})`;
};
