import React from "react";
import {
  cornerRadius,
  TypographyProperties,
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useSpacing } from "@surya-digital/leo-reactjs-core";

const Size = {
  mediumHeight: "48px",
  smallHeight: "32px",
};
export interface ToggleButtonItems {
  value: string;
  label: string | React.ReactElement;
}

export interface ToggleButtonProps {
  items: ToggleButtonItems[];
  label?: string;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (event: React.MouseEvent<HTMLElement>, value: string) => void;
  size?: "small" | "medium";
}

export const KHToggleButton = ({
  items,
  label,
  required,
  value,
  disabled,
  onChange,
  size = "medium",
}: ToggleButtonProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();
  const getTextSize = (): TypographyProperties => {
    if (size === "small") {
      return typography.b3;
    } else {
      return typography.b1;
    }
  };

  return (
    <Stack spacing={spacing.spaceXXS} flexGrow={1}>
      {label && (
        <Typography
          sx={{
            ...typography.s2,
            color: disabled ? tokens.labelLowEmphasis : tokens.label,
          }}
        >
          {label} {required && "*"}
        </Typography>
      )}
      <ToggleButtonGroup
        value={value}
        exclusive
        disabled={disabled}
        sx={{
          flexGrow: 1,
          height: size === "small" ? Size.smallHeight : Size.mediumHeight,
          borderRadius: cornerRadius.radiusXS,
          "& .MuiToggleButton-root": {
            borderRadius: cornerRadius.radiusXS,
            textTransform: "none",
          },
          "& .Mui-selected": {
            color: `${
              disabled ? tokens.labelLowEmphasis : tokens.labelPrimary
            } !important`,
            backgroundColor: `${
              disabled
                ? tokens.backgroundLowEmphasis
                : tokens.backgroundSelected
            } !important`,
          },
          backgroundColor: `${tokens.backgroundElevatedLevel1} !important`,
          justifyContent: "space-evenly",
        }}
        onChange={onChange}
      >
        {items.map((item) => (
          <ToggleButton
            key={item.value}
            value={item.value}
            sx={{ width: "max-content", flexGrow: 1 }}
          >
            {typeof item.label === "string" ? (
              <Typography sx={{ typography: getTextSize() }}>
                {item.label}
              </Typography>
            ) : (
              item.label
            )}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Stack>
  );
};
