import {
  Currency,
  GetMFSchemeListRPC,
  ISIN,
  MFScheme,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFSchemeListRPCImpl extends GetMFSchemeListRPC {
  execute(
    _request: GetMFSchemeListRPC.Request,
  ): Promise<
    LeoRPCResult<GetMFSchemeListRPC.Response, GetMFSchemeListRPC.Errors.Errors>
  > {
    const response = new GetMFSchemeListRPC.Response([
      new MFScheme(
        new ISIN("INF223J01OT1"),
        "PGIM India Short Duration Fund - Direct Plan",
        new Currency("INR", "₹"),
        30,
      ),
      new MFScheme(
        new ISIN("INF761K01439"),
        "Bank of India Conservative Hybrid Fund - Eco Plan",
        new Currency("INR", "₹"),
        5,
      ),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFSchemeListRPC.Response,
        GetMFSchemeListRPC.Errors.Errors
      >
    >;
  }
}
