import { cast, flow, Instance, types, getEnv } from "mobx-state-tree";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { FiContractNoteDetailType } from "../models/FiContractNoteDetailType";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { createContractNoteEditModel } from "../models/FiContractNoteEditModel";
import { FiContractNoteDetailErrors } from "./FiContractNoteDetailsErrors";
import { useGetFiContractNoteDetailsRPCClientImpl } from "../rpcs/RPC";
import {
  createSecurityModel,
  SecurityModel,
} from "../../../models/SecurityModel";
import {
  CheckResponse,
  FiContractNoteRequestStatus,
  FiHoldingCategory,
  FiSecurity,
  GetFiContractNoteDetailsRPC,
} from "@khazana/khazana-rpcs";
import { getAPIClient, LeoErrors } from "@khazana/khazana-boilerplate";
import {
  createFiContractNoteValidateStore,
  FiContractNoteValidateStore,
} from "./FiContractNoteValidateStore";
import {
  ViewFiContractNoteHistoryStore,
  createViewFiContractNoteHistoryStore,
} from "./ViewFiContractNoteHistoryStore";
import {
  AllowedActions,
  FiContractNoteCheckResponseStore,
  createFiContractNoteCheckResponseStore,
} from "./FiContractNoteCheckRequestStore";
import { FiLinkDealRequestStore } from "./FiLinkDealRequestStore";
import {
  FiContractNoteChargeType,
  createFiContractNoteChargeType,
} from "../models/FiContractNoteChargeType";
import {
  FiInvestmentDetailsStore,
  createFiInvestmentDetailsStore,
} from "../../components/investment-details/FiInvestmentDetailsStore";

export const ContractNoteDetailHeader = types.model({
  originalDataHeader: types.string,
  diffDataHeader: types.string,
});

export const FiContractNoteDetailsStore = types
  .model({
    status: types.maybe(
      types.enumeration(
        "FiContractNoteRequestStatus",
        Object.values(FiContractNoteRequestStatus.FiContractNoteRequestStatus),
      ),
    ),
    brokerId: types.maybe(types.string),
    entityId: types.maybe(types.string),
    security: types.maybe(SecurityModel),
    contractNoteUrl: types.maybe(types.string),
    columnHeaders: types.maybe(ContractNoteDetailHeader),
    fiContractNoteHistoryId: types.maybe(types.number),
    tempContractNoteId: types.maybe(types.number),
    currency: types.maybe(CurrencyModel),
    details: types.array(FiContractNoteDetailType),
    charges: types.array(FiContractNoteChargeType),
    validateStore: FiContractNoteValidateStore,
    checkRequestStore: FiContractNoteCheckResponseStore,
    linkDealStore: FiLinkDealRequestStore,
    investmentDetailsStore: FiInvestmentDetailsStore,
    error: types.maybeNull(
      types.union(
        types.enumeration(
          "ContractNoteDetailErrors",
          Object.values(FiContractNoteDetailErrors),
        ),
        types.enumeration("LeoErrors", Object.values(LeoErrors)),
      ),
    ),
    holdingCategory: types.optional(
      types.enumeration(
        "FiHoldingCategory",
        Object.values(FiHoldingCategory.FiHoldingCategory),
      ),
      FiHoldingCategory.FiHoldingCategory.HTM,
    ),
    viewContractNoteHistoryStore: ViewFiContractNoteHistoryStore,
  })
  .actions((store) => ({
    createEdit(): void {
      store.validateStore.editDetails = createContractNoteEditModel({
        brokerId: undefined,
        entityId: undefined,
        isin: undefined,
        securityName: undefined,
        fields: [],
        charges: [],
        currency: undefined,
      });
    },
    removeError(): void {
      store.error = null;
    },
    setError(error: FiContractNoteDetailErrors | LeoErrors | null): void {
      store.error = error;
    },
    setHoldingCategory(value: string): void {
      switch (value) {
        case FiHoldingCategory.FiHoldingCategory.AFS:
          store.holdingCategory = FiHoldingCategory.FiHoldingCategory.AFS;
          break;
        case FiHoldingCategory.FiHoldingCategory.HFT:
          store.holdingCategory = FiHoldingCategory.FiHoldingCategory.HFT;
          break;
        case FiHoldingCategory.FiHoldingCategory.HTM:
          store.holdingCategory = FiHoldingCategory.FiHoldingCategory.HTM;
          break;
      }
    },
    resetEdit(): void {
      store.validateStore.entityDropdownStore.deselectEntity();
      store.validateStore.editDetails = createContractNoteEditModel({
        brokerId: store.brokerId,
        entityId: store.entityId,
        isin: store.security?.isin.isin,
        securityName: store.security?.name,
        fields: store.details,
        charges: store.charges,
        currency: store.currency,
      });
    },
    resetStore(): void {
      store.error = null;
      store.status = undefined;
      store.brokerId = undefined;
      store.contractNoteUrl = undefined;
      store.columnHeaders = undefined;
      store.fiContractNoteHistoryId = undefined;
      store.tempContractNoteId = undefined;
      store.currency = undefined;
      store.details = cast([]);
      store.charges = cast([]);
      store.security = undefined;
      store.validateStore.resetStore();
      store.checkRequestStore.resetStore();
      store.investmentDetailsStore.resetStore();
      store.holdingCategory = FiHoldingCategory.FiHoldingCategory.HTM;
    },
    fetchContractNoteDetails: flow(function* (
      contractNoteId: number | undefined,
    ) {
      const logger = getEnv(store).logger;
      if (contractNoteId === undefined) {
        store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
        logger.error(
          `InvalidContractNoteID occurred in GetContractNoteDetailsRPC`,
        );
        return;
      }
      try {
        const request = new GetFiContractNoteDetailsRPC.Request(contractNoteId);
        const apiClient: APIClient = getAPIClient(store);
        const result: LeoRPCResult<
          GetFiContractNoteDetailsRPC.Response,
          GetFiContractNoteDetailsRPC.Errors.InvalidContractNoteId
        > =
          yield useGetFiContractNoteDetailsRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.checkRequestStore.allowedActions = AllowedActions.create({
            allowEdit: response.allowedActions.allowEdit,
            allowReviewRequest: response.allowedActions.allowReviewRequest,
            allowDiscardRequest: response.allowedActions.allowDiscardRequest,
            allowUnknownRequest: response.allowedActions.allowUnknownRequest,
            allowLinkRequest: response.allowedActions.allowLinkRequest,
            allowContractCheck: response.allowedActions.allowContractCheck,
            allowLinkCheck: response.allowedActions.allowLinkCheck,
            allowDiscardCheck: response.allowedActions.allowDiscardCheck,
            allowUnknownCheck: response.allowedActions.allowUnknownCheck,
          });
          store.contractNoteUrl = response.contractNoteURL?.toString();
          store.status = response.status;
          store.brokerId = response.brokerId?.uuid;
          store.entityId = response.entityId?.uuid;
          if (response.security) {
            store.security = createSecurityModel(
              new FiSecurity(
                response.security.isin,
                response.security.securityName,
                response.security.currency,
              ),
            );
          }
          store.fiContractNoteHistoryId =
            response.fiContractNoteHistoryId ?? undefined;
          store.columnHeaders = response.headers
            ? ContractNoteDetailHeader.create({
                originalDataHeader: response.headers.originalDataHeader,
                diffDataHeader: response.headers.diffDataHeader,
              })
            : undefined;
          store.currency = response.currency
            ? CurrencyModel.create({
                code: response.currency.code,
                symbol: response.currency.symbol,
              })
            : undefined;
          store.details = cast(
            response.details?.map((item) =>
              FiContractNoteDetailType.create({
                localizedTextId: item.localizedTextId,
                originalData: item.originalData,
                diffData: item.diffData ? item.diffData : null,
              }),
            ) ?? [],
          );
          store.charges = cast(
            response.charges?.map((charge) =>
              createFiContractNoteChargeType(charge),
            ),
          );
          store.validateStore.editDetails = createContractNoteEditModel({
            brokerId: store.brokerId,
            entityId: store.entityId,
            isin: store.security?.isin.isin,
            securityName: store.security?.name,
            fields: store.details,
            charges: store.charges,
            currency: store.currency,
            diffDataHeader: store.columnHeaders?.diffDataHeader,
          });
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case FiContractNoteDetailErrors.InvalidContractNoteID:
              store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetContractNoteDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error occurred in GetContractNoteDetailsRPC with result: ${result}`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
              break;
            default:
              store.error = FiContractNoteDetailErrors.Unknown;
              logger.error(
                `Unhandled error: ${error} occurred in GetContractNoteDetailsRPC`,
              );
          }
        } else {
          store.error = FiContractNoteDetailErrors.Unknown;
          logger.error(
            `Unknown error: ${error} occurred in GetContractNoteDetailsRPC`,
          );
        }
      }
    }),
    validateContractNote: flow(function* (contractNoteId?: number) {
      store.error = null;
      yield store.validateStore.validateContractNote(contractNoteId);
    }),
    submitContractNote: flow(function* ({
      contractNoteId,
      note,
    }: {
      contractNoteId: number | undefined;
      note: string | undefined;
    }) {
      store.error = null;
      yield store.validateStore.submitContractNote(contractNoteId, note);
    }),
    checkContractNote: flow(function* (response: CheckResponse) {
      store.error = null;
      if (store.fiContractNoteHistoryId) {
        yield store.checkRequestStore.checkContractNote(
          response,
          store.fiContractNoteHistoryId,
          store.holdingCategory,
        );
      } else {
        store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
      }
    }),
    submitContractNoteUnknownRequest: flow(function* (
      note: string | undefined,
    ) {
      store.error = null;
      if (store.fiContractNoteHistoryId) {
        yield store.checkRequestStore.submitContractNoteUnknownRequest(
          note,
          store.fiContractNoteHistoryId,
        );
      } else {
        store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
      }
    }),
    submitContractNoteDiscardRequest: flow(function* (
      note: string | undefined,
    ) {
      store.error = null;
      if (store.fiContractNoteHistoryId) {
        yield store.checkRequestStore.submitContractNoteDiscardRequest(
          note,
          store.fiContractNoteHistoryId,
        );
      } else {
        store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
      }
    }),
    submitContractNoteLinkRequest: flow(function* (
      dealRequestId: number | undefined,
      note: string | undefined,
    ) {
      store.error = null;
      if (dealRequestId) {
        if (store.fiContractNoteHistoryId) {
          yield store.checkRequestStore.submitContractNoteLinkRequest(
            store.fiContractNoteHistoryId,
            dealRequestId,
            note,
          );
        } else {
          store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
        }
      } else {
        store.error = FiContractNoteDetailErrors.InvalidDealRequestId;
      }
    }),
    getFiContractNoteHistory: flow(function* (requestId: number | undefined) {
      store.error = null;
      yield store.viewContractNoteHistoryStore.getFiContractNoteHistory(
        requestId,
      );
    }),
    getInvestmentDetails: flow(function* (contractNoteId: number | undefined) {
      if (contractNoteId === undefined) {
        store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
        return;
      }
      yield store.investmentDetailsStore.getInvestmentDetails(
        undefined,
        contractNoteId,
        undefined,
        undefined,
      );
      if (store.investmentDetailsStore.error) {
        store.error = FiContractNoteDetailErrors.InvalidContractNoteID;
      }
    }),
  }));

export const createFiContractNoteDetailsStore = (): Instance<
  typeof FiContractNoteDetailsStore
> => {
  return FiContractNoteDetailsStore.create({
    validateStore: createFiContractNoteValidateStore(),
    linkDealStore: FiLinkDealRequestStore.create(),
    viewContractNoteHistoryStore: createViewFiContractNoteHistoryStore(),
    checkRequestStore: createFiContractNoteCheckResponseStore(),
    investmentDetailsStore: createFiInvestmentDetailsStore(),
  });
};
