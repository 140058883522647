import { Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useDataVisualizationPalette } from "../../../theme/color-tokens/lightColorTokens";
import { useBorder } from "../../../utils/BorderUtils";
import { getRoundedOffPercentage } from "../utils/UIUtils";

export const PortfolioChartSize = {
  headerContainer: "252px",
  chart: {
    buy: "380px",
    sell: "336px",
  },
};

export const PortfolioBuyChart = (
  requested: number,
  existing: number,
  otherStocks: number,
  assetName: string | null,
  transactionType: string | null,
): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const dataVisualizationPalette = useDataVisualizationPalette();
  const border = useBorder();
  const { t } = useTranslation();
  return (
    <Stack
      border={border.default}
      height={PortfolioChartSize.headerContainer}
      borderRadius={cornerRadius.radiusXS}
    >
      <Stack sx={{ marginBottom: spacing.spaceMD }}>
        <Typography
          sx={{
            ...typography.sh3,
            color: tokens.label,
            backgroundColor: tokens.backgroundSubtle,
            padding: `${spacing.spaceSM} ${spacing.spaceMD}`,
            borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
            borderBottom: border.default,
          }}
        >
          {assetName} - {transactionType}
        </Typography>
      </Stack>
      <Chart
        type="pie"
        options={{
          labels: [
            t("common.requested"),
            t("common.existing"),
            t("common.otherStocks"),
          ],
          tooltip: {
            y: {
              formatter: (value): string => {
                return t(`common.percentageSymbol`, { value });
              },
            },
          },
          legend: {
            formatter: (legendName, opts): string => {
              return `${legendName} - ${
                opts.w.globals.series[opts.seriesIndex]
              }%`;
            },
            offsetY: 40,
          },
          dataLabels: {
            enabled: false,
            textAnchor: "middle",
          },
          colors: [
            dataVisualizationPalette.one,
            dataVisualizationPalette.two,
            dataVisualizationPalette.three,
          ],
        }}
        series={[
          getRoundedOffPercentage(requested),
          getRoundedOffPercentage(existing),
          getRoundedOffPercentage(otherStocks),
        ]}
        width={PortfolioChartSize.chart.buy}
      />
    </Stack>
  );
};
