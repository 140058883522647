import { Instance, types } from "mobx-state-tree";
import {
  createRequestNoteModel,
  RequestNoteModel,
} from "../../../models/RequestNoteModel";
import { MFConfirmationHistoryDetail } from "@khazana/khazana-rpcs";
import { DiffDetailType } from "../../../models/DiffDetailType";
import { MFConfirmationStatus } from "./MFConfirmationStatus";
import {
  BigAmountModel,
  createBigAmountModel,
} from "../../../models/BigAmountModel";
import { getMFRequestStatusValue } from "../../deal-request/utils/UIUtils";

export const MfConfirmationDealRequestDetailsModel = types.model({
  folioNumber: types.string,
  entity: types.string,
  amount: types.maybe(BigAmountModel),
  quantity: types.maybe(types.string),
  transactionType: types.string,
  schemeName: types.string,
  requestId: types.number,
  status: types.string,
});

export const MFConfirmationHistoryDetailModel = types.model(
  "MFConfirmationHistoryDetailModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: MFConfirmationStatus,
    diffData: types.array(DiffDetailType),
    dealDetail: types.maybeNull(MfConfirmationDealRequestDetailsModel),
  },
);

export const createMFConfirmationHistoryDetailModel = (
  historyDetails: MFConfirmationHistoryDetail | null,
): Instance<typeof MFConfirmationHistoryDetailModel> => {
  if (historyDetails) {
    const { status, requestNote, diffDetails, dealRequestDetails } =
      historyDetails;
    return MFConfirmationHistoryDetailModel.create({
      status,
      requestNote: createRequestNoteModel(requestNote),
      diffData: diffDetails.map((diffDetail) =>
        DiffDetailType.create(diffDetail),
      ),
      dealDetail: dealRequestDetails
        ? MfConfirmationDealRequestDetailsModel.create({
            folioNumber: dealRequestDetails.folioNumber ?? "-",
            entity: dealRequestDetails.entity,
            amount: dealRequestDetails.amount
              ? createBigAmountModel(dealRequestDetails.amount)
              : undefined,
            quantity: dealRequestDetails.quantity?.decimalValue,
            transactionType: dealRequestDetails.transactionType,
            schemeName: dealRequestDetails.schemeName,
            requestId: dealRequestDetails.requestId,
            status: getMFRequestStatusValue(dealRequestDetails.requestStatus),
          })
        : null,
    });
  }
  return MFConfirmationHistoryDetailModel.create();
};
