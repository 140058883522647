import React from "react";
import { Divider, Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../utils/BorderUtils";

export interface ContractNoteDealDetailsProps {
  header: string;
  detailsList: {
    label: string;
    value: string | number;
  }[];
}

const Size = {
  container: {
    width: "100%",
    padding: "10px",
  },
  dealDetailsContainer: "356px",
  dealDetailsHeading: "48px",
  dealDetailsSubheading: "182px",
};

export const ContractNoteDealDetails = ({
  header,
  detailsList,
}: ContractNoteDealDetailsProps): React.ReactElement => {
  const typography = useTypography();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();

  const detailsStack: React.ReactElement[] = [];
  let i = 0;
  detailsList.forEach((value) => {
    detailsStack.push(
      <Stack direction={"row"} key={i++}>
        <Typography
          sx={{ ...typography.b2, minWidth: Size.dealDetailsSubheading }}
        >
          {value.label}
        </Typography>
        <Typography sx={{ ...typography.b2 }}>{value.value}</Typography>
      </Stack>,
    );
  });
  return (
    <Stack
      width={Size.dealDetailsContainer}
      sx={{
        border: border.default,
        borderRadius: cornerRadius.radiusXXS,
      }}
    >
      <Stack
        height={Size.dealDetailsHeading}
        width={Size.container.width}
        justifyContent={"center"}
        sx={{
          background: tokens.backgroundSubtle,
          borderRadius: cornerRadius.radiusXXS,
        }}
        padding={Size.container.padding}
      >
        <Typography
          sx={{
            ...typography.sh3,
          }}
        >
          {header}
        </Typography>
      </Stack>
      <Divider />
      <Stack spacing={spacing.spaceXS} padding={spacing.spaceMD}>
        {detailsStack}
      </Stack>
    </Stack>
  );
};
