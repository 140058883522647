"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomeInvestmentRegisterResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedIncomeSecurityType_1 = require("./fixedIncomeSecurityType");
const fixedIncomePurchaseStatus_1 = require("./fixedIncomePurchaseStatus");
class InvalidFixedIncomeInvestmentRegisterResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomeInvestmentRegisterResponseError";
    }
}
class FixedIncomeInvestmentRegisterResponse {
    constructor(symbol, holdingCategory = null, instrumentType = null, openingAmount = null, purchaseAmount = null, maturedAmount = null, closingAmount = null, daysInHolding = null, couponRate = null, startDate = null, maturityDate = null, nextCallDate = null, status = null, openingInterest = null, openingTds = null, openingOtherCharges = null, interestReceived = null, taxPaid = null, otherChargesPaid = null, netInterestReceived = null, interestAccrued = null, taxOnInterestAccrued = null, otherTaxesOnInterestAccrued = null, netInterestReceivable = null) {
        this.isFixedIncomeInvestmentRegisterResponse = true;
        FixedIncomeInvestmentRegisterResponse.validateSymbol(symbol);
        this.symbol = symbol;
        if (holdingCategory !== undefined && holdingCategory !== null) {
            FixedIncomeInvestmentRegisterResponse.validateHoldingCategory(holdingCategory);
        }
        this.holdingCategory = holdingCategory;
        if (instrumentType !== undefined && instrumentType !== null) {
            FixedIncomeInvestmentRegisterResponse.validateInstrumentType(instrumentType);
        }
        this.instrumentType = instrumentType;
        if (openingAmount !== undefined && openingAmount !== null) {
            FixedIncomeInvestmentRegisterResponse.validateOpeningAmount(openingAmount);
        }
        this.openingAmount = openingAmount;
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
            FixedIncomeInvestmentRegisterResponse.validatePurchaseAmount(purchaseAmount);
        }
        this.purchaseAmount = purchaseAmount;
        if (maturedAmount !== undefined && maturedAmount !== null) {
            FixedIncomeInvestmentRegisterResponse.validateMaturedAmount(maturedAmount);
        }
        this.maturedAmount = maturedAmount;
        if (closingAmount !== undefined && closingAmount !== null) {
            FixedIncomeInvestmentRegisterResponse.validateClosingAmount(closingAmount);
        }
        this.closingAmount = closingAmount;
        if (daysInHolding !== undefined && daysInHolding !== null) {
            FixedIncomeInvestmentRegisterResponse.validateDaysInHolding(daysInHolding);
        }
        this.daysInHolding = daysInHolding;
        if (couponRate !== undefined && couponRate !== null) {
            FixedIncomeInvestmentRegisterResponse.validateCouponRate(couponRate);
        }
        this.couponRate = couponRate;
        if (startDate !== undefined && startDate !== null) {
            FixedIncomeInvestmentRegisterResponse.validateStartDate(startDate);
        }
        this.startDate = startDate;
        if (maturityDate !== undefined && maturityDate !== null) {
            FixedIncomeInvestmentRegisterResponse.validateMaturityDate(maturityDate);
        }
        this.maturityDate = maturityDate;
        if (nextCallDate !== undefined && nextCallDate !== null) {
            FixedIncomeInvestmentRegisterResponse.validateNextCallDate(nextCallDate);
        }
        this.nextCallDate = nextCallDate;
        if (status !== undefined && status !== null) {
            FixedIncomeInvestmentRegisterResponse.validateStatus(status);
        }
        this.status = status;
        if (openingInterest !== undefined && openingInterest !== null) {
            FixedIncomeInvestmentRegisterResponse.validateOpeningInterest(openingInterest);
        }
        this.openingInterest = openingInterest;
        if (openingTds !== undefined && openingTds !== null) {
            FixedIncomeInvestmentRegisterResponse.validateOpeningTds(openingTds);
        }
        this.openingTds = openingTds;
        if (openingOtherCharges !== undefined && openingOtherCharges !== null) {
            FixedIncomeInvestmentRegisterResponse.validateOpeningOtherCharges(openingOtherCharges);
        }
        this.openingOtherCharges = openingOtherCharges;
        if (interestReceived !== undefined && interestReceived !== null) {
            FixedIncomeInvestmentRegisterResponse.validateInterestReceived(interestReceived);
        }
        this.interestReceived = interestReceived;
        if (taxPaid !== undefined && taxPaid !== null) {
            FixedIncomeInvestmentRegisterResponse.validateTaxPaid(taxPaid);
        }
        this.taxPaid = taxPaid;
        if (otherChargesPaid !== undefined && otherChargesPaid !== null) {
            FixedIncomeInvestmentRegisterResponse.validateOtherChargesPaid(otherChargesPaid);
        }
        this.otherChargesPaid = otherChargesPaid;
        if (netInterestReceived !== undefined && netInterestReceived !== null) {
            FixedIncomeInvestmentRegisterResponse.validateNetInterestReceived(netInterestReceived);
        }
        this.netInterestReceived = netInterestReceived;
        if (interestAccrued !== undefined && interestAccrued !== null) {
            FixedIncomeInvestmentRegisterResponse.validateInterestAccrued(interestAccrued);
        }
        this.interestAccrued = interestAccrued;
        if (taxOnInterestAccrued !== undefined && taxOnInterestAccrued !== null) {
            FixedIncomeInvestmentRegisterResponse.validateTaxOnInterestAccrued(taxOnInterestAccrued);
        }
        this.taxOnInterestAccrued = taxOnInterestAccrued;
        if (otherTaxesOnInterestAccrued !== undefined && otherTaxesOnInterestAccrued !== null) {
            FixedIncomeInvestmentRegisterResponse.validateOtherTaxesOnInterestAccrued(otherTaxesOnInterestAccrued);
        }
        this.otherTaxesOnInterestAccrued = otherTaxesOnInterestAccrued;
        if (netInterestReceivable !== undefined && netInterestReceivable !== null) {
            FixedIncomeInvestmentRegisterResponse.validateNetInterestReceivable(netInterestReceivable);
        }
        this.netInterestReceivable = netInterestReceivable;
    }
    static fromDTO(dto) {
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidFixedIncomeInvestmentRegisterResponseError);
        const holdingCategory = (0, leo_ts_runtime_1.getOptionalString)(dto, "holdingCategory", InvalidFixedIncomeInvestmentRegisterResponseError);
        let instrumentType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "instrumentType");
        if (instrumentType !== undefined && instrumentType !== null) {
            instrumentType = fixedIncomeSecurityType_1.FixedIncomeSecurityType.fromDTO(instrumentType);
        }
        const openingAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingAmount", InvalidFixedIncomeInvestmentRegisterResponseError);
        const purchaseAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "purchaseAmount", InvalidFixedIncomeInvestmentRegisterResponseError);
        const maturedAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "maturedAmount", InvalidFixedIncomeInvestmentRegisterResponseError);
        const closingAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "closingAmount", InvalidFixedIncomeInvestmentRegisterResponseError);
        const daysInHolding = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "daysInHolding", InvalidFixedIncomeInvestmentRegisterResponseError);
        const couponRate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "couponRate", InvalidFixedIncomeInvestmentRegisterResponseError);
        const startDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "startDate", InvalidFixedIncomeInvestmentRegisterResponseError);
        const maturityDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "maturityDate", InvalidFixedIncomeInvestmentRegisterResponseError);
        const nextCallDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "nextCallDate", InvalidFixedIncomeInvestmentRegisterResponseError);
        let status = (0, leo_ts_runtime_1.getOptionalObject)(dto, "status");
        if (status !== undefined && status !== null) {
            status = fixedIncomePurchaseStatus_1.FixedIncomePurchaseStatus.fromDTO(status);
        }
        const openingInterest = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingInterest", InvalidFixedIncomeInvestmentRegisterResponseError);
        const openingTds = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingTds", InvalidFixedIncomeInvestmentRegisterResponseError);
        const openingOtherCharges = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingOtherCharges", InvalidFixedIncomeInvestmentRegisterResponseError);
        const interestReceived = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "interestReceived", InvalidFixedIncomeInvestmentRegisterResponseError);
        const taxPaid = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "taxPaid", InvalidFixedIncomeInvestmentRegisterResponseError);
        const otherChargesPaid = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "otherChargesPaid", InvalidFixedIncomeInvestmentRegisterResponseError);
        const netInterestReceived = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "netInterestReceived", InvalidFixedIncomeInvestmentRegisterResponseError);
        const interestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "interestAccrued", InvalidFixedIncomeInvestmentRegisterResponseError);
        const taxOnInterestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "taxOnInterestAccrued", InvalidFixedIncomeInvestmentRegisterResponseError);
        const otherTaxesOnInterestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "otherTaxesOnInterestAccrued", InvalidFixedIncomeInvestmentRegisterResponseError);
        const netInterestReceivable = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "netInterestReceivable", InvalidFixedIncomeInvestmentRegisterResponseError);
        return new FixedIncomeInvestmentRegisterResponse(symbol, holdingCategory, instrumentType, openingAmount, purchaseAmount, maturedAmount, closingAmount, daysInHolding, couponRate, startDate, maturityDate, nextCallDate, status, openingInterest, openingTds, openingOtherCharges, interestReceived, taxPaid, otherChargesPaid, netInterestReceived, interestAccrued, taxOnInterestAccrued, otherTaxesOnInterestAccrued, netInterestReceivable);
    }
    toDTO() {
        const ret = {
            symbol: this.symbol,
        };
        if (this.holdingCategory !== undefined && this.holdingCategory !== null) {
            ret.holdingCategory = this.holdingCategory;
        }
        if (this.instrumentType !== undefined && this.instrumentType !== null) {
            ret.instrumentType = fixedIncomeSecurityType_1.FixedIncomeSecurityType.toDTO(this.instrumentType);
        }
        if (this.openingAmount !== undefined && this.openingAmount !== null) {
            ret.openingAmount = this.openingAmount;
        }
        if (this.purchaseAmount !== undefined && this.purchaseAmount !== null) {
            ret.purchaseAmount = this.purchaseAmount;
        }
        if (this.maturedAmount !== undefined && this.maturedAmount !== null) {
            ret.maturedAmount = this.maturedAmount;
        }
        if (this.closingAmount !== undefined && this.closingAmount !== null) {
            ret.closingAmount = this.closingAmount;
        }
        if (this.daysInHolding !== undefined && this.daysInHolding !== null) {
            ret.daysInHolding = this.daysInHolding;
        }
        if (this.couponRate !== undefined && this.couponRate !== null) {
            ret.couponRate = this.couponRate;
        }
        if (this.startDate) {
            ret.startDate = this.startDate.toDTO();
        }
        if (this.maturityDate) {
            ret.maturityDate = this.maturityDate.toDTO();
        }
        if (this.nextCallDate) {
            ret.nextCallDate = this.nextCallDate.toDTO();
        }
        if (this.status !== undefined && this.status !== null) {
            ret.status = fixedIncomePurchaseStatus_1.FixedIncomePurchaseStatus.toDTO(this.status);
        }
        if (this.openingInterest !== undefined && this.openingInterest !== null) {
            ret.openingInterest = this.openingInterest;
        }
        if (this.openingTds !== undefined && this.openingTds !== null) {
            ret.openingTds = this.openingTds;
        }
        if (this.openingOtherCharges !== undefined && this.openingOtherCharges !== null) {
            ret.openingOtherCharges = this.openingOtherCharges;
        }
        if (this.interestReceived !== undefined && this.interestReceived !== null) {
            ret.interestReceived = this.interestReceived;
        }
        if (this.taxPaid !== undefined && this.taxPaid !== null) {
            ret.taxPaid = this.taxPaid;
        }
        if (this.otherChargesPaid !== undefined && this.otherChargesPaid !== null) {
            ret.otherChargesPaid = this.otherChargesPaid;
        }
        if (this.netInterestReceived !== undefined && this.netInterestReceived !== null) {
            ret.netInterestReceived = this.netInterestReceived;
        }
        if (this.interestAccrued !== undefined && this.interestAccrued !== null) {
            ret.interestAccrued = this.interestAccrued;
        }
        if (this.taxOnInterestAccrued !== undefined && this.taxOnInterestAccrued !== null) {
            ret.taxOnInterestAccrued = this.taxOnInterestAccrued;
        }
        if (this.otherTaxesOnInterestAccrued !== undefined && this.otherTaxesOnInterestAccrued !== null) {
            ret.otherTaxesOnInterestAccrued = this.otherTaxesOnInterestAccrued;
        }
        if (this.netInterestReceivable !== undefined && this.netInterestReceivable !== null) {
            ret.netInterestReceivable = this.netInterestReceivable;
        }
        return ret;
    }
    copy(symbol = this.symbol, holdingCategory = this.holdingCategory, instrumentType = this.instrumentType, openingAmount = this.openingAmount, purchaseAmount = this.purchaseAmount, maturedAmount = this.maturedAmount, closingAmount = this.closingAmount, daysInHolding = this.daysInHolding, couponRate = this.couponRate, startDate = this.startDate, maturityDate = this.maturityDate, nextCallDate = this.nextCallDate, status = this.status, openingInterest = this.openingInterest, openingTds = this.openingTds, openingOtherCharges = this.openingOtherCharges, interestReceived = this.interestReceived, taxPaid = this.taxPaid, otherChargesPaid = this.otherChargesPaid, netInterestReceived = this.netInterestReceived, interestAccrued = this.interestAccrued, taxOnInterestAccrued = this.taxOnInterestAccrued, otherTaxesOnInterestAccrued = this.otherTaxesOnInterestAccrued, netInterestReceivable = this.netInterestReceivable) {
        return new FixedIncomeInvestmentRegisterResponse(symbol, holdingCategory, instrumentType, openingAmount, purchaseAmount, maturedAmount, closingAmount, daysInHolding, couponRate, startDate, maturityDate, nextCallDate, status, openingInterest, openingTds, openingOtherCharges, interestReceived, taxPaid, otherChargesPaid, netInterestReceived, interestAccrued, taxOnInterestAccrued, otherTaxesOnInterestAccrued, netInterestReceivable);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedIncomeInvestmentRegisterResponseValue = other.isFixedIncomeInvestmentRegisterResponse;
        if (!(other instanceof FixedIncomeInvestmentRegisterResponse) || Boolean(isFixedIncomeInvestmentRegisterResponseValue)) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.holdingCategory !== other.holdingCategory) {
            return false;
        }
        if (this.instrumentType !== other.instrumentType) {
            return false;
        }
        if (this.openingAmount !== other.openingAmount) {
            return false;
        }
        if (this.purchaseAmount !== other.purchaseAmount) {
            return false;
        }
        if (this.maturedAmount !== other.maturedAmount) {
            return false;
        }
        if (this.closingAmount !== other.closingAmount) {
            return false;
        }
        if (this.daysInHolding !== other.daysInHolding) {
            return false;
        }
        if (this.couponRate !== other.couponRate) {
            return false;
        }
        if (this.startDate) {
            if (!this.startDate.equals(other.startDate)) {
                return false;
            }
        }
        if (this.maturityDate) {
            if (!this.maturityDate.equals(other.maturityDate)) {
                return false;
            }
        }
        if (this.nextCallDate) {
            if (!this.nextCallDate.equals(other.nextCallDate)) {
                return false;
            }
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.openingInterest !== other.openingInterest) {
            return false;
        }
        if (this.openingTds !== other.openingTds) {
            return false;
        }
        if (this.openingOtherCharges !== other.openingOtherCharges) {
            return false;
        }
        if (this.interestReceived !== other.interestReceived) {
            return false;
        }
        if (this.taxPaid !== other.taxPaid) {
            return false;
        }
        if (this.otherChargesPaid !== other.otherChargesPaid) {
            return false;
        }
        if (this.netInterestReceived !== other.netInterestReceived) {
            return false;
        }
        if (this.interestAccrued !== other.interestAccrued) {
            return false;
        }
        if (this.taxOnInterestAccrued !== other.taxOnInterestAccrued) {
            return false;
        }
        if (this.otherTaxesOnInterestAccrued !== other.otherTaxesOnInterestAccrued) {
            return false;
        }
        if (this.netInterestReceivable !== other.netInterestReceivable) {
            return false;
        }
        return true;
    }
}
exports.FixedIncomeInvestmentRegisterResponse = FixedIncomeInvestmentRegisterResponse;
FixedIncomeInvestmentRegisterResponse.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute symbol cannot be an empty string.`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateHoldingCategory = (holdingCategory) => {
    if (!(0, leo_ts_runtime_1.isString)(holdingCategory)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute holdingCategory is not a String`);
    }
    if (holdingCategory.trim().length === 0) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute holdingCategory cannot be an empty string.`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateInstrumentType = (instrumentType) => {
    if (!fixedIncomeSecurityType_1.FixedIncomeSecurityType.isInstanceOf(instrumentType)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute instrumentType is not a FixedIncomeSecurityType.FixedIncomeSecurityType`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateOpeningAmount = (openingAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingAmount)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute openingAmount is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validatePurchaseAmount = (purchaseAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(purchaseAmount)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute purchaseAmount is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateMaturedAmount = (maturedAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(maturedAmount)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute maturedAmount is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateClosingAmount = (closingAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(closingAmount)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute closingAmount is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateDaysInHolding = (daysInHolding) => {
    if (!(0, leo_ts_runtime_1.isInt64)(daysInHolding)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute daysInHolding is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateCouponRate = (couponRate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(couponRate)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute couponRate is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateStartDate = (startDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(startDate)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute startDate is not a Date`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateMaturityDate = (maturityDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute maturityDate is not a Date`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateNextCallDate = (nextCallDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(nextCallDate)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute nextCallDate is not a Date`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateStatus = (status) => {
    if (!fixedIncomePurchaseStatus_1.FixedIncomePurchaseStatus.isInstanceOf(status)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute status is not a FixedIncomePurchaseStatus.FixedIncomePurchaseStatus`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateOpeningInterest = (openingInterest) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingInterest)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute openingInterest is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateOpeningTds = (openingTds) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingTds)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute openingTds is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateOpeningOtherCharges = (openingOtherCharges) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingOtherCharges)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute openingOtherCharges is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateInterestReceived = (interestReceived) => {
    if (!(0, leo_ts_runtime_1.isInt64)(interestReceived)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute interestReceived is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateTaxPaid = (taxPaid) => {
    if (!(0, leo_ts_runtime_1.isInt64)(taxPaid)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute taxPaid is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateOtherChargesPaid = (otherChargesPaid) => {
    if (!(0, leo_ts_runtime_1.isInt64)(otherChargesPaid)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute otherChargesPaid is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateNetInterestReceived = (netInterestReceived) => {
    if (!(0, leo_ts_runtime_1.isInt64)(netInterestReceived)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute netInterestReceived is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateInterestAccrued = (interestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(interestAccrued)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute interestAccrued is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateTaxOnInterestAccrued = (taxOnInterestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(taxOnInterestAccrued)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute taxOnInterestAccrued is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateOtherTaxesOnInterestAccrued = (otherTaxesOnInterestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(otherTaxesOnInterestAccrued)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute otherTaxesOnInterestAccrued is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.validateNetInterestReceivable = (netInterestReceivable) => {
    if (!(0, leo_ts_runtime_1.isInt64)(netInterestReceivable)) {
        throw new InvalidFixedIncomeInvestmentRegisterResponseError(`Attribute netInterestReceivable is not an Int64`);
    }
};
FixedIncomeInvestmentRegisterResponse.prototype.toString = function toString() {
    return `FixedIncomeInvestmentRegisterResponse(symbol='${this.symbol}',holdingCategory='${this.holdingCategory}',instrumentType=${this.instrumentType},openingAmount=${this.openingAmount},purchaseAmount=${this.purchaseAmount},maturedAmount=${this.maturedAmount},closingAmount=${this.closingAmount},daysInHolding=${this.daysInHolding},couponRate=${this.couponRate},startDate=${this.startDate},maturityDate=${this.maturityDate},nextCallDate=${this.nextCallDate},status=${this.status},openingInterest=${this.openingInterest},openingTds=${this.openingTds},openingOtherCharges=${this.openingOtherCharges},interestReceived=${this.interestReceived},taxPaid=${this.taxPaid},otherChargesPaid=${this.otherChargesPaid},netInterestReceived=${this.netInterestReceived},interestAccrued=${this.interestAccrued},taxOnInterestAccrued=${this.taxOnInterestAccrued},otherTaxesOnInterestAccrued=${this.otherTaxesOnInterestAccrued},netInterestReceivable=${this.netInterestReceivable})`;
};
