import { PendingCashflows } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";
import { BigAmountModel, createBigAmountModel } from "./BigAmountModel";

export const PendingCashflowModel = types.model({
  accruedCouponBooked: types.maybe(BigAmountModel),
  accruedCouponPending: types.maybe(BigAmountModel),
  accruedTdsBooked: types.maybe(BigAmountModel),
  accruedTdsPending: types.maybe(BigAmountModel),
});

export const createPendingCashflowModel = ({
  accruedCouponBooked,
  accruedCouponPending,
  accruedTdsBooked,
  accruedTdsPending,
}: PendingCashflows): Instance<typeof PendingCashflowModel> => {
  return PendingCashflowModel.create({
    accruedCouponBooked: accruedCouponBooked
      ? createBigAmountModel(accruedCouponBooked)
      : undefined,
    accruedCouponPending: accruedCouponPending
      ? createBigAmountModel(accruedCouponPending)
      : undefined,
    accruedTdsBooked: accruedTdsBooked
      ? createBigAmountModel(accruedTdsBooked)
      : undefined,
    accruedTdsPending: accruedTdsPending
      ? createBigAmountModel(accruedTdsPending)
      : undefined,
  });
};
