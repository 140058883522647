import React from "react";
import { ReportGrid } from "../../../components/reports/ReportGrid";
import { ReportCardProps } from "../../../components/reports/ReportCard";
import { useTranslation } from "react-i18next";
import {
  PageHeader,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useUserStore } from "../../../store/hooks";
import { MFUserPrivileges } from "../../../../user/UserPrivileges";
import { RefreshCW } from "../../../../../assets/RefreshCW";
import { ClipboardList } from "../../../../../assets/ClipboardList";

const Size = {
  icon: "24px",
};

export const MfReportList = (): React.ReactElement => {
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const privileges = useUserStore().privileges;

  const reports: ReportCardProps[] = [];

  if (privileges.includes(MFUserPrivileges.ViewMutualFundHoldingSummary)) {
    reports.push({
      icon: (
        <RefreshCW
          height={Size.icon}
          width={Size.icon}
          color={tokens.iconPrimary}
        />
      ),
      name: t("fi.reports.holdingSummaryLabel"),
      path: getPath(Module.MF, Route.HoldingSummary),
    });
  }

  if (
    privileges.includes(MFUserPrivileges.ViewMutualFundInvestmentReturnsGrowth)
  ) {
    reports.push({
      icon: (
        <ClipboardList
          height={Size.icon}
          width={Size.icon}
          color={tokens.iconPrimary}
        />
      ),
      name: t("mf.reports.investmentReturnsGrowthLabel"),
      path: getPath(Module.MF, Route.InvestmentReturnsGrowthReport),
    });
  }

  return (
    <>
      <PageHeader title={t("common.reports")} />
      <ReportGrid reports={reports} />
    </>
  );
};
