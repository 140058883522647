import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetMFDealRequestHistoryRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { useGetMFDealRequestHistoryRPC } from "../../rpc/RPC";
import {
  createMFDealRequestHistoryModel,
  MFDealRequestHistoryModel,
} from "../../models/MFDealRequestHistoryModel";
import { mfDealDetailsErrorStore } from "./hooks";

export const MFDealHistoryStore = types
  .model("DealHistoryStore", {
    historyDetails: types.array(MFDealRequestHistoryModel),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFDealRequestHistory: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFDealRequestHistoryRPC.Request(requestId);
        const result: LeoRPCResult<
          GetMFDealRequestHistoryRPC.Response,
          GetMFDealRequestHistoryRPC.Errors.Errors
        > = yield useGetMFDealRequestHistoryRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.historyDetails = cast(
            response.requestDetails.map((detail) =>
              createMFDealRequestHistoryModel(detail),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const errorStore = mfDealDetailsErrorStore(store);
          errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in getMFDealRequestHistory");
        }
      }),
    };
  });

export const createMFDealHistoryStore = (): Instance<
  typeof MFDealHistoryStore
> => {
  return MFDealHistoryStore.create({});
};
