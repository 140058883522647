"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomeInstrumentType = exports.InvalidFixedIncomeInstrumentTypeError = void 0;
class InvalidFixedIncomeInstrumentTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomeInstrumentTypeError";
    }
}
exports.InvalidFixedIncomeInstrumentTypeError = InvalidFixedIncomeInstrumentTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedIncomeInstrumentType;
(function (FixedIncomeInstrumentType_1) {
    let FixedIncomeInstrumentType;
    (function (FixedIncomeInstrumentType) {
        FixedIncomeInstrumentType["G_SEC"] = "G_SEC";
        FixedIncomeInstrumentType["T_BILL"] = "T_BILL";
        FixedIncomeInstrumentType["CORPORATE_BOND"] = "CORPORATE_BOND";
    })(FixedIncomeInstrumentType = FixedIncomeInstrumentType_1.FixedIncomeInstrumentType || (FixedIncomeInstrumentType_1.FixedIncomeInstrumentType = {}));
    FixedIncomeInstrumentType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "G_SEC":
                return FixedIncomeInstrumentType.G_SEC;
            case "T_BILL":
                return FixedIncomeInstrumentType.T_BILL;
            case "CORPORATE_BOND":
                return FixedIncomeInstrumentType.CORPORATE_BOND;
            default:
                throw new InvalidFixedIncomeInstrumentTypeError(`Case ${dto.case} is not valid case of FixedIncomeInstrumentType`);
        }
    };
    FixedIncomeInstrumentType_1.toDTO = (fixedIncomeInstrumentType) => {
        const ret = {
            case: FixedIncomeInstrumentType[fixedIncomeInstrumentType],
        };
        return ret;
    };
    FixedIncomeInstrumentType_1.isInstanceOf = (other) => {
        if (other in FixedIncomeInstrumentType) {
            return true;
        }
        return false;
    };
})(FixedIncomeInstrumentType = exports.FixedIncomeInstrumentType || (exports.FixedIncomeInstrumentType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
