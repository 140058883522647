import { types } from "mobx-state-tree";
import { CurrencyModel } from "../../../models/CurrencyModel";
import {
  FiDealRequestStatus,
  FiTransactionType,
  FiYieldType,
} from "@khazana/khazana-rpcs";

export const FiLinkDealRequestModel = types.model({
  dealRequestId: types.number,
  dematAccountNumber: types.string,
  security: types.string,
  ytm: types.number,
  yieldType: types.enumeration<FiYieldType.FiYieldType>(
    "FiYieldType",
    Object.values(FiYieldType.FiYieldType),
  ),
  amountHCY: types.maybeNull(types.number),
  currency: CurrencyModel,
  quantity: types.maybeNull(types.number),
  entityName: types.string,
  status: types.enumeration<FiDealRequestStatus.FiDealRequestStatus>(
    "DealRequestStatus",
    Object.values(FiDealRequestStatus.FiDealRequestStatus),
  ),
  transactionType: types.enumeration<FiTransactionType.FiTransactionType>(
    "TransactionType",
    Object.values(FiTransactionType.FiTransactionType),
  ),
});
