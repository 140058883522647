"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFDividendType = exports.InvalidMFDividendTypeError = void 0;
class InvalidMFDividendTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFDividendTypeError";
    }
}
exports.InvalidMFDividendTypeError = InvalidMFDividendTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFDividendType;
(function (MFDividendType_1) {
    let MFDividendType;
    (function (MFDividendType) {
        MFDividendType["PAYOUT"] = "PAYOUT";
        MFDividendType["GROWTH"] = "GROWTH";
    })(MFDividendType = MFDividendType_1.MFDividendType || (MFDividendType_1.MFDividendType = {}));
    MFDividendType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "PAYOUT":
                return MFDividendType.PAYOUT;
            case "GROWTH":
                return MFDividendType.GROWTH;
            default:
                throw new InvalidMFDividendTypeError(`Case ${dto.case} is not valid case of MFDividendType`);
        }
    };
    MFDividendType_1.toDTO = (mFDividendType) => {
        const ret = {
            case: MFDividendType[mFDividendType],
        };
        return ret;
    };
    MFDividendType_1.isInstanceOf = (other) => {
        if (other in MFDividendType) {
            return true;
        }
        return false;
    };
})(MFDividendType = exports.MFDividendType || (exports.MFDividendType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
