"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiMaturityDealRequestSearchBy = exports.InvalidFiMaturityDealRequestSearchByError = void 0;
class InvalidFiMaturityDealRequestSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiMaturityDealRequestSearchByError";
    }
}
exports.InvalidFiMaturityDealRequestSearchByError = InvalidFiMaturityDealRequestSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiMaturityDealRequestSearchBy;
(function (FiMaturityDealRequestSearchBy_1) {
    let FiMaturityDealRequestSearchBy;
    (function (FiMaturityDealRequestSearchBy) {
        FiMaturityDealRequestSearchBy["REQUEST_ID"] = "REQUEST_ID";
        FiMaturityDealRequestSearchBy["PORTFOLIO"] = "PORTFOLIO";
        FiMaturityDealRequestSearchBy["ENTITY"] = "ENTITY";
        FiMaturityDealRequestSearchBy["ISIN"] = "ISIN";
    })(FiMaturityDealRequestSearchBy = FiMaturityDealRequestSearchBy_1.FiMaturityDealRequestSearchBy || (FiMaturityDealRequestSearchBy_1.FiMaturityDealRequestSearchBy = {}));
    FiMaturityDealRequestSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "REQUEST_ID":
                return FiMaturityDealRequestSearchBy.REQUEST_ID;
            case "PORTFOLIO":
                return FiMaturityDealRequestSearchBy.PORTFOLIO;
            case "ENTITY":
                return FiMaturityDealRequestSearchBy.ENTITY;
            case "ISIN":
                return FiMaturityDealRequestSearchBy.ISIN;
            default:
                throw new InvalidFiMaturityDealRequestSearchByError(`Case ${dto.case} is not valid case of FiMaturityDealRequestSearchBy`);
        }
    };
    FiMaturityDealRequestSearchBy_1.toDTO = (fiMaturityDealRequestSearchBy) => {
        const ret = {
            case: FiMaturityDealRequestSearchBy[fiMaturityDealRequestSearchBy],
        };
        return ret;
    };
    FiMaturityDealRequestSearchBy_1.isInstanceOf = (other) => {
        if (other in FiMaturityDealRequestSearchBy) {
            return true;
        }
        return false;
    };
})(FiMaturityDealRequestSearchBy = exports.FiMaturityDealRequestSearchBy || (exports.FiMaturityDealRequestSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
