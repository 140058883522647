import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createFDWithdrawModel,
  FDWithdrawModel,
} from "../../models/FDWithdrawModel";
import { ConfirmWithdrawRequestRPC, Note } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useConfirmWithdrawRequestRPC } from "../../rpc/RPC";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { createServerTransactionAmountRPCType } from "../../../../models/AmountModel";
import { getLeoDate, getServerAmount } from "../../../../../../utils";
import {
  FDConfirmWithdrawRequestErrors,
  FDDealInvalidRequestError,
} from "../FDDealRequestErrors";
import i18next from "i18next";

export const ConfirmWithdrawRequestStore = types
  .model("ConfirmWithdrawRequestStore", {
    fields: FDWithdrawModel,
    note: types.maybe(types.string),
    error: types.maybeNull(
      types.union(
        types.enumeration<FDDealInvalidRequestError>(
          "FDDealInvalidRequestError",
          Object.values(FDDealInvalidRequestError),
        ),
        types.enumeration<FDConfirmWithdrawRequestErrors>(
          "FDConfirmWithdrawRequestErrors",
          Object.values(FDConfirmWithdrawRequestErrors),
        ),
      ),
    ),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setNote: (value: string): void => {
        store.note = value;
      },
      setFields: (fields: Instance<typeof FDWithdrawModel>): void => {
        store.fields = fields;
      },
      confirmWithdrawRequest: flow(function* (requestId: number) {
        if (
          store.fields.isConfirmValid() &&
          store.fields.requestId &&
          store.fields.withdrawalAmount &&
          store.fields.currency &&
          store.fields.penaltyCharges &&
          store.fields.penaltyAmount &&
          store.fields.actualWithdrawalDate
        ) {
          const logger = getEnv(store).logger;
          const apiClient = getAPIClient(store);
          const request = new ConfirmWithdrawRequestRPC.Request(
            store.fields.requestId,
            requestId,
            createServerTransactionAmountRPCType(
              store.fields.withdrawalAmount,
              store.fields.currency,
            ),
            getServerAmount(store.fields.penaltyCharges),
            createServerTransactionAmountRPCType(
              store.fields.penaltyAmount,
              store.fields.currency,
            ),
            getLeoDate(store.fields.actualWithdrawalDate),
            store.fields.withdrawalReferenceNumber,
            store.note ? new Note(store.note) : null,
          );
          const result: LeoRPCResult<
            ConfirmWithdrawRequestRPC.Response,
            ConfirmWithdrawRequestRPC.Errors.Errors
          > = yield useConfirmWithdrawRequestRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            return;
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              case FDDealInvalidRequestError.InvalidRequestId:
                store.error = FDDealInvalidRequestError.InvalidRequestId;
                break;
              case FDConfirmWithdrawRequestErrors.CanModifyOnlySelfRequest:
                store.error =
                  FDConfirmWithdrawRequestErrors.CanModifyOnlySelfRequest;
                break;
              case FDConfirmWithdrawRequestErrors.RequestNotApproved:
                store.error = FDConfirmWithdrawRequestErrors.RequestNotApproved;
                break;
              case FDConfirmWithdrawRequestErrors.InvalidWithdrawAmount:
                store.fields.setShowWarning(
                  i18next.t("fd.dealRequest.errors.withdrawWarningMessage"),
                );
                break;
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in confirmWithdrawRequestRPC`,
                );
            }
          } else {
            logger.error("Unhandled error");
          }
        }
      }),
    };
  });

export const createConfirmWithdrawRequestStore = (): Instance<
  typeof ConfirmWithdrawRequestStore
> => {
  return ConfirmWithdrawRequestStore.create({
    fields: createFDWithdrawModel(),
  });
};
