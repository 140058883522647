"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFAmcListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mFAmc_1 = require("./mFAmc");
/* eslint-disable import/export */
class GetMFAmcListRPC {
}
exports.GetMFAmcListRPC = GetMFAmcListRPC;
(function (GetMFAmcListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFAmcListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor() {
            this.isGetMFAmcListRPCRequest = true;
        }
        toDTO() {
            return {};
        }
    }
    GetMFAmcListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `WithAuthRPC.Request()`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFAmcListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(amcs) {
            this.isGetMFAmcListRPCResponse = true;
            Response.validateAmcs(amcs);
            this.amcs = amcs;
        }
        static fromDTO(dto) {
            const amcs = (0, leo_ts_runtime_1.getList)(dto, "amcs", mFAmc_1.MFAmc.fromDTO, InvalidResponseError);
            return new Response(amcs);
        }
        toDTO() {
            const ret = {
                amcs: this.amcs.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(amcs = this.amcs) {
            return new Response(amcs);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFAmcListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.amcs, other.amcs)) {
                return false;
            }
            return true;
        }
    }
    Response.validateAmcs = (amcs) => {
        if (!Array.isArray(amcs)) {
            throw new InvalidResponseError(`Attribute amcs is not a List`);
        }
        for (let i = 0; i < amcs.length; i += 1) {
            const isMFAmcValue = amcs[i].isMFAmc;
            if (!(amcs[i] instanceof mFAmc_1.MFAmc || Boolean(isMFAmcValue))) {
                throw new InvalidResponseError(`Attribute amcs at index ${i} is not a MFAmc.`);
            }
        }
    };
    GetMFAmcListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFAmcListRPC.Response(amcs=${this.amcs})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetMFAmcListRPC.Errors || (GetMFAmcListRPC.Errors = {}));
})(GetMFAmcListRPC = exports.GetMFAmcListRPC || (exports.GetMFAmcListRPC = {}));
