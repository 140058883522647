"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdInvestmentDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const chargeAmount_1 = require("../types/chargeAmount");
const cashflowTimelineItem_1 = require("../types/cashflowTimelineItem");
const interestType_1 = require("../types/interestType");
const payoutFrequency_1 = require("../types/payoutFrequency");
/* eslint-disable import/export */
class GetFdInvestmentDetailsRPC {
}
exports.GetFdInvestmentDetailsRPC = GetFdInvestmentDetailsRPC;
(function (GetFdInvestmentDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFdInvestmentDetailsRPC.InvalidRequestError = InvalidRequestError;
    let RequestEnums;
    (function (RequestEnums) {
        let RequestType;
        (function (RequestType) {
            class DealDetails {
                constructor(fdDealRequestId) {
                    this.isDealDetails = true;
                    DealDetails.validateFdDealRequestId(fdDealRequestId);
                    this.fdDealRequestId = fdDealRequestId;
                }
                static fromDTO(dto) {
                    const fdDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "fdDealRequestId", InvalidRequestError);
                    return new DealDetails(fdDealRequestId);
                }
                toDTO() {
                    const ret = {
                        case: "DEAL_DETAILS",
                        fdDealRequestId: this.fdDealRequestId,
                    };
                    return ret;
                }
                copy(fdDealRequestId = this.fdDealRequestId) {
                    return new DealDetails(fdDealRequestId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isDealDetailsValue = other.isDealDetails;
                    if (!(other instanceof DealDetails) || Boolean(isDealDetailsValue)) {
                        return false;
                    }
                    if (this.fdDealRequestId !== other.fdDealRequestId) {
                        return false;
                    }
                    return true;
                }
            }
            DealDetails.validateFdDealRequestId = (fdDealRequestId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fdDealRequestId)) {
                    throw new InvalidRequestError(`Attribute fdDealRequestId is not an Int64`);
                }
            };
            RequestType.DealDetails = DealDetails;
            DealDetails.prototype.toString = function toString() {
                return `RequestType.DEAL_DETAILS(fdDealRequestId=${this.fdDealRequestId})`;
            };
            class InvestmentDetails {
                constructor(fdInvestmentId) {
                    this.isInvestmentDetails = true;
                    InvestmentDetails.validateFdInvestmentId(fdInvestmentId);
                    this.fdInvestmentId = fdInvestmentId;
                }
                static fromDTO(dto) {
                    const fdInvestmentId = (0, leo_ts_runtime_1.getInt64)(dto, "fdInvestmentId", InvalidRequestError);
                    return new InvestmentDetails(fdInvestmentId);
                }
                toDTO() {
                    const ret = {
                        case: "INVESTMENT_DETAILS",
                        fdInvestmentId: this.fdInvestmentId,
                    };
                    return ret;
                }
                copy(fdInvestmentId = this.fdInvestmentId) {
                    return new InvestmentDetails(fdInvestmentId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isInvestmentDetailsValue = other.isInvestmentDetails;
                    if (!(other instanceof InvestmentDetails) || Boolean(isInvestmentDetailsValue)) {
                        return false;
                    }
                    if (this.fdInvestmentId !== other.fdInvestmentId) {
                        return false;
                    }
                    return true;
                }
            }
            InvestmentDetails.validateFdInvestmentId = (fdInvestmentId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fdInvestmentId)) {
                    throw new InvalidRequestError(`Attribute fdInvestmentId is not an Int64`);
                }
            };
            RequestType.InvestmentDetails = InvestmentDetails;
            InvestmentDetails.prototype.toString = function toString() {
                return `RequestType.INVESTMENT_DETAILS(fdInvestmentId=${this.fdInvestmentId})`;
            };
            RequestType.fromDTO = (dto) => {
                switch (dto.case) {
                    case "DEAL_DETAILS":
                        return DealDetails.fromDTO(dto);
                    case "INVESTMENT_DETAILS":
                        return InvestmentDetails.fromDTO(dto);
                    default:
                        throw new InvalidRequestError(`Case ${dto.case} is not valid case of RequestType.`);
                }
            };
            RequestType.isInstanceOf = (other) => {
                const isDEAL_DETAILSValue = other.isDEAL_DETAILS;
                if (other instanceof DealDetails || Boolean(isDEAL_DETAILSValue)) {
                    return true;
                }
                const isINVESTMENT_DETAILSValue = other.isINVESTMENT_DETAILS;
                if (other instanceof InvestmentDetails || Boolean(isINVESTMENT_DETAILSValue)) {
                    return true;
                }
                return false;
            };
        })(RequestType = RequestEnums.RequestType || (RequestEnums.RequestType = {}));
    })(RequestEnums = GetFdInvestmentDetailsRPC.RequestEnums || (GetFdInvestmentDetailsRPC.RequestEnums = {}));
    class Request {
        constructor(requestType) {
            this.isGetFdInvestmentDetailsRPCRequest = true;
            Request.validateRequestType(requestType);
            this.requestType = requestType;
        }
        static fromDTO(dto) {
            const requestType = RequestEnums.RequestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestType", InvalidRequestError));
            return new Request(requestType);
        }
        toDTO() {
            const ret = {
                requestType: this.requestType.toDTO(),
            };
            return ret;
        }
        copy(requestType = this.requestType) {
            return new Request(requestType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFdInvestmentDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.requestType.equals(other.requestType)) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestType = (requestType) => {
        if (!RequestEnums.RequestType.isInstanceOf(requestType)) {
            throw new InvalidRequestError(`Attribute requestType is not a RequestEnums.RequestType`);
        }
    };
    GetFdInvestmentDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFdInvestmentDetailsRPC.Request(requestType=${this.requestType})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFdInvestmentDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(interestType, depositBankName, payoutFrequency, maturityDate, isMaturityDateFixed, valueDate, isValueDateFixed, maturityAmount, paymentBankAccountNumber, paymentBankName, interestRate, interestReceivedTillDate = null, depositBankAccountNumber = null, cashFlowList, isViewCashFlowButtonEnabled, fdInvestmentId = null) {
            this.isGetFdInvestmentDetailsRPCResponse = true;
            Response.validateInterestType(interestType);
            this.interestType = interestType;
            Response.validateDepositBankName(depositBankName);
            this.depositBankName = depositBankName;
            Response.validatePayoutFrequency(payoutFrequency);
            this.payoutFrequency = payoutFrequency;
            Response.validateMaturityDate(maturityDate);
            this.maturityDate = maturityDate;
            Response.validateIsMaturityDateFixed(isMaturityDateFixed);
            this.isMaturityDateFixed = isMaturityDateFixed;
            Response.validateValueDate(valueDate);
            this.valueDate = valueDate;
            Response.validateIsValueDateFixed(isValueDateFixed);
            this.isValueDateFixed = isValueDateFixed;
            Response.validateMaturityAmount(maturityAmount);
            this.maturityAmount = maturityAmount;
            Response.validatePaymentBankAccountNumber(paymentBankAccountNumber);
            this.paymentBankAccountNumber = paymentBankAccountNumber;
            Response.validatePaymentBankName(paymentBankName);
            this.paymentBankName = paymentBankName;
            Response.validateInterestRate(interestRate);
            this.interestRate = interestRate;
            if (interestReceivedTillDate !== undefined && interestReceivedTillDate !== null) {
                Response.validateInterestReceivedTillDate(interestReceivedTillDate);
            }
            this.interestReceivedTillDate = interestReceivedTillDate;
            if (depositBankAccountNumber !== undefined && depositBankAccountNumber !== null) {
                Response.validateDepositBankAccountNumber(depositBankAccountNumber);
            }
            this.depositBankAccountNumber = depositBankAccountNumber;
            Response.validateCashFlowList(cashFlowList);
            this.cashFlowList = cashFlowList;
            Response.validateIsViewCashFlowButtonEnabled(isViewCashFlowButtonEnabled);
            this.isViewCashFlowButtonEnabled = isViewCashFlowButtonEnabled;
            if (fdInvestmentId !== undefined && fdInvestmentId !== null) {
                Response.validateFdInvestmentId(fdInvestmentId);
            }
            this.fdInvestmentId = fdInvestmentId;
        }
        static fromDTO(dto) {
            const interestType = interestType_1.InterestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "interestType", InvalidResponseError));
            const depositBankName = (0, leo_ts_runtime_1.getString)(dto, "depositBankName", InvalidResponseError);
            const payoutFrequency = payoutFrequency_1.PayoutFrequency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "payoutFrequency", InvalidResponseError));
            const maturityDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "maturityDate", InvalidResponseError);
            const isMaturityDateFixed = (0, leo_ts_runtime_1.getBoolean)(dto, "isMaturityDateFixed", InvalidResponseError);
            const valueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "valueDate", InvalidResponseError);
            const isValueDateFixed = (0, leo_ts_runtime_1.getBoolean)(dto, "isValueDateFixed", InvalidResponseError);
            const maturityAmount = chargeAmount_1.ChargeAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "maturityAmount", InvalidResponseError));
            const paymentBankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "paymentBankAccountNumber", InvalidResponseError);
            const paymentBankName = (0, leo_ts_runtime_1.getString)(dto, "paymentBankName", InvalidResponseError);
            const interestRate = (0, leo_ts_runtime_1.getInt32)(dto, "interestRate", InvalidResponseError);
            let interestReceivedTillDate = (0, leo_ts_runtime_1.getOptionalObject)(dto, "interestReceivedTillDate");
            if (interestReceivedTillDate !== undefined && interestReceivedTillDate !== null) {
                interestReceivedTillDate = chargeAmount_1.ChargeAmount.fromDTO(interestReceivedTillDate);
            }
            const depositBankAccountNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "depositBankAccountNumber", InvalidResponseError);
            const cashFlowList = (0, leo_ts_runtime_1.getList)(dto, "cashFlowList", cashflowTimelineItem_1.CashflowTimelineItem.fromDTO, InvalidResponseError);
            const isViewCashFlowButtonEnabled = (0, leo_ts_runtime_1.getBoolean)(dto, "isViewCashFlowButtonEnabled", InvalidResponseError);
            const fdInvestmentId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "fdInvestmentId", InvalidResponseError);
            return new Response(interestType, depositBankName, payoutFrequency, maturityDate, isMaturityDateFixed, valueDate, isValueDateFixed, maturityAmount, paymentBankAccountNumber, paymentBankName, interestRate, interestReceivedTillDate, depositBankAccountNumber, cashFlowList, isViewCashFlowButtonEnabled, fdInvestmentId);
        }
        toDTO() {
            const ret = {
                interestType: interestType_1.InterestType.toDTO(this.interestType),
                depositBankName: this.depositBankName,
                payoutFrequency: payoutFrequency_1.PayoutFrequency.toDTO(this.payoutFrequency),
                maturityDate: this.maturityDate.toDTO(),
                isMaturityDateFixed: this.isMaturityDateFixed,
                valueDate: this.valueDate.toDTO(),
                isValueDateFixed: this.isValueDateFixed,
                maturityAmount: this.maturityAmount.toDTO(),
                paymentBankAccountNumber: this.paymentBankAccountNumber,
                paymentBankName: this.paymentBankName,
                interestRate: this.interestRate,
                cashFlowList: this.cashFlowList.map((e) => {
                    return e.toDTO();
                }),
                isViewCashFlowButtonEnabled: this.isViewCashFlowButtonEnabled,
            };
            if (this.interestReceivedTillDate) {
                ret.interestReceivedTillDate = this.interestReceivedTillDate.toDTO();
            }
            if (this.depositBankAccountNumber !== undefined && this.depositBankAccountNumber !== null) {
                ret.depositBankAccountNumber = this.depositBankAccountNumber;
            }
            if (this.fdInvestmentId !== undefined && this.fdInvestmentId !== null) {
                ret.fdInvestmentId = this.fdInvestmentId;
            }
            return ret;
        }
        copy(interestType = this.interestType, depositBankName = this.depositBankName, payoutFrequency = this.payoutFrequency, maturityDate = this.maturityDate, isMaturityDateFixed = this.isMaturityDateFixed, valueDate = this.valueDate, isValueDateFixed = this.isValueDateFixed, maturityAmount = this.maturityAmount, paymentBankAccountNumber = this.paymentBankAccountNumber, paymentBankName = this.paymentBankName, interestRate = this.interestRate, interestReceivedTillDate = this.interestReceivedTillDate, depositBankAccountNumber = this.depositBankAccountNumber, cashFlowList = this.cashFlowList, isViewCashFlowButtonEnabled = this.isViewCashFlowButtonEnabled, fdInvestmentId = this.fdInvestmentId) {
            return new Response(interestType, depositBankName, payoutFrequency, maturityDate, isMaturityDateFixed, valueDate, isValueDateFixed, maturityAmount, paymentBankAccountNumber, paymentBankName, interestRate, interestReceivedTillDate, depositBankAccountNumber, cashFlowList, isViewCashFlowButtonEnabled, fdInvestmentId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFdInvestmentDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.interestType !== other.interestType) {
                return false;
            }
            if (this.depositBankName !== other.depositBankName) {
                return false;
            }
            if (this.payoutFrequency !== other.payoutFrequency) {
                return false;
            }
            if (!this.maturityDate.equals(other.maturityDate)) {
                return false;
            }
            if (this.isMaturityDateFixed !== other.isMaturityDateFixed) {
                return false;
            }
            if (!this.valueDate.equals(other.valueDate)) {
                return false;
            }
            if (this.isValueDateFixed !== other.isValueDateFixed) {
                return false;
            }
            if (!this.maturityAmount.equals(other.maturityAmount)) {
                return false;
            }
            if (this.paymentBankAccountNumber !== other.paymentBankAccountNumber) {
                return false;
            }
            if (this.paymentBankName !== other.paymentBankName) {
                return false;
            }
            if (this.interestRate !== other.interestRate) {
                return false;
            }
            if (this.interestReceivedTillDate) {
                if (!this.interestReceivedTillDate.equals(other.interestReceivedTillDate)) {
                    return false;
                }
            }
            if (this.depositBankAccountNumber !== other.depositBankAccountNumber) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.cashFlowList, other.cashFlowList)) {
                return false;
            }
            if (this.isViewCashFlowButtonEnabled !== other.isViewCashFlowButtonEnabled) {
                return false;
            }
            if (this.fdInvestmentId !== other.fdInvestmentId) {
                return false;
            }
            return true;
        }
    }
    Response.validateInterestType = (interestType) => {
        if (!interestType_1.InterestType.isInstanceOf(interestType)) {
            throw new InvalidResponseError(`Attribute interestType is not a InterestType.InterestType`);
        }
    };
    Response.validateDepositBankName = (depositBankName) => {
        if (!(0, leo_ts_runtime_1.isString)(depositBankName)) {
            throw new InvalidResponseError(`Attribute depositBankName is not a String`);
        }
        if (depositBankName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute depositBankName cannot be an empty string.`);
        }
    };
    Response.validatePayoutFrequency = (payoutFrequency) => {
        if (!payoutFrequency_1.PayoutFrequency.isInstanceOf(payoutFrequency)) {
            throw new InvalidResponseError(`Attribute payoutFrequency is not a PayoutFrequency.PayoutFrequency`);
        }
    };
    Response.validateMaturityDate = (maturityDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
            throw new InvalidResponseError(`Attribute maturityDate is not a Date`);
        }
    };
    Response.validateIsMaturityDateFixed = (isMaturityDateFixed) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isMaturityDateFixed)) {
            throw new InvalidResponseError(`Attribute isMaturityDateFixed is not a Boolean`);
        }
    };
    Response.validateValueDate = (valueDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(valueDate)) {
            throw new InvalidResponseError(`Attribute valueDate is not a Date`);
        }
    };
    Response.validateIsValueDateFixed = (isValueDateFixed) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isValueDateFixed)) {
            throw new InvalidResponseError(`Attribute isValueDateFixed is not a Boolean`);
        }
    };
    Response.validateMaturityAmount = (maturityAmount) => {
        const isChargeAmountValue = maturityAmount.isChargeAmount;
        if (!(maturityAmount instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
            throw new InvalidResponseError(`Attribute maturityAmount is not a ChargeAmount`);
        }
    };
    Response.validatePaymentBankAccountNumber = (paymentBankAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(paymentBankAccountNumber)) {
            throw new InvalidResponseError(`Attribute paymentBankAccountNumber is not a String`);
        }
        if (paymentBankAccountNumber.trim().length === 0) {
            throw new InvalidResponseError(`Attribute paymentBankAccountNumber cannot be an empty string.`);
        }
    };
    Response.validatePaymentBankName = (paymentBankName) => {
        if (!(0, leo_ts_runtime_1.isString)(paymentBankName)) {
            throw new InvalidResponseError(`Attribute paymentBankName is not a String`);
        }
        if (paymentBankName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute paymentBankName cannot be an empty string.`);
        }
    };
    Response.validateInterestRate = (interestRate) => {
        if (!(0, leo_ts_runtime_1.isInt32)(interestRate)) {
            throw new InvalidResponseError(`Attribute interestRate is not an Int32`);
        }
    };
    Response.validateInterestReceivedTillDate = (interestReceivedTillDate) => {
        const isChargeAmountValue = interestReceivedTillDate.isChargeAmount;
        if (!(interestReceivedTillDate instanceof chargeAmount_1.ChargeAmount || Boolean(isChargeAmountValue))) {
            throw new InvalidResponseError(`Attribute interestReceivedTillDate is not a ChargeAmount`);
        }
    };
    Response.validateDepositBankAccountNumber = (depositBankAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(depositBankAccountNumber)) {
            throw new InvalidResponseError(`Attribute depositBankAccountNumber is not a String`);
        }
        if (depositBankAccountNumber.trim().length === 0) {
            throw new InvalidResponseError(`Attribute depositBankAccountNumber cannot be an empty string.`);
        }
    };
    Response.validateCashFlowList = (cashFlowList) => {
        if (!Array.isArray(cashFlowList)) {
            throw new InvalidResponseError(`Attribute cashFlowList is not a List`);
        }
        for (let i = 0; i < cashFlowList.length; i += 1) {
            const isCashflowTimelineItemValue = cashFlowList[i].isCashflowTimelineItem;
            if (!(cashFlowList[i] instanceof cashflowTimelineItem_1.CashflowTimelineItem || Boolean(isCashflowTimelineItemValue))) {
                throw new InvalidResponseError(`Attribute cashFlowList at index ${i} is not a CashflowTimelineItem.`);
            }
        }
    };
    Response.validateIsViewCashFlowButtonEnabled = (isViewCashFlowButtonEnabled) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isViewCashFlowButtonEnabled)) {
            throw new InvalidResponseError(`Attribute isViewCashFlowButtonEnabled is not a Boolean`);
        }
    };
    Response.validateFdInvestmentId = (fdInvestmentId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(fdInvestmentId)) {
            throw new InvalidResponseError(`Attribute fdInvestmentId is not an Int64`);
        }
    };
    GetFdInvestmentDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFdInvestmentDetailsRPC.Response(interestType=${this.interestType},depositBankName='${this.depositBankName}',payoutFrequency=${this.payoutFrequency},maturityDate=${this.maturityDate},isMaturityDateFixed=${this.isMaturityDateFixed},valueDate=${this.valueDate},isValueDateFixed=${this.isValueDateFixed},maturityAmount=${this.maturityAmount},paymentBankAccountNumber='${this.paymentBankAccountNumber}',paymentBankName='${this.paymentBankName}',interestRate=${this.interestRate},interestReceivedTillDate=${this.interestReceivedTillDate},depositBankAccountNumber='${this.depositBankAccountNumber}',cashFlowList=${this.cashFlowList},isViewCashFlowButtonEnabled=${this.isViewCashFlowButtonEnabled},fdInvestmentId=${this.fdInvestmentId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidDealRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_REQUEST_ID") {
                super(code);
                this.isInvalidDealRequestId = true;
                InvalidDealRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
                if (!(other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealRequestId = InvalidDealRequestId;
        InvalidDealRequestId.prototype.toString = function toString() {
            return `GetFdInvestmentDetailsRPC.INVALID_DEAL_REQUEST_ID()`;
        };
        class InvalidInvestmentId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_INVESTMENT_ID") {
                super(code);
                this.isInvalidInvestmentId = true;
                InvalidInvestmentId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidInvestmentId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidInvestmentId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidInvestmentIdValue = other.isInvalidInvestmentId;
                if (!(other instanceof InvalidInvestmentId || Boolean(isInvalidInvestmentIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidInvestmentId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_INVESTMENT_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidInvestmentId = InvalidInvestmentId;
        InvalidInvestmentId.prototype.toString = function toString() {
            return `GetFdInvestmentDetailsRPC.INVALID_INVESTMENT_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_DEAL_REQUEST_ID":
                    return InvalidDealRequestId.fromDTO(dto);
                case "INVALID_INVESTMENT_ID":
                    return InvalidInvestmentId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
            if (other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue)) {
                return true;
            }
            const isInvalidInvestmentIdValue = other.isInvalidInvestmentId;
            if (other instanceof InvalidInvestmentId || Boolean(isInvalidInvestmentIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFdInvestmentDetailsRPC.Errors || (GetFdInvestmentDetailsRPC.Errors = {}));
})(GetFdInvestmentDetailsRPC = exports.GetFdInvestmentDetailsRPC || (exports.GetFdInvestmentDetailsRPC = {}));
