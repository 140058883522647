"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFSettlementDetails = exports.InvalidMFSettlementDetailsError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
class InvalidMFSettlementDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFSettlementDetailsError";
    }
}
exports.InvalidMFSettlementDetailsError = InvalidMFSettlementDetailsError;
var MFSettlementDetails;
(function (MFSettlementDetails) {
    class AmountSettlementDetail {
        constructor(requestedAmount, executedAmount, pendingAmount, currency, isAmountExcess) {
            this.isAmountSettlementDetail = true;
            AmountSettlementDetail.validateRequestedAmount(requestedAmount);
            this.requestedAmount = requestedAmount;
            AmountSettlementDetail.validateExecutedAmount(executedAmount);
            this.executedAmount = executedAmount;
            AmountSettlementDetail.validatePendingAmount(pendingAmount);
            this.pendingAmount = pendingAmount;
            AmountSettlementDetail.validateCurrency(currency);
            this.currency = currency;
            AmountSettlementDetail.validateIsAmountExcess(isAmountExcess);
            this.isAmountExcess = isAmountExcess;
        }
        static fromDTO(dto) {
            const requestedAmount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedAmount", InvalidMFSettlementDetailsError));
            const executedAmount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedAmount", InvalidMFSettlementDetailsError));
            const pendingAmount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pendingAmount", InvalidMFSettlementDetailsError));
            const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidMFSettlementDetailsError));
            const isAmountExcess = (0, leo_ts_runtime_1.getBoolean)(dto, "isAmountExcess", InvalidMFSettlementDetailsError);
            return new AmountSettlementDetail(requestedAmount, executedAmount, pendingAmount, currency, isAmountExcess);
        }
        toDTO() {
            const ret = {
                case: "AMOUNT_SETTLEMENT_DETAIL",
                requestedAmount: this.requestedAmount.toDTO(),
                executedAmount: this.executedAmount.toDTO(),
                pendingAmount: this.pendingAmount.toDTO(),
                currency: this.currency.toDTO(),
                isAmountExcess: this.isAmountExcess,
            };
            return ret;
        }
        copy(requestedAmount = this.requestedAmount, executedAmount = this.executedAmount, pendingAmount = this.pendingAmount, currency = this.currency, isAmountExcess = this.isAmountExcess) {
            return new AmountSettlementDetail(requestedAmount, executedAmount, pendingAmount, currency, isAmountExcess);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAmountSettlementDetailValue = other.isAmountSettlementDetail;
            if (!(other instanceof AmountSettlementDetail) || Boolean(isAmountSettlementDetailValue)) {
                return false;
            }
            if (!this.requestedAmount.equals(other.requestedAmount)) {
                return false;
            }
            if (!this.executedAmount.equals(other.executedAmount)) {
                return false;
            }
            if (!this.pendingAmount.equals(other.pendingAmount)) {
                return false;
            }
            if (!this.currency.equals(other.currency)) {
                return false;
            }
            if (this.isAmountExcess !== other.isAmountExcess) {
                return false;
            }
            return true;
        }
    }
    AmountSettlementDetail.validateRequestedAmount = (requestedAmount) => {
        const isBigDecimalValue = requestedAmount.isBigDecimal;
        if (!(requestedAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFSettlementDetailsError(`Attribute requestedAmount is not a BigDecimal`);
        }
    };
    AmountSettlementDetail.validateExecutedAmount = (executedAmount) => {
        const isBigDecimalValue = executedAmount.isBigDecimal;
        if (!(executedAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFSettlementDetailsError(`Attribute executedAmount is not a BigDecimal`);
        }
    };
    AmountSettlementDetail.validatePendingAmount = (pendingAmount) => {
        const isBigDecimalValue = pendingAmount.isBigDecimal;
        if (!(pendingAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFSettlementDetailsError(`Attribute pendingAmount is not a BigDecimal`);
        }
    };
    AmountSettlementDetail.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidMFSettlementDetailsError(`Attribute currency is not a Currency`);
        }
    };
    AmountSettlementDetail.validateIsAmountExcess = (isAmountExcess) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isAmountExcess)) {
            throw new InvalidMFSettlementDetailsError(`Attribute isAmountExcess is not a Boolean`);
        }
    };
    MFSettlementDetails.AmountSettlementDetail = AmountSettlementDetail;
    AmountSettlementDetail.prototype.toString = function toString() {
        return `MFSettlementDetails.AMOUNT_SETTLEMENT_DETAIL(requestedAmount=${this.requestedAmount},executedAmount=${this.executedAmount},pendingAmount=${this.pendingAmount},currency=${this.currency},isAmountExcess=${this.isAmountExcess})`;
    };
    class UnitsSettlementDetail {
        constructor(requestedUnits, executedUnits, pendingUnits, isUnitsExcess) {
            this.isUnitsSettlementDetail = true;
            UnitsSettlementDetail.validateRequestedUnits(requestedUnits);
            this.requestedUnits = requestedUnits;
            UnitsSettlementDetail.validateExecutedUnits(executedUnits);
            this.executedUnits = executedUnits;
            UnitsSettlementDetail.validatePendingUnits(pendingUnits);
            this.pendingUnits = pendingUnits;
            UnitsSettlementDetail.validateIsUnitsExcess(isUnitsExcess);
            this.isUnitsExcess = isUnitsExcess;
        }
        static fromDTO(dto) {
            const requestedUnits = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestedUnits", InvalidMFSettlementDetailsError));
            const executedUnits = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedUnits", InvalidMFSettlementDetailsError));
            const pendingUnits = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pendingUnits", InvalidMFSettlementDetailsError));
            const isUnitsExcess = (0, leo_ts_runtime_1.getBoolean)(dto, "isUnitsExcess", InvalidMFSettlementDetailsError);
            return new UnitsSettlementDetail(requestedUnits, executedUnits, pendingUnits, isUnitsExcess);
        }
        toDTO() {
            const ret = {
                case: "UNITS_SETTLEMENT_DETAIL",
                requestedUnits: this.requestedUnits.toDTO(),
                executedUnits: this.executedUnits.toDTO(),
                pendingUnits: this.pendingUnits.toDTO(),
                isUnitsExcess: this.isUnitsExcess,
            };
            return ret;
        }
        copy(requestedUnits = this.requestedUnits, executedUnits = this.executedUnits, pendingUnits = this.pendingUnits, isUnitsExcess = this.isUnitsExcess) {
            return new UnitsSettlementDetail(requestedUnits, executedUnits, pendingUnits, isUnitsExcess);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isUnitsSettlementDetailValue = other.isUnitsSettlementDetail;
            if (!(other instanceof UnitsSettlementDetail) || Boolean(isUnitsSettlementDetailValue)) {
                return false;
            }
            if (!this.requestedUnits.equals(other.requestedUnits)) {
                return false;
            }
            if (!this.executedUnits.equals(other.executedUnits)) {
                return false;
            }
            if (!this.pendingUnits.equals(other.pendingUnits)) {
                return false;
            }
            if (this.isUnitsExcess !== other.isUnitsExcess) {
                return false;
            }
            return true;
        }
    }
    UnitsSettlementDetail.validateRequestedUnits = (requestedUnits) => {
        const isBigDecimalValue = requestedUnits.isBigDecimal;
        if (!(requestedUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFSettlementDetailsError(`Attribute requestedUnits is not a BigDecimal`);
        }
    };
    UnitsSettlementDetail.validateExecutedUnits = (executedUnits) => {
        const isBigDecimalValue = executedUnits.isBigDecimal;
        if (!(executedUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFSettlementDetailsError(`Attribute executedUnits is not a BigDecimal`);
        }
    };
    UnitsSettlementDetail.validatePendingUnits = (pendingUnits) => {
        const isBigDecimalValue = pendingUnits.isBigDecimal;
        if (!(pendingUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFSettlementDetailsError(`Attribute pendingUnits is not a BigDecimal`);
        }
    };
    UnitsSettlementDetail.validateIsUnitsExcess = (isUnitsExcess) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isUnitsExcess)) {
            throw new InvalidMFSettlementDetailsError(`Attribute isUnitsExcess is not a Boolean`);
        }
    };
    MFSettlementDetails.UnitsSettlementDetail = UnitsSettlementDetail;
    UnitsSettlementDetail.prototype.toString = function toString() {
        return `MFSettlementDetails.UNITS_SETTLEMENT_DETAIL(requestedUnits=${this.requestedUnits},executedUnits=${this.executedUnits},pendingUnits=${this.pendingUnits},isUnitsExcess=${this.isUnitsExcess})`;
    };
    MFSettlementDetails.fromDTO = (dto) => {
        switch (dto.case) {
            case "AMOUNT_SETTLEMENT_DETAIL":
                return AmountSettlementDetail.fromDTO(dto);
            case "UNITS_SETTLEMENT_DETAIL":
                return UnitsSettlementDetail.fromDTO(dto);
            default:
                throw new InvalidMFSettlementDetailsError(`Case ${dto.case} is not valid case of MFSettlementDetails.`);
        }
    };
    MFSettlementDetails.isInstanceOf = (other) => {
        const isAMOUNT_SETTLEMENT_DETAILValue = other.isAMOUNT_SETTLEMENT_DETAIL;
        if (other instanceof AmountSettlementDetail || Boolean(isAMOUNT_SETTLEMENT_DETAILValue)) {
            return true;
        }
        const isUNITS_SETTLEMENT_DETAILValue = other.isUNITS_SETTLEMENT_DETAIL;
        if (other instanceof UnitsSettlementDetail || Boolean(isUNITS_SETTLEMENT_DETAILValue)) {
            return true;
        }
        return false;
    };
})(MFSettlementDetails = exports.MFSettlementDetails || (exports.MFSettlementDetails = {}));
