import {
  Currency,
  FiMaturityDealRequestStatus,
  GetFiMaturityDealRequestsPaginationResponse,
  GetFiMaturityDealRequestsRPC,
  FixedIncomeSortOrder,
  ISIN,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

const fiDealRequests: GetFiMaturityDealRequestsPaginationResponse[] = [
  new GetFiMaturityDealRequestsPaginationResponse(
    1,
    "ICICI Prudential Corporate Bond Fund",
    FiMaturityDealRequestStatus.FiMaturityDealRequestStatus.SETTLED,
    new ISIN("INE100002342"),
    "Peter",
    "Compounding",
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    "100",
    "97.53",
    "5000000",
    "48764400",
    new Currency("INR", "₹"),
  ),
  new GetFiMaturityDealRequestsPaginationResponse(
    2,
    "ICICI Prudential Corporate Bond Fund",
    FiMaturityDealRequestStatus.FiMaturityDealRequestStatus.SETTLED,
    new ISIN("INE100002342"),
    "Clarkson",
    "Compounding",
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    "100",
    "97.53",
    "5000000",
    "48764400",
    new Currency("INR", "₹"),
  ),
  new GetFiMaturityDealRequestsPaginationResponse(
    3,
    "ICICI Prudential Corporate Bond Fund",
    FiMaturityDealRequestStatus.FiMaturityDealRequestStatus.SETTLEMENT_PENDING,
    new ISIN("INE100002342"),
    "Peter",
    "Opportunities",
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    "100",
    "97.53",
    "5000000",
    "48764400",
    new Currency("INR", "₹"),
  ),
  new GetFiMaturityDealRequestsPaginationResponse(
    4,
    "ICICI Prudential Corporate Bond Fund",
    FiMaturityDealRequestStatus.FiMaturityDealRequestStatus.SETTLEMENT_PENDING,
    new ISIN("INE100002342"),
    "Clarkson",
    "Opportunities",
    new LeoDate(),
    new LeoDate(),
    new LeoDate(),
    "100",
    "97.53",
    "5000000",
    "48764400",
    new Currency("INR", "₹"),
  ),
];

export class MockGetFiMaturityDealRequestsRPCImpl extends GetFiMaturityDealRequestsRPC {
  execute(
    request: GetFiMaturityDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiMaturityDealRequestsRPC.Response,
      GetFiMaturityDealRequestsRPC.Errors.InvalidPageIndex
    >
  > {
    let dealRequests = fiDealRequests;
    if (request.dealRequestStatus) {
      dealRequests = dealRequests.filter((t) => {
        return t.dealRequestStatus === request.dealRequestStatus;
      });
    }
    if (request.requestId) {
      dealRequests = dealRequests.filter((t) => {
        return t.requestId
          .toString()
          .toLocaleLowerCase()
          .includes(request.requestId?.toLocaleLowerCase()!);
      });
    }
    if (request.portfolio) {
      dealRequests = dealRequests.filter((t) => {
        return t.portfolio
          ?.toLocaleLowerCase()
          .includes(request.portfolio?.toLocaleLowerCase()!);
      });
    }
    if (request.isin) {
      dealRequests = dealRequests.filter((t) => {
        return t.isin.isin
          .toLocaleLowerCase()
          .includes(request.isin?.toLocaleLowerCase()!);
      });
    }
    if (request.entity) {
      dealRequests = dealRequests.filter((t) => {
        return t.entity
          .toLocaleLowerCase()
          .includes(request.entity?.toLocaleLowerCase()!);
      });
    }
    if (
      request.sortList[0].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.DESCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(a.issueDate.date).valueOf() -
          new Date(b.issueDate.date).valueOf(),
      );
    } else if (
      request.sortList[0].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.ASCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(b.issueDate.date).valueOf() -
          new Date(a.issueDate.date).valueOf(),
      );
    }
    if (
      request.sortList[1].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.DESCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(a.valueDate.date).valueOf() -
          new Date(b.valueDate.date).valueOf(),
      );
    } else if (
      request.sortList[1].order ===
      FixedIncomeSortOrder.FixedIncomeSortOrder.ASCENDING
    ) {
      dealRequests.sort(
        (a, b): number =>
          new Date(b.valueDate.date).valueOf() -
          new Date(a.valueDate.date).valueOf(),
      );
    }
    const response = new GetFiMaturityDealRequestsRPC.Response(
      dealRequests.slice(
        request.pageIndex.items * request.itemsPerPage.items,
        request.itemsPerPage.items,
      ),
      dealRequests.length,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiMaturityDealRequestsRPC.Response,
        GetFiMaturityDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
