import { Instance, types } from "mobx-state-tree";
import { MFConfirmationActions } from "@khazana/khazana-rpcs";

export const MFConfirmationActionModel = types.model(
  "MFConfirmationActionModel",
  {
    allowEdit: types.optional(types.boolean, false),
    allowUnknownRequest: types.optional(types.boolean, false),
    allowLinkRequest: types.optional(types.boolean, false),
    allowContractCheck: types.optional(types.boolean, false),
    allowFolioCreationCheck: types.optional(types.boolean, false),
    allowLinkCheck: types.optional(types.boolean, false),
    allowUnknownCheck: types.optional(types.boolean, false),
  },
);

export const createMFConfirmationActionModel = (
  mfConfirmationAction?: MFConfirmationActions,
): Instance<typeof MFConfirmationActionModel> => {
  if (mfConfirmationAction) {
    return MFConfirmationActionModel.create({
      allowEdit: mfConfirmationAction.allowEdit,
      allowUnknownRequest: mfConfirmationAction.allowUnknownRequest,
      allowLinkRequest: mfConfirmationAction.allowLinkRequest,
      allowContractCheck: mfConfirmationAction.allowContractCheck,
      allowFolioCreationCheck: mfConfirmationAction.allowFolioCreationCheck,
      allowLinkCheck: mfConfirmationAction.allowLinkCheck,
      allowUnknownCheck: mfConfirmationAction.allowUnknownCheck,
    });
  }
  return MFConfirmationActionModel.create();
};
