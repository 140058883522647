import { cast, flow, types } from "mobx-state-tree";
import {
  FiTransactionType,
  FixedIncomeItemsPerPage,
  FixedIncomePageIndex,
  GetFiApprovedDealRequestsPaginationResponse,
  GetFiApprovedDealRequestsRPC,
  ISIN,
} from "@khazana/khazana-rpcs";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { FiLinkDealRequestModel } from "../models/FiLinkDealRequestModel";
import { useGetFiApprovedDealRequestRPCClientImpl } from "../rpcs/RPC";

export const FiLinkDealRequestStore = types
  .model({
    totalItems: types.optional(types.number, 0),
    requests: types.array(FiLinkDealRequestModel),
    currencySymbol: types.maybeNull(types.string),
  })
  .actions((store) => ({
    resetStore(): void {
      store.requests = cast([]);
    },
    getApprovedEquityDealRequests: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      dematAccountNumber: string,
      isin: string,
      transactionType: string,
    ) {
      const request = new GetFiApprovedDealRequestsRPC.Request(
        dematAccountNumber,
        new ISIN(isin),
        transactionType === FiTransactionType.FiTransactionType.FI_PURCHASE
          ? FiTransactionType.FiTransactionType.FI_PURCHASE
          : FiTransactionType.FiTransactionType.FI_SELL,
        new FixedIncomeItemsPerPage(itemsPerPage),
        new FixedIncomePageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetFiApprovedDealRequestsRPC.Response,
        GetFiApprovedDealRequestsRPC.Errors.InvalidPageIndex
      > =
        yield useGetFiApprovedDealRequestRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response
          .getFiApprovedDealRequestsPaginationResponse[0]?.currency
          ? response.getFiApprovedDealRequestsPaginationResponse[0].currency
              .symbol
          : null;
        const requests =
          response.getFiApprovedDealRequestsPaginationResponse.map(
            (_request: GetFiApprovedDealRequestsPaginationResponse) => {
              return FiLinkDealRequestModel.create({
                dealRequestId: _request.fiDealRequestId,
                dematAccountNumber: _request.dematAccountNumber,
                security: _request.security,
                ytm: _request.ytm,
                yieldType: _request.yieldType,
                amountHCY: _request.amountHCY,
                currency: CurrencyModel.create({
                  code: _request.currency.code,
                  symbol: _request.currency.symbol,
                }),
                quantity: _request.quantity,
                entityName: _request.entityName,
                status: _request.currentDealStatus,
                transactionType: _request.transactionType,
              });
            },
          );
        store.requests = cast(requests);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));
