import { getEnv, Instance, types } from "mobx-state-tree";
import {
  createDropdownField,
  createDropdownStoreFromDiff,
  DropdownFieldStore,
} from "../../../store/fieldStores/DropdownFieldStore";
import {
  AutocompleteFieldStore,
  createAutocompleteField,
  createAutocompleteFieldFromDiff,
} from "../../../store/fieldStores/AutocompleteFieldStore";
import {
  createEntityDropdownStore,
  createEntityDropdownStoreFromDiff,
  EntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  createTextField,
  createTextFieldFromDiff,
  TextFieldStore,
} from "../../../store/fieldStores/TextFieldStore";
import {
  createNumberField,
  createNumberFieldFromDiff,
  NumberFieldStore,
} from "../../../store/fieldStores/NumberFieldStore";
import {
  BigDecimal,
  ISIN,
  MFConfirmation,
  MFTransactionType,
  SHA256,
} from "@khazana/khazana-rpcs";
import { LeoUUID } from "@surya-digital/leo-ts-runtime";
import { DiffDetailType } from "../../../models/DiffDetailType";
import {
  DiffCellType,
  getDiffDetailCellValue,
} from "../../../utils/DiffDetailCellUtils";
import { MFConfirmationDetailsKeys } from "./MFConfirmationDetailsKeys";
import i18next from "i18next";
import { getFormattedAmountString } from "../../../../../utils";

export const MFConfirmationFields = types
  .model("MFConfirmationFields", {
    transactionType: DropdownFieldStore,
    amc: AutocompleteFieldStore,
    entity: EntityDropdownStore,
    folio: TextFieldStore,
    scheme: AutocompleteFieldStore,
    broker: DropdownFieldStore,
    referenceNumber: TextFieldStore,
    quantity: NumberFieldStore,
    navPerUnit: NumberFieldStore,
    entryExitLoad: NumberFieldStore,
    netAmount: NumberFieldStore,
    documentUrl: types.maybe(types.string),
    sha256: types.maybe(types.string),
  })
  .actions((store) => {
    return {
      setDocumentUrl: (documentUrl: string | undefined): void => {
        store.documentUrl = documentUrl;
      },
      setSha256: (sha256: string | undefined): void => {
        store.sha256 = sha256;
      },
      isValid: (): boolean => {
        let isValid = true;
        if (!store.transactionType.value) {
          store.transactionType.setErrorMessage("");
          isValid = false;
        }
        if (!store.amc.value) {
          store.amc.setErrorMessage("");
          isValid = false;
        }
        if (!store.entity.selectedEntity) {
          store.entity.setError("");
          isValid = false;
        }
        if (!store.folio.value) {
          store.folio.setErrorMessage("");
          isValid = false;
        }
        if (!store.scheme.value) {
          store.scheme.setErrorMessage("");
          isValid = false;
        }
        if (!Number(store.quantity.value)) {
          store.quantity.setErrorMessage("");
          isValid = false;
        }
        if (!Number(store.navPerUnit.value)) {
          store.navPerUnit.setErrorMessage("");
          isValid = false;
        }
        if (!Number(store.netAmount.value)) {
          store.netAmount.setErrorMessage("");
          isValid = false;
        }
        if (Number(store.quantity.value) && Number(store.navPerUnit.value)) {
          let netAmount =
            Number(store.quantity.value) * Number(store.navPerUnit.value);
          if (
            store.entryExitLoad.value &&
            store.transactionType.value ===
              MFTransactionType.MFTransactionType.MF_PURCHASE
          ) {
            netAmount += Number(store.entryExitLoad.value);
          } else if (
            store.entryExitLoad.value &&
            store.transactionType.value ===
              MFTransactionType.MFTransactionType.MF_REDEEM
          ) {
            netAmount -= Number(store.entryExitLoad.value);
          }
          if (netAmount !== Number(store.netAmount.value)) {
            store.netAmount.setErrorMessage(
              i18next.t("common.shouldBe", {
                val: getFormattedAmountString(netAmount),
              }),
            );
            isValid = false;
          }
        }
        return isValid;
      },
      getMFConfirmationRPCType: (): MFConfirmation => {
        const logger = getEnv(store).logger;
        if (
          !store.transactionType.value ||
          !store.amc.value ||
          !store.entity.selectedEntity ||
          !store.folio.value ||
          !store.scheme.value ||
          !store.quantity.value ||
          !store.navPerUnit.value ||
          !store.netAmount.value
        ) {
          logger.error("Required fields are missing");
          throw new Error("Required fields are missing");
        }
        try {
          return new MFConfirmation(
            MFTransactionType.fromDTO({ case: store.transactionType.value }),
            store.sha256 ? new SHA256(store.sha256) : null,
            Number(store.amc.value.id),
            new LeoUUID(store.entity.selectedEntity),
            store.folio.value,
            new ISIN(store.scheme.value.id),
            store.referenceNumber.value,
            store.broker.value ? new LeoUUID(store.broker.value) : null,
            new BigDecimal(store.quantity.value),
            new BigDecimal(store.navPerUnit.value),
            store.entryExitLoad.value
              ? new BigDecimal(store.entryExitLoad.value)
              : null,
            new BigDecimal(store.netAmount.value),
          );
        } catch (e) {
          logger.error("Invalid fields");
          throw new Error("Invalid fields");
        }
      },
    };
  });

export const createMFConfirmationFields = (): Instance<
  typeof MFConfirmationFields
> => {
  return MFConfirmationFields.create({
    transactionType: createDropdownField(),
    amc: createAutocompleteField(),
    entity: createEntityDropdownStore(),
    folio: createTextField(),
    scheme: createAutocompleteField(),
    broker: createDropdownField(),
    referenceNumber: createTextField(),
    quantity: createNumberField(),
    navPerUnit: createNumberField(),
    entryExitLoad: createNumberField(),
    netAmount: createNumberField(),
  });
};

export const createMFConfirmationFieldsFromRPC = (
  mfConfirmationFields: Instance<typeof DiffDetailType>[],
): Instance<typeof MFConfirmationFields> => {
  const transactionType = getDiffDetailCellValue(
    mfConfirmationFields,
    MFConfirmationDetailsKeys.transactionType,
  );
  let entryExitLoad: DiffCellType | undefined;
  if (
    transactionType?.diffValue &&
    MFTransactionType.fromDTO({ case: transactionType.diffValue as string }) ===
      MFTransactionType.MFTransactionType.MF_PURCHASE
  ) {
    entryExitLoad = getDiffDetailCellValue(
      mfConfirmationFields,
      MFConfirmationDetailsKeys.entryLoad,
    );
  } else if (
    transactionType?.value &&
    MFTransactionType.fromDTO({ case: transactionType.value as string }) ===
      MFTransactionType.MFTransactionType.MF_PURCHASE
  ) {
    entryExitLoad = getDiffDetailCellValue(
      mfConfirmationFields,
      MFConfirmationDetailsKeys.entryLoad,
    );
  } else {
    entryExitLoad = getDiffDetailCellValue(
      mfConfirmationFields,
      MFConfirmationDetailsKeys.exitLoad,
    );
  }
  return MFConfirmationFields.create({
    transactionType: createDropdownStoreFromDiff(transactionType),
    amc: createAutocompleteFieldFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.amc,
      ),
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.amcId,
      ),
    ),
    entity: createEntityDropdownStoreFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.entityId,
      ),
    ),
    folio: createTextFieldFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.folioNumber,
      ),
    ),
    scheme: createAutocompleteFieldFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.schemeName,
      ),
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.isin,
      ),
    ),
    broker: createDropdownStoreFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.brokerId,
      ),
    ),
    referenceNumber: createTextFieldFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.referenceNumber,
      ),
    ),
    quantity: createNumberFieldFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.units,
      ),
    ),
    navPerUnit: createNumberFieldFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.navPerUnit,
      ),
    ),
    entryExitLoad: createNumberFieldFromDiff(entryExitLoad),
    netAmount: createNumberFieldFromDiff(
      getDiffDetailCellValue(
        mfConfirmationFields,
        MFConfirmationDetailsKeys.netAmount,
      ),
    ),
  });
};
