import { Stack } from "@mui/material";
import { observer } from "mobx-react";
import {
  Breadcrumb,
  PageHeader,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DepositCertificateDetails } from "../components/DepositCertificateDetails";
import { FdUserPrivileges } from "../../../../user/UserPrivileges";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { useUserStore } from "../../../store/hooks";
import { CheckResponse, FDDealRequestStatus } from "@khazana/khazana-rpcs";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { createServerNoteRPCType } from "../../../../../utils";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import {
  FDCertificateDetailsErrors,
  FDDealInvalidRequestError,
} from "../store/FDDealRequestErrors";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { HeaderContainer } from "../../../components/page-header/HeaderContainer";
import { FDDealRequestDetailsKeys } from "../models/FDDealRequestDetailsKeys";
import { getDetailCellValue } from "../../../utils/DetailCellUtils";

const CreateDepositCertificateStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    body: {
      gap: spacing.space2XL,
      padding: spacing.space2XL,
      minWidth: "calc(100vw - 256px)",
    },
  };
};
export const DepositCertificateDetailsPage = observer(
  (): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const navigate = useNavigate();
    const [searchParam] = useSearchParams();
    const requestId = Number(searchParam.get("requestId"));
    const certificateId = Number(searchParam.get("certificateId"));
    const detailsStore = useFDDealRequestDetailsStore();
    const certificateStore = detailsStore.certificateStore;
    const privileges = useUserStore().privileges;
    const [dialogType, setDialogType] = useState<"Approve" | "Reject">(
      "Approve",
    );
    const [isDialogLoading, setIsDialogLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const style = useMemo(() => CreateDepositCertificateStyle(spacing), []);

    const getData = useCallback(() => {
      detailsStore.getFdDealDetails(requestId);
      if (privileges.includes(FdUserPrivileges.ViewFixedDepositCertificate)) {
        certificateStore?.getFDCertificateList(requestId);
      }
    }, []);

    useEffect(() => {
      getData();
      return (): void => {
        detailsStore.reset();
      };
    }, []);

    const getErrorDialogText = (): string => {
      switch (certificateStore.error) {
        case FDDealInvalidRequestError.InvalidRequestId:
        case FDCertificateDetailsErrors.InvalidCertificateId:
          return t("common.noResultsFound");
        default:
          return t("common.somethingWentWrong");
      }
    };

    const getDialog = (): React.ReactElement => {
      if (isDialogOpen) {
        let title = "";
        let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
        switch (dialogType) {
          case "Approve":
            title = t("common.approveDealRequest");
            onPrimaryButtonClick = async (
              note: string | undefined,
            ): Promise<void> => {
              if (
                privileges.includes(
                  FdUserPrivileges.CheckFixedDepositDealRequest,
                )
              ) {
                await detailsStore.checkFDDealRequestStore.checkICDealRequest(
                  Number(requestId),
                  new CheckResponse(
                    CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
                    createServerNoteRPCType(note),
                  ),
                );
              } else if (
                privileges.includes(
                  FdUserPrivileges.CheckFixedDepositCertificateRequest,
                )
              ) {
                await detailsStore.checkFDDealRequestStore.checkACDealRequest(
                  Number(requestId),
                  Number(certificateStore.certificateList[0].certificateId),
                  new CheckResponse(
                    CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
                    createServerNoteRPCType(note),
                  ),
                );
              }
            };
            break;
          case "Reject":
            title = t("common.rejectDealRequest");
            onPrimaryButtonClick = async (
              note: string | undefined,
            ): Promise<void> => {
              if (
                privileges.includes(
                  FdUserPrivileges.CheckFixedDepositDealRequest,
                )
              ) {
                await detailsStore.checkFDDealRequestStore.checkICDealRequest(
                  Number(requestId),
                  new CheckResponse(
                    CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                    createServerNoteRPCType(note),
                  ),
                );
              } else if (
                privileges.includes(
                  FdUserPrivileges.CheckFixedDepositCertificateRequest,
                )
              ) {
                await detailsStore.checkFDDealRequestStore.checkACDealRequest(
                  Number(requestId),
                  Number(certificateStore.certificateList[0].certificateId),
                  new CheckResponse(
                    CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                    createServerNoteRPCType(note),
                  ),
                );
              }
            };
            break;
        }
        return (
          <TextFieldDialog
            title={title}
            onClose={(): void => {
              setIsDialogOpen(false);
            }}
            isOpen={isDialogOpen}
            primaryButtonType={dialogType}
            onPrimaryButtonClick={async (
              note: string | undefined,
            ): Promise<void> => {
              setIsDialogLoading(true);
              await onPrimaryButtonClick(note);
              setIsDialogOpen(false);
              setIsDialogLoading(false);
              navigate({
                pathname: getPath(Module.Fd, Route.ManageDealRequestDetails),
                search: createSearchParams({
                  requestId: requestId.toString(),
                }).toString(),
              });
            }}
            isCloseIconPresent={!isDialogLoading}
          />
        );
      } else {
        return <></>;
      }
    };

    const getPageHeaderButtons = (): React.ReactElement | undefined => {
      const status = getDetailCellValue(
        detailsStore.details,
        FDDealRequestDetailsKeys.requestStatus,
      ).value as FDDealRequestStatus.FDDealRequestStatus | undefined;
      if (
        detailsStore.requestActions.allowCheck &&
        status === FDDealRequestStatus.FDDealRequestStatus.ACCOUNTS_PENDING
      ) {
        return (
          <HeaderContainer
            primaryButton={{
              label: t("common.approve"),
              onClick: (): void => {
                setDialogType("Approve");
                setIsDialogOpen(true);
              },
              buttonType: "outlined",
            }}
            secondaryButton={{
              label: t("common.reject"),
              onClick: (): void => {
                setDialogType("Reject");
                setIsDialogOpen(true);
              },
              buttonType: "outlined",
            }}
          />
        );
      } else {
        return undefined;
      }
    };

    return (
      <Stack>
        <PageHeader
          title={t("fd.depositCertificate.viewPageTitle")}
          actionElement={getPageHeaderButtons()}
        />
        <Stack sx={style.body}>
          <Breadcrumb
            links={[
              {
                label: t("common.manageDealRequests"),
                onLabelClick: (): void => {
                  navigate(getPath(Module.Fd, Route.ManageDealRequest));
                },
              },
              {
                label: t("fd.dealDetails.pageTitle"),
                onLabelClick: (): void => {
                  navigate({
                    pathname: getPath(
                      Module.Fd,
                      Route.ManageDealRequestDetails,
                    ),
                    search: createSearchParams({
                      requestId: requestId.toString(),
                    }).toString(),
                  });
                },
              },
            ]}
            currentLabel={t("fd.depositCertificate.viewPageTitle")}
          />
          <DepositCertificateDetails
            certificateId={certificateId}
            requestId={requestId}
            onCancel={(): void => {
              navigate({
                pathname: getPath(Module.Fd, Route.ManageDealRequestDetails),
                search: createSearchParams({
                  requestId: requestId.toString(),
                }).toString(),
              });
            }}
            onEdit={(): void => {
              navigate({
                pathname: getPath(
                  Module.Fd,
                  Route.ManageDealRequestCertificateEdit,
                ),
                search: createSearchParams({
                  requestId: requestId.toString(),
                  certificateId: certificateId.toString(),
                }).toString(),
              });
            }}
          />
        </Stack>
        {isDialogOpen &&
          privileges.includes(
            FdUserPrivileges.CheckFixedDepositCertificateRequest,
          ) &&
          getDialog()}
        {certificateStore.error !== null && (
          <ErrorDialog
            errorMessage={getErrorDialogText()}
            isErrorDialogOpen={certificateStore.error !== null}
            onClose={(): void => {
              if (
                certificateStore.error ===
                  FDDealInvalidRequestError.InvalidRequestId ||
                certificateStore.error ===
                  FDCertificateDetailsErrors.InvalidCertificateId
              ) {
                navigate(-1);
              }
              certificateStore.clearError();
            }}
          />
        )}
      </Stack>
    );
  },
);
