"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFOrderStatus = exports.InvalidMFOrderStatusError = void 0;
class InvalidMFOrderStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFOrderStatusError";
    }
}
exports.InvalidMFOrderStatusError = InvalidMFOrderStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFOrderStatus;
(function (MFOrderStatus_1) {
    let MFOrderStatus;
    (function (MFOrderStatus) {
        MFOrderStatus["LINKED"] = "LINKED";
        MFOrderStatus["LINKING_PENDING"] = "LINKING_PENDING";
    })(MFOrderStatus = MFOrderStatus_1.MFOrderStatus || (MFOrderStatus_1.MFOrderStatus = {}));
    MFOrderStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "LINKED":
                return MFOrderStatus.LINKED;
            case "LINKING_PENDING":
                return MFOrderStatus.LINKING_PENDING;
            default:
                throw new InvalidMFOrderStatusError(`Case ${dto.case} is not valid case of MFOrderStatus`);
        }
    };
    MFOrderStatus_1.toDTO = (mFOrderStatus) => {
        const ret = {
            case: MFOrderStatus[mFOrderStatus],
        };
        return ret;
    };
    MFOrderStatus_1.isInstanceOf = (other) => {
        if (other in MFOrderStatus) {
            return true;
        }
        return false;
    };
})(MFOrderStatus = exports.MFOrderStatus || (exports.MFOrderStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
