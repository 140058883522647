import React from "react";

import { RouteObject } from "react-router-dom";
import { Module, Route } from "./RoutesEnum";
import { FdUserPrivileges } from "../modules/user/UserPrivileges";
import { CreateFDDealRequest } from "../modules/home/fixed-deposit/deal-request/pages/CreateFDDealRequest";
import { ManageDealRequests } from "../modules/home/fixed-deposit/deal-request/pages/ManageDealRequests";
import { ViewFDDealRequest } from "../modules/home/fixed-deposit/deal-request/pages/ViewFDDealRequest";
import { DepositCertificateDetailsPage } from "../modules/home/fixed-deposit/deal-request/pages/DepositCertificateDetailsPage";
import { DepositCertificateEditPage } from "../modules/home/fixed-deposit/deal-request/pages/DepositCertificateEditPage";
import { FdReportList } from "../modules/home/fixed-deposit/reports/pages/FdReportList";
import { FdInvestmentRegister } from "../modules/home/fixed-deposit/reports/pages/FdInvestmentRegister";
import { FdHoldingSummary } from "../modules/home/fixed-deposit/reports/pages/FdHoldingSummary";
import { ViewFdInvestmentCashFlows } from "../modules/home/fixed-deposit/deal-request/pages/FdViewInvestmentCashFlows";

export const getFdRoutes = (userPrivileges: string[]): RouteObject[] => {
  const fdChildren: RouteObject[] = [];
  const fdPath = Module.Fd;

  if (userPrivileges.includes(FdUserPrivileges.CreateFixedDepositDealRequest)) {
    const createDealRequestRoute: RouteObject = {
      path: Route.CreateDealRequest,
      element: <CreateFDDealRequest />,
    };
    fdChildren.push(createDealRequestRoute);
  }

  if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositCashFlow)) {
    const fdCashFlowsRoute: RouteObject = {
      path: Route.DealInvestmentCashFlows,
      element: <ViewFdInvestmentCashFlows />,
    };
    fdChildren.push(fdCashFlowsRoute);
  }

  if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositDealRequest)) {
    const manageDealRequestRoute: RouteObject = {
      path: Route.ManageDealRequest,
      element: <ManageDealRequests />,
    };
    fdChildren.push(manageDealRequestRoute);
    const dealRequestDetailsRoute: RouteObject = {
      path: Route.ManageDealRequestDetails,
      element: <ViewFDDealRequest />,
    };
    fdChildren.push(dealRequestDetailsRoute);
  }

  if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositCertificate)) {
    const depositCertificateDetailsRoute: RouteObject = {
      path: Route.ManageDealRequestCertificateDetails,
      element: <DepositCertificateDetailsPage />,
    };
    fdChildren.push(depositCertificateDetailsRoute);
  }

  if (userPrivileges.includes(FdUserPrivileges.EditFixedDepositCertificate)) {
    const depositCertificateDetaisRoute: RouteObject = {
      path: Route.ManageDealRequestCertificateEdit,
      element: <DepositCertificateEditPage />,
    };
    fdChildren.push(depositCertificateDetaisRoute);
  }

  if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositReports)) {
    const fdReportsListRoute: RouteObject = {
      path: Route.Reports,
      element: <FdReportList />,
    };
    fdChildren.push(fdReportsListRoute);

    const investmentRegister: RouteObject = {
      path: Route.InvestmentRegisterReport,
      element: <FdInvestmentRegister />,
    };
    fdChildren.push(investmentRegister);

    const holdingSummary: RouteObject = {
      path: Route.HoldingSummary,
      element: <FdHoldingSummary />,
    };
    fdChildren.push(holdingSummary);
  }

  if (fdChildren.length === 0) {
    return [];
  } else {
    return [
      {
        path: fdPath,
        children: fdChildren,
      },
    ];
  }
};
