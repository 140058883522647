import { DealRequestDetailEnums } from "@khazana/khazana-rpcs/build/types/dealRequestDetail";
import {
  DetailRowCellEnum,
  DetailsRowProps,
} from "@surya-digital/leo-reactjs-core";
import { TFunction } from "i18next";
import {
  getAmountStringOrHyphen,
  getFormattedAmount,
  getFormattedAmountString,
  getFormattedTimeDateWithComma,
  getAmountOrUndefined,
} from "../../../utils";
import { FiDealRequestDetailEnums } from "@khazana/khazana-rpcs/build/fixed-income/fiDealRequestDetail";
import {
  EquityTransactionType,
  FiTransactionType,
} from "@khazana/khazana-rpcs";

export const convertStringToNumber = (value: string | null): number | null => {
  const parsedNumber = Number(value);
  if (!value || isNaN(parsedNumber)) return null;
  return parsedNumber;
};

export const getRoundedOffPercentage = (percentage: number): number => {
  // As per the user requirement it was needed to lock the percentage to 2 decimal places.
  return parseFloat((percentage / 10000).toFixed(2));
};

interface CellProps {
  cellType:
    | DealRequestDetailEnums.CellType.CellType
    | FiDealRequestDetailEnums.CellType.CellType;
  setCurrencySymbol: (currencySymbol: string) => void;
  label: string;
  setTransactionType?: (transactionType: string) => void;
  t: TFunction;
}

// TODO: Need to be replaced with Detail Cell type
export const transformCell = ({
  cellType,
  setCurrencySymbol,
  label,
  setTransactionType,
  t,
}: CellProps): DetailsRowProps => {
  if (
    cellType instanceof DealRequestDetailEnums.CellType.AmountType ||
    cellType instanceof FiDealRequestDetailEnums.CellType.AmountType
  ) {
    if (cellType.amount) {
      setCurrencySymbol(cellType.amount.currency.symbol);
    }
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: `${t(`${label}`)} ${
        cellType.amount ? `(${cellType.amount.currency.symbol})` : ``
      }`,
      cellType: {
        text: cellType.amount
          ? getFormattedAmount(cellType.amount.amount, 4, 2)
          : "-",
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.QuantityType ||
    cellType instanceof FiDealRequestDetailEnums.CellType.QuantityType
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: getFormattedAmountString(cellType.quantity.quantity),
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof FiDealRequestDetailEnums.CellType.PercentageType
  ) {
    const text = getAmountStringOrHyphen(
      getAmountOrUndefined(cellType.ytm.yieldObject),
      2,
      4,
    );
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: text ? text.toString() : "",
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.Time ||
    cellType instanceof FiDealRequestDetailEnums.CellType.Time
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: getFormattedTimeDateWithComma(new Date(cellType.time.timestamp)),
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.Unstyled ||
    cellType instanceof FiDealRequestDetailEnums.CellType.Unstyled
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: cellType.text,
        type: DetailRowCellEnum.Unstyled,
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.ChipType ||
    cellType instanceof FiDealRequestDetailEnums.CellType.ChipType
  ) {
    if (setTransactionType) {
      setTransactionType(cellType.transactionType);
    }
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        type: DetailRowCellEnum.Chip,
        chips: [
          {
            label:
              cellType instanceof DealRequestDetailEnums.CellType.ChipType
                ? cellType.transactionType
                : cellType.transactionType ===
                    FiTransactionType.FiTransactionType.FI_PURCHASE
                  ? t("fi.transactionType.purchase")
                  : t("fi.transactionType.sell"),
            color:
              cellType instanceof DealRequestDetailEnums.CellType.ChipType
                ? cellType.transactionType !==
                  EquityTransactionType.EquityTransactionType.SELL
                  ? "blueSubtle"
                  : "orangeSubtle"
                : cellType.transactionType !==
                    FiTransactionType.FiTransactionType.FI_SELL
                  ? "blueSubtle"
                  : "orangeSubtle",
          },
        ],
      },
    };
  } else if (
    cellType instanceof DealRequestDetailEnums.CellType.None ||
    cellType instanceof FiDealRequestDetailEnums.CellType.None
  ) {
    return {
      // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
      /* @ts-ignore */
      label: t(`${label}`),
      cellType: {
        text: "-",
        type: DetailRowCellEnum.Unstyled,
      },
    };
  }
  // Below code is added for type safety.
  else {
    return {
      label,
      cellType: {
        text: label,
        type: DetailRowCellEnum.Unstyled,
      },
    };
  }
};
