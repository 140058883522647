"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiMaturityDealRequestsSortOrder = exports.GetFiMaturityDealRequestsSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedIncomeSortOrder_1 = require("./fixedIncomeSortOrder");
class InvalidGetFiMaturityDealRequestsSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiMaturityDealRequestsSortOrderError";
    }
}
var GetFiMaturityDealRequestsSortOrderEnums;
(function (GetFiMaturityDealRequestsSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["ISSUE_DATE"] = "ISSUE_DATE";
            ColumnName["VALUE_DATE"] = "VALUE_DATE";
            ColumnName["MATURED_DATE"] = "MATURED_DATE";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "ISSUE_DATE":
                    return ColumnName.ISSUE_DATE;
                case "VALUE_DATE":
                    return ColumnName.VALUE_DATE;
                case "MATURED_DATE":
                    return ColumnName.MATURED_DATE;
                default:
                    throw new InvalidGetFiMaturityDealRequestsSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFiMaturityDealRequestsSortOrderEnums.ColumnName || (GetFiMaturityDealRequestsSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFiMaturityDealRequestsSortOrderEnums = exports.GetFiMaturityDealRequestsSortOrderEnums || (exports.GetFiMaturityDealRequestsSortOrderEnums = {}));
class GetFiMaturityDealRequestsSortOrder {
    constructor(sortIndex, columnName, order) {
        this.isGetFiMaturityDealRequestsSortOrder = true;
        GetFiMaturityDealRequestsSortOrder.validateSortIndex(sortIndex);
        this.sortIndex = sortIndex;
        GetFiMaturityDealRequestsSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFiMaturityDealRequestsSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const sortIndex = (0, leo_ts_runtime_1.getInt32)(dto, "sortIndex", InvalidGetFiMaturityDealRequestsSortOrderError);
        const columnName = GetFiMaturityDealRequestsSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFiMaturityDealRequestsSortOrderError));
        const order = fixedIncomeSortOrder_1.FixedIncomeSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFiMaturityDealRequestsSortOrderError));
        return new GetFiMaturityDealRequestsSortOrder(sortIndex, columnName, order);
    }
    toDTO() {
        const ret = {
            sortIndex: this.sortIndex,
            columnName: GetFiMaturityDealRequestsSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: fixedIncomeSortOrder_1.FixedIncomeSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(sortIndex = this.sortIndex, columnName = this.columnName, order = this.order) {
        return new GetFiMaturityDealRequestsSortOrder(sortIndex, columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiMaturityDealRequestsSortOrderValue = other.isGetFiMaturityDealRequestsSortOrder;
        if (!(other instanceof GetFiMaturityDealRequestsSortOrder) || Boolean(isGetFiMaturityDealRequestsSortOrderValue)) {
            return false;
        }
        if (this.sortIndex !== other.sortIndex) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFiMaturityDealRequestsSortOrder = GetFiMaturityDealRequestsSortOrder;
GetFiMaturityDealRequestsSortOrder.validateSortIndex = (sortIndex) => {
    if (!(0, leo_ts_runtime_1.isInt32)(sortIndex)) {
        throw new InvalidGetFiMaturityDealRequestsSortOrderError(`Attribute sortIndex is not an Int32`);
    }
};
GetFiMaturityDealRequestsSortOrder.validateColumnName = (columnName) => {
    if (!GetFiMaturityDealRequestsSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFiMaturityDealRequestsSortOrderError(`Attribute columnName is not a GetFiMaturityDealRequestsSortOrderEnums.ColumnName`);
    }
};
GetFiMaturityDealRequestsSortOrder.validateOrder = (order) => {
    if (!fixedIncomeSortOrder_1.FixedIncomeSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFiMaturityDealRequestsSortOrderError(`Attribute order is not a FixedIncomeSortOrder.FixedIncomeSortOrder`);
    }
};
GetFiMaturityDealRequestsSortOrder.prototype.toString = function toString() {
    return `GetFiMaturityDealRequestsSortOrder(sortIndex=${this.sortIndex},columnName=${this.columnName},order=${this.order})`;
};
