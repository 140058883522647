import { Box, Drawer, IconButton, Stack, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Menu,
  ChevronLeft,
  SubListItemProps,
  useSpacing,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { SidebarFooter } from "./SidebarFooter";
import { SidebarMenu } from "./SidebarMenu";
import { ListItemProps } from "@surya-digital/leo-reactjs-core/dist/sidebar/Sidebar";

/**
 * Creates an Sidebar
 * @param open - If true, then Sidebar is open.
 * @param list - Array of ListItems for MenuList on top.
 * @param logo - Logo that will be displayed at the Sidebar top.
 * @param handleMenuClick - Function to handle menu icon click i.e to open/close the sidebar.
 * @param currentPath - URL to image resource. When src and userName is not specified fallback defaults to image.
 * @param setCurrentPath - Function to update the current page's path.
 * @param bottomListData - Array of ListItem's for Sidebar Footer at the bottom.
 * @param isCollapsible - If true, then Sidebar is collapsible, on collapsed state only the Sidebar Item icons are visible.
 * @returns Sidebar component.
 */

export const StyledLogoBox = styled(Box)(() => ({
  height: "40px",
}));

export interface SidebarProps {
  open: boolean;
  list: ListItemProps[];
  logo?: React.ReactElement;
  handleMenuClick?: () => void;
  currentPath: string;
  setCurrentPath: (path: string) => void;
  bottomListData?: ListItemProps[];
  bottomElement?: React.ReactElement;
  isCollapsible: boolean;
}

export const Sidebar = ({
  open,
  list,
  bottomListData,
  logo,
  handleMenuClick,
  currentPath,
  setCurrentPath,
  bottomElement,
  isCollapsible,
}: SidebarProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const [isOverflow, setIsOverflow] = useState<boolean>(true);
  const defaultSelectedItemList = new Array(Object.keys(list).length).fill(
    false,
  );
  const [selectedItemList, setSelectedItemList] = useState<boolean[]>([
    ...defaultSelectedItemList,
  ]);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const handleSidebarOverflow = (isOverflowFromCallback: boolean): void => {
    setIsOverflow(isOverflowFromCallback);
  };

  const isDropdownExpandable = (
    subListItemList: SubListItemProps[],
  ): boolean => {
    return subListItemList.some((item) => currentPath.includes(item.path));
  };

  useEffect(() => {
    const _selectedItemList = list.map((item) => {
      if (item.subList) {
        return isDropdownExpandable(item.subList);
      }
      return false;
    });

    setSelectedItemList(_selectedItemList);
  }, [currentPath]);

  return (
    <>
      {!isCollapsible && (
        <IconButton onClick={(): void => handleMenuClick && handleMenuClick()}>
          <Menu color={tokens.icon} height="24px" width="24px" />
        </IconButton>
      )}
      <Drawer
        variant="persistent"
        open={open}
        onClose={(): void => handleMenuClick && handleMenuClick()}
        sx={{
          "& .MuiDrawer-paper": {
            borderRight: `1px solid ${tokens.border}`,
            boxShadow: "none",
            minWidth: !isCollapsed ? "240px" : 0,
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          height="56px"
          padding={spacing.spaceXS}
        >
          {!isCollapsed && logo && <StyledLogoBox>{logo}</StyledLogoBox>}
          {isCollapsible && (
            <IconButton
              onClick={(): void => setIsCollapsed(!isCollapsed)}
              disableRipple={true}
              sx={{
                padding: spacing.spaceSM,
                borderRadius: cornerRadius.radiusXS,
                "&:hover": {
                  backgroundColor: tokens.backgroundHovered,
                },
              }}
            >
              {!isCollapsed ? (
                <ChevronLeft color={tokens.icon} height="20px" width="20px" />
              ) : (
                <Menu color={tokens.icon} height="20px" width="20px" />
              )}
            </IconButton>
          )}
        </Stack>
        <SidebarMenu
          list={list}
          selectedItemList={selectedItemList}
          setSelectedItemList={(selectedList: boolean[]): void => {
            setSelectedItemList(selectedList);
          }}
          onSidebarOverflow={handleSidebarOverflow}
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
        {bottomListData && (
          <SidebarFooter
            isOverflow={isOverflow}
            list={bottomListData}
            currentPath={currentPath}
            setCurrentPath={setCurrentPath}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        )}
        {bottomElement}
      </Drawer>
    </>
  );
};
