import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { HeaderButton } from "../../../components/page-header/HeaderButton";
import { useSubmitConfirmationDetailsStore } from "../store/SubmitConfirmationDetailsStore/hooks";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { MFSubmitError } from "../store/SubmitConfirmationDetailsStore/SubmitErrorStore";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { ConfirmationRequestForm } from "../components/ConfirmationRequestForm";
import { ContractNote } from "../../../components/contract-note/ContractNote";
import { useMFConfirmationRequestDetailsStore } from "../store/confirmationRequestDetailsStore/hooks";
import { FolioCreationDialog } from "../components/FolioCreationDialog";

const createMFConfirmationStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    body: {
      gap: spacing.space2XL,
      padding: spacing.space2XL,
      minWidth: "calc(100vw - 256px)",
    },
  };
};

export const CreateConfirmationRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useSubmitConfirmationDetailsStore();
  const detailsStore = useMFConfirmationRequestDetailsStore();
  const [searchParam] = useSearchParams();
  const requestId = searchParam.get("requestId");
  const spacing = useSpacing();
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isFolioDialogOpen, setIsFolioDialogOpen] = useState(false);

  const getData = useCallback(async () => {
    store.setIsLoading(true);
    if (requestId) {
      const id = Number(requestId);
      await detailsStore.getMFConfirmationRequestDetails(id);
      store.setFields(
        detailsStore.details,
        detailsStore.confirmationDocumentURL,
      );
    }
    store.getMFAmcList();
    store.getBrokerList();
    store.fields.entity.getEntityList();
    store.setIsLoading(false);
  }, []);

  useEffect(() => {
    getData();
    store.fields.scheme.setNoOptionsMessage(
      t("mf.fields.schemeNoOptionsPlaceholder"),
    );
    return (): void => {
      store.reset();
    };
  }, []);

  const style = useMemo(() => createMFConfirmationStyle(spacing), []);
  const submitButton = useMemo((): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.submit")}
        onClick={async (): Promise<void> => {
          store.errorStore.setError(undefined);
          const success = await store.validateMFConfirmationRequest(undefined);
          if (!store.errorStore.error && success) {
            setIsDialogOpen(true);
          } else if (
            store.errorStore.error === MFSubmitError.InvalidFolioNumber
          ) {
            setIsFolioDialogOpen(true);
          }
        }}
        buttonType="filled"
      />
    );
  }, []);

  const submitRequest = async (
    note: string | undefined,
    forceFolioCreation = false,
  ): Promise<void> => {
    store.errorStore.reset();
    const id = await store.submitMFConfirmationRequest(
      note,
      forceFolioCreation,
      requestId ? Number(requestId) : undefined,
    );
    if (!store.errorStore.error) {
      if (id) {
        navigate(
          getPath(
            Module.MF,
            Route.ManageConfirmationRequestsDetailsWithParams,
            id.toString(),
          ),
        );
      } else {
        navigate(getPath(Module.MF, Route.ManageConfirmationRequests));
      }
    }
  };

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      return (
        <TextFieldDialog
          title={t("mf.confirmationRequest.submitDialogTitle")}
          onClose={(): void => {
            setIsDialogOpen(false);
          }}
          isOpen={isDialogOpen}
          primaryButtonType={"Submit"}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            await submitRequest(note);
            setIsDialogOpen(false);
          }}
          isCloseIconPresent={!store.isLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const getErrorMessage = (): string => {
    switch (store.errorStore.error) {
      case MFSubmitError.InvalidFolioAMCCombination:
      case MFSubmitError.InvalidFolioEntityCombination:
        return t("mf.errorMessages.invalidFolioNumber");
      case MFSubmitError.InvalidRequestID:
        return t("contractNotes.invalidContractNote");
      default:
        return t("errors.internalServerErrorDescription");
    }
  };

  const getBreadcrumbLinks = (): {
    label: string;
    onLabelClick: () => void;
  }[] => {
    const links = [
      {
        label: t("mf.manageConfirmationRequests.pageTitle"),
        onLabelClick: (): void => {
          navigate(getPath(Module.MF, Route.ManageConfirmationRequests));
        },
      },
    ];
    if (requestId) {
      links.push({
        label: t("mf.confirmationRequest.pageTitle"),
        onLabelClick: (): void => {
          navigate(
            getPath(
              Module.MF,
              Route.ManageConfirmationRequestsDetailsWithParams,
              requestId,
            ),
          );
        },
      });
    }
    return links;
  };

  return (
    <Stack>
      {store.errorStore.error &&
        store.errorStore.error !== MFSubmitError.InvalidFolioNumber && (
          <ErrorDialog
            errorMessage={getErrorMessage()}
            isErrorDialogOpen={store.errorStore.error !== null}
            onClose={(): void => {
              navigate(getPath(Module.MF, Route.ManageConfirmationRequests));
              store.errorStore.setError(undefined);
            }}
          />
        )}
      {getDialog()}
      {isFolioDialogOpen && (
        <FolioCreationDialog
          isRequest={true}
          onClose={() => setIsFolioDialogOpen(false)}
          isOpen={isFolioDialogOpen}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            await submitRequest(note, true);
            setIsFolioDialogOpen(false);
          }}
          amcName={store.fields.amc.value?.label}
          entityName={store.fields.entity.getSelectedEntityName()}
          folioNumber={store.fields.folio.value}
        />
      )}
      <PageHeader
        title={t("mf.confirmationRequest.pageTitle")}
        actionElement={submitButton}
      />
      <Stack sx={style.body}>
        <Breadcrumb
          links={getBreadcrumbLinks()}
          currentLabel={
            requestId
              ? t("mf.confirmationRequest.editDetailsSubtitle")
              : t("mf.confirmationRequest.addDetailsSubtitle")
          }
        />
        {store.isLoading ? (
          <LoadingIndicator isLoading={store.isLoading} variant={"container"} />
        ) : (
          <ContractNote
            title={
              requestId
                ? t("mf.confirmationRequest.editDetailsSubtitle")
                : t("mf.confirmationRequest.addDetailsSubtitle")
            }
            isEditing={true}
            onCancelClick={(): void => {
              if (requestId) {
                navigate(
                  getPath(
                    Module.MF,
                    Route.ManageConfirmationRequestsDetailsWithParams,
                    requestId,
                  ),
                );
              } else {
                navigate(getPath(Module.MF, Route.ManageConfirmationRequests));
              }
            }}
            form={
              <ConfirmationRequestForm
                fields={store.fields}
                schemeDisabled={store.schemeDisabled}
                schemeSearchStore={store.schemeSearchStore}
                loadLabel={store.loadLabel}
                loadPlaceholder={store.loadPlaceholder}
              />
            }
            attachFileDialogTitle={t(
              "mf.confirmationRequest.attachFileDialogTitle",
            )}
            editFileDialogTitle={t(
              "mf.confirmationRequest.editFileDialogTitle",
            )}
            attachFileButtonText={t(
              "mf.confirmationRequest.attachFileButtonTitle",
            )}
            editFileButtonText={t("mf.confirmationRequest.editFileButtonTitle")}
            onFileUploadSuccess={(url, sha256): void => {
              store.fields.setDocumentUrl(url);
              store.fields.setSha256(sha256);
            }}
            fileUploadStore={store.fileUploadStore}
            pdfUrl={store.fields.documentUrl}
          />
        )}
      </Stack>
    </Stack>
  );
});
