import { Instance, types } from "mobx-state-tree";
import {
  FixedIncomeHoldingSummaryResponse,
  FixedIncomeInstrumentType,
} from "@khazana/khazana-rpcs";
import { getAmountOrUndefined } from "../../../../../utils";

export const FiHoldingSummaryModel = types.model("FiHoldingSummaryModel", {
  isin: types.string,
  securityName: types.maybe(types.string),
  issuerName: types.maybe(types.string),
  instrumentType: types.maybe(
    types.enumeration<FixedIncomeInstrumentType.FixedIncomeInstrumentType>(
      "FixedIncomeInstrumentType",
      Object.values(FixedIncomeInstrumentType.FixedIncomeInstrumentType),
    ),
  ),
  maturityDate: types.maybe(types.string),
  holdingCategory: types.maybe(types.string),
  entityName: types.maybe(types.string),
  portfolioName: types.maybe(types.string),
  holdingUnits: types.maybe(types.number),
  averageRate: types.maybe(types.number),
  purchaseAmount: types.maybe(types.number),
  marketPrice: types.maybe(types.number),
  marketValue: types.maybe(types.number),
});

export const createFiHoldingSummaryModel = (
  response: FixedIncomeHoldingSummaryResponse,
): Instance<typeof FiHoldingSummaryModel> => {
  return FiHoldingSummaryModel.create({
    isin: response.isin,
    securityName: response.securityName ?? undefined,
    issuerName: response.issuerName ?? undefined,
    instrumentType: response.instrumentType ?? undefined,
    maturityDate: response.maturityDate?.date,
    holdingCategory: response.category ?? undefined,
    entityName: response.entityName ?? undefined,
    portfolioName: response.portfolioName ?? undefined,
    holdingUnits: response.holdingUnits ?? undefined,
    averageRate: getAmountOrUndefined(response.averageRate),
    purchaseAmount: getAmountOrUndefined(response.purchaseAmount),
    marketPrice: getAmountOrUndefined(response.marketPrice),
    marketValue: getAmountOrUndefined(response.marketValue),
  });
};
