import React from "react";
import { useTranslation } from "react-i18next";
import { Instance } from "mobx-state-tree";
import { FiInvestmentDetailsStore } from "./FiInvestmentDetailsStore";
import { DetailsTimeLineCard } from "../../../components/DetailsTimelineCard";
import { observer } from "mobx-react";

interface FiInvestmentDetailsCardProps {
  store: Instance<typeof FiInvestmentDetailsStore>;
  onButtonClick: () => void;
}

export const FiInvestmentDetailsCard = observer(
  ({
    store,
    onButtonClick,
  }: FiInvestmentDetailsCardProps): React.ReactElement => {
    const { t } = useTranslation();

    return (
      <>
        {store.detailsList.length > 0 && (
          <DetailsTimeLineCard
            dataList={store.detailsList.map((item) => {
              return {
                top: item.top,
                middle: item.middle,
                bottom: item.bottom,
              };
            })}
            cashFlowTimeLineItems={store.cashflowTimelineList}
            title={t("fi.investmentDetails.investmentDetailsTitle")}
            headerButtonTitle={t(
              "fi.investmentDetails.viewCashFlowDetailsButtonTitle",
            )}
            isButtonVisible={store.isHeaderButtonVisible}
            onButtonClick={onButtonClick}
          />
        )}
      </>
    );
  },
);
