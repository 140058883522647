import { Instance } from "mobx-state-tree";
import { FDStore } from "./FDStore";
import { useHomeStore } from "../../store/hooks";
import { FDViewCashflowsStore } from "../deal-request/store/FDViewCashflowsStore";

export const useFDStore = (): Instance<typeof FDStore> => {
  return useHomeStore().fdStore;
};

export const useFdViewCashflowsStore = (): Instance<
  typeof FDViewCashflowsStore
> => {
  return useFDStore().fdViewCashflowsStore;
};
