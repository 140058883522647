import { Instance, types } from "mobx-state-tree";
import { GetEquityGrandTotalChangeInGainRPC } from "@khazana/khazana-rpcs";
import {
  DashboardStatModel,
  createDashboardStatModel,
} from "../../../models/DashboardStatModel";

export const ChangeInGainSummaryModel = types.model(
  "ChangeInGainSummaryModel",
  {
    totalUnrealisedGain: DashboardStatModel,
    mvEndDate: DashboardStatModel,
    newShareCost: DashboardStatModel,
    totalRealisedGain: DashboardStatModel,
    totalIrr: DashboardStatModel,
  },
);

export const createChangeInGainSummaryModel = (
  response: GetEquityGrandTotalChangeInGainRPC.Response,
): Instance<typeof ChangeInGainSummaryModel> => {
  return ChangeInGainSummaryModel.create({
    totalUnrealisedGain: createDashboardStatModel(response.totalUnrealisedGain),
    mvEndDate: createDashboardStatModel(response.mvEndDate),
    newShareCost: createDashboardStatModel(response.newShareCost),
    totalRealisedGain: createDashboardStatModel(response.totalRealisedGain),
    totalIrr: createDashboardStatModel(response.totalIrr, false),
  });
};
