import { CreateFiSellDealRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCreateFiSellRequestRPCImpl extends CreateFiSellDealRequestRPC {
  execute(
    _request: CreateFiSellDealRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CreateFiSellDealRequestRPC.Response,
      CreateFiSellDealRequestRPC.Errors.Errors
    >
  > {
    const response = new CreateFiSellDealRequestRPC.Response(1);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CreateFiSellDealRequestRPC.Response,
        CreateFiSellDealRequestRPC.Errors.Errors
      >
    >;
  }
}
