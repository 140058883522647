import { flow, getEnv, types } from "mobx-state-tree";
import { CreateFiDealRequestError } from "./CreateFiDealRequestError";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CreateFiBuyDealRequestRPC,
  FiBuyDealValue,
  FiCollection,
  FiPriceYtm,
  FixedIncomeInstrumentType,
  GetEntityListRPC,
  GetFiBuyDealRequestImpactRPC,
  GetFiBuyDealSummaryDetailsRPC,
  GetFiBuyDematAccountNumberListRPC,
  GetFiBuySecurityListRPC,
  GetFiBuyShowYtmRPC,
  GetPortfolioListRPC,
  ISIN,
  ModuleType,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { createSecurityModel } from "../../../models/SecurityModel";
import {
  useCreateFiBuyDealRequestRPCClientImpl,
  useGetFiBuyDealRequestImpactRPCClientImpl,
  useGetFiBuyDealSummaryDetailsRPC,
  useGetFiBuyDematAccountNumberListRPCClientImpl,
  useGetFiBuySecurityListRPCClientImpl,
  useGetFiBuyShowYtmRPCClientImpl,
} from "../rpcs/RPC";
import { createEntityModel } from "../../../models/EntityModel";
import { createPortfolioModel } from "../../../models/PortfolioModel";
import { createDematAccountModel } from "../../../models/DematAccountModel";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import {
  useGetEntityRPCClientImpl,
  useGetPortfolioListRPCClientImpl,
} from "../../../rpcs/RPC";
import { createServerNoteRPCType } from "../../../../../utils";
import { createFiImpactTableModel } from "../../../models/ImpactTableModel";
import { createSummaryDetailModel } from "../../../models/SummaryDetailModel";
import { createCashflowTimelineModel } from "../../../models/CashflowTimelineModel";

export const CreateFiBuyDealRequestStore = types
  .model("CreateBuyFiDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<CreateFiDealRequestError>(
        "CreateFiDealRequestError",
        Object.values(CreateFiDealRequestError),
      ),
    ),
    errorBankBalance: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    getBuySecurityList: flow(function* (
      searchText: string,
      securityType: FixedIncomeInstrumentType.FixedIncomeInstrumentType,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiBuySecurityListRPC.Request(
        searchText,
        securityType,
      );
      const result: LeoRPCResult<
        GetFiBuySecurityListRPC.Response,
        GetFiBuySecurityListRPC.Errors.Errors
      > =
        yield useGetFiBuySecurityListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.securities.map((security) =>
          createSecurityModel(security),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetFiBuySecurityListRPC`,
        );
      }
    }),
    getFiBuyDealSummaryDetails: flow(function* (
      dealValue: FiBuyDealValue.FiBuyDealValue | null,
      isin: ISIN,
      priceYtm: FiPriceYtm.FiPriceYtm | null,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiBuyDealSummaryDetailsRPC.Request(
        dealValue,
        isin,
        priceYtm,
      );
      const result: LeoRPCResult<
        GetFiBuyDealSummaryDetailsRPC.Response,
        GetFiBuyDealSummaryDetailsRPC.Errors.Errors
      > = yield useGetFiBuyDealSummaryDetailsRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        const transactionDetails = response.transactionDetails.map(
          (details) => {
            return createSummaryDetailModel(details);
          },
        );
        const securityDetails = response.securityDetails.map((details) => {
          return createSummaryDetailModel(details);
        });
        const cashflowTimeline = response.cashflowTimeline.map((details) => {
          return createCashflowTimelineModel(details);
        });
        return { transactionDetails, securityDetails, cashflowTimeline };
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          default:
            logger.error(
              `Unhandled Error: ${result.error} from FIBuyDealSummaryRPC`,
            );
            break;
        }
      } else {
        logger.error(`Unhandled Error from FIBuyDealSummaryRPC`);
      }
    }),
    getBuyEntityList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetEntityListRPC.Request();
      const result: LeoRPCResult<
        GetEntityListRPC.Response,
        GetEntityListRPC.Errors.Errors
      > = yield useGetEntityRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.entities.map((entity) => createEntityModel(entity));
      } else {
        logger.error(`Unhandled Error: ${result.error} from GetEntityListRPC`);
      }
    }),
    getFiBuyShowYtm: flow(function* (isin: ISIN) {
      const logger = getEnv(store).logger;
      store.error = null;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiBuyShowYtmRPC.Request(isin);
      const result: LeoRPCResult<
        GetFiBuyShowYtmRPC.Response,
        GetFiBuyShowYtmRPC.Errors.Errors
      > = yield useGetFiBuyShowYtmRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.yieldType;
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          case CreateFiDealRequestError.InvalidISIN:
            store.error = CreateFiDealRequestError.InvalidISIN;
            break;
          case CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound:
            store.error =
              CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound;
            break;
        }
      } else {
        logger.error(
          `Unknown error occurred in getFiBuyShowYtm with result: ${result}`,
        );
      }
    }),
    getBuyPortfolioList: flow(function* (entityId: LeoUUID) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetPortfolioListRPC.Request(
        entityId,
        ModuleType.ModuleType.FIXED_INCOME,
      );
      const result: LeoRPCResult<
        GetPortfolioListRPC.Response,
        GetPortfolioListRPC.Errors.Errors
      > = yield useGetPortfolioListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.portfolios.map((portfolio) =>
          createPortfolioModel(portfolio),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetPortfolioListRPC`,
        );
      }
    }),
    getBuyDematAccountNumberList: flow(function* (entityId: LeoUUID) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiBuyDematAccountNumberListRPC.Request(entityId);
      const result: LeoRPCResult<
        GetFiBuyDematAccountNumberListRPC.Response,
        GetFiBuyDematAccountNumberListRPC.Errors.Errors
      > =
        yield useGetFiBuyDematAccountNumberListRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dematAccountNumbers.map((dematAccountNumber) =>
          createDematAccountModel(
            dematAccountNumber.accountNumber,
            dematAccountNumber.accountCode,
          ),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetFiBuyDematAccountNumberListRPC`,
        );
      }
    }),
    createFiBuyDealRequest: flow(function* (
      isin: string,
      entityId: LeoUUID,
      fiCollection: FiCollection.FiCollection,
      dealValue: FiBuyDealValue.FiBuyDealValue,
      note: string | null,
      priceYtm: FiPriceYtm.FiPriceYtm,
      bankId: LeoUUID,
      accountNumber: string,
    ) {
      const logger = getEnv(store).logger;
      store.error = null;
      const apiClient: APIClient = getAPIClient(store);
      const request = new CreateFiBuyDealRequestRPC.Request(
        entityId,
        fiCollection,
        new ISIN(isin),
        dealValue,
        priceYtm,
        bankId,
        accountNumber,
        createServerNoteRPCType(note),
      );
      const result: LeoRPCResult<
        CreateFiBuyDealRequestRPC.Response,
        CreateFiBuyDealRequestRPC.Errors.Errors
      > =
        yield useCreateFiBuyDealRequestRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dealRequestId;
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          case CreateFiDealRequestError.InvalidISIN:
            store.error = CreateFiDealRequestError.InvalidISIN;
            break;
          case CreateFiDealRequestError.InvalidEntityId:
            store.error = CreateFiDealRequestError.InvalidEntityId;
            break;
          case CreateFiDealRequestError.InvalidPortfolioId:
            store.error = CreateFiDealRequestError.InvalidPortfolioId;
            break;
          case CreateFiDealRequestError.InvalidDematAccountNumber:
            store.error = CreateFiDealRequestError.InvalidDematAccountNumber;
            break;
          case CreateFiDealRequestError.CurrencyMismatch:
            store.error = CreateFiDealRequestError.CurrencyMismatch;
            break;
          case CreateFiDealRequestError.AmountLimitExceeded:
            store.error = CreateFiDealRequestError.AmountLimitExceeded;
            break;
          case CreateFiDealRequestError.GrossAmountTooLess:
            store.error = CreateFiDealRequestError.GrossAmountTooLess;
            break;
          case CreateFiDealRequestError.InvalidPrice:
            store.error = CreateFiDealRequestError.InvalidPrice;
            break;
          case CreateFiDealRequestError.InsufficientBankBalance:
            store.errorBankBalance = true;
            break;
        }
      } else {
        logger.error(
          `Unknown error occurred in CreateFiBuyDealRequestRPC with result: ${result}`,
        );
      }
    }),
    getFiBuyImpactOnPortfolio: flow(function* (
      isin: string,
      entityId: LeoUUID,
      portfolioId: LeoUUID,
      dealValue: FiBuyDealValue.FiBuyDealValue,
      priceYtm: FiPriceYtm.FiPriceYtm,
    ) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);

        const request = new GetFiBuyDealRequestImpactRPC.Request(
          new ISIN(isin),
          entityId,
          portfolioId,
          dealValue,
          priceYtm,
        );
        const result: LeoRPCResult<
          GetFiBuyDealRequestImpactRPC.Response,
          GetFiBuyDealRequestImpactRPC.Errors.Errors
        > =
          yield useGetFiBuyDealRequestImpactRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          if (response.impactTable) {
            return createFiImpactTableModel(response.impactTable);
          }
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetFiBuyDealRequestImpactRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              logger.error("Could not create new Leo UUID");
              break;
            case CreateFiDealRequestError.InvalidISINError:
              logger.error("Could not create new ISIN object");
              break;
            default:
              logger.error(
                `Unhandled Error: ${error} occurred in getFiBuyImpactOnPortfolio`,
              );
          }
        }
        logger.error(
          `Unhandled error: ${error} occurred in getFiBuyImpactOnPortfolio`,
        );
      }
    }),
  }));
