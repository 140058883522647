"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiYieldType = exports.InvalidFiYieldTypeError = void 0;
class InvalidFiYieldTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiYieldTypeError";
    }
}
exports.InvalidFiYieldTypeError = InvalidFiYieldTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiYieldType;
(function (FiYieldType_1) {
    let FiYieldType;
    (function (FiYieldType) {
        FiYieldType["M"] = "M";
        FiYieldType["C"] = "C";
        FiYieldType["P"] = "P";
    })(FiYieldType = FiYieldType_1.FiYieldType || (FiYieldType_1.FiYieldType = {}));
    FiYieldType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "M":
                return FiYieldType.M;
            case "C":
                return FiYieldType.C;
            case "P":
                return FiYieldType.P;
            default:
                throw new InvalidFiYieldTypeError(`Case ${dto.case} is not valid case of FiYieldType`);
        }
    };
    FiYieldType_1.toDTO = (fiYieldType) => {
        const ret = {
            case: FiYieldType[fiYieldType],
        };
        return ret;
    };
    FiYieldType_1.isInstanceOf = (other) => {
        if (other in FiYieldType) {
            return true;
        }
        return false;
    };
})(FiYieldType = exports.FiYieldType || (exports.FiYieldType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
