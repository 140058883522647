import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import { KeycloakProvider } from "keycloak-react-web";
import { keycloak } from "./common/keycloak";

const root = ReactDOM.createRoot(document.getElementById("root") as Element);
root.render(
  <KeycloakProvider
    client={keycloak}
    initOptions={{
      onLoad: "check-sso",
      checkLoginIframe: true,
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    }}
  >
    <App />
  </KeycloakProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
