"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetInvestmentCashflowBannerInfoRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigAmount_1 = require("../types/bigAmount");
const settledCashflows_1 = require("../types/settledCashflows");
const pendingCashflows_1 = require("../types/pendingCashflows");
const moduleType_1 = require("../types/moduleType");
/* eslint-disable import/export */
class GetInvestmentCashflowBannerInfoRPC {
}
exports.GetInvestmentCashflowBannerInfoRPC = GetInvestmentCashflowBannerInfoRPC;
(function (GetInvestmentCashflowBannerInfoRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetInvestmentCashflowBannerInfoRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(investmentId = null, maturityId = null, moduleType) {
            this.isGetInvestmentCashflowBannerInfoRPCRequest = true;
            if (investmentId !== undefined && investmentId !== null) {
                Request.validateInvestmentId(investmentId);
            }
            this.investmentId = investmentId;
            if (maturityId !== undefined && maturityId !== null) {
                Request.validateMaturityId(maturityId);
            }
            this.maturityId = maturityId;
            Request.validateModuleType(moduleType);
            this.moduleType = moduleType;
        }
        static fromDTO(dto) {
            const investmentId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "investmentId", InvalidRequestError);
            const maturityId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "maturityId", InvalidRequestError);
            const moduleType = moduleType_1.ModuleType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "moduleType", InvalidRequestError));
            return new Request(investmentId, maturityId, moduleType);
        }
        toDTO() {
            const ret = {
                moduleType: moduleType_1.ModuleType.toDTO(this.moduleType),
            };
            if (this.investmentId !== undefined && this.investmentId !== null) {
                ret.investmentId = this.investmentId;
            }
            if (this.maturityId !== undefined && this.maturityId !== null) {
                ret.maturityId = this.maturityId;
            }
            return ret;
        }
        copy(investmentId = this.investmentId, maturityId = this.maturityId, moduleType = this.moduleType) {
            return new Request(investmentId, maturityId, moduleType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetInvestmentCashflowBannerInfoRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.investmentId !== other.investmentId) {
                return false;
            }
            if (this.maturityId !== other.maturityId) {
                return false;
            }
            if (this.moduleType !== other.moduleType) {
                return false;
            }
            return true;
        }
    }
    Request.validateInvestmentId = (investmentId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(investmentId)) {
            throw new InvalidRequestError(`Attribute investmentId is not an Int64`);
        }
    };
    Request.validateMaturityId = (maturityId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(maturityId)) {
            throw new InvalidRequestError(`Attribute maturityId is not an Int64`);
        }
    };
    Request.validateModuleType = (moduleType) => {
        if (!moduleType_1.ModuleType.isInstanceOf(moduleType)) {
            throw new InvalidRequestError(`Attribute moduleType is not a ModuleType.ModuleType`);
        }
    };
    GetInvestmentCashflowBannerInfoRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetInvestmentCashflowBannerInfoRPC.Request(investmentId=${this.investmentId},maturityId=${this.maturityId},moduleType=${this.moduleType})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetInvestmentCashflowBannerInfoRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(totalInvestment, amountPaidOut = null, accrualsTillDate = null, settledCashflows, pendingCashflows) {
            this.isGetInvestmentCashflowBannerInfoRPCResponse = true;
            Response.validateTotalInvestment(totalInvestment);
            this.totalInvestment = totalInvestment;
            if (amountPaidOut !== undefined && amountPaidOut !== null) {
                Response.validateAmountPaidOut(amountPaidOut);
            }
            this.amountPaidOut = amountPaidOut;
            if (accrualsTillDate !== undefined && accrualsTillDate !== null) {
                Response.validateAccrualsTillDate(accrualsTillDate);
            }
            this.accrualsTillDate = accrualsTillDate;
            Response.validateSettledCashflows(settledCashflows);
            this.settledCashflows = settledCashflows;
            Response.validatePendingCashflows(pendingCashflows);
            this.pendingCashflows = pendingCashflows;
        }
        static fromDTO(dto) {
            const totalInvestment = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "totalInvestment", InvalidResponseError));
            let amountPaidOut = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amountPaidOut");
            if (amountPaidOut !== undefined && amountPaidOut !== null) {
                amountPaidOut = bigAmount_1.BigAmount.fromDTO(amountPaidOut);
            }
            let accrualsTillDate = (0, leo_ts_runtime_1.getOptionalObject)(dto, "accrualsTillDate");
            if (accrualsTillDate !== undefined && accrualsTillDate !== null) {
                accrualsTillDate = bigAmount_1.BigAmount.fromDTO(accrualsTillDate);
            }
            const settledCashflows = settledCashflows_1.SettledCashflows.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "settledCashflows", InvalidResponseError));
            const pendingCashflows = pendingCashflows_1.PendingCashflows.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pendingCashflows", InvalidResponseError));
            return new Response(totalInvestment, amountPaidOut, accrualsTillDate, settledCashflows, pendingCashflows);
        }
        toDTO() {
            const ret = {
                totalInvestment: this.totalInvestment.toDTO(),
                settledCashflows: this.settledCashflows.toDTO(),
                pendingCashflows: this.pendingCashflows.toDTO(),
            };
            if (this.amountPaidOut) {
                ret.amountPaidOut = this.amountPaidOut.toDTO();
            }
            if (this.accrualsTillDate) {
                ret.accrualsTillDate = this.accrualsTillDate.toDTO();
            }
            return ret;
        }
        copy(totalInvestment = this.totalInvestment, amountPaidOut = this.amountPaidOut, accrualsTillDate = this.accrualsTillDate, settledCashflows = this.settledCashflows, pendingCashflows = this.pendingCashflows) {
            return new Response(totalInvestment, amountPaidOut, accrualsTillDate, settledCashflows, pendingCashflows);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetInvestmentCashflowBannerInfoRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.totalInvestment.equals(other.totalInvestment)) {
                return false;
            }
            if (this.amountPaidOut) {
                if (!this.amountPaidOut.equals(other.amountPaidOut)) {
                    return false;
                }
            }
            if (this.accrualsTillDate) {
                if (!this.accrualsTillDate.equals(other.accrualsTillDate)) {
                    return false;
                }
            }
            if (!this.settledCashflows.equals(other.settledCashflows)) {
                return false;
            }
            if (!this.pendingCashflows.equals(other.pendingCashflows)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTotalInvestment = (totalInvestment) => {
        const isBigAmountValue = totalInvestment.isBigAmount;
        if (!(totalInvestment instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidResponseError(`Attribute totalInvestment is not a BigAmount`);
        }
    };
    Response.validateAmountPaidOut = (amountPaidOut) => {
        const isBigAmountValue = amountPaidOut.isBigAmount;
        if (!(amountPaidOut instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidResponseError(`Attribute amountPaidOut is not a BigAmount`);
        }
    };
    Response.validateAccrualsTillDate = (accrualsTillDate) => {
        const isBigAmountValue = accrualsTillDate.isBigAmount;
        if (!(accrualsTillDate instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidResponseError(`Attribute accrualsTillDate is not a BigAmount`);
        }
    };
    Response.validateSettledCashflows = (settledCashflows) => {
        const isSettledCashflowsValue = settledCashflows.isSettledCashflows;
        if (!(settledCashflows instanceof settledCashflows_1.SettledCashflows || Boolean(isSettledCashflowsValue))) {
            throw new InvalidResponseError(`Attribute settledCashflows is not a SettledCashflows`);
        }
    };
    Response.validatePendingCashflows = (pendingCashflows) => {
        const isPendingCashflowsValue = pendingCashflows.isPendingCashflows;
        if (!(pendingCashflows instanceof pendingCashflows_1.PendingCashflows || Boolean(isPendingCashflowsValue))) {
            throw new InvalidResponseError(`Attribute pendingCashflows is not a PendingCashflows`);
        }
    };
    GetInvestmentCashflowBannerInfoRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetInvestmentCashflowBannerInfoRPC.Response(totalInvestment=${this.totalInvestment},amountPaidOut=${this.amountPaidOut},accrualsTillDate=${this.accrualsTillDate},settledCashflows=${this.settledCashflows},pendingCashflows=${this.pendingCashflows})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidInvestmentId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_INVESTMENT_ID") {
                super(code);
                this.isInvalidInvestmentId = true;
                InvalidInvestmentId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidInvestmentId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidInvestmentId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidInvestmentIdValue = other.isInvalidInvestmentId;
                if (!(other instanceof InvalidInvestmentId || Boolean(isInvalidInvestmentIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidInvestmentId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_INVESTMENT_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidInvestmentId = InvalidInvestmentId;
        InvalidInvestmentId.prototype.toString = function toString() {
            return `GetInvestmentCashflowBannerInfoRPC.INVALID_INVESTMENT_ID()`;
        };
        class InvalidMaturityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_MATURITY_ID") {
                super(code);
                this.isInvalidMaturityId = true;
                InvalidMaturityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidMaturityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidMaturityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidMaturityIdValue = other.isInvalidMaturityId;
                if (!(other instanceof InvalidMaturityId || Boolean(isInvalidMaturityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidMaturityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_MATURITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidMaturityId = InvalidMaturityId;
        InvalidMaturityId.prototype.toString = function toString() {
            return `GetInvestmentCashflowBannerInfoRPC.INVALID_MATURITY_ID()`;
        };
        class InvalidModuleId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_MODULE_ID") {
                super(code);
                this.isInvalidModuleId = true;
                InvalidModuleId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidModuleId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidModuleId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidModuleIdValue = other.isInvalidModuleId;
                if (!(other instanceof InvalidModuleId || Boolean(isInvalidModuleIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidModuleId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_MODULE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidModuleId = InvalidModuleId;
        InvalidModuleId.prototype.toString = function toString() {
            return `GetInvestmentCashflowBannerInfoRPC.INVALID_MODULE_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_INVESTMENT_ID":
                    return InvalidInvestmentId.fromDTO(dto);
                case "INVALID_MATURITY_ID":
                    return InvalidMaturityId.fromDTO(dto);
                case "INVALID_MODULE_ID":
                    return InvalidModuleId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidInvestmentIdValue = other.isInvalidInvestmentId;
            if (other instanceof InvalidInvestmentId || Boolean(isInvalidInvestmentIdValue)) {
                return true;
            }
            const isInvalidMaturityIdValue = other.isInvalidMaturityId;
            if (other instanceof InvalidMaturityId || Boolean(isInvalidMaturityIdValue)) {
                return true;
            }
            const isInvalidModuleIdValue = other.isInvalidModuleId;
            if (other instanceof InvalidModuleId || Boolean(isInvalidModuleIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetInvestmentCashflowBannerInfoRPC.Errors || (GetInvestmentCashflowBannerInfoRPC.Errors = {}));
})(GetInvestmentCashflowBannerInfoRPC = exports.GetInvestmentCashflowBannerInfoRPC || (exports.GetInvestmentCashflowBannerInfoRPC = {}));
