"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiHoldingCategory = exports.InvalidFiHoldingCategoryError = void 0;
class InvalidFiHoldingCategoryError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiHoldingCategoryError";
    }
}
exports.InvalidFiHoldingCategoryError = InvalidFiHoldingCategoryError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiHoldingCategory;
(function (FiHoldingCategory_1) {
    let FiHoldingCategory;
    (function (FiHoldingCategory) {
        FiHoldingCategory["HTM"] = "HTM";
        FiHoldingCategory["AFS"] = "AFS";
        FiHoldingCategory["HFT"] = "HFT";
    })(FiHoldingCategory = FiHoldingCategory_1.FiHoldingCategory || (FiHoldingCategory_1.FiHoldingCategory = {}));
    FiHoldingCategory_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "HTM":
                return FiHoldingCategory.HTM;
            case "AFS":
                return FiHoldingCategory.AFS;
            case "HFT":
                return FiHoldingCategory.HFT;
            default:
                throw new InvalidFiHoldingCategoryError(`Case ${dto.case} is not valid case of FiHoldingCategory`);
        }
    };
    FiHoldingCategory_1.toDTO = (fiHoldingCategory) => {
        const ret = {
            case: FiHoldingCategory[fiHoldingCategory],
        };
        return ret;
    };
    FiHoldingCategory_1.isInstanceOf = (other) => {
        if (other in FiHoldingCategory) {
            return true;
        }
        return false;
    };
})(FiHoldingCategory = exports.FiHoldingCategory || (exports.FiHoldingCategory = {}));
/* eslint-enable @typescript-eslint/no-shadow */
