"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFSellPortfolioListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const portfolio_1 = require("../types/portfolio");
/* eslint-disable import/export */
class GetMFSellPortfolioListRPC {
}
exports.GetMFSellPortfolioListRPC = GetMFSellPortfolioListRPC;
(function (GetMFSellPortfolioListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFSellPortfolioListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(isin, entityId) {
            this.isGetMFSellPortfolioListRPCRequest = true;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            return new Request(isin, entityId);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
                entityId: this.entityId.toDTO(),
            };
            return ret;
        }
        copy(isin = this.isin, entityId = this.entityId) {
            return new Request(isin, entityId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFSellPortfolioListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            return true;
        }
    }
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    GetMFSellPortfolioListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFSellPortfolioListRPC.Request(isin=${this.isin},entityId=${this.entityId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFSellPortfolioListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(portfolios) {
            this.isGetMFSellPortfolioListRPCResponse = true;
            Response.validatePortfolios(portfolios);
            this.portfolios = portfolios;
        }
        static fromDTO(dto) {
            const portfolios = (0, leo_ts_runtime_1.getList)(dto, "portfolios", portfolio_1.Portfolio.fromDTO, InvalidResponseError);
            return new Response(portfolios);
        }
        toDTO() {
            const ret = {
                portfolios: this.portfolios.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(portfolios = this.portfolios) {
            return new Response(portfolios);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFSellPortfolioListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.portfolios, other.portfolios)) {
                return false;
            }
            return true;
        }
    }
    Response.validatePortfolios = (portfolios) => {
        if (!Array.isArray(portfolios)) {
            throw new InvalidResponseError(`Attribute portfolios is not a List`);
        }
        for (let i = 0; i < portfolios.length; i += 1) {
            const isPortfolioValue = portfolios[i].isPortfolio;
            if (!(portfolios[i] instanceof portfolio_1.Portfolio || Boolean(isPortfolioValue))) {
                throw new InvalidResponseError(`Attribute portfolios at index ${i} is not a Portfolio.`);
            }
        }
    };
    GetMFSellPortfolioListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFSellPortfolioListRPC.Response(portfolios=${this.portfolios})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetMFSellPortfolioListRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetMFSellPortfolioListRPC.INVALID_ENTITY_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFSellPortfolioListRPC.Errors || (GetMFSellPortfolioListRPC.Errors = {}));
})(GetMFSellPortfolioListRPC = exports.GetMFSellPortfolioListRPC || (exports.GetMFSellPortfolioListRPC = {}));
