"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFConfirmationRequestDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mFConfirmationActions_1 = require("./mFConfirmationActions");
const detailCellDiff_1 = require("../types/detailCellDiff");
const contractNoteDetailHeader_1 = require("../types/contractNoteDetailHeader");
const mFConfirmationRequestStatus_1 = require("./mFConfirmationRequestStatus");
/* eslint-disable import/export */
class GetMFConfirmationRequestDetailsRPC {
}
exports.GetMFConfirmationRequestDetailsRPC = GetMFConfirmationRequestDetailsRPC;
(function (GetMFConfirmationRequestDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFConfirmationRequestDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId) {
            this.isGetMFConfirmationRequestDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            return new Request(requestId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
            };
            return ret;
        }
        copy(requestId = this.requestId) {
            return new Request(requestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFConfirmationRequestDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    GetMFConfirmationRequestDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFConfirmationRequestDetailsRPC.Request(requestId=${this.requestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFConfirmationRequestDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(requestAction, requestDetails, requestStatus, headers = null, confirmationDocumentURL = null) {
            this.isGetMFConfirmationRequestDetailsRPCResponse = true;
            Response.validateRequestAction(requestAction);
            this.requestAction = requestAction;
            Response.validateRequestDetails(requestDetails);
            this.requestDetails = requestDetails;
            Response.validateRequestStatus(requestStatus);
            this.requestStatus = requestStatus;
            if (headers !== undefined && headers !== null) {
                Response.validateHeaders(headers);
            }
            this.headers = headers;
            if (confirmationDocumentURL !== undefined && confirmationDocumentURL !== null) {
                Response.validateConfirmationDocumentURL(confirmationDocumentURL);
            }
            this.confirmationDocumentURL = confirmationDocumentURL;
        }
        static fromDTO(dto) {
            const requestAction = mFConfirmationActions_1.MFConfirmationActions.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestAction", InvalidResponseError));
            const requestDetails = (0, leo_ts_runtime_1.getList)(dto, "requestDetails", detailCellDiff_1.DetailCellDiff.fromDTO, InvalidResponseError);
            const requestStatus = mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestStatus", InvalidResponseError));
            let headers = (0, leo_ts_runtime_1.getOptionalObject)(dto, "headers");
            if (headers !== undefined && headers !== null) {
                headers = contractNoteDetailHeader_1.ContractNoteDetailHeader.fromDTO(headers);
            }
            const confirmationDocumentURL = (0, leo_ts_runtime_1.getOptionalUrl)(dto, "confirmationDocumentURL", InvalidResponseError);
            return new Response(requestAction, requestDetails, requestStatus, headers, confirmationDocumentURL);
        }
        toDTO() {
            const ret = {
                requestAction: this.requestAction.toDTO(),
                requestDetails: this.requestDetails.map((e) => {
                    return e.toDTO();
                }),
                requestStatus: mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.toDTO(this.requestStatus),
            };
            if (this.headers) {
                ret.headers = this.headers.toDTO();
            }
            if (this.confirmationDocumentURL) {
                ret.confirmationDocumentURL = this.confirmationDocumentURL.toString();
            }
            return ret;
        }
        copy(requestAction = this.requestAction, requestDetails = this.requestDetails, requestStatus = this.requestStatus, headers = this.headers, confirmationDocumentURL = this.confirmationDocumentURL) {
            return new Response(requestAction, requestDetails, requestStatus, headers, confirmationDocumentURL);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFConfirmationRequestDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.requestAction.equals(other.requestAction)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.requestDetails, other.requestDetails)) {
                return false;
            }
            if (this.requestStatus !== other.requestStatus) {
                return false;
            }
            if (this.headers) {
                if (!this.headers.equals(other.headers)) {
                    return false;
                }
            }
            if (this.confirmationDocumentURL) {
                if (other.confirmationDocumentURL instanceof URL) {
                    if (this.confirmationDocumentURL.toString() !== other.confirmationDocumentURL.toString()) {
                        return false;
                    }
                }
            }
            return true;
        }
    }
    Response.validateRequestAction = (requestAction) => {
        const isMFConfirmationActionsValue = requestAction.isMFConfirmationActions;
        if (!(requestAction instanceof mFConfirmationActions_1.MFConfirmationActions || Boolean(isMFConfirmationActionsValue))) {
            throw new InvalidResponseError(`Attribute requestAction is not a MFConfirmationActions`);
        }
    };
    Response.validateRequestDetails = (requestDetails) => {
        if (!Array.isArray(requestDetails)) {
            throw new InvalidResponseError(`Attribute requestDetails is not a List`);
        }
        for (let i = 0; i < requestDetails.length; i += 1) {
            const isDetailCellDiffValue = requestDetails[i].isDetailCellDiff;
            if (!(requestDetails[i] instanceof detailCellDiff_1.DetailCellDiff || Boolean(isDetailCellDiffValue))) {
                throw new InvalidResponseError(`Attribute requestDetails at index ${i} is not a DetailCellDiff.`);
            }
        }
    };
    Response.validateRequestStatus = (requestStatus) => {
        if (!mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.isInstanceOf(requestStatus)) {
            throw new InvalidResponseError(`Attribute requestStatus is not a MFConfirmationRequestStatus.MFConfirmationRequestStatus`);
        }
    };
    Response.validateHeaders = (headers) => {
        const isContractNoteDetailHeaderValue = headers.isContractNoteDetailHeader;
        if (!(headers instanceof contractNoteDetailHeader_1.ContractNoteDetailHeader || Boolean(isContractNoteDetailHeaderValue))) {
            throw new InvalidResponseError(`Attribute headers is not a ContractNoteDetailHeader`);
        }
    };
    Response.validateConfirmationDocumentURL = (confirmationDocumentURL) => {
        if (!(confirmationDocumentURL instanceof URL)) {
            throw new InvalidResponseError(`Attribute confirmationDocumentURL is not a URL`);
        }
    };
    GetMFConfirmationRequestDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFConfirmationRequestDetailsRPC.Response(requestAction=${this.requestAction},requestDetails=${this.requestDetails},requestStatus=${this.requestStatus},headers=${this.headers},confirmationDocumentURL=${this.confirmationDocumentURL})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetMFConfirmationRequestDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFConfirmationRequestDetailsRPC.Errors || (GetMFConfirmationRequestDetailsRPC.Errors = {}));
})(GetMFConfirmationRequestDetailsRPC = exports.GetMFConfirmationRequestDetailsRPC || (exports.GetMFConfirmationRequestDetailsRPC = {}));
