import { Instance, types } from "mobx-state-tree";
import { EquityChangeInGainResponse } from "@khazana/khazana-rpcs";
import { getAmountOrUndefined } from "../../../../../utils";

export const ChangeInGainModel = types.model("ChangeInGainModel", {
  symbol: types.string,
  costNewShares: types.maybe(types.number),
  costSoldShares: types.maybe(types.number),
  costOnEndDate: types.maybe(types.number),
  totalDividend: types.maybe(types.number),
  irr: types.maybe(types.number),
  change: types.maybe(types.number),
  mvOldShares: types.maybe(types.number),
  rgNewShares: types.maybe(types.number),
  rgOldShares: types.maybe(types.number),
  salesProceeds: types.maybe(types.number),
  totalMVOnEndDate: types.maybe(types.number),
  ugNewShares: types.maybe(types.number),
  ugOldShares: types.maybe(types.number),
  totalUg: types.maybe(types.number),
  totalRg: types.maybe(types.number),
  tgOnOldShares: types.maybe(types.number),
  tgOnNewShares: types.maybe(types.number),
  totalGains: types.maybe(types.number),
});

export const createChangeInGainModel = (
  response: EquityChangeInGainResponse,
): Instance<typeof ChangeInGainModel> => {
  return ChangeInGainModel.create({
    symbol: response.symbol,
    costNewShares: getAmountOrUndefined(response.costNewShares),
    costSoldShares: getAmountOrUndefined(response.costSoldShares),
    costOnEndDate: getAmountOrUndefined(response.costOnEndDate),
    totalDividend: getAmountOrUndefined(response.totalDividend),
    irr: getAmountOrUndefined(response.irr),
    change: getAmountOrUndefined(response.change),
    mvOldShares: getAmountOrUndefined(response.mvOldShares),
    rgNewShares: getAmountOrUndefined(response.rgNewShares),
    rgOldShares: getAmountOrUndefined(response.rgOldShares),
    salesProceeds: getAmountOrUndefined(response.salesProceeds),
    totalMVOnEndDate: getAmountOrUndefined(response.totalMVOnEndDate),
    ugNewShares: getAmountOrUndefined(response.ugNewShares),
    ugOldShares: getAmountOrUndefined(response.ugOldShares),
    totalUg: getAmountOrUndefined(response.totalUg),
    totalRg: getAmountOrUndefined(response.totalRg),
    tgOnOldShares: getAmountOrUndefined(response.tgOnOldShares),
    tgOnNewShares: getAmountOrUndefined(response.tgOnNewShares),
    totalGains: getAmountOrUndefined(response.totalGains),
  });
};
