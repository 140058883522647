import { Instance, types } from "mobx-state-tree";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import { MFDealRequestStatusModel } from "../../deal-request/models/MFDealRequestStatusModel";
import { MFTransactionTypeModel } from "../../deal-request/models/MFTransactionTypeModel";
import { GetMFApprovedDealRequestsPaginationResponse } from "@khazana/khazana-rpcs";

export const MFLinkDealRequestModel = types.model("MFLinkDealRequestModel", {
  dealRequestId: types.number,
  folioNumber: types.string,
  schemeName: types.string,
  amountHCY: types.maybe(types.string),
  currency: CurrencyModel,
  units: types.maybe(types.string),
  entityName: types.string,
  status: MFDealRequestStatusModel,
  transactionType: MFTransactionTypeModel,
});

export const createMFLinkDealRequestModel = (
  response?: GetMFApprovedDealRequestsPaginationResponse,
): Instance<typeof MFLinkDealRequestModel> => {
  if (!response) {
    return MFLinkDealRequestModel.create();
  }
  return MFLinkDealRequestModel.create({
    dealRequestId: response.mfDealRequestId,
    folioNumber: response.folioNumber,
    schemeName: response.schemeName,
    amountHCY: response.amountHCY?.decimalValue ?? undefined,
    currency: createCurrencyModel(response.currency),
    units: response.units?.decimalValue ?? undefined,
    entityName: response.entityName,
    status: response.currentDealStatus,
    transactionType: response.transactionType,
  });
};
