"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BigDecimal = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidBigDecimalError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBigDecimalError";
    }
}
class BigDecimal {
    constructor(decimalValue, isPositive = null) {
        this.isBigDecimal = true;
        BigDecimal.validateDecimalValue(decimalValue);
        this.decimalValue = decimalValue;
        if (isPositive !== undefined && isPositive !== null) {
            BigDecimal.validateIsPositive(isPositive);
        }
        this.isPositive = isPositive;
    }
    static fromDTO(dto) {
        const decimalValue = (0, leo_ts_runtime_1.getString)(dto, "decimalValue", InvalidBigDecimalError);
        const isPositive = (0, leo_ts_runtime_1.getOptionalBoolean)(dto, "isPositive", InvalidBigDecimalError);
        return new BigDecimal(decimalValue, isPositive);
    }
    toDTO() {
        const ret = {
            decimalValue: this.decimalValue,
        };
        if (this.isPositive !== undefined && this.isPositive !== null) {
            ret.isPositive = this.isPositive;
        }
        return ret;
    }
    copy(decimalValue = this.decimalValue, isPositive = this.isPositive) {
        return new BigDecimal(decimalValue, isPositive);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isBigDecimalValue = other.isBigDecimal;
        if (!(other instanceof BigDecimal) || Boolean(isBigDecimalValue)) {
            return false;
        }
        if (this.decimalValue !== other.decimalValue) {
            return false;
        }
        if (this.isPositive !== other.isPositive) {
            return false;
        }
        return true;
    }
}
exports.BigDecimal = BigDecimal;
BigDecimal.validateDecimalValue = (decimalValue) => {
    if (!(0, leo_ts_runtime_1.isString)(decimalValue)) {
        throw new InvalidBigDecimalError(`Attribute decimalValue is not a String`);
    }
    if (decimalValue.trim().length === 0) {
        throw new InvalidBigDecimalError(`Attribute decimalValue cannot be an empty string.`);
    }
};
BigDecimal.validateIsPositive = (isPositive) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isPositive)) {
        throw new InvalidBigDecimalError(`Attribute isPositive is not a Boolean`);
    }
};
BigDecimal.prototype.toString = function toString() {
    return `BigDecimal(decimalValue='${this.decimalValue}',isPositive=${this.isPositive})`;
};
