import {
  Instance,
  getParent,
  types,
  castToSnapshot,
  flow,
  getEnv,
} from "mobx-state-tree";
import { EquityStore, createEquityStore } from "../equity/store/EquityStore";
import {
  AppConfigurationStore,
  createAppConfigurationStore,
} from "./AppConfigurationStore";
import { removePersistedUserPrivileges } from "../../user/UserPrivileges";
import { RootStore } from "../../root/store/RootStore";
import {
  UserStore,
  createUserStore,
  removePersistedUserData,
} from "../../user/UserStore";
import { FiStore, createFiStore } from "../fixed-income/store/FiStore";
import {
  SettingsStore,
  createConfigurationStore,
} from "../settings/store/SettingsStore";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { HealthCheckRPC, UserName } from "@khazana/khazana-rpcs";
import { LeoEmailId, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useHealthCheckRPCClientImpl } from "../rpcs/RPC";
import { createFDStore, FDStore } from "../fixed-deposit/store/FDStore";
import { createDocumentStore, DocumentStore } from "./DocumentStore";
import { createMFStore, MFStore } from "../mutual-fund/store/MFStore";

export const HomeStore = types
  .model("HomeStore", {
    appConfigurationStore: AppConfigurationStore,
    settingsStore: SettingsStore,
    userStore: UserStore,
    isEquityEnabled: types.optional(
      types.boolean,
      localStorage.getItem("isEquityEnabled") === "true" ? true : false,
    ),
    equityStore: EquityStore,
    isFIEnabled: types.optional(
      types.boolean,
      localStorage.getItem("isFIEnabled") === "true" ? true : false,
    ),
    fiStore: FiStore,
    isFDEnabled: types.optional(
      types.boolean,
      localStorage.getItem("isFDEnabled") === "true" ? true : false,
    ),
    fdStore: FDStore,
    isMFEnabled: types.optional(
      types.boolean,
      localStorage.getItem("isMFEnabled") === "true" ? true : false,
    ),
    mfStore: MFStore,
    documentStore: DocumentStore,
  })
  .actions((store) => ({
    signOutUser(): void {
      removePersistedUserPrivileges();
      removePersistedUserData();
      const rootStore = getParent<typeof RootStore>(store);
      rootStore.signOutUser();
    },
    setUserPrivileges(privileges: string[]): void {
      store.userStore.setPrivileges(privileges);
    },
    setUserData(username: UserName, emailId: LeoEmailId): void {
      store.userStore.setUserData(username, emailId);
    },
    setIsEquityEnabled(isEnabled: boolean): void {
      store.isEquityEnabled = isEnabled;
      localStorage.setItem("isEquityEnabled", `${isEnabled}`);
    },
    setIsFIEnabled(isEnabled: boolean): void {
      store.isFIEnabled = isEnabled;
      localStorage.setItem("isFIEnabled", `${isEnabled}`);
    },
    setIsFDEnabled(isEnabled: boolean): void {
      store.isFDEnabled = isEnabled;
      localStorage.setItem("isFDEnabled", `${isEnabled}`);
    },
    setIsMFEnabled(isEnabled: boolean): void {
      store.isMFEnabled = isEnabled;
      localStorage.setItem("isMFEnabled", `${isEnabled}`);
    },
  }))
  .actions((store) => ({
    getUserPrivileges: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new HealthCheckRPC.Request();
      const result: LeoRPCResult<
        HealthCheckRPC.Response,
        HealthCheckRPC.Errors.Errors
      > = yield useHealthCheckRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.setUserPrivileges(response.privileges);
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        logger.error("Invalid error state", error);
      } else {
        logger.error("Invalid error state");
      }
    }),
  }));

export const createHomeStore = (): Instance<typeof HomeStore> => {
  return HomeStore.create({
    equityStore: castToSnapshot(createEquityStore()),
    settingsStore: createConfigurationStore(),
    fiStore: castToSnapshot(createFiStore()),
    fdStore: castToSnapshot(createFDStore()),
    mfStore: castToSnapshot(createMFStore()),
    documentStore: createDocumentStore(),
    userStore: createUserStore(),
    appConfigurationStore: createAppConfigurationStore(),
  });
};
