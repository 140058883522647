import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./en/translation.json";
import { numericFormatter } from "react-number-format";

export const resources = {
  en: {
    translation: enTranslation,
  },
};

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  resources,
  interpolation: {
    escapeValue: false,
    format: (value: string | undefined, format: string | undefined): string => {
      switch (format) {
        case "lowercase":
          return value?.toLowerCase() ?? "";
        case "numberFormat":
          if (!value) return "-";
          return numericFormatter(value, {
            decimalScale: 0,
            allowNegative: true,
            thousandsGroupStyle: "lakh",
            thousandSeparator: ",",
          });
        case "decimal2":
          if (!value) return "-";
          return numericFormatter(value, {
            decimalScale: 2,
            allowNegative: true,
            thousandsGroupStyle: "lakh",
            thousandSeparator: ",",
          });
        case "decimal4":
          if (!value) return "-";
          return numericFormatter(value, {
            decimalScale: 4,
            allowNegative: true,
            thousandsGroupStyle: "lakh",
            thousandSeparator: ",",
          });
        case "currencyWithoutParentheses":
          if (!value) return "";
          return `${value}`;
        case "currency":
          if (!value) return "";
          return `(${value})`;
        default:
          return value ?? "";
      }
    },
  },
});
