import {
  GetMFHoldingSummaryDownloadURLRPC,
  GetMFHoldingSummaryDownloadURLRPCClientImpl,
  GetMFHoldingSummaryReportRPC,
  GetMFHoldingSummaryReportRPCClientImpl,
  GetMFInvestmentReturnsGrowthDownloadURLRPC,
  GetMFInvestmentReturnsGrowthDownloadURLRPCClientImpl,
  GetMFInvestmentReturnsGrowthReportRPC,
  GetMFInvestmentReturnsGrowthReportRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetMFInvestmentReturnsGrowthReportRPC } from "./MockGetMFInvestmentReturnsGrowthReportRPC";
import { MockGetMFInvestmentReturnsGrowthDownloadURLRPC } from "./MockGetMFInvestmentReturnsGrowthDownloadURLRPC";
import { MockGetMFHoldingSummaryReportRPC } from "./MockGetMFHoldingSummaryReportRPC";
import { MockGetMFHoldingSummaryDownloadURLRPC } from "./MockGetMFHoldingSummaryDownloadURLRPC";

export const useGetMFHoldingSummaryReportRPCClientImpl = (
  apiClient: APIClient,
): GetMFHoldingSummaryReportRPC => {
  if (MOCK.mf) {
    return new MockGetMFHoldingSummaryReportRPC();
  } else {
    return new GetMFHoldingSummaryReportRPCClientImpl(apiClient);
  }
};

export const useGetMFHoldingSummaryDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetMFHoldingSummaryDownloadURLRPC => {
  if (MOCK.mf) {
    return new MockGetMFHoldingSummaryDownloadURLRPC();
  } else {
    return new GetMFHoldingSummaryDownloadURLRPCClientImpl(apiClient);
  }
};

export const useGetMFInvestmentReturnsGrowthReportRPCClientImpl = (
  apiClient: APIClient,
): GetMFInvestmentReturnsGrowthReportRPC => {
  if (MOCK.mf) {
    return new MockGetMFInvestmentReturnsGrowthReportRPC();
  } else {
    return new GetMFInvestmentReturnsGrowthReportRPCClientImpl(apiClient);
  }
};

export const useGetMFInvestmentReturnsGrowthDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetMFInvestmentReturnsGrowthDownloadURLRPC => {
  if (MOCK.mf) {
    return new MockGetMFInvestmentReturnsGrowthDownloadURLRPC();
  } else {
    return new GetMFInvestmentReturnsGrowthDownloadURLRPCClientImpl(apiClient);
  }
};
