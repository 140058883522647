"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFConfirmationSearchBy = exports.InvalidMFConfirmationSearchByError = void 0;
class InvalidMFConfirmationSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFConfirmationSearchByError";
    }
}
exports.InvalidMFConfirmationSearchByError = InvalidMFConfirmationSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFConfirmationSearchBy;
(function (MFConfirmationSearchBy_1) {
    let MFConfirmationSearchBy;
    (function (MFConfirmationSearchBy) {
        MFConfirmationSearchBy["SCHEME"] = "SCHEME";
        MFConfirmationSearchBy["REFERENCE_NUMBER"] = "REFERENCE_NUMBER";
        MFConfirmationSearchBy["REQUEST_ID"] = "REQUEST_ID";
    })(MFConfirmationSearchBy = MFConfirmationSearchBy_1.MFConfirmationSearchBy || (MFConfirmationSearchBy_1.MFConfirmationSearchBy = {}));
    MFConfirmationSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "SCHEME":
                return MFConfirmationSearchBy.SCHEME;
            case "REFERENCE_NUMBER":
                return MFConfirmationSearchBy.REFERENCE_NUMBER;
            case "REQUEST_ID":
                return MFConfirmationSearchBy.REQUEST_ID;
            default:
                throw new InvalidMFConfirmationSearchByError(`Case ${dto.case} is not valid case of MFConfirmationSearchBy`);
        }
    };
    MFConfirmationSearchBy_1.toDTO = (mFConfirmationSearchBy) => {
        const ret = {
            case: MFConfirmationSearchBy[mFConfirmationSearchBy],
        };
        return ret;
    };
    MFConfirmationSearchBy_1.isInstanceOf = (other) => {
        if (other in MFConfirmationSearchBy) {
            return true;
        }
        return false;
    };
})(MFConfirmationSearchBy = exports.MFConfirmationSearchBy || (exports.MFConfirmationSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
