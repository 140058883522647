import {
  BigDecimal,
  GetMFDealRequestImpactRPC,
  MFImpactTable,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFDealRequestImpactRPCImpl extends GetMFDealRequestImpactRPC {
  execute(
    _request: GetMFDealRequestImpactRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFDealRequestImpactRPC.Response,
      GetMFDealRequestImpactRPC.Errors.Errors
    >
  > {
    const response = new GetMFDealRequestImpactRPC.Response(
      new MFImpactTable(
        new BigDecimal("10000"),
        new BigDecimal("10000"),
        new BigDecimal("10000"),
        new BigDecimal("10000"),
        new BigDecimal("10000"),
        new BigDecimal("10000"),
      ),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFDealRequestImpactRPC.Response,
        GetMFDealRequestImpactRPC.Errors.Errors
      >
    >;
  }
}
