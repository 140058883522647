import { Instance, types } from "mobx-state-tree";
import { createCurrencyModel, CurrencyModel } from "./CurrencyModel";
import { DashboardStat } from "@khazana/khazana-rpcs";
import {
  CRORE,
  LAKH,
  PRECISION_FACTOR,
  getFormattedAmount,
} from "../../../utils";
import i18next from "i18next";

export const DashboardStatModel = types.model("DashboardStatModel", {
  amount: types.maybe(types.string),
  fullAmount: types.maybe(types.string),
  currency: types.maybe(CurrencyModel),
  isGain: types.maybe(types.boolean),
  percentage: types.maybe(types.string),
});

export const createDashboardStatModel = (
  stat?: DashboardStat | undefined,
  isAmountInCrore = true,
): Instance<typeof DashboardStatModel> => {
  if (stat) {
    return DashboardStatModel.create({
      amount:
        isAmountInCrore &&
        stat.amount &&
        Math.abs(stat.amount) >= LAKH * PRECISION_FACTOR
          ? i18next.t("common.croreSuffix", {
              val: getFormattedAmount(stat.amount / CRORE),
            })
          : stat.amount
            ? getFormattedAmount(stat.amount)
            : undefined,
      fullAmount:
        isAmountInCrore &&
        stat.amount &&
        Math.abs(stat.amount) >= LAKH * PRECISION_FACTOR
          ? getFormattedAmount(stat.amount)
          : undefined,
      currency: stat.currency ? createCurrencyModel(stat.currency) : undefined,
      isGain: stat.isGain ?? undefined,
      percentage: stat.percentage
        ? getFormattedAmount(stat.percentage)
        : undefined,
    });
  }
  return DashboardStatModel.create({});
};
