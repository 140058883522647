"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiSubmitContractNoteRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiContractNoteEdits_1 = require("./fiContractNoteEdits");
const note_1 = require("../types/note");
const fiContractNoteRow_1 = require("./fiContractNoteRow");
const fiContractNoteCharge_1 = require("./fiContractNoteCharge");
/* eslint-disable import/export */
class FiSubmitContractNoteRequestRPC {
}
exports.FiSubmitContractNoteRequestRPC = FiSubmitContractNoteRequestRPC;
(function (FiSubmitContractNoteRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    FiSubmitContractNoteRequestRPC.InvalidRequestError = InvalidRequestError;
    let RequestEnums;
    (function (RequestEnums) {
        let ContractNote;
        (function (ContractNote) {
            class Parsed {
                constructor(fiContractNoteHistoryId) {
                    this.isParsed = true;
                    Parsed.validateFiContractNoteHistoryId(fiContractNoteHistoryId);
                    this.fiContractNoteHistoryId = fiContractNoteHistoryId;
                }
                static fromDTO(dto) {
                    const fiContractNoteHistoryId = (0, leo_ts_runtime_1.getInt64)(dto, "fiContractNoteHistoryId", InvalidRequestError);
                    return new Parsed(fiContractNoteHistoryId);
                }
                toDTO() {
                    const ret = {
                        case: "PARSED",
                        fiContractNoteHistoryId: this.fiContractNoteHistoryId,
                    };
                    return ret;
                }
                copy(fiContractNoteHistoryId = this.fiContractNoteHistoryId) {
                    return new Parsed(fiContractNoteHistoryId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isParsedValue = other.isParsed;
                    if (!(other instanceof Parsed) || Boolean(isParsedValue)) {
                        return false;
                    }
                    if (this.fiContractNoteHistoryId !== other.fiContractNoteHistoryId) {
                        return false;
                    }
                    return true;
                }
            }
            Parsed.validateFiContractNoteHistoryId = (fiContractNoteHistoryId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fiContractNoteHistoryId)) {
                    throw new InvalidRequestError(`Attribute fiContractNoteHistoryId is not an Int64`);
                }
            };
            ContractNote.Parsed = Parsed;
            Parsed.prototype.toString = function toString() {
                return `ContractNote.PARSED(fiContractNoteHistoryId=${this.fiContractNoteHistoryId})`;
            };
            class Edited {
                constructor(fiContractNoteId = null, contractNoteEdits) {
                    this.isEdited = true;
                    if (fiContractNoteId !== undefined && fiContractNoteId !== null) {
                        Edited.validateFiContractNoteId(fiContractNoteId);
                    }
                    this.fiContractNoteId = fiContractNoteId;
                    Edited.validateContractNoteEdits(contractNoteEdits);
                    this.contractNoteEdits = contractNoteEdits;
                }
                static fromDTO(dto) {
                    const fiContractNoteId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "fiContractNoteId", InvalidRequestError);
                    const contractNoteEdits = fiContractNoteEdits_1.FiContractNoteEdits.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "contractNoteEdits", InvalidRequestError));
                    return new Edited(fiContractNoteId, contractNoteEdits);
                }
                toDTO() {
                    const ret = {
                        case: "EDITED",
                        contractNoteEdits: this.contractNoteEdits.toDTO(),
                    };
                    if (this.fiContractNoteId !== undefined && this.fiContractNoteId !== null) {
                        ret.fiContractNoteId = this.fiContractNoteId;
                    }
                    return ret;
                }
                copy(fiContractNoteId = this.fiContractNoteId, contractNoteEdits = this.contractNoteEdits) {
                    return new Edited(fiContractNoteId, contractNoteEdits);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isEditedValue = other.isEdited;
                    if (!(other instanceof Edited) || Boolean(isEditedValue)) {
                        return false;
                    }
                    if (this.fiContractNoteId !== other.fiContractNoteId) {
                        return false;
                    }
                    if (!this.contractNoteEdits.equals(other.contractNoteEdits)) {
                        return false;
                    }
                    return true;
                }
            }
            Edited.validateFiContractNoteId = (fiContractNoteId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fiContractNoteId)) {
                    throw new InvalidRequestError(`Attribute fiContractNoteId is not an Int64`);
                }
            };
            Edited.validateContractNoteEdits = (contractNoteEdits) => {
                const isFiContractNoteEditsValue = contractNoteEdits.isFiContractNoteEdits;
                if (!(contractNoteEdits instanceof fiContractNoteEdits_1.FiContractNoteEdits || Boolean(isFiContractNoteEditsValue))) {
                    throw new InvalidRequestError(`Attribute contractNoteEdits is not a FiContractNoteEdits`);
                }
            };
            ContractNote.Edited = Edited;
            Edited.prototype.toString = function toString() {
                return `ContractNote.EDITED(fiContractNoteId=${this.fiContractNoteId},contractNoteEdits=${this.contractNoteEdits})`;
            };
            ContractNote.fromDTO = (dto) => {
                switch (dto.case) {
                    case "PARSED":
                        return Parsed.fromDTO(dto);
                    case "EDITED":
                        return Edited.fromDTO(dto);
                    default:
                        throw new InvalidRequestError(`Case ${dto.case} is not valid case of ContractNote.`);
                }
            };
            ContractNote.isInstanceOf = (other) => {
                const isPARSEDValue = other.isPARSED;
                if (other instanceof Parsed || Boolean(isPARSEDValue)) {
                    return true;
                }
                const isEDITEDValue = other.isEDITED;
                if (other instanceof Edited || Boolean(isEDITEDValue)) {
                    return true;
                }
                return false;
            };
        })(ContractNote = RequestEnums.ContractNote || (RequestEnums.ContractNote = {}));
    })(RequestEnums = FiSubmitContractNoteRequestRPC.RequestEnums || (FiSubmitContractNoteRequestRPC.RequestEnums = {}));
    class Request {
        constructor(contractNote, note = null) {
            this.isFiSubmitContractNoteRequestRPCRequest = true;
            Request.validateContractNote(contractNote);
            this.contractNote = contractNote;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const contractNote = RequestEnums.ContractNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "contractNote", InvalidRequestError));
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(contractNote, note);
        }
        toDTO() {
            const ret = {
                contractNote: this.contractNote.toDTO(),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(contractNote = this.contractNote, note = this.note) {
            return new Request(contractNote, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isFiSubmitContractNoteRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.contractNote.equals(other.contractNote)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateContractNote = (contractNote) => {
        if (!RequestEnums.ContractNote.isInstanceOf(contractNote)) {
            throw new InvalidRequestError(`Attribute contractNote is not a RequestEnums.ContractNote`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    FiSubmitContractNoteRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `FiSubmitContractNoteRequestRPC.Request(contractNote=${this.contractNote},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    FiSubmitContractNoteRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    FiSubmitContractNoteRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidContractNoteId = true;
                InvalidContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
                if (!(other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidContractNoteId = InvalidContractNoteId;
        InvalidContractNoteId.prototype.toString = function toString() {
            return `FiSubmitContractNoteRequestRPC.INVALID_CONTRACT_NOTE_ID()`;
        };
        class DataMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "DATA_MISMATCH", brokerId, details, charges) {
                super(code);
                this.isDataMismatch = true;
                DataMismatch.validateCode(code);
                DataMismatch.validateBrokerId(brokerId);
                this.brokerId = brokerId;
                DataMismatch.validateDetails(details);
                this.details = details;
                DataMismatch.validateCharges(charges);
                this.charges = charges;
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                const brokerId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "brokerId", InvalidResponseError);
                const details = (0, leo_ts_runtime_1.getList)(dto, "details", fiContractNoteRow_1.FiContractNoteRow.fromDTO, InvalidResponseError);
                const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", fiContractNoteCharge_1.FiContractNoteCharge.fromDTO, InvalidResponseError);
                return new DataMismatch(code, brokerId, details, charges);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                    brokerId: this.brokerId.toDTO(),
                    details: this.details.map((e) => {
                        return e.toDTO();
                    }),
                    charges: this.charges.map((e) => {
                        return e.toDTO();
                    }),
                };
                return ret;
            }
            copy(code = this.code, brokerId = this.brokerId, details = this.details, charges = this.charges) {
                return new DataMismatch(code, brokerId, details, charges);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isDataMismatchValue = other.isDataMismatch;
                if (!(other instanceof DataMismatch || Boolean(isDataMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                if (!this.brokerId.equals(other.brokerId)) {
                    return false;
                }
                if (!(0, leo_ts_runtime_1.listEquals)(this.details, other.details)) {
                    return false;
                }
                if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
                    return false;
                }
                return true;
            }
        }
        DataMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "DATA_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        DataMismatch.validateBrokerId = (brokerId) => {
            if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
                throw new InvalidResponseError(`Attribute brokerId is not a UUID`);
            }
        };
        DataMismatch.validateDetails = (details) => {
            if (!Array.isArray(details)) {
                throw new InvalidResponseError(`Attribute details is not a List`);
            }
            for (let i = 0; i < details.length; i += 1) {
                const isFiContractNoteRowValue = details[i].isFiContractNoteRow;
                if (!(details[i] instanceof fiContractNoteRow_1.FiContractNoteRow || Boolean(isFiContractNoteRowValue))) {
                    throw new InvalidResponseError(`Attribute details at index ${i} is not a FiContractNoteRow.`);
                }
            }
        };
        DataMismatch.validateCharges = (charges) => {
            if (!Array.isArray(charges)) {
                throw new InvalidResponseError(`Attribute charges is not a List`);
            }
            for (let i = 0; i < charges.length; i += 1) {
                const isFiContractNoteChargeValue = charges[i].isFiContractNoteCharge;
                if (!(charges[i] instanceof fiContractNoteCharge_1.FiContractNoteCharge || Boolean(isFiContractNoteChargeValue))) {
                    throw new InvalidResponseError(`Attribute charges at index ${i} is not a FiContractNoteCharge.`);
                }
            }
        };
        Errors.DataMismatch = DataMismatch;
        DataMismatch.prototype.toString = function toString() {
            return `FiSubmitContractNoteRequestRPC.DATA_MISMATCH(brokerId=${this.brokerId},details=${this.details},charges=${this.charges})`;
        };
        class IllegalContractNoteState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONTRACT_NOTE_STATE") {
                super(code);
                this.isIllegalContractNoteState = true;
                IllegalContractNoteState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalContractNoteState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalContractNoteState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
                if (!(other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalContractNoteState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONTRACT_NOTE_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalContractNoteState = IllegalContractNoteState;
        IllegalContractNoteState.prototype.toString = function toString() {
            return `FiSubmitContractNoteRequestRPC.ILLEGAL_CONTRACT_NOTE_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CONTRACT_NOTE_ID":
                    return InvalidContractNoteId.fromDTO(dto);
                case "DATA_MISMATCH":
                    return DataMismatch.fromDTO(dto);
                case "ILLEGAL_CONTRACT_NOTE_STATE":
                    return IllegalContractNoteState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
            if (other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue)) {
                return true;
            }
            const isDataMismatchValue = other.isDataMismatch;
            if (other instanceof DataMismatch || Boolean(isDataMismatchValue)) {
                return true;
            }
            const isIllegalContractNoteStateValue = other.isIllegalContractNoteState;
            if (other instanceof IllegalContractNoteState || Boolean(isIllegalContractNoteStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = FiSubmitContractNoteRequestRPC.Errors || (FiSubmitContractNoteRequestRPC.Errors = {}));
})(FiSubmitContractNoteRequestRPC = exports.FiSubmitContractNoteRequestRPC || (exports.FiSubmitContractNoteRequestRPC = {}));
