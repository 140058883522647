import { Instance, flow, types, getEnv } from "mobx-state-tree";
import { CheckFiICDealRequestRPC, CheckResponse } from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { LeoErrors } from "@khazana/khazana-boilerplate";

import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import {
  CheckFiDealRequestDetailsError,
  ViewFiDealInvalidRequestError,
} from "./ViewFiDealRequestDetailsError";
import { useCheckFiICDealRequestRPCImpl } from "../rpcs/RPC";
import { createServerNoteRPCType } from "../../../../../utils";

export const CheckFiDealRequestStore = types
  .model("CheckFiDealRequestStore", {
    error: types.maybeNull(
      types.union(
        types.enumeration<CheckFiDealRequestDetailsError>(
          "CheckFiDealRequestDetailsError",
          Object.values(CheckFiDealRequestDetailsError),
        ),
        types.enumeration<ViewFiDealInvalidRequestError>(
          "ViewFiDealInvalidRequestError",
          Object.values(ViewFiDealInvalidRequestError),
        ),
      ),
    ),
    note: types.maybeNull(types.string),
  })
  .actions((store) => ({
    resetStore(): void {
      store.error = null;
      store.note = null;
    },
    setNote(note: string): void {
      store.note = note;
    },
    checkICFiDealRequest: flow(function* (
      requestId: number,
      checkStatus: CheckResponseEnums.CheckStatus.CheckStatus,
    ) {
      const logger = getEnv(store).logger;
      store.error = null;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const checkResponse = new CheckResponse(
          checkStatus,
          createServerNoteRPCType(store.note),
        );
        const request = new CheckFiICDealRequestRPC.Request(
          requestId,
          checkResponse,
        );
        const result: LeoRPCResult<
          CheckFiICDealRequestRPC.Response,
          CheckFiICDealRequestRPC.Errors.Errors
        > = yield useCheckFiICDealRequestRPCImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          store.note = null;
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiDealInvalidRequestError.InvalidRequestId:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            case CheckFiDealRequestDetailsError.CannotCheckSelfRequest:
              store.error =
                CheckFiDealRequestDetailsError.CannotCheckSelfRequest;
              break;
            case CheckFiDealRequestDetailsError.RequestAlreadyChecked:
              store.error =
                CheckFiDealRequestDetailsError.RequestAlreadyChecked;
              break;
            case CheckFiDealRequestDetailsError.RequestAlreadyCancelled:
              store.error =
                CheckFiDealRequestDetailsError.RequestAlreadyCancelled;
              break;
            case CheckFiDealRequestDetailsError.RequestAlreadyExpired:
              store.error =
                CheckFiDealRequestDetailsError.RequestAlreadyExpired;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in CheckFiICDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from CheckFiICDealRequestRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in CheckFiICDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in CheckFiICDealRequestRPC`,
          );
        }
      }
    }),
  }));

export const createCheckFiDealRequestStore = (): Instance<
  typeof CheckFiDealRequestStore
> => {
  return CheckFiDealRequestStore.create();
};
