"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFTransactionDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const mFSchemeType_1 = require("./mFSchemeType");
const mFPlanType_1 = require("./mFPlanType");
const mFDividendType_1 = require("./mFDividendType");
class InvalidMFTransactionDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFTransactionDetailsError";
    }
}
class MFTransactionDetails {
    constructor(amc, schemeType, planType, dividendType, entryLoad = null, exitLoad = null, grossAmount = null, totalAmount = null) {
        this.isMFTransactionDetails = true;
        MFTransactionDetails.validateAmc(amc);
        this.amc = amc;
        MFTransactionDetails.validateSchemeType(schemeType);
        this.schemeType = schemeType;
        MFTransactionDetails.validatePlanType(planType);
        this.planType = planType;
        MFTransactionDetails.validateDividendType(dividendType);
        this.dividendType = dividendType;
        if (entryLoad !== undefined && entryLoad !== null) {
            MFTransactionDetails.validateEntryLoad(entryLoad);
        }
        this.entryLoad = entryLoad;
        if (exitLoad !== undefined && exitLoad !== null) {
            MFTransactionDetails.validateExitLoad(exitLoad);
        }
        this.exitLoad = exitLoad;
        if (grossAmount !== undefined && grossAmount !== null) {
            MFTransactionDetails.validateGrossAmount(grossAmount);
        }
        this.grossAmount = grossAmount;
        if (totalAmount !== undefined && totalAmount !== null) {
            MFTransactionDetails.validateTotalAmount(totalAmount);
        }
        this.totalAmount = totalAmount;
    }
    static fromDTO(dto) {
        const amc = (0, leo_ts_runtime_1.getString)(dto, "amc", InvalidMFTransactionDetailsError);
        const schemeType = mFSchemeType_1.MFSchemeType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "schemeType", InvalidMFTransactionDetailsError));
        const planType = mFPlanType_1.MFPlanType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "planType", InvalidMFTransactionDetailsError));
        const dividendType = mFDividendType_1.MFDividendType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dividendType", InvalidMFTransactionDetailsError));
        let entryLoad = (0, leo_ts_runtime_1.getOptionalObject)(dto, "entryLoad");
        if (entryLoad !== undefined && entryLoad !== null) {
            entryLoad = bigDecimal_1.BigDecimal.fromDTO(entryLoad);
        }
        let exitLoad = (0, leo_ts_runtime_1.getOptionalObject)(dto, "exitLoad");
        if (exitLoad !== undefined && exitLoad !== null) {
            exitLoad = bigDecimal_1.BigDecimal.fromDTO(exitLoad);
        }
        let grossAmount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "grossAmount");
        if (grossAmount !== undefined && grossAmount !== null) {
            grossAmount = bigDecimal_1.BigDecimal.fromDTO(grossAmount);
        }
        let totalAmount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "totalAmount");
        if (totalAmount !== undefined && totalAmount !== null) {
            totalAmount = bigDecimal_1.BigDecimal.fromDTO(totalAmount);
        }
        return new MFTransactionDetails(amc, schemeType, planType, dividendType, entryLoad, exitLoad, grossAmount, totalAmount);
    }
    toDTO() {
        const ret = {
            amc: this.amc,
            schemeType: mFSchemeType_1.MFSchemeType.toDTO(this.schemeType),
            planType: mFPlanType_1.MFPlanType.toDTO(this.planType),
            dividendType: mFDividendType_1.MFDividendType.toDTO(this.dividendType),
        };
        if (this.entryLoad) {
            ret.entryLoad = this.entryLoad.toDTO();
        }
        if (this.exitLoad) {
            ret.exitLoad = this.exitLoad.toDTO();
        }
        if (this.grossAmount) {
            ret.grossAmount = this.grossAmount.toDTO();
        }
        if (this.totalAmount) {
            ret.totalAmount = this.totalAmount.toDTO();
        }
        return ret;
    }
    copy(amc = this.amc, schemeType = this.schemeType, planType = this.planType, dividendType = this.dividendType, entryLoad = this.entryLoad, exitLoad = this.exitLoad, grossAmount = this.grossAmount, totalAmount = this.totalAmount) {
        return new MFTransactionDetails(amc, schemeType, planType, dividendType, entryLoad, exitLoad, grossAmount, totalAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFTransactionDetailsValue = other.isMFTransactionDetails;
        if (!(other instanceof MFTransactionDetails) || Boolean(isMFTransactionDetailsValue)) {
            return false;
        }
        if (this.amc !== other.amc) {
            return false;
        }
        if (this.schemeType !== other.schemeType) {
            return false;
        }
        if (this.planType !== other.planType) {
            return false;
        }
        if (this.dividendType !== other.dividendType) {
            return false;
        }
        if (this.entryLoad) {
            if (!this.entryLoad.equals(other.entryLoad)) {
                return false;
            }
        }
        if (this.exitLoad) {
            if (!this.exitLoad.equals(other.exitLoad)) {
                return false;
            }
        }
        if (this.grossAmount) {
            if (!this.grossAmount.equals(other.grossAmount)) {
                return false;
            }
        }
        if (this.totalAmount) {
            if (!this.totalAmount.equals(other.totalAmount)) {
                return false;
            }
        }
        return true;
    }
}
exports.MFTransactionDetails = MFTransactionDetails;
MFTransactionDetails.validateAmc = (amc) => {
    if (!(0, leo_ts_runtime_1.isString)(amc)) {
        throw new InvalidMFTransactionDetailsError(`Attribute amc is not a String`);
    }
    if (amc.trim().length === 0) {
        throw new InvalidMFTransactionDetailsError(`Attribute amc cannot be an empty string.`);
    }
};
MFTransactionDetails.validateSchemeType = (schemeType) => {
    if (!mFSchemeType_1.MFSchemeType.isInstanceOf(schemeType)) {
        throw new InvalidMFTransactionDetailsError(`Attribute schemeType is not a MFSchemeType.MFSchemeType`);
    }
};
MFTransactionDetails.validatePlanType = (planType) => {
    if (!mFPlanType_1.MFPlanType.isInstanceOf(planType)) {
        throw new InvalidMFTransactionDetailsError(`Attribute planType is not a MFPlanType.MFPlanType`);
    }
};
MFTransactionDetails.validateDividendType = (dividendType) => {
    if (!mFDividendType_1.MFDividendType.isInstanceOf(dividendType)) {
        throw new InvalidMFTransactionDetailsError(`Attribute dividendType is not a MFDividendType.MFDividendType`);
    }
};
MFTransactionDetails.validateEntryLoad = (entryLoad) => {
    const isBigDecimalValue = entryLoad.isBigDecimal;
    if (!(entryLoad instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFTransactionDetailsError(`Attribute entryLoad is not a BigDecimal`);
    }
};
MFTransactionDetails.validateExitLoad = (exitLoad) => {
    const isBigDecimalValue = exitLoad.isBigDecimal;
    if (!(exitLoad instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFTransactionDetailsError(`Attribute exitLoad is not a BigDecimal`);
    }
};
MFTransactionDetails.validateGrossAmount = (grossAmount) => {
    const isBigDecimalValue = grossAmount.isBigDecimal;
    if (!(grossAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFTransactionDetailsError(`Attribute grossAmount is not a BigDecimal`);
    }
};
MFTransactionDetails.validateTotalAmount = (totalAmount) => {
    const isBigDecimalValue = totalAmount.isBigDecimal;
    if (!(totalAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFTransactionDetailsError(`Attribute totalAmount is not a BigDecimal`);
    }
};
MFTransactionDetails.prototype.toString = function toString() {
    return `MFTransactionDetails(amc='${this.amc}',schemeType=${this.schemeType},planType=${this.planType},dividendType=${this.dividendType},entryLoad=${this.entryLoad},exitLoad=${this.exitLoad},grossAmount=${this.grossAmount},totalAmount=${this.totalAmount})`;
};
