import { SubmitMFConfirmationUnknownRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockSubmitMFConfirmationUnknownRequestRPCImpl extends SubmitMFConfirmationUnknownRequestRPC {
  execute(
    _request: SubmitMFConfirmationUnknownRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      SubmitMFConfirmationUnknownRequestRPC.Response,
      SubmitMFConfirmationUnknownRequestRPC.Errors.Errors
    >
  > {
    const response = new SubmitMFConfirmationUnknownRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        SubmitMFConfirmationUnknownRequestRPC.Response,
        SubmitMFConfirmationUnknownRequestRPC.Errors.Errors
      >
    >;
  }
}
