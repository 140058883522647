"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetPresignedDownloadUrlRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const sHA256_1 = require("../types/sHA256");
/* eslint-disable import/export */
class GetPresignedDownloadUrlRPC {
}
exports.GetPresignedDownloadUrlRPC = GetPresignedDownloadUrlRPC;
(function (GetPresignedDownloadUrlRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetPresignedDownloadUrlRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sha256) {
            this.isGetPresignedDownloadUrlRPCRequest = true;
            Request.validateSha256(sha256);
            this.sha256 = sha256;
        }
        static fromDTO(dto) {
            const sha256 = sHA256_1.SHA256.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "sha256", InvalidRequestError));
            return new Request(sha256);
        }
        toDTO() {
            const ret = {
                sha256: this.sha256.toDTO(),
            };
            return ret;
        }
        copy(sha256 = this.sha256) {
            return new Request(sha256);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetPresignedDownloadUrlRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.sha256.equals(other.sha256)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSha256 = (sha256) => {
        const isSHA256Value = sha256.isSHA256;
        if (!(sha256 instanceof sHA256_1.SHA256 || Boolean(isSHA256Value))) {
            throw new InvalidRequestError(`Attribute sha256 is not a SHA256`);
        }
    };
    GetPresignedDownloadUrlRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetPresignedDownloadUrlRPC.Request(sha256=${this.sha256})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetPresignedDownloadUrlRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(downloadURL) {
            this.isGetPresignedDownloadUrlRPCResponse = true;
            Response.validateDownloadURL(downloadURL);
            this.downloadURL = downloadURL;
        }
        static fromDTO(dto) {
            const downloadURL = (0, leo_ts_runtime_1.getUrl)(dto, "downloadURL", InvalidResponseError);
            return new Response(downloadURL);
        }
        toDTO() {
            const ret = {
                downloadURL: this.downloadURL.toString(),
            };
            return ret;
        }
        copy(downloadURL = this.downloadURL) {
            return new Response(downloadURL);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetPresignedDownloadUrlRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.downloadURL.toString() !== other.downloadURL.toString()) {
                return false;
            }
            return true;
        }
    }
    Response.validateDownloadURL = (downloadURL) => {
        if (!(downloadURL instanceof URL)) {
            throw new InvalidResponseError(`Attribute downloadURL is not a URL`);
        }
    };
    GetPresignedDownloadUrlRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetPresignedDownloadUrlRPC.Response(downloadURL=${this.downloadURL})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetPresignedDownloadUrlRPC.Errors || (GetPresignedDownloadUrlRPC.Errors = {}));
})(GetPresignedDownloadUrlRPC = exports.GetPresignedDownloadUrlRPC || (exports.GetPresignedDownloadUrlRPC = {}));
