import {
  applySnapshot,
  cast,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { DiffCellType } from "../../utils/DiffDetailCellUtils";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";

export const AutocompleteFieldOptionModel = types.model(
  "AutocompleteFieldOptionModel",
  {
    id: types.string,
    label: types.string,
    subLabel: types.maybe(types.string),
  },
);

export const AutocompleteFieldStore = types
  .model("AutocompleteFieldStore", {
    value: types.maybe(AutocompleteFieldOptionModel),
    isDisabled: types.optional(types.boolean, false),
    errorMessage: types.maybe(types.string),
    options: types.array(AutocompleteFieldOptionModel),
    noOptionsMessage: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setValue: (value: AutoCompleteItem | null): void => {
        store.errorMessage = undefined;
        if (value && value.id) {
          store.value = AutocompleteFieldOptionModel.create({
            id: value.id,
            label: value.label,
            subLabel: value.subLabel,
          });
        } else {
          store.value = undefined;
        }
      },
      setIsDisabled: (isDisabled: boolean): void => {
        store.isDisabled = isDisabled;
      },
      setNoOptionsMessage: (noOptionsMessage: string | undefined): void => {
        store.noOptionsMessage = noOptionsMessage;
      },
      setErrorMessage: (errorMessage: string | undefined): void => {
        store.errorMessage = errorMessage;
      },
      setOptions: (options: { id: string; label: string }[]): void => {
        store.options = cast(
          options.map((option) =>
            AutocompleteFieldOptionModel.create({
              id: option.id,
              label: option.label,
            }),
          ),
        );
      },
      setIsLoading: (isLoading: boolean): void => {
        store.isLoading = isLoading;
      },
    };
  });
export const createAutocompleteField = (): Instance<
  typeof AutocompleteFieldStore
> => {
  return AutocompleteFieldStore.create({});
};

export const createAutocompleteFieldFromDiff = (
  label: DiffCellType | undefined,
  id: DiffCellType | undefined,
): Instance<typeof AutocompleteFieldStore> => {
  if (id && label) {
    return AutocompleteFieldStore.create({
      value: AutocompleteFieldOptionModel.create({
        id: (id.diffValue as string | undefined) ?? (id.value as string),
        label:
          (label.diffValue as string | undefined) ?? (label.value as string),
        subLabel: (id.diffValue as string | undefined) ?? (id.value as string),
      }),
    });
  }
  return createAutocompleteField();
};
