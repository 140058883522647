import { Instance, types } from "mobx-state-tree";
import { MfAMCOrderDetail } from "@khazana/khazana-rpcs";
import {
  createMFOrderDetailModel,
  MFOrderDetailModel,
} from "./MFOrderDetailModel";
import { MFOrderStatusModel } from "./MFOrderStatusModel";

export const MfAMCOrderDetailModel = types
  .model("MfAMCOrderDetailModel", {
    confirmationId: types.maybe(types.number),
    orderStatus: types.maybe(MFOrderStatusModel),
    referenceNumber: types.maybe(types.string),
    requestDetail: types.maybe(MFOrderDetailModel),
    executedDetail: types.maybe(MFOrderDetailModel),
    isAmountUnitsError: types.boolean, // This is added to show error for individual amountQuantity component in the dialog.
  })
  .actions((store) => ({
    getAMCOrderDetailRPCType: (): MfAMCOrderDetail => {
      if (store.requestDetail) {
        return new MfAMCOrderDetail(
          store.confirmationId,
          store.requestDetail?.getMFOrderDetailRPCType(),
          store.executedDetail?.getMFOrderDetailRPCType(),
          store.orderStatus,
          store.referenceNumber,
        );
      } else {
        throw new Error("Invalid AMC Order Detail");
      }
    },
  }));

export const createMfAMCOrderDetailModel = (
  order?: MfAMCOrderDetail,
): Instance<typeof MfAMCOrderDetailModel> => {
  if (!order) {
    return MfAMCOrderDetailModel.create({
      isAmountUnitsError: false,
    });
  }
  return MfAMCOrderDetailModel.create({
    confirmationId: order.confirmationId ?? undefined,
    orderStatus: order.orderStatus ?? undefined,
    referenceNumber: order.referenceNumber ?? undefined,
    requestDetail: createMFOrderDetailModel(order.requestDetail),
    executedDetail: createMFOrderDetailModel(order.executedDetail),
    isAmountUnitsError: false,
  });
};
