import { Portfolio } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const PortfolioModel = types.model("PortfolioModel", {
  id: types.string,
  name: types.string,
});

export const createPortfolioModel = ({
  id,
  name,
}: Portfolio): Instance<typeof PortfolioModel> => {
  return PortfolioModel.create({
    id: id.uuid,
    name,
  });
};
