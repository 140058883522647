import { observer } from "mobx-react";
import React, { useState } from "react";
import {
  AutoCompleteItem,
  Banner,
  Button,
  Dialog,
  Icon,
  IconButton,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { useSearchParams } from "react-router-dom";
import { useMFDealRequestDetailsStore } from "../store/DealRequestDetailsStore/hooks";
import { getDetailCellValue } from "../../../utils/DetailCellUtils";
import { MFDealRequestDetailsKeys } from "../models/MFDealRequestDetailKeys";
import { useTranslation } from "react-i18next";
import { OrderType } from "../models/MFDealRequestOrderModel";
import { Instance } from "mobx-state-tree";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import { AmountTextField } from "../../../components/AmountTextField";
import { FileCheck } from "../../../../../assets/FileCheck";
import { Trash } from "../../../../../assets/Trash";
import { CurrencyModel } from "../../../models/CurrencyModel";
import {
  MFOrderDetailModel,
  MFOrderDetailType,
} from "../models/MFOrderDetailModel";
import { MFOrderStatus } from "@khazana/khazana-rpcs";
import { MFDealDetailsPageErrors } from "../store/DealRequestDetailsStore/DealDetailsErrorStore";

export interface MFOrderDialogProps {
  isOpen: boolean;
  onClose: () => void;
  orderType: OrderType;
}

const Size = {
  containerWidth: "100%",
  amountUnitsLabel: {
    container: {
      height: "32px",
    },
    height: "24px",
  },
  addButton: "67px",
  icon: "24px",
  bannerWidth: "100%",
};

export const MFOrderDialog = observer(
  ({ isOpen, onClose, orderType }: MFOrderDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const [note, setNote] = useState<string>();
    const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
    const [searchParam] = useSearchParams();
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const requestId = searchParam.get("requestId");
    const detailsStore = useMFDealRequestDetailsStore();
    const store = detailsStore.dealOrderStore;
    const requestedAmount = getDetailCellValue(
      detailsStore.details,
      MFDealRequestDetailsKeys.grossAmount,
    ).value as string | undefined;
    const orderDetailsType =
      requestedAmount !== "-"
        ? MFOrderDetailType.AMOUNT
        : MFOrderDetailType.UNITS;
    const currency = getDetailCellValue(
      detailsStore.details,
      MFDealRequestDetailsKeys.approxNav,
    ).currency as Instance<typeof CurrencyModel> | undefined;

    const getErrorBanner = (): React.ReactElement => {
      let errorMessage: string | undefined;
      if (
        detailsStore.errorStore.error ===
        MFDealDetailsPageErrors.OrderAmountExceeded
      ) {
        errorMessage = t("mf.errorMessages.orderAmountExceeded");
      } else if (
        detailsStore.errorStore.error ===
        MFDealDetailsPageErrors.OrderUnitsExceeded
      ) {
        errorMessage = t("mf.errorMessages.orderUnitsExceeded");
      }
      if (errorMessage) {
        return (
          <Banner
            severity={"error"}
            message={errorMessage}
            width={Size.bannerWidth}
          />
        );
      }
      return <></>;
    };

    const getRequestedAmountUnitsLabel = (): React.ReactElement => {
      const requestedUnits = getDetailCellValue(
        detailsStore.details,
        MFDealRequestDetailsKeys.units,
      ).value as string | undefined;
      let label = "-";
      let value = "-";
      if (requestedAmount !== "-") {
        label = t("common.requestedAmount");
        if (currency) {
          label = `${label} (${currency.symbol})`;
        }
        value = t("common.decimal2", { val: requestedAmount });
      } else if (requestedUnits) {
        label = t("common.requestedUnits");
        value = t("common.decimal4", { val: requestedUnits });
      }
      if (!!requestedAmount || !!requestedUnits) {
        return (
          <Stack
            direction="column"
            justifyContent="space-between"
            height={Size.amountUnitsLabel.container.height}
            width={Size.containerWidth}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              height={Size.amountUnitsLabel.height}
              width={Size.containerWidth}
            >
              <Typography style={typography.s1}>{label}</Typography>
              <Typography style={typography.s1} textAlign="right">
                {value}
              </Typography>
            </Stack>
            <Divider sx={{ mt: spacing.spaceXS, color: tokens.border }} />
          </Stack>
        );
      }
      return <></>;
    };

    const getBrokerAutoCompleteField = (
      brokerValue: AutoCompleteItem | undefined,
      index: number,
      isContractNoteLinked: boolean,
    ): React.ReactElement => {
      return (
        <AsyncAutoCompleteInputFieldSeparateLabel
          id="broker"
          key={brokerValue?.label}
          label={t("common.broker")}
          value={brokerValue}
          placeholder={t("common.selectBroker")}
          options={store.brokerList.map(({ id, name }) => ({
            label: name,
            id,
          }))}
          onSelect={(value): void => {
            if (value && typeof value !== "string" && value.id) {
              store.updateAutoCompleteError(false, index);
              store.editBrokerDetail(index, value.id);
            }
          }}
          onInputClear={(): void => {
            store.updateAutoCompleteError(false, index);
            store.clearBroker(index);
          }}
          isDisabled={isContractNoteLinked}
          error={
            brokerValue === undefined &&
            store.createEditDealRequestOrder.brokerOrders[index].isError
          }
          style={{
            background: tokens.background,
          }}
        />
      );
    };

    const getAmountUnitsTextFieldPlaceholder = (): string => {
      if (orderDetailsType === MFOrderDetailType.AMOUNT) {
        return t("common.enterAmount");
      } else {
        return t("common.enterQuantity");
      }
    };

    const getAmountUnitsTextFieldLabel = (): string => {
      if (orderDetailsType === MFOrderDetailType.AMOUNT) {
        const currencySymbol = currency?.symbol;
        return `${t("common.amountWithPostfixLabel", {
          val: currencySymbol,
        })}`;
      } else {
        return t("common.quantity");
      }
    };

    const getAmountTextField = (
      brokerValue: AutoCompleteItem | undefined,
      amountUnits: Instance<typeof MFOrderDetailModel> | undefined,
      index: number,
      isLinked: boolean,
      isAmountUnitsError: boolean,
    ): React.ReactElement => {
      return (
        <AmountTextField
          name="amount-quantity"
          key={brokerValue?.id}
          value={amountUnits?.amountUnits}
          isDecimalAllowed={true}
          onAmountChange={(value: string): void => {
            store.updateAmountUnitsError(false, index);
            const _value = Number(value);
            store.editAmountDetail(index, _value, orderDetailsType);
          }}
          isDisabled={isLinked}
          placeholder={getAmountUnitsTextFieldPlaceholder()}
          label={getAmountUnitsTextFieldLabel()}
          error={isAmountUnitsError}
        />
      );
    };

    const getBrokerAmountTrailingButton = (
      index: number,
      isConfirmationLinked: boolean,
    ): React.ReactElement => {
      return (
        <>
          {(store.createEditDealRequestOrder.amcOrders.length > 1 ||
            store.createEditDealRequestOrder.brokerOrders.length > 1 ||
            isConfirmationLinked) && (
            <IconButton
              name="remove"
              variant="plain-neutral"
              size="large"
              style={{
                marginTop: spacing.spaceXL,
                padding: 0,
                background: tokens.backgroundSubtle,
              }}
              icon={
                isConfirmationLinked ? (
                  <FileCheck height={Size.icon} width={Size.icon} />
                ) : (
                  <Trash height={Size.icon} width={Size.icon} />
                )
              }
              disabled={isConfirmationLinked}
              onClick={(): void => {
                if (isConfirmationLinked) {
                  // since the button will be disabled when contract note is linked, no action should be performed
                } else {
                  store.deleteAmountDetail(index);
                }
              }}
            />
          )}
        </>
      );
    };

    const getBrokerAmountComponent = (
      brokerValue: AutoCompleteItem | undefined,
      amountUnits: Instance<typeof MFOrderDetailModel> | undefined,
      index: number,
      isConfirmationLinked: boolean,
      isAmountQuantityError: boolean,
      showBroker = true,
    ): React.ReactElement => {
      return (
        <Stack
          key={index}
          sx={{
            width: Size.containerWidth,
            backgroundColor: tokens.backgroundSubtle,
            padding: spacing.spaceMD,
            flexDirection: "row",
            borderRadius: cornerRadius.radiusXS,
            justifyContent: "space-between",
            gap: spacing.spaceMD,
          }}
        >
          <Stack
            width={Size.containerWidth}
            sx={{
              gap: spacing.spaceMD,
            }}
          >
            {showBroker &&
              getBrokerAutoCompleteField(
                brokerValue,
                index,
                isConfirmationLinked,
              )}
            {getAmountTextField(
              brokerValue,
              amountUnits,
              index,
              isConfirmationLinked,
              isAmountQuantityError,
            )}
          </Stack>
          {getBrokerAmountTrailingButton(index, isConfirmationLinked)}
        </Stack>
      );
    };

    const getBrokerAmountComponents = (): React.ReactElement[] => {
      if (orderType === OrderType.BROKER) {
        return store.createEditDealRequestOrder.brokerOrders.map(
          (brokerOrder, index) => {
            return getBrokerAmountComponent(
              brokerOrder.broker
                ? { id: brokerOrder.broker.id, label: brokerOrder.broker.name }
                : undefined,
              brokerOrder.requestDetail,
              index,
              brokerOrder.orderStatus === MFOrderStatus.MFOrderStatus.LINKED,
              brokerOrder.isAmountUnitsError,
            );
          },
        );
      } else {
        return store.createEditDealRequestOrder.amcOrders.map(
          (amcOrder, index) => {
            return getBrokerAmountComponent(
              undefined,
              amcOrder.requestDetail,
              index,
              amcOrder.orderStatus === MFOrderStatus.MFOrderStatus.LINKED,
              amcOrder.isAmountUnitsError,
              false,
            );
          },
        );
      }
    };

    const getBrokerAmountUnitsComponent = (): React.ReactElement => {
      if (
        (orderType === OrderType.BROKER &&
          store.createEditDealRequestOrder.brokerOrders) ||
        (orderType === OrderType.AMC &&
          store.createEditDealRequestOrder.amcOrders)
      ) {
        return (
          <Stack width={Size.containerWidth} gap={spacing.spaceMD}>
            {getBrokerAmountComponents()}
          </Stack>
        );
      }
      return <></>;
    };
    const getAddButton = (): React.ReactElement => {
      if (
        orderType === OrderType.AMC &&
        store.createEditDealRequestOrder.amcOrders.filter(
          (amcOrder) =>
            amcOrder.orderStatus === MFOrderStatus.MFOrderStatus.LINKED,
        ).length === 0
      ) {
        return <></>;
      } else {
        return (
          <Button
            name="add-broker-amount-quantity"
            size="small"
            variant="outlined-color"
            label={t("common.add")}
            iconPosition="leading"
            icon={<Icon type="plus" color="" />} // since the color will be overridden by the button hence it is not provided here
            onClick={(): void => {
              store.addBrokerAmountDetail();
            }}
            style={{
              width: Size.addButton,
            }}
          />
        );
      }
    };

    return (
      <Dialog
        open={isOpen}
        onClose={(): void => {
          store.resetCreateEditDealRequestOrder();
          onClose();
        }}
        title={store.createEditDealRequestOrder.getOrderDialogTitle(orderType)}
        isCloseIconPresent={isCloseIconPresent}
        primaryButtonText={t("common.submit")}
        onPrimaryButtonClick={async (): Promise<void> => {
          detailsStore.errorStore.reset();
          await store.createEditMFDealRequestOrder(Number(requestId), note);
          setIsCloseIconPresent(false);
          if (!detailsStore.errorStore.error) {
            detailsStore.getMFDealRequestDetails(Number(requestId));
            onClose();
          } else {
            setIsCloseIconPresent(true);
          }
        }}
        disableBackdropClick={true}
      >
        <Box
          display="flex"
          width={Size.containerWidth}
          gap={spacing.spaceLG}
          flexDirection="column"
        >
          {getErrorBanner()}
          {getRequestedAmountUnitsLabel()}
          {getBrokerAmountUnitsComponent()}
          {getAddButton()}
          <NoteTextArea note={note} setNote={setNote} />
        </Box>
      </Dialog>
    );
  },
);
