"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdCashflowsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
class InvalidGetFdCashflowsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFdCashflowsPaginationResponseError";
    }
}
class GetFdCashflowsPaginationResponse {
    constructor(fdCashflowId, purchaseTransactionId, cashflowDate, cashflowType, rate, amountCCY, amountHCY, settlementDate = null, settlementBankAccount = null, bankName = null, isCredited, currency) {
        this.isGetFdCashflowsPaginationResponse = true;
        GetFdCashflowsPaginationResponse.validateFdCashflowId(fdCashflowId);
        this.fdCashflowId = fdCashflowId;
        GetFdCashflowsPaginationResponse.validatePurchaseTransactionId(purchaseTransactionId);
        this.purchaseTransactionId = purchaseTransactionId;
        GetFdCashflowsPaginationResponse.validateCashflowDate(cashflowDate);
        this.cashflowDate = cashflowDate;
        GetFdCashflowsPaginationResponse.validateCashflowType(cashflowType);
        this.cashflowType = cashflowType;
        GetFdCashflowsPaginationResponse.validateRate(rate);
        this.rate = rate;
        GetFdCashflowsPaginationResponse.validateAmountCCY(amountCCY);
        this.amountCCY = amountCCY;
        GetFdCashflowsPaginationResponse.validateAmountHCY(amountHCY);
        this.amountHCY = amountHCY;
        if (settlementDate !== undefined && settlementDate !== null) {
            GetFdCashflowsPaginationResponse.validateSettlementDate(settlementDate);
        }
        this.settlementDate = settlementDate;
        if (settlementBankAccount !== undefined && settlementBankAccount !== null) {
            GetFdCashflowsPaginationResponse.validateSettlementBankAccount(settlementBankAccount);
        }
        this.settlementBankAccount = settlementBankAccount;
        if (bankName !== undefined && bankName !== null) {
            GetFdCashflowsPaginationResponse.validateBankName(bankName);
        }
        this.bankName = bankName;
        GetFdCashflowsPaginationResponse.validateIsCredited(isCredited);
        this.isCredited = isCredited;
        GetFdCashflowsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const fdCashflowId = (0, leo_ts_runtime_1.getInt64)(dto, "fdCashflowId", InvalidGetFdCashflowsPaginationResponseError);
        const purchaseTransactionId = (0, leo_ts_runtime_1.getInt64)(dto, "purchaseTransactionId", InvalidGetFdCashflowsPaginationResponseError);
        const cashflowDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "cashflowDate", InvalidGetFdCashflowsPaginationResponseError);
        const cashflowType = (0, leo_ts_runtime_1.getString)(dto, "cashflowType", InvalidGetFdCashflowsPaginationResponseError);
        const rate = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "rate", InvalidGetFdCashflowsPaginationResponseError));
        const amountCCY = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountCCY", InvalidGetFdCashflowsPaginationResponseError));
        const amountHCY = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amountHCY", InvalidGetFdCashflowsPaginationResponseError));
        const settlementDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "settlementDate", InvalidGetFdCashflowsPaginationResponseError);
        const settlementBankAccount = (0, leo_ts_runtime_1.getOptionalString)(dto, "settlementBankAccount", InvalidGetFdCashflowsPaginationResponseError);
        const bankName = (0, leo_ts_runtime_1.getOptionalString)(dto, "bankName", InvalidGetFdCashflowsPaginationResponseError);
        const isCredited = (0, leo_ts_runtime_1.getBoolean)(dto, "isCredited", InvalidGetFdCashflowsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetFdCashflowsPaginationResponseError));
        return new GetFdCashflowsPaginationResponse(fdCashflowId, purchaseTransactionId, cashflowDate, cashflowType, rate, amountCCY, amountHCY, settlementDate, settlementBankAccount, bankName, isCredited, currency);
    }
    toDTO() {
        const ret = {
            fdCashflowId: this.fdCashflowId,
            purchaseTransactionId: this.purchaseTransactionId,
            cashflowDate: this.cashflowDate.toDTO(),
            cashflowType: this.cashflowType,
            rate: this.rate.toDTO(),
            amountCCY: this.amountCCY.toDTO(),
            amountHCY: this.amountHCY.toDTO(),
            isCredited: this.isCredited,
            currency: this.currency.toDTO(),
        };
        if (this.settlementDate) {
            ret.settlementDate = this.settlementDate.toDTO();
        }
        if (this.settlementBankAccount !== undefined && this.settlementBankAccount !== null) {
            ret.settlementBankAccount = this.settlementBankAccount;
        }
        if (this.bankName !== undefined && this.bankName !== null) {
            ret.bankName = this.bankName;
        }
        return ret;
    }
    copy(fdCashflowId = this.fdCashflowId, purchaseTransactionId = this.purchaseTransactionId, cashflowDate = this.cashflowDate, cashflowType = this.cashflowType, rate = this.rate, amountCCY = this.amountCCY, amountHCY = this.amountHCY, settlementDate = this.settlementDate, settlementBankAccount = this.settlementBankAccount, bankName = this.bankName, isCredited = this.isCredited, currency = this.currency) {
        return new GetFdCashflowsPaginationResponse(fdCashflowId, purchaseTransactionId, cashflowDate, cashflowType, rate, amountCCY, amountHCY, settlementDate, settlementBankAccount, bankName, isCredited, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFdCashflowsPaginationResponseValue = other.isGetFdCashflowsPaginationResponse;
        if (!(other instanceof GetFdCashflowsPaginationResponse) || Boolean(isGetFdCashflowsPaginationResponseValue)) {
            return false;
        }
        if (this.fdCashflowId !== other.fdCashflowId) {
            return false;
        }
        if (this.purchaseTransactionId !== other.purchaseTransactionId) {
            return false;
        }
        if (!this.cashflowDate.equals(other.cashflowDate)) {
            return false;
        }
        if (this.cashflowType !== other.cashflowType) {
            return false;
        }
        if (!this.rate.equals(other.rate)) {
            return false;
        }
        if (!this.amountCCY.equals(other.amountCCY)) {
            return false;
        }
        if (!this.amountHCY.equals(other.amountHCY)) {
            return false;
        }
        if (this.settlementDate) {
            if (!this.settlementDate.equals(other.settlementDate)) {
                return false;
            }
        }
        if (this.settlementBankAccount !== other.settlementBankAccount) {
            return false;
        }
        if (this.bankName !== other.bankName) {
            return false;
        }
        if (this.isCredited !== other.isCredited) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetFdCashflowsPaginationResponse = GetFdCashflowsPaginationResponse;
GetFdCashflowsPaginationResponse.validateFdCashflowId = (fdCashflowId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(fdCashflowId)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute fdCashflowId is not an Int64`);
    }
};
GetFdCashflowsPaginationResponse.validatePurchaseTransactionId = (purchaseTransactionId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(purchaseTransactionId)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute purchaseTransactionId is not an Int64`);
    }
};
GetFdCashflowsPaginationResponse.validateCashflowDate = (cashflowDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(cashflowDate)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute cashflowDate is not a Date`);
    }
};
GetFdCashflowsPaginationResponse.validateCashflowType = (cashflowType) => {
    if (!(0, leo_ts_runtime_1.isString)(cashflowType)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute cashflowType is not a String`);
    }
    if (cashflowType.trim().length === 0) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute cashflowType cannot be an empty string.`);
    }
};
GetFdCashflowsPaginationResponse.validateRate = (rate) => {
    const isBigDecimalValue = rate.isBigDecimal;
    if (!(rate instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute rate is not a BigDecimal`);
    }
};
GetFdCashflowsPaginationResponse.validateAmountCCY = (amountCCY) => {
    const isBigDecimalValue = amountCCY.isBigDecimal;
    if (!(amountCCY instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute amountCCY is not a BigDecimal`);
    }
};
GetFdCashflowsPaginationResponse.validateAmountHCY = (amountHCY) => {
    const isBigDecimalValue = amountHCY.isBigDecimal;
    if (!(amountHCY instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute amountHCY is not a BigDecimal`);
    }
};
GetFdCashflowsPaginationResponse.validateSettlementDate = (settlementDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(settlementDate)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute settlementDate is not a Date`);
    }
};
GetFdCashflowsPaginationResponse.validateSettlementBankAccount = (settlementBankAccount) => {
    if (!(0, leo_ts_runtime_1.isString)(settlementBankAccount)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute settlementBankAccount is not a String`);
    }
    if (settlementBankAccount.trim().length === 0) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute settlementBankAccount cannot be an empty string.`);
    }
};
GetFdCashflowsPaginationResponse.validateBankName = (bankName) => {
    if (!(0, leo_ts_runtime_1.isString)(bankName)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute bankName is not a String`);
    }
    if (bankName.trim().length === 0) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute bankName cannot be an empty string.`);
    }
};
GetFdCashflowsPaginationResponse.validateIsCredited = (isCredited) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(isCredited)) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute isCredited is not a Boolean`);
    }
};
GetFdCashflowsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFdCashflowsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFdCashflowsPaginationResponse.prototype.toString = function toString() {
    return `GetFdCashflowsPaginationResponse(fdCashflowId=${this.fdCashflowId},purchaseTransactionId=${this.purchaseTransactionId},cashflowDate=${this.cashflowDate},cashflowType='${this.cashflowType}',rate=${this.rate},amountCCY=${this.amountCCY},amountHCY=${this.amountHCY},settlementDate=${this.settlementDate},settlementBankAccount='${this.settlementBankAccount}',bankName='${this.bankName}',isCredited=${this.isCredited},currency=${this.currency})`;
};
