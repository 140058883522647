import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { Stack } from "@mui/material";
import { Instance } from "mobx-state-tree";
import {
  getFormattedAmount,
  getFormattedTimeDateWithComma,
} from "../../../../../utils";
import {
  FiContractNoteRequestStatus,
  FiDealRequestStatus,
} from "@khazana/khazana-rpcs";
import { useFiContractNoteDetailsStore } from "../store/hooks";
import { FiContractNoteDealRequestDetails } from "../models/FiContractNoteHistoryDetailModel";
import { getFiContractNoteRequestStatusValue } from "../utils/SearchUtils";
import { getFiContractNoteStatusBackgroundColor } from "../utils/UIUtils";
import { ContractNoteDealDetails } from "../../../components/ContractNoteDealDetails";
import {
  getRequestStatusValue,
  getFIYieldTypeValue,
} from "../../deal-request/utils/SearchUtils";
import { ContractNoteHistoryRow } from "../../../components/ContractNoteHistoryRow";
import { getFiTransactionText } from "../../deal-request/utils/UIUtils";

export interface FiContractNoteHistoryDialogProps {
  onClose: () => void;
  isOpen: boolean;
}

const Size = {
  container: {
    width: "100%",
  },
  dialogWidth: "960px",
};

export const FiContractNoteHistoryDialog = ({
  onClose,
  isOpen,
}: FiContractNoteHistoryDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const store = useFiContractNoteDetailsStore();
  const [searchParam] = useSearchParams();
  const contractNoteId = searchParam.get("contractNoteId")
    ? Number(searchParam.get("contractNoteId"))
    : undefined;

  useEffect(() => {
    const getHistory = async (): Promise<void> => {
      store.viewContractNoteHistoryStore.getFiContractNoteHistory(
        contractNoteId,
      );
    };
    getHistory();
  }, []);

  const getTitleText = (
    status: FiContractNoteRequestStatus.FiContractNoteRequestStatus | null,
    firstName: string | undefined,
    lastName: string | undefined,
  ): string => {
    switch (status) {
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus.LINKED:
        return t("contractNotes.linkedMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNPARSED:
        return t("contractNotes.unparsedMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus.PARSED:
        return t("contractNotes.parsedMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus
        .ACCOUNTS_PENDING:
        return t("contractNotes.accountsPendingMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus
        .UNKNOWN_REQUESTED:
        return t("contractNotes.unknownRequestedMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus
        .ACCOUNTS_APPROVED:
        return t("contractNotes.accountsApprovedMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus
        .ACCOUNTS_REJECTED:
        return t("contractNotes.accountsRejectedMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus
        .DATA_MISMATCH:
        return t("contractNotes.dataMismatchMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus
        .DISCARD_PENDING:
        return t("contractNotes.discardPendingMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus.DISCARDED:
        return t("contractNotes.discardedMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus
        .LINKING_PENDING:
        return t("contractNotes.linkRequestMessage", {
          firstName,
          lastName,
        });
        break;
      case FiContractNoteRequestStatus.FiContractNoteRequestStatus.UNKNOWN:
        return t("contractNotes.unknownMessage", {
          firstName,
          lastName,
        });
        break;
      default:
        return "";
        break;
    }
  };

  const getDealDetails = (
    details: Instance<typeof FiContractNoteDealRequestDetails>,
  ): React.ReactElement => {
    return (
      <ContractNoteDealDetails
        header={t("fi.contractNotes.fiDealRequestDetails")}
        detailsList={[
          {
            label: t("contractNotes.dematAccountNumber"),
            value: details.dematAccountNumber,
          },
          {
            label: t("common.entity"),
            value: details.entity,
          },
          {
            label: getFIYieldTypeValue(details.yieldType),
            value: getFormattedAmount(details.ytm, 4, 2),
          },
          {
            label: t("common.amountWithPostfixLabel", {
              val: details.amount?.currency.symbol,
            }),
            value: details.amount
              ? getFormattedAmount(details.amount.amount)
              : "-",
          },
          {
            label: t("contractNotes.quantity"),
            value: details.quantity ?? "-",
          },
          {
            label: t("contractNotes.transactionType"),
            value: getFiTransactionText(details.transactionType),
          },
          {
            label: t("fi.security"),
            value: details.symbol,
          },
          {
            label: t("common.requestId"),
            value: details.requestId,
          },
          {
            label: t("common.requestStatus"),
            value: getRequestStatusValue(
              t,
              FiDealRequestStatus.fromDTO({ case: details.status }),
            ),
          },
        ]}
      />
    );
  };

  return (
    <Dialog
      title={t("contractNotes.contractNoteHistory")}
      open={isOpen}
      onSecondaryButtonClick={onClose}
      secondaryButtonText={t("common.close")}
      disableBackdropClick={true}
      dialogWidth={Size.dialogWidth}
    >
      <Stack width={Size.container.width}>
        {store.viewContractNoteHistoryStore.fiContractNoteHistoryDetail?.map(
          (item, index) => {
            return (
              <ContractNoteHistoryRow
                key={index}
                index={index}
                firstName={item.requestNote?.userName.firstName}
                lastName={item.requestNote?.userName.lastName ?? ""}
                profilePhoto={item.requestNote?.profilePhoto}
                titleText={getTitleText(
                  item.status,
                  item.requestNote?.userName.firstName,
                  item.requestNote?.userName.lastName ?? undefined,
                )}
                requestedAt={
                  item.requestNote?.requestedAt
                    ? getFormattedTimeDateWithComma(
                        new Date(item.requestNote?.requestedAt),
                      )
                    : null
                }
                chipText={
                  item.status
                    ? getFiContractNoteRequestStatusValue(
                        item.status,
                      ).toLocaleUpperCase()
                    : undefined
                }
                backgroundColor={
                  item.status
                    ? getFiContractNoteStatusBackgroundColor(item.status)
                    : undefined
                }
                note={item.requestNote?.note}
                dealDetails={
                  item.dealRequestDetails &&
                  getDealDetails(item.dealRequestDetails)
                }
                diffData={item.diffData}
                charges={item.charges}
                currencySymbol={store.currency?.symbol}
              />
            );
          },
        )}
      </Stack>
    </Dialog>
  );
};
