import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import {
  getFiCashflowDetailsPath,
  getPath,
} from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useViewFiMaturityRequestDetailsStore } from "../store/hooks";
import { HeaderButton } from "../../../components/page-header/HeaderButton";
import { FiInvestmentDetailsCard } from "../../components/investment-details/FiInvestmentDetailsCard";
import { DealStatusSection } from "../../../components/DealStatusSection";
import { getFiMaturityStatusColor } from "../utils/UIUtils";
import { FiMaturityDealRequestStatus } from "@khazana/khazana-rpcs";
import { getMaturityRequestStatusValue } from "../utils/SearchUtils";
import { FiMaturityRequestDetailsSection } from "../components/FiMaturityRequestDetailsSection";
import { FiMaturityRequestHistorySection } from "../components/FiMaturityRequestHistorySection";
import { Instance } from "mobx-state-tree";
import { FiMaturityRequestHistoryDetailModel } from "../models/FiMaturityRequestHistoryDetailModel";
import { ViewFiMaturityInvalidRequestError } from "../store/ViewFiMaturityRequestDetailsStore";
import { TFunction } from "i18next";
import { InvestmentCashflowBanner } from "../../../components/cash-flow-summary/InvestmentCashflowBanner";

const Size = {
  textInput: "520px",
  dialogWidth: "560px",
  containerWidth: "100%",
  container: "100%",
};

export const getMaturityTitleText = (
  bannerInfo: Instance<typeof FiMaturityRequestHistoryDetailModel> | null,
  status: FiMaturityDealRequestStatus.FiMaturityDealRequestStatus,
  t: TFunction,
): string => {
  const firstName = bannerInfo?.requestNote?.userName.firstName;
  const lastName = bannerInfo?.requestNote?.userName.lastName;
  switch (status) {
    case FiMaturityDealRequestStatus.FiMaturityDealRequestStatus
      .SETTLEMENT_PENDING:
      return t("common.hasRaisedAMaturityRequest", {
        firstName,
        lastName,
      });
    case FiMaturityDealRequestStatus.FiMaturityDealRequestStatus.SETTLED:
      return t("common.hasSettledTheMaturityRequest", {
        firstName,
        lastName,
      });
  }
};

export const ViewFiMaturityRequestDetails = observer((): React.ReactElement => {
  const spacing = useSpacing();
  const { t } = useTranslation();
  const store = useViewFiMaturityRequestDetailsStore();
  const [isScreenBlockingLoading, setIsScreenBlockingLoading] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [searchParam] = useSearchParams();
  const requestId = searchParam.get("requestId")
    ? Number(searchParam.get("requestId"))
    : undefined;
  const navigate = useNavigate();

  useEffect(() => {
    const getDetails = async (): Promise<void> => {
      setIsScreenBlockingLoading(true);
      store.resetError();
      if (requestId) {
        const promises = [
          store.getFiMaturityRequestDetailsBannerInfo(requestId),
          store.getInvestmentDetails(requestId),
          store.getFiMaturityRequestHistory(requestId),
          store.getInvestmentBannerData(requestId),
          store.getFiMaturityRequestDetails(requestId),
        ];
        await Promise.all(promises);
      } else {
        setIsErrorDialogOpen(true);
      }
      if (store.error !== null) {
        setIsErrorDialogOpen(true);
      }
      setIsScreenBlockingLoading(false);
    };

    getDetails();

    return () => {
      // The store needs to get cleared once the page is unmounted to remove the persistent data.
      store.resetStore();
    };
  }, []);

  const getErrorDialogText = (): string => {
    switch (store.error) {
      case ViewFiMaturityInvalidRequestError.InvalidRequestId:
        return t("common.noResultsFound");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const getSettleActionButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.settleMaturityRequest")}
        onClick={async (): Promise<void> => {
          navigate(
            getPath(
              Module.Fi,
              Route.SettleMaturityDealRequestWithParams,
              requestId?.toString(),
            ),
          );
        }}
        buttonType="outlined"
      />
    );
  };

  const getDealStatusSection = (): React.ReactElement => {
    const value = store.fiDealRequestDetailsBannerInfo;
    if (value && value.status && value.requestNote) {
      return (
        <>
          <DealStatusSection
            firstName={value.requestNote?.userName.firstName}
            lastName={value.requestNote?.userName.lastName}
            requestedAt={value.requestNote?.requestedAt}
            note={value.requestNote?.note}
            isHistoryComponent={false}
            userImage={value.requestNote.profilePhoto?.toString()}
            title={getMaturityTitleText(
              store.fiDealRequestDetailsBannerInfo,
              value.status,
              t,
            )}
            chipText={getMaturityRequestStatusValue(
              t,
              value.status,
            ).toLocaleUpperCase()}
            chipColor={getFiMaturityStatusColor(value.status)}
            assetName={null}
            impactBuyChart={undefined}
            impactSellChart={undefined}
            impactOnPortfolio={undefined}
            transactionType={null}
          />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <Stack
      direction="column"
      alignItems={"center"}
      style={{ width: Size.containerWidth }}
    >
      <LoadingIndicator isLoading={isScreenBlockingLoading} />
      <PageHeader
        title={t("fi.fiMaturityRequestDetails")}
        actionElement={
          store.isSettlementAllowed ? getSettleActionButton() : undefined
        }
      />
      <Stack
        padding={spacing.space2XL}
        gap={spacing.space2XL}
        width={Size.containerWidth}
      >
        <Breadcrumb
          links={[
            {
              label: t("common.manageMaturityRequest"),
              onLabelClick: (): void => {
                navigate(getPath(Module.Fi, Route.ManageMaturityRequest));
              },
            },
          ]}
          currentLabel={t("fi.fiMaturityRequestDetails")}
        />
        {getDealStatusSection()}
        <FiInvestmentDetailsCard
          store={store.investmentDetailsStore}
          onButtonClick={() => {
            navigate(
              getFiCashflowDetailsPath(
                store.investmentDetailsStore.investmentId,
                undefined,
                requestId,
              ) ?? "",
            );
          }}
        />
        <InvestmentCashflowBanner
          store={store.investmentDetailsBannerStore}
          isSettledDetailsHidden={true}
          isPendingDetailsHidden={true}
        />
        {store.dealDetails.length > 0 && (
          <>
            <FiMaturityRequestDetailsSection />
            <FiMaturityRequestHistorySection />
          </>
        )}
      </Stack>
      <ErrorDialog
        errorMessage={getErrorDialogText()}
        isErrorDialogOpen={isErrorDialogOpen}
        onClose={(): void => {
          if (
            store.error === ViewFiMaturityInvalidRequestError.InvalidRequestId
          ) {
            navigate(-1);
          }
          setIsErrorDialogOpen(false);
        }}
      />
    </Stack>
  );
});
