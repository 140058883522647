import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { ActionButtons } from "../../../components/Section";
import { Edit } from "../../../../../assets/Edit";
import { useTranslation } from "react-i18next";
import { Section } from "../../../components/Section";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import {
  DetailRowCellEnum,
  DetailRowCellType,
  DetailsRow,
  LoadingIndicator,
} from "@surya-digital/leo-reactjs-material-ui";
import { Box, Divider, Stack } from "@mui/material";
import {
  getFormattedAmountString,
  getFormattedDate,
} from "../../../../../utils";
import { DEPOSIT_CERTIFICATE_PDF_ZOOM_LEVEL } from "../utils/UIUtils";
import { Pdf } from "../../../components/Pdf";
import {
  getCompoundingFrequencyName,
  getInterestTypeName,
  getPayoutFrequencyName,
  getPeriodUnitName,
} from "../../../utils/DetailCellUtils";

export interface DepositCertificateDetailsProps {
  certificateId: number;
  requestId: number;
  onCancel: () => void;
  onEdit: () => void;
}

const style = {
  width: "100%",
  halfWidth: "50%",
  height: "Calc(100vh - 290px)",
};
export const DepositCertificateDetails = observer(
  ({
    certificateId,
    requestId,
    onCancel,
    onEdit,
  }: DepositCertificateDetailsProps): React.ReactElement => {
    const { t } = useTranslation();
    const detailsStore = useFDDealRequestDetailsStore();
    const store = detailsStore.certificateStore;

    useEffect(() => {
      store.getFDCertificateDetails(Number(requestId), certificateId);
      return () => {
        store.certificateDetail?.reset();
      };
    }, []);

    const getActionButtons = (): ActionButtons => {
      let buttons: ActionButtons = {
        primaryButton: {
          label: t("common.cancel"),
          onClick: onCancel,
        },
      };
      if (detailsStore && detailsStore.requestActions.allowEditCertificate) {
        buttons = {
          ...buttons,
          secondaryButton: {
            label: t("common.editDetails"),
            onClick: onEdit,
            icon: <Edit />,
          },
        };
      }
      return buttons;
    };

    const getUnstyledRow = (text: string | undefined): DetailRowCellType => {
      return {
        text: text ?? "-",
        type: DetailRowCellEnum.Unstyled,
      };
    };

    const getDepositDuration = (): string | undefined => {
      if (
        store.certificateDetail?.depositDuration &&
        store.certificateDetail?.depositDurationUnit
      ) {
        return `${store.certificateDetail.depositDuration} ${getPeriodUnitName(
          store.certificateDetail.depositDurationUnit,
        )}`;
      }
    };

    return (
      <>
        {store.isLoading ? (
          <LoadingIndicator isLoading={store.isLoading} />
        ) : (
          <Section
            title={t("fd.depositCertificate.sectionTitle")}
            actions={getActionButtons()}
          >
            <Stack
              sx={{ width: style.width, height: style.height }}
              direction={"row"}
            >
              <Stack
                sx={{
                  overflow: "auto",
                  height: style.height,
                  width: store.certificateDetail?.certificateURL
                    ? style.halfWidth
                    : style.width,
                }}
              >
                <Box>
                  <DetailsRow
                    label={t("fd.fields.certificateNumberLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.depositCertificateNumber,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.fileName")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.certificateFileName,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.depositBankLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.depositBankName,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.depositBranchLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.depositBranchName,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.valueDateLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.valueDate
                        ? getFormattedDate(store.certificateDetail.valueDate)
                        : undefined,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.depositDurationLabel")}
                    cellType={getUnstyledRow(getDepositDuration())}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.depositAccountNumberLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.depositAccountNumber,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.depositAmountLabel", {
                      currencySymbol: store.certificateDetail?.currencySymbol,
                    })}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.depositAmount
                        ? getFormattedAmountString(
                            store.certificateDetail?.depositAmount,
                          )
                        : undefined,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.rateOfInterestLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.rateOfInterest
                        ? getFormattedAmountString(
                            store.certificateDetail.rateOfInterest,
                          )
                        : undefined,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.interestTypeLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.interestType
                        ? getInterestTypeName(
                            store.certificateDetail?.interestType,
                          )
                        : undefined,
                    )}
                  />
                  <Divider />
                </Box>
                {store.certificateDetail?.payoutFrequency ? (
                  <Box>
                    <DetailsRow
                      label={t("fd.fields.payoutFrequencyLabel")}
                      cellType={getUnstyledRow(
                        store.certificateDetail?.payoutFrequency
                          ? getPayoutFrequencyName(
                              store.certificateDetail?.payoutFrequency,
                            )
                          : undefined,
                      )}
                    />
                    <Divider />
                  </Box>
                ) : (
                  <Box>
                    <DetailsRow
                      label={t("fd.fields.compoundingFrequencyLabel")}
                      cellType={getUnstyledRow(
                        store.certificateDetail?.compoundingFrequency
                          ? getCompoundingFrequencyName(
                              store.certificateDetail?.compoundingFrequency,
                            )
                          : undefined,
                      )}
                    />
                    <Divider />
                  </Box>
                )}

                <Box>
                  <DetailsRow
                    label={t("fd.fields.maturityDateLabel")}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.maturityDate
                        ? getFormattedDate(store.certificateDetail.maturityDate)
                        : undefined,
                    )}
                  />
                  <Divider />
                </Box>
                <Box>
                  <DetailsRow
                    label={t("fd.fields.maturityAmountLabel", {
                      currencySymbol: store.certificateDetail?.currencySymbol,
                    })}
                    cellType={getUnstyledRow(
                      store.certificateDetail?.expectedMaturityAmount
                        ? getFormattedAmountString(
                            store.certificateDetail?.expectedMaturityAmount,
                          )
                        : undefined,
                    )}
                  />
                  <Divider />
                </Box>
              </Stack>
              {store.certificateDetail?.certificateURL && (
                <Pdf
                  url={store.certificateDetail?.certificateURL}
                  zoom={DEPOSIT_CERTIFICATE_PDF_ZOOM_LEVEL}
                />
              )}
            </Stack>
          </Section>
        )}
      </>
    );
  },
);
