import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";

export enum CreateMFDealError {
  InsufficientBankBalance = "INSUFFICIENT_BANK_BALANCE",
  MissingFolioNumber = "MISSING_FOLIO_NUMBER",
  ClientError = "CLIENT_ERROR",
}
export const MFCreateDealRequestErrorStore = types
  .model("MFCreateDealRequestErrorStore", {
    error: types.maybe(types.enumeration(Object.values(CreateMFDealError))),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setError: (error: CreateMFDealError | undefined): void => {
        store.error = error;
      },
    };
  });

export const createMFCreateDealRequestErrorStore = (): Instance<
  typeof MFCreateDealRequestErrorStore
> => {
  return MFCreateDealRequestErrorStore.create({});
};
