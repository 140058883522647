import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import {
  PortfolioModel,
  createPortfolioModel,
} from "../../../models/PortfolioModel";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  GetEquityDealRequestSettlementDetailsRPC,
  Portfolio,
  SettleDealRequestRPC,
} from "@khazana/khazana-rpcs";
import {
  SettleEquityDealInvalidRequestError,
  ViewEquityDealInvalidRequestError,
} from "./ViewEquityDealRequestDetailsError";
import { LeoErrors } from "@khazana/khazana-boilerplate";

import {
  SettlementDetailsModel,
  createSettlementDetailsModel,
} from "../models/SettlementDetailsModel";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import {
  useGetEquityDealRequestSettlementDetailsRPCImpl,
  useSettleDealRequestRPCImpl,
} from "../rpcs/RPC";
import { createServerNoteRPCType } from "../../../../../utils";

export const SettleEquityDealRequestStore = types
  .model({
    portfolioList: types.maybeNull(types.array(PortfolioModel)),
    settlementDetails: types.maybeNull(SettlementDetailsModel),
    selectedPortfolio: types.maybeNull(PortfolioModel),
    note: types.maybeNull(types.string),
    error: types.maybeNull(
      types.union(
        types.enumeration<ViewEquityDealInvalidRequestError>(
          "ViewEquityDealInvalidRequestError",
          Object.values(ViewEquityDealInvalidRequestError),
        ),
        types.enumeration<SettleEquityDealInvalidRequestError>(
          "SettleEquityDealInvalidRequestError",
          Object.values(SettleEquityDealInvalidRequestError),
        ),
      ),
    ),
  })
  .actions((store) => ({
    setSelectedPortfolio(portfolio: DropdownItem): void {
      const logger = getEnv(store).logger;
      try {
        store.selectedPortfolio = createPortfolioModel(
          new Portfolio(new LeoUUID(portfolio.value), portfolio.name),
        );
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in setSelectedPortfolio`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in setSelectedPortfolio`,
          );
        }
      }
    },
    setSettleNote(note: string): void {
      store.note = note;
    },
    getEquityDealRequestSettlementDetails: flow(function* (requestId: string) {
      const logger = getEnv(store).logger;
      try {
        const equityDealRequestId = new LeoUUID(requestId);
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetEquityDealRequestSettlementDetailsRPC.Request(
          equityDealRequestId,
        );
        const result: LeoRPCResult<
          GetEquityDealRequestSettlementDetailsRPC.Response,
          GetEquityDealRequestSettlementDetailsRPC.Errors.Errors
        > =
          yield useGetEquityDealRequestSettlementDetailsRPCImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.portfolioList = cast(
            response.portfolio.map((portfolioDetail) => {
              return createPortfolioModel(portfolioDetail);
            }),
          );
          store.settlementDetails =
            createSettlementDetailsModel(
              response.settlementDetails,
              response.priceDetails,
            ) ?? null;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewEquityDealInvalidRequestError.InvalidRequestId:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetEquityDealRequestSettlementDetailsRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetEquityDealRequestSettlementDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetEquityDealRequestSettlementDetailsRPC`,
          );
        }
      }
    }),
    settleDealRequest: flow(function* (requestId: string) {
      const logger = getEnv(store).logger;
      try {
        const equityDealRequestId = new LeoUUID(requestId);
        const apiClient: APIClient = getAPIClient(store);

        const request = new SettleDealRequestRPC.Request(
          equityDealRequestId,
          store.portfolioList &&
          store.portfolioList.length > 0 &&
          store.selectedPortfolio
            ? new Portfolio(
                new LeoUUID(store.selectedPortfolio.id),
                store.selectedPortfolio.name,
              )
            : null,
          createServerNoteRPCType(store.note),
        );

        const result: LeoRPCResult<
          SettleDealRequestRPC.Response,
          SettleDealRequestRPC.Errors.Errors
        > = yield useSettleDealRequestRPCImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewEquityDealInvalidRequestError.InvalidRequestId:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            case SettleEquityDealInvalidRequestError.CanOnlySettleSelfRequest:
              store.error =
                SettleEquityDealInvalidRequestError.CanOnlySettleSelfRequest;
              break;
            case SettleEquityDealInvalidRequestError.DealAlreadyCancelled:
              store.error =
                SettleEquityDealInvalidRequestError.DealAlreadyCancelled;
              break;
            case SettleEquityDealInvalidRequestError.DealAlreadyExpired:
              store.error =
                SettleEquityDealInvalidRequestError.DealAlreadyExpired;
              break;
            case SettleEquityDealInvalidRequestError.DealAlreadySettled:
              store.error =
                SettleEquityDealInvalidRequestError.DealAlreadySettled;
              break;
            case SettleEquityDealInvalidRequestError.DealNotApproved:
              store.error = SettleEquityDealInvalidRequestError.DealNotApproved;
              break;
            case SettleEquityDealInvalidRequestError.InvalidPortfolio:
              store.error =
                SettleEquityDealInvalidRequestError.InvalidPortfolio;
              break;
            case SettleEquityDealInvalidRequestError.NoBrokersLinked:
              store.error = SettleEquityDealInvalidRequestError.NoBrokersLinked;
              break;
            default:
              logger.error(
                `Unhandled Result: ${result} from SettleDealRequestRPC`,
              );
          }
        } else {
          logger.error(`Unhandled Result: ${result} from SettleDealRequestRPC`);
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewEquityDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in SettleDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in SettleDealRequestRPC`,
          );
        }
      }
    }),
    resetStore(): void {
      store.error = null;
      store.note = null;
      store.portfolioList = null;
      store.selectedPortfolio = null;
      store.settlementDetails = null;
    },
  }));

export const createSettleEquityDealRequestStore = (): Instance<
  typeof SettleEquityDealRequestStore
> => {
  return SettleEquityDealRequestStore.create();
};
