"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFFolioNumbersRPCClientImpl = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const tedwig_1 = require("@surya-digital/tedwig");
const getMFFolioNumbersRPC_1 = require("./getMFFolioNumbersRPC");
class GetMFFolioNumbersRPCClientImpl extends getMFFolioNumbersRPC_1.GetMFFolioNumbersRPC {
    constructor(client) {
        super();
        this.client = client;
    }
    execute(request) {
        return __awaiter(this, void 0, void 0, function* () {
            GetMFFolioNumbersRPCClientImpl.validateRequest(request);
            const requestBody = request.toDTO();
            const response = yield this.client.sendRequest(new tedwig_1.Request(tedwig_1.Method.Post, "mutual-fund/GetMFFolioNumbers", undefined, undefined, requestBody));
            return (0, leo_ts_runtime_1.parseResponse)(response, "mutual-fund/GetMFFolioNumbers", getMFFolioNumbersRPC_1.GetMFFolioNumbersRPC.Response.fromDTO, getMFFolioNumbersRPC_1.GetMFFolioNumbersRPC.Errors.fromDTO);
        });
    }
    static validateRequest(request) {
        const isGetMFFolioNumbersRPCRequestValue = request.isGetMFFolioNumbersRPCRequest;
        if (!(request instanceof getMFFolioNumbersRPC_1.GetMFFolioNumbersRPC.Request || Boolean(isGetMFFolioNumbersRPCRequestValue))) {
            throw new getMFFolioNumbersRPC_1.GetMFFolioNumbersRPC.InvalidRequestError("Attribute request is not a GetMFFolioNumbersRPC.Request");
        }
    }
}
exports.GetMFFolioNumbersRPCClientImpl = GetMFFolioNumbersRPCClientImpl;
