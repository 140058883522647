"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrokerStatusType = exports.InvalidBrokerStatusTypeError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const amountQuantity_1 = require("./amountQuantity");
class InvalidBrokerStatusTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidBrokerStatusTypeError";
    }
}
exports.InvalidBrokerStatusTypeError = InvalidBrokerStatusTypeError;
var BrokerStatusType;
(function (BrokerStatusType) {
    class Linked {
        constructor(executedAmountQuantity, contractNoteNumber, rawContractNoteDisplayId) {
            this.isLinked = true;
            Linked.validateExecutedAmountQuantity(executedAmountQuantity);
            this.executedAmountQuantity = executedAmountQuantity;
            Linked.validateContractNoteNumber(contractNoteNumber);
            this.contractNoteNumber = contractNoteNumber;
            Linked.validateRawContractNoteDisplayId(rawContractNoteDisplayId);
            this.rawContractNoteDisplayId = rawContractNoteDisplayId;
        }
        static fromDTO(dto) {
            const executedAmountQuantity = amountQuantity_1.AmountQuantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedAmountQuantity", InvalidBrokerStatusTypeError));
            const contractNoteNumber = (0, leo_ts_runtime_1.getString)(dto, "contractNoteNumber", InvalidBrokerStatusTypeError);
            const rawContractNoteDisplayId = (0, leo_ts_runtime_1.getInt64)(dto, "rawContractNoteDisplayId", InvalidBrokerStatusTypeError);
            return new Linked(executedAmountQuantity, contractNoteNumber, rawContractNoteDisplayId);
        }
        toDTO() {
            const ret = {
                case: "LINKED",
                executedAmountQuantity: this.executedAmountQuantity.toDTO(),
                contractNoteNumber: this.contractNoteNumber,
                rawContractNoteDisplayId: this.rawContractNoteDisplayId,
            };
            return ret;
        }
        copy(executedAmountQuantity = this.executedAmountQuantity, contractNoteNumber = this.contractNoteNumber, rawContractNoteDisplayId = this.rawContractNoteDisplayId) {
            return new Linked(executedAmountQuantity, contractNoteNumber, rawContractNoteDisplayId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isLinkedValue = other.isLinked;
            if (!(other instanceof Linked) || Boolean(isLinkedValue)) {
                return false;
            }
            if (!this.executedAmountQuantity.equals(other.executedAmountQuantity)) {
                return false;
            }
            if (this.contractNoteNumber !== other.contractNoteNumber) {
                return false;
            }
            if (this.rawContractNoteDisplayId !== other.rawContractNoteDisplayId) {
                return false;
            }
            return true;
        }
    }
    Linked.validateExecutedAmountQuantity = (executedAmountQuantity) => {
        if (!amountQuantity_1.AmountQuantity.isInstanceOf(executedAmountQuantity)) {
            throw new InvalidBrokerStatusTypeError(`Attribute executedAmountQuantity is not a AmountQuantity.AmountQuantity`);
        }
    };
    Linked.validateContractNoteNumber = (contractNoteNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(contractNoteNumber)) {
            throw new InvalidBrokerStatusTypeError(`Attribute contractNoteNumber is not a String`);
        }
        if (contractNoteNumber.trim().length === 0) {
            throw new InvalidBrokerStatusTypeError(`Attribute contractNoteNumber cannot be an empty string.`);
        }
    };
    Linked.validateRawContractNoteDisplayId = (rawContractNoteDisplayId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(rawContractNoteDisplayId)) {
            throw new InvalidBrokerStatusTypeError(`Attribute rawContractNoteDisplayId is not an Int64`);
        }
    };
    BrokerStatusType.Linked = Linked;
    Linked.prototype.toString = function toString() {
        return `BrokerStatusType.LINKED(executedAmountQuantity=${this.executedAmountQuantity},contractNoteNumber='${this.contractNoteNumber}',rawContractNoteDisplayId=${this.rawContractNoteDisplayId})`;
    };
    class LinkingPending {
        constructor() {
            this.isLinkingPending = true;
        }
        static fromDTO(dto) {
            return new LinkingPending();
        }
        toDTO() {
            const ret = {
                case: "LINKING_PENDING",
            };
            return ret;
        }
        copy() {
            return new LinkingPending();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isLinkingPendingValue = other.isLinkingPending;
            if (!(other instanceof LinkingPending) || Boolean(isLinkingPendingValue)) {
                return false;
            }
            return true;
        }
    }
    BrokerStatusType.LinkingPending = LinkingPending;
    LinkingPending.prototype.toString = function toString() {
        return `BrokerStatusType.LINKING_PENDING()`;
    };
    BrokerStatusType.fromDTO = (dto) => {
        switch (dto.case) {
            case "LINKED":
                return Linked.fromDTO(dto);
            case "LINKING_PENDING":
                return LinkingPending.fromDTO(dto);
            default:
                throw new InvalidBrokerStatusTypeError(`Case ${dto.case} is not valid case of BrokerStatusType.`);
        }
    };
    BrokerStatusType.isInstanceOf = (other) => {
        const isLINKEDValue = other.isLINKED;
        if (other instanceof Linked || Boolean(isLINKEDValue)) {
            return true;
        }
        const isLINKING_PENDINGValue = other.isLINKING_PENDING;
        if (other instanceof LinkingPending || Boolean(isLINKING_PENDINGValue)) {
            return true;
        }
        return false;
    };
})(BrokerStatusType = exports.BrokerStatusType || (exports.BrokerStatusType = {}));
