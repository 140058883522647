"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getMFDealRequestsSortOrder_1 = require("./getMFDealRequestsSortOrder");
const mutualFundItemsPerPage_1 = require("./mutualFundItemsPerPage");
const mutualFundPageIndex_1 = require("./mutualFundPageIndex");
const getMFDealRequestsPaginationResponse_1 = require("./getMFDealRequestsPaginationResponse");
const mFDealRequestStatus_1 = require("./mFDealRequestStatus");
const mFTransactionType_1 = require("./mFTransactionType");
/* eslint-disable import/export */
class GetMFDealRequestsRPC {
}
exports.GetMFDealRequestsRPC = GetMFDealRequestsRPC;
(function (GetMFDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, requestId = null, requestStatus = null, scheme = null, amc = null, transactionType = null, portfolio = null, itemsPerPage, pageIndex) {
            this.isGetMFDealRequestsRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (requestId !== undefined && requestId !== null) {
                Request.validateRequestId(requestId);
            }
            this.requestId = requestId;
            if (requestStatus !== undefined && requestStatus !== null) {
                Request.validateRequestStatus(requestStatus);
            }
            this.requestStatus = requestStatus;
            if (scheme !== undefined && scheme !== null) {
                Request.validateScheme(scheme);
            }
            this.scheme = scheme;
            if (amc !== undefined && amc !== null) {
                Request.validateAmc(amc);
            }
            this.amc = amc;
            if (transactionType !== undefined && transactionType !== null) {
                Request.validateTransactionType(transactionType);
            }
            this.transactionType = transactionType;
            if (portfolio !== undefined && portfolio !== null) {
                Request.validatePortfolio(portfolio);
            }
            this.portfolio = portfolio;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getMFDealRequestsSortOrder_1.GetMFDealRequestsSortOrder.fromDTO, InvalidRequestError);
            const requestId = (0, leo_ts_runtime_1.getOptionalString)(dto, "requestId", InvalidRequestError);
            let requestStatus = (0, leo_ts_runtime_1.getOptionalObject)(dto, "requestStatus");
            if (requestStatus !== undefined && requestStatus !== null) {
                requestStatus = mFDealRequestStatus_1.MFDealRequestStatus.fromDTO(requestStatus);
            }
            const scheme = (0, leo_ts_runtime_1.getOptionalString)(dto, "scheme", InvalidRequestError);
            const amc = (0, leo_ts_runtime_1.getOptionalString)(dto, "amc", InvalidRequestError);
            let transactionType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "transactionType");
            if (transactionType !== undefined && transactionType !== null) {
                transactionType = mFTransactionType_1.MFTransactionType.fromDTO(transactionType);
            }
            const portfolio = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolio", InvalidRequestError);
            const itemsPerPage = mutualFundItemsPerPage_1.MutualFundItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = mutualFundPageIndex_1.MutualFundPageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, requestId, requestStatus, scheme, amc, transactionType, portfolio, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.requestId !== undefined && this.requestId !== null) {
                ret.requestId = this.requestId;
            }
            if (this.requestStatus !== undefined && this.requestStatus !== null) {
                ret.requestStatus = mFDealRequestStatus_1.MFDealRequestStatus.toDTO(this.requestStatus);
            }
            if (this.scheme !== undefined && this.scheme !== null) {
                ret.scheme = this.scheme;
            }
            if (this.amc !== undefined && this.amc !== null) {
                ret.amc = this.amc;
            }
            if (this.transactionType !== undefined && this.transactionType !== null) {
                ret.transactionType = mFTransactionType_1.MFTransactionType.toDTO(this.transactionType);
            }
            if (this.portfolio !== undefined && this.portfolio !== null) {
                ret.portfolio = this.portfolio;
            }
            return ret;
        }
        copy(sortList = this.sortList, requestId = this.requestId, requestStatus = this.requestStatus, scheme = this.scheme, amc = this.amc, transactionType = this.transactionType, portfolio = this.portfolio, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, requestId, requestStatus, scheme, amc, transactionType, portfolio, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.requestStatus !== other.requestStatus) {
                return false;
            }
            if (this.scheme !== other.scheme) {
                return false;
            }
            if (this.amc !== other.amc) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.portfolio !== other.portfolio) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetMFDealRequestsSortOrderValue = sortList[i].isGetMFDealRequestsSortOrder;
            if (!(sortList[i] instanceof getMFDealRequestsSortOrder_1.GetMFDealRequestsSortOrder || Boolean(isGetMFDealRequestsSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetMFDealRequestsSortOrder.`);
            }
        }
    };
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isString)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not a String`);
        }
        if (requestId.length < 3) {
            throw new InvalidRequestError(`Attribute requestId is too short. Size is ${requestId.length}. Min size is 3.`);
        }
        if (requestId.length > 200) {
            throw new InvalidRequestError(`Attribute requestId is too long. Size is ${requestId.length}. Max size is 200.`);
        }
        if (requestId.trim().length === 0) {
            throw new InvalidRequestError(`Attribute requestId cannot be an empty string.`);
        }
    };
    Request.validateRequestStatus = (requestStatus) => {
        if (!mFDealRequestStatus_1.MFDealRequestStatus.isInstanceOf(requestStatus)) {
            throw new InvalidRequestError(`Attribute requestStatus is not a MFDealRequestStatus.MFDealRequestStatus`);
        }
    };
    Request.validateScheme = (scheme) => {
        if (!(0, leo_ts_runtime_1.isString)(scheme)) {
            throw new InvalidRequestError(`Attribute scheme is not a String`);
        }
        if (scheme.length < 3) {
            throw new InvalidRequestError(`Attribute scheme is too short. Size is ${scheme.length}. Min size is 3.`);
        }
        if (scheme.length > 200) {
            throw new InvalidRequestError(`Attribute scheme is too long. Size is ${scheme.length}. Max size is 200.`);
        }
        if (scheme.trim().length === 0) {
            throw new InvalidRequestError(`Attribute scheme cannot be an empty string.`);
        }
    };
    Request.validateAmc = (amc) => {
        if (!(0, leo_ts_runtime_1.isString)(amc)) {
            throw new InvalidRequestError(`Attribute amc is not a String`);
        }
        if (amc.length < 3) {
            throw new InvalidRequestError(`Attribute amc is too short. Size is ${amc.length}. Min size is 3.`);
        }
        if (amc.length > 200) {
            throw new InvalidRequestError(`Attribute amc is too long. Size is ${amc.length}. Max size is 200.`);
        }
        if (amc.trim().length === 0) {
            throw new InvalidRequestError(`Attribute amc cannot be an empty string.`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
        }
    };
    Request.validatePortfolio = (portfolio) => {
        if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
            throw new InvalidRequestError(`Attribute portfolio is not a String`);
        }
        if (portfolio.length < 3) {
            throw new InvalidRequestError(`Attribute portfolio is too short. Size is ${portfolio.length}. Min size is 3.`);
        }
        if (portfolio.length > 200) {
            throw new InvalidRequestError(`Attribute portfolio is too long. Size is ${portfolio.length}. Max size is 200.`);
        }
        if (portfolio.trim().length === 0) {
            throw new InvalidRequestError(`Attribute portfolio cannot be an empty string.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isMutualFundItemsPerPageValue = itemsPerPage.isMutualFundItemsPerPage;
        if (!(itemsPerPage instanceof mutualFundItemsPerPage_1.MutualFundItemsPerPage || Boolean(isMutualFundItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a MutualFundItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isMutualFundPageIndexValue = pageIndex.isMutualFundPageIndex;
        if (!(pageIndex instanceof mutualFundPageIndex_1.MutualFundPageIndex || Boolean(isMutualFundPageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a MutualFundPageIndex`);
        }
    };
    GetMFDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFDealRequestsRPC.Request(sortList=${this.sortList},requestId='${this.requestId}',requestStatus=${this.requestStatus},scheme='${this.scheme}',amc='${this.amc}',transactionType=${this.transactionType},portfolio='${this.portfolio}',itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getMFDealRequestsPaginationResponse, totalItems) {
            this.isGetMFDealRequestsRPCResponse = true;
            Response.validateGetMFDealRequestsPaginationResponse(getMFDealRequestsPaginationResponse);
            this.getMFDealRequestsPaginationResponse = getMFDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getMFDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getMFDealRequestsPaginationResponse", getMFDealRequestsPaginationResponse_1.GetMFDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getMFDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getMFDealRequestsPaginationResponse: this.getMFDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getMFDealRequestsPaginationResponse = this.getMFDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getMFDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getMFDealRequestsPaginationResponse, other.getMFDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetMFDealRequestsPaginationResponse = (getMFDealRequestsPaginationResponse) => {
        if (!Array.isArray(getMFDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getMFDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getMFDealRequestsPaginationResponse.length; i += 1) {
            const isGetMFDealRequestsPaginationResponseValue = getMFDealRequestsPaginationResponse[i].isGetMFDealRequestsPaginationResponse;
            if (!(getMFDealRequestsPaginationResponse[i] instanceof getMFDealRequestsPaginationResponse_1.GetMFDealRequestsPaginationResponse || Boolean(isGetMFDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getMFDealRequestsPaginationResponse at index ${i} is not a GetMFDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetMFDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFDealRequestsRPC.Response(getMFDealRequestsPaginationResponse=${this.getMFDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetMFDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFDealRequestsRPC.Errors || (GetMFDealRequestsRPC.Errors = {}));
})(GetMFDealRequestsRPC = exports.GetMFDealRequestsRPC || (exports.GetMFDealRequestsRPC = {}));
