"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FDWithdrawStatus = exports.InvalidFDWithdrawStatusError = void 0;
class InvalidFDWithdrawStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFDWithdrawStatusError";
    }
}
exports.InvalidFDWithdrawStatusError = InvalidFDWithdrawStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FDWithdrawStatus;
(function (FDWithdrawStatus_1) {
    let FDWithdrawStatus;
    (function (FDWithdrawStatus) {
        FDWithdrawStatus["WITHDRAW_PENDING"] = "WITHDRAW_PENDING";
        FDWithdrawStatus["WITHDRAW_REJECTED"] = "WITHDRAW_REJECTED";
        FDWithdrawStatus["WITHDRAW_APPROVED"] = "WITHDRAW_APPROVED";
    })(FDWithdrawStatus = FDWithdrawStatus_1.FDWithdrawStatus || (FDWithdrawStatus_1.FDWithdrawStatus = {}));
    FDWithdrawStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "WITHDRAW_PENDING":
                return FDWithdrawStatus.WITHDRAW_PENDING;
            case "WITHDRAW_REJECTED":
                return FDWithdrawStatus.WITHDRAW_REJECTED;
            case "WITHDRAW_APPROVED":
                return FDWithdrawStatus.WITHDRAW_APPROVED;
            default:
                throw new InvalidFDWithdrawStatusError(`Case ${dto.case} is not valid case of FDWithdrawStatus`);
        }
    };
    FDWithdrawStatus_1.toDTO = (fDWithdrawStatus) => {
        const ret = {
            case: FDWithdrawStatus[fDWithdrawStatus],
        };
        return ret;
    };
    FDWithdrawStatus_1.isInstanceOf = (other) => {
        if (other in FDWithdrawStatus) {
            return true;
        }
        return false;
    };
})(FDWithdrawStatus = exports.FDWithdrawStatus || (exports.FDWithdrawStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
