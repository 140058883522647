import { types } from "mobx-state-tree";
import { BigAmountModel } from "../../../models/BigAmountModel";
import { AccrualItemModel } from "../../../models/AccrualItemModel";

export const FiCashflowDetailModel = types.model({
  bankId: types.string,
  bankName: types.string,
  bankAccountNumber: types.string,
  amount: BigAmountModel,
  accruals: types.array(AccrualItemModel),
});
