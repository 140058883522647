import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createMFImpactTableModel,
  ImpactTableModel,
} from "../../../../models/ImpactTableModel";
import { getAPIClient, mfCreateDealErrorStore } from "./hooks";
import {
  BigAmount,
  BigDecimal,
  GetMFDealRequestImpactRPC,
  ISIN,
  MFTransactionType,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { useGetMFDealRequestImpactRPC } from "../../rpc/RPC";
import {
  createCurrencyRPCType,
  CurrencyModel,
} from "../../../../models/CurrencyModel";
import { CreateMFDealError } from "./MFCreateDealRequestErrorStore";

export const MFDealImpactStore = types
  .model("MFDealImpactStore", {
    impactTableModel: types.maybeNull(ImpactTableModel),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFDealRequestImpact: flow(function* (
        isin: string,
        entityId: string,
        portfolioId: string,
        transactionType: MFTransactionType.MFTransactionType,
        grossAmount: string | undefined,
        units: string | undefined,
        approxNav: string,
        currency: Instance<typeof CurrencyModel>,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        try {
          const request = new GetMFDealRequestImpactRPC.Request(
            new ISIN(isin),
            new LeoUUID(entityId),
            new LeoUUID(portfolioId),
            transactionType,
            grossAmount
              ? new BigAmount(
                  new BigDecimal(grossAmount),
                  createCurrencyRPCType(currency),
                )
              : null,
            units ? new BigDecimal(units) : null,
            new BigAmount(
              new BigDecimal(approxNav),
              createCurrencyRPCType(currency),
            ),
          );
          store.isLoading = true;
          const result: LeoRPCResult<
            GetMFDealRequestImpactRPC.Response,
            GetMFDealRequestImpactRPC.Errors.Errors
          > = yield useGetMFDealRequestImpactRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            if (response.impactTable) {
              store.impactTableModel = createMFImpactTableModel(
                response.impactTable,
              );
            }
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in GetMFDealRequestImpactRPC`,
                );
            }
          } else {
            logger.error("Unhandled error");
          }
        } catch (e) {
          const errorStore = mfCreateDealErrorStore(store);
          errorStore.setError(CreateMFDealError.ClientError);
        }
        store.isLoading = false;
      }),
    };
  });

export const createMFDealImpactStore = (): Instance<
  typeof MFDealImpactStore
> => {
  return MFDealImpactStore.create({});
};
