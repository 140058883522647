import { cast, types, flow, Instance } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FiDealRequestStatus,
  FiTransactionType,
  GetFiDealRequestsPaginationResponse,
  GetFiDealRequestsRPC,
  FixedIncomeItemsPerPage,
  FixedIncomeSortOrder,
  FiDealRequestSearchBy,
  GetFiDealRequestsSortOrder,
  FixedIncomePageIndex,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { AllEnum } from "../../../../../types/EnumTypes";
import { FiDealRequestModel } from "../models/FiDealRequestModel";
import { GetFiDealRequestsSortOrderEnums } from "@khazana/khazana-rpcs/build/fixed-income/getFiDealRequestsSortOrder";
import { useGetFiDealRequestsRPCImpl } from "../rpcs/RPC";
import { DealRequestsFilterOptionsModel } from "../../../models/DealRequestsFilterOptionsModel";
import { getFiDealRequestsInitialFilter } from "../utils/SearchUtils";
import { getAmount, getAmountOrUndefined } from "../../../../../utils";

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): FixedIncomeSortOrder.FixedIncomeSortOrder => {
  if (sortOrder === "asc") {
    return FixedIncomeSortOrder.FixedIncomeSortOrder.ASCENDING;
  }
  return FixedIncomeSortOrder.FixedIncomeSortOrder.DESCENDING;
};

const getDealRequestStatusType = (
  dealRequestStatus: string,
): FiDealRequestStatus.FiDealRequestStatus | null => {
  return dealRequestStatus !== AllEnum.All
    ? FiDealRequestStatus.fromDTO({ case: dealRequestStatus })
    : null;
};

const getTransactionType = (
  transactionType: string,
): FiTransactionType.FiTransactionType | null => {
  return transactionType !== AllEnum.All
    ? FiTransactionType.fromDTO({ case: transactionType })
    : null;
};

const getSearchText = (
  searchBy: FiDealRequestSearchBy.FiDealRequestSearchBy,
  filter: Instance<typeof DealRequestsFilterOptionsModel>,
): string | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return filter.searchText;
  }
};

export const FiDealRequestsStore = types
  .model({
    filterOptions: DealRequestsFilterOptionsModel,
    totalItems: types.number,
    requests: types.array(FiDealRequestModel),
    currencySymbol: types.maybeNull(types.string),
  })
  .actions((store) => ({
    updateFilterOptions(
      filterOptions: Instance<typeof DealRequestsFilterOptionsModel>,
    ): void {
      store.filterOptions = filterOptions;
    },
    getFiDealRequests: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sortOrder?: "asc" | "desc",
    ) {
      const request = new GetFiDealRequestsRPC.Request(
        [
          new GetFiDealRequestsSortOrder(
            0,
            GetFiDealRequestsSortOrderEnums.ColumnName.ColumnName.MODIFIED_AT,
            getSortOrder(sortOrder),
          ),
          new GetFiDealRequestsSortOrder(
            0,
            GetFiDealRequestsSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
            getSortOrder(sortOrder),
          ),
        ],
        getSearchText(
          FiDealRequestSearchBy.FiDealRequestSearchBy.REQUEST_ID,
          store.filterOptions,
        ),
        getDealRequestStatusType(store.filterOptions.requestStatus),
        getSearchText(
          FiDealRequestSearchBy.FiDealRequestSearchBy.SECURITY,
          store.filterOptions,
        ),
        getTransactionType(store.filterOptions.transactionType),
        getSearchText(
          FiDealRequestSearchBy.FiDealRequestSearchBy.PORTFOLIO,
          store.filterOptions,
        ),
        getSearchText(
          FiDealRequestSearchBy.FiDealRequestSearchBy.DEMAT_ACCOUNT_NUMBER,
          store.filterOptions,
        ),
        new FixedIncomeItemsPerPage(itemsPerPage),
        new FixedIncomePageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetFiDealRequestsRPC.Response,
        GetFiDealRequestsRPC.Errors.InvalidPageIndex
      > = yield useGetFiDealRequestsRPCImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response.getFiDealRequestsPaginationResponse[0]
          ?.currency
          ? response.getFiDealRequestsPaginationResponse[0].currency.symbol
          : null;
        const requests = response.getFiDealRequestsPaginationResponse.map(
          (_request: GetFiDealRequestsPaginationResponse) => {
            return FiDealRequestModel.create({
              requestId: _request.requestId,
              dealRequestStatus: _request.dealRequestStatus,
              security: _request.security,
              transactionType: _request.transactionType,
              amount: getAmountOrUndefined(_request.amountHCY) ?? 0,
              ytm: getAmount(_request.ytm),
              quantity: _request.quantity ?? 0,
              entity: _request.entity,
              portfolio: _request.portfolio,
              dematAccountNumber: _request.dematAccountNumber,
              executedAmount: getAmountOrUndefined(_request.executedAmountHCY),
              executedUnits: _request.executedQuantity ?? undefined,
              modifiedAt: new Date(_request.modifiedAt.timestamp),
              createdAt: new Date(_request.createdAt.timestamp),
            });
          },
        );
        store.requests = cast(requests);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createFiDealRequestsStore = (): Instance<
  typeof FiDealRequestsStore
> => {
  return FiDealRequestsStore.create({
    filterOptions: getFiDealRequestsInitialFilter(),
    requests: [],
    totalItems: 0,
  });
};
