import { observer } from "mobx-react";
import React, { useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Button,
  Chip,
  ChipColor,
  DetailsRow,
  DetailsRowProps,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../../utils/BorderUtils";
import { useTranslation } from "react-i18next";
import { History } from "../../../../assets/History";
import { Edit } from "../../../../assets/Edit";
import { Pdf } from "../Pdf";
import { FileUploadDialog } from "../dialog/FileUploadDialog";
import { Instance } from "mobx-state-tree";
import { FileUploadStore } from "../../store/FileUploadStore";

const Size = {
  container: {
    halfWidth: "50%",
    fullWidth: "100%",
    height: "Calc(100vh - 290px)",
  },
  row: {
    maxWidth: "730px",
  },
};

export interface ContractNoteProps {
  title: string;
  attachFileButtonText?: string;
  editFileButtonText?: string;
  attachFileDialogTitle?: string;
  editFileDialogTitle?: string;
  status?: { name: string; color: ChipColor };
  showEditButton?: boolean;
  isEditing?: boolean;
  onEditClick?: () => void;
  onCancelClick?: () => void;
  onViewHistoryClick?: () => void;
  onFileUploadSuccess?: (url: string | undefined, sha256: string) => void;
  rows?: DetailsRowProps[];
  form?: React.ReactNode;
  pdfUrl?: string;
  fileUploadStore?: Instance<typeof FileUploadStore>;
}
export const ContractNote = observer(
  ({
    title,
    attachFileDialogTitle,
    editFileDialogTitle,
    attachFileButtonText,
    editFileButtonText,
    status,
    showEditButton = false,
    isEditing = false,
    onEditClick = (): void => {},
    onCancelClick = (): void => {},
    onViewHistoryClick = (): void => {},
    onFileUploadSuccess = (): void => {},
    rows,
    form,
    pdfUrl,
    fileUploadStore,
  }: ContractNoteProps): React.ReactElement => {
    const spacing = useSpacing();
    const border = useBorder();
    const tokens = useFoundationColorTokens();
    const cornerRadius = useCornerRadius();
    const typography = useTypography();
    const { t } = useTranslation();
    const [openDialog, setOpenDialog] = useState(false);

    const getAttachFileButtonText = (): string => {
      if (pdfUrl) {
        return editFileButtonText ?? t("common.editFileButtonTitle");
      } else {
        return attachFileButtonText ?? t("common.attachFileButtonTitle");
      }
    };

    return (
      <Stack
        sx={{
          borderRadius: cornerRadius.radiusXS,
          backgroundColor: tokens.background,
          border: border.default,
        }}
      >
        {fileUploadStore && (
          <FileUploadDialog
            title={(pdfUrl ? editFileDialogTitle : attachFileDialogTitle) ?? ""}
            onSuccess={(url, sha256) => {
              onFileUploadSuccess(url, sha256);
            }}
            showUploadNoteDialog={openDialog}
            onUploadNoteDialogClose={() => setOpenDialog(false)}
            store={fileUploadStore}
          />
        )}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            padding: `${spacing.spaceMD} ${spacing.spaceXL}`,
            borderBottom: border.default,
          }}
          spacing={spacing.spaceXS}
        >
          <Stack
            direction={"row"}
            spacing={spacing.spaceXS}
            alignItems={"center"}
          >
            <Typography sx={{ ...typography.sh3 }}>{title}</Typography>
            {status ? (
              <Chip
                label={status.name.toLocaleUpperCase()}
                color={status.color}
              />
            ) : null}
          </Stack>
          <Stack direction={"row"} spacing={spacing.spaceXS}>
            {isEditing && (
              <>
                <Button
                  name="attachFile"
                  size="small"
                  variant="outlined-color"
                  onClick={() => setOpenDialog(true)}
                  label={getAttachFileButtonText()}
                />
                <Button
                  name="cancel"
                  size="small"
                  variant="outlined-color"
                  onClick={onCancelClick}
                  label={t("common.cancel")}
                />
              </>
            )}
            {!isEditing && (
              <>
                <Button
                  name={"ViewHistory"}
                  size={"small"}
                  iconPosition="leading"
                  icon={<History />}
                  variant={"outlined-color"}
                  onClick={onViewHistoryClick}
                  label={t("contractNotes.viewHistory")}
                />
                {showEditButton ? (
                  <Button
                    name={"edit"}
                    size={"small"}
                    iconPosition="leading"
                    icon={<Edit />}
                    variant={"outlined-color"}
                    onClick={onEditClick}
                    label={t("common.editDetails")}
                  />
                ) : null}
              </>
            )}
          </Stack>
        </Stack>
        <Stack direction={"row"} height={Size.container.height}>
          {!isEditing && (
            <Stack
              sx={{
                height: Size.container.height,
                width: pdfUrl
                  ? Size.container.halfWidth
                  : Size.container.fullWidth,
                overflow: "auto",
                borderRight: border.default,
              }}
            >
              {rows?.map(({ label, cellType }) => (
                <Box
                  key={label}
                  maxWidth={
                    pdfUrl ? Size.row.maxWidth : Size.container.fullWidth
                  }
                >
                  <DetailsRow label={label} cellType={cellType} />
                  <Divider />
                </Box>
              ))}
            </Stack>
          )}
          {isEditing && (
            <Stack
              sx={{
                height: Size.container.height,
                width: pdfUrl
                  ? Size.container.halfWidth
                  : Size.container.fullWidth,
                overflow: "auto",
                borderRight: border.default,
              }}
            >
              {form}
            </Stack>
          )}
          {pdfUrl && <Pdf url={pdfUrl} />}
        </Stack>
      </Stack>
    );
  },
);
