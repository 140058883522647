"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFInvestmentReturnsGrowthResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const bigDecimal_1 = require("../types/bigDecimal");
class InvalidMFInvestmentReturnsGrowthResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFInvestmentReturnsGrowthResponseError";
    }
}
class MFInvestmentReturnsGrowthResponse {
    constructor(purchaseId, schemeId = null, schemeName = null, amcName = null, brokerName = null, entityName = null, portfolioName = null, investmentAmount = null, totalUnits = null, outstandingUnits = null, navOnDoi = null, purchaseDate = null, latestNavDate = null, latestNav = null, numberOfDays = null, growthReturnPct = null, amountRealisable = null) {
        this.isMFInvestmentReturnsGrowthResponse = true;
        MFInvestmentReturnsGrowthResponse.validatePurchaseId(purchaseId);
        this.purchaseId = purchaseId;
        if (schemeId !== undefined && schemeId !== null) {
            MFInvestmentReturnsGrowthResponse.validateSchemeId(schemeId);
        }
        this.schemeId = schemeId;
        if (schemeName !== undefined && schemeName !== null) {
            MFInvestmentReturnsGrowthResponse.validateSchemeName(schemeName);
        }
        this.schemeName = schemeName;
        if (amcName !== undefined && amcName !== null) {
            MFInvestmentReturnsGrowthResponse.validateAmcName(amcName);
        }
        this.amcName = amcName;
        if (brokerName !== undefined && brokerName !== null) {
            MFInvestmentReturnsGrowthResponse.validateBrokerName(brokerName);
        }
        this.brokerName = brokerName;
        if (entityName !== undefined && entityName !== null) {
            MFInvestmentReturnsGrowthResponse.validateEntityName(entityName);
        }
        this.entityName = entityName;
        if (portfolioName !== undefined && portfolioName !== null) {
            MFInvestmentReturnsGrowthResponse.validatePortfolioName(portfolioName);
        }
        this.portfolioName = portfolioName;
        if (investmentAmount !== undefined && investmentAmount !== null) {
            MFInvestmentReturnsGrowthResponse.validateInvestmentAmount(investmentAmount);
        }
        this.investmentAmount = investmentAmount;
        if (totalUnits !== undefined && totalUnits !== null) {
            MFInvestmentReturnsGrowthResponse.validateTotalUnits(totalUnits);
        }
        this.totalUnits = totalUnits;
        if (outstandingUnits !== undefined && outstandingUnits !== null) {
            MFInvestmentReturnsGrowthResponse.validateOutstandingUnits(outstandingUnits);
        }
        this.outstandingUnits = outstandingUnits;
        if (navOnDoi !== undefined && navOnDoi !== null) {
            MFInvestmentReturnsGrowthResponse.validateNavOnDoi(navOnDoi);
        }
        this.navOnDoi = navOnDoi;
        if (purchaseDate !== undefined && purchaseDate !== null) {
            MFInvestmentReturnsGrowthResponse.validatePurchaseDate(purchaseDate);
        }
        this.purchaseDate = purchaseDate;
        if (latestNavDate !== undefined && latestNavDate !== null) {
            MFInvestmentReturnsGrowthResponse.validateLatestNavDate(latestNavDate);
        }
        this.latestNavDate = latestNavDate;
        if (latestNav !== undefined && latestNav !== null) {
            MFInvestmentReturnsGrowthResponse.validateLatestNav(latestNav);
        }
        this.latestNav = latestNav;
        if (numberOfDays !== undefined && numberOfDays !== null) {
            MFInvestmentReturnsGrowthResponse.validateNumberOfDays(numberOfDays);
        }
        this.numberOfDays = numberOfDays;
        if (growthReturnPct !== undefined && growthReturnPct !== null) {
            MFInvestmentReturnsGrowthResponse.validateGrowthReturnPct(growthReturnPct);
        }
        this.growthReturnPct = growthReturnPct;
        if (amountRealisable !== undefined && amountRealisable !== null) {
            MFInvestmentReturnsGrowthResponse.validateAmountRealisable(amountRealisable);
        }
        this.amountRealisable = amountRealisable;
    }
    static fromDTO(dto) {
        const purchaseId = (0, leo_ts_runtime_1.getString)(dto, "purchaseId", InvalidMFInvestmentReturnsGrowthResponseError);
        let schemeId = (0, leo_ts_runtime_1.getOptionalObject)(dto, "schemeId");
        if (schemeId !== undefined && schemeId !== null) {
            schemeId = iSIN_1.ISIN.fromDTO(schemeId);
        }
        const schemeName = (0, leo_ts_runtime_1.getOptionalString)(dto, "schemeName", InvalidMFInvestmentReturnsGrowthResponseError);
        const amcName = (0, leo_ts_runtime_1.getOptionalString)(dto, "amcName", InvalidMFInvestmentReturnsGrowthResponseError);
        const brokerName = (0, leo_ts_runtime_1.getOptionalString)(dto, "brokerName", InvalidMFInvestmentReturnsGrowthResponseError);
        const entityName = (0, leo_ts_runtime_1.getOptionalString)(dto, "entityName", InvalidMFInvestmentReturnsGrowthResponseError);
        const portfolioName = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolioName", InvalidMFInvestmentReturnsGrowthResponseError);
        let investmentAmount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "investmentAmount");
        if (investmentAmount !== undefined && investmentAmount !== null) {
            investmentAmount = bigDecimal_1.BigDecimal.fromDTO(investmentAmount);
        }
        let totalUnits = (0, leo_ts_runtime_1.getOptionalObject)(dto, "totalUnits");
        if (totalUnits !== undefined && totalUnits !== null) {
            totalUnits = bigDecimal_1.BigDecimal.fromDTO(totalUnits);
        }
        let outstandingUnits = (0, leo_ts_runtime_1.getOptionalObject)(dto, "outstandingUnits");
        if (outstandingUnits !== undefined && outstandingUnits !== null) {
            outstandingUnits = bigDecimal_1.BigDecimal.fromDTO(outstandingUnits);
        }
        let navOnDoi = (0, leo_ts_runtime_1.getOptionalObject)(dto, "navOnDoi");
        if (navOnDoi !== undefined && navOnDoi !== null) {
            navOnDoi = bigDecimal_1.BigDecimal.fromDTO(navOnDoi);
        }
        const purchaseDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "purchaseDate", InvalidMFInvestmentReturnsGrowthResponseError);
        const latestNavDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "latestNavDate", InvalidMFInvestmentReturnsGrowthResponseError);
        let latestNav = (0, leo_ts_runtime_1.getOptionalObject)(dto, "latestNav");
        if (latestNav !== undefined && latestNav !== null) {
            latestNav = bigDecimal_1.BigDecimal.fromDTO(latestNav);
        }
        const numberOfDays = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "numberOfDays", InvalidMFInvestmentReturnsGrowthResponseError);
        let growthReturnPct = (0, leo_ts_runtime_1.getOptionalObject)(dto, "growthReturnPct");
        if (growthReturnPct !== undefined && growthReturnPct !== null) {
            growthReturnPct = bigDecimal_1.BigDecimal.fromDTO(growthReturnPct);
        }
        let amountRealisable = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amountRealisable");
        if (amountRealisable !== undefined && amountRealisable !== null) {
            amountRealisable = bigDecimal_1.BigDecimal.fromDTO(amountRealisable);
        }
        return new MFInvestmentReturnsGrowthResponse(purchaseId, schemeId, schemeName, amcName, brokerName, entityName, portfolioName, investmentAmount, totalUnits, outstandingUnits, navOnDoi, purchaseDate, latestNavDate, latestNav, numberOfDays, growthReturnPct, amountRealisable);
    }
    toDTO() {
        const ret = {
            purchaseId: this.purchaseId,
        };
        if (this.schemeId) {
            ret.schemeId = this.schemeId.toDTO();
        }
        if (this.schemeName !== undefined && this.schemeName !== null) {
            ret.schemeName = this.schemeName;
        }
        if (this.amcName !== undefined && this.amcName !== null) {
            ret.amcName = this.amcName;
        }
        if (this.brokerName !== undefined && this.brokerName !== null) {
            ret.brokerName = this.brokerName;
        }
        if (this.entityName !== undefined && this.entityName !== null) {
            ret.entityName = this.entityName;
        }
        if (this.portfolioName !== undefined && this.portfolioName !== null) {
            ret.portfolioName = this.portfolioName;
        }
        if (this.investmentAmount) {
            ret.investmentAmount = this.investmentAmount.toDTO();
        }
        if (this.totalUnits) {
            ret.totalUnits = this.totalUnits.toDTO();
        }
        if (this.outstandingUnits) {
            ret.outstandingUnits = this.outstandingUnits.toDTO();
        }
        if (this.navOnDoi) {
            ret.navOnDoi = this.navOnDoi.toDTO();
        }
        if (this.purchaseDate) {
            ret.purchaseDate = this.purchaseDate.toDTO();
        }
        if (this.latestNavDate) {
            ret.latestNavDate = this.latestNavDate.toDTO();
        }
        if (this.latestNav) {
            ret.latestNav = this.latestNav.toDTO();
        }
        if (this.numberOfDays !== undefined && this.numberOfDays !== null) {
            ret.numberOfDays = this.numberOfDays;
        }
        if (this.growthReturnPct) {
            ret.growthReturnPct = this.growthReturnPct.toDTO();
        }
        if (this.amountRealisable) {
            ret.amountRealisable = this.amountRealisable.toDTO();
        }
        return ret;
    }
    copy(purchaseId = this.purchaseId, schemeId = this.schemeId, schemeName = this.schemeName, amcName = this.amcName, brokerName = this.brokerName, entityName = this.entityName, portfolioName = this.portfolioName, investmentAmount = this.investmentAmount, totalUnits = this.totalUnits, outstandingUnits = this.outstandingUnits, navOnDoi = this.navOnDoi, purchaseDate = this.purchaseDate, latestNavDate = this.latestNavDate, latestNav = this.latestNav, numberOfDays = this.numberOfDays, growthReturnPct = this.growthReturnPct, amountRealisable = this.amountRealisable) {
        return new MFInvestmentReturnsGrowthResponse(purchaseId, schemeId, schemeName, amcName, brokerName, entityName, portfolioName, investmentAmount, totalUnits, outstandingUnits, navOnDoi, purchaseDate, latestNavDate, latestNav, numberOfDays, growthReturnPct, amountRealisable);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFInvestmentReturnsGrowthResponseValue = other.isMFInvestmentReturnsGrowthResponse;
        if (!(other instanceof MFInvestmentReturnsGrowthResponse) || Boolean(isMFInvestmentReturnsGrowthResponseValue)) {
            return false;
        }
        if (this.purchaseId !== other.purchaseId) {
            return false;
        }
        if (this.schemeId) {
            if (!this.schemeId.equals(other.schemeId)) {
                return false;
            }
        }
        if (this.schemeName !== other.schemeName) {
            return false;
        }
        if (this.amcName !== other.amcName) {
            return false;
        }
        if (this.brokerName !== other.brokerName) {
            return false;
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        if (this.portfolioName !== other.portfolioName) {
            return false;
        }
        if (this.investmentAmount) {
            if (!this.investmentAmount.equals(other.investmentAmount)) {
                return false;
            }
        }
        if (this.totalUnits) {
            if (!this.totalUnits.equals(other.totalUnits)) {
                return false;
            }
        }
        if (this.outstandingUnits) {
            if (!this.outstandingUnits.equals(other.outstandingUnits)) {
                return false;
            }
        }
        if (this.navOnDoi) {
            if (!this.navOnDoi.equals(other.navOnDoi)) {
                return false;
            }
        }
        if (this.purchaseDate) {
            if (!this.purchaseDate.equals(other.purchaseDate)) {
                return false;
            }
        }
        if (this.latestNavDate) {
            if (!this.latestNavDate.equals(other.latestNavDate)) {
                return false;
            }
        }
        if (this.latestNav) {
            if (!this.latestNav.equals(other.latestNav)) {
                return false;
            }
        }
        if (this.numberOfDays !== other.numberOfDays) {
            return false;
        }
        if (this.growthReturnPct) {
            if (!this.growthReturnPct.equals(other.growthReturnPct)) {
                return false;
            }
        }
        if (this.amountRealisable) {
            if (!this.amountRealisable.equals(other.amountRealisable)) {
                return false;
            }
        }
        return true;
    }
}
exports.MFInvestmentReturnsGrowthResponse = MFInvestmentReturnsGrowthResponse;
MFInvestmentReturnsGrowthResponse.validatePurchaseId = (purchaseId) => {
    if (!(0, leo_ts_runtime_1.isString)(purchaseId)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute purchaseId is not a String`);
    }
    if (purchaseId.trim().length === 0) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute purchaseId cannot be an empty string.`);
    }
};
MFInvestmentReturnsGrowthResponse.validateSchemeId = (schemeId) => {
    const isISINValue = schemeId.isISIN;
    if (!(schemeId instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute schemeId is not a ISIN`);
    }
};
MFInvestmentReturnsGrowthResponse.validateSchemeName = (schemeName) => {
    if (!(0, leo_ts_runtime_1.isString)(schemeName)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute schemeName is not a String`);
    }
    if (schemeName.trim().length === 0) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute schemeName cannot be an empty string.`);
    }
};
MFInvestmentReturnsGrowthResponse.validateAmcName = (amcName) => {
    if (!(0, leo_ts_runtime_1.isString)(amcName)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute amcName is not a String`);
    }
    if (amcName.trim().length === 0) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute amcName cannot be an empty string.`);
    }
};
MFInvestmentReturnsGrowthResponse.validateBrokerName = (brokerName) => {
    if (!(0, leo_ts_runtime_1.isString)(brokerName)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute brokerName is not a String`);
    }
    if (brokerName.trim().length === 0) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute brokerName cannot be an empty string.`);
    }
};
MFInvestmentReturnsGrowthResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
MFInvestmentReturnsGrowthResponse.validatePortfolioName = (portfolioName) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolioName)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute portfolioName is not a String`);
    }
    if (portfolioName.trim().length === 0) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute portfolioName cannot be an empty string.`);
    }
};
MFInvestmentReturnsGrowthResponse.validateInvestmentAmount = (investmentAmount) => {
    const isBigDecimalValue = investmentAmount.isBigDecimal;
    if (!(investmentAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute investmentAmount is not a BigDecimal`);
    }
};
MFInvestmentReturnsGrowthResponse.validateTotalUnits = (totalUnits) => {
    const isBigDecimalValue = totalUnits.isBigDecimal;
    if (!(totalUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute totalUnits is not a BigDecimal`);
    }
};
MFInvestmentReturnsGrowthResponse.validateOutstandingUnits = (outstandingUnits) => {
    const isBigDecimalValue = outstandingUnits.isBigDecimal;
    if (!(outstandingUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute outstandingUnits is not a BigDecimal`);
    }
};
MFInvestmentReturnsGrowthResponse.validateNavOnDoi = (navOnDoi) => {
    const isBigDecimalValue = navOnDoi.isBigDecimal;
    if (!(navOnDoi instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute navOnDoi is not a BigDecimal`);
    }
};
MFInvestmentReturnsGrowthResponse.validatePurchaseDate = (purchaseDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(purchaseDate)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute purchaseDate is not a Date`);
    }
};
MFInvestmentReturnsGrowthResponse.validateLatestNavDate = (latestNavDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(latestNavDate)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute latestNavDate is not a Date`);
    }
};
MFInvestmentReturnsGrowthResponse.validateLatestNav = (latestNav) => {
    const isBigDecimalValue = latestNav.isBigDecimal;
    if (!(latestNav instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute latestNav is not a BigDecimal`);
    }
};
MFInvestmentReturnsGrowthResponse.validateNumberOfDays = (numberOfDays) => {
    if (!(0, leo_ts_runtime_1.isInt64)(numberOfDays)) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute numberOfDays is not an Int64`);
    }
};
MFInvestmentReturnsGrowthResponse.validateGrowthReturnPct = (growthReturnPct) => {
    const isBigDecimalValue = growthReturnPct.isBigDecimal;
    if (!(growthReturnPct instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute growthReturnPct is not a BigDecimal`);
    }
};
MFInvestmentReturnsGrowthResponse.validateAmountRealisable = (amountRealisable) => {
    const isBigDecimalValue = amountRealisable.isBigDecimal;
    if (!(amountRealisable instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMFInvestmentReturnsGrowthResponseError(`Attribute amountRealisable is not a BigDecimal`);
    }
};
MFInvestmentReturnsGrowthResponse.prototype.toString = function toString() {
    return `MFInvestmentReturnsGrowthResponse(purchaseId='${this.purchaseId}',schemeId=${this.schemeId},schemeName='${this.schemeName}',amcName='${this.amcName}',brokerName='${this.brokerName}',entityName='${this.entityName}',portfolioName='${this.portfolioName}',investmentAmount=${this.investmentAmount},totalUnits=${this.totalUnits},outstandingUnits=${this.outstandingUnits},navOnDoi=${this.navOnDoi},purchaseDate=${this.purchaseDate},latestNavDate=${this.latestNavDate},latestNav=${this.latestNav},numberOfDays=${this.numberOfDays},growthReturnPct=${this.growthReturnPct},amountRealisable=${this.amountRealisable})`;
};
