import React, { Fragment } from "react";
import { Divider, Stack, Typography } from "@mui/material";
import { useTypography } from "@surya-digital/leo-reactjs-material-ui";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { useBorder } from "../../../../utils/BorderUtils";

export interface SummaryItem {
  title: string;
  value: string;
}

export interface ReportSummaryProps {
  summary: SummaryItem[];
}

const Size = {
  separatorHeight: "32px",
};

export const ReportSummary = ({
  summary,
}: ReportSummaryProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();

  return (
    <Stack
      direction="row"
      spacing={spacing.spaceXL}
      sx={{
        padding: spacing.space2XL,
        borderRadius: cornerRadius.radiusXS,
        background: tokens.background,
        border: border.default,
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      {summary.map((summaryItem, index) => {
        return (
          <Fragment key={index}>
            <Stack
              spacing={spacing.spaceMD}
              width={(100 / summary.length).toString() + "%"}
              alignItems={"center"}
            >
              <Stack spacing={spacing.spaceMD}>
                <Typography {...typography.b2} color={tokens.labelSubtle}>
                  {summaryItem.title}
                </Typography>
                <Typography {...typography.h5} color={tokens.label}>
                  {summaryItem.value}
                </Typography>
              </Stack>
            </Stack>
            {index !== summary.length - 1 && (
              <Stack>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{
                    color: tokens.border,
                    height: Size.separatorHeight,
                  }}
                />
              </Stack>
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
};
