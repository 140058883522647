import Chart from "react-apexcharts";
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { getFormattedAmountString } from "../../../../../utils";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useViewFiDealRequestDetailsStore } from "../store/hooks";
import { useBorder } from "../../../../../utils/BorderUtils";

interface DateNumber {
  x: Date;
  y: number;
}

const Size = {
  container: "619px",
  chart: {
    width: "472px",
    height: "206px",
    strokeWidth: 1,
  },
};

export const FiLineGraph = (): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const store = useViewFiDealRequestDetailsStore();
  const { t } = useTranslation();
  const cornerRadius = useCornerRadius();
  const symbolHistoryData = (): DateNumber[] | undefined => {
    if (store.lineGraphData) {
      return store.lineGraphData.map((data) => {
        return { x: new Date(data.date), y: data.amount.amount };
      });
    }
  };

  return (
    <Box
      sx={{
        background: tokens.background,
        height: Size.container,
        marginLeft: spacing.spaceXL,
        border: border.default,
        borderRadius: cornerRadius.radiusXS,
      }}
    >
      <Stack
        style={{
          backgroundColor: tokens.background,
          borderRadius: cornerRadius.radiusXXS,
        }}
      >
        <Typography
          sx={{
            ...typography.sh3,
            padding: `${spacing.spaceMD} ${spacing.spaceXL}`,
            borderBottom: border.default,
          }}
        >
          {`${store.securityName} - ${t("common.historicalPrice")}`}
        </Typography>
        <Box
          sx={{
            padding: spacing.spaceSM,
          }}
        >
          <Chart
            {...Size.chart}
            type="line"
            options={{
              stroke: {
                curve: "straight",
                width: Size.chart.strokeWidth,
              },
              colors: [tokens.backgroundPrimary],
              // We do not need a label for x-axis as it is not indicated in the design.
              xaxis: {
                type: "datetime",
              },
              yaxis: {
                title: {
                  text: t("common.price"),
                },
              },
              grid: {
                borderColor: tokens.backgroundSubtle,
                padding: {
                  right: 30,
                },
              },
              markers: {
                size: 0,
              },
              tooltip: {
                y: {
                  formatter: (val: number) =>
                    `${store.currencySymbol} ${getFormattedAmountString(val)}`,
                },
              },
              chart: {
                toolbar: {
                  show: false,
                },
              },
            }}
            series={[
              {
                type: "line",
                name: `${store.securityName} - ${t("common.price")}`,
                data: symbolHistoryData() ?? [],
              },
            ]}
          />
        </Box>
      </Stack>
    </Box>
  );
};
