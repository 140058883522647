import React, { useState } from "react";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { Stack, Typography } from "@mui/material";
import { useBorder } from "../../../../utils/BorderUtils";
import {
  Button,
  LoadingIndicator,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@mui/lab";
import { EmptyState } from "../emptyState/EmptyState";

const Size = {
  separatorHeight: "100%",
  timelineDotSize: "24px",
  loadingIndicatorSize: "16px",
};

export interface KHTimelineItem {
  date: string;
  amount: string;
  title: string;
}
export interface KHTimelineProps {
  title: string;
  items: KHTimelineItem[];
  showHeader?: boolean;
  displayRows?: number;
  isLoading?: boolean;
  emptyStateMessage?: string;
  isCreateDealScreen?: boolean;
}
export const KHTimeline = ({
  title,
  items,
  showHeader = false,
  displayRows = 3,
  isLoading = false,
  emptyStateMessage,
  isCreateDealScreen = true,
}: KHTimelineProps): React.ReactElement => {
  const spacing = useSpacing();
  const border = useBorder();
  const { t } = useTranslation();
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const [showMore, setShowMore] = useState(false);
  const [showMoreButtonTitle, setShowMoreButtonTitle] = useState(
    t("common.viewMoreButtonTitle"),
  );

  const emptyState = (): React.ReactElement => {
    if (isLoading) {
      return (
        <Stack paddingBottom={spacing.spaceMD}>
          <LoadingIndicator
            isLoading={isLoading}
            variant={"container"}
            loadingText={t("common.loading")}
          />
        </Stack>
      );
    } else {
      return (
        <Stack paddingBottom={spacing.spaceMD}>
          <EmptyState message={emptyStateMessage} />
        </Stack>
      );
    }
  };

  return (
    <Stack
      spacing={spacing.spaceMD}
      borderBottom={isCreateDealScreen ? border.default : undefined}
    >
      {showHeader && (
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={`${spacing.spaceMD} 0 0 ${spacing.spaceMD}`}
        >
          <Typography
            sx={{ ...typography.sh3, color: tokens.labelHighEmphasis }}
          >
            {title}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} width={"fit-content"}>
            {isLoading && items.length > 0 && (
              <LoadingIndicator
                isLoading={isLoading}
                variant={"container"}
                style={{
                  height: Size.loadingIndicatorSize,
                  width: Size.loadingIndicatorSize,
                }}
              />
            )}
            {items.length > displayRows && (
              <Stack sx={{ width: "fit-content", flexGrow: 1 }}>
                <Button
                  label={showMoreButtonTitle}
                  name={"viewMore"}
                  size={"small"}
                  variant={"plain-color"}
                  onClick={() => {
                    if (showMore) {
                      setShowMore(false);
                      setShowMoreButtonTitle(t("common.viewMoreButtonTitle"));
                    } else {
                      setShowMore(true);
                      setShowMoreButtonTitle(t("common.viewLessButtonTitle"));
                    }
                  }}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      {items.length === 0 && emptyState()}
      {items.length > 0 && (
        <Timeline
          sx={{
            paddingX: spacing.spaceMD,
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
              padding: `0px ${spacing.spaceMD} 0px 0px`,
            },
          }}
        >
          {items.map((item, index) => {
            if (index >= displayRows && !showMore) {
              return null;
            }
            return (
              <TimelineItem key={index} sx={{ alignItems: "center" }}>
                <TimelineOppositeContent>
                  <Typography
                    sx={{
                      typography: { ...typography.b1 },
                      color: tokens.labelSubtle,
                      minWidth: "100px",
                    }}
                  >
                    {item.date}
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator
                  sx={{
                    height: Size.separatorHeight,
                  }}
                >
                  <TimelineConnector
                    sx={{
                      backgroundColor: tokens.borderPrimary,
                    }}
                  />
                  <TimelineDot
                    sx={{
                      height: Size.timelineDotSize,
                      width: Size.timelineDotSize,
                      backgroundColor: tokens.borderPrimary,
                    }}
                  />
                  <TimelineConnector
                    sx={{
                      backgroundColor: tokens.borderPrimary,
                    }}
                  />
                </TimelineSeparator>
                <TimelineContent>
                  <Stack>
                    <Typography sx={{ typography: { ...typography.sh3 } }}>
                      {item.amount}
                    </Typography>
                    <Typography
                      sx={{
                        typography: { ...typography.b2 },
                        color: tokens.labelSubtle,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      )}
    </Stack>
  );
};
