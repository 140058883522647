import { Instance, types } from "mobx-state-tree";
import {
  RequestNoteModel,
  createRequestNoteModel,
} from "../../../models/RequestNoteModel";
import {
  FiMaturityDealRequestStatus,
  FiMaturityRequestHistoryDetail,
} from "@khazana/khazana-rpcs";

export const FiMaturityRequestHistoryDetailModel = types.model(
  "FiMaturityRequestHistoryDetailModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: types.maybeNull(
      types.enumeration<FiMaturityDealRequestStatus.FiMaturityDealRequestStatus>(
        "FiMaturityDealRequestStatus",
        Object.values(FiMaturityDealRequestStatus.FiMaturityDealRequestStatus),
      ),
    ),
  },
);

export const createFiMaturityRequestHistoryDetailModel = (
  requestDetails: FiMaturityRequestHistoryDetail | null,
): Instance<typeof FiMaturityRequestHistoryDetailModel> => {
  if (requestDetails) {
    const { status, requestNote } = requestDetails;
    return FiMaturityRequestHistoryDetailModel.create({
      requestNote: createRequestNoteModel(requestNote),
      status,
    });
  }
  return FiMaturityRequestHistoryDetailModel.create();
};
