import { cast, flow, getEnv, Instance, types } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetBranchSearchListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetBranchSearchListRPC } from "../../rpcs/RPC";
import { MIN_SEARCH_LENGTH } from "../../../../utils";
import { AutoCompleteItem } from "@surya-digital/leo-reactjs-core";

export const BranchModel = types.model("BranchModel", {
  branchId: types.string,
  name: types.string,
});
export const BankBranchAutocompleteStore = types
  .model("BankBranchAutocompleteStore", {
    searchText: types.maybe(types.string),
    branchList: types.array(BranchModel),
    selectedBranchId: types.maybe(types.string),
    isLoading: types.optional(types.boolean, false),
    noOptionText: types.maybe(types.string),
  })
  .actions((store) => ({
    setSelectedBranch: (branchId: string): void => {
      store.selectedBranchId = branchId;
    },
    setisLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    setNoOptionText: (text: string): void => {
      store.noOptionText = text;
    },
    setSearchText: (text: string): void => {
      store.searchText = text;
    },
    getBranchList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.isLoading = true;
      if (
        store.searchText?.length &&
        store.searchText?.length >= MIN_SEARCH_LENGTH
      ) {
        try {
          const request = new GetBranchSearchListRPC.Request(store.searchText);
          const result: LeoRPCResult<
            GetBranchSearchListRPC.Response,
            GetBranchSearchListRPC.Errors.Errors
          > = yield useGetBranchSearchListRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.branchList = cast(
              response.branches.map((branch) => {
                return BranchModel.create({
                  branchId: branch.branchId,
                  name: branch.bankName + ", " + branch.branchName,
                });
              }),
            );
          } else {
            logger.error("Unable to fetch branch list, Invalid response type.");
          }
        } catch (e) {
          logger.error(`Unknown Error: ${e}`);
        }
      }
      store.isLoading = false;
    }),
    deselectBranch: (): void => {
      store.selectedBranchId = undefined;
    },
  }))
  .views((store) => ({
    getSelectedBranch: (): AutoCompleteItem | null => {
      const selectedBranch = store.branchList.find((item) => {
        return item.branchId === store.selectedBranchId;
      });

      return selectedBranch
        ? {
            id: selectedBranch.branchId,
            label: selectedBranch.branchId,
            subLabel: selectedBranch.name,
          }
        : null;
    },
    getBranchOptions: (): AutoCompleteItem[] => {
      return store.branchList.map((branch) => {
        return {
          id: branch.branchId,
          label: branch.branchId,
          subLabel: branch.name,
        };
      });
    },
  }));

export const createDepositBankBranchDropdownStore = (
  selectedId?: string,
): Instance<typeof BankBranchAutocompleteStore> => {
  return BankBranchAutocompleteStore.create({
    selectedBranchId: selectedId,
  });
};
