import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  FileExtension,
  FixedIncomeHoldingCategory,
  FixedIncomeInstrumentType,
  GetFixedIncomeHoldingSummaryDownloadURLRPC,
  GetFixedIncomeHoldingSummaryReportRPC,
  PeriodInput,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import {
  Instance,
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  types,
} from "mobx-state-tree";
import {
  useGetFixedIncomeHoldingSummaryDownloadURLRPCClientImpl,
  useGetFixedIncomeHoldingSummaryReportRPCClientImpl,
} from "../rpcs/RPC";
import { TableSortOption } from "@surya-digital/leo-reactjs-core";
import {
  PortfolioDropdownStore,
  createPortfolioDropdownStore,
} from "../../../components/portfolio/PortfolioDropdownStore";
import {
  EntityDropdownStore,
  createEntityDropdownStore,
} from "../../../components/entity/EntityDropdownStore";
import {
  ReportExportFormatEnum,
  checkAllOptionAndGetId,
} from "../../../utils/ReportUtils";
import { AllEnum } from "../../../../../types/EnumTypes";
import { getFiHoldingSummarySortOrder } from "../utils/SortUtils";
import {
  FiHoldingSummaryModel,
  createFiHoldingSummaryModel,
} from "../models/FiHoldingSummaryModel";
import { getLeoDate } from "../../../../../utils";
import {
  getFixedIncomeHoldingCategoryOrAll,
  getFixedIncomeHoldingCategoryOrNull,
  getFixedIncomeInstrumentTypeOrAll,
  getFixedIncomeInstrumentTypeOrNull,
} from "./utils";

export const FiHoldingSummaryStore = types
  .model("FiHoldingSummaryStore", {
    entityDropdownStore: EntityDropdownStore,
    portfolioDropdownStore: PortfolioDropdownStore,
    hasError: types.boolean,
    totalItems: types.number,
    downloadURL: types.maybe(types.string),
    fiHoldingSummarySummaryList: types.array(FiHoldingSummaryModel),
    asOnDate: types.maybe(types.string),
    maturityFromDate: types.maybe(types.string),
    maturityToDate: types.maybe(types.string),
    instrumentType: types.union(
      types.enumeration<FixedIncomeInstrumentType.FixedIncomeInstrumentType>(
        "FixedIncomeInstrumentType",
        Object.values(FixedIncomeInstrumentType.FixedIncomeInstrumentType),
      ),
      types.enumeration<AllEnum>("AllEnum", Object.values(AllEnum)),
    ),
    holdingCategory: types.union(
      types.enumeration<FixedIncomeHoldingCategory.FixedIncomeHoldingCategory>(
        "FixedIncomeHoldingCategory",
        Object.values(FixedIncomeHoldingCategory.FixedIncomeHoldingCategory),
      ),
      types.enumeration<AllEnum>("AllEnum", Object.values(AllEnum)),
    ),
    isScreenLoading: types.boolean,
    isDataFetched: types.boolean,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    clearError: (): void => {
      store.hasError = false;
    },
    getHoldingSummarySummary: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sort: TableSortOption | undefined,
      period?: PeriodInput.PeriodInput,
    ) {
      if (period instanceof PeriodInput.BetweenTwoDates) {
        store.maturityToDate = period.endDate.date;
        store.maturityFromDate = period.startDate.date;
      }
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetFixedIncomeHoldingSummaryReportRPC.Request(
          checkAllOptionAndGetId(store.entityDropdownStore.selectedEntity),
          checkAllOptionAndGetId(
            store.portfolioDropdownStore.selectedPortfolio?.id,
          ),
          store.asOnDate ? getLeoDate(new Date(store.asOnDate)) : null,
          store.maturityFromDate
            ? getLeoDate(new Date(store.maturityFromDate))
            : null,
          store.maturityToDate
            ? getLeoDate(new Date(store.maturityToDate))
            : null,
          getFixedIncomeHoldingCategoryOrNull(store.holdingCategory),
          getFixedIncomeInstrumentTypeOrNull(store.instrumentType),
          pageIndex,
          itemsPerPage,
          getFiHoldingSummarySortOrder(sort),
        );
        const result: LeoRPCResult<
          GetFixedIncomeHoldingSummaryReportRPC.Response,
          GetFixedIncomeHoldingSummaryReportRPC.Errors.Errors
        > =
          yield useGetFixedIncomeHoldingSummaryReportRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.totalItems = response.totalItems;
          store.asOnDate = response.asOnDate?.date;
          store.fiHoldingSummarySummaryList = cast(
            response.holdingSummaryResponse.map((item) => {
              return createFiHoldingSummaryModel(item);
            }),
          );
          store.isDataFetched = true;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedIncomeHoldingSummaryReportRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in getHoldingSummarySummary`,
          );
        }
      }
    }),
    getDownloadURL: flow(function* (fileExtension: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      try {
        const request = new GetFixedIncomeHoldingSummaryDownloadURLRPC.Request(
          new LeoUUID(store.entityDropdownStore.selectedEntity),
          new LeoUUID(store.portfolioDropdownStore.selectedPortfolio?.id),
          new LeoDate(store.asOnDate),
          new LeoDate(store.maturityFromDate),
          new LeoDate(store.maturityToDate),
          getFixedIncomeHoldingCategoryOrNull(store.holdingCategory),
          getFixedIncomeInstrumentTypeOrNull(store.instrumentType),
          fileExtension === ReportExportFormatEnum.CSV
            ? FileExtension.FileExtension.CSV
            : FileExtension.FileExtension.XLSX,
        );
        const result: LeoRPCResult<
          GetFixedIncomeHoldingSummaryDownloadURLRPC.Response,
          GetFixedIncomeHoldingSummaryDownloadURLRPC.Errors.Errors
        > =
          yield useGetFixedIncomeHoldingSummaryDownloadURLRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.downloadURL = response.downloadUrl.toString();
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedIncomeHoldingSummaryDownloadURLRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          store.hasError = true;
          logger.error(
            `Unhandled error: ${error} occurred in GetFixedIncomeHoldingSummaryDownloadURLRPC`,
          );
        }
      }
    }),
    setHoldingCategory: (value: string): void => {
      store.holdingCategory = getFixedIncomeHoldingCategoryOrAll(value);
    },
    setInstrumentType: (value: string): void => {
      store.instrumentType = getFixedIncomeInstrumentTypeOrAll(value);
    },
    setAsOnDate: (value: string | undefined): void => {
      store.asOnDate = value;
    },
    setIsScreenLoading: (value: boolean): void => {
      store.isScreenLoading = value;
    },
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createFiHoldingSummaryStore = (): Instance<
  typeof FiHoldingSummaryStore
> => {
  return FiHoldingSummaryStore.create({
    entityDropdownStore: createEntityDropdownStore(),
    portfolioDropdownStore: createPortfolioDropdownStore(),
    totalItems: 0,
    hasError: false,
    isScreenLoading: false,
    isDataFetched: false,
    holdingCategory: AllEnum.All,
    instrumentType: AllEnum.All,
  });
};
