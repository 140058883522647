import { Instance } from "mobx-state-tree";
import { FiReportsStore } from "./FiReportsStore";
import { useFiStore } from "../../store/hooks";
import { FiInvestmentRegisterStore } from "./FiInvestmentRegisterStore";
import { FiHoldingSummaryStore } from "./FiHoldingSummaryStore";

export const useFiReportsStore = (): Instance<typeof FiReportsStore> => {
  return useFiStore().fiReportsStore;
};

export const useFiInvestmentRegisterStore = (): Instance<
  typeof FiInvestmentRegisterStore
> => {
  return useFiStore().fiReportsStore.fiInvestmentRegisterStore;
};

export const useFiHoldingSummaryStore = (): Instance<
  typeof FiHoldingSummaryStore
> => {
  return useFiStore().fiReportsStore.fiHoldingSummaryStore;
};
