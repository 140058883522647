"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityGrandTotalChangeInGainRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const dashboardStat_1 = require("../types/dashboardStat");
/* eslint-disable import/export */
class GetEquityGrandTotalChangeInGainRPC {
}
exports.GetEquityGrandTotalChangeInGainRPC = GetEquityGrandTotalChangeInGainRPC;
(function (GetEquityGrandTotalChangeInGainRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityGrandTotalChangeInGainRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId = null, portfolioId = null, fromDate = null, toDate = null) {
            this.isGetEquityGrandTotalChangeInGainRPCRequest = true;
            if (entityId !== undefined && entityId !== null) {
                Request.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
            if (fromDate !== undefined && fromDate !== null) {
                Request.validateFromDate(fromDate);
            }
            this.fromDate = fromDate;
            if (toDate !== undefined && toDate !== null) {
                Request.validateToDate(toDate);
            }
            this.toDate = toDate;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const fromDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "fromDate", InvalidRequestError);
            const toDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "toDate", InvalidRequestError);
            return new Request(entityId, portfolioId, fromDate, toDate);
        }
        toDTO() {
            const ret = {};
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            if (this.fromDate) {
                ret.fromDate = this.fromDate.toDTO();
            }
            if (this.toDate) {
                ret.toDate = this.toDate.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, fromDate = this.fromDate, toDate = this.toDate) {
            return new Request(entityId, portfolioId, fromDate, toDate);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityGrandTotalChangeInGainRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            if (this.fromDate) {
                if (!this.fromDate.equals(other.fromDate)) {
                    return false;
                }
            }
            if (this.toDate) {
                if (!this.toDate.equals(other.toDate)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateFromDate = (fromDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(fromDate)) {
            throw new InvalidRequestError(`Attribute fromDate is not a Date`);
        }
    };
    Request.validateToDate = (toDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(toDate)) {
            throw new InvalidRequestError(`Attribute toDate is not a Date`);
        }
    };
    GetEquityGrandTotalChangeInGainRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityGrandTotalChangeInGainRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},fromDate=${this.fromDate},toDate=${this.toDate})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityGrandTotalChangeInGainRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(totalUnrealisedGain, mvEndDate, newShareCost, totalRealisedGain, totalIrr) {
            this.isGetEquityGrandTotalChangeInGainRPCResponse = true;
            Response.validateTotalUnrealisedGain(totalUnrealisedGain);
            this.totalUnrealisedGain = totalUnrealisedGain;
            Response.validateMvEndDate(mvEndDate);
            this.mvEndDate = mvEndDate;
            Response.validateNewShareCost(newShareCost);
            this.newShareCost = newShareCost;
            Response.validateTotalRealisedGain(totalRealisedGain);
            this.totalRealisedGain = totalRealisedGain;
            Response.validateTotalIrr(totalIrr);
            this.totalIrr = totalIrr;
        }
        static fromDTO(dto) {
            const totalUnrealisedGain = dashboardStat_1.DashboardStat.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "totalUnrealisedGain", InvalidResponseError));
            const mvEndDate = dashboardStat_1.DashboardStat.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "mvEndDate", InvalidResponseError));
            const newShareCost = dashboardStat_1.DashboardStat.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "newShareCost", InvalidResponseError));
            const totalRealisedGain = dashboardStat_1.DashboardStat.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "totalRealisedGain", InvalidResponseError));
            const totalIrr = dashboardStat_1.DashboardStat.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "totalIrr", InvalidResponseError));
            return new Response(totalUnrealisedGain, mvEndDate, newShareCost, totalRealisedGain, totalIrr);
        }
        toDTO() {
            const ret = {
                totalUnrealisedGain: this.totalUnrealisedGain.toDTO(),
                mvEndDate: this.mvEndDate.toDTO(),
                newShareCost: this.newShareCost.toDTO(),
                totalRealisedGain: this.totalRealisedGain.toDTO(),
                totalIrr: this.totalIrr.toDTO(),
            };
            return ret;
        }
        copy(totalUnrealisedGain = this.totalUnrealisedGain, mvEndDate = this.mvEndDate, newShareCost = this.newShareCost, totalRealisedGain = this.totalRealisedGain, totalIrr = this.totalIrr) {
            return new Response(totalUnrealisedGain, mvEndDate, newShareCost, totalRealisedGain, totalIrr);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityGrandTotalChangeInGainRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.totalUnrealisedGain.equals(other.totalUnrealisedGain)) {
                return false;
            }
            if (!this.mvEndDate.equals(other.mvEndDate)) {
                return false;
            }
            if (!this.newShareCost.equals(other.newShareCost)) {
                return false;
            }
            if (!this.totalRealisedGain.equals(other.totalRealisedGain)) {
                return false;
            }
            if (!this.totalIrr.equals(other.totalIrr)) {
                return false;
            }
            return true;
        }
    }
    Response.validateTotalUnrealisedGain = (totalUnrealisedGain) => {
        const isDashboardStatValue = totalUnrealisedGain.isDashboardStat;
        if (!(totalUnrealisedGain instanceof dashboardStat_1.DashboardStat || Boolean(isDashboardStatValue))) {
            throw new InvalidResponseError(`Attribute totalUnrealisedGain is not a DashboardStat`);
        }
    };
    Response.validateMvEndDate = (mvEndDate) => {
        const isDashboardStatValue = mvEndDate.isDashboardStat;
        if (!(mvEndDate instanceof dashboardStat_1.DashboardStat || Boolean(isDashboardStatValue))) {
            throw new InvalidResponseError(`Attribute mvEndDate is not a DashboardStat`);
        }
    };
    Response.validateNewShareCost = (newShareCost) => {
        const isDashboardStatValue = newShareCost.isDashboardStat;
        if (!(newShareCost instanceof dashboardStat_1.DashboardStat || Boolean(isDashboardStatValue))) {
            throw new InvalidResponseError(`Attribute newShareCost is not a DashboardStat`);
        }
    };
    Response.validateTotalRealisedGain = (totalRealisedGain) => {
        const isDashboardStatValue = totalRealisedGain.isDashboardStat;
        if (!(totalRealisedGain instanceof dashboardStat_1.DashboardStat || Boolean(isDashboardStatValue))) {
            throw new InvalidResponseError(`Attribute totalRealisedGain is not a DashboardStat`);
        }
    };
    Response.validateTotalIrr = (totalIrr) => {
        const isDashboardStatValue = totalIrr.isDashboardStat;
        if (!(totalIrr instanceof dashboardStat_1.DashboardStat || Boolean(isDashboardStatValue))) {
            throw new InvalidResponseError(`Attribute totalIrr is not a DashboardStat`);
        }
    };
    GetEquityGrandTotalChangeInGainRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityGrandTotalChangeInGainRPC.Response(totalUnrealisedGain=${this.totalUnrealisedGain},mvEndDate=${this.mvEndDate},newShareCost=${this.newShareCost},totalRealisedGain=${this.totalRealisedGain},totalIrr=${this.totalIrr})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY") {
                super(code);
                this.isInvalidEntity = true;
                InvalidEntity.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntity(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntity(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityValue = other.isInvalidEntity;
                if (!(other instanceof InvalidEntity || Boolean(isInvalidEntityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntity = InvalidEntity;
        InvalidEntity.prototype.toString = function toString() {
            return `GetEquityGrandTotalChangeInGainRPC.INVALID_ENTITY()`;
        };
        class InvalidPortfolio extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO") {
                super(code);
                this.isInvalidPortfolio = true;
                InvalidPortfolio.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolio(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolio(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioValue = other.isInvalidPortfolio;
                if (!(other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolio.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolio = InvalidPortfolio;
        InvalidPortfolio.prototype.toString = function toString() {
            return `GetEquityGrandTotalChangeInGainRPC.INVALID_PORTFOLIO()`;
        };
        class InvalidDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DATE") {
                super(code);
                this.isInvalidDate = true;
                InvalidDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDateValue = other.isInvalidDate;
                if (!(other instanceof InvalidDate || Boolean(isInvalidDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDate = InvalidDate;
        InvalidDate.prototype.toString = function toString() {
            return `GetEquityGrandTotalChangeInGainRPC.INVALID_DATE()`;
        };
        class InvalidReportTypeCombination extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REPORT_TYPE_COMBINATION") {
                super(code);
                this.isInvalidReportTypeCombination = true;
                InvalidReportTypeCombination.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidReportTypeCombination(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidReportTypeCombination(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidReportTypeCombinationValue = other.isInvalidReportTypeCombination;
                if (!(other instanceof InvalidReportTypeCombination || Boolean(isInvalidReportTypeCombinationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidReportTypeCombination.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REPORT_TYPE_COMBINATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidReportTypeCombination = InvalidReportTypeCombination;
        InvalidReportTypeCombination.prototype.toString = function toString() {
            return `GetEquityGrandTotalChangeInGainRPC.INVALID_REPORT_TYPE_COMBINATION()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY":
                    return InvalidEntity.fromDTO(dto);
                case "INVALID_PORTFOLIO":
                    return InvalidPortfolio.fromDTO(dto);
                case "INVALID_DATE":
                    return InvalidDate.fromDTO(dto);
                case "INVALID_REPORT_TYPE_COMBINATION":
                    return InvalidReportTypeCombination.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityValue = other.isInvalidEntity;
            if (other instanceof InvalidEntity || Boolean(isInvalidEntityValue)) {
                return true;
            }
            const isInvalidPortfolioValue = other.isInvalidPortfolio;
            if (other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue)) {
                return true;
            }
            const isInvalidDateValue = other.isInvalidDate;
            if (other instanceof InvalidDate || Boolean(isInvalidDateValue)) {
                return true;
            }
            const isInvalidReportTypeCombinationValue = other.isInvalidReportTypeCombination;
            if (other instanceof InvalidReportTypeCombination || Boolean(isInvalidReportTypeCombinationValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityGrandTotalChangeInGainRPC.Errors || (GetEquityGrandTotalChangeInGainRPC.Errors = {}));
})(GetEquityGrandTotalChangeInGainRPC = exports.GetEquityGrandTotalChangeInGainRPC || (exports.GetEquityGrandTotalChangeInGainRPC = {}));
