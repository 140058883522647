"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFDealRequestOrder = exports.InvalidMFDealRequestOrderError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mfAMCOrderDetail_1 = require("./mfAMCOrderDetail");
const mFBrokerOrderDetail_1 = require("./mFBrokerOrderDetail");
class InvalidMFDealRequestOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFDealRequestOrderError";
    }
}
exports.InvalidMFDealRequestOrderError = InvalidMFDealRequestOrderError;
var MFDealRequestOrder;
(function (MFDealRequestOrder) {
    class Amc {
        constructor(orderDetails) {
            this.isAmc = true;
            Amc.validateOrderDetails(orderDetails);
            this.orderDetails = orderDetails;
        }
        static fromDTO(dto) {
            const orderDetails = (0, leo_ts_runtime_1.getList)(dto, "orderDetails", mfAMCOrderDetail_1.MfAMCOrderDetail.fromDTO, InvalidMFDealRequestOrderError);
            return new Amc(orderDetails);
        }
        toDTO() {
            const ret = {
                case: "AMC",
                orderDetails: this.orderDetails.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(orderDetails = this.orderDetails) {
            return new Amc(orderDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAmcValue = other.isAmc;
            if (!(other instanceof Amc) || Boolean(isAmcValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.orderDetails, other.orderDetails)) {
                return false;
            }
            return true;
        }
    }
    Amc.validateOrderDetails = (orderDetails) => {
        if (!Array.isArray(orderDetails)) {
            throw new InvalidMFDealRequestOrderError(`Attribute orderDetails is not a List`);
        }
        for (let i = 0; i < orderDetails.length; i += 1) {
            const isMfAMCOrderDetailValue = orderDetails[i].isMfAMCOrderDetail;
            if (!(orderDetails[i] instanceof mfAMCOrderDetail_1.MfAMCOrderDetail || Boolean(isMfAMCOrderDetailValue))) {
                throw new InvalidMFDealRequestOrderError(`Attribute orderDetails at index ${i} is not a MfAMCOrderDetail.`);
            }
        }
    };
    MFDealRequestOrder.Amc = Amc;
    Amc.prototype.toString = function toString() {
        return `MFDealRequestOrder.AMC(orderDetails=${this.orderDetails})`;
    };
    class Broker {
        constructor(orderDetails) {
            this.isBroker = true;
            Broker.validateOrderDetails(orderDetails);
            this.orderDetails = orderDetails;
        }
        static fromDTO(dto) {
            const orderDetails = (0, leo_ts_runtime_1.getList)(dto, "orderDetails", mFBrokerOrderDetail_1.MFBrokerOrderDetail.fromDTO, InvalidMFDealRequestOrderError);
            return new Broker(orderDetails);
        }
        toDTO() {
            const ret = {
                case: "BROKER",
                orderDetails: this.orderDetails.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(orderDetails = this.orderDetails) {
            return new Broker(orderDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isBrokerValue = other.isBroker;
            if (!(other instanceof Broker) || Boolean(isBrokerValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.orderDetails, other.orderDetails)) {
                return false;
            }
            return true;
        }
    }
    Broker.validateOrderDetails = (orderDetails) => {
        if (!Array.isArray(orderDetails)) {
            throw new InvalidMFDealRequestOrderError(`Attribute orderDetails is not a List`);
        }
        for (let i = 0; i < orderDetails.length; i += 1) {
            const isMFBrokerOrderDetailValue = orderDetails[i].isMFBrokerOrderDetail;
            if (!(orderDetails[i] instanceof mFBrokerOrderDetail_1.MFBrokerOrderDetail || Boolean(isMFBrokerOrderDetailValue))) {
                throw new InvalidMFDealRequestOrderError(`Attribute orderDetails at index ${i} is not a MFBrokerOrderDetail.`);
            }
        }
    };
    MFDealRequestOrder.Broker = Broker;
    Broker.prototype.toString = function toString() {
        return `MFDealRequestOrder.BROKER(orderDetails=${this.orderDetails})`;
    };
    MFDealRequestOrder.fromDTO = (dto) => {
        switch (dto.case) {
            case "AMC":
                return Amc.fromDTO(dto);
            case "BROKER":
                return Broker.fromDTO(dto);
            default:
                throw new InvalidMFDealRequestOrderError(`Case ${dto.case} is not valid case of MFDealRequestOrder.`);
        }
    };
    MFDealRequestOrder.isInstanceOf = (other) => {
        const isAMCValue = other.isAMC;
        if (other instanceof Amc || Boolean(isAMCValue)) {
            return true;
        }
        const isBROKERValue = other.isBROKER;
        if (other instanceof Broker || Boolean(isBROKERValue)) {
            return true;
        }
        return false;
    };
})(MFDealRequestOrder = exports.MFDealRequestOrder || (exports.MFDealRequestOrder = {}));
