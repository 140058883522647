"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityHoldingSummarySortOrder = exports.GetEquityHoldingSummarySortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const reportsSortOrder_1 = require("./reportsSortOrder");
class InvalidGetEquityHoldingSummarySortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetEquityHoldingSummarySortOrderError";
    }
}
var GetEquityHoldingSummarySortOrderEnums;
(function (GetEquityHoldingSummarySortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["SYMBOL"] = "SYMBOL";
            ColumnName["COST_OF_HOLDING"] = "COST_OF_HOLDING";
            ColumnName["CURRENT_VALUE"] = "CURRENT_VALUE";
            ColumnName["NET_GAIN"] = "NET_GAIN";
            ColumnName["CHANGE"] = "CHANGE";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "SYMBOL":
                    return ColumnName.SYMBOL;
                case "COST_OF_HOLDING":
                    return ColumnName.COST_OF_HOLDING;
                case "CURRENT_VALUE":
                    return ColumnName.CURRENT_VALUE;
                case "NET_GAIN":
                    return ColumnName.NET_GAIN;
                case "CHANGE":
                    return ColumnName.CHANGE;
                default:
                    throw new InvalidGetEquityHoldingSummarySortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetEquityHoldingSummarySortOrderEnums.ColumnName || (GetEquityHoldingSummarySortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetEquityHoldingSummarySortOrderEnums = exports.GetEquityHoldingSummarySortOrderEnums || (exports.GetEquityHoldingSummarySortOrderEnums = {}));
class GetEquityHoldingSummarySortOrder {
    constructor(columnName, order) {
        this.isGetEquityHoldingSummarySortOrder = true;
        GetEquityHoldingSummarySortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetEquityHoldingSummarySortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const columnName = GetEquityHoldingSummarySortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetEquityHoldingSummarySortOrderError));
        const order = reportsSortOrder_1.ReportsSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetEquityHoldingSummarySortOrderError));
        return new GetEquityHoldingSummarySortOrder(columnName, order);
    }
    toDTO() {
        const ret = {
            columnName: GetEquityHoldingSummarySortOrderEnums.ColumnName.toDTO(this.columnName),
            order: reportsSortOrder_1.ReportsSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(columnName = this.columnName, order = this.order) {
        return new GetEquityHoldingSummarySortOrder(columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetEquityHoldingSummarySortOrderValue = other.isGetEquityHoldingSummarySortOrder;
        if (!(other instanceof GetEquityHoldingSummarySortOrder) || Boolean(isGetEquityHoldingSummarySortOrderValue)) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetEquityHoldingSummarySortOrder = GetEquityHoldingSummarySortOrder;
GetEquityHoldingSummarySortOrder.validateColumnName = (columnName) => {
    if (!GetEquityHoldingSummarySortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetEquityHoldingSummarySortOrderError(`Attribute columnName is not a GetEquityHoldingSummarySortOrderEnums.ColumnName`);
    }
};
GetEquityHoldingSummarySortOrder.validateOrder = (order) => {
    if (!reportsSortOrder_1.ReportsSortOrder.isInstanceOf(order)) {
        throw new InvalidGetEquityHoldingSummarySortOrderError(`Attribute order is not a ReportsSortOrder.ReportsSortOrder`);
    }
};
GetEquityHoldingSummarySortOrder.prototype.toString = function toString() {
    return `GetEquityHoldingSummarySortOrder(columnName=${this.columnName},order=${this.order})`;
};
