import { TableSortOption } from "@surya-digital/leo-reactjs-material-ui";
import {
  GetEquityChangeInGainSortOrder,
  GetEquityHoldingSummarySortOrder,
  ReportsSortOrder,
} from "@khazana/khazana-rpcs";
import { GetEquityChangeInGainSortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getEquityChangeInGainSortOrder";
import { GetEquityHoldingSummarySortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getEquityHoldingSummarySortOrder";
import i18next from "i18next";

export const getChangeInGainSortOrder = (
  sort?: TableSortOption,
): GetEquityChangeInGainSortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }
  let column = GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.SYMBOL;

  switch (sort?.id) {
    case i18next.t("common.symbol"):
      column = GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.salesProceeds"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.SOLD_VALUE;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.totalRg"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.REALISED_GAIN;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.change"):
      column = GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.CHANGE;
      break;
    case i18next.t("equity.reports.changeInGainHeaders.netGain"):
      column =
        GetEquityChangeInGainSortOrderEnums.ColumnName.ColumnName.NET_GAIN;
      break;
  }

  return new GetEquityChangeInGainSortOrder(column, sortOrder);
};

export const getHoldingSummarySortOrder = (
  sort?: TableSortOption,
): GetEquityHoldingSummarySortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;

  switch (sort?.id) {
    case i18next.t("common.symbol"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.SYMBOL;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.activeCost"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .COST_OF_HOLDING;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.activeMarketValue"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .CURRENT_VALUE;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.netGain"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.NET_GAIN;
      break;
    case i18next.t("equity.reports.holdingSummaryHeaders.change"):
      column =
        GetEquityHoldingSummarySortOrderEnums.ColumnName.ColumnName.CHANGE;
      break;
  }

  return new GetEquityHoldingSummarySortOrder(column, sortOrder);
};
