import {
  FixedIncomeHoldingCategory,
  FixedIncomeInstrumentType,
} from "@khazana/khazana-rpcs";
import { AllEnum } from "../../../../../types/EnumTypes";

export const getFixedIncomeInstrumentTypeOrNull = (
  instrumentType: FixedIncomeInstrumentType.FixedIncomeInstrumentType | AllEnum,
): FixedIncomeInstrumentType.FixedIncomeInstrumentType | null => {
  if (instrumentType !== AllEnum.All) {
    return FixedIncomeInstrumentType.fromDTO({ case: instrumentType });
  }
  return null;
};

export const getFixedIncomeInstrumentTypeOrAll = (
  instrumentType: string,
): FixedIncomeInstrumentType.FixedIncomeInstrumentType | AllEnum => {
  if (instrumentType !== AllEnum.All) {
    return FixedIncomeInstrumentType.fromDTO({ case: instrumentType });
  }
  return AllEnum.All;
};

export const getFixedIncomeHoldingCategoryOrNull = (
  holdingCategory:
    | FixedIncomeHoldingCategory.FixedIncomeHoldingCategory
    | AllEnum,
): FixedIncomeHoldingCategory.FixedIncomeHoldingCategory | null => {
  if (holdingCategory !== AllEnum.All) {
    return FixedIncomeHoldingCategory.fromDTO({ case: holdingCategory });
  }
  return null;
};

export const getFixedIncomeHoldingCategoryOrAll = (
  holdingCategory: string,
): FixedIncomeHoldingCategory.FixedIncomeHoldingCategory | AllEnum => {
  if (holdingCategory !== AllEnum.All) {
    return FixedIncomeHoldingCategory.fromDTO({ case: holdingCategory });
  }
  return AllEnum.All;
};
