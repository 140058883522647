"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFDealRequestAction = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidMFDealRequestActionError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFDealRequestActionError";
    }
}
class MFDealRequestAction {
    constructor(allowCancel, allowCheck, allowSettle, allowCreateOrder, allowEditOrder) {
        this.isMFDealRequestAction = true;
        MFDealRequestAction.validateAllowCancel(allowCancel);
        this.allowCancel = allowCancel;
        MFDealRequestAction.validateAllowCheck(allowCheck);
        this.allowCheck = allowCheck;
        MFDealRequestAction.validateAllowSettle(allowSettle);
        this.allowSettle = allowSettle;
        MFDealRequestAction.validateAllowCreateOrder(allowCreateOrder);
        this.allowCreateOrder = allowCreateOrder;
        MFDealRequestAction.validateAllowEditOrder(allowEditOrder);
        this.allowEditOrder = allowEditOrder;
    }
    static fromDTO(dto) {
        const allowCancel = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCancel", InvalidMFDealRequestActionError);
        const allowCheck = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCheck", InvalidMFDealRequestActionError);
        const allowSettle = (0, leo_ts_runtime_1.getBoolean)(dto, "allowSettle", InvalidMFDealRequestActionError);
        const allowCreateOrder = (0, leo_ts_runtime_1.getBoolean)(dto, "allowCreateOrder", InvalidMFDealRequestActionError);
        const allowEditOrder = (0, leo_ts_runtime_1.getBoolean)(dto, "allowEditOrder", InvalidMFDealRequestActionError);
        return new MFDealRequestAction(allowCancel, allowCheck, allowSettle, allowCreateOrder, allowEditOrder);
    }
    toDTO() {
        const ret = {
            allowCancel: this.allowCancel,
            allowCheck: this.allowCheck,
            allowSettle: this.allowSettle,
            allowCreateOrder: this.allowCreateOrder,
            allowEditOrder: this.allowEditOrder,
        };
        return ret;
    }
    copy(allowCancel = this.allowCancel, allowCheck = this.allowCheck, allowSettle = this.allowSettle, allowCreateOrder = this.allowCreateOrder, allowEditOrder = this.allowEditOrder) {
        return new MFDealRequestAction(allowCancel, allowCheck, allowSettle, allowCreateOrder, allowEditOrder);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFDealRequestActionValue = other.isMFDealRequestAction;
        if (!(other instanceof MFDealRequestAction) || Boolean(isMFDealRequestActionValue)) {
            return false;
        }
        if (this.allowCancel !== other.allowCancel) {
            return false;
        }
        if (this.allowCheck !== other.allowCheck) {
            return false;
        }
        if (this.allowSettle !== other.allowSettle) {
            return false;
        }
        if (this.allowCreateOrder !== other.allowCreateOrder) {
            return false;
        }
        if (this.allowEditOrder !== other.allowEditOrder) {
            return false;
        }
        return true;
    }
}
exports.MFDealRequestAction = MFDealRequestAction;
MFDealRequestAction.validateAllowCancel = (allowCancel) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCancel)) {
        throw new InvalidMFDealRequestActionError(`Attribute allowCancel is not a Boolean`);
    }
};
MFDealRequestAction.validateAllowCheck = (allowCheck) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCheck)) {
        throw new InvalidMFDealRequestActionError(`Attribute allowCheck is not a Boolean`);
    }
};
MFDealRequestAction.validateAllowSettle = (allowSettle) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowSettle)) {
        throw new InvalidMFDealRequestActionError(`Attribute allowSettle is not a Boolean`);
    }
};
MFDealRequestAction.validateAllowCreateOrder = (allowCreateOrder) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowCreateOrder)) {
        throw new InvalidMFDealRequestActionError(`Attribute allowCreateOrder is not a Boolean`);
    }
};
MFDealRequestAction.validateAllowEditOrder = (allowEditOrder) => {
    if (!(0, leo_ts_runtime_1.isBoolean)(allowEditOrder)) {
        throw new InvalidMFDealRequestActionError(`Attribute allowEditOrder is not a Boolean`);
    }
};
MFDealRequestAction.prototype.toString = function toString() {
    return `MFDealRequestAction(allowCancel=${this.allowCancel},allowCheck=${this.allowCheck},allowSettle=${this.allowSettle},allowCreateOrder=${this.allowCreateOrder},allowEditOrder=${this.allowEditOrder})`;
};
