import { SettledCashflows } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";
import { BigAmountModel, createBigAmountModel } from "./BigAmountModel";

export const SettledCashflowModel = types.model({
  couponReceived: types.maybe(BigAmountModel),
  couponTds: types.maybe(BigAmountModel),
  principalReceived: types.maybe(BigAmountModel),
  principalTds: types.maybe(BigAmountModel),
});

export const createSettledCashflowModel = ({
  couponReceived,
  couponTds,
  principalReceived,
  principalTds,
}: SettledCashflows): Instance<typeof SettledCashflowModel> => {
  return SettledCashflowModel.create({
    couponReceived: couponReceived
      ? createBigAmountModel(couponReceived)
      : undefined,
    couponTds: couponTds ? createBigAmountModel(couponTds) : undefined,
    principalReceived: principalReceived
      ? createBigAmountModel(principalReceived)
      : undefined,
    principalTds: principalTds ? createBigAmountModel(principalTds) : undefined,
  });
};
