import { flow, getEnv, types } from "mobx-state-tree";
import { CreateFiDealRequestError } from "./CreateFiDealRequestError";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CreateFiSellDealRequestRPC,
  FiCollection,
  FiPriceYtm,
  FiSellDealValue,
  FixedIncomeInstrumentType,
  GetFiSellDealRequestImpactRPC,
  GetFiSellDealSummaryDetailsRPC,
  GetFiSellDematAccountNumberListRPC,
  GetFiSellEntityListRPC,
  GetFiSellPortfolioListRPC,
  GetFiSellSecurityListRPC,
  GetFiSellShowYtmRPC,
  ISIN,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { createSecurityModel } from "../../../models/SecurityModel";
import {
  useCreateSellFiDealRequestRPCClientImpl,
  useGetFiSellPortfolioListRPCClientImpl,
  useGetFiSellDematAccountNumberListRPCClientImpl,
  useGetFiSellEntityRPCClientImpl,
  useGetFiSellSecurityListRPCClientImpl,
  useGetFiSellDealRequestImpactRPCClientImpl,
  useGetFiSellShowYtmRPCClientImpl,
  useGetFiSellDealSummaryDetailsRPC,
} from "../rpcs/RPC";
import { createEntityModel } from "../../../models/EntityModel";
import { createPortfolioModel } from "../../../models/PortfolioModel";
import { createDematAccountModel } from "../../../models/DematAccountModel";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { createServerNoteRPCType } from "../../../../../utils";
import { createFiImpactTableModel } from "../../../models/ImpactTableModel";
import { createSummaryDetailModel } from "../../../models/SummaryDetailModel";
import { createCashflowTimelineModel } from "../../../models/CashflowTimelineModel";

export const CreateFiSellDealRequestStore = types
  .model("CreateFiSellDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<CreateFiDealRequestError>(
        "CreateFiDealRequestError",
        Object.values(CreateFiDealRequestError),
      ),
    ),
    errorBankBalance: types.optional(types.boolean, false),
  })
  .actions((store) => ({
    getSellSecurityList: flow(function* (
      searchText: string,
      securityType: FixedIncomeInstrumentType.FixedIncomeInstrumentType,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiSellSecurityListRPC.Request(
        searchText,
        securityType,
      );
      const result: LeoRPCResult<
        GetFiSellSecurityListRPC.Response,
        GetFiSellSecurityListRPC.Errors.Errors
      > =
        yield useGetFiSellSecurityListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.securities.map((security) =>
          createSecurityModel(security),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetFiSellSecurityListRPC`,
        );
      }
    }),
    getFiSellDealSummaryDetails: flow(function* (
      dealValue: FiSellDealValue.FiSellDealValue | null,
      isin: ISIN,
      priceYtm: FiPriceYtm.FiPriceYtm | null,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiSellDealSummaryDetailsRPC.Request(
        dealValue,
        isin,
        priceYtm,
      );
      const result: LeoRPCResult<
        GetFiSellDealSummaryDetailsRPC.Response,
        GetFiSellDealSummaryDetailsRPC.Errors.Errors
      > = yield useGetFiSellDealSummaryDetailsRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        const transactionDetails = response.transactionDetails.map(
          (details) => {
            return createSummaryDetailModel(details);
          },
        );
        const securityDetails = response.securityDetails.map((details) => {
          return createSummaryDetailModel(details);
        });
        const cashflowTimeline = response.cashflowTimeline.map((details) => {
          return createCashflowTimelineModel(details);
        });
        return { transactionDetails, securityDetails, cashflowTimeline };
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetFiSellDealSummaryDetailsRPC`,
        );
      }
    }),
    getSellEntityList: flow(function* (securityIsin: ISIN) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiSellEntityListRPC.Request(securityIsin);
      const result: LeoRPCResult<
        GetFiSellEntityListRPC.Response,
        GetFiSellEntityListRPC.Errors.Errors
      > = yield useGetFiSellEntityRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.entities.map((entity) => createEntityModel(entity));
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetFiSellEntityListRPC`,
        );
      }
    }),
    getFiSellShowYtm: flow(function* (isin: string) {
      store.error = null;
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiSellShowYtmRPC.Request(new ISIN(isin));
      const result: LeoRPCResult<
        GetFiSellShowYtmRPC.Response,
        GetFiSellShowYtmRPC.Errors.Errors
      > = yield useGetFiSellShowYtmRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.yieldType;
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          case CreateFiDealRequestError.InvalidISIN:
            store.error = CreateFiDealRequestError.InvalidISIN;
            break;
          case CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound:
            store.error =
              CreateFiDealRequestError.NeitherMaturityNorNextOptionDateFound;
            break;
        }
      } else {
        logger.error(
          `Unknown error occurred in getFiSellShowYtm with result: ${result}`,
        );
      }
    }),
    getSellPortfolioList: flow(function* (
      securityIsin: ISIN,
      entityId: LeoUUID,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiSellPortfolioListRPC.Request(
        securityIsin,
        entityId,
      );
      const result: LeoRPCResult<
        GetFiSellPortfolioListRPC.Response,
        GetFiSellPortfolioListRPC.Errors.Errors
      > =
        yield useGetFiSellPortfolioListRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.portfolios.map((portfolio) =>
          createPortfolioModel(portfolio),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from FiGetSellPortfolioListRPC`,
        );
      }
    }),
    getSellDematAccountNumberList: flow(function* (
      securityIsin: ISIN,
      entityId: LeoUUID,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetFiSellDematAccountNumberListRPC.Request(
        securityIsin,
        entityId,
      );
      const result: LeoRPCResult<
        GetFiSellDematAccountNumberListRPC.Response,
        GetFiSellDematAccountNumberListRPC.Errors.Errors
      > =
        yield useGetFiSellDematAccountNumberListRPCClientImpl(
          apiClient,
        ).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dematAccountNumbers.map((dematAccountNumber) =>
          createDematAccountModel(
            dematAccountNumber.accountNumber,
            dematAccountNumber.accountCode,
          ),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetFiSellDematAccountNumberListRPC`,
        );
      }
    }),
    createSellFiDealRequest: flow(function* (
      isin: string,
      entityId: LeoUUID,
      fiCollection: FiCollection.FiCollection,
      dealValue: FiSellDealValue.FiSellDealValue,
      note: string | null,
      priceYtm: FiPriceYtm.FiPriceYtm,
      bankId: LeoUUID,
      accountNumber: string,
      setErrorGrossQuantity: (value: number) => void,
      setErrorNetQuantity: (value: number) => void,
    ) {
      const logger = getEnv(store).logger;
      store.error = null;
      const apiClient: APIClient = getAPIClient(store);
      const request = new CreateFiSellDealRequestRPC.Request(
        entityId,
        fiCollection,
        new ISIN(isin),
        dealValue,
        priceYtm,
        bankId,
        accountNumber,
        createServerNoteRPCType(note),
      );
      const result: LeoRPCResult<
        CreateFiSellDealRequestRPC.Response,
        CreateFiSellDealRequestRPC.Errors.Errors
      > =
        yield useCreateSellFiDealRequestRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dealRequestId;
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          case CreateFiDealRequestError.InvalidISIN:
            store.error = CreateFiDealRequestError.InvalidISIN;
            break;
          case CreateFiDealRequestError.InvalidEntityId:
            store.error = CreateFiDealRequestError.InvalidEntityId;
            break;
          case CreateFiDealRequestError.InvalidPortfolioId:
            store.error = CreateFiDealRequestError.InvalidPortfolioId;
            break;
          case CreateFiDealRequestError.InvalidDematAccountNumber:
            store.error = CreateFiDealRequestError.InvalidDematAccountNumber;
            break;
          case CreateFiDealRequestError.CurrencyMismatch:
            store.error = CreateFiDealRequestError.CurrencyMismatch;
            break;
          case CreateFiDealRequestError.AmountLimitExceeded:
            store.error = CreateFiDealRequestError.AmountLimitExceeded;
            break;
          case CreateFiDealRequestError.InsufficientGrossQuantity:
            store.error = CreateFiDealRequestError.InsufficientGrossQuantity;
            const grossQuantity =
              error as CreateFiSellDealRequestRPC.Errors.InsufficientGrossQuantity;
            setErrorGrossQuantity(grossQuantity.grossQuantity.quantity);
            break;
          case CreateFiDealRequestError.InsufficientNetQuantity:
            store.error = CreateFiDealRequestError.InsufficientNetQuantity;
            const netQuantity =
              error as CreateFiSellDealRequestRPC.Errors.InsufficientNetQuantity;
            setErrorNetQuantity(netQuantity.netQuantity.quantity);
            break;
          case CreateFiDealRequestError.GrossAmountTooLess:
            store.error = CreateFiDealRequestError.GrossAmountTooLess;
            break;
          case CreateFiDealRequestError.InvalidPrice:
            store.error = CreateFiDealRequestError.InvalidPrice;
            break;
        }
      } else {
        logger.error(
          `Unknown error occurred in CreateSellFiDealRequestRPC with result: ${result}`,
        );
      }
    }),
    getFiSellImpactOnPortfolio: flow(function* (
      isin: string,
      entityId: LeoUUID,
      portfolioId: LeoUUID,
      dealValue: FiSellDealValue.DealQuantity,
      priceYtm: FiPriceYtm.FiPriceYtm,
    ) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);

        const request = new GetFiSellDealRequestImpactRPC.Request(
          new ISIN(isin),
          entityId,
          portfolioId,
          dealValue,
          priceYtm,
        );
        const result: LeoRPCResult<
          GetFiSellDealRequestImpactRPC.Response,
          GetFiSellDealRequestImpactRPC.Errors.Errors
        > =
          yield useGetFiSellDealRequestImpactRPCClientImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          if (response.impactTable) {
            return createFiImpactTableModel(response.impactTable);
          }
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetFiSellDealRequestImpactRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              logger.error("Could not create new Leo UUID");
              break;
            case CreateFiDealRequestError.InvalidISINError:
              logger.error("Could not create new ISIN object");
              break;
            default:
              logger.error(
                `Unhandled Error: ${error} occurred in getFiSellImpactOnPortfolio`,
              );
          }
        }
        logger.error(
          `Unhandled error: ${error} occurred in getFiSellImpactOnPortfolio`,
        );
      }
    }),
  }));
