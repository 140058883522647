import {
  CheckMfConfirmationRequestRPC,
  CheckMfConfirmationRequestRPCClientImpl,
  CheckMFFolioCreationRequestRPC,
  CheckMFFolioCreationRequestRPCClientImpl,
  GetMFAmcListRPC,
  GetMFAmcListRPCClientImpl,
  GetMFApprovedDealRequestsRPC,
  GetMFApprovedDealRequestsRPCClientImpl,
  GetMFConfirmationHistoryRPC,
  GetMFConfirmationHistoryRPCClientImpl,
  GetMFConfirmationRequestDetailsRPC,
  GetMFConfirmationRequestDetailsRPCClientImpl,
  GetMFConfirmationRequestsRPC,
  GetMFConfirmationRequestsRPCClientImpl,
  SubmitMFConfirmationLinkRequestRPC,
  SubmitMFConfirmationLinkRequestRPCClientImpl,
  SubmitMFConfirmationRequestRPC,
  SubmitMFConfirmationRequestRPCClientImpl,
  SubmitMFConfirmationUnknownRequestRPC,
  SubmitMFConfirmationUnknownRequestRPCClientImpl,
  ValidateMFConfirmationRequestRPC,
  ValidateMFConfirmationRequestRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetMFConfirmationRequestsRPCImpl } from "./MockGetMFConfirmationRequestsRPCImpl";
import { MockValidateMFConfirmationRequestRPCImpl } from "./MockValidateMFConfirmationRequestRPCImpl";
import { MockSubmitMFConfirmationRequestRPCImpl } from "./MockSubmitMFConfirmationRequestRPCImpl";
import { MockGetMFAmcListRPCImpl } from "./MockGetMFAmcListRPCImpl";
import { MockGetMFConfirmationRequestDetailsRPCImpl } from "./MockGetMFConfirmationRequestDetailsRPCImpl";
import { MockGetMFConfirmationHistoryRPCImpl } from "./MockGetMFConfirmationHistoryRPCImpl";
import { MockSubmitMFConfirmationUnknownRequestRPCImpl } from "./MockSubmitMFConfirmationUnknownRequestRPCImpl";
import { MockCheckMfConfirmationRequestRPCImpl } from "./MockCheckMfConfirmationRequestRPCImpl";
import { MockCheckMFFolioCreationRequestRPCImpl } from "./MockCheckMFFolioCreationRequestRPCImpl";
import { MockGetMFApprovedDealRequestsRPCImpl } from "./MockGetMFApprovedDealRequestsRPCImpl";
import { MockSubmitMFConfirmationLinkRequestRPCImpl } from "./MockSubmitMFConfirmationLinkRequestRPCImpl";

export const useGetMFConfirmationRequestsRPC = (
  apiClient: APIClient,
): GetMFConfirmationRequestsRPC => {
  if (MOCK.mf) {
    return new MockGetMFConfirmationRequestsRPCImpl();
  } else {
    return new GetMFConfirmationRequestsRPCClientImpl(apiClient);
  }
};

export const useValidateMFConfirmationRequestRPC = (
  apiClient: APIClient,
): ValidateMFConfirmationRequestRPC => {
  if (MOCK.mf) {
    return new MockValidateMFConfirmationRequestRPCImpl();
  } else {
    return new ValidateMFConfirmationRequestRPCClientImpl(apiClient);
  }
};

export const useSubmitMFConfirmationRequestRPC = (
  apiClient: APIClient,
): SubmitMFConfirmationRequestRPC => {
  if (MOCK.mf) {
    return new MockSubmitMFConfirmationRequestRPCImpl();
  } else {
    return new SubmitMFConfirmationRequestRPCClientImpl(apiClient);
  }
};

export const useGetMFAmcListRPC = (apiClient: APIClient): GetMFAmcListRPC => {
  if (MOCK.mf) {
    return new MockGetMFAmcListRPCImpl();
  } else {
    return new GetMFAmcListRPCClientImpl(apiClient);
  }
};

export const useGetMFConfirmationRequestDetailsRPC = (
  apiClient: APIClient,
): GetMFConfirmationRequestDetailsRPC => {
  if (MOCK.mf) {
    return new MockGetMFConfirmationRequestDetailsRPCImpl();
  } else {
    return new GetMFConfirmationRequestDetailsRPCClientImpl(apiClient);
  }
};

export const useGetMFConfirmationHistoryRPC = (
  apiClient: APIClient,
): GetMFConfirmationHistoryRPC => {
  if (MOCK.mf) {
    return new MockGetMFConfirmationHistoryRPCImpl();
  } else {
    return new GetMFConfirmationHistoryRPCClientImpl(apiClient);
  }
};

export const useSubmitMFConfirmationUnknownRequestRPC = (
  apiClient: APIClient,
): SubmitMFConfirmationUnknownRequestRPC => {
  if (MOCK.mf) {
    return new MockSubmitMFConfirmationUnknownRequestRPCImpl();
  } else {
    return new SubmitMFConfirmationUnknownRequestRPCClientImpl(apiClient);
  }
};

export const useCheckMfConfirmationRequestRPC = (
  apiClient: APIClient,
): CheckMfConfirmationRequestRPC => {
  if (MOCK.mf) {
    return new MockCheckMfConfirmationRequestRPCImpl();
  } else {
    return new CheckMfConfirmationRequestRPCClientImpl(apiClient);
  }
};

export const useCheckMFFolioCreationRequestRPC = (
  apiClient: APIClient,
): CheckMFFolioCreationRequestRPC => {
  if (MOCK.mf) {
    return new MockCheckMFFolioCreationRequestRPCImpl();
  } else {
    return new CheckMFFolioCreationRequestRPCClientImpl(apiClient);
  }
};

export const useGetMFApprovedDealRequestsRPC = (
  apiClient: APIClient,
): GetMFApprovedDealRequestsRPC => {
  if (MOCK.mf) {
    return new MockGetMFApprovedDealRequestsRPCImpl();
  } else {
    return new GetMFApprovedDealRequestsRPCClientImpl(apiClient);
  }
};

export const useSubmitMFConfirmationLinkRequestRPC = (
  apiClient: APIClient,
): SubmitMFConfirmationLinkRequestRPC => {
  if (MOCK.mf) {
    return new MockSubmitMFConfirmationLinkRequestRPCImpl();
  } else {
    return new SubmitMFConfirmationLinkRequestRPCClientImpl(apiClient);
  }
};
