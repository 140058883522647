"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFSchemeListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mFScheme_1 = require("./mFScheme");
const mFTransactionType_1 = require("./mFTransactionType");
/* eslint-disable import/export */
class GetMFSchemeListRPC {
}
exports.GetMFSchemeListRPC = GetMFSchemeListRPC;
(function (GetMFSchemeListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFSchemeListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(searchText, transactionType, amcId = null) {
            this.isGetMFSchemeListRPCRequest = true;
            Request.validateSearchText(searchText);
            this.searchText = searchText;
            Request.validateTransactionType(transactionType);
            this.transactionType = transactionType;
            if (amcId !== undefined && amcId !== null) {
                Request.validateAmcId(amcId);
            }
            this.amcId = amcId;
        }
        static fromDTO(dto) {
            const searchText = (0, leo_ts_runtime_1.getString)(dto, "searchText", InvalidRequestError);
            const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidRequestError));
            const amcId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amcId", InvalidRequestError);
            return new Request(searchText, transactionType, amcId);
        }
        toDTO() {
            const ret = {
                searchText: this.searchText,
                transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
            };
            if (this.amcId !== undefined && this.amcId !== null) {
                ret.amcId = this.amcId;
            }
            return ret;
        }
        copy(searchText = this.searchText, transactionType = this.transactionType, amcId = this.amcId) {
            return new Request(searchText, transactionType, amcId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFSchemeListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.searchText !== other.searchText) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.amcId !== other.amcId) {
                return false;
            }
            return true;
        }
    }
    Request.validateSearchText = (searchText) => {
        if (!(0, leo_ts_runtime_1.isString)(searchText)) {
            throw new InvalidRequestError(`Attribute searchText is not a String`);
        }
        if (searchText.length < 3) {
            throw new InvalidRequestError(`Attribute searchText is too short. Size is ${searchText.length}. Min size is 3.`);
        }
        if (searchText.trim().length === 0) {
            throw new InvalidRequestError(`Attribute searchText cannot be an empty string.`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
        }
    };
    Request.validateAmcId = (amcId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(amcId)) {
            throw new InvalidRequestError(`Attribute amcId is not an Int64`);
        }
    };
    GetMFSchemeListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFSchemeListRPC.Request(searchText='${this.searchText}',transactionType=${this.transactionType},amcId=${this.amcId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFSchemeListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(schemes) {
            this.isGetMFSchemeListRPCResponse = true;
            Response.validateSchemes(schemes);
            this.schemes = schemes;
        }
        static fromDTO(dto) {
            const schemes = (0, leo_ts_runtime_1.getList)(dto, "schemes", mFScheme_1.MFScheme.fromDTO, InvalidResponseError);
            return new Response(schemes);
        }
        toDTO() {
            const ret = {
                schemes: this.schemes.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(schemes = this.schemes) {
            return new Response(schemes);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFSchemeListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.schemes, other.schemes)) {
                return false;
            }
            return true;
        }
    }
    Response.validateSchemes = (schemes) => {
        if (!Array.isArray(schemes)) {
            throw new InvalidResponseError(`Attribute schemes is not a List`);
        }
        for (let i = 0; i < schemes.length; i += 1) {
            const isMFSchemeValue = schemes[i].isMFScheme;
            if (!(schemes[i] instanceof mFScheme_1.MFScheme || Boolean(isMFSchemeValue))) {
                throw new InvalidResponseError(`Attribute schemes at index ${i} is not a MFScheme.`);
            }
        }
    };
    GetMFSchemeListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFSchemeListRPC.Response(schemes=${this.schemes})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetMFSchemeListRPC.Errors || (GetMFSchemeListRPC.Errors = {}));
})(GetMFSchemeListRPC = exports.GetMFSchemeListRPC || (exports.GetMFSchemeListRPC = {}));
