import { Stack, Typography } from "@mui/material";
import {
  Dialog,
  LoadingIndicator,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import { getFormattedAmountString } from "../../../../../utils";
import { observer } from "mobx-react";
import { SettleEquityDealRequestStore } from "../store/SettleEquityDealRequestStore";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import {
  SettleEquityDealInvalidRequestError,
  ViewEquityDealInvalidRequestError,
} from "../store/ViewEquityDealRequestDetailsError";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getPath } from "../../../../../utils/RoutesUtils";
import { useBorder } from "../../../../../utils/BorderUtils";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { EquityTransactionType } from "@khazana/khazana-rpcs";

interface SettleEquityDealRequestDialogProps {
  isSettleEquityDealRequestDialogOpen: boolean;
  currencySymbol: string | null;
  store: Instance<typeof SettleEquityDealRequestStore>;
  onSuccessfulSettleDeal: () => void;
  onClose: () => void;
  onError: () => void;
  fetchDealRequestDetails: () => void;
  transactionType: EquityTransactionType.EquityTransactionType;
}

const Size = {
  styledTypography: {
    container: "520px",
    header1: "200px",
    header2: "312px",
  },
  settleDealMessage: "496px",
  dialogWidth: "560px",
  content: "520px",
  dropdown: "320px",
};

export const SettleEquityDealRequestDialog = observer(
  ({
    isSettleEquityDealRequestDialogOpen,
    currencySymbol,
    store,
    onSuccessfulSettleDeal,
    onClose,
    onError,
    fetchDealRequestDetails,
    transactionType,
  }: SettleEquityDealRequestDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const border = useBorder();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const [searchParam] = useSearchParams();
    const requestId = searchParam.get("requestId");
    const [isDropdownError, setIsDropdownError] = useState(false);
    const navigate = useNavigate();
    const [isSettleDialogOpen, setIsSettleDialogOpen] = useState(
      isSettleEquityDealRequestDialogOpen,
    );
    const [isScreenBlockingLoading, setIsScreenBlockingLoading] =
      useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
    const settlementAmountDetails =
      store.settlementDetails?.settlementAmountDetails;
    const settlementQuantityDetails =
      store.settlementDetails?.settlementQuantityDetails;

    interface GetStyledTypographyProps {
      text: string;
      amountQuantity: string;
    }

    const getErrorDialogText = (): string => {
      switch (store.error) {
        case ViewEquityDealInvalidRequestError.InvalidRequestId:
          return t("common.noResultsFound");
        case SettleEquityDealInvalidRequestError.CanOnlySettleSelfRequest:
          return t("common.somethingWentWrong");
        case SettleEquityDealInvalidRequestError.DealAlreadyCancelled:
          return t("equity.dealRequestDetails.requestAlreadyCancelled");
        case SettleEquityDealInvalidRequestError.DealAlreadyExpired:
          return t("equity.dealRequestDetails.requestAlreadyExpired");
        case SettleEquityDealInvalidRequestError.DealAlreadySettled:
          return t("equity.dealRequestDetails.dealAlreadySettled");
        case SettleEquityDealInvalidRequestError.DealNotApproved:
          return t("equity.dealRequestDetails.dealNotApproved");
        case SettleEquityDealInvalidRequestError.InvalidPortfolio:
          return t("common.somethingWentWrong");
        case SettleEquityDealInvalidRequestError.NoBrokersLinked:
          return t("equity.dealRequestDetails.noBrokersLinked");
        default:
          return t("common.somethingWentWrong");
      }
    };

    const GetStyledTypography = ({
      text,
      amountQuantity,
    }: GetStyledTypographyProps): React.ReactElement => {
      return (
        <Stack
          sx={{
            flexDirection: "row",
            width: Size.styledTypography.container,
            borderBottom: border.default,
          }}
        >
          <Typography
            sx={{
              ...typography.s1,
              width: Size.styledTypography.header1,
              padding: `${spacing.spaceMD} 0`,
              color: tokens.label,
            }}
          >
            {text}
          </Typography>
          <Typography
            sx={{
              ...typography.b1,
              width: Size.styledTypography.header2,
              padding: `${spacing.spaceMD} ${spacing.spaceSM}`,
              textAlign: "right",
            }}
          >
            {amountQuantity}
          </Typography>
        </Stack>
      );
    };

    const getRequestedValue = (): string => {
      let requestedValue;
      let decimalPlaces = 0;
      if (settlementAmountDetails?.settlementBuyAmountDetailsModel) {
        requestedValue =
          settlementAmountDetails?.settlementBuyAmountDetailsModel
            .requestedAmount.amount;
        decimalPlaces = 2;
      } else if (settlementAmountDetails?.settlementSellAmountDetailsModel) {
        requestedValue =
          settlementAmountDetails?.settlementSellAmountDetailsModel
            .requestedAmount.amount;
        decimalPlaces = 2;
      } else if (settlementQuantityDetails?.settlementBuyQuantityDetailsModel) {
        requestedValue =
          settlementQuantityDetails.settlementBuyQuantityDetailsModel
            .requestedQuantity;
      } else if (
        settlementQuantityDetails?.settlementSellQuantityDetailsModel
      ) {
        requestedValue =
          settlementQuantityDetails.settlementSellQuantityDetailsModel
            .requestedQuantity;
      }
      if (requestedValue) {
        return getFormattedAmountString(
          requestedValue,
          decimalPlaces,
          decimalPlaces,
        );
      }
      console.error("Neither Requested Amount nor Requested Quantity found");
      return "-";
    };

    const getExecutedValue = (): string => {
      let executedValue;
      let decimalPlaces = 0;
      if (settlementAmountDetails?.settlementBuyAmountDetailsModel) {
        executedValue =
          settlementAmountDetails?.settlementBuyAmountDetailsModel
            .executedAmount.amount;
        decimalPlaces = 2;
      } else if (settlementAmountDetails?.settlementSellAmountDetailsModel) {
        executedValue =
          settlementAmountDetails?.settlementSellAmountDetailsModel
            .soldQuantity;
      } else if (settlementQuantityDetails?.settlementBuyQuantityDetailsModel) {
        executedValue =
          settlementQuantityDetails.settlementBuyQuantityDetailsModel
            .executedUnits;
      } else if (
        settlementQuantityDetails?.settlementSellQuantityDetailsModel
      ) {
        executedValue =
          settlementQuantityDetails.settlementSellQuantityDetailsModel
            .soldQuantity;
      }
      if (executedValue) {
        return getFormattedAmountString(
          executedValue,
          decimalPlaces,
          decimalPlaces,
        );
      }
      console.error("Neither Executed Amount nor Executed Quantity found");
      return "-";
    };

    const getExecutedText = (): string => {
      if (settlementAmountDetails?.settlementBuyAmountDetailsModel) {
        return t("equity.dealRequestDetails.executedAmount");
      } else if (settlementQuantityDetails?.settlementBuyQuantityDetailsModel) {
        return t("equity.dealRequestDetails.executedQuantity");
      } else {
        return t("equity.dealRequestDetails.soldQuantity");
      }
    };

    const getPendingValue = (): string => {
      let pendingValue;
      let decimalPlaces = 0;
      if (settlementAmountDetails?.settlementBuyAmountDetailsModel) {
        pendingValue =
          settlementAmountDetails?.settlementBuyAmountDetailsModel.pendingAmount
            ?.amount;
        decimalPlaces = 2;
      } else if (settlementAmountDetails?.settlementSellAmountDetailsModel) {
        pendingValue =
          settlementAmountDetails?.settlementSellAmountDetailsModel
            .unsoldQuantity;
      } else if (settlementQuantityDetails?.settlementBuyQuantityDetailsModel) {
        pendingValue =
          settlementQuantityDetails.settlementBuyQuantityDetailsModel
            .pendingUnits;
      } else if (
        settlementQuantityDetails?.settlementSellQuantityDetailsModel
      ) {
        pendingValue =
          settlementQuantityDetails.settlementSellQuantityDetailsModel
            .unsoldQuantity;
      }
      if (pendingValue) {
        return getFormattedAmountString(
          pendingValue,
          decimalPlaces,
          decimalPlaces,
        );
      }
      return "0";
    };

    const getPendingText = (): string => {
      if (settlementAmountDetails?.settlementBuyAmountDetailsModel) {
        return t("equity.dealRequestDetails.pendingAmount");
      } else if (settlementQuantityDetails?.settlementBuyQuantityDetailsModel) {
        return t("equity.dealRequestDetails.pendingQuantity");
      } else {
        return t("equity.dealRequestDetails.unsoldQuantity");
      }
    };

    const getSettleDealMessage = (): string => {
      if (
        settlementAmountDetails?.settlementBuyAmountDetailsModel ??
        settlementQuantityDetails?.settlementBuyQuantityDetailsModel
      ) {
        return t(
          "equity.dealRequestDetails.settlingDealRequestWillUnblockPendingAmount",
        );
      } else {
        return t(
          "equity.dealRequestDetails.settlingDealRequestReleaseRemainingQuantity",
        );
      }
    };

    const getSettlementDetails = (): React.ReactElement => {
      return (
        <Stack sx={{ marginBottom: spacing.space2XL }}>
          <Typography
            sx={{
              ...typography.s1,
              paddingBottom: spacing.spaceXS,
              borderBottom: border.default,
            }}
          >
            {t("common.settlementDetailsForDealRequest", {
              val: currencySymbol,
            })}
          </Typography>
          <GetStyledTypography
            text={
              settlementQuantityDetails === null
                ? t("equity.dealRequestDetails.requestedAmount")
                : t("equity.dealRequestDetails.requestedQuantity")
            }
            amountQuantity={getRequestedValue()}
          />
          {settlementAmountDetails?.settlementSellAmountDetailsModel
            ?.approxQuantityRequested && (
            <GetStyledTypography
              text={t("equity.dealRequestDetails.approxQuantityRequired")}
              amountQuantity={getFormattedAmountString(
                settlementAmountDetails?.settlementSellAmountDetailsModel
                  ?.approxQuantityRequested,
              )}
            />
          )}
          <GetStyledTypography
            text={getExecutedText()}
            amountQuantity={getExecutedValue()}
          />
          <GetStyledTypography
            text={getPendingText()}
            amountQuantity={getPendingValue()}
          />
        </Stack>
      );
    };

    const getSettlementPriceDetails = (): React.ReactElement => {
      return (
        <Stack sx={{ marginBottom: spacing.space2XL }}>
          <Typography
            sx={{
              ...typography.s1,
              paddingBottom: spacing.spaceXS,
              borderBottom: border.default,
            }}
          >
            {`${t("equity.dealRequestDetails.settlementPriceDetailsTitle", {
              val: currencySymbol,
            })}`}
          </Typography>
          {store.settlementDetails?.settlementPriceDetails
            .requestedPricePerUnit && (
            <GetStyledTypography
              text={
                transactionType ===
                EquityTransactionType.EquityTransactionType.BUY
                  ? t("equity.dealRequestDetails.requestedMaxPriceLabel")
                  : t("equity.dealRequestDetails.requestedMinPriceLabel")
              }
              amountQuantity={getFormattedAmountString(
                store.settlementDetails.settlementPriceDetails
                  .requestedPricePerUnit.amount,
                2,
                2,
              )}
            />
          )}
          {store.settlementDetails?.settlementPriceDetails
            .executedPricePerUnit && (
            <GetStyledTypography
              text={t("equity.dealRequestDetails.executedPriceLabel")}
              amountQuantity={getFormattedAmountString(
                store.settlementDetails.settlementPriceDetails
                  .executedPricePerUnit.amount,
                2,
                2,
              )}
            />
          )}
        </Stack>
      );
    };

    return (
      <>
        <LoadingIndicator isLoading={isScreenBlockingLoading} />
        <ErrorDialog
          errorMessage={getErrorDialogText()}
          isErrorDialogOpen={isErrorDialogOpen}
          onClose={(): void => {
            if (
              store.error === ViewEquityDealInvalidRequestError.InvalidRequestId
            ) {
              onClose();
              navigate(getPath(Module.Equity, Route.ViewDealRequest));
            } else if (
              store.error ===
                SettleEquityDealInvalidRequestError.CanOnlySettleSelfRequest ||
              store.error ===
                SettleEquityDealInvalidRequestError.DealAlreadyCancelled ||
              store.error ===
                SettleEquityDealInvalidRequestError.DealAlreadyExpired ||
              store.error ===
                SettleEquityDealInvalidRequestError.DealAlreadySettled ||
              store.error ===
                SettleEquityDealInvalidRequestError.DealNotApproved
            ) {
              fetchDealRequestDetails();
            }
            store.resetStore();
            setIsErrorDialogOpen(false);
            onError();
          }}
        />
        <Dialog
          open={isSettleDialogOpen}
          title={t("equity.dealRequestDetails.settleDealRequest")}
          primaryButtonText={t("equity.dealRequestDetails.settleDeal")}
          isCloseIconPresent={isCloseIconPresent}
          onClose={(): void => {
            setIsSettleDialogOpen(false);
            onClose();
          }}
          onPrimaryButtonClick={async (): Promise<void> => {
            if (
              store.portfolioList &&
              store.portfolioList.length > 0 &&
              store.selectedPortfolio === null
            ) {
              setIsDropdownError(true);
              return;
            }
            setIsScreenBlockingLoading(true);
            if (requestId && !isDropdownError) {
              setIsCloseIconPresent(false);
              await store.settleDealRequest(requestId);
              setIsCloseIconPresent(true);
            } else {
              console.error("No requestId found to settle deal request");
            }
            setIsSettleDialogOpen(false);
            if (store.error === null) {
              onSuccessfulSettleDeal();
            } else {
              setIsErrorDialogOpen(true);
            }
            setIsScreenBlockingLoading(false);
          }}
          disableBackdropClick={true}
          dialogWidth={Size.dialogWidth}
          style={{
            borderRadius: cornerRadius.radiusXXS,
            flexDirection: "column",
          }}
        >
          <Stack>
            <Typography
              sx={{
                ...typography.b1,
                color: tokens.labelInfo,
                background: tokens.backgroundInfoSubtle,
                padding: `${spacing.spaceXS} ${spacing.spaceMD}`,
                width: Size.settleDealMessage,
                borderRadius: cornerRadius.radiusXXS,
                border: border.infoSubtle,
                marginBottom: spacing.spaceLG,
              }}
            >
              {getSettleDealMessage()}
            </Typography>
            {store.portfolioList && store.portfolioList.length > 0 && (
              <Stack
                sx={{
                  width: Size.content,
                  gap: spacing.spaceSM,
                  marginBottom: spacing.space2XL,
                }}
              >
                <Typography
                  sx={{
                    ...typography.s1,
                    paddingBottom: spacing.spaceXS,
                    borderBottom: border.default,
                  }}
                >
                  {t("common.selectPortfolio")}
                </Typography>
                <DropdownInputFieldSeparateLabel
                  name={"portfolio"}
                  isRequired={true}
                  error={isDropdownError}
                  helperText={
                    isDropdownError
                      ? t("equity.dealRequestDetails.portfolioIsRequired")
                      : undefined
                  }
                  helperTextColor={tokens.labelError}
                  label={t("common.portfolio")}
                  placeholder={t("common.selectPortfolio")}
                  options={store.portfolioList.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                  onSelect={(value): void => {
                    setIsDropdownError(false);
                    store.setSelectedPortfolio(value);
                  }}
                  value={
                    store.selectedPortfolio
                      ? store.selectedPortfolio.id
                      : undefined
                  }
                  style={{ width: Size.dropdown }}
                />
              </Stack>
            )}
            {getSettlementDetails()}
            {getSettlementPriceDetails()}
            <NoteTextArea
              note={store.note}
              setNote={store.setSettleNote}
              style={{ width: Size.content }}
            />
          </Stack>
        </Dialog>
      </>
    );
  },
);
