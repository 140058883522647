import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../../models/CurrencyModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  GetMFDealRequestSettlementDetailsRPC,
  MFSettlementDetails,
  MFTransactionType,
  Note,
  SettleMFDealRequestRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useGetMFDealRequestSettlementDetailsRPC,
  useSettleMFDealRequestRPC,
} from "../../rpc/RPC";
import { mfDealDetailsErrorStore } from "./hooks";
import { SettleRequestDetailRow } from "../../../../components/dialog/SettleRequestDialog";
import i18next from "i18next";
import { MFTransactionTypeModel } from "../../models/MFTransactionTypeModel";
import { BannerSeverity } from "@surya-digital/leo-reactjs-material-ui";

export const DealRequestSettleStore = types
  .model("DealRequestSettleStore", {
    requestedUnits: types.maybe(types.string),
    executedUnits: types.maybe(types.string),
    pendingUnits: types.maybe(types.string),
    requestedAmount: types.maybe(types.string),
    executedAmount: types.maybe(types.string),
    pendingAmount: types.maybe(types.string),
    currency: types.maybe(CurrencyModel),
    transactionType: types.maybe(MFTransactionTypeModel),
    isAmountExcess: types.optional(types.boolean, false),
    isUnitsExcess: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFDealRequestSettlementDetails: flow(function* (
        requestId: number,
        transactionType: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFDealRequestSettlementDetailsRPC.Request(
          requestId,
        );
        const result: LeoRPCResult<
          GetMFDealRequestSettlementDetailsRPC.Response,
          GetMFDealRequestSettlementDetailsRPC.Errors.Errors
        > =
          yield useGetMFDealRequestSettlementDetailsRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          if (
            response.settlementDetails instanceof
            MFSettlementDetails.AmountSettlementDetail
          ) {
            store.requestedAmount =
              response.settlementDetails.requestedAmount.decimalValue;
            store.executedAmount =
              response.settlementDetails.executedAmount.decimalValue;
            store.pendingAmount =
              response.settlementDetails.pendingAmount.decimalValue;
            store.currency = createCurrencyModel(
              response.settlementDetails.currency,
            );
            store.isAmountExcess = response.settlementDetails.isAmountExcess;
          } else if (
            response.settlementDetails instanceof
            MFSettlementDetails.UnitsSettlementDetail
          ) {
            store.requestedUnits =
              response.settlementDetails.requestedUnits.decimalValue;
            store.executedUnits =
              response.settlementDetails.executedUnits.decimalValue;
            store.pendingUnits =
              response.settlementDetails.pendingUnits.decimalValue;
            store.isUnitsExcess = response.settlementDetails.isUnitsExcess;
          }
          store.transactionType = MFTransactionType.fromDTO({
            case: transactionType,
          });
        } else if (result instanceof LeoRPCResult.Error) {
          const errorStore = mfDealDetailsErrorStore(store);
          errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in getMFDealRequestSettlementDetails");
        }
      }),
      settleMFDealRequest: flow(function* (
        dealRequestId: number,
        note?: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new SettleMFDealRequestRPC.Request(
          dealRequestId,
          note ? new Note(note) : null,
        );
        const result: LeoRPCResult<
          SettleMFDealRequestRPC.Response,
          SettleMFDealRequestRPC.Errors.Errors
        > = yield useSettleMFDealRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const errorStore = mfDealDetailsErrorStore(store);
          errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in settleMFDealRequest");
        }
      }),
    };
  })
  .views((store) => {
    return {
      get settleDealBannerInfo(): string {
        if (store.isAmountExcess) {
          return i18next.t(
            "mf.dealDetails.settleDealAmountExcessBannerMessage",
          );
        } else if (store.isUnitsExcess) {
          return i18next.t("mf.dealDetails.settleDealUnitsExcessBannerMessage");
        } else if (
          store.transactionType ===
          MFTransactionType.MFTransactionType.MF_REDEEM
        ) {
          return i18next.t("mf.dealDetails.settleDealRedeemBannerMessage");
        } else {
          return i18next.t("mf.dealDetails.settleDealPurchaseBannerMessage");
        }
      },
      get bannerSeverity(): BannerSeverity {
        if (store.isAmountExcess || store.isUnitsExcess) {
          return "warning";
        } else {
          return "info";
        }
      },
      get settleRequestDetailRows(): SettleRequestDetailRow[] {
        if (
          store.transactionType ===
          MFTransactionType.MFTransactionType.MF_REDEEM
        ) {
          return [
            {
              label: i18next.t("mf.fields.approxUnitsRequestedLabel"),
              value: i18next.t("common.decimal4", {
                val: store.requestedUnits,
              }),
            },
            {
              label: i18next.t("mf.fields.redeemedUnitsLabel"),
              value: i18next.t("common.decimal2", { val: store.executedUnits }),
            },
            {
              label: i18next.t("mf.fields.UnredeemedUnitsLabel"),
              value: i18next.t("common.decimal2", { val: store.pendingUnits }),
            },
          ];
        } else {
          return [
            {
              label: i18next.t("common.requestedAmount"),
              value: i18next.t("common.decimal2", {
                val: store.requestedAmount,
              }),
            },
            {
              label: i18next.t("common.executedAmount"),
              value: i18next.t("common.decimal2", {
                val: store.executedAmount,
              }),
            },
            {
              label: i18next.t("common.pendingAmount"),
              value: i18next.t("common.decimal2", { val: store.pendingAmount }),
            },
          ];
        }
      },
    };
  });

export const createDealRequestSettleStore = (): Instance<
  typeof DealRequestSettleStore
> => {
  return DealRequestSettleStore.create({});
};
