"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFAmc = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidMFAmcError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFAmcError";
    }
}
class MFAmc {
    constructor(id, name) {
        this.isMFAmc = true;
        MFAmc.validateId(id);
        this.id = id;
        MFAmc.validateName(name);
        this.name = name;
    }
    static fromDTO(dto) {
        const id = (0, leo_ts_runtime_1.getInt64)(dto, "id", InvalidMFAmcError);
        const name = (0, leo_ts_runtime_1.getString)(dto, "name", InvalidMFAmcError);
        return new MFAmc(id, name);
    }
    toDTO() {
        const ret = {
            id: this.id,
            name: this.name,
        };
        return ret;
    }
    copy(id = this.id, name = this.name) {
        return new MFAmc(id, name);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFAmcValue = other.isMFAmc;
        if (!(other instanceof MFAmc) || Boolean(isMFAmcValue)) {
            return false;
        }
        if (this.id !== other.id) {
            return false;
        }
        if (this.name !== other.name) {
            return false;
        }
        return true;
    }
}
exports.MFAmc = MFAmc;
MFAmc.validateId = (id) => {
    if (!(0, leo_ts_runtime_1.isInt64)(id)) {
        throw new InvalidMFAmcError(`Attribute id is not an Int64`);
    }
};
MFAmc.validateName = (name) => {
    if (!(0, leo_ts_runtime_1.isString)(name)) {
        throw new InvalidMFAmcError(`Attribute name is not a String`);
    }
    if (name.trim().length === 0) {
        throw new InvalidMFAmcError(`Attribute name cannot be an empty string.`);
    }
};
MFAmc.prototype.toString = function toString() {
    return `MFAmc(id=${this.id},name='${this.name}')`;
};
