"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFHoldingSummaryReportRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getMFHoldingSummarySortOrder_1 = require("./getMFHoldingSummarySortOrder");
const mFHoldingSummaryResponse_1 = require("./mFHoldingSummaryResponse");
/* eslint-disable import/export */
class GetMFHoldingSummaryReportRPC {
}
exports.GetMFHoldingSummaryReportRPC = GetMFHoldingSummaryReportRPC;
(function (GetMFHoldingSummaryReportRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFHoldingSummaryReportRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId = null, portfolioId = null, asOnDate = null, pageIndex, itemsPerPage, sort) {
            this.isGetMFHoldingSummaryReportRPCRequest = true;
            if (entityId !== undefined && entityId !== null) {
                Request.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
            if (asOnDate !== undefined && asOnDate !== null) {
                Request.validateAsOnDate(asOnDate);
            }
            this.asOnDate = asOnDate;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validateSort(sort);
            this.sort = sort;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const asOnDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "asOnDate", InvalidRequestError);
            const pageIndex = (0, leo_ts_runtime_1.getInt32)(dto, "pageIndex", InvalidRequestError);
            const itemsPerPage = (0, leo_ts_runtime_1.getInt32)(dto, "itemsPerPage", InvalidRequestError);
            const sort = getMFHoldingSummarySortOrder_1.GetMFHoldingSummarySortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "sort", InvalidRequestError));
            return new Request(entityId, portfolioId, asOnDate, pageIndex, itemsPerPage, sort);
        }
        toDTO() {
            const ret = {
                pageIndex: this.pageIndex,
                itemsPerPage: this.itemsPerPage,
                sort: this.sort.toDTO(),
            };
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            if (this.asOnDate) {
                ret.asOnDate = this.asOnDate.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, asOnDate = this.asOnDate, pageIndex = this.pageIndex, itemsPerPage = this.itemsPerPage, sort = this.sort) {
            return new Request(entityId, portfolioId, asOnDate, pageIndex, itemsPerPage, sort);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFHoldingSummaryReportRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            if (this.asOnDate) {
                if (!this.asOnDate.equals(other.asOnDate)) {
                    return false;
                }
            }
            if (this.pageIndex !== other.pageIndex) {
                return false;
            }
            if (this.itemsPerPage !== other.itemsPerPage) {
                return false;
            }
            if (!this.sort.equals(other.sort)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateAsOnDate = (asOnDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(asOnDate)) {
            throw new InvalidRequestError(`Attribute asOnDate is not a Date`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        if (!(0, leo_ts_runtime_1.isInt32)(pageIndex)) {
            throw new InvalidRequestError(`Attribute pageIndex is not an Int32`);
        }
        if (pageIndex < 0) {
            throw new InvalidRequestError(`Attribute pageIndex has value ${pageIndex}. Min value is 0.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        if (!(0, leo_ts_runtime_1.isInt32)(itemsPerPage)) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not an Int32`);
        }
        if (itemsPerPage < 1) {
            throw new InvalidRequestError(`Attribute itemsPerPage has value ${itemsPerPage}. Min value is 1.`);
        }
    };
    Request.validateSort = (sort) => {
        const isGetMFHoldingSummarySortOrderValue = sort.isGetMFHoldingSummarySortOrder;
        if (!(sort instanceof getMFHoldingSummarySortOrder_1.GetMFHoldingSummarySortOrder || Boolean(isGetMFHoldingSummarySortOrderValue))) {
            throw new InvalidRequestError(`Attribute sort is not a GetMFHoldingSummarySortOrder`);
        }
    };
    GetMFHoldingSummaryReportRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFHoldingSummaryReportRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},asOnDate=${this.asOnDate},pageIndex=${this.pageIndex},itemsPerPage=${this.itemsPerPage},sort=${this.sort})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFHoldingSummaryReportRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(asOnDate, holdingSummaryResponse, totalItems) {
            this.isGetMFHoldingSummaryReportRPCResponse = true;
            Response.validateAsOnDate(asOnDate);
            this.asOnDate = asOnDate;
            Response.validateHoldingSummaryResponse(holdingSummaryResponse);
            this.holdingSummaryResponse = holdingSummaryResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const asOnDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "asOnDate", InvalidResponseError);
            const holdingSummaryResponse = (0, leo_ts_runtime_1.getList)(dto, "holdingSummaryResponse", mFHoldingSummaryResponse_1.MFHoldingSummaryResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(asOnDate, holdingSummaryResponse, totalItems);
        }
        toDTO() {
            const ret = {
                asOnDate: this.asOnDate.toDTO(),
                holdingSummaryResponse: this.holdingSummaryResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(asOnDate = this.asOnDate, holdingSummaryResponse = this.holdingSummaryResponse, totalItems = this.totalItems) {
            return new Response(asOnDate, holdingSummaryResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFHoldingSummaryReportRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.asOnDate.equals(other.asOnDate)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.holdingSummaryResponse, other.holdingSummaryResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateAsOnDate = (asOnDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(asOnDate)) {
            throw new InvalidResponseError(`Attribute asOnDate is not a Date`);
        }
    };
    Response.validateHoldingSummaryResponse = (holdingSummaryResponse) => {
        if (!Array.isArray(holdingSummaryResponse)) {
            throw new InvalidResponseError(`Attribute holdingSummaryResponse is not a List`);
        }
        for (let i = 0; i < holdingSummaryResponse.length; i += 1) {
            const isMFHoldingSummaryResponseValue = holdingSummaryResponse[i].isMFHoldingSummaryResponse;
            if (!(holdingSummaryResponse[i] instanceof mFHoldingSummaryResponse_1.MFHoldingSummaryResponse || Boolean(isMFHoldingSummaryResponseValue))) {
                throw new InvalidResponseError(`Attribute holdingSummaryResponse at index ${i} is not a MFHoldingSummaryResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetMFHoldingSummaryReportRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFHoldingSummaryReportRPC.Response(asOnDate=${this.asOnDate},holdingSummaryResponse=${this.holdingSummaryResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY") {
                super(code);
                this.isInvalidEntity = true;
                InvalidEntity.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntity(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntity(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityValue = other.isInvalidEntity;
                if (!(other instanceof InvalidEntity || Boolean(isInvalidEntityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntity = InvalidEntity;
        InvalidEntity.prototype.toString = function toString() {
            return `GetMFHoldingSummaryReportRPC.INVALID_ENTITY()`;
        };
        class InvalidPortfolio extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO") {
                super(code);
                this.isInvalidPortfolio = true;
                InvalidPortfolio.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolio(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolio(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioValue = other.isInvalidPortfolio;
                if (!(other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolio.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolio = InvalidPortfolio;
        InvalidPortfolio.prototype.toString = function toString() {
            return `GetMFHoldingSummaryReportRPC.INVALID_PORTFOLIO()`;
        };
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetMFHoldingSummaryReportRPC.INVALID_PAGE_INDEX()`;
        };
        class InvalidDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DATE") {
                super(code);
                this.isInvalidDate = true;
                InvalidDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDateValue = other.isInvalidDate;
                if (!(other instanceof InvalidDate || Boolean(isInvalidDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDate = InvalidDate;
        InvalidDate.prototype.toString = function toString() {
            return `GetMFHoldingSummaryReportRPC.INVALID_DATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY":
                    return InvalidEntity.fromDTO(dto);
                case "INVALID_PORTFOLIO":
                    return InvalidPortfolio.fromDTO(dto);
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                case "INVALID_DATE":
                    return InvalidDate.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityValue = other.isInvalidEntity;
            if (other instanceof InvalidEntity || Boolean(isInvalidEntityValue)) {
                return true;
            }
            const isInvalidPortfolioValue = other.isInvalidPortfolio;
            if (other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue)) {
                return true;
            }
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            const isInvalidDateValue = other.isInvalidDate;
            if (other instanceof InvalidDate || Boolean(isInvalidDateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFHoldingSummaryReportRPC.Errors || (GetMFHoldingSummaryReportRPC.Errors = {}));
})(GetMFHoldingSummaryReportRPC = exports.GetMFHoldingSummaryReportRPC || (exports.GetMFHoldingSummaryReportRPC = {}));
