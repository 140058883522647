"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFOrderDetail = exports.InvalidMFOrderDetailError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
class InvalidMFOrderDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFOrderDetailError";
    }
}
exports.InvalidMFOrderDetailError = InvalidMFOrderDetailError;
var MFOrderDetail;
(function (MFOrderDetail) {
    class Amount {
        constructor(amount, date = null, currency = null) {
            this.isAmount = true;
            Amount.validateAmount(amount);
            this.amount = amount;
            if (date !== undefined && date !== null) {
                Amount.validateDate(date);
            }
            this.date = date;
            if (currency !== undefined && currency !== null) {
                Amount.validateCurrency(currency);
            }
            this.currency = currency;
        }
        static fromDTO(dto) {
            const amount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidMFOrderDetailError));
            const date = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "date", InvalidMFOrderDetailError);
            let currency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "currency");
            if (currency !== undefined && currency !== null) {
                currency = currency_1.Currency.fromDTO(currency);
            }
            return new Amount(amount, date, currency);
        }
        toDTO() {
            const ret = {
                case: "AMOUNT",
                amount: this.amount.toDTO(),
            };
            if (this.date) {
                ret.date = this.date.toDTO();
            }
            if (this.currency) {
                ret.currency = this.currency.toDTO();
            }
            return ret;
        }
        copy(amount = this.amount, date = this.date, currency = this.currency) {
            return new Amount(amount, date, currency);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAmountValue = other.isAmount;
            if (!(other instanceof Amount) || Boolean(isAmountValue)) {
                return false;
            }
            if (!this.amount.equals(other.amount)) {
                return false;
            }
            if (this.date) {
                if (!this.date.equals(other.date)) {
                    return false;
                }
            }
            if (this.currency) {
                if (!this.currency.equals(other.currency)) {
                    return false;
                }
            }
            return true;
        }
    }
    Amount.validateAmount = (amount) => {
        const isBigDecimalValue = amount.isBigDecimal;
        if (!(amount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFOrderDetailError(`Attribute amount is not a BigDecimal`);
        }
    };
    Amount.validateDate = (date) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(date)) {
            throw new InvalidMFOrderDetailError(`Attribute date is not a Date`);
        }
    };
    Amount.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidMFOrderDetailError(`Attribute currency is not a Currency`);
        }
    };
    MFOrderDetail.Amount = Amount;
    Amount.prototype.toString = function toString() {
        return `MFOrderDetail.AMOUNT(amount=${this.amount},date=${this.date},currency=${this.currency})`;
    };
    class Units {
        constructor(units, date = null) {
            this.isUnits = true;
            Units.validateUnits(units);
            this.units = units;
            if (date !== undefined && date !== null) {
                Units.validateDate(date);
            }
            this.date = date;
        }
        static fromDTO(dto) {
            const units = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "units", InvalidMFOrderDetailError));
            const date = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "date", InvalidMFOrderDetailError);
            return new Units(units, date);
        }
        toDTO() {
            const ret = {
                case: "UNITS",
                units: this.units.toDTO(),
            };
            if (this.date) {
                ret.date = this.date.toDTO();
            }
            return ret;
        }
        copy(units = this.units, date = this.date) {
            return new Units(units, date);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isUnitsValue = other.isUnits;
            if (!(other instanceof Units) || Boolean(isUnitsValue)) {
                return false;
            }
            if (!this.units.equals(other.units)) {
                return false;
            }
            if (this.date) {
                if (!this.date.equals(other.date)) {
                    return false;
                }
            }
            return true;
        }
    }
    Units.validateUnits = (units) => {
        const isBigDecimalValue = units.isBigDecimal;
        if (!(units instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidMFOrderDetailError(`Attribute units is not a BigDecimal`);
        }
    };
    Units.validateDate = (date) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(date)) {
            throw new InvalidMFOrderDetailError(`Attribute date is not a Date`);
        }
    };
    MFOrderDetail.Units = Units;
    Units.prototype.toString = function toString() {
        return `MFOrderDetail.UNITS(units=${this.units},date=${this.date})`;
    };
    MFOrderDetail.fromDTO = (dto) => {
        switch (dto.case) {
            case "AMOUNT":
                return Amount.fromDTO(dto);
            case "UNITS":
                return Units.fromDTO(dto);
            default:
                throw new InvalidMFOrderDetailError(`Case ${dto.case} is not valid case of MFOrderDetail.`);
        }
    };
    MFOrderDetail.isInstanceOf = (other) => {
        const isAMOUNTValue = other.isAMOUNT;
        if (other instanceof Amount || Boolean(isAMOUNTValue)) {
            return true;
        }
        const isUNITSValue = other.isUNITS;
        if (other instanceof Units || Boolean(isUNITSValue)) {
            return true;
        }
        return false;
    };
})(MFOrderDetail = exports.MFOrderDetail || (exports.MFOrderDetail = {}));
