import { observer } from "mobx-react";
import React, { useCallback, useEffect, useState } from "react";
import {
  AutoCompleteInputFieldSeparateLabel,
  PageHeader,
  TabProps,
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useMFCreateDealRequestStore } from "../store/createDealRequestStore/hooks";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import {
  getDividendTypeName,
  getPlanTypeName,
  getSchemeDropdownOptions,
  getSchemeTypeName,
} from "../utils/UIUtils";
import { debounce, Divider, Stack, Typography } from "@mui/material";
import { DEBOUNCE_DELAY, getFormattedAmount } from "../../../../../utils";
import { CreateDealContainer } from "../../../components/create-deal/CreateDealContainer";
import { CreateDealForm } from "../../../components/create-deal/CreateDealForm";
import {
  MFRedeemType,
  MFTransactionType,
  ModuleType,
} from "@khazana/khazana-rpcs";
import { AmountTextField } from "../../../components/AmountTextField";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import { EntityDropdown } from "../../../components/entity/EntityDropdown";
import { PortfolioDropdown } from "../../../components/portfolio/PortfolioDropdown";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { CreateMFDealError } from "../store/createDealRequestStore/MFCreateDealRequestErrorStore";
import { ImpactOnPortfolio } from "../../../components/ImpactOnPortfolio";
import { TransactionDetails } from "../../../components/create-deal/TransactionDetails";
import { KHToggleButton } from "../../../components/toggleButton/KHToggleButton";

const Size = {
  grossAmountWidth: "270px",
  unitsWidth: "152px",
  navWidth: "174px",
  entityWidth: "170px",
  portfolioWidth: "200px",
  folioNumberWidth: "290px",
  bankWidth: "336px",
  accountWidth: "340px",
  subLabelWidth: "130px",
  toggleButtonWidth: "231px",
};

export const CreateMFDealRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const store = useMFCreateDealRequestStore();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const [noOptionsText, setNoOptionsText] = useState(
    t("mf.fields.schemeNoOptionsPlaceholder"),
  );
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      store.reset();
    };
  }, []);

  const getDealRequestTabBackgroundColor = (): string => {
    switch (store.transactionType) {
      case MFTransactionType.MFTransactionType.MF_PURCHASE:
        return tokens.backgroundInfoSubtle;
      case MFTransactionType.MFTransactionType.MF_REDEEM:
        return tokens.backgroundWarningSubtle;
    }
  };

  const debounceSymbolSearch = useCallback(
    debounce(function (searchValue: string | null | undefined) {
      if (!searchValue || searchValue.length < 3) {
        if (!searchValue) {
          store.reset();
        }
        debounceSymbolSearch.clear();
        setNoOptionsText(t("mf.fields.schemeNoOptionsPlaceholder"));
        return;
      }
      store.schemeSearchStore.setIsLoading(true);
      store.schemeSearchStore
        .getMFSchemeList(searchValue, store.transactionType)
        .then(() => {
          if (!store.schemeSearchStore.schemeList.length) {
            setNoOptionsText(t("mf.fields.noSchemeFound"));
          }
        })
        .finally(() => {
          store.schemeSearchStore.setIsLoading(false);
        });
    }, DEBOUNCE_DELAY),
    [store.transactionType],
  );

  /* 'time' is declared in the global scope to persist the previous value.
    If we declare 'time' inside the debounce function then we'll have to initialise it with null,
    which will keep on resetting the value and the condition for debounce will fail.
*/
  let impactOnPortfolioTime: Date;
  let transactionDetailsTime: Date;

  const debouncedGetMFImpactOnPortfolio = useCallback(
    debounce(async () => {
      if (
        impactOnPortfolioTime &&
        new Date().getTime() - impactOnPortfolioTime.getTime() < DEBOUNCE_DELAY
      ) {
        return;
      }
      impactOnPortfolioTime = new Date();
      if (
        store.schemeSearchStore.selectedScheme?.isin &&
        store.entity.selectedEntity &&
        store.portfolio.selectedPortfolio &&
        ((store.grossAmount !== undefined && store.grossAmount !== "") ||
          (store.units !== undefined && store.units !== "")) &&
        store.approxNav
      ) {
        await store.dealImpactStore.getMFDealRequestImpact(
          store.schemeSearchStore.selectedScheme.isin,
          store.entity.selectedEntity,
          store.portfolio.selectedPortfolio.id,
          store.transactionType,
          store.grossAmount,
          store.units,
          store.approxNav,
          store.schemeSearchStore.selectedScheme.currency,
        );
      } else {
        store.dealImpactStore.reset();
      }
    }, DEBOUNCE_DELAY),
    [store.transactionType],
  );

  const debouncedGetMFTransactionDetails = useCallback(() => {
    debounce(async () => {
      if (
        transactionDetailsTime &&
        new Date().getTime() - transactionDetailsTime.getTime() < DEBOUNCE_DELAY
      ) {
        // Since `debouncedGetFiTransactionDetails` is used inside the useEffect hook, this method is called
        // every time  the gross amount, quantity or price input changes. Although, the `debounce` method waits until
        // the given time period(500ms) to execute the defined method, the method is executed as many times as it is triggered in
        // the `useEffect` hook after the interval of 500ms.
        // This condition prevents the unnecessary multiple triggering of the `store.getFiTransactionDetails` method.
        return;
      }
      transactionDetailsTime = new Date();
      if (store.schemeSearchStore.selectedScheme?.isin) {
        await store.dealRequestSummaryStore.getMFDealRequestSummary(
          store.schemeSearchStore.selectedScheme.isin,
          store.transactionType,
          store.grossAmount,
          store.units,
          store.approxNav,
          store.schemeSearchStore.selectedScheme.currency,
        );
      } else {
        store.dealRequestSummaryStore.reset();
      }
    }, DEBOUNCE_DELAY)();
  }, [store.transactionType]);

  useEffect(() => {
    debouncedGetMFTransactionDetails();
  }, [
    store.schemeSearchStore.selectedScheme,
    store.grossAmount,
    store.units,
    store.approxNav,
  ]);

  useEffect(() => {
    debouncedGetMFImpactOnPortfolio();
  }, [
    store.schemeSearchStore.selectedScheme?.isin,
    store.grossAmount,
    store.units,
    store.approxNav,
    store.entity.selectedEntity,
    store.portfolio.selectedPortfolio,
  ]);

  useEffect(() => {
    if (
      store.transactionType === MFTransactionType.MFTransactionType.MF_REDEEM &&
      store.redeemType === MFRedeemType.MFRedeemType.ALL
    ) {
      if (
        store.schemeSearchStore.selectedScheme?.isin &&
        store.entity.selectedEntity &&
        store.folioNumberStore.selectedFolio
      ) {
        store.getMFRedemptionUnits(
          store.schemeSearchStore.selectedScheme?.isin,
          store.entity.selectedEntity,
          store.folioNumberStore.selectedFolio,
        );
      }
    }
  }, [
    store.entity.selectedEntity,
    store.folioNumberStore.selectedFolio,
    store.redeemType,
  ]);

  const getSearchField = (): React.ReactElement => {
    return (
      <AsyncAutoCompleteInputFieldSeparateLabel
        key={store.transactionType}
        isRequired
        id="scheme"
        label={t("mf.fields.schemeLabel")}
        placeholder={t("mf.fields.schemePlaceholder")}
        options={getSchemeDropdownOptions(store.schemeSearchStore.schemeList)}
        onInputChange={(inputValue: string | null): void => {
          const searchText = inputValue?.trim();
          if (!searchText) {
            store.reset();
          }
          debounceSymbolSearch(searchText);
        }}
        onInputClear={(): void => {
          setNoOptionsText(t("mf.fields.schemeNoOptionsPlaceholder"));
          store.reset();
        }}
        onSelect={(value): void => {
          store.clearFields();
          if (typeof value !== "string") {
            store.schemeSearchStore.setSelectedScheme(value?.id);
            if (
              store.transactionType ===
              MFTransactionType.MFTransactionType.MF_PURCHASE
            ) {
              store.entity.getEntityList();
            } else {
              store.getSellEntityList();
            }
            store.bank.getBankList();
          } else {
            store.reset();
          }
        }}
        subLabelWidth={Size.subLabelWidth}
        isLoading={store.schemeSearchStore.isLoading}
        style={{
          backgroundColor: tokens.background,
        }}
        noOptionsText={noOptionsText}
      />
    );
  };

  const tabLabels: TabProps[] = [
    {
      label: t("mf.transactionType.purchaseLabel"),
    },
    {
      label: t("mf.transactionType.redeemLabel"),
    },
  ];

  const getFirstFormRow = (): React.ReactElement => {
    return (
      <Stack
        direction="row"
        spacing={spacing.spaceMD}
        key={store.schemeSearchStore.selectedScheme?.isin + store.redeemType}
      >
        <AmountTextField
          isRequired
          name="grossAmount"
          label={t("mf.fields.grossAmountLabel", {
            val: store.currencySymbol,
          })}
          error={Boolean(store.grossAmountError)}
          helperText={store.grossAmountHelperText}
          isDisabled={store.isFieldsDisabled || store.isAmountDisabled}
          value={store.grossAmount}
          onAmountChange={(value: string): void => {
            store.setGrossAmount(value);
          }}
          placeholder={t("dealRequest.enterGrossAmount")}
          isDecimalAllowed={true}
          style={{ width: Size.grossAmountWidth }}
        />
        <Stack
          sx={{
            // Note: !important is used because there is a CSS rule with margin: 0 with higher specificity.
            marginTop: "32px !important",
          }}
        >
          <Typography sx={{ ...typography.b1 }} color={tokens.labelSubtle}>
            {t("common.or")}
          </Typography>
        </Stack>
        <AmountTextField
          isRequired
          name="units"
          label={t("mf.fields.unitsLabel")}
          error={Boolean(store.unitsError)}
          helperText={store.unitsHelperText}
          isDisabled={store.isFieldsDisabled || store.isUnitsDisabled}
          value={store.units}
          onAmountChange={store.setUnits}
          placeholder={t("mf.fields.unitsPlaceholder")}
          isDecimalAllowed={true}
          style={{ width: Size.unitsWidth }}
        />
        <Stack>
          <Divider
            sx={{
              width: "16px",
              borderColor: tokens.border,
              // Note: !important is used because there is a CSS rule with margin: 0 with higher specificity.
              marginTop: "48px !important",
            }}
          />
        </Stack>
        <AmountTextField
          isRequired
          name="nav"
          label={t("mf.fields.approxNavLabel", { val: store.currencySymbol })}
          error={Boolean(store.approxNavError)}
          helperText={store.approxNavError}
          isDisabled={store.isFieldsDisabled}
          value={store.approxNav}
          onAmountChange={store.setApproxNav}
          placeholder={t("mf.fields.approxNavPlaceholder")}
          isDecimalAllowed={true}
          style={{ width: Size.navWidth }}
        />
      </Stack>
    );
  };

  const getSecondFormRow = (): React.ReactElement => {
    return (
      <Stack
        direction="row"
        spacing={spacing.spaceMD}
        key={store.schemeSearchStore.selectedScheme?.isin}
      >
        <EntityDropdown
          isRequired={true}
          store={store.entity}
          isDisabled={store.isFieldsDisabled}
          error={store.entity.error}
          onChange={(): void => {
            store.portfolio.deselectPortfolio();
            store.portfolio.clearList();
            store.folioNumberStore.reset();
            store.folioNumberStore.setSelectedFolio(undefined);
            if (MFTransactionType.MFTransactionType.MF_PURCHASE) {
              store.portfolio.getPortfolioList(
                store.entity.selectedEntity ?? "",
                ModuleType.ModuleType.MUTUAL_FUND,
              );
              store.folioNumberStore.getMFFolioNumbers(
                store.entity.selectedEntity ?? "",
                store.schemeSearchStore.selectedScheme?.amcId ?? 0,
              );
            } else {
              store.getSellPortfolioList(store.entity.selectedEntity ?? "");
              store.folioNumberStore.getMFFolioNumbers(
                store.entity.selectedEntity ?? "",
                store.schemeSearchStore.selectedScheme?.amcId ?? 0,
                store.schemeSearchStore.selectedScheme?.isin,
              );
            }
          }}
          width={Size.entityWidth}
        />
        <PortfolioDropdown
          isRequired={true}
          store={store.portfolio}
          error={store.portfolio.error}
          isDisabled={
            store.isFieldsDisabled || store.entity.selectedEntity === undefined
          }
          width={Size.portfolioWidth}
        />
        <AutoCompleteInputFieldSeparateLabel
          id="folioNumber"
          isDisabled={
            store.isFieldsDisabled || store.entity.selectedEntity === undefined
          }
          value={
            store.folioNumberStore.selectedFolio
              ? {
                  id: store.folioNumberStore.selectedFolio,
                  label: store.folioNumberStore.selectedFolio,
                }
              : undefined
          }
          onSelect={(value): void => {
            if (typeof value !== "string") {
              store.folioNumberStore.setSelectedFolio(value?.id ?? undefined);
            }
          }}
          isRequired={
            store.transactionType ===
            MFTransactionType.MFTransactionType.MF_REDEEM
          }
          label={t("mf.fields.folioNumberLabel")}
          placeholder={t("mf.fields.folioNumberPlaceholder")}
          options={store.folioNumberStore.folioList.map((folioNumber) => ({
            id: folioNumber,
            label: folioNumber,
          }))}
          error={Boolean(store.folioNumberStore.error)}
          helperText={store.folioNumberStore.error}
          loadingText={t("common.loading")}
          isLoading={store.folioNumberStore.isLoading}
          style={{ width: Size.folioNumberWidth }}
          noOptionsText={t("mf.fields.noFolioNumberFoundMessage")}
        />
      </Stack>
    );
  };

  const getThirdFormRow = (): React.ReactElement => {
    const currency = store.schemeSearchStore.selectedScheme?.currency;
    let currencySymbol;
    if (currency) {
      currencySymbol = `(${currency.symbol})`;
    }
    const selectedBank = store.bank.bankList.find(
      (bank) => bank.id === store.bank.selectedBankId,
    );
    const selectedAccount =
      store.bankAccount.selectedBankAccount?.bankAccountNumber;
    return (
      <Stack
        direction="row"
        spacing={spacing.spaceMD}
        key={store.schemeSearchStore.selectedScheme?.isin}
      >
        <AutoCompleteInputFieldSeparateLabel
          id={"bank"}
          label={t("mf.fields.paymentBankNameLabel")}
          placeholder={t("mf.fields.paymentBankNamePlaceholder")}
          isRequired
          value={
            selectedBank
              ? { label: selectedBank.name, id: selectedBank.id }
              : undefined
          }
          options={store.bank.bankList.map((bank) => {
            return { id: bank.id, label: bank.name };
          })}
          error={store.bank.error !== undefined}
          helperText={store.bank.error}
          isLoading={store.bank.isLoading}
          isDisabled={store.isFieldsDisabled}
          loadingText={t("common.loading")}
          onSelect={(bank) => {
            if (typeof bank !== "string") {
              store.bank.setError(undefined);
              store.resetAvailableBalance();
              store.bank.setSelectedBank(bank?.id ?? undefined);
              store.bankAccount.getAccountList(bank?.id ?? undefined);
            }
          }}
          style={{ width: Size.bankWidth }}
        />
        <AutoCompleteInputFieldSeparateLabel
          key={store.bank.selectedBankId}
          id={"paymentBankAccount"}
          label={t("mf.fields.paymentAccountNumberLabel")}
          placeholder={t("mf.fields.paymentAccountNumberPlaceholder")}
          isRequired
          value={
            selectedAccount
              ? { id: selectedAccount, label: selectedAccount }
              : undefined
          }
          error={
            store.bankAccount.error !== undefined ||
            store.errorStore.error === CreateMFDealError.InsufficientBankBalance
          }
          helperText={
            store.availableBalance !== undefined
              ? t("common.availableBalance", {
                  symbol: currencySymbol,
                  amount: getFormattedAmount(
                    store.availableBalance,
                    undefined,
                    undefined,
                    true,
                  ),
                })
              : store.bankAccount.error
          }
          options={store.bankAccount.bankAccountList.map((account) => {
            return {
              id: account.bankAccountNumber,
              label: account.bankAccountNumber,
            };
          })}
          isLoading={store.bankAccount.isLoading}
          isDisabled={store.isFieldsDisabled || store.isBankAccountDisabled}
          loadingText={t("common.loading")}
          onSelect={(value) => {
            if (typeof value !== "string") {
              store.errorStore.reset();
              store.resetAvailableBalance();
              store.bankAccount.setError(undefined);
              store.bankAccount.setSelectedAccount(value?.id ?? undefined);
              if (
                store.transactionType ===
                MFTransactionType.MFTransactionType.MF_PURCHASE
              ) {
                store.getAvailableBankBalance();
              }
            }
          }}
          style={{ width: Size.accountWidth }}
        />
      </Stack>
    );
  };

  const getRedeemType = (): React.ReactElement => {
    return (
      <Stack width={Size.toggleButtonWidth}>
        <KHToggleButton
          items={[
            {
              value: MFRedeemType.MFRedeemType.ALL,
              label: MFRedeemType.MFRedeemType.ALL,
            },
            {
              value: MFRedeemType.MFRedeemType.UNITS,
              label: MFRedeemType.MFRedeemType.UNITS,
            },
            {
              value: MFRedeemType.MFRedeemType.AMOUNT,
              label: MFRedeemType.MFRedeemType.AMOUNT,
            },
          ]}
          label={t("mf.fields.redeemTypeLabel")}
          required={true}
          value={store.redeemType}
          disabled={store.isFieldsDisabled}
          onChange={(_event, value) => store.setRedeemType(value)}
        />
      </Stack>
    );
  };

  const getEntryExitLoad = (): { label: string; value: string | undefined } => {
    switch (store.transactionType) {
      case MFTransactionType.MFTransactionType.MF_PURCHASE:
        const entryLoad = store.dealRequestSummaryStore.entryLoad;
        return {
          label: t("mf.fields.entryLoadLabel", { val: store.currencySymbol }),
          value: t("common.decimal2", { val: entryLoad }),
        };
      case MFTransactionType.MFTransactionType.MF_REDEEM:
        const exitLoad = store.dealRequestSummaryStore.exitLoad;
        return {
          label: t("mf.fields.exitLoadLabel", { val: store.currencySymbol }),
          value: t("common.decimal2", { val: exitLoad }),
        };
    }
  };

  const getAmountDetails = (): { label: string; value: string | undefined } => {
    const grossAmount = store.dealRequestSummaryStore.grossAmount;
    return {
      label: t("mf.fields.grossAmountLabel", { val: store.currencySymbol }),
      value: t("common.decimal2", { val: grossAmount }),
    };
  };

  return (
    <Stack>
      <PageHeader title={t("mf.createDeal.pageTitle")} />
      <CreateDealContainer>
        <CreateDealForm
          header={{
            color: getDealRequestTabBackgroundColor(),
            node: getSearchField(),
            tabs: tabLabels,
            onTabChange: (index) => {
              store.schemeSearchStore.reset();
              store.clearFields();
              if (index === 0) {
                store.setTransactionType(
                  MFTransactionType.MFTransactionType.MF_PURCHASE,
                );
              } else {
                store.setTransactionType(
                  MFTransactionType.MFTransactionType.MF_REDEEM,
                );
              }
            },
            selectedTab:
              store.transactionType ===
              MFTransactionType.MFTransactionType.MF_PURCHASE
                ? 0
                : 1,
          }}
          body={[
            store.transactionType ===
            MFTransactionType.MFTransactionType.MF_REDEEM ? (
              getRedeemType()
            ) : (
              <></>
            ),
            getFirstFormRow(),
            getSecondFormRow(),
            getThirdFormRow(),
            <NoteTextArea
              key={store.schemeSearchStore.selectedScheme?.isin}
              note={store.note}
              setNote={store.setNote}
              isDisabled={store.isFieldsDisabled}
            />,
          ]}
          onCreateClick={async (): Promise<void> => {
            const requestId = await store.createMFDealRequest();
            if (requestId) {
              navigate(
                getPath(
                  Module.MF,
                  Route.ManageDealRequestDetailsWithParams,
                  requestId.toString(),
                ),
              );
            }
          }}
        />
        <Stack gap={spacing.spaceXL}>
          <TransactionDetails
            title={t("mf.createDeal.transactionDetailsTitle")}
            section={[
              {
                label: t("mf.fields.schemeDetailsLabel"),
                rows: [
                  {
                    label: t("mf.fields.isinLabel"),
                    value: store.dealRequestSummaryStore.isin,
                  },
                  {
                    label: t("mf.fields.amcLabel"),
                    value: store.dealRequestSummaryStore.amc,
                  },
                  {
                    label: t("mf.fields.schemeTypeLabel"),
                    value: getSchemeTypeName(
                      store.dealRequestSummaryStore.schemeType,
                    ),
                  },
                  {
                    label: t("mf.fields.planTypeLabel"),
                    value: getPlanTypeName(
                      store.dealRequestSummaryStore.planType,
                    ),
                  },
                  {
                    label: t("mf.fields.dividendTypeLabel"),
                    value: getDividendTypeName(
                      store.dealRequestSummaryStore.dividendType,
                    ),
                  },
                ],
              },
              {
                label: t("mf.fields.transactionDetailLabel"),
                rows: [getEntryExitLoad(), getAmountDetails()],
              },
            ]}
            totalAmount={store.dealRequestSummaryStore.totalAmount}
            postfixLabel={
              store.schemeSearchStore.selectedScheme?.currency.symbol
            }
            errorBankBalance={
              store.errorStore.error ===
              CreateMFDealError.InsufficientBankBalance
            }
            isSell={
              store.transactionType ===
              MFTransactionType.MFTransactionType.MF_REDEEM
            }
            isTransactionDetailsLoading={
              store.dealRequestSummaryStore.isLoading
            }
          />
          <ImpactOnPortfolio
            impactTableModel={store.dealImpactStore.impactTableModel}
            isCreateDealScreen={true}
            isLoading={store.dealImpactStore.isLoading}
          />
        </Stack>
      </CreateDealContainer>
    </Stack>
  );
});
