import {
  EquityChangeInGainResponse,
  GetEquityChangeInGainRPC,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityChangeInGainRPC extends GetEquityChangeInGainRPC {
  execute(
    _request: GetEquityChangeInGainRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityChangeInGainRPC.Response,
      GetEquityChangeInGainRPC.Errors.Errors
    >
  > {
    const response = new GetEquityChangeInGainRPC.Response(
      new LeoDate(),
      new LeoDate(),
      [
        new EquityChangeInGainResponse(
          "ICICI",
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
        ),
      ],
      10,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityChangeInGainRPC.Response,
        GetEquityChangeInGainRPC.Errors.Errors
      >
    >;
  }
}
