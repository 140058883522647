import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createMFLinkDealRequestModel,
  MFLinkDealRequestModel,
} from "../../models/MFLinkDealRequestModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  GetMFApprovedDealRequestsRPC,
  ISIN,
  MFTransactionType,
  MutualFundItemsPerPage,
  MutualFundPageIndex,
  Note,
  SubmitMFConfirmationLinkRequestRPC,
} from "@khazana/khazana-rpcs";
import {
  useGetMFApprovedDealRequestsRPC,
  useSubmitMFConfirmationLinkRequestRPC,
} from "../../rpc/RPC";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mfConfirmationErrorStore } from "./hooks";

export const MFLinkDealRequestStore = types
  .model("MFLinkDealRequestStore", {
    totalItems: types.optional(types.number, 0),
    requests: types.array(MFLinkDealRequestModel),
    currencySymbol: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFApprovedDealRequests: flow(function* (
        pageIndex: number,
        itemsPerPage: number,
        folioNumber: string,
        isin: string,
        transactionType: string,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFApprovedDealRequestsRPC.Request(
          MFTransactionType.fromDTO({ case: transactionType }),
          folioNumber,
          new ISIN(isin),
          new MutualFundItemsPerPage(itemsPerPage),
          new MutualFundPageIndex(pageIndex),
        );
        const result: LeoRPCResult<
          GetMFApprovedDealRequestsRPC.Response,
          GetMFApprovedDealRequestsRPC.Errors.Errors
        > = yield useGetMFApprovedDealRequestsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.currencySymbol =
            response.getMFApprovedDealRequestsPaginationResponse[0]?.currency
              .symbol;
          store.requests = cast(
            response.getMFApprovedDealRequestsPaginationResponse.map(
              (_response) => createMFLinkDealRequestModel(_response),
            ),
          );
          store.totalItems = response.totalItems;
        } else if (result instanceof LeoRPCResult.Error) {
          const errorStore = mfConfirmationErrorStore(store);
          errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in getMFApprovedDealRequests");
        }
      }),
      submitMFConfirmationLinkRequest: flow(function* (
        requestId: number,
        dealRequestId?: number,
        note?: string,
      ) {
        if (!dealRequestId) {
          return;
        }
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new SubmitMFConfirmationLinkRequestRPC.Request(
          requestId,
          dealRequestId,
          note ? new Note(note) : null,
        );
        const result: LeoRPCResult<
          SubmitMFConfirmationLinkRequestRPC.Response,
          SubmitMFConfirmationLinkRequestRPC.Errors.Errors
        > =
          yield useSubmitMFConfirmationLinkRequestRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const errorStore = mfConfirmationErrorStore(store);
          errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in submitMFConfirmationLinkRequest");
        }
      }),
    };
  })
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createMFLinkDealRequestStore = (): Instance<
  typeof MFLinkDealRequestStore
> => {
  return MFLinkDealRequestStore.create({});
};
