import {
  applySnapshot,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  CreateFdDealRequestRPC,
  SubmitFDCertificateDetailsRPC,
} from "@khazana/khazana-rpcs";
import i18next from "i18next";

export const CreateFDDealRequestErrorStore = types
  .model("CreateFDDealRequestErrorStore", {
    entityError: types.maybe(types.string),
    portfolioError: types.maybe(types.string),
    paymentBankAccountError: types.maybe(types.string),
    depositAmountError: types.maybe(types.string),
    valueDateError: types.maybe(types.string),
    interestTypeError: types.maybe(types.string),
    rateOfInterestError: types.maybe(types.string),
    frequencyError: types.maybe(types.string),
    depositBankError: types.maybe(types.string),
    depositBranchError: types.maybe(types.string),
    depositDurationError: types.maybe(types.string),
    depositCertificateNumberError: types.maybe(types.string),
    depositAccountError: types.maybe(types.string),
    maturityDateError: types.maybe(types.string),
    maturityAmountError: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setEntityError: (error: string | undefined): void => {
        store.entityError = error;
      },
      setPortfolioError: (error: string | undefined): void => {
        store.portfolioError = error;
      },
      setPaymentBankAccountError: (error: string | undefined): void => {
        store.paymentBankAccountError = error;
      },
      setDepositAmountError: (error: string | undefined): void => {
        store.depositAmountError = error;
      },
      setValueDateError: (error: string | undefined): void => {
        store.valueDateError = error;
      },
      setInterestTypeError: (error: string | undefined): void => {
        store.interestTypeError = error;
      },
      setRateOfInterestError: (error: string | undefined): void => {
        store.rateOfInterestError = error;
      },
      setFrequencyError: (error: string | undefined): void => {
        store.frequencyError = error;
      },
      setDepositBankError: (error: string | undefined): void => {
        store.depositBankError = error;
      },
      setDepositBranchError: (error: string | undefined): void => {
        store.depositBranchError = error;
      },
      setDepositDurationError: (error: string | undefined): void => {
        store.depositDurationError = error;
      },
      setDepositCertificateNumberError: (error: string | undefined): void => {
        store.depositCertificateNumberError = error;
      },
      setDepositAccountError: (error: string | undefined): void => {
        store.depositAccountError = error;
      },
      setMaturityDateError: (error: string | undefined): void => {
        store.maturityDateError = error;
      },
      setMaturityAmountError: (error: string | undefined): void => {
        store.maturityAmountError = error;
      },
      handleRPCError: (
        error:
          | CreateFdDealRequestRPC.Errors.Errors
          | SubmitFDCertificateDetailsRPC.Errors.Errors,
      ): void => {
        const logger = getEnv(store).logger;
        if (error instanceof CreateFdDealRequestRPC.Errors.InvalidEntityId) {
          store.entityError = i18next.t(
            "fd.dealRequest.errors.invalidEntityErrorMessage",
          );
        } else if (
          error instanceof CreateFdDealRequestRPC.Errors.InvalidPortfolioId
        ) {
          store.portfolioError = i18next.t(
            "fd.dealRequest.errors.invalidPortfolioErrorMessage",
          );
        } else if (
          error instanceof
          CreateFdDealRequestRPC.Errors.InvalidPaymentBankAccountNumber
        ) {
          store.paymentBankAccountError = i18next.t(
            "fd.dealRequest.errors.invalidAccountNumberErrorMessage",
          );
        } else if (
          error instanceof
            CreateFdDealRequestRPC.Errors
              .InvalidInterestTypeFrequencyCombination ||
          error instanceof
            SubmitFDCertificateDetailsRPC.Errors
              .InvalidInterestTypeFrequencyCombination
        ) {
          store.interestTypeError = i18next.t(
            "fd.dealRequest.errors.invalidInterestTypeCombinationErrorMessage",
          );
        } else if (
          error instanceof CreateFdDealRequestRPC.Errors.InvalidBranchId ||
          error instanceof SubmitFDCertificateDetailsRPC.Errors.InvalidBranchId
        ) {
          store.depositBranchError = i18next.t(
            "fd.dealRequest.errors.invalidBranchErrorMessage",
          );
        } else if (
          error instanceof
            CreateFdDealRequestRPC.Errors.InvalidDepositDuration ||
          error instanceof
            SubmitFDCertificateDetailsRPC.Errors.InvalidDepositDuration
        ) {
          store.depositDurationError = i18next.t(
            "fd.dealRequest.errors.invalidDurationErrorMessage",
          );
        } else {
          logger.error("unhandled error", error);
        }
      },
    };
  });

export const createFDDealRequestErrorStore = (): Instance<
  typeof CreateFDDealRequestErrorStore
> => {
  return CreateFDDealRequestErrorStore.create();
};
