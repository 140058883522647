import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ContractNote } from "../../../components/contract-note/ContractNote";
import React, { useState } from "react";
import { useMFConfirmationRequestDetailsStore } from "../store/confirmationRequestDetailsStore/hooks";
import { getDiffDetailRow } from "../../../utils/DiffDetailCellUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { ConfirmationHistoryDialog } from "./ConfirmationHistoryDialog";

export const ConfirmationRequestDetailSection = observer(
  (): React.ReactElement => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const store = useMFConfirmationRequestDetailsStore();
    const [searchParam] = useSearchParams();
    const requestId = searchParam.get("requestId");
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);

    return (
      <>
        {
          <ConfirmationHistoryDialog
            onClose={(): void => {
              setIsHistoryOpen(false);
            }}
            isOpen={isHistoryOpen}
          />
        }
        <ContractNote
          title={t("mf.confirmationRequest.pageTitle")}
          status={store.requestStatusView}
          showEditButton={store.actions.allowEdit}
          isEditing={false}
          onViewHistoryClick={(): void => {
            setIsHistoryOpen(true);
          }}
          onEditClick={(): void => {
            if (requestId) {
              navigate(
                getPath(
                  Module.MF,
                  Route.EditConfirmationRequestWithParams,
                  requestId,
                ),
              );
            }
          }}
          rows={getDiffDetailRow(store.details, Module.MF)}
          pdfUrl={store.confirmationDocumentURL}
        />
      </>
    );
  },
);
