// The rule is disabled since privileges for three different kinds of users are created but only one of them is used.
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EquityUserPrivileges,
  FdUserPrivileges,
  FiUserPrivileges,
} from "../../user/UserPrivileges";
import {
  ModuleAvailability,
  Name,
  SignInRPC,
  UserName,
} from "@khazana/khazana-rpcs";
import { LeoEmailId, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

const fundManagerPrivileges = [
  // Equity Deal Request
  EquityUserPrivileges.CreateEquityDealRequest,
  EquityUserPrivileges.BuyEquityDeal,
  EquityUserPrivileges.SellEquityDeal,
  EquityUserPrivileges.ViewEquityDealRequest,
  EquityUserPrivileges.ViewEquityContractNote,
  EquityUserPrivileges.CancelEquityDealRequest,
  EquityUserPrivileges.ViewEquityDashboard,

  // Fi
  FiUserPrivileges.CreateFixedIncomeDealRequest,
  FiUserPrivileges.SellFixedIncomeDeal,
  FiUserPrivileges.BuyFixedIncomeDeal,
  FiUserPrivileges.ViewFixedIncomeContractNote,
  FiUserPrivileges.ViewFixedIncomeDealRequest,
  FiUserPrivileges.UploadFixedIncomeContractNote,
  FiUserPrivileges.CreateFixedIncomeContractNoteReviewRequest,
  FiUserPrivileges.EditFixedIncomeContractNote,
  FiUserPrivileges.ModifyFixedIncomeBrokerEntryInDealRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteLinkRequest,
  FiUserPrivileges.SettleFixedIncomeDealRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteUnknownRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteDiscardRequest,
  FiUserPrivileges.ViewFixedIncomeMaturityDealRequest,
  FiUserPrivileges.ViewFixedIncomeCashFlow,

  //FD
  FdUserPrivileges.ViewFixedDepositDealRequest,
  FdUserPrivileges.ViewFixedDepositCertificate,
  FdUserPrivileges.ViewFixedDepositWithdrawCertificate,
  FdUserPrivileges.CreateFixedDepositCertificate,
  FdUserPrivileges.CreateFixedDepositDealRequest,
  FdUserPrivileges.EditFixedDepositCertificate,
];

const icPrivileges = [
  EquityUserPrivileges.CheckEquityImpactDealRequest,
  EquityUserPrivileges.ViewEquityDealRequest,
  EquityUserPrivileges.ViewEquityHistoricalIsinData,
  EquityUserPrivileges.ViewEquityImpactOnHoldingsDisplaySection,
  EquityUserPrivileges.CheckEquityImpactDealRequest,
];

const accountsPrivileges = [
  EquityUserPrivileges.CheckEquityTransactionDetailsForDealRequest,
  EquityUserPrivileges.ViewEquityContractNote,
  EquityUserPrivileges.ViewEquityDetails,
];
export class MockSignInRPCImpl extends SignInRPC {
  execute(
    _request: SignInRPC.Request,
  ): Promise<LeoRPCResult<SignInRPC.Response, SignInRPC.Errors.Errors>> {
    const privileges = fundManagerPrivileges;
    const response = new SignInRPC.Response(
      privileges,
      new UserName(new Name("John"), new Name("Doe")),
      new LeoEmailId("john.doe@example.com"),
      new ModuleAvailability(true, true, true, true),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<SignInRPC.Response, SignInRPC.Errors.Errors>
    >;
  }
}
