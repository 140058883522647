import { observer } from "mobx-react";
import React from "react";
import { Section } from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { ActionButtons } from "../../../components/Section";
import {
  Table,
  TableHeader,
  TableRowItems,
  useCornerRadius,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  getFormattedAmountString,
  getFormattedDate,
} from "../../../../../utils";
import { Stack } from "@mui/material";
import { getFDWithdrawalStatusValue } from "../utils/UIUtils";
import { useUserStore } from "../../../store/hooks";
import { FdUserPrivileges } from "../../../../user/UserPrivileges";
import { useFDWithdrawListStore } from "../store/withdrawStore/hooks";
import { FDDealRequestStatus, FDWithdrawStatus } from "@khazana/khazana-rpcs";

const Size = {
  columnWidth: "236px",
};

export interface WithdrawTableProps {
  status: FDDealRequestStatus.FDDealRequestStatus;
  onClick: (withdrawId: number) => void;
}

export const WithdrawTable = observer(
  ({ status, onClick }: WithdrawTableProps): React.ReactElement => {
    const { t } = useTranslation();
    const privileges = useUserStore().privileges;
    const store = useFDWithdrawListStore();
    const cornerRadius = useCornerRadius();

    const getActionButtons = (): ActionButtons | undefined => {
      if (
        privileges.includes(FdUserPrivileges.CreateFixedDepositCertificate) &&
        status ===
          FDDealRequestStatus.FDDealRequestStatus.FD_WITHDRAWAL_APPROVED
      ) {
        return {
          secondaryButton: {
            label: t("fd.withdraw.confirmTitle"),
            onClick: async (): Promise<void> => {
              const withdrawId = store.items.find(
                (item) =>
                  item.status ===
                  FDWithdrawStatus.FDWithdrawStatus.WITHDRAW_APPROVED,
              )?.requestId;
              if (withdrawId) {
                onClick(withdrawId);
              }
            },
            variant: "plain-color",
          },
        };
      }
    };

    const getTableOptions = async (): Promise<string | TableRowItems> => {
      return (
        store.items.map((item) => {
          return [
            {
              data: getFormattedAmountString(item.withdrawalAmount ?? 0),
              align: "right",
            },
            {
              data: item.withdrawalReferenceNumber ?? "-",
              align: "right",
            },
            {
              data: item.requestWithdrawalDate
                ? getFormattedDate(new Date(item.requestWithdrawalDate))
                : "-",
            },
            {
              data: item.actualWithdrawalDate
                ? getFormattedDate(new Date(item.actualWithdrawalDate))
                : "-",
            },
            {
              data: item.status ? getFDWithdrawalStatusValue(item.status) : "-",
            },
          ];
        }) ?? []
      );
    };

    const getHeaders = (): TableHeader => {
      return [
        {
          id: "withdrawalAmount",
          name: t("fd.fields.withdrawalAmountLabel", {
            currencySymbol: `(${store?.currencySymbol})`,
          }),
          width: Size.columnWidth,
          align: "right",
        },
        {
          id: "withdrawReferenceNumber",
          name: t("fd.fields.withdrawReferenceNumberLabel"),
          width: Size.columnWidth,
          align: "right",
        },
        {
          id: "requestedWithdrawalDate",
          name: t("fd.fields.requestedWithdrawalDate"),
          width: Size.columnWidth,
        },
        {
          id: "actualWithdrawalDate",
          name: t("fd.fields.actualWithdrawalDate"),
          width: Size.columnWidth,
        },
        {
          id: "withdrawStatusLabel",
          name: t("fd.fields.withdrawStatusLabel"),
          width: Size.columnWidth,
        },
      ];
    };

    return (
      <>
        {store.items.length > 0 && (
          <Section
            title={t("fd.withdraw.tableTitle")}
            actions={getActionButtons()}
          >
            <Stack width={"100%"}>
              <Table
                name={"withdrawTable"}
                headers={getHeaders()}
                onTableOptionsChange={getTableOptions}
                viewOverrides={{
                  empty: { message: t("common.noResultsFound") },
                  idle: { message: t("common.searchTableIdleState") },
                  loading: { message: t("common.searchTableLoadingState") },
                }}
                styleOverrides={{
                  divider: "cell",
                  borderRadius: `0 0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS}`,
                  border: "0",
                }}
              />
            </Stack>
          </Section>
        )}
      </>
    );
  },
);
