"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityContractNoteHistoryDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const requestNote_1 = require("../types/requestNote");
const equityContractNoteDealRequestDetail_1 = require("./equityContractNoteDealRequestDetail");
const contractNoteRow_1 = require("./contractNoteRow");
const contractNoteCharge_1 = require("../types/contractNoteCharge");
const contractNoteRequestStatus_1 = require("./contractNoteRequestStatus");
class InvalidEquityContractNoteHistoryDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityContractNoteHistoryDetailError";
    }
}
class EquityContractNoteHistoryDetail {
    constructor(requestNote, status, dealRequestDetails = null, diffDetails, charges) {
        this.isEquityContractNoteHistoryDetail = true;
        EquityContractNoteHistoryDetail.validateRequestNote(requestNote);
        this.requestNote = requestNote;
        EquityContractNoteHistoryDetail.validateStatus(status);
        this.status = status;
        if (dealRequestDetails !== undefined && dealRequestDetails !== null) {
            EquityContractNoteHistoryDetail.validateDealRequestDetails(dealRequestDetails);
        }
        this.dealRequestDetails = dealRequestDetails;
        EquityContractNoteHistoryDetail.validateDiffDetails(diffDetails);
        this.diffDetails = diffDetails;
        EquityContractNoteHistoryDetail.validateCharges(charges);
        this.charges = charges;
    }
    static fromDTO(dto) {
        const requestNote = requestNote_1.RequestNote.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestNote", InvalidEquityContractNoteHistoryDetailError));
        const status = contractNoteRequestStatus_1.ContractNoteRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidEquityContractNoteHistoryDetailError));
        let dealRequestDetails = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestDetails");
        if (dealRequestDetails !== undefined && dealRequestDetails !== null) {
            dealRequestDetails = equityContractNoteDealRequestDetail_1.EquityContractNoteDealRequestDetail.fromDTO(dealRequestDetails);
        }
        const diffDetails = (0, leo_ts_runtime_1.getList)(dto, "diffDetails", contractNoteRow_1.ContractNoteRow.fromDTO, InvalidEquityContractNoteHistoryDetailError);
        const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", contractNoteCharge_1.ContractNoteCharge.fromDTO, InvalidEquityContractNoteHistoryDetailError);
        return new EquityContractNoteHistoryDetail(requestNote, status, dealRequestDetails, diffDetails, charges);
    }
    toDTO() {
        const ret = {
            requestNote: this.requestNote.toDTO(),
            status: contractNoteRequestStatus_1.ContractNoteRequestStatus.toDTO(this.status),
            diffDetails: this.diffDetails.map((e) => {
                return e.toDTO();
            }),
            charges: this.charges.map((e) => {
                return e.toDTO();
            }),
        };
        if (this.dealRequestDetails) {
            ret.dealRequestDetails = this.dealRequestDetails.toDTO();
        }
        return ret;
    }
    copy(requestNote = this.requestNote, status = this.status, dealRequestDetails = this.dealRequestDetails, diffDetails = this.diffDetails, charges = this.charges) {
        return new EquityContractNoteHistoryDetail(requestNote, status, dealRequestDetails, diffDetails, charges);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityContractNoteHistoryDetailValue = other.isEquityContractNoteHistoryDetail;
        if (!(other instanceof EquityContractNoteHistoryDetail) || Boolean(isEquityContractNoteHistoryDetailValue)) {
            return false;
        }
        if (!this.requestNote.equals(other.requestNote)) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.dealRequestDetails) {
            if (!this.dealRequestDetails.equals(other.dealRequestDetails)) {
                return false;
            }
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.diffDetails, other.diffDetails)) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
            return false;
        }
        return true;
    }
}
exports.EquityContractNoteHistoryDetail = EquityContractNoteHistoryDetail;
EquityContractNoteHistoryDetail.validateRequestNote = (requestNote) => {
    const isRequestNoteValue = requestNote.isRequestNote;
    if (!(requestNote instanceof requestNote_1.RequestNote || Boolean(isRequestNoteValue))) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute requestNote is not a RequestNote`);
    }
};
EquityContractNoteHistoryDetail.validateStatus = (status) => {
    if (!contractNoteRequestStatus_1.ContractNoteRequestStatus.isInstanceOf(status)) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute status is not a ContractNoteRequestStatus.ContractNoteRequestStatus`);
    }
};
EquityContractNoteHistoryDetail.validateDealRequestDetails = (dealRequestDetails) => {
    const isEquityContractNoteDealRequestDetailValue = dealRequestDetails.isEquityContractNoteDealRequestDetail;
    if (!(dealRequestDetails instanceof equityContractNoteDealRequestDetail_1.EquityContractNoteDealRequestDetail || Boolean(isEquityContractNoteDealRequestDetailValue))) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute dealRequestDetails is not a EquityContractNoteDealRequestDetail`);
    }
};
EquityContractNoteHistoryDetail.validateDiffDetails = (diffDetails) => {
    if (!Array.isArray(diffDetails)) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute diffDetails is not a List`);
    }
    for (let i = 0; i < diffDetails.length; i += 1) {
        const isContractNoteRowValue = diffDetails[i].isContractNoteRow;
        if (!(diffDetails[i] instanceof contractNoteRow_1.ContractNoteRow || Boolean(isContractNoteRowValue))) {
            throw new InvalidEquityContractNoteHistoryDetailError(`Attribute diffDetails at index ${i} is not a ContractNoteRow.`);
        }
    }
};
EquityContractNoteHistoryDetail.validateCharges = (charges) => {
    if (!Array.isArray(charges)) {
        throw new InvalidEquityContractNoteHistoryDetailError(`Attribute charges is not a List`);
    }
    for (let i = 0; i < charges.length; i += 1) {
        const isContractNoteChargeValue = charges[i].isContractNoteCharge;
        if (!(charges[i] instanceof contractNoteCharge_1.ContractNoteCharge || Boolean(isContractNoteChargeValue))) {
            throw new InvalidEquityContractNoteHistoryDetailError(`Attribute charges at index ${i} is not a ContractNoteCharge.`);
        }
    }
};
EquityContractNoteHistoryDetail.prototype.toString = function toString() {
    return `EquityContractNoteHistoryDetail(requestNote=${this.requestNote},status=${this.status},dealRequestDetails=${this.dealRequestDetails},diffDetails=${this.diffDetails},charges=${this.charges})`;
};
