import React from "react";
import { Stack } from "@mui/material";
import {
  Button,
  TabProps,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../../utils/BorderUtils";
import { Tabs } from "../Tabs";
import { useTranslation } from "react-i18next";

interface CreateDealHeader {
  node: React.ReactNode;
  color: string;
  tabs: TabProps[];
  onTabChange: (index: number) => void;
  selectedTab: number;
}
interface CreateDealFormProps {
  header: CreateDealHeader;
  body: React.ReactElement[];
  onCreateClick: () => Promise<void>;
}

const Size = {
  containerWidth: "740px",
  createButton: "164px",
};

export const CreateDealForm = ({
  header,
  body,
  onCreateClick,
}: CreateDealFormProps): React.ReactElement => {
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        backgroundColor: tokens.background,
        borderRadius: cornerRadius.radiusXS,
        border: border.default,
        width: Size.containerWidth,
      }}
    >
      <Stack
        spacing={spacing.spaceXL}
        sx={{
          backgroundColor: header.color,
          padding: `${spacing.spaceXL} ${spacing.spaceXL} 0 ${spacing.spaceXL}`,
          borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
        }}
      >
        {header.node}
        <Tabs
          tabs={header.tabs}
          onTabChange={(index: number): void => {
            header.onTabChange(index);
          }}
          tabIndex={header.selectedTab}
        />
      </Stack>
      <Stack
        padding={spacing.spaceXL}
        spacing={spacing.spaceXL}
        sx={{
          ".MuiOutlinedInput-root": {
            "&.Mui-disabled": {
              backgroundColor: tokens.backgroundSubtle,
            },
          },
        }}
      >
        {body.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
        <Button
          name={t("common.createRequest")}
          label={t("common.createRequest")}
          size="large"
          variant="filled"
          onClick={onCreateClick}
          style={{ alignSelf: "flex-end", width: Size.createButton }}
        />
      </Stack>
    </Stack>
  );
};
