"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiApprovedDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const currency_1 = require("../types/currency");
const fiYieldType_1 = require("./fiYieldType");
const fiDealRequestStatus_1 = require("./fiDealRequestStatus");
const fiTransactionType_1 = require("./fiTransactionType");
class InvalidGetFiApprovedDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiApprovedDealRequestsPaginationResponseError";
    }
}
class GetFiApprovedDealRequestsPaginationResponse {
    constructor(fiDealRequestId, dematAccountNumber, isin, security, ytm, yieldType, amountHCY = null, currency, quantity = null, entityName, currentDealStatus, transactionType) {
        this.isGetFiApprovedDealRequestsPaginationResponse = true;
        GetFiApprovedDealRequestsPaginationResponse.validateFiDealRequestId(fiDealRequestId);
        this.fiDealRequestId = fiDealRequestId;
        GetFiApprovedDealRequestsPaginationResponse.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        GetFiApprovedDealRequestsPaginationResponse.validateIsin(isin);
        this.isin = isin;
        GetFiApprovedDealRequestsPaginationResponse.validateSecurity(security);
        this.security = security;
        GetFiApprovedDealRequestsPaginationResponse.validateYtm(ytm);
        this.ytm = ytm;
        GetFiApprovedDealRequestsPaginationResponse.validateYieldType(yieldType);
        this.yieldType = yieldType;
        if (amountHCY !== undefined && amountHCY !== null) {
            GetFiApprovedDealRequestsPaginationResponse.validateAmountHCY(amountHCY);
        }
        this.amountHCY = amountHCY;
        GetFiApprovedDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
        if (quantity !== undefined && quantity !== null) {
            GetFiApprovedDealRequestsPaginationResponse.validateQuantity(quantity);
        }
        this.quantity = quantity;
        GetFiApprovedDealRequestsPaginationResponse.validateEntityName(entityName);
        this.entityName = entityName;
        GetFiApprovedDealRequestsPaginationResponse.validateCurrentDealStatus(currentDealStatus);
        this.currentDealStatus = currentDealStatus;
        GetFiApprovedDealRequestsPaginationResponse.validateTransactionType(transactionType);
        this.transactionType = transactionType;
    }
    static fromDTO(dto) {
        const fiDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "fiDealRequestId", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        const security = (0, leo_ts_runtime_1.getString)(dto, "security", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const ytm = (0, leo_ts_runtime_1.getInt32)(dto, "ytm", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const yieldType = fiYieldType_1.FiYieldType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yieldType", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        const amountHCY = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amountHCY", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        const quantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "quantity", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const entityName = (0, leo_ts_runtime_1.getString)(dto, "entityName", InvalidGetFiApprovedDealRequestsPaginationResponseError);
        const currentDealStatus = fiDealRequestStatus_1.FiDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currentDealStatus", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        const transactionType = fiTransactionType_1.FiTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidGetFiApprovedDealRequestsPaginationResponseError));
        return new GetFiApprovedDealRequestsPaginationResponse(fiDealRequestId, dematAccountNumber, isin, security, ytm, yieldType, amountHCY, currency, quantity, entityName, currentDealStatus, transactionType);
    }
    toDTO() {
        const ret = {
            fiDealRequestId: this.fiDealRequestId,
            dematAccountNumber: this.dematAccountNumber,
            isin: this.isin.toDTO(),
            security: this.security,
            ytm: this.ytm,
            yieldType: fiYieldType_1.FiYieldType.toDTO(this.yieldType),
            currency: this.currency.toDTO(),
            entityName: this.entityName,
            currentDealStatus: fiDealRequestStatus_1.FiDealRequestStatus.toDTO(this.currentDealStatus),
            transactionType: fiTransactionType_1.FiTransactionType.toDTO(this.transactionType),
        };
        if (this.amountHCY !== undefined && this.amountHCY !== null) {
            ret.amountHCY = this.amountHCY;
        }
        if (this.quantity !== undefined && this.quantity !== null) {
            ret.quantity = this.quantity;
        }
        return ret;
    }
    copy(fiDealRequestId = this.fiDealRequestId, dematAccountNumber = this.dematAccountNumber, isin = this.isin, security = this.security, ytm = this.ytm, yieldType = this.yieldType, amountHCY = this.amountHCY, currency = this.currency, quantity = this.quantity, entityName = this.entityName, currentDealStatus = this.currentDealStatus, transactionType = this.transactionType) {
        return new GetFiApprovedDealRequestsPaginationResponse(fiDealRequestId, dematAccountNumber, isin, security, ytm, yieldType, amountHCY, currency, quantity, entityName, currentDealStatus, transactionType);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiApprovedDealRequestsPaginationResponseValue = other.isGetFiApprovedDealRequestsPaginationResponse;
        if (!(other instanceof GetFiApprovedDealRequestsPaginationResponse) || Boolean(isGetFiApprovedDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (this.fiDealRequestId !== other.fiDealRequestId) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.security !== other.security) {
            return false;
        }
        if (this.ytm !== other.ytm) {
            return false;
        }
        if (this.yieldType !== other.yieldType) {
            return false;
        }
        if (this.amountHCY !== other.amountHCY) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        if (this.currentDealStatus !== other.currentDealStatus) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        return true;
    }
}
exports.GetFiApprovedDealRequestsPaginationResponse = GetFiApprovedDealRequestsPaginationResponse;
GetFiApprovedDealRequestsPaginationResponse.validateFiDealRequestId = (fiDealRequestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(fiDealRequestId)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute fiDealRequestId is not an Int64`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute isin is not a ISIN`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateSecurity = (security) => {
    if (!(0, leo_ts_runtime_1.isString)(security)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute security is not a String`);
    }
    if (security.trim().length === 0) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute security cannot be an empty string.`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateYtm = (ytm) => {
    if (!(0, leo_ts_runtime_1.isInt32)(ytm)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute ytm is not an Int32`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateYieldType = (yieldType) => {
    if (!fiYieldType_1.FiYieldType.isInstanceOf(yieldType)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute yieldType is not a FiYieldType.FiYieldType`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateAmountHCY = (amountHCY) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amountHCY)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute amountHCY is not an Int64`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute quantity is not an Int64`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateCurrentDealStatus = (currentDealStatus) => {
    if (!fiDealRequestStatus_1.FiDealRequestStatus.isInstanceOf(currentDealStatus)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute currentDealStatus is not a FiDealRequestStatus.FiDealRequestStatus`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.validateTransactionType = (transactionType) => {
    if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidGetFiApprovedDealRequestsPaginationResponseError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
    }
};
GetFiApprovedDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetFiApprovedDealRequestsPaginationResponse(fiDealRequestId=${this.fiDealRequestId},dematAccountNumber='${this.dematAccountNumber}',isin=${this.isin},security='${this.security}',ytm=${this.ytm},yieldType=${this.yieldType},amountHCY=${this.amountHCY},currency=${this.currency},quantity=${this.quantity},entityName='${this.entityName}',currentDealStatus=${this.currentDealStatus},transactionType=${this.transactionType})`;
};
