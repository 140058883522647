import { Instance, types } from "mobx-state-tree";
import { MFHoldingSummaryResponse } from "@khazana/khazana-rpcs";

export const MfHoldingSummaryModel = types.model("MfHoldingSummaryModel", {
  isin: types.string,
  schemeName: types.maybe(types.string),
  amcName: types.maybe(types.string),
  entityName: types.maybe(types.string),
  portfolioName: types.maybe(types.string),
  holdingUnits: types.maybe(types.string),
  marketPrice: types.maybe(types.string),
  marketValue: types.maybe(types.string),
  salesProceed: types.maybe(types.string),
  unrealizedGain: types.maybe(types.string),
  realizedGain: types.maybe(types.string),
  irr: types.maybe(types.string),
});

export const createMfHoldingSummaryModel = (
  response: MFHoldingSummaryResponse,
): Instance<typeof MfHoldingSummaryModel> => {
  return MfHoldingSummaryModel.create({
    isin: response.isin,
    schemeName: response.schemeName ?? undefined,
    amcName: response.amcName ?? undefined,
    entityName: response.entityName ?? undefined,
    portfolioName: response.portfolioName ?? undefined,
    holdingUnits: response.holdingUnits?.decimalValue ?? undefined,
    marketPrice: response.marketPrice?.decimalValue ?? undefined,
    marketValue: response.marketValue?.decimalValue ?? undefined,
    salesProceed: response.salesProceed?.decimalValue ?? undefined,
    unrealizedGain: response.unrealizedGain?.decimalValue ?? undefined,
    realizedGain: response.realizedGain?.decimalValue ?? undefined,
    irr: response.irr?.decimalValue ?? undefined,
  });
};
