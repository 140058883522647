import { FiContractNoteDetail } from "@khazana/khazana-rpcs";
import { Instance } from "mobx-state-tree";
import { FiContractNoteDetailType } from "../models/FiContractNoteDetailType";

export const getContractNoteFieldValue = (
  name: string,
  fields: Instance<typeof FiContractNoteDetailType>[],
  fieldType: "original" | "diff",
): string | number | null => {
  let field: FiContractNoteDetail.FiContractNoteDetail | null = null;
  if (fieldType === "original") {
    field =
      fields.find((item) => item.localizedTextId === name)?.originalData ??
      null;
  } else {
    field =
      fields.find((item) => item.localizedTextId === name)?.diffData ?? null;
  }
  if (field instanceof FiContractNoteDetail.Amount) {
    return field.amount ? field.amount / 10000 : 0;
  }

  if (field instanceof FiContractNoteDetail.Error) {
    return field.errorMessage;
  }

  if (field instanceof FiContractNoteDetail.QuantityType) {
    return field.quantity?.quantity ?? 0;
  }

  if (field instanceof FiContractNoteDetail.DateType) {
    return field.date?.date?.toString() ?? null;
  }

  if (field instanceof FiContractNoteDetail.TransactionType) {
    return field.transactionType;
  }

  if (field instanceof FiContractNoteDetail.Percentage) {
    return field.percentage !== undefined && field.percentage !== null
      ? field.percentage / 10000
      : null;
  }

  if (field instanceof FiContractNoteDetail.Unstyled) {
    return field.text;
  }
  return null;
};

// [TODO: https://surya-digital.atlassian.net/browse/KD-680]
export const getContractNoteField = (
  field: FiContractNoteDetail.FiContractNoteDetail | undefined | null,
): string | number | null => {
  if (field instanceof FiContractNoteDetail.Amount) {
    return field.amount ? field.amount / 10000 : 0;
  }

  if (field instanceof FiContractNoteDetail.Error) {
    return field.errorMessage;
  }

  if (field instanceof FiContractNoteDetail.QuantityType) {
    return field.quantity?.quantity ?? 0;
  }

  if (field instanceof FiContractNoteDetail.DateType) {
    return field.date?.date?.toString() ?? null;
  }

  if (field instanceof FiContractNoteDetail.TransactionType) {
    return field.transactionType;
  }

  if (field instanceof FiContractNoteDetail.Percentage) {
    return field.percentage !== null ? field.percentage / 10000 : null;
  }

  if (field instanceof FiContractNoteDetail.Unstyled) {
    return field.text;
  }
  return null;
};

export const getContractNoteFieldData = (
  isUnedited: boolean,
  fieldData:
    | {
        originalData: FiContractNoteDetail.FiContractNoteDetail;
        diffData: FiContractNoteDetail.FiContractNoteDetail | null;
      }
    | undefined,
): FiContractNoteDetail.FiContractNoteDetail | undefined | null => {
  if (isUnedited) {
    return fieldData?.originalData;
  } else {
    return fieldData?.diffData ?? fieldData?.originalData;
  }
};
