import React, { useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  TableHeader,
  TableOptions,
  TableRowItems,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-core";
import {
  Dialog,
  IconButton,
  Table,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useFiViewCashflowsStore } from "../store/hooks";
import { getFormattedDate } from "../../../../../utils";
import { useBorder } from "../../../../../utils/BorderUtils";
import { Edit } from "../../../../../assets/Edit";
import { AmountTextField } from "../../../components/AmountTextField";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { observer } from "mobx-react";
import { useUserStore } from "../../../store/hooks";
import { FiUserPrivileges } from "../../../../user/UserPrivileges";

const Size = {
  containerWidth: "100%",
  table: {
    default: "max-content",
  },
};

interface AccrualTableProps {
  requestId: number | undefined;
  getDetails: () => Promise<void>;
}

export const FiAccrualTable = observer(
  ({ requestId, getDetails }: AccrualTableProps): React.ReactElement => {
    const spacing = useSpacing();
    const { t } = useTranslation();
    const store = useFiViewCashflowsStore();

    const typography = useTypography();
    const border = useBorder();
    const [isAccrualDialogOpen, setIsAccrualDialogOpen] = useState(false);
    const privileges = useUserStore().privileges;

    const AccrualDialog = observer((): React.ReactElement => {
      return (
        <Dialog
          open={isAccrualDialogOpen}
          title={t("cashflow.dialogs.editAccrual")}
          isCloseIconPresent={false}
          onClose={() => {}}
          onSecondaryButtonClick={() => {
            setIsAccrualDialogOpen(false);
            store.accrualStore.setNote(undefined);
          }}
          secondaryButtonText={t("common.cancel")}
          onPrimaryButtonClick={async (): Promise<void> => {
            await store.accrualStore.editAccrual();
            store.accrualStore.setNote(undefined);
            setIsAccrualDialogOpen(false);
            if (!store.error) {
              store.softReset();
              await getDetails();
            }
          }}
          primaryButtonText={t("common.submit")}
          isPrimaryButtonDisabled={
            store.accrualStore.accrualAmount === undefined
          }
          disableBackdropClick={true}
        >
          <Stack
            spacing={spacing.spaceLG}
            justifyContent={"space-around"}
            width={Size.containerWidth}
          >
            <Stack spacing={spacing.spaceXS}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography sx={{ ...typography.s1 }}>
                  {`${t("cashflow.dialogs.calculatedAccrualAmount")} (${store
                    .accrualStore.selectedAccural?.currency.symbol})`}
                </Typography>
                <Typography>
                  {t("common.decimal2", {
                    val: store.accrualStore.selectedAccural?.amountCCY,
                  })}
                </Typography>
              </Stack>
              <Divider />
            </Stack>
            <Stack direction={"row"} spacing={spacing.spaceMD}>
              <AmountTextField
                isRequired
                name="interestRate"
                label={t("cashflow.dialogs.interestRate")}
                isDisabled={true}
                value={t("common.decimal2", {
                  val: store.accrualStore.selectedAccural?.rate,
                })}
                onAmountChange={() => {}}
                placeholder={""}
                isDecimalAllowed={true}
              />
              <AmountTextField
                isRequired
                name="accruedAmount"
                label={`${t("cashflow.dialogs.accruedAmount")} (${store
                  .accrualStore.selectedAccural?.currency.symbol})`}
                isDisabled={false}
                value={undefined}
                onAmountChange={(value) => {
                  store.accrualStore.setAccrualAmount(value);
                }}
                placeholder={t("common.enterAmount")}
                isDecimalAllowed={true}
              />
            </Stack>
            <NoteTextArea
              note={store.accrualStore.note}
              setNote={store.accrualStore.setNote}
            />
          </Stack>
        </Dialog>
      );
    });

    const getAccrualData = async (
      option: TableOptions<undefined>,
      setTotalItems: React.Dispatch<React.SetStateAction<number>>,
    ): Promise<string | TableRowItems> => {
      store.accrualStore.setAccrualPage(option.page ? option.page - 1 : 0);
      await store.accrualStore.getAccruals(requestId);
      setTotalItems(store.accrualStore.accrualTotalItems);
      return store.accrualStore.accrualList.map((accrual) => {
        return [
          {
            data: accrual.accrualId,
          },
          {
            data: accrual.accrualType,
          },
          {
            data: t("common.decimal2", { val: accrual.rate }),
            align: "right",
          },
          {
            data: accrual.isCredited
              ? `+${t("common.decimal2", { val: accrual.amountCCY })}`
              : t("common.decimal2", { val: accrual.amountCCY }),
            align: "right",
          },
          {
            data: accrual.overriddenAmountCCY
              ? accrual.isCredited
                ? `+${t("common.decimal2", {
                    val: accrual.overriddenAmountCCY,
                  })}`
                : t("common.decimal2", { val: accrual.overriddenAmountCCY })
              : "-",
            align: "right",
          },
          {
            data: getFormattedDate(accrual.accrualStartDate),
          },
          {
            data: getFormattedDate(accrual.accrualEndDate),
          },
          {
            data:
              accrual.isPosted && accrual.accuralPostedDate
                ? getFormattedDate(accrual.accuralPostedDate)
                : "-",
          },
          {
            data: accrual.isPosted
              ? t("cashflow.accrualsTable.settled")
              : t("cashflow.accrualsTable.notSettled"),
          },
          {
            data:
              !accrual.isPosted &&
              privileges.includes(FiUserPrivileges.EditFixedIncomeAccrual) ? (
                <Stack width={Size.containerWidth} alignItems={"center"}>
                  <IconButton
                    name="edit"
                    variant="plain-color"
                    size="small"
                    icon={<Edit />}
                    loading={false}
                    disabled={false}
                    color="primary"
                    onClick={() => {
                      store.accrualStore.setSelectedAccrual(accrual);
                      setIsAccrualDialogOpen(true);
                    }}
                  />
                </Stack>
              ) : (
                ""
              ),
          },
        ];
      });
    };
    const getAccuralHeaders = (): TableHeader => {
      return [
        {
          id: "id",
          name: t("cashflow.accrualsTable.headers.id"),
          width: Size.table.default,
        },
        {
          id: "accrualType",
          name: t("cashflow.accrualsTable.headers.accrualType"),
          width: Size.table.default,
        },
        {
          id: "rate",
          name: t("cashflow.accrualsTable.headers.rate"),
          width: Size.table.default,
          align: "right",
        },
        {
          id: "amount",
          name: `${t("cashflow.accrualsTable.headers.amount")} (${store
            .accrualStore.currency?.symbol})`,
          width: Size.table.default,
          align: "right",
        },
        {
          id: "amount",
          name: `${t(
            "cashflow.accrualsTable.headers.overriddenAmount",
          )} (${store.accrualStore.currency?.symbol})`,
          width: Size.table.default,
          align: "right",
        },
        {
          id: "startDate",
          name: t("cashflow.accrualsTable.headers.accrualStartDate"),
          width: Size.table.default,
        },
        {
          id: "endDate",
          name: t("cashflow.accrualsTable.headers.accrualEndDate"),
          width: Size.table.default,
        },
        {
          id: "endDate",
          name: t("cashflow.accrualsTable.headers.postedDate"),
          width: Size.table.default,
        },
        {
          id: "status",
          name: t("cashflow.accrualsTable.headers.status"),
          width: Size.table.default,
        },
        {
          id: "editButton",
          name: "",
          width: Size.table.default,
        },
      ];
    };

    return (
      <>
        <Box borderTop={border.lowEmphasis}>
          <Table
            key="Accrual"
            name="fixedIncomeContractNotesTable"
            styleOverrides={{
              divider: "cell",
              border: "none",
            }}
            headers={getAccuralHeaders()}
            onTableOptionsChange={getAccrualData}
            viewOverrides={{
              empty: { message: t("common.noResultsFound") },
              idle: { message: t("common.searchTableIdleState") },
              loading: {
                message: t("cashflow.accrualsTable.searchTableLoadingState"),
              },
            }}
            paginationOption={{
              itemsPerPage: 10,
              getPageIndicatorText(startItem, endItem, totalItems): string {
                return t("common.paginationIndicationText", {
                  startItem,
                  endItem,
                  totalItems,
                });
              },
              initialPage: store.accrualStore.accrualPageNumber + 1,
            }}
            filterOption={undefined}
            onRowClick={undefined}
          />
        </Box>
        <AccrualDialog />
      </>
    );
  },
);
