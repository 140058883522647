"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedDepositHoldingSummaryResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFixedDepositHoldingSummaryResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedDepositHoldingSummaryResponseError";
    }
}
class FixedDepositHoldingSummaryResponse {
    constructor(bank, openingAmount = null, purchaseAmount = null, closingAmount = null, interestReceived = null, tdsPaid = null, otherTaxesPaid = null, netInterestReceived = null, interestAccrued = null, tdsOnInterestAccrued = null, otherTaxesOnInterestAccrued = null, netInterestReceivable = null) {
        this.isFixedDepositHoldingSummaryResponse = true;
        FixedDepositHoldingSummaryResponse.validateBank(bank);
        this.bank = bank;
        if (openingAmount !== undefined && openingAmount !== null) {
            FixedDepositHoldingSummaryResponse.validateOpeningAmount(openingAmount);
        }
        this.openingAmount = openingAmount;
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
            FixedDepositHoldingSummaryResponse.validatePurchaseAmount(purchaseAmount);
        }
        this.purchaseAmount = purchaseAmount;
        if (closingAmount !== undefined && closingAmount !== null) {
            FixedDepositHoldingSummaryResponse.validateClosingAmount(closingAmount);
        }
        this.closingAmount = closingAmount;
        if (interestReceived !== undefined && interestReceived !== null) {
            FixedDepositHoldingSummaryResponse.validateInterestReceived(interestReceived);
        }
        this.interestReceived = interestReceived;
        if (tdsPaid !== undefined && tdsPaid !== null) {
            FixedDepositHoldingSummaryResponse.validateTdsPaid(tdsPaid);
        }
        this.tdsPaid = tdsPaid;
        if (otherTaxesPaid !== undefined && otherTaxesPaid !== null) {
            FixedDepositHoldingSummaryResponse.validateOtherTaxesPaid(otherTaxesPaid);
        }
        this.otherTaxesPaid = otherTaxesPaid;
        if (netInterestReceived !== undefined && netInterestReceived !== null) {
            FixedDepositHoldingSummaryResponse.validateNetInterestReceived(netInterestReceived);
        }
        this.netInterestReceived = netInterestReceived;
        if (interestAccrued !== undefined && interestAccrued !== null) {
            FixedDepositHoldingSummaryResponse.validateInterestAccrued(interestAccrued);
        }
        this.interestAccrued = interestAccrued;
        if (tdsOnInterestAccrued !== undefined && tdsOnInterestAccrued !== null) {
            FixedDepositHoldingSummaryResponse.validateTdsOnInterestAccrued(tdsOnInterestAccrued);
        }
        this.tdsOnInterestAccrued = tdsOnInterestAccrued;
        if (otherTaxesOnInterestAccrued !== undefined && otherTaxesOnInterestAccrued !== null) {
            FixedDepositHoldingSummaryResponse.validateOtherTaxesOnInterestAccrued(otherTaxesOnInterestAccrued);
        }
        this.otherTaxesOnInterestAccrued = otherTaxesOnInterestAccrued;
        if (netInterestReceivable !== undefined && netInterestReceivable !== null) {
            FixedDepositHoldingSummaryResponse.validateNetInterestReceivable(netInterestReceivable);
        }
        this.netInterestReceivable = netInterestReceivable;
    }
    static fromDTO(dto) {
        const bank = (0, leo_ts_runtime_1.getString)(dto, "bank", InvalidFixedDepositHoldingSummaryResponseError);
        const openingAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "openingAmount", InvalidFixedDepositHoldingSummaryResponseError);
        const purchaseAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "purchaseAmount", InvalidFixedDepositHoldingSummaryResponseError);
        const closingAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "closingAmount", InvalidFixedDepositHoldingSummaryResponseError);
        const interestReceived = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "interestReceived", InvalidFixedDepositHoldingSummaryResponseError);
        const tdsPaid = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "tdsPaid", InvalidFixedDepositHoldingSummaryResponseError);
        const otherTaxesPaid = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "otherTaxesPaid", InvalidFixedDepositHoldingSummaryResponseError);
        const netInterestReceived = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "netInterestReceived", InvalidFixedDepositHoldingSummaryResponseError);
        const interestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "interestAccrued", InvalidFixedDepositHoldingSummaryResponseError);
        const tdsOnInterestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "tdsOnInterestAccrued", InvalidFixedDepositHoldingSummaryResponseError);
        const otherTaxesOnInterestAccrued = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "otherTaxesOnInterestAccrued", InvalidFixedDepositHoldingSummaryResponseError);
        const netInterestReceivable = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "netInterestReceivable", InvalidFixedDepositHoldingSummaryResponseError);
        return new FixedDepositHoldingSummaryResponse(bank, openingAmount, purchaseAmount, closingAmount, interestReceived, tdsPaid, otherTaxesPaid, netInterestReceived, interestAccrued, tdsOnInterestAccrued, otherTaxesOnInterestAccrued, netInterestReceivable);
    }
    toDTO() {
        const ret = {
            bank: this.bank,
        };
        if (this.openingAmount !== undefined && this.openingAmount !== null) {
            ret.openingAmount = this.openingAmount;
        }
        if (this.purchaseAmount !== undefined && this.purchaseAmount !== null) {
            ret.purchaseAmount = this.purchaseAmount;
        }
        if (this.closingAmount !== undefined && this.closingAmount !== null) {
            ret.closingAmount = this.closingAmount;
        }
        if (this.interestReceived !== undefined && this.interestReceived !== null) {
            ret.interestReceived = this.interestReceived;
        }
        if (this.tdsPaid !== undefined && this.tdsPaid !== null) {
            ret.tdsPaid = this.tdsPaid;
        }
        if (this.otherTaxesPaid !== undefined && this.otherTaxesPaid !== null) {
            ret.otherTaxesPaid = this.otherTaxesPaid;
        }
        if (this.netInterestReceived !== undefined && this.netInterestReceived !== null) {
            ret.netInterestReceived = this.netInterestReceived;
        }
        if (this.interestAccrued !== undefined && this.interestAccrued !== null) {
            ret.interestAccrued = this.interestAccrued;
        }
        if (this.tdsOnInterestAccrued !== undefined && this.tdsOnInterestAccrued !== null) {
            ret.tdsOnInterestAccrued = this.tdsOnInterestAccrued;
        }
        if (this.otherTaxesOnInterestAccrued !== undefined && this.otherTaxesOnInterestAccrued !== null) {
            ret.otherTaxesOnInterestAccrued = this.otherTaxesOnInterestAccrued;
        }
        if (this.netInterestReceivable !== undefined && this.netInterestReceivable !== null) {
            ret.netInterestReceivable = this.netInterestReceivable;
        }
        return ret;
    }
    copy(bank = this.bank, openingAmount = this.openingAmount, purchaseAmount = this.purchaseAmount, closingAmount = this.closingAmount, interestReceived = this.interestReceived, tdsPaid = this.tdsPaid, otherTaxesPaid = this.otherTaxesPaid, netInterestReceived = this.netInterestReceived, interestAccrued = this.interestAccrued, tdsOnInterestAccrued = this.tdsOnInterestAccrued, otherTaxesOnInterestAccrued = this.otherTaxesOnInterestAccrued, netInterestReceivable = this.netInterestReceivable) {
        return new FixedDepositHoldingSummaryResponse(bank, openingAmount, purchaseAmount, closingAmount, interestReceived, tdsPaid, otherTaxesPaid, netInterestReceived, interestAccrued, tdsOnInterestAccrued, otherTaxesOnInterestAccrued, netInterestReceivable);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedDepositHoldingSummaryResponseValue = other.isFixedDepositHoldingSummaryResponse;
        if (!(other instanceof FixedDepositHoldingSummaryResponse) || Boolean(isFixedDepositHoldingSummaryResponseValue)) {
            return false;
        }
        if (this.bank !== other.bank) {
            return false;
        }
        if (this.openingAmount !== other.openingAmount) {
            return false;
        }
        if (this.purchaseAmount !== other.purchaseAmount) {
            return false;
        }
        if (this.closingAmount !== other.closingAmount) {
            return false;
        }
        if (this.interestReceived !== other.interestReceived) {
            return false;
        }
        if (this.tdsPaid !== other.tdsPaid) {
            return false;
        }
        if (this.otherTaxesPaid !== other.otherTaxesPaid) {
            return false;
        }
        if (this.netInterestReceived !== other.netInterestReceived) {
            return false;
        }
        if (this.interestAccrued !== other.interestAccrued) {
            return false;
        }
        if (this.tdsOnInterestAccrued !== other.tdsOnInterestAccrued) {
            return false;
        }
        if (this.otherTaxesOnInterestAccrued !== other.otherTaxesOnInterestAccrued) {
            return false;
        }
        if (this.netInterestReceivable !== other.netInterestReceivable) {
            return false;
        }
        return true;
    }
}
exports.FixedDepositHoldingSummaryResponse = FixedDepositHoldingSummaryResponse;
FixedDepositHoldingSummaryResponse.validateBank = (bank) => {
    if (!(0, leo_ts_runtime_1.isString)(bank)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute bank is not a String`);
    }
    if (bank.trim().length === 0) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute bank cannot be an empty string.`);
    }
};
FixedDepositHoldingSummaryResponse.validateOpeningAmount = (openingAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(openingAmount)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute openingAmount is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validatePurchaseAmount = (purchaseAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(purchaseAmount)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute purchaseAmount is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateClosingAmount = (closingAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(closingAmount)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute closingAmount is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateInterestReceived = (interestReceived) => {
    if (!(0, leo_ts_runtime_1.isInt64)(interestReceived)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute interestReceived is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateTdsPaid = (tdsPaid) => {
    if (!(0, leo_ts_runtime_1.isInt64)(tdsPaid)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute tdsPaid is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateOtherTaxesPaid = (otherTaxesPaid) => {
    if (!(0, leo_ts_runtime_1.isInt64)(otherTaxesPaid)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute otherTaxesPaid is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateNetInterestReceived = (netInterestReceived) => {
    if (!(0, leo_ts_runtime_1.isInt64)(netInterestReceived)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute netInterestReceived is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateInterestAccrued = (interestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(interestAccrued)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute interestAccrued is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateTdsOnInterestAccrued = (tdsOnInterestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(tdsOnInterestAccrued)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute tdsOnInterestAccrued is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateOtherTaxesOnInterestAccrued = (otherTaxesOnInterestAccrued) => {
    if (!(0, leo_ts_runtime_1.isInt64)(otherTaxesOnInterestAccrued)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute otherTaxesOnInterestAccrued is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.validateNetInterestReceivable = (netInterestReceivable) => {
    if (!(0, leo_ts_runtime_1.isInt64)(netInterestReceivable)) {
        throw new InvalidFixedDepositHoldingSummaryResponseError(`Attribute netInterestReceivable is not an Int64`);
    }
};
FixedDepositHoldingSummaryResponse.prototype.toString = function toString() {
    return `FixedDepositHoldingSummaryResponse(bank='${this.bank}',openingAmount=${this.openingAmount},purchaseAmount=${this.purchaseAmount},closingAmount=${this.closingAmount},interestReceived=${this.interestReceived},tdsPaid=${this.tdsPaid},otherTaxesPaid=${this.otherTaxesPaid},netInterestReceived=${this.netInterestReceived},interestAccrued=${this.interestAccrued},tdsOnInterestAccrued=${this.tdsOnInterestAccrued},otherTaxesOnInterestAccrued=${this.otherTaxesOnInterestAccrued},netInterestReceivable=${this.netInterestReceivable})`;
};
