import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import {
  useConfirmWithdrawRequestStore,
  useCreateWithdrawRequestStore,
} from "../store/withdrawStore/hooks";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import {
  TextInputFieldSeparateLabel,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useMemo } from "react";
import { CreateRequestDialog } from "../../../components/dialog/CreateRequestDialog";
import { AmountTextField } from "../../../components/AmountTextField";
import { Stack } from "@mui/material";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import {
  DATE_PICKER_FORMAT,
  getFormattedAmountString,
  getUnformattedAmount,
} from "../../../../../utils";
import { FDDealRequestDetailsKeys } from "../models/FDDealRequestDetailsKeys";
import { getDetailCellValue } from "../../../utils/DetailCellUtils";
import { DatePickerFieldSeparateLabel } from "../../../components/date-picker/DatePickerFieldSeparateLabel";

export interface WithdrawDialogProps {
  isConfirm: boolean;
  isOpen: boolean;
  onClose: (reload?: boolean) => void;
}

const createWithdrawDialogStyle = (
  spacing: Spacing,
): {
  [key: string]: React.CSSProperties;
} => {
  return {
    fieldContainer: {
      width: "100%",
      gap: spacing.spaceSM,
    },
    field: {
      width: "254px",
    },
  };
};

export const WithdrawDialog = observer(
  ({ isConfirm, isOpen, onClose }: WithdrawDialogProps) => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const [searchParam] = useSearchParams();
    const requestId = Number(searchParam.get("requestId"));
    const confirmStore = useConfirmWithdrawRequestStore();
    const createStore = useCreateWithdrawRequestStore();
    const detailStore = useFDDealRequestDetailsStore();
    const depositAmount = getUnformattedAmount(
      getDetailCellValue(
        detailStore.details,
        FDDealRequestDetailsKeys.depositAmount,
      ).value as string | undefined,
    );
    const outstandingDepositAmount = getUnformattedAmount(
      getDetailCellValue(
        detailStore.details,
        FDDealRequestDetailsKeys.outstandingDepositAmount,
      ).value as string | undefined,
    );
    const valueDate = getDetailCellValue(
      detailStore.details,
      FDDealRequestDetailsKeys.valueDate,
    ).value as string | undefined;

    const style = useMemo(() => createWithdrawDialogStyle(spacing), []);

    const getCommonFields = (): React.ReactElement[] => {
      let store = createStore.fields;
      if (isConfirm) {
        store = confirmStore.fields;
      }
      return [
        <Stack key={1} sx={style.fieldContainer}>
          <AmountTextField
            name={"withdrawalAmount"}
            label={t("fd.fields.withdrawalAmountLabel", {
              currencySymbol: store.currencySymbol,
            })}
            isRequired
            placeholder={t("fd.fields.withdrawalAmountPlaceholder")}
            value={store.withdrawalAmount}
            error={store.withdrawalAmountError}
            onAmountChange={store.setWithdrawalAmount}
            isDecimalAllowed={true}
          />
        </Stack>,
        <Stack key={2} sx={style.fieldContainer} direction={"row"}>
          <AmountTextField
            name={"penaltyCharge"}
            label={t("fd.fields.penaltyChargeLabel")}
            error={store.penaltyChargeError}
            placeholder={t("fd.fields.penaltyChargePlaceholder")}
            isRequired
            value={store.penaltyCharges}
            onAmountChange={store.setPenaltyCharges}
            isDecimalAllowed={true}
          />
          <AmountTextField
            name={"penaltyAmount"}
            label={t("fd.fields.penaltyAmountLabel", {
              currencySymbol: store.currencySymbol,
            })}
            placeholder={t("fd.fields.penaltyAmountPlaceholder")}
            isRequired
            error={store.penaltyAmountError}
            value={store.penaltyAmount}
            onAmountChange={store.setPenaltyAmount}
            isDecimalAllowed={true}
          />
        </Stack>,
      ];
    };

    const getConfirmForm = (): React.ReactElement[] => {
      return [
        ...getCommonFields(),
        <Stack key={"confirm"} sx={style.fieldContainer} direction={"row"}>
          <DatePickerFieldSeparateLabel
            style={style.field}
            label={t("fd.fields.withdrawalDateLabel")}
            isRequired={true}
            minDate={valueDate ? new Date(valueDate) : undefined}
            value={confirmStore.fields.actualWithdrawalDate ?? null}
            onChange={(date) =>
              confirmStore.fields.setActualWithdrawalDate(date)
            }
            onError={(error) => {
              if (error) {
                confirmStore.fields.setActualDateError(true);
              } else {
                confirmStore.fields.setActualDateError(false);
              }
            }}
            format={DATE_PICKER_FORMAT}
          />
          <TextInputFieldSeparateLabel
            style={style.field}
            name={"referenceNumber"}
            value={confirmStore.fields.withdrawalReferenceNumber}
            type={"text"}
            onTextChange={confirmStore.fields.setWithdrawalReferenceNumber}
            label={t("fd.fields.referenceNumberLabel")}
            placeholder={t("fd.fields.referenceNumberPlaceholder")}
          />
        </Stack>,
      ];
    };

    const getDialogChildren = (): React.ReactElement[] => {
      if (isConfirm) {
        return getConfirmForm();
      } else {
        return getCommonFields();
      }
    };

    return (
      <CreateRequestDialog
        title={
          isConfirm
            ? t("fd.withdraw.confirmTitle")
            : t("fd.withdraw.createTitle")
        }
        warning={
          confirmStore.fields.showWarning ?? createStore.fields.showWarning
        }
        primaryButtonText={t("common.submit")}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (isConfirm) {
            if (
              (confirmStore.fields.withdrawalAmount ?? 0) > (depositAmount ?? 0)
            ) {
              confirmStore.fields.setShowWarning(
                t("fd.dealRequest.errors.withdrawWarningMessage"),
              );
            } else if (confirmStore.fields.isConfirmValid()) {
              await confirmStore.confirmWithdrawRequest(requestId);
              if (!confirmStore.fields.showWarning) {
                confirmStore.reset();
                onClose(true);
              }
            }
          } else {
            if (
              (createStore.fields.withdrawalAmount ?? 0) > (depositAmount ?? 0)
            ) {
              createStore.fields.setShowWarning(
                t("fd.dealRequest.errors.withdrawWarningMessage"),
              );
            } else if (createStore.fields.isCreateValid()) {
              await createStore.createWithdrawRequest(requestId);
              if (!createStore.fields.showWarning) {
                createStore.reset();
                onClose(true);
              }
            }
          }
        }}
        secondaryButtonText={t("common.cancel")}
        onSecondaryButtonClick={(): void => {
          if (isConfirm) {
            confirmStore.reset();
          } else {
            createStore.reset();
          }
          onClose();
        }}
        subtitle={{
          title: t("fd.fields.outstandingDepositAmountLabel", {
            currencySymbol: createStore.fields.currencySymbol,
          }),
          value: getFormattedAmountString(
            outstandingDepositAmount ?? depositAmount ?? 0,
          ),
        }}
        isCloseIconPresent={false}
        isOpen={isOpen}
        onClose={(): void => {
          confirmStore.reset();
          createStore.reset();
          onClose();
        }}
      >
        {getDialogChildren()}
        <Stack key={"note"} sx={style.fieldContainer}>
          <NoteTextArea
            note={isConfirm ? confirmStore.note : createStore.note}
            setNote={isConfirm ? confirmStore.setNote : createStore.setNote}
          />
        </Stack>
      </CreateRequestDialog>
    );
  },
);
