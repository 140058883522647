import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  LoadingIndicator,
  spacing,
  useCornerRadius,
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../../utils/BorderUtils";
import { DataSeries, LineChart } from "../charts/LineChart";
import { DurationToggleButton } from "../toggleButton/DurationToggleButton";
import { ReportDateRange } from "../../equity/models/EquityReportDateRangeModel";
import { Instance } from "mobx-state-tree";
import { DateRangeModel } from "../../equity/models/DateRangeModel";

const Size = {
  contentHeight: "346px",
};

export interface DashboardLineGraphProps {
  title: string;
  series: DataSeries[];
  isLoading?: boolean;
  duration?: ReportDateRange | Instance<typeof DateRangeModel>;
  onDurationChange?: (
    value: ReportDateRange | { startDate: Date | null; endDate: Date | null },
  ) => void;
}

export const DashboardLineGraph = ({
  title,
  series,
  isLoading,
  duration,
  onDurationChange,
}: DashboardLineGraphProps): React.ReactElement => {
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  return (
    <Stack
      sx={{
        flexGrow: 1,
        border: border.default,
        background: tokens.backgroundElevatedLevel1,
        borderRadius: cornerRadius.radiusXS,
        padding: spacing.spaceXL,
        gap: spacing.spaceLG,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography
          sx={{ typography: typography.sh3, color: tokens.labelHighEmphasis }}
        >
          {title}
        </Typography>
        {duration && (
          <Stack>
            <DurationToggleButton
              value={duration}
              onChange={(value): void => {
                if (onDurationChange) {
                  onDurationChange(value);
                }
              }}
            />
          </Stack>
        )}
      </Stack>
      {isLoading ? (
        <Stack height={Size.contentHeight}>
          <LoadingIndicator isLoading={isLoading} variant={"container"} />
        </Stack>
      ) : (
        <LineChart series={series} />
      )}
    </Stack>
  );
};
