import { Stack } from "@mui/material";
import React from "react";
import { Section, SectionError } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useMFDealHistoryStore } from "../store/DealRequestDetailsStore/hooks";
import { DealHistoryRow } from "../../../components/deal-history/DealHistoryRow";
import { getMFHistoryTitleText, getMFStatusColor } from "../utils/UIUtils";
import { getRequestStatusValue } from "../utils/SearchUtils";
import { ImpactOnPortfolio } from "../../../components/ImpactOnPortfolio";
import { SchemeDetails } from "./SchemeDetails";

const Size = {
  section: "100%",
};

export const MFDealRequestHistorySection = (): React.ReactElement => {
  const { t } = useTranslation();
  const store = useMFDealHistoryStore();
  const [searchParam] = useSearchParams();
  const requestId = searchParam.get("requestId");

  async function fetchDealRequestHistory(): Promise<
    SectionError | React.ReactElement[]
  > {
    if (requestId) {
      await store.getMFDealRequestHistory(Number(requestId));
    } else {
      console.error("No requestId found while fetching deal request history");
    }

    const mfHistory = store.historyDetails;

    if (mfHistory) {
      const history = [...mfHistory];
      if (history && history.length > 0) {
        return history.map((value, index) => {
          const additionalDetails = [];
          if (value.impactTable) {
            additionalDetails.push(
              <ImpactOnPortfolio impactTableModel={value.impactTable} />,
            );
          }
          if (value.schemeDetails) {
            additionalDetails.push(
              <SchemeDetails store={value.schemeDetails} />,
            );
          }
          return history && value.requestNote?.requestedAt && value.status ? (
            <Stack key={index}>
              <DealHistoryRow
                firstName={value.requestNote.userName.firstName}
                lastName={value.requestNote.userName.lastName}
                requestedAt={value.requestNote.requestedAt}
                note={value.requestNote?.note}
                isHistoryComponent={true}
                userImage={value.requestNote.profilePhoto?.toString()}
                title={getMFHistoryTitleText(
                  value.requestNote.userName.firstName,
                  value.requestNote.userName.lastName,
                  value.status,
                )}
                chipText={getRequestStatusValue(
                  value.status,
                ).toLocaleUpperCase()}
                chipColor={getMFStatusColor(value.status)}
                additionalDetails={additionalDetails}
              />
            </Stack>
          ) : (
            <></>
          );
        });
      } else {
        console.error(`History Data is missing for mutual fund deal request`);
      }
    }
    return [];
  }

  return (
    <Section
      title={t("common.dealRequestHistory")}
      viewOverrides={{
        empty: { message: t("common.noResultsFound") },
        loading: { message: t("common.fetchingHistory") },
      }}
      onExpand={fetchDealRequestHistory}
      expandIndicator={t("common.showMore")}
      collapseIndicator={t("common.showLess")}
      styleOverrides={{ width: Size.section }}
    />
  );
};
