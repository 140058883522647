import React from "react";
import { Section, SectionError } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getMaturityTitleText } from "../pages/ViewFiMaturityRequestDetails";
import { getMaturityRequestStatusValue } from "../utils/SearchUtils";
import { getFiMaturityStatusColor } from "../utils/UIUtils";
import { useViewFiMaturityRequestDetailsStore } from "../store/hooks";
import { DealStatusSection } from "../../../components/DealStatusSection";

const Size = {
  section: "100%",
};

export const FiMaturityRequestHistorySection = (): React.ReactElement => {
  const { t } = useTranslation();
  const store = useViewFiMaturityRequestDetailsStore();
  const [searchParam] = useSearchParams();
  const requestId = Number(searchParam.get("requestId"));

  async function fetchDealRequestHistory(): Promise<
    SectionError | React.ReactElement[]
  > {
    if (requestId) {
      await store.getFiMaturityRequestHistory(requestId);
    } else {
      console.error("No requestId found while fetching deal request history");
    }

    const fiHistory = store.fiDealRequestHistoryDetail;

    if (fiHistory) {
      const history = [...fiHistory];

      if (history && history.length > 0) {
        return history.map((value, index) => {
          return history && value.requestNote?.requestedAt && value.status ? (
            <DealStatusSection
              key={index}
              firstName={value.requestNote?.userName.firstName}
              lastName={value.requestNote?.userName.lastName}
              requestedAt={value.requestNote?.requestedAt}
              note={value.requestNote?.note}
              isHistoryComponent={true}
              userImage={value.requestNote.profilePhoto?.toString()}
              title={getMaturityTitleText(value, value.status, t)}
              chipText={getMaturityRequestStatusValue(
                t,
                value.status,
              ).toLocaleUpperCase()}
              chipColor={getFiMaturityStatusColor(value.status)}
              assetName={null}
              impactBuyChart={undefined}
              impactSellChart={undefined}
              impactOnPortfolio={undefined}
              transactionType={null}
            />
          ) : (
            <></>
          );
        });
      } else {
        console.error(
          `History Data is missing for fixed income maturity request`,
        );
      }
    } else if (store.error) {
      console.error(
        `Error: ${store.error} occurred while showing fixed income maturity request history`,
      );
      return t("common.somethingWentWrongProcessingRequest");
    }
    return [];
  }

  return (
    <Section
      title={t("common.maturityRequestHistory")}
      viewOverrides={{
        empty: { message: t("common.noResultsFound") },
        loading: { message: t("common.fetchingHistory") },
      }}
      onExpand={fetchDealRequestHistory}
      expandIndicator={t("common.showMore")}
      collapseIndicator={t("common.showLess")}
      styleOverrides={{ width: Size.section }}
    />
  );
};
