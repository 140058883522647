import { Module, Route } from "../routes/RoutesEnum";

export const getPath = (
  module: Module,
  route: Route,
  params?: string,
): string => {
  if (params) {
    return `/${module}/${route}${params}`;
  } else {
    return `/${module}/${route}`;
  }
};

export const getFiCashflowDetailsPath = (
  investmentId?: number,
  contractNoteId?: number,
  maturityRequestId?: number,
): string | undefined => {
  const route =
    contractNoteId !== undefined
      ? Route.ContractNoteInvestmentCashFlowsWithParams
      : Route.MaturityInvestmentCashFlowsWithParams;
  const suffix =
    contractNoteId !== undefined
      ? `&contractNoteId=${contractNoteId}`
      : `&maturityRequestId=${maturityRequestId}`;
  return `${getPath(Module.Fi, route, investmentId?.toString())}${suffix}`;
};

export const getFdCashflowDetailsPath = (
  investmentId?: number,
  dealRequestId?: number,
): string | undefined => {
  return `${getPath(
    Module.Fd,
    Route.DealInvestmentCashFlowsWithParams,
    investmentId?.toString(),
  )}&dealRequestId=${dealRequestId}`;
};
