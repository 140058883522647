import {
  applySnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createMFDealRequestHistoryModel,
  MFDealRequestHistoryModel,
} from "../../models/MFDealRequestHistoryModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetMFDealRequestDetailsBannerInfoRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetMFDealRequestDetailsBannerInfoRPC } from "../../rpc/RPC";
import { MFDealDetailsPageErrors } from "./DealDetailsErrorStore";
import { mfDealDetailsErrorStore } from "./hooks";

export const MFDealRequestBannerInfoStore = types
  .model("MFDealRequestBannerInfoStore", {
    bannerInfo: types.maybe(MFDealRequestHistoryModel),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFDealRequestDetailsBannerInfo: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFDealRequestDetailsBannerInfoRPC.Request(
          requestId,
        );
        const result: LeoRPCResult<
          GetMFDealRequestDetailsBannerInfoRPC.Response,
          GetMFDealRequestDetailsBannerInfoRPC.Errors.Errors
        > =
          yield useGetMFDealRequestDetailsBannerInfoRPC(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.bannerInfo = createMFDealRequestHistoryModel(
            response.requestDetails,
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case MFDealDetailsPageErrors.InvalidRequestId:
              const errorStore = mfDealDetailsErrorStore(store);
              errorStore.setError(MFDealDetailsPageErrors.InvalidRequestId);
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getMFDealRequestDetailsBannerInfoRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createMFDealRequestBannerInfoStore = (): Instance<
  typeof MFDealRequestBannerInfoStore
> => {
  return MFDealRequestBannerInfoStore.create({});
};
