import { EquityDealRequestStatus } from "@khazana/khazana-rpcs";
import {
  ChipColor,
  AutoCompleteItem,
  FoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { Instance } from "mobx-state-tree";
import { EquityModel } from "../models/EquityModel";
import { DealType } from "../../../../../types/EnumTypes";

export const getEQStatusColor = (
  status: EquityDealRequestStatus.EquityDealRequestStatus,
): ChipColor => {
  switch (status) {
    case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_PENDING:
    case EquityDealRequestStatus.EquityDealRequestStatus.IC_PENDING:
    case EquityDealRequestStatus.EquityDealRequestStatus.IC_APPROVED:
    case EquityDealRequestStatus.EquityDealRequestStatus.DEAL_OPEN:
    case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_APPROVED:
      return "orange";
    case EquityDealRequestStatus.EquityDealRequestStatus.CANCELLED:
    case EquityDealRequestStatus.EquityDealRequestStatus.EXPIRED:
      return "neutral";
    case EquityDealRequestStatus.EquityDealRequestStatus.ACCOUNTS_REJECTED:
    case EquityDealRequestStatus.EquityDealRequestStatus.IC_REJECTED:
      return "red";
    case EquityDealRequestStatus.EquityDealRequestStatus.SETTLED:
    case EquityDealRequestStatus.EquityDealRequestStatus.SYSTEM_SETTLED:
      return "green";
  }
};

export const getSymbolsDropdownOptions = (
  equityList: Instance<typeof EquityModel>[],
): AutoCompleteItem[] => {
  return equityList.map((equity) => ({
    id: equity.isin.isin,
    label: equity.legalName,
    subLabel: equity.isin.isin,
  }));
};

export const getDealRequestTabBackgroundColor = (
  dealType: DealType,
  tokens: FoundationColorTokens<string>,
): string => {
  switch (dealType) {
    case DealType.Buy:
      return tokens.backgroundInfoSubtle;
    case DealType.Sell:
      return tokens.backgroundWarningSubtle;
  }
};
