"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiMaturityCharge = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
class InvalidFiMaturityChargeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiMaturityChargeError";
    }
}
class FiMaturityCharge {
    constructor(displayName, chargeType, chargeRuleId = null, amount = null) {
        this.isFiMaturityCharge = true;
        FiMaturityCharge.validateDisplayName(displayName);
        this.displayName = displayName;
        FiMaturityCharge.validateChargeType(chargeType);
        this.chargeType = chargeType;
        if (chargeRuleId !== undefined && chargeRuleId !== null) {
            FiMaturityCharge.validateChargeRuleId(chargeRuleId);
        }
        this.chargeRuleId = chargeRuleId;
        if (amount !== undefined && amount !== null) {
            FiMaturityCharge.validateAmount(amount);
        }
        this.amount = amount;
    }
    static fromDTO(dto) {
        const displayName = (0, leo_ts_runtime_1.getString)(dto, "displayName", InvalidFiMaturityChargeError);
        const chargeType = (0, leo_ts_runtime_1.getString)(dto, "chargeType", InvalidFiMaturityChargeError);
        const chargeRuleId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "chargeRuleId", InvalidFiMaturityChargeError);
        let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
        if (amount !== undefined && amount !== null) {
            amount = bigDecimal_1.BigDecimal.fromDTO(amount);
        }
        return new FiMaturityCharge(displayName, chargeType, chargeRuleId, amount);
    }
    toDTO() {
        const ret = {
            displayName: this.displayName,
            chargeType: this.chargeType,
        };
        if (this.chargeRuleId !== undefined && this.chargeRuleId !== null) {
            ret.chargeRuleId = this.chargeRuleId;
        }
        if (this.amount) {
            ret.amount = this.amount.toDTO();
        }
        return ret;
    }
    copy(displayName = this.displayName, chargeType = this.chargeType, chargeRuleId = this.chargeRuleId, amount = this.amount) {
        return new FiMaturityCharge(displayName, chargeType, chargeRuleId, amount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiMaturityChargeValue = other.isFiMaturityCharge;
        if (!(other instanceof FiMaturityCharge) || Boolean(isFiMaturityChargeValue)) {
            return false;
        }
        if (this.displayName !== other.displayName) {
            return false;
        }
        if (this.chargeType !== other.chargeType) {
            return false;
        }
        if (this.chargeRuleId !== other.chargeRuleId) {
            return false;
        }
        if (this.amount) {
            if (!this.amount.equals(other.amount)) {
                return false;
            }
        }
        return true;
    }
}
exports.FiMaturityCharge = FiMaturityCharge;
FiMaturityCharge.validateDisplayName = (displayName) => {
    if (!(0, leo_ts_runtime_1.isString)(displayName)) {
        throw new InvalidFiMaturityChargeError(`Attribute displayName is not a String`);
    }
    if (displayName.trim().length === 0) {
        throw new InvalidFiMaturityChargeError(`Attribute displayName cannot be an empty string.`);
    }
};
FiMaturityCharge.validateChargeType = (chargeType) => {
    if (!(0, leo_ts_runtime_1.isString)(chargeType)) {
        throw new InvalidFiMaturityChargeError(`Attribute chargeType is not a String`);
    }
    if (chargeType.trim().length === 0) {
        throw new InvalidFiMaturityChargeError(`Attribute chargeType cannot be an empty string.`);
    }
};
FiMaturityCharge.validateChargeRuleId = (chargeRuleId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(chargeRuleId)) {
        throw new InvalidFiMaturityChargeError(`Attribute chargeRuleId is not an Int64`);
    }
};
FiMaturityCharge.validateAmount = (amount) => {
    const isBigDecimalValue = amount.isBigDecimal;
    if (!(amount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidFiMaturityChargeError(`Attribute amount is not a BigDecimal`);
    }
};
FiMaturityCharge.prototype.toString = function toString() {
    return `FiMaturityCharge(displayName='${this.displayName}',chargeType='${this.chargeType}',chargeRuleId=${this.chargeRuleId},amount=${this.amount})`;
};
