"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiCashflowsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getFiCashflowsSortOrder_1 = require("./getFiCashflowsSortOrder");
const fixedIncomeItemsPerPage_1 = require("./fixedIncomeItemsPerPage");
const fixedIncomePageIndex_1 = require("./fixedIncomePageIndex");
const getFiCashflowsPaginationResponse_1 = require("./getFiCashflowsPaginationResponse");
/* eslint-disable import/export */
class GetFiCashflowsRPC {
}
exports.GetFiCashflowsRPC = GetFiCashflowsRPC;
(function (GetFiCashflowsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiCashflowsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, purchaseTransactionId = null, itemsPerPage, pageIndex) {
            this.isGetFiCashflowsRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (purchaseTransactionId !== undefined && purchaseTransactionId !== null) {
                Request.validatePurchaseTransactionId(purchaseTransactionId);
            }
            this.purchaseTransactionId = purchaseTransactionId;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getFiCashflowsSortOrder_1.GetFiCashflowsSortOrder.fromDTO, InvalidRequestError);
            const purchaseTransactionId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "purchaseTransactionId", InvalidRequestError);
            const itemsPerPage = fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = fixedIncomePageIndex_1.FixedIncomePageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, purchaseTransactionId, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.purchaseTransactionId !== undefined && this.purchaseTransactionId !== null) {
                ret.purchaseTransactionId = this.purchaseTransactionId;
            }
            return ret;
        }
        copy(sortList = this.sortList, purchaseTransactionId = this.purchaseTransactionId, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, purchaseTransactionId, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiCashflowsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.purchaseTransactionId !== other.purchaseTransactionId) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetFiCashflowsSortOrderValue = sortList[i].isGetFiCashflowsSortOrder;
            if (!(sortList[i] instanceof getFiCashflowsSortOrder_1.GetFiCashflowsSortOrder || Boolean(isGetFiCashflowsSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetFiCashflowsSortOrder.`);
            }
        }
    };
    Request.validatePurchaseTransactionId = (purchaseTransactionId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(purchaseTransactionId)) {
            throw new InvalidRequestError(`Attribute purchaseTransactionId is not an Int64`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isFixedIncomeItemsPerPageValue = itemsPerPage.isFixedIncomeItemsPerPage;
        if (!(itemsPerPage instanceof fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage || Boolean(isFixedIncomeItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a FixedIncomeItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isFixedIncomePageIndexValue = pageIndex.isFixedIncomePageIndex;
        if (!(pageIndex instanceof fixedIncomePageIndex_1.FixedIncomePageIndex || Boolean(isFixedIncomePageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a FixedIncomePageIndex`);
        }
    };
    GetFiCashflowsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiCashflowsRPC.Request(sortList=${this.sortList},purchaseTransactionId=${this.purchaseTransactionId},itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiCashflowsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getFiCashflowsPaginationResponse, totalItems) {
            this.isGetFiCashflowsRPCResponse = true;
            Response.validateGetFiCashflowsPaginationResponse(getFiCashflowsPaginationResponse);
            this.getFiCashflowsPaginationResponse = getFiCashflowsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getFiCashflowsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getFiCashflowsPaginationResponse", getFiCashflowsPaginationResponse_1.GetFiCashflowsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getFiCashflowsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getFiCashflowsPaginationResponse: this.getFiCashflowsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getFiCashflowsPaginationResponse = this.getFiCashflowsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getFiCashflowsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiCashflowsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getFiCashflowsPaginationResponse, other.getFiCashflowsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetFiCashflowsPaginationResponse = (getFiCashflowsPaginationResponse) => {
        if (!Array.isArray(getFiCashflowsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getFiCashflowsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getFiCashflowsPaginationResponse.length; i += 1) {
            const isGetFiCashflowsPaginationResponseValue = getFiCashflowsPaginationResponse[i].isGetFiCashflowsPaginationResponse;
            if (!(getFiCashflowsPaginationResponse[i] instanceof getFiCashflowsPaginationResponse_1.GetFiCashflowsPaginationResponse || Boolean(isGetFiCashflowsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getFiCashflowsPaginationResponse at index ${i} is not a GetFiCashflowsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetFiCashflowsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiCashflowsRPC.Response(getFiCashflowsPaginationResponse=${this.getFiCashflowsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetFiCashflowsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiCashflowsRPC.Errors || (GetFiCashflowsRPC.Errors = {}));
})(GetFiCashflowsRPC = exports.GetFiCashflowsRPC || (exports.GetFiCashflowsRPC = {}));
