import { Box, Stack, Typography } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import {
  Avatar,
  Dialog,
  EllipsisTypography,
  IconButton,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { SignOut } from "../assets/SignOut";
import { sidebarTopListData } from "../utils/SidebarUtils";
import { useUserStore } from "../modules/home/store/hooks";
import { useKeycloak } from "keycloak-react-web";
import { useRootStore } from "../modules/root/store/RootStore";
import { Khazana } from "../assets/Khazana";
import { Route } from "../routes/RoutesEnum";
import { observer } from "mobx-react";
import { Sidebar } from "./sidebar/Sidebar";

const Size = {
  container: "256px",
  logo: {
    width: "240px",
    height: "85px",
  },
  profileWidth: "144px",
};

export const SidebarComponent = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const userStore = useUserStore();
  const { keycloak } = useKeycloak();
  const store = useRootStore();
  const spacing = useSpacing();
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const [isSignOutOpen, setSignOutOpen] = useState(false);
  const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);

  const sidebarBottomElement = (): React.ReactElement => {
    return (
      <Stack
        direction={"row"}
        spacing={spacing.spaceXS}
        padding={spacing.spaceSM}
        alignItems={"center"}
        position={"sticky"}
        bottom={spacing.spaceMD}
        flex={"none"}
        order={2}
      >
        <Avatar size={"medium"} userName={userStore.fullName} />
        <Stack>
          <EllipsisTypography
            width={Size.profileWidth}
            ellipseTypography={typography.s1}
            textColor={tokens.label}
            disableTooltip={false}
          >
            {userStore.fullName}
          </EllipsisTypography>
          <EllipsisTypography
            width={Size.profileWidth}
            ellipseTypography={typography.b3}
            textColor={tokens.labelSubtle}
            disableTooltip={false}
          >
            {userStore.emailId}
          </EllipsisTypography>
        </Stack>
        <IconButton
          name={"signOut"}
          size={"medium"}
          icon={<SignOut />}
          variant={"plain-neutral"}
          onClick={() => setSignOutOpen(true)}
        />
      </Stack>
    );
  };

  return (
    <Box sx={{ width: Size.container, flexShrink: 0 }}>
      <Dialog
        open={isSignOutOpen}
        title={t("common.signOutDialog")}
        primaryButtonText={t("common.signOut")}
        onPrimaryButtonClick={async (): Promise<void> => {
          setIsCloseIconPresent(false);
          store.signOutUser();
          await keycloak.logout({
            redirectUri: `${window.location.protocol}//${window.location.host}`,
          });
          setIsCloseIconPresent(true);
          navigate(Route.Root);
        }}
        isCloseIconPresent={isCloseIconPresent}
        onClose={(): void => {
          setSignOutOpen(false);
        }}
        disableBackdropClick
      >
        <Typography>{t("common.signOutConfirmation")}</Typography>
      </Dialog>
      <Sidebar
        open={true}
        list={sidebarTopListData(t, userStore.privileges, store.homeStore)}
        logo={<Khazana height={Size.logo.height} width={Size.logo.width} />}
        currentPath={pathname}
        setCurrentPath={(path: string): void => {
          navigate(path);
        }}
        isCollapsible={false}
        bottomElement={sidebarBottomElement()}
      />
    </Box>
  );
});
