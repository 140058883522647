import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Router as AppRouter } from "./routes/routes";
import "./i18n/config";
import { ThemeConfig } from "@surya-digital/leo-reactjs-material-ui";
import { lightTheme } from "./theme/color-tokens/lightColorTokens";
import { RootStoreProvider } from "./modules/root/store/RootStore";
import { darkTheme } from "./theme/color-tokens/darkColorTokens";

export function App(): JSX.Element {
  return (
    <ThemeConfig currentTheme="light" themes={[lightTheme, darkTheme]}>
      <RootStoreProvider>
        <Router>
          <AppRouter />
        </Router>
      </RootStoreProvider>
    </ThemeConfig>
  );
}
