"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBankAccountListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bankAccount_1 = require("../types/bankAccount");
/* eslint-disable import/export */
class GetBankAccountListRPC {
}
exports.GetBankAccountListRPC = GetBankAccountListRPC;
(function (GetBankAccountListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetBankAccountListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(bankId = null) {
            this.isGetBankAccountListRPCRequest = true;
            if (bankId !== undefined && bankId !== null) {
                Request.validateBankId(bankId);
            }
            this.bankId = bankId;
        }
        static fromDTO(dto) {
            const bankId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "bankId", InvalidRequestError);
            return new Request(bankId);
        }
        toDTO() {
            const ret = {};
            if (this.bankId) {
                ret.bankId = this.bankId.toDTO();
            }
            return ret;
        }
        copy(bankId = this.bankId) {
            return new Request(bankId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetBankAccountListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.bankId) {
                if (!this.bankId.equals(other.bankId)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateBankId = (bankId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(bankId)) {
            throw new InvalidRequestError(`Attribute bankId is not a UUID`);
        }
    };
    GetBankAccountListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetBankAccountListRPC.Request(bankId=${this.bankId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetBankAccountListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(bankAccounts) {
            this.isGetBankAccountListRPCResponse = true;
            Response.validateBankAccounts(bankAccounts);
            this.bankAccounts = bankAccounts;
        }
        static fromDTO(dto) {
            const bankAccounts = (0, leo_ts_runtime_1.getList)(dto, "bankAccounts", bankAccount_1.BankAccount.fromDTO, InvalidResponseError);
            return new Response(bankAccounts);
        }
        toDTO() {
            const ret = {
                bankAccounts: this.bankAccounts.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(bankAccounts = this.bankAccounts) {
            return new Response(bankAccounts);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetBankAccountListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.bankAccounts, other.bankAccounts)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBankAccounts = (bankAccounts) => {
        if (!Array.isArray(bankAccounts)) {
            throw new InvalidResponseError(`Attribute bankAccounts is not a List`);
        }
        for (let i = 0; i < bankAccounts.length; i += 1) {
            const isBankAccountValue = bankAccounts[i].isBankAccount;
            if (!(bankAccounts[i] instanceof bankAccount_1.BankAccount || Boolean(isBankAccountValue))) {
                throw new InvalidResponseError(`Attribute bankAccounts at index ${i} is not a BankAccount.`);
            }
        }
    };
    GetBankAccountListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetBankAccountListRPC.Response(bankAccounts=${this.bankAccounts})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetBankAccountListRPC.Errors || (GetBankAccountListRPC.Errors = {}));
})(GetBankAccountListRPC = exports.GetBankAccountListRPC || (exports.GetBankAccountListRPC = {}));
