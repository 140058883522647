import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetMFConfirmationHistoryRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetMFConfirmationHistoryRPC } from "../../rpc/RPC";
import { mfConfirmationErrorStore } from "./hooks";
import {
  createMFConfirmationHistoryDetailModel,
  MFConfirmationHistoryDetailModel,
} from "../../models/MFConfirmationHistoryDetailModel";

export const ConfirmationRequestHistoryStore = types
  .model("ConfirmationRequestHistoryStore", {
    isLoading: types.optional(types.boolean, false),
    historyDetails: types.array(MFConfirmationHistoryDetailModel),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFConfirmationHistory: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFConfirmationHistoryRPC.Request(requestId);
        store.isLoading = true;
        const result: LeoRPCResult<
          GetMFConfirmationHistoryRPC.Response,
          GetMFConfirmationHistoryRPC.Errors.Errors
        > = yield useGetMFConfirmationHistoryRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.historyDetails = cast(
            response.historyDetails.map((detail) =>
              createMFConfirmationHistoryDetailModel(detail),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const errorStore = mfConfirmationErrorStore(store);
          errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error");
        }
        store.isLoading = false;
      }),
    };
  });

export const createConfirmationRequestHistoryStore = (): Instance<
  typeof ConfirmationRequestHistoryStore
> => {
  return ConfirmationRequestHistoryStore.create({});
};
