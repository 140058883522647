import { CheckMFFolioCreationRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockCheckMFFolioCreationRequestRPCImpl extends CheckMFFolioCreationRequestRPC {
  execute(
    _request: CheckMFFolioCreationRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      CheckMFFolioCreationRequestRPC.Response,
      CheckMFFolioCreationRequestRPC.Errors.Errors
    >
  > {
    const response = new CheckMFFolioCreationRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        CheckMFFolioCreationRequestRPC.Response,
        CheckMFFolioCreationRequestRPC.Errors.Errors
      >
    >;
  }
}
