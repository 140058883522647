import { Instance, types } from "mobx-state-tree";
import { MFDealRequestAction } from "@khazana/khazana-rpcs";

export const MFDealRequestActionModel = types.model({
  allowCancel: types.optional(types.boolean, false),
  allowCheck: types.optional(types.boolean, false),
  allowSettle: types.optional(types.boolean, false),
  allowCreateOrder: types.optional(types.boolean, false),
  allowEditOrder: types.optional(types.boolean, false),
});

export const createMFDealRequestActionModel = (
  mfDealRequestAction?: MFDealRequestAction,
): Instance<typeof MFDealRequestActionModel> => {
  if (mfDealRequestAction) {
    return MFDealRequestActionModel.create({
      allowCancel: mfDealRequestAction.allowCancel,
      allowCheck: mfDealRequestAction.allowCheck,
      allowSettle: mfDealRequestAction.allowSettle,
      allowCreateOrder: mfDealRequestAction.allowCreateOrder,
      allowEditOrder: mfDealRequestAction.allowEditOrder,
    });
  }
  return MFDealRequestActionModel.create();
};
