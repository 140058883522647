import {
  BigDecimal,
  Currency,
  GetMFConfirmationRequestsPaginationResponse,
  GetMFConfirmationRequestsRPC,
  MFConfirmationRequestStatus,
} from "@khazana/khazana-rpcs";
import {
  LeoRPCResult,
  LeoTimestamp,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFConfirmationRequestsRPCImpl extends GetMFConfirmationRequestsRPC {
  execute(
    _request: GetMFConfirmationRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFConfirmationRequestsRPC.Response,
      GetMFConfirmationRequestsRPC.Errors.Errors
    >
  > {
    const response = new GetMFConfirmationRequestsRPC.Response(
      [
        new GetMFConfirmationRequestsPaginationResponse(
          1,
          MFConfirmationRequestStatus.MFConfirmationRequestStatus.ACCOUNTS_PENDING,
          "CLSA",
          new LeoUUID(),
          "Aditya Birla Sun Life AMC Limited",
          "Aditya Birla Sun Life Global Emerging Opportunities Fund",
          "A01CM2007",
          new BigDecimal("20000000"),
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
        new GetMFConfirmationRequestsPaginationResponse(
          2,
          MFConfirmationRequestStatus.MFConfirmationRequestStatus.ACCOUNTS_PENDING,
          "CLSA",
          new LeoUUID(),
          "Aditya Birla Sun Life AMC Limited",
          "Aditya Birla Sun Life Global Emerging Opportunities Fund",
          "A01CM2007",
          new BigDecimal("20000000"),
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
        new GetMFConfirmationRequestsPaginationResponse(
          3,
          MFConfirmationRequestStatus.MFConfirmationRequestStatus.ACCOUNTS_PENDING,
          "CLSA",
          new LeoUUID(),
          "Aditya Birla Sun Life AMC Limited",
          "Aditya Birla Sun Life Global Emerging Opportunities Fund",
          "A01CM2007",
          new BigDecimal("20000000"),
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
        new GetMFConfirmationRequestsPaginationResponse(
          4,
          MFConfirmationRequestStatus.MFConfirmationRequestStatus.ACCOUNTS_PENDING,
          "CLSA",
          new LeoUUID(),
          "Aditya Birla Sun Life AMC Limited",
          "Aditya Birla Sun Life Global Emerging Opportunities Fund",
          "A01CM2007",
          new BigDecimal("20000000"),
          new LeoTimestamp(),
          new Currency("INR", "₹"),
        ),
      ],
      4,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFConfirmationRequestsRPC.Response,
        GetMFConfirmationRequestsRPC.Errors.Errors
      >
    >;
  }
}
