import {
  GetBrokerListRPC,
  GetBrokerListRPCClientImpl,
  GetFiContractNoteChargesRPC,
  GetFiContractNoteChargesRPCClientImpl,
  GetFiContractNoteDetailsRPC,
  GetFiContractNoteDetailsRPCClientImpl,
  GetFiContractNotesRPC,
  GetFiContractNotesRPCClientImpl,
  GetPresignedUploadUrlRPC,
  GetPresignedUploadUrlRPCClientImpl,
  SubmitFiDealContractNoteRPC,
  SubmitFiDealContractNoteRPCClientImpl,
  FiValidateContractNoteRequestRPC,
  FiValidateContractNoteRequestRPCClientImpl,
  FiSubmitContractNoteRequestRPC,
  FiSubmitContractNoteRequestRPCClientImpl,
  GetFiContractNoteHistoryRPC,
  GetFiContractNoteHistoryRPCClientImpl,
  CheckFiContractNoteRequestRPC,
  CheckFiContractNoteRequestRPCClientImpl,
  FiSubmitContractNoteDiscardRequestRPC,
  FiSubmitContractNoteDiscardRequestRPCClientImpl,
  FiSubmitContractNoteUnknownRequestRPC,
  FiSubmitContractNoteUnknownRequestRPCClientImpl,
  GetFiApprovedDealRequestsRPC,
  GetFiApprovedDealRequestsRPCClientImpl,
  FiSubmitContractNoteLinkRequestRPC,
  FiSubmitContractNoteLinkRequestRPCClientImpl,
  GetFiCashflowsRPC,
  GetFiCashflowsRPCClientImpl,
  FiEditAccrualRPCClientImpl,
  FiEditAccrualRPC,
  GetFiCashflowDetailRPC,
  GetFiCashflowDetailRPCClientImpl,
  FiSettleCashflowRPC,
  FiSettleCashflowRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetBrokerListRPCImpl } from "../../../rpcs/MockGetBrokerListRPCImpl";
import { MockGetFiContractNotesRPCImpl } from "./MockGetFiContractNotesRPCImpl";
import { MockGetPresignedUploadUrlRPCImpl } from "../../../rpcs/MockGetPresignedUploadUrlRPCImpl";
import { mockUploadFileToBackblaze } from "../../../rpcs/MockUploadFileToBackblaze";
import { uploadFileToBackblaze } from "../../../../../utils/FileUploadUtils";
import { MockSubmitFiDealContractNoteRPCImpl } from "./MockSubmitFiDealContractNoteRPCImpl";
import { MockGetFiContractNoteDetailsRPCImpl } from "./MockGetFiContractNoteDetailsRPCImpl";
import { MockGetFiContractNoteChargesRPCImpl } from "./MockGetFiContractNoteChargesRPCImpl";
import { MockFiSubmitContractNoteRequestRPCImpl } from "./MockFiSubmitContractNoteRequestRPCImpl";
import { MockFiValidateContractNoteRPCImpl } from "./MockFiValidateContractNotteRequestRPCImpl";
import { MockGetFiContractNoteHistoryRPCImpl } from "./MockGetFiContractNoteHistoryRPCImpl";
import { MockCheckFiContractNoteRequestRPCImpl } from "./MockCheckFiContractNoteRequestRPCImpl";
import { MockFiSubmitContractNoteDiscardRequestRPCImpl } from "./MockFiSubmitContractNoteDiscardRequestRPCImpl";
import { MockFiSubmitContractNoteUnknownRequestRPCImpl } from "./MockFiSubmitContractNoteUnknownRequestRPCImpl";
import { MockGetFiApprovedDealRequestsRPCImpl } from "./MockGetFiApprovedDealRequestsRPCImpl";
import { MockFiSubmitContractNoteLinkRequestRPCImpl } from "./MockFiSubmitContractNoteLinkRequestRPCImpl";
import { MockGetFiCashflowsRPCImpl } from "./MockGetFiCashflowsRPCImpl";
import { MockFiEditAccrualRPCImpl } from "./MockFiEditAccrualRPCImpl";
import { MockGetFiCashflowDetailRPCImpl } from "./MockGetFiCashflowDetailRPCImpl";
import { MockFiSettleCashflowRPCImpl } from "./MockFiSettleCashflowRPCImpl";

export const useGetBrokerListRPCClientImpl = (
  apiClient: APIClient,
): GetBrokerListRPC => {
  if (MOCK.fi) {
    return new MockGetBrokerListRPCImpl();
  } else {
    return new GetBrokerListRPCClientImpl(apiClient);
  }
};

export const useGetFiContractNotesRPCClientImpl = (
  apiClient: APIClient,
): GetFiContractNotesRPC => {
  if (MOCK.fi) {
    return new MockGetFiContractNotesRPCImpl();
  } else {
    return new GetFiContractNotesRPCClientImpl(apiClient);
  }
};

export const useGetPresignedUploadUrlRPCClientImpl = (
  apiClient: APIClient,
): GetPresignedUploadUrlRPC => {
  if (MOCK.fi) {
    return new MockGetPresignedUploadUrlRPCImpl();
  } else {
    return new GetPresignedUploadUrlRPCClientImpl(apiClient);
  }
};

export const useUploadFileToBackBlaze = (
  url: URL,
  file: File,
): Promise<void> => {
  if (MOCK.fi) {
    return mockUploadFileToBackblaze();
  } else {
    return uploadFileToBackblaze(url, file);
  }
};

export const useSubmitFiDealContractNoteRPCClientImpl = (
  apiClient: APIClient,
): SubmitFiDealContractNoteRPC => {
  if (MOCK.fi) {
    return new MockSubmitFiDealContractNoteRPCImpl();
  } else {
    return new SubmitFiDealContractNoteRPCClientImpl(apiClient);
  }
};

export const useGetFiContractNoteDetailsRPCClientImpl = (
  apiClient: APIClient,
): GetFiContractNoteDetailsRPC => {
  if (MOCK.fi) {
    return new MockGetFiContractNoteDetailsRPCImpl();
  } else {
    return new GetFiContractNoteDetailsRPCClientImpl(apiClient);
  }
};

export const useGetFiContractNoteChargesClientImpl = (
  apiClient: APIClient,
): GetFiContractNoteChargesRPC => {
  if (MOCK.fi) {
    return new MockGetFiContractNoteChargesRPCImpl();
  } else {
    return new GetFiContractNoteChargesRPCClientImpl(apiClient);
  }
};

export const useFiValidateContractNoteRequestRPCClientImpl = (
  apiClient: APIClient,
): FiValidateContractNoteRequestRPC => {
  if (MOCK.fi) {
    return new MockFiValidateContractNoteRPCImpl();
  } else {
    return new FiValidateContractNoteRequestRPCClientImpl(apiClient);
  }
};

export const useFiSubmitContractNoteRequestRPCClientImpl = (
  apiClient: APIClient,
): FiSubmitContractNoteRequestRPC => {
  if (MOCK.fi) {
    return new MockFiSubmitContractNoteRequestRPCImpl();
  } else {
    return new FiSubmitContractNoteRequestRPCClientImpl(apiClient);
  }
};

export const useGetFiContractNoteHistoryRPCClientImpl = (
  apiClient: APIClient,
): GetFiContractNoteHistoryRPC => {
  if (MOCK.fi) {
    return new MockGetFiContractNoteHistoryRPCImpl();
  } else {
    return new GetFiContractNoteHistoryRPCClientImpl(apiClient);
  }
};

export const useCheckFiContractNoteRequestRPCClientImpl = (
  apiClient: APIClient,
): CheckFiContractNoteRequestRPC => {
  if (MOCK.fi) {
    return new MockCheckFiContractNoteRequestRPCImpl();
  } else {
    return new CheckFiContractNoteRequestRPCClientImpl(apiClient);
  }
};

export const useFiSubmitContractNoteDiscardRequestRPCClientImpl = (
  apiClient: APIClient,
): FiSubmitContractNoteDiscardRequestRPC => {
  if (MOCK.fi) {
    return new MockFiSubmitContractNoteDiscardRequestRPCImpl();
  } else {
    return new FiSubmitContractNoteDiscardRequestRPCClientImpl(apiClient);
  }
};

export const useFiSubmitContractNoteUnknownRequestRPCClientImpl = (
  apiClient: APIClient,
): FiSubmitContractNoteUnknownRequestRPC => {
  if (MOCK.fi) {
    return new MockFiSubmitContractNoteUnknownRequestRPCImpl();
  } else {
    return new FiSubmitContractNoteUnknownRequestRPCClientImpl(apiClient);
  }
};

export const useGetFiApprovedDealRequestRPCClientImpl = (
  apiClient: APIClient,
): GetFiApprovedDealRequestsRPC => {
  if (MOCK.fi) {
    return new MockGetFiApprovedDealRequestsRPCImpl();
  } else {
    return new GetFiApprovedDealRequestsRPCClientImpl(apiClient);
  }
};

export const useFiSubmitContractNoteLinkRequestRPCClientImpl = (
  apiClient: APIClient,
): FiSubmitContractNoteLinkRequestRPC => {
  if (MOCK.fi) {
    return new MockFiSubmitContractNoteLinkRequestRPCImpl();
  } else {
    return new FiSubmitContractNoteLinkRequestRPCClientImpl(apiClient);
  }
};

export const useGetFiCashflowsRPCClientImpl = (
  apiClient: APIClient,
): GetFiCashflowsRPC => {
  if (MOCK.fi) {
    return new MockGetFiCashflowsRPCImpl();
  } else {
    return new GetFiCashflowsRPCClientImpl(apiClient);
  }
};

export const useFiEditAccrualRPClientImpl = (
  apiClient: APIClient,
): FiEditAccrualRPC => {
  if (MOCK.fi) {
    return new MockFiEditAccrualRPCImpl();
  } else {
    return new FiEditAccrualRPCClientImpl(apiClient);
  }
};

export const useGetFiCashflowDetailRPCClientImpl = (
  apiClient: APIClient,
): GetFiCashflowDetailRPC => {
  if (MOCK.fi) {
    return new MockGetFiCashflowDetailRPCImpl();
  } else {
    return new GetFiCashflowDetailRPCClientImpl(apiClient);
  }
};

export const useFiSettleCashflowRPCClientImpl = (
  apiClient: APIClient,
): FiSettleCashflowRPC => {
  if (MOCK.fi) {
    return new MockFiSettleCashflowRPCImpl();
  } else {
    return new FiSettleCashflowRPCClientImpl(apiClient);
  }
};
