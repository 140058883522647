import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { DetailType } from "../../../../models/DetailCellModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CancelMFDealRequestRPC,
  CheckICMfDealRequestRPC,
  CheckResponse,
  GetMFDealRequestDetailsRPC,
  Note,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  useCancelMFDealRequestRPC,
  useCheckICMfDealRequestRPC,
  useGetMFDealRequestDetailsRPC,
} from "../../rpc/RPC";
import {
  createDealDetailsErrorStore,
  DealDetailsErrorStore,
} from "./DealDetailsErrorStore";
import {
  createMFDealHistoryStore,
  MFDealHistoryStore,
} from "./MFDealHistoryStore";
import {
  createMFDealRequestActionModel,
  MFDealRequestActionModel,
} from "../../models/MFDealRequestActionModel";
import {
  AmountDateModel,
  createAmountDateModel,
} from "../../../../models/AmountDateModel";
import {
  createMFDealRequestBannerInfoStore,
  MFDealRequestBannerInfoStore,
} from "./MFDealRequestBannerInfoStore";
import {
  createDealRequestSettleStore,
  DealRequestSettleStore,
} from "./DealRequestSettleStore";
import { createDealOrderStore, DealOrderStore } from "./DealOrderStore";

export const DealRequestDetailsStore = types
  .model("DealRequestDetailsStore", {
    isLoading: types.optional(types.boolean, false),
    details: types.array(DetailType),
    lineGraphData: types.maybeNull(types.array(AmountDateModel)),
    actions: MFDealRequestActionModel,
    historyStore: MFDealHistoryStore,
    bannerInfoStore: MFDealRequestBannerInfoStore,
    errorStore: DealDetailsErrorStore,
    settleStore: DealRequestSettleStore,
    dealOrderStore: DealOrderStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getMFDealRequestDetails: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFDealRequestDetailsRPC.Request(requestId);
        store.isLoading = true;
        const result: LeoRPCResult<
          GetMFDealRequestDetailsRPC.Response,
          GetMFDealRequestDetailsRPC.Errors.Errors
        > = yield useGetMFDealRequestDetailsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.details = cast(response.requestDetails);
          store.lineGraphData = cast(
            response.historicalGraph?.map((data) =>
              createAmountDateModel(data),
            ),
          );
          store.actions = createMFDealRequestActionModel(
            response.requestAction,
          );
          store.dealOrderStore.setDealRequestOrder(
            response.dealRequestOrderSectionDetail,
          );
        } else if (result instanceof LeoRPCResult.Error) {
          store.errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error in getMFDealRequestDetails");
        }
        store.isLoading = false;
      }),
      cancelMFDealRequest: flow(function* (requestId: number, note?: string) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        store.errorStore.setError(undefined);
        store.isLoading = true;
        const request = new CancelMFDealRequestRPC.Request(
          requestId,
          note ? new Note(note) : null,
        );
        const result: LeoRPCResult<
          CancelMFDealRequestRPC.Response,
          CancelMFDealRequestRPC.Errors.Errors
        > = yield useCancelMFDealRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          store.isLoading = false;
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          store.errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error");
        }
      }),
      checkICMfDealRequest: flow(function* (
        requestId: number,
        checkResponse: CheckResponse,
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new CheckICMfDealRequestRPC.Request(
          requestId,
          checkResponse,
        );
        store.isLoading = true;
        const result: LeoRPCResult<
          CheckICMfDealRequestRPC.Response,
          CheckICMfDealRequestRPC.Errors.Errors
        > = yield useCheckICMfDealRequestRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          store.isLoading = false;
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          store.errorStore.setError(result.error.code);
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createDealRequestDetailsStore = (): Instance<
  typeof DealRequestDetailsStore
> => {
  return DealRequestDetailsStore.create({
    errorStore: createDealDetailsErrorStore(),
    historyStore: createMFDealHistoryStore(),
    bannerInfoStore: createMFDealRequestBannerInfoStore(),
    actions: createMFDealRequestActionModel(),
    settleStore: createDealRequestSettleStore(),
    dealOrderStore: createDealOrderStore(),
  });
};
