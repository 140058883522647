import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";
import {
  createEquityDashboardStatsStore,
  EquityDashboardStatsStore,
} from "./EquityDashboardStatsStore";
import {
  createEquityHoldingDistributionStore,
  EquityHoldingDistributionStore,
} from "./EquityHoldingDistributionStore";
import {
  createEquityReturnVsBenchmarkStore,
  EquityReturnVsBenchmarkStore,
} from "./EquityReturnVsBenchmarkStore";
import {
  createEquityDashboardTopHoldingsStore,
  EquityDashboardTopHoldingsStore,
} from "./EquityDashboardTopHoldingsStore";
import {
  createEquityTopRealisedGain,
  EquityTopRealisedGain,
} from "./EquityTopRealisedGain";

export const EquityDashboardStore = types
  .model("EquityDashboardStore", {
    dashboardStats: EquityDashboardStatsStore,
    holdingDistribution: EquityHoldingDistributionStore,
    benchmarkReturns: EquityReturnVsBenchmarkStore,
    topTenHoldings: EquityDashboardTopHoldingsStore,
    topTenRealisedGains: EquityTopRealisedGain,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  });

export const createEquityDashboardStore = (): Instance<
  typeof EquityDashboardStore
> => {
  return EquityDashboardStore.create({
    dashboardStats: createEquityDashboardStatsStore(),
    holdingDistribution: createEquityHoldingDistributionStore(),
    benchmarkReturns: createEquityReturnVsBenchmarkStore(),
    topTenHoldings: createEquityDashboardTopHoldingsStore(),
    topTenRealisedGains: createEquityTopRealisedGain(),
  });
};
