import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { debounce, Stack } from "@mui/material";
import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-core";
import { DataSection } from "../../../components/section/DataSection";
import { useTranslation } from "react-i18next";
import { KHTimeline } from "../../../components/timeline/KHTimeline";
import { ImpactOnPortfolio } from "../../../components/ImpactOnPortfolio";
import { useCreateFiDealRequestStore } from "../store/hooks";
import { DEBOUNCE_DELAY } from "../../../../../utils";
import { FiDealType } from "../../../../../types/EnumTypes";
import { getSummaryCell } from "../../../utils/SummaryCellUtils";
import { getTimelineItem } from "../../../utils/TimelineUtils";
import { useBorder } from "../../../../../utils/BorderUtils";

const Size = {
  width: "388px",
  height: "calc(100vh - 85px)",
};
export interface CreateFIDealDetailsProps {
  tabValue: FiDealType;
}

export const CreateFIDealDetails = observer(
  ({ tabValue }: CreateFIDealDetailsProps): React.ReactElement => {
    const { t } = useTranslation();
    const tokens = useFoundationColorTokens();
    const store = useCreateFiDealRequestStore();
    const border = useBorder();
    const [isLoading, setIsLoading] = useState(false);
    const [isImpactTableLoading, setIsImpactTableLoading] = useState(false);

    /* 'time' is declared in the global scope to persist the previous value.
    If we declare 'time' inside the debounce function then we'll have to initialise it with null,
    which will keep on resetting the value and the condition for debounce will fail.
*/
    let impactOnPortfolioTime: Date;
    let transactionDetailsTime: Date;

    const debouncedGetFiTransactionDetails = useCallback(() => {
      debounce(async () => {
        if (store.isAmountValid()) {
          if (
            transactionDetailsTime &&
            new Date().getTime() - transactionDetailsTime.getTime() <
              DEBOUNCE_DELAY
          ) {
            // Since `debouncedGetFiTransactionDetails` is used inside the useEffect hook, this method is called
            // every time  the gross amount, quantity or price input changes. Although, the `debounce` method waits until
            // the given time period(500ms) to execute the defined method, the method is executed as many times as it is triggered in
            // the `useEffect` hook after the interval of 500ms.
            // This condition prevents the unnecessary multiple triggering of the `store.getFiTransactionDetails` method.
            return;
          }
          transactionDetailsTime = new Date();
          if (store.isin) {
            setIsLoading(true);
            await store.getFiDealSummaryDetails(tabValue);
            setIsLoading(false);
          } else {
            store.resetFiTransactionDetails();
          }
        }
      }, DEBOUNCE_DELAY)();
    }, [
      store.grossAmount,
      store.quantity,
      store.price,
      store.yieldToMaturity,
      store.isin,
    ]);

    useEffect(() => {
      debouncedGetFiTransactionDetails();
    }, [
      store.grossAmount,
      store.quantity,
      store.price,
      store.yieldToMaturity,
      store.isin,
    ]);

    const debouncedGetFiImpactOnPortfolio = useCallback(
      debounce(async () => {
        if (
          impactOnPortfolioTime &&
          new Date().getTime() - impactOnPortfolioTime.getTime() <
            DEBOUNCE_DELAY
        ) {
          return;
        }
        impactOnPortfolioTime = new Date();
        if (
          store.isin &&
          store.entity &&
          store.portfolio &&
          (store.grossAmount ?? (store.price && store.quantity))
        ) {
          setIsImpactTableLoading(true);
          await store.getFiImpactOnPortfolio(tabValue).finally(() => {
            setIsImpactTableLoading(false);
          });
        } else {
          store.resetFiImpactTableModel();
        }
      }, DEBOUNCE_DELAY),
      [
        store.isin,
        store.grossAmount,
        store.quantity,
        store.price,
        store.yieldToMaturity,
        store.entity,
        store.portfolio,
      ],
    );

    useEffect(() => {
      debouncedGetFiImpactOnPortfolio();
    }, [
      store.isin,
      store.grossAmount,
      store.quantity,
      store.price,
      store.yieldToMaturity,
      store.entity,
      store.portfolio,
    ]);

    const emptyStateMessage = (): string => {
      return tabValue === FiDealType.Purchase
        ? t("fi.createDealRequest.PurchaseTransactionDetailEmptyStateMessage")
        : t("fi.createDealRequest.SellTransactionDetailEmptyStateMessage");
    };

    return (
      <Stack
        sx={{
          backgroundColor: tokens.backgroundElevatedLevel1,
          width: Size.width,
          minWidth: Size.width,
          height: Size.height,
          overflowY: "auto",
        }}
      >
        <DataSection
          title={t("fi.createDealRequest.securityDetailsTitle")}
          emptyStateMessage={t("fi.securityDetails.emptyStateMessage")}
          isLoading={isLoading}
          items={
            store.fiSummaryDetails?.securityDetails.map((item) => {
              return getSummaryCell(item);
            }) ?? []
          }
        />
        <DataSection
          title={t("common.transactionDetails")}
          emptyStateMessage={emptyStateMessage()}
          isLoading={isLoading}
          items={
            store.fiSummaryDetails?.transactionDetails.map((item) => {
              return getSummaryCell(item);
            }) ?? []
          }
        />
        <KHTimeline
          title={t("common.expectedCashFlowsLabel")}
          isLoading={isLoading}
          items={getTimelineItem(store.fiSummaryDetails?.cashflowTimeline)}
          showHeader={true}
          emptyStateMessage={emptyStateMessage()}
        />
        {/* TODO :- FiImpactOnPortfolio should only be visible based on configuration. */}
        <ImpactOnPortfolio
          impactTableModel={store.impactTableModel}
          isCreateDealScreen={true}
          isLoading={isImpactTableLoading}
          showBorder={false}
        />
        <div style={{ borderBottom: border.default }} />
      </Stack>
    );
  },
);
