import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { FiYieldType, GetFiInvestmentDetailsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import i18next from "i18next";
import {
  createDetailsCardItemModel,
  DetailsCardItemModel,
} from "../../../models/DetailsCardItemModel";
import { useGetFiInvestmentDetailsClientImpl } from "../../rpcs/RPC";
import { DATE_FORMAT, getFormattedDate } from "../../../../../utils";
import { getPayoutFrequencyName } from "../../../utils/DetailCellUtils";
import {
  CashflowTimelineModel,
  createCashflowTimelineModel,
} from "../../../models/CashflowTimelineModel";

export const FiInvestmentDetailsStore = types
  .model("FiInvestmentDetailsStore", {
    detailsList: types.array(DetailsCardItemModel),
    cashflowTimelineList: types.array(CashflowTimelineModel),
    isHeaderButtonVisible: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    investmentId: types.maybe(types.number),
    error: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      resetStore: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    getInvestmentDetails: flow(function* (
      dealRequestId: number | undefined = undefined,
      contractNoteId: number | undefined = undefined,
      investmentId: number | undefined = undefined,
      maturityDealRequestId: number | undefined = undefined,
    ) {
      if (
        dealRequestId ??
        contractNoteId ??
        investmentId ??
        maturityDealRequestId
      ) {
        store.error = false;
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.isLoading = true;
        try {
          const requestType = dealRequestId
            ? new GetFiInvestmentDetailsRPC.RequestEnums.RequestType.DealDetails(
                dealRequestId,
              )
            : contractNoteId
              ? new GetFiInvestmentDetailsRPC.RequestEnums.RequestType.ContractNoteDetails(
                  contractNoteId,
                )
              : investmentId
                ? new GetFiInvestmentDetailsRPC.RequestEnums.RequestType.InvestmentDetails(
                    investmentId,
                  )
                : new GetFiInvestmentDetailsRPC.RequestEnums.RequestType.MaturityDetails(
                    maturityDealRequestId!!,
                  );
          const request = new GetFiInvestmentDetailsRPC.Request(requestType);
          const result: LeoRPCResult<
            GetFiInvestmentDetailsRPC.Response,
            GetFiInvestmentDetailsRPC.Errors.Errors
          > =
            yield useGetFiInvestmentDetailsClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.investmentId = response.investmentId ?? undefined;
            store.isHeaderButtonVisible = response.isViewCashFlowButtonEnabled;
            store.cashflowTimelineList = cast(
              response.cashFlowList.map((item) => {
                return createCashflowTimelineModel(item);
              }),
            );
            store.detailsList = cast([
              createDetailsCardItemModel({
                top: response.isin.isin,
                middle: response.legalName.toUpperCase(),
                bottom: `${i18next.t(
                  "fi.investmentDetails.issueDate",
                )} ${getFormattedDate(
                  new Date(response.issueDate.date),
                  DATE_FORMAT,
                )}`,
              }),
              createDetailsCardItemModel({
                top: i18next.t("common.numberFormat", {
                  val: response.totalUnits.decimalValue,
                }),
                middle: i18next.t("fi.investmentDetails.totalUnits"),
                bottom: `${i18next.t("fi.investmentDetails.purchasePrice")} ${
                  response.purchasePrice.currency.symbol
                }${i18next.t("common.decimal4", {
                  val: response.purchasePrice.amount.decimalValue,
                })}`,
              }),
              createDetailsCardItemModel({
                top: getFormattedDate(
                  new Date(response.yieldDate.date),
                  DATE_FORMAT,
                ),
                middle:
                  response.yieldType === FiYieldType.FiYieldType.C
                    ? i18next.t("fi.investmentDetails.callDate")
                    : response.yieldType === FiYieldType.FiYieldType.P
                      ? i18next.t("fi.investmentDetails.putDate")
                      : i18next.t("fi.investmentDetails.maturityDate"),
                bottom: `${i18next.t(
                  "fi.investmentDetails.purchaseDate",
                )} ${getFormattedDate(
                  new Date(response.purchaseDate.date),
                  DATE_FORMAT,
                )}`,
              }),
              createDetailsCardItemModel({
                top: response.bankAccountNumber,
                middle: response.bankName.toUpperCase(),
                bottom: `${i18next.t("fi.investmentDetails.dematAccount")} ${
                  response.dematAccountNumber
                }`,
              }),
              createDetailsCardItemModel({
                top: `${i18next.t("common.decimal2", {
                  val: response.ytm.decimalValue,
                })} %`,
                middle:
                  response.yieldType === FiYieldType.FiYieldType.C
                    ? i18next.t("fi.investmentDetails.ytc")
                    : response.yieldType === FiYieldType.FiYieldType.P
                      ? i18next.t("fi.investmentDetails.ytp")
                      : i18next.t("fi.investmentDetails.ytm"),
                bottom: `${i18next.t(
                  "fi.investmentDetails.couponRate",
                )} ${i18next.t("common.decimal2", {
                  val: response.couponRate.decimalValue,
                })} %`,
              }),
              createDetailsCardItemModel({
                top: `${
                  response.accruedInterestTillDate.currency.symbol
                }${i18next.t("common.decimal2", {
                  val: response.accruedInterestTillDate.amount.decimalValue,
                })}`,
                middle: i18next.t(
                  "fi.investmentDetails.interestReceivedTillDate",
                ),
                bottom: `${i18next.t(
                  "fi.investmentDetails.payoutFrequency",
                )} ${getPayoutFrequencyName(response.payoutFrequency)}`,
              }),
            ]);
          } else {
            store.error = true;
            logger.error(
              `Unhandled Error: ${result.error} from GetFiInvestmentDetailsRPC`,
            );
          }
        } catch (error) {
          if (error instanceof Error) {
            store.error = true;
            logger.error(
              `Unhandled error: ${error} occurred in GetPortfolioListRPC`,
            );
          }
        }
        store.isLoading = false;
      }
    }),
  }));
export const createFiInvestmentDetailsStore = (): Instance<
  typeof FiInvestmentDetailsStore
> => {
  return FiInvestmentDetailsStore.create();
};
