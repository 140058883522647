"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedDepositType = exports.InvalidFixedDepositTypeError = void 0;
class InvalidFixedDepositTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedDepositTypeError";
    }
}
exports.InvalidFixedDepositTypeError = InvalidFixedDepositTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedDepositType;
(function (FixedDepositType_1) {
    let FixedDepositType;
    (function (FixedDepositType) {
        FixedDepositType["CUMULATIVE"] = "CUMULATIVE";
        FixedDepositType["NON_CUMULATIVE"] = "NON_CUMULATIVE";
    })(FixedDepositType = FixedDepositType_1.FixedDepositType || (FixedDepositType_1.FixedDepositType = {}));
    FixedDepositType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "CUMULATIVE":
                return FixedDepositType.CUMULATIVE;
            case "NON_CUMULATIVE":
                return FixedDepositType.NON_CUMULATIVE;
            default:
                throw new InvalidFixedDepositTypeError(`Case ${dto.case} is not valid case of FixedDepositType`);
        }
    };
    FixedDepositType_1.toDTO = (fixedDepositType) => {
        const ret = {
            case: FixedDepositType[fixedDepositType],
        };
        return ret;
    };
    FixedDepositType_1.isInstanceOf = (other) => {
        if (other in FixedDepositType) {
            return true;
        }
        return false;
    };
})(FixedDepositType = exports.FixedDepositType || (exports.FixedDepositType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
