"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHA256 = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidSHA256Error extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidSHA256Error";
    }
}
class SHA256 {
    constructor(sha256) {
        this.isSHA256 = true;
        SHA256.validateSha256(sha256);
        this.sha256 = sha256;
    }
    static fromDTO(dto) {
        const sha256 = (0, leo_ts_runtime_1.getString)(dto, "sha256", InvalidSHA256Error);
        return new SHA256(sha256);
    }
    toDTO() {
        const ret = {
            sha256: this.sha256,
        };
        return ret;
    }
    copy(sha256 = this.sha256) {
        return new SHA256(sha256);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isSHA256Value = other.isSHA256;
        if (!(other instanceof SHA256) || Boolean(isSHA256Value)) {
            return false;
        }
        if (this.sha256 !== other.sha256) {
            return false;
        }
        return true;
    }
}
exports.SHA256 = SHA256;
SHA256.validateSha256 = (sha256) => {
    if (!(0, leo_ts_runtime_1.isString)(sha256)) {
        throw new InvalidSHA256Error(`Attribute sha256 is not a String`);
    }
    if (sha256.length < 64) {
        throw new InvalidSHA256Error(`Attribute sha256 is too short. Size is ${sha256.length}. Min size is 64.`);
    }
    if (sha256.length > 64) {
        throw new InvalidSHA256Error(`Attribute sha256 is too long. Size is ${sha256.length}. Max size is 64.`);
    }
    if (sha256.trim().length === 0) {
        throw new InvalidSHA256Error(`Attribute sha256 cannot be an empty string.`);
    }
};
SHA256.prototype.toString = function toString() {
    return `SHA256(sha256='${this.sha256}')`;
};
