import React from "react";
import i18n, { TFunction } from "i18next";
import { EquityLogo } from "../assets/EquityLogo";
import {
  SettingsPrivileges,
  EquityUserPrivileges,
  FiUserPrivileges,
  FdUserPrivileges,
  MFUserPrivileges,
} from "../modules/user/UserPrivileges";
import {
  ListItemProps,
  SubListItemProps,
} from "@surya-digital/leo-reactjs-core";
import { getPath } from "./RoutesUtils";
import { Module, Route } from "../routes/RoutesEnum";
import { Settings } from "../assets/Settings";
import { FixedIncomeLogo } from "../assets/FIxedIncomeLogo";
import { FixedDepositLogo } from "../assets/FixedDepositLogo";
import { MutualFundLogo } from "../assets/MutualFundLogo";
import { Instance } from "mobx-state-tree";
import { HomeStore } from "../modules/home/store/HomeStore";

const getFdChildren = (
  userPrivileges: string[],
  isEnabled: boolean,
): SubListItemProps[] => {
  const fdChildren: SubListItemProps[] = [];
  if (isEnabled) {
    if (
      userPrivileges.includes(FdUserPrivileges.CreateFixedDepositDealRequest)
    ) {
      fdChildren.push({
        label: i18n.t("common.createDealRequest"),
        path: getPath(Module.Fd, Route.CreateDealRequest),
      });
    }
    if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositDealRequest)) {
      fdChildren.push({
        label: i18n.t("common.manageDealRequests"),
        path: getPath(Module.Fd, Route.ManageDealRequest),
      });
    }
    if (userPrivileges.includes(FdUserPrivileges.ViewFixedDepositReports)) {
      fdChildren.push({
        label: i18n.t("common.reports"),
        path: getPath(Module.Fd, Route.Reports),
      });
    }
  }
  return fdChildren;
};

const getMFChildren = (
  userPrivileges: string[],
  isEnabled: boolean,
): SubListItemProps[] => {
  const mfChildren: SubListItemProps[] = [];
  if (isEnabled) {
    if (userPrivileges.includes(MFUserPrivileges.CreateMutualFundDealRequest)) {
      mfChildren.push({
        label: i18n.t("common.createDealRequest"),
        path: getPath(Module.MF, Route.CreateDealRequest),
      });
    }
    if (userPrivileges.includes(MFUserPrivileges.ViewMutualFundDealRequest)) {
      mfChildren.push({
        label: i18n.t("common.manageDealRequests"),
        path: getPath(Module.MF, Route.ManageDealRequest),
      });
    }
    if (
      userPrivileges.includes(
        MFUserPrivileges.ViewMutualFundConfirmationRequest,
      )
    ) {
      mfChildren.push({
        label: i18n.t("mf.manageConfirmationRequests.sidebarTitle"),
        path: getPath(Module.MF, Route.ManageConfirmationRequests),
      });
    }
    if (userPrivileges.includes(MFUserPrivileges.ViewMutualFundReports)) {
      mfChildren.push({
        label: i18n.t("common.reports"),
        path: getPath(Module.MF, Route.Reports),
      });
    }
  }
  return mfChildren;
};

export const getTopListData = (
  t: TFunction,
  userPrivileges: string[],
  store: Instance<typeof HomeStore>,
): ListItemProps[] | null => {
  const equityChildren: SubListItemProps[] = [];
  const fiChildren: SubListItemProps[] = [];
  const topListData: ListItemProps[] = [];
  const getIsEquityUser = (): boolean => {
    return (
      store.isEquityEnabled &&
      userPrivileges.find((privilege) =>
        Object.values(EquityUserPrivileges).includes(
          privilege as EquityUserPrivileges,
        ),
      ) !== undefined
    );
  };

  const getIsFixedIncomeUser = (): boolean => {
    return (
      store.isFIEnabled &&
      userPrivileges.find((privilege) =>
        Object.values(FiUserPrivileges).includes(privilege as FiUserPrivileges),
      ) !== undefined
    );
  };

  const getIsFDUser = (): boolean => {
    return (
      store.isFDEnabled &&
      userPrivileges.find((privilege) =>
        Object.values(FdUserPrivileges).includes(privilege as FdUserPrivileges),
      ) !== undefined
    );
  };

  const getIsMFUser = (): boolean => {
    return (
      store.isMFEnabled &&
      userPrivileges.find((privilege) =>
        Object.values(MFUserPrivileges).includes(privilege as MFUserPrivileges),
      ) !== undefined
    );
  };

  if (getIsEquityUser()) {
    if (userPrivileges.includes(EquityUserPrivileges.ViewEquityDashboard)) {
      equityChildren.push({
        label: t("dashboard.sidebarTitle"),
        path: getPath(Module.Equity, Route.Dashboard),
      });
    }
    if (userPrivileges.includes(EquityUserPrivileges.CreateEquityDealRequest)) {
      equityChildren.push({
        label: t("dealRequest.createDealRequest"),
        path: getPath(Module.Equity, Route.CreateDealRequest),
      });
    }

    if (userPrivileges.includes(EquityUserPrivileges.ViewEquityDealRequest)) {
      equityChildren.push({
        label: t("common.manageDealRequests"),
        path: getPath(Module.Equity, Route.ViewDealRequest),
      });
    }

    if (userPrivileges.includes(EquityUserPrivileges.ViewEquityContractNote)) {
      equityChildren.push({
        label: t("common.manageContractNotes"),
        path: getPath(Module.Equity, Route.ManageContractNotes),
      });
    }

    if (userPrivileges.includes(EquityUserPrivileges.ViewEquityReports)) {
      equityChildren.push({
        label: t("common.reports"),
        path: getPath(Module.Equity, Route.Reports),
      });
    }

    if (equityChildren.length === 0) {
      // If the user does not have equity privilege then we won't be showing it on the sidebar.
    } else {
      topListData.push({
        label: t("common.equity"),
        icon: <EquityLogo />,
        subList: equityChildren,
      });
    }
  }

  if (getIsFixedIncomeUser()) {
    if (
      userPrivileges.includes(FiUserPrivileges.CreateFixedIncomeDealRequest)
    ) {
      fiChildren.push({
        label: t("common.createDealRequest"),
        path: getPath(Module.Fi, Route.CreateDealRequest),
      });
    }

    if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeDealRequest)) {
      fiChildren.push({
        label: t("common.manageDealRequest"),
        path: getPath(Module.Fi, Route.ManageDealRequest),
      });
    }

    if (
      userPrivileges.includes(
        FiUserPrivileges.ViewFixedIncomeMaturityDealRequest,
      )
    ) {
      fiChildren.push({
        label: t("common.manageMaturityRequest"),
        path: getPath(Module.Fi, Route.ManageMaturityRequest),
      });
    }

    if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeContractNote)) {
      fiChildren.push({
        label: t("common.manageContractNotes"),
        path: getPath(Module.Fi, Route.ManageContractNotes),
      });
    }

    if (userPrivileges.includes(FiUserPrivileges.ViewFixedIncomeReports)) {
      fiChildren.push({
        label: t("common.reports"),
        path: getPath(Module.Fi, Route.Reports),
      });
    }

    if (fiChildren.length === 0) {
      // If the user does not have fixed income privilege then we won't be showing it on the sidebar.
    } else {
      topListData.push({
        label: t("fi.fixedIncome"),
        icon: <FixedIncomeLogo />,
        subList: fiChildren,
      });
    }
  }

  const fdChildren: SubListItemProps[] = getFdChildren(
    userPrivileges,
    getIsFDUser(),
  );
  if (fdChildren.length === 0) {
    // If the user does not have fixed income privilege then we won't be showing it on the sidebar.
  } else {
    topListData.push({
      label: t("fd.sidebarTitle"),
      icon: <FixedDepositLogo />,
      subList: fdChildren,
    });
  }

  const mfChildren: SubListItemProps[] = getMFChildren(
    userPrivileges,
    getIsMFUser(),
  );
  if (mfChildren.length === 0) {
    // If the user does not have mutual fund privilege then we won't be showing it on the sidebar.
  } else {
    topListData.push({
      label: t("mf.sidebarTitle"),
      icon: <MutualFundLogo />,
      subList: mfChildren,
    });
  }
  return topListData;
};

const getSettingsListData = (
  t: TFunction,
  userPrivileges: string[],
): ListItemProps | undefined => {
  const children: SubListItemProps[] = [];
  const isAdminUser = (): boolean => {
    const isAdmin = userPrivileges.find((privilege) =>
      Object.values(FiUserPrivileges).includes(privilege as FiUserPrivileges),
    );
    return isAdmin !== undefined;
  };
  if (isAdminUser()) {
    if (userPrivileges.includes(SettingsPrivileges.ManageEntity)) {
      children.push({
        label: t("common.entity"),
        path: Route.EntityDetails,
      });
    }
    if (userPrivileges.includes(SettingsPrivileges.ManagePortfolio)) {
      children.push({
        label: t("common.portfolio"),
        path: Route.PortfolioDetails,
      });
    }
  }
  if (children.length === 0) {
    return undefined;
  } else {
    return {
      label: t("common.settings"),
      icon: <Settings />,
      subList: children,
    };
  }
};

export const sidebarTopListData = (
  t: TFunction,
  userPrivileges: string[],
  store: Instance<typeof HomeStore>,
): ListItemProps[] => {
  let listData: ListItemProps[] = [];
  const transactionListData = getTopListData(t, userPrivileges, store);
  if (transactionListData) {
    listData = transactionListData;
  }
  const settingsListData = getSettingsListData(t, userPrivileges);
  if (settingsListData) {
    listData.push(settingsListData);
  }
  return listData;
};
