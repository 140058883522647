import {
  GetFixedIncomeHoldingSummaryDownloadURLRPC,
  GetFixedIncomeHoldingSummaryDownloadURLRPCClientImpl,
  GetFixedIncomeHoldingSummaryReportRPC,
  GetFixedIncomeHoldingSummaryReportRPCClientImpl,
  GetFixedIncomeInvestmentRegisterDownloadURLRPC,
  GetFixedIncomeInvestmentRegisterDownloadURLRPCClientImpl,
  GetFixedIncomeInvestmentRegisterReportRPC,
  GetFixedIncomeInvestmentRegisterReportRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { APIClient } from "@surya-digital/tedwig";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockGetFixedIncomeInvestmentRegisterReportRPC } from "./MockGetFixedIncomeInvestmentRegisterReportRPC";
import { MockGetFixedIncomeInvestmentRegisterDownloadURLRPC } from "./MockGetFixedIncomeInvestmentRegisterDownloadURLRPC";
import { MockGetFixedIncomeHoldingSummaryReportRPC } from "./MockGetFixedIncomeHoldingSummaryReportRPC";
import { MockGetFixedIncomeHoldingSummaryDownloadURLRPC } from "./MockGetFixedIncomeHoldingSummaryDownloadURLRPC";

export const useGetFixedIncomeInvestmentRegisterReportRPCClientImpl = (
  apiClient: APIClient,
): GetFixedIncomeInvestmentRegisterReportRPC => {
  if (MOCK.fi) {
    return new MockGetFixedIncomeInvestmentRegisterReportRPC();
  } else {
    return new GetFixedIncomeInvestmentRegisterReportRPCClientImpl(apiClient);
  }
};

export const useGetFixedIncomeInvestmentRegisterDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetFixedIncomeInvestmentRegisterDownloadURLRPC => {
  if (MOCK.fi) {
    return new MockGetFixedIncomeInvestmentRegisterDownloadURLRPC();
  } else {
    return new GetFixedIncomeInvestmentRegisterDownloadURLRPCClientImpl(
      apiClient,
    );
  }
};

export const useGetFixedIncomeHoldingSummaryReportRPCClientImpl = (
  apiClient: APIClient,
): GetFixedIncomeHoldingSummaryReportRPC => {
  if (MOCK.fi) {
    return new MockGetFixedIncomeHoldingSummaryReportRPC();
  } else {
    return new GetFixedIncomeHoldingSummaryReportRPCClientImpl(apiClient);
  }
};

export const useGetFixedIncomeHoldingSummaryDownloadURLRPCClientImpl = (
  apiClient: APIClient,
): GetFixedIncomeHoldingSummaryDownloadURLRPC => {
  if (MOCK.fi) {
    return new MockGetFixedIncomeHoldingSummaryDownloadURLRPC();
  } else {
    return new GetFixedIncomeHoldingSummaryDownloadURLRPCClientImpl(apiClient);
  }
};
