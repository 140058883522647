import {
  applySnapshot,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";

export enum MFDealDetailsPageErrors {
  InvalidRequestId = "INVALID_REQUEST_ID",
  RequestAlreadySettled = "REQUEST_ALREADY_SETTLED",
  RequestAlreadyCancelled = "REQUEST_ALREADY_CANCELLED",
  RequestAlreadyChecked = "REQUEST_ALREADY_CHECKED",
  RequestAlreadyExpired = "REQUEST_ALREADY_EXPIRED",
  RequestAlreadyRejected = "REQUEST_ALREADY_REJECTED",
  CanCancelOnlySelfRequest = "CAN_ONLY_CANCEL_SELF_REQUEST",
  CannotCheckSelfRequest = "CANNOT_CHECK_SELF_REQUEST",
  RequestAlreadyActive = "REQUEST_ALREADY_ACTIVE",
  IllegalRequestState = "ILLEGAL_REQUEST_STATE",
  InvalidDealRequestId = "INVALID_DEAL_REQUEST_ID",
  IllegalDealRequestState = "ILLEGAL_DEAL_REQUEST_STATE",
  NoOrderEntryFound = "NO_ORDER_ENTRY_FOUND",
  InvalidBroker = "INVALID_BROKER",
  UnalteredData = "UNALTERED_DATA",
  OrderAmountExceeded = "ORDER_AMOUNT_EXCEEDED",
  OrderUnitsExceeded = "ORDER_UNITS_EXCEEDED",
  CurrencyMismatch = "CURRENCY_MISMATCH",
  CanModifyOnlySelfRequest = "CAN_MODIFY_ONLY_SELF_REQUEST",
  CannotChangeAlreadyLinkedOrderData = "CANNOT_CHANGE_ALREADY_LINKED_ORDER_DATA",
  MultipleEntriesForTheSameNonLinkedOrderFound = "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_ORDER_FOUND",
  UnknownError = "UNKNOWN_ERROR",
}
export const DealDetailsErrorStore = types
  .model("DealDetailsErrorStore", {
    error: types.maybe(
      types.enumeration(Object.values(MFDealDetailsPageErrors)),
    ),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setError: (error: string | undefined): void => {
        if (error) {
          const logger = getEnv(store).logger;
          switch (error) {
            case MFDealDetailsPageErrors.InvalidRequestId:
              store.error = MFDealDetailsPageErrors.InvalidRequestId;
              break;
            case MFDealDetailsPageErrors.RequestAlreadyCancelled:
              store.error = MFDealDetailsPageErrors.RequestAlreadyCancelled;
              break;
            case MFDealDetailsPageErrors.RequestAlreadyActive:
              store.error = MFDealDetailsPageErrors.RequestAlreadyActive;
              break;
            case MFDealDetailsPageErrors.RequestAlreadyRejected:
              store.error = MFDealDetailsPageErrors.RequestAlreadyRejected;
              break;
            case MFDealDetailsPageErrors.CanCancelOnlySelfRequest:
              store.error = MFDealDetailsPageErrors.CanCancelOnlySelfRequest;
              break;
            case MFDealDetailsPageErrors.RequestAlreadySettled:
              store.error = MFDealDetailsPageErrors.RequestAlreadySettled;
              break;
            case MFDealDetailsPageErrors.RequestAlreadyExpired:
              store.error = MFDealDetailsPageErrors.RequestAlreadyExpired;
              break;
            case MFDealDetailsPageErrors.RequestAlreadyChecked:
              store.error = MFDealDetailsPageErrors.RequestAlreadyChecked;
              break;
            case MFDealDetailsPageErrors.CannotCheckSelfRequest:
              store.error = MFDealDetailsPageErrors.CannotCheckSelfRequest;
              break;
            case MFDealDetailsPageErrors.IllegalRequestState:
              store.error = MFDealDetailsPageErrors.IllegalRequestState;
              break;
            case MFDealDetailsPageErrors.InvalidDealRequestId:
              store.error = MFDealDetailsPageErrors.InvalidDealRequestId;
              break;
            case MFDealDetailsPageErrors.IllegalDealRequestState:
              store.error = MFDealDetailsPageErrors.IllegalDealRequestState;
              break;
            case MFDealDetailsPageErrors.NoOrderEntryFound:
              store.error = MFDealDetailsPageErrors.NoOrderEntryFound;
              break;
            case MFDealDetailsPageErrors.InvalidBroker:
              store.error = MFDealDetailsPageErrors.InvalidBroker;
              break;
            case MFDealDetailsPageErrors.UnalteredData:
              store.error = MFDealDetailsPageErrors.UnalteredData;
              break;
            case MFDealDetailsPageErrors.OrderAmountExceeded:
              store.error = MFDealDetailsPageErrors.OrderAmountExceeded;
              break;
            case MFDealDetailsPageErrors.OrderUnitsExceeded:
              store.error = MFDealDetailsPageErrors.OrderUnitsExceeded;
              break;
            case MFDealDetailsPageErrors.CurrencyMismatch:
              store.error = MFDealDetailsPageErrors.CurrencyMismatch;
              break;
            case MFDealDetailsPageErrors.CanModifyOnlySelfRequest:
              store.error = MFDealDetailsPageErrors.CanModifyOnlySelfRequest;
              break;
            case MFDealDetailsPageErrors.CannotChangeAlreadyLinkedOrderData:
              store.error =
                MFDealDetailsPageErrors.CannotChangeAlreadyLinkedOrderData;
              break;
            case MFDealDetailsPageErrors.MultipleEntriesForTheSameNonLinkedOrderFound:
              store.error =
                MFDealDetailsPageErrors.MultipleEntriesForTheSameNonLinkedOrderFound;
              break;
            default:
              store.error = MFDealDetailsPageErrors.UnknownError;
              logger.error(`Unhandled error: ${error}`);
          }
        } else {
          store.error = undefined;
        }
      },
    };
  });

export const createDealDetailsErrorStore = (): Instance<
  typeof DealDetailsErrorStore
> => {
  return DealDetailsErrorStore.create({});
};
