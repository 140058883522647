import { Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { useBorder } from "../../../utils/BorderUtils";
import { useDataVisualizationPalette } from "../../../theme/color-tokens/lightColorTokens";
import { PortfolioChartSize } from "./PortfolioBuyChart";
import { getRoundedOffPercentage } from "../utils/UIUtils";

export const PortfolioSellChart = (
  existing: number,
  otherStocksWithoutRequest: number,
  withRequest: number,
  otherStocksWithRequest: number,
  assetName: string | null,
  transactionType: string | null,
): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const dataVisualizationPalette = useDataVisualizationPalette();
  const { t } = useTranslation();
  return (
    <Stack
      border={border.default}
      height={PortfolioChartSize.headerContainer}
      borderRadius={cornerRadius.radiusXS}
    >
      <Stack sx={{ marginBottom: spacing.space2XL }}>
        <Typography
          sx={{
            ...typography.sh3,
            color: tokens.label,
            backgroundColor: tokens.backgroundSubtle,
            padding: `${spacing.spaceSM} ${spacing.spaceMD}`,
            borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
            borderBottom: border.default,
          }}
        >
          {assetName} - {transactionType}
        </Typography>
      </Stack>
      <Stack flexDirection="row">
        <Chart
          type="pie"
          options={{
            labels: [
              t("equity.dealRequestDetails.existing"),
              t("equity.dealRequestDetails.otherStocks"),
            ],
            tooltip: {
              y: {
                formatter: (value): string => {
                  return t(`common.percentageSymbol`, {
                    value,
                  });
                },
              },
            },
            legend: {
              formatter: (legendName, opts): string => {
                return `${legendName} - ${t("common.percentageSymbol", {
                  value: opts.w.globals.series[opts.seriesIndex],
                })}`;
              },
              offsetY: 40,
            },
            dataLabels: {
              enabled: false,
              textAnchor: "middle",
            },
            colors: [
              dataVisualizationPalette.one,
              dataVisualizationPalette.three,
            ],
          }}
          series={[
            getRoundedOffPercentage(existing),
            getRoundedOffPercentage(otherStocksWithoutRequest),
          ]}
          width={PortfolioChartSize.chart.sell}
        />
        <Chart
          type="pie"
          options={{
            labels: [
              t("equity.dealRequestDetails.withRequest"),
              t("equity.dealRequestDetails.otherStocks"),
            ],
            tooltip: {
              y: {
                formatter: (value): string => {
                  return `${value}%`;
                },
              },
            },
            legend: {
              formatter: (legendName, opts): string => {
                return `${legendName} - ${
                  opts.w.globals.series[opts.seriesIndex]
                }%`;
              },
              offsetY: 40,
            },
            dataLabels: {
              enabled: false,
              textAnchor: "middle",
            },
            colors: [
              dataVisualizationPalette.four,
              dataVisualizationPalette.two,
            ],
          }}
          series={[
            getRoundedOffPercentage(withRequest),
            getRoundedOffPercentage(otherStocksWithRequest),
          ]}
          width={PortfolioChartSize.chart.sell}
        />
      </Stack>
    </Stack>
  );
};
