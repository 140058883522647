"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
const mFDealRequestStatus_1 = require("./mFDealRequestStatus");
const mFTransactionType_1 = require("./mFTransactionType");
class InvalidGetMFDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetMFDealRequestsPaginationResponseError";
    }
}
class GetMFDealRequestsPaginationResponse {
    constructor(requestId, requestStatus, scheme, amc, transactionType, amount = null, units = null, navPerUnit, entity, portfolio, folioNumber = null, receivedAt, currency) {
        this.isGetMFDealRequestsPaginationResponse = true;
        GetMFDealRequestsPaginationResponse.validateRequestId(requestId);
        this.requestId = requestId;
        GetMFDealRequestsPaginationResponse.validateRequestStatus(requestStatus);
        this.requestStatus = requestStatus;
        GetMFDealRequestsPaginationResponse.validateScheme(scheme);
        this.scheme = scheme;
        GetMFDealRequestsPaginationResponse.validateAmc(amc);
        this.amc = amc;
        GetMFDealRequestsPaginationResponse.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        if (amount !== undefined && amount !== null) {
            GetMFDealRequestsPaginationResponse.validateAmount(amount);
        }
        this.amount = amount;
        if (units !== undefined && units !== null) {
            GetMFDealRequestsPaginationResponse.validateUnits(units);
        }
        this.units = units;
        GetMFDealRequestsPaginationResponse.validateNavPerUnit(navPerUnit);
        this.navPerUnit = navPerUnit;
        GetMFDealRequestsPaginationResponse.validateEntity(entity);
        this.entity = entity;
        GetMFDealRequestsPaginationResponse.validatePortfolio(portfolio);
        this.portfolio = portfolio;
        if (folioNumber !== undefined && folioNumber !== null) {
            GetMFDealRequestsPaginationResponse.validateFolioNumber(folioNumber);
        }
        this.folioNumber = folioNumber;
        GetMFDealRequestsPaginationResponse.validateReceivedAt(receivedAt);
        this.receivedAt = receivedAt;
        GetMFDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidGetMFDealRequestsPaginationResponseError);
        const requestStatus = mFDealRequestStatus_1.MFDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestStatus", InvalidGetMFDealRequestsPaginationResponseError));
        const scheme = (0, leo_ts_runtime_1.getString)(dto, "scheme", InvalidGetMFDealRequestsPaginationResponseError);
        const amc = (0, leo_ts_runtime_1.getString)(dto, "amc", InvalidGetMFDealRequestsPaginationResponseError);
        const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidGetMFDealRequestsPaginationResponseError));
        let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
        if (amount !== undefined && amount !== null) {
            amount = bigDecimal_1.BigDecimal.fromDTO(amount);
        }
        let units = (0, leo_ts_runtime_1.getOptionalObject)(dto, "units");
        if (units !== undefined && units !== null) {
            units = bigDecimal_1.BigDecimal.fromDTO(units);
        }
        const navPerUnit = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "navPerUnit", InvalidGetMFDealRequestsPaginationResponseError));
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidGetMFDealRequestsPaginationResponseError);
        const portfolio = (0, leo_ts_runtime_1.getString)(dto, "portfolio", InvalidGetMFDealRequestsPaginationResponseError);
        const folioNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "folioNumber", InvalidGetMFDealRequestsPaginationResponseError);
        const receivedAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "receivedAt", InvalidGetMFDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetMFDealRequestsPaginationResponseError));
        return new GetMFDealRequestsPaginationResponse(requestId, requestStatus, scheme, amc, transactionType, amount, units, navPerUnit, entity, portfolio, folioNumber, receivedAt, currency);
    }
    toDTO() {
        const ret = {
            requestId: this.requestId,
            requestStatus: mFDealRequestStatus_1.MFDealRequestStatus.toDTO(this.requestStatus),
            scheme: this.scheme,
            amc: this.amc,
            transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
            navPerUnit: this.navPerUnit.toDTO(),
            entity: this.entity,
            portfolio: this.portfolio,
            receivedAt: this.receivedAt.toDTO(),
            currency: this.currency.toDTO(),
        };
        if (this.amount) {
            ret.amount = this.amount.toDTO();
        }
        if (this.units) {
            ret.units = this.units.toDTO();
        }
        if (this.folioNumber !== undefined && this.folioNumber !== null) {
            ret.folioNumber = this.folioNumber;
        }
        return ret;
    }
    copy(requestId = this.requestId, requestStatus = this.requestStatus, scheme = this.scheme, amc = this.amc, transactionType = this.transactionType, amount = this.amount, units = this.units, navPerUnit = this.navPerUnit, entity = this.entity, portfolio = this.portfolio, folioNumber = this.folioNumber, receivedAt = this.receivedAt, currency = this.currency) {
        return new GetMFDealRequestsPaginationResponse(requestId, requestStatus, scheme, amc, transactionType, amount, units, navPerUnit, entity, portfolio, folioNumber, receivedAt, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetMFDealRequestsPaginationResponseValue = other.isGetMFDealRequestsPaginationResponse;
        if (!(other instanceof GetMFDealRequestsPaginationResponse) || Boolean(isGetMFDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.requestStatus !== other.requestStatus) {
            return false;
        }
        if (this.scheme !== other.scheme) {
            return false;
        }
        if (this.amc !== other.amc) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.amount) {
            if (!this.amount.equals(other.amount)) {
                return false;
            }
        }
        if (this.units) {
            if (!this.units.equals(other.units)) {
                return false;
            }
        }
        if (!this.navPerUnit.equals(other.navPerUnit)) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.portfolio !== other.portfolio) {
            return false;
        }
        if (this.folioNumber !== other.folioNumber) {
            return false;
        }
        if (!this.receivedAt.equals(other.receivedAt)) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetMFDealRequestsPaginationResponse = GetMFDealRequestsPaginationResponse;
GetMFDealRequestsPaginationResponse.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute requestId is not an Int64`);
    }
};
GetMFDealRequestsPaginationResponse.validateRequestStatus = (requestStatus) => {
    if (!mFDealRequestStatus_1.MFDealRequestStatus.isInstanceOf(requestStatus)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute requestStatus is not a MFDealRequestStatus.MFDealRequestStatus`);
    }
};
GetMFDealRequestsPaginationResponse.validateScheme = (scheme) => {
    if (!(0, leo_ts_runtime_1.isString)(scheme)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute scheme is not a String`);
    }
    if (scheme.trim().length === 0) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute scheme cannot be an empty string.`);
    }
};
GetMFDealRequestsPaginationResponse.validateAmc = (amc) => {
    if (!(0, leo_ts_runtime_1.isString)(amc)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute amc is not a String`);
    }
    if (amc.trim().length === 0) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute amc cannot be an empty string.`);
    }
};
GetMFDealRequestsPaginationResponse.validateTransactionType = (transactionType) => {
    if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
    }
};
GetMFDealRequestsPaginationResponse.validateAmount = (amount) => {
    const isBigDecimalValue = amount.isBigDecimal;
    if (!(amount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute amount is not a BigDecimal`);
    }
};
GetMFDealRequestsPaginationResponse.validateUnits = (units) => {
    const isBigDecimalValue = units.isBigDecimal;
    if (!(units instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute units is not a BigDecimal`);
    }
};
GetMFDealRequestsPaginationResponse.validateNavPerUnit = (navPerUnit) => {
    const isBigDecimalValue = navPerUnit.isBigDecimal;
    if (!(navPerUnit instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute navPerUnit is not a BigDecimal`);
    }
};
GetMFDealRequestsPaginationResponse.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute entity cannot be an empty string.`);
    }
};
GetMFDealRequestsPaginationResponse.validatePortfolio = (portfolio) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute portfolio is not a String`);
    }
    if (portfolio.trim().length === 0) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute portfolio cannot be an empty string.`);
    }
};
GetMFDealRequestsPaginationResponse.validateFolioNumber = (folioNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(folioNumber)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute folioNumber is not a String`);
    }
    if (folioNumber.trim().length === 0) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute folioNumber cannot be an empty string.`);
    }
};
GetMFDealRequestsPaginationResponse.validateReceivedAt = (receivedAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(receivedAt)) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute receivedAt is not a Timestamp`);
    }
};
GetMFDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetMFDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetMFDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetMFDealRequestsPaginationResponse(requestId=${this.requestId},requestStatus=${this.requestStatus},scheme='${this.scheme}',amc='${this.amc}',transactionType=${this.transactionType},amount=${this.amount},units=${this.units},navPerUnit=${this.navPerUnit},entity='${this.entity}',portfolio='${this.portfolio}',folioNumber='${this.folioNumber}',receivedAt=${this.receivedAt},currency=${this.currency})`;
};
