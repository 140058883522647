"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateMFConfirmationRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mFConfirmation_1 = require("./mFConfirmation");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class ValidateMFConfirmationRequestRPC {
}
exports.ValidateMFConfirmationRequestRPC = ValidateMFConfirmationRequestRPC;
(function (ValidateMFConfirmationRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    ValidateMFConfirmationRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId = null, confirmationDetails, note = null) {
            this.isValidateMFConfirmationRequestRPCRequest = true;
            if (requestId !== undefined && requestId !== null) {
                Request.validateRequestId(requestId);
            }
            this.requestId = requestId;
            Request.validateConfirmationDetails(confirmationDetails);
            this.confirmationDetails = confirmationDetails;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "requestId", InvalidRequestError);
            const confirmationDetails = mFConfirmation_1.MFConfirmation.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "confirmationDetails", InvalidRequestError));
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(requestId, confirmationDetails, note);
        }
        toDTO() {
            const ret = {
                confirmationDetails: this.confirmationDetails.toDTO(),
            };
            if (this.requestId !== undefined && this.requestId !== null) {
                ret.requestId = this.requestId;
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(requestId = this.requestId, confirmationDetails = this.confirmationDetails, note = this.note) {
            return new Request(requestId, confirmationDetails, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isValidateMFConfirmationRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (!this.confirmationDetails.equals(other.confirmationDetails)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    Request.validateConfirmationDetails = (confirmationDetails) => {
        const isMFConfirmationValue = confirmationDetails.isMFConfirmation;
        if (!(confirmationDetails instanceof mFConfirmation_1.MFConfirmation || Boolean(isMFConfirmationValue))) {
            throw new InvalidRequestError(`Attribute confirmationDetails is not a MFConfirmation`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    ValidateMFConfirmationRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `ValidateMFConfirmationRequestRPC.Request(requestId=${this.requestId},confirmationDetails=${this.confirmationDetails},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    ValidateMFConfirmationRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    ValidateMFConfirmationRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidAmcId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_AMC_ID") {
                super(code);
                this.isInvalidAmcId = true;
                InvalidAmcId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidAmcId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidAmcId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidAmcIdValue = other.isInvalidAmcId;
                if (!(other instanceof InvalidAmcId || Boolean(isInvalidAmcIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidAmcId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_AMC_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidAmcId = InvalidAmcId;
        InvalidAmcId.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_AMC_ID()`;
        };
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_ISIN()`;
        };
        class InvalidFolioNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_FOLIO_NUMBER") {
                super(code);
                this.isInvalidFolioNumber = true;
                InvalidFolioNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidFolioNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidFolioNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidFolioNumberValue = other.isInvalidFolioNumber;
                if (!(other instanceof InvalidFolioNumber || Boolean(isInvalidFolioNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidFolioNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_FOLIO_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidFolioNumber = InvalidFolioNumber;
        InvalidFolioNumber.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_FOLIO_NUMBER()`;
        };
        class InvalidFolioEntityCombination extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_FOLIO_ENTITY_COMBINATION") {
                super(code);
                this.isInvalidFolioEntityCombination = true;
                InvalidFolioEntityCombination.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidFolioEntityCombination(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidFolioEntityCombination(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidFolioEntityCombinationValue = other.isInvalidFolioEntityCombination;
                if (!(other instanceof InvalidFolioEntityCombination || Boolean(isInvalidFolioEntityCombinationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidFolioEntityCombination.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_FOLIO_ENTITY_COMBINATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidFolioEntityCombination = InvalidFolioEntityCombination;
        InvalidFolioEntityCombination.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_FOLIO_ENTITY_COMBINATION()`;
        };
        class InvalidFolioAmcCombination extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_FOLIO_AMC_COMBINATION") {
                super(code);
                this.isInvalidFolioAmcCombination = true;
                InvalidFolioAmcCombination.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidFolioAmcCombination(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidFolioAmcCombination(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidFolioAmcCombinationValue = other.isInvalidFolioAmcCombination;
                if (!(other instanceof InvalidFolioAmcCombination || Boolean(isInvalidFolioAmcCombinationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidFolioAmcCombination.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_FOLIO_AMC_COMBINATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidFolioAmcCombination = InvalidFolioAmcCombination;
        InvalidFolioAmcCombination.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_FOLIO_AMC_COMBINATION()`;
        };
        class InvalidBrokerId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BROKER_ID") {
                super(code);
                this.isInvalidBrokerId = true;
                InvalidBrokerId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBrokerId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBrokerId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBrokerIdValue = other.isInvalidBrokerId;
                if (!(other instanceof InvalidBrokerId || Boolean(isInvalidBrokerIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBrokerId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BROKER_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBrokerId = InvalidBrokerId;
        InvalidBrokerId.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_BROKER_ID()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.INVALID_REQUEST_ID()`;
        };
        class IllegalConfirmationRequestState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONFIRMATION_REQUEST_STATE") {
                super(code);
                this.isIllegalConfirmationRequestState = true;
                IllegalConfirmationRequestState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalConfirmationRequestState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalConfirmationRequestState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalConfirmationRequestStateValue = other.isIllegalConfirmationRequestState;
                if (!(other instanceof IllegalConfirmationRequestState || Boolean(isIllegalConfirmationRequestStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalConfirmationRequestState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONFIRMATION_REQUEST_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalConfirmationRequestState = IllegalConfirmationRequestState;
        IllegalConfirmationRequestState.prototype.toString = function toString() {
            return `ValidateMFConfirmationRequestRPC.ILLEGAL_CONFIRMATION_REQUEST_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_AMC_ID":
                    return InvalidAmcId.fromDTO(dto);
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_FOLIO_NUMBER":
                    return InvalidFolioNumber.fromDTO(dto);
                case "INVALID_FOLIO_ENTITY_COMBINATION":
                    return InvalidFolioEntityCombination.fromDTO(dto);
                case "INVALID_FOLIO_AMC_COMBINATION":
                    return InvalidFolioAmcCombination.fromDTO(dto);
                case "INVALID_BROKER_ID":
                    return InvalidBrokerId.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "ILLEGAL_CONFIRMATION_REQUEST_STATE":
                    return IllegalConfirmationRequestState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidAmcIdValue = other.isInvalidAmcId;
            if (other instanceof InvalidAmcId || Boolean(isInvalidAmcIdValue)) {
                return true;
            }
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidFolioNumberValue = other.isInvalidFolioNumber;
            if (other instanceof InvalidFolioNumber || Boolean(isInvalidFolioNumberValue)) {
                return true;
            }
            const isInvalidFolioEntityCombinationValue = other.isInvalidFolioEntityCombination;
            if (other instanceof InvalidFolioEntityCombination || Boolean(isInvalidFolioEntityCombinationValue)) {
                return true;
            }
            const isInvalidFolioAmcCombinationValue = other.isInvalidFolioAmcCombination;
            if (other instanceof InvalidFolioAmcCombination || Boolean(isInvalidFolioAmcCombinationValue)) {
                return true;
            }
            const isInvalidBrokerIdValue = other.isInvalidBrokerId;
            if (other instanceof InvalidBrokerId || Boolean(isInvalidBrokerIdValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isIllegalConfirmationRequestStateValue = other.isIllegalConfirmationRequestState;
            if (other instanceof IllegalConfirmationRequestState || Boolean(isIllegalConfirmationRequestStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = ValidateMFConfirmationRequestRPC.Errors || (ValidateMFConfirmationRequestRPC.Errors = {}));
})(ValidateMFConfirmationRequestRPC = exports.ValidateMFConfirmationRequestRPC || (exports.ValidateMFConfirmationRequestRPC = {}));
