"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionType = exports.InvalidTransactionTypeError = void 0;
class InvalidTransactionTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidTransactionTypeError";
    }
}
exports.InvalidTransactionTypeError = InvalidTransactionTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var TransactionType;
(function (TransactionType_1) {
    let TransactionType;
    (function (TransactionType) {
        TransactionType["FI_PURCHASE"] = "FI_PURCHASE";
        TransactionType["FI_SELL"] = "FI_SELL";
        TransactionType["FI_MATURITY"] = "FI_MATURITY";
        TransactionType["FI_COUPON"] = "FI_COUPON";
        TransactionType["FI_PRINCIPAL_PAID"] = "FI_PRINCIPAL_PAID";
        TransactionType["FI_PRINCIPAL_RECEIVED"] = "FI_PRINCIPAL_RECEIVED";
        TransactionType["FI_COUPON_RECEIVED"] = "FI_COUPON_RECEIVED";
        TransactionType["FI_PURCHASE_OTHER_CHARGES"] = "FI_PURCHASE_OTHER_CHARGES";
        TransactionType["FI_SELL_OTHER_CHARGES"] = "FI_SELL_OTHER_CHARGES";
        TransactionType["FI_ACCRUED_COUPON_PAID_AT_PURCHASE"] = "FI_ACCRUED_COUPON_PAID_AT_PURCHASE";
        TransactionType["FI_ACCRUED_COUPON_RECEIVED_AT_SALE"] = "FI_ACCRUED_COUPON_RECEIVED_AT_SALE";
        TransactionType["FI_COUPON_TDS"] = "FI_COUPON_TDS";
        TransactionType["FI_PURCHASE_STAMP_DUTY"] = "FI_PURCHASE_STAMP_DUTY";
        TransactionType["FI_SELL_STAMP_DUTY"] = "FI_SELL_STAMP_DUTY";
        TransactionType["FI_PRINCIPAL_EXPECTED"] = "FI_PRINCIPAL_EXPECTED";
        TransactionType["FI_COUPON_EXPECTED"] = "FI_COUPON_EXPECTED";
        TransactionType["FI_COUPON_TDS_EXPECTED"] = "FI_COUPON_TDS_EXPECTED";
        TransactionType["FI_COUPON_ACCRUAL"] = "FI_COUPON_ACCRUAL";
        TransactionType["FI_COUPON_ACCRUAL_CLEARED"] = "FI_COUPON_ACCRUAL_CLEARED";
        TransactionType["FI_COUPON_TDS_ACCRUAL"] = "FI_COUPON_TDS_ACCRUAL";
        TransactionType["FI_COUPON_TDS_ACCRUAL_CLEARED"] = "FI_COUPON_TDS_ACCRUAL_CLEARED";
        TransactionType["FD_COUPON_ACCRUAL"] = "FD_COUPON_ACCRUAL";
        TransactionType["FD_COUPON_ACCRUAL_CLEARED"] = "FD_COUPON_ACCRUAL_CLEARED";
        TransactionType["FD_COUPON_TDS_ACCRUAL"] = "FD_COUPON_TDS_ACCRUAL";
        TransactionType["FD_COUPON_TDS_ACCRUAL_CLEARED"] = "FD_COUPON_TDS_ACCRUAL_CLEARED";
    })(TransactionType = TransactionType_1.TransactionType || (TransactionType_1.TransactionType = {}));
    TransactionType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "FI_PURCHASE":
                return TransactionType.FI_PURCHASE;
            case "FI_SELL":
                return TransactionType.FI_SELL;
            case "FI_MATURITY":
                return TransactionType.FI_MATURITY;
            case "FI_COUPON":
                return TransactionType.FI_COUPON;
            case "FI_PRINCIPAL_PAID":
                return TransactionType.FI_PRINCIPAL_PAID;
            case "FI_PRINCIPAL_RECEIVED":
                return TransactionType.FI_PRINCIPAL_RECEIVED;
            case "FI_COUPON_RECEIVED":
                return TransactionType.FI_COUPON_RECEIVED;
            case "FI_PURCHASE_OTHER_CHARGES":
                return TransactionType.FI_PURCHASE_OTHER_CHARGES;
            case "FI_SELL_OTHER_CHARGES":
                return TransactionType.FI_SELL_OTHER_CHARGES;
            case "FI_ACCRUED_COUPON_PAID_AT_PURCHASE":
                return TransactionType.FI_ACCRUED_COUPON_PAID_AT_PURCHASE;
            case "FI_ACCRUED_COUPON_RECEIVED_AT_SALE":
                return TransactionType.FI_ACCRUED_COUPON_RECEIVED_AT_SALE;
            case "FI_COUPON_TDS":
                return TransactionType.FI_COUPON_TDS;
            case "FI_PURCHASE_STAMP_DUTY":
                return TransactionType.FI_PURCHASE_STAMP_DUTY;
            case "FI_SELL_STAMP_DUTY":
                return TransactionType.FI_SELL_STAMP_DUTY;
            case "FI_PRINCIPAL_EXPECTED":
                return TransactionType.FI_PRINCIPAL_EXPECTED;
            case "FI_COUPON_EXPECTED":
                return TransactionType.FI_COUPON_EXPECTED;
            case "FI_COUPON_TDS_EXPECTED":
                return TransactionType.FI_COUPON_TDS_EXPECTED;
            case "FI_COUPON_ACCRUAL":
                return TransactionType.FI_COUPON_ACCRUAL;
            case "FI_COUPON_ACCRUAL_CLEARED":
                return TransactionType.FI_COUPON_ACCRUAL_CLEARED;
            case "FI_COUPON_TDS_ACCRUAL":
                return TransactionType.FI_COUPON_TDS_ACCRUAL;
            case "FI_COUPON_TDS_ACCRUAL_CLEARED":
                return TransactionType.FI_COUPON_TDS_ACCRUAL_CLEARED;
            case "FD_COUPON_ACCRUAL":
                return TransactionType.FD_COUPON_ACCRUAL;
            case "FD_COUPON_ACCRUAL_CLEARED":
                return TransactionType.FD_COUPON_ACCRUAL_CLEARED;
            case "FD_COUPON_TDS_ACCRUAL":
                return TransactionType.FD_COUPON_TDS_ACCRUAL;
            case "FD_COUPON_TDS_ACCRUAL_CLEARED":
                return TransactionType.FD_COUPON_TDS_ACCRUAL_CLEARED;
            default:
                throw new InvalidTransactionTypeError(`Case ${dto.case} is not valid case of TransactionType`);
        }
    };
    TransactionType_1.toDTO = (transactionType) => {
        const ret = {
            case: TransactionType[transactionType],
        };
        return ret;
    };
    TransactionType_1.isInstanceOf = (other) => {
        if (other in TransactionType) {
            return true;
        }
        return false;
    };
})(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
