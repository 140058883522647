import { getParent, IAnyStateTreeNode, Instance } from "mobx-state-tree";
import { useMFStore } from "../../../store/hooks";
import { ConfirmationRequestDetailsStore } from "./ConfirmationRequestDetailsStore";
import { ConfirmationRequestDetailsErrorStore } from "./ConfirmationRequestDetailsErrorStore";

export const useMFConfirmationRequestDetailsStore = (): Instance<
  typeof ConfirmationRequestDetailsStore
> => {
  return useMFStore().confirmationRequestDetailsStore;
};

export const mfConfirmationErrorStore = (
  store: IAnyStateTreeNode,
): Instance<typeof ConfirmationRequestDetailsErrorStore> => {
  const confirmationDetailsStore =
    getParent<typeof ConfirmationRequestDetailsStore>(store);
  return confirmationDetailsStore.errorStore;
};
