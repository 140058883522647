import {
  Currency,
  EquityHoldings,
  GetEquityTopTenHoldingsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityTopTenHoldingsRPCImpl extends GetEquityTopTenHoldingsRPC {
  execute(
    _request: GetEquityTopTenHoldingsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityTopTenHoldingsRPC.Response,
      GetEquityTopTenHoldingsRPC.Errors.Errors
    >
  > {
    const response = new GetEquityTopTenHoldingsRPC.Response(
      [
        new EquityHoldings("HDFC", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("ICICI", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("Axis", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("SBI", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("Kotak", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("BOSCHLTD", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("FOCUS", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("PIXTRANS", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("MAGADSUGAR", 1000000000, 123232323, 2000, 120000),
        new EquityHoldings("HCG", 1000000000, 123232323, 2000, 120000),
      ],
      new Currency("INR", "₹"),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityTopTenHoldingsRPC.Response,
        GetEquityTopTenHoldingsRPC.Errors.Errors
      >
    >;
  }
}
