"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteCharge = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidFiContractNoteChargeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteChargeError";
    }
}
class FiContractNoteCharge {
    constructor(displayName, chargeType, chargeRuleId = null, amount = null, diffValue = null) {
        this.isFiContractNoteCharge = true;
        FiContractNoteCharge.validateDisplayName(displayName);
        this.displayName = displayName;
        FiContractNoteCharge.validateChargeType(chargeType);
        this.chargeType = chargeType;
        if (chargeRuleId !== undefined && chargeRuleId !== null) {
            FiContractNoteCharge.validateChargeRuleId(chargeRuleId);
        }
        this.chargeRuleId = chargeRuleId;
        if (amount !== undefined && amount !== null) {
            FiContractNoteCharge.validateAmount(amount);
        }
        this.amount = amount;
        if (diffValue !== undefined && diffValue !== null) {
            FiContractNoteCharge.validateDiffValue(diffValue);
        }
        this.diffValue = diffValue;
    }
    static fromDTO(dto) {
        const displayName = (0, leo_ts_runtime_1.getString)(dto, "displayName", InvalidFiContractNoteChargeError);
        const chargeType = (0, leo_ts_runtime_1.getString)(dto, "chargeType", InvalidFiContractNoteChargeError);
        const chargeRuleId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "chargeRuleId", InvalidFiContractNoteChargeError);
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidFiContractNoteChargeError);
        const diffValue = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "diffValue", InvalidFiContractNoteChargeError);
        return new FiContractNoteCharge(displayName, chargeType, chargeRuleId, amount, diffValue);
    }
    toDTO() {
        const ret = {
            displayName: this.displayName,
            chargeType: this.chargeType,
        };
        if (this.chargeRuleId !== undefined && this.chargeRuleId !== null) {
            ret.chargeRuleId = this.chargeRuleId;
        }
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.diffValue !== undefined && this.diffValue !== null) {
            ret.diffValue = this.diffValue;
        }
        return ret;
    }
    copy(displayName = this.displayName, chargeType = this.chargeType, chargeRuleId = this.chargeRuleId, amount = this.amount, diffValue = this.diffValue) {
        return new FiContractNoteCharge(displayName, chargeType, chargeRuleId, amount, diffValue);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFiContractNoteChargeValue = other.isFiContractNoteCharge;
        if (!(other instanceof FiContractNoteCharge) || Boolean(isFiContractNoteChargeValue)) {
            return false;
        }
        if (this.displayName !== other.displayName) {
            return false;
        }
        if (this.chargeType !== other.chargeType) {
            return false;
        }
        if (this.chargeRuleId !== other.chargeRuleId) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (this.diffValue !== other.diffValue) {
            return false;
        }
        return true;
    }
}
exports.FiContractNoteCharge = FiContractNoteCharge;
FiContractNoteCharge.validateDisplayName = (displayName) => {
    if (!(0, leo_ts_runtime_1.isString)(displayName)) {
        throw new InvalidFiContractNoteChargeError(`Attribute displayName is not a String`);
    }
    if (displayName.trim().length === 0) {
        throw new InvalidFiContractNoteChargeError(`Attribute displayName cannot be an empty string.`);
    }
};
FiContractNoteCharge.validateChargeType = (chargeType) => {
    if (!(0, leo_ts_runtime_1.isString)(chargeType)) {
        throw new InvalidFiContractNoteChargeError(`Attribute chargeType is not a String`);
    }
    if (chargeType.trim().length === 0) {
        throw new InvalidFiContractNoteChargeError(`Attribute chargeType cannot be an empty string.`);
    }
};
FiContractNoteCharge.validateChargeRuleId = (chargeRuleId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(chargeRuleId)) {
        throw new InvalidFiContractNoteChargeError(`Attribute chargeRuleId is not an Int64`);
    }
};
FiContractNoteCharge.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidFiContractNoteChargeError(`Attribute amount is not an Int64`);
    }
    if (amount < 0) {
        throw new InvalidFiContractNoteChargeError(`Attribute amount has value ${amount}. Min value is 0.`);
    }
};
FiContractNoteCharge.validateDiffValue = (diffValue) => {
    if (!(0, leo_ts_runtime_1.isInt64)(diffValue)) {
        throw new InvalidFiContractNoteChargeError(`Attribute diffValue is not an Int64`);
    }
    if (diffValue < 0) {
        throw new InvalidFiContractNoteChargeError(`Attribute diffValue has value ${diffValue}. Min value is 0.`);
    }
};
FiContractNoteCharge.prototype.toString = function toString() {
    return `FiContractNoteCharge(displayName='${this.displayName}',chargeType='${this.chargeType}',chargeRuleId=${this.chargeRuleId},amount=${this.amount},diffValue=${this.diffValue})`;
};
