"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFixedDepositInvestmentRegisterDownloadURLRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fileExtension_1 = require("./fileExtension");
/* eslint-disable import/export */
class GetFixedDepositInvestmentRegisterDownloadURLRPC {
}
exports.GetFixedDepositInvestmentRegisterDownloadURLRPC = GetFixedDepositInvestmentRegisterDownloadURLRPC;
(function (GetFixedDepositInvestmentRegisterDownloadURLRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFixedDepositInvestmentRegisterDownloadURLRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId = null, portfolioId = null, fromDate, toDate, fileExtension) {
            this.isGetFixedDepositInvestmentRegisterDownloadURLRPCRequest = true;
            if (entityId !== undefined && entityId !== null) {
                Request.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
            Request.validateFromDate(fromDate);
            this.fromDate = fromDate;
            Request.validateToDate(toDate);
            this.toDate = toDate;
            Request.validateFileExtension(fileExtension);
            this.fileExtension = fileExtension;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const fromDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "fromDate", InvalidRequestError);
            const toDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "toDate", InvalidRequestError);
            const fileExtension = fileExtension_1.FileExtension.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "fileExtension", InvalidRequestError));
            return new Request(entityId, portfolioId, fromDate, toDate, fileExtension);
        }
        toDTO() {
            const ret = {
                fromDate: this.fromDate.toDTO(),
                toDate: this.toDate.toDTO(),
                fileExtension: fileExtension_1.FileExtension.toDTO(this.fileExtension),
            };
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, fromDate = this.fromDate, toDate = this.toDate, fileExtension = this.fileExtension) {
            return new Request(entityId, portfolioId, fromDate, toDate, fileExtension);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFixedDepositInvestmentRegisterDownloadURLRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            if (!this.fromDate.equals(other.fromDate)) {
                return false;
            }
            if (!this.toDate.equals(other.toDate)) {
                return false;
            }
            if (this.fileExtension !== other.fileExtension) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateFromDate = (fromDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(fromDate)) {
            throw new InvalidRequestError(`Attribute fromDate is not a Date`);
        }
    };
    Request.validateToDate = (toDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(toDate)) {
            throw new InvalidRequestError(`Attribute toDate is not a Date`);
        }
    };
    Request.validateFileExtension = (fileExtension) => {
        if (!fileExtension_1.FileExtension.isInstanceOf(fileExtension)) {
            throw new InvalidRequestError(`Attribute fileExtension is not a FileExtension.FileExtension`);
        }
    };
    GetFixedDepositInvestmentRegisterDownloadURLRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFixedDepositInvestmentRegisterDownloadURLRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},fromDate=${this.fromDate},toDate=${this.toDate},fileExtension=${this.fileExtension})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFixedDepositInvestmentRegisterDownloadURLRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(downloadUrl) {
            this.isGetFixedDepositInvestmentRegisterDownloadURLRPCResponse = true;
            Response.validateDownloadUrl(downloadUrl);
            this.downloadUrl = downloadUrl;
        }
        static fromDTO(dto) {
            const downloadUrl = (0, leo_ts_runtime_1.getUrl)(dto, "downloadUrl", InvalidResponseError);
            return new Response(downloadUrl);
        }
        toDTO() {
            const ret = {
                downloadUrl: this.downloadUrl.toString(),
            };
            return ret;
        }
        copy(downloadUrl = this.downloadUrl) {
            return new Response(downloadUrl);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFixedDepositInvestmentRegisterDownloadURLRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.downloadUrl.toString() !== other.downloadUrl.toString()) {
                return false;
            }
            return true;
        }
    }
    Response.validateDownloadUrl = (downloadUrl) => {
        if (!(downloadUrl instanceof URL)) {
            throw new InvalidResponseError(`Attribute downloadUrl is not a URL`);
        }
    };
    GetFixedDepositInvestmentRegisterDownloadURLRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFixedDepositInvestmentRegisterDownloadURLRPC.Response(downloadUrl=${this.downloadUrl})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidEntity extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY") {
                super(code);
                this.isInvalidEntity = true;
                InvalidEntity.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntity(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntity(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityValue = other.isInvalidEntity;
                if (!(other instanceof InvalidEntity || Boolean(isInvalidEntityValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntity.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntity = InvalidEntity;
        InvalidEntity.prototype.toString = function toString() {
            return `GetFixedDepositInvestmentRegisterDownloadURLRPC.INVALID_ENTITY()`;
        };
        class InvalidPortfolio extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO") {
                super(code);
                this.isInvalidPortfolio = true;
                InvalidPortfolio.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolio(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolio(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioValue = other.isInvalidPortfolio;
                if (!(other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolio.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolio = InvalidPortfolio;
        InvalidPortfolio.prototype.toString = function toString() {
            return `GetFixedDepositInvestmentRegisterDownloadURLRPC.INVALID_PORTFOLIO()`;
        };
        class InvalidDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DATE") {
                super(code);
                this.isInvalidDate = true;
                InvalidDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDateValue = other.isInvalidDate;
                if (!(other instanceof InvalidDate || Boolean(isInvalidDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDate = InvalidDate;
        InvalidDate.prototype.toString = function toString() {
            return `GetFixedDepositInvestmentRegisterDownloadURLRPC.INVALID_DATE()`;
        };
        class NoDataAvailable extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NO_DATA_AVAILABLE") {
                super(code);
                this.isNoDataAvailable = true;
                NoDataAvailable.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NoDataAvailable(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NoDataAvailable(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNoDataAvailableValue = other.isNoDataAvailable;
                if (!(other instanceof NoDataAvailable || Boolean(isNoDataAvailableValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NoDataAvailable.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NO_DATA_AVAILABLE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NoDataAvailable = NoDataAvailable;
        NoDataAvailable.prototype.toString = function toString() {
            return `GetFixedDepositInvestmentRegisterDownloadURLRPC.NO_DATA_AVAILABLE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ENTITY":
                    return InvalidEntity.fromDTO(dto);
                case "INVALID_PORTFOLIO":
                    return InvalidPortfolio.fromDTO(dto);
                case "INVALID_DATE":
                    return InvalidDate.fromDTO(dto);
                case "NO_DATA_AVAILABLE":
                    return NoDataAvailable.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidEntityValue = other.isInvalidEntity;
            if (other instanceof InvalidEntity || Boolean(isInvalidEntityValue)) {
                return true;
            }
            const isInvalidPortfolioValue = other.isInvalidPortfolio;
            if (other instanceof InvalidPortfolio || Boolean(isInvalidPortfolioValue)) {
                return true;
            }
            const isInvalidDateValue = other.isInvalidDate;
            if (other instanceof InvalidDate || Boolean(isInvalidDateValue)) {
                return true;
            }
            const isNoDataAvailableValue = other.isNoDataAvailable;
            if (other instanceof NoDataAvailable || Boolean(isNoDataAvailableValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFixedDepositInvestmentRegisterDownloadURLRPC.Errors || (GetFixedDepositInvestmentRegisterDownloadURLRPC.Errors = {}));
})(GetFixedDepositInvestmentRegisterDownloadURLRPC = exports.GetFixedDepositInvestmentRegisterDownloadURLRPC || (exports.GetFixedDepositInvestmentRegisterDownloadURLRPC = {}));
