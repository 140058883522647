"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFConfirmationRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const currency_1 = require("../types/currency");
const mFConfirmationRequestStatus_1 = require("./mFConfirmationRequestStatus");
class InvalidGetMFConfirmationRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetMFConfirmationRequestsPaginationResponseError";
    }
}
class GetMFConfirmationRequestsPaginationResponse {
    constructor(requestId, requestStatus, broker = null, brokerId = null, amc, scheme, referenceNumber = null, amount, receivedAt, currency) {
        this.isGetMFConfirmationRequestsPaginationResponse = true;
        GetMFConfirmationRequestsPaginationResponse.validateRequestId(requestId);
        this.requestId = requestId;
        GetMFConfirmationRequestsPaginationResponse.validateRequestStatus(requestStatus);
        this.requestStatus = requestStatus;
        if (broker !== undefined && broker !== null) {
            GetMFConfirmationRequestsPaginationResponse.validateBroker(broker);
        }
        this.broker = broker;
        if (brokerId !== undefined && brokerId !== null) {
            GetMFConfirmationRequestsPaginationResponse.validateBrokerId(brokerId);
        }
        this.brokerId = brokerId;
        GetMFConfirmationRequestsPaginationResponse.validateAmc(amc);
        this.amc = amc;
        GetMFConfirmationRequestsPaginationResponse.validateScheme(scheme);
        this.scheme = scheme;
        if (referenceNumber !== undefined && referenceNumber !== null) {
            GetMFConfirmationRequestsPaginationResponse.validateReferenceNumber(referenceNumber);
        }
        this.referenceNumber = referenceNumber;
        GetMFConfirmationRequestsPaginationResponse.validateAmount(amount);
        this.amount = amount;
        GetMFConfirmationRequestsPaginationResponse.validateReceivedAt(receivedAt);
        this.receivedAt = receivedAt;
        GetMFConfirmationRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidGetMFConfirmationRequestsPaginationResponseError);
        const requestStatus = mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestStatus", InvalidGetMFConfirmationRequestsPaginationResponseError));
        const broker = (0, leo_ts_runtime_1.getOptionalString)(dto, "broker", InvalidGetMFConfirmationRequestsPaginationResponseError);
        const brokerId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "brokerId", InvalidGetMFConfirmationRequestsPaginationResponseError);
        const amc = (0, leo_ts_runtime_1.getString)(dto, "amc", InvalidGetMFConfirmationRequestsPaginationResponseError);
        const scheme = (0, leo_ts_runtime_1.getString)(dto, "scheme", InvalidGetMFConfirmationRequestsPaginationResponseError);
        const referenceNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "referenceNumber", InvalidGetMFConfirmationRequestsPaginationResponseError);
        const amount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidGetMFConfirmationRequestsPaginationResponseError));
        const receivedAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "receivedAt", InvalidGetMFConfirmationRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetMFConfirmationRequestsPaginationResponseError));
        return new GetMFConfirmationRequestsPaginationResponse(requestId, requestStatus, broker, brokerId, amc, scheme, referenceNumber, amount, receivedAt, currency);
    }
    toDTO() {
        const ret = {
            requestId: this.requestId,
            requestStatus: mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.toDTO(this.requestStatus),
            amc: this.amc,
            scheme: this.scheme,
            amount: this.amount.toDTO(),
            receivedAt: this.receivedAt.toDTO(),
            currency: this.currency.toDTO(),
        };
        if (this.broker !== undefined && this.broker !== null) {
            ret.broker = this.broker;
        }
        if (this.brokerId) {
            ret.brokerId = this.brokerId.toDTO();
        }
        if (this.referenceNumber !== undefined && this.referenceNumber !== null) {
            ret.referenceNumber = this.referenceNumber;
        }
        return ret;
    }
    copy(requestId = this.requestId, requestStatus = this.requestStatus, broker = this.broker, brokerId = this.brokerId, amc = this.amc, scheme = this.scheme, referenceNumber = this.referenceNumber, amount = this.amount, receivedAt = this.receivedAt, currency = this.currency) {
        return new GetMFConfirmationRequestsPaginationResponse(requestId, requestStatus, broker, brokerId, amc, scheme, referenceNumber, amount, receivedAt, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetMFConfirmationRequestsPaginationResponseValue = other.isGetMFConfirmationRequestsPaginationResponse;
        if (!(other instanceof GetMFConfirmationRequestsPaginationResponse) || Boolean(isGetMFConfirmationRequestsPaginationResponseValue)) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.requestStatus !== other.requestStatus) {
            return false;
        }
        if (this.broker !== other.broker) {
            return false;
        }
        if (this.brokerId) {
            if (!this.brokerId.equals(other.brokerId)) {
                return false;
            }
        }
        if (this.amc !== other.amc) {
            return false;
        }
        if (this.scheme !== other.scheme) {
            return false;
        }
        if (this.referenceNumber !== other.referenceNumber) {
            return false;
        }
        if (!this.amount.equals(other.amount)) {
            return false;
        }
        if (!this.receivedAt.equals(other.receivedAt)) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetMFConfirmationRequestsPaginationResponse = GetMFConfirmationRequestsPaginationResponse;
GetMFConfirmationRequestsPaginationResponse.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute requestId is not an Int64`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateRequestStatus = (requestStatus) => {
    if (!mFConfirmationRequestStatus_1.MFConfirmationRequestStatus.isInstanceOf(requestStatus)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute requestStatus is not a MFConfirmationRequestStatus.MFConfirmationRequestStatus`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateBroker = (broker) => {
    if (!(0, leo_ts_runtime_1.isString)(broker)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute broker is not a String`);
    }
    if (broker.trim().length === 0) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute broker cannot be an empty string.`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute brokerId is not a UUID`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateAmc = (amc) => {
    if (!(0, leo_ts_runtime_1.isString)(amc)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute amc is not a String`);
    }
    if (amc.trim().length === 0) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute amc cannot be an empty string.`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateScheme = (scheme) => {
    if (!(0, leo_ts_runtime_1.isString)(scheme)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute scheme is not a String`);
    }
    if (scheme.trim().length === 0) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute scheme cannot be an empty string.`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateReferenceNumber = (referenceNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(referenceNumber)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute referenceNumber is not a String`);
    }
    if (referenceNumber.trim().length === 0) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute referenceNumber cannot be an empty string.`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateAmount = (amount) => {
    const isBigDecimalValue = amount.isBigDecimal;
    if (!(amount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute amount is not a BigDecimal`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateReceivedAt = (receivedAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(receivedAt)) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute receivedAt is not a Timestamp`);
    }
};
GetMFConfirmationRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetMFConfirmationRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetMFConfirmationRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetMFConfirmationRequestsPaginationResponse(requestId=${this.requestId},requestStatus=${this.requestStatus},broker='${this.broker}',brokerId=${this.brokerId},amc='${this.amc}',scheme='${this.scheme}',referenceNumber='${this.referenceNumber}',amount=${this.amount},receivedAt=${this.receivedAt},currency=${this.currency})`;
};
