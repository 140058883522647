import { observer } from "mobx-react";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Dialog } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Divider, Stack } from "@mui/material";
import { EntityDropdown } from "../entity/EntityDropdown";
import { Instance } from "mobx-state-tree";
import { EntityDropdownStore } from "../entity/EntityDropdownStore";
import { PortfolioDropdown } from "../portfolio/PortfolioDropdown";
import { PortfolioDropdownStore } from "../portfolio/PortfolioDropdownStore";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { getPeriodOptions, Period } from "../../utils/ReportUtils";
import { useSpacing } from "@surya-digital/leo-reactjs-core";
import { ModuleType, PeriodInput } from "@khazana/khazana-rpcs";
import { DATE_PICKER_FORMAT, getLeoDate } from "../../../../utils";
import { PortfolioModel } from "../../models/PortfolioModel";
import { AllEnum } from "../../../../types/EnumTypes";
import { DatePickerInput } from "../date-picker/DatePickerField";
import { DateRangePickerInput } from "../date-picker/DateRangePickerField";
import { DatePickerFieldSeparateLabel } from "../date-picker/DatePickerFieldSeparateLabel";
import { DateRangePickerFieldSeparateLabel } from "../date-picker/DateRangePickerFieldSeparateLabel";

const size = {
  width: "250px",
  dateRangeWidth: "235px",
};

export interface ReportFilterDialogProps {
  open: boolean;
  onClose: () => void;
  onViewReport: (period: PeriodInput.PeriodInput | undefined) => Promise<void>;
  module: ModuleType.ModuleType;
  showReportType: boolean;
  period: {
    reportType?: Period;
    asOnDate: DatePickerInput | null;
    betweenTwoDatesDateRange: DateRangePickerInput;
  };
  entityDropdownStore: Instance<typeof EntityDropdownStore>;
  portfolioDropdownStore: Instance<typeof PortfolioDropdownStore>;
  isAllOptionEnabled?: boolean;
  additionalFields?: AdditionalFilterFields[];
  toDateLabel?: string;
  fromDateLabel?: string;
  isPeriodRequired?: boolean;
}

interface AdditionalFilterFields {
  element: ReactElement;
  selectedValue?: string | undefined;
  isRequired: boolean;
}

interface InititalFilter {
  entity: string | undefined;
  portfolio: Instance<typeof PortfolioModel> | undefined;
}
export const ReportFilterDialog = observer(
  ({
    open,
    onClose,
    onViewReport,
    module,
    showReportType,
    period,
    entityDropdownStore,
    portfolioDropdownStore,
    isAllOptionEnabled = false,
    additionalFields = [],
    fromDateLabel,
    toDateLabel,
    isPeriodRequired = true,
  }: ReportFilterDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const spacing = useSpacing();
    const [selectedReportType, setSelectedReportType] = useState<
      string | undefined
    >(period.reportType);
    const [initialState, setInitialState] = useState<InititalFilter>({
      entity: entityDropdownStore.selectedEntity,
      portfolio: portfolioDropdownStore.selectedPortfolio,
    });
    const [viewReportButtonDisabled, setViewReportButtonDisabled] =
      useState(false);
    const [asOnDate, setAsOnDate] = useState<DatePickerInput | null>(
      period.asOnDate,
    );
    const [betweenTwoDatesDateRange, setBetweenTwoDatesRange] =
      useState<DateRangePickerInput>(period.betweenTwoDatesDateRange);

    const getEntityList = useCallback(() => {
      entityDropdownStore.getEntityList();
    }, []);

    const getPortfolioList = useCallback(() => {
      if (entityDropdownStore.selectedEntity) {
        portfolioDropdownStore.getPortfolioList(
          entityDropdownStore.selectedEntity,
          module,
        );
      }
    }, [entityDropdownStore.selectedEntity]);

    useEffect(() => {
      getEntityList();
      if (isAllOptionEnabled && !entityDropdownStore.selectedEntity) {
        entityDropdownStore.setSelectedEntity(AllEnum.All);
      }
    }, []);

    useEffect(() => {
      if (entityDropdownStore.selectedEntity !== AllEnum.All) {
        getPortfolioList();
      } else {
        portfolioDropdownStore.clearList();
        portfolioDropdownStore.deselectPortfolio();
        portfolioDropdownStore.setSelectedPortfolio({
          id: AllEnum.All,
          name: t("common.all"),
        });
      }
    }, [entityDropdownStore.selectedEntity]);

    useEffect(() => {
      if (
        !entityDropdownStore.selectedEntity ||
        !portfolioDropdownStore.selectedPortfolio.id ||
        !selectedReportType ||
        (selectedReportType === Period.AS_ON_DATE &&
          (!asOnDate || asOnDate > new Date()) &&
          isPeriodRequired) ||
        (selectedReportType === Period.BETWEEN_TWO_DATES &&
          !betweenTwoDatesDateRange.endDate &&
          isPeriodRequired)
      ) {
        setViewReportButtonDisabled(true);
      } else {
        if (
          additionalFields.find((item) => {
            Boolean(item.selectedValue === undefined && item.isRequired);
          }) !== undefined
        ) {
          setViewReportButtonDisabled(true);
        } else {
          setViewReportButtonDisabled(false);
        }
      }
    }, [
      entityDropdownStore.selectedEntity,
      selectedReportType,
      portfolioDropdownStore.selectedPortfolio,
      betweenTwoDatesDateRange,
      asOnDate,
      ...additionalFields.map((item) => item.selectedValue),
    ]);

    const getPeriodInput = (): PeriodInput.PeriodInput | undefined => {
      switch (selectedReportType) {
        case Period.AS_ON_DATE:
          if (!asOnDate) {
            return undefined;
          }
          return new PeriodInput.AsOnDate(getLeoDate(asOnDate as Date));
        case Period.BETWEEN_TWO_DATES:
          if (
            !betweenTwoDatesDateRange.startDate &&
            !betweenTwoDatesDateRange.endDate
          ) {
            return undefined;
          }
          return new PeriodInput.BetweenTwoDates(
            getLeoDate(betweenTwoDatesDateRange.startDate as Date),
            getLeoDate(betweenTwoDatesDateRange.endDate as Date),
          );
      }
    };

    const getDateField = (): React.ReactElement => {
      return (
        <DatePickerFieldSeparateLabel
          label={t("reports.date")}
          isRequired={true}
          style={{ width: size.width }}
          value={asOnDate}
          maxDate={new Date()}
          onChange={(newDate): void => {
            setAsOnDate(newDate);
          }}
          onError={(): void => {
            setViewReportButtonDisabled(true);
          }}
          format={DATE_PICKER_FORMAT}
        />
      );
    };

    const getDateRange = (): React.ReactElement => {
      return (
        <DateRangePickerFieldSeparateLabel
          value={betweenTwoDatesDateRange}
          startDateLabel={fromDateLabel ?? t("reports.startDate")}
          endDateLabel={toDateLabel ?? t("reports.endDate")}
          maxDate={new Date()}
          onChange={(newValue: DateRangePickerInput): void => {
            const value = {
              startDate: newValue.startDate,
              endDate: newValue.endDate,
            };
            if (!newValue.startDate) {
              value.startDate = betweenTwoDatesDateRange.startDate;
            }
            if (!newValue.endDate) {
              value.endDate = betweenTwoDatesDateRange.endDate;
            }
            setBetweenTwoDatesRange(value);
          }}
          showDivider
          onError={(startDateError, endDateError): void => {
            if (startDateError ?? endDateError) {
              setViewReportButtonDisabled(true);
            }
          }}
          style={{ width: size.dateRangeWidth }}
          format={DATE_PICKER_FORMAT}
        />
      );
    };

    const getDateFilter = (): React.ReactElement => {
      switch (selectedReportType) {
        case Period.AS_ON_DATE:
          return getDateField();
        case Period.BETWEEN_TWO_DATES:
          return getDateRange();
        default:
          return <></>;
      }
    };

    const resetFilter = (): void => {
      entityDropdownStore.setSelectedEntity(initialState.entity);
      portfolioDropdownStore.setSelectedPortfolio(initialState.portfolio);
      setInitialState({
        entity: undefined,
        portfolio: undefined,
      });
    };

    const getAdditionalFields = (): React.ReactElement => {
      //shift() functions modifies the original list, hence to avoid modifications to the additionalFields list, a copy of it is created.
      const copyList = [...additionalFields];
      const stackList: React.ReactElement[] = [];
      while (copyList.length > 0) {
        stackList.push(
          <Stack direction={"row"} spacing={spacing.spaceLG}>
            {copyList.shift()?.element}
            {copyList.shift()?.element}
          </Stack>,
        );
      }
      return <>{stackList}</>;
    };

    return (
      <Dialog
        open={open}
        onClose={() => {
          resetFilter();
          onClose();
        }}
        isCloseIconPresent={true}
        disableBackdropClick
        title={t("reports.editFilter")}
        primaryButtonText={t("reports.viewReport")}
        isPrimaryButtonDisabled={viewReportButtonDisabled}
        onPrimaryButtonClick={() => {
          const periodInput = getPeriodInput();
          return onViewReport(periodInput);
        }}
      >
        <Stack spacing={spacing.spaceLG}>
          <Stack direction={"row"} spacing={spacing.spaceLG}>
            <EntityDropdown
              store={entityDropdownStore}
              width={size.width}
              isRequired={true}
              onChange={(): void => {
                portfolioDropdownStore.deselectPortfolio();
                portfolioDropdownStore.clearList();
              }}
              isAllOptionEnabled={isAllOptionEnabled}
            />
            <PortfolioDropdown
              store={portfolioDropdownStore}
              width={size.width}
              isRequired={true}
              isDisabled={entityDropdownStore.selectedEntity === undefined}
              isAllOptionEnabled={isAllOptionEnabled}
            />
          </Stack>
          {showReportType && (
            <DropdownInputFieldSeparateLabel
              name={"reportType"}
              isRequired={true}
              label={t("reports.reportType")}
              placeholder={t("reports.selectReportType")}
              value={selectedReportType}
              style={{ width: size.width }}
              options={getPeriodOptions()}
              onSelect={(value): void => {
                setSelectedReportType(value.value);
                setAsOnDate(null);
                setBetweenTwoDatesRange({ startDate: null, endDate: null });
              }}
            />
          )}
          {getAdditionalFields()}
          <Divider
            sx={{ marginTop: spacing.spaceLG, marginBottom: spacing.spaceLG }}
          />
          {getDateFilter()}
        </Stack>
      </Dialog>
    );
  },
);
