import { Button, ButtonIcon } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";

export interface HeaderButtonProps {
  label: string;
  onClick: () => void;
  buttonType: ButtonType;
  icon?: ButtonIcon;
  disabled?: boolean;
}

export type ButtonType = "filled" | "outlined" | "red-outlined";

export const HeaderButton = ({
  label,
  onClick,
  buttonType,
  icon,
  disabled = false,
}: HeaderButtonProps): React.ReactElement => {
  return (
    <Button
      name={label}
      label={label}
      onClick={onClick}
      disabled={disabled}
      icon={icon}
      variant={buttonType === "filled" ? "filled" : "outlined-color"}
      color={buttonType === "red-outlined" ? "destructive" : "primary"}
      size="large"
      iconPosition="leading"
    />
  );
};
