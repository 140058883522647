import {
  applySnapshot,
  cast,
  castToSnapshot,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createMFCreateDealRequestErrorStore,
  CreateMFDealError,
  MFCreateDealRequestErrorStore,
} from "./MFCreateDealRequestErrorStore";
import {
  createMFSchemeSearchStore,
  MFSchemeSearchStore,
} from "./MFSchemeSearchStore";
import {
  createMFFolioNumberStore,
  MFFolioNumberStore,
} from "./MFFolioNumberStore";
import {
  createMFDealImpactStore,
  MFDealImpactStore,
} from "./MFDealImpactStore";
import {
  createMFDealRequestSummaryStore,
  MFDealRequestSummaryStore,
} from "./MFDealRequestSummaryStore";
import {
  createEntityDropdownStore,
  EntityDropdownStore,
} from "../../../../components/entity/EntityDropdownStore";
import {
  createPortfolioDropdownStore,
  PortfolioDropdownStore,
} from "../../../../components/portfolio/PortfolioDropdownStore";
import {
  BankAccountDropdownStore,
  createBankAccountDropdownStore,
} from "../../../../store/BankAccountDropdownStore";
import {
  createDepositBankDropdownStore,
  DepositBankDropdownStore,
} from "../../../../store/DepositBankDropdownStore";
import {
  BigAmount,
  BigDecimal,
  CreateMFDealRequestRPC,
  GetAvailableBankBalanceRPC,
  GetMFRedemptionUnitsRPC,
  GetMFSellEntityListRPC,
  GetMFSellPortfolioListRPC,
  ISIN,
  MFRedeemType,
  MFTransactionType,
  Note,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import {
  useCreateMFDealRequestRPC,
  useGetMFRedemptionUnitsRPC,
  useGetMFSellEntityListRPC,
  useGetMFSellPortfolioListRPC,
} from "../../rpc/RPC";
import { getAPIClient, LeoErrors } from "@khazana/khazana-boilerplate";
import { getAmount } from "../../../../../../utils";
import i18next from "i18next";
import { APIClient } from "@surya-digital/tedwig";
import { useGetAvailableBankBalanceRPCClientImpl } from "../../../../rpcs/RPC";
import { createCurrencyRPCType } from "../../../../models/CurrencyModel";
import { createEntityModel } from "../../../../models/EntityModel";
import { createPortfolioModel } from "../../../../models/PortfolioModel";

export const MFCreateDealRequestStore = types
  .model("MFCreateDealRequestStore", {
    grossAmount: types.maybe(types.string),
    grossAmountError: types.maybe(types.string),
    units: types.maybe(types.string),
    unitsError: types.maybe(types.string),
    approxNav: types.maybe(types.string),
    approxNavError: types.maybe(types.string),
    entity: EntityDropdownStore,
    portfolio: PortfolioDropdownStore,
    folioNumberStore: MFFolioNumberStore,
    bankAccount: BankAccountDropdownStore,
    bank: DepositBankDropdownStore,
    note: types.maybe(types.string),
    availableBalance: types.maybe(types.number),
    transactionType: types.optional(
      types.enumeration(Object.values(MFTransactionType.MFTransactionType)),
      MFTransactionType.MFTransactionType.MF_PURCHASE,
    ),
    redeemType: types.optional(
      types.enumeration(Object.values(MFRedeemType.MFRedeemType)),
      MFRedeemType.MFRedeemType.ALL,
    ),
    schemeSearchStore: MFSchemeSearchStore,
    dealImpactStore: MFDealImpactStore,
    dealRequestSummaryStore: MFDealRequestSummaryStore,
    errorStore: MFCreateDealRequestErrorStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setTransactionType: (type: MFTransactionType.MFTransactionType): void => {
        store.transactionType = type;
      },
      setGrossAmount: (amount: string): void => {
        store.grossAmountError = undefined;
        store.grossAmount = amount;
      },
      setUnits: (units: string): void => {
        store.unitsError = undefined;
        store.units = units;
      },
      setApproxNav: (nav: string): void => {
        store.approxNavError = undefined;
        store.approxNav = nav;
      },
      setRedeemType: (type: string): void => {
        store.units = undefined;
        store.grossAmount = undefined;
        store.redeemType = MFRedeemType.fromDTO({ case: type });
      },
      setNote: (note: string): void => {
        store.note = note;
      },
      clearFields: (): void => {
        store.grossAmount = undefined;
        store.units = undefined;
        store.approxNav = undefined;
        store.entity.deselectEntity();
        store.portfolio.deselectPortfolio();
        store.bank.setSelectedBank(undefined);
        store.bankAccount.setSelectedAccount(undefined);
        store.availableBalance = undefined;
        store.folioNumberStore.setSelectedFolio(undefined);
        store.grossAmountError = undefined;
        store.unitsError = undefined;
        store.approxNavError = undefined;
        store.errorStore.setError(undefined);
      },
      resetAvailableBalance: (): void => {
        store.availableBalance = undefined;
      },
      isValid: (): boolean => {
        let isValid = true;
        if (store.grossAmount === undefined && store.units === undefined) {
          store.grossAmountError = i18next.t("common.grossAmountIsRequired");
          store.unitsError = i18next.t("common.unitIsRequired");
          isValid = false;
        }
        if (store.grossAmount === "0") {
          store.grossAmountError = i18next.t("common.grossAmountCannotBeZero");
          isValid = false;
        }
        if (store.units === "0") {
          store.unitsError = i18next.t("common.unitsCannotBeZero");
          isValid = false;
        }
        if (!store.approxNav) {
          store.approxNavError = i18next.t("common.navIsRequired");
          isValid = false;
        }
        if (store.approxNav === "0") {
          store.approxNavError = i18next.t("common.navCannotBeZero");
          isValid = false;
        }
        if (!store.entity.selectedEntity) {
          store.entity.setError(i18next.t("common.entityIsRequired"));
          isValid = false;
        }
        if (!store.portfolio.selectedPortfolio) {
          store.portfolio.setError(i18next.t("common.portfolioIsRequired"));
          isValid = false;
        }
        if (!store.bank.selectedBankId) {
          store.bank.setError(i18next.t("common.recipientBankNameIsRequired"));
          isValid = false;
        }
        if (!store.bankAccount.selectedBankAccount) {
          store.bankAccount.setError(
            i18next.t("common.recipientAccountNumberIsRequired"),
          );
          isValid = false;
        }
        if (
          store.transactionType ===
          MFTransactionType.MFTransactionType.MF_REDEEM
        ) {
          if (!store.folioNumberStore.selectedFolio) {
            store.folioNumberStore.setError(
              i18next.t("common.folioNumberIsRequired"),
            );
            isValid = false;
          }
        }
        return isValid;
      },
      validateAvailableBalanceWithGrossAmount: (): void => {
        if (
          store.grossAmount &&
          store.availableBalance &&
          Number(store.grossAmount) > Number(store.availableBalance)
        ) {
          store.errorStore.setError(CreateMFDealError.InsufficientBankBalance);
        } else {
          store.errorStore.setError(undefined);
        }
      },
      validateAvailableBalanceWithUnits: (): void => {
        if (
          store.units &&
          store.availableBalance &&
          store.approxNav &&
          Number(store.units) * Number(store.approxNav) > store.availableBalance
        ) {
          store.errorStore.setError(CreateMFDealError.InsufficientBankBalance);
        } else {
          store.errorStore.setError(undefined);
        }
      },
    };
  })
  .actions((store) => ({
    getSellEntityList: flow(function* () {
      if (store.schemeSearchStore.selectedScheme?.isin) {
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.entity.isLoading = true;
        const request = new GetMFSellEntityListRPC.Request(
          new ISIN(store.schemeSearchStore.selectedScheme?.isin),
        );
        const result: LeoRPCResult<
          GetMFSellEntityListRPC.Response,
          GetMFSellEntityListRPC.Errors.Errors
        > = yield useGetMFSellEntityListRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.entity.entityList = cast(
            response.entities.map((entity) => createEntityModel(entity)),
          );
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetMFSellEntityListRPC`,
          );
        }
        store.entity.isLoading = false;
      }
    }),
    getSellPortfolioList: flow(function* (entityId: string) {
      if (store.schemeSearchStore.selectedScheme?.isin) {
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.portfolio.isLoading = true;
        try {
          const request = new GetMFSellPortfolioListRPC.Request(
            new ISIN(store.schemeSearchStore.selectedScheme?.isin),
            new LeoUUID(entityId),
          );
          const result: LeoRPCResult<
            GetMFSellPortfolioListRPC.Response,
            GetMFSellPortfolioListRPC.Errors.Errors
          > = yield useGetMFSellPortfolioListRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.portfolio.portfolioList = cast(
              response.portfolios.map((portfolio) =>
                createPortfolioModel(portfolio),
              ),
            );
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetMFSellPortfolioListRPC`,
            );
          }
        } catch (error) {
          if (error instanceof Error) {
            switch (error.name) {
              case LeoErrors.InvalidLeoUUIDError:
                logger.error(`Invalid entity id: ${entityId}`);
                break;
              default:
                logger.error(
                  `Unhandled error: ${error} occurred in GetMFSellPortfolioListRPC`,
                );
            }
          }
        }
        store.portfolio.isLoading = false;
      }
    }),
    createMFDealRequest: flow<number | undefined, []>(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      if (
        store.isValid() &&
        store.schemeSearchStore.selectedScheme?.isin &&
        store.approxNav &&
        store.portfolio.selectedPortfolio &&
        store.bankAccount.selectedBankAccount
      ) {
        try {
          const request = new CreateMFDealRequestRPC.Request(
            new ISIN(store.schemeSearchStore.selectedScheme.isin),
            store.transactionType,
            store.transactionType ===
            MFTransactionType.MFTransactionType.MF_REDEEM
              ? store.redeemType
              : null,
            store.grossAmount
              ? new BigAmount(
                  new BigDecimal(store.grossAmount),
                  createCurrencyRPCType(
                    store.schemeSearchStore.selectedScheme.currency,
                  ),
                )
              : null,
            store.units ? new BigDecimal(store.units) : null,
            new BigAmount(
              new BigDecimal(store.approxNav),
              createCurrencyRPCType(
                store.schemeSearchStore.selectedScheme.currency,
              ),
            ),
            new LeoUUID(store.entity.selectedEntity),
            new LeoUUID(store.portfolio.selectedPortfolio.id),
            store.folioNumberStore.selectedFolio ?? null,
            store.schemeSearchStore.selectedScheme.amcId,
            new LeoUUID(store.bank.selectedBankId),
            store.bankAccount.selectedBankAccount.bankAccountNumber,
            store.note ? new Note(store.note) : null,
          );
          const result: LeoRPCResult<
            CreateMFDealRequestRPC.Response,
            CreateMFDealRequestRPC.Errors.Errors
          > = yield useCreateMFDealRequestRPC(apiClient).execute(request);
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            return response.dealRequestId;
          } else if (result instanceof LeoRPCResult.Error) {
            const { error } = result;
            switch (error.code) {
              case CreateMFDealError.InsufficientBankBalance:
                store.errorStore.setError(
                  CreateMFDealError.InsufficientBankBalance,
                );
                break;
              case CreateMFDealError.MissingFolioNumber:
                store.folioNumberStore.error = i18next.t(
                  "common.folioNumberIsRequired",
                );
                break;
              default:
                store.errorStore.setError(CreateMFDealError.ClientError);
            }
          } else {
            logger.error("Unhandled error");
          }
        } catch (e) {
          store.errorStore.setError(CreateMFDealError.ClientError);
        }
      }
    }),
    getMFRedemptionUnits: flow(function* (
      isin: string,
      entityId: string,
      folioNumber: string,
    ) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      try {
        const request = new GetMFRedemptionUnitsRPC.Request(
          new ISIN(isin),
          new LeoUUID(entityId),
          folioNumber,
        );
        const result: LeoRPCResult<
          GetMFRedemptionUnitsRPC.Response,
          GetMFRedemptionUnitsRPC.Errors.Errors
        > = yield useGetMFRedemptionUnitsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.grossAmount = undefined;
          store.units = response.units.decimalValue;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetMFRedemptionUnitsRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      } catch (e) {
        store.errorStore.setError(CreateMFDealError.ClientError);
      }
    }),
    getAvailableBankBalance: flow(function* () {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const bankAccount =
          store.bankAccount.selectedBankAccount?.bankAccountNumber;
        if (bankAccount !== undefined) {
          const request = new GetAvailableBankBalanceRPC.Request(
            new GetAvailableBankBalanceRPC.RequestEnums.AccountNumber.BankAccount(
              bankAccount,
            ),
          );
          const result: LeoRPCResult<
            GetAvailableBankBalanceRPC.Response,
            GetAvailableBankBalanceRPC.Errors.Errors
          > =
            yield useGetAvailableBankBalanceRPCClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.availableBalance = getAmount(
              response.availableBalance?.amount,
            );
            if (store.grossAmount) {
              store.validateAvailableBalanceWithGrossAmount();
            } else {
              store.validateAvailableBalanceWithUnits();
            }
          } else {
            logger.error(
              `Unhandled Error: ${result.error} from GetAvailableBankBalanceRPC`,
            );
          }
        } else {
          logger.error("Demat account is not present");
        }
      } catch (error) {
        logger.error(
          `Unknown Error: ${error} occured in GetAvailableBankBalanceRPC`,
        );
      }
    }),
  }))
  .views((store) => {
    return {
      get isFieldsDisabled(): boolean {
        return !store.schemeSearchStore.selectedScheme;
      },
      get isAmountDisabled(): boolean {
        if (
          store.transactionType ===
          MFTransactionType.MFTransactionType.MF_PURCHASE
        ) {
          return store.units !== undefined && store.units !== "";
        } else {
          return store.redeemType !== MFRedeemType.MFRedeemType.AMOUNT;
        }
      },
      get isUnitsDisabled(): boolean {
        if (
          store.transactionType ===
          MFTransactionType.MFTransactionType.MF_PURCHASE
        ) {
          return store.grossAmount !== undefined && store.grossAmount !== "";
        } else {
          return store.redeemType !== MFRedeemType.MFRedeemType.UNITS;
        }
      },
      get isPortfolioDisabled(): boolean {
        return !store.entity.selectedEntity;
      },
      get isFolioDisabled(): boolean {
        return !store.entity.selectedEntity;
      },
      get isBankAccountDisabled(): boolean {
        return !store.bank.selectedBankId;
      },
      get currencySymbol(): string | undefined {
        return store.schemeSearchStore.selectedScheme?.currency.symbol;
      },
      get grossAmountHelperText(): string | undefined {
        if (
          store.transactionType ===
          MFTransactionType.MFTransactionType.MF_PURCHASE
        ) {
          return (
            store.grossAmountError ??
            (store.units
              ? i18next.t("mf.errorMessages.clearUnitsFieldError")
              : undefined)
          );
        } else {
          return store.grossAmountError;
        }
      },
      get unitsHelperText(): string | undefined {
        if (
          store.transactionType ===
          MFTransactionType.MFTransactionType.MF_PURCHASE
        ) {
          return (
            store.unitsError ??
            (store.grossAmount
              ? i18next.t("mf.errorMessages.clearAmountFieldError")
              : undefined)
          );
        } else {
          return store.unitsError;
        }
      },
    };
  });

export const createMFCreateDealRequestStore = (): Instance<
  typeof MFCreateDealRequestStore
> => {
  return MFCreateDealRequestStore.create({
    folioNumberStore: createMFFolioNumberStore(),
    schemeSearchStore: createMFSchemeSearchStore(),
    dealImpactStore: createMFDealImpactStore(),
    dealRequestSummaryStore: createMFDealRequestSummaryStore(),
    errorStore: createMFCreateDealRequestErrorStore(),
    entity: createEntityDropdownStore(),
    portfolio: castToSnapshot(createPortfolioDropdownStore()),
    bankAccount: createBankAccountDropdownStore(),
    bank: createDepositBankDropdownStore(),
  });
};
