import {
  AmountDate,
  Currency,
  DetailCell,
  DetailCellType,
  GetMFDealRequestDetailsRPC,
  MFDealRequestAction,
  MFDealRequestStatus,
  TransactionAmount,
} from "@khazana/khazana-rpcs";
import {
  LeoDate,
  LeoRPCResult,
  LeoTimestamp,
} from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFDealRequestDetailsRPCImpl extends GetMFDealRequestDetailsRPC {
  execute(
    _request: GetMFDealRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFDealRequestDetailsRPC.Response,
      GetMFDealRequestDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetMFDealRequestDetailsRPC.Response(
      new MFDealRequestAction(true, false, false, false, false),
      [
        new DetailCell(
          "common.requestStatus",
          new DetailCellType.StatusType(
            MFDealRequestStatus.MFDealRequestStatus.IC_PENDING,
          ),
        ),
        new DetailCell(
          "common.createdAt",
          new DetailCellType.Time(new LeoTimestamp()),
        ),
        new DetailCell(
          "common.requestId",
          new DetailCellType.Unstyled("1", false),
        ),
      ],
      [
        new AmountDate(
          new TransactionAmount(9010000, new Currency("INR", "₹")),
          new LeoDate("2023-01-01"),
        ),
        new AmountDate(
          new TransactionAmount(9020000, new Currency("INR", "₹")),
          new LeoDate("2023-01-02"),
        ),
        new AmountDate(
          new TransactionAmount(9030000, new Currency("INR", "₹")),
          new LeoDate("2023-01-03"),
        ),
      ],
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFDealRequestDetailsRPC.Response,
        GetMFDealRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
