import { types } from "mobx-state-tree";
import { FiMaturityDealRequestStatus } from "@khazana/khazana-rpcs";
import { CurrencyModel } from "../../../models/CurrencyModel";

export const FiMaturityDealRequestModel = types.model({
  requestId: types.number,
  security: types.string,
  dealRequestStatus:
    types.enumeration<FiMaturityDealRequestStatus.FiMaturityDealRequestStatus>(
      "FiMaturityDealRequestStatus",
      Object.values(FiMaturityDealRequestStatus.FiMaturityDealRequestStatus),
    ),
  isin: types.string,
  entity: types.string,
  portfolio: types.string,
  issueDate: types.Date,
  valueDate: types.Date,
  maturedDate: types.Date,
  faceValue: types.string,
  purchasePrice: types.string,
  maturedUnits: types.string,
  settlementAmount: types.string,
  currency: CurrencyModel,
});
