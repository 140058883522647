import React, { useEffect } from "react";
import * as echarts from "echarts";
import { Stack } from "@mui/material";
import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-material-ui";
import { useDataVisualizationPalette } from "../../../../theme/color-tokens/lightColorTokens";
import { useSpacing, useTypography } from "@surya-digital/leo-reactjs-core";
import { getFormattedAmountString } from "../../../../utils";

const Size = {
  legendSize: 17,
  legendRadius: 4,
};

export interface DoughnutSeriesItem {
  value: number;
  name: string;
}

type DoughnutSize = "small" | "medium" | "large";

export interface DoughnutChartProps {
  series: DoughnutSeriesItem[];
  height?: DoughnutSize;
  width?: DoughnutSize;
}

export const DoughnutChart = ({
  series,
  height = "medium",
  width = "medium",
}: DoughnutChartProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();
  const dataVisualisation = useDataVisualizationPalette();

  const getHeight = (): string => {
    if (height === "small") {
      return "300px";
    } else if (height === "medium") {
      return "420px";
    }
    return "600px";
  };

  const getWidth = (): string => {
    if (width === "small") {
      return "360px";
    } else if (width === "medium") {
      return "420px";
    }
    return "600px";
  };
  useEffect(() => {
    const chart = echarts.init(
      document.getElementById("doughnut") as HTMLDivElement,
    );
    chart.setOption({
      tooltip: {
        trigger: "item",
        valueFormatter: (value: number) =>
          getFormattedAmountString(value, 2, 2),
      },
      align: "center",
      legend: {
        bottom: spacing.space2XL,
        height: "25%",
        align: "left",
        orient: "vertical",
        itemWidth: Size.legendSize,
        itemHeight: Size.legendSize,
        textStyle: {
          fontFamily: typography.b2.fontFamily,
          fontSize: typography.b2.fontSize,
          color: tokens.labelSubtle,
          fontWeight: typography.b2.fontWeight,
        },
      },
      lineStyle: {
        width: spacing.spaceXXS,
      },
      textStyle: {
        fontSize: 14,
        color: tokens.labelSubtle,
      },
      color: [
        dataVisualisation.one,
        dataVisualisation.two,
        dataVisualisation.three,
        dataVisualisation.four,
        dataVisualisation.five,
        dataVisualisation.six,
        dataVisualisation.seven,
        dataVisualisation.eight,
        dataVisualisation.nine,
        dataVisualisation.ten,
      ],
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          height: "75%",
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          avoidLabelOverlap: false,
          data: series.map((s) => {
            return {
              value: s.value,
              name: s.name,
            };
          }),
        },
      ],
    });
  }, [series]);

  return (
    <Stack
      id="doughnut"
      sx={{
        width: getWidth(),
        height: getHeight(),
        flexGrow: 1,
        alignItems: "center",
      }}
    />
  );
};
