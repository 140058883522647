"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiOptionType = exports.InvalidFiOptionTypeError = void 0;
class InvalidFiOptionTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiOptionTypeError";
    }
}
exports.InvalidFiOptionTypeError = InvalidFiOptionTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FiOptionType;
(function (FiOptionType_1) {
    let FiOptionType;
    (function (FiOptionType) {
        FiOptionType["C"] = "C";
        FiOptionType["P"] = "P";
    })(FiOptionType = FiOptionType_1.FiOptionType || (FiOptionType_1.FiOptionType = {}));
    FiOptionType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "C":
                return FiOptionType.C;
            case "P":
                return FiOptionType.P;
            default:
                throw new InvalidFiOptionTypeError(`Case ${dto.case} is not valid case of FiOptionType`);
        }
    };
    FiOptionType_1.toDTO = (fiOptionType) => {
        const ret = {
            case: FiOptionType[fiOptionType],
        };
        return ret;
    };
    FiOptionType_1.isInstanceOf = (other) => {
        if (other in FiOptionType) {
            return true;
        }
        return false;
    };
})(FiOptionType = exports.FiOptionType || (exports.FiOptionType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
