import {
  Instance,
  types,
  getSnapshot,
  applySnapshot,
  flow,
  getEnv,
  cast,
  clone,
} from "mobx-state-tree";
import {
  BigAmount,
  BigDecimal,
  CommonItemsPerPage,
  CommonPageIndex,
  FiEditAccrualRPC,
  GetActiveAccrualsPaginationResponse,
  GetActiveAccrualsRPC,
  ModuleType,
  Note,
} from "@khazana/khazana-rpcs";
import { ActiveAccrualsModel } from "../../../models/ActiveAccrualsModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { useFiEditAccrualRPClientImpl } from "../rpcs/RPC";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  CurrencyModel,
  createCurrencyModel,
  createCurrencyRPCType,
} from "../../../models/CurrencyModel";
import { useGetActiveAccrualsRPC } from "../../../rpcs/RPC";

export const FiAccrualStore = types
  .model("FiAccrualStore", {
    error: types.optional(types.boolean, false),
    accrualPageNumber: types.optional(types.number, 0),
    accrualList: types.array(ActiveAccrualsModel),
    currency: types.maybe(CurrencyModel),
    accrualTotalItems: types.optional(types.number, 0),
    selectedAccural: types.maybe(ActiveAccrualsModel),
    accrualAmount: types.maybe(types.string),
    note: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    setAccrualPage: (pageNumber: number): void => {
      store.accrualPageNumber = pageNumber;
    },
    setSelectedAccrual: (
      selectedAccural: Instance<typeof ActiveAccrualsModel>,
    ): void => {
      store.accrualAmount = undefined;
      store.selectedAccural = clone(selectedAccural);
    },
    setAccrualAmount: (accrualAmount: string | undefined): void => {
      if (accrualAmount === "") {
        store.accrualAmount = undefined;
      } else {
        store.accrualAmount = accrualAmount;
      }
    },
    setNote: (note: string | undefined): void => {
      if (note === "") {
        store.note = undefined;
      } else {
        store.note = note;
      }
    },
    getAccruals: flow(function* (investmentId?: number) {
      const logger = getEnv(store).logger;
      if (investmentId !== undefined) {
        const request = new GetActiveAccrualsRPC.Request(
          ModuleType.ModuleType.FIXED_INCOME,
          investmentId,
          new CommonItemsPerPage(10),
          new CommonPageIndex(store.accrualPageNumber),
        );
        const apiClient = getAPIClient(store);
        const result: LeoRPCResult<
          GetActiveAccrualsRPC.Response,
          GetActiveAccrualsRPC.Errors.InvalidPageIndex
        > = yield useGetActiveAccrualsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.currency = response.getActiveAccrualsPaginationResponse[0]
            ?.currency
            ? createCurrencyModel(
                response.getActiveAccrualsPaginationResponse[0]?.currency,
              )
            : undefined;
          const accruals = response.getActiveAccrualsPaginationResponse.map(
            (accrual: GetActiveAccrualsPaginationResponse) => {
              return ActiveAccrualsModel.create({
                accrualId: accrual.accrualId,
                moduleName: accrual.moduleName,
                correlationId: accrual.correlationId,
                accrualType: accrual.accuralType,
                rate: accrual.rate.decimalValue,
                amountCCY: accrual.amountCCY.decimalValue,
                amountHCY: accrual.amountHCY.decimalValue,
                overriddenAmountCCY:
                  accrual.accrualCCYOverridden?.decimalValue ?? undefined,
                overriddenAmountHCY:
                  accrual.accrualHCYOverridden?.decimalValue ?? undefined,
                accrualStartDate: new Date(accrual.accrualStartDate.date),
                accrualEndDate: new Date(accrual.accrualEndDate.date),
                accuralPostedDate: accrual.accrualPostedDate
                  ? new Date(accrual.accrualPostedDate.date)
                  : undefined,
                isCredited: accrual.isCredited,
                isPosted: accrual.isPosted,
                currency: createCurrencyModel(accrual.currency),
              });
            },
          );
          store.accrualList = cast(accruals);
          store.accrualTotalItems = response.totalItems;
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetActiveAccrualsRPC`,
          );
        }
      }
    }),
    editAccrual: flow(function* () {
      const logger = getEnv(store).logger;
      store.error = false;
      if (
        store.selectedAccural !== undefined &&
        store.accrualAmount !== undefined
      ) {
        const request = new FiEditAccrualRPC.Request(
          store.selectedAccural?.accrualId,
          new BigAmount(
            new BigDecimal(store.accrualAmount?.toString()),
            createCurrencyRPCType(store.selectedAccural?.currency),
          ),
          store.note ? new Note(store.note) : null,
        );
        const apiClient = getAPIClient(store);
        const result: LeoRPCResult<
          FiEditAccrualRPC.Response,
          FiEditAccrualRPC.Errors.Errors
        > = yield useFiEditAccrualRPClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Error) {
          store.error = true;
          logger.error(
            `Unhandled Error: ${result.error} from GetActiveAccrualsRPC`,
          );
        }
      }
    }),
  }));

export const createFiAccrualStore = (): Instance<typeof FiAccrualStore> => {
  return FiAccrualStore.create();
};
