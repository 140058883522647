"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiMaturityTransactionDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigDecimal_1 = require("../types/bigDecimal");
const fiMaturityCharge_1 = require("./fiMaturityCharge");
const currency_1 = require("../types/currency");
/* eslint-disable import/export */
class GetFiMaturityTransactionDetailsRPC {
}
exports.GetFiMaturityTransactionDetailsRPC = GetFiMaturityTransactionDetailsRPC;
(function (GetFiMaturityTransactionDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiMaturityTransactionDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId, couponReceivedOnMaturity = null, charges = null) {
            this.isGetFiMaturityTransactionDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
            if (couponReceivedOnMaturity !== undefined && couponReceivedOnMaturity !== null) {
                Request.validateCouponReceivedOnMaturity(couponReceivedOnMaturity);
            }
            this.couponReceivedOnMaturity = couponReceivedOnMaturity;
            if (charges !== undefined && charges !== null) {
                Request.validateCharges(charges);
            }
            this.charges = charges;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            let couponReceivedOnMaturity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "couponReceivedOnMaturity");
            if (couponReceivedOnMaturity !== undefined && couponReceivedOnMaturity !== null) {
                couponReceivedOnMaturity = bigDecimal_1.BigDecimal.fromDTO(couponReceivedOnMaturity);
            }
            const charges = (0, leo_ts_runtime_1.getOptionalList)(dto, "charges", fiMaturityCharge_1.FiMaturityCharge.fromDTO, InvalidRequestError);
            return new Request(requestId, couponReceivedOnMaturity, charges);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
            };
            if (this.couponReceivedOnMaturity) {
                ret.couponReceivedOnMaturity = this.couponReceivedOnMaturity.toDTO();
            }
            if (this.charges) {
                ret.charges = this.charges.map((e) => {
                    return e.toDTO();
                });
            }
            return ret;
        }
        copy(requestId = this.requestId, couponReceivedOnMaturity = this.couponReceivedOnMaturity, charges = this.charges) {
            return new Request(requestId, couponReceivedOnMaturity, charges);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiMaturityTransactionDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.couponReceivedOnMaturity) {
                if (!this.couponReceivedOnMaturity.equals(other.couponReceivedOnMaturity)) {
                    return false;
                }
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    Request.validateCouponReceivedOnMaturity = (couponReceivedOnMaturity) => {
        const isBigDecimalValue = couponReceivedOnMaturity.isBigDecimal;
        if (!(couponReceivedOnMaturity instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidRequestError(`Attribute couponReceivedOnMaturity is not a BigDecimal`);
        }
    };
    Request.validateCharges = (charges) => {
        if (!Array.isArray(charges)) {
            throw new InvalidRequestError(`Attribute charges is not a List`);
        }
        for (let i = 0; i < charges.length; i += 1) {
            const isFiMaturityChargeValue = charges[i].isFiMaturityCharge;
            if (!(charges[i] instanceof fiMaturityCharge_1.FiMaturityCharge || Boolean(isFiMaturityChargeValue))) {
                throw new InvalidRequestError(`Attribute charges at index ${i} is not a FiMaturityCharge.`);
            }
        }
    };
    GetFiMaturityTransactionDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiMaturityTransactionDetailsRPC.Request(requestId=${this.requestId},couponReceivedOnMaturity=${this.couponReceivedOnMaturity},charges=${this.charges})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiMaturityTransactionDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(maturedUnits, maturedAmount, couponReceivedOnMaturity, currency, charges, totalSettlementAmount) {
            this.isGetFiMaturityTransactionDetailsRPCResponse = true;
            Response.validateMaturedUnits(maturedUnits);
            this.maturedUnits = maturedUnits;
            Response.validateMaturedAmount(maturedAmount);
            this.maturedAmount = maturedAmount;
            Response.validateCouponReceivedOnMaturity(couponReceivedOnMaturity);
            this.couponReceivedOnMaturity = couponReceivedOnMaturity;
            Response.validateCurrency(currency);
            this.currency = currency;
            Response.validateCharges(charges);
            this.charges = charges;
            Response.validateTotalSettlementAmount(totalSettlementAmount);
            this.totalSettlementAmount = totalSettlementAmount;
        }
        static fromDTO(dto) {
            const maturedUnits = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "maturedUnits", InvalidResponseError));
            const maturedAmount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "maturedAmount", InvalidResponseError));
            const couponReceivedOnMaturity = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "couponReceivedOnMaturity", InvalidResponseError));
            const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidResponseError));
            const charges = (0, leo_ts_runtime_1.getList)(dto, "charges", fiMaturityCharge_1.FiMaturityCharge.fromDTO, InvalidResponseError);
            const totalSettlementAmount = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "totalSettlementAmount", InvalidResponseError));
            return new Response(maturedUnits, maturedAmount, couponReceivedOnMaturity, currency, charges, totalSettlementAmount);
        }
        toDTO() {
            const ret = {
                maturedUnits: this.maturedUnits.toDTO(),
                maturedAmount: this.maturedAmount.toDTO(),
                couponReceivedOnMaturity: this.couponReceivedOnMaturity.toDTO(),
                currency: this.currency.toDTO(),
                charges: this.charges.map((e) => {
                    return e.toDTO();
                }),
                totalSettlementAmount: this.totalSettlementAmount.toDTO(),
            };
            return ret;
        }
        copy(maturedUnits = this.maturedUnits, maturedAmount = this.maturedAmount, couponReceivedOnMaturity = this.couponReceivedOnMaturity, currency = this.currency, charges = this.charges, totalSettlementAmount = this.totalSettlementAmount) {
            return new Response(maturedUnits, maturedAmount, couponReceivedOnMaturity, currency, charges, totalSettlementAmount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiMaturityTransactionDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.maturedUnits.equals(other.maturedUnits)) {
                return false;
            }
            if (!this.maturedAmount.equals(other.maturedAmount)) {
                return false;
            }
            if (!this.couponReceivedOnMaturity.equals(other.couponReceivedOnMaturity)) {
                return false;
            }
            if (!this.currency.equals(other.currency)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.charges, other.charges)) {
                return false;
            }
            if (!this.totalSettlementAmount.equals(other.totalSettlementAmount)) {
                return false;
            }
            return true;
        }
    }
    Response.validateMaturedUnits = (maturedUnits) => {
        const isBigDecimalValue = maturedUnits.isBigDecimal;
        if (!(maturedUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute maturedUnits is not a BigDecimal`);
        }
    };
    Response.validateMaturedAmount = (maturedAmount) => {
        const isBigDecimalValue = maturedAmount.isBigDecimal;
        if (!(maturedAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute maturedAmount is not a BigDecimal`);
        }
    };
    Response.validateCouponReceivedOnMaturity = (couponReceivedOnMaturity) => {
        const isBigDecimalValue = couponReceivedOnMaturity.isBigDecimal;
        if (!(couponReceivedOnMaturity instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute couponReceivedOnMaturity is not a BigDecimal`);
        }
    };
    Response.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidResponseError(`Attribute currency is not a Currency`);
        }
    };
    Response.validateCharges = (charges) => {
        if (!Array.isArray(charges)) {
            throw new InvalidResponseError(`Attribute charges is not a List`);
        }
        for (let i = 0; i < charges.length; i += 1) {
            const isFiMaturityChargeValue = charges[i].isFiMaturityCharge;
            if (!(charges[i] instanceof fiMaturityCharge_1.FiMaturityCharge || Boolean(isFiMaturityChargeValue))) {
                throw new InvalidResponseError(`Attribute charges at index ${i} is not a FiMaturityCharge.`);
            }
        }
    };
    Response.validateTotalSettlementAmount = (totalSettlementAmount) => {
        const isBigDecimalValue = totalSettlementAmount.isBigDecimal;
        if (!(totalSettlementAmount instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute totalSettlementAmount is not a BigDecimal`);
        }
    };
    GetFiMaturityTransactionDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiMaturityTransactionDetailsRPC.Response(maturedUnits=${this.maturedUnits},maturedAmount=${this.maturedAmount},couponReceivedOnMaturity=${this.couponReceivedOnMaturity},currency=${this.currency},charges=${this.charges},totalSettlementAmount=${this.totalSettlementAmount})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetFiMaturityTransactionDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiMaturityTransactionDetailsRPC.Errors || (GetFiMaturityTransactionDetailsRPC.Errors = {}));
})(GetFiMaturityTransactionDetailsRPC = exports.GetFiMaturityTransactionDetailsRPC || (exports.GetFiMaturityTransactionDetailsRPC = {}));
