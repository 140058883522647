import React, { useCallback, useEffect, useMemo } from "react";
import { debounce, Stack, Typography } from "@mui/material";
import {
  LoadingIndicator,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { useTranslation } from "react-i18next";
import { BorderStyle, useBorder } from "../../../../../utils/BorderUtils";
import { CornerRadius } from "@surya-digital/leo-reactjs-core/dist/theme/cornerRadius";
import {
  FoundationColorTokens,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { observer } from "mobx-react";
import { useCreateFDDealRequestStore } from "../store/hooks";
import {
  DEBOUNCE_DELAY,
  getFormattedAmountString,
  getLeoDate,
  getServerAmount,
} from "../../../../../utils";
import {
  TransactionAmount,
  Currency,
  FdDepositDuration,
} from "@khazana/khazana-rpcs";

const DepositDetailsStyle = (
  border: BorderStyle,
  tokens: FoundationColorTokens<string>,
  cornerRadius: CornerRadius,
  spacing: Spacing,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  return {
    container: {
      border: border.default,
      borderRadius: cornerRadius.radiusXS,
      maxWidth: "365px",
      minWidth: "365px",
      background: tokens.background,
      padding: spacing.spaceMD,
      spacing: spacing.spaceMD,
      height: "fit-content",
    },
    detailsContainer: {
      padding: spacing.spaceSM,
    },
    fieldContainer: {
      spacing: spacing.spaceXS,
    },
    field: {
      justifyContent: "space-between",
    },
    titleContainer: {
      justifyContent: "space-between",
      width: "100%",
    },
    loader: {
      width: "24px",
      height: "24px",
    },
  };
};
export const DepositDetails = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();
  const store = useCreateFDDealRequestStore();
  const fdDetailsStore = store.depositDetailsStore;

  const style = useMemo(
    () => DepositDetailsStyle(border, tokens, cornerRadius, spacing),
    [],
  );

  const debounceGetdetails = useCallback(
    debounce(function () {
      if (
        store.interestType &&
        store.interestRate &&
        store.valueDate &&
        store.depositAmount &&
        store.depositDuration &&
        store.depositDurationUnit &&
        store.bankAccountDropdownStore.selectedBankAccount
      ) {
        fdDetailsStore.getfdDetails(
          store.interestType,
          store.interestRate,
          getLeoDate(store.valueDate),
          new TransactionAmount(
            getServerAmount(store.depositAmount),
            new Currency(
              store.bankAccountDropdownStore.selectedBankAccount.currency.code,
              store.bankAccountDropdownStore.selectedBankAccount.currency.symbol,
            ),
          ),
          new FdDepositDuration(
            Number(store.depositDuration),
            store.depositDurationUnit,
          ),
        );
      }
    }, DEBOUNCE_DELAY),
    [
      store.interestType,
      store.interestRate,
      store.valueDate,
      store.depositAmount,
      store.depositDuration,
      store.depositDurationUnit,
      store.bankAccountDropdownStore.selectedBankAccount,
    ],
  );

  useEffect(() => {
    debounceGetdetails();
  }, [
    store.interestType,
    store.interestRate,
    store.valueDate,
    store.depositAmount,
    store.depositDuration,
    store.depositDurationUnit,
    store.bankAccountDropdownStore.selectedBankAccount,
  ]);

  const getField = (label: string, value?: string): React.ReactElement => {
    return (
      <Stack direction={"row"} sx={style.field}>
        <Typography sx={typography.b2}>{label}</Typography>
        <Typography sx={typography.b2}>{value ?? "-"}</Typography>
      </Stack>
    );
  };

  return (
    <Stack sx={style.container} spacing={style.container.spacing}>
      <Stack direction={"row"} sx={style.titleContainer}>
        <Typography sx={typography.sh2}>
          {t("fd.dealRequest.fdDetails.title")}
        </Typography>
        <Stack sx={style.loader}>
          {fdDetailsStore.isLoading && (
            <LoadingIndicator
              isLoading={fdDetailsStore.isLoading}
              variant={"container"}
              style={style.loader}
            />
          )}
        </Stack>
      </Stack>
      <Stack>
        <Typography sx={typography.b2}>
          {t("fd.dealRequest.fdDetails.approximateReturnsSubtitle")}
        </Typography>
        <Stack
          sx={style.detailsContainer}
          spacing={style.fieldContainer.spacing}
        >
          {getField(
            t("fd.fields.interestTypeLabel"),
            fdDetailsStore.interestType,
          )}
          {getField(
            t("fd.fields.rateOfInterestLabel"),
            fdDetailsStore.rateOfInterest,
          )}
          {getField(
            t("fd.fields.interestEarnedLabel", {
              currencySymbol: fdDetailsStore.interestEarned
                ? `(${fdDetailsStore.interestEarned.currency.symbol})`
                : undefined,
            }),
            fdDetailsStore.interestEarned
              ? getFormattedAmountString(
                  fdDetailsStore.interestEarned.amount,
                  2,
                  2,
                )
              : undefined,
          )}
          {getField(
            t("fd.fields.maturityAmountLabel", {
              currencySymbol: fdDetailsStore.maturityAmount
                ? `(${fdDetailsStore.maturityAmount.currency.symbol})`
                : undefined,
            }),
            fdDetailsStore.maturityAmount
              ? getFormattedAmountString(
                  fdDetailsStore.maturityAmount.amount,
                  2,
                  2,
                )
              : undefined,
          )}
          {getField(
            t("fd.fields.maturityDateLabel"),
            fdDetailsStore.maturityDate,
          )}
        </Stack>
      </Stack>
    </Stack>
  );
});
