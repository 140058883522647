import {
  EquityHoldingSummaryResponse,
  GetEquityHoldingSummaryRPC,
} from "@khazana/khazana-rpcs";
import { LeoDate, LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityHoldingSummaryRPC extends GetEquityHoldingSummaryRPC {
  execute(
    _request: GetEquityHoldingSummaryRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityHoldingSummaryRPC.Response,
      GetEquityHoldingSummaryRPC.Errors.Errors
    >
  > {
    const response = new GetEquityHoldingSummaryRPC.Response(
      new LeoDate(),
      [
        new EquityHoldingSummaryResponse(
          "ICICI",
          10000,
          10000,
          10000,
          10000,
          10000,
          10000,
        ),
      ],
      0,
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityHoldingSummaryRPC.Response,
        GetEquityHoldingSummaryRPC.Errors.Errors
      >
    >;
  }
}
