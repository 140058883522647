"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateEditMFDealRequestOrderRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
const mFDealRequestOrder_1 = require("./mFDealRequestOrder");
/* eslint-disable import/export */
class CreateEditMFDealRequestOrderRPC {
}
exports.CreateEditMFDealRequestOrderRPC = CreateEditMFDealRequestOrderRPC;
(function (CreateEditMFDealRequestOrderRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    CreateEditMFDealRequestOrderRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(mfDealRequestId, dealRequestOrder, note = null) {
            this.isCreateEditMFDealRequestOrderRPCRequest = true;
            Request.validateMfDealRequestId(mfDealRequestId);
            this.mfDealRequestId = mfDealRequestId;
            Request.validateDealRequestOrder(dealRequestOrder);
            this.dealRequestOrder = dealRequestOrder;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const mfDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "mfDealRequestId", InvalidRequestError);
            const dealRequestOrder = mFDealRequestOrder_1.MFDealRequestOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealRequestOrder", InvalidRequestError));
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(mfDealRequestId, dealRequestOrder, note);
        }
        toDTO() {
            const ret = {
                mfDealRequestId: this.mfDealRequestId,
                dealRequestOrder: this.dealRequestOrder.toDTO(),
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(mfDealRequestId = this.mfDealRequestId, dealRequestOrder = this.dealRequestOrder, note = this.note) {
            return new Request(mfDealRequestId, dealRequestOrder, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isCreateEditMFDealRequestOrderRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.mfDealRequestId !== other.mfDealRequestId) {
                return false;
            }
            if (!this.dealRequestOrder.equals(other.dealRequestOrder)) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateMfDealRequestId = (mfDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(mfDealRequestId)) {
            throw new InvalidRequestError(`Attribute mfDealRequestId is not an Int64`);
        }
    };
    Request.validateDealRequestOrder = (dealRequestOrder) => {
        if (!mFDealRequestOrder_1.MFDealRequestOrder.isInstanceOf(dealRequestOrder)) {
            throw new InvalidRequestError(`Attribute dealRequestOrder is not a MFDealRequestOrder.MFDealRequestOrder`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    CreateEditMFDealRequestOrderRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `CreateEditMFDealRequestOrderRPC.Request(mfDealRequestId=${this.mfDealRequestId},dealRequestOrder=${this.dealRequestOrder},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    CreateEditMFDealRequestOrderRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    CreateEditMFDealRequestOrderRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidDealRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_REQUEST_ID") {
                super(code);
                this.isInvalidDealRequestId = true;
                InvalidDealRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
                if (!(other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealRequestId = InvalidDealRequestId;
        InvalidDealRequestId.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.INVALID_DEAL_REQUEST_ID()`;
        };
        class IllegalDealRequestState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_DEAL_REQUEST_STATE") {
                super(code);
                this.isIllegalDealRequestState = true;
                IllegalDealRequestState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalDealRequestState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalDealRequestState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalDealRequestStateValue = other.isIllegalDealRequestState;
                if (!(other instanceof IllegalDealRequestState || Boolean(isIllegalDealRequestStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalDealRequestState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_DEAL_REQUEST_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalDealRequestState = IllegalDealRequestState;
        IllegalDealRequestState.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.ILLEGAL_DEAL_REQUEST_STATE()`;
        };
        class NoOrderEntryFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NO_ORDER_ENTRY_FOUND") {
                super(code);
                this.isNoOrderEntryFound = true;
                NoOrderEntryFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NoOrderEntryFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NoOrderEntryFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNoOrderEntryFoundValue = other.isNoOrderEntryFound;
                if (!(other instanceof NoOrderEntryFound || Boolean(isNoOrderEntryFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NoOrderEntryFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NO_ORDER_ENTRY_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NoOrderEntryFound = NoOrderEntryFound;
        NoOrderEntryFound.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.NO_ORDER_ENTRY_FOUND()`;
        };
        class InvalidBroker extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BROKER") {
                super(code);
                this.isInvalidBroker = true;
                InvalidBroker.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBroker(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBroker(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBrokerValue = other.isInvalidBroker;
                if (!(other instanceof InvalidBroker || Boolean(isInvalidBrokerValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBroker.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BROKER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBroker = InvalidBroker;
        InvalidBroker.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.INVALID_BROKER()`;
        };
        class UnalteredData extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "UNALTERED_DATA") {
                super(code);
                this.isUnalteredData = true;
                UnalteredData.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new UnalteredData(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new UnalteredData(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isUnalteredDataValue = other.isUnalteredData;
                if (!(other instanceof UnalteredData || Boolean(isUnalteredDataValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        UnalteredData.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "UNALTERED_DATA") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.UnalteredData = UnalteredData;
        UnalteredData.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.UNALTERED_DATA()`;
        };
        class OrderAmountExceeded extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ORDER_AMOUNT_EXCEEDED") {
                super(code);
                this.isOrderAmountExceeded = true;
                OrderAmountExceeded.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new OrderAmountExceeded(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new OrderAmountExceeded(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isOrderAmountExceededValue = other.isOrderAmountExceeded;
                if (!(other instanceof OrderAmountExceeded || Boolean(isOrderAmountExceededValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        OrderAmountExceeded.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ORDER_AMOUNT_EXCEEDED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.OrderAmountExceeded = OrderAmountExceeded;
        OrderAmountExceeded.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.ORDER_AMOUNT_EXCEEDED()`;
        };
        class OrderUnitsExceeded extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ORDER_UNITS_EXCEEDED") {
                super(code);
                this.isOrderUnitsExceeded = true;
                OrderUnitsExceeded.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new OrderUnitsExceeded(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new OrderUnitsExceeded(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isOrderUnitsExceededValue = other.isOrderUnitsExceeded;
                if (!(other instanceof OrderUnitsExceeded || Boolean(isOrderUnitsExceededValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        OrderUnitsExceeded.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ORDER_UNITS_EXCEEDED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.OrderUnitsExceeded = OrderUnitsExceeded;
        OrderUnitsExceeded.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.ORDER_UNITS_EXCEEDED()`;
        };
        class CurrencyMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CURRENCY_MISMATCH") {
                super(code);
                this.isCurrencyMismatch = true;
                CurrencyMismatch.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CurrencyMismatch(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CurrencyMismatch(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCurrencyMismatchValue = other.isCurrencyMismatch;
                if (!(other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CurrencyMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CURRENCY_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CurrencyMismatch = CurrencyMismatch;
        CurrencyMismatch.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.CURRENCY_MISMATCH()`;
        };
        class CanModifyOnlySelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_MODIFY_ONLY_SELF_REQUEST") {
                super(code);
                this.isCanModifyOnlySelfRequest = true;
                CanModifyOnlySelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanModifyOnlySelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanModifyOnlySelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
                if (!(other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanModifyOnlySelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_MODIFY_ONLY_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanModifyOnlySelfRequest = CanModifyOnlySelfRequest;
        CanModifyOnlySelfRequest.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.CAN_MODIFY_ONLY_SELF_REQUEST()`;
        };
        class CannotChangeAlreadyLinkedOrderData extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CANNOT_CHANGE_ALREADY_LINKED_ORDER_DATA") {
                super(code);
                this.isCannotChangeAlreadyLinkedOrderData = true;
                CannotChangeAlreadyLinkedOrderData.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CannotChangeAlreadyLinkedOrderData(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CannotChangeAlreadyLinkedOrderData(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCannotChangeAlreadyLinkedOrderDataValue = other.isCannotChangeAlreadyLinkedOrderData;
                if (!(other instanceof CannotChangeAlreadyLinkedOrderData || Boolean(isCannotChangeAlreadyLinkedOrderDataValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CannotChangeAlreadyLinkedOrderData.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CANNOT_CHANGE_ALREADY_LINKED_ORDER_DATA") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CannotChangeAlreadyLinkedOrderData = CannotChangeAlreadyLinkedOrderData;
        CannotChangeAlreadyLinkedOrderData.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.CANNOT_CHANGE_ALREADY_LINKED_ORDER_DATA()`;
        };
        class MultipleEntriesForSameNonLinkedOrderFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_ORDER_FOUND") {
                super(code);
                this.isMultipleEntriesForSameNonLinkedOrderFound = true;
                MultipleEntriesForSameNonLinkedOrderFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new MultipleEntriesForSameNonLinkedOrderFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new MultipleEntriesForSameNonLinkedOrderFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isMultipleEntriesForSameNonLinkedOrderFoundValue = other.isMultipleEntriesForSameNonLinkedOrderFound;
                if (!(other instanceof MultipleEntriesForSameNonLinkedOrderFound || Boolean(isMultipleEntriesForSameNonLinkedOrderFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        MultipleEntriesForSameNonLinkedOrderFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_ORDER_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.MultipleEntriesForSameNonLinkedOrderFound = MultipleEntriesForSameNonLinkedOrderFound;
        MultipleEntriesForSameNonLinkedOrderFound.prototype.toString = function toString() {
            return `CreateEditMFDealRequestOrderRPC.MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_ORDER_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_DEAL_REQUEST_ID":
                    return InvalidDealRequestId.fromDTO(dto);
                case "ILLEGAL_DEAL_REQUEST_STATE":
                    return IllegalDealRequestState.fromDTO(dto);
                case "NO_ORDER_ENTRY_FOUND":
                    return NoOrderEntryFound.fromDTO(dto);
                case "INVALID_BROKER":
                    return InvalidBroker.fromDTO(dto);
                case "UNALTERED_DATA":
                    return UnalteredData.fromDTO(dto);
                case "ORDER_AMOUNT_EXCEEDED":
                    return OrderAmountExceeded.fromDTO(dto);
                case "ORDER_UNITS_EXCEEDED":
                    return OrderUnitsExceeded.fromDTO(dto);
                case "CURRENCY_MISMATCH":
                    return CurrencyMismatch.fromDTO(dto);
                case "CAN_MODIFY_ONLY_SELF_REQUEST":
                    return CanModifyOnlySelfRequest.fromDTO(dto);
                case "CANNOT_CHANGE_ALREADY_LINKED_ORDER_DATA":
                    return CannotChangeAlreadyLinkedOrderData.fromDTO(dto);
                case "MULTIPLE_ENTRIES_FOR_SAME_NON_LINKED_ORDER_FOUND":
                    return MultipleEntriesForSameNonLinkedOrderFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
            if (other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue)) {
                return true;
            }
            const isIllegalDealRequestStateValue = other.isIllegalDealRequestState;
            if (other instanceof IllegalDealRequestState || Boolean(isIllegalDealRequestStateValue)) {
                return true;
            }
            const isNoOrderEntryFoundValue = other.isNoOrderEntryFound;
            if (other instanceof NoOrderEntryFound || Boolean(isNoOrderEntryFoundValue)) {
                return true;
            }
            const isInvalidBrokerValue = other.isInvalidBroker;
            if (other instanceof InvalidBroker || Boolean(isInvalidBrokerValue)) {
                return true;
            }
            const isUnalteredDataValue = other.isUnalteredData;
            if (other instanceof UnalteredData || Boolean(isUnalteredDataValue)) {
                return true;
            }
            const isOrderAmountExceededValue = other.isOrderAmountExceeded;
            if (other instanceof OrderAmountExceeded || Boolean(isOrderAmountExceededValue)) {
                return true;
            }
            const isOrderUnitsExceededValue = other.isOrderUnitsExceeded;
            if (other instanceof OrderUnitsExceeded || Boolean(isOrderUnitsExceededValue)) {
                return true;
            }
            const isCurrencyMismatchValue = other.isCurrencyMismatch;
            if (other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue)) {
                return true;
            }
            const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
            if (other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue)) {
                return true;
            }
            const isCannotChangeAlreadyLinkedOrderDataValue = other.isCannotChangeAlreadyLinkedOrderData;
            if (other instanceof CannotChangeAlreadyLinkedOrderData || Boolean(isCannotChangeAlreadyLinkedOrderDataValue)) {
                return true;
            }
            const isMultipleEntriesForSameNonLinkedOrderFoundValue = other.isMultipleEntriesForSameNonLinkedOrderFound;
            if (other instanceof MultipleEntriesForSameNonLinkedOrderFound || Boolean(isMultipleEntriesForSameNonLinkedOrderFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = CreateEditMFDealRequestOrderRPC.Errors || (CreateEditMFDealRequestOrderRPC.Errors = {}));
})(CreateEditMFDealRequestOrderRPC = exports.CreateEditMFDealRequestOrderRPC || (exports.CreateEditMFDealRequestOrderRPC = {}));
