"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFDealRequestSearchBy = exports.InvalidMFDealRequestSearchByError = void 0;
class InvalidMFDealRequestSearchByError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFDealRequestSearchByError";
    }
}
exports.InvalidMFDealRequestSearchByError = InvalidMFDealRequestSearchByError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFDealRequestSearchBy;
(function (MFDealRequestSearchBy_1) {
    let MFDealRequestSearchBy;
    (function (MFDealRequestSearchBy) {
        MFDealRequestSearchBy["REQUEST_ID"] = "REQUEST_ID";
        MFDealRequestSearchBy["PORTFOLIO"] = "PORTFOLIO";
        MFDealRequestSearchBy["SCHEME"] = "SCHEME";
        MFDealRequestSearchBy["AMC"] = "AMC";
    })(MFDealRequestSearchBy = MFDealRequestSearchBy_1.MFDealRequestSearchBy || (MFDealRequestSearchBy_1.MFDealRequestSearchBy = {}));
    MFDealRequestSearchBy_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "REQUEST_ID":
                return MFDealRequestSearchBy.REQUEST_ID;
            case "PORTFOLIO":
                return MFDealRequestSearchBy.PORTFOLIO;
            case "SCHEME":
                return MFDealRequestSearchBy.SCHEME;
            case "AMC":
                return MFDealRequestSearchBy.AMC;
            default:
                throw new InvalidMFDealRequestSearchByError(`Case ${dto.case} is not valid case of MFDealRequestSearchBy`);
        }
    };
    MFDealRequestSearchBy_1.toDTO = (mFDealRequestSearchBy) => {
        const ret = {
            case: MFDealRequestSearchBy[mFDealRequestSearchBy],
        };
        return ret;
    };
    MFDealRequestSearchBy_1.isInstanceOf = (other) => {
        if (other in MFDealRequestSearchBy) {
            return true;
        }
        return false;
    };
})(MFDealRequestSearchBy = exports.MFDealRequestSearchBy || (exports.MFDealRequestSearchBy = {}));
/* eslint-enable @typescript-eslint/no-shadow */
