"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFDealRequestStatus = exports.InvalidMFDealRequestStatusError = void 0;
class InvalidMFDealRequestStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFDealRequestStatusError";
    }
}
exports.InvalidMFDealRequestStatusError = InvalidMFDealRequestStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFDealRequestStatus;
(function (MFDealRequestStatus_1) {
    let MFDealRequestStatus;
    (function (MFDealRequestStatus) {
        MFDealRequestStatus["IC_PENDING"] = "IC_PENDING";
        MFDealRequestStatus["IC_APPROVED"] = "IC_APPROVED";
        MFDealRequestStatus["IC_REJECTED"] = "IC_REJECTED";
        MFDealRequestStatus["EXPIRED"] = "EXPIRED";
        MFDealRequestStatus["CANCELLED"] = "CANCELLED";
        MFDealRequestStatus["DEAL_OPEN"] = "DEAL_OPEN";
        MFDealRequestStatus["SETTLED"] = "SETTLED";
        MFDealRequestStatus["SYSTEM_SETTLED"] = "SYSTEM_SETTLED";
        MFDealRequestStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        MFDealRequestStatus["ACCOUNTS_APPROVED"] = "ACCOUNTS_APPROVED";
        MFDealRequestStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
    })(MFDealRequestStatus = MFDealRequestStatus_1.MFDealRequestStatus || (MFDealRequestStatus_1.MFDealRequestStatus = {}));
    MFDealRequestStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "IC_PENDING":
                return MFDealRequestStatus.IC_PENDING;
            case "IC_APPROVED":
                return MFDealRequestStatus.IC_APPROVED;
            case "IC_REJECTED":
                return MFDealRequestStatus.IC_REJECTED;
            case "EXPIRED":
                return MFDealRequestStatus.EXPIRED;
            case "CANCELLED":
                return MFDealRequestStatus.CANCELLED;
            case "DEAL_OPEN":
                return MFDealRequestStatus.DEAL_OPEN;
            case "SETTLED":
                return MFDealRequestStatus.SETTLED;
            case "SYSTEM_SETTLED":
                return MFDealRequestStatus.SYSTEM_SETTLED;
            case "ACCOUNTS_PENDING":
                return MFDealRequestStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_APPROVED":
                return MFDealRequestStatus.ACCOUNTS_APPROVED;
            case "ACCOUNTS_REJECTED":
                return MFDealRequestStatus.ACCOUNTS_REJECTED;
            default:
                throw new InvalidMFDealRequestStatusError(`Case ${dto.case} is not valid case of MFDealRequestStatus`);
        }
    };
    MFDealRequestStatus_1.toDTO = (mFDealRequestStatus) => {
        const ret = {
            case: MFDealRequestStatus[mFDealRequestStatus],
        };
        return ret;
    };
    MFDealRequestStatus_1.isInstanceOf = (other) => {
        if (other in MFDealRequestStatus) {
            return true;
        }
        return false;
    };
})(MFDealRequestStatus = exports.MFDealRequestStatus || (exports.MFDealRequestStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
