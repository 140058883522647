"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeeType = exports.InvalidFeeTypeError = void 0;
class InvalidFeeTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFeeTypeError";
    }
}
exports.InvalidFeeTypeError = InvalidFeeTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FeeType;
(function (FeeType_1) {
    let FeeType;
    (function (FeeType) {
        FeeType["NONE"] = "NONE";
        FeeType["FLAT_FEE"] = "FLAT_FEE";
        FeeType["PERCENTAGE"] = "PERCENTAGE";
        FeeType["PERCENTAGE_WITH_BOUNDS"] = "PERCENTAGE_WITH_BOUNDS";
    })(FeeType = FeeType_1.FeeType || (FeeType_1.FeeType = {}));
    FeeType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "NONE":
                return FeeType.NONE;
            case "FLAT_FEE":
                return FeeType.FLAT_FEE;
            case "PERCENTAGE":
                return FeeType.PERCENTAGE;
            case "PERCENTAGE_WITH_BOUNDS":
                return FeeType.PERCENTAGE_WITH_BOUNDS;
            default:
                throw new InvalidFeeTypeError(`Case ${dto.case} is not valid case of FeeType`);
        }
    };
    FeeType_1.toDTO = (feeType) => {
        const ret = {
            case: FeeType[feeType],
        };
        return ret;
    };
    FeeType_1.isInstanceOf = (other) => {
        if (other in FeeType) {
            return true;
        }
        return false;
    };
})(FeeType = exports.FeeType || (exports.FeeType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
