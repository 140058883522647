import { Instance, types } from "mobx-state-tree";
import { EquityReportDateRange } from "@khazana/khazana-rpcs";
import { DateRangeModel } from "./DateRangeModel";
import { getLeoDate } from "../../../../utils";

export enum ReportDateRange {
  YTD = "YTD",
  ONE_MONTH = "ONE_MONTH",
  THREE_MONTH = "THREE_MONTHS",
  SIX_MONTH = "SIX_MONTHS",
  ONE_YEAR = "ONE_YEAR",
  CUSTOM = "CUSTOM",
}
export const EquityReportDateRangeModel = types.union(
  types.enumeration(Object.values(ReportDateRange)),
  DateRangeModel,
);

export const createEquityReportDateRangeModel = (
  dateRange: EquityReportDateRange.EquityReportDateRange,
): Instance<typeof EquityReportDateRangeModel> => {
  if (dateRange instanceof EquityReportDateRange.Custom) {
    return DateRangeModel.create({
      startDate: dateRange?.startDate?.date
        ? new Date(dateRange?.startDate?.date)
        : null,
      endDate: dateRange?.endDate?.date
        ? new Date(dateRange?.endDate?.date)
        : null,
    });
  }
  return dateRange.toDTO().case as ReportDateRange;
};

export const createServerDateRangeModel = (
  dateRange: Instance<typeof EquityReportDateRangeModel>,
): EquityReportDateRange.EquityReportDateRange => {
  if (
    typeof dateRange === "object" &&
    dateRange.startDate &&
    dateRange.endDate
  ) {
    return new EquityReportDateRange.Custom(
      getLeoDate(new Date(dateRange.startDate)),
      getLeoDate(new Date(dateRange.endDate)),
    );
  } else if (typeof dateRange === "string") {
    return EquityReportDateRange.fromDTO({ case: dateRange });
  } else {
    throw new Error("Invalid date range");
  }
};
