"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiPriceYtm = exports.InvalidFiPriceYtmError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const yieldObject_1 = require("./yieldObject");
class InvalidFiPriceYtmError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiPriceYtmError";
    }
}
exports.InvalidFiPriceYtmError = InvalidFiPriceYtmError;
var FiPriceYtm;
(function (FiPriceYtm) {
    class Price {
        constructor(price) {
            this.isPrice = true;
            Price.validatePrice(price);
            this.price = price;
        }
        static fromDTO(dto) {
            const price = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "price", InvalidFiPriceYtmError));
            return new Price(price);
        }
        toDTO() {
            const ret = {
                case: "PRICE",
                price: this.price.toDTO(),
            };
            return ret;
        }
        copy(price = this.price) {
            return new Price(price);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPriceValue = other.isPrice;
            if (!(other instanceof Price) || Boolean(isPriceValue)) {
                return false;
            }
            if (!this.price.equals(other.price)) {
                return false;
            }
            return true;
        }
    }
    Price.validatePrice = (price) => {
        const isTransactionAmountValue = price.isTransactionAmount;
        if (!(price instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidFiPriceYtmError(`Attribute price is not a TransactionAmount`);
        }
    };
    FiPriceYtm.Price = Price;
    Price.prototype.toString = function toString() {
        return `FiPriceYtm.PRICE(price=${this.price})`;
    };
    class YieldToMaturity {
        constructor(yieldToMaturity) {
            this.isYieldToMaturity = true;
            YieldToMaturity.validateYieldToMaturity(yieldToMaturity);
            this.yieldToMaturity = yieldToMaturity;
        }
        static fromDTO(dto) {
            const yieldToMaturity = yieldObject_1.YieldObject.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yieldToMaturity", InvalidFiPriceYtmError));
            return new YieldToMaturity(yieldToMaturity);
        }
        toDTO() {
            const ret = {
                case: "YIELD_TO_MATURITY",
                yieldToMaturity: this.yieldToMaturity.toDTO(),
            };
            return ret;
        }
        copy(yieldToMaturity = this.yieldToMaturity) {
            return new YieldToMaturity(yieldToMaturity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isYieldToMaturityValue = other.isYieldToMaturity;
            if (!(other instanceof YieldToMaturity) || Boolean(isYieldToMaturityValue)) {
                return false;
            }
            if (!this.yieldToMaturity.equals(other.yieldToMaturity)) {
                return false;
            }
            return true;
        }
    }
    YieldToMaturity.validateYieldToMaturity = (yieldToMaturity) => {
        const isYieldObjectValue = yieldToMaturity.isYieldObject;
        if (!(yieldToMaturity instanceof yieldObject_1.YieldObject || Boolean(isYieldObjectValue))) {
            throw new InvalidFiPriceYtmError(`Attribute yieldToMaturity is not a YieldObject`);
        }
    };
    FiPriceYtm.YieldToMaturity = YieldToMaturity;
    YieldToMaturity.prototype.toString = function toString() {
        return `FiPriceYtm.YIELD_TO_MATURITY(yieldToMaturity=${this.yieldToMaturity})`;
    };
    FiPriceYtm.fromDTO = (dto) => {
        switch (dto.case) {
            case "PRICE":
                return Price.fromDTO(dto);
            case "YIELD_TO_MATURITY":
                return YieldToMaturity.fromDTO(dto);
            default:
                throw new InvalidFiPriceYtmError(`Case ${dto.case} is not valid case of FiPriceYtm.`);
        }
    };
    FiPriceYtm.isInstanceOf = (other) => {
        const isPRICEValue = other.isPRICE;
        if (other instanceof Price || Boolean(isPRICEValue)) {
            return true;
        }
        const isYIELD_TO_MATURITYValue = other.isYIELD_TO_MATURITY;
        if (other instanceof YieldToMaturity || Boolean(isYIELD_TO_MATURITYValue)) {
            return true;
        }
        return false;
    };
})(FiPriceYtm = exports.FiPriceYtm || (exports.FiPriceYtm = {}));
