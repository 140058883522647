import { flow, getEnv, types } from "mobx-state-tree";
import { CreateEquityDealRequestError } from "./CreateEquityDealRequestError";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  CreateSellEquityDealRequestRPC,
  EquityCollection,
  EquityDealValue,
  GetSellDematAccountNumberListRPC,
  GetSellEntityListRPC,
  GetSellEquityDealRequestImpactRPC,
  GetSellEquityListRPC,
  GetSellEquityTransactionDetailsRPC,
  GetSellPortfolioListRPC,
  ISIN,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { createEquityModel } from "../models/EquityModel";
import {
  useCreateSellEquityDealRequestRPCClientImpl,
  useGetSellDematAccountNumberListRPCClientImpl,
  useGetSellEntityListRPCClientImpl,
  useGetSellEquityDealRequestImpactRPCClientImpl,
  useGetSellEquityListRPCClientImpl,
  useGetSellEquityTransactionDetailsRPCClientImpl,
  useGetSellPortfolioListRPCClientImpl,
} from "../rpcs/RPC";
import { createEntityModel } from "../../../models/EntityModel";
import { createPortfolioModel } from "../../../models/PortfolioModel";
import { createDematAccountModel } from "../../../models/DematAccountModel";
import { createEquityTransactionDetailsModel } from "../models/EquityTransactionDetailsModel";
import { createEqImpactTableModel } from "../models/EquityDealRequestHistoryAdditionalDetailModel";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { createServerNoteRPCType } from "../../../../../utils";

export const CreateSellEquityDealRequestStore = types
  .model("CreateSellEquityDealRequestStore", {
    error: types.maybeNull(
      types.enumeration<CreateEquityDealRequestError>(
        "CreateEquityDealRequestError",
        Object.values(CreateEquityDealRequestError),
      ),
    ),
    // errorGrossQuantity to store data related to INSUFFICIENT_GROSS_QUANTITY error code in Sell flow.
    errorGrossQuantity: types.maybeNull(types.number),
    // errorNetQuantity to store data related to INSUFFICIENT_NET_QUANTITY error code in Sell flow.
    errorNetQuantity: types.maybeNull(types.number),
  })
  .actions((store) => ({
    getSellEquityList: flow(function* (searchText: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetSellEquityListRPC.Request(searchText);
      const result: LeoRPCResult<
        GetSellEquityListRPC.Response,
        GetSellEquityListRPC.Errors.Errors
      > = yield useGetSellEquityListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.equities.map((equity) => createEquityModel(equity));
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetSellEquityListRPC`,
        );
      }
    }),
    getSellEntityListRPC: flow(function* (isin: string) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetSellEntityListRPC.Request(new ISIN(isin));
      const result: LeoRPCResult<
        GetSellEntityListRPC.Response,
        GetSellEntityListRPC.Errors.Errors
      > = yield useGetSellEntityListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.entities.map((entity) => createEntityModel(entity));
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetSellEntityListRPC`,
        );
      }
    }),
    getSellPortfolioList: flow(function* (isin: string, entityId: LeoUUID) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetSellPortfolioListRPC.Request(
        new ISIN(isin),
        entityId,
      );
      const result: LeoRPCResult<
        GetSellPortfolioListRPC.Response,
        GetSellPortfolioListRPC.Errors.Errors
      > =
        yield useGetSellPortfolioListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.portfolios.map((portfolio) =>
          createPortfolioModel(portfolio),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetSellPortfolioListRPC`,
        );
      }
    }),
    getSellDematAccountNumberList: flow(function* (
      isin: string,
      entityId: LeoUUID,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetSellDematAccountNumberListRPC.Request(
        new ISIN(isin),
        entityId,
      );
      const result: LeoRPCResult<
        GetSellDematAccountNumberListRPC.Response,
        GetSellDematAccountNumberListRPC.Errors.Errors
      > =
        yield useGetSellDematAccountNumberListRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dematAccountNumbers.map((dematAccountNumber) =>
          createDematAccountModel(
            dematAccountNumber.accountNumber,
            dematAccountNumber.accountCode,
          ),
        );
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetSellDematAccountNumberListRPC`,
        );
      }
    }),
    getSellEquityTransactionDetails: flow(function* (
      dealValue: EquityDealValue.EquityDealValue,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new GetSellEquityTransactionDetailsRPC.Request(dealValue);
      const result: LeoRPCResult<
        GetSellEquityTransactionDetailsRPC.Response,
        GetSellEquityTransactionDetailsRPC.Errors.Errors
      > =
        yield useGetSellEquityTransactionDetailsRPCClientImpl(
          apiClient,
        ).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return createEquityTransactionDetailsModel(response.transactionDetails);
      } else {
        logger.error(
          `Unhandled Error: ${result.error} from GetSellEquityTransactionDetailsRPC`,
        );
      }
    }),
    createSellEquityDealRequest: flow(function* (
      isin: string,
      entityId: LeoUUID,
      equityCollection: EquityCollection.EquityCollection,
      dealValue: EquityDealValue.EquityDealValue,
      note: string | null,
    ) {
      const logger = getEnv(store).logger;
      const apiClient: APIClient = getAPIClient(store);
      const request = new CreateSellEquityDealRequestRPC.Request(
        entityId,
        equityCollection,
        new ISIN(isin),
        dealValue,
        createServerNoteRPCType(note),
      );
      const result: LeoRPCResult<
        CreateSellEquityDealRequestRPC.Response,
        CreateSellEquityDealRequestRPC.Errors.Errors
      > =
        yield useCreateSellEquityDealRequestRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        return response.dealRequestId.uuid;
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          case CreateEquityDealRequestError.InvalidISIN:
            store.error = CreateEquityDealRequestError.InvalidISIN;
            break;
          case CreateEquityDealRequestError.InvalidEntityId:
            store.error = CreateEquityDealRequestError.InvalidEntityId;
            break;
          case CreateEquityDealRequestError.InvalidPortfolioId:
            store.error = CreateEquityDealRequestError.InvalidPortfolioId;
            break;
          case CreateEquityDealRequestError.InvalidDematAccountNumber:
            store.error =
              CreateEquityDealRequestError.InvalidDematAccountNumber;
            break;
          case CreateEquityDealRequestError.CurrencyMismatch:
            store.error = CreateEquityDealRequestError.CurrencyMismatch;
            break;
          case CreateEquityDealRequestError.InsufficientGrossQuantity:
            store.errorGrossQuantity = (
              error as CreateSellEquityDealRequestRPC.Errors.InsufficientGrossQuantity
            ).grossQuantity;
            break;
          case CreateEquityDealRequestError.InsufficientNetQuantity:
            store.errorNetQuantity = (
              error as CreateSellEquityDealRequestRPC.Errors.InsufficientNetQuantity
            ).netQuantity;
            break;
        }
      } else {
        logger.error(
          `Unknown error occurred in CreateSellEquityDealRequestRPC with result: ${result}`,
        );
      }
    }),
    getSellEquityImpactOnPortfolio: flow(function* (
      isin: string,
      entityId: LeoUUID,
      portfolioId: LeoUUID,
      dealValue: EquityDealValue.EquityDealValue,
    ) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetSellEquityDealRequestImpactRPC.Request(
          new ISIN(isin),
          entityId,
          portfolioId,
          dealValue,
        );
        const result: LeoRPCResult<
          GetSellEquityDealRequestImpactRPC.Response,
          GetSellEquityDealRequestImpactRPC.Errors.Errors
        > =
          yield useGetSellEquityDealRequestImpactRPCClientImpl(
            apiClient,
          ).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          if (response.impactTable) {
            return createEqImpactTableModel(response.impactTable);
          }
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetSellEquityDealRequestImpactRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              logger.error("Could not create new Leo UUID");
              break;
            case CreateEquityDealRequestError.InvalidISINError:
              logger.error("Could not create new ISIN object");
              break;
            default:
              logger.error(
                `Unhandled Error: ${error} occurred in getSellEquityImpactOnPortfolio`,
              );
          }
        }
        logger.error(
          `Unhandled error: ${error} occurred in getSellEquityImpactOnPortfolio`,
        );
      }
    }),
  }))
  .actions((store) => ({
    clearStoreError: (): void => {
      store.error = null;
      store.errorGrossQuantity = null;
      store.errorNetQuantity = null;
    },
  }));
