import { CircularProgress, Stack, Typography } from "@mui/material";
import {
  Card,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { getFormattedAmount, getFormattedAmountString } from "../../../utils";
import { EqImpactTableModel } from "../equity/deal-request/models/EquityDealRequestHistoryAdditionalDetailModel";
import { Instance } from "mobx-state-tree";
import { useBorder } from "../../../utils/BorderUtils";
import { ImpactTableModel } from "../models/ImpactTableModel";

export interface ImpactOnPortfolioProps {
  impactTableModel:
    | Instance<typeof EqImpactTableModel>
    | Instance<typeof ImpactTableModel>
    | null;
  isCreateDealScreen?: boolean;
  isLoading?: boolean;
  showBorder?: boolean;
}

const Size = {
  amountFieldContainer: "115px",
  amountFieldHeading: {
    height: "40px",
    width: "94px",
  },
  cardWidth: "388px",
  cardHeading: "48px",
  cardContent: "124px",
  cardDivider: "40px",
};

export const ImpactOnPortfolio = ({
  impactTableModel,
  isCreateDealScreen = false,
  isLoading = false,
  showBorder = true,
}: ImpactOnPortfolioProps): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();

  const getStyledTypography = (
    value: number | string | undefined,
  ): React.ReactElement => {
    return (
      <Stack
        padding={spacing.spaceSM}
        alignItems="end"
        borderLeft={border.default}
      >
        <Typography
          sx={{
            ...typography.b2,
            color: tokens.labelHighEmphasis,
          }}
        >
          {value}
        </Typography>
      </Stack>
    );
  };

  const getAmountInfoField = (
    label: string,
    units?: number,
    percentageOfPortfolio?: number,
  ): React.ReactElement => {
    return (
      <Stack width="115px">
        <Stack
          padding={spacing.spaceSM}
          height={Size.amountFieldHeading.height}
          borderBottom={border.default}
          borderLeft={border.default}
        >
          <Typography
            sx={{
              display: "flex",
              ...typography.s3,
              color: tokens.labelLowEmphasis,
              width: Size.amountFieldHeading.width,
              justifyContent: "flex-end",
            }}
          >
            {label}
          </Typography>
        </Stack>
        {/* If there are no units or there is no portfolio percentage then we are showing 0 */}
        {getStyledTypography(
          units === 0 || units ? getFormattedAmountString(units) : "-",
        )}
        {getStyledTypography(
          percentageOfPortfolio === 0 || percentageOfPortfolio
            ? getFormattedAmount(percentageOfPortfolio)
            : "-",
        )}
      </Stack>
    );
  };

  const getStyledLabel = (label: string): React.ReactElement => {
    return (
      <Stack padding={spacing.spaceSM}>
        <Typography
          sx={{
            ...typography.s2,
            color: tokens.labelLowEmphasis,
          }}
        >
          {label}
        </Typography>
      </Stack>
    );
  };

  return (
    <Card
      style={{
        width: Size.cardWidth,
        background: tokens.background,
        border: showBorder ? border.default : undefined,
      }}
    >
      <Stack
        flexDirection="column"
        spacing={isCreateDealScreen ? undefined : spacing.spaceMD}
        borderRadius={cornerRadius.radiusXXS}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: isCreateDealScreen
              ? undefined
              : tokens.backgroundSubtle,
            borderBottom: isCreateDealScreen ? undefined : border.default,
            borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
          }}
        >
          <Typography
            sx={{
              typography: { ...typography.sh3 },
              color: tokens.label,
              height: Size.cardHeading,
              padding: `${spacing.spaceSM} ${spacing.spaceMD}`,
              borderRadius: `${cornerRadius.radiusXXS} ${cornerRadius.radiusXXS} 0 0`,
            }}
          >
            {t("common.impactOnPortfolio")}
          </Typography>
          {isLoading && (
            <CircularProgress
              size={28}
              sx={{ marginRight: spacing.spaceMD, color: tokens.iconPrimary }}
            />
          )}
        </Stack>
        <Stack flexDirection="row" paddingLeft={spacing.spaceMD}>
          <Stack width={Size.cardContent}>
            <Stack height={Size.cardDivider} borderBottom={border.default} />
            {getStyledLabel(t("common.units"))}
            {getStyledLabel(t("common.percentOfPortfolio"))}
          </Stack>
          {getAmountInfoField(
            t("common.current"),
            impactTableModel?.currentUnits,
            impactTableModel?.currentPercentage,
          )}
          {getAmountInfoField(
            t("common.afterRequest"),
            impactTableModel?.unitsAfterRequest,
            impactTableModel?.percentageAfterRequest,
          )}
        </Stack>
      </Stack>
    </Card>
  );
};
