"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitFDCertificateDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const sHA256_1 = require("../types/sHA256");
const note_1 = require("../types/note");
const fDCertificateDetails_1 = require("./fDCertificateDetails");
/* eslint-disable import/export */
class SubmitFDCertificateDetailsRPC {
}
exports.SubmitFDCertificateDetailsRPC = SubmitFDCertificateDetailsRPC;
(function (SubmitFDCertificateDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SubmitFDCertificateDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId, sha256 = null, note = null, requestDetails) {
            this.isSubmitFDCertificateDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
            if (sha256 !== undefined && sha256 !== null) {
                Request.validateSha256(sha256);
            }
            this.sha256 = sha256;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
            Request.validateRequestDetails(requestDetails);
            this.requestDetails = requestDetails;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            let sha256 = (0, leo_ts_runtime_1.getOptionalObject)(dto, "sha256");
            if (sha256 !== undefined && sha256 !== null) {
                sha256 = sHA256_1.SHA256.fromDTO(sha256);
            }
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            const requestDetails = fDCertificateDetails_1.FDCertificateDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestDetails", InvalidRequestError));
            return new Request(requestId, sha256, note, requestDetails);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
                requestDetails: this.requestDetails.toDTO(),
            };
            if (this.sha256) {
                ret.sha256 = this.sha256.toDTO();
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(requestId = this.requestId, sha256 = this.sha256, note = this.note, requestDetails = this.requestDetails) {
            return new Request(requestId, sha256, note, requestDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSubmitFDCertificateDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.sha256) {
                if (!this.sha256.equals(other.sha256)) {
                    return false;
                }
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            if (!this.requestDetails.equals(other.requestDetails)) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    Request.validateSha256 = (sha256) => {
        const isSHA256Value = sha256.isSHA256;
        if (!(sha256 instanceof sHA256_1.SHA256 || Boolean(isSHA256Value))) {
            throw new InvalidRequestError(`Attribute sha256 is not a SHA256`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    Request.validateRequestDetails = (requestDetails) => {
        const isFDCertificateDetailsValue = requestDetails.isFDCertificateDetails;
        if (!(requestDetails instanceof fDCertificateDetails_1.FDCertificateDetails || Boolean(isFDCertificateDetailsValue))) {
            throw new InvalidRequestError(`Attribute requestDetails is not a FDCertificateDetails`);
        }
    };
    SubmitFDCertificateDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SubmitFDCertificateDetailsRPC.Request(requestId=${this.requestId},sha256=${this.sha256},note=${this.note},requestDetails=${this.requestDetails})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SubmitFDCertificateDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SubmitFDCertificateDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_STATE") {
                super(code);
                this.isInvalidRequestState = true;
                InvalidRequestState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestStateValue = other.isInvalidRequestState;
                if (!(other instanceof InvalidRequestState || Boolean(isInvalidRequestStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestState = InvalidRequestState;
        InvalidRequestState.prototype.toString = function toString() {
            return `SubmitFDCertificateDetailsRPC.INVALID_REQUEST_STATE()`;
        };
        class CanModifyOnlySelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_MODIFY_ONLY_SELF_REQUEST") {
                super(code);
                this.isCanModifyOnlySelfRequest = true;
                CanModifyOnlySelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanModifyOnlySelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanModifyOnlySelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
                if (!(other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanModifyOnlySelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_MODIFY_ONLY_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanModifyOnlySelfRequest = CanModifyOnlySelfRequest;
        CanModifyOnlySelfRequest.prototype.toString = function toString() {
            return `SubmitFDCertificateDetailsRPC.CAN_MODIFY_ONLY_SELF_REQUEST()`;
        };
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `SubmitFDCertificateDetailsRPC.INVALID_REQUEST_ID()`;
        };
        class InvalidCertificateId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CERTIFICATE_ID") {
                super(code);
                this.isInvalidCertificateId = true;
                InvalidCertificateId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidCertificateId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidCertificateId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidCertificateIdValue = other.isInvalidCertificateId;
                if (!(other instanceof InvalidCertificateId || Boolean(isInvalidCertificateIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidCertificateId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CERTIFICATE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidCertificateId = InvalidCertificateId;
        InvalidCertificateId.prototype.toString = function toString() {
            return `SubmitFDCertificateDetailsRPC.INVALID_CERTIFICATE_ID()`;
        };
        class InvalidInterestTypeFrequencyCombination extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION") {
                super(code);
                this.isInvalidInterestTypeFrequencyCombination = true;
                InvalidInterestTypeFrequencyCombination.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidInterestTypeFrequencyCombination(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidInterestTypeFrequencyCombination(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidInterestTypeFrequencyCombinationValue = other.isInvalidInterestTypeFrequencyCombination;
                if (!(other instanceof InvalidInterestTypeFrequencyCombination || Boolean(isInvalidInterestTypeFrequencyCombinationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidInterestTypeFrequencyCombination.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidInterestTypeFrequencyCombination = InvalidInterestTypeFrequencyCombination;
        InvalidInterestTypeFrequencyCombination.prototype.toString = function toString() {
            return `SubmitFDCertificateDetailsRPC.INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION()`;
        };
        class InvalidBranchId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_BRANCH_ID") {
                super(code);
                this.isInvalidBranchId = true;
                InvalidBranchId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidBranchId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidBranchId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidBranchIdValue = other.isInvalidBranchId;
                if (!(other instanceof InvalidBranchId || Boolean(isInvalidBranchIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidBranchId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_BRANCH_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidBranchId = InvalidBranchId;
        InvalidBranchId.prototype.toString = function toString() {
            return `SubmitFDCertificateDetailsRPC.INVALID_BRANCH_ID()`;
        };
        class InvalidDepositDuration extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEPOSIT_DURATION") {
                super(code);
                this.isInvalidDepositDuration = true;
                InvalidDepositDuration.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDepositDuration(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDepositDuration(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDepositDurationValue = other.isInvalidDepositDuration;
                if (!(other instanceof InvalidDepositDuration || Boolean(isInvalidDepositDurationValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDepositDuration.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEPOSIT_DURATION") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDepositDuration = InvalidDepositDuration;
        InvalidDepositDuration.prototype.toString = function toString() {
            return `SubmitFDCertificateDetailsRPC.INVALID_DEPOSIT_DURATION()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_STATE":
                    return InvalidRequestState.fromDTO(dto);
                case "CAN_MODIFY_ONLY_SELF_REQUEST":
                    return CanModifyOnlySelfRequest.fromDTO(dto);
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "INVALID_CERTIFICATE_ID":
                    return InvalidCertificateId.fromDTO(dto);
                case "INVALID_INTEREST_TYPE_FREQUENCY_COMBINATION":
                    return InvalidInterestTypeFrequencyCombination.fromDTO(dto);
                case "INVALID_BRANCH_ID":
                    return InvalidBranchId.fromDTO(dto);
                case "INVALID_DEPOSIT_DURATION":
                    return InvalidDepositDuration.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestStateValue = other.isInvalidRequestState;
            if (other instanceof InvalidRequestState || Boolean(isInvalidRequestStateValue)) {
                return true;
            }
            const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
            if (other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue)) {
                return true;
            }
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isInvalidCertificateIdValue = other.isInvalidCertificateId;
            if (other instanceof InvalidCertificateId || Boolean(isInvalidCertificateIdValue)) {
                return true;
            }
            const isInvalidInterestTypeFrequencyCombinationValue = other.isInvalidInterestTypeFrequencyCombination;
            if (other instanceof InvalidInterestTypeFrequencyCombination || Boolean(isInvalidInterestTypeFrequencyCombinationValue)) {
                return true;
            }
            const isInvalidBranchIdValue = other.isInvalidBranchId;
            if (other instanceof InvalidBranchId || Boolean(isInvalidBranchIdValue)) {
                return true;
            }
            const isInvalidDepositDurationValue = other.isInvalidDepositDuration;
            if (other instanceof InvalidDepositDuration || Boolean(isInvalidDepositDurationValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SubmitFDCertificateDetailsRPC.Errors || (SubmitFDCertificateDetailsRPC.Errors = {}));
})(SubmitFDCertificateDetailsRPC = exports.SubmitFDCertificateDetailsRPC || (exports.SubmitFDCertificateDetailsRPC = {}));
