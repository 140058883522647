"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiInvestmentDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const bigDecimal_1 = require("../types/bigDecimal");
const bigAmount_1 = require("../types/bigAmount");
const cashflowTimelineItem_1 = require("../types/cashflowTimelineItem");
const fiYieldType_1 = require("./fiYieldType");
const payoutFrequency_1 = require("../types/payoutFrequency");
/* eslint-disable import/export */
class GetFiInvestmentDetailsRPC {
}
exports.GetFiInvestmentDetailsRPC = GetFiInvestmentDetailsRPC;
(function (GetFiInvestmentDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiInvestmentDetailsRPC.InvalidRequestError = InvalidRequestError;
    let RequestEnums;
    (function (RequestEnums) {
        let RequestType;
        (function (RequestType) {
            class DealDetails {
                constructor(fiDealRequestId) {
                    this.isDealDetails = true;
                    DealDetails.validateFiDealRequestId(fiDealRequestId);
                    this.fiDealRequestId = fiDealRequestId;
                }
                static fromDTO(dto) {
                    const fiDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "fiDealRequestId", InvalidRequestError);
                    return new DealDetails(fiDealRequestId);
                }
                toDTO() {
                    const ret = {
                        case: "DEAL_DETAILS",
                        fiDealRequestId: this.fiDealRequestId,
                    };
                    return ret;
                }
                copy(fiDealRequestId = this.fiDealRequestId) {
                    return new DealDetails(fiDealRequestId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isDealDetailsValue = other.isDealDetails;
                    if (!(other instanceof DealDetails) || Boolean(isDealDetailsValue)) {
                        return false;
                    }
                    if (this.fiDealRequestId !== other.fiDealRequestId) {
                        return false;
                    }
                    return true;
                }
            }
            DealDetails.validateFiDealRequestId = (fiDealRequestId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fiDealRequestId)) {
                    throw new InvalidRequestError(`Attribute fiDealRequestId is not an Int64`);
                }
            };
            RequestType.DealDetails = DealDetails;
            DealDetails.prototype.toString = function toString() {
                return `RequestType.DEAL_DETAILS(fiDealRequestId=${this.fiDealRequestId})`;
            };
            class ContractNoteDetails {
                constructor(fiContractNoteId) {
                    this.isContractNoteDetails = true;
                    ContractNoteDetails.validateFiContractNoteId(fiContractNoteId);
                    this.fiContractNoteId = fiContractNoteId;
                }
                static fromDTO(dto) {
                    const fiContractNoteId = (0, leo_ts_runtime_1.getInt64)(dto, "fiContractNoteId", InvalidRequestError);
                    return new ContractNoteDetails(fiContractNoteId);
                }
                toDTO() {
                    const ret = {
                        case: "CONTRACT_NOTE_DETAILS",
                        fiContractNoteId: this.fiContractNoteId,
                    };
                    return ret;
                }
                copy(fiContractNoteId = this.fiContractNoteId) {
                    return new ContractNoteDetails(fiContractNoteId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isContractNoteDetailsValue = other.isContractNoteDetails;
                    if (!(other instanceof ContractNoteDetails) || Boolean(isContractNoteDetailsValue)) {
                        return false;
                    }
                    if (this.fiContractNoteId !== other.fiContractNoteId) {
                        return false;
                    }
                    return true;
                }
            }
            ContractNoteDetails.validateFiContractNoteId = (fiContractNoteId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fiContractNoteId)) {
                    throw new InvalidRequestError(`Attribute fiContractNoteId is not an Int64`);
                }
            };
            RequestType.ContractNoteDetails = ContractNoteDetails;
            ContractNoteDetails.prototype.toString = function toString() {
                return `RequestType.CONTRACT_NOTE_DETAILS(fiContractNoteId=${this.fiContractNoteId})`;
            };
            class InvestmentDetails {
                constructor(fiPurchaseId) {
                    this.isInvestmentDetails = true;
                    InvestmentDetails.validateFiPurchaseId(fiPurchaseId);
                    this.fiPurchaseId = fiPurchaseId;
                }
                static fromDTO(dto) {
                    const fiPurchaseId = (0, leo_ts_runtime_1.getInt64)(dto, "fiPurchaseId", InvalidRequestError);
                    return new InvestmentDetails(fiPurchaseId);
                }
                toDTO() {
                    const ret = {
                        case: "INVESTMENT_DETAILS",
                        fiPurchaseId: this.fiPurchaseId,
                    };
                    return ret;
                }
                copy(fiPurchaseId = this.fiPurchaseId) {
                    return new InvestmentDetails(fiPurchaseId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isInvestmentDetailsValue = other.isInvestmentDetails;
                    if (!(other instanceof InvestmentDetails) || Boolean(isInvestmentDetailsValue)) {
                        return false;
                    }
                    if (this.fiPurchaseId !== other.fiPurchaseId) {
                        return false;
                    }
                    return true;
                }
            }
            InvestmentDetails.validateFiPurchaseId = (fiPurchaseId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fiPurchaseId)) {
                    throw new InvalidRequestError(`Attribute fiPurchaseId is not an Int64`);
                }
            };
            RequestType.InvestmentDetails = InvestmentDetails;
            InvestmentDetails.prototype.toString = function toString() {
                return `RequestType.INVESTMENT_DETAILS(fiPurchaseId=${this.fiPurchaseId})`;
            };
            class MaturityDetails {
                constructor(fiMaturityDealRequestId) {
                    this.isMaturityDetails = true;
                    MaturityDetails.validateFiMaturityDealRequestId(fiMaturityDealRequestId);
                    this.fiMaturityDealRequestId = fiMaturityDealRequestId;
                }
                static fromDTO(dto) {
                    const fiMaturityDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "fiMaturityDealRequestId", InvalidRequestError);
                    return new MaturityDetails(fiMaturityDealRequestId);
                }
                toDTO() {
                    const ret = {
                        case: "MATURITY_DETAILS",
                        fiMaturityDealRequestId: this.fiMaturityDealRequestId,
                    };
                    return ret;
                }
                copy(fiMaturityDealRequestId = this.fiMaturityDealRequestId) {
                    return new MaturityDetails(fiMaturityDealRequestId);
                }
                equals(other) {
                    if (Object.is(this, other)) {
                        return true;
                    }
                    const isMaturityDetailsValue = other.isMaturityDetails;
                    if (!(other instanceof MaturityDetails) || Boolean(isMaturityDetailsValue)) {
                        return false;
                    }
                    if (this.fiMaturityDealRequestId !== other.fiMaturityDealRequestId) {
                        return false;
                    }
                    return true;
                }
            }
            MaturityDetails.validateFiMaturityDealRequestId = (fiMaturityDealRequestId) => {
                if (!(0, leo_ts_runtime_1.isInt64)(fiMaturityDealRequestId)) {
                    throw new InvalidRequestError(`Attribute fiMaturityDealRequestId is not an Int64`);
                }
            };
            RequestType.MaturityDetails = MaturityDetails;
            MaturityDetails.prototype.toString = function toString() {
                return `RequestType.MATURITY_DETAILS(fiMaturityDealRequestId=${this.fiMaturityDealRequestId})`;
            };
            RequestType.fromDTO = (dto) => {
                switch (dto.case) {
                    case "DEAL_DETAILS":
                        return DealDetails.fromDTO(dto);
                    case "CONTRACT_NOTE_DETAILS":
                        return ContractNoteDetails.fromDTO(dto);
                    case "INVESTMENT_DETAILS":
                        return InvestmentDetails.fromDTO(dto);
                    case "MATURITY_DETAILS":
                        return MaturityDetails.fromDTO(dto);
                    default:
                        throw new InvalidRequestError(`Case ${dto.case} is not valid case of RequestType.`);
                }
            };
            RequestType.isInstanceOf = (other) => {
                const isDEAL_DETAILSValue = other.isDEAL_DETAILS;
                if (other instanceof DealDetails || Boolean(isDEAL_DETAILSValue)) {
                    return true;
                }
                const isCONTRACT_NOTE_DETAILSValue = other.isCONTRACT_NOTE_DETAILS;
                if (other instanceof ContractNoteDetails || Boolean(isCONTRACT_NOTE_DETAILSValue)) {
                    return true;
                }
                const isINVESTMENT_DETAILSValue = other.isINVESTMENT_DETAILS;
                if (other instanceof InvestmentDetails || Boolean(isINVESTMENT_DETAILSValue)) {
                    return true;
                }
                const isMATURITY_DETAILSValue = other.isMATURITY_DETAILS;
                if (other instanceof MaturityDetails || Boolean(isMATURITY_DETAILSValue)) {
                    return true;
                }
                return false;
            };
        })(RequestType = RequestEnums.RequestType || (RequestEnums.RequestType = {}));
    })(RequestEnums = GetFiInvestmentDetailsRPC.RequestEnums || (GetFiInvestmentDetailsRPC.RequestEnums = {}));
    class Request {
        constructor(requestType) {
            this.isGetFiInvestmentDetailsRPCRequest = true;
            Request.validateRequestType(requestType);
            this.requestType = requestType;
        }
        static fromDTO(dto) {
            const requestType = RequestEnums.RequestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestType", InvalidRequestError));
            return new Request(requestType);
        }
        toDTO() {
            const ret = {
                requestType: this.requestType.toDTO(),
            };
            return ret;
        }
        copy(requestType = this.requestType) {
            return new Request(requestType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiInvestmentDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.requestType.equals(other.requestType)) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestType = (requestType) => {
        if (!RequestEnums.RequestType.isInstanceOf(requestType)) {
            throw new InvalidRequestError(`Attribute requestType is not a RequestEnums.RequestType`);
        }
    };
    GetFiInvestmentDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiInvestmentDetailsRPC.Request(requestType=${this.requestType})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiInvestmentDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(isin, legalName, issueDate, totalUnits, purchasePrice, yieldDate, yieldType, purchaseDate, bankAccountNumber, bankName, dematAccountNumber, ytm, couponRate, accruedInterestTillDate, payoutFrequency, cashFlowList, isViewCashFlowButtonEnabled, investmentId = null) {
            this.isGetFiInvestmentDetailsRPCResponse = true;
            Response.validateIsin(isin);
            this.isin = isin;
            Response.validateLegalName(legalName);
            this.legalName = legalName;
            Response.validateIssueDate(issueDate);
            this.issueDate = issueDate;
            Response.validateTotalUnits(totalUnits);
            this.totalUnits = totalUnits;
            Response.validatePurchasePrice(purchasePrice);
            this.purchasePrice = purchasePrice;
            Response.validateYieldDate(yieldDate);
            this.yieldDate = yieldDate;
            Response.validateYieldType(yieldType);
            this.yieldType = yieldType;
            Response.validatePurchaseDate(purchaseDate);
            this.purchaseDate = purchaseDate;
            Response.validateBankAccountNumber(bankAccountNumber);
            this.bankAccountNumber = bankAccountNumber;
            Response.validateBankName(bankName);
            this.bankName = bankName;
            Response.validateDematAccountNumber(dematAccountNumber);
            this.dematAccountNumber = dematAccountNumber;
            Response.validateYtm(ytm);
            this.ytm = ytm;
            Response.validateCouponRate(couponRate);
            this.couponRate = couponRate;
            Response.validateAccruedInterestTillDate(accruedInterestTillDate);
            this.accruedInterestTillDate = accruedInterestTillDate;
            Response.validatePayoutFrequency(payoutFrequency);
            this.payoutFrequency = payoutFrequency;
            Response.validateCashFlowList(cashFlowList);
            this.cashFlowList = cashFlowList;
            Response.validateIsViewCashFlowButtonEnabled(isViewCashFlowButtonEnabled);
            this.isViewCashFlowButtonEnabled = isViewCashFlowButtonEnabled;
            if (investmentId !== undefined && investmentId !== null) {
                Response.validateInvestmentId(investmentId);
            }
            this.investmentId = investmentId;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidResponseError));
            const legalName = (0, leo_ts_runtime_1.getString)(dto, "legalName", InvalidResponseError);
            const issueDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "issueDate", InvalidResponseError);
            const totalUnits = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "totalUnits", InvalidResponseError));
            const purchasePrice = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "purchasePrice", InvalidResponseError));
            const yieldDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "yieldDate", InvalidResponseError);
            const yieldType = fiYieldType_1.FiYieldType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "yieldType", InvalidResponseError));
            const purchaseDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "purchaseDate", InvalidResponseError);
            const bankAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "bankAccountNumber", InvalidResponseError);
            const bankName = (0, leo_ts_runtime_1.getString)(dto, "bankName", InvalidResponseError);
            const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidResponseError);
            const ytm = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "ytm", InvalidResponseError));
            const couponRate = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "couponRate", InvalidResponseError));
            const accruedInterestTillDate = bigAmount_1.BigAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "accruedInterestTillDate", InvalidResponseError));
            const payoutFrequency = payoutFrequency_1.PayoutFrequency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "payoutFrequency", InvalidResponseError));
            const cashFlowList = (0, leo_ts_runtime_1.getList)(dto, "cashFlowList", cashflowTimelineItem_1.CashflowTimelineItem.fromDTO, InvalidResponseError);
            const isViewCashFlowButtonEnabled = (0, leo_ts_runtime_1.getBoolean)(dto, "isViewCashFlowButtonEnabled", InvalidResponseError);
            const investmentId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "investmentId", InvalidResponseError);
            return new Response(isin, legalName, issueDate, totalUnits, purchasePrice, yieldDate, yieldType, purchaseDate, bankAccountNumber, bankName, dematAccountNumber, ytm, couponRate, accruedInterestTillDate, payoutFrequency, cashFlowList, isViewCashFlowButtonEnabled, investmentId);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
                legalName: this.legalName,
                issueDate: this.issueDate.toDTO(),
                totalUnits: this.totalUnits.toDTO(),
                purchasePrice: this.purchasePrice.toDTO(),
                yieldDate: this.yieldDate.toDTO(),
                yieldType: fiYieldType_1.FiYieldType.toDTO(this.yieldType),
                purchaseDate: this.purchaseDate.toDTO(),
                bankAccountNumber: this.bankAccountNumber,
                bankName: this.bankName,
                dematAccountNumber: this.dematAccountNumber,
                ytm: this.ytm.toDTO(),
                couponRate: this.couponRate.toDTO(),
                accruedInterestTillDate: this.accruedInterestTillDate.toDTO(),
                payoutFrequency: payoutFrequency_1.PayoutFrequency.toDTO(this.payoutFrequency),
                cashFlowList: this.cashFlowList.map((e) => {
                    return e.toDTO();
                }),
                isViewCashFlowButtonEnabled: this.isViewCashFlowButtonEnabled,
            };
            if (this.investmentId !== undefined && this.investmentId !== null) {
                ret.investmentId = this.investmentId;
            }
            return ret;
        }
        copy(isin = this.isin, legalName = this.legalName, issueDate = this.issueDate, totalUnits = this.totalUnits, purchasePrice = this.purchasePrice, yieldDate = this.yieldDate, yieldType = this.yieldType, purchaseDate = this.purchaseDate, bankAccountNumber = this.bankAccountNumber, bankName = this.bankName, dematAccountNumber = this.dematAccountNumber, ytm = this.ytm, couponRate = this.couponRate, accruedInterestTillDate = this.accruedInterestTillDate, payoutFrequency = this.payoutFrequency, cashFlowList = this.cashFlowList, isViewCashFlowButtonEnabled = this.isViewCashFlowButtonEnabled, investmentId = this.investmentId) {
            return new Response(isin, legalName, issueDate, totalUnits, purchasePrice, yieldDate, yieldType, purchaseDate, bankAccountNumber, bankName, dematAccountNumber, ytm, couponRate, accruedInterestTillDate, payoutFrequency, cashFlowList, isViewCashFlowButtonEnabled, investmentId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiInvestmentDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (this.legalName !== other.legalName) {
                return false;
            }
            if (!this.issueDate.equals(other.issueDate)) {
                return false;
            }
            if (!this.totalUnits.equals(other.totalUnits)) {
                return false;
            }
            if (!this.purchasePrice.equals(other.purchasePrice)) {
                return false;
            }
            if (!this.yieldDate.equals(other.yieldDate)) {
                return false;
            }
            if (this.yieldType !== other.yieldType) {
                return false;
            }
            if (!this.purchaseDate.equals(other.purchaseDate)) {
                return false;
            }
            if (this.bankAccountNumber !== other.bankAccountNumber) {
                return false;
            }
            if (this.bankName !== other.bankName) {
                return false;
            }
            if (this.dematAccountNumber !== other.dematAccountNumber) {
                return false;
            }
            if (!this.ytm.equals(other.ytm)) {
                return false;
            }
            if (!this.couponRate.equals(other.couponRate)) {
                return false;
            }
            if (!this.accruedInterestTillDate.equals(other.accruedInterestTillDate)) {
                return false;
            }
            if (this.payoutFrequency !== other.payoutFrequency) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.cashFlowList, other.cashFlowList)) {
                return false;
            }
            if (this.isViewCashFlowButtonEnabled !== other.isViewCashFlowButtonEnabled) {
                return false;
            }
            if (this.investmentId !== other.investmentId) {
                return false;
            }
            return true;
        }
    }
    Response.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidResponseError(`Attribute isin is not a ISIN`);
        }
    };
    Response.validateLegalName = (legalName) => {
        if (!(0, leo_ts_runtime_1.isString)(legalName)) {
            throw new InvalidResponseError(`Attribute legalName is not a String`);
        }
        if (legalName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute legalName cannot be an empty string.`);
        }
    };
    Response.validateIssueDate = (issueDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(issueDate)) {
            throw new InvalidResponseError(`Attribute issueDate is not a Date`);
        }
    };
    Response.validateTotalUnits = (totalUnits) => {
        const isBigDecimalValue = totalUnits.isBigDecimal;
        if (!(totalUnits instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute totalUnits is not a BigDecimal`);
        }
    };
    Response.validatePurchasePrice = (purchasePrice) => {
        const isBigAmountValue = purchasePrice.isBigAmount;
        if (!(purchasePrice instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidResponseError(`Attribute purchasePrice is not a BigAmount`);
        }
    };
    Response.validateYieldDate = (yieldDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(yieldDate)) {
            throw new InvalidResponseError(`Attribute yieldDate is not a Date`);
        }
    };
    Response.validateYieldType = (yieldType) => {
        if (!fiYieldType_1.FiYieldType.isInstanceOf(yieldType)) {
            throw new InvalidResponseError(`Attribute yieldType is not a FiYieldType.FiYieldType`);
        }
    };
    Response.validatePurchaseDate = (purchaseDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(purchaseDate)) {
            throw new InvalidResponseError(`Attribute purchaseDate is not a Date`);
        }
    };
    Response.validateBankAccountNumber = (bankAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(bankAccountNumber)) {
            throw new InvalidResponseError(`Attribute bankAccountNumber is not a String`);
        }
        if (bankAccountNumber.trim().length === 0) {
            throw new InvalidResponseError(`Attribute bankAccountNumber cannot be an empty string.`);
        }
    };
    Response.validateBankName = (bankName) => {
        if (!(0, leo_ts_runtime_1.isString)(bankName)) {
            throw new InvalidResponseError(`Attribute bankName is not a String`);
        }
        if (bankName.trim().length === 0) {
            throw new InvalidResponseError(`Attribute bankName cannot be an empty string.`);
        }
    };
    Response.validateDematAccountNumber = (dematAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
            throw new InvalidResponseError(`Attribute dematAccountNumber is not a String`);
        }
        if (dematAccountNumber.trim().length === 0) {
            throw new InvalidResponseError(`Attribute dematAccountNumber cannot be an empty string.`);
        }
    };
    Response.validateYtm = (ytm) => {
        const isBigDecimalValue = ytm.isBigDecimal;
        if (!(ytm instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute ytm is not a BigDecimal`);
        }
    };
    Response.validateCouponRate = (couponRate) => {
        const isBigDecimalValue = couponRate.isBigDecimal;
        if (!(couponRate instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute couponRate is not a BigDecimal`);
        }
    };
    Response.validateAccruedInterestTillDate = (accruedInterestTillDate) => {
        const isBigAmountValue = accruedInterestTillDate.isBigAmount;
        if (!(accruedInterestTillDate instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidResponseError(`Attribute accruedInterestTillDate is not a BigAmount`);
        }
    };
    Response.validatePayoutFrequency = (payoutFrequency) => {
        if (!payoutFrequency_1.PayoutFrequency.isInstanceOf(payoutFrequency)) {
            throw new InvalidResponseError(`Attribute payoutFrequency is not a PayoutFrequency.PayoutFrequency`);
        }
    };
    Response.validateCashFlowList = (cashFlowList) => {
        if (!Array.isArray(cashFlowList)) {
            throw new InvalidResponseError(`Attribute cashFlowList is not a List`);
        }
        for (let i = 0; i < cashFlowList.length; i += 1) {
            const isCashflowTimelineItemValue = cashFlowList[i].isCashflowTimelineItem;
            if (!(cashFlowList[i] instanceof cashflowTimelineItem_1.CashflowTimelineItem || Boolean(isCashflowTimelineItemValue))) {
                throw new InvalidResponseError(`Attribute cashFlowList at index ${i} is not a CashflowTimelineItem.`);
            }
        }
    };
    Response.validateIsViewCashFlowButtonEnabled = (isViewCashFlowButtonEnabled) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(isViewCashFlowButtonEnabled)) {
            throw new InvalidResponseError(`Attribute isViewCashFlowButtonEnabled is not a Boolean`);
        }
    };
    Response.validateInvestmentId = (investmentId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(investmentId)) {
            throw new InvalidResponseError(`Attribute investmentId is not an Int64`);
        }
    };
    GetFiInvestmentDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiInvestmentDetailsRPC.Response(isin=${this.isin},legalName='${this.legalName}',issueDate=${this.issueDate},totalUnits=${this.totalUnits},purchasePrice=${this.purchasePrice},yieldDate=${this.yieldDate},yieldType=${this.yieldType},purchaseDate=${this.purchaseDate},bankAccountNumber='${this.bankAccountNumber}',bankName='${this.bankName}',dematAccountNumber='${this.dematAccountNumber}',ytm=${this.ytm},couponRate=${this.couponRate},accruedInterestTillDate=${this.accruedInterestTillDate},payoutFrequency=${this.payoutFrequency},cashFlowList=${this.cashFlowList},isViewCashFlowButtonEnabled=${this.isViewCashFlowButtonEnabled},investmentId=${this.investmentId})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidDealRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_REQUEST_ID") {
                super(code);
                this.isInvalidDealRequestId = true;
                InvalidDealRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
                if (!(other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealRequestId = InvalidDealRequestId;
        InvalidDealRequestId.prototype.toString = function toString() {
            return `GetFiInvestmentDetailsRPC.INVALID_DEAL_REQUEST_ID()`;
        };
        class InvalidContractNoteId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONTRACT_NOTE_ID") {
                super(code);
                this.isInvalidContractNoteId = true;
                InvalidContractNoteId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidContractNoteId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidContractNoteId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
                if (!(other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidContractNoteId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONTRACT_NOTE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidContractNoteId = InvalidContractNoteId;
        InvalidContractNoteId.prototype.toString = function toString() {
            return `GetFiInvestmentDetailsRPC.INVALID_CONTRACT_NOTE_ID()`;
        };
        class InvalidPurchaseId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PURCHASE_ID") {
                super(code);
                this.isInvalidPurchaseId = true;
                InvalidPurchaseId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPurchaseId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPurchaseId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPurchaseIdValue = other.isInvalidPurchaseId;
                if (!(other instanceof InvalidPurchaseId || Boolean(isInvalidPurchaseIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPurchaseId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PURCHASE_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPurchaseId = InvalidPurchaseId;
        InvalidPurchaseId.prototype.toString = function toString() {
            return `GetFiInvestmentDetailsRPC.INVALID_PURCHASE_ID()`;
        };
        class InvalidMaturityDealRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_MATURITY_DEAL_REQUEST_ID") {
                super(code);
                this.isInvalidMaturityDealRequestId = true;
                InvalidMaturityDealRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidMaturityDealRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidMaturityDealRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidMaturityDealRequestIdValue = other.isInvalidMaturityDealRequestId;
                if (!(other instanceof InvalidMaturityDealRequestId || Boolean(isInvalidMaturityDealRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidMaturityDealRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_MATURITY_DEAL_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidMaturityDealRequestId = InvalidMaturityDealRequestId;
        InvalidMaturityDealRequestId.prototype.toString = function toString() {
            return `GetFiInvestmentDetailsRPC.INVALID_MATURITY_DEAL_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_DEAL_REQUEST_ID":
                    return InvalidDealRequestId.fromDTO(dto);
                case "INVALID_CONTRACT_NOTE_ID":
                    return InvalidContractNoteId.fromDTO(dto);
                case "INVALID_PURCHASE_ID":
                    return InvalidPurchaseId.fromDTO(dto);
                case "INVALID_MATURITY_DEAL_REQUEST_ID":
                    return InvalidMaturityDealRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
            if (other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue)) {
                return true;
            }
            const isInvalidContractNoteIdValue = other.isInvalidContractNoteId;
            if (other instanceof InvalidContractNoteId || Boolean(isInvalidContractNoteIdValue)) {
                return true;
            }
            const isInvalidPurchaseIdValue = other.isInvalidPurchaseId;
            if (other instanceof InvalidPurchaseId || Boolean(isInvalidPurchaseIdValue)) {
                return true;
            }
            const isInvalidMaturityDealRequestIdValue = other.isInvalidMaturityDealRequestId;
            if (other instanceof InvalidMaturityDealRequestId || Boolean(isInvalidMaturityDealRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiInvestmentDetailsRPC.Errors || (GetFiInvestmentDetailsRPC.Errors = {}));
})(GetFiInvestmentDetailsRPC = exports.GetFiInvestmentDetailsRPC || (exports.GetFiInvestmentDetailsRPC = {}));
