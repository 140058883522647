"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFRedeemType = exports.InvalidMFRedeemTypeError = void 0;
class InvalidMFRedeemTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFRedeemTypeError";
    }
}
exports.InvalidMFRedeemTypeError = InvalidMFRedeemTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFRedeemType;
(function (MFRedeemType_1) {
    let MFRedeemType;
    (function (MFRedeemType) {
        MFRedeemType["UNITS"] = "UNITS";
        MFRedeemType["AMOUNT"] = "AMOUNT";
        MFRedeemType["ALL"] = "ALL";
    })(MFRedeemType = MFRedeemType_1.MFRedeemType || (MFRedeemType_1.MFRedeemType = {}));
    MFRedeemType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "UNITS":
                return MFRedeemType.UNITS;
            case "AMOUNT":
                return MFRedeemType.AMOUNT;
            case "ALL":
                return MFRedeemType.ALL;
            default:
                throw new InvalidMFRedeemTypeError(`Case ${dto.case} is not valid case of MFRedeemType`);
        }
    };
    MFRedeemType_1.toDTO = (mFRedeemType) => {
        const ret = {
            case: MFRedeemType[mFRedeemType],
        };
        return ret;
    };
    MFRedeemType_1.isInstanceOf = (other) => {
        if (other in MFRedeemType) {
            return true;
        }
        return false;
    };
})(MFRedeemType = exports.MFRedeemType || (exports.MFRedeemType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
