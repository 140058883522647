import { Instance, types } from "mobx-state-tree";
import { PercentageDate } from "@khazana/khazana-rpcs";
import { PRECISION_FACTOR } from "../../../utils";

export const PercentageDateModel = types.model("PercentageDateModel", {
  date: types.Date,
  percentage: types.number,
});

export const createPercentageDateModel = (
  data?: PercentageDate,
): Instance<typeof PercentageDateModel> => {
  if (data) {
    return PercentageDateModel.create({
      date: new Date(data.date.date),
      percentage: data.percentage / PRECISION_FACTOR,
    });
  }
  return PercentageDateModel.create();
};
