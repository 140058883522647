import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  DropdownItem,
  LoadingIndicator,
  TextInputFieldSeparateLabel,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  Alert,
  Box,
  debounce,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { getTransactionTypeOptions } from "../../utils/SearchUtils";
import { AmountTextField } from "../../../components/AmountTextField";
import { Instance } from "mobx-state-tree";
import {
  ContractNoteEditAutocompleteFieldModel,
  ContractNoteEditFieldModel,
  ContractNoteFields,
} from "../models/ContractNoteEditModel";
import { DEBOUNCE_DELAY, getFormattedAmountString } from "../../../../../utils";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import {
  useContractNoteDetailsStore,
  useEquityContractNotesSearchStore,
} from "../store/hooks";
import { ContractNoteChargeType } from "../../../models/ContractNoteChargeType";
import { getBrokerAutocompleteOptions } from "../utils/SearchUtils";
import {
  AutoCompleteItem,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { getSymbolsDropdownOptions } from "../../deal-request/utils/UIUtils";
import { AsyncAutoCompleteInputFieldSeparateLabel } from "../../../components/AsyncAutoCompleteInputFieldSeparateLabel";
import { useBorder } from "../../../../../utils/BorderUtils";
import { EquityTransactionType } from "@khazana/khazana-rpcs";
import { EntityDropdown } from "../../../components/entity/EntityDropdown";

interface ContractNoteEditProps {
  isSubmitClicked: boolean;
  setIsSubmitClicked: (value: React.SetStateAction<boolean>) => void;
  isAlertVisible: boolean;
  setIsAlertVisible: (value: React.SetStateAction<boolean>) => void;
}

const Size = {
  loadingIndicator: "28px",
  autoComplete: {
    subLabelWidth: "120px",
  },
};

export const ContractNoteEdit = observer(
  ({
    isSubmitClicked,
    setIsSubmitClicked,
    isAlertVisible,
    setIsAlertVisible,
  }: ContractNoteEditProps): React.ReactElement => {
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const typography = useTypography();
    const border = useBorder();
    const { t } = useTranslation();
    const detailsStore = useContractNoteDetailsStore();
    const store = useEquityContractNotesSearchStore();
    const [isBrokerDropdownLoading, setIsBrokerDropdownLoading] =
      useState(false);
    const [isSymbolDropdownOptionsLoading, setIsSymbolDropdownOptionsLoading] =
      useState(false);
    const [isChargesLoading, setIsChargesLoading] = useState(false);
    const [
      isDematAccountDropdownOptionsLoading,
      setIsDematAccountDropdownOptionsLoading,
    ] = useState(false);
    const [symbolSearchNoOptionsText, setSymbolSearchNoOptionsText] = useState(
      t("dealRequest.startTypingToSearchEquity"),
    );
    const [isErrorAlertVisible, setIsErrorAlertVisible] =
      useState(isAlertVisible);

    const debounceSymbolSearch = useCallback(
      debounce(function (searchValue: string | null | undefined) {
        if (!searchValue || searchValue.length < 2) {
          if (!searchValue) {
            detailsStore.editDetails?.clearEquityList();
          }
          debounceSymbolSearch.clear();
          setSymbolSearchNoOptionsText(
            t("dealRequest.startTypingToSearchEquity"),
          );
          return;
        }
        setIsSymbolDropdownOptionsLoading(true);
        detailsStore.editDetails
          ?.getEquityList(searchValue)
          .then(() => {
            if (!detailsStore.editDetails?.equityList.length) {
              setSymbolSearchNoOptionsText(t("dealRequest.noSymbolsFound"));
            }
            debounceSymbolSearch.clear();
          })
          .finally(() => {
            setIsSymbolDropdownOptionsLoading(false);
          });
      }, DEBOUNCE_DELAY),
      [],
    );

    useEffect(() => {
      if (detailsStore.editDetails?.symbol) {
        debounceSymbolSearch(detailsStore.editDetails.symbol.id.trim());
      }
      setIsBrokerDropdownLoading(true);
      store.getBrokerList().finally(() => {
        setIsBrokerDropdownLoading(false);
      });
      detailsStore.entityDropdownStore
        .getEntityList()
        .then(() => {
          const entityValue = detailsStore.editDetails?.entity.value;
          if (entityValue !== "") {
            detailsStore.entityDropdownStore.setSelectedEntity(entityValue);
            setIsDematAccountDropdownOptionsLoading(true);
            return detailsStore.editDetails?.getDematAccountNumberList();
          }
        })
        .finally(() => {
          setIsDematAccountDropdownOptionsLoading(false);
        });
    }, []);

    const getHeader = (
      value: string,
      hasLoader?: boolean,
    ): React.ReactElement => {
      return (
        <Stack spacing={spacing.spaceXS}>
          <Stack direction="row" justifyContent="space-between">
            <Typography
              sx={{
                ...typography.s1,
              }}
            >
              {value}
            </Typography>
            {hasLoader && isChargesLoading && (
              <Stack>
                <LoadingIndicator
                  isLoading={isChargesLoading}
                  variant="container"
                  style={{
                    width: Size.loadingIndicator,
                    height: Size.loadingIndicator,
                  }}
                />
              </Stack>
            )}
          </Stack>
          <Divider sx={{ color: tokens.border }} />
        </Stack>
      );
    };

    const getEntityField = (): React.ReactElement => {
      const hasError =
        isSubmitClicked && !detailsStore.entityDropdownStore.selectedEntity;
      if (hasError && !isErrorAlertVisible) {
        setIsErrorAlertVisible(true);
      }
      return (
        <Grid item xs={6}>
          <EntityDropdown
            store={detailsStore.entityDropdownStore}
            onChange={() => {
              setIsSubmitClicked(false);
              setIsErrorAlertVisible(false);
              setIsAlertVisible(false);
              detailsStore.editDetails?.updateField(
                ContractNoteFields.entity,
                detailsStore.entityDropdownStore.selectedEntity,
              );
              setIsDematAccountDropdownOptionsLoading(true);
              detailsStore.editDetails
                ?.getDematAccountNumberList()
                .finally(() => setIsDematAccountDropdownOptionsLoading(false));
            }}
            error={hasError ? "" : undefined}
            isRequired={true}
          />
        </Grid>
      );
    };

    const getField = ({
      label,
      type,
      isDecimalAllowed = true,
      fractionDigits = 2,
      options = [],
      isRequired = true,
      isLoading,
      onInputChange,
      onSelect,
      onInputClear,
      width = "half",
      placeholder,
      data,
      isDisabled = false,
      noOptionsText,
    }: {
      label: ContractNoteFields;
      type: "Autocomplete" | "Dropdown" | "Text" | "Amount";
      isDecimalAllowed?: boolean;
      fractionDigits?: number;
      onInputChange?: (inputValue: string | null) => void;
      onSelect?: () => void;
      onInputClear?: () => void;
      options?: DropdownItem[] | AutoCompleteItem[];
      isRequired?: boolean;
      isLoading?: boolean;
      width?: "full" | "half";
      placeholder: string;
      data?:
        | Instance<typeof ContractNoteEditFieldModel>
        | Instance<typeof ContractNoteEditAutocompleteFieldModel>;
      isDisabled?: boolean;
      noOptionsText?: string;
    }): React.ReactElement => {
      const error = data?.error;
      let hasError = error !== undefined && !isDisabled;
      if (hasError && !isErrorAlertVisible) {
        setIsErrorAlertVisible(true);
      }

      switch (type) {
        case "Autocomplete":
          const autocompleteData = data as Instance<
            typeof ContractNoteEditAutocompleteFieldModel
          >;
          if (isSubmitClicked && !isDisabled && !autocompleteData.id) {
            hasError = true;
          }
          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <AsyncAutoCompleteInputFieldSeparateLabel
                key={autocompleteData.id}
                id={label}
                /* @ts-ignore */
                label={t(label)}
                value={
                  autocompleteData.label && autocompleteData.id
                    ? {
                        label: autocompleteData.label,
                        id: autocompleteData.id,
                      }
                    : {
                        label: "",
                        id: "",
                      }
                }
                isFilterDisabled={label === ContractNoteFields.symbol}
                options={options as AutoCompleteItem[]}
                subLabelWidth={Size.autoComplete.subLabelWidth}
                onSelect={(value): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  if (typeof value !== "string") {
                    detailsStore.editDetails?.updateAutocompleteField(
                      label,
                      value,
                    );
                  }
                  if (onSelect) {
                    onSelect();
                  }
                }}
                onInputChange={onInputChange}
                isLoading={isLoading}
                placeholder={placeholder}
                isRequired={isRequired}
                error={hasError}
                helperText={
                  /* @ts-ignore */
                  hasError ? t(error?.toString()) : autocompleteData.info
                }
                helperTextColor={hasError ? "error" : "neutral"}
                onInputClear={onInputClear}
                isDisabled={isDisabled}
                noOptionsText={noOptionsText}
                displayLoadingText={
                  label !== ContractNoteFields.symbol && isLoading
                }
              />
            </Grid>
          );
        case "Dropdown":
          const dropdownData = data as Instance<
            typeof ContractNoteEditFieldModel
          >;
          if (isSubmitClicked && !isDisabled && !dropdownData.value) {
            hasError = true;
          }
          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <DropdownInputFieldSeparateLabel
                name={label}
                /* @ts-ignore */
                label={t(label)}
                value={dropdownData?.value ? dropdownData.value : undefined}
                options={options as DropdownItem[]}
                onSelect={(value): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  detailsStore.editDetails?.updateField(label, value.value);
                  if (onSelect) {
                    onSelect();
                  }
                }}
                placeholder={placeholder}
                isRequired={isRequired}
                error={hasError}
                /* @ts-ignore */
                helperText={hasError ? t(error?.toString()) : undefined}
                helperTextColor="error"
                isDisabled={isDisabled}
                isLoading={isLoading}
                loadingText={t("common.loading")}
              />
            </Grid>
          );
        case "Text":
          const textData = data as Instance<typeof ContractNoteEditFieldModel>;
          if (isSubmitClicked && !isDisabled && !textData.value) {
            hasError = true;
          }
          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <TextInputFieldSeparateLabel
                name={label}
                value={textData?.value}
                type="text"
                onTextChange={(value): void => {
                  setIsSubmitClicked(false);
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  detailsStore.editDetails?.updateField(label, value);
                }}
                // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
                /* @ts-ignore */
                label={t(label)}
                isRequired={isRequired}
                error={hasError}
                // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
                /* @ts-ignore */
                helperText={hasError ? t(error?.toString()) : undefined}
                helperTextColor="error"
                placeholder={placeholder}
                isDisabled={isDisabled}
              />
            </Grid>
          );

        case "Amount":
          const amountData = data as Instance<
            typeof ContractNoteEditFieldModel
          >;
          let errorText: string | undefined;
          let amountError = false;
          if (isSubmitClicked) {
            switch (label) {
              case ContractNoteFields.grossAmount:
                if (error && amountData.value !== error) {
                  errorText = t("common.shouldBe", {
                    val: getFormattedAmountString(
                      Number(error),
                      fractionDigits,
                    ),
                  });
                  amountError = true;
                }
                break;
              case ContractNoteFields.grossPricePerUnit:
                amountError =
                  !detailsStore.editDetails?.grossPricePerUnit.value;
                if (amountError) {
                  errorText = t("contractNotes.pricePerUnitZero");
                }
                break;
              case ContractNoteFields.quantity:
                amountError = !detailsStore.editDetails?.quantity.value;
                if (amountError) {
                  errorText = t("contractNotes.pricePerUnitZero");
                }
                break;
            }
          }
          if (
            !detailsStore.editDetails?.isAmountValid() &&
            (label === ContractNoteFields.grossPricePerUnit ||
              label === ContractNoteFields.quantity)
          ) {
            amountError = true;
            errorText = t("common.resultGrossAmountTooLarge");
          }

          return (
            <Grid item xs={width === "full" ? 12 : 6}>
              <AmountTextField
                name={label}
                value={amountData?.value}
                onAmountChange={(amount): void => {
                  setIsErrorAlertVisible(false);
                  setIsAlertVisible(false);
                  detailsStore.editDetails?.updateField(label, amount, error);
                }}
                // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
                /* @ts-ignore */
                label={t(label, {
                  val: detailsStore.editDetails?.currency?.symbol
                    ? `(${detailsStore.editDetails?.currency?.symbol})`
                    : null,
                })}
                isRequired={isRequired}
                placeholder={placeholder}
                isDecimalAllowed={isDecimalAllowed}
                fractionDigits={fractionDigits}
                error={amountError && !isDisabled}
                helperText={errorText}
                isDisabled={isDisabled}
              />
            </Grid>
          );
      }
    };

    const getChargeField = (
      charge: Instance<typeof ContractNoteChargeType>,
    ): React.ReactElement => {
      const width = charge.chargeRuleId ? 6 : 12;
      const isDisabled = !detailsStore.editDetails?.isBasicSectionValid();
      let errorText: string | undefined;
      // [TODO: https://surya-digital.atlassian.net/browse/KD-680]
      const error =
        (detailsStore.columnHeaders?.diffDataHeader ===
        "contractNotes.suggestedEdits"
          ? charge.diffValue !== null && !detailsStore.isValidated
          : charge.diffValue !== null) &&
        !isDisabled &&
        getFormattedAmountString(Number(charge.diffValue)) !==
          getFormattedAmountString(Number(charge.amount));

      if (error) {
        errorText = t("common.shouldBe", {
          val: getFormattedAmountString(Number(charge.diffValue)),
        });
      }

      const placeholder =
        charge.chargeType !== "STT" && charge.chargeType !== "IGST"
          ? t("contractNotes.enterChargeLowercase", {
              /* @ts-ignore */
              charge: t(charge.displayName),
            })
          : t("contractNotes.enterCharge", {
              /* @ts-ignore */
              charge: t(charge.displayName),
            });
      const label: string = detailsStore.editDetails?.currency
        ? /* @ts-ignore */
          t(charge.displayName) +
          ` (${detailsStore.editDetails?.currency?.symbol})`
        : /* @ts-ignore */
          t(charge.displayName);
      return (
        <Grid key={charge.chargeType} item xs={width}>
          <AmountTextField
            name={charge.chargeType}
            value={charge?.amount}
            onAmountChange={(amount): void => {
              setIsSubmitClicked(false);
              setIsErrorAlertVisible(false);
              setIsAlertVisible(false);
              detailsStore.editDetails?.updateCharge(
                amount ? Number(amount) : 0,
                charge.chargeType,
              );
            }}
            // Ideally we need to assert the template string `as const` but the eslint still throws error. See https://www.i18next.com/overview/typescript#type-error-template-literal
            label={label}
            isRequired={charge.chargeType !== "STAMP_DUTY"}
            placeholder={placeholder}
            isDecimalAllowed={true}
            error={error}
            helperText={errorText}
            isDisabled={isDisabled}
          />
        </Grid>
      );
    };

    return (
      <Stack
        sx={{
          height: "Calc(100vh - 220px)",
          width: "50%",
          overflow: "-moz-scrollbars-vertical",
          overflowY: "scroll",
          borderRight: border.default,
          padding: spacing.spaceXL,
        }}
        spacing={spacing.space3XL}
      >
        <Stack spacing={spacing.spaceLG}>
          {(isErrorAlertVisible ||
            isAlertVisible ||
            detailsStore.error === LeoErrors.InvalidContractNoteEditsError) && (
            <Alert
              icon={<></>}
              variant="filled"
              sx={{
                background: tokens.backgroundErrorSubtle,
                border: border.errorSubtle,
                color: tokens.labelError,
                ...typography.b1,
              }}
            >
              {t("contractNotes.editRequiredFieldsError")}
            </Alert>
          )}
          {getHeader(t("contractNotes.basicDetails"))}
          <Box>
            <Grid
              container
              rowGap={spacing.spaceLG}
              columnSpacing={spacing.spaceMD}
            >
              {getField({
                label: ContractNoteFields.broker,
                type: "Autocomplete",
                options: getBrokerAutocompleteOptions(t, store.brokers),
                placeholder: t("contractNotes.selectBroker"),
                data: detailsStore.editDetails?.broker,
                onSelect: () => {
                  const currency = store.brokers.find(
                    (broker) =>
                      broker.id === detailsStore.editDetails?.broker.id,
                  )?.currency;
                  detailsStore.editDetails?.updateCurrency(currency);
                  if (
                    detailsStore.editDetails?.currency &&
                    detailsStore.editDetails?.broker.id &&
                    detailsStore.editDetails?.transactionType.value
                  ) {
                    setIsChargesLoading(true);
                    detailsStore.editDetails
                      ?.getContractNoteCharges()
                      .finally(() => {
                        setIsChargesLoading(false);
                      });
                  }
                },
                isLoading: isBrokerDropdownLoading,
                noOptionsText: t("contractNotes.noBrokersFound"),
              })}
              {getField({
                label: ContractNoteFields.transactionType,
                type: "Dropdown",
                options: getTransactionTypeOptions(t),
                placeholder: t("contractNotes.selectTransactionType"),
                data: detailsStore.editDetails?.transactionType,
                onSelect: () => {
                  if (
                    detailsStore.editDetails?.currency &&
                    detailsStore.editDetails?.broker.id &&
                    detailsStore.editDetails?.transactionType.value
                  ) {
                    setIsChargesLoading(true);
                    detailsStore.editDetails
                      ?.getContractNoteCharges()
                      .finally(() => {
                        setIsChargesLoading(false);
                      });
                  }
                },
              })}
              {getField({
                label: ContractNoteFields.contractNoteNumber,
                type: "Text",
                placeholder: t("contractNotes.enterContractNoteNumber"),
                data: detailsStore.editDetails?.contractNoteNumber,
              })}
              {getEntityField()}
              {getField({
                label: ContractNoteFields.dematAccountNumber,
                type: "Autocomplete",
                placeholder: t("contractNotes.selectDematAccountNumber"),
                data: detailsStore.editDetails?.dematAccountNumber,
                options: detailsStore.editDetails?.dematAccountNumberList.map(
                  (acc) => ({
                    label: acc.dematAccountNumber,
                    id: acc.dematAccountCode,
                  }),
                ),
                onSelect: () => {
                  detailsStore.editDetails?.updateField(
                    ContractNoteFields.mapinId,
                    detailsStore.editDetails?.dematAccountNumber.id,
                  );
                },
                isDisabled:
                  detailsStore.entityDropdownStore.selectedEntity === undefined,
                isLoading: isDematAccountDropdownOptionsLoading,
                noOptionsText: t("contractNotes.noDematAccountNumbersFound"),
              })}
              {getField({
                label: ContractNoteFields.mapinId,
                type: "Text",
                isRequired: false,
                placeholder: t("contractNotes.enterMapinId"),
                data: detailsStore.editDetails?.mapinId,
                isDisabled: true,
              })}
              {getField({
                label: ContractNoteFields.symbol,
                type: "Autocomplete",
                width: "full",
                options: getSymbolsDropdownOptions(
                  detailsStore.editDetails?.equityList ?? [],
                ),
                onInputChange: (inputValue: string | null) => {
                  const searchText = inputValue?.trim();
                  if (!searchText) {
                    detailsStore.editDetails?.clearEquityList();
                  }
                  debounceSymbolSearch(searchText);
                },
                onInputClear: (): void => {
                  setSymbolSearchNoOptionsText(
                    t("dealRequest.startTypingToSearchEquity"),
                  );
                  detailsStore.editDetails?.clearEquityList();
                },
                placeholder: t("common.selectSymbol"),
                data: detailsStore.editDetails?.symbol,
                isLoading: isSymbolDropdownOptionsLoading,
                // Symbol Search Field has two noOptionsText state, on initial search and when no results are found
                noOptionsText: symbolSearchNoOptionsText,
              })}
            </Grid>
          </Box>
        </Stack>
        <Stack spacing={spacing.spaceLG}>
          {getHeader(t("contractNotes.amountDetails"))}
          <Box>
            <Grid
              container
              rowGap={spacing.spaceLG}
              columnSpacing={spacing.spaceMD}
            >
              {getField({
                label: ContractNoteFields.grossPricePerUnit,
                type: "Amount",
                placeholder: t("contractNotes.enterGrossPricePerUnit"),
                data: detailsStore.editDetails?.grossPricePerUnit,
                isDisabled: !detailsStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 4,
              })}
              {getField({
                label: ContractNoteFields.quantity,
                type: "Amount",
                isDecimalAllowed: false,
                placeholder: t("contractNotes.enterQuantity"),
                data: detailsStore.editDetails?.quantity,
                isDisabled: !detailsStore.editDetails?.isBasicSectionValid(),
              })}
              {getField({
                label: ContractNoteFields.grossAmount,
                type: "Amount",
                width: "full",
                placeholder: t("contractNotes.enterGrossAmount"),
                data: detailsStore.editDetails?.grossAmount,
                isDisabled: !detailsStore.editDetails?.isBasicSectionValid(),
                fractionDigits: 4,
              })}
            </Grid>
          </Box>
        </Stack>
        {detailsStore.editDetails?.charges &&
          detailsStore.editDetails?.charges.filter(
            (charge) => charge.chargeType !== "NET_AMOUNT",
          ).length > 0 && (
            <>
              <Stack spacing={spacing.spaceLG}>
                {getHeader(t("contractNotes.applicableCharges"), true)}
                <Box>
                  <Grid
                    container
                    rowGap={spacing.spaceLG}
                    columnSpacing={spacing.spaceMD}
                  >
                    {detailsStore.editDetails?.charges
                      .filter((charge) => charge.chargeType !== "NET_AMOUNT")
                      .map((charge) => getChargeField(charge))}
                  </Grid>
                </Box>
              </Stack>
              <Stack spacing={spacing.spaceLG}>
                {getHeader(
                  detailsStore.editDetails?.transactionType.value ===
                    EquityTransactionType.EquityTransactionType.BUY
                    ? t("contractNotes.netAmountBuySectionHeader")
                    : t("contractNotes.netAmountSellSectionHeader"),
                  true,
                )}
                <Box>
                  <Grid
                    container
                    rowGap={spacing.spaceLG}
                    columnSpacing={spacing.spaceMD}
                  >
                    {detailsStore.editDetails?.charges
                      .filter((charge) => charge.chargeType === "NET_AMOUNT")
                      .map((charge) => getChargeField(charge))}
                  </Grid>
                </Box>
              </Stack>
            </>
          )}
      </Stack>
    );
  },
);
