"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CashflowTimelineItem = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const cashflowTimelineAmount_1 = require("./cashflowTimelineAmount");
class InvalidCashflowTimelineItemError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidCashflowTimelineItemError";
    }
}
class CashflowTimelineItem {
    constructor(cashflowDate, timelineAmount) {
        this.isCashflowTimelineItem = true;
        CashflowTimelineItem.validateCashflowDate(cashflowDate);
        this.cashflowDate = cashflowDate;
        CashflowTimelineItem.validateTimelineAmount(timelineAmount);
        this.timelineAmount = timelineAmount;
    }
    static fromDTO(dto) {
        const cashflowDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "cashflowDate", InvalidCashflowTimelineItemError);
        const timelineAmount = (0, leo_ts_runtime_1.getList)(dto, "timelineAmount", cashflowTimelineAmount_1.CashflowTimelineAmount.fromDTO, InvalidCashflowTimelineItemError);
        return new CashflowTimelineItem(cashflowDate, timelineAmount);
    }
    toDTO() {
        const ret = {
            cashflowDate: this.cashflowDate.toDTO(),
            timelineAmount: this.timelineAmount.map((e) => {
                return e.toDTO();
            }),
        };
        return ret;
    }
    copy(cashflowDate = this.cashflowDate, timelineAmount = this.timelineAmount) {
        return new CashflowTimelineItem(cashflowDate, timelineAmount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isCashflowTimelineItemValue = other.isCashflowTimelineItem;
        if (!(other instanceof CashflowTimelineItem) || Boolean(isCashflowTimelineItemValue)) {
            return false;
        }
        if (!this.cashflowDate.equals(other.cashflowDate)) {
            return false;
        }
        if (!(0, leo_ts_runtime_1.listEquals)(this.timelineAmount, other.timelineAmount)) {
            return false;
        }
        return true;
    }
}
exports.CashflowTimelineItem = CashflowTimelineItem;
CashflowTimelineItem.validateCashflowDate = (cashflowDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(cashflowDate)) {
        throw new InvalidCashflowTimelineItemError(`Attribute cashflowDate is not a Date`);
    }
};
CashflowTimelineItem.validateTimelineAmount = (timelineAmount) => {
    if (!Array.isArray(timelineAmount)) {
        throw new InvalidCashflowTimelineItemError(`Attribute timelineAmount is not a List`);
    }
    for (let i = 0; i < timelineAmount.length; i += 1) {
        const isCashflowTimelineAmountValue = timelineAmount[i].isCashflowTimelineAmount;
        if (!(timelineAmount[i] instanceof cashflowTimelineAmount_1.CashflowTimelineAmount || Boolean(isCashflowTimelineAmountValue))) {
            throw new InvalidCashflowTimelineItemError(`Attribute timelineAmount at index ${i} is not a CashflowTimelineAmount.`);
        }
    }
};
CashflowTimelineItem.prototype.toString = function toString() {
    return `CashflowTimelineItem(cashflowDate=${this.cashflowDate},timelineAmount=${this.timelineAmount})`;
};
