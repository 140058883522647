import {
  Currency,
  DashboardStat,
  GetEquityDashboardStatsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetEquityDashboardStatsRPCImpl extends GetEquityDashboardStatsRPC {
  execute(
    _request: GetEquityDashboardStatsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityDashboardStatsRPC.Response,
      GetEquityDashboardStatsRPC.Errors.Errors
    >
  > {
    const response = new GetEquityDashboardStatsRPC.Response(
      new DashboardStat(71589190500000, new Currency("INR", "₹"), null, null),
      new DashboardStat(72043690500000, new Currency("INR", "₹"), null, null),
      new DashboardStat(4545000000, new Currency("INR", "₹"), true, 26490000),
      new DashboardStat(4545000000, new Currency("INR", "₹"), true, 26490000),
      new DashboardStat(120000, null, true, null),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityDashboardStatsRPC.Response,
        GetEquityDashboardStatsRPC.Errors.Errors
      >
    >;
  }
}
