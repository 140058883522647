"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiContractNotesPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("../types/currency");
const fiContractNoteRequestStatus_1 = require("./fiContractNoteRequestStatus");
class InvalidGetFiContractNotesPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiContractNotesPaginationResponseError";
    }
}
class GetFiContractNotesPaginationResponse {
    constructor(contractNoteId, status, brokerId = null, brokerName = null, fileName = null, contractNoteNumber = null, security = null, isin = null, dealRequestId = null, amount = null, currency = null, receivedAt) {
        this.isGetFiContractNotesPaginationResponse = true;
        GetFiContractNotesPaginationResponse.validateContractNoteId(contractNoteId);
        this.contractNoteId = contractNoteId;
        GetFiContractNotesPaginationResponse.validateStatus(status);
        this.status = status;
        if (brokerId !== undefined && brokerId !== null) {
            GetFiContractNotesPaginationResponse.validateBrokerId(brokerId);
        }
        this.brokerId = brokerId;
        if (brokerName !== undefined && brokerName !== null) {
            GetFiContractNotesPaginationResponse.validateBrokerName(brokerName);
        }
        this.brokerName = brokerName;
        if (fileName !== undefined && fileName !== null) {
            GetFiContractNotesPaginationResponse.validateFileName(fileName);
        }
        this.fileName = fileName;
        if (contractNoteNumber !== undefined && contractNoteNumber !== null) {
            GetFiContractNotesPaginationResponse.validateContractNoteNumber(contractNoteNumber);
        }
        this.contractNoteNumber = contractNoteNumber;
        if (security !== undefined && security !== null) {
            GetFiContractNotesPaginationResponse.validateSecurity(security);
        }
        this.security = security;
        if (isin !== undefined && isin !== null) {
            GetFiContractNotesPaginationResponse.validateIsin(isin);
        }
        this.isin = isin;
        if (dealRequestId !== undefined && dealRequestId !== null) {
            GetFiContractNotesPaginationResponse.validateDealRequestId(dealRequestId);
        }
        this.dealRequestId = dealRequestId;
        if (amount !== undefined && amount !== null) {
            GetFiContractNotesPaginationResponse.validateAmount(amount);
        }
        this.amount = amount;
        if (currency !== undefined && currency !== null) {
            GetFiContractNotesPaginationResponse.validateCurrency(currency);
        }
        this.currency = currency;
        GetFiContractNotesPaginationResponse.validateReceivedAt(receivedAt);
        this.receivedAt = receivedAt;
    }
    static fromDTO(dto) {
        const contractNoteId = (0, leo_ts_runtime_1.getInt64)(dto, "contractNoteId", InvalidGetFiContractNotesPaginationResponseError);
        const status = fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "status", InvalidGetFiContractNotesPaginationResponseError));
        const brokerId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "brokerId", InvalidGetFiContractNotesPaginationResponseError);
        const brokerName = (0, leo_ts_runtime_1.getOptionalString)(dto, "brokerName", InvalidGetFiContractNotesPaginationResponseError);
        const fileName = (0, leo_ts_runtime_1.getOptionalString)(dto, "fileName", InvalidGetFiContractNotesPaginationResponseError);
        const contractNoteNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "contractNoteNumber", InvalidGetFiContractNotesPaginationResponseError);
        const security = (0, leo_ts_runtime_1.getOptionalString)(dto, "security", InvalidGetFiContractNotesPaginationResponseError);
        const isin = (0, leo_ts_runtime_1.getOptionalString)(dto, "isin", InvalidGetFiContractNotesPaginationResponseError);
        const dealRequestId = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "dealRequestId", InvalidGetFiContractNotesPaginationResponseError);
        const amount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amount", InvalidGetFiContractNotesPaginationResponseError);
        let currency = (0, leo_ts_runtime_1.getOptionalObject)(dto, "currency");
        if (currency !== undefined && currency !== null) {
            currency = currency_1.Currency.fromDTO(currency);
        }
        const receivedAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "receivedAt", InvalidGetFiContractNotesPaginationResponseError);
        return new GetFiContractNotesPaginationResponse(contractNoteId, status, brokerId, brokerName, fileName, contractNoteNumber, security, isin, dealRequestId, amount, currency, receivedAt);
    }
    toDTO() {
        const ret = {
            contractNoteId: this.contractNoteId,
            status: fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.toDTO(this.status),
            receivedAt: this.receivedAt.toDTO(),
        };
        if (this.brokerId) {
            ret.brokerId = this.brokerId.toDTO();
        }
        if (this.brokerName !== undefined && this.brokerName !== null) {
            ret.brokerName = this.brokerName;
        }
        if (this.fileName !== undefined && this.fileName !== null) {
            ret.fileName = this.fileName;
        }
        if (this.contractNoteNumber !== undefined && this.contractNoteNumber !== null) {
            ret.contractNoteNumber = this.contractNoteNumber;
        }
        if (this.security !== undefined && this.security !== null) {
            ret.security = this.security;
        }
        if (this.isin !== undefined && this.isin !== null) {
            ret.isin = this.isin;
        }
        if (this.dealRequestId !== undefined && this.dealRequestId !== null) {
            ret.dealRequestId = this.dealRequestId;
        }
        if (this.amount !== undefined && this.amount !== null) {
            ret.amount = this.amount;
        }
        if (this.currency) {
            ret.currency = this.currency.toDTO();
        }
        return ret;
    }
    copy(contractNoteId = this.contractNoteId, status = this.status, brokerId = this.brokerId, brokerName = this.brokerName, fileName = this.fileName, contractNoteNumber = this.contractNoteNumber, security = this.security, isin = this.isin, dealRequestId = this.dealRequestId, amount = this.amount, currency = this.currency, receivedAt = this.receivedAt) {
        return new GetFiContractNotesPaginationResponse(contractNoteId, status, brokerId, brokerName, fileName, contractNoteNumber, security, isin, dealRequestId, amount, currency, receivedAt);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiContractNotesPaginationResponseValue = other.isGetFiContractNotesPaginationResponse;
        if (!(other instanceof GetFiContractNotesPaginationResponse) || Boolean(isGetFiContractNotesPaginationResponseValue)) {
            return false;
        }
        if (this.contractNoteId !== other.contractNoteId) {
            return false;
        }
        if (this.status !== other.status) {
            return false;
        }
        if (this.brokerId) {
            if (!this.brokerId.equals(other.brokerId)) {
                return false;
            }
        }
        if (this.brokerName !== other.brokerName) {
            return false;
        }
        if (this.fileName !== other.fileName) {
            return false;
        }
        if (this.contractNoteNumber !== other.contractNoteNumber) {
            return false;
        }
        if (this.security !== other.security) {
            return false;
        }
        if (this.isin !== other.isin) {
            return false;
        }
        if (this.dealRequestId !== other.dealRequestId) {
            return false;
        }
        if (this.amount !== other.amount) {
            return false;
        }
        if (this.currency) {
            if (!this.currency.equals(other.currency)) {
                return false;
            }
        }
        if (!this.receivedAt.equals(other.receivedAt)) {
            return false;
        }
        return true;
    }
}
exports.GetFiContractNotesPaginationResponse = GetFiContractNotesPaginationResponse;
GetFiContractNotesPaginationResponse.validateContractNoteId = (contractNoteId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(contractNoteId)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute contractNoteId is not an Int64`);
    }
};
GetFiContractNotesPaginationResponse.validateStatus = (status) => {
    if (!fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.isInstanceOf(status)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute status is not a FiContractNoteRequestStatus.FiContractNoteRequestStatus`);
    }
};
GetFiContractNotesPaginationResponse.validateBrokerId = (brokerId) => {
    if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute brokerId is not a UUID`);
    }
};
GetFiContractNotesPaginationResponse.validateBrokerName = (brokerName) => {
    if (!(0, leo_ts_runtime_1.isString)(brokerName)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute brokerName is not a String`);
    }
    if (brokerName.trim().length === 0) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute brokerName cannot be an empty string.`);
    }
};
GetFiContractNotesPaginationResponse.validateFileName = (fileName) => {
    if (!(0, leo_ts_runtime_1.isString)(fileName)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute fileName is not a String`);
    }
    if (fileName.trim().length === 0) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute fileName cannot be an empty string.`);
    }
};
GetFiContractNotesPaginationResponse.validateContractNoteNumber = (contractNoteNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(contractNoteNumber)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute contractNoteNumber is not a String`);
    }
    if (contractNoteNumber.trim().length === 0) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute contractNoteNumber cannot be an empty string.`);
    }
};
GetFiContractNotesPaginationResponse.validateSecurity = (security) => {
    if (!(0, leo_ts_runtime_1.isString)(security)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute security is not a String`);
    }
    if (security.trim().length === 0) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute security cannot be an empty string.`);
    }
};
GetFiContractNotesPaginationResponse.validateIsin = (isin) => {
    if (!(0, leo_ts_runtime_1.isString)(isin)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute isin is not a String`);
    }
    if (isin.trim().length === 0) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute isin cannot be an empty string.`);
    }
};
GetFiContractNotesPaginationResponse.validateDealRequestId = (dealRequestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(dealRequestId)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute dealRequestId is not an Int64`);
    }
};
GetFiContractNotesPaginationResponse.validateAmount = (amount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amount)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute amount is not an Int64`);
    }
};
GetFiContractNotesPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFiContractNotesPaginationResponse.validateReceivedAt = (receivedAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(receivedAt)) {
        throw new InvalidGetFiContractNotesPaginationResponseError(`Attribute receivedAt is not a Timestamp`);
    }
};
GetFiContractNotesPaginationResponse.prototype.toString = function toString() {
    return `GetFiContractNotesPaginationResponse(contractNoteId=${this.contractNoteId},status=${this.status},brokerId=${this.brokerId},brokerName='${this.brokerName}',fileName='${this.fileName}',contractNoteNumber='${this.contractNoteNumber}',security='${this.security}',isin='${this.isin}',dealRequestId=${this.dealRequestId},amount=${this.amount},currency=${this.currency},receivedAt=${this.receivedAt})`;
};
