"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquityHoldingSummaryResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidEquityHoldingSummaryResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidEquityHoldingSummaryResponseError";
    }
}
class EquityHoldingSummaryResponse {
    constructor(symbol, residualQuantity = null, holdingPeriod = null, activeCost = null, change = null, netGain = null, activeMarketValue = null, activeUnrealizedgain = null, irr = null, activeRealizedGain = null) {
        this.isEquityHoldingSummaryResponse = true;
        EquityHoldingSummaryResponse.validateSymbol(symbol);
        this.symbol = symbol;
        if (residualQuantity !== undefined && residualQuantity !== null) {
            EquityHoldingSummaryResponse.validateResidualQuantity(residualQuantity);
        }
        this.residualQuantity = residualQuantity;
        if (holdingPeriod !== undefined && holdingPeriod !== null) {
            EquityHoldingSummaryResponse.validateHoldingPeriod(holdingPeriod);
        }
        this.holdingPeriod = holdingPeriod;
        if (activeCost !== undefined && activeCost !== null) {
            EquityHoldingSummaryResponse.validateActiveCost(activeCost);
        }
        this.activeCost = activeCost;
        if (change !== undefined && change !== null) {
            EquityHoldingSummaryResponse.validateChange(change);
        }
        this.change = change;
        if (netGain !== undefined && netGain !== null) {
            EquityHoldingSummaryResponse.validateNetGain(netGain);
        }
        this.netGain = netGain;
        if (activeMarketValue !== undefined && activeMarketValue !== null) {
            EquityHoldingSummaryResponse.validateActiveMarketValue(activeMarketValue);
        }
        this.activeMarketValue = activeMarketValue;
        if (activeUnrealizedgain !== undefined && activeUnrealizedgain !== null) {
            EquityHoldingSummaryResponse.validateActiveUnrealizedgain(activeUnrealizedgain);
        }
        this.activeUnrealizedgain = activeUnrealizedgain;
        if (irr !== undefined && irr !== null) {
            EquityHoldingSummaryResponse.validateIrr(irr);
        }
        this.irr = irr;
        if (activeRealizedGain !== undefined && activeRealizedGain !== null) {
            EquityHoldingSummaryResponse.validateActiveRealizedGain(activeRealizedGain);
        }
        this.activeRealizedGain = activeRealizedGain;
    }
    static fromDTO(dto) {
        const symbol = (0, leo_ts_runtime_1.getString)(dto, "symbol", InvalidEquityHoldingSummaryResponseError);
        const residualQuantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "residualQuantity", InvalidEquityHoldingSummaryResponseError);
        const holdingPeriod = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "holdingPeriod", InvalidEquityHoldingSummaryResponseError);
        const activeCost = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "activeCost", InvalidEquityHoldingSummaryResponseError);
        const change = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "change", InvalidEquityHoldingSummaryResponseError);
        const netGain = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "netGain", InvalidEquityHoldingSummaryResponseError);
        const activeMarketValue = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "activeMarketValue", InvalidEquityHoldingSummaryResponseError);
        const activeUnrealizedgain = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "activeUnrealizedgain", InvalidEquityHoldingSummaryResponseError);
        const irr = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "irr", InvalidEquityHoldingSummaryResponseError);
        const activeRealizedGain = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "activeRealizedGain", InvalidEquityHoldingSummaryResponseError);
        return new EquityHoldingSummaryResponse(symbol, residualQuantity, holdingPeriod, activeCost, change, netGain, activeMarketValue, activeUnrealizedgain, irr, activeRealizedGain);
    }
    toDTO() {
        const ret = {
            symbol: this.symbol,
        };
        if (this.residualQuantity !== undefined && this.residualQuantity !== null) {
            ret.residualQuantity = this.residualQuantity;
        }
        if (this.holdingPeriod !== undefined && this.holdingPeriod !== null) {
            ret.holdingPeriod = this.holdingPeriod;
        }
        if (this.activeCost !== undefined && this.activeCost !== null) {
            ret.activeCost = this.activeCost;
        }
        if (this.change !== undefined && this.change !== null) {
            ret.change = this.change;
        }
        if (this.netGain !== undefined && this.netGain !== null) {
            ret.netGain = this.netGain;
        }
        if (this.activeMarketValue !== undefined && this.activeMarketValue !== null) {
            ret.activeMarketValue = this.activeMarketValue;
        }
        if (this.activeUnrealizedgain !== undefined && this.activeUnrealizedgain !== null) {
            ret.activeUnrealizedgain = this.activeUnrealizedgain;
        }
        if (this.irr !== undefined && this.irr !== null) {
            ret.irr = this.irr;
        }
        if (this.activeRealizedGain !== undefined && this.activeRealizedGain !== null) {
            ret.activeRealizedGain = this.activeRealizedGain;
        }
        return ret;
    }
    copy(symbol = this.symbol, residualQuantity = this.residualQuantity, holdingPeriod = this.holdingPeriod, activeCost = this.activeCost, change = this.change, netGain = this.netGain, activeMarketValue = this.activeMarketValue, activeUnrealizedgain = this.activeUnrealizedgain, irr = this.irr, activeRealizedGain = this.activeRealizedGain) {
        return new EquityHoldingSummaryResponse(symbol, residualQuantity, holdingPeriod, activeCost, change, netGain, activeMarketValue, activeUnrealizedgain, irr, activeRealizedGain);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isEquityHoldingSummaryResponseValue = other.isEquityHoldingSummaryResponse;
        if (!(other instanceof EquityHoldingSummaryResponse) || Boolean(isEquityHoldingSummaryResponseValue)) {
            return false;
        }
        if (this.symbol !== other.symbol) {
            return false;
        }
        if (this.residualQuantity !== other.residualQuantity) {
            return false;
        }
        if (this.holdingPeriod !== other.holdingPeriod) {
            return false;
        }
        if (this.activeCost !== other.activeCost) {
            return false;
        }
        if (this.change !== other.change) {
            return false;
        }
        if (this.netGain !== other.netGain) {
            return false;
        }
        if (this.activeMarketValue !== other.activeMarketValue) {
            return false;
        }
        if (this.activeUnrealizedgain !== other.activeUnrealizedgain) {
            return false;
        }
        if (this.irr !== other.irr) {
            return false;
        }
        if (this.activeRealizedGain !== other.activeRealizedGain) {
            return false;
        }
        return true;
    }
}
exports.EquityHoldingSummaryResponse = EquityHoldingSummaryResponse;
EquityHoldingSummaryResponse.validateSymbol = (symbol) => {
    if (!(0, leo_ts_runtime_1.isString)(symbol)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute symbol is not a String`);
    }
    if (symbol.trim().length === 0) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute symbol cannot be an empty string.`);
    }
};
EquityHoldingSummaryResponse.validateResidualQuantity = (residualQuantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(residualQuantity)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute residualQuantity is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateHoldingPeriod = (holdingPeriod) => {
    if (!(0, leo_ts_runtime_1.isInt64)(holdingPeriod)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute holdingPeriod is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateActiveCost = (activeCost) => {
    if (!(0, leo_ts_runtime_1.isInt64)(activeCost)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute activeCost is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateChange = (change) => {
    if (!(0, leo_ts_runtime_1.isInt64)(change)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute change is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateNetGain = (netGain) => {
    if (!(0, leo_ts_runtime_1.isInt64)(netGain)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute netGain is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateActiveMarketValue = (activeMarketValue) => {
    if (!(0, leo_ts_runtime_1.isInt64)(activeMarketValue)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute activeMarketValue is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateActiveUnrealizedgain = (activeUnrealizedgain) => {
    if (!(0, leo_ts_runtime_1.isInt64)(activeUnrealizedgain)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute activeUnrealizedgain is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateIrr = (irr) => {
    if (!(0, leo_ts_runtime_1.isInt64)(irr)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute irr is not an Int64`);
    }
};
EquityHoldingSummaryResponse.validateActiveRealizedGain = (activeRealizedGain) => {
    if (!(0, leo_ts_runtime_1.isInt64)(activeRealizedGain)) {
        throw new InvalidEquityHoldingSummaryResponseError(`Attribute activeRealizedGain is not an Int64`);
    }
};
EquityHoldingSummaryResponse.prototype.toString = function toString() {
    return `EquityHoldingSummaryResponse(symbol='${this.symbol}',residualQuantity=${this.residualQuantity},holdingPeriod=${this.holdingPeriod},activeCost=${this.activeCost},change=${this.change},netGain=${this.netGain},activeMarketValue=${this.activeMarketValue},activeUnrealizedgain=${this.activeUnrealizedgain},irr=${this.irr},activeRealizedGain=${this.activeRealizedGain})`;
};
