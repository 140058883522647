"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonPageIndex = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidCommonPageIndexError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidCommonPageIndexError";
    }
}
class CommonPageIndex {
    constructor(items) {
        this.isCommonPageIndex = true;
        CommonPageIndex.validateItems(items);
        this.items = items;
    }
    static fromDTO(dto) {
        const items = (0, leo_ts_runtime_1.getInt32)(dto, "items", InvalidCommonPageIndexError);
        return new CommonPageIndex(items);
    }
    toDTO() {
        const ret = {
            items: this.items,
        };
        return ret;
    }
    copy(items = this.items) {
        return new CommonPageIndex(items);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isCommonPageIndexValue = other.isCommonPageIndex;
        if (!(other instanceof CommonPageIndex) || Boolean(isCommonPageIndexValue)) {
            return false;
        }
        if (this.items !== other.items) {
            return false;
        }
        return true;
    }
}
exports.CommonPageIndex = CommonPageIndex;
CommonPageIndex.validateItems = (items) => {
    if (!(0, leo_ts_runtime_1.isInt32)(items)) {
        throw new InvalidCommonPageIndexError(`Attribute items is not an Int32`);
    }
    if (items < 0) {
        throw new InvalidCommonPageIndexError(`Attribute items has value ${items}. Min value is 0.`);
    }
};
CommonPageIndex.prototype.toString = function toString() {
    return `CommonPageIndex(items=${this.items})`;
};
