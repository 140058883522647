"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiContractNotesRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getFiContractNotesSortOrder_1 = require("./getFiContractNotesSortOrder");
const fixedIncomeItemsPerPage_1 = require("./fixedIncomeItemsPerPage");
const fixedIncomePageIndex_1 = require("./fixedIncomePageIndex");
const getFiContractNotesPaginationResponse_1 = require("./getFiContractNotesPaginationResponse");
const fiContractNoteRequestStatus_1 = require("./fiContractNoteRequestStatus");
/* eslint-disable import/export */
class GetFiContractNotesRPC {
}
exports.GetFiContractNotesRPC = GetFiContractNotesRPC;
(function (GetFiContractNotesRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiContractNotesRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, status = null, brokerId = null, contractNoteNumber = null, security = null, dealRequestId = null, itemsPerPage, pageIndex) {
            this.isGetFiContractNotesRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (status !== undefined && status !== null) {
                Request.validateStatus(status);
            }
            this.status = status;
            if (brokerId !== undefined && brokerId !== null) {
                Request.validateBrokerId(brokerId);
            }
            this.brokerId = brokerId;
            if (contractNoteNumber !== undefined && contractNoteNumber !== null) {
                Request.validateContractNoteNumber(contractNoteNumber);
            }
            this.contractNoteNumber = contractNoteNumber;
            if (security !== undefined && security !== null) {
                Request.validateSecurity(security);
            }
            this.security = security;
            if (dealRequestId !== undefined && dealRequestId !== null) {
                Request.validateDealRequestId(dealRequestId);
            }
            this.dealRequestId = dealRequestId;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getFiContractNotesSortOrder_1.GetFiContractNotesSortOrder.fromDTO, InvalidRequestError);
            let status = (0, leo_ts_runtime_1.getOptionalObject)(dto, "status");
            if (status !== undefined && status !== null) {
                status = fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.fromDTO(status);
            }
            const brokerId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "brokerId", InvalidRequestError);
            const contractNoteNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "contractNoteNumber", InvalidRequestError);
            const security = (0, leo_ts_runtime_1.getOptionalString)(dto, "security", InvalidRequestError);
            const dealRequestId = (0, leo_ts_runtime_1.getOptionalString)(dto, "dealRequestId", InvalidRequestError);
            const itemsPerPage = fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = fixedIncomePageIndex_1.FixedIncomePageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, status, brokerId, contractNoteNumber, security, dealRequestId, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.status !== undefined && this.status !== null) {
                ret.status = fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.toDTO(this.status);
            }
            if (this.brokerId) {
                ret.brokerId = this.brokerId.toDTO();
            }
            if (this.contractNoteNumber !== undefined && this.contractNoteNumber !== null) {
                ret.contractNoteNumber = this.contractNoteNumber;
            }
            if (this.security !== undefined && this.security !== null) {
                ret.security = this.security;
            }
            if (this.dealRequestId !== undefined && this.dealRequestId !== null) {
                ret.dealRequestId = this.dealRequestId;
            }
            return ret;
        }
        copy(sortList = this.sortList, status = this.status, brokerId = this.brokerId, contractNoteNumber = this.contractNoteNumber, security = this.security, dealRequestId = this.dealRequestId, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, status, brokerId, contractNoteNumber, security, dealRequestId, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiContractNotesRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.status !== other.status) {
                return false;
            }
            if (this.brokerId) {
                if (!this.brokerId.equals(other.brokerId)) {
                    return false;
                }
            }
            if (this.contractNoteNumber !== other.contractNoteNumber) {
                return false;
            }
            if (this.security !== other.security) {
                return false;
            }
            if (this.dealRequestId !== other.dealRequestId) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetFiContractNotesSortOrderValue = sortList[i].isGetFiContractNotesSortOrder;
            if (!(sortList[i] instanceof getFiContractNotesSortOrder_1.GetFiContractNotesSortOrder || Boolean(isGetFiContractNotesSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetFiContractNotesSortOrder.`);
            }
        }
    };
    Request.validateStatus = (status) => {
        if (!fiContractNoteRequestStatus_1.FiContractNoteRequestStatus.isInstanceOf(status)) {
            throw new InvalidRequestError(`Attribute status is not a FiContractNoteRequestStatus.FiContractNoteRequestStatus`);
        }
    };
    Request.validateBrokerId = (brokerId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(brokerId)) {
            throw new InvalidRequestError(`Attribute brokerId is not a UUID`);
        }
    };
    Request.validateContractNoteNumber = (contractNoteNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(contractNoteNumber)) {
            throw new InvalidRequestError(`Attribute contractNoteNumber is not a String`);
        }
        if (contractNoteNumber.length < 3) {
            throw new InvalidRequestError(`Attribute contractNoteNumber is too short. Size is ${contractNoteNumber.length}. Min size is 3.`);
        }
        if (contractNoteNumber.length > 200) {
            throw new InvalidRequestError(`Attribute contractNoteNumber is too long. Size is ${contractNoteNumber.length}. Max size is 200.`);
        }
        if (contractNoteNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute contractNoteNumber cannot be an empty string.`);
        }
    };
    Request.validateSecurity = (security) => {
        if (!(0, leo_ts_runtime_1.isString)(security)) {
            throw new InvalidRequestError(`Attribute security is not a String`);
        }
        if (security.length < 3) {
            throw new InvalidRequestError(`Attribute security is too short. Size is ${security.length}. Min size is 3.`);
        }
        if (security.length > 200) {
            throw new InvalidRequestError(`Attribute security is too long. Size is ${security.length}. Max size is 200.`);
        }
        if (security.trim().length === 0) {
            throw new InvalidRequestError(`Attribute security cannot be an empty string.`);
        }
    };
    Request.validateDealRequestId = (dealRequestId) => {
        if (!(0, leo_ts_runtime_1.isString)(dealRequestId)) {
            throw new InvalidRequestError(`Attribute dealRequestId is not a String`);
        }
        if (dealRequestId.length < 3) {
            throw new InvalidRequestError(`Attribute dealRequestId is too short. Size is ${dealRequestId.length}. Min size is 3.`);
        }
        if (dealRequestId.length > 200) {
            throw new InvalidRequestError(`Attribute dealRequestId is too long. Size is ${dealRequestId.length}. Max size is 200.`);
        }
        if (dealRequestId.trim().length === 0) {
            throw new InvalidRequestError(`Attribute dealRequestId cannot be an empty string.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isFixedIncomeItemsPerPageValue = itemsPerPage.isFixedIncomeItemsPerPage;
        if (!(itemsPerPage instanceof fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage || Boolean(isFixedIncomeItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a FixedIncomeItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isFixedIncomePageIndexValue = pageIndex.isFixedIncomePageIndex;
        if (!(pageIndex instanceof fixedIncomePageIndex_1.FixedIncomePageIndex || Boolean(isFixedIncomePageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a FixedIncomePageIndex`);
        }
    };
    GetFiContractNotesRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiContractNotesRPC.Request(sortList=${this.sortList},status=${this.status},brokerId=${this.brokerId},contractNoteNumber='${this.contractNoteNumber}',security='${this.security}',dealRequestId='${this.dealRequestId}',itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiContractNotesRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getFiContractNotesPaginationResponse, totalItems) {
            this.isGetFiContractNotesRPCResponse = true;
            Response.validateGetFiContractNotesPaginationResponse(getFiContractNotesPaginationResponse);
            this.getFiContractNotesPaginationResponse = getFiContractNotesPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getFiContractNotesPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getFiContractNotesPaginationResponse", getFiContractNotesPaginationResponse_1.GetFiContractNotesPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getFiContractNotesPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getFiContractNotesPaginationResponse: this.getFiContractNotesPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getFiContractNotesPaginationResponse = this.getFiContractNotesPaginationResponse, totalItems = this.totalItems) {
            return new Response(getFiContractNotesPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiContractNotesRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getFiContractNotesPaginationResponse, other.getFiContractNotesPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetFiContractNotesPaginationResponse = (getFiContractNotesPaginationResponse) => {
        if (!Array.isArray(getFiContractNotesPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getFiContractNotesPaginationResponse is not a List`);
        }
        for (let i = 0; i < getFiContractNotesPaginationResponse.length; i += 1) {
            const isGetFiContractNotesPaginationResponseValue = getFiContractNotesPaginationResponse[i].isGetFiContractNotesPaginationResponse;
            if (!(getFiContractNotesPaginationResponse[i] instanceof getFiContractNotesPaginationResponse_1.GetFiContractNotesPaginationResponse || Boolean(isGetFiContractNotesPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getFiContractNotesPaginationResponse at index ${i} is not a GetFiContractNotesPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetFiContractNotesRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiContractNotesRPC.Response(getFiContractNotesPaginationResponse=${this.getFiContractNotesPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetFiContractNotesRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiContractNotesRPC.Errors || (GetFiContractNotesRPC.Errors = {}));
})(GetFiContractNotesRPC = exports.GetFiContractNotesRPC || (exports.GetFiContractNotesRPC = {}));
