import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  createFDWithdrawModelFromRPCType,
  FDWithdrawModel,
} from "../../models/FDWithdrawModel";
import {
  FDWithdrawListItem,
  GetFdWithdrawListRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetFdWithdrawListRPC } from "../../rpc/RPC";
import { getAPIClient } from "@khazana/khazana-boilerplate";

export const FDWithdrawListStore = types
  .model("FDWithdrawListStore", {
    items: types.array(FDWithdrawModel),
    currencySymbol: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      getFdWithdrawList: flow(function* (requestId: number) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetFdWithdrawListRPC.Request(requestId);
        const result: LeoRPCResult<
          GetFdWithdrawListRPC.Response,
          GetFdWithdrawListRPC.Errors.Errors
        > = yield useGetFdWithdrawListRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.currencySymbol =
            response.withdrawList[0]?.withdrawalAmount.currency.symbol;
          store.items = cast(
            response.withdrawList.map((item: FDWithdrawListItem) =>
              createFDWithdrawModelFromRPCType(item),
            ),
          );
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFdWithdrawListRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createFDWithdrawListStore = (): Instance<
  typeof FDWithdrawListStore
> => {
  return FDWithdrawListStore.create({});
};
