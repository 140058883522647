import { observer } from "mobx-react";
import {
  PageHeader,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  getAmountStringOrHyphen,
  getFormattedTimeDateWithComma,
} from "../../../../../utils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { useFDDealRequestStore } from "../store/hooks";
import { FDDealRequestsFilterOptionsModel } from "../store/FDDealRequestsStore";
import { FDDealRequestsSearchFilter } from "../components/FDDealRequestsSearchFilter";
import { getFDRequestStatusValue } from "../utils/UIUtils";
import { getPath } from "../../../../../utils/RoutesUtils";

const Size = {
  container: "calc(100vw - 256px)",
  table: {
    default: "max-content",
    status: "220px",
    entity: "180px",
    timestamp: "220px",
  },
};

export const ManageDealRequests = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useFDDealRequestStore();
  const spacing = useSpacing();
  const navigate = useNavigate();

  const getDealRequests = async (
    option: TableOptions<Instance<typeof FDDealRequestsFilterOptionsModel>>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    if (option.filter) store.updateFilterOptions(option.filter);
    await store.getFdDealRequests(
      option.page ? option.page - 1 : 0,
      store.itemsPerPage(),
      option.sort?.order,
    );
    setTotalItems(store.totalItems);

    return store.requests.map((request) => {
      return [
        {
          data: getFDRequestStatusValue(
            request.requestStatus,
          ).toLocaleUpperCase(),
        },
        { data: request.depositBankName },
        {
          data: request.interestType
            ? t("interestTypeEnumValues.cumulative")
            : t("interestTypeEnumValues.simple"),
        },
        {
          data: getAmountStringOrHyphen(request.depositAmount),
          align: "right",
        },
        { data: request.entity },
        { data: request.portfolio },
        { data: request.bankAccountNumber },
        {
          data: request.requestId,
        },
        {
          data: getFormattedTimeDateWithComma(request.createdAt),
        },
      ];
    });
  };

  const getHeaders = (): TableHeader => {
    return [
      {
        id: "requestStatus",
        name: t("fd.fields.requestStatusLabel"),
        width: Size.table.status,
      },
      {
        id: "depositBankName",
        name: t("fd.fields.depositBankLabel"),
        width: Size.table.default,
      },
      {
        id: "interestType",
        name: t("fd.fields.interestTypeLabel"),
        width: Size.table.default,
      },
      {
        id: "depositAmount",
        name: t("fd.fields.depositAmountLabel", {
          currencySymbol: `(${store.currencySymbol})`,
        }),
        width: Size.table.default,
      },
      {
        id: "entity",
        name: t("common.entity"),
        width: Size.table.entity,
      },
      {
        id: "portfolio",
        name: t("common.portfolio"),
        width: Size.table.default,
      },
      {
        id: "bankAccountNumber",
        name: t("fd.fields.paymentBankAccountLabel"),
        width: Size.table.default,
      },
      {
        id: "requestId",
        name: t("common.requestId"),
        width: Size.table.default,
      },
      {
        id: "createdAt",
        name: t("common.createdAt"),
        width: Size.table.timestamp,
        sortable: true,
      },
    ];
  };

  return (
    <Stack width={Size.container}>
      <PageHeader title={t("fd.manageDealRequest.pageTitle")} />
      <Box margin={spacing.space2XL}>
        <Table
          name="fdDealRequestsTable"
          styleOverrides={{
            divider: "cell",
          }}
          headers={getHeaders()}
          onTableOptionsChange={getDealRequests}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            idle: { message: t("common.searchTableIdleState") },
            loading: { message: t("common.searchTableLoadingState") },
          }}
          paginationOption={{
            itemsPerPage: store.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          filterOption={{
            initialFilterValue: store.filterOptions,
            filterComponent(filter, setFilter): React.ReactElement {
              return (
                <FDDealRequestsSearchFilter
                  filter={filter}
                  setFilter={setFilter}
                />
              );
            },
          }}
          onRowClick={(_, index): void => {
            const selectedRequestId = store.requests[index].requestId;
            navigate(
              getPath(
                Module.Fd,
                Route.ManageDealRequestDetailsWithParams,
                selectedRequestId.toString(),
              ),
            );
          }}
        />
      </Box>
    </Stack>
  );
});
