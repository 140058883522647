import { getCellValueFromCellType } from "./DetailCellUtils";
import { SummaryDetailModel } from "../models/SummaryDetailModel";
import { Instance } from "mobx-state-tree";
import { DataSectionItem } from "../components/section/DataSection";
import i18next from "i18next";

export const getSummaryCell = (
  item: Instance<typeof SummaryDetailModel>,
): DataSectionItem => {
  const cellData = getCellValueFromCellType(item.summary.cellType);
  if (item.detailList.length > 0) {
    return {
      // @ts-ignore
      label: i18next.t(item.summary.label),
      value: item.detailList.map((detail) => {
        const detailCellData = getCellValueFromCellType(detail.cellType);
        return {
          // @ts-ignore
          label: i18next.t(detail.label, {
            val: detailCellData.currency?.symbol,
          }),
          value: detailCellData.displayValue,
          isSummary: false,
          isBold: item.isBold ?? false,
        };
      }),
    };
  } else {
    return {
      // @ts-ignore
      label: i18next.t(item.summary.label, { val: cellData.currency?.symbol }),
      value: cellData.displayValue,
      isSummary: item.isShownInCollapsedView ?? false,
      isBold: item.isBold ?? false,
    };
  }
};
