import { Instance, types } from "mobx-state-tree";
import {
  FixedDepositInvestmentRegisterResponse,
  FixedDepositType,
} from "@khazana/khazana-rpcs";
import { getAmountOrUndefined } from "../../../../../utils";
import { getStatusString } from "../utils/ReportUtils";

export const FdInvestmentRegisterSummaryModel = types.model(
  "FdInvestmentRegisterSummaryModel",
  {
    investmentId: types.string,
    depositNumber: types.maybe(types.string),
    bankName: types.maybe(types.string),
    openingAmount: types.maybe(types.number),
    purchaseAmount: types.maybe(types.number),
    closingAmount: types.maybe(types.number),
    fdType: types.maybe(
      types.enumeration<FixedDepositType.FixedDepositType>(
        "FixedDepositType",
        Object.values(FixedDepositType.FixedDepositType),
      ),
    ),
    daysInHolding: types.maybe(types.number),
    interestRate: types.maybe(types.number),
    maturityAmount: types.maybe(types.number),
    startDate: types.maybe(types.string),
    maturityDate: types.maybe(types.string),
    isCallable: types.maybe(types.boolean),
    status: types.maybe(types.string),
    openingTds: types.maybe(types.number),
    openingTaxes: types.maybe(types.number),
    interestReceived: types.maybe(types.number),
    tdsPaid: types.maybe(types.number),
    otherTaxesPaid: types.maybe(types.number),
    netInterestReceived: types.maybe(types.number),
    interestAccrued: types.maybe(types.number),
    tdsOnInterestAccrued: types.maybe(types.number),
    otherTaxesOnInterestAccrued: types.maybe(types.number),
    netInterestReceivable: types.maybe(types.number),
  },
);

export const createFdInvestmentRegisterSummaryModel = (
  response: FixedDepositInvestmentRegisterResponse,
): Instance<typeof FdInvestmentRegisterSummaryModel> => {
  return FdInvestmentRegisterSummaryModel.create({
    investmentId: response.investmentId,
    depositNumber: response.depositNumber ?? undefined,
    bankName: response.bank ?? undefined,
    openingAmount: getAmountOrUndefined(response.openingAmount),
    purchaseAmount: getAmountOrUndefined(response.purchaseAmount),
    closingAmount: getAmountOrUndefined(response.closingAmount),
    fdType: response.fdType ?? undefined,
    daysInHolding: response.days ?? undefined,
    interestRate: getAmountOrUndefined(response.interestRate),
    maturityAmount: getAmountOrUndefined(response.maturedFDAmount),
    startDate: response.startDate?.date,
    maturityDate: response.maturityDate?.date,
    isCallable: response.isCallable ?? undefined,
    openingTds: getAmountOrUndefined(response.openingTds),
    openingTaxes: getAmountOrUndefined(response.openingTaxes),
    interestReceived: getAmountOrUndefined(response.interestReceived),
    tdsPaid: getAmountOrUndefined(response.tdsPaid),
    otherTaxesPaid: getAmountOrUndefined(response.otherTaxesPaid),
    netInterestReceived: getAmountOrUndefined(response.netInterestReceived),
    interestAccrued: getAmountOrUndefined(response.interestAccrued),
    tdsOnInterestAccrued: getAmountOrUndefined(response.tdsOnInterestAccrued),
    otherTaxesOnInterestAccrued: getAmountOrUndefined(
      response.otherTaxesOnInterestAccrued,
    ),
    netInterestReceivable: getAmountOrUndefined(response.netInterestReceivable),
    status: getStatusString(response.status),
  });
};
