import { Stack, Typography } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { Button, useTypography } from "@surya-digital/leo-reactjs-material-ui";
import React from "react";
import { useTranslation } from "react-i18next";

interface ErrorComponentProps {
  title: string;
  description: string;
  errorImage: React.ReactElement;
  onGoToHomeClick: () => void;
}

const Size = {
  container: "220px",
};

export const ErrorComponent = ({
  title,
  description,
  errorImage,
  onGoToHomeClick,
}: ErrorComponentProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const { t } = useTranslation();

  return (
    <Stack
      height={Size.container}
      justifyContent="center"
      alignItems="center"
      spacing={spacing.spaceSM}
    >
      <Stack>{errorImage}</Stack>
      <Typography
        sx={{
          ...typography.sh3,
          color: tokens.label,
          mt: spacing.spaceSM,
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          ...typography.b2,
          color: tokens.label,
          mb: spacing.spaceSM,
        }}
      >
        {description}
      </Typography>
      <Button
        name="GoToHome"
        size="small"
        label={t("errors.goToHome")}
        variant="outlined-color"
        onClick={onGoToHomeClick}
      />
    </Stack>
  );
};
