import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { PageHeader, useSpacing } from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { ModuleType } from "@khazana/khazana-rpcs";
import { BorderStyle, useBorder } from "../../../../../utils/BorderUtils";
import {
  FoundationColorTokens,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { EntityDropdown } from "../../../components/entity/EntityDropdown";
import { useCreateFDDealRequestStore } from "../store/hooks";
import { PortfolioDropdown } from "../../../components/portfolio/PortfolioDropdown";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { AmountTextField } from "../../../components/AmountTextField";
import { DepositDuration } from "../components/DepositDuration";
import { DepositDetails } from "../components/DepositDetails";
import { CornerRadius } from "@surya-digital/leo-reactjs-core/dist/theme/cornerRadius";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import {
  getFrequencyPlaceholder,
  getFrequencyTitle,
  getFrequencyTypeOptions,
  getInterestTypeOptions,
} from "../utils/UIUtils";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { FormGroup } from "../../../components/form/FormGroup";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import { HeaderButton } from "../../../components/page-header/HeaderButton";
import { BankBranchAsyncAutocomplete } from "../../../components/bank-branch/BankBranchAsyncAutoComplete";
import { DatePickerFieldSeparateLabel } from "../../../components/date-picker/DatePickerFieldSeparateLabel";
import { DATE_PICKER_FORMAT } from "../../../../../utils";

const CreateFDDealRequestStyle = (
  border: BorderStyle,
  tokens: FoundationColorTokens<string>,
  cornerRadius: CornerRadius,
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    rootContainerStyle: {
      justifyContent: "center",
      padding: spacing.space2XL,
      gap: spacing.spaceXL,
      width: "calc(100vw - 256px)",
      overflow: "auto",
    },
    formContainerStyle: {
      border: border.default,
      background: tokens.background,
      borderRadius: cornerRadius.radiusXS,
      padding: spacing.spaceXL,
      gap: spacing.spaceXL,
      maxWidth: "658px",
    },
    branchAutocomplete: {
      width: "610px",
    },
  };
};

export const CreateFDDealRequest = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const border = useBorder();
  const tokens = useFoundationColorTokens();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const store = useCreateFDDealRequestStore();
  const entityDropdownStore = store.entityDropdownStore;
  const portfolioDropdownStore = store.portfolioDropdownStore;
  const bankAccountDropdownStore = store.bankAccountDropdownStore;
  const depositBankBranchDropdownStore =
    store.depositBankBranchAutocompleteStore;
  const errorStore = store.errorStore;
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const style = useMemo(
    () => CreateFDDealRequestStyle(border, tokens, cornerRadius, spacing),
    [],
  );

  useEffect(() => {
    entityDropdownStore.getEntityList();
    bankAccountDropdownStore.getAccountList();
    return () => {
      store.reset();
    };
  }, []);

  const getDialog = (): React.ReactElement => {
    return (
      <TextFieldDialog
        title={t("fd.dealRequest.createDealPageTitle")}
        onClose={(): void => {
          setIsDialogOpen(false);
        }}
        isOpen={isDialogOpen}
        primaryButtonType={"Create"}
        onPrimaryButtonClick={async (
          note: string | undefined,
        ): Promise<void> => {
          setIsDialogLoading(true);
          store.setNote(note);
          const requestId = await store.onSubmit();
          if (requestId) {
            navigate(
              getPath(
                Module.Fd,
                Route.ManageDealRequestDetailsWithParams,
                requestId.toString(),
              ),
            );
          }
          setIsDialogOpen(false);
          setIsDialogLoading(false);
        }}
        isCloseIconPresent={!isDialogLoading}
      />
    );
  };

  return (
    <Stack>
      <PageHeader
        title={t("fd.dealRequest.createDealPageTitle")}
        actionElement={
          <HeaderButton
            label={t("fd.dealRequest.createRequestButtonTitle")}
            onClick={async (): Promise<void> => {
              if (store.isValid()) {
                setIsDialogOpen(true);
              }
            }}
            buttonType="filled"
          />
        }
      />
      <Stack direction={"row"} sx={style.rootContainerStyle}>
        <Stack sx={style.formContainerStyle}>
          <FormGroup title={t("fd.formGroupHeaders.basicDetailsTitle")}>
            <EntityDropdown
              store={entityDropdownStore}
              isRequired
              error={errorStore.entityError}
              onChange={() => {
                errorStore.setEntityError(undefined);
                portfolioDropdownStore.deselectPortfolio();
                portfolioDropdownStore.clearList();
                if (entityDropdownStore.selectedEntity) {
                  portfolioDropdownStore.getPortfolioList(
                    entityDropdownStore.selectedEntity,
                    ModuleType.ModuleType.FIXED_DEPOSIT,
                  );
                }
              }}
            />
            <PortfolioDropdown
              store={portfolioDropdownStore}
              isRequired
              error={errorStore.portfolioError}
              isDisabled={!entityDropdownStore.selectedEntity}
              onChange={() => {
                errorStore.setPortfolioError(undefined);
              }}
            />
            <DropdownInputFieldSeparateLabel
              name={"paymentBankAccount"}
              label={t("fd.fields.paymentBankAccountLabel")}
              placeholder={t("fd.fields.paymentBankAccountPlaceholder")}
              isRequired
              value={
                bankAccountDropdownStore.selectedBankAccount?.bankAccountNumber
              }
              error={errorStore.paymentBankAccountError !== undefined}
              helperText={errorStore.paymentBankAccountError}
              options={bankAccountDropdownStore.bankAccountList.map(
                (account) => {
                  return {
                    name: account.bankAccountNumber,
                    value: account.bankAccountNumber,
                  };
                },
              )}
              isLoading={bankAccountDropdownStore.isLoading}
              loadingText={t("common.loading")}
              onSelect={(value) => {
                errorStore.setPaymentBankAccountError(undefined);
                bankAccountDropdownStore.setSelectedAccount(value.value);
              }}
            />
            <AmountTextField
              name={"depositAmount"}
              label={t("fd.fields.depositAmountLabel", {
                currencySymbol: store.currencySymbol,
              })}
              placeholder={t("fd.fields.depositAmountPlaceholder")}
              isRequired
              error={errorStore.depositAmountError !== undefined}
              helperText={errorStore.depositAmountError}
              value={store.depositAmount}
              onAmountChange={(amount) => {
                errorStore.setDepositAmountError(undefined);
                store.setDepositAmount(amount);
              }}
              isDecimalAllowed={true}
            />
          </FormGroup>
          <FormGroup title={t("fd.formGroupHeaders.depositDetailsTitle")}>
            <DatePickerFieldSeparateLabel
              label={t("fd.fields.valueDateLabel")}
              value={store.valueDate}
              isRequired
              error={errorStore.valueDateError !== undefined}
              helperText={errorStore.valueDateError}
              onChange={(date) => {
                errorStore.setValueDateError(undefined);
                store.setValueDate(date);
              }}
              onError={() => {
                errorStore.setValueDateError("");
              }}
              format={DATE_PICKER_FORMAT}
            />
            <DepositDuration
              depositDuration={store.depositDuration}
              onDurationChange={(duration) => {
                errorStore.setDepositDurationError(undefined);
                store.setDepositDuration(duration);
              }}
              error={errorStore.depositDurationError}
              depositUnit={store.depositDurationUnit}
              onDepositUnitChange={(unit) => {
                errorStore.setDepositDurationError(undefined);
                store.setDepositDurationUnit(unit);
              }}
            />
            <BankBranchAsyncAutocomplete
              label={t("fd.fields.depositBranchLabel")}
              placeholder={t("fd.fields.depositBankBranchPlaceholder")}
              isRequired
              error={errorStore.depositBranchError !== undefined}
              helperText={errorStore.depositBranchError}
              width={style.branchAutocomplete.width as string}
              store={depositBankBranchDropdownStore}
            />
          </FormGroup>
          <FormGroup title={t("fd.formGroupHeaders.depositDetailsTitle")}>
            <DropdownInputFieldSeparateLabel
              name={"interestType"}
              label={t("fd.fields.interestTypeLabel")}
              placeholder={t("fd.fields.interestTypePlaceholder")}
              isRequired
              error={errorStore.interestTypeError !== undefined}
              helperText={errorStore.interestTypeError}
              value={store.interestType}
              options={getInterestTypeOptions()}
              onSelect={(item) => {
                errorStore.setInterestTypeError(undefined);
                store.setInterestType(item.value);
              }}
            />
            <DropdownInputFieldSeparateLabel
              name={"frequency"}
              label={getFrequencyTitle(store.interestType)}
              placeholder={getFrequencyPlaceholder(store.interestType)}
              isRequired
              error={errorStore.frequencyError !== undefined}
              helperText={errorStore.frequencyError}
              isDisabled={!store.interestType}
              value={store.frequency}
              options={getFrequencyTypeOptions(store.interestType)}
              onSelect={(item) => {
                errorStore.setFrequencyError(undefined);
                store.setFrequency(item.value);
              }}
            />
            <AmountTextField
              name={"rateOfInterest"}
              label={t("fd.fields.rateOfInterestLabel")}
              placeholder={t("fd.fields.rateOfInterestPlaceholder")}
              isRequired
              error={errorStore.rateOfInterestError !== undefined}
              helperText={errorStore.rateOfInterestError}
              value={store.interestRate}
              onAmountChange={(value) => {
                errorStore.setRateOfInterestError(undefined);
                store.setInterestRate(value);
              }}
              isDecimalAllowed={true}
              maxLimit={100}
            />
          </FormGroup>
          {isDialogOpen && getDialog()}
        </Stack>
        <DepositDetails />
      </Stack>
    </Stack>
  );
});
