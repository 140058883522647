import { ImpactChart, ImpactTable } from "@khazana/khazana-rpcs";
import { Instance, types } from "mobx-state-tree";

export const EqImpactChartBuyModel = types.model("EqImpactChartBuyModel", {
  requested: types.number,
  existing: types.number,
  otherStocks: types.number,
});

export const EqImpactChartSellModel = types.model("EqImpactChartSellModel", {
  existing: types.number,
  otherStocksWithExisting: types.number,
  requested: types.number,
  otherStocksWithRequested: types.number,
});

const EqImpactChartModel = types.model("EqImpactChartModel", {
  // TODO :- Make a single model for both buy and sell with pipe (||) operator
  impactChartBuyCase: types.maybeNull(EqImpactChartBuyModel),
  impactChartSellCase: types.maybeNull(EqImpactChartSellModel),
});

export const EqImpactTableModel = types.model("EqImpactTableModel", {
  currentUnits: types.number,
  unitsAfterRequest: types.number,
  currentPercentage: types.number,
  percentageAfterRequest: types.number,
  currentVar: types.number,
  varAfterRequest: types.number,
});

const EqPortfolioHoldingImpactDetails = types.model(
  "EqPortfolioHoldingImpactDetails",
  {
    impactTable: EqImpactTableModel,
    impactChart: EqImpactChartModel,
  },
);

export const EquityDealRequestHistoryAdditionalDetailModel = types.model(
  "EquityDealRequestHistoryAdditionalDetailModel",
  {
    eqPortfolioHoldingImpactDetails: EqPortfolioHoldingImpactDetails,
    //   TODO :- TRANSACTION_DETAILS & ACCOUNT_DETAILS
  },
);

const createEqImpactChartModel = (
  impactChartValues: ImpactChart.ImpactChart,
): Instance<typeof EqImpactChartModel> => {
  if (impactChartValues instanceof ImpactChart.Buy) {
    return EqImpactChartModel.create({
      impactChartBuyCase: {
        existing: impactChartValues.existing,
        otherStocks: impactChartValues.otherStocks,
        requested: impactChartValues.requested,
      },
    });
  } else if (impactChartValues instanceof ImpactChart.Sell) {
    return EqImpactChartModel.create({
      impactChartSellCase: {
        existing: impactChartValues.existing,
        otherStocksWithExisting: impactChartValues.otherStocksWithExisting,
        otherStocksWithRequested: impactChartValues.otherStocksWithRequested,
        requested: impactChartValues.requested,
      },
    });
  }

  // This statement will not execute, it is only added for type safety.
  return EqImpactChartModel.create({});
};

export const createEqImpactTableModel = (
  impactTableValues: ImpactTable,
): Instance<typeof EqImpactTableModel> => {
  return EqImpactTableModel.create({
    currentPercentage: impactTableValues.currentPercentage,
    currentUnits: impactTableValues.currentUnits,
    currentVar: impactTableValues.currentVar,
    percentageAfterRequest: impactTableValues.percentageAfterRequest,
    unitsAfterRequest: impactTableValues.unitsAfterRequest,
    varAfterRequest: impactTableValues.varAfterRequest,
  });
};

export const createEquityDealRequestHistoryAdditionalDetailModel = (
  impactTable: ImpactTable,
  impactChart: ImpactChart.ImpactChart,
): Instance<typeof EquityDealRequestHistoryAdditionalDetailModel> => {
  return EquityDealRequestHistoryAdditionalDetailModel.create({
    eqPortfolioHoldingImpactDetails: EqPortfolioHoldingImpactDetails.create({
      impactTable: createEqImpactTableModel(impactTable),
      impactChart: createEqImpactChartModel(impactChart),
    }),
  });
};
