import { GetMFFolioNumbersRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFFolioNumbersRPCImpl extends GetMFFolioNumbersRPC {
  execute(
    _request: GetMFFolioNumbersRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFFolioNumbersRPC.Response,
      GetMFFolioNumbersRPC.Errors.Errors
    >
  > {
    const response = new GetMFFolioNumbersRPC.Response([
      "ADI123456",
      "AXI123456",
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFFolioNumbersRPC.Response,
        GetMFFolioNumbersRPC.Errors.Errors
      >
    >;
  }
}
