import { Instance, castToSnapshot, types } from "mobx-state-tree";
import {
  MfHoldingSummaryStore,
  createMFHoldingSummaryStore,
} from "./MfHoldingSummaryStore";
import {
  MfInvestmentReturnsGrowthStore,
  createMFInvestmentReturnsGrowthStore,
} from "./MfInvestmentReturnsGrowthStore";

export const MfReportsStore = types.model("MfReportsStore", {
  mfHoldingSummaryStore: MfHoldingSummaryStore,
  mfInvestmentRetunsGrowthStore: MfInvestmentReturnsGrowthStore,
});

export const createMfReportsStore = (): Instance<typeof MfReportsStore> => {
  return MfReportsStore.create({
    mfHoldingSummaryStore: castToSnapshot(createMFHoldingSummaryStore()),
    mfInvestmentRetunsGrowthStore: castToSnapshot(
      createMFInvestmentReturnsGrowthStore(),
    ),
  });
};
