"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiBrokerStatusType = exports.InvalidFiBrokerStatusTypeError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiAmountQuantity_1 = require("./fiAmountQuantity");
class InvalidFiBrokerStatusTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiBrokerStatusTypeError";
    }
}
exports.InvalidFiBrokerStatusTypeError = InvalidFiBrokerStatusTypeError;
var FiBrokerStatusType;
(function (FiBrokerStatusType) {
    class Linked {
        constructor(executedAmountQuantity, contractNoteNumber, fiContractNoteId) {
            this.isLinked = true;
            Linked.validateExecutedAmountQuantity(executedAmountQuantity);
            this.executedAmountQuantity = executedAmountQuantity;
            Linked.validateContractNoteNumber(contractNoteNumber);
            this.contractNoteNumber = contractNoteNumber;
            Linked.validateFiContractNoteId(fiContractNoteId);
            this.fiContractNoteId = fiContractNoteId;
        }
        static fromDTO(dto) {
            const executedAmountQuantity = fiAmountQuantity_1.FiAmountQuantity.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "executedAmountQuantity", InvalidFiBrokerStatusTypeError));
            const contractNoteNumber = (0, leo_ts_runtime_1.getString)(dto, "contractNoteNumber", InvalidFiBrokerStatusTypeError);
            const fiContractNoteId = (0, leo_ts_runtime_1.getInt64)(dto, "fiContractNoteId", InvalidFiBrokerStatusTypeError);
            return new Linked(executedAmountQuantity, contractNoteNumber, fiContractNoteId);
        }
        toDTO() {
            const ret = {
                case: "LINKED",
                executedAmountQuantity: this.executedAmountQuantity.toDTO(),
                contractNoteNumber: this.contractNoteNumber,
                fiContractNoteId: this.fiContractNoteId,
            };
            return ret;
        }
        copy(executedAmountQuantity = this.executedAmountQuantity, contractNoteNumber = this.contractNoteNumber, fiContractNoteId = this.fiContractNoteId) {
            return new Linked(executedAmountQuantity, contractNoteNumber, fiContractNoteId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isLinkedValue = other.isLinked;
            if (!(other instanceof Linked) || Boolean(isLinkedValue)) {
                return false;
            }
            if (!this.executedAmountQuantity.equals(other.executedAmountQuantity)) {
                return false;
            }
            if (this.contractNoteNumber !== other.contractNoteNumber) {
                return false;
            }
            if (this.fiContractNoteId !== other.fiContractNoteId) {
                return false;
            }
            return true;
        }
    }
    Linked.validateExecutedAmountQuantity = (executedAmountQuantity) => {
        if (!fiAmountQuantity_1.FiAmountQuantity.isInstanceOf(executedAmountQuantity)) {
            throw new InvalidFiBrokerStatusTypeError(`Attribute executedAmountQuantity is not a FiAmountQuantity.FiAmountQuantity`);
        }
    };
    Linked.validateContractNoteNumber = (contractNoteNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(contractNoteNumber)) {
            throw new InvalidFiBrokerStatusTypeError(`Attribute contractNoteNumber is not a String`);
        }
        if (contractNoteNumber.trim().length === 0) {
            throw new InvalidFiBrokerStatusTypeError(`Attribute contractNoteNumber cannot be an empty string.`);
        }
    };
    Linked.validateFiContractNoteId = (fiContractNoteId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(fiContractNoteId)) {
            throw new InvalidFiBrokerStatusTypeError(`Attribute fiContractNoteId is not an Int64`);
        }
    };
    FiBrokerStatusType.Linked = Linked;
    Linked.prototype.toString = function toString() {
        return `FiBrokerStatusType.LINKED(executedAmountQuantity=${this.executedAmountQuantity},contractNoteNumber='${this.contractNoteNumber}',fiContractNoteId=${this.fiContractNoteId})`;
    };
    class LinkingPending {
        constructor() {
            this.isLinkingPending = true;
        }
        static fromDTO(dto) {
            return new LinkingPending();
        }
        toDTO() {
            const ret = {
                case: "LINKING_PENDING",
            };
            return ret;
        }
        copy() {
            return new LinkingPending();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isLinkingPendingValue = other.isLinkingPending;
            if (!(other instanceof LinkingPending) || Boolean(isLinkingPendingValue)) {
                return false;
            }
            return true;
        }
    }
    FiBrokerStatusType.LinkingPending = LinkingPending;
    LinkingPending.prototype.toString = function toString() {
        return `FiBrokerStatusType.LINKING_PENDING()`;
    };
    FiBrokerStatusType.fromDTO = (dto) => {
        switch (dto.case) {
            case "LINKED":
                return Linked.fromDTO(dto);
            case "LINKING_PENDING":
                return LinkingPending.fromDTO(dto);
            default:
                throw new InvalidFiBrokerStatusTypeError(`Case ${dto.case} is not valid case of FiBrokerStatusType.`);
        }
    };
    FiBrokerStatusType.isInstanceOf = (other) => {
        const isLINKEDValue = other.isLINKED;
        if (other instanceof Linked || Boolean(isLINKEDValue)) {
            return true;
        }
        const isLINKING_PENDINGValue = other.isLINKING_PENDING;
        if (other instanceof LinkingPending || Boolean(isLINKING_PENDINGValue)) {
            return true;
        }
        return false;
    };
})(FiBrokerStatusType = exports.FiBrokerStatusType || (exports.FiBrokerStatusType = {}));
