import { Instance } from "mobx-state-tree";
import { useFDDealRequestDetailsStore } from "../hooks";
import { FDWithdrawStore } from "./FDWithdrawStore";
import { FDWithdrawListStore } from "./FDWithdrawListStore";
import { CreateWithdrawRequestStore } from "./CreateWithdrawRequestStore";
import { ConfirmWithdrawRequestStore } from "./ConfirmWithdrawRequestStore";

export const useFDWithdrawStore = (): Instance<typeof FDWithdrawStore> => {
  return useFDDealRequestDetailsStore().withdrawStore;
};

export const useFDWithdrawListStore = (): Instance<
  typeof FDWithdrawListStore
> => {
  return useFDWithdrawStore().list;
};

export const useCreateWithdrawRequestStore = (): Instance<
  typeof CreateWithdrawRequestStore
> => {
  return useFDWithdrawStore().createRequest;
};

export const useConfirmWithdrawRequestStore = (): Instance<
  typeof ConfirmWithdrawRequestStore
> => {
  return useFDWithdrawStore().confirmRequest;
};
