import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Stack } from "@mui/material";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { FDCertificateEdit } from "../components/FDCertificateEdit";
import { FdUserPrivileges } from "../../../../user/UserPrivileges";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { useUserStore } from "../../../store/hooks";
import { TextFieldDialog } from "../../../components/TextFieldDialog";
import {
  FDCertificateDetailsErrors,
  FDDealInvalidRequestError,
  FDSubmitCertificateDetailsErrors,
} from "../store/FDDealRequestErrors";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { HeaderButton } from "../../../components/page-header/HeaderButton";

const CreateDepositCertificateEditPageStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    body: {
      gap: spacing.space2XL,
      padding: spacing.space2XL,
      minWidth: "calc(100vw - 256px)",
    },
  };
};
export const DepositCertificateEditPage = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const spacing = useSpacing();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const requestId = Number(searchParam.get("requestId"));
  const certificateId = searchParam.get("certificateId");
  const detailsStore = useFDDealRequestDetailsStore();
  const certificateStore = detailsStore.certificateStore;
  const privileges = useUserStore().privileges;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);

  const style = useMemo(
    () => CreateDepositCertificateEditPageStyle(spacing),
    [],
  );

  const getErrorDialogText = (): string => {
    switch (certificateStore.error) {
      case FDDealInvalidRequestError.InvalidRequestId:
      case FDCertificateDetailsErrors.InvalidCertificateId:
        return t("common.noResultsFound");
      case FDSubmitCertificateDetailsErrors.FieldError:
        return "";
      default:
        return t("common.somethingWentWrong");
    }
  };

  const navigateToDealDetails = (): void => {
    navigate(
      {
        pathname: getPath(Module.Fd, Route.ManageDealRequestDetails),
        search: createSearchParams({
          requestId: requestId.toString(),
        }).toString(),
      },
      { replace: true },
    );
  };

  const navigateToCertificateDetails = (): void => {
    if (certificateId) {
      navigate(
        {
          pathname: getPath(
            Module.Fd,
            Route.ManageDealRequestCertificateDetails,
          ),
          search: createSearchParams({
            requestId: requestId.toString(),
            certificateId,
          }).toString(),
        },
        { replace: true },
      );
    }
  };

  const getData = useCallback(() => {
    if (privileges.includes(FdUserPrivileges.ViewFixedDepositCertificate)) {
      certificateStore?.getFDCertificateList(requestId);
      if (certificateId) {
        certificateStore.getFDCertificateDetails(
          requestId,
          Number(certificateId),
        );
      }
    }
    detailsStore.getFdDealDetails(requestId).then(() => {
      if (!certificateId) {
        detailsStore.createCertificateEditModel();
      }
    });
  }, []);

  useEffect(() => {
    getData();
    return (): void => {
      detailsStore.reset();
    };
  }, []);

  const getPageHeaderButtons = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.submit")}
        onClick={async (): Promise<void> => {
          if (certificateStore.editCertificateDetail?.isValid()) {
            setIsDialogOpen(true);
          }
        }}
        buttonType="filled"
      />
    );
  };

  const getBreadcrumbLinks = (): {
    label: string;
    onLabelClick: () => void;
  }[] => {
    const links = [
      {
        label: t("common.manageDealRequests"),
        onLabelClick: (): void => {
          navigate(getPath(Module.Fd, Route.ManageDealRequest), {
            replace: true,
          });
        },
      },
      {
        label: t("fd.dealDetails.pageTitle"),
        onLabelClick: (): void => {
          navigateToDealDetails();
        },
      },
    ];
    if (certificateId) {
      links.push({
        label: t("fd.depositCertificate.viewPageTitle"),
        onLabelClick: (): void => {
          navigateToCertificateDetails();
        },
      });
    }
    return links;
  };

  return (
    <Stack>
      <PageHeader
        title={t("fd.depositCertificate.editPageTitle")}
        actionElement={getPageHeaderButtons()}
      />
      <Stack sx={style.body}>
        <Breadcrumb
          links={getBreadcrumbLinks()}
          currentLabel={t("fd.depositCertificate.editPageTitle")}
        />
        {detailsStore.isLoading ? (
          <LoadingIndicator isLoading={detailsStore.isLoading} />
        ) : (
          <FDCertificateEdit
            onCancel={() => {
              if (certificateId) {
                navigateToCertificateDetails();
              } else {
                navigateToDealDetails();
              }
            }}
          />
        )}
      </Stack>
      {isDialogOpen && (
        <TextFieldDialog
          title={t("fd.dealDetails.confirmDepositDetailsTitle")}
          onClose={(): void => {
            setIsDialogOpen(false);
          }}
          isOpen={isDialogOpen}
          primaryButtonType={"Submit"}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsDialogLoading(true);
            await certificateStore?.submitFDCertificateDetails(
              Number(requestId),
              note,
            );
            setIsDialogOpen(false);
            setIsDialogLoading(false);
            if (certificateStore.error === null) {
              if (certificateId) {
                navigateToCertificateDetails();
              } else {
                navigateToDealDetails();
              }
            }
          }}
          isCloseIconPresent={!isDialogLoading}
        />
      )}
      {certificateStore.error !== null &&
        certificateStore.error !==
          FDSubmitCertificateDetailsErrors.FieldError && (
          <ErrorDialog
            errorMessage={getErrorDialogText()}
            isErrorDialogOpen={certificateStore.error !== null}
            onClose={(): void => {
              if (
                certificateStore.error ===
                  FDDealInvalidRequestError.InvalidRequestId ||
                certificateStore.error ===
                  FDCertificateDetailsErrors.InvalidCertificateId
              ) {
                navigate(-1);
              }
              certificateStore.clearError();
            }}
          />
        )}
    </Stack>
  );
});
