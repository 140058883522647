import {
  GetFixedDepositHoldingSummarySortOrder,
  GetFixedDepositInvestmentRegisterSortOrder,
  ReportsSortOrder,
} from "@khazana/khazana-rpcs";
import { GetFixedDepositHoldingSummarySortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getFixedDepositHoldingSummarySortOrder";
import { GetFixedDepositInvestmentRegisterSortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getFixedDepositInvestmentRegisterSortOrder";
import { TableSortOption } from "@surya-digital/leo-reactjs-core";
import i18next from "i18next";

export const getInvestmentRegisterSummarySortOrder = (
  sort?: TableSortOption,
): GetFixedDepositInvestmentRegisterSortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
      .INVESTMENT_ID;

  switch (sort?.id) {
    case i18next.t("fd.reports.investmentRegisterHeaders.investmentId"):
      column =
        GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .INVESTMENT_ID;
      break;
    case i18next.t("fd.reports.investmentRegisterHeaders.bank"):
      column =
        GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .BANK_NAME;
      break;
    case i18next.t("fd.reports.investmentRegisterHeaders.openingAmount"):
      column =
        GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .OPENING_AMOUNT;
      break;
    case i18next.t("fd.reports.investmentRegisterHeaders.purchaseAmount"):
      column =
        GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .PURCHASE_AMOUNT;
      break;
    case i18next.t("fd.reports.investmentRegisterHeaders.closingAmount"):
      column =
        GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .CLOSING_AMOUNT;
      break;
    case i18next.t("fd.reports.investmentRegisterHeaders.startDate"):
      column =
        GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .START_DATE;
      break;
    case i18next.t("fd.reports.investmentRegisterHeaders.maturityDate"):
      column =
        GetFixedDepositInvestmentRegisterSortOrderEnums.ColumnName.ColumnName
          .MATURITY_DATE;
      break;
  }

  return new GetFixedDepositInvestmentRegisterSortOrder(column, sortOrder);
};

export const getHoldingSummarySortOrder = (
  sort?: TableSortOption,
): GetFixedDepositHoldingSummarySortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.ColumnName.BANK_NAME;

  switch (sort?.id) {
    case i18next.t("fd.reports.holdingSummaryHeaders.bank"):
      column =
        GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .BANK_NAME;
      break;
    case i18next.t("fd.reports.holdingSummaryHeaders.openingAmount"):
      column =
        GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .OPENING_AMOUNT;
      break;
    case i18next.t("fd.reports.holdingSummaryHeaders.purchaseAmount"):
      column =
        GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .PURCHASE_AMOUNT;
      break;
    case i18next.t("fd.reports.holdingSummaryHeaders.closingAmount"):
      column =
        GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.ColumnName
          .CLOSING_AMOUNT;
      break;
  }

  return new GetFixedDepositHoldingSummarySortOrder(column, sortOrder);
};
