"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFixedIncomeHoldingSummarySortOrder = exports.GetFixedIncomeHoldingSummarySortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const reportsSortOrder_1 = require("./reportsSortOrder");
class InvalidGetFixedIncomeHoldingSummarySortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFixedIncomeHoldingSummarySortOrderError";
    }
}
var GetFixedIncomeHoldingSummarySortOrderEnums;
(function (GetFixedIncomeHoldingSummarySortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["SECURITY_NAME"] = "SECURITY_NAME";
            ColumnName["INSTRUMENT_TYPE"] = "INSTRUMENT_TYPE";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "SECURITY_NAME":
                    return ColumnName.SECURITY_NAME;
                case "INSTRUMENT_TYPE":
                    return ColumnName.INSTRUMENT_TYPE;
                default:
                    throw new InvalidGetFixedIncomeHoldingSummarySortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName || (GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFixedIncomeHoldingSummarySortOrderEnums = exports.GetFixedIncomeHoldingSummarySortOrderEnums || (exports.GetFixedIncomeHoldingSummarySortOrderEnums = {}));
class GetFixedIncomeHoldingSummarySortOrder {
    constructor(columnName, order) {
        this.isGetFixedIncomeHoldingSummarySortOrder = true;
        GetFixedIncomeHoldingSummarySortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFixedIncomeHoldingSummarySortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const columnName = GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFixedIncomeHoldingSummarySortOrderError));
        const order = reportsSortOrder_1.ReportsSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFixedIncomeHoldingSummarySortOrderError));
        return new GetFixedIncomeHoldingSummarySortOrder(columnName, order);
    }
    toDTO() {
        const ret = {
            columnName: GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName.toDTO(this.columnName),
            order: reportsSortOrder_1.ReportsSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(columnName = this.columnName, order = this.order) {
        return new GetFixedIncomeHoldingSummarySortOrder(columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFixedIncomeHoldingSummarySortOrderValue = other.isGetFixedIncomeHoldingSummarySortOrder;
        if (!(other instanceof GetFixedIncomeHoldingSummarySortOrder) || Boolean(isGetFixedIncomeHoldingSummarySortOrderValue)) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFixedIncomeHoldingSummarySortOrder = GetFixedIncomeHoldingSummarySortOrder;
GetFixedIncomeHoldingSummarySortOrder.validateColumnName = (columnName) => {
    if (!GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFixedIncomeHoldingSummarySortOrderError(`Attribute columnName is not a GetFixedIncomeHoldingSummarySortOrderEnums.ColumnName`);
    }
};
GetFixedIncomeHoldingSummarySortOrder.validateOrder = (order) => {
    if (!reportsSortOrder_1.ReportsSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFixedIncomeHoldingSummarySortOrderError(`Attribute order is not a ReportsSortOrder.ReportsSortOrder`);
    }
};
GetFixedIncomeHoldingSummarySortOrder.prototype.toString = function toString() {
    return `GetFixedIncomeHoldingSummarySortOrder(columnName=${this.columnName},order=${this.order})`;
};
