"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedDepositStatus = exports.InvalidFixedDepositStatusError = void 0;
class InvalidFixedDepositStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedDepositStatusError";
    }
}
exports.InvalidFixedDepositStatusError = InvalidFixedDepositStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedDepositStatus;
(function (FixedDepositStatus_1) {
    let FixedDepositStatus;
    (function (FixedDepositStatus) {
        FixedDepositStatus["ACTIVE"] = "ACTIVE";
        FixedDepositStatus["MATURED"] = "MATURED";
        FixedDepositStatus["WITHDRAWN"] = "WITHDRAWN";
    })(FixedDepositStatus = FixedDepositStatus_1.FixedDepositStatus || (FixedDepositStatus_1.FixedDepositStatus = {}));
    FixedDepositStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "ACTIVE":
                return FixedDepositStatus.ACTIVE;
            case "MATURED":
                return FixedDepositStatus.MATURED;
            case "WITHDRAWN":
                return FixedDepositStatus.WITHDRAWN;
            default:
                throw new InvalidFixedDepositStatusError(`Case ${dto.case} is not valid case of FixedDepositStatus`);
        }
    };
    FixedDepositStatus_1.toDTO = (fixedDepositStatus) => {
        const ret = {
            case: FixedDepositStatus[fixedDepositStatus],
        };
        return ret;
    };
    FixedDepositStatus_1.isInstanceOf = (other) => {
        if (other in FixedDepositStatus) {
            return true;
        }
        return false;
    };
})(FixedDepositStatus = exports.FixedDepositStatus || (exports.FixedDepositStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
