import {
  BankAccount,
  Currency,
  GetBankAccountListRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";

export class MockGetBankAccountListRPCImpl extends GetBankAccountListRPC {
  execute(
    _request: GetBankAccountListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetBankAccountListRPC.Response,
      GetBankAccountListRPC.Errors.Errors
    >
  > {
    const response = new GetBankAccountListRPC.Response([
      new BankAccount("7363130238873", new Currency("INR", "₹")),
      new BankAccount("7323158232870", new Currency("INR", "₹")),
      new BankAccount("7363112238233", new Currency("INR", "₹")),
      new BankAccount("7373130312876", new Currency("INR", "₹")),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetBankAccountListRPC.Response,
        GetBankAccountListRPC.Errors.Errors
      >
    >;
  }
}
