import { types } from "mobx-state-tree";
import { CurrencyModel } from "./CurrencyModel";

export const ActiveAccrualsModel = types.model({
  accrualId: types.number,
  moduleName: types.string,
  correlationId: types.number,
  accrualType: types.string,
  rate: types.string,
  amountCCY: types.string,
  amountHCY: types.string,
  overriddenAmountCCY: types.maybe(types.string),
  overriddenAmountHCY: types.maybe(types.string),
  accrualStartDate: types.Date,
  accrualEndDate: types.Date,
  accuralPostedDate: types.maybe(types.Date),
  isCredited: types.boolean,
  isPosted: types.boolean,
  currency: CurrencyModel,
});
