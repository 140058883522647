import { observer } from "mobx-react";
import React from "react";
import { ActionButtons, Section } from "../../../components/Section";
import { useTranslation } from "react-i18next";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import {
  Table,
  TableHeader,
  TableRowItems,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  getFormattedAmountString,
  getFormattedDate,
} from "../../../../../utils";
import { Stack } from "@mui/material";
import { getFDCertificateStatusValue } from "../utils/UIUtils";

const Size = {
  columnWidth: "236px",
};

export interface DepositCertificateTableProps {
  onEditCertificate: (certificateId: number) => void;
  onViewCertificate: (certificateId: number) => void;
}

export const DepositCertificateTable = observer(
  ({
    onEditCertificate,
    onViewCertificate,
  }: DepositCertificateTableProps): React.ReactElement => {
    const { t } = useTranslation();
    const tokens = useFoundationColorTokens();
    const cornerRadius = useCornerRadius();
    const detailsStore = useFDDealRequestDetailsStore();
    const store = detailsStore.certificateStore;

    const getActionButtons = (): ActionButtons => {
      let buttons: ActionButtons = {
        secondaryButton: {
          label: t("fd.depositCertificate.viewCertificateDetailsButtonTitle"),
          onClick: async (): Promise<void> => {
            onViewCertificate(store.certificateList[0].certificateId);
          },
          variant: "plain-color",
        },
      };
      if (detailsStore && detailsStore.requestActions.allowEditCertificate) {
        buttons = {
          ...buttons,
          primaryButton: {
            label: t("fd.depositCertificate.editCertificateDetailsButtonTitle"),
            onClick: async (): Promise<void> => {
              onEditCertificate(store.certificateList[0].certificateId);
            },
            variant: "plain-color",
          },
        };
      }
      return buttons;
    };

    const getTableOptions = async (): Promise<string | TableRowItems> => {
      return (
        store?.certificateList.map((request) => {
          return [
            { data: request.depositBank },
            {
              data: getFormattedAmountString(request.depositAmount ?? 0),
              align: "right",
            },
            {
              data: request.certificateNumber ?? "-",
              align: "right",
            },
            {
              data: getFormattedDate(new Date(request.maturityDate)),
            },
            {
              data: getFDCertificateStatusValue(request.status),
            },
          ];
        }) ?? []
      );
    };

    const getHeaders = (): TableHeader => {
      return [
        {
          id: "depositBank",
          name: t("fd.fields.depositBankLabel"),
          width: Size.columnWidth,
        },
        {
          id: "depositAmount",
          name: t("fd.fields.depositAmountLabel", {
            currencySymbol: `(${store?.currencySymbol})`,
          }),
          width: Size.columnWidth,
          align: "right",
        },
        {
          id: "depositCertificateNumber",
          name: t("fd.fields.depositCertificateNumberLabel"),
          width: Size.columnWidth,
          align: "right",
        },
        {
          id: "maturityDate",
          name: t("fd.fields.maturityDateLabel"),
          width: Size.columnWidth,
        },
        {
          id: "certificateStatus",
          name: t("fd.fields.certificateStatusLabel"),
          width: Size.columnWidth,
        },
      ];
    };

    return (
      <>
        {store?.certificateList && store.certificateList.length > 0 && (
          <Section
            title={t("fd.depositCertificate.sectionTitle")}
            actions={getActionButtons()}
          >
            <Stack width={"100%"}>
              <Table
                name={"certificateTable"}
                headers={getHeaders()}
                onTableOptionsChange={getTableOptions}
                viewOverrides={{
                  empty: { message: t("common.noResultsFound") },
                  idle: { message: t("common.searchTableIdleState") },
                  loading: { message: t("common.searchTableLoadingState") },
                }}
                styleOverrides={{
                  divider: "cell",
                  background: tokens.background,
                  borderRadius: `0 0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS}`,
                  border: "0",
                }}
              />
            </Stack>
          </Section>
        )}
      </>
    );
  },
);
