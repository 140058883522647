"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiCashflowsSortOrder = exports.GetFiCashflowsSortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedIncomeSortOrder_1 = require("./fixedIncomeSortOrder");
class InvalidGetFiCashflowsSortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiCashflowsSortOrderError";
    }
}
var GetFiCashflowsSortOrderEnums;
(function (GetFiCashflowsSortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["CF_DATE"] = "CF_DATE";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "CF_DATE":
                    return ColumnName.CF_DATE;
                default:
                    throw new InvalidGetFiCashflowsSortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFiCashflowsSortOrderEnums.ColumnName || (GetFiCashflowsSortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFiCashflowsSortOrderEnums = exports.GetFiCashflowsSortOrderEnums || (exports.GetFiCashflowsSortOrderEnums = {}));
class GetFiCashflowsSortOrder {
    constructor(sortIndex, columnName, order) {
        this.isGetFiCashflowsSortOrder = true;
        GetFiCashflowsSortOrder.validateSortIndex(sortIndex);
        this.sortIndex = sortIndex;
        GetFiCashflowsSortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFiCashflowsSortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const sortIndex = (0, leo_ts_runtime_1.getInt32)(dto, "sortIndex", InvalidGetFiCashflowsSortOrderError);
        const columnName = GetFiCashflowsSortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFiCashflowsSortOrderError));
        const order = fixedIncomeSortOrder_1.FixedIncomeSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFiCashflowsSortOrderError));
        return new GetFiCashflowsSortOrder(sortIndex, columnName, order);
    }
    toDTO() {
        const ret = {
            sortIndex: this.sortIndex,
            columnName: GetFiCashflowsSortOrderEnums.ColumnName.toDTO(this.columnName),
            order: fixedIncomeSortOrder_1.FixedIncomeSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(sortIndex = this.sortIndex, columnName = this.columnName, order = this.order) {
        return new GetFiCashflowsSortOrder(sortIndex, columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiCashflowsSortOrderValue = other.isGetFiCashflowsSortOrder;
        if (!(other instanceof GetFiCashflowsSortOrder) || Boolean(isGetFiCashflowsSortOrderValue)) {
            return false;
        }
        if (this.sortIndex !== other.sortIndex) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFiCashflowsSortOrder = GetFiCashflowsSortOrder;
GetFiCashflowsSortOrder.validateSortIndex = (sortIndex) => {
    if (!(0, leo_ts_runtime_1.isInt32)(sortIndex)) {
        throw new InvalidGetFiCashflowsSortOrderError(`Attribute sortIndex is not an Int32`);
    }
};
GetFiCashflowsSortOrder.validateColumnName = (columnName) => {
    if (!GetFiCashflowsSortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFiCashflowsSortOrderError(`Attribute columnName is not a GetFiCashflowsSortOrderEnums.ColumnName`);
    }
};
GetFiCashflowsSortOrder.validateOrder = (order) => {
    if (!fixedIncomeSortOrder_1.FixedIncomeSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFiCashflowsSortOrderError(`Attribute order is not a FixedIncomeSortOrder.FixedIncomeSortOrder`);
    }
};
GetFiCashflowsSortOrder.prototype.toString = function toString() {
    return `GetFiCashflowsSortOrder(sortIndex=${this.sortIndex},columnName=${this.columnName},order=${this.order})`;
};
