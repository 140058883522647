import { Instance, cast, flow, types, getEnv } from "mobx-state-tree";
import {
  PortfolioModel,
  createPortfolioModel,
} from "../../../models/PortfolioModel";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  GetFiDealRequestSettlementDetailsRPC,
  Portfolio,
  SettleFiDealRequestRPC,
} from "@khazana/khazana-rpcs";
import { LeoErrors } from "@khazana/khazana-boilerplate";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import {
  FiSettlementDetailsModel,
  createFiSettlementDetailsModel,
} from "../models/FiSettlementDetailsModel";
import {
  SettleFiDealInvalidRequestError,
  ViewFiDealInvalidRequestError,
} from "./ViewFiDealRequestDetailsError";
import {
  useGetFiDealRequestSettlementDetailsRPCImpl,
  useSettleFiDealRequestRPCImpl,
} from "../rpcs/RPC";
import { createServerNoteRPCType } from "../../../../../utils";

export const SettleFiDealRequestStore = types
  .model({
    portfolioList: types.maybeNull(types.array(PortfolioModel)),
    settlementDetails: types.maybeNull(FiSettlementDetailsModel),
    selectedPortfolio: types.maybeNull(PortfolioModel),
    note: types.maybeNull(types.string),
    error: types.maybeNull(
      types.union(
        types.enumeration<ViewFiDealInvalidRequestError>(
          "ViewFiDealInvalidRequestError",
          Object.values(ViewFiDealInvalidRequestError),
        ),
        types.enumeration<SettleFiDealInvalidRequestError>(
          "SettleFiDealInvalidRequestError",
          Object.values(SettleFiDealInvalidRequestError),
        ),
      ),
    ),
  })
  .actions((store) => ({
    setSelectedPortfolio(portfolio: DropdownItem): void {
      const logger = getEnv(store).logger;
      try {
        store.selectedPortfolio = createPortfolioModel(
          new Portfolio(new LeoUUID(portfolio.value), portfolio.name),
        );
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in setSelectedPortfolio`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in setSelectedPortfolio`,
          );
        }
      }
    },
    setSettleNote(note: string): void {
      store.note = note;
    },
    getFiDealRequestSettlementDetails: flow(function* (requestId: number) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);
        const request = new GetFiDealRequestSettlementDetailsRPC.Request(
          requestId,
        );
        const result: LeoRPCResult<
          GetFiDealRequestSettlementDetailsRPC.Response,
          GetFiDealRequestSettlementDetailsRPC.Errors.Errors
        > =
          yield useGetFiDealRequestSettlementDetailsRPCImpl(apiClient).execute(
            request,
          );
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.portfolioList = cast(
            response.portfolio.map((portfolioDetail) => {
              return createPortfolioModel(portfolioDetail);
            }),
          );
          store.settlementDetails =
            createFiSettlementDetailsModel(
              response.settlementDetails,
              response.ytmSettlementDetails,
              response.ytcSettlementDetails,
            ) ?? null;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiDealInvalidRequestError.InvalidRequestId:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
          }
        } else {
          logger.error(
            `Unhandled Result: ${result} from GetFiDealRequestSettlementDetailsRPC`,
          );
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in GetFiDealRequestSettlementDetailsRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in GetFiDealRequestSettlementDetailsRPC`,
          );
        }
      }
    }),
    settleDealRequest: flow(function* (requestId: number) {
      const logger = getEnv(store).logger;
      try {
        const apiClient: APIClient = getAPIClient(store);

        const request = new SettleFiDealRequestRPC.Request(
          requestId,
          store.portfolioList &&
          store.portfolioList.length > 0 &&
          store.selectedPortfolio
            ? new Portfolio(
                new LeoUUID(store.selectedPortfolio.id),
                store.selectedPortfolio.name,
              )
            : null,
          createServerNoteRPCType(store.note),
        );

        const result: LeoRPCResult<
          SettleFiDealRequestRPC.Response,
          SettleFiDealRequestRPC.Errors.Errors
        > = yield useSettleFiDealRequestRPCImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          return;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            case ViewFiDealInvalidRequestError.InvalidRequestId:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            case SettleFiDealInvalidRequestError.CanOnlySettleSelfRequest:
              store.error =
                SettleFiDealInvalidRequestError.CanOnlySettleSelfRequest;
              break;
            case SettleFiDealInvalidRequestError.DealAlreadyCancelled:
              store.error =
                SettleFiDealInvalidRequestError.DealAlreadyCancelled;
              break;
            case SettleFiDealInvalidRequestError.DealAlreadyExpired:
              store.error = SettleFiDealInvalidRequestError.DealAlreadyExpired;
              break;
            case SettleFiDealInvalidRequestError.DealAlreadySettled:
              store.error = SettleFiDealInvalidRequestError.DealAlreadySettled;
              break;
            case SettleFiDealInvalidRequestError.DealNotApproved:
              store.error = SettleFiDealInvalidRequestError.DealNotApproved;
              break;
            case SettleFiDealInvalidRequestError.InvalidPortfolio:
              store.error = SettleFiDealInvalidRequestError.InvalidPortfolio;
              break;
            case SettleFiDealInvalidRequestError.NoBrokersLinked:
              store.error = SettleFiDealInvalidRequestError.NoBrokersLinked;
              break;
            default:
              logger.error(
                `Unhandled Result: ${result} from SettleDealRequestRPC`,
              );
          }
        } else {
          logger.error(`Unhandled Result: ${result} from SettleDealRequestRPC`);
        }
      } catch (error) {
        if (error instanceof Error) {
          switch (error.name) {
            case LeoErrors.InvalidLeoUUIDError:
              store.error = ViewFiDealInvalidRequestError.InvalidRequestId;
              break;
            default:
              logger.error(
                `Unhandled error: ${error} occurred in SettleDealRequestRPC`,
              );
          }
        } else {
          logger.error(
            `Unknown error: ${error} occurred in SettleDealRequestRPC`,
          );
        }
      }
    }),
    resetStore(): void {
      store.error = null;
      store.note = null;
      store.portfolioList = null;
      store.selectedPortfolio = null;
      store.settlementDetails = null;
    },
  }));

export const createSettleFiDealRequestStore = (): Instance<
  typeof SettleFiDealRequestStore
> => {
  return SettleFiDealRequestStore.create();
};
