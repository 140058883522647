"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFdWithdrawListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fDWithdrawListItem_1 = require("./fDWithdrawListItem");
/* eslint-disable import/export */
class GetFdWithdrawListRPC {
}
exports.GetFdWithdrawListRPC = GetFdWithdrawListRPC;
(function (GetFdWithdrawListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFdWithdrawListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId) {
            this.isGetFdWithdrawListRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            return new Request(requestId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
            };
            return ret;
        }
        copy(requestId = this.requestId) {
            return new Request(requestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFdWithdrawListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    GetFdWithdrawListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFdWithdrawListRPC.Request(requestId=${this.requestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFdWithdrawListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(withdrawList) {
            this.isGetFdWithdrawListRPCResponse = true;
            Response.validateWithdrawList(withdrawList);
            this.withdrawList = withdrawList;
        }
        static fromDTO(dto) {
            const withdrawList = (0, leo_ts_runtime_1.getList)(dto, "withdrawList", fDWithdrawListItem_1.FDWithdrawListItem.fromDTO, InvalidResponseError);
            return new Response(withdrawList);
        }
        toDTO() {
            const ret = {
                withdrawList: this.withdrawList.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(withdrawList = this.withdrawList) {
            return new Response(withdrawList);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFdWithdrawListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.withdrawList, other.withdrawList)) {
                return false;
            }
            return true;
        }
    }
    Response.validateWithdrawList = (withdrawList) => {
        if (!Array.isArray(withdrawList)) {
            throw new InvalidResponseError(`Attribute withdrawList is not a List`);
        }
        for (let i = 0; i < withdrawList.length; i += 1) {
            const isFDWithdrawListItemValue = withdrawList[i].isFDWithdrawListItem;
            if (!(withdrawList[i] instanceof fDWithdrawListItem_1.FDWithdrawListItem || Boolean(isFDWithdrawListItemValue))) {
                throw new InvalidResponseError(`Attribute withdrawList at index ${i} is not a FDWithdrawListItem.`);
            }
        }
    };
    GetFdWithdrawListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFdWithdrawListRPC.Response(withdrawList=${this.withdrawList})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetFdWithdrawListRPC.Errors || (GetFdWithdrawListRPC.Errors = {}));
})(GetFdWithdrawListRPC = exports.GetFdWithdrawListRPC || (exports.GetFdWithdrawListRPC = {}));
