// The rule is disabled since privileges for three different kinds of users are created but only one of them is used.
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../networking/MockExecuteHelper";
import { HealthCheckRPC, ModuleAvailability } from "@khazana/khazana-rpcs";
import {
  EquityUserPrivileges,
  FdUserPrivileges,
  FiUserPrivileges,
  MFUserPrivileges,
} from "../../user/UserPrivileges";

const fundManagerPrivileges = [
  // Equity Deal Request
  EquityUserPrivileges.CreateEquityDealRequest,
  EquityUserPrivileges.BuyEquityDeal,
  EquityUserPrivileges.SellEquityDeal,
  EquityUserPrivileges.ViewEquityDealRequest,
  EquityUserPrivileges.ViewEquityContractNote,
  EquityUserPrivileges.CancelEquityDealRequest,
  EquityUserPrivileges.ViewEquityDashboard,
  EquityUserPrivileges.ViewEquityDetails,

  // Fi
  FiUserPrivileges.CreateFixedIncomeDealRequest,
  FiUserPrivileges.SellFixedIncomeDeal,
  FiUserPrivileges.BuyFixedIncomeDeal,
  FiUserPrivileges.ViewFixedIncomeContractNote,
  FiUserPrivileges.ViewFixedIncomeDealRequest,
  FiUserPrivileges.UploadFixedIncomeContractNote,
  FiUserPrivileges.CreateFixedIncomeContractNoteReviewRequest,
  FiUserPrivileges.EditFixedIncomeContractNote,
  FiUserPrivileges.ModifyFixedIncomeBrokerEntryInDealRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteLinkRequest,
  FiUserPrivileges.SettleFixedIncomeDealRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteUnknownRequest,
  FiUserPrivileges.CreateFixedIncomeContractNoteDiscardRequest,
  FiUserPrivileges.ViewFixedIncomeCashFlow,
  FiUserPrivileges.ViewFixedIncomeMaturityDealRequest,
  FiUserPrivileges.SettleFixedIncomeMaturityDealRequest,

  //FD
  FdUserPrivileges.ViewFixedDepositDealRequest,
  FdUserPrivileges.ViewFixedDepositCertificate,
  FdUserPrivileges.ViewFixedDepositWithdrawCertificate,
  FdUserPrivileges.CreateFixedDepositCertificate,
  FdUserPrivileges.CreateFixedDepositDealRequest,
  FdUserPrivileges.EditFixedDepositCertificate,
  FdUserPrivileges.ViewFixedDepositCashFlow,

  //MF
  MFUserPrivileges.ViewMutualFundDealRequest,
  MFUserPrivileges.CheckMutualFundDealRequest,
  MFUserPrivileges.ViewMutualFundConfirmationRequest,
  MFUserPrivileges.CreateMutualFundConfirmationRequest,
  MFUserPrivileges.ViewMutualFundReports,
  MFUserPrivileges.CreateMutualFundDealRequest,
  MFUserPrivileges.DownloadMutualFundHoldingSummary,
  MFUserPrivileges.ViewMutualFundHoldingSummary,
  MFUserPrivileges.DownloadMutualFundInvestmentReturnsGrowth,
  MFUserPrivileges.ViewMutualFundInvestmentReturnsGrowth,
];

const icPrivileges = [
  EquityUserPrivileges.CheckEquityImpactDealRequest,
  EquityUserPrivileges.ViewEquityDealRequest,
  EquityUserPrivileges.ViewEquityHistoricalIsinData,
  EquityUserPrivileges.ViewEquityImpactOnHoldingsDisplaySection,
  EquityUserPrivileges.CheckEquityImpactDealRequest,
];

const accountsPrivileges = [
  EquityUserPrivileges.CheckEquityTransactionDetailsForDealRequest,
  EquityUserPrivileges.ViewEquityContractNote,
  EquityUserPrivileges.ViewEquityDetails,
];
export class MockHealthCheckRPCImpl extends HealthCheckRPC {
  execute(
    _request: HealthCheckRPC.Request,
  ): Promise<
    LeoRPCResult<HealthCheckRPC.Response, HealthCheckRPC.Errors.Errors>
  > {
    const response = new HealthCheckRPC.Response(fundManagerPrivileges);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<HealthCheckRPC.Response, HealthCheckRPC.Errors.Errors>
    >;
  }
}
