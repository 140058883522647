import React from "react";

export const EmptyStateIcon = (
  props: React.SVGProps<SVGSVGElement>,
): React.ReactElement<React.SVGProps<SVGSVGElement>> => {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66602 8.00033C2.66602 7.26395 3.26297 6.66699 3.99935 6.66699H21.3327C22.0691 6.66699 22.666 7.26395 22.666 8.00033C22.666 8.73671 22.0691 9.33366 21.3327 9.33366H3.99935C3.26297 9.33366 2.66602 8.73671 2.66602 8.00033ZM19.0565 12.3909C19.5772 11.8702 20.4215 11.8702 20.9422 12.3909L22.666 14.1147L24.3899 12.3909C24.9106 11.8702 25.7548 11.8702 26.2755 12.3909C26.7962 12.9115 26.7962 13.7558 26.2755 14.2765L24.5516 16.0003L26.2755 17.7242C26.7962 18.2449 26.7962 19.0891 26.2755 19.6098C25.7548 20.1305 24.9106 20.1305 24.3899 19.6098L22.666 17.8859L20.9422 19.6098C20.4215 20.1305 19.5772 20.1305 19.0565 19.6098C18.5358 19.0891 18.5358 18.2449 19.0565 17.7242L20.7804 16.0003L19.0565 14.2765C18.5358 13.7558 18.5358 12.9115 19.0565 12.3909ZM2.66602 16.0003C2.66602 15.2639 3.26297 14.667 3.99935 14.667H14.666C15.4024 14.667 15.9993 15.2639 15.9993 16.0003C15.9993 16.7367 15.4024 17.3337 14.666 17.3337H3.99935C3.26297 17.3337 2.66602 16.7367 2.66602 16.0003ZM2.66602 24.0003C2.66602 23.2639 3.26297 22.667 3.99935 22.667H21.3327C22.0691 22.667 22.666 23.2639 22.666 24.0003C22.666 24.7367 22.0691 25.3337 21.3327 25.3337H3.99935C3.26297 25.3337 2.66602 24.7367 2.66602 24.0003Z"
        fill="#818A93"
      />
    </svg>
  );
};
