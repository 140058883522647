import React from "react";
import { Instance } from "mobx-state-tree";
import { FDDealStatusSection } from "./FDDealStatusSection";
import { FDDealRequestHistoryModel } from "../models/FDDealRequestHistoryModel";

interface GetFDDealStatusSectionProps {
  value: Instance<typeof FDDealRequestHistoryModel>;
  isHistoryComponent?: boolean;
}
export const GetFDDealStatusSection = ({
  value,
  isHistoryComponent = false,
}: GetFDDealStatusSectionProps): React.ReactElement => {
  return value.requestNote?.requestedAt && value.status ? (
    <FDDealStatusSection
      firstName={value.requestNote?.userName.firstName}
      lastName={value.requestNote?.userName.lastName}
      requestedAt={value.requestNote?.requestedAt}
      status={value.status}
      note={value.requestNote?.note}
      isHistoryComponent={isHistoryComponent}
      withdrawDetails={value.withdrawDetails}
      userImage={value.requestNote.profilePhoto?.toString()}
    />
  ) : (
    <></>
  );
};
