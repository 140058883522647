"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFixedDepositHoldingSummarySortOrder = exports.GetFixedDepositHoldingSummarySortOrderEnums = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const reportsSortOrder_1 = require("./reportsSortOrder");
class InvalidGetFixedDepositHoldingSummarySortOrderError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFixedDepositHoldingSummarySortOrderError";
    }
}
var GetFixedDepositHoldingSummarySortOrderEnums;
(function (GetFixedDepositHoldingSummarySortOrderEnums) {
    let ColumnName;
    (function (ColumnName_1) {
        let ColumnName;
        (function (ColumnName) {
            ColumnName["BANK_NAME"] = "BANK_NAME";
            ColumnName["OPENING_AMOUNT"] = "OPENING_AMOUNT";
            ColumnName["PURCHASE_AMOUNT"] = "PURCHASE_AMOUNT";
            ColumnName["CLOSING_AMOUNT"] = "CLOSING_AMOUNT";
        })(ColumnName = ColumnName_1.ColumnName || (ColumnName_1.ColumnName = {}));
        ColumnName_1.fromDTO = (dto) => {
            switch (dto.case) {
                case "BANK_NAME":
                    return ColumnName.BANK_NAME;
                case "OPENING_AMOUNT":
                    return ColumnName.OPENING_AMOUNT;
                case "PURCHASE_AMOUNT":
                    return ColumnName.PURCHASE_AMOUNT;
                case "CLOSING_AMOUNT":
                    return ColumnName.CLOSING_AMOUNT;
                default:
                    throw new InvalidGetFixedDepositHoldingSummarySortOrderError(`Case ${dto.case} is not valid case of ColumnName`);
            }
        };
        ColumnName_1.toDTO = (columnName) => {
            const ret = {
                case: ColumnName[columnName],
            };
            return ret;
        };
        ColumnName_1.isInstanceOf = (other) => {
            if (other in ColumnName) {
                return true;
            }
            return false;
        };
    })(ColumnName = GetFixedDepositHoldingSummarySortOrderEnums.ColumnName || (GetFixedDepositHoldingSummarySortOrderEnums.ColumnName = {}));
    /* eslint-enable @typescript-eslint/no-shadow */
})(GetFixedDepositHoldingSummarySortOrderEnums = exports.GetFixedDepositHoldingSummarySortOrderEnums || (exports.GetFixedDepositHoldingSummarySortOrderEnums = {}));
class GetFixedDepositHoldingSummarySortOrder {
    constructor(columnName, order) {
        this.isGetFixedDepositHoldingSummarySortOrder = true;
        GetFixedDepositHoldingSummarySortOrder.validateColumnName(columnName);
        this.columnName = columnName;
        GetFixedDepositHoldingSummarySortOrder.validateOrder(order);
        this.order = order;
    }
    static fromDTO(dto) {
        const columnName = GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "columnName", InvalidGetFixedDepositHoldingSummarySortOrderError));
        const order = reportsSortOrder_1.ReportsSortOrder.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "order", InvalidGetFixedDepositHoldingSummarySortOrderError));
        return new GetFixedDepositHoldingSummarySortOrder(columnName, order);
    }
    toDTO() {
        const ret = {
            columnName: GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.toDTO(this.columnName),
            order: reportsSortOrder_1.ReportsSortOrder.toDTO(this.order),
        };
        return ret;
    }
    copy(columnName = this.columnName, order = this.order) {
        return new GetFixedDepositHoldingSummarySortOrder(columnName, order);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFixedDepositHoldingSummarySortOrderValue = other.isGetFixedDepositHoldingSummarySortOrder;
        if (!(other instanceof GetFixedDepositHoldingSummarySortOrder) || Boolean(isGetFixedDepositHoldingSummarySortOrderValue)) {
            return false;
        }
        if (this.columnName !== other.columnName) {
            return false;
        }
        if (this.order !== other.order) {
            return false;
        }
        return true;
    }
}
exports.GetFixedDepositHoldingSummarySortOrder = GetFixedDepositHoldingSummarySortOrder;
GetFixedDepositHoldingSummarySortOrder.validateColumnName = (columnName) => {
    if (!GetFixedDepositHoldingSummarySortOrderEnums.ColumnName.isInstanceOf(columnName)) {
        throw new InvalidGetFixedDepositHoldingSummarySortOrderError(`Attribute columnName is not a GetFixedDepositHoldingSummarySortOrderEnums.ColumnName`);
    }
};
GetFixedDepositHoldingSummarySortOrder.validateOrder = (order) => {
    if (!reportsSortOrder_1.ReportsSortOrder.isInstanceOf(order)) {
        throw new InvalidGetFixedDepositHoldingSummarySortOrderError(`Attribute order is not a ReportsSortOrder.ReportsSortOrder`);
    }
};
GetFixedDepositHoldingSummarySortOrder.prototype.toString = function toString() {
    return `GetFixedDepositHoldingSummarySortOrder(columnName=${this.columnName},order=${this.order})`;
};
