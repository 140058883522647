import { Instance, types } from "mobx-state-tree";
import { createAmountModel } from "../../../models/AmountModel";
import {
  BrokerStatusType,
  EquityDealRequestBrokerSectionDetail,
  AmountQuantity,
} from "@khazana/khazana-rpcs";
import {
  EquityDealRequestBrokerModel,
  createEquityDealRequestBrokerModel,
} from "./EquityDealRequestBrokerModel";
import { Logger } from "../../../../logger/Logger";
import { AmountQuantityModel } from "../../../models/AmountQuantityModel";

const LinkedModel = types.model("LinkedModel", {
  executedAmountQuantity: AmountQuantityModel,
  referenceNumber: types.string,
  rawContractNoteId: types.number,
});

const LinkingPendingModel = types.model("LinkingPendingModel", {});

const BrokerStatusModel = types.model("BrokerStatusModel", {
  brokerStatusLinked: types.maybeNull(LinkedModel),
  brokerStatusLinkingPending: types.maybeNull(LinkingPendingModel),
});

export const EquityDealRequestBrokerSectionDetailModel = types.model(
  "EquityDealRequestBrokerSectionDetailModel",
  {
    broker: EquityDealRequestBrokerModel,
    requestedAmountQuantity: AmountQuantityModel,
    brokerStatus: types.maybeNull(BrokerStatusModel),
  },
);

export const createAmountQuantityModel = (
  amountQuantity: AmountQuantity.AmountQuantity | undefined,
  logger: Logger,
): Instance<typeof AmountQuantityModel> => {
  if (amountQuantity instanceof AmountQuantity.AmountCase) {
    return AmountQuantityModel.create({
      amount: createAmountModel(amountQuantity.amount),
    });
  }

  if (amountQuantity instanceof AmountQuantity.QuantityCase) {
    return AmountQuantityModel.create({
      quantity: amountQuantity.quantity.quantity,
    });
  }
  // TODO: Ideally an OR operator should be used for Amount and Quantity since only one of them can exist at one time
  // This return statement is added for type safety and will never be called.
  logger.error(
    `Found amountQuantity: ${amountQuantity} which is not a instance of AmountCase or QuantityCase`,
  );
  return AmountQuantityModel.create();
};

const createLinkedModel = (
  linkedDetails: BrokerStatusType.Linked,
  logger: Logger,
): Instance<typeof LinkedModel> => {
  return LinkedModel.create({
    referenceNumber: linkedDetails.contractNoteNumber,
    executedAmountQuantity: createAmountQuantityModel(
      linkedDetails.executedAmountQuantity,
      logger,
    ),
    rawContractNoteId: linkedDetails.rawContractNoteDisplayId,
  });
};

const createBrokerStatusModel = (
  brokerStatus: BrokerStatusType.BrokerStatusType | null,
  logger: Logger,
): Instance<typeof BrokerStatusModel> => {
  if (brokerStatus instanceof BrokerStatusType.Linked) {
    return BrokerStatusModel.create({
      brokerStatusLinked: createLinkedModel(brokerStatus, logger),
    });
  } else {
    return BrokerStatusModel.create({
      brokerStatusLinkingPending: LinkingPendingModel.create(),
    });
  }
};

export const createEquityDealRequestBrokerSectionDetailModel = (
  detail: EquityDealRequestBrokerSectionDetail,
  logger: Logger,
): Instance<typeof EquityDealRequestBrokerSectionDetailModel> => {
  return EquityDealRequestBrokerSectionDetailModel.create({
    broker: createEquityDealRequestBrokerModel(detail.broker),
    brokerStatus: createBrokerStatusModel(detail.brokerStatus, logger),
    requestedAmountQuantity: createAmountQuantityModel(
      detail.requestedAmountQuantity,
      logger,
    ),
  });
};
