import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  EquityRealisedGain,
  GetEquityTopTenRealisedGainsRPC,
} from "@khazana/khazana-rpcs";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetEquityTopTenRealisedGainsRPC } from "../rpcs/RPC";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import {
  createServerDateRangeModel,
  EquityReportDateRangeModel,
  ReportDateRange,
} from "../../models/EquityReportDateRangeModel";
import { DateRangeModel } from "../../models/DateRangeModel";

export const RealisedGainModel = types.model("RealisedGainModel", {
  symbol: types.maybe(types.string),
  soldValue: types.maybe(types.number),
  realisedGain: types.maybe(types.number),
  changePercentage: types.maybe(types.number),
  irr: types.maybe(types.number),
});

export const createRealisedGainModel = (
  data?: EquityRealisedGain,
): Instance<typeof RealisedGainModel> => {
  if (data) {
    return RealisedGainModel.create({
      symbol: data.symbol,
      soldValue: data.soldValue ?? undefined,
      realisedGain: data.realisedPnl ?? undefined,
      changePercentage: data.changePercentage ?? undefined,
      irr: data.irr ?? undefined,
    });
  }
  return RealisedGainModel.create();
};

export const EquityTopRealisedGain = types
  .model("EquityTopRealisedGain", {
    list: types.array(RealisedGainModel),
    currency: types.maybe(CurrencyModel),
    dateRange: EquityReportDateRangeModel,
    totalRealisedGains: types.maybe(types.number),
    totalRealisedGainsPercentage: types.maybe(types.number),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      onDateRangeChange: (
        dateRange:
          | ReportDateRange
          | { startDate: Date | null; endDate: Date | null },
      ): void => {
        if (typeof dateRange === "string") {
          store.dateRange = dateRange;
        } else if (typeof dateRange === "object") {
          store.dateRange = DateRangeModel.create({
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
          });
        }
      },
      getEquityTopTenRealisedGains: flow(function* () {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        if (
          typeof store.dateRange === "object" &&
          (!store.dateRange.startDate || !store.dateRange.endDate)
        ) {
          return;
        }
        const request = new GetEquityTopTenRealisedGainsRPC.Request(
          null,
          null,
          createServerDateRangeModel(store.dateRange),
        );
        const result: LeoRPCResult<
          GetEquityTopTenRealisedGainsRPC.Response,
          GetEquityTopTenRealisedGainsRPC.Errors.Errors
        > =
          yield useGetEquityTopTenRealisedGainsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.list = cast(
            response.topTenRealisedGains.map((data) =>
              createRealisedGainModel(data),
            ),
          );
          store.currency = createCurrencyModel(response.currency);
          store.totalRealisedGains = response.totalRealisedGains;
          store.totalRealisedGainsPercentage =
            response.totalRealisedGainsPercentage;
        } else if (result instanceof LeoRPCResult.Error) {
          const { error } = result;
          switch (error.code) {
            default:
              logger.error(
                `Unhandled error: ${error} occurred in getEquityTopTenRealisedGainsRPCRPC`,
              );
          }
        } else {
          logger.error("Unhandled error");
        }
      }),
    };
  });

export const createEquityTopRealisedGain = (): Instance<
  typeof EquityTopRealisedGain
> => {
  return EquityTopRealisedGain.create({
    list: [],
    dateRange: ReportDateRange.YTD,
  });
};
