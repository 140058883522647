"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailCellType = exports.InvalidDetailCellTypeError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const quantity_1 = require("./quantity");
const transactionAmount_1 = require("./transactionAmount");
const bigDecimal_1 = require("./bigDecimal");
const bigAmount_1 = require("./bigAmount");
const periodUnit_1 = require("./periodUnit");
const interestType_1 = require("./interestType");
const payoutFrequency_1 = require("./payoutFrequency");
const compoundingFrequency_1 = require("./compoundingFrequency");
class InvalidDetailCellTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidDetailCellTypeError";
    }
}
exports.InvalidDetailCellTypeError = InvalidDetailCellTypeError;
var DetailCellType;
(function (DetailCellType) {
    class TransactionType {
        constructor(text) {
            this.isTransactionType = true;
            TransactionType.validateText(text);
            this.text = text;
        }
        static fromDTO(dto) {
            const text = (0, leo_ts_runtime_1.getString)(dto, "text", InvalidDetailCellTypeError);
            return new TransactionType(text);
        }
        toDTO() {
            const ret = {
                case: "TRANSACTION_TYPE",
                text: this.text,
            };
            return ret;
        }
        copy(text = this.text) {
            return new TransactionType(text);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isTransactionTypeValue = other.isTransactionType;
            if (!(other instanceof TransactionType) || Boolean(isTransactionTypeValue)) {
                return false;
            }
            if (this.text !== other.text) {
                return false;
            }
            return true;
        }
    }
    TransactionType.validateText = (text) => {
        if (!(0, leo_ts_runtime_1.isString)(text)) {
            throw new InvalidDetailCellTypeError(`Attribute text is not a String`);
        }
        if (text.trim().length === 0) {
            throw new InvalidDetailCellTypeError(`Attribute text cannot be an empty string.`);
        }
    };
    DetailCellType.TransactionType = TransactionType;
    TransactionType.prototype.toString = function toString() {
        return `DetailCellType.TRANSACTION_TYPE(text='${this.text}')`;
    };
    class StatusType {
        constructor(text) {
            this.isStatusType = true;
            StatusType.validateText(text);
            this.text = text;
        }
        static fromDTO(dto) {
            const text = (0, leo_ts_runtime_1.getString)(dto, "text", InvalidDetailCellTypeError);
            return new StatusType(text);
        }
        toDTO() {
            const ret = {
                case: "STATUS_TYPE",
                text: this.text,
            };
            return ret;
        }
        copy(text = this.text) {
            return new StatusType(text);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isStatusTypeValue = other.isStatusType;
            if (!(other instanceof StatusType) || Boolean(isStatusTypeValue)) {
                return false;
            }
            if (this.text !== other.text) {
                return false;
            }
            return true;
        }
    }
    StatusType.validateText = (text) => {
        if (!(0, leo_ts_runtime_1.isString)(text)) {
            throw new InvalidDetailCellTypeError(`Attribute text is not a String`);
        }
        if (text.trim().length === 0) {
            throw new InvalidDetailCellTypeError(`Attribute text cannot be an empty string.`);
        }
    };
    DetailCellType.StatusType = StatusType;
    StatusType.prototype.toString = function toString() {
        return `DetailCellType.STATUS_TYPE(text='${this.text}')`;
    };
    class QuantityType {
        constructor(quantity = null) {
            this.isQuantityType = true;
            if (quantity !== undefined && quantity !== null) {
                QuantityType.validateQuantity(quantity);
            }
            this.quantity = quantity;
        }
        static fromDTO(dto) {
            let quantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "quantity");
            if (quantity !== undefined && quantity !== null) {
                quantity = quantity_1.Quantity.fromDTO(quantity);
            }
            return new QuantityType(quantity);
        }
        toDTO() {
            const ret = {
                case: "QUANTITY_TYPE",
            };
            if (this.quantity) {
                ret.quantity = this.quantity.toDTO();
            }
            return ret;
        }
        copy(quantity = this.quantity) {
            return new QuantityType(quantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isQuantityTypeValue = other.isQuantityType;
            if (!(other instanceof QuantityType) || Boolean(isQuantityTypeValue)) {
                return false;
            }
            if (this.quantity) {
                if (!this.quantity.equals(other.quantity)) {
                    return false;
                }
            }
            return true;
        }
    }
    QuantityType.validateQuantity = (quantity) => {
        const isQuantityValue = quantity.isQuantity;
        if (!(quantity instanceof quantity_1.Quantity || Boolean(isQuantityValue))) {
            throw new InvalidDetailCellTypeError(`Attribute quantity is not a Quantity`);
        }
    };
    DetailCellType.QuantityType = QuantityType;
    QuantityType.prototype.toString = function toString() {
        return `DetailCellType.QUANTITY_TYPE(quantity=${this.quantity})`;
    };
    class PercentageType {
        constructor(percentage = null) {
            this.isPercentageType = true;
            if (percentage !== undefined && percentage !== null) {
                PercentageType.validatePercentage(percentage);
            }
            this.percentage = percentage;
        }
        static fromDTO(dto) {
            const percentage = (0, leo_ts_runtime_1.getOptionalInt32)(dto, "percentage", InvalidDetailCellTypeError);
            return new PercentageType(percentage);
        }
        toDTO() {
            const ret = {
                case: "PERCENTAGE_TYPE",
            };
            if (this.percentage !== undefined && this.percentage !== null) {
                ret.percentage = this.percentage;
            }
            return ret;
        }
        copy(percentage = this.percentage) {
            return new PercentageType(percentage);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPercentageTypeValue = other.isPercentageType;
            if (!(other instanceof PercentageType) || Boolean(isPercentageTypeValue)) {
                return false;
            }
            if (this.percentage !== other.percentage) {
                return false;
            }
            return true;
        }
    }
    PercentageType.validatePercentage = (percentage) => {
        if (!(0, leo_ts_runtime_1.isInt32)(percentage)) {
            throw new InvalidDetailCellTypeError(`Attribute percentage is not an Int32`);
        }
    };
    DetailCellType.PercentageType = PercentageType;
    PercentageType.prototype.toString = function toString() {
        return `DetailCellType.PERCENTAGE_TYPE(percentage=${this.percentage})`;
    };
    class AmountType {
        constructor(amount = null) {
            this.isAmountType = true;
            if (amount !== undefined && amount !== null) {
                AmountType.validateAmount(amount);
            }
            this.amount = amount;
        }
        static fromDTO(dto) {
            let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
            if (amount !== undefined && amount !== null) {
                amount = transactionAmount_1.TransactionAmount.fromDTO(amount);
            }
            return new AmountType(amount);
        }
        toDTO() {
            const ret = {
                case: "AMOUNT_TYPE",
            };
            if (this.amount) {
                ret.amount = this.amount.toDTO();
            }
            return ret;
        }
        copy(amount = this.amount) {
            return new AmountType(amount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isAmountTypeValue = other.isAmountType;
            if (!(other instanceof AmountType) || Boolean(isAmountTypeValue)) {
                return false;
            }
            if (this.amount) {
                if (!this.amount.equals(other.amount)) {
                    return false;
                }
            }
            return true;
        }
    }
    AmountType.validateAmount = (amount) => {
        const isTransactionAmountValue = amount.isTransactionAmount;
        if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidDetailCellTypeError(`Attribute amount is not a TransactionAmount`);
        }
    };
    DetailCellType.AmountType = AmountType;
    AmountType.prototype.toString = function toString() {
        return `DetailCellType.AMOUNT_TYPE(amount=${this.amount})`;
    };
    class BigQuantityType {
        constructor(quantity = null) {
            this.isBigQuantityType = true;
            if (quantity !== undefined && quantity !== null) {
                BigQuantityType.validateQuantity(quantity);
            }
            this.quantity = quantity;
        }
        static fromDTO(dto) {
            let quantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "quantity");
            if (quantity !== undefined && quantity !== null) {
                quantity = bigDecimal_1.BigDecimal.fromDTO(quantity);
            }
            return new BigQuantityType(quantity);
        }
        toDTO() {
            const ret = {
                case: "BIG_QUANTITY_TYPE",
            };
            if (this.quantity) {
                ret.quantity = this.quantity.toDTO();
            }
            return ret;
        }
        copy(quantity = this.quantity) {
            return new BigQuantityType(quantity);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isBigQuantityTypeValue = other.isBigQuantityType;
            if (!(other instanceof BigQuantityType) || Boolean(isBigQuantityTypeValue)) {
                return false;
            }
            if (this.quantity) {
                if (!this.quantity.equals(other.quantity)) {
                    return false;
                }
            }
            return true;
        }
    }
    BigQuantityType.validateQuantity = (quantity) => {
        const isBigDecimalValue = quantity.isBigDecimal;
        if (!(quantity instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidDetailCellTypeError(`Attribute quantity is not a BigDecimal`);
        }
    };
    DetailCellType.BigQuantityType = BigQuantityType;
    BigQuantityType.prototype.toString = function toString() {
        return `DetailCellType.BIG_QUANTITY_TYPE(quantity=${this.quantity})`;
    };
    class BigAmountType {
        constructor(amount = null) {
            this.isBigAmountType = true;
            if (amount !== undefined && amount !== null) {
                BigAmountType.validateAmount(amount);
            }
            this.amount = amount;
        }
        static fromDTO(dto) {
            let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
            if (amount !== undefined && amount !== null) {
                amount = bigAmount_1.BigAmount.fromDTO(amount);
            }
            return new BigAmountType(amount);
        }
        toDTO() {
            const ret = {
                case: "BIG_AMOUNT_TYPE",
            };
            if (this.amount) {
                ret.amount = this.amount.toDTO();
            }
            return ret;
        }
        copy(amount = this.amount) {
            return new BigAmountType(amount);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isBigAmountTypeValue = other.isBigAmountType;
            if (!(other instanceof BigAmountType) || Boolean(isBigAmountTypeValue)) {
                return false;
            }
            if (this.amount) {
                if (!this.amount.equals(other.amount)) {
                    return false;
                }
            }
            return true;
        }
    }
    BigAmountType.validateAmount = (amount) => {
        const isBigAmountValue = amount.isBigAmount;
        if (!(amount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
            throw new InvalidDetailCellTypeError(`Attribute amount is not a BigAmount`);
        }
    };
    DetailCellType.BigAmountType = BigAmountType;
    BigAmountType.prototype.toString = function toString() {
        return `DetailCellType.BIG_AMOUNT_TYPE(amount=${this.amount})`;
    };
    class Unstyled {
        constructor(text = null, hidden) {
            this.isUnstyled = true;
            if (text !== undefined && text !== null) {
                Unstyled.validateText(text);
            }
            this.text = text;
            Unstyled.validateHidden(hidden);
            this.hidden = hidden;
        }
        static fromDTO(dto) {
            const text = (0, leo_ts_runtime_1.getOptionalString)(dto, "text", InvalidDetailCellTypeError);
            const hidden = (0, leo_ts_runtime_1.getBoolean)(dto, "hidden", InvalidDetailCellTypeError);
            return new Unstyled(text, hidden);
        }
        toDTO() {
            const ret = {
                case: "UNSTYLED",
                hidden: this.hidden,
            };
            if (this.text !== undefined && this.text !== null) {
                ret.text = this.text;
            }
            return ret;
        }
        copy(text = this.text, hidden = this.hidden) {
            return new Unstyled(text, hidden);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isUnstyledValue = other.isUnstyled;
            if (!(other instanceof Unstyled) || Boolean(isUnstyledValue)) {
                return false;
            }
            if (this.text !== other.text) {
                return false;
            }
            if (this.hidden !== other.hidden) {
                return false;
            }
            return true;
        }
    }
    Unstyled.validateText = (text) => {
        if (!(0, leo_ts_runtime_1.isString)(text)) {
            throw new InvalidDetailCellTypeError(`Attribute text is not a String`);
        }
        if (text.trim().length === 0) {
            throw new InvalidDetailCellTypeError(`Attribute text cannot be an empty string.`);
        }
    };
    Unstyled.validateHidden = (hidden) => {
        if (!(0, leo_ts_runtime_1.isBoolean)(hidden)) {
            throw new InvalidDetailCellTypeError(`Attribute hidden is not a Boolean`);
        }
    };
    DetailCellType.Unstyled = Unstyled;
    Unstyled.prototype.toString = function toString() {
        return `DetailCellType.UNSTYLED(text='${this.text}',hidden=${this.hidden})`;
    };
    class Time {
        constructor(time) {
            this.isTime = true;
            Time.validateTime(time);
            this.time = time;
        }
        static fromDTO(dto) {
            const time = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "time", InvalidDetailCellTypeError);
            return new Time(time);
        }
        toDTO() {
            const ret = {
                case: "TIME",
                time: this.time.toDTO(),
            };
            return ret;
        }
        copy(time = this.time) {
            return new Time(time);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isTimeValue = other.isTime;
            if (!(other instanceof Time) || Boolean(isTimeValue)) {
                return false;
            }
            if (!this.time.equals(other.time)) {
                return false;
            }
            return true;
        }
    }
    Time.validateTime = (time) => {
        if (!(0, leo_ts_runtime_1.isLeoTimestamp)(time)) {
            throw new InvalidDetailCellTypeError(`Attribute time is not a Timestamp`);
        }
    };
    DetailCellType.Time = Time;
    Time.prototype.toString = function toString() {
        return `DetailCellType.TIME(time=${this.time})`;
    };
    class Date {
        constructor(date = null) {
            this.isDate = true;
            if (date !== undefined && date !== null) {
                Date.validateDate(date);
            }
            this.date = date;
        }
        static fromDTO(dto) {
            const date = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "date", InvalidDetailCellTypeError);
            return new Date(date);
        }
        toDTO() {
            const ret = {
                case: "DATE",
            };
            if (this.date) {
                ret.date = this.date.toDTO();
            }
            return ret;
        }
        copy(date = this.date) {
            return new Date(date);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDateValue = other.isDate;
            if (!(other instanceof Date) || Boolean(isDateValue)) {
                return false;
            }
            if (this.date) {
                if (!this.date.equals(other.date)) {
                    return false;
                }
            }
            return true;
        }
    }
    Date.validateDate = (date) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(date)) {
            throw new InvalidDetailCellTypeError(`Attribute date is not a Date`);
        }
    };
    DetailCellType.Date = Date;
    Date.prototype.toString = function toString() {
        return `DetailCellType.DATE(date=${this.date})`;
    };
    class Duration {
        constructor(duration, unit) {
            this.isDuration = true;
            Duration.validateDuration(duration);
            this.duration = duration;
            Duration.validateUnit(unit);
            this.unit = unit;
        }
        static fromDTO(dto) {
            const duration = (0, leo_ts_runtime_1.getInt64)(dto, "duration", InvalidDetailCellTypeError);
            const unit = periodUnit_1.PeriodUnit.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "unit", InvalidDetailCellTypeError));
            return new Duration(duration, unit);
        }
        toDTO() {
            const ret = {
                case: "DURATION",
                duration: this.duration,
                unit: periodUnit_1.PeriodUnit.toDTO(this.unit),
            };
            return ret;
        }
        copy(duration = this.duration, unit = this.unit) {
            return new Duration(duration, unit);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDurationValue = other.isDuration;
            if (!(other instanceof Duration) || Boolean(isDurationValue)) {
                return false;
            }
            if (this.duration !== other.duration) {
                return false;
            }
            if (this.unit !== other.unit) {
                return false;
            }
            return true;
        }
    }
    Duration.validateDuration = (duration) => {
        if (!(0, leo_ts_runtime_1.isInt64)(duration)) {
            throw new InvalidDetailCellTypeError(`Attribute duration is not an Int64`);
        }
    };
    Duration.validateUnit = (unit) => {
        if (!periodUnit_1.PeriodUnit.isInstanceOf(unit)) {
            throw new InvalidDetailCellTypeError(`Attribute unit is not a PeriodUnit.PeriodUnit`);
        }
    };
    DetailCellType.Duration = Duration;
    Duration.prototype.toString = function toString() {
        return `DetailCellType.DURATION(duration=${this.duration},unit=${this.unit})`;
    };
    class Interest {
        constructor(interestType) {
            this.isInterest = true;
            Interest.validateInterestType(interestType);
            this.interestType = interestType;
        }
        static fromDTO(dto) {
            const interestType = interestType_1.InterestType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "interestType", InvalidDetailCellTypeError));
            return new Interest(interestType);
        }
        toDTO() {
            const ret = {
                case: "INTEREST",
                interestType: interestType_1.InterestType.toDTO(this.interestType),
            };
            return ret;
        }
        copy(interestType = this.interestType) {
            return new Interest(interestType);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isInterestValue = other.isInterest;
            if (!(other instanceof Interest) || Boolean(isInterestValue)) {
                return false;
            }
            if (this.interestType !== other.interestType) {
                return false;
            }
            return true;
        }
    }
    Interest.validateInterestType = (interestType) => {
        if (!interestType_1.InterestType.isInstanceOf(interestType)) {
            throw new InvalidDetailCellTypeError(`Attribute interestType is not a InterestType.InterestType`);
        }
    };
    DetailCellType.Interest = Interest;
    Interest.prototype.toString = function toString() {
        return `DetailCellType.INTEREST(interestType=${this.interestType})`;
    };
    class PayoutFrequencyType {
        constructor(frequency) {
            this.isPayoutFrequencyType = true;
            PayoutFrequencyType.validateFrequency(frequency);
            this.frequency = frequency;
        }
        static fromDTO(dto) {
            const frequency = payoutFrequency_1.PayoutFrequency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "frequency", InvalidDetailCellTypeError));
            return new PayoutFrequencyType(frequency);
        }
        toDTO() {
            const ret = {
                case: "PAYOUT_FREQUENCY_TYPE",
                frequency: payoutFrequency_1.PayoutFrequency.toDTO(this.frequency),
            };
            return ret;
        }
        copy(frequency = this.frequency) {
            return new PayoutFrequencyType(frequency);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPayoutFrequencyTypeValue = other.isPayoutFrequencyType;
            if (!(other instanceof PayoutFrequencyType) || Boolean(isPayoutFrequencyTypeValue)) {
                return false;
            }
            if (this.frequency !== other.frequency) {
                return false;
            }
            return true;
        }
    }
    PayoutFrequencyType.validateFrequency = (frequency) => {
        if (!payoutFrequency_1.PayoutFrequency.isInstanceOf(frequency)) {
            throw new InvalidDetailCellTypeError(`Attribute frequency is not a PayoutFrequency.PayoutFrequency`);
        }
    };
    DetailCellType.PayoutFrequencyType = PayoutFrequencyType;
    PayoutFrequencyType.prototype.toString = function toString() {
        return `DetailCellType.PAYOUT_FREQUENCY_TYPE(frequency=${this.frequency})`;
    };
    class CompoundingFrequencyType {
        constructor(frequency) {
            this.isCompoundingFrequencyType = true;
            CompoundingFrequencyType.validateFrequency(frequency);
            this.frequency = frequency;
        }
        static fromDTO(dto) {
            const frequency = compoundingFrequency_1.CompoundingFrequency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "frequency", InvalidDetailCellTypeError));
            return new CompoundingFrequencyType(frequency);
        }
        toDTO() {
            const ret = {
                case: "COMPOUNDING_FREQUENCY_TYPE",
                frequency: compoundingFrequency_1.CompoundingFrequency.toDTO(this.frequency),
            };
            return ret;
        }
        copy(frequency = this.frequency) {
            return new CompoundingFrequencyType(frequency);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isCompoundingFrequencyTypeValue = other.isCompoundingFrequencyType;
            if (!(other instanceof CompoundingFrequencyType) || Boolean(isCompoundingFrequencyTypeValue)) {
                return false;
            }
            if (this.frequency !== other.frequency) {
                return false;
            }
            return true;
        }
    }
    CompoundingFrequencyType.validateFrequency = (frequency) => {
        if (!compoundingFrequency_1.CompoundingFrequency.isInstanceOf(frequency)) {
            throw new InvalidDetailCellTypeError(`Attribute frequency is not a CompoundingFrequency.CompoundingFrequency`);
        }
    };
    DetailCellType.CompoundingFrequencyType = CompoundingFrequencyType;
    CompoundingFrequencyType.prototype.toString = function toString() {
        return `DetailCellType.COMPOUNDING_FREQUENCY_TYPE(frequency=${this.frequency})`;
    };
    class TranslationKey {
        constructor(key) {
            this.isTranslationKey = true;
            TranslationKey.validateKey(key);
            this.key = key;
        }
        static fromDTO(dto) {
            const key = (0, leo_ts_runtime_1.getString)(dto, "key", InvalidDetailCellTypeError);
            return new TranslationKey(key);
        }
        toDTO() {
            const ret = {
                case: "TRANSLATION_KEY",
                key: this.key,
            };
            return ret;
        }
        copy(key = this.key) {
            return new TranslationKey(key);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isTranslationKeyValue = other.isTranslationKey;
            if (!(other instanceof TranslationKey) || Boolean(isTranslationKeyValue)) {
                return false;
            }
            if (this.key !== other.key) {
                return false;
            }
            return true;
        }
    }
    TranslationKey.validateKey = (key) => {
        if (!(0, leo_ts_runtime_1.isString)(key)) {
            throw new InvalidDetailCellTypeError(`Attribute key is not a String`);
        }
        if (key.trim().length === 0) {
            throw new InvalidDetailCellTypeError(`Attribute key cannot be an empty string.`);
        }
    };
    DetailCellType.TranslationKey = TranslationKey;
    TranslationKey.prototype.toString = function toString() {
        return `DetailCellType.TRANSLATION_KEY(key='${this.key}')`;
    };
    class None {
        constructor() {
            this.isNone = true;
        }
        static fromDTO(dto) {
            return new None();
        }
        toDTO() {
            const ret = {
                case: "NONE",
            };
            return ret;
        }
        copy() {
            return new None();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isNoneValue = other.isNone;
            if (!(other instanceof None) || Boolean(isNoneValue)) {
                return false;
            }
            return true;
        }
    }
    DetailCellType.None = None;
    None.prototype.toString = function toString() {
        return `DetailCellType.NONE()`;
    };
    DetailCellType.fromDTO = (dto) => {
        switch (dto.case) {
            case "TRANSACTION_TYPE":
                return TransactionType.fromDTO(dto);
            case "STATUS_TYPE":
                return StatusType.fromDTO(dto);
            case "QUANTITY_TYPE":
                return QuantityType.fromDTO(dto);
            case "PERCENTAGE_TYPE":
                return PercentageType.fromDTO(dto);
            case "AMOUNT_TYPE":
                return AmountType.fromDTO(dto);
            case "BIG_QUANTITY_TYPE":
                return BigQuantityType.fromDTO(dto);
            case "BIG_AMOUNT_TYPE":
                return BigAmountType.fromDTO(dto);
            case "UNSTYLED":
                return Unstyled.fromDTO(dto);
            case "TIME":
                return Time.fromDTO(dto);
            case "DATE":
                return Date.fromDTO(dto);
            case "DURATION":
                return Duration.fromDTO(dto);
            case "INTEREST":
                return Interest.fromDTO(dto);
            case "PAYOUT_FREQUENCY_TYPE":
                return PayoutFrequencyType.fromDTO(dto);
            case "COMPOUNDING_FREQUENCY_TYPE":
                return CompoundingFrequencyType.fromDTO(dto);
            case "TRANSLATION_KEY":
                return TranslationKey.fromDTO(dto);
            case "NONE":
                return None.fromDTO(dto);
            default:
                throw new InvalidDetailCellTypeError(`Case ${dto.case} is not valid case of DetailCellType.`);
        }
    };
    DetailCellType.isInstanceOf = (other) => {
        const isTRANSACTION_TYPEValue = other.isTRANSACTION_TYPE;
        if (other instanceof TransactionType || Boolean(isTRANSACTION_TYPEValue)) {
            return true;
        }
        const isSTATUS_TYPEValue = other.isSTATUS_TYPE;
        if (other instanceof StatusType || Boolean(isSTATUS_TYPEValue)) {
            return true;
        }
        const isQUANTITY_TYPEValue = other.isQUANTITY_TYPE;
        if (other instanceof QuantityType || Boolean(isQUANTITY_TYPEValue)) {
            return true;
        }
        const isPERCENTAGE_TYPEValue = other.isPERCENTAGE_TYPE;
        if (other instanceof PercentageType || Boolean(isPERCENTAGE_TYPEValue)) {
            return true;
        }
        const isAMOUNT_TYPEValue = other.isAMOUNT_TYPE;
        if (other instanceof AmountType || Boolean(isAMOUNT_TYPEValue)) {
            return true;
        }
        const isBIG_QUANTITY_TYPEValue = other.isBIG_QUANTITY_TYPE;
        if (other instanceof BigQuantityType || Boolean(isBIG_QUANTITY_TYPEValue)) {
            return true;
        }
        const isBIG_AMOUNT_TYPEValue = other.isBIG_AMOUNT_TYPE;
        if (other instanceof BigAmountType || Boolean(isBIG_AMOUNT_TYPEValue)) {
            return true;
        }
        const isUNSTYLEDValue = other.isUNSTYLED;
        if (other instanceof Unstyled || Boolean(isUNSTYLEDValue)) {
            return true;
        }
        const isTIMEValue = other.isTIME;
        if (other instanceof Time || Boolean(isTIMEValue)) {
            return true;
        }
        const isDATEValue = other.isDATE;
        if (other instanceof Date || Boolean(isDATEValue)) {
            return true;
        }
        const isDURATIONValue = other.isDURATION;
        if (other instanceof Duration || Boolean(isDURATIONValue)) {
            return true;
        }
        const isINTERESTValue = other.isINTEREST;
        if (other instanceof Interest || Boolean(isINTERESTValue)) {
            return true;
        }
        const isPAYOUT_FREQUENCY_TYPEValue = other.isPAYOUT_FREQUENCY_TYPE;
        if (other instanceof PayoutFrequencyType || Boolean(isPAYOUT_FREQUENCY_TYPEValue)) {
            return true;
        }
        const isCOMPOUNDING_FREQUENCY_TYPEValue = other.isCOMPOUNDING_FREQUENCY_TYPE;
        if (other instanceof CompoundingFrequencyType || Boolean(isCOMPOUNDING_FREQUENCY_TYPEValue)) {
            return true;
        }
        const isTRANSLATION_KEYValue = other.isTRANSLATION_KEY;
        if (other instanceof TranslationKey || Boolean(isTRANSLATION_KEYValue)) {
            return true;
        }
        const isNONEValue = other.isNONE;
        if (other instanceof None || Boolean(isNONEValue)) {
            return true;
        }
        return false;
    };
})(DetailCellType = exports.DetailCellType || (exports.DetailCellType = {}));
