import { Entity, GetMFSellEntityListRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult, LeoUUID } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFSellEntityListRPCImpl extends GetMFSellEntityListRPC {
  execute(
    _request: GetMFSellEntityListRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFSellEntityListRPC.Response,
      GetMFSellEntityListRPC.Errors.Errors
    >
  > {
    const response = new GetMFSellEntityListRPC.Response([
      new Entity(new LeoUUID(), "Udit"),
      new Entity(new LeoUUID(), "Deepak"),
    ]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFSellEntityListRPC.Response,
        GetMFSellEntityListRPC.Errors.Errors
      >
    >;
  }
}
