"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmitMFConfirmationLinkRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class SubmitMFConfirmationLinkRequestRPC {
}
exports.SubmitMFConfirmationLinkRequestRPC = SubmitMFConfirmationLinkRequestRPC;
(function (SubmitMFConfirmationLinkRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    SubmitMFConfirmationLinkRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(mfConfiramtionId, mfDealRequestId, note = null) {
            this.isSubmitMFConfirmationLinkRequestRPCRequest = true;
            Request.validateMfConfiramtionId(mfConfiramtionId);
            this.mfConfiramtionId = mfConfiramtionId;
            Request.validateMfDealRequestId(mfDealRequestId);
            this.mfDealRequestId = mfDealRequestId;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const mfConfiramtionId = (0, leo_ts_runtime_1.getInt64)(dto, "mfConfiramtionId", InvalidRequestError);
            const mfDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "mfDealRequestId", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(mfConfiramtionId, mfDealRequestId, note);
        }
        toDTO() {
            const ret = {
                mfConfiramtionId: this.mfConfiramtionId,
                mfDealRequestId: this.mfDealRequestId,
            };
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(mfConfiramtionId = this.mfConfiramtionId, mfDealRequestId = this.mfDealRequestId, note = this.note) {
            return new Request(mfConfiramtionId, mfDealRequestId, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isSubmitMFConfirmationLinkRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.mfConfiramtionId !== other.mfConfiramtionId) {
                return false;
            }
            if (this.mfDealRequestId !== other.mfDealRequestId) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateMfConfiramtionId = (mfConfiramtionId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(mfConfiramtionId)) {
            throw new InvalidRequestError(`Attribute mfConfiramtionId is not an Int64`);
        }
    };
    Request.validateMfDealRequestId = (mfDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(mfDealRequestId)) {
            throw new InvalidRequestError(`Attribute mfDealRequestId is not an Int64`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    SubmitMFConfirmationLinkRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `SubmitMFConfirmationLinkRequestRPC.Request(mfConfiramtionId=${this.mfConfiramtionId},mfDealRequestId=${this.mfDealRequestId},note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    SubmitMFConfirmationLinkRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    SubmitMFConfirmationLinkRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidConfirmationId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_CONFIRMATION_ID") {
                super(code);
                this.isInvalidConfirmationId = true;
                InvalidConfirmationId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidConfirmationId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidConfirmationId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidConfirmationIdValue = other.isInvalidConfirmationId;
                if (!(other instanceof InvalidConfirmationId || Boolean(isInvalidConfirmationIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidConfirmationId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_CONFIRMATION_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidConfirmationId = InvalidConfirmationId;
        InvalidConfirmationId.prototype.toString = function toString() {
            return `SubmitMFConfirmationLinkRequestRPC.INVALID_CONFIRMATION_ID()`;
        };
        class InvalidDealRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DEAL_REQUEST_ID") {
                super(code);
                this.isInvalidDealRequestId = true;
                InvalidDealRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDealRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDealRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
                if (!(other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDealRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DEAL_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDealRequestId = InvalidDealRequestId;
        InvalidDealRequestId.prototype.toString = function toString() {
            return `SubmitMFConfirmationLinkRequestRPC.INVALID_DEAL_REQUEST_ID()`;
        };
        class LinkRequestAlreadyExists extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "LINK_REQUEST_ALREADY_EXISTS") {
                super(code);
                this.isLinkRequestAlreadyExists = true;
                LinkRequestAlreadyExists.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new LinkRequestAlreadyExists(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new LinkRequestAlreadyExists(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isLinkRequestAlreadyExistsValue = other.isLinkRequestAlreadyExists;
                if (!(other instanceof LinkRequestAlreadyExists || Boolean(isLinkRequestAlreadyExistsValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        LinkRequestAlreadyExists.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "LINK_REQUEST_ALREADY_EXISTS") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.LinkRequestAlreadyExists = LinkRequestAlreadyExists;
        LinkRequestAlreadyExists.prototype.toString = function toString() {
            return `SubmitMFConfirmationLinkRequestRPC.LINK_REQUEST_ALREADY_EXISTS()`;
        };
        class ConfirmationAlreadyLinked extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CONFIRMATION_ALREADY_LINKED") {
                super(code);
                this.isConfirmationAlreadyLinked = true;
                ConfirmationAlreadyLinked.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new ConfirmationAlreadyLinked(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new ConfirmationAlreadyLinked(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isConfirmationAlreadyLinkedValue = other.isConfirmationAlreadyLinked;
                if (!(other instanceof ConfirmationAlreadyLinked || Boolean(isConfirmationAlreadyLinkedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        ConfirmationAlreadyLinked.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CONFIRMATION_ALREADY_LINKED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.ConfirmationAlreadyLinked = ConfirmationAlreadyLinked;
        ConfirmationAlreadyLinked.prototype.toString = function toString() {
            return `SubmitMFConfirmationLinkRequestRPC.CONFIRMATION_ALREADY_LINKED()`;
        };
        class IllegalConfirmationState extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "ILLEGAL_CONFIRMATION_STATE") {
                super(code);
                this.isIllegalConfirmationState = true;
                IllegalConfirmationState.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new IllegalConfirmationState(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new IllegalConfirmationState(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isIllegalConfirmationStateValue = other.isIllegalConfirmationState;
                if (!(other instanceof IllegalConfirmationState || Boolean(isIllegalConfirmationStateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        IllegalConfirmationState.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "ILLEGAL_CONFIRMATION_STATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.IllegalConfirmationState = IllegalConfirmationState;
        IllegalConfirmationState.prototype.toString = function toString() {
            return `SubmitMFConfirmationLinkRequestRPC.ILLEGAL_CONFIRMATION_STATE()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_CONFIRMATION_ID":
                    return InvalidConfirmationId.fromDTO(dto);
                case "INVALID_DEAL_REQUEST_ID":
                    return InvalidDealRequestId.fromDTO(dto);
                case "LINK_REQUEST_ALREADY_EXISTS":
                    return LinkRequestAlreadyExists.fromDTO(dto);
                case "CONFIRMATION_ALREADY_LINKED":
                    return ConfirmationAlreadyLinked.fromDTO(dto);
                case "ILLEGAL_CONFIRMATION_STATE":
                    return IllegalConfirmationState.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidConfirmationIdValue = other.isInvalidConfirmationId;
            if (other instanceof InvalidConfirmationId || Boolean(isInvalidConfirmationIdValue)) {
                return true;
            }
            const isInvalidDealRequestIdValue = other.isInvalidDealRequestId;
            if (other instanceof InvalidDealRequestId || Boolean(isInvalidDealRequestIdValue)) {
                return true;
            }
            const isLinkRequestAlreadyExistsValue = other.isLinkRequestAlreadyExists;
            if (other instanceof LinkRequestAlreadyExists || Boolean(isLinkRequestAlreadyExistsValue)) {
                return true;
            }
            const isConfirmationAlreadyLinkedValue = other.isConfirmationAlreadyLinked;
            if (other instanceof ConfirmationAlreadyLinked || Boolean(isConfirmationAlreadyLinkedValue)) {
                return true;
            }
            const isIllegalConfirmationStateValue = other.isIllegalConfirmationState;
            if (other instanceof IllegalConfirmationState || Boolean(isIllegalConfirmationStateValue)) {
                return true;
            }
            return false;
        };
    })(Errors = SubmitMFConfirmationLinkRequestRPC.Errors || (SubmitMFConfirmationLinkRequestRPC.Errors = {}));
})(SubmitMFConfirmationLinkRequestRPC = exports.SubmitMFConfirmationLinkRequestRPC || (exports.SubmitMFConfirmationLinkRequestRPC = {}));
