import { Instance, types } from "mobx-state-tree";
import {
  createRequestNoteModel,
  RequestNoteModel,
} from "../../../models/RequestNoteModel";
import {
  ContractNoteRequestStatus,
  EquityContractNoteHistoryDetail,
} from "@khazana/khazana-rpcs";
import { AmountModel } from "../../../models/AmountModel";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { ContractNoteDetailType } from "./ContractNoteDetailType";
import {
  ContractNoteChargeType,
  createContractNoteChargeType,
} from "../../../models/ContractNoteChargeType";

export const ContractNoteDealRequestDetails = types.model({
  dematAccountNumber: types.string,
  entity: types.string,
  amount: types.maybeNull(AmountModel),
  quantity: types.maybeNull(types.number),
  transactionType: types.string,
  symbol: types.string,
  requestId: types.number,
  status: types.string,
});

export const EquityContractNoteHistoryDetailModel = types.model(
  "EquityDealRequestHistoryDetailModel",
  {
    requestNote: types.maybeNull(RequestNoteModel),
    status: types.maybeNull(
      types.enumeration<ContractNoteRequestStatus.ContractNoteRequestStatus>(
        "ContractNoteRequestStatus",
        Object.values(ContractNoteRequestStatus.ContractNoteRequestStatus),
      ),
    ),
    dealRequestDetails: types.maybeNull(ContractNoteDealRequestDetails),
    diffData: types.array(ContractNoteDetailType),
    charges: types.array(ContractNoteChargeType),
  },
);

export const createEquityContractNoteHistoryDetailModel = (
  historyDetails: EquityContractNoteHistoryDetail | null,
): Instance<typeof EquityContractNoteHistoryDetailModel> => {
  if (historyDetails) {
    const { status, requestNote, dealRequestDetails, diffDetails, charges } =
      historyDetails;
    let dealDetails: Instance<typeof ContractNoteDealRequestDetails> | null =
      null;
    if (dealRequestDetails) {
      dealDetails = ContractNoteDealRequestDetails.create({
        dematAccountNumber: dealRequestDetails?.dematAccountNumber,
        entity: dealRequestDetails.entity,
        amount: dealRequestDetails.amount
          ? AmountModel.create({
              amount: dealRequestDetails.amount.amount,
              currency: CurrencyModel.create({
                code: dealRequestDetails.amount.currency.code,
                symbol: dealRequestDetails.amount.currency.symbol,
              }),
            })
          : null,
        quantity: dealRequestDetails.quantity,
        transactionType: dealRequestDetails.transactionType,
        symbol: dealRequestDetails.symbol,
        requestId: dealRequestDetails.requestId,
        status: dealRequestDetails.requestStatus,
      });
    }
    return EquityContractNoteHistoryDetailModel.create({
      requestNote: createRequestNoteModel(requestNote),
      status,
      dealRequestDetails: dealDetails,
      diffData:
        diffDetails.map((detail) =>
          ContractNoteDetailType.create({
            diffData: detail.diffData ? detail.diffData : null,
            originalData: detail.originalData,
            localizedTextId: detail.localizedTextId,
          }),
        ) ?? [],
      charges: charges.map((charge) => createContractNoteChargeType(charge)),
    });
  } else {
    return EquityContractNoteHistoryDetailModel.create();
  }
};
