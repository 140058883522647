import { Instance } from "mobx-state-tree";
import { CreateFiDealRequestStore } from "./CreateFiDealRequestStore";
import { useFiStore } from "../../store/hooks";
import { FiDealRequestsStore } from "./FiDealRequestsStore";
import { ViewFiDealRequestDetailsStore } from "./ViewFiDealRequestDetailsStore";
import { FiMaturityDealRequestsStore } from "./FiMaturityDealRequestsStore";
import { ViewFiMaturityRequestDetailsStore } from "./ViewFiMaturityRequestDetailsStore";
import { FiMaturitySettleStore } from "./FiMaturitySettleStore";

export const useCreateFiDealRequestStore = (): Instance<
  typeof CreateFiDealRequestStore
> => {
  return useFiStore().createFiDealRequestStore;
};

export const useFiDealRequestsStore = (): Instance<
  typeof FiDealRequestsStore
> => {
  return useFiStore().dealRequestsStore;
};

export const useViewFiMaturityRequestDetailsStore = (): Instance<
  typeof ViewFiMaturityRequestDetailsStore
> => {
  return useFiStore().fiMaturityRequestDetailsStore;
};

export const useFiMaturityDealRequestsStore = (): Instance<
  typeof FiMaturityDealRequestsStore
> => {
  return useFiStore().maturityDealRequestsStore;
};

export const useViewFiDealRequestDetailsStore = (): Instance<
  typeof ViewFiDealRequestDetailsStore
> => {
  return useFiStore().fiDealRequestDetailsStore;
};

export const useFiMaturitySettleStore = (): Instance<
  typeof FiMaturitySettleStore
> => {
  return useFiStore().maturitySettleStore;
};
