import { Instance, types } from "mobx-state-tree";
import {
  createEQChanageInGainStore,
  EQChangeInGainStore,
} from "./EQChangeInGainStore";
import {
  createEQHoldingSummaryStore,
  EQHoldingSummaryStore,
} from "./EQHoldingSummaryStore";

export const EQReportsStore = types.model("EQReportsStore", {
  eqChangeInGainStore: EQChangeInGainStore,
  eqHoldingSummaryStore: EQHoldingSummaryStore,
});

export const createEQReportsStore = (): Instance<typeof EQReportsStore> => {
  return EQReportsStore.create({
    eqChangeInGainStore: createEQChanageInGainStore(),
    eqHoldingSummaryStore: createEQHoldingSummaryStore(),
  });
};
