import { observer } from "mobx-react";
import { Instance } from "mobx-state-tree";
import React from "react";
import {
  Chip,
  PageHeader,
  Table,
  TableHeader,
  TableOptions,
  TableRowItems,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { MFTransactionType } from "@khazana/khazana-rpcs";
import { getFormattedTimeDateWithComma } from "../../../../../utils";
import { DealRequestsSearchFilter } from "../components/DealRequestsSearchFilter";
import { getRequestStatusValue } from "../utils/SearchUtils";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { useMFDealRequestsStore } from "../store/DealRequestListStore/hooks";
import { MFDealRequestsFilterOptionsModel } from "../store/DealRequestListStore/MFDealRequestsStore";
import { getMFTransactionText } from "../utils/UIUtils";

const Size = {
  table: {
    default: "max-content",
    timestamp: "230px",
    status: "200px",
    entity: "200px",
    scheme: "250px",
  },
};

export const ManageDealRequests = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const store = useMFDealRequestsStore();
  const spacing = useSpacing();
  const navigate = useNavigate();

  const getDealRequests = async (
    option: TableOptions<Instance<typeof MFDealRequestsFilterOptionsModel>>,
    setTotalItems: React.Dispatch<React.SetStateAction<number>>,
  ): Promise<string | TableRowItems> => {
    if (option.filter) store.updateFilterOptions(option.filter);
    await store.getMFDealRequests(
      option.page ? option.page - 1 : 0,
      store.itemsPerPage(),
      option.sort?.order,
    );
    setTotalItems(store.totalItems);

    return store.requests.map((request) => {
      return [
        {
          data: getRequestStatusValue(
            request.dealRequestStatus,
          ).toLocaleUpperCase(),
        },
        {
          data: request.scheme,
        },
        {
          data: (
            <Chip
              label={getMFTransactionText(request.transactionType)}
              color={
                request.transactionType ===
                MFTransactionType.MFTransactionType.MF_PURCHASE
                  ? "blueSubtle"
                  : "orangeSubtle"
              }
            />
          ),
        },
        {
          data: request.amount
            ? t("common.numberFormat", { val: request.amount })
            : "-",
          align: "right",
        },
        {
          data: request.units
            ? t("common.numberFormat", { val: request.units })
            : "-",
          align: "right",
        },
        {
          data: t("common.decimal2", { val: request.navPerUnit }),
          align: "right",
        },
        {
          data: request.entity,
        },
        {
          data: request.portfolio ?? "-",
        },
        {
          data: request.folioNumber ?? "-",
        },
        {
          data: request.requestId,
        },
        {
          data: getFormattedTimeDateWithComma(request.receivedAt),
        },
      ];
    });
  };

  const getHeaders = (): TableHeader => {
    return [
      {
        id: "requestStatus",
        name: t("common.requestStatus"),
        width: Size.table.status,
      },
      {
        id: "scheme",
        name: t("mf.fields.schemeLabel"),
        width: Size.table.scheme,
      },
      {
        id: "transactionType",
        name: t("common.transactionType"),
        width: Size.table.default,
      },
      {
        id: "amount",
        name: t("common.amountWithPostfixLabel", {
          val: store.currencySymbol,
        }),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "units",
        name: t("mf.fields.unitsLabel"),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "nav",
        name: t("mf.fields.navPerUnitLabel", {
          currencySymbol: store.currencySymbol
            ? `(${store.currencySymbol})`
            : null,
        }),
        width: Size.table.default,
        align: "right",
      },
      {
        id: "entity",
        name: t("common.entity"),
        width: Size.table.entity,
      },
      {
        id: "portfolio",
        name: t("common.portfolio"),
        width: Size.table.default,
      },
      {
        id: "folioNumber",
        name: t("mf.fields.folioNumberLabel"),
        width: Size.table.default,
      },
      {
        id: "requestId",
        name: t("common.requestId"),
        width: Size.table.default,
      },
      {
        id: "requestedAt",
        name: t("mf.fields.requestedAtLabel"),
        width: Size.table.timestamp,
        sortable: true,
      },
    ];
  };

  return (
    <>
      <PageHeader title={t("mf.manageDealRequests.pageTitle")} />
      <Box margin={spacing.space2XL}>
        <Table
          name="mfDealRequestsTable"
          styleOverrides={{
            divider: "cell",
          }}
          headers={getHeaders()}
          onTableOptionsChange={getDealRequests}
          viewOverrides={{
            empty: { message: t("common.noResultsFound") },
            idle: { message: t("common.searchTableIdleState") },
            loading: { message: t("common.searchTableLoadingState") },
          }}
          paginationOption={{
            itemsPerPage: store.itemsPerPage(),
            getPageIndicatorText(startItem, endItem, totalItems): string {
              return t("common.paginationIndicationText", {
                startItem,
                endItem,
                totalItems,
              });
            },
          }}
          filterOption={{
            initialFilterValue: store.filterOptions,
            filterComponent(filter, setFilter): React.ReactElement {
              return (
                <DealRequestsSearchFilter
                  filter={filter}
                  setFilter={setFilter}
                />
              );
            },
          }}
          onRowClick={(_, index): void => {
            const selectedRequestId = store.requests[index].requestId;
            navigate(
              getPath(
                Module.MF,
                Route.ManageDealRequestDetailsWithParams,
                selectedRequestId.toString(),
              ),
            );
          }}
        />
      </Box>
    </>
  );
});
