"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DetailCell = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const detailCellType_1 = require("./detailCellType");
class InvalidDetailCellError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidDetailCellError";
    }
}
class DetailCell {
    constructor(localizedTextId, cellType) {
        this.isDetailCell = true;
        DetailCell.validateLocalizedTextId(localizedTextId);
        this.localizedTextId = localizedTextId;
        DetailCell.validateCellType(cellType);
        this.cellType = cellType;
    }
    static fromDTO(dto) {
        const localizedTextId = (0, leo_ts_runtime_1.getString)(dto, "localizedTextId", InvalidDetailCellError);
        const cellType = detailCellType_1.DetailCellType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "cellType", InvalidDetailCellError));
        return new DetailCell(localizedTextId, cellType);
    }
    toDTO() {
        const ret = {
            localizedTextId: this.localizedTextId,
            cellType: this.cellType.toDTO(),
        };
        return ret;
    }
    copy(localizedTextId = this.localizedTextId, cellType = this.cellType) {
        return new DetailCell(localizedTextId, cellType);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isDetailCellValue = other.isDetailCell;
        if (!(other instanceof DetailCell) || Boolean(isDetailCellValue)) {
            return false;
        }
        if (this.localizedTextId !== other.localizedTextId) {
            return false;
        }
        if (!this.cellType.equals(other.cellType)) {
            return false;
        }
        return true;
    }
}
exports.DetailCell = DetailCell;
DetailCell.validateLocalizedTextId = (localizedTextId) => {
    if (!(0, leo_ts_runtime_1.isString)(localizedTextId)) {
        throw new InvalidDetailCellError(`Attribute localizedTextId is not a String`);
    }
    if (localizedTextId.trim().length === 0) {
        throw new InvalidDetailCellError(`Attribute localizedTextId cannot be an empty string.`);
    }
};
DetailCell.validateCellType = (cellType) => {
    if (!detailCellType_1.DetailCellType.isInstanceOf(cellType)) {
        throw new InvalidDetailCellError(`Attribute cellType is not a DetailCellType.DetailCellType`);
    }
};
DetailCell.prototype.toString = function toString() {
    return `DetailCell(localizedTextId='${this.localizedTextId}',cellType=${this.cellType})`;
};
