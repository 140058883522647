import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  spacing,
  useCornerRadius,
  useFoundationColorTokens,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useBorder } from "../../../../utils/BorderUtils";
import { DoughnutChart, DoughnutSeriesItem } from "../charts/DoughnutChart";

export interface DashboardDoughnutGraphProps {
  title: string;
  series: DoughnutSeriesItem[];
}
export const DashboardDoughnutGraph = ({
  title,
  series,
}: DashboardDoughnutGraphProps): React.ReactElement => {
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const typography = useTypography();
  return (
    <Stack
      key={series.length}
      sx={{
        flexGrow: 1,
        border: border.default,
        background: tokens.backgroundElevatedLevel1,
        borderRadius: cornerRadius.radiusXS,
      }}
    >
      <Stack
        sx={{
          padding: `${spacing.spaceMD} ${spacing.spaceXL}`,
          borderBottom: border.default,
        }}
      >
        <Typography
          sx={{ typography: typography.sh3, color: tokens.labelHighEmphasis }}
        >
          {title}
        </Typography>
      </Stack>
      <Stack justifyContent={"center"} alignItems={"center"}>
        <DoughnutChart series={series} height={"large"} width={"small"} />
      </Stack>
    </Stack>
  );
};
