import {
  FiContractNoteSearchBy,
  FiContractNoteRequestStatus,
} from "@khazana/khazana-rpcs";
import { DropdownItem } from "@surya-digital/leo-reactjs-core";
import i18next, { TFunction } from "i18next";
import { Instance } from "mobx-state-tree";
import { AllEnum } from "../../../../../types/EnumTypes";
import { FiContractNotesFilterOptionsModel } from "../models/FiContractNotesFilterOptionsModel";

export const getFiContractNotesInitialFilter = (): Instance<
  typeof FiContractNotesFilterOptionsModel
> => {
  return FiContractNotesFilterOptionsModel.create({
    searchBy: FiContractNoteSearchBy.FiContractNoteSearchBy.SECURITY,
    searchText: "",
    status: AllEnum.All,
    brokerId: AllEnum.All,
  });
};

export const getFiContractNotesSearchByValue = (
  t: TFunction,
  value: FiContractNoteSearchBy.FiContractNoteSearchBy,
): string => {
  const searchByType = FiContractNoteSearchBy.FiContractNoteSearchBy;
  switch (value) {
    case searchByType.REQUEST_ID:
      return t("common.requestId");
    case searchByType.CONTRACT_NOTE_NUMBER:
      return t("fi.contractNotes.contractNoteNumber");
    case searchByType.SECURITY:
      return t("fi.security");
  }
};

export const getFiContractNoteRequestStatusValue = (
  value: FiContractNoteRequestStatus.FiContractNoteRequestStatus,
): string => {
  const statusType = FiContractNoteRequestStatus.FiContractNoteRequestStatus;
  switch (value) {
    case statusType.PARSED:
      return i18next.t("contractNotes.parsed");
    case statusType.UNPARSED:
      return i18next.t("contractNotes.unparsed");
    case statusType.DATA_MISMATCH:
      return i18next.t("contractNotes.dataMismatch");
    case statusType.ACCOUNTS_PENDING:
      return i18next.t("contractNotes.accountsPending");
    case statusType.ACCOUNTS_APPROVED:
      return i18next.t("contractNotes.accountsApproved");
    case statusType.ACCOUNTS_REJECTED:
      return i18next.t("contractNotes.accountsRejected");
    case statusType.LINKING_PENDING:
      return i18next.t("contractNotes.linkingPending");
    case statusType.LINKED:
      return i18next.t("contractNotes.linked");
    case statusType.UNKNOWN_REQUESTED:
      return i18next.t("contractNotes.unknownRequested");
    case statusType.UNKNOWN:
      return i18next.t("contractNotes.unknown");
    case statusType.DISCARD_PENDING:
      return i18next.t("contractNotes.discardPending");
    case statusType.DISCARDED:
      return i18next.t("contractNotes.discarded");
  }
};

export const getFiContractNoteStatusOptionsWithAll = (
  t: TFunction,
): DropdownItem[] => {
  const values = Object.keys(
    FiContractNoteRequestStatus.FiContractNoteRequestStatus,
  );
  const dropdownItems = values.map((value: string): DropdownItem => {
    const name = getFiContractNoteRequestStatusValue(
      value as FiContractNoteRequestStatus.FiContractNoteRequestStatus,
    );
    return { name, value };
  });
  return [
    {
      name: t("common.all"),
      value: AllEnum.All,
    },
    ...dropdownItems,
  ];
};

export const getFiContractNoteSearchByOptions = (
  t: TFunction,
): DropdownItem[] => {
  const values = Object.values(FiContractNoteSearchBy.FiContractNoteSearchBy);
  return values.map((value: string): DropdownItem => {
    const name = getFiContractNotesSearchByValue(
      t,
      value as FiContractNoteSearchBy.FiContractNoteSearchBy,
    );
    return { name, value };
  });
};
