import { observer } from "mobx-react";
import {
  Dialog,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useCornerRadius,
  Typography as TypographyProps,
  FoundationColorTokens,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { useFDDealRequestDetailsStore } from "../store/hooks";
import { useSearchParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import { getFormattedAmountString } from "../../../../../utils";
import { AmountTextField } from "../../../components/AmountTextField";
import { NoteTextArea } from "../../../components/NoteTextArea";
import { BorderStyle, useBorder } from "../../../../../utils/BorderUtils";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { WarningAlertComponent } from "../../../components/WarningAlertComponent";

export interface FDSettleDealDialogProps {
  isOpen: boolean;
  onClose: (reload?: boolean) => void;
}

const createFDSettleDealDialogStyle = (
  border: BorderStyle,
  typography: TypographyProps,
  spacing: Spacing,
  tokens: FoundationColorTokens<string>,
): {
  [key: string]: React.CSSProperties;
} => {
  return {
    rootContainer: {
      width: "100%",
      gap: spacing.spaceLG,
    },
    subtitleContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: border.default,
      paddingBottom: spacing.spaceXS,
      gap: spacing.spaceXS,
    },
    subtitle: {
      ...typography.s1,
    },
    rowLabel: {
      ...typography.s1,
      color: tokens.label,
    },
    rowValue: {
      ...typography.b1,
    },
    fieldContainer: {
      width: "100%",
      gap: spacing.spaceSM,
    },
  };
};

export const FDSettleDealDialog = observer(
  ({ isOpen, onClose }: FDSettleDealDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const cornerRadius = useCornerRadius();
    const border = useBorder();
    const spacing = useSpacing();
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const [searchParam] = useSearchParams();
    const requestId = searchParam.get("requestId");
    const store = useFDDealRequestDetailsStore().settleStore;

    const style = useMemo(
      () => createFDSettleDealDialogStyle(border, typography, spacing, tokens),
      [],
    );

    const getSettleForm = (): React.ReactElement => {
      return (
        <Stack sx={style.rootContainer}>
          {store.showWarning && (
            <WarningAlertComponent
              text={t("fd.dealRequest.errors.settleDealWarningMessage")}
            />
          )}
          <Stack sx={style.subtitleContainer}>
            <Typography sx={style.subtitle}>
              {t("fd.fields.outstandingDepositAmountLabel", {
                currencySymbol: store.currencySymbol,
              })}
            </Typography>
            <Typography sx={style.subtitle}>
              {getFormattedAmountString(store.outstandingDepositAmount ?? 0)}
            </Typography>
          </Stack>
          <Stack sx={style.fieldContainer} direction={"row"}>
            {store.isOnMaturity && (
              <AmountTextField
                name={"totalInterestReceived"}
                label={t("fd.fields.totalInterestReceivedLabel", {
                  currencySymbol: store.currencySymbol,
                })}
                error={store.totalInterestAmountError}
                placeholder={t("fd.fields.totalInterestReceivedPlaceholder")}
                isRequired
                value={store.totalInterestAmount}
                onAmountChange={store.setTotalInterestAmount}
                isDecimalAllowed={true}
              />
            )}
            <AmountTextField
              name={"totalPrincipalReceived"}
              label={t("fd.fields.totalAmountReceivedLabel", {
                currencySymbol: store.currencySymbol,
              })}
              placeholder={t("fd.fields.totalAmountReceivedPlaceholder")}
              isRequired
              error={store.totalPrincipalAmountError !== undefined}
              helperText={
                store.totalPrincipalAmountError
                  ? t("common.shouldBe", {
                      val:
                        store.currency?.symbol +
                        getFormattedAmountString(
                          Number(store.totalPrincipalAmountError),
                        ),
                    })
                  : ""
              }
              value={store.totalPrincipalAmount}
              onAmountChange={store.setTotalPrincipalAmount}
              isDecimalAllowed={true}
            />
          </Stack>
          <Stack sx={style.fieldContainer}>
            <NoteTextArea note={store.note} setNote={store.setNote} />
          </Stack>
        </Stack>
      );
    };

    return (
      <Dialog
        open={isOpen}
        title={t("common.settleDealRequest")}
        primaryButtonText={t("common.submit")}
        onPrimaryButtonClick={async (): Promise<void> => {
          if (store.isValid()) {
            await store.settleFdDealRequest(Number(requestId));
            if (!store.totalPrincipalAmountError) {
              store.reset();
              onClose(true);
            }
          }
        }}
        secondaryButtonText={t("common.cancel")}
        onSecondaryButtonClick={() => {
          store.reset();
          onClose();
        }}
        isCloseIconPresent={false}
        onClose={(): void => {
          store.reset();
          onClose();
        }}
        disableBackdropClick={true}
        style={{
          borderRadius: `${cornerRadius.radiusXXS}px`,
        }}
      >
        {getSettleForm()}
      </Dialog>
    );
  },
);
