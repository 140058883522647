import { Instance } from "mobx-state-tree";
import { DetailCellType } from "@khazana/khazana-rpcs";
import { DetailsRowProps } from "@surya-digital/leo-reactjs-material-ui";
import i18next from "i18next";
import { DiffDetailType } from "../models/DiffDetailType";
import {
  CellType,
  getCellValueFromCellType,
  getTransactionTypeColor,
  getTransactionTypeText,
} from "./DetailCellUtils";
import { DetailRowCellEnum } from "@surya-digital/leo-reactjs-core";
import { Module } from "../../../routes/RoutesEnum";

export interface DiffCellType {
  value: CellType;
  diffValue: CellType;
  hidden: boolean;
}

export const getDiffDetailCellValue = (
  items: Instance<typeof DiffDetailType>[],
  key: string,
): DiffCellType | undefined => {
  const cell = items.find((item) => item.label === key);
  if (!cell) {
    return { value: undefined, diffValue: undefined, hidden: true };
  }
  const originalData = getCellValueFromCellType(cell.originalData);
  const diffValue = cell.diffData
    ? getCellValueFromCellType(cell.diffData)
    : undefined;
  return {
    value: originalData.value,
    diffValue: diffValue?.value,
    hidden: originalData.hidden,
  };
};

export const getDiffDetailRow = (
  items: Instance<typeof DiffDetailType>[],
  module: Module,
): DetailsRowProps[] => {
  const rows: DetailsRowProps[] = [];
  items.forEach((item) => {
    const originalData = getCellValueFromCellType(item.originalData);
    const diffData = item.diffData
      ? getCellValueFromCellType(item.diffData)
      : undefined;
    if (item.originalData instanceof DetailCellType.TransactionType) {
      rows.push({
        /* @ts-ignore */
        label: i18next.t(item.label),
        cellType: {
          type: DetailRowCellEnum.Chip,
          chips: [
            {
              label: getTransactionTypeText(module, item.originalData.text),
              color: getTransactionTypeColor(module, item.originalData.text),
            },
          ],
          updatedChips: diffData
            ? [
                {
                  label: getTransactionTypeText(
                    module,
                    diffData.value as string,
                  ),
                  color: getTransactionTypeColor(
                    module,
                    diffData.value as string,
                  ),
                },
              ]
            : undefined,
        },
      });
    } else if (item.originalData instanceof DetailCellType.BigQuantityType) {
      const originalValue = originalData.value as string | undefined;
      const diffValue = diffData?.value as string | undefined;
      rows.push({
        // @ts-ignore
        label: i18next.t(item.label),
        cellType: {
          text: originalValue ?? "-",
          type: DetailRowCellEnum.Unstyled,
          diffText: diffValue,
        },
      });
    } else if (item.originalData instanceof DetailCellType.BigAmountType) {
      const originalValue = originalData.value as string | undefined;
      const diffValue = diffData?.value as string | undefined;
      rows.push({
        // @ts-ignore
        label: i18next.t(item.label, {
          val: item.originalData.amount?.currency?.symbol,
        }),
        cellType: {
          text: originalValue ?? "-",
          type: DetailRowCellEnum.Unstyled,
          diffText: diffValue,
        },
      });
    } else {
      if (!originalData.hidden) {
        rows.push({
          // @ts-ignore
          label: i18next.t(item.label),
          cellType: {
            text: (originalData.value as string | undefined) ?? "-",
            type: DetailRowCellEnum.Unstyled,
            diffText: diffData?.value ? (diffData.value as string) : undefined,
          },
        });
      }
    }
  });
  return rows;
};
