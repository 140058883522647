"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquityTopTenRealisedGainsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const equityRealisedGain_1 = require("./equityRealisedGain");
const currency_1 = require("../types/currency");
const equityReportDateRange_1 = require("./equityReportDateRange");
/* eslint-disable import/export */
class GetEquityTopTenRealisedGainsRPC {
}
exports.GetEquityTopTenRealisedGainsRPC = GetEquityTopTenRealisedGainsRPC;
(function (GetEquityTopTenRealisedGainsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetEquityTopTenRealisedGainsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(entityId = null, portfolioId = null, range) {
            this.isGetEquityTopTenRealisedGainsRPCRequest = true;
            if (entityId !== undefined && entityId !== null) {
                Request.validateEntityId(entityId);
            }
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
            Request.validateRange(range);
            this.range = range;
        }
        static fromDTO(dto) {
            const entityId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const range = equityReportDateRange_1.EquityReportDateRange.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "range", InvalidRequestError));
            return new Request(entityId, portfolioId, range);
        }
        toDTO() {
            const ret = {
                range: this.range.toDTO(),
            };
            if (this.entityId) {
                ret.entityId = this.entityId.toDTO();
            }
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            return ret;
        }
        copy(entityId = this.entityId, portfolioId = this.portfolioId, range = this.range) {
            return new Request(entityId, portfolioId, range);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetEquityTopTenRealisedGainsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.entityId) {
                if (!this.entityId.equals(other.entityId)) {
                    return false;
                }
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            if (!this.range.equals(other.range)) {
                return false;
            }
            return true;
        }
    }
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateRange = (range) => {
        if (!equityReportDateRange_1.EquityReportDateRange.isInstanceOf(range)) {
            throw new InvalidRequestError(`Attribute range is not a EquityReportDateRange.EquityReportDateRange`);
        }
    };
    GetEquityTopTenRealisedGainsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetEquityTopTenRealisedGainsRPC.Request(entityId=${this.entityId},portfolioId=${this.portfolioId},range=${this.range})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetEquityTopTenRealisedGainsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(topTenRealisedGains, currency, totalRealisedGains, totalRealisedGainsPercentage) {
            this.isGetEquityTopTenRealisedGainsRPCResponse = true;
            Response.validateTopTenRealisedGains(topTenRealisedGains);
            this.topTenRealisedGains = topTenRealisedGains;
            Response.validateCurrency(currency);
            this.currency = currency;
            Response.validateTotalRealisedGains(totalRealisedGains);
            this.totalRealisedGains = totalRealisedGains;
            Response.validateTotalRealisedGainsPercentage(totalRealisedGainsPercentage);
            this.totalRealisedGainsPercentage = totalRealisedGainsPercentage;
        }
        static fromDTO(dto) {
            const topTenRealisedGains = (0, leo_ts_runtime_1.getList)(dto, "topTenRealisedGains", equityRealisedGain_1.EquityRealisedGain.fromDTO, InvalidResponseError);
            const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidResponseError));
            const totalRealisedGains = (0, leo_ts_runtime_1.getInt64)(dto, "totalRealisedGains", InvalidResponseError);
            const totalRealisedGainsPercentage = (0, leo_ts_runtime_1.getInt64)(dto, "totalRealisedGainsPercentage", InvalidResponseError);
            return new Response(topTenRealisedGains, currency, totalRealisedGains, totalRealisedGainsPercentage);
        }
        toDTO() {
            const ret = {
                topTenRealisedGains: this.topTenRealisedGains.map((e) => {
                    return e.toDTO();
                }),
                currency: this.currency.toDTO(),
                totalRealisedGains: this.totalRealisedGains,
                totalRealisedGainsPercentage: this.totalRealisedGainsPercentage,
            };
            return ret;
        }
        copy(topTenRealisedGains = this.topTenRealisedGains, currency = this.currency, totalRealisedGains = this.totalRealisedGains, totalRealisedGainsPercentage = this.totalRealisedGainsPercentage) {
            return new Response(topTenRealisedGains, currency, totalRealisedGains, totalRealisedGainsPercentage);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetEquityTopTenRealisedGainsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.topTenRealisedGains, other.topTenRealisedGains)) {
                return false;
            }
            if (!this.currency.equals(other.currency)) {
                return false;
            }
            if (this.totalRealisedGains !== other.totalRealisedGains) {
                return false;
            }
            if (this.totalRealisedGainsPercentage !== other.totalRealisedGainsPercentage) {
                return false;
            }
            return true;
        }
    }
    Response.validateTopTenRealisedGains = (topTenRealisedGains) => {
        if (!Array.isArray(topTenRealisedGains)) {
            throw new InvalidResponseError(`Attribute topTenRealisedGains is not a List`);
        }
        for (let i = 0; i < topTenRealisedGains.length; i += 1) {
            const isEquityRealisedGainValue = topTenRealisedGains[i].isEquityRealisedGain;
            if (!(topTenRealisedGains[i] instanceof equityRealisedGain_1.EquityRealisedGain || Boolean(isEquityRealisedGainValue))) {
                throw new InvalidResponseError(`Attribute topTenRealisedGains at index ${i} is not a EquityRealisedGain.`);
            }
        }
    };
    Response.validateCurrency = (currency) => {
        const isCurrencyValue = currency.isCurrency;
        if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
            throw new InvalidResponseError(`Attribute currency is not a Currency`);
        }
    };
    Response.validateTotalRealisedGains = (totalRealisedGains) => {
        if (!(0, leo_ts_runtime_1.isInt64)(totalRealisedGains)) {
            throw new InvalidResponseError(`Attribute totalRealisedGains is not an Int64`);
        }
    };
    Response.validateTotalRealisedGainsPercentage = (totalRealisedGainsPercentage) => {
        if (!(0, leo_ts_runtime_1.isInt64)(totalRealisedGainsPercentage)) {
            throw new InvalidResponseError(`Attribute totalRealisedGainsPercentage is not an Int64`);
        }
    };
    GetEquityTopTenRealisedGainsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetEquityTopTenRealisedGainsRPC.Response(topTenRealisedGains=${this.topTenRealisedGains},currency=${this.currency},totalRealisedGains=${this.totalRealisedGains},totalRealisedGainsPercentage=${this.totalRealisedGainsPercentage})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidDate extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_DATE") {
                super(code);
                this.isInvalidDate = true;
                InvalidDate.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidDate(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidDate(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidDateValue = other.isInvalidDate;
                if (!(other instanceof InvalidDate || Boolean(isInvalidDateValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidDate.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_DATE") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidDate = InvalidDate;
        InvalidDate.prototype.toString = function toString() {
            return `GetEquityTopTenRealisedGainsRPC.INVALID_DATE()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetEquityTopTenRealisedGainsRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `GetEquityTopTenRealisedGainsRPC.INVALID_PORTFOLIO_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_DATE":
                    return InvalidDate.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidDateValue = other.isInvalidDate;
            if (other instanceof InvalidDate || Boolean(isInvalidDateValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetEquityTopTenRealisedGainsRPC.Errors || (GetEquityTopTenRealisedGainsRPC.Errors = {}));
})(GetEquityTopTenRealisedGainsRPC = exports.GetEquityTopTenRealisedGainsRPC || (exports.GetEquityTopTenRealisedGainsRPC = {}));
