import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import {
  Breadcrumb,
  LoadingIndicator,
  PageHeader,
  useSpacing,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { useMFDealRequestDetailsStore } from "../store/DealRequestDetailsStore/hooks";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getPath } from "../../../../../utils/RoutesUtils";
import { MFDealRequestDetailsSection } from "../components/MFDealRequestDetailsSection";
import { MFDealRequestHistorySection } from "../components/MFDealRequestHistorySection";
import { HeaderButton } from "../../../components/page-header/HeaderButton";
import { HeaderContainer } from "../../../components/page-header/HeaderContainer";
import { CheckResponse } from "@khazana/khazana-rpcs";
import { CheckResponseEnums } from "@khazana/khazana-rpcs/build/types/checkResponse";
import { createServerNoteRPCType } from "../../../../../utils";
import {
  TextFieldDialog,
  TextFieldDialogButtonType,
} from "../../../components/TextFieldDialog";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { MFDealDetailsPageErrors } from "../store/DealRequestDetailsStore/DealDetailsErrorStore";
import { observer } from "mobx-react";
import { MFDealStatusSection } from "../components/MFDealStatusSection";
import { SettleRequestDialog } from "../../../components/dialog/SettleRequestDialog";
import { getDetailCellValue } from "../../../utils/DetailCellUtils";
import { MFDealRequestOrderSection } from "../components/MFDealRequestOrderSection";
import { MFOrderDialog } from "../components/MFOrderDialog";
import { OrderType } from "../models/MFDealRequestOrderModel";
import { MFDealRequestDetailsKeys } from "../models/MFDealRequestDetailKeys";
import { Instance } from "mobx-state-tree";
import { CurrencyModel } from "../../../models/CurrencyModel";
import { MenuButton } from "../../../components/page-header/MenuButton";

const CreateViewMFDealRequestStyle = (
  spacing: Spacing,
): { [key: string]: React.CSSProperties } => {
  return {
    body: {
      gap: spacing.space2XL,
      padding: spacing.space2XL,
      minWidth: "calc(100vw - 256px)",
    },
  };
};

export const MFDealRequestDetails = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const [searchParam] = useSearchParams();
  const requestId = searchParam.get("requestId");
  const spacing = useSpacing();
  const store = useMFDealRequestDetailsStore();
  const navigate = useNavigate();
  const [dialogType, setDialogType] = useState<
    "Approve" | "Reject" | "Submit" | "Cancel" | "Settle"
  >("Submit");
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isDialogLoading, setIsDialogLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.AMC);

  const getData = useCallback(() => {
    const id = Number(requestId);
    store.getMFDealRequestDetails(id);
    store.bannerInfoStore.getMFDealRequestDetailsBannerInfo(id);
  }, []);

  useEffect(() => {
    getData();
    return (): void => {
      store.reset();
    };
  }, []);

  useEffect(() => {
    if (
      store.errorStore.error &&
      store.errorStore.error !== MFDealDetailsPageErrors.OrderAmountExceeded &&
      store.errorStore.error !== MFDealDetailsPageErrors.OrderUnitsExceeded
    ) {
      setIsDialogLoading(false);
      setIsOrderDialogOpen(false);
      setIsDialogOpen(false);
      setIsErrorDialogOpen(true);
    }
  }, [store.errorStore.error]);

  const getDealStatusSection = (): React.ReactElement => {
    const bannerInfo = store.bannerInfoStore.bannerInfo;
    if (bannerInfo && bannerInfo.status && bannerInfo.requestNote) {
      return (
        <>
          <MFDealStatusSection value={bannerInfo} />
        </>
      );
    } else {
      return <></>;
    }
  };

  const getDealOrderSection = (): React.ReactElement => {
    if (
      store.dealOrderStore.dealRequestOrder.amcOrders.length > 0 ||
      store.dealOrderStore.dealRequestOrder.brokerOrders.length > 0
    ) {
      return (
        <MFDealRequestOrderSection
          orderSectionDetails={store.dealOrderStore.dealRequestOrder}
          isEditable={store.actions.allowEditOrder}
          onEditOrderButtonClick={async (): Promise<void> => {
            const currency = getDetailCellValue(
              store.details,
              MFDealRequestDetailsKeys.approxNav,
            ).currency as Instance<typeof CurrencyModel> | undefined;
            setOrderType(
              store.dealOrderStore.createEditDealRequestOrder.orderType,
            );
            await store.dealOrderStore.getBrokerList(currency);
            setIsOrderDialogOpen(true);
          }}
        />
      );
    }
    return <></>;
  };

  const getBody = (): React.ReactElement => {
    return (
      <>
        {getDealStatusSection()}
        {getDealOrderSection()}
        <MFDealRequestDetailsSection />
        <MFDealRequestHistorySection />
      </>
    );
  };

  const getCancelActionButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.cancel")}
        onClick={(): void => {
          setDialogType("Cancel");
          setIsDialogOpen(true);
        }}
        buttonType="outlined"
      />
    );
  };

  const getCheckActionButtons = (): React.ReactElement => {
    return (
      <HeaderContainer
        primaryButton={{
          label: t("common.approve"),
          buttonType: "outlined",
          onClick: (): void => {
            setDialogType("Approve");
            setIsDialogOpen(true);
          },
        }}
        secondaryButton={{
          label: t("common.reject"),
          onClick: async (): Promise<void> => {
            setDialogType("Reject");
            setIsDialogOpen(true);
          },
          buttonType: "outlined",
        }}
      />
    );
  };

  const getCancelSendToBrokerActionButtons = (): React.ReactElement => {
    return (
      <Stack direction="row" spacing={spacing.spaceXS}>
        <HeaderButton
          label={t("common.cancel")}
          onClick={(): void => {
            setDialogType("Cancel");
            setIsDialogOpen(true);
          }}
          buttonType="outlined"
        />
        <MenuButton
          label={t("common.sendTo")}
          menuItems={[
            {
              label: t("mf.fields.amcLabel"),
              onClick: async (): Promise<void> => {
                setOrderType(OrderType.AMC);
                store.dealOrderStore.setupOrderDialogData(OrderType.AMC);
                setIsOrderDialogOpen(true);
              },
            },
            {
              label: t("common.broker"),
              onClick: async (): Promise<void> => {
                setOrderType(OrderType.BROKER);
                const currency = getDetailCellValue(
                  store.details,
                  MFDealRequestDetailsKeys.approxNav,
                ).currency as Instance<typeof CurrencyModel> | undefined;
                store.dealOrderStore.setupOrderDialogData(OrderType.BROKER);
                await store.dealOrderStore.getBrokerList(currency);
                setIsOrderDialogOpen(true);
              },
            },
          ]}
          buttonType={"outlined"}
        />
      </Stack>
    );
  };

  const getSettleActionButton = (): React.ReactElement => {
    return (
      <HeaderButton
        label={t("common.settleDeal")}
        onClick={async (): Promise<void> => {
          setDialogType("Settle");
          await store.settleStore.getMFDealRequestSettlementDetails(
            Number(requestId),
            getDetailCellValue(store.details, "common.transactionType")
              .value as string,
          );
          if (!store.errorStore.error) {
            setIsDialogOpen(true);
          }
        }}
        buttonType="filled"
      />
    );
  };

  const getPageHeaderButtons = (): React.ReactElement | undefined => {
    if (store.actions.allowCancel) {
      if (store.actions.allowCreateOrder) {
        return getCancelSendToBrokerActionButtons();
      } else {
        return getCancelActionButton();
      }
    } else if (store.actions.allowCheck) {
      return getCheckActionButtons();
    } else if (store.actions.allowSettle) {
      return getSettleActionButton();
    }
  };

  const getDialog = (): React.ReactElement => {
    if (isDialogOpen) {
      if (dialogType === "Settle") {
        return (
          <SettleRequestDialog
            isOpen={isDialogOpen}
            onClose={(): void => {
              setIsDialogOpen(false);
            }}
            onSettle={async (note?: string): Promise<void> => {
              await store.settleStore.settleMFDealRequest(
                Number(requestId),
                note,
              );
              if (!store.errorStore.error) {
                setIsDialogOpen(false);
                getData();
              }
            }}
            detailRows={store.settleStore.settleRequestDetailRows}
            bannerText={store.settleStore.settleDealBannerInfo}
            currencySymbol={store.settleStore.currency?.symbol}
          />
        );
      }
      let title = "";
      let onPrimaryButtonClick: (note: string | undefined) => Promise<void>;
      switch (dialogType) {
        case "Approve":
          title = t("common.approveDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            await store.checkICMfDealRequest(
              Number(requestId),
              new CheckResponse(
                CheckResponseEnums.CheckStatus.CheckStatus.APPROVE,
                createServerNoteRPCType(note),
              ),
            );
          };
          break;
        case "Reject":
          title = t("common.rejectDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            await store.checkICMfDealRequest(
              Number(requestId),
              new CheckResponse(
                CheckResponseEnums.CheckStatus.CheckStatus.REJECT,
                createServerNoteRPCType(note),
              ),
            );
          };
          break;
        case "Cancel":
          title = t("common.cancelDealRequest");
          onPrimaryButtonClick = async (
            note: string | undefined,
          ): Promise<void> => {
            await store.cancelMFDealRequest(Number(requestId), note);
          };
          break;
      }
      return (
        <TextFieldDialog
          title={title}
          onClose={(): void => {
            setIsDialogOpen(false);
          }}
          isOpen={isDialogOpen}
          primaryButtonType={dialogType as TextFieldDialogButtonType}
          onPrimaryButtonClick={async (
            note: string | undefined,
          ): Promise<void> => {
            setIsDialogLoading(true);
            await onPrimaryButtonClick(note);
            setIsDialogOpen(false);
            getData();
            setIsDialogLoading(false);
          }}
          isCloseIconPresent={!isDialogLoading}
        />
      );
    } else {
      return <></>;
    }
  };

  const getMFOrderDialog = (): React.ReactElement => {
    return (
      <MFOrderDialog
        isOpen={isOrderDialogOpen}
        onClose={(): void => setIsOrderDialogOpen(false)}
        orderType={orderType}
      />
    );
  };

  const getErrorDialogText = (): string => {
    switch (store.errorStore.error) {
      case MFDealDetailsPageErrors.InvalidRequestId:
        return t("common.invalidDealRequestId");
      default:
        return t("common.somethingWentWrong");
    }
  };

  const style = useMemo(() => CreateViewMFDealRequestStyle(spacing), []);
  return (
    <Stack>
      <PageHeader
        title={t("mf.dealDetails.pageTitle")}
        actionElement={getPageHeaderButtons()}
      />
      <Stack sx={style.body}>
        <Breadcrumb
          links={[
            {
              label: t("common.manageDealRequests"),
              onLabelClick: (): void => {
                navigate(getPath(Module.MF, Route.ManageDealRequest));
              },
            },
          ]}
          currentLabel={t("mf.dealDetails.pageTitle")}
        />
        {store.isLoading ? (
          <LoadingIndicator isLoading={store.isLoading} />
        ) : (
          getBody()
        )}
      </Stack>
      {isErrorDialogOpen && (
        <ErrorDialog
          errorMessage={getErrorDialogText()}
          isErrorDialogOpen={isErrorDialogOpen}
          onClose={(): void => {
            if (
              store.errorStore.error ===
              MFDealDetailsPageErrors.InvalidRequestId
            ) {
              navigate(-1);
            }
            setIsErrorDialogOpen(false);
            store.errorStore.reset();
          }}
        />
      )}
      {isDialogOpen && getDialog()}
      {isOrderDialogOpen && getMFOrderDialog()}
    </Stack>
  );
});
