"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiDealRequestsPaginationResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const currency_1 = require("../types/currency");
const fiDealRequestStatus_1 = require("./fiDealRequestStatus");
const fiTransactionType_1 = require("./fiTransactionType");
class InvalidGetFiDealRequestsPaginationResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidGetFiDealRequestsPaginationResponseError";
    }
}
class GetFiDealRequestsPaginationResponse {
    constructor(requestId, dealRequestStatus, security, transactionType, amountHCY = null, quantity = null, ytm, entity, portfolio = null, dematAccountNumber, executedAmountHCY = null, executedQuantity = null, modifiedAt, createdAt, currency) {
        this.isGetFiDealRequestsPaginationResponse = true;
        GetFiDealRequestsPaginationResponse.validateRequestId(requestId);
        this.requestId = requestId;
        GetFiDealRequestsPaginationResponse.validateDealRequestStatus(dealRequestStatus);
        this.dealRequestStatus = dealRequestStatus;
        GetFiDealRequestsPaginationResponse.validateSecurity(security);
        this.security = security;
        GetFiDealRequestsPaginationResponse.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        if (amountHCY !== undefined && amountHCY !== null) {
            GetFiDealRequestsPaginationResponse.validateAmountHCY(amountHCY);
        }
        this.amountHCY = amountHCY;
        if (quantity !== undefined && quantity !== null) {
            GetFiDealRequestsPaginationResponse.validateQuantity(quantity);
        }
        this.quantity = quantity;
        GetFiDealRequestsPaginationResponse.validateYtm(ytm);
        this.ytm = ytm;
        GetFiDealRequestsPaginationResponse.validateEntity(entity);
        this.entity = entity;
        if (portfolio !== undefined && portfolio !== null) {
            GetFiDealRequestsPaginationResponse.validatePortfolio(portfolio);
        }
        this.portfolio = portfolio;
        GetFiDealRequestsPaginationResponse.validateDematAccountNumber(dematAccountNumber);
        this.dematAccountNumber = dematAccountNumber;
        if (executedAmountHCY !== undefined && executedAmountHCY !== null) {
            GetFiDealRequestsPaginationResponse.validateExecutedAmountHCY(executedAmountHCY);
        }
        this.executedAmountHCY = executedAmountHCY;
        if (executedQuantity !== undefined && executedQuantity !== null) {
            GetFiDealRequestsPaginationResponse.validateExecutedQuantity(executedQuantity);
        }
        this.executedQuantity = executedQuantity;
        GetFiDealRequestsPaginationResponse.validateModifiedAt(modifiedAt);
        this.modifiedAt = modifiedAt;
        GetFiDealRequestsPaginationResponse.validateCreatedAt(createdAt);
        this.createdAt = createdAt;
        GetFiDealRequestsPaginationResponse.validateCurrency(currency);
        this.currency = currency;
    }
    static fromDTO(dto) {
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidGetFiDealRequestsPaginationResponseError);
        const dealRequestStatus = fiDealRequestStatus_1.FiDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealRequestStatus", InvalidGetFiDealRequestsPaginationResponseError));
        const security = (0, leo_ts_runtime_1.getString)(dto, "security", InvalidGetFiDealRequestsPaginationResponseError);
        const transactionType = fiTransactionType_1.FiTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidGetFiDealRequestsPaginationResponseError));
        const amountHCY = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "amountHCY", InvalidGetFiDealRequestsPaginationResponseError);
        const quantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "quantity", InvalidGetFiDealRequestsPaginationResponseError);
        const ytm = (0, leo_ts_runtime_1.getInt32)(dto, "ytm", InvalidGetFiDealRequestsPaginationResponseError);
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidGetFiDealRequestsPaginationResponseError);
        const portfolio = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolio", InvalidGetFiDealRequestsPaginationResponseError);
        const dematAccountNumber = (0, leo_ts_runtime_1.getString)(dto, "dematAccountNumber", InvalidGetFiDealRequestsPaginationResponseError);
        const executedAmountHCY = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "executedAmountHCY", InvalidGetFiDealRequestsPaginationResponseError);
        const executedQuantity = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "executedQuantity", InvalidGetFiDealRequestsPaginationResponseError);
        const modifiedAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "modifiedAt", InvalidGetFiDealRequestsPaginationResponseError);
        const createdAt = (0, leo_ts_runtime_1.getLeoTimestamp)(dto, "createdAt", InvalidGetFiDealRequestsPaginationResponseError);
        const currency = currency_1.Currency.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "currency", InvalidGetFiDealRequestsPaginationResponseError));
        return new GetFiDealRequestsPaginationResponse(requestId, dealRequestStatus, security, transactionType, amountHCY, quantity, ytm, entity, portfolio, dematAccountNumber, executedAmountHCY, executedQuantity, modifiedAt, createdAt, currency);
    }
    toDTO() {
        const ret = {
            requestId: this.requestId,
            dealRequestStatus: fiDealRequestStatus_1.FiDealRequestStatus.toDTO(this.dealRequestStatus),
            security: this.security,
            transactionType: fiTransactionType_1.FiTransactionType.toDTO(this.transactionType),
            ytm: this.ytm,
            entity: this.entity,
            dematAccountNumber: this.dematAccountNumber,
            modifiedAt: this.modifiedAt.toDTO(),
            createdAt: this.createdAt.toDTO(),
            currency: this.currency.toDTO(),
        };
        if (this.amountHCY !== undefined && this.amountHCY !== null) {
            ret.amountHCY = this.amountHCY;
        }
        if (this.quantity !== undefined && this.quantity !== null) {
            ret.quantity = this.quantity;
        }
        if (this.portfolio !== undefined && this.portfolio !== null) {
            ret.portfolio = this.portfolio;
        }
        if (this.executedAmountHCY !== undefined && this.executedAmountHCY !== null) {
            ret.executedAmountHCY = this.executedAmountHCY;
        }
        if (this.executedQuantity !== undefined && this.executedQuantity !== null) {
            ret.executedQuantity = this.executedQuantity;
        }
        return ret;
    }
    copy(requestId = this.requestId, dealRequestStatus = this.dealRequestStatus, security = this.security, transactionType = this.transactionType, amountHCY = this.amountHCY, quantity = this.quantity, ytm = this.ytm, entity = this.entity, portfolio = this.portfolio, dematAccountNumber = this.dematAccountNumber, executedAmountHCY = this.executedAmountHCY, executedQuantity = this.executedQuantity, modifiedAt = this.modifiedAt, createdAt = this.createdAt, currency = this.currency) {
        return new GetFiDealRequestsPaginationResponse(requestId, dealRequestStatus, security, transactionType, amountHCY, quantity, ytm, entity, portfolio, dematAccountNumber, executedAmountHCY, executedQuantity, modifiedAt, createdAt, currency);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isGetFiDealRequestsPaginationResponseValue = other.isGetFiDealRequestsPaginationResponse;
        if (!(other instanceof GetFiDealRequestsPaginationResponse) || Boolean(isGetFiDealRequestsPaginationResponseValue)) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.dealRequestStatus !== other.dealRequestStatus) {
            return false;
        }
        if (this.security !== other.security) {
            return false;
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.amountHCY !== other.amountHCY) {
            return false;
        }
        if (this.quantity !== other.quantity) {
            return false;
        }
        if (this.ytm !== other.ytm) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.portfolio !== other.portfolio) {
            return false;
        }
        if (this.dematAccountNumber !== other.dematAccountNumber) {
            return false;
        }
        if (this.executedAmountHCY !== other.executedAmountHCY) {
            return false;
        }
        if (this.executedQuantity !== other.executedQuantity) {
            return false;
        }
        if (!this.modifiedAt.equals(other.modifiedAt)) {
            return false;
        }
        if (!this.createdAt.equals(other.createdAt)) {
            return false;
        }
        if (!this.currency.equals(other.currency)) {
            return false;
        }
        return true;
    }
}
exports.GetFiDealRequestsPaginationResponse = GetFiDealRequestsPaginationResponse;
GetFiDealRequestsPaginationResponse.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute requestId is not an Int64`);
    }
};
GetFiDealRequestsPaginationResponse.validateDealRequestStatus = (dealRequestStatus) => {
    if (!fiDealRequestStatus_1.FiDealRequestStatus.isInstanceOf(dealRequestStatus)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute dealRequestStatus is not a FiDealRequestStatus.FiDealRequestStatus`);
    }
};
GetFiDealRequestsPaginationResponse.validateSecurity = (security) => {
    if (!(0, leo_ts_runtime_1.isString)(security)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute security is not a String`);
    }
    if (security.trim().length === 0) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute security cannot be an empty string.`);
    }
};
GetFiDealRequestsPaginationResponse.validateTransactionType = (transactionType) => {
    if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
    }
};
GetFiDealRequestsPaginationResponse.validateAmountHCY = (amountHCY) => {
    if (!(0, leo_ts_runtime_1.isInt64)(amountHCY)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute amountHCY is not an Int64`);
    }
};
GetFiDealRequestsPaginationResponse.validateQuantity = (quantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(quantity)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute quantity is not an Int64`);
    }
};
GetFiDealRequestsPaginationResponse.validateYtm = (ytm) => {
    if (!(0, leo_ts_runtime_1.isInt32)(ytm)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute ytm is not an Int32`);
    }
};
GetFiDealRequestsPaginationResponse.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute entity cannot be an empty string.`);
    }
};
GetFiDealRequestsPaginationResponse.validatePortfolio = (portfolio) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute portfolio is not a String`);
    }
    if (portfolio.trim().length === 0) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute portfolio cannot be an empty string.`);
    }
};
GetFiDealRequestsPaginationResponse.validateDematAccountNumber = (dematAccountNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute dematAccountNumber is not a String`);
    }
    if (dematAccountNumber.trim().length === 0) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute dematAccountNumber cannot be an empty string.`);
    }
};
GetFiDealRequestsPaginationResponse.validateExecutedAmountHCY = (executedAmountHCY) => {
    if (!(0, leo_ts_runtime_1.isInt64)(executedAmountHCY)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute executedAmountHCY is not an Int64`);
    }
};
GetFiDealRequestsPaginationResponse.validateExecutedQuantity = (executedQuantity) => {
    if (!(0, leo_ts_runtime_1.isInt64)(executedQuantity)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute executedQuantity is not an Int64`);
    }
};
GetFiDealRequestsPaginationResponse.validateModifiedAt = (modifiedAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(modifiedAt)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute modifiedAt is not a Timestamp`);
    }
};
GetFiDealRequestsPaginationResponse.validateCreatedAt = (createdAt) => {
    if (!(0, leo_ts_runtime_1.isLeoTimestamp)(createdAt)) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute createdAt is not a Timestamp`);
    }
};
GetFiDealRequestsPaginationResponse.validateCurrency = (currency) => {
    const isCurrencyValue = currency.isCurrency;
    if (!(currency instanceof currency_1.Currency || Boolean(isCurrencyValue))) {
        throw new InvalidGetFiDealRequestsPaginationResponseError(`Attribute currency is not a Currency`);
    }
};
GetFiDealRequestsPaginationResponse.prototype.toString = function toString() {
    return `GetFiDealRequestsPaginationResponse(requestId=${this.requestId},dealRequestStatus=${this.dealRequestStatus},security='${this.security}',transactionType=${this.transactionType},amountHCY=${this.amountHCY},quantity=${this.quantity},ytm=${this.ytm},entity='${this.entity}',portfolio='${this.portfolio}',dematAccountNumber='${this.dematAccountNumber}',executedAmountHCY=${this.executedAmountHCY},executedQuantity=${this.executedQuantity},modifiedAt=${this.modifiedAt},createdAt=${this.createdAt},currency=${this.currency})`;
};
