import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React from "react";
import {
  InvalidDateError,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";
import { Stack } from "@mui/material";
import { HelperContent, Icon } from "@surya-digital/leo-reactjs-material-ui";
import { InputFieldLabel } from "./InputFieldLabel";
import { DatePickerFieldProps } from "./DatePickerField";
import { MONTH_FORMAT } from "../../../../utils";

/**
 * Creates a DatePickerFieldSeparateLabel
 * @param isDisabled - If `true`, component is disabled
 * @param isRequired - If `true`, input is required
 * @param value - Value of the input element, required for a controller component
 * @param onChange - Callback fired when the value is changed
 * @param onError - Callback fired when an error is occurred
 * @param label - Label of the input
 * @param error - If `true`, the input will indicate an error
 * @param minDate - Minimal selectable date
 * @param maxDate - Maximal selectable date
 * @param disableFuture -  If `true` future days are disabled.
 * @param locale - Set the locale
 * @param style - Prop of type React.CSSProperties if further customization of password input field is required
 * @param helperText - Helper text
 * @param format - String value to format the date. If provided it will override the format specified by the locale.
 * @returns DatePickerFieldSeparateLabel component
 */

export const DatePickerFieldSeparateLabel = ({
  label,
  value,
  onChange,
  onError,
  minDate,
  maxDate,
  locale,
  isDisabled,
  isRequired,
  style,
  error,
  disableFuture,
  helperText,
  format,
}: DatePickerFieldProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();

  const getIconColor = (): string => {
    if (isDisabled) return tokens.iconLowEmphasis;
    else return tokens.icon;
  };

  const CalendarIcon = (): React.ReactElement<
    React.SVGProps<SVGSVGElement>
  > => <Icon type="calendar" height={20} width={20} color={getIconColor()} />;

  // For ChevronRightIcon and ChevronLeftIcon color is set to 'inherit'.
  // Reason: When the MuiPickersArrowSwitcher icons are active and disabled the color is set in DatePicker theme overrides.
  const ChevronRightIcon = (): React.ReactElement<
    React.SVGProps<SVGSVGElement>
  > => (
    <Icon
      type="chevron-right"
      height={16}
      width={16}
      color={tokens.iconPrimary}
    />
  );

  const ChevronLeftIcon = (): React.ReactElement<
    React.SVGProps<SVGSVGElement>
  > => (
    <Icon
      type="chevron-left"
      height={16}
      width={16}
      color={tokens.iconPrimary}
    />
  );

  const ChevronDownIcon = (): React.ReactElement<
    React.SVGProps<SVGSVGElement>
  > => (
    <Icon
      type="chevron-down"
      height={24}
      width={24}
      color={tokens.iconPrimary}
    />
  );

  return (
    <Stack direction="column">
      <InputFieldLabel
        label={label}
        disabled={isDisabled}
        required={isRequired}
        error={error}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
        <DatePicker
          slots={{
            openPickerIcon: CalendarIcon,
            leftArrowIcon: ChevronLeftIcon,
            rightArrowIcon: ChevronRightIcon,
            switchViewIcon: ChevronDownIcon,
          }}
          sx={{
            ...style,
            "& .MuiCalendarPicker-root": { width: "auto" },
          }}
          value={value}
          onChange={onChange}
          onError={(err): void => {
            onError(err ? new InvalidDateError("Invalid Error") : null);
          }}
          disabled={isDisabled}
          minDate={minDate}
          maxDate={maxDate}
          disableFuture={disableFuture}
          localeText={{
            // This needs to be overriden as the default value is not in the correct format. Referenced this issue: https://github.com/mui/mui-x/issues/8526
            fieldMonthPlaceholder: () => MONTH_FORMAT,
          }}
          slotProps={{
            textField: {
              required: isRequired,
              error,
              helperText: (
                <HelperContent
                  value={value?.toDateString()}
                  isDisabled={isDisabled}
                  error={Boolean(error)}
                  helperText={helperText}
                />
              ),
            },
          }}
          format={format}
        />
      </LocalizationProvider>
    </Stack>
  );
};
