import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { BorderStyle, useBorder } from "../../../utils/BorderUtils";
import { Spacing } from "@surya-digital/leo-reactjs-core/dist/theme/spacing";
import { CornerRadius } from "@surya-digital/leo-reactjs-core/dist/theme/cornerRadius";
import {
  Button,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ButtonIcon,
  ButtonVariant,
  FoundationColorTokens,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";

const createSectionStyle = (
  border: BorderStyle,
  spacing: Spacing,
  tokens: FoundationColorTokens<string>,
  cornerRadius: CornerRadius,
): { [key: string]: React.CSSProperties } => {
  return {
    section: {
      borderRadius: cornerRadius.radiusXS,
      backgroundColor: tokens.background,
      border: border.default,
      height: "100%",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      padding: `${spacing.spaceMD} ${spacing.spaceXL}`,
      borderBottom: border.default,
      gap: spacing.spaceXS,
    },
    actionButtonsContainer: {
      flexDirection: "row",
      gap: spacing.spaceXS,
    },
    detailContainer: {
      flexDirection: "row",
      justifyContent: "center",
      gap: spacing.spaceXS,
      padding: spacing.spaceXL,
      overflow: "auto",
    },
  };
};

export interface ActionButton {
  label: string;
  onClick: () => void;
  variant?: ButtonVariant;
  icon?: ButtonIcon;
}

export interface ActionButtons {
  primaryButton?: ActionButton;
  secondaryButton?: ActionButton;
}

export interface SectionProps {
  title: string;
  titleBackgroundColor?: string;
  width?: string;
  actions?: ActionButtons;
  children: React.ReactNode;
  hasPadding?: boolean;
}

export const Section = ({
  title,
  titleBackgroundColor,
  width,
  actions,
  children,
  hasPadding,
}: SectionProps): React.ReactElement => {
  const border = useBorder();
  const tokens = useFoundationColorTokens();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();
  const typography = useTypography();

  const style = useMemo(
    () => createSectionStyle(border, spacing, tokens, cornerRadius),
    [],
  );
  return (
    <Stack sx={{ ...style.section, width, flexGrow: 1 }}>
      <Stack sx={{ ...style.header, backgroundColor: titleBackgroundColor }}>
        <Typography sx={{ ...typography.sh3 }}>{title}</Typography>
        <Stack sx={style.actionButtonsContainer}>
          {actions?.secondaryButton && (
            <Button
              name={"secondary"}
              size={"small"}
              variant={actions.secondaryButton.variant ?? "outlined-color"}
              onClick={(): void => {
                if (actions.secondaryButton) {
                  actions.secondaryButton.onClick();
                }
              }}
              label={actions.secondaryButton.label}
              icon={actions.secondaryButton.icon}
              iconPosition="leading"
            />
          )}
          {actions?.primaryButton && (
            <Button
              name={"primary"}
              size={"small"}
              variant={actions.primaryButton.variant ?? "outlined-color"}
              onClick={(): void => {
                if (actions.primaryButton) {
                  actions.primaryButton.onClick();
                }
              }}
              label={actions.primaryButton.label}
              icon={actions.primaryButton.icon}
              iconPosition="leading"
            />
          )}
        </Stack>
      </Stack>
      <Stack
        sx={{
          ...style.detailContainer,
          padding: hasPadding ? style.detailContainer.padding : 0,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
