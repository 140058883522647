import { cast, clone, flow, getEnv, Instance, types } from "mobx-state-tree";
import { GetIndexListRPC } from "@khazana/khazana-rpcs";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { useGetIndexListRPC } from "../rpcs/RPC";

export const BenchmarkIndex = types.model("BenchmarkIndex", {
  indexName: types.string,
  id: types.number,
});

export const BenchmarkIndexDropdownStore = types
  .model("BenchmarkIndexDropdownStore", {
    benchmarkIndexList: types.array(BenchmarkIndex),
    selectedBenchmarkIndex: types.maybe(BenchmarkIndex),
    isLoading: types.optional(types.boolean, false),
    error: types.maybe(types.string),
  })
  .actions((store) => ({
    setSelectedBenchmarkIndex: (index: string | undefined): void => {
      const benchmarkIndex = store.benchmarkIndexList.find(
        (item) => item.id === Number(index),
      );
      if (benchmarkIndex) {
        store.selectedBenchmarkIndex = BenchmarkIndex.create({
          indexName: benchmarkIndex.indexName,
          id: benchmarkIndex.id,
        });
      } else {
        store.selectedBenchmarkIndex = undefined;
      }
    },
    setIsLoading: (isLoading: boolean): void => {
      store.isLoading = isLoading;
    },
    setError: (error: string | undefined): void => {
      store.error = error;
    },
    getBenchmarkIndexList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      store.isLoading = true;
      const request = new GetIndexListRPC.Request();
      const result: LeoRPCResult<
        GetIndexListRPC.Response,
        GetIndexListRPC.Errors.Errors
      > = yield useGetIndexListRPC(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.benchmarkIndexList = cast(
          response.indexList.map((benchmarkIndex) => {
            return BenchmarkIndex.create({
              indexName: benchmarkIndex.name,
              id: benchmarkIndex.id,
            });
          }),
        );
        if (store.benchmarkIndexList.length > 0) {
          store.selectedBenchmarkIndex = clone(store.benchmarkIndexList[0]);
        }
      } else {
        logger.error("unable to fetch benchmark index list");
      }
      store.isLoading = false;
    }),
  }));

export const createBenchmarkIndexDropdownStore = (): Instance<
  typeof BenchmarkIndexDropdownStore
> => {
  return BenchmarkIndexDropdownStore.create();
};
