import { Stack, Typography } from "@mui/material";
import {
  Dialog,
  LoadingIndicator,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownInputFieldSeparateLabel } from "@surya-digital/leo-reactjs-material-ui";
import { Instance } from "mobx-state-tree";
import {
  getAmountStringOrHyphen,
  getFormattedAmountString,
} from "../../../../../utils";
import { observer } from "mobx-react";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { getPath } from "../../../../../utils/RoutesUtils";
import {
  SettleFiDealInvalidRequestError,
  ViewFiDealInvalidRequestError,
} from "../store/ViewFiDealRequestDetailsError";
import { SettleFiDealRequestStore } from "../store/SettleFiDealRequestStore";
import { useBorder } from "../../../../../utils/BorderUtils";
import { WarningAlertComponent } from "../../../components/WarningAlertComponent";
import { NoteTextArea } from "../../../components/NoteTextArea";

interface SettleFiDealRequestDialogProps {
  isSettleFiDealRequestDialogOpen: boolean;
  currencySymbol: string | null;
  store: Instance<typeof SettleFiDealRequestStore>;
  onSuccessfulSettleDeal: () => void;
  onClose: () => void;
  onError: () => void;
  fetchDealRequestDetails: () => void;
}

const Size = {
  styledTypography: {
    container: "520px",
    header1: "200px",
    header2: "312px",
  },
  settleDealMessage: "496px",
  dialogWidth: "560px",
  content: "520px",
  dropdown: "320px",
};

export const SettleFiDealRequestDialog = observer(
  ({
    isSettleFiDealRequestDialogOpen,
    currencySymbol,
    store,
    onSuccessfulSettleDeal,
    onClose,
    onError,
    fetchDealRequestDetails,
  }: SettleFiDealRequestDialogProps): React.ReactElement => {
    const { t } = useTranslation();
    const typography = useTypography();
    const tokens = useFoundationColorTokens();
    const spacing = useSpacing();
    const cornerRadius = useCornerRadius();
    const border = useBorder();
    const [searchParam] = useSearchParams();
    const requestId = Number(searchParam.get("requestId"));
    const [isDropdownError, setIsDropdownError] = useState(false);
    const navigate = useNavigate();
    const [isSettleDialogOpen, setIsSettleDialogOpen] = useState(
      isSettleFiDealRequestDialogOpen,
    );
    const [isScreenBlockingLoading, setIsScreenBlockingLoading] =
      useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [isCloseIconPresent, setIsCloseIconPresent] = useState(true);
    const settlementAmountDetails =
      store.settlementDetails?.fiSettlementAmountDetails;
    const settlementQuantityDetails =
      store.settlementDetails?.fiSettlementQuantityDetails;

    interface GetStyledTypographyProps {
      text: string;
      amountQuantity: string;
    }

    const getErrorDialogText = (): string => {
      switch (store.error) {
        case ViewFiDealInvalidRequestError.InvalidRequestId:
          return t("common.noResultsFound");
        case SettleFiDealInvalidRequestError.CanOnlySettleSelfRequest:
          return t("common.somethingWentWrong");
        case SettleFiDealInvalidRequestError.DealAlreadyCancelled:
          return t("common.requestAlreadyCancelled");
        case SettleFiDealInvalidRequestError.DealAlreadyExpired:
          return t("common.requestAlreadyExpired");
        case SettleFiDealInvalidRequestError.DealAlreadySettled:
          return t("common.dealAlreadySettled");
        case SettleFiDealInvalidRequestError.DealNotApproved:
          return t("common.dealNotApproved");
        case SettleFiDealInvalidRequestError.InvalidPortfolio:
          return t("common.somethingWentWrong");
        case SettleFiDealInvalidRequestError.NoBrokersLinked:
          return t("common.noBrokersLinked");
        default:
          return t("common.somethingWentWrong");
      }
    };

    const GetStyledTypography = ({
      text,
      amountQuantity,
    }: GetStyledTypographyProps): React.ReactElement => {
      return (
        <Stack
          sx={{
            flexDirection: "row",
            width: Size.styledTypography.container,
            borderBottom: border.default,
          }}
        >
          <Typography
            sx={{
              ...typography.s1,
              width: Size.styledTypography.header1,
              padding: `${spacing.spaceMD} 0`,
              color: tokens.label,
            }}
          >
            {text}
          </Typography>
          <Typography
            sx={{
              ...typography.b1,
              width: Size.styledTypography.header2,
              padding: `${spacing.spaceMD} ${spacing.spaceSM}`,
              textAlign: "right",
            }}
          >
            {amountQuantity}
          </Typography>
        </Stack>
      );
    };

    const getRequestedValue = (): string => {
      let requestedValue;
      if (settlementAmountDetails?.fiSettlementBuyAmountDetailsModel) {
        requestedValue =
          settlementAmountDetails?.fiSettlementBuyAmountDetailsModel
            .requestedAmount.amount;
      } else if (settlementAmountDetails?.fiSettlementSellAmountDetailsModel) {
        requestedValue =
          settlementAmountDetails?.fiSettlementSellAmountDetailsModel
            .requestedAmount.amount;
      } else if (
        settlementQuantityDetails?.fiSettlementBuyQuantityDetailsModel
      ) {
        requestedValue =
          settlementQuantityDetails.fiSettlementBuyQuantityDetailsModel
            .requestedQuantity;
      } else if (
        settlementQuantityDetails?.fiSettlementSellQuantityDetailsModel
      ) {
        requestedValue =
          settlementQuantityDetails.fiSettlementSellQuantityDetailsModel
            .requestedQuantity;
      }
      if (requestedValue) {
        return getFormattedAmountString(requestedValue);
      }
      console.error("Neither Requested Amount nor Requested Quantity found");
      return "-";
    };

    const getExecutedValue = (): string => {
      let executedValue;
      if (settlementAmountDetails?.fiSettlementBuyAmountDetailsModel) {
        executedValue =
          settlementAmountDetails?.fiSettlementBuyAmountDetailsModel
            .executedAmount.amount;
      } else if (settlementAmountDetails?.fiSettlementSellAmountDetailsModel) {
        executedValue =
          settlementAmountDetails?.fiSettlementSellAmountDetailsModel
            .soldQuantity;
      } else if (
        settlementQuantityDetails?.fiSettlementBuyQuantityDetailsModel
      ) {
        executedValue =
          settlementQuantityDetails.fiSettlementBuyQuantityDetailsModel
            .executedUnits;
      } else if (
        settlementQuantityDetails?.fiSettlementSellQuantityDetailsModel
      ) {
        executedValue =
          settlementQuantityDetails.fiSettlementSellQuantityDetailsModel
            .soldQuantity;
      }
      if (executedValue) {
        return getFormattedAmountString(executedValue);
      }
      console.error("Neither Executed Amount nor Executed Quantity found");
      return "-";
    };

    const getExecutedText = (): string => {
      if (settlementAmountDetails?.fiSettlementBuyAmountDetailsModel) {
        return `${t("common.executedAmount")} ${
          currencySymbol ? `(${currencySymbol})` : ""
        }`;
      } else if (
        settlementQuantityDetails?.fiSettlementBuyQuantityDetailsModel
      ) {
        return t("common.executedQuantity");
      } else {
        return t("common.soldQuantity");
      }
    };

    const getPendingValue = (): string => {
      let pendingValue;
      if (settlementAmountDetails?.fiSettlementBuyAmountDetailsModel) {
        pendingValue =
          settlementAmountDetails?.fiSettlementBuyAmountDetailsModel
            .pendingAmount?.amount;
      } else if (settlementAmountDetails?.fiSettlementSellAmountDetailsModel) {
        pendingValue =
          settlementAmountDetails?.fiSettlementSellAmountDetailsModel
            .unsoldQuantity;
      } else if (
        settlementQuantityDetails?.fiSettlementBuyQuantityDetailsModel
      ) {
        pendingValue =
          settlementQuantityDetails.fiSettlementBuyQuantityDetailsModel
            .pendingUnits;
      } else if (
        settlementQuantityDetails?.fiSettlementSellQuantityDetailsModel
      ) {
        pendingValue =
          settlementQuantityDetails.fiSettlementSellQuantityDetailsModel
            .unsoldQuantity;
      }
      if (pendingValue) {
        return getFormattedAmountString(pendingValue);
      }
      return "0";
    };

    const getPendingText = (): string => {
      if (settlementAmountDetails?.fiSettlementBuyAmountDetailsModel) {
        return store.settlementDetails?.isAmountExcess
          ? `${t("fi.dealRequestDetails.excessAmountLabel")} ${
              currencySymbol ? `(${currencySymbol})` : ""
            }`
          : `${t("common.pendingAmount")} ${
              currencySymbol ? `(${currencySymbol})` : ""
            }`;
      } else if (
        settlementQuantityDetails?.fiSettlementBuyQuantityDetailsModel
      ) {
        return store.settlementDetails?.isQuantityExcess
          ? t("fi.dealRequestDetails.excessQuantityLabel")
          : t("common.pendingQuantity");
      } else {
        return t("common.unsoldQuantity");
      }
    };

    const getSettleDealMessage = (): string => {
      if (
        settlementAmountDetails?.fiSettlementBuyAmountDetailsModel ??
        settlementQuantityDetails?.fiSettlementBuyQuantityDetailsModel
      ) {
        return t("common.settlingDealRequestWillUnblockPendingAmount");
      } else {
        return t("common.settlingDealRequestReleaseRemainingQuantity");
      }
    };

    const getYtmSettlementDetails = (): React.ReactElement => {
      if (
        store.settlementDetails?.ytmSettlementDetails.requestedYtm !== null ||
        store.settlementDetails?.ytmSettlementDetails.executedYtm !== null
      ) {
        return (
          <Stack>
            <Typography
              sx={{
                ...typography.s1,
                paddingBottom: spacing.spaceXS,
                borderBottom: border.default,
              }}
            >
              {`${t("fi.dealRequestDetails.settlementYtmDetailsTitle")}`}
            </Typography>
            <GetStyledTypography
              text={t("fi.dealRequestDetails.requestedYtmLable")}
              amountQuantity={getAmountStringOrHyphen(
                store.settlementDetails?.ytmSettlementDetails.requestedYtm ??
                  undefined,
                2,
              )}
            />
            <GetStyledTypography
              text={t("fi.dealRequestDetails.executedYtmLabel")}
              amountQuantity={getAmountStringOrHyphen(
                store.settlementDetails?.ytmSettlementDetails.executedYtm ??
                  undefined,
                2,
              )}
            />
          </Stack>
        );
      }
      return <></>;
    };

    const getYtcSettlementDetails = (): React.ReactElement => {
      if (
        store.settlementDetails?.ytcSettlementDetails.requestedYtc !== null ||
        store.settlementDetails?.ytcSettlementDetails.executedYtc !== null
      ) {
        return (
          <Stack>
            <Typography
              sx={{
                ...typography.s1,
                paddingBottom: spacing.spaceXS,
                borderBottom: border.default,
              }}
            >
              {`${t("fi.dealRequestDetails.settlementYtcDetailsTitle")}`}
            </Typography>
            <GetStyledTypography
              text={t("fi.dealRequestDetails.requestedYtcLabel")}
              amountQuantity={getAmountStringOrHyphen(
                store.settlementDetails?.ytcSettlementDetails.requestedYtc ??
                  undefined,
                2,
              )}
            />
            <GetStyledTypography
              text={t("fi.dealRequestDetails.executedYtcLabel")}
              amountQuantity={getAmountStringOrHyphen(
                store.settlementDetails?.ytcSettlementDetails.executedYtc ??
                  undefined,
                2,
              )}
            />
          </Stack>
        );
      }
      return <></>;
    };

    const getSettlementDetails = (): React.ReactElement => {
      return (
        <Stack>
          <Typography
            sx={{
              ...typography.s1,
              paddingBottom: spacing.spaceXS,
              borderBottom: border.default,
            }}
          >
            {t("common.settlementDetailsForDealRequest", {
              val: currencySymbol,
            })}
          </Typography>
          <GetStyledTypography
            text={
              settlementQuantityDetails === null
                ? `${t("common.requestedAmount")} ${
                    currencySymbol ? `(${currencySymbol})` : ""
                  }`
                : t("common.requestedQuantity")
            }
            amountQuantity={getRequestedValue()}
          />
          {settlementAmountDetails?.fiSettlementSellAmountDetailsModel
            ?.approxQuantityRequested && (
            <GetStyledTypography
              text={t("common.approxQuantityRequired")}
              amountQuantity={getFormattedAmountString(
                settlementAmountDetails?.fiSettlementSellAmountDetailsModel
                  ?.approxQuantityRequested,
              )}
            />
          )}
          <GetStyledTypography
            text={getExecutedText()}
            amountQuantity={getExecutedValue()}
          />
          <GetStyledTypography
            text={getPendingText()}
            amountQuantity={getPendingValue()}
          />
        </Stack>
      );
    };

    const getBanner = (): React.ReactElement => {
      if (
        Boolean(store.settlementDetails?.isAmountExcess) ||
        Boolean(store.settlementDetails?.isQuantityExcess)
      ) {
        return (
          <WarningAlertComponent
            text={
              Boolean(store.settlementDetails?.isAmountExcess)
                ? t("fi.dealRequestDetails.executedAmountIsMoreErrorMessage")
                : t("fi.dealRequestDetails.executedQuantityIsMoreErrorMessage")
            }
          />
        );
      }
      return (
        <Typography
          sx={{
            ...typography.b1,
            color: tokens.labelInfo,
            background: tokens.backgroundInfoSubtle,
            padding: `${spacing.spaceXS} ${spacing.spaceMD}`,
            width: Size.settleDealMessage,
            borderRadius: cornerRadius.radiusXXS,
            border: border.infoSubtle,
            marginBottom: spacing.spaceLG,
          }}
        >
          {getSettleDealMessage()}
        </Typography>
      );
    };

    return (
      <>
        <LoadingIndicator isLoading={isScreenBlockingLoading} />
        <ErrorDialog
          errorMessage={getErrorDialogText()}
          isErrorDialogOpen={isErrorDialogOpen}
          onClose={(): void => {
            if (
              store.error === ViewFiDealInvalidRequestError.InvalidRequestId
            ) {
              onClose();
              navigate(getPath(Module.Fi, Route.ViewDealRequest));
            } else if (
              store.error ===
                SettleFiDealInvalidRequestError.CanOnlySettleSelfRequest ||
              store.error ===
                SettleFiDealInvalidRequestError.DealAlreadyCancelled ||
              store.error ===
                SettleFiDealInvalidRequestError.DealAlreadyExpired ||
              store.error ===
                SettleFiDealInvalidRequestError.DealAlreadySettled ||
              store.error === SettleFiDealInvalidRequestError.DealNotApproved
            ) {
              fetchDealRequestDetails();
            }
            store.resetStore();
            setIsErrorDialogOpen(false);
            onError();
          }}
        />
        <Dialog
          open={isSettleDialogOpen}
          title={t("common.settleDealRequest")}
          primaryButtonText={t("common.settleDeal")}
          isCloseIconPresent={isCloseIconPresent}
          onClose={(): void => {
            setIsSettleDialogOpen(false);
            onClose();
          }}
          onPrimaryButtonClick={async (): Promise<void> => {
            if (
              store.portfolioList &&
              store.portfolioList.length > 0 &&
              store.selectedPortfolio === null
            ) {
              setIsDropdownError(true);
              return;
            }
            setIsScreenBlockingLoading(true);
            if (requestId && !isDropdownError) {
              setIsCloseIconPresent(false);
              await store.settleDealRequest(requestId);
              setIsCloseIconPresent(true);
            } else {
              console.error("No requestId found to settle deal request");
            }
            setIsSettleDialogOpen(false);
            if (store.error === null) {
              onSuccessfulSettleDeal();
            } else {
              setIsErrorDialogOpen(true);
            }
            setIsScreenBlockingLoading(false);
          }}
          disableBackdropClick={true}
          style={{
            borderRadius: cornerRadius.radiusXXS,
            flexDirection: "column",
          }}
        >
          <Stack>
            {getBanner()}
            {store.portfolioList && store.portfolioList.length > 0 && (
              <Stack
                sx={{
                  width: Size.content,
                  gap: spacing.spaceSM,
                  marginBottom: spacing.space2XL,
                }}
              >
                <Typography
                  sx={{
                    ...typography.s1,
                    paddingBottom: spacing.spaceXS,
                    borderBottom: border.default,
                  }}
                >
                  {t("common.selectPortfolio")}
                </Typography>
                <DropdownInputFieldSeparateLabel
                  name={"portfolio"}
                  isRequired={true}
                  error={isDropdownError}
                  helperText={
                    isDropdownError
                      ? t("common.portfolioIsRequired")
                      : undefined
                  }
                  helperTextColor={tokens.labelError}
                  label={t("common.portfolio")}
                  placeholder={t("common.selectPortfolio")}
                  options={store.portfolioList.map(({ id, name }) => ({
                    name,
                    value: id,
                  }))}
                  onSelect={(value): void => {
                    setIsDropdownError(false);
                    store.setSelectedPortfolio(value);
                  }}
                  value={
                    store.selectedPortfolio
                      ? store.selectedPortfolio.id
                      : undefined
                  }
                  style={{ width: Size.dropdown }}
                />
              </Stack>
            )}
            <Stack
              sx={{ gap: spacing.space2XL, marginBottom: spacing.space2XL }}
            >
              {getSettlementDetails()}
              {getYtmSettlementDetails()}
              {getYtcSettlementDetails()}
            </Stack>
            <NoteTextArea
              note={store.note}
              setNote={store.setSettleNote}
              style={{ width: Size.content }}
            />
          </Stack>
        </Dialog>
      </>
    );
  },
);
