"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomeHoldingSummaryResponse = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fixedIncomeInstrumentType_1 = require("../types/fixedIncomeInstrumentType");
const fixedIncomeHoldingCategory_1 = require("./fixedIncomeHoldingCategory");
class InvalidFixedIncomeHoldingSummaryResponseError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomeHoldingSummaryResponseError";
    }
}
class FixedIncomeHoldingSummaryResponse {
    constructor(isin, securityName = null, issuerName = null, instrumentType = null, maturityDate = null, callDate = null, putDate = null, category = null, entityName = null, portfolioName = null, holdingUnits = null, averageRate = null, purchaseAmount = null, marketPrice = null, marketValue = null) {
        this.isFixedIncomeHoldingSummaryResponse = true;
        FixedIncomeHoldingSummaryResponse.validateIsin(isin);
        this.isin = isin;
        if (securityName !== undefined && securityName !== null) {
            FixedIncomeHoldingSummaryResponse.validateSecurityName(securityName);
        }
        this.securityName = securityName;
        if (issuerName !== undefined && issuerName !== null) {
            FixedIncomeHoldingSummaryResponse.validateIssuerName(issuerName);
        }
        this.issuerName = issuerName;
        if (instrumentType !== undefined && instrumentType !== null) {
            FixedIncomeHoldingSummaryResponse.validateInstrumentType(instrumentType);
        }
        this.instrumentType = instrumentType;
        if (maturityDate !== undefined && maturityDate !== null) {
            FixedIncomeHoldingSummaryResponse.validateMaturityDate(maturityDate);
        }
        this.maturityDate = maturityDate;
        if (callDate !== undefined && callDate !== null) {
            FixedIncomeHoldingSummaryResponse.validateCallDate(callDate);
        }
        this.callDate = callDate;
        if (putDate !== undefined && putDate !== null) {
            FixedIncomeHoldingSummaryResponse.validatePutDate(putDate);
        }
        this.putDate = putDate;
        if (category !== undefined && category !== null) {
            FixedIncomeHoldingSummaryResponse.validateCategory(category);
        }
        this.category = category;
        if (entityName !== undefined && entityName !== null) {
            FixedIncomeHoldingSummaryResponse.validateEntityName(entityName);
        }
        this.entityName = entityName;
        if (portfolioName !== undefined && portfolioName !== null) {
            FixedIncomeHoldingSummaryResponse.validatePortfolioName(portfolioName);
        }
        this.portfolioName = portfolioName;
        if (holdingUnits !== undefined && holdingUnits !== null) {
            FixedIncomeHoldingSummaryResponse.validateHoldingUnits(holdingUnits);
        }
        this.holdingUnits = holdingUnits;
        if (averageRate !== undefined && averageRate !== null) {
            FixedIncomeHoldingSummaryResponse.validateAverageRate(averageRate);
        }
        this.averageRate = averageRate;
        if (purchaseAmount !== undefined && purchaseAmount !== null) {
            FixedIncomeHoldingSummaryResponse.validatePurchaseAmount(purchaseAmount);
        }
        this.purchaseAmount = purchaseAmount;
        if (marketPrice !== undefined && marketPrice !== null) {
            FixedIncomeHoldingSummaryResponse.validateMarketPrice(marketPrice);
        }
        this.marketPrice = marketPrice;
        if (marketValue !== undefined && marketValue !== null) {
            FixedIncomeHoldingSummaryResponse.validateMarketValue(marketValue);
        }
        this.marketValue = marketValue;
    }
    static fromDTO(dto) {
        const isin = (0, leo_ts_runtime_1.getString)(dto, "isin", InvalidFixedIncomeHoldingSummaryResponseError);
        const securityName = (0, leo_ts_runtime_1.getOptionalString)(dto, "securityName", InvalidFixedIncomeHoldingSummaryResponseError);
        const issuerName = (0, leo_ts_runtime_1.getOptionalString)(dto, "issuerName", InvalidFixedIncomeHoldingSummaryResponseError);
        let instrumentType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "instrumentType");
        if (instrumentType !== undefined && instrumentType !== null) {
            instrumentType = fixedIncomeInstrumentType_1.FixedIncomeInstrumentType.fromDTO(instrumentType);
        }
        const maturityDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "maturityDate", InvalidFixedIncomeHoldingSummaryResponseError);
        const callDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "callDate", InvalidFixedIncomeHoldingSummaryResponseError);
        const putDate = (0, leo_ts_runtime_1.getOptionalLeoDate)(dto, "putDate", InvalidFixedIncomeHoldingSummaryResponseError);
        let category = (0, leo_ts_runtime_1.getOptionalObject)(dto, "category");
        if (category !== undefined && category !== null) {
            category = fixedIncomeHoldingCategory_1.FixedIncomeHoldingCategory.fromDTO(category);
        }
        const entityName = (0, leo_ts_runtime_1.getOptionalString)(dto, "entityName", InvalidFixedIncomeHoldingSummaryResponseError);
        const portfolioName = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolioName", InvalidFixedIncomeHoldingSummaryResponseError);
        const holdingUnits = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "holdingUnits", InvalidFixedIncomeHoldingSummaryResponseError);
        const averageRate = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "averageRate", InvalidFixedIncomeHoldingSummaryResponseError);
        const purchaseAmount = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "purchaseAmount", InvalidFixedIncomeHoldingSummaryResponseError);
        const marketPrice = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "marketPrice", InvalidFixedIncomeHoldingSummaryResponseError);
        const marketValue = (0, leo_ts_runtime_1.getOptionalInt64)(dto, "marketValue", InvalidFixedIncomeHoldingSummaryResponseError);
        return new FixedIncomeHoldingSummaryResponse(isin, securityName, issuerName, instrumentType, maturityDate, callDate, putDate, category, entityName, portfolioName, holdingUnits, averageRate, purchaseAmount, marketPrice, marketValue);
    }
    toDTO() {
        const ret = {
            isin: this.isin,
        };
        if (this.securityName !== undefined && this.securityName !== null) {
            ret.securityName = this.securityName;
        }
        if (this.issuerName !== undefined && this.issuerName !== null) {
            ret.issuerName = this.issuerName;
        }
        if (this.instrumentType !== undefined && this.instrumentType !== null) {
            ret.instrumentType = fixedIncomeInstrumentType_1.FixedIncomeInstrumentType.toDTO(this.instrumentType);
        }
        if (this.maturityDate) {
            ret.maturityDate = this.maturityDate.toDTO();
        }
        if (this.callDate) {
            ret.callDate = this.callDate.toDTO();
        }
        if (this.putDate) {
            ret.putDate = this.putDate.toDTO();
        }
        if (this.category !== undefined && this.category !== null) {
            ret.category = fixedIncomeHoldingCategory_1.FixedIncomeHoldingCategory.toDTO(this.category);
        }
        if (this.entityName !== undefined && this.entityName !== null) {
            ret.entityName = this.entityName;
        }
        if (this.portfolioName !== undefined && this.portfolioName !== null) {
            ret.portfolioName = this.portfolioName;
        }
        if (this.holdingUnits !== undefined && this.holdingUnits !== null) {
            ret.holdingUnits = this.holdingUnits;
        }
        if (this.averageRate !== undefined && this.averageRate !== null) {
            ret.averageRate = this.averageRate;
        }
        if (this.purchaseAmount !== undefined && this.purchaseAmount !== null) {
            ret.purchaseAmount = this.purchaseAmount;
        }
        if (this.marketPrice !== undefined && this.marketPrice !== null) {
            ret.marketPrice = this.marketPrice;
        }
        if (this.marketValue !== undefined && this.marketValue !== null) {
            ret.marketValue = this.marketValue;
        }
        return ret;
    }
    copy(isin = this.isin, securityName = this.securityName, issuerName = this.issuerName, instrumentType = this.instrumentType, maturityDate = this.maturityDate, callDate = this.callDate, putDate = this.putDate, category = this.category, entityName = this.entityName, portfolioName = this.portfolioName, holdingUnits = this.holdingUnits, averageRate = this.averageRate, purchaseAmount = this.purchaseAmount, marketPrice = this.marketPrice, marketValue = this.marketValue) {
        return new FixedIncomeHoldingSummaryResponse(isin, securityName, issuerName, instrumentType, maturityDate, callDate, putDate, category, entityName, portfolioName, holdingUnits, averageRate, purchaseAmount, marketPrice, marketValue);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isFixedIncomeHoldingSummaryResponseValue = other.isFixedIncomeHoldingSummaryResponse;
        if (!(other instanceof FixedIncomeHoldingSummaryResponse) || Boolean(isFixedIncomeHoldingSummaryResponseValue)) {
            return false;
        }
        if (this.isin !== other.isin) {
            return false;
        }
        if (this.securityName !== other.securityName) {
            return false;
        }
        if (this.issuerName !== other.issuerName) {
            return false;
        }
        if (this.instrumentType !== other.instrumentType) {
            return false;
        }
        if (this.maturityDate) {
            if (!this.maturityDate.equals(other.maturityDate)) {
                return false;
            }
        }
        if (this.callDate) {
            if (!this.callDate.equals(other.callDate)) {
                return false;
            }
        }
        if (this.putDate) {
            if (!this.putDate.equals(other.putDate)) {
                return false;
            }
        }
        if (this.category !== other.category) {
            return false;
        }
        if (this.entityName !== other.entityName) {
            return false;
        }
        if (this.portfolioName !== other.portfolioName) {
            return false;
        }
        if (this.holdingUnits !== other.holdingUnits) {
            return false;
        }
        if (this.averageRate !== other.averageRate) {
            return false;
        }
        if (this.purchaseAmount !== other.purchaseAmount) {
            return false;
        }
        if (this.marketPrice !== other.marketPrice) {
            return false;
        }
        if (this.marketValue !== other.marketValue) {
            return false;
        }
        return true;
    }
}
exports.FixedIncomeHoldingSummaryResponse = FixedIncomeHoldingSummaryResponse;
FixedIncomeHoldingSummaryResponse.validateIsin = (isin) => {
    if (!(0, leo_ts_runtime_1.isString)(isin)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute isin is not a String`);
    }
    if (isin.trim().length === 0) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute isin cannot be an empty string.`);
    }
};
FixedIncomeHoldingSummaryResponse.validateSecurityName = (securityName) => {
    if (!(0, leo_ts_runtime_1.isString)(securityName)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute securityName is not a String`);
    }
    if (securityName.trim().length === 0) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute securityName cannot be an empty string.`);
    }
};
FixedIncomeHoldingSummaryResponse.validateIssuerName = (issuerName) => {
    if (!(0, leo_ts_runtime_1.isString)(issuerName)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute issuerName is not a String`);
    }
    if (issuerName.trim().length === 0) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute issuerName cannot be an empty string.`);
    }
};
FixedIncomeHoldingSummaryResponse.validateInstrumentType = (instrumentType) => {
    if (!fixedIncomeInstrumentType_1.FixedIncomeInstrumentType.isInstanceOf(instrumentType)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute instrumentType is not a FixedIncomeInstrumentType.FixedIncomeInstrumentType`);
    }
};
FixedIncomeHoldingSummaryResponse.validateMaturityDate = (maturityDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(maturityDate)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute maturityDate is not a Date`);
    }
};
FixedIncomeHoldingSummaryResponse.validateCallDate = (callDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(callDate)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute callDate is not a Date`);
    }
};
FixedIncomeHoldingSummaryResponse.validatePutDate = (putDate) => {
    if (!(0, leo_ts_runtime_1.isLeoDate)(putDate)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute putDate is not a Date`);
    }
};
FixedIncomeHoldingSummaryResponse.validateCategory = (category) => {
    if (!fixedIncomeHoldingCategory_1.FixedIncomeHoldingCategory.isInstanceOf(category)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute category is not a FixedIncomeHoldingCategory.FixedIncomeHoldingCategory`);
    }
};
FixedIncomeHoldingSummaryResponse.validateEntityName = (entityName) => {
    if (!(0, leo_ts_runtime_1.isString)(entityName)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute entityName is not a String`);
    }
    if (entityName.trim().length === 0) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute entityName cannot be an empty string.`);
    }
};
FixedIncomeHoldingSummaryResponse.validatePortfolioName = (portfolioName) => {
    if (!(0, leo_ts_runtime_1.isString)(portfolioName)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute portfolioName is not a String`);
    }
    if (portfolioName.trim().length === 0) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute portfolioName cannot be an empty string.`);
    }
};
FixedIncomeHoldingSummaryResponse.validateHoldingUnits = (holdingUnits) => {
    if (!(0, leo_ts_runtime_1.isInt64)(holdingUnits)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute holdingUnits is not an Int64`);
    }
};
FixedIncomeHoldingSummaryResponse.validateAverageRate = (averageRate) => {
    if (!(0, leo_ts_runtime_1.isInt64)(averageRate)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute averageRate is not an Int64`);
    }
};
FixedIncomeHoldingSummaryResponse.validatePurchaseAmount = (purchaseAmount) => {
    if (!(0, leo_ts_runtime_1.isInt64)(purchaseAmount)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute purchaseAmount is not an Int64`);
    }
};
FixedIncomeHoldingSummaryResponse.validateMarketPrice = (marketPrice) => {
    if (!(0, leo_ts_runtime_1.isInt64)(marketPrice)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute marketPrice is not an Int64`);
    }
};
FixedIncomeHoldingSummaryResponse.validateMarketValue = (marketValue) => {
    if (!(0, leo_ts_runtime_1.isInt64)(marketValue)) {
        throw new InvalidFixedIncomeHoldingSummaryResponseError(`Attribute marketValue is not an Int64`);
    }
};
FixedIncomeHoldingSummaryResponse.prototype.toString = function toString() {
    return `FixedIncomeHoldingSummaryResponse(isin='${this.isin}',securityName='${this.securityName}',issuerName='${this.issuerName}',instrumentType=${this.instrumentType},maturityDate=${this.maturityDate},callDate=${this.callDate},putDate=${this.putDate},category=${this.category},entityName='${this.entityName}',portfolioName='${this.portfolioName}',holdingUnits=${this.holdingUnits},averageRate=${this.averageRate},purchaseAmount=${this.purchaseAmount},marketPrice=${this.marketPrice},marketValue=${this.marketValue})`;
};
