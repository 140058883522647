"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmWithdrawRequestRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("../types/transactionAmount");
const note_1 = require("../types/note");
/* eslint-disable import/export */
class ConfirmWithdrawRequestRPC {
}
exports.ConfirmWithdrawRequestRPC = ConfirmWithdrawRequestRPC;
(function (ConfirmWithdrawRequestRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    ConfirmWithdrawRequestRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId, fdDealRequestId, withdrawalAmount, penaltyCharges, penaltyAmount, withdrawalDate, referenceNumber = null, note = null) {
            this.isConfirmWithdrawRequestRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
            Request.validateFdDealRequestId(fdDealRequestId);
            this.fdDealRequestId = fdDealRequestId;
            Request.validateWithdrawalAmount(withdrawalAmount);
            this.withdrawalAmount = withdrawalAmount;
            Request.validatePenaltyCharges(penaltyCharges);
            this.penaltyCharges = penaltyCharges;
            Request.validatePenaltyAmount(penaltyAmount);
            this.penaltyAmount = penaltyAmount;
            Request.validateWithdrawalDate(withdrawalDate);
            this.withdrawalDate = withdrawalDate;
            if (referenceNumber !== undefined && referenceNumber !== null) {
                Request.validateReferenceNumber(referenceNumber);
            }
            this.referenceNumber = referenceNumber;
            if (note !== undefined && note !== null) {
                Request.validateNote(note);
            }
            this.note = note;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            const fdDealRequestId = (0, leo_ts_runtime_1.getInt64)(dto, "fdDealRequestId", InvalidRequestError);
            const withdrawalAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "withdrawalAmount", InvalidRequestError));
            const penaltyCharges = (0, leo_ts_runtime_1.getInt32)(dto, "penaltyCharges", InvalidRequestError);
            const penaltyAmount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "penaltyAmount", InvalidRequestError));
            const withdrawalDate = (0, leo_ts_runtime_1.getLeoDate)(dto, "withdrawalDate", InvalidRequestError);
            const referenceNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "referenceNumber", InvalidRequestError);
            let note = (0, leo_ts_runtime_1.getOptionalObject)(dto, "note");
            if (note !== undefined && note !== null) {
                note = note_1.Note.fromDTO(note);
            }
            return new Request(requestId, fdDealRequestId, withdrawalAmount, penaltyCharges, penaltyAmount, withdrawalDate, referenceNumber, note);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
                fdDealRequestId: this.fdDealRequestId,
                withdrawalAmount: this.withdrawalAmount.toDTO(),
                penaltyCharges: this.penaltyCharges,
                penaltyAmount: this.penaltyAmount.toDTO(),
                withdrawalDate: this.withdrawalDate.toDTO(),
            };
            if (this.referenceNumber !== undefined && this.referenceNumber !== null) {
                ret.referenceNumber = this.referenceNumber;
            }
            if (this.note) {
                ret.note = this.note.toDTO();
            }
            return ret;
        }
        copy(requestId = this.requestId, fdDealRequestId = this.fdDealRequestId, withdrawalAmount = this.withdrawalAmount, penaltyCharges = this.penaltyCharges, penaltyAmount = this.penaltyAmount, withdrawalDate = this.withdrawalDate, referenceNumber = this.referenceNumber, note = this.note) {
            return new Request(requestId, fdDealRequestId, withdrawalAmount, penaltyCharges, penaltyAmount, withdrawalDate, referenceNumber, note);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isConfirmWithdrawRequestRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.fdDealRequestId !== other.fdDealRequestId) {
                return false;
            }
            if (!this.withdrawalAmount.equals(other.withdrawalAmount)) {
                return false;
            }
            if (this.penaltyCharges !== other.penaltyCharges) {
                return false;
            }
            if (!this.penaltyAmount.equals(other.penaltyAmount)) {
                return false;
            }
            if (!this.withdrawalDate.equals(other.withdrawalDate)) {
                return false;
            }
            if (this.referenceNumber !== other.referenceNumber) {
                return false;
            }
            if (this.note) {
                if (!this.note.equals(other.note)) {
                    return false;
                }
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    Request.validateFdDealRequestId = (fdDealRequestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(fdDealRequestId)) {
            throw new InvalidRequestError(`Attribute fdDealRequestId is not an Int64`);
        }
    };
    Request.validateWithdrawalAmount = (withdrawalAmount) => {
        const isTransactionAmountValue = withdrawalAmount.isTransactionAmount;
        if (!(withdrawalAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidRequestError(`Attribute withdrawalAmount is not a TransactionAmount`);
        }
    };
    Request.validatePenaltyCharges = (penaltyCharges) => {
        if (!(0, leo_ts_runtime_1.isInt32)(penaltyCharges)) {
            throw new InvalidRequestError(`Attribute penaltyCharges is not an Int32`);
        }
        if (penaltyCharges > 10000000) {
            throw new InvalidRequestError(`Attribute penaltyCharges has value ${penaltyCharges}. Max value is 10000000.`);
        }
    };
    Request.validatePenaltyAmount = (penaltyAmount) => {
        const isTransactionAmountValue = penaltyAmount.isTransactionAmount;
        if (!(penaltyAmount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
            throw new InvalidRequestError(`Attribute penaltyAmount is not a TransactionAmount`);
        }
    };
    Request.validateWithdrawalDate = (withdrawalDate) => {
        if (!(0, leo_ts_runtime_1.isLeoDate)(withdrawalDate)) {
            throw new InvalidRequestError(`Attribute withdrawalDate is not a Date`);
        }
    };
    Request.validateReferenceNumber = (referenceNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(referenceNumber)) {
            throw new InvalidRequestError(`Attribute referenceNumber is not a String`);
        }
        if (referenceNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute referenceNumber cannot be an empty string.`);
        }
    };
    Request.validateNote = (note) => {
        const isNoteValue = note.isNote;
        if (!(note instanceof note_1.Note || Boolean(isNoteValue))) {
            throw new InvalidRequestError(`Attribute note is not a Note`);
        }
    };
    ConfirmWithdrawRequestRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `ConfirmWithdrawRequestRPC.Request(requestId=${this.requestId},fdDealRequestId=${this.fdDealRequestId},withdrawalAmount=${this.withdrawalAmount},penaltyCharges=${this.penaltyCharges},penaltyAmount=${this.penaltyAmount},withdrawalDate=${this.withdrawalDate},referenceNumber='${this.referenceNumber}',note=${this.note})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    ConfirmWithdrawRequestRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        static fromDTO(dto) {
            return new Response();
        }
        toDTO() {
            return {};
        }
    }
    ConfirmWithdrawRequestRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `WithAuthRPC.Response()`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `ConfirmWithdrawRequestRPC.INVALID_REQUEST_ID()`;
        };
        class InvalidWithdrawAmount extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_WITHDRAW_AMOUNT") {
                super(code);
                this.isInvalidWithdrawAmount = true;
                InvalidWithdrawAmount.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidWithdrawAmount(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidWithdrawAmount(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidWithdrawAmountValue = other.isInvalidWithdrawAmount;
                if (!(other instanceof InvalidWithdrawAmount || Boolean(isInvalidWithdrawAmountValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidWithdrawAmount.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_WITHDRAW_AMOUNT") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidWithdrawAmount = InvalidWithdrawAmount;
        InvalidWithdrawAmount.prototype.toString = function toString() {
            return `ConfirmWithdrawRequestRPC.INVALID_WITHDRAW_AMOUNT()`;
        };
        class CanModifyOnlySelfRequest extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CAN_MODIFY_ONLY_SELF_REQUEST") {
                super(code);
                this.isCanModifyOnlySelfRequest = true;
                CanModifyOnlySelfRequest.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CanModifyOnlySelfRequest(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CanModifyOnlySelfRequest(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
                if (!(other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CanModifyOnlySelfRequest.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CAN_MODIFY_ONLY_SELF_REQUEST") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CanModifyOnlySelfRequest = CanModifyOnlySelfRequest;
        CanModifyOnlySelfRequest.prototype.toString = function toString() {
            return `ConfirmWithdrawRequestRPC.CAN_MODIFY_ONLY_SELF_REQUEST()`;
        };
        class RequestNotApproved extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "REQUEST_NOT_APPROVED") {
                super(code);
                this.isRequestNotApproved = true;
                RequestNotApproved.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new RequestNotApproved(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new RequestNotApproved(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isRequestNotApprovedValue = other.isRequestNotApproved;
                if (!(other instanceof RequestNotApproved || Boolean(isRequestNotApprovedValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        RequestNotApproved.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "REQUEST_NOT_APPROVED") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.RequestNotApproved = RequestNotApproved;
        RequestNotApproved.prototype.toString = function toString() {
            return `ConfirmWithdrawRequestRPC.REQUEST_NOT_APPROVED()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                case "INVALID_WITHDRAW_AMOUNT":
                    return InvalidWithdrawAmount.fromDTO(dto);
                case "CAN_MODIFY_ONLY_SELF_REQUEST":
                    return CanModifyOnlySelfRequest.fromDTO(dto);
                case "REQUEST_NOT_APPROVED":
                    return RequestNotApproved.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            const isInvalidWithdrawAmountValue = other.isInvalidWithdrawAmount;
            if (other instanceof InvalidWithdrawAmount || Boolean(isInvalidWithdrawAmountValue)) {
                return true;
            }
            const isCanModifyOnlySelfRequestValue = other.isCanModifyOnlySelfRequest;
            if (other instanceof CanModifyOnlySelfRequest || Boolean(isCanModifyOnlySelfRequestValue)) {
                return true;
            }
            const isRequestNotApprovedValue = other.isRequestNotApproved;
            if (other instanceof RequestNotApproved || Boolean(isRequestNotApprovedValue)) {
                return true;
            }
            return false;
        };
    })(Errors = ConfirmWithdrawRequestRPC.Errors || (ConfirmWithdrawRequestRPC.Errors = {}));
})(ConfirmWithdrawRequestRPC = exports.ConfirmWithdrawRequestRPC || (exports.ConfirmWithdrawRequestRPC = {}));
