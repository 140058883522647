import React, { useState } from "react";
import {
  Banner,
  BannerSeverity,
  Dialog,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { Divider, Stack, Typography } from "@mui/material";
import {
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { NoteTextArea } from "../NoteTextArea";

const Size = {
  bannerWidth: "100%",
  detailRow: {
    container: "520px",
    header1: "200px",
    header2: "312px",
  },
};

export interface SettleRequestDetailRow {
  label: string;
  value?: string;
}

export interface SettleRequestDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSettle: (note?: string) => Promise<void>;
  detailRows: SettleRequestDetailRow[];
  bannerText?: string;
  bannerSeverity?: BannerSeverity;
  currencySymbol?: string;
}
export const SettleRequestDialog = ({
  isOpen,
  onClose,
  onSettle,
  detailRows = [],
  bannerText,
  bannerSeverity = "info",
  currencySymbol,
}: SettleRequestDialogProps): React.ReactElement => {
  const { t } = useTranslation();
  const typography = useTypography();
  const spacing = useSpacing();
  const tokens = useFoundationColorTokens();
  const [note, setNote] = useState<string>();

  const getBanner = (): React.ReactElement => {
    if (!bannerText) {
      return <></>;
    }
    return (
      <Banner
        message={bannerText}
        severity={bannerSeverity}
        width={Size.bannerWidth}
      />
    );
  };

  const detailTitle = (): React.ReactElement => {
    return (
      <Stack spacing={spacing.spaceXS}>
        <Typography
          sx={{
            ...typography.s1,
          }}
        >
          {t("common.settlementDetailsForDealRequest", {
            val: currencySymbol,
          })}
        </Typography>
        <Divider />
      </Stack>
    );
  };

  const getDetailRows = (): React.ReactElement[] => {
    return detailRows.map((row, index) => {
      return (
        <Stack key={index}>
          <Stack direction={"row"}>
            <Typography
              sx={{
                ...typography.s1,
                width: Size.detailRow.header1,
                padding: `${spacing.spaceMD} 0`,
                color: tokens.labelLowEmphasis,
              }}
            >
              {row.label}
            </Typography>
            <Typography
              sx={{
                ...typography.b1,
                width: Size.detailRow.header2,
                padding: `${spacing.spaceMD} ${spacing.spaceSM}`,
                textAlign: "right",
              }}
            >
              {row.value}
            </Typography>
          </Stack>
          <Divider />
        </Stack>
      );
    });
  };

  return (
    <Dialog
      open={isOpen}
      title={t("common.settleDealRequest")}
      onClose={onClose}
      isCloseIconPresent={true}
      onPrimaryButtonClick={(): Promise<void> => onSettle(note)}
      primaryButtonText={t("common.settleDeal")}
      disableBackdropClick={true}
    >
      <Stack spacing={spacing.spaceLG}>
        {getBanner()}
        <Stack>
          {detailTitle()}
          {getDetailRows()}
        </Stack>
        <NoteTextArea note={note} setNote={setNote} />
      </Stack>
    </Dialog>
  );
};
