"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiBuyDealRequestImpactRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const fiImpactTable_1 = require("./fiImpactTable");
const fiBuyDealValue_1 = require("./fiBuyDealValue");
const fiPriceYtm_1 = require("./fiPriceYtm");
/* eslint-disable import/export */
class GetFiBuyDealRequestImpactRPC {
}
exports.GetFiBuyDealRequestImpactRPC = GetFiBuyDealRequestImpactRPC;
(function (GetFiBuyDealRequestImpactRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiBuyDealRequestImpactRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(isin, entityId, portfolioId = null, dealValue, priceYtm) {
            this.isGetFiBuyDealRequestImpactRPCRequest = true;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            if (portfolioId !== undefined && portfolioId !== null) {
                Request.validatePortfolioId(portfolioId);
            }
            this.portfolioId = portfolioId;
            Request.validateDealValue(dealValue);
            this.dealValue = dealValue;
            Request.validatePriceYtm(priceYtm);
            this.priceYtm = priceYtm;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const portfolioId = (0, leo_ts_runtime_1.getOptionalLeoUuid)(dto, "portfolioId", InvalidRequestError);
            const dealValue = fiBuyDealValue_1.FiBuyDealValue.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "dealValue", InvalidRequestError));
            const priceYtm = fiPriceYtm_1.FiPriceYtm.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "priceYtm", InvalidRequestError));
            return new Request(isin, entityId, portfolioId, dealValue, priceYtm);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
                entityId: this.entityId.toDTO(),
                dealValue: this.dealValue.toDTO(),
                priceYtm: this.priceYtm.toDTO(),
            };
            if (this.portfolioId) {
                ret.portfolioId = this.portfolioId.toDTO();
            }
            return ret;
        }
        copy(isin = this.isin, entityId = this.entityId, portfolioId = this.portfolioId, dealValue = this.dealValue, priceYtm = this.priceYtm) {
            return new Request(isin, entityId, portfolioId, dealValue, priceYtm);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiBuyDealRequestImpactRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (this.portfolioId) {
                if (!this.portfolioId.equals(other.portfolioId)) {
                    return false;
                }
            }
            if (!this.dealValue.equals(other.dealValue)) {
                return false;
            }
            if (!this.priceYtm.equals(other.priceYtm)) {
                return false;
            }
            return true;
        }
    }
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validatePortfolioId = (portfolioId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(portfolioId)) {
            throw new InvalidRequestError(`Attribute portfolioId is not a UUID`);
        }
    };
    Request.validateDealValue = (dealValue) => {
        if (!fiBuyDealValue_1.FiBuyDealValue.isInstanceOf(dealValue)) {
            throw new InvalidRequestError(`Attribute dealValue is not a FiBuyDealValue.FiBuyDealValue`);
        }
    };
    Request.validatePriceYtm = (priceYtm) => {
        if (!fiPriceYtm_1.FiPriceYtm.isInstanceOf(priceYtm)) {
            throw new InvalidRequestError(`Attribute priceYtm is not a FiPriceYtm.FiPriceYtm`);
        }
    };
    GetFiBuyDealRequestImpactRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiBuyDealRequestImpactRPC.Request(isin=${this.isin},entityId=${this.entityId},portfolioId=${this.portfolioId},dealValue=${this.dealValue},priceYtm=${this.priceYtm})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiBuyDealRequestImpactRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(impactTable = null) {
            this.isGetFiBuyDealRequestImpactRPCResponse = true;
            if (impactTable !== undefined && impactTable !== null) {
                Response.validateImpactTable(impactTable);
            }
            this.impactTable = impactTable;
        }
        static fromDTO(dto) {
            let impactTable = (0, leo_ts_runtime_1.getOptionalObject)(dto, "impactTable");
            if (impactTable !== undefined && impactTable !== null) {
                impactTable = fiImpactTable_1.FiImpactTable.fromDTO(impactTable);
            }
            return new Response(impactTable);
        }
        toDTO() {
            const ret = {};
            if (this.impactTable) {
                ret.impactTable = this.impactTable.toDTO();
            }
            return ret;
        }
        copy(impactTable = this.impactTable) {
            return new Response(impactTable);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiBuyDealRequestImpactRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (this.impactTable) {
                if (!this.impactTable.equals(other.impactTable)) {
                    return false;
                }
            }
            return true;
        }
    }
    Response.validateImpactTable = (impactTable) => {
        const isFiImpactTableValue = impactTable.isFiImpactTable;
        if (!(impactTable instanceof fiImpactTable_1.FiImpactTable || Boolean(isFiImpactTableValue))) {
            throw new InvalidResponseError(`Attribute impactTable is not a FiImpactTable`);
        }
    };
    GetFiBuyDealRequestImpactRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiBuyDealRequestImpactRPC.Response(impactTable=${this.impactTable})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetFiBuyDealRequestImpactRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetFiBuyDealRequestImpactRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidPortfolioId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PORTFOLIO_ID") {
                super(code);
                this.isInvalidPortfolioId = true;
                InvalidPortfolioId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPortfolioId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPortfolioId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
                if (!(other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPortfolioId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PORTFOLIO_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPortfolioId = InvalidPortfolioId;
        InvalidPortfolioId.prototype.toString = function toString() {
            return `GetFiBuyDealRequestImpactRPC.INVALID_PORTFOLIO_ID()`;
        };
        class CurrencyMismatch extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "CURRENCY_MISMATCH") {
                super(code);
                this.isCurrencyMismatch = true;
                CurrencyMismatch.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new CurrencyMismatch(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new CurrencyMismatch(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isCurrencyMismatchValue = other.isCurrencyMismatch;
                if (!(other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        CurrencyMismatch.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "CURRENCY_MISMATCH") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.CurrencyMismatch = CurrencyMismatch;
        CurrencyMismatch.prototype.toString = function toString() {
            return `GetFiBuyDealRequestImpactRPC.CURRENCY_MISMATCH()`;
        };
        class NeitherMaturityNorNextOptionDateFound extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                super(code);
                this.isNeitherMaturityNorNextOptionDateFound = true;
                NeitherMaturityNorNextOptionDateFound.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new NeitherMaturityNorNextOptionDateFound(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
                if (!(other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        NeitherMaturityNorNextOptionDateFound.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.NeitherMaturityNorNextOptionDateFound = NeitherMaturityNorNextOptionDateFound;
        NeitherMaturityNorNextOptionDateFound.prototype.toString = function toString() {
            return `GetFiBuyDealRequestImpactRPC.NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_PORTFOLIO_ID":
                    return InvalidPortfolioId.fromDTO(dto);
                case "CURRENCY_MISMATCH":
                    return CurrencyMismatch.fromDTO(dto);
                case "NEITHER_MATURITY_NOR_NEXT_OPTION_DATE_FOUND":
                    return NeitherMaturityNorNextOptionDateFound.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidPortfolioIdValue = other.isInvalidPortfolioId;
            if (other instanceof InvalidPortfolioId || Boolean(isInvalidPortfolioIdValue)) {
                return true;
            }
            const isCurrencyMismatchValue = other.isCurrencyMismatch;
            if (other instanceof CurrencyMismatch || Boolean(isCurrencyMismatchValue)) {
                return true;
            }
            const isNeitherMaturityNorNextOptionDateFoundValue = other.isNeitherMaturityNorNextOptionDateFound;
            if (other instanceof NeitherMaturityNorNextOptionDateFound || Boolean(isNeitherMaturityNorNextOptionDateFoundValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiBuyDealRequestImpactRPC.Errors || (GetFiBuyDealRequestImpactRPC.Errors = {}));
})(GetFiBuyDealRequestImpactRPC = exports.GetFiBuyDealRequestImpactRPC || (exports.GetFiBuyDealRequestImpactRPC = {}));
