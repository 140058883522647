"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FiContractNoteCheckType = exports.InvalidFiContractNoteCheckTypeError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const fiHoldingCategory_1 = require("./fiHoldingCategory");
class InvalidFiContractNoteCheckTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFiContractNoteCheckTypeError";
    }
}
exports.InvalidFiContractNoteCheckTypeError = InvalidFiContractNoteCheckTypeError;
var FiContractNoteCheckType;
(function (FiContractNoteCheckType) {
    class ReviewRequest {
        constructor() {
            this.isReviewRequest = true;
        }
        static fromDTO(dto) {
            return new ReviewRequest();
        }
        toDTO() {
            const ret = {
                case: "REVIEW_REQUEST",
            };
            return ret;
        }
        copy() {
            return new ReviewRequest();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isReviewRequestValue = other.isReviewRequest;
            if (!(other instanceof ReviewRequest) || Boolean(isReviewRequestValue)) {
                return false;
            }
            return true;
        }
    }
    FiContractNoteCheckType.ReviewRequest = ReviewRequest;
    ReviewRequest.prototype.toString = function toString() {
        return `FiContractNoteCheckType.REVIEW_REQUEST()`;
    };
    class LinkRequest {
        constructor(holdingCategory) {
            this.isLinkRequest = true;
            LinkRequest.validateHoldingCategory(holdingCategory);
            this.holdingCategory = holdingCategory;
        }
        static fromDTO(dto) {
            const holdingCategory = fiHoldingCategory_1.FiHoldingCategory.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "holdingCategory", InvalidFiContractNoteCheckTypeError));
            return new LinkRequest(holdingCategory);
        }
        toDTO() {
            const ret = {
                case: "LINK_REQUEST",
                holdingCategory: fiHoldingCategory_1.FiHoldingCategory.toDTO(this.holdingCategory),
            };
            return ret;
        }
        copy(holdingCategory = this.holdingCategory) {
            return new LinkRequest(holdingCategory);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isLinkRequestValue = other.isLinkRequest;
            if (!(other instanceof LinkRequest) || Boolean(isLinkRequestValue)) {
                return false;
            }
            if (this.holdingCategory !== other.holdingCategory) {
                return false;
            }
            return true;
        }
    }
    LinkRequest.validateHoldingCategory = (holdingCategory) => {
        if (!fiHoldingCategory_1.FiHoldingCategory.isInstanceOf(holdingCategory)) {
            throw new InvalidFiContractNoteCheckTypeError(`Attribute holdingCategory is not a FiHoldingCategory.FiHoldingCategory`);
        }
    };
    FiContractNoteCheckType.LinkRequest = LinkRequest;
    LinkRequest.prototype.toString = function toString() {
        return `FiContractNoteCheckType.LINK_REQUEST(holdingCategory=${this.holdingCategory})`;
    };
    class UnknownRequest {
        constructor() {
            this.isUnknownRequest = true;
        }
        static fromDTO(dto) {
            return new UnknownRequest();
        }
        toDTO() {
            const ret = {
                case: "UNKNOWN_REQUEST",
            };
            return ret;
        }
        copy() {
            return new UnknownRequest();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isUnknownRequestValue = other.isUnknownRequest;
            if (!(other instanceof UnknownRequest) || Boolean(isUnknownRequestValue)) {
                return false;
            }
            return true;
        }
    }
    FiContractNoteCheckType.UnknownRequest = UnknownRequest;
    UnknownRequest.prototype.toString = function toString() {
        return `FiContractNoteCheckType.UNKNOWN_REQUEST()`;
    };
    class DiscardRequest {
        constructor() {
            this.isDiscardRequest = true;
        }
        static fromDTO(dto) {
            return new DiscardRequest();
        }
        toDTO() {
            const ret = {
                case: "DISCARD_REQUEST",
            };
            return ret;
        }
        copy() {
            return new DiscardRequest();
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isDiscardRequestValue = other.isDiscardRequest;
            if (!(other instanceof DiscardRequest) || Boolean(isDiscardRequestValue)) {
                return false;
            }
            return true;
        }
    }
    FiContractNoteCheckType.DiscardRequest = DiscardRequest;
    DiscardRequest.prototype.toString = function toString() {
        return `FiContractNoteCheckType.DISCARD_REQUEST()`;
    };
    FiContractNoteCheckType.fromDTO = (dto) => {
        switch (dto.case) {
            case "REVIEW_REQUEST":
                return ReviewRequest.fromDTO(dto);
            case "LINK_REQUEST":
                return LinkRequest.fromDTO(dto);
            case "UNKNOWN_REQUEST":
                return UnknownRequest.fromDTO(dto);
            case "DISCARD_REQUEST":
                return DiscardRequest.fromDTO(dto);
            default:
                throw new InvalidFiContractNoteCheckTypeError(`Case ${dto.case} is not valid case of FiContractNoteCheckType.`);
        }
    };
    FiContractNoteCheckType.isInstanceOf = (other) => {
        const isREVIEW_REQUESTValue = other.isREVIEW_REQUEST;
        if (other instanceof ReviewRequest || Boolean(isREVIEW_REQUESTValue)) {
            return true;
        }
        const isLINK_REQUESTValue = other.isLINK_REQUEST;
        if (other instanceof LinkRequest || Boolean(isLINK_REQUESTValue)) {
            return true;
        }
        const isUNKNOWN_REQUESTValue = other.isUNKNOWN_REQUEST;
        if (other instanceof UnknownRequest || Boolean(isUNKNOWN_REQUESTValue)) {
            return true;
        }
        const isDISCARD_REQUESTValue = other.isDISCARD_REQUEST;
        if (other instanceof DiscardRequest || Boolean(isDISCARD_REQUESTValue)) {
            return true;
        }
        return false;
    };
})(FiContractNoteCheckType = exports.FiContractNoteCheckType || (exports.FiContractNoteCheckType = {}));
