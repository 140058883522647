import { FiContractNotesFilterOptionsModel } from "../models/FiContractNotesFilterOptionsModel";
import { cast, types, flow, Instance, getEnv } from "mobx-state-tree";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import {
  TransactionAmount,
  GetFiContractNotesRPC,
  FiContractNoteRequestStatus,
  GetBrokerListRPC,
  FiContractNoteSearchBy,
  ModuleType,
  FixedIncomeItemsPerPage,
  FixedIncomePageIndex,
  FixedIncomeSortOrder,
  GetFiContractNotesPaginationResponse,
} from "@khazana/khazana-rpcs";
import {
  InvalidLeoUUIDError,
  LeoRPCResult,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { AllEnum } from "../../../../../types/EnumTypes";
import { FiContractNoteModel } from "../models/FiContractNoteModel";
import { createAmountModel } from "../../../models/AmountModel";
import { getFiContractNotesInitialFilter } from "../utils/SearchUtils";
import {
  GetFiContractNotesSortOrder,
  GetFiContractNotesSortOrderEnums,
} from "@khazana/khazana-rpcs/build/fixed-income/getFiContractNotesSortOrder";
import {
  useGetBrokerListRPCClientImpl,
  useGetFiContractNotesRPCClientImpl,
} from "../rpcs/RPC";
import {
  FiUploadContractNoteStore,
  createFiUploadContractNotesStore,
} from "./FiUploadContractNoteStore";
import { BrokerModel, createBrokerModel } from "../../../models/BrokerModel";
import { Logger } from "../../../../logger/Logger";

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): FixedIncomeSortOrder.FixedIncomeSortOrder => {
  if (sortOrder === "asc") {
    return FixedIncomeSortOrder.FixedIncomeSortOrder.ASCENDING;
  }
  return FixedIncomeSortOrder.FixedIncomeSortOrder.DESCENDING;
};

const getContractNoteStatusType = (
  status: string,
): FiContractNoteRequestStatus.FiContractNoteRequestStatus | null => {
  return status !== AllEnum.All
    ? FiContractNoteRequestStatus.fromDTO({ case: status })
    : null;
};

const getBrokerId = (
  brokerId: string,
  brokers: Instance<typeof BrokerModel>[],
  logger: Logger,
): LeoUUID | null | undefined => {
  const brokerStringId = brokers.find((broker) => broker.id === brokerId)?.id;
  try {
    return brokerStringId ? new LeoUUID(brokerStringId) : null;
  } catch (error) {
    if (error instanceof InvalidLeoUUIDError) {
      logger.error("brokerId conversion to UUID failed.");
    } else {
      logger.error(
        `Unhandled error: ${error} while converting brokerId: ${brokerId} to UUID`,
      );
    }
  }
};

const getSearchText = (
  searchBy: FiContractNoteSearchBy.FiContractNoteSearchBy,
  filter: Instance<typeof FiContractNotesFilterOptionsModel>,
): string | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return filter.searchText;
  }
};

export const FiContractNotesStore = types
  .model({
    filterOptions: FiContractNotesFilterOptionsModel,
    totalItems: types.number,
    contractNotes: types.array(FiContractNoteModel),
    brokers: types.array(BrokerModel),
    uploadContractNoteStore: FiUploadContractNoteStore,
    currencySymbol: types.maybeNull(types.string),
  })
  .actions((store) => ({
    updateFilterOptions(
      filterOptions: Instance<typeof FiContractNotesFilterOptionsModel>,
    ): void {
      store.filterOptions = filterOptions;
    },
    getBrokerList: flow(function* () {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      const request = new GetBrokerListRPC.Request(
        ModuleType.ModuleType.FIXED_INCOME,
      );
      const result: LeoRPCResult<
        GetBrokerListRPC.Response,
        GetBrokerListRPC.Errors.Errors
      > = yield useGetBrokerListRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.brokers = cast(
          response.brokers.map((broker) => createBrokerModel(broker)),
        );
      } else {
        logger.error(`Unhandled Error: ${result.error} from GetEntityListRPC`);
      }
    }),
    getContractNotes: flow(function* (
      pageIndex: number,
      itemsPerPage: number,
      sortOrder?: "asc" | "desc",
    ) {
      const logger = getEnv(store).logger;
      const request = new GetFiContractNotesRPC.Request(
        [
          new GetFiContractNotesSortOrder(
            0,
            GetFiContractNotesSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
            getSortOrder(sortOrder),
          ),
        ],
        getContractNoteStatusType(store.filterOptions.status),
        getBrokerId(store.filterOptions.brokerId, store.brokers, logger),
        getSearchText(
          FiContractNoteSearchBy.FiContractNoteSearchBy.CONTRACT_NOTE_NUMBER,
          store.filterOptions,
        ),
        getSearchText(
          FiContractNoteSearchBy.FiContractNoteSearchBy.SECURITY,
          store.filterOptions,
        ),
        getSearchText(
          FiContractNoteSearchBy.FiContractNoteSearchBy.REQUEST_ID,
          store.filterOptions,
        ),
        new FixedIncomeItemsPerPage(itemsPerPage),
        new FixedIncomePageIndex(pageIndex),
      );
      const apiClient = getAPIClient(store);
      const result: LeoRPCResult<
        GetFiContractNotesRPC.Response,
        GetFiContractNotesRPC.Errors.InvalidPageIndex
      > = yield useGetFiContractNotesRPCClientImpl(apiClient).execute(request);
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.currencySymbol = response.getFiContractNotesPaginationResponse[0]
          ?.currency
          ? response.getFiContractNotesPaginationResponse[0].currency?.symbol
          : null;
        const contractNotes = response.getFiContractNotesPaginationResponse.map(
          (contractNote: GetFiContractNotesPaginationResponse) => {
            return FiContractNoteModel.create({
              contractNoteId: contractNote.contractNoteId,
              status: contractNote.status,
              brokerName: contractNote.brokerName,
              fileName: contractNote.fileName,
              referenceNumber: contractNote.contractNoteNumber,
              isin: contractNote.isin,
              security: contractNote.security,
              dealRequestId: contractNote.dealRequestId,
              amount:
                contractNote.amount && contractNote.currency
                  ? createAmountModel(
                      new TransactionAmount(
                        contractNote.amount,
                        contractNote.currency,
                      ),
                    )
                  : null,
              receivedAt: new Date(contractNote.receivedAt.timestamp),
            });
          },
        );
        store.contractNotes = cast(contractNotes);
        store.totalItems = response.totalItems;
      }
    }),
  }))
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createFiContractNotesStore = (): Instance<
  typeof FiContractNotesStore
> => {
  return FiContractNotesStore.create({
    filterOptions: getFiContractNotesInitialFilter(),
    contractNotes: [],
    totalItems: 0,
    uploadContractNoteStore: createFiUploadContractNotesStore(),
  });
};
