import {
  BigDecimal,
  Currency,
  GetMFDealRequestSettlementDetailsRPC,
  MFSettlementDetails,
} from "@khazana/khazana-rpcs";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";

export class MockGetMFDealRequestSettlementDetailsRPCImpl extends GetMFDealRequestSettlementDetailsRPC {
  execute(
    _request: GetMFDealRequestSettlementDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFDealRequestSettlementDetailsRPC.Response,
      GetMFDealRequestSettlementDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetMFDealRequestSettlementDetailsRPC.Response(
      new MFSettlementDetails.AmountSettlementDetail(
        new BigDecimal("10000000000"),
        new BigDecimal("10000000000"),
        new BigDecimal("10000000000"),
        new Currency("INR", "₹"),
        false,
      ),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFDealRequestSettlementDetailsRPC.Response,
        GetMFDealRequestSettlementDetailsRPC.Errors.Errors
      >
    >;
  }
}
