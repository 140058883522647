import {
  TransactionAmount,
  AmountDate,
  Currency,
  EquityDealRequestAction,
  DealRequestDetail,
  EquityDealRequestStatus,
  EquityTransactionType,
  GetEquityDealRequestDetailsRPC,
  EquityDealRequestBrokerSectionDetail,
  AmountQuantity,
  BrokerStatusType,
  Broker,
  Quantity,
} from "@khazana/khazana-rpcs";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import {
  LeoDate,
  LeoRPCResult,
  LeoTimestamp,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { DealRequestDetailEnums } from "@khazana/khazana-rpcs/build/types/dealRequestDetail";

export class MockGetEquityDealRequestDetailsRPCImpl extends GetEquityDealRequestDetailsRPC {
  execute(
    _request: GetEquityDealRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetEquityDealRequestDetailsRPC.Response,
      GetEquityDealRequestDetailsRPC.Errors.Errors
    >
  > {
    const graphData = [
      new AmountDate(
        new TransactionAmount(9010000, new Currency("INR", "₹")),
        new LeoDate("2023-01-01"),
      ),
      new AmountDate(
        new TransactionAmount(9020000, new Currency("INR", "₹")),
        new LeoDate("2023-01-02"),
      ),
      new AmountDate(
        new TransactionAmount(9030000, new Currency("INR", "₹")),
        new LeoDate("2023-01-03"),
      ),
      new AmountDate(
        new TransactionAmount(9040000, new Currency("INR", "₹")),
        new LeoDate("2023-01-04"),
      ),
      new AmountDate(
        new TransactionAmount(9050000, new Currency("INR", "₹")),
        new LeoDate("2023-01-05"),
      ),
      new AmountDate(
        new TransactionAmount(9060000, new Currency("INR", "₹")),
        new LeoDate("2023-01-06"),
      ),
      new AmountDate(
        new TransactionAmount(9070000, new Currency("INR", "₹")),
        new LeoDate("2023-01-07"),
      ),
      new AmountDate(
        new TransactionAmount(9080000, new Currency("INR", "₹")),
        new LeoDate("2023-01-08"),
      ),
      new AmountDate(
        new TransactionAmount(9090000, new Currency("INR", "₹")),
        new LeoDate("2023-01-09"),
      ),
      new AmountDate(
        new TransactionAmount(9100000, new Currency("INR", "₹")),
        new LeoDate("2023-01-10"),
      ),
      new AmountDate(
        new TransactionAmount(9110000, new Currency("INR", "₹")),
        new LeoDate("2023-01-11"),
      ),
      new AmountDate(
        new TransactionAmount(9120000, new Currency("INR", "₹")),
        new LeoDate("2023-01-12"),
      ),
      new AmountDate(
        new TransactionAmount(9130000, new Currency("INR", "₹")),
        new LeoDate("2023-01-13"),
      ),
      new AmountDate(
        new TransactionAmount(9140000, new Currency("INR", "₹")),
        new LeoDate("2023-01-14"),
      ),
      new AmountDate(
        new TransactionAmount(9150000, new Currency("INR", "₹")),
        new LeoDate("2023-01-15"),
      ),
      new AmountDate(
        new TransactionAmount(9160000, new Currency("INR", "₹")),
        new LeoDate("2023-01-16"),
      ),
      new AmountDate(
        new TransactionAmount(9170000, new Currency("INR", "₹")),
        new LeoDate("2023-01-17"),
      ),
      new AmountDate(
        new TransactionAmount(9180000, new Currency("INR", "₹")),
        new LeoDate("2023-01-18"),
      ),
      new AmountDate(
        new TransactionAmount(9190000, new Currency("INR", "₹")),
        new LeoDate("2023-01-19"),
      ),
      new AmountDate(
        new TransactionAmount(9200000, new Currency("INR", "₹")),
        new LeoDate("2023-01-20"),
      ),
      new AmountDate(
        new TransactionAmount(9210000, new Currency("INR", "₹")),
        new LeoDate("2023-01-21"),
      ),
      new AmountDate(
        new TransactionAmount(9220000, new Currency("INR", "₹")),
        new LeoDate("2023-01-22"),
      ),
      new AmountDate(
        new TransactionAmount(9230000, new Currency("INR", "₹")),
        new LeoDate("2023-01-23"),
      ),
      new AmountDate(
        new TransactionAmount(9240000, new Currency("INR", "₹")),
        new LeoDate("2023-01-24"),
      ),
      new AmountDate(
        new TransactionAmount(9250000, new Currency("INR", "₹")),
        new LeoDate("2023-01-25"),
      ),
      new AmountDate(
        new TransactionAmount(9260000, new Currency("INR", "₹")),
        new LeoDate("2023-01-26"),
      ),
      new AmountDate(
        new TransactionAmount(9270000, new Currency("INR", "₹")),
        new LeoDate("2023-01-27"),
      ),
      new AmountDate(
        new TransactionAmount(9280000, new Currency("INR", "₹")),
        new LeoDate("2023-01-28"),
      ),
      new AmountDate(
        new TransactionAmount(9290000, new Currency("INR", "₹")),
        new LeoDate("2023-01-29"),
      ),
      new AmountDate(
        new TransactionAmount(9300000, new Currency("INR", "₹")),
        new LeoDate("2023-01-30"),
      ),
      new AmountDate(
        new TransactionAmount(9310000, new Currency("INR", "₹")),
        new LeoDate("2023-01-31"),
      ),
      new AmountDate(
        new TransactionAmount(9320000, new Currency("INR", "₹")),
        new LeoDate("2023-02-01"),
      ),
      new AmountDate(
        new TransactionAmount(9330000, new Currency("INR", "₹")),
        new LeoDate("2023-02-02"),
      ),
      new AmountDate(
        new TransactionAmount(9340000, new Currency("INR", "₹")),
        new LeoDate("2023-02-03"),
      ),
      new AmountDate(
        new TransactionAmount(9350000, new Currency("INR", "₹")),
        new LeoDate("2023-02-04"),
      ),
      new AmountDate(
        new TransactionAmount(9360000, new Currency("INR", "₹")),
        new LeoDate("2023-02-05"),
      ),
      new AmountDate(
        new TransactionAmount(9370000, new Currency("INR", "₹")),
        new LeoDate("2023-02-06"),
      ),
      new AmountDate(
        new TransactionAmount(9380000, new Currency("INR", "₹")),
        new LeoDate("2023-02-07"),
      ),
      new AmountDate(
        new TransactionAmount(9390000, new Currency("INR", "₹")),
        new LeoDate("2023-02-08"),
      ),
      new AmountDate(
        new TransactionAmount(9410000, new Currency("INR", "₹")),
        new LeoDate("2023-02-09"),
      ),
      new AmountDate(
        new TransactionAmount(9420000, new Currency("INR", "₹")),
        new LeoDate("2023-02-10"),
      ),
      new AmountDate(
        new TransactionAmount(9430000, new Currency("INR", "₹")),
        new LeoDate("2023-02-11"),
      ),
      new AmountDate(
        new TransactionAmount(9440000, new Currency("INR", "₹")),
        new LeoDate("2023-02-12"),
      ),
      new AmountDate(
        new TransactionAmount(9450000, new Currency("INR", "₹")),
        new LeoDate("2023-02-13"),
      ),
      new AmountDate(
        new TransactionAmount(9460000, new Currency("INR", "₹")),
        new LeoDate("2023-02-14"),
      ),
      new AmountDate(
        new TransactionAmount(9470000, new Currency("INR", "₹")),
        new LeoDate("2023-02-15"),
      ),
      new AmountDate(
        new TransactionAmount(9480000, new Currency("INR", "₹")),
        new LeoDate("2023-02-16"),
      ),
      new AmountDate(
        new TransactionAmount(9490000, new Currency("INR", "₹")),
        new LeoDate("2023-02-17"),
      ),
      new AmountDate(
        new TransactionAmount(9500000, new Currency("INR", "₹")),
        new LeoDate("2023-02-18"),
      ),
      new AmountDate(
        new TransactionAmount(9510000, new Currency("INR", "₹")),
        new LeoDate("2023-02-19"),
      ),
      new AmountDate(
        new TransactionAmount(9520000, new Currency("INR", "₹")),
        new LeoDate("2023-02-20"),
      ),
      new AmountDate(
        new TransactionAmount(9530000, new Currency("INR", "₹")),
        new LeoDate("2023-02-21"),
      ),
      new AmountDate(
        new TransactionAmount(9540000, new Currency("INR", "₹")),
        new LeoDate("2023-02-22"),
      ),
      new AmountDate(
        new TransactionAmount(9550000, new Currency("INR", "₹")),
        new LeoDate("2023-02-23"),
      ),
      new AmountDate(
        new TransactionAmount(9560000, new Currency("INR", "₹")),
        new LeoDate("2023-02-24"),
      ),
      new AmountDate(
        new TransactionAmount(9570000, new Currency("INR", "₹")),
        new LeoDate("2023-02-25"),
      ),
      new AmountDate(
        new TransactionAmount(9580000, new Currency("INR", "₹")),
        new LeoDate("2023-02-26"),
      ),
      new AmountDate(
        new TransactionAmount(9590000, new Currency("INR", "₹")),
        new LeoDate("2023-02-27"),
      ),
      new AmountDate(
        new TransactionAmount(9600000, new Currency("INR", "₹")),
        new LeoDate("2023-02-28"),
      ),
      new AmountDate(
        new TransactionAmount(9610000, new Currency("INR", "₹")),
        new LeoDate("2023-03-01"),
      ),
      new AmountDate(
        new TransactionAmount(9620000, new Currency("INR", "₹")),
        new LeoDate("2023-03-02"),
      ),
      new AmountDate(
        new TransactionAmount(9630000, new Currency("INR", "₹")),
        new LeoDate("2023-03-03"),
      ),
      new AmountDate(
        new TransactionAmount(9640000, new Currency("INR", "₹")),
        new LeoDate("2023-03-04"),
      ),
      new AmountDate(
        new TransactionAmount(9650000, new Currency("INR", "₹")),
        new LeoDate("2023-03-05"),
      ),
      new AmountDate(
        new TransactionAmount(9660000, new Currency("INR", "₹")),
        new LeoDate("2023-03-06"),
      ),
      new AmountDate(
        new TransactionAmount(9670000, new Currency("INR", "₹")),
        new LeoDate("2023-03-07"),
      ),
      new AmountDate(
        new TransactionAmount(9680000, new Currency("INR", "₹")),
        new LeoDate("2023-03-08"),
      ),
      new AmountDate(
        new TransactionAmount(9690000, new Currency("INR", "₹")),
        new LeoDate("2023-03-09"),
      ),
      new AmountDate(
        new TransactionAmount(9700000, new Currency("INR", "₹")),
        new LeoDate("2023-03-10"),
      ),
      new AmountDate(
        new TransactionAmount(9710000, new Currency("INR", "₹")),
        new LeoDate("2023-03-11"),
      ),
      new AmountDate(
        new TransactionAmount(9720000, new Currency("INR", "₹")),
        new LeoDate("2023-03-12"),
      ),
      new AmountDate(
        new TransactionAmount(9730000, new Currency("INR", "₹")),
        new LeoDate("2023-03-13"),
      ),
      new AmountDate(
        new TransactionAmount(9740000, new Currency("INR", "₹")),
        new LeoDate("2023-03-14"),
      ),
      new AmountDate(
        new TransactionAmount(9750000, new Currency("INR", "₹")),
        new LeoDate("2023-03-15"),
      ),
      new AmountDate(
        new TransactionAmount(9760000, new Currency("INR", "₹")),
        new LeoDate("2023-03-16"),
      ),
      new AmountDate(
        new TransactionAmount(9770000, new Currency("INR", "₹")),
        new LeoDate("2023-03-17"),
      ),
      new AmountDate(
        new TransactionAmount(9780000, new Currency("INR", "₹")),
        new LeoDate("2023-03-18"),
      ),
      new AmountDate(
        new TransactionAmount(9790000, new Currency("INR", "₹")),
        new LeoDate("2023-03-19"),
      ),
      new AmountDate(
        new TransactionAmount(9800000, new Currency("INR", "₹")),
        new LeoDate("2023-03-20"),
      ),
      new AmountDate(
        new TransactionAmount(9810000, new Currency("INR", "₹")),
        new LeoDate("2023-03-21"),
      ),
      new AmountDate(
        new TransactionAmount(9820000, new Currency("INR", "₹")),
        new LeoDate("2023-03-22"),
      ),
      new AmountDate(
        new TransactionAmount(9830000, new Currency("INR", "₹")),
        new LeoDate("2023-03-23"),
      ),
      new AmountDate(
        new TransactionAmount(9840000, new Currency("INR", "₹")),
        new LeoDate("2023-03-24"),
      ),
      new AmountDate(
        new TransactionAmount(9850000, new Currency("INR", "₹")),
        new LeoDate("2023-03-25"),
      ),
      new AmountDate(
        new TransactionAmount(9860000, new Currency("INR", "₹")),
        new LeoDate("2023-03-26"),
      ),
      new AmountDate(
        new TransactionAmount(9870000, new Currency("INR", "₹")),
        new LeoDate("2023-03-27"),
      ),
      new AmountDate(
        new TransactionAmount(9880000, new Currency("INR", "₹")),
        new LeoDate("2023-03-28"),
      ),
      new AmountDate(
        new TransactionAmount(9890000, new Currency("INR", "₹")),
        new LeoDate("2023-03-29"),
      ),
      new AmountDate(
        new TransactionAmount(9900000, new Currency("INR", "₹")),
        new LeoDate("2023-03-30"),
      ),
      new AmountDate(
        new TransactionAmount(9910000, new Currency("INR", "₹")),
        new LeoDate("2023-03-31"),
      ),
      new AmountDate(
        new TransactionAmount(9920000, new Currency("INR", "₹")),
        new LeoDate("2023-04-01"),
      ),
      new AmountDate(
        new TransactionAmount(9930000, new Currency("INR", "₹")),
        new LeoDate("2023-04-02"),
      ),
      new AmountDate(
        new TransactionAmount(9940000, new Currency("INR", "₹")),
        new LeoDate("2023-04-03"),
      ),
      new AmountDate(
        new TransactionAmount(9950000, new Currency("INR", "₹")),
        new LeoDate("2023-04-04"),
      ),
      new AmountDate(
        new TransactionAmount(9960000, new Currency("INR", "₹")),
        new LeoDate("2023-04-05"),
      ),
      new AmountDate(
        new TransactionAmount(9970000, new Currency("INR", "₹")),
        new LeoDate("2023-04-06"),
      ),
      new AmountDate(
        new TransactionAmount(9980000, new Currency("INR", "₹")),
        new LeoDate("2023-04-07"),
      ),
      new AmountDate(
        new TransactionAmount(9990000, new Currency("INR", "₹")),
        new LeoDate("2023-04-08"),
      ),
      new AmountDate(
        new TransactionAmount(10010000, new Currency("INR", "₹")),
        new LeoDate("2023-04-09"),
      ),
      new AmountDate(
        new TransactionAmount(10020000, new Currency("INR", "₹")),
        new LeoDate("2023-04-10"),
      ),
      new AmountDate(
        new TransactionAmount(10030000, new Currency("INR", "₹")),
        new LeoDate("2023-04-11"),
      ),
      new AmountDate(
        new TransactionAmount(10040000, new Currency("INR", "₹")),
        new LeoDate("2023-04-12"),
      ),
      new AmountDate(
        new TransactionAmount(10050000, new Currency("INR", "₹")),
        new LeoDate("2023-04-13"),
      ),
      new AmountDate(
        new TransactionAmount(10060000, new Currency("INR", "₹")),
        new LeoDate("2023-04-14"),
      ),
      new AmountDate(
        new TransactionAmount(10070000, new Currency("INR", "₹")),
        new LeoDate("2023-04-15"),
      ),
      new AmountDate(
        new TransactionAmount(10080000, new Currency("INR", "₹")),
        new LeoDate("2023-04-16"),
      ),
      new AmountDate(
        new TransactionAmount(10090000, new Currency("INR", "₹")),
        new LeoDate("2023-04-17"),
      ),
      new AmountDate(
        new TransactionAmount(10100000, new Currency("INR", "₹")),
        new LeoDate("2023-04-18"),
      ),
      new AmountDate(
        new TransactionAmount(10110000, new Currency("INR", "₹")),
        new LeoDate("2023-04-19"),
      ),
      new AmountDate(
        new TransactionAmount(10120000, new Currency("INR", "₹")),
        new LeoDate("2023-04-20"),
      ),
      new AmountDate(
        new TransactionAmount(10130000, new Currency("INR", "₹")),
        new LeoDate("2023-04-21"),
      ),
      new AmountDate(
        new TransactionAmount(10140000, new Currency("INR", "₹")),
        new LeoDate("2023-04-22"),
      ),
      new AmountDate(
        new TransactionAmount(10150000, new Currency("INR", "₹")),
        new LeoDate("2023-04-23"),
      ),
      new AmountDate(
        new TransactionAmount(10160000, new Currency("INR", "₹")),
        new LeoDate("2023-04-24"),
      ),
      new AmountDate(
        new TransactionAmount(10170000, new Currency("INR", "₹")),
        new LeoDate("2023-04-25"),
      ),
      new AmountDate(
        new TransactionAmount(10180000, new Currency("INR", "₹")),
        new LeoDate("2023-04-26"),
      ),
      new AmountDate(
        new TransactionAmount(10190000, new Currency("INR", "₹")),
        new LeoDate("2023-04-27"),
      ),
      new AmountDate(
        new TransactionAmount(10200000, new Currency("INR", "₹")),
        new LeoDate("2023-04-28"),
      ),
      new AmountDate(
        new TransactionAmount(10210000, new Currency("INR", "₹")),
        new LeoDate("2023-04-29"),
      ),
      new AmountDate(
        new TransactionAmount(10220000, new Currency("INR", "₹")),
        new LeoDate("2023-04-30"),
      ),
    ];

    const requestedDetails: DealRequestDetail[] = [
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.quantity",
        new DealRequestDetailEnums.CellType.QuantityType(new Quantity(1000000)),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.maxPrice",
        new DealRequestDetailEnums.CellType.AmountType(
          new TransactionAmount(10000000000, new Currency("INR", "₹")),
        ),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.grossAmount",
        new DealRequestDetailEnums.CellType.AmountType(null),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.createdAt",
        new DealRequestDetailEnums.CellType.Time(new LeoTimestamp()),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.transactionType",
        new DealRequestDetailEnums.CellType.ChipType(
          EquityTransactionType.EquityTransactionType.BUY,
        ),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.requestId",
        new DealRequestDetailEnums.CellType.Unstyled("81"),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.symbol",
        new DealRequestDetailEnums.CellType.Unstyled("AXISBANK"),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.entity",
        new DealRequestDetailEnums.CellType.Unstyled("Udit"),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.dematAccountNumber",
        new DealRequestDetailEnums.CellType.Unstyled("5224696920163445"),
      ),
      new DealRequestDetail(
        "equity.dealRequestDetails.serverMappings.portfolio",
        new DealRequestDetailEnums.CellType.Unstyled("Compounding"),
      ),
    ];

    const equityDealRequestAction = new EquityDealRequestAction(
      false,
      false,
      false,
      false,
      false,
    );

    const equityDealRequestBrokerSectionDetail: EquityDealRequestBrokerSectionDetail[] =
      [
        new EquityDealRequestBrokerSectionDetail(
          new Broker(
            new LeoUUID("70127105-fa9a-4993-9f4f-eeb03d4599a7"),
            "CLSA",
            new Currency("INR", "₹"),
          ),
          new AmountQuantity.AmountCase(
            new TransactionAmount(2400000000, new Currency("INR", "INR")),
          ),
          new BrokerStatusType.Linked(
            new AmountQuantity.AmountCase(
              new TransactionAmount(1200000000, new Currency("INR", "INR")),
            ),
            "81",
            1,
          ),
        ),
        new EquityDealRequestBrokerSectionDetail(
          new Broker(
            new LeoUUID("f2f66eea-7ae9-438e-a387-3430bba5755c"),
            "JP Morgan",
            new Currency("INR", "₹"),
          ),
          new AmountQuantity.AmountCase(
            new TransactionAmount(1200000000, new Currency("INR", "INR")),
          ),
          new BrokerStatusType.LinkingPending(),
        ),
        new EquityDealRequestBrokerSectionDetail(
          new Broker(
            new LeoUUID("d1d0c0f2-b5d7-4a43-81ae-6835e995b315"),
            "AXIS",
            new Currency("INR", "₹"),
          ),
          new AmountQuantity.AmountCase(
            new TransactionAmount(1200000000, new Currency("INR", "INR")),
          ),
          new BrokerStatusType.LinkingPending(),
        ),
      ];

    const response = new GetEquityDealRequestDetailsRPC.Response(
      equityDealRequestAction,
      EquityDealRequestStatus.EquityDealRequestStatus.IC_PENDING,
      requestedDetails,
      "ICICI BANK",
      graphData,
      equityDealRequestBrokerSectionDetail,
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetEquityDealRequestDetailsRPC.Response,
        GetEquityDealRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
