import {
  BigDecimal,
  Currency,
  FiMaturityCharge,
  GetFiMaturityTransactionDetailsRPC,
} from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiMaturityTransactionDetailsRPCImpl extends GetFiMaturityTransactionDetailsRPC {
  execute(
    _request: GetFiMaturityTransactionDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiMaturityTransactionDetailsRPC.Response,
      GetFiMaturityTransactionDetailsRPC.Errors.Errors
    >
  > {
    const response = new GetFiMaturityTransactionDetailsRPC.Response(
      new BigDecimal("10000"),
      new BigDecimal("10000000"),
      new BigDecimal("12435"),
      new Currency("INR", "₹"),
      [
        new FiMaturityCharge(
          "fi.maturityRequestDetails.tdsLabel",
          "TDS",
          1,
          new BigDecimal("10"),
        ),
        new FiMaturityCharge(
          "fi.maturityRequestDetails.otherChargesLabel",
          "OTHER_CHARGES",
          1,
          new BigDecimal("10"),
        ),
      ],
      new BigDecimal("10000000"),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiMaturityTransactionDetailsRPC.Response,
        GetFiMaturityTransactionDetailsRPC.Errors.Errors
      >
    >;
  }
}
