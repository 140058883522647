import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  GetBrokerListRPC,
  GetMFConfirmationRequestsPaginationResponse,
  GetMFConfirmationRequestsRPC,
  GetMFConfirmationRequestsSortOrder,
  MFConfirmationRequestStatus,
  MFConfirmationSearchBy,
  ModuleType,
  MutualFundItemsPerPage,
  MutualFundPageIndex,
  MutualFundSortOrder,
} from "@khazana/khazana-rpcs";
import { AllEnum } from "../../../../../../types/EnumTypes";
import { BrokerModel, createBrokerModel } from "../../../../models/BrokerModel";
import { Logger } from "../../../../../logger/Logger";
import {
  InvalidLeoUUIDError,
  LeoRPCResult,
  LeoUUID,
} from "@surya-digital/leo-ts-runtime";
import { MFConfirmationModel } from "../../models/MFConfirmationModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { useGetMFConfirmationRequestsRPC } from "../../rpc/RPC";
import { useGetBrokerListRPCClientImpl } from "../../../../fixed-income/contract-note/rpcs/RPC";
import { getMFConfirmationInitialFilter } from "../../utils/SearchUtils";
import { GetMFConfirmationRequestsSortOrderEnums } from "@khazana/khazana-rpcs/build/mutual-fund/getMFConfirmationRequestsSortOrder";

export const MFConfirmationFilterOptionsModel = types.model({
  searchBy: types.string,
  searchText: types.string,
  requestStatus: types.string,
  brokerId: types.string,
});

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): MutualFundSortOrder.MutualFundSortOrder => {
  if (sortOrder === "asc") {
    return MutualFundSortOrder.MutualFundSortOrder.ASCENDING;
  }
  return MutualFundSortOrder.MutualFundSortOrder.DESCENDING;
};

const getSearchText = (
  searchBy: MFConfirmationSearchBy.MFConfirmationSearchBy,
  filter: Instance<typeof MFConfirmationFilterOptionsModel>,
): string | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return filter.searchText;
  }
};

const getContractNoteRequestStatusType = (
  dealRequestStatus: string,
): MFConfirmationRequestStatus.MFConfirmationRequestStatus | null => {
  return dealRequestStatus !== AllEnum.All
    ? MFConfirmationRequestStatus.fromDTO({ case: dealRequestStatus })
    : null;
};

const getBrokerId = (
  brokerId: string,
  brokers: Instance<typeof BrokerModel>[],
  logger: Logger,
): LeoUUID | null | undefined => {
  const brokerStringId = brokers.find((broker) => broker.id === brokerId)?.id;
  try {
    return brokerStringId ? new LeoUUID(brokerStringId) : null;
  } catch (error) {
    if (error instanceof InvalidLeoUUIDError) {
      logger.error("brokerId conversion to UUID failed.");
    } else {
      logger.error(
        `Unhandled error: ${error} while converting brokerId: ${brokerId} to UUID`,
      );
    }
  }
};

export const ManageConfirmationDetailsStore = types
  .model("ManageContractNoteDetailsStore", {
    filterOptions: MFConfirmationFilterOptionsModel,
    totalItems: types.number,
    requests: types.array(MFConfirmationModel),
    brokers: types.array(BrokerModel),
    currencySymbol: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      updateFilterOptions(
        filterOptions: Instance<typeof MFConfirmationFilterOptionsModel>,
      ): void {
        store.filterOptions = filterOptions;
      },
      getBrokerList: flow(function* () {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetBrokerListRPC.Request(
          ModuleType.ModuleType.MUTUAL_FUND,
        );
        const result: LeoRPCResult<
          GetBrokerListRPC.Response,
          GetBrokerListRPC.Errors.Errors
        > = yield useGetBrokerListRPCClientImpl(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.brokers = cast(
            response.brokers.map((broker) => createBrokerModel(broker)),
          );
        } else {
          logger.error(
            `Unhandled Error: ${result.error} from GetBrokerListRPC`,
          );
        }
      }),
      getMFConfirmationRequests: flow(function* (
        pageIndex: number,
        itemsPerPage: number,
        sortOrder?: "asc" | "desc",
      ) {
        const logger = getEnv(store).logger;
        const apiClient = getAPIClient(store);
        const request = new GetMFConfirmationRequestsRPC.Request(
          [
            new GetMFConfirmationRequestsSortOrder(
              0,
              GetMFConfirmationRequestsSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
              getSortOrder(sortOrder),
            ),
          ],
          getSearchText(
            MFConfirmationSearchBy.MFConfirmationSearchBy.REQUEST_ID,
            store.filterOptions,
          ),
          getContractNoteRequestStatusType(store.filterOptions.requestStatus),
          getBrokerId(store.filterOptions.brokerId, store.brokers, logger),
          getSearchText(
            MFConfirmationSearchBy.MFConfirmationSearchBy.SCHEME,
            store.filterOptions,
          ),
          getSearchText(
            MFConfirmationSearchBy.MFConfirmationSearchBy.REFERENCE_NUMBER,
            store.filterOptions,
          ),
          new MutualFundItemsPerPage(itemsPerPage),
          new MutualFundPageIndex(pageIndex),
        );
        const result: LeoRPCResult<
          GetMFConfirmationRequestsRPC.Response,
          GetMFConfirmationRequestsRPC.Errors.Errors
        > = yield useGetMFConfirmationRequestsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.currencySymbol = response
            .getMFConfirmationRequestsPaginationResponse[0]?.currency
            ? response.getMFConfirmationRequestsPaginationResponse[0].currency
                .symbol
            : undefined;
          const requests =
            response.getMFConfirmationRequestsPaginationResponse.map(
              (_request: GetMFConfirmationRequestsPaginationResponse) => {
                return MFConfirmationModel.create({
                  requestId: _request.requestId,
                  status: _request.requestStatus,
                  schemeName: _request.scheme,
                  amcName: _request.amc,
                  brokerName: _request.broker ?? undefined,
                  amount: _request.amount.decimalValue,
                  receivedAt: new Date(_request.receivedAt.timestamp),
                });
              },
            );
          store.requests = cast(requests);
          store.totalItems = response.totalItems;
        }
      }),
    };
  })
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createManageConfirmationDetailsStore = (): Instance<
  typeof ManageConfirmationDetailsStore
> => {
  return ManageConfirmationDetailsStore.create({
    filterOptions: getMFConfirmationInitialFilter(),
    requests: [],
    totalItems: 0,
  });
};
