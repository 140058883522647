import { Instance } from "mobx-state-tree";
import { FDReportsStore } from "./FdReportsStore";
import { useFDStore } from "../../store/hooks";
import { FdInvestmentRegisterStore } from "./FdInvestmentRegisterStore";
import { FdHoldingSummaryStore } from "./FdHoldingSummaryStore";

export const useFdReportsStore = (): Instance<typeof FDReportsStore> => {
  return useFDStore().fdReportsStore;
};

export const useFdInvestmentRegisterStore = (): Instance<
  typeof FdInvestmentRegisterStore
> => {
  return useFDStore().fdReportsStore.fdInvestmentRegisterStore;
};

export const useFdHoldingSummaryStore = (): Instance<
  typeof FdHoldingSummaryStore
> => {
  return useFDStore().fdReportsStore.fdHoldingSummaryStore;
};
