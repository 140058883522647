import { Instance } from "mobx-state-tree";
import { useFiStore } from "../../store/hooks";
import { FiContractNotesStore } from "./FiContractNotesStore";
import { FiUploadContractNoteStore } from "./FiUploadContractNoteStore";
import { FiContractNoteDetailsStore } from "./FiContractNoteDetailsStore";
import { FiContractNoteValidateStore } from "./FiContractNoteValidateStore";
import { FiViewCashflowsStore } from "./FiViewCashflowsStore";

export const useFiContractNotesSearchStore = (): Instance<
  typeof FiContractNotesStore
> => {
  return useFiStore().contractNotesStore;
};

export const useFiUploadContractNoteStore = (): Instance<
  typeof FiUploadContractNoteStore
> => {
  return useFiStore().contractNotesStore.uploadContractNoteStore;
};

export const useFiContractNoteDetailsStore = (): Instance<
  typeof FiContractNoteDetailsStore
> => {
  return useFiStore().contractNoteDetailsStore;
};

export const useFiContractNoteValidateStore = (): Instance<
  typeof FiContractNoteValidateStore
> => {
  return useFiStore().contractNoteDetailsStore.validateStore;
};

export const useFiViewCashflowsStore = (): Instance<
  typeof FiViewCashflowsStore
> => {
  return useFiStore().fiViewCashflowsStore;
};
