import { Box, Stack } from "@mui/material";
import {
  DetailsSection,
  useCornerRadius,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-material-ui";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useViewFiMaturityRequestDetailsStore } from "../store/hooks";
import { getDetailCellRows } from "../../../utils/DetailCellUtils";
import { Module } from "../../../../../routes/RoutesEnum";

const Size = {
  container: "100%",
};

export const FiMaturityRequestDetailsSection = observer(
  (): React.ReactElement => {
    const { t } = useTranslation();
    const tokens = useFoundationColorTokens();
    const cornerRadius = useCornerRadius();
    const store = useViewFiMaturityRequestDetailsStore();

    return (
      <Stack
        sx={{
          backgroundColor: tokens.backgroundSubtle,
          width: Size.container,
          flexDirection: "row",
          borderRadius: cornerRadius.radiusXS,
        }}
      >
        <Box
          sx={{
            width: Size.container,
          }}
        >
          {store.dealDetails && (
            <DetailsSection
              title={t("common.dealRequestDetails")}
              rows={getDetailCellRows(store.dealDetails, Module.Fi, true)}
            />
          )}
        </Box>
      </Stack>
    );
  },
);
