import { FiYieldType, GetFiBuyShowYtmRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetFiBuyShowYtmRPCImpl extends GetFiBuyShowYtmRPC {
  execute(
    _request: GetFiBuyShowYtmRPC.Request,
  ): Promise<
    LeoRPCResult<GetFiBuyShowYtmRPC.Response, GetFiBuyShowYtmRPC.Errors.Errors>
  > {
    const response = new GetFiBuyShowYtmRPC.Response(FiYieldType.FiYieldType.C);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiBuyShowYtmRPC.Response,
        GetFiBuyShowYtmRPC.Errors.Errors
      >
    >;
  }
}
