import { Instance, flow, cast, types, getEnv } from "mobx-state-tree";
import {
  FileUploadError,
  getFileAttributes,
} from "../../../../../utils/FileUploadUtils";
import {
  FileAttributes,
  FileAttributesEnums,
} from "@khazana/khazana-rpcs/build/document/fileAttributes";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import {
  GetPresignedUploadUrlRPC,
  SHA256,
  SubmitFiDealContractNoteRPC,
} from "@khazana/khazana-rpcs";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { APIClient } from "@surya-digital/tedwig";
import {
  useGetPresignedUploadUrlRPCClientImpl,
  useUploadFileToBackBlaze,
  useSubmitFiDealContractNoteRPCClientImpl,
} from "../rpcs/RPC";
import { createServerNoteRPCType } from "../../../../../utils";

export enum SubmitFiDealContractNoteRPCError {
  DocumentNotFound = "DOCUMENT_NOT_FOUND",
}

const getUploadURL = async (
  fileAttributes: FileAttributes,
  apiClient: APIClient,
): Promise<{
  documentURL: URL;
}> => {
  const request = new GetPresignedUploadUrlRPC.Request(fileAttributes);
  const result: LeoRPCResult<
    GetPresignedUploadUrlRPC.Response,
    GetPresignedUploadUrlRPC.Errors.Errors
  > = await useGetPresignedUploadUrlRPCClientImpl(apiClient).execute(request);
  if (result instanceof LeoRPCResult.Response) {
    const { response } = result;
    return { documentURL: response.uploadDestinationURL };
  } else {
    return Promise.reject(FileUploadError.CouldNotFetchUploadURL);
  }
};

const _uploadFile = async (
  file: File,
  fileAttribute: FileAttributes,
  apiClient: APIClient,
): Promise<void> => {
  const { documentURL } = await getUploadURL(fileAttribute, apiClient);
  await useUploadFileToBackBlaze(documentURL, file);
};

export const FiUploadContractNoteStore = types
  .model({
    sha256: types.maybeNull(types.string),
    error: types.maybeNull(
      types.enumeration<FileUploadError>(
        "FileUploadError",
        Object.values(FileUploadError),
      ),
    ),
    submitContractNoteError: types.maybeNull(
      types.enumeration<SubmitFiDealContractNoteRPCError>(
        "SubmitFiDealContractNoteRPCError",
        Object.values(SubmitFiDealContractNoteRPCError),
      ),
    ),
    note: types.maybeNull(types.string),
    contractNoteIdList: types.maybeNull(types.array(types.string)),
  })
  .actions((store) => ({
    resetError(): void {
      store.error = null;
    },
    uploadFile: flow(function* (file: File) {
      const logger = getEnv(store).logger;
      const apiClient = getAPIClient(store);
      try {
        const result: FileAttributes = yield getFileAttributes(
          file,
          FileAttributesEnums.FileExtension.FileExtension.PDF,
          logger,
        );
        yield _uploadFile(file, result, apiClient);
        store.sha256 = result.sha256.sha256;
      } catch (error) {
        switch (error) {
          case FileUploadError.MaxFileSizeExceeded:
            store.error = FileUploadError.MaxFileSizeExceeded;
            break;
          case FileUploadError.InvalidFileFormat:
            store.error = FileUploadError.InvalidFileFormat;
            break;
          case FileUploadError.UploadFailed:
            store.error = FileUploadError.UploadFailed;
            break;
          case FileUploadError.UploadedFileIdNotFound:
            store.error = FileUploadError.UploadedFileIdNotFound;
            break;
          case FileUploadError.InvalidFileName:
            store.error = FileUploadError.InvalidFileName;
            break;
          case FileUploadError.InvalidFileSHA:
            store.error = FileUploadError.InvalidFileSHA;
            break;
          case FileUploadError.MinFileSizeDidNotExceed:
            store.error = FileUploadError.MinFileSizeDidNotExceed;
            break;
          case FileUploadError.CouldNotFetchUploadURL:
            store.error = FileUploadError.CouldNotFetchUploadURL;
            break;
          default:
            store.error = FileUploadError.InternalError;
        }
      }
    }),
    submitContractNote: flow(function* () {
      const logger = getEnv(store).logger;
      if (!store.sha256) {
        logger.error("sha256 of uploaded contract note file is not defined.");
        return;
      }
      const apiClient = getAPIClient(store);
      const request = new SubmitFiDealContractNoteRPC.Request(
        new SHA256(store.sha256),
        createServerNoteRPCType(store.note),
      );
      const result: LeoRPCResult<
        SubmitFiDealContractNoteRPC.Response,
        SubmitFiDealContractNoteRPC.Errors.Errors
      > =
        yield useSubmitFiDealContractNoteRPCClientImpl(apiClient).execute(
          request,
        );
      if (result instanceof LeoRPCResult.Response) {
        const { response } = result;
        store.contractNoteIdList = cast(
          response.requestIds.map((requestId) => requestId.toString()),
        );
      } else if (result instanceof LeoRPCResult.Error) {
        const { error } = result;
        switch (error.code) {
          case SubmitFiDealContractNoteRPCError.DocumentNotFound:
            store.submitContractNoteError =
              SubmitFiDealContractNoteRPCError.DocumentNotFound;
            break;
        }
      } else {
        logger.error(
          `Unhandled Error: ${result} from SubmitFiDealContractNoteRPC`,
        );
      }
    }),
    setNote: (value: string): void => {
      store.note = value;
    },
    resetStore: (): void => {
      store.error = null;
      store.note = "";
      store.sha256 = null;
      store.contractNoteIdList = null;
      store.submitContractNoteError = null;
    },
  }));

export const createFiUploadContractNotesStore = (): Instance<
  typeof FiUploadContractNoteStore
> => {
  return FiUploadContractNoteStore.create({
    note: "",
  });
};
