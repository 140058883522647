"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFDealRequestHistoryAdditionalDetails = exports.InvalidMFDealRequestHistoryAdditionalDetailsError = void 0;
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mFImpactTable_1 = require("./mFImpactTable");
const mFSchemeDetails_1 = require("./mFSchemeDetails");
class InvalidMFDealRequestHistoryAdditionalDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFDealRequestHistoryAdditionalDetailsError";
    }
}
exports.InvalidMFDealRequestHistoryAdditionalDetailsError = InvalidMFDealRequestHistoryAdditionalDetailsError;
var MFDealRequestHistoryAdditionalDetails;
(function (MFDealRequestHistoryAdditionalDetails) {
    class PortfolioHoldingImpactDetails {
        constructor(impactTable) {
            this.isPortfolioHoldingImpactDetails = true;
            PortfolioHoldingImpactDetails.validateImpactTable(impactTable);
            this.impactTable = impactTable;
        }
        static fromDTO(dto) {
            const impactTable = mFImpactTable_1.MFImpactTable.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "impactTable", InvalidMFDealRequestHistoryAdditionalDetailsError));
            return new PortfolioHoldingImpactDetails(impactTable);
        }
        toDTO() {
            const ret = {
                case: "PORTFOLIO_HOLDING_IMPACT_DETAILS",
                impactTable: this.impactTable.toDTO(),
            };
            return ret;
        }
        copy(impactTable = this.impactTable) {
            return new PortfolioHoldingImpactDetails(impactTable);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isPortfolioHoldingImpactDetailsValue = other.isPortfolioHoldingImpactDetails;
            if (!(other instanceof PortfolioHoldingImpactDetails) || Boolean(isPortfolioHoldingImpactDetailsValue)) {
                return false;
            }
            if (!this.impactTable.equals(other.impactTable)) {
                return false;
            }
            return true;
        }
    }
    PortfolioHoldingImpactDetails.validateImpactTable = (impactTable) => {
        const isMFImpactTableValue = impactTable.isMFImpactTable;
        if (!(impactTable instanceof mFImpactTable_1.MFImpactTable || Boolean(isMFImpactTableValue))) {
            throw new InvalidMFDealRequestHistoryAdditionalDetailsError(`Attribute impactTable is not a MFImpactTable`);
        }
    };
    MFDealRequestHistoryAdditionalDetails.PortfolioHoldingImpactDetails = PortfolioHoldingImpactDetails;
    PortfolioHoldingImpactDetails.prototype.toString = function toString() {
        return `MFDealRequestHistoryAdditionalDetails.PORTFOLIO_HOLDING_IMPACT_DETAILS(impactTable=${this.impactTable})`;
    };
    class SchemeDetails {
        constructor(schemeDetails) {
            this.isSchemeDetails = true;
            SchemeDetails.validateSchemeDetails(schemeDetails);
            this.schemeDetails = schemeDetails;
        }
        static fromDTO(dto) {
            const schemeDetails = mFSchemeDetails_1.MFSchemeDetails.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "schemeDetails", InvalidMFDealRequestHistoryAdditionalDetailsError));
            return new SchemeDetails(schemeDetails);
        }
        toDTO() {
            const ret = {
                case: "SCHEME_DETAILS",
                schemeDetails: this.schemeDetails.toDTO(),
            };
            return ret;
        }
        copy(schemeDetails = this.schemeDetails) {
            return new SchemeDetails(schemeDetails);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isSchemeDetailsValue = other.isSchemeDetails;
            if (!(other instanceof SchemeDetails) || Boolean(isSchemeDetailsValue)) {
                return false;
            }
            if (!this.schemeDetails.equals(other.schemeDetails)) {
                return false;
            }
            return true;
        }
    }
    SchemeDetails.validateSchemeDetails = (schemeDetails) => {
        const isMFSchemeDetailsValue = schemeDetails.isMFSchemeDetails;
        if (!(schemeDetails instanceof mFSchemeDetails_1.MFSchemeDetails || Boolean(isMFSchemeDetailsValue))) {
            throw new InvalidMFDealRequestHistoryAdditionalDetailsError(`Attribute schemeDetails is not a MFSchemeDetails`);
        }
    };
    MFDealRequestHistoryAdditionalDetails.SchemeDetails = SchemeDetails;
    SchemeDetails.prototype.toString = function toString() {
        return `MFDealRequestHistoryAdditionalDetails.SCHEME_DETAILS(schemeDetails=${this.schemeDetails})`;
    };
    MFDealRequestHistoryAdditionalDetails.fromDTO = (dto) => {
        switch (dto.case) {
            case "PORTFOLIO_HOLDING_IMPACT_DETAILS":
                return PortfolioHoldingImpactDetails.fromDTO(dto);
            case "SCHEME_DETAILS":
                return SchemeDetails.fromDTO(dto);
            default:
                throw new InvalidMFDealRequestHistoryAdditionalDetailsError(`Case ${dto.case} is not valid case of MFDealRequestHistoryAdditionalDetails.`);
        }
    };
    MFDealRequestHistoryAdditionalDetails.isInstanceOf = (other) => {
        const isPORTFOLIO_HOLDING_IMPACT_DETAILSValue = other.isPORTFOLIO_HOLDING_IMPACT_DETAILS;
        if (other instanceof PortfolioHoldingImpactDetails || Boolean(isPORTFOLIO_HOLDING_IMPACT_DETAILSValue)) {
            return true;
        }
        const isSCHEME_DETAILSValue = other.isSCHEME_DETAILS;
        if (other instanceof SchemeDetails || Boolean(isSCHEME_DETAILSValue)) {
            return true;
        }
        return false;
    };
})(MFDealRequestHistoryAdditionalDetails = exports.MFDealRequestHistoryAdditionalDetails || (exports.MFDealRequestHistoryAdditionalDetails = {}));
