import {
  GetMFHoldingSummarySortOrder,
  GetMFInvestmentReturnsGrowthSortOrder,
  ReportsSortOrder,
} from "@khazana/khazana-rpcs";
import { GetMFHoldingSummarySortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getMFHoldingSummarySortOrder";
import { GetMFInvestmentReturnsGrowthSortOrderEnums } from "@khazana/khazana-rpcs/build/reports/getMFInvestmentReturnsGrowthSortOrder";
import { TableSortOption } from "@surya-digital/leo-reactjs-core";
import i18next from "i18next";

export const getMfHoldingSummarySortOrder = (
  sort?: TableSortOption,
): GetMFHoldingSummarySortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetMFHoldingSummarySortOrderEnums.ColumnName.ColumnName.SCHEME_NAME;

  switch (sort?.id) {
    case i18next.t("mf.fields.schemeNameLabel"):
      column =
        GetMFHoldingSummarySortOrderEnums.ColumnName.ColumnName.SCHEME_NAME;
      break;
    case i18next.t("mf.fields.amcNameLabel"):
      column = GetMFHoldingSummarySortOrderEnums.ColumnName.ColumnName.AMC_NAME;
      break;
  }

  return new GetMFHoldingSummarySortOrder(column, sortOrder);
};

export const getMfInvestmentReturnsGrowthSortOrder = (
  sort?: TableSortOption,
): GetMFInvestmentReturnsGrowthSortOrder => {
  let sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
  switch (sort?.order) {
    case "asc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.ASCENDING;
      break;
    case "desc":
      sortOrder = ReportsSortOrder.ReportsSortOrder.DESCENDING;
      break;
  }

  let column =
    GetMFInvestmentReturnsGrowthSortOrderEnums.ColumnName.ColumnName
      .PURCHASE_ID;

  switch (sort?.id) {
    case i18next.t("mf.reports.investmentReturnsGrowthHeaders.purchaseId"):
      column =
        GetMFInvestmentReturnsGrowthSortOrderEnums.ColumnName.ColumnName
          .PURCHASE_ID;
      break;
    case i18next.t("mf.reports.investmentReturnsGrowthHeaders.schemeName"):
      column =
        GetMFInvestmentReturnsGrowthSortOrderEnums.ColumnName.ColumnName
          .SCHEME_NAME;
      break;
    case i18next.t("mf.fields.amcNameLabel"):
      column =
        GetMFInvestmentReturnsGrowthSortOrderEnums.ColumnName.ColumnName
          .AMC_NAME;
      break;
  }

  return new GetMFInvestmentReturnsGrowthSortOrder(column, sortOrder);
};
