import { Instance, types } from "mobx-state-tree";
import { MFInvestmentReturnsGrowthResponse } from "@khazana/khazana-rpcs";

export const MfInvestmentReturnsGrowthModel = types.model(
  "MfInvestmentReturnsGrowthModel",
  {
    purchaseId: types.string,
    schemeId: types.maybe(types.string),
    schemeName: types.maybe(types.string),
    amcName: types.maybe(types.string),
    brokerName: types.maybe(types.string),
    entityName: types.maybe(types.string),
    portfolioName: types.maybe(types.string),
    investmentAmount: types.maybe(types.string),
    totalUnits: types.maybe(types.string),
    outstandingUnits: types.maybe(types.string),
    navOnDoi: types.maybe(types.string),
    purchaseDate: types.maybe(types.string),
    latestNavDate: types.maybe(types.string),
    latestNav: types.maybe(types.string),
    numberOfDays: types.maybe(types.number),
    growthReturn: types.maybe(types.string),
    isGrowthReturnPositive: types.maybe(types.boolean),
    amountRealisable: types.maybe(types.string),
  },
);

export const createMfInvestmentReturnsGrowthModel = (
  response: MFInvestmentReturnsGrowthResponse,
): Instance<typeof MfInvestmentReturnsGrowthModel> => {
  return MfInvestmentReturnsGrowthModel.create({
    purchaseId: response.purchaseId,
    schemeId: response.schemeId?.isin ?? undefined,
    schemeName: response.schemeName ?? undefined,
    amcName: response.amcName ?? undefined,
    brokerName: response.brokerName ?? undefined,
    entityName: response.entityName ?? undefined,
    portfolioName: response.portfolioName ?? undefined,
    investmentAmount: response.investmentAmount?.decimalValue ?? undefined,
    totalUnits: response.totalUnits?.decimalValue ?? undefined,
    outstandingUnits: response.outstandingUnits?.decimalValue ?? undefined,
    navOnDoi: response.navOnDoi?.decimalValue ?? undefined,
    purchaseDate: response.purchaseDate?.date ?? undefined,
    latestNavDate: response.latestNavDate?.date ?? undefined,
    latestNav: response.latestNav?.decimalValue ?? undefined,
    numberOfDays: response.numberOfDays ?? undefined,
    growthReturn: response.growthReturnPct?.decimalValue ?? undefined,
    isGrowthReturnPositive: response.growthReturnPct?.isPositive ?? undefined,
    amountRealisable: response.amountRealisable?.decimalValue ?? undefined,
  });
};
