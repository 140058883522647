import { Instance, types } from "mobx-state-tree";
import { MFDealRequestOrder } from "@khazana/khazana-rpcs";
import {
  createMFBrokerOrderDetailModel,
  MFBrokerOrderDetailModel,
} from "./MFBrokerOrderDetailModel";
import {
  createMfAMCOrderDetailModel,
  MfAMCOrderDetailModel,
} from "./MfAMCOrderDetailModel";
import i18next from "i18next";

export enum OrderType {
  AMC = "AMC",
  BROKER = "BROKER",
}

export const MFDealRequestOrderModel = types
  .model("MFDealRequestOrderModel", {
    amcOrders: types.array(MfAMCOrderDetailModel),
    brokerOrders: types.array(MFBrokerOrderDetailModel),
    orderType: types.enumeration("OrderType", Object.values(OrderType)),
  })
  .actions((store) => ({
    getDealRequestOrderRPCType: (): MFDealRequestOrder.MFDealRequestOrder => {
      if (store.orderType === OrderType.AMC) {
        return new MFDealRequestOrder.Amc(
          store.amcOrders.map((order) => {
            return (
              order as Instance<typeof MfAMCOrderDetailModel>
            ).getAMCOrderDetailRPCType();
          }),
        );
      } else {
        return new MFDealRequestOrder.Broker(
          store.brokerOrders.map((order) => {
            return (
              order as Instance<typeof MFBrokerOrderDetailModel>
            ).getBrokerOrderDetailRPCType();
          }),
        );
      }
    },
  }))
  .views((store) => ({
    getOrderDialogTitle(type?: OrderType): string {
      const t = type ?? store.orderType;
      return store.orderType === t
        ? i18next.t("common.sendToAmc")
        : i18next.t("common.sendToBroker");
    },
  }));

export const createMFDealRequestOrderModel = (
  mfDealRequestOrder?: MFDealRequestOrder.MFDealRequestOrder | null,
): Instance<typeof MFDealRequestOrderModel> => {
  if (!mfDealRequestOrder) {
    return MFDealRequestOrderModel.create({ orderType: OrderType.AMC });
  }
  if (mfDealRequestOrder instanceof MFDealRequestOrder.Amc) {
    return MFDealRequestOrderModel.create({
      amcOrders: mfDealRequestOrder.orderDetails.map((order) =>
        createMfAMCOrderDetailModel(order),
      ),
      orderType: OrderType.AMC,
    });
  } else {
    return MFDealRequestOrderModel.create({
      brokerOrders: mfDealRequestOrder.orderDetails.map((order) =>
        createMFBrokerOrderDetailModel(order),
      ),
      orderType: OrderType.BROKER,
    });
  }
};
