import React, { RefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { ModuleType } from "@khazana/khazana-rpcs";
import { Period, getDateOrNull } from "../../../utils/ReportUtils";
import { ReportTablePage } from "../../../components/reports/ReportTablePage";
import {
  DatePickerInput,
  LoadingIndicator,
  TableReloadHandle,
  TableSortOption,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  ReportTableData,
  ReportTableHeaderProps,
  ReportTableHeaderWidth,
} from "../../../components/reports/ReportTable";
import { ErrorDialog } from "@khazana/khazana-boilerplate";
import { observer } from "mobx-react";
import { downloadFile } from "../../../../../utils/FileDownloadUtils";
import { useMfHoldingSummaryStore } from "../store/hooks";

export const MfHoldingSummary = observer((): React.ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const store = useMfHoldingSummaryStore();
  const [viewFilter, setViewFilter] = useState(false);
  const tableRef = useRef<TableReloadHandle>();

  useEffect(() => {
    store.getHoldingSummarySummary(0, store.itemsPerPage(), undefined);
    return () => {
      store.reset();
    };
  }, []);

  const headers: ReportTableHeaderProps[] = [
    {
      name: t("contractNotes.isin"),
    },
    {
      name: t("mf.fields.schemeNameLabel"),
      width: ReportTableHeaderWidth.TableWidthXL,
      sortable: true,
    },
    {
      name: t("common.entity"),
    },
    {
      name: t("common.portfolio"),
    },
    {
      name: t("mf.reports.holdingSummaryHeaders.holdingUnits"),
      alignment: "right",
    },
    {
      name: t("mf.reports.holdingSummaryHeaders.marketPrice"),
      alignment: "right",
    },
    {
      name: t("mf.reports.holdingSummaryHeaders.marketValue"),
      alignment: "right",
    },
    {
      name: t("mf.reports.holdingSummaryHeaders.salesProceeds"),
      alignment: "right",
    },
    {
      name: t("mf.reports.holdingSummaryHeaders.unrealizedGain"),
      alignment: "right",
    },
    {
      name: t("mf.reports.holdingSummaryHeaders.realizedGain"),
      alignment: "right",
    },
    {
      name: t("mf.reports.holdingSummaryHeaders.irr"),
      alignment: "right",
    },
  ];

  const getAsOnDate = (): DatePickerInput => {
    return getDateOrNull(store.asOnDate);
  };

  const getRows = async (
    page: number,
    itemsPerPage: number,
    sort: TableSortOption | undefined,
  ): Promise<ReportTableData> => {
    await store.getHoldingSummarySummary(page, itemsPerPage, sort);
    return {
      rows: store.mfHoldingSummarySummaryList.map((item) => {
        return [
          item.isin,
          item.schemeName,
          item.entityName,
          item.portfolioName,
          t("common.decimal4", { val: item.holdingUnits }),
          t("common.decimal2", { val: item.marketPrice }),
          t("common.decimal2", { val: item.marketValue }),
          t("common.decimal2", { val: item.salesProceed }),
          t("common.decimal2", { val: item.unrealizedGain }),
          t("common.decimal2", { val: item.realizedGain }),
          t("common.decimal2", { val: item.irr }),
        ];
      }),
      totalItems: store.totalItems,
    };
  };

  return (
    <>
      <LoadingIndicator isLoading={store.isScreenLoading} />
      <ErrorDialog
        title={t("errors.internalServerError")}
        errorMessage={t("errors.internalServerErrorDescription")}
        isErrorDialogOpen={store.hasError}
        onClose={(): void => {
          store.clearError();
        }}
      />
      {store.isDataFetched && (
        <ReportTablePage
          pageHeader={{
            title: t("mf.reports.holdingSummaryLabel"),
          }}
          reloadTable={tableRef as RefObject<TableReloadHandle>}
          previousPage={{
            label: t("common.reports"),
            onLabelClick: (): void => {
              navigate(getPath(Module.MF, Route.Reports), {
                replace: true,
              });
            },
          }}
          onEditFilter={(open) => setViewFilter(open)}
          reportFilterDialogProps={{
            isAllOptionEnabled: true,
            isPeriodRequired: false,
            open: viewFilter,
            onClose: () => setViewFilter(false),
            onViewReport: async (period): Promise<void> => {
              tableRef.current?.reload();
              await store.getHoldingSummarySummary(
                0,
                store.itemsPerPage(),
                undefined,
                period,
              );
              return setViewFilter(false);
            },
            module: ModuleType.ModuleType.MUTUAL_FUND,
            showReportType: false,
            period: {
              reportType: Period.AS_ON_DATE,
              asOnDate: getAsOnDate(),
              betweenTwoDatesDateRange: {
                startDate: null,
                endDate: null,
              },
            },
            entityDropdownStore: store.entityDropdownStore,
            portfolioDropdownStore: store.portfolioDropdownStore,
          }}
          headers={headers}
          getRows={getRows}
          itemsPerPage={store.itemsPerPage()}
          summary={undefined}
          isExportable={true}
          onExport={async (exportFormat): Promise<void> => {
            if (
              store.entityDropdownStore.selectedEntity &&
              store.portfolioDropdownStore.selectedPortfolio
            ) {
              await store.getDownloadURL(exportFormat);
              if (store.downloadURL) {
                downloadFile(store.downloadURL);
              }
            }
            return Promise.resolve();
          }}
          downloadButtonDisabled={
            store.mfHoldingSummarySummaryList.length === 0
          }
        />
      )}
    </>
  );
});
