import { types } from "mobx-state-tree";
import { CurrencyModel } from "../../../models/CurrencyModel";

export const FiCashflowModel = types.model({
  cashFlowId: types.number,
  purchaseTransactionId: types.number,
  cashflowDate: types.Date,
  cashflowType: types.string,
  rate: types.string,
  amountCCY: types.string,
  amountHCY: types.string,
  settlementDate: types.maybe(types.Date),
  settlementBankAccountNumber: types.maybe(types.string),
  settlementBank: types.maybe(types.string),
  isCredited: types.boolean,
  currency: CurrencyModel,
});
