import React from "react";
import {
  ChevronUp,
  getButtonHeight,
  getButtonIconSize,
  useButtonColorTokens,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { ButtonType } from "./HeaderButton";
import { ChevronDown } from "@surya-digital/leo-reactjs-core";
import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useBorder } from "../../../../utils/BorderUtils";

export interface MenuItemProps {
  label: string;
  onClick: () => void;
}

export interface MenuButtonProps {
  label: string;
  menuItems: MenuItemProps[];
  buttonType?: ButtonType;
  color?: string;
  buttonSize?: "small" | "medium" | "large";
  disabled?: boolean;
  menuFullWidth?: boolean;
}

const Size = {
  labelMargin: "4px",
};

export const MenuButton = ({
  label,
  menuItems,
  buttonType,
  color,
  buttonSize = "large",
  disabled = false,
  menuFullWidth = false,
}: MenuButtonProps): React.ReactElement => {
  const colorPalette = useFoundationColorTokens();
  const buttonTokens = useButtonColorTokens();
  const typography = useTypography();
  const spacing = useSpacing();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const getTextColor = (): string => {
    if (color) {
      return color;
    } else {
      return colorPalette.labelPrimary;
    }
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  function buttonVariant(): "text" | "outlined" | "contained" {
    switch (buttonType) {
      case "filled":
        return "contained";
      case "outlined":
      case "red-outlined":
        return "outlined";
      default:
        return "text";
    }
  }

  return (
    <div>
      <Button
        disableElevation
        name={label}
        ref={buttonRef}
        disabled={disabled}
        sx={{
          height: `${getButtonHeight(buttonSize)}px`,
          borderRadius: cornerRadius.radiusXS,
          padding: `${spacing.spaceXS} ${spacing.spaceMD}`,
          border: 0,
          outline: buttonType ? border.button : "unset",
          color: getTextColor(),
          position: "relative",
          "&:hover": {
            background: colorPalette.backgroundPrimarySubtle,
            border: 0,
            outline: buttonType ? border.button : "unset",
          },
          "&& .MuiTouchRipple-child": {
            background: buttonTokens.buttonBackgroundOutlinedPrimaryPressed,
          },
        }}
        variant={buttonVariant()}
        onClick={handleClick}
      >
        <>
          {label && (
            <Typography
              sx={{
                textTransform: "none",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                textAlign: "left",
                typography:
                  buttonSize === "small" ? typography.bt3 : typography.bt1,
                marginRight: Size.labelMargin,
              }}
            >
              {label}
            </Typography>
          )}
          <Stack
            width={getButtonIconSize(buttonSize).width}
            height={getButtonIconSize(buttonSize).height}
          >
            {open ? (
              <ChevronUp color={color ? color : colorPalette.iconPrimary} />
            ) : (
              <ChevronDown color={color ? color : colorPalette.iconPrimary} />
            )}
          </Stack>
        </>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        sx={{
          "& .MuiPaper-root": {
            width: menuFullWidth ? "unset" : buttonRef.current?.clientWidth,
          },
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={(): void => {
              item.onClick();
              handleClose();
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
