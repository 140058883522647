import { Instance, types } from "mobx-state-tree";
import { FixedDepositHoldingSummaryResponse } from "@khazana/khazana-rpcs";
import { getAmountOrUndefined } from "../../../../../utils";

export const FdHoldingSummaryModel = types.model("FdHoldingSummaryModel", {
  bankName: types.string,
  openingAmount: types.maybe(types.number),
  purchaseAmount: types.maybe(types.number),
  closingAmount: types.maybe(types.number),
  interestReceived: types.maybe(types.number),
  tdsPaid: types.maybe(types.number),
  otherTaxesPaid: types.maybe(types.number),
  netInterestReceived: types.maybe(types.number),
  interestAccrued: types.maybe(types.number),
  tdsOnInterestAccrued: types.maybe(types.number),
  otherTaxesOnInterestAccrued: types.maybe(types.number),
  netInterestReceivable: types.maybe(types.number),
});

export const createFdHoldingSummaryModel = (
  response: FixedDepositHoldingSummaryResponse,
): Instance<typeof FdHoldingSummaryModel> => {
  return FdHoldingSummaryModel.create({
    bankName: response.bank,
    openingAmount: getAmountOrUndefined(response.openingAmount),
    purchaseAmount: getAmountOrUndefined(response.purchaseAmount),
    closingAmount: getAmountOrUndefined(response.closingAmount),
    interestReceived: getAmountOrUndefined(response.interestReceived),
    tdsPaid: getAmountOrUndefined(response.tdsPaid),
    otherTaxesPaid: getAmountOrUndefined(response.otherTaxesPaid),
    netInterestReceived: getAmountOrUndefined(response.netInterestReceived),
    interestAccrued: getAmountOrUndefined(response.interestAccrued),
    tdsOnInterestAccrued: getAmountOrUndefined(response.tdsOnInterestAccrued),
    otherTaxesOnInterestAccrued: getAmountOrUndefined(
      response.otherTaxesOnInterestAccrued,
    ),
    netInterestReceivable: getAmountOrUndefined(response.netInterestReceivable),
  });
};
