import {
  applySnapshot,
  cast,
  flow,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import {
  GetMFDealRequestsPaginationResponse,
  GetMFDealRequestsRPC,
  GetMFDealRequestsSortOrder,
  MFDealRequestSearchBy,
  MFDealRequestStatus,
  MFTransactionType,
  MutualFundItemsPerPage,
  MutualFundPageIndex,
  MutualFundSortOrder,
} from "@khazana/khazana-rpcs";
import { AllEnum } from "../../../../../../types/EnumTypes";
import { MFDealRequestModel } from "../../models/MFDealRequestModel";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { useGetMFDealRequestsRPC } from "../../rpc/RPC";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { GetMFDealRequestsSortOrderEnums } from "@khazana/khazana-rpcs/build/mutual-fund/getMFDealRequestsSortOrder";
import { getMFDealRequestsInitialFilter } from "../../utils/SearchUtils";

export const MFDealRequestsFilterOptionsModel = types.model({
  searchBy: types.string,
  searchText: types.string,
  requestStatus: types.string,
  transactionType: types.string,
});

const getSortOrder = (
  sortOrder?: "asc" | "desc",
): MutualFundSortOrder.MutualFundSortOrder => {
  if (sortOrder === "asc") {
    return MutualFundSortOrder.MutualFundSortOrder.ASCENDING;
  }
  return MutualFundSortOrder.MutualFundSortOrder.DESCENDING;
};

const getSearchText = (
  searchBy: MFDealRequestSearchBy.MFDealRequestSearchBy,
  filter: Instance<typeof MFDealRequestsFilterOptionsModel>,
): string | null => {
  if (filter.searchBy !== searchBy || !filter.searchText.trim()) {
    return null;
  } else {
    return filter.searchText;
  }
};

const getDealRequestStatusType = (
  dealRequestStatus: string,
): MFDealRequestStatus.MFDealRequestStatus | null => {
  return dealRequestStatus !== AllEnum.All
    ? MFDealRequestStatus.fromDTO({ case: dealRequestStatus })
    : null;
};

const getTransactionType = (
  transactionType: string,
): MFTransactionType.MFTransactionType | null => {
  return transactionType !== AllEnum.All
    ? MFTransactionType.fromDTO({ case: transactionType })
    : null;
};

export const MFDealRequestsStore = types
  .model("MFDealRequestsStore", {
    filterOptions: MFDealRequestsFilterOptionsModel,
    totalItems: types.number,
    requests: types.array(MFDealRequestModel),
    currencySymbol: types.maybe(types.string),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      updateFilterOptions(
        filterOptions: Instance<typeof MFDealRequestsFilterOptionsModel>,
      ): void {
        store.filterOptions = filterOptions;
      },
      getMFDealRequests: flow(function* (
        pageIndex: number,
        itemsPerPage: number,
        sortOrder?: "asc" | "desc",
      ) {
        const apiClient = getAPIClient(store);
        const request = new GetMFDealRequestsRPC.Request(
          [
            new GetMFDealRequestsSortOrder(
              0,
              GetMFDealRequestsSortOrderEnums.ColumnName.ColumnName.CREATED_AT,
              getSortOrder(sortOrder),
            ),
          ],
          getSearchText(
            MFDealRequestSearchBy.MFDealRequestSearchBy.REQUEST_ID,
            store.filterOptions,
          ),
          getDealRequestStatusType(store.filterOptions.requestStatus),
          getSearchText(
            MFDealRequestSearchBy.MFDealRequestSearchBy.SCHEME,
            store.filterOptions,
          ),
          getSearchText(
            MFDealRequestSearchBy.MFDealRequestSearchBy.AMC,
            store.filterOptions,
          ),
          getTransactionType(store.filterOptions.transactionType),
          getSearchText(
            MFDealRequestSearchBy.MFDealRequestSearchBy.PORTFOLIO,
            store.filterOptions,
          ),
          new MutualFundItemsPerPage(itemsPerPage),
          new MutualFundPageIndex(pageIndex),
        );
        const result: LeoRPCResult<
          GetMFDealRequestsRPC.Response,
          GetMFDealRequestsRPC.Errors.Errors
        > = yield useGetMFDealRequestsRPC(apiClient).execute(request);
        if (result instanceof LeoRPCResult.Response) {
          const { response } = result;
          store.currencySymbol = response.getMFDealRequestsPaginationResponse[0]
            ?.currency
            ? response.getMFDealRequestsPaginationResponse[0].currency.symbol
            : undefined;
          const requests = response.getMFDealRequestsPaginationResponse.map(
            (_request: GetMFDealRequestsPaginationResponse) => {
              return MFDealRequestModel.create({
                requestId: _request.requestId,
                dealRequestStatus: _request.requestStatus,
                scheme: _request.scheme,
                amc: _request.amc,
                transactionType: _request.transactionType,
                amount: _request.amount?.decimalValue,
                units: _request.units?.decimalValue,
                navPerUnit: _request.navPerUnit.decimalValue,
                folioNumber: _request.folioNumber ?? undefined,
                entity: _request.entity,
                portfolio: _request.portfolio,
                receivedAt: new Date(_request.receivedAt.timestamp),
              });
            },
          );
          store.requests = cast(requests);
          store.totalItems = response.totalItems;
        }
      }),
    };
  })
  .views(() => ({
    itemsPerPage: (): number => 10,
  }));

export const createMFDealRequestsStore = (): Instance<
  typeof MFDealRequestsStore
> => {
  return MFDealRequestsStore.create({
    filterOptions: getMFDealRequestsInitialFilter(),
    requests: [],
    totalItems: 0,
  });
};
