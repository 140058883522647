import { UserLocalStorageKeys } from "./UserLocalStorageKeys";
import { cast, Instance, types } from "mobx-state-tree";
import { getPersistedUserPrivileges } from "./UserPrivileges";
import { UserName } from "@khazana/khazana-rpcs";
import { LeoEmailId } from "@surya-digital/leo-ts-runtime";

export const UserStore = types
  .model({
    privileges: types.array(types.string),
    firstName: types.optional(types.string, ""),
    lastName: types.optional(types.string, ""),
    emailId: types.optional(types.string, ""),
  })
  .actions((store) => ({
    setPrivileges(_privileges: string[]): void {
      store.privileges = cast(_privileges);
      // the privileges are persisted for the following reasons.
      // 1. they help to identify if the user is logged in or not.
      // 2. this ensures that we don't lose privileges once the page reloads ( since the state is refreshed ).
      localStorage.setItem(
        UserLocalStorageKeys.privileges,
        _privileges.toString(),
      );
    },
    setUserData(username: UserName, emailId: LeoEmailId): void {
      store.firstName = username.firstName.text;
      store.lastName = username.lastName?.text ?? "";
      store.emailId = emailId.emailId;
      localStorage.setItem(
        UserLocalStorageKeys.firstName,
        username.firstName.text,
      );
      localStorage.setItem(
        UserLocalStorageKeys.lastName,
        username.lastName?.text ?? "",
      );
      localStorage.setItem(UserLocalStorageKeys.emailId, emailId.emailId);
    },
  }))
  .views((store) => ({
    get fullName(): string {
      return `${store.firstName} ${store.lastName}`;
    },
  }));

export const removePersistedUserData = (): void => {
  localStorage.removeItem(UserLocalStorageKeys.firstName);
  localStorage.removeItem(UserLocalStorageKeys.lastName);
  localStorage.removeItem(UserLocalStorageKeys.emailId);
};

export const createUserStore = (): Instance<typeof UserStore> => {
  return UserStore.create({
    privileges: getPersistedUserPrivileges(),
    firstName: localStorage.getItem(UserLocalStorageKeys.firstName) ?? "",
    lastName: localStorage.getItem(UserLocalStorageKeys.lastName) ?? "",
    emailId: localStorage.getItem(UserLocalStorageKeys.emailId) ?? "",
  });
};
