import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";
import {
  CompoundingFrequency,
  FDCertificateDetails,
  FdDepositDuration,
  InterestType,
  PayoutFrequency,
  PeriodUnit,
  RateOfInterest,
  SHA256,
} from "@khazana/khazana-rpcs";
import {
  createCurrencyModel,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import { createServerTransactionAmountRPCType } from "../../../models/AmountModel";
import {
  getServerAmount,
  getAmountOrUndefined,
  getLeoDate,
  getUnformattedAmount,
} from "../../../../../utils";
import {
  createDepositBankBranchDropdownStore,
  BankBranchAutocompleteStore,
} from "../../../components/bank-branch/BankBranchAutocompleteStore";
import { DatePickerInput } from "@surya-digital/leo-reactjs-material-ui";
import {
  createFDDealRequestErrorStore,
  CreateFDDealRequestErrorStore,
} from "../store/CreateFDDealRequestErrorStore";
import { DetailType } from "../../../models/DetailCellModel";
import { FDDealRequestDetailsKeys } from "./FDDealRequestDetailsKeys";
import { Duration, getDetailCellValue } from "../../../utils/DetailCellUtils";

export const FDCertificateModel = types
  .model("FDCertificateModel", {
    requestId: types.number,
    certificateId: types.maybe(types.number),
    certificateURL: types.maybe(types.string),
    certificateFileName: types.maybe(types.string),
    depositBankName: types.string,
    depositBranchName: types.string,
    depositCertificateNumber: types.maybe(types.string),
    valueDate: types.maybeNull(types.Date),
    depositAmount: types.maybe(types.number),
    depositDuration: types.maybe(types.string),
    depositDurationUnit: types.enumeration<PeriodUnit.PeriodUnit>(
      "PeriodUnit",
      Object.values(PeriodUnit.PeriodUnit),
    ),
    depositAccountNumber: types.maybe(types.string),
    maturityDate: types.maybeNull(types.Date),
    rateOfInterest: types.maybe(types.number),
    interestType: types.enumeration<InterestType.InterestType>(
      "InterestType",
      Object.values(InterestType.InterestType),
    ),
    payoutFrequency: types.maybe(
      types.enumeration<PayoutFrequency.PayoutFrequency>(
        "PayoutFrequency",
        Object.values(PayoutFrequency.PayoutFrequency),
      ),
    ),
    compoundingFrequency: types.maybe(
      types.enumeration<CompoundingFrequency.CompoundingFrequency>(
        "CompoundingFrequency",
        Object.values(CompoundingFrequency.CompoundingFrequency),
      ),
    ),
    expectedMaturityAmount: types.maybe(types.number),
    currency: types.maybe(CurrencyModel),
    sha: types.maybeNull(types.string),
    depositBankBranchAutocompleteStore: BankBranchAutocompleteStore,
    errorStore: CreateFDDealRequestErrorStore,
  })
  .actions((store) => ({
    isValid: (): boolean => {
      let isValid = true;
      if (!store.depositAmount) {
        store.errorStore.setDepositAmountError("");
        isValid = false;
      }
      if (!store.valueDate) {
        store.errorStore.setValueDateError("");
        isValid = false;
      }
      if (!store.interestType) {
        store.errorStore.setInterestTypeError("");
        isValid = false;
      }
      if (!store.rateOfInterest) {
        store.errorStore.setRateOfInterestError("");
        isValid = false;
      }
      if (!store.payoutFrequency && !store.compoundingFrequency) {
        store.errorStore.setFrequencyError("");
        isValid = false;
      }
      if (!store.depositBankBranchAutocompleteStore.selectedBranchId) {
        store.errorStore.setDepositBranchError("");
        isValid = false;
      }
      if (!store.depositDuration) {
        store.errorStore.setDepositDurationError("");
        isValid = false;
      }
      if (!store.depositAccountNumber) {
        store.errorStore.setDepositAccountError("");
        isValid = false;
      }
      if (!store.maturityDate) {
        store.errorStore.setMaturityDateError("");
        isValid = false;
      }
      if (!store.expectedMaturityAmount) {
        store.errorStore.setMaturityAmountError("");
        isValid = false;
      }
      return isValid;
    },
  }))
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setDepositCertificateNumber: (number: string): void => {
        store.depositCertificateNumber = number;
      },
      setDepositAmount: (amount: string): void => {
        store.depositAmount = Number(amount);
      },
      setValueDate: (date: DatePickerInput): void => {
        store.valueDate = date;
      },
      setInterestType: (interestType: string): void => {
        store.interestType = InterestType.fromDTO({ case: interestType });
        store.compoundingFrequency = undefined;
        store.payoutFrequency = undefined;
      },
      setInterestRate: (interestRate: string): void => {
        store.rateOfInterest = Number(interestRate);
      },
      setFrequency: (frequency: string): void => {
        switch (store.interestType) {
          case InterestType.InterestType.CUMULATIVE:
            store.compoundingFrequency = CompoundingFrequency.fromDTO({
              case: frequency,
            });
            break;
          case InterestType.InterestType.SIMPLE:
            store.payoutFrequency = PayoutFrequency.fromDTO({
              case: frequency,
            });
            break;
          default:
            store.compoundingFrequency = undefined;
            store.payoutFrequency = undefined;
        }
      },
      setDepositDuration: (duration: string): void => {
        store.depositDuration = duration;
      },
      setDepositDurationUnit: (unit: string): void => {
        store.depositDurationUnit = PeriodUnit.fromDTO({ case: unit });
      },
      setDepositAccount: (account: string): void => {
        store.depositAccountNumber = account;
      },
      setMaturityDate: (date: DatePickerInput): void => {
        store.maturityDate = date;
      },
      setMaturityAmount: (amount: string): void => {
        store.expectedMaturityAmount = Number(amount);
      },
      setSHA: (sha: string | null): void => {
        store.sha = sha;
      },
      setCertificateUrl: (url: string | undefined): void => {
        store.certificateURL = url;
      },
    };
  })
  .views((store) => ({
    get frequency(): string | undefined {
      switch (store.interestType) {
        case InterestType.InterestType.CUMULATIVE:
          return store.compoundingFrequency;
        case InterestType.InterestType.SIMPLE:
          return store.payoutFrequency;
        default:
          return undefined;
      }
    },
    get currencySymbol(): string | undefined {
      return store.currency ? `(${store.currency.symbol})` : undefined;
    },
  }));

export const createFDCertificateModel = (
  requestId: number,
  details: FDCertificateDetails,
): Instance<typeof FDCertificateModel> => {
  return FDCertificateModel.create({
    requestId,
    certificateId: details.fdCertificateId ?? undefined,
    certificateURL: details.certificateURL?.toString() ?? undefined,
    certificateFileName: details.certificateFileName ?? undefined,
    sha: details.sha256?.sha256,
    depositBankName: details.depositBank,
    depositBranchName: details.depositBankBranch,
    depositBankBranchAutocompleteStore: createDepositBankBranchDropdownStore(
      details.depositBankBranchId,
    ),
    depositCertificateNumber: details.depositCertificateNumber ?? undefined,
    valueDate: new Date(details.valueDate.date),
    depositAmount: getAmountOrUndefined(details.depositAmount.amount),
    depositDuration: details.depositDuration.duration.toString(),
    depositDurationUnit: details.depositDuration.unit,
    depositAccountNumber: details.depositAccountNumber,
    maturityDate: new Date(details.maturityDate.date),
    rateOfInterest: getAmountOrUndefined(details.rateOfInterest.rateOfInterest),
    interestType: details.interestType,
    payoutFrequency: details.payoutFrequency ?? undefined,
    compoundingFrequency: details.compoundingFrequency ?? undefined,
    expectedMaturityAmount: getAmountOrUndefined(
      details.expectedMaturityAmount.amount,
    ),
    currency: createCurrencyModel(details.depositAmount.currency),
    errorStore: createFDDealRequestErrorStore(),
  });
};

export const createFDCertificateModelWithDefault = (
  details: Instance<typeof DetailType>[],
): Instance<typeof FDCertificateModel> => {
  const amountDetail = getDetailCellValue(
    details,
    FDDealRequestDetailsKeys.depositAmount,
  );
  const amount = amountDetail.value as string | undefined;
  const currency = amountDetail.currency;
  const duration = getDetailCellValue(
    details,
    FDDealRequestDetailsKeys.depositDuration,
  ).value as Duration;
  return FDCertificateModel.create({
    requestId: Number(
      getDetailCellValue(details, FDDealRequestDetailsKeys.requestId)
        .value as string,
    ),
    depositBankName: getDetailCellValue(
      details,
      FDDealRequestDetailsKeys.depositBank,
    ).value as string,
    depositBranchName: getDetailCellValue(
      details,
      FDDealRequestDetailsKeys.depositBankBranch,
    ).value as string,
    depositBankBranchAutocompleteStore: createDepositBankBranchDropdownStore(
      getDetailCellValue(details, FDDealRequestDetailsKeys.branchId)
        .value as string,
    ),
    valueDate: new Date(
      getDetailCellValue(details, FDDealRequestDetailsKeys.valueDate)
        .value as string,
    ),
    depositAmount: getUnformattedAmount(amount),
    depositDuration: duration.duration.toString(),
    depositDurationUnit: duration.unit,
    rateOfInterest:
      getUnformattedAmount(
        getDetailCellValue(details, FDDealRequestDetailsKeys.rateOfInterest)
          .value as string,
      ) ?? 0,
    interestType: getDetailCellValue(
      details,
      FDDealRequestDetailsKeys.interestType,
    ).value as InterestType.InterestType,
    payoutFrequency: getDetailCellValue(
      details,
      FDDealRequestDetailsKeys.payoutFrequency,
    ).value as PayoutFrequency.PayoutFrequency | undefined,
    compoundingFrequency: getDetailCellValue(
      details,
      FDDealRequestDetailsKeys.compoundingFrequency,
    ).value as CompoundingFrequency.CompoundingFrequency | undefined,
    maturityDate: new Date(
      getDetailCellValue(details, FDDealRequestDetailsKeys.expectedMaturityDate)
        .value as string,
    ),
    expectedMaturityAmount:
      getUnformattedAmount(
        getDetailCellValue(
          details,
          FDDealRequestDetailsKeys.expectedMaturityAmount,
        ).value as string,
      ) ?? 0,
    currency: currency
      ? CurrencyModel.create({
          code: currency.code,
          symbol: currency.symbol,
        })
      : undefined,
    errorStore: createFDDealRequestErrorStore(),
  });
};

export const createFDCertificateRPCType = (
  details: Instance<typeof FDCertificateModel>,
): FDCertificateDetails | undefined => {
  if (
    details.depositAmount &&
    details.expectedMaturityAmount &&
    details.depositBankBranchAutocompleteStore.selectedBranchId &&
    details.rateOfInterest &&
    details.maturityDate &&
    details.depositAccountNumber &&
    details.valueDate &&
    details.currency
  ) {
    return new FDCertificateDetails(
      details.certificateId,
      details.certificateURL ? new URL(details.certificateURL) : null,
      details.certificateFileName,
      details.sha ? new SHA256(details.sha) : null,
      details.depositBankName,
      details.depositBranchName,
      details.depositBankBranchAutocompleteStore.selectedBranchId,
      details.depositCertificateNumber,
      getLeoDate(details.valueDate),
      createServerTransactionAmountRPCType(
        details.depositAmount,
        details.currency,
      ),
      new FdDepositDuration(
        Number(details.depositDuration),
        details.depositDurationUnit,
      ),
      details.depositAccountNumber,
      getLeoDate(details.maturityDate),
      new RateOfInterest(getServerAmount(details.rateOfInterest)),
      details.interestType,
      details.payoutFrequency ?? null,
      details.compoundingFrequency ?? null,
      createServerTransactionAmountRPCType(
        details.expectedMaturityAmount,
        details.currency,
      ),
    );
  }
};
