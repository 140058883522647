import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNetworkingErrorStore } from "../../networking/store/hooks";
import { NetworkingError } from "@khazana/khazana-boilerplate";
import { useRootStore } from "../store/RootStore";
import { getPersistedUserPrivileges } from "../../user/UserPrivileges";
import { homeRoutes } from "../../../routes/routes";
import { useKeycloak } from "keycloak-react-web";
import { Route } from "../../../routes/RoutesEnum";
import { useFoundationColorTokens } from "@surya-digital/leo-reactjs-core";

const Size = {
  container: {
    height: "100vh",
    width: "100vw",
  },
};

export const RootPage = observer((): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const navigate = useNavigate();
  const errorStore = useNetworkingErrorStore();
  const store = useRootStore();
  const { keycloak } = useKeycloak();

  useEffect(() => {
    if (errorStore.error) {
      switch (errorStore.error) {
        case NetworkingError.InternalServerError:
          navigate("/500");
          break;
        case NetworkingError.PageNotFound:
          navigate("/404");
          break;
      }
      errorStore.removeError();
    }
  }, [errorStore.error]);

  const handleInitialStartState = async (): Promise<void> => {
    if (!store.isUserLoggedIn && !keycloak.authenticated) {
      navigate(Route.SignIn);
    } else {
      const privileges = getPersistedUserPrivileges();
      const _homeRoutes = homeRoutes(privileges, store.homeStore);
      const _childrenHomeRoutes = _homeRoutes[0].children;
      if (_childrenHomeRoutes) {
        const firstChildRoute = _childrenHomeRoutes[0];
        // This is done to handle the situation in which the child contains further sub-children ( Since the sidebar support sub-items )
        if (
          firstChildRoute.children?.length &&
          firstChildRoute.children?.length > 0
        ) {
          const modulePath = firstChildRoute.path;
          const childrenPath = firstChildRoute.children[0].path;
          const routeToNavigate = `${modulePath}/${childrenPath}`;
          if (routeToNavigate) {
            navigate(routeToNavigate);
          } else {
            console.debug(
              `Could not find route for privileges - ${privileges} hence the user is navigated to profile screen`,
            );
            // To avoid a bad user experience, we are taking the user to the profile screen if no paths are found for the children routes
            // TODO: navigate to user profile when it is added
          }
        } else {
          const routeToNavigate = firstChildRoute.path;
          if (routeToNavigate) {
            navigate(routeToNavigate);
          } else {
            console.debug(
              `Could not find route for privileges - ${privileges} hence the user is navigated to profile screen`,
            );
            // To avoid a bad user experience, we are taking the user to the profile screen if no paths are found for the children routes
            // TODO: navigate to user profile when it is added
          }
        }
      } else {
        console.debug(
          `There are no paths present in routes for privileges - ${privileges} hence the user is taken back to the auth workflow`,
        );
      }
    }
  };

  useEffect(() => {
    handleInitialStartState();
  }, []);

  return (
    <Stack
      {...Size.container}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: tokens.backgroundSubtle,
      }}
    ></Stack>
  );
});
