"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFConfirmationRequestStatus = exports.InvalidMFConfirmationRequestStatusError = void 0;
class InvalidMFConfirmationRequestStatusError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFConfirmationRequestStatusError";
    }
}
exports.InvalidMFConfirmationRequestStatusError = InvalidMFConfirmationRequestStatusError;
/* eslint-disable @typescript-eslint/no-shadow */
var MFConfirmationRequestStatus;
(function (MFConfirmationRequestStatus_1) {
    let MFConfirmationRequestStatus;
    (function (MFConfirmationRequestStatus) {
        MFConfirmationRequestStatus["FOLIO_CREATION_REQUESTED"] = "FOLIO_CREATION_REQUESTED";
        MFConfirmationRequestStatus["FOLIO_CREATION_REJECTED"] = "FOLIO_CREATION_REJECTED";
        MFConfirmationRequestStatus["ACCOUNTS_PENDING"] = "ACCOUNTS_PENDING";
        MFConfirmationRequestStatus["ACCOUNTS_APPROVED"] = "ACCOUNTS_APPROVED";
        MFConfirmationRequestStatus["ACCOUNTS_REJECTED"] = "ACCOUNTS_REJECTED";
        MFConfirmationRequestStatus["LINKING_PENDING"] = "LINKING_PENDING";
        MFConfirmationRequestStatus["LINKED"] = "LINKED";
        MFConfirmationRequestStatus["UNKNOWN_REQUESTED"] = "UNKNOWN_REQUESTED";
        MFConfirmationRequestStatus["UNKNOWN"] = "UNKNOWN";
    })(MFConfirmationRequestStatus = MFConfirmationRequestStatus_1.MFConfirmationRequestStatus || (MFConfirmationRequestStatus_1.MFConfirmationRequestStatus = {}));
    MFConfirmationRequestStatus_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "FOLIO_CREATION_REQUESTED":
                return MFConfirmationRequestStatus.FOLIO_CREATION_REQUESTED;
            case "FOLIO_CREATION_REJECTED":
                return MFConfirmationRequestStatus.FOLIO_CREATION_REJECTED;
            case "ACCOUNTS_PENDING":
                return MFConfirmationRequestStatus.ACCOUNTS_PENDING;
            case "ACCOUNTS_APPROVED":
                return MFConfirmationRequestStatus.ACCOUNTS_APPROVED;
            case "ACCOUNTS_REJECTED":
                return MFConfirmationRequestStatus.ACCOUNTS_REJECTED;
            case "LINKING_PENDING":
                return MFConfirmationRequestStatus.LINKING_PENDING;
            case "LINKED":
                return MFConfirmationRequestStatus.LINKED;
            case "UNKNOWN_REQUESTED":
                return MFConfirmationRequestStatus.UNKNOWN_REQUESTED;
            case "UNKNOWN":
                return MFConfirmationRequestStatus.UNKNOWN;
            default:
                throw new InvalidMFConfirmationRequestStatusError(`Case ${dto.case} is not valid case of MFConfirmationRequestStatus`);
        }
    };
    MFConfirmationRequestStatus_1.toDTO = (mFConfirmationRequestStatus) => {
        const ret = {
            case: MFConfirmationRequestStatus[mFConfirmationRequestStatus],
        };
        return ret;
    };
    MFConfirmationRequestStatus_1.isInstanceOf = (other) => {
        if (other in MFConfirmationRequestStatus) {
            return true;
        }
        return false;
    };
})(MFConfirmationRequestStatus = exports.MFConfirmationRequestStatus || (exports.MFConfirmationRequestStatus = {}));
/* eslint-enable @typescript-eslint/no-shadow */
