import { Instance, types } from "mobx-state-tree";
import { BigDecimal, MFOrderDetail } from "@khazana/khazana-rpcs";
import {
  createCurrencyModel,
  createCurrencyRPCType,
  CurrencyModel,
} from "../../../models/CurrencyModel";
import { getLeoDate } from "../../../../../utils";

export enum MFOrderDetailType {
  AMOUNT = "AMOUNT",
  UNITS = "UNITS",
}

export const MFOrderDetailModel = types
  .model("MFOrderDetailModel", {
    amountUnits: types.maybe(types.string),
    date: types.maybe(types.Date),
    currency: types.maybe(CurrencyModel),
    type: types.enumeration(
      "MFOrderDetailType",
      Object.values(MFOrderDetailType),
    ),
  })
  .actions((store) => ({
    getMFOrderDetailRPCType: (): MFOrderDetail.MFOrderDetail => {
      if (store.type === MFOrderDetailType.AMOUNT) {
        return new MFOrderDetail.Amount(
          new BigDecimal(store.amountUnits ?? "0"),
          store.date ? getLeoDate(store.date) : null,
          store.currency ? createCurrencyRPCType(store.currency) : null,
        );
      } else {
        return new MFOrderDetail.Units(
          new BigDecimal(store.amountUnits ?? "0"),
          store.date ? getLeoDate(store.date) : null,
        );
      }
    },
  }));

export const createMFOrderDetailModel = (
  orderDetail?: MFOrderDetail.MFOrderDetail | null,
): Instance<typeof MFOrderDetailModel> => {
  if (orderDetail instanceof MFOrderDetail.Amount) {
    return MFOrderDetailModel.create({
      amountUnits: orderDetail.amount.decimalValue ?? undefined,
      date: orderDetail.date ? new Date(orderDetail.date.date) : undefined,
      currency: orderDetail.currency
        ? createCurrencyModel(orderDetail.currency)
        : undefined,
      type: MFOrderDetailType.AMOUNT,
    });
  } else if (orderDetail instanceof MFOrderDetail.Units) {
    return MFOrderDetailModel.create({
      amountUnits: orderDetail.units.decimalValue ?? undefined,
      date: orderDetail.date ? new Date(orderDetail.date.date) : undefined,
      type: MFOrderDetailType.UNITS,
    });
  }
  return MFOrderDetailModel.create({
    type: MFOrderDetailType.AMOUNT,
  });
};
