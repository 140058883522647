"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFSchemeDetails = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
class InvalidMFSchemeDetailsError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMFSchemeDetailsError";
    }
}
class MFSchemeDetails {
    constructor(isin, amc, schemeType, planType, dividendType) {
        this.isMFSchemeDetails = true;
        MFSchemeDetails.validateIsin(isin);
        this.isin = isin;
        MFSchemeDetails.validateAmc(amc);
        this.amc = amc;
        MFSchemeDetails.validateSchemeType(schemeType);
        this.schemeType = schemeType;
        MFSchemeDetails.validatePlanType(planType);
        this.planType = planType;
        MFSchemeDetails.validateDividendType(dividendType);
        this.dividendType = dividendType;
    }
    static fromDTO(dto) {
        const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidMFSchemeDetailsError));
        const amc = (0, leo_ts_runtime_1.getString)(dto, "amc", InvalidMFSchemeDetailsError);
        const schemeType = (0, leo_ts_runtime_1.getString)(dto, "schemeType", InvalidMFSchemeDetailsError);
        const planType = (0, leo_ts_runtime_1.getString)(dto, "planType", InvalidMFSchemeDetailsError);
        const dividendType = (0, leo_ts_runtime_1.getString)(dto, "dividendType", InvalidMFSchemeDetailsError);
        return new MFSchemeDetails(isin, amc, schemeType, planType, dividendType);
    }
    toDTO() {
        const ret = {
            isin: this.isin.toDTO(),
            amc: this.amc,
            schemeType: this.schemeType,
            planType: this.planType,
            dividendType: this.dividendType,
        };
        return ret;
    }
    copy(isin = this.isin, amc = this.amc, schemeType = this.schemeType, planType = this.planType, dividendType = this.dividendType) {
        return new MFSchemeDetails(isin, amc, schemeType, planType, dividendType);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMFSchemeDetailsValue = other.isMFSchemeDetails;
        if (!(other instanceof MFSchemeDetails) || Boolean(isMFSchemeDetailsValue)) {
            return false;
        }
        if (!this.isin.equals(other.isin)) {
            return false;
        }
        if (this.amc !== other.amc) {
            return false;
        }
        if (this.schemeType !== other.schemeType) {
            return false;
        }
        if (this.planType !== other.planType) {
            return false;
        }
        if (this.dividendType !== other.dividendType) {
            return false;
        }
        return true;
    }
}
exports.MFSchemeDetails = MFSchemeDetails;
MFSchemeDetails.validateIsin = (isin) => {
    const isISINValue = isin.isISIN;
    if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
        throw new InvalidMFSchemeDetailsError(`Attribute isin is not a ISIN`);
    }
};
MFSchemeDetails.validateAmc = (amc) => {
    if (!(0, leo_ts_runtime_1.isString)(amc)) {
        throw new InvalidMFSchemeDetailsError(`Attribute amc is not a String`);
    }
    if (amc.trim().length === 0) {
        throw new InvalidMFSchemeDetailsError(`Attribute amc cannot be an empty string.`);
    }
};
MFSchemeDetails.validateSchemeType = (schemeType) => {
    if (!(0, leo_ts_runtime_1.isString)(schemeType)) {
        throw new InvalidMFSchemeDetailsError(`Attribute schemeType is not a String`);
    }
    if (schemeType.trim().length === 0) {
        throw new InvalidMFSchemeDetailsError(`Attribute schemeType cannot be an empty string.`);
    }
};
MFSchemeDetails.validatePlanType = (planType) => {
    if (!(0, leo_ts_runtime_1.isString)(planType)) {
        throw new InvalidMFSchemeDetailsError(`Attribute planType is not a String`);
    }
    if (planType.trim().length === 0) {
        throw new InvalidMFSchemeDetailsError(`Attribute planType cannot be an empty string.`);
    }
};
MFSchemeDetails.validateDividendType = (dividendType) => {
    if (!(0, leo_ts_runtime_1.isString)(dividendType)) {
        throw new InvalidMFSchemeDetailsError(`Attribute dividendType is not a String`);
    }
    if (dividendType.trim().length === 0) {
        throw new InvalidMFSchemeDetailsError(`Attribute dividendType cannot be an empty string.`);
    }
};
MFSchemeDetails.prototype.toString = function toString() {
    return `MFSchemeDetails(isin=${this.isin},amc='${this.amc}',schemeType='${this.schemeType}',planType='${this.planType}',dividendType='${this.dividendType}')`;
};
