import { applySnapshot, getSnapshot, Instance, types } from "mobx-state-tree";
import {
  createFDWithdrawListStore,
  FDWithdrawListStore,
} from "./FDWithdrawListStore";
import {
  createCreateWithdrawRequestStore,
  CreateWithdrawRequestStore,
} from "./CreateWithdrawRequestStore";
import {
  ConfirmWithdrawRequestStore,
  createConfirmWithdrawRequestStore,
} from "./ConfirmWithdrawRequestStore";
import { createFDWithdrawModel } from "../../models/FDWithdrawModel";

export const FDWithdrawStore = types
  .model("FDWithdrawStore", {
    list: FDWithdrawListStore,
    createRequest: CreateWithdrawRequestStore,
    confirmRequest: ConfirmWithdrawRequestStore,
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      reset: (): void => {
        applySnapshot(store, initialState);
      },
      setConfirmRequest: (id: number): void => {
        const withdrawDetails = store.list.items.find(
          (item) => item.requestId === id,
        );
        store.confirmRequest.setFields(createFDWithdrawModel(withdrawDetails));
      },
    };
  });

export const createFDWithdrawStore = (): Instance<typeof FDWithdrawStore> => {
  return FDWithdrawStore.create({
    list: createFDWithdrawListStore(),
    createRequest: createCreateWithdrawRequestStore(),
    confirmRequest: createConfirmWithdrawRequestStore(),
  });
};
