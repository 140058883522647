"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBranchSearchListRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bankBranch_1 = require("../types/bankBranch");
/* eslint-disable import/export */
class GetBranchSearchListRPC {
}
exports.GetBranchSearchListRPC = GetBranchSearchListRPC;
(function (GetBranchSearchListRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetBranchSearchListRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(searchText) {
            this.isGetBranchSearchListRPCRequest = true;
            Request.validateSearchText(searchText);
            this.searchText = searchText;
        }
        static fromDTO(dto) {
            const searchText = (0, leo_ts_runtime_1.getString)(dto, "searchText", InvalidRequestError);
            return new Request(searchText);
        }
        toDTO() {
            const ret = {
                searchText: this.searchText,
            };
            return ret;
        }
        copy(searchText = this.searchText) {
            return new Request(searchText);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetBranchSearchListRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.searchText !== other.searchText) {
                return false;
            }
            return true;
        }
    }
    Request.validateSearchText = (searchText) => {
        if (!(0, leo_ts_runtime_1.isString)(searchText)) {
            throw new InvalidRequestError(`Attribute searchText is not a String`);
        }
        if (searchText.length < 2) {
            throw new InvalidRequestError(`Attribute searchText is too short. Size is ${searchText.length}. Min size is 2.`);
        }
        if (searchText.trim().length === 0) {
            throw new InvalidRequestError(`Attribute searchText cannot be an empty string.`);
        }
    };
    GetBranchSearchListRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetBranchSearchListRPC.Request(searchText='${this.searchText}')`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetBranchSearchListRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(branches) {
            this.isGetBranchSearchListRPCResponse = true;
            Response.validateBranches(branches);
            this.branches = branches;
        }
        static fromDTO(dto) {
            const branches = (0, leo_ts_runtime_1.getList)(dto, "branches", bankBranch_1.BankBranch.fromDTO, InvalidResponseError);
            return new Response(branches);
        }
        toDTO() {
            const ret = {
                branches: this.branches.map((e) => {
                    return e.toDTO();
                }),
            };
            return ret;
        }
        copy(branches = this.branches) {
            return new Response(branches);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetBranchSearchListRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.branches, other.branches)) {
                return false;
            }
            return true;
        }
    }
    Response.validateBranches = (branches) => {
        if (!Array.isArray(branches)) {
            throw new InvalidResponseError(`Attribute branches is not a List`);
        }
        for (let i = 0; i < branches.length; i += 1) {
            const isBankBranchValue = branches[i].isBankBranch;
            if (!(branches[i] instanceof bankBranch_1.BankBranch || Boolean(isBankBranchValue))) {
                throw new InvalidResponseError(`Attribute branches at index ${i} is not a BankBranch.`);
            }
        }
    };
    GetBranchSearchListRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetBranchSearchListRPC.Response(branches=${this.branches})`;
    };
    let Errors;
    (function (Errors) {
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            return false;
        };
    })(Errors = GetBranchSearchListRPC.Errors || (GetBranchSearchListRPC.Errors = {}));
})(GetBranchSearchListRPC = exports.GetBranchSearchListRPC || (exports.GetBranchSearchListRPC = {}));
