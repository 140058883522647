import { GetMFAmcListRPC, MFAmc } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFAmcListRPCImpl extends GetMFAmcListRPC {
  execute(
    _request: GetMFAmcListRPC.Request,
  ): Promise<
    LeoRPCResult<GetMFAmcListRPC.Response, GetMFAmcListRPC.Errors.Errors>
  > {
    const response = new GetMFAmcListRPC.Response([new MFAmc(1, "Axis")]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<GetMFAmcListRPC.Response, GetMFAmcListRPC.Errors.Errors>
    >;
  }
}
