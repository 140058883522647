"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YieldObject = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
class InvalidYieldObjectError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidYieldObjectError";
    }
}
class YieldObject {
    constructor(yieldObject) {
        this.isYieldObject = true;
        YieldObject.validateYieldObject(yieldObject);
        this.yieldObject = yieldObject;
    }
    static fromDTO(dto) {
        const yieldObject = (0, leo_ts_runtime_1.getInt32)(dto, "yieldObject", InvalidYieldObjectError);
        return new YieldObject(yieldObject);
    }
    toDTO() {
        const ret = {
            yieldObject: this.yieldObject,
        };
        return ret;
    }
    copy(yieldObject = this.yieldObject) {
        return new YieldObject(yieldObject);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isYieldObjectValue = other.isYieldObject;
        if (!(other instanceof YieldObject) || Boolean(isYieldObjectValue)) {
            return false;
        }
        if (this.yieldObject !== other.yieldObject) {
            return false;
        }
        return true;
    }
}
exports.YieldObject = YieldObject;
YieldObject.validateYieldObject = (yieldObject) => {
    if (!(0, leo_ts_runtime_1.isInt32)(yieldObject)) {
        throw new InvalidYieldObjectError(`Attribute yieldObject is not an Int32`);
    }
    if (yieldObject < -1000000) {
        throw new InvalidYieldObjectError(`Attribute yieldObject has value ${yieldObject}. Min value is -1000000.`);
    }
    if (yieldObject > 1000000) {
        throw new InvalidYieldObjectError(`Attribute yieldObject has value ${yieldObject}. Max value is 1000000.`);
    }
};
YieldObject.prototype.toString = function toString() {
    return `YieldObject(yieldObject=${this.yieldObject})`;
};
