import { Instance, types } from "mobx-state-tree";
import { EquityHoldingSummaryResponse } from "@khazana/khazana-rpcs";
import { getAmountOrUndefined } from "../../../../../utils";

export const HoldingSummaryModel = types.model("HoldingSummaryModel", {
  symbol: types.string,
  residualQuantity: types.maybe(types.number),
  holdingPeriod: types.maybe(types.number),
  activeCost: types.maybe(types.number),
  activeMarketValue: types.maybe(types.number),
  activeUnrealizedGain: types.maybe(types.number),
  activeRealizedGain: types.maybe(types.number),
  irr: types.maybe(types.number),
  change: types.maybe(types.number),
  netGain: types.maybe(types.number),
});

export const createHoldingSummaryModel = (
  response: EquityHoldingSummaryResponse,
): Instance<typeof HoldingSummaryModel> => {
  return HoldingSummaryModel.create({
    symbol: response.symbol,
    residualQuantity: response.residualQuantity ?? undefined,
    holdingPeriod: getAmountOrUndefined(response.holdingPeriod),
    activeCost: getAmountOrUndefined(response.activeCost),
    activeMarketValue: getAmountOrUndefined(response.activeMarketValue),
    activeUnrealizedGain: getAmountOrUndefined(response.activeUnrealizedgain),
    activeRealizedGain: getAmountOrUndefined(response.activeRealizedGain),
    irr: getAmountOrUndefined(response.irr),
    change: getAmountOrUndefined(response.change),
    netGain: getAmountOrUndefined(response.netGain),
  });
};
