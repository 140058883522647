import { Instance, types } from "mobx-state-tree";
import { CashflowTimelineItem } from "@khazana/khazana-rpcs";
import {
  CashflowTimelineAmountModel,
  createCashflowTimelineAmountModel,
} from "./CashflowTimelineAmountModel";

export const CashflowTimelineModel = types.model("CashflowTimelineModel", {
  cashflowDate: types.Date,
  timelineAmount: types.array(CashflowTimelineAmountModel),
});

export const createCashflowTimelineModel = ({
  cashflowDate,
  timelineAmount,
}: CashflowTimelineItem): Instance<typeof CashflowTimelineModel> => {
  return CashflowTimelineModel.create({
    cashflowDate: new Date(cashflowDate.date),
    timelineAmount: timelineAmount.map((amount) => {
      return createCashflowTimelineAmountModel(amount);
    }),
  });
};
