import {
  applySnapshot,
  cast,
  flow,
  getEnv,
  getSnapshot,
  Instance,
  types,
} from "mobx-state-tree";
import { APIClient } from "@surya-digital/tedwig";
import { getAPIClient } from "@khazana/khazana-boilerplate";
import { GetFdInvestmentDetailsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import i18next from "i18next";
import {
  createDetailsCardItemModel,
  DetailsCardItemModel,
} from "../../../models/DetailsCardItemModel";
import { getFormattedAmount, getFormattedDate } from "../../../../../utils";
import {
  getInterestTypeName,
  getPayoutFrequencyName,
} from "../../../utils/DetailCellUtils";
import {
  CashflowTimelineModel,
  createCashflowTimelineModel,
} from "../../../models/CashflowTimelineModel";
import { useGetFdInvestmentDetailsClientImpl } from "../../rpcs/RPC";

export const FdInvestmentDetailsStore = types
  .model("FdInvestmentDetailsStore", {
    detailsList: types.array(DetailsCardItemModel),
    cashflowTimelineList: types.array(CashflowTimelineModel),
    isHeaderButtonVisible: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
    investmentId: types.maybe(types.number),
    error: types.optional(types.boolean, false),
  })
  .actions((store) => {
    let initialState = {};
    return {
      afterCreate: (): void => {
        initialState = getSnapshot(store);
      },
      resetStore: (): void => {
        applySnapshot(store, initialState);
      },
    };
  })
  .actions((store) => ({
    getInvestmentDetails: flow(function* (
      dealRequestId: number | undefined = undefined,
      investmentId: number | undefined = undefined,
    ) {
      if (dealRequestId ?? investmentId) {
        store.error = false;
        const logger = getEnv(store).logger;
        const apiClient: APIClient = getAPIClient(store);
        store.isLoading = true;
        try {
          const requestType = dealRequestId
            ? new GetFdInvestmentDetailsRPC.RequestEnums.RequestType.DealDetails(
                dealRequestId,
              )
            : new GetFdInvestmentDetailsRPC.RequestEnums.RequestType.InvestmentDetails(
                investmentId!!,
              );
          const request = new GetFdInvestmentDetailsRPC.Request(requestType);
          const result: LeoRPCResult<
            GetFdInvestmentDetailsRPC.Response,
            GetFdInvestmentDetailsRPC.Errors.Errors
          > =
            yield useGetFdInvestmentDetailsClientImpl(apiClient).execute(
              request,
            );
          if (result instanceof LeoRPCResult.Response) {
            const { response } = result;
            store.investmentId = response.fdInvestmentId ?? undefined;
            store.isHeaderButtonVisible = response.isViewCashFlowButtonEnabled;
            store.cashflowTimelineList = cast(
              response.cashFlowList.map((item) => {
                return createCashflowTimelineModel(item);
              }),
            );
            store.detailsList = cast(
              [
                createDetailsCardItemModel({
                  top: response.depositBankAccountNumber
                    ? response.depositBankAccountNumber
                    : `${getInterestTypeName(
                        response.interestType,
                      ).toUpperCase()} ${i18next.t("fd.investmentDetails.fd")}`,
                  middle: response.depositBankName.toUpperCase(),
                  bottom: response.depositBankAccountNumber
                    ? `${getInterestTypeName(
                        response.interestType,
                      )} ${i18next.t("fd.investmentDetails.fd")}`
                    : `${getPayoutFrequencyName(
                        response.payoutFrequency,
                      )} ${i18next.t("fd.investmentDetails.payout")}`,
                }),
                createDetailsCardItemModel({
                  top: getFormattedDate(new Date(response.maturityDate.date)),
                  middle: response.isMaturityDateFixed
                    ? i18next.t("fd.investmentDetails.maturityDate")
                    : i18next.t("fd.investmentDetails.expectedMaturityDate"),
                  bottom: `${
                    response.isValueDateFixed
                      ? i18next.t("fd.investmentDetails.valueDate")
                      : i18next.t("fd.investmentDetails.expectedValueDate")
                  } ${getFormattedDate(new Date(response.valueDate.date))}`,
                }),
                createDetailsCardItemModel({
                  top: `${
                    response.maturityAmount.currency.symbol
                  } ${getFormattedAmount(response.maturityAmount.amount)}`,
                  middle: i18next.t("fd.investmentDetails.totalMaturityAmount"),
                  bottom: i18next.t(
                    "fd.investmentDetails.principalPlusInterest",
                  ),
                }),
                createDetailsCardItemModel({
                  top: response.paymentBankAccountNumber,
                  middle: response.paymentBankName.toUpperCase(),
                  bottom: "",
                }),
                createDetailsCardItemModel({
                  top: `${getFormattedAmount(response.interestRate)} %`,
                  middle: i18next.t("fd.investmentDetails.rateOfInterest"),
                  bottom: "",
                }),
              ].concat(
                response.interestReceivedTillDate
                  ? [
                      createDetailsCardItemModel({
                        top: `${
                          response.interestReceivedTillDate.currency.symbol
                        } ${getFormattedAmount(
                          response.interestReceivedTillDate.amount,
                        )}`,
                        middle: i18next.t(
                          "fd.investmentDetails.interestReceivedTillDate",
                        ),
                        bottom: `${getPayoutFrequencyName(
                          response.payoutFrequency,
                        )} ${i18next.t("fd.investmentDetails.payout")}`,
                      }),
                    ]
                  : [],
              ),
            );
          } else {
            store.error = true;
            logger.error(
              `Unhandled Error: ${result.error} from GetFdInvestmentDetailsRPC`,
            );
          }
        } catch (error) {
          if (error instanceof Error) {
            store.error = true;
            logger.error(
              `Unhandled error: ${error} occurred in GetFdInvestmentDetailsRPC`,
            );
          }
        }
        store.isLoading = false;
      }
    }),
  }));
export const createFdInvestmentDetailsStore = (): Instance<
  typeof FdInvestmentDetailsStore
> => {
  return FdInvestmentDetailsStore.create();
};
