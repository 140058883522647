"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFDealRequestDetailsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const mFDealRequestAction_1 = require("./mFDealRequestAction");
const detailCell_1 = require("../types/detailCell");
const amountDate_1 = require("../types/amountDate");
const mFDealRequestOrder_1 = require("./mFDealRequestOrder");
/* eslint-disable import/export */
class GetMFDealRequestDetailsRPC {
}
exports.GetMFDealRequestDetailsRPC = GetMFDealRequestDetailsRPC;
(function (GetMFDealRequestDetailsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFDealRequestDetailsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(requestId) {
            this.isGetMFDealRequestDetailsRPCRequest = true;
            Request.validateRequestId(requestId);
            this.requestId = requestId;
        }
        static fromDTO(dto) {
            const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidRequestError);
            return new Request(requestId);
        }
        toDTO() {
            const ret = {
                requestId: this.requestId,
            };
            return ret;
        }
        copy(requestId = this.requestId) {
            return new Request(requestId);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFDealRequestDetailsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            return true;
        }
    }
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not an Int64`);
        }
    };
    GetMFDealRequestDetailsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFDealRequestDetailsRPC.Request(requestId=${this.requestId})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFDealRequestDetailsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(requestAction, requestDetails, historicalGraph = null, dealRequestOrderSectionDetail = null) {
            this.isGetMFDealRequestDetailsRPCResponse = true;
            Response.validateRequestAction(requestAction);
            this.requestAction = requestAction;
            Response.validateRequestDetails(requestDetails);
            this.requestDetails = requestDetails;
            if (historicalGraph !== undefined && historicalGraph !== null) {
                Response.validateHistoricalGraph(historicalGraph);
            }
            this.historicalGraph = historicalGraph;
            if (dealRequestOrderSectionDetail !== undefined && dealRequestOrderSectionDetail !== null) {
                Response.validateDealRequestOrderSectionDetail(dealRequestOrderSectionDetail);
            }
            this.dealRequestOrderSectionDetail = dealRequestOrderSectionDetail;
        }
        static fromDTO(dto) {
            const requestAction = mFDealRequestAction_1.MFDealRequestAction.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestAction", InvalidResponseError));
            const requestDetails = (0, leo_ts_runtime_1.getList)(dto, "requestDetails", detailCell_1.DetailCell.fromDTO, InvalidResponseError);
            const historicalGraph = (0, leo_ts_runtime_1.getOptionalList)(dto, "historicalGraph", amountDate_1.AmountDate.fromDTO, InvalidResponseError);
            let dealRequestOrderSectionDetail = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestOrderSectionDetail");
            if (dealRequestOrderSectionDetail !== undefined && dealRequestOrderSectionDetail !== null) {
                dealRequestOrderSectionDetail = mFDealRequestOrder_1.MFDealRequestOrder.fromDTO(dealRequestOrderSectionDetail);
            }
            return new Response(requestAction, requestDetails, historicalGraph, dealRequestOrderSectionDetail);
        }
        toDTO() {
            const ret = {
                requestAction: this.requestAction.toDTO(),
                requestDetails: this.requestDetails.map((e) => {
                    return e.toDTO();
                }),
            };
            if (this.historicalGraph) {
                ret.historicalGraph = this.historicalGraph.map((e) => {
                    return e.toDTO();
                });
            }
            if (this.dealRequestOrderSectionDetail) {
                ret.dealRequestOrderSectionDetail = this.dealRequestOrderSectionDetail.toDTO();
            }
            return ret;
        }
        copy(requestAction = this.requestAction, requestDetails = this.requestDetails, historicalGraph = this.historicalGraph, dealRequestOrderSectionDetail = this.dealRequestOrderSectionDetail) {
            return new Response(requestAction, requestDetails, historicalGraph, dealRequestOrderSectionDetail);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFDealRequestDetailsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.requestAction.equals(other.requestAction)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.requestDetails, other.requestDetails)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.historicalGraph, other.historicalGraph)) {
                return false;
            }
            if (this.dealRequestOrderSectionDetail) {
                if (!this.dealRequestOrderSectionDetail.equals(other.dealRequestOrderSectionDetail)) {
                    return false;
                }
            }
            return true;
        }
    }
    Response.validateRequestAction = (requestAction) => {
        const isMFDealRequestActionValue = requestAction.isMFDealRequestAction;
        if (!(requestAction instanceof mFDealRequestAction_1.MFDealRequestAction || Boolean(isMFDealRequestActionValue))) {
            throw new InvalidResponseError(`Attribute requestAction is not a MFDealRequestAction`);
        }
    };
    Response.validateRequestDetails = (requestDetails) => {
        if (!Array.isArray(requestDetails)) {
            throw new InvalidResponseError(`Attribute requestDetails is not a List`);
        }
        for (let i = 0; i < requestDetails.length; i += 1) {
            const isDetailCellValue = requestDetails[i].isDetailCell;
            if (!(requestDetails[i] instanceof detailCell_1.DetailCell || Boolean(isDetailCellValue))) {
                throw new InvalidResponseError(`Attribute requestDetails at index ${i} is not a DetailCell.`);
            }
        }
    };
    Response.validateHistoricalGraph = (historicalGraph) => {
        if (!Array.isArray(historicalGraph)) {
            throw new InvalidResponseError(`Attribute historicalGraph is not a List`);
        }
        for (let i = 0; i < historicalGraph.length; i += 1) {
            const isAmountDateValue = historicalGraph[i].isAmountDate;
            if (!(historicalGraph[i] instanceof amountDate_1.AmountDate || Boolean(isAmountDateValue))) {
                throw new InvalidResponseError(`Attribute historicalGraph at index ${i} is not a AmountDate.`);
            }
        }
    };
    Response.validateDealRequestOrderSectionDetail = (dealRequestOrderSectionDetail) => {
        if (!mFDealRequestOrder_1.MFDealRequestOrder.isInstanceOf(dealRequestOrderSectionDetail)) {
            throw new InvalidResponseError(`Attribute dealRequestOrderSectionDetail is not a MFDealRequestOrder.MFDealRequestOrder`);
        }
    };
    GetMFDealRequestDetailsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFDealRequestDetailsRPC.Response(requestAction=${this.requestAction},requestDetails=${this.requestDetails},historicalGraph=${this.historicalGraph},dealRequestOrderSectionDetail=${this.dealRequestOrderSectionDetail})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidRequestId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_REQUEST_ID") {
                super(code);
                this.isInvalidRequestId = true;
                InvalidRequestId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidRequestId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidRequestId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidRequestIdValue = other.isInvalidRequestId;
                if (!(other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidRequestId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_REQUEST_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidRequestId = InvalidRequestId;
        InvalidRequestId.prototype.toString = function toString() {
            return `GetMFDealRequestDetailsRPC.INVALID_REQUEST_ID()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_REQUEST_ID":
                    return InvalidRequestId.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidRequestIdValue = other.isInvalidRequestId;
            if (other instanceof InvalidRequestId || Boolean(isInvalidRequestIdValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFDealRequestDetailsRPC.Errors || (GetMFDealRequestDetailsRPC.Errors = {}));
})(GetMFDealRequestDetailsRPC = exports.GetMFDealRequestDetailsRPC || (exports.GetMFDealRequestDetailsRPC = {}));
