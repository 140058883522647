import { Box, styled } from "@mui/material";
import React from "react";
import { ListItem } from "./ListItem";
import {
  SidebarFooterProps,
  useSpacing,
  sidebarOverflowShadow,
  useFoundationColorTokens,
} from "@surya-digital/leo-reactjs-core";

export const SidebarFooter = ({
  list,
  isOverflow,
  currentPath,
  setCurrentPath,
  isCollapsed,
  setIsCollapsed,
}: SidebarFooterProps): React.ReactElement => {
  const tokens = useFoundationColorTokens();
  const spacing = useSpacing();

  const StyledBottomMenuList = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    padding: spacing.spaceXS,
    overflow: "hidden",
    flex: "none",
    order: 2,
    position: "sticky",
    bottom: 0,
    gap: spacing.spaceXS,
    backgroundColor: tokens.backgroundElevatedLevel1,
    boxShadow: isOverflow ? sidebarOverflowShadow : "none",
  }));

  return (
    <StyledBottomMenuList>
      {list.map((listItem, index) => {
        return (
          <ListItem
            key={index}
            listItem={listItem}
            onSelect={(path: string): void => {
              setIsCollapsed(false);
              if (listItem.action) {
                listItem.action();
              } else {
                setCurrentPath(path);
              }
            }}
            currentPath={currentPath}
            isCollapsed={isCollapsed}
          />
        );
      })}
    </StyledBottomMenuList>
  );
};
