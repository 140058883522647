import { BigDecimal, GetMFRedemptionUnitsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFRedemptionUnitsRPCImpl extends GetMFRedemptionUnitsRPC {
  execute(
    _request: GetMFRedemptionUnitsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFRedemptionUnitsRPC.Response,
      GetMFRedemptionUnitsRPC.Errors.Errors
    >
  > {
    const response = new GetMFRedemptionUnitsRPC.Response(
      new BigDecimal("10000000"),
    );
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFRedemptionUnitsRPC.Response,
        GetMFRedemptionUnitsRPC.Errors.Errors
      >
    >;
  }
}
