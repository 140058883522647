import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { useTypography } from "@surya-digital/leo-reactjs-material-ui";
import { t } from "i18next";
import React from "react";
import {
  getAmountStringOrHyphenTransactionDetails,
  getFormattedDate,
} from "../../../utils";
import { useBorder } from "../../../utils/BorderUtils";
import { WarningAlertComponent } from "./WarningAlertComponent";

interface DealTransactionDetailsProps {
  rows: { label: string; value: number | undefined }[];
  subTotal: number | undefined;
  grossAmount: number | undefined;
  finalAmount: number | undefined;
  postfixLabel: string | undefined;
  errorBankBalance: boolean;
  isSell: boolean;
  isin: string | null;
  isTransactionDetailsLoading: boolean;
  isPriceVisible?: boolean;
  price?: number;
  isAccruedInterestVisible?: boolean;
  accruedInterest?: number;
  isIssueDateVisible?: boolean;
  issueDate?: Date;
}

interface CardRowProps {
  heading: string;
  value?: string;
}

interface CardSummaryRowProps {
  heading: string;
  value: string | undefined;
}

const Size = {
  container: "386px",
  errorBanner: "64px",
};

export const DealTransactionDetails = ({
  rows,
  subTotal,
  grossAmount,
  finalAmount,
  postfixLabel,
  errorBankBalance,
  isSell,
  isin,
  isTransactionDetailsLoading,
  isPriceVisible = false,
  price,
  isAccruedInterestVisible = false,
  accruedInterest,
  isIssueDateVisible = false,
  issueDate,
}: DealTransactionDetailsProps): React.ReactElement => {
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const typography = useTypography();

  const getTransactionAmountLabel = (): string => {
    if (isSell) {
      return t("common.minReceivableAmount", { val: postfixLabel });
    } else {
      return t("common.maxPayableAmount", { val: postfixLabel });
    }
  };

  const CardRow = ({ heading, value }: CardRowProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        marginBottom={spacing.spaceXXS}
      >
        <Typography sx={typography.b2}>{heading}</Typography>
        <Typography sx={typography.b2}>{value}</Typography>
      </Stack>
    );
  };

  const CardSummaryRow = ({
    heading,
    value,
  }: CardSummaryRowProps): React.ReactElement => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingY={spacing.spaceSM}
        borderTop={border.default}
        borderBottom={border.default}
      >
        <Typography sx={typography.s2}>{heading}</Typography>
        <Typography sx={typography.s2}>{value}</Typography>
      </Stack>
    );
  };

  const WarningBanner = (): React.ReactElement => {
    if (errorBankBalance) {
      return (
        <WarningAlertComponent
          text={t("common.availableBalanceErrorMessage")}
        />
      );
    }
    return <></>;
  };

  const getStringWithCurrencySymbol = (value: string): string => {
    if (postfixLabel) {
      return `${value} (${postfixLabel})`;
    }
    return value;
  };

  return (
    <Stack
      width={Size.container}
      padding={spacing.spaceMD}
      spacing={spacing.spaceMD}
      height="fit-content"
      sx={{
        borderRadius: cornerRadius.radiusXS,
        background: tokens.background,
        border: border.default,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={typography.sh2}>
          {t("common.transactionDetails")}
        </Typography>
        {isTransactionDetailsLoading && (
          <CircularProgress
            size={28}
            sx={{
              color: tokens.iconPrimary,
            }}
          />
        )}
      </Stack>
      {isPriceVisible && (
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.b2}>
            {getStringWithCurrencySymbol(t("common.price"))}
          </Typography>
          <Typography sx={typography.b2}>
            {getAmountStringOrHyphenTransactionDetails(price, isin, 2)}
          </Typography>
        </Stack>
      )}
      {isIssueDateVisible && (
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.b2}>
            {t("fi.createDealRequest.issueDate")}
          </Typography>
          <Typography sx={typography.b2}>
            {issueDate ? getFormattedDate(issueDate) : "-"}
          </Typography>
        </Stack>
      )}
      {isAccruedInterestVisible && (
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.b2}>
            {getStringWithCurrencySymbol(
              t("fi.createDealRequest.accruedInterest"),
            )}
          </Typography>
          <Typography sx={typography.b2}>
            {getAmountStringOrHyphenTransactionDetails(
              accruedInterest,
              isin,
              2,
            )}
          </Typography>
        </Stack>
      )}
      <Stack spacing={spacing.spaceXS}>
        <div>
          <Typography sx={typography.b2}>
            {t("common.approxChargesAndTaxes")}
          </Typography>
          <Box
            padding={`${spacing.spaceSM} 0 ${spacing.spaceSM} ${spacing.spaceSM}`}
          >
            {rows.map((row) => {
              return (
                <CardRow
                  key={row.label}
                  heading={row.label}
                  value={getAmountStringOrHyphenTransactionDetails(
                    row.value,
                    isin,
                    2,
                  )}
                />
              );
            })}
          </Box>
        </div>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.b2}>
            {getStringWithCurrencySymbol(t("common.subTotal"))}
          </Typography>
          <Typography sx={typography.b2}>
            {getAmountStringOrHyphenTransactionDetails(subTotal, isin, 2)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={typography.b2}>
            {getStringWithCurrencySymbol(t("common.grossAmount"))}
          </Typography>
          <Typography sx={typography.b2}>
            {getAmountStringOrHyphenTransactionDetails(grossAmount, isin, 2)}
          </Typography>
        </Stack>
      </Stack>
      <CardSummaryRow
        heading={getTransactionAmountLabel()}
        value={getAmountStringOrHyphenTransactionDetails(finalAmount, isin, 2)}
      />
      {WarningBanner()}
    </Stack>
  );
};
