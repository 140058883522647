import { APIClient } from "@surya-digital/tedwig";
import {
  CancelMFDealRequestRPC,
  CancelMFDealRequestRPCClientImpl,
  CheckICMfDealRequestRPC,
  CheckICMfDealRequestRPCClientImpl,
  CreateEditMFDealRequestOrderRPC,
  CreateEditMFDealRequestOrderRPCClientImpl,
  CreateMFDealRequestRPC,
  CreateMFDealRequestRPCClientImpl,
  GetMFDealRequestDetailsBannerInfoRPC,
  GetMFDealRequestDetailsBannerInfoRPCClientImpl,
  GetMFDealRequestDetailsRPC,
  GetMFDealRequestDetailsRPCClientImpl,
  GetMFDealRequestHistoryRPC,
  GetMFDealRequestHistoryRPCClientImpl,
  GetMFDealRequestImpactRPC,
  GetMFDealRequestImpactRPCClientImpl,
  GetMFDealRequestSettlementDetailsRPC,
  GetMFDealRequestSettlementDetailsRPCClientImpl,
  GetMFDealRequestsRPC,
  GetMFDealRequestsRPCClientImpl,
  GetMFDealRequestSummaryRPC,
  GetMFDealRequestSummaryRPCClientImpl,
  GetMFFolioNumbersRPC,
  GetMFFolioNumbersRPCClientImpl,
  GetMFRedemptionUnitsRPC,
  GetMFRedemptionUnitsRPCClientImpl,
  GetMFSchemeListRPC,
  GetMFSchemeListRPCClientImpl,
  GetMFSellEntityListRPC,
  GetMFSellEntityListRPCClientImpl,
  GetMFSellPortfolioListRPC,
  GetMFSellPortfolioListRPCClientImpl,
  SettleMFDealRequestRPC,
  SettleMFDealRequestRPCClientImpl,
} from "@khazana/khazana-rpcs";
import { MOCK } from "../../../../../constants/RPC-Mock";
import { MockCreateMFDealRequestRPCImpl } from "./MockCreateMFDealRequestRPCImpl";
import { MockGetMFDealRequestImpactRPCImpl } from "./MockGetMFDealRequestImpactRPCImpl";
import { MockGetMFDealRequestSummaryRPCImpl } from "./MockGetMFDealRequestSummaryRPCImpl";
import { MockGetMFFolioNumbersRPCImpl } from "./MockGetMFFolioNumbersRPCImpl";
import { MockGetMFRedemptionUnitsRPCImpl } from "./MockGetMFRedemptionUnitsRPCImpl";
import { MockGetMFSchemeListRPCImpl } from "./MockGetMFSchemeListRPCImpl";
import { MockGetMFDealRequestsRPCImpl } from "./MockGetMFDealRequestsRPCImpl";
import { MockGetMFDealRequestDetailsRPCImpl } from "./MockGetMFDealRequestDetailsRPCImpl";
import { MockGetMFDealRequestHistoryRPCImpl } from "./MockGetMFDealRequestHistoryRPCImpl";
import { MockCancelMFDealRequestRPCImpl } from "./MockCancelMFDealRequestRPCImpl";
import { MockCheckICMfDealRequestRPCImpl } from "./MockCheckICMfDealRequestRPCImpl";
import { MockGetMFDealRequestDetailsBannerInfoRPCImpl } from "./MockGetMFDealRequestDetailsBannerInfoRPCImpl";
import { MockGetMFDealRequestSettlementDetailsRPCImpl } from "./MockGetMFDealRequestSettlementDetailsRPCImpl";
import { MockSettleMFDealRequestRPCImpl } from "./MockSettleMFDealRequestRPCImpl";
import { MockCreateEditMFDealRequestOrderRPCImpl } from "./MockCreateEditMFDealRequestOrderRPCImpl";
import { MockGetMFSellEntityListRPCImpl } from "./MockGetMFSellEntityListRPCImpl";
import { MockGetMFSellPortfolioListRPCImpl } from "./MockGetMFSellPortfolioListRpcImpl";

export const useCreateMFDealRequestRPC = (
  apiClient: APIClient,
): CreateMFDealRequestRPC => {
  if (MOCK.mf) {
    return new MockCreateMFDealRequestRPCImpl();
  } else {
    return new CreateMFDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetMFDealRequestImpactRPC = (
  apiClient: APIClient,
): GetMFDealRequestImpactRPC => {
  if (MOCK.mf) {
    return new MockGetMFDealRequestImpactRPCImpl();
  } else {
    return new GetMFDealRequestImpactRPCClientImpl(apiClient);
  }
};

export const useGetMFDealRequestSummaryRPC = (
  apiClient: APIClient,
): GetMFDealRequestSummaryRPC => {
  if (MOCK.mf) {
    return new MockGetMFDealRequestSummaryRPCImpl();
  } else {
    return new GetMFDealRequestSummaryRPCClientImpl(apiClient);
  }
};

export const useGetMFFolioNumbersRPC = (
  apiClient: APIClient,
): GetMFFolioNumbersRPC => {
  if (MOCK.mf) {
    return new MockGetMFFolioNumbersRPCImpl();
  } else {
    return new GetMFFolioNumbersRPCClientImpl(apiClient);
  }
};

export const useGetMFRedemptionUnitsRPC = (
  apiClient: APIClient,
): GetMFRedemptionUnitsRPC => {
  if (MOCK.mf) {
    return new MockGetMFRedemptionUnitsRPCImpl();
  } else {
    return new GetMFRedemptionUnitsRPCClientImpl(apiClient);
  }
};

export const useGetMFSchemeListRPC = (
  apiClient: APIClient,
): GetMFSchemeListRPC => {
  if (MOCK.mf) {
    return new MockGetMFSchemeListRPCImpl();
  } else {
    return new GetMFSchemeListRPCClientImpl(apiClient);
  }
};

export const useGetMFDealRequestsRPC = (
  apiClient: APIClient,
): GetMFDealRequestsRPC => {
  if (MOCK.mf) {
    return new MockGetMFDealRequestsRPCImpl();
  } else {
    return new GetMFDealRequestsRPCClientImpl(apiClient);
  }
};

export const useGetMFDealRequestDetailsRPC = (
  apiClient: APIClient,
): GetMFDealRequestDetailsRPC => {
  if (MOCK.mf) {
    return new MockGetMFDealRequestDetailsRPCImpl();
  } else {
    return new GetMFDealRequestDetailsRPCClientImpl(apiClient);
  }
};

export const useGetMFDealRequestHistoryRPC = (
  apiClient: APIClient,
): GetMFDealRequestHistoryRPC => {
  if (MOCK.mf) {
    return new MockGetMFDealRequestHistoryRPCImpl();
  } else {
    return new GetMFDealRequestHistoryRPCClientImpl(apiClient);
  }
};

export const useCancelMFDealRequestRPC = (
  apiClient: APIClient,
): CancelMFDealRequestRPC => {
  if (MOCK.mf) {
    return new MockCancelMFDealRequestRPCImpl();
  } else {
    return new CancelMFDealRequestRPCClientImpl(apiClient);
  }
};

export const useCheckICMfDealRequestRPC = (
  apiClient: APIClient,
): CheckICMfDealRequestRPC => {
  if (MOCK.mf) {
    return new MockCheckICMfDealRequestRPCImpl();
  } else {
    return new CheckICMfDealRequestRPCClientImpl(apiClient);
  }
};

export const useGetMFDealRequestDetailsBannerInfoRPC = (
  apiClient: APIClient,
): GetMFDealRequestDetailsBannerInfoRPC => {
  if (MOCK.mf) {
    return new MockGetMFDealRequestDetailsBannerInfoRPCImpl();
  } else {
    return new GetMFDealRequestDetailsBannerInfoRPCClientImpl(apiClient);
  }
};

export const useGetMFDealRequestSettlementDetailsRPC = (
  apiClient: APIClient,
): GetMFDealRequestSettlementDetailsRPC => {
  if (MOCK.mf) {
    return new MockGetMFDealRequestSettlementDetailsRPCImpl();
  } else {
    return new GetMFDealRequestSettlementDetailsRPCClientImpl(apiClient);
  }
};

export const useSettleMFDealRequestRPC = (
  apiClient: APIClient,
): SettleMFDealRequestRPC => {
  if (MOCK.mf) {
    return new MockSettleMFDealRequestRPCImpl();
  } else {
    return new SettleMFDealRequestRPCClientImpl(apiClient);
  }
};

export const useCreateEditMFDealRequestOrderRPC = (
  apiClient: APIClient,
): CreateEditMFDealRequestOrderRPC => {
  if (MOCK.mf) {
    return new MockCreateEditMFDealRequestOrderRPCImpl();
  } else {
    return new CreateEditMFDealRequestOrderRPCClientImpl(apiClient);
  }
};

export const useGetMFSellEntityListRPC = (
  apiClient: APIClient,
): GetMFSellEntityListRPC => {
  if (MOCK.mf) {
    return new MockGetMFSellEntityListRPCImpl();
  } else {
    return new GetMFSellEntityListRPCClientImpl(apiClient);
  }
};

export const useGetMFSellPortfolioListRPC = (
  apiClient: APIClient,
): GetMFSellPortfolioListRPC => {
  if (MOCK.mf) {
    return new MockGetMFSellPortfolioListRPCImpl();
  } else {
    return new GetMFSellPortfolioListRPCClientImpl(apiClient);
  }
};
