"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFRedemptionUnitsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const iSIN_1 = require("../types/iSIN");
const bigDecimal_1 = require("../types/bigDecimal");
/* eslint-disable import/export */
class GetMFRedemptionUnitsRPC {
}
exports.GetMFRedemptionUnitsRPC = GetMFRedemptionUnitsRPC;
(function (GetMFRedemptionUnitsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetMFRedemptionUnitsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(isin, entityId, folioNumber) {
            this.isGetMFRedemptionUnitsRPCRequest = true;
            Request.validateIsin(isin);
            this.isin = isin;
            Request.validateEntityId(entityId);
            this.entityId = entityId;
            Request.validateFolioNumber(folioNumber);
            this.folioNumber = folioNumber;
        }
        static fromDTO(dto) {
            const isin = iSIN_1.ISIN.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "isin", InvalidRequestError));
            const entityId = (0, leo_ts_runtime_1.getLeoUuid)(dto, "entityId", InvalidRequestError);
            const folioNumber = (0, leo_ts_runtime_1.getString)(dto, "folioNumber", InvalidRequestError);
            return new Request(isin, entityId, folioNumber);
        }
        toDTO() {
            const ret = {
                isin: this.isin.toDTO(),
                entityId: this.entityId.toDTO(),
                folioNumber: this.folioNumber,
            };
            return ret;
        }
        copy(isin = this.isin, entityId = this.entityId, folioNumber = this.folioNumber) {
            return new Request(isin, entityId, folioNumber);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetMFRedemptionUnitsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!this.isin.equals(other.isin)) {
                return false;
            }
            if (!this.entityId.equals(other.entityId)) {
                return false;
            }
            if (this.folioNumber !== other.folioNumber) {
                return false;
            }
            return true;
        }
    }
    Request.validateIsin = (isin) => {
        const isISINValue = isin.isISIN;
        if (!(isin instanceof iSIN_1.ISIN || Boolean(isISINValue))) {
            throw new InvalidRequestError(`Attribute isin is not a ISIN`);
        }
    };
    Request.validateEntityId = (entityId) => {
        if (!(0, leo_ts_runtime_1.isLeoUuid)(entityId)) {
            throw new InvalidRequestError(`Attribute entityId is not a UUID`);
        }
    };
    Request.validateFolioNumber = (folioNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(folioNumber)) {
            throw new InvalidRequestError(`Attribute folioNumber is not a String`);
        }
        if (folioNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute folioNumber cannot be an empty string.`);
        }
    };
    GetMFRedemptionUnitsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetMFRedemptionUnitsRPC.Request(isin=${this.isin},entityId=${this.entityId},folioNumber='${this.folioNumber}')`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetMFRedemptionUnitsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(units) {
            this.isGetMFRedemptionUnitsRPCResponse = true;
            Response.validateUnits(units);
            this.units = units;
        }
        static fromDTO(dto) {
            const units = bigDecimal_1.BigDecimal.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "units", InvalidResponseError));
            return new Response(units);
        }
        toDTO() {
            const ret = {
                units: this.units.toDTO(),
            };
            return ret;
        }
        copy(units = this.units) {
            return new Response(units);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetMFRedemptionUnitsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!this.units.equals(other.units)) {
                return false;
            }
            return true;
        }
    }
    Response.validateUnits = (units) => {
        const isBigDecimalValue = units.isBigDecimal;
        if (!(units instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
            throw new InvalidResponseError(`Attribute units is not a BigDecimal`);
        }
    };
    GetMFRedemptionUnitsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetMFRedemptionUnitsRPC.Response(units=${this.units})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidIsin extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ISIN") {
                super(code);
                this.isInvalidIsin = true;
                InvalidIsin.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidIsin(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidIsin(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidIsinValue = other.isInvalidIsin;
                if (!(other instanceof InvalidIsin || Boolean(isInvalidIsinValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidIsin.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ISIN") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidIsin = InvalidIsin;
        InvalidIsin.prototype.toString = function toString() {
            return `GetMFRedemptionUnitsRPC.INVALID_ISIN()`;
        };
        class InvalidEntityId extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_ENTITY_ID") {
                super(code);
                this.isInvalidEntityId = true;
                InvalidEntityId.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidEntityId(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidEntityId(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidEntityIdValue = other.isInvalidEntityId;
                if (!(other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidEntityId.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_ENTITY_ID") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidEntityId = InvalidEntityId;
        InvalidEntityId.prototype.toString = function toString() {
            return `GetMFRedemptionUnitsRPC.INVALID_ENTITY_ID()`;
        };
        class InvalidFolioNumber extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_FOLIO_NUMBER") {
                super(code);
                this.isInvalidFolioNumber = true;
                InvalidFolioNumber.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidFolioNumber(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidFolioNumber(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidFolioNumberValue = other.isInvalidFolioNumber;
                if (!(other instanceof InvalidFolioNumber || Boolean(isInvalidFolioNumberValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidFolioNumber.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_FOLIO_NUMBER") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidFolioNumber = InvalidFolioNumber;
        InvalidFolioNumber.prototype.toString = function toString() {
            return `GetMFRedemptionUnitsRPC.INVALID_FOLIO_NUMBER()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_ISIN":
                    return InvalidIsin.fromDTO(dto);
                case "INVALID_ENTITY_ID":
                    return InvalidEntityId.fromDTO(dto);
                case "INVALID_FOLIO_NUMBER":
                    return InvalidFolioNumber.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidIsinValue = other.isInvalidIsin;
            if (other instanceof InvalidIsin || Boolean(isInvalidIsinValue)) {
                return true;
            }
            const isInvalidEntityIdValue = other.isInvalidEntityId;
            if (other instanceof InvalidEntityId || Boolean(isInvalidEntityIdValue)) {
                return true;
            }
            const isInvalidFolioNumberValue = other.isInvalidFolioNumber;
            if (other instanceof InvalidFolioNumber || Boolean(isInvalidFolioNumberValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetMFRedemptionUnitsRPC.Errors || (GetMFRedemptionUnitsRPC.Errors = {}));
})(GetMFRedemptionUnitsRPC = exports.GetMFRedemptionUnitsRPC || (exports.GetMFRedemptionUnitsRPC = {}));
