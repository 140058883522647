import { types } from "mobx-state-tree";
import { MFTransactionTypeModel } from "./MFTransactionTypeModel";
import { MFDealRequestStatusModel } from "./MFDealRequestStatusModel";

export const MFDealRequestModel = types.model({
  requestId: types.number,
  dealRequestStatus: MFDealRequestStatusModel,
  scheme: types.string,
  amc: types.string,
  transactionType: MFTransactionTypeModel,
  amount: types.maybe(types.string),
  units: types.maybe(types.string),
  navPerUnit: types.string,
  folioNumber: types.maybe(types.string),
  entity: types.string,
  portfolio: types.maybe(types.string),
  receivedAt: types.Date,
});
