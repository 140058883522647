"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelAmount = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const transactionAmount_1 = require("./transactionAmount");
class InvalidLabelAmountError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidLabelAmountError";
    }
}
class LabelAmount {
    constructor(label, amount) {
        this.isLabelAmount = true;
        LabelAmount.validateLabel(label);
        this.label = label;
        LabelAmount.validateAmount(amount);
        this.amount = amount;
    }
    static fromDTO(dto) {
        const label = (0, leo_ts_runtime_1.getString)(dto, "label", InvalidLabelAmountError);
        const amount = transactionAmount_1.TransactionAmount.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "amount", InvalidLabelAmountError));
        return new LabelAmount(label, amount);
    }
    toDTO() {
        const ret = {
            label: this.label,
            amount: this.amount.toDTO(),
        };
        return ret;
    }
    copy(label = this.label, amount = this.amount) {
        return new LabelAmount(label, amount);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isLabelAmountValue = other.isLabelAmount;
        if (!(other instanceof LabelAmount) || Boolean(isLabelAmountValue)) {
            return false;
        }
        if (this.label !== other.label) {
            return false;
        }
        if (!this.amount.equals(other.amount)) {
            return false;
        }
        return true;
    }
}
exports.LabelAmount = LabelAmount;
LabelAmount.validateLabel = (label) => {
    if (!(0, leo_ts_runtime_1.isString)(label)) {
        throw new InvalidLabelAmountError(`Attribute label is not a String`);
    }
    if (label.trim().length === 0) {
        throw new InvalidLabelAmountError(`Attribute label cannot be an empty string.`);
    }
};
LabelAmount.validateAmount = (amount) => {
    const isTransactionAmountValue = amount.isTransactionAmount;
    if (!(amount instanceof transactionAmount_1.TransactionAmount || Boolean(isTransactionAmountValue))) {
        throw new InvalidLabelAmountError(`Attribute amount is not a TransactionAmount`);
    }
};
LabelAmount.prototype.toString = function toString() {
    return `LabelAmount(label='${this.label}',amount=${this.amount})`;
};
