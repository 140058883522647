import { Instance, castToSnapshot, types } from "mobx-state-tree";
import {
  CreateFiDealRequestStore,
  createCreateFiDealRequestStore,
} from "../deal-request/store/CreateFiDealRequestStore";
import {
  FiDealRequestsStore,
  createFiDealRequestsStore,
} from "../deal-request/store/FiDealRequestsStore";
import {
  ViewFiDealRequestDetailsStore,
  createViewFiDealRequestDetailsStore,
} from "../deal-request/store/ViewFiDealRequestDetailsStore";
import {
  FiContractNotesStore,
  createFiContractNotesStore,
} from "../contract-note/store/FiContractNotesStore";
import {
  FiContractNoteDetailsStore,
  createFiContractNoteDetailsStore,
} from "../contract-note/store/FiContractNoteDetailsStore";
import {
  FiReportsStore,
  createFiReportsStore,
} from "../reports/store/FiReportsStore";
import {
  FiViewCashflowsStore,
  createFiViewCashflowsStore,
} from "../contract-note/store/FiViewCashflowsStore";
import {
  FiMaturityDealRequestsStore,
  createFiMaturityDealRequestsStore,
} from "../deal-request/store/FiMaturityDealRequestsStore";
import {
  ViewFiMaturityRequestDetailsStore,
  createViewFiMaturityRequestDetailsStore,
} from "../deal-request/store/ViewFiMaturityRequestDetailsStore";
import {
  createFiMaturitySettleStore,
  FiMaturitySettleStore,
} from "../deal-request/store/FiMaturitySettleStore";

export const FiStore = types.model({
  createFiDealRequestStore: CreateFiDealRequestStore,
  dealRequestsStore: FiDealRequestsStore,
  fiDealRequestDetailsStore: ViewFiDealRequestDetailsStore,
  fiMaturityRequestDetailsStore: ViewFiMaturityRequestDetailsStore,
  contractNotesStore: FiContractNotesStore,
  contractNoteDetailsStore: FiContractNoteDetailsStore,
  fiReportsStore: FiReportsStore,
  fiViewCashflowsStore: FiViewCashflowsStore,
  maturityDealRequestsStore: FiMaturityDealRequestsStore,
  maturitySettleStore: FiMaturitySettleStore,
});

export const createFiStore = (): Instance<typeof FiStore> => {
  return FiStore.create({
    createFiDealRequestStore: createCreateFiDealRequestStore(),
    dealRequestsStore: createFiDealRequestsStore(),
    fiDealRequestDetailsStore: castToSnapshot(
      createViewFiDealRequestDetailsStore(),
    ),
    fiMaturityRequestDetailsStore: createViewFiMaturityRequestDetailsStore(),
    contractNotesStore: createFiContractNotesStore(),
    contractNoteDetailsStore: createFiContractNoteDetailsStore(),
    fiReportsStore: castToSnapshot(createFiReportsStore()),
    fiViewCashflowsStore: createFiViewCashflowsStore(),
    maturityDealRequestsStore: createFiMaturityDealRequestsStore(),
    maturitySettleStore: createFiMaturitySettleStore(),
  });
};
