"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetFiDealRequestsRPC = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
/* eslint max-classes-per-file: ["error", 20] */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const getFiDealRequestsSortOrder_1 = require("./getFiDealRequestsSortOrder");
const fixedIncomeItemsPerPage_1 = require("./fixedIncomeItemsPerPage");
const fixedIncomePageIndex_1 = require("./fixedIncomePageIndex");
const getFiDealRequestsPaginationResponse_1 = require("./getFiDealRequestsPaginationResponse");
const fiDealRequestStatus_1 = require("./fiDealRequestStatus");
const fiTransactionType_1 = require("./fiTransactionType");
/* eslint-disable import/export */
class GetFiDealRequestsRPC {
}
exports.GetFiDealRequestsRPC = GetFiDealRequestsRPC;
(function (GetFiDealRequestsRPC) {
    class InvalidRequestError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidRequestError";
        }
    }
    GetFiDealRequestsRPC.InvalidRequestError = InvalidRequestError;
    class Request {
        constructor(sortList, requestId = null, dealRequestStatus = null, security = null, transactionType = null, portfolio = null, dematAccountNumber = null, itemsPerPage, pageIndex) {
            this.isGetFiDealRequestsRPCRequest = true;
            Request.validateSortList(sortList);
            this.sortList = sortList;
            if (requestId !== undefined && requestId !== null) {
                Request.validateRequestId(requestId);
            }
            this.requestId = requestId;
            if (dealRequestStatus !== undefined && dealRequestStatus !== null) {
                Request.validateDealRequestStatus(dealRequestStatus);
            }
            this.dealRequestStatus = dealRequestStatus;
            if (security !== undefined && security !== null) {
                Request.validateSecurity(security);
            }
            this.security = security;
            if (transactionType !== undefined && transactionType !== null) {
                Request.validateTransactionType(transactionType);
            }
            this.transactionType = transactionType;
            if (portfolio !== undefined && portfolio !== null) {
                Request.validatePortfolio(portfolio);
            }
            this.portfolio = portfolio;
            if (dematAccountNumber !== undefined && dematAccountNumber !== null) {
                Request.validateDematAccountNumber(dematAccountNumber);
            }
            this.dematAccountNumber = dematAccountNumber;
            Request.validateItemsPerPage(itemsPerPage);
            this.itemsPerPage = itemsPerPage;
            Request.validatePageIndex(pageIndex);
            this.pageIndex = pageIndex;
        }
        static fromDTO(dto) {
            const sortList = (0, leo_ts_runtime_1.getList)(dto, "sortList", getFiDealRequestsSortOrder_1.GetFiDealRequestsSortOrder.fromDTO, InvalidRequestError);
            const requestId = (0, leo_ts_runtime_1.getOptionalString)(dto, "requestId", InvalidRequestError);
            let dealRequestStatus = (0, leo_ts_runtime_1.getOptionalObject)(dto, "dealRequestStatus");
            if (dealRequestStatus !== undefined && dealRequestStatus !== null) {
                dealRequestStatus = fiDealRequestStatus_1.FiDealRequestStatus.fromDTO(dealRequestStatus);
            }
            const security = (0, leo_ts_runtime_1.getOptionalString)(dto, "security", InvalidRequestError);
            let transactionType = (0, leo_ts_runtime_1.getOptionalObject)(dto, "transactionType");
            if (transactionType !== undefined && transactionType !== null) {
                transactionType = fiTransactionType_1.FiTransactionType.fromDTO(transactionType);
            }
            const portfolio = (0, leo_ts_runtime_1.getOptionalString)(dto, "portfolio", InvalidRequestError);
            const dematAccountNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "dematAccountNumber", InvalidRequestError);
            const itemsPerPage = fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "itemsPerPage", InvalidRequestError));
            const pageIndex = fixedIncomePageIndex_1.FixedIncomePageIndex.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "pageIndex", InvalidRequestError));
            return new Request(sortList, requestId, dealRequestStatus, security, transactionType, portfolio, dematAccountNumber, itemsPerPage, pageIndex);
        }
        toDTO() {
            const ret = {
                sortList: this.sortList.map((e) => {
                    return e.toDTO();
                }),
                itemsPerPage: this.itemsPerPage.toDTO(),
                pageIndex: this.pageIndex.toDTO(),
            };
            if (this.requestId !== undefined && this.requestId !== null) {
                ret.requestId = this.requestId;
            }
            if (this.dealRequestStatus !== undefined && this.dealRequestStatus !== null) {
                ret.dealRequestStatus = fiDealRequestStatus_1.FiDealRequestStatus.toDTO(this.dealRequestStatus);
            }
            if (this.security !== undefined && this.security !== null) {
                ret.security = this.security;
            }
            if (this.transactionType !== undefined && this.transactionType !== null) {
                ret.transactionType = fiTransactionType_1.FiTransactionType.toDTO(this.transactionType);
            }
            if (this.portfolio !== undefined && this.portfolio !== null) {
                ret.portfolio = this.portfolio;
            }
            if (this.dematAccountNumber !== undefined && this.dematAccountNumber !== null) {
                ret.dematAccountNumber = this.dematAccountNumber;
            }
            return ret;
        }
        copy(sortList = this.sortList, requestId = this.requestId, dealRequestStatus = this.dealRequestStatus, security = this.security, transactionType = this.transactionType, portfolio = this.portfolio, dematAccountNumber = this.dematAccountNumber, itemsPerPage = this.itemsPerPage, pageIndex = this.pageIndex) {
            return new Request(sortList, requestId, dealRequestStatus, security, transactionType, portfolio, dematAccountNumber, itemsPerPage, pageIndex);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isRequestValue = other.isGetFiDealRequestsRPCRequest;
            if (!(other instanceof Request) || Boolean(isRequestValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.sortList, other.sortList)) {
                return false;
            }
            if (this.requestId !== other.requestId) {
                return false;
            }
            if (this.dealRequestStatus !== other.dealRequestStatus) {
                return false;
            }
            if (this.security !== other.security) {
                return false;
            }
            if (this.transactionType !== other.transactionType) {
                return false;
            }
            if (this.portfolio !== other.portfolio) {
                return false;
            }
            if (this.dematAccountNumber !== other.dematAccountNumber) {
                return false;
            }
            if (!this.itemsPerPage.equals(other.itemsPerPage)) {
                return false;
            }
            if (!this.pageIndex.equals(other.pageIndex)) {
                return false;
            }
            return true;
        }
    }
    Request.validateSortList = (sortList) => {
        if (!Array.isArray(sortList)) {
            throw new InvalidRequestError(`Attribute sortList is not a List`);
        }
        for (let i = 0; i < sortList.length; i += 1) {
            const isGetFiDealRequestsSortOrderValue = sortList[i].isGetFiDealRequestsSortOrder;
            if (!(sortList[i] instanceof getFiDealRequestsSortOrder_1.GetFiDealRequestsSortOrder || Boolean(isGetFiDealRequestsSortOrderValue))) {
                throw new InvalidRequestError(`Attribute sortList at index ${i} is not a GetFiDealRequestsSortOrder.`);
            }
        }
    };
    Request.validateRequestId = (requestId) => {
        if (!(0, leo_ts_runtime_1.isString)(requestId)) {
            throw new InvalidRequestError(`Attribute requestId is not a String`);
        }
        if (requestId.length < 3) {
            throw new InvalidRequestError(`Attribute requestId is too short. Size is ${requestId.length}. Min size is 3.`);
        }
        if (requestId.length > 200) {
            throw new InvalidRequestError(`Attribute requestId is too long. Size is ${requestId.length}. Max size is 200.`);
        }
        if (requestId.trim().length === 0) {
            throw new InvalidRequestError(`Attribute requestId cannot be an empty string.`);
        }
    };
    Request.validateDealRequestStatus = (dealRequestStatus) => {
        if (!fiDealRequestStatus_1.FiDealRequestStatus.isInstanceOf(dealRequestStatus)) {
            throw new InvalidRequestError(`Attribute dealRequestStatus is not a FiDealRequestStatus.FiDealRequestStatus`);
        }
    };
    Request.validateSecurity = (security) => {
        if (!(0, leo_ts_runtime_1.isString)(security)) {
            throw new InvalidRequestError(`Attribute security is not a String`);
        }
        if (security.length < 3) {
            throw new InvalidRequestError(`Attribute security is too short. Size is ${security.length}. Min size is 3.`);
        }
        if (security.length > 200) {
            throw new InvalidRequestError(`Attribute security is too long. Size is ${security.length}. Max size is 200.`);
        }
        if (security.trim().length === 0) {
            throw new InvalidRequestError(`Attribute security cannot be an empty string.`);
        }
    };
    Request.validateTransactionType = (transactionType) => {
        if (!fiTransactionType_1.FiTransactionType.isInstanceOf(transactionType)) {
            throw new InvalidRequestError(`Attribute transactionType is not a FiTransactionType.FiTransactionType`);
        }
    };
    Request.validatePortfolio = (portfolio) => {
        if (!(0, leo_ts_runtime_1.isString)(portfolio)) {
            throw new InvalidRequestError(`Attribute portfolio is not a String`);
        }
        if (portfolio.length < 3) {
            throw new InvalidRequestError(`Attribute portfolio is too short. Size is ${portfolio.length}. Min size is 3.`);
        }
        if (portfolio.length > 200) {
            throw new InvalidRequestError(`Attribute portfolio is too long. Size is ${portfolio.length}. Max size is 200.`);
        }
        if (portfolio.trim().length === 0) {
            throw new InvalidRequestError(`Attribute portfolio cannot be an empty string.`);
        }
    };
    Request.validateDematAccountNumber = (dematAccountNumber) => {
        if (!(0, leo_ts_runtime_1.isString)(dematAccountNumber)) {
            throw new InvalidRequestError(`Attribute dematAccountNumber is not a String`);
        }
        if (dematAccountNumber.length < 3) {
            throw new InvalidRequestError(`Attribute dematAccountNumber is too short. Size is ${dematAccountNumber.length}. Min size is 3.`);
        }
        if (dematAccountNumber.length > 200) {
            throw new InvalidRequestError(`Attribute dematAccountNumber is too long. Size is ${dematAccountNumber.length}. Max size is 200.`);
        }
        if (dematAccountNumber.trim().length === 0) {
            throw new InvalidRequestError(`Attribute dematAccountNumber cannot be an empty string.`);
        }
    };
    Request.validateItemsPerPage = (itemsPerPage) => {
        const isFixedIncomeItemsPerPageValue = itemsPerPage.isFixedIncomeItemsPerPage;
        if (!(itemsPerPage instanceof fixedIncomeItemsPerPage_1.FixedIncomeItemsPerPage || Boolean(isFixedIncomeItemsPerPageValue))) {
            throw new InvalidRequestError(`Attribute itemsPerPage is not a FixedIncomeItemsPerPage`);
        }
    };
    Request.validatePageIndex = (pageIndex) => {
        const isFixedIncomePageIndexValue = pageIndex.isFixedIncomePageIndex;
        if (!(pageIndex instanceof fixedIncomePageIndex_1.FixedIncomePageIndex || Boolean(isFixedIncomePageIndexValue))) {
            throw new InvalidRequestError(`Attribute pageIndex is not a FixedIncomePageIndex`);
        }
    };
    GetFiDealRequestsRPC.Request = Request;
    Request.prototype.toString = function toString() {
        return `GetFiDealRequestsRPC.Request(sortList=${this.sortList},requestId='${this.requestId}',dealRequestStatus=${this.dealRequestStatus},security='${this.security}',transactionType=${this.transactionType},portfolio='${this.portfolio}',dematAccountNumber='${this.dematAccountNumber}',itemsPerPage=${this.itemsPerPage},pageIndex=${this.pageIndex})`;
    };
    class InvalidResponseError extends Error {
        constructor(message) {
            super(message);
            Object.setPrototypeOf(this, new.target.prototype);
            this.name = "InvalidResponseError";
        }
    }
    GetFiDealRequestsRPC.InvalidResponseError = InvalidResponseError;
    class Response {
        constructor(getFiDealRequestsPaginationResponse, totalItems) {
            this.isGetFiDealRequestsRPCResponse = true;
            Response.validateGetFiDealRequestsPaginationResponse(getFiDealRequestsPaginationResponse);
            this.getFiDealRequestsPaginationResponse = getFiDealRequestsPaginationResponse;
            Response.validateTotalItems(totalItems);
            this.totalItems = totalItems;
        }
        static fromDTO(dto) {
            const getFiDealRequestsPaginationResponse = (0, leo_ts_runtime_1.getList)(dto, "getFiDealRequestsPaginationResponse", getFiDealRequestsPaginationResponse_1.GetFiDealRequestsPaginationResponse.fromDTO, InvalidResponseError);
            const totalItems = (0, leo_ts_runtime_1.getInt32)(dto, "totalItems", InvalidResponseError);
            return new Response(getFiDealRequestsPaginationResponse, totalItems);
        }
        toDTO() {
            const ret = {
                getFiDealRequestsPaginationResponse: this.getFiDealRequestsPaginationResponse.map((e) => {
                    return e.toDTO();
                }),
                totalItems: this.totalItems,
            };
            return ret;
        }
        copy(getFiDealRequestsPaginationResponse = this.getFiDealRequestsPaginationResponse, totalItems = this.totalItems) {
            return new Response(getFiDealRequestsPaginationResponse, totalItems);
        }
        equals(other) {
            if (Object.is(this, other)) {
                return true;
            }
            const isResponseValue = other.isGetFiDealRequestsRPCResponse;
            if (!(other instanceof Response) || Boolean(isResponseValue)) {
                return false;
            }
            if (!(0, leo_ts_runtime_1.listEquals)(this.getFiDealRequestsPaginationResponse, other.getFiDealRequestsPaginationResponse)) {
                return false;
            }
            if (this.totalItems !== other.totalItems) {
                return false;
            }
            return true;
        }
    }
    Response.validateGetFiDealRequestsPaginationResponse = (getFiDealRequestsPaginationResponse) => {
        if (!Array.isArray(getFiDealRequestsPaginationResponse)) {
            throw new InvalidResponseError(`Attribute getFiDealRequestsPaginationResponse is not a List`);
        }
        for (let i = 0; i < getFiDealRequestsPaginationResponse.length; i += 1) {
            const isGetFiDealRequestsPaginationResponseValue = getFiDealRequestsPaginationResponse[i].isGetFiDealRequestsPaginationResponse;
            if (!(getFiDealRequestsPaginationResponse[i] instanceof getFiDealRequestsPaginationResponse_1.GetFiDealRequestsPaginationResponse || Boolean(isGetFiDealRequestsPaginationResponseValue))) {
                throw new InvalidResponseError(`Attribute getFiDealRequestsPaginationResponse at index ${i} is not a GetFiDealRequestsPaginationResponse.`);
            }
        }
    };
    Response.validateTotalItems = (totalItems) => {
        if (!(0, leo_ts_runtime_1.isInt32)(totalItems)) {
            throw new InvalidResponseError(`Attribute totalItems is not an Int32`);
        }
    };
    GetFiDealRequestsRPC.Response = Response;
    Response.prototype.toString = function toString() {
        return `GetFiDealRequestsRPC.Response(getFiDealRequestsPaginationResponse=${this.getFiDealRequestsPaginationResponse},totalItems=${this.totalItems})`;
    };
    let Errors;
    (function (Errors) {
        class InvalidPageIndex extends leo_ts_runtime_1.LeoRPCError {
            constructor(code = "INVALID_PAGE_INDEX") {
                super(code);
                this.isInvalidPageIndex = true;
                InvalidPageIndex.validateCode(code);
            }
            static fromDTO(dto) {
                const code = (0, leo_ts_runtime_1.getString)(dto, "code", InvalidResponseError);
                return new InvalidPageIndex(code);
            }
            toDTO() {
                const ret = {
                    code: this.code,
                };
                return ret;
            }
            copy(code = this.code) {
                return new InvalidPageIndex(code);
            }
            equals(other) {
                if (Object.is(this, other)) {
                    return true;
                }
                const isInvalidPageIndexValue = other.isInvalidPageIndex;
                if (!(other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue))) {
                    return false;
                }
                if (this.code !== other.code) {
                    return false;
                }
                return true;
            }
        }
        InvalidPageIndex.validateCode = (code) => {
            if (!(0, leo_ts_runtime_1.isString)(code)) {
                throw new InvalidResponseError(`Attribute code is not a String.`);
            }
            if (code !== "INVALID_PAGE_INDEX") {
                throw new InvalidResponseError(`Unexpected error code: ${code}`);
            }
        };
        Errors.InvalidPageIndex = InvalidPageIndex;
        InvalidPageIndex.prototype.toString = function toString() {
            return `GetFiDealRequestsRPC.INVALID_PAGE_INDEX()`;
        };
        Errors.fromDTO = (dto) => {
            switch (dto.code) {
                case "INVALID_PAGE_INDEX":
                    return InvalidPageIndex.fromDTO(dto);
                default:
                    throw new InvalidResponseError(`Unexpected error code: ${dto.code}`);
            }
        };
        Errors.isInstanceOf = (other) => {
            const isInvalidPageIndexValue = other.isInvalidPageIndex;
            if (other instanceof InvalidPageIndex || Boolean(isInvalidPageIndexValue)) {
                return true;
            }
            return false;
        };
    })(Errors = GetFiDealRequestsRPC.Errors || (GetFiDealRequestsRPC.Errors = {}));
})(GetFiDealRequestsRPC = exports.GetFiDealRequestsRPC || (exports.GetFiDealRequestsRPC = {}));
