"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FixedIncomeSecurityType = exports.InvalidFixedIncomeSecurityTypeError = void 0;
class InvalidFixedIncomeSecurityTypeError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidFixedIncomeSecurityTypeError";
    }
}
exports.InvalidFixedIncomeSecurityTypeError = InvalidFixedIncomeSecurityTypeError;
/* eslint-disable @typescript-eslint/no-shadow */
var FixedIncomeSecurityType;
(function (FixedIncomeSecurityType_1) {
    let FixedIncomeSecurityType;
    (function (FixedIncomeSecurityType) {
        FixedIncomeSecurityType["SECURITY"] = "SECURITY";
        FixedIncomeSecurityType["T_BILL"] = "T_BILL";
    })(FixedIncomeSecurityType = FixedIncomeSecurityType_1.FixedIncomeSecurityType || (FixedIncomeSecurityType_1.FixedIncomeSecurityType = {}));
    FixedIncomeSecurityType_1.fromDTO = (dto) => {
        switch (dto.case) {
            case "SECURITY":
                return FixedIncomeSecurityType.SECURITY;
            case "T_BILL":
                return FixedIncomeSecurityType.T_BILL;
            default:
                throw new InvalidFixedIncomeSecurityTypeError(`Case ${dto.case} is not valid case of FixedIncomeSecurityType`);
        }
    };
    FixedIncomeSecurityType_1.toDTO = (fixedIncomeSecurityType) => {
        const ret = {
            case: FixedIncomeSecurityType[fixedIncomeSecurityType],
        };
        return ret;
    };
    FixedIncomeSecurityType_1.isInstanceOf = (other) => {
        if (other in FixedIncomeSecurityType) {
            return true;
        }
        return false;
    };
})(FixedIncomeSecurityType = exports.FixedIncomeSecurityType || (exports.FixedIncomeSecurityType = {}));
/* eslint-enable @typescript-eslint/no-shadow */
