import { Instance, types } from "mobx-state-tree";
import { SummaryDetailModel } from "../../../models/SummaryDetailModel";
import { CashflowTimelineModel } from "../../../models/CashflowTimelineModel";

export const FiDealSummaryModel = types.model("FiDealSummaryModel", {
  transactionDetails: types.array(SummaryDetailModel),
  securityDetails: types.array(SummaryDetailModel),
  cashflowTimeline: types.array(CashflowTimelineModel),
});

export const createFiDealSummaryModel = ({
  transactionDetails,
  securityDetails,
  cashflowTimeline,
}: {
  transactionDetails: Instance<typeof SummaryDetailModel>[];
  securityDetails: Instance<typeof SummaryDetailModel>[];
  cashflowTimeline: Instance<typeof CashflowTimelineModel>[];
}): Instance<typeof FiDealSummaryModel> => {
  return FiDealSummaryModel.create({
    transactionDetails,
    securityDetails,
    cashflowTimeline,
  });
};
