import { Instance, types } from "mobx-state-tree";
import { LabelAmount } from "@khazana/khazana-rpcs";
import { AmountModel, createAmountModel } from "./AmountModel";

export const LabelAmountModel = types.model("LabelAmountModel", {
  label: types.string,
  amount: AmountModel,
});

export const createLabelAmountModel = (
  data?: LabelAmount,
): Instance<typeof LabelAmountModel> => {
  if (data) {
    return LabelAmountModel.create({
      label: data.label,
      amount: createAmountModel(data.amount),
    });
  }
  return LabelAmountModel.create();
};
