import { GetMFConfirmationHistoryRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFConfirmationHistoryRPCImpl extends GetMFConfirmationHistoryRPC {
  execute(
    _request: GetMFConfirmationHistoryRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFConfirmationHistoryRPC.Response,
      GetMFConfirmationHistoryRPC.Errors.Errors
    >
  > {
    const response = new GetMFConfirmationHistoryRPC.Response([]);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFConfirmationHistoryRPC.Response,
        GetMFConfirmationHistoryRPC.Errors.Errors
      >
    >;
  }
}
