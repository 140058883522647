import React from "react";
import { Stack, Typography } from "@mui/material";
import {
  ChevronRight,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import {
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
} from "@surya-digital/leo-reactjs-core";
import { useBorder } from "../../../../utils/BorderUtils";

export interface ReportCardProps {
  icon: React.ReactElement<React.SVGProps<SVGSVGElement>>;
  name: string;
  subtitle?: string;
  path: string;
}

const Size = {
  container: "360px",
  cardIcon: "fit-content",
  chevronRight: {
    container: "fit-content",
    icon: "16px",
  },
};

export const ReportCard = ({
  icon,
  name,
  subtitle,
}: ReportCardProps): React.ReactElement => {
  const typography = useTypography();
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const spacing = useSpacing();

  return (
    <Stack
      sx={{
        background: tokens.background,
        borderRadius: cornerRadius.radiusXS,
        border: border.lowEmphasis,
        width: Size.container,
        padding: spacing.spaceXL,
      }}
      spacing={spacing.spaceMD}
    >
      <Stack
        sx={{
          padding: spacing.spaceXS,
          borderRadius: cornerRadius.radiusXXS,
          background: tokens.backgroundPrimarySubtle,
          width: Size.cardIcon,
        }}
      >
        {icon}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Stack spacing={spacing.spaceXXS}>
          <Typography sx={{ ...typography.s1 }}>{name}</Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {subtitle}
          </Typography>
        </Stack>
        <Stack
          sx={{
            padding: spacing.spaceXS,
            width: Size.chevronRight.container,
            height: Size.chevronRight.container,
          }}
        >
          <ChevronRight
            height={Size.chevronRight.icon}
            width={Size.chevronRight.icon}
            color={tokens.iconPrimary}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
