import React, { useState } from "react";
import {
  Button,
  Icon,
  Table,
  TableHeader,
  TableRowItems,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { useTranslation } from "react-i18next";
import { IMSTArray, Instance } from "mobx-state-tree";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFormattedAmountString } from "../../../../../utils";
import { useUserStore } from "../../../store/hooks";
import { getPath } from "../../../../../utils/RoutesUtils";
import { Module, Route } from "../../../../../routes/RoutesEnum";
import { FiUserPrivileges } from "../../../../user/UserPrivileges";
import { FiDealRequestBrokerSectionDetailModel } from "../models/FiDealRequestBrokerSectionDetailModel";
import { useBorder } from "../../../../../utils/BorderUtils";

export interface FiDealRequestBrokerSectionProps {
  brokerSectionDetails: IMSTArray<typeof FiDealRequestBrokerSectionDetailModel>;
  currencySymbol: string;
  isEditable: boolean;
  onEditBrokerButtonClick: () => Promise<void>;
}

const Size = {
  container: "100%",
  // This is a specific case where width values are expected to be a integer
  icon: 16,
  header: {
    name: "240px",
    requestedAmount: "200px",
    executedAmount: "200px",
    contractNoteNumber: "240px",
    status: "236px",
    action: "56px",
  },
};

export const FiDealRequestBrokerSection = ({
  brokerSectionDetails,
  currencySymbol,
  isEditable,
  onEditBrokerButtonClick,
}: FiDealRequestBrokerSectionProps): React.ReactElement => {
  const { t } = useTranslation();
  const userPrivileges = useUserStore().privileges;
  const tokens = useFoundationColorTokens();
  const border = useBorder();
  const cornerRadius = useCornerRadius();
  const navigate = useNavigate();
  const typography = useTypography();
  const spacing = useSpacing();
  const [isEditButtonLoading, setIsEditButtonLoading] = useState(false);

  const getRequestedAmountQuantity = (
    request: Instance<typeof FiDealRequestBrokerSectionDetailModel>,
  ): string => {
    if (request.requestedAmountQuantity.amount) {
      return getFormattedAmountString(
        request.requestedAmountQuantity.amount.amount,
      );
    } else if (request.requestedAmountQuantity.quantity) {
      return getFormattedAmountString(request.requestedAmountQuantity.quantity);
    } else {
      console.error(
        `No requested amount or quantity found. This should not happen since broker details should include quantity or amount.`,
      );
      return "-";
    }
  };

  const getExecutedAmountQuantity = (
    request: Instance<typeof FiDealRequestBrokerSectionDetailModel>,
  ): string => {
    const executedAmountQuantity =
      request.brokerStatus?.brokerStatusLinked?.executedAmountQuantity;
    if (executedAmountQuantity) {
      if (executedAmountQuantity.amount) {
        return getFormattedAmountString(executedAmountQuantity.amount.amount);
      } else if (executedAmountQuantity.quantity) {
        return getFormattedAmountString(executedAmountQuantity.quantity);
      }
    }
    return "-";
  };

  const getStatus = (
    request: Instance<typeof FiDealRequestBrokerSectionDetailModel>,
  ): string => {
    return request.brokerStatus?.brokerStatusLinked !== null
      ? t("common.linked")
      : t("common.linkingPending");
  };

  const getTableOptions = async (): Promise<string | TableRowItems> => {
    return brokerSectionDetails.map((request) => {
      return [
        { data: request.broker.name },
        {
          data: getRequestedAmountQuantity(request),
          align: "right",
        },
        {
          data: getExecutedAmountQuantity(request),
          align: "right",
        },
        {
          data:
            request.brokerStatus?.brokerStatusLinked?.contractNoteNumber ?? "-",
        },
        {
          data: getStatus(request),
        },
        {
          align: "right",
          data: (
            <Icon
              color={tokens.iconLowEmphasis}
              type="chevron-right"
              height={Size.icon}
              width={Size.icon}
            />
          ),
        },
      ];
    });
  };

  const getRequestedAmountQuantityTableHeader = (): string => {
    // We are using the first element to look for quantity or amount as it will be same for all the brokers.
    return brokerSectionDetails[0].requestedAmountQuantity.quantity === null
      ? `${t("common.requestedAmount", { symbol: currencySymbol })}`
      : t("common.requestedQuantity");
  };

  const getExecutedAmountQuantityTableHeader = (): string => {
    // We are using the first element to look for quantity or amount as it will be same for all the brokers.
    return brokerSectionDetails[0].requestedAmountQuantity.quantity === null
      ? `${t("common.executedAmount", { symbol: currencySymbol })}`
      : t("common.executedQuantity");
  };

  const getHeaders = (): TableHeader => {
    return [
      {
        id: "name",
        name: t("common.name"),
        width: Size.header.name,
      },
      {
        id: "requestedAmount",
        name: getRequestedAmountQuantityTableHeader(),
        width: Size.header.requestedAmount,
        align: "right",
      },
      {
        id: "executedAmount",
        name: getExecutedAmountQuantityTableHeader(),
        width: Size.header.executedAmount,
        align: "right",
      },
      {
        id: "contractNoteNumber",
        name: t("fi.contractNotes.contractNoteNumber"),
        width: Size.header.contractNoteNumber,
      },
      {
        id: "status",
        name: t("common.status"),
        width: Size.header.status,
      },
      { id: "action", name: "", width: Size.header.action },
    ];
  };
  return (
    <Stack
      sx={{
        width: "100%",
        border: border.default,
        borderRadius: cornerRadius.radiusXS,
      }}
    >
      <Stack
        sx={{
          background: tokens.background,
          padding: `${spacing.spaceSM} ${spacing.spaceXL}`,
          borderRadius: `${cornerRadius.radiusXS} ${cornerRadius.radiusXS} 0 0`,
          borderBottom: border.default,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            ...typography.sh3,
          }}
        >
          {t("common.broker")}
        </Typography>
        {isEditable && (
          <Button
            name={"editBrokerDetail"}
            size={"small"}
            loading={isEditButtonLoading}
            variant={"plain-color"}
            label={t("common.editBrokerDetails")}
            onClick={async (): Promise<void> => {
              setIsEditButtonLoading(true);
              await onEditBrokerButtonClick();
              setIsEditButtonLoading(false);
            }}
            style={{ padding: `${spacing.spaceXS} ${spacing.spaceSM}` }}
          />
        )}
      </Stack>
      <Table
        name={"fiDealRequestBrokerSection"}
        headers={getHeaders()}
        onTableOptionsChange={getTableOptions}
        viewOverrides={{
          empty: { message: t("common.noResultsFound") },
          idle: { message: t("common.searchTableIdleState") },
          loading: { message: t("common.searchTableLoadingState") },
        }}
        styleOverrides={{
          divider: "cell",
          background: tokens.background,
          borderRadius: `0 0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS}`,
          border: "0",
        }}
        onRowClick={(_, index): void => {
          if (
            userPrivileges.includes(
              FiUserPrivileges.ViewFixedIncomeContractNote,
            ) &&
            brokerSectionDetails[index].brokerStatus?.brokerStatusLinked !==
              null
          ) {
            const contractNoteId =
              brokerSectionDetails[index].brokerStatus?.brokerStatusLinked
                ?.contractNoteId;
            if (contractNoteId) {
              navigate(
                getPath(
                  Module.Fi,
                  Route.ContractNotesDetailsWithParams,
                  contractNoteId.toString(),
                ),
              );
            }
          }
        }}
      />
    </Stack>
  );
};
