import { GetMFApprovedDealRequestsRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockGetMFApprovedDealRequestsRPCImpl extends GetMFApprovedDealRequestsRPC {
  execute(
    _request: GetMFApprovedDealRequestsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetMFApprovedDealRequestsRPC.Response,
      GetMFApprovedDealRequestsRPC.Errors.Errors
    >
  > {
    const response = new GetMFApprovedDealRequestsRPC.Response([], 0);
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetMFApprovedDealRequestsRPC.Response,
        GetMFApprovedDealRequestsRPC.Errors.Errors
      >
    >;
  }
}
