export enum FDDealInvalidRequestError {
  InvalidRequestId = "INVALID_REQUEST_ID",
}

export enum FDDealCancelError {
  RequestAlreadySettled = "REQUEST_ALREADY_SETTLED",
  RequestAlreadyCancelled = "REQUEST_ALREADY_CANCELLED",
  RequestAlreadyRejected = "REQUEST_ALREADY_REJECTED",
  CanCancelOnlySelfRequest = "CAN_ONLY_CANCEL_SELF_REQUEST",
  RequestAlreadyActive = "REQUEST_ALREADY_ACTIVE",
}

export enum FDDealCkeckError {
  RequestAlreadyChecked = "REQUEST_ALREADY_CHECKED",
  RequestAlreadyCancelled = "REQUEST_ALREADY_CANCELLED",
  CannotCheckSelfRequest = "CANNOT_CHECK_SELF_REQUEST",
  RequestNotInACPending = "REQUEST_NOT_IN_AC_PENDING",
}

export enum FDCreateWithdrawRequestErrors {
  InvalidWithdrawAmount = "INVALID_WITHDRAW_AMOUNT",
  CanModifyOnlySelfRequest = "CAN_MODIFY_ONLY_SELF_REQUEST",
  DealNotActive = "DEAL_NOT_ACTIVE",
}

export enum FDConfirmWithdrawRequestErrors {
  InvalidWithdrawAmount = "INVALID_WITHDRAW_AMOUNT",
  CanModifyOnlySelfRequest = "CAN_MODIFY_ONLY_SELF_REQUEST",
  RequestNotApproved = "REQUEST_NOT_APPROVED",
}

export enum FDCertificateDetailsErrors {
  InvalidCertificateId = "INVALID_CERTIFICATE_ID",
}

export enum FDSubmitCertificateDetailsErrors {
  InvalidRequestState = "INVALID_REQUEST_STATE",
  CanModifyOnlySelfRequest = "CAN_MODIFY_ONLY_SELF_REQUEST",
  FieldError = "FIELD_ERROR",
}

export enum FDSettleDealErrors {
  IncorrectTotalAmount = "INCORRECT_TOTAL_AMOUNT",
}
