import { ConfirmWithdrawRequestRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockConfirmWithdrawRequestRPCImpl extends ConfirmWithdrawRequestRPC {
  execute(
    _request: ConfirmWithdrawRequestRPC.Request,
  ): Promise<
    LeoRPCResult<
      ConfirmWithdrawRequestRPC.Response,
      ConfirmWithdrawRequestRPC.Errors.Errors
    >
  > {
    const response = new ConfirmWithdrawRequestRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        ConfirmWithdrawRequestRPC.Response,
        ConfirmWithdrawRequestRPC.Errors.Errors
      >
    >;
  }
}
