import { getParent, IAnyStateTreeNode, Instance } from "mobx-state-tree";
import { useMFStore } from "../../../store/hooks";
import { DealRequestDetailsStore } from "./DealRequestDetailsStore";
import { DealDetailsErrorStore } from "./DealDetailsErrorStore";
import { MFDealHistoryStore } from "./MFDealHistoryStore";

export const useMFDealRequestDetailsStore = (): Instance<
  typeof DealRequestDetailsStore
> => {
  return useMFStore().dealRequestDetailsStore;
};

export const useMFDealDetailsErrorStore = (): Instance<
  typeof DealDetailsErrorStore
> => {
  return useMFDealRequestDetailsStore().errorStore;
};

export const useMFDealHistoryStore = (): Instance<
  typeof MFDealHistoryStore
> => {
  return useMFDealRequestDetailsStore().historyStore;
};

export const mfDealDetailsErrorStore = (
  store: IAnyStateTreeNode,
): Instance<typeof DealDetailsErrorStore> => {
  const createDealStore = getParent<typeof DealRequestDetailsStore>(store);
  return createDealStore.errorStore;
};
