"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MfConfirmationDealRequestDetail = void 0;
/* eslint-disable prettier/prettier */
/* prettier-ignore */
const leo_ts_runtime_1 = require("@surya-digital/leo-ts-runtime");
const bigAmount_1 = require("../types/bigAmount");
const bigDecimal_1 = require("../types/bigDecimal");
const mFTransactionType_1 = require("./mFTransactionType");
const mFDealRequestStatus_1 = require("./mFDealRequestStatus");
class InvalidMfConfirmationDealRequestDetailError extends Error {
    constructor(message) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = "InvalidMfConfirmationDealRequestDetailError";
    }
}
class MfConfirmationDealRequestDetail {
    constructor(folioNumber = null, entity, amount = null, quantity = null, transactionType, schemeName, requestId, requestStatus) {
        this.isMfConfirmationDealRequestDetail = true;
        if (folioNumber !== undefined && folioNumber !== null) {
            MfConfirmationDealRequestDetail.validateFolioNumber(folioNumber);
        }
        this.folioNumber = folioNumber;
        MfConfirmationDealRequestDetail.validateEntity(entity);
        this.entity = entity;
        if (amount !== undefined && amount !== null) {
            MfConfirmationDealRequestDetail.validateAmount(amount);
        }
        this.amount = amount;
        if (quantity !== undefined && quantity !== null) {
            MfConfirmationDealRequestDetail.validateQuantity(quantity);
        }
        this.quantity = quantity;
        MfConfirmationDealRequestDetail.validateTransactionType(transactionType);
        this.transactionType = transactionType;
        MfConfirmationDealRequestDetail.validateSchemeName(schemeName);
        this.schemeName = schemeName;
        MfConfirmationDealRequestDetail.validateRequestId(requestId);
        this.requestId = requestId;
        MfConfirmationDealRequestDetail.validateRequestStatus(requestStatus);
        this.requestStatus = requestStatus;
    }
    static fromDTO(dto) {
        const folioNumber = (0, leo_ts_runtime_1.getOptionalString)(dto, "folioNumber", InvalidMfConfirmationDealRequestDetailError);
        const entity = (0, leo_ts_runtime_1.getString)(dto, "entity", InvalidMfConfirmationDealRequestDetailError);
        let amount = (0, leo_ts_runtime_1.getOptionalObject)(dto, "amount");
        if (amount !== undefined && amount !== null) {
            amount = bigAmount_1.BigAmount.fromDTO(amount);
        }
        let quantity = (0, leo_ts_runtime_1.getOptionalObject)(dto, "quantity");
        if (quantity !== undefined && quantity !== null) {
            quantity = bigDecimal_1.BigDecimal.fromDTO(quantity);
        }
        const transactionType = mFTransactionType_1.MFTransactionType.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "transactionType", InvalidMfConfirmationDealRequestDetailError));
        const schemeName = (0, leo_ts_runtime_1.getString)(dto, "schemeName", InvalidMfConfirmationDealRequestDetailError);
        const requestId = (0, leo_ts_runtime_1.getInt64)(dto, "requestId", InvalidMfConfirmationDealRequestDetailError);
        const requestStatus = mFDealRequestStatus_1.MFDealRequestStatus.fromDTO((0, leo_ts_runtime_1.getObject)(dto, "requestStatus", InvalidMfConfirmationDealRequestDetailError));
        return new MfConfirmationDealRequestDetail(folioNumber, entity, amount, quantity, transactionType, schemeName, requestId, requestStatus);
    }
    toDTO() {
        const ret = {
            entity: this.entity,
            transactionType: mFTransactionType_1.MFTransactionType.toDTO(this.transactionType),
            schemeName: this.schemeName,
            requestId: this.requestId,
            requestStatus: mFDealRequestStatus_1.MFDealRequestStatus.toDTO(this.requestStatus),
        };
        if (this.folioNumber !== undefined && this.folioNumber !== null) {
            ret.folioNumber = this.folioNumber;
        }
        if (this.amount) {
            ret.amount = this.amount.toDTO();
        }
        if (this.quantity) {
            ret.quantity = this.quantity.toDTO();
        }
        return ret;
    }
    copy(folioNumber = this.folioNumber, entity = this.entity, amount = this.amount, quantity = this.quantity, transactionType = this.transactionType, schemeName = this.schemeName, requestId = this.requestId, requestStatus = this.requestStatus) {
        return new MfConfirmationDealRequestDetail(folioNumber, entity, amount, quantity, transactionType, schemeName, requestId, requestStatus);
    }
    equals(other) {
        if (Object.is(this, other)) {
            return true;
        }
        const isMfConfirmationDealRequestDetailValue = other.isMfConfirmationDealRequestDetail;
        if (!(other instanceof MfConfirmationDealRequestDetail) || Boolean(isMfConfirmationDealRequestDetailValue)) {
            return false;
        }
        if (this.folioNumber !== other.folioNumber) {
            return false;
        }
        if (this.entity !== other.entity) {
            return false;
        }
        if (this.amount) {
            if (!this.amount.equals(other.amount)) {
                return false;
            }
        }
        if (this.quantity) {
            if (!this.quantity.equals(other.quantity)) {
                return false;
            }
        }
        if (this.transactionType !== other.transactionType) {
            return false;
        }
        if (this.schemeName !== other.schemeName) {
            return false;
        }
        if (this.requestId !== other.requestId) {
            return false;
        }
        if (this.requestStatus !== other.requestStatus) {
            return false;
        }
        return true;
    }
}
exports.MfConfirmationDealRequestDetail = MfConfirmationDealRequestDetail;
MfConfirmationDealRequestDetail.validateFolioNumber = (folioNumber) => {
    if (!(0, leo_ts_runtime_1.isString)(folioNumber)) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute folioNumber is not a String`);
    }
    if (folioNumber.trim().length === 0) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute folioNumber cannot be an empty string.`);
    }
};
MfConfirmationDealRequestDetail.validateEntity = (entity) => {
    if (!(0, leo_ts_runtime_1.isString)(entity)) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute entity is not a String`);
    }
    if (entity.trim().length === 0) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute entity cannot be an empty string.`);
    }
};
MfConfirmationDealRequestDetail.validateAmount = (amount) => {
    const isBigAmountValue = amount.isBigAmount;
    if (!(amount instanceof bigAmount_1.BigAmount || Boolean(isBigAmountValue))) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute amount is not a BigAmount`);
    }
};
MfConfirmationDealRequestDetail.validateQuantity = (quantity) => {
    const isBigDecimalValue = quantity.isBigDecimal;
    if (!(quantity instanceof bigDecimal_1.BigDecimal || Boolean(isBigDecimalValue))) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute quantity is not a BigDecimal`);
    }
};
MfConfirmationDealRequestDetail.validateTransactionType = (transactionType) => {
    if (!mFTransactionType_1.MFTransactionType.isInstanceOf(transactionType)) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute transactionType is not a MFTransactionType.MFTransactionType`);
    }
};
MfConfirmationDealRequestDetail.validateSchemeName = (schemeName) => {
    if (!(0, leo_ts_runtime_1.isString)(schemeName)) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute schemeName is not a String`);
    }
    if (schemeName.trim().length === 0) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute schemeName cannot be an empty string.`);
    }
};
MfConfirmationDealRequestDetail.validateRequestId = (requestId) => {
    if (!(0, leo_ts_runtime_1.isInt64)(requestId)) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute requestId is not an Int64`);
    }
};
MfConfirmationDealRequestDetail.validateRequestStatus = (requestStatus) => {
    if (!mFDealRequestStatus_1.MFDealRequestStatus.isInstanceOf(requestStatus)) {
        throw new InvalidMfConfirmationDealRequestDetailError(`Attribute requestStatus is not a MFDealRequestStatus.MFDealRequestStatus`);
    }
};
MfConfirmationDealRequestDetail.prototype.toString = function toString() {
    return `MfConfirmationDealRequestDetail(folioNumber='${this.folioNumber}',entity='${this.entity}',amount=${this.amount},quantity=${this.quantity},transactionType=${this.transactionType},schemeName='${this.schemeName}',requestId=${this.requestId},requestStatus=${this.requestStatus})`;
};
