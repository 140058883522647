import { FdEditAccrualRPC } from "@khazana/khazana-rpcs";
import { LeoRPCResult } from "@surya-digital/leo-ts-runtime";
import { mockExecute } from "../../../../networking/MockExecuteHelper";

export class MockFdEditAccrualRPCImpl extends FdEditAccrualRPC {
  execute(
    _request: FdEditAccrualRPC.Request,
  ): Promise<
    LeoRPCResult<FdEditAccrualRPC.Response, FdEditAccrualRPC.Errors.Errors>
  > {
    const response = new FdEditAccrualRPC.Response();
    return mockExecute({ response }) as Promise<
      LeoRPCResult<FdEditAccrualRPC.Response, FdEditAccrualRPC.Errors.Errors>
    >;
  }
}
