import { Instance } from "mobx-state-tree";
import { useMFStore } from "../../store/hooks";
import { MfReportsStore } from "./MfReportsStore";
import { MfHoldingSummaryStore } from "./MfHoldingSummaryStore";
import { MfInvestmentReturnsGrowthStore } from "./MfInvestmentReturnsGrowthStore";

export const useMfReportsStore = (): Instance<typeof MfReportsStore> => {
  return useMFStore().mfReportsStore;
};

export const useMfHoldingSummaryStore = (): Instance<
  typeof MfHoldingSummaryStore
> => {
  return useMfReportsStore().mfHoldingSummaryStore;
};

export const useMfInvestmentReturnsGrowthStore = (): Instance<
  typeof MfInvestmentReturnsGrowthStore
> => {
  return useMfReportsStore().mfInvestmentRetunsGrowthStore;
};
