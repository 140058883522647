import { Box, Divider, Stack, Typography } from "@mui/material";
import {
  Avatar,
  Chip,
  ChipColor,
  useCornerRadius,
  useFoundationColorTokens,
  useSpacing,
  useTypography,
} from "@surya-digital/leo-reactjs-material-ui";
import { getFormattedTimeDateWithComma } from "../../../../utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBorder } from "../../../../utils/BorderUtils";

const Size = {
  container: "100%",
};

export interface DealHistoryRowProps {
  firstName: string;
  lastName: string | null;
  requestedAt: string;
  note: string | null;
  isHistoryComponent: boolean;
  userImage: string | undefined;
  title: string;
  chipText: string;
  chipColor: ChipColor;
  additionalDetails: React.ReactElement[];
}

export const DealHistoryRow = ({
  firstName,
  lastName,
  requestedAt,
  note,
  isHistoryComponent,
  userImage,
  title,
  chipText,
  chipColor,
  additionalDetails = [],
}: DealHistoryRowProps): React.ReactElement => {
  const typography = useTypography();
  const spacing = useSpacing();
  const cornerRadius = useCornerRadius();
  const { t } = useTranslation();
  const tokens = useFoundationColorTokens();
  const border = useBorder();

  return (
    <Stack
      sx={{
        backgroundColor: tokens.background,
        width: Size.container,
        borderRadius: isHistoryComponent
          ? `0 0 ${cornerRadius.radiusXS} ${cornerRadius.radiusXS}`
          : cornerRadius.radiusXS,
        border: isHistoryComponent ? undefined : border.default,
        borderRight: isHistoryComponent ? undefined : border.default,
      }}
    >
      <Stack flexDirection="row" padding={spacing.spaceXL}>
        <Avatar
          userName={`${firstName} ${lastName ?? ""}`}
          size="large"
          src={userImage}
        />
        <Box sx={{ marginLeft: spacing.spaceMD }}>
          <Typography
            sx={{
              ...typography.sh3,
              color: tokens.labelHighEmphasis,
            }}
          >
            {title}
          </Typography>
          <Typography sx={{ ...typography.b2, color: tokens.labelSubtle }}>
            {getFormattedTimeDateWithComma(new Date(requestedAt))}
          </Typography>
        </Box>
      </Stack>
      {!isHistoryComponent && <Divider />}
      <Stack
        padding={spacing.spaceXL}
        paddingTop={isHistoryComponent ? "0" : spacing.spaceXL}
        gap={spacing.spaceXL}
        marginLeft={isHistoryComponent ? "64px" : "0px"}
      >
        <Box>
          <Chip label={chipText} color={chipColor} />
        </Box>
        {note && (
          <Stack>
            <Typography {...typography.s1}>{t("common.note")}</Typography>
            <Typography {...typography.b1}>{note}</Typography>
          </Stack>
        )}
        {additionalDetails.length > 0 && (
          <Stack flexDirection="row" gap={spacing.spaceXL}>
            {additionalDetails.map((value, index) => {
              return <div key={index}>{value}</div>;
            })}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
