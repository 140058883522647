import { types } from "mobx-state-tree";
import { FiDealRequestStatus, FiTransactionType } from "@khazana/khazana-rpcs";

export const FiDealRequestModel = types.model({
  requestId: types.number,
  dealRequestStatus: types.enumeration<FiDealRequestStatus.FiDealRequestStatus>(
    "FiDealRequestStatus",
    Object.values(FiDealRequestStatus.FiDealRequestStatus),
  ),
  security: types.string,
  transactionType: types.enumeration<FiTransactionType.FiTransactionType>(
    "FiTransactionType",
    Object.values(FiTransactionType.FiTransactionType),
  ),
  amount: types.number,
  quantity: types.number,
  ytm: types.number,
  entity: types.string,
  portfolio: types.maybeNull(types.string),
  dematAccountNumber: types.string,
  executedAmount: types.maybe(types.number),
  executedUnits: types.maybe(types.number),
  modifiedAt: types.Date,
  createdAt: types.Date,
});
