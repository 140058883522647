import {
  Currency,
  GetFiMaturityRequestDetailsRPC,
  DetailCell,
  FiMaturityDealRequestStatus,
  DetailCellType,
  BigDecimal,
  BigAmount,
} from "@khazana/khazana-rpcs";
import { mockExecute } from "../../../../networking/MockExecuteHelper";
import { LeoRPCResult, LeoTimestamp } from "@surya-digital/leo-ts-runtime";

export class MockGetFiMaturityRequestDetailsRPCImpl extends GetFiMaturityRequestDetailsRPC {
  execute(
    _request: GetFiMaturityRequestDetailsRPC.Request,
  ): Promise<
    LeoRPCResult<
      GetFiMaturityRequestDetailsRPC.Response,
      GetFiMaturityRequestDetailsRPC.Errors.Errors
    >
  > {
    const requestedDetails: DetailCell[] = [
      new DetailCell(
        "fi.maturityRequestDetails.maturityRequestId",
        new DetailCellType.Unstyled("81", false),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.securityName",
        new DetailCellType.Unstyled("AXISBANK", false),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.requestStatus",
        new DetailCellType.StatusType(
          FiMaturityDealRequestStatus.FiMaturityDealRequestStatus.SETTLEMENT_PENDING,
        ),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.entity",
        new DetailCellType.Unstyled("Udit", false),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.portfolio",
        new DetailCellType.Unstyled("Compounding", false),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.maturedUnits",
        new DetailCellType.BigQuantityType(new BigDecimal("100000")),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.settlementAmount",
        new DetailCellType.BigAmountType(
          new BigAmount(
            new BigDecimal("10000000000"),
            new Currency("INR", "₹"),
          ),
        ),
      ),
      new DetailCell(
        "fi.dealRequestDetails.createdAt",
        new DetailCellType.Time(new LeoTimestamp()),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.recipientBankLabel",
        new DetailCellType.Unstyled(
          "38385beb-7066-41cf-8233-24b343e78f9b",
          true,
        ),
      ),
      new DetailCell(
        "fi.maturityRequestDetails.recipientBankAccountLabel",
        new DetailCellType.Unstyled("7363130238873", true),
      ),
    ];

    const response = new GetFiMaturityRequestDetailsRPC.Response(
      true,
      requestedDetails,
    );

    return mockExecute({ response }) as Promise<
      LeoRPCResult<
        GetFiMaturityRequestDetailsRPC.Response,
        GetFiMaturityRequestDetailsRPC.Errors.Errors
      >
    >;
  }
}
